<template>
  <div>
    <modal name="officeSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <OfficeSelect :table-data="params.res.office_list" @close="hideOffice"/>
      </div>
    </modal>
    <modal name="workScheduleSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <WorkScheduleSelect :table-data="workScheduleList" @close="hideWorkSchedule"/>
      </div>
    </modal>
    <form @submit.prevent="submitStamp" method="POST" action="#" style="padding: 50px;">
      <!--
      <canvas id="canvas" width="800" height="800"></canvas>
      -->
      <iframe style="border: none;" width="850" height="850" :src="`/static/clock/index.html?width=800`"></iframe>
      <div style="display: inline-block; vertical-align: top; padding-left: 50px;">
        <p style="padding: 50px 0 30px 0;">
          打刻種類を選択し、従業員番号にカーソルがある状態でQRコードを読み取ってください。
        </p>
        <div style="padding-bottom: 10px;">
          <div class="form-group">
            <label>事業所名</label>
            <div>
              <input 
                type="text" 
                :name="officeName"
                class="form-control width300" 
                v-model="officeName" 
                :inline="true"
                :maxlength="30"
                :disabled="true"
                style="display: inline;"
              />
              <!-- <button type="button" class="btn btn-sm btn-default btn btn-warning" @click="showOffice">選択</button> -->
            </div>
          </div>
        </div>
        <div style="padding-bottom: 20px;">
          <div><label>打刻種類</label></div>
          <div class="btn-group btn-group-toggle mx-2 my-2">
            <label class="btn btn-outline-secondary" :class="{ active: stampType === '1' }">
              <input type="radio" id="workStart" v-model="stampType" value="1" style="position: absolute;" checked>　出勤　
            </label>
            <label class="btn btn-outline-secondary" :class="{ active: stampType === '2' }">
              <input type="radio" id="workEnd" v-model="stampType" value="2" style="position: absolute;">　退勤　
            </label>
            <label class="btn btn-outline-secondary" :class="{ active: stampType === '3' }">
              <input type="radio" id="breakStart" v-model="stampType" value="3" style="position: absolute;">休憩開始
            </label>
            <label class="btn btn-outline-secondary" :class="{ active: stampType === '4' }">
              <input type="radio" id="breakEnd" v-model="stampType" value="4" style="position: absolute;">休憩終了
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>従業員番号</label>
          <div>
            <input 
              type="tel" 
              name="employeeCode"
              class="form-control width130" 
              v-model="employeeCode"
              maxlength="10"
            />
          </div>
        </div>
        <div style="text-align: left; padding: 20px 0 0 0;">
          <button type="submit" class="btn btn-sm btn-default btn-wd btn-info" style="display: none;">送信</button>
        </div>
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                  :data="tableData"
                  height="400px"
                  style="width: 440px;">
          <el-table-column min-width="120" label="受付" align="center">
            <template slot-scope="props">
              <small></small>
              <br>
              <div style="text-align: center;">{{props.row.stampType}}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="従業員" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <small>{{props.row.employeeCode}}</small>
                <br>
                {{props.row.employeeName}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="200" label="打刻時刻" align="center">
            <template slot-scope="props">
              <small></small>
              <br>
              <div style="text-align: center;">{{props.row.stampTime}}</div>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding-top: 20px;">
          <button type="button" class="btn btn-sm btn-default btn-wd btn-warning" @click="logout">{{$t('label.logout')}}</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import OfficeSelect from '@/components/Dashboard/Views/Qrcode/OfficeSelect'
  import WorkScheduleSelect from '@/components/Dashboard/Views/Qrcode/WorkScheduleSelect'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default{
    mixins: [mixin],
    components: {
      OfficeSelect,
      WorkScheduleSelect,
      ValidationText
    },
    data () {
      return {
        workScheduleList: [],
        params: undefined,
        intervalId: undefined,
        gDate: new Date(),
        gClockColor: '#000000',
        gClockBgColor: '#ffffff',
        officeCode: undefined,
        officeName: undefined,
        stampType: '1',
        employeeCode: undefined,
        employeeName: undefined,
        workScheduleCode: undefined,
        tableData: []
      }
    },
    watch: {
      stampType () {
        this.setFocus()
      }
    },
    computed: {
      stampTypeName () {
        if (this.stampType === '1') {
          return this.$t('button.job_start')
        } else if (this.stampType === '2') {
          return this.$t('button.job_end')
        } else if (this.stampType === '3') {
          return this.$t('button.break_start')
        } else if (this.stampType === '4') {
          return this.$t('button.break_end')
        }
        return ''
      }
    },
    methods: {
      logout () {
        this.$router.go(-1)
      },
      updateCanvas () {
        // ---------------------------------
        // 時刻取得
        var dateNow = new Date()
        // ---------------------------------
        // 現在の秒と前回描写時の秒が同じ場合、一定時間スリープ
        // if (this.gDate.getSeconds() === dateNow.getSeconds()) {
        //   return false
        // }
        // ---------------------------------
        // 時刻更新
        this.gDate = dateNow
        // ---------------------------------
        // 時計の描写
        this.draw()
        // ---------------------------------
        // 一定時間スリープ後、再びこの関数を実行させる
        return true
      },
      draw () {
        // ---------------------------------
        // 変数宣言
        var canvas = document.getElementById('canvas') // HTMLのCanvas要素
        if (!canvas || !canvas.getContext) {
          return false
        }
        var ctx = canvas.getContext('2d') // Canvasのコンテキスト
        var center = {
          x: canvas.width / 2,
          y: canvas.height / 2
        } // Canvasの中心座標
        // ---------------------------------
        // 基盤・針・文字盤の描写
        ctx.clearRect(0, 0, center.x * 2, center.y * 2)
        ctx.strokeStyle = this.gColor
        ctx.fillStyle = this.gColor
        this.drawClockBase(ctx, center)
        this.drawClockTime(ctx, center, this.gDate)
        this.drawClockString(ctx, center, this.gDate)
      },
      drawClockBase (ctx, center) {
        // ---------------------------------
        // 変数宣言
        var ClockRomanNumerals = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
        var rCanvas = Math.min(center.x, center.y)
        var rLineStart = rCanvas * 0.97
        var rLineEndShort = rCanvas * 0.94
        var rLineEndLong = rCanvas * 0.91
        var rString = rCanvas * 0.85
        var i, uv, angle
        ctx.font = 'normal 24px "Times New Roman"'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        // ---------------------------------
        // 基盤の描写
        ctx.beginPath()
        ctx.fillStyle = this.gClockBgColor
        ctx.arc(center.x, center.y, rCanvas, 0, Math.PI * 2, false)
        ctx.fill()
        ctx.beginPath()
        ctx.strokeStyle = this.gClockColor
        ctx.fillStyle = this.gClockColor
        for (i = 0; i < 60; i++) {
          angle = Math.PI * i / 30
          uv = {
            x: Math.sin(angle),
            y: -Math.cos(angle)
          }
          ctx.moveTo(
            center.x + uv.x * rLineStart,
            center.y + uv.y * rLineStart)
          if (i % 5 === 0) {
            ctx.lineTo(
              center.x + uv.x * rLineEndLong,
              center.y + uv.y * rLineEndLong)
            ctx.fillText(
              ClockRomanNumerals[i / 5],
              center.x + uv.x * rString,
              center.y + uv.y * rString)
          } else {
            ctx.lineTo(
              center.x + uv.x * rLineEndShort,
              center.y + uv.y * rLineEndShort)
          }
        }
        ctx.stroke()
      },
      drawClockTime (ctx, center, date) {
        // ---------------------------------
        // 変数宣言
        var rCanvas = Math.min(center.x, center.y)
        var rHour = rCanvas * 0.575
        var rMinute = rCanvas * 0.825
        var rSecond = rCanvas * 0.900
        var uv, angle
        ctx.beginPath()
        ctx.strokeStyle = this.gClockColor
        // ---------------------------------
        // 時
        angle = Math.PI * (date.getHours() % 12 + date.getMinutes() / 60) / 6
        uv = {
          x: Math.sin(angle),
          y: -Math.cos(angle)
        }
        ctx.moveTo(
          center.x - uv.x * rHour / 4,
          center.y - uv.y * rHour / 4)
        ctx.lineTo(
          center.x + uv.x * rHour,
          center.y + uv.y * rHour)
        // ---------------------------------
        // 分
        angle = Math.PI * (date.getMinutes() + date.getSeconds() / 60) / 30
        uv = {
          x: Math.sin(angle),
          y: -Math.cos(angle)
        }
        ctx.moveTo(
          center.x - uv.x * rMinute / 4,
          center.y - uv.y * rMinute / 4)
        ctx.lineTo(
          center.x + uv.x * rMinute,
          center.y + uv.y * rMinute)
        // ---------------------------------
        // 秒
        let seconds = (date.getSeconds() * 1000.0 + date.getMilliseconds()) / 1000.0
        angle = (Math.PI * seconds) / 30.0
        uv = {
          x: Math.sin(angle),
          y: -Math.cos(angle)
        }
        ctx.moveTo(
          center.x - uv.x * rSecond / 4,
          center.y - uv.y * rSecond / 4)
        ctx.lineTo(
          center.x + uv.x * rSecond,
          center.y + uv.y * rSecond)
        ctx.stroke()
      },
      drawClockString (ctx, center, date) {
        // ---------------------------------
        // 変数宣言
        var offset1 = 90
        var offset2 = 120
        ctx.font = 'normal 24px "Times New Roman"'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = this.gClockColor
        // ---------------------------------
        // 文字盤の描写
        ctx.fillText(this.formatYMD(date, '.'), center.x, center.y + offset1)
        ctx.fillText(this.formatHMS(date, ':'), center.x, center.y + offset2)
      },
      formatYMD (date) {
        var s = []
        s[0] = ('0000' + date.getFullYear()).replace(/^.*(....)$/, '$1')
        s[1] = ('00' + (date.getMonth() + 1)).replace(/^.*(..)$/, '$1')
        s[2] = ('00' + date.getDate()).replace(/^.*(..)$/, '$1')
        s[3] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
        s[4] = ''
        return s.join('.')
      },
      formatHMS (date) {
        var s = []
        s[0] = ('00' + date.getHours()).replace(/^.*(..)$/, '$1')
        s[1] = ('00' + date.getMinutes()).replace(/^.*(..)$/, '$1')
        s[2] = ('00' + date.getSeconds()).replace(/^.*(..)$/, '$1')
        return s.join(':')
      },
      submitStamp () {
        for (const employee of this.params.res.employee_list) {
          if (employee.employee_code === this.employeeCode) {
            this.employeeName = employee.employee_name
            if (employee.work_schedule_list.length > 1) {
              this.workScheduleList = employee.work_schedule_list
              this.$modal.show('workScheduleSelect')
            } else {
              this.workScheduleCode = employee.work_schedule_list[0].work_schedule_code
              this.submit()
            }
            break
          }
        }
        this.setFocus()
      },
      setFocus () {
        this.$nextTick(() => {
          document.getElementsByName('employeeCode')[0].value = ''
          document.getElementsByName('employeeCode')[0].focus()
        })
      },
      showOffice () {
        this.$modal.show('officeSelect')
      },
      hideOffice (selectedOffice) {
        this.$modal.hide('officeSelect')
        this.params.office = selectedOffice
        this.officeCode = selectedOffice.office_code
        this.officeName = selectedOffice.office_name
        this.setFocus()
      },
      hideWorkSchedule (selectedWorkSchdule) {
        this.$modal.hide('workScheduleSelect')
        this.workScheduleCode = selectedWorkSchdule.work_schedule_code
        this.submit()
        this.setFocus()
      },
      submit () {
        let groupCode = ''
        for (const row of this.params.res.employee_list) {
          if (row.employee_code === this.employeeCode) {
            groupCode = row.group_list[0].group_code
          }
        }
        let self = this
        let params = {
          company_code: this.params.res.company_code,
          group_code: groupCode,
          office_code: this.officeCode,
          employee_code: this.employeeCode,
          work_schedule_code: this.workScheduleCode,
          stamping_type: parseInt(this.stampType, 10),
          device_id: 5,
          smile_mark: null,
          lat: '',
          lng: ''
        }
        this.sendDirect(`${process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN}/${process.env.VUE_APP_PUBLISH_FUNCTION_NAME}`, params)
        .then(data => {
          let row = {
            employeeCode: self.employeeCode,
            employeeName: self.employeeName,
            stampTime: data.stamping_time,
            stampType: self.stampTypeName
          }
          self.tableData.splice(0, 0, row)
          self.setFocus()
        })
        .catch(error => {
          console.error(error)
          swal({
            text: '打刻に失敗しました。',
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(function (result) {
            self.setFocus()
          })
        })
      }
    },
    created () {
      this.params = this.$route.params
      this.officeCode = this.params.office.office_code
      this.officeName = this.params.office.office_name
    },
    mounted () {
      this.intervalId = setInterval(function () {
        this.updateCanvas()
      }.bind(this), 100)
      this.setFocus()
    }
  }
</script>
<style>
  #canvas {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .8);
    border-radius: 400px;
  }
</style>
