import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Generated/LunchSupplierDetail'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'LunchSupplierSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    PPagination
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        supplier_name: ''
      },
      showOperation: true,
      showSupplierName$homePage: true,
      showPostCode: true,
      showTel: true,
      showMailAddress: true,
      showDateOfCollection: true,
      showCollectionDay: true,
      showUpdateDate: true,
      showLunch: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      tableStyle: ''
    }
  },
  computed: {
    screenCode () {
      return '03-014'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    lunchClicked (index, row) {},
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/lunch_supplier/list', reqData)
      .then(res => {
        this.pagination.currentPage = 1
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.lunch_supplier_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0 && this.showSearchCondition) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          let width = 0
          if (!this.$refs.table) return
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.$refs.summary.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          supplier_code: this.params.supplier_code
        }
      }
      this.send('/init/lunch_supplier/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.supplier_code = row.supplier_code
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.supplier_code = row.supplier_code
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.supplier_code = row.supplier_code
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      } else if (row.update_date) {
        if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
          return 'update-row'
        }
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    }
  },
  created () {
    this.init()
  }
}
