<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <!-- 発行日 -->
            <ValidationDate
              v-model="detail.issue_date"
              item-name="issue_date"
              rules="required"
              :item-label="labels.issue_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.start_date')"
              :disabled="isReadOnly"
              @change="inputIssueDate(detail.issue_date)"
            />
            <!-- 見積もり有効期限 -->
            <ValidationDate
              v-model="detail.request_quote_term_to"
              item-name="request_quote_term_to"
              :item-label="labels.request_quote_term_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.start_date')"
              :disabled="isReadOnly"
            />
            <!-- 取引先 -->
            <ValidationSelectSimple
              v-model="detail.client_code"
              item-name="client_code"
              :placeholder="$t('label.select_description')"
              classes="length10"
              rules="required"
              :item-label="labels.client_code_ryaku"
              :options="comboData.customer_list"
              :disabled="isReadOnly"
              @change="clientCodeChanged(detail.client_code)"
            />
            <!-- 敬称 -->
            <ValidationSelectSimple
              v-model="detail.honorific"
              item-name="honorific"
              :placeholder="$t('label.select_description')"
              classes="length0"
              :item-label="labels.honorific"
              :options="comboData.honorific_list"
              :disabled="isReadOnly"
              @change="honorificChanged"
            />
            <!-- 従業員番号[担当] -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <label>{{labels.responsible_employee_code}}</label>
              <div>
                <input type="text"
                  class="form-control width130"
                  style="display:inline-block;"
                  :placeholder="labels.PH_CODE"
                  :disabled="isReadOnly"
                  v-model="detail.employee_code"
                  @blur="employeeSelected">
              </div>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <label>{{labels.employee_name}}</label>
              <div>
                <div style="display: inline-block; transform: translate(0px,1px);">
                  <input type="text"
                    class="form-control width300"
                    style="display:inline-block;"
                    :disabled="true"
                    v-model="detail.employee_name">
                </div>
                <button type="button"
                  class="btn btn-sm btn-default btn-warning"
                  style="transform: translate(0px,4px);"
                  :disabled="isReadOnly"
                  @click="showEmployeeSearchModal"
                  v-show="canShow('/SS/menuSSScreen018')">
                  <i class="ti-search"></i>
                </button>
              </div>
            </div>
            <!-- 件名 -->
            <ValidationText
              v-model="detail.subject"
              item-name="subject"
              max-length="255"
              classes="form-control length255"
              rules="required"
              :item-label="labels.subject"
              :placeholder="isReadOnly ? '' : $t('placeholder.subject')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 明細毎の端数処理 -->
            <ValidationRadio
              v-model="detail.rounding_off_for_each_line_item"
              rules="required"
              :item-label="labels.rounding_off_for_each_line_item"
              :disabled="isReadOnly"
              @change="roundingOffForEachLineItemChanged"
            />
            <!-- 消費税の端数処理 -->
            <ValidationRadio
              v-model="detail.rounding_of_consumption_tax"
              rules="required"
              :item-label="labels.rounding_of_consumption_tax"
              :disabled="isReadOnly"
              @change="roundingOfConsumptionTaxChanged"
            />
            <!-- 消費税[内税/外税] -->
            <ValidationRadio
              v-model="detail.tax_inclusive_exclusive"
              rules="required"
              :item-label="labels.tax_inclusive_exclusive"
              :disabled="isReadOnly"
              @change="taxInclusiveExclusiveChanged"
            />
            <!-- 見積明細 -->
            <label>{{labels.quotationDetail}}</label><span class="required">{{$t('label.required')}}</span>
            <div class="form-group">
              <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="addRow(detail.quotation_detail_list)" v-show="mode==modes.add || mode==modes.edit">{{$t('button.add_row')}}</button>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in quotationDetailListErrors" v-bind:key="error">
                <small>{{error}}</small>
              </div>
              <!-- 合計のテーブル -->
              <table boder="1" style="border-right: 1px solid #ddd; margin-bottom: 5px;">
                <!-- 幅調整 -->
                <tr style="border-top:none; border-right:none; border-left:none;">
                  <th width="110"></th>
                  <th width="130"></th>
                  <th width="110"></th>
                  <th width="130"></th>
                  <th width="110"></th>
                  <th width="130"></th>
                </tr>
                <tr style="background-color: #EEE;">
                  <!-- 小計 -->
                  <th style="text-align: center;">{{ labels.subtotal }}</th>
                  <th style="text-align: right;">
                    <ValidationTextNumber
                      v-model="detail.subtotal"
                      item-name="subtotal"
                      integral="10"
                      classes="form-control numLength10"
                      :disabled="true"
                      :newLine="false"
                    />
                  </th>
                  <!-- 消費税 -->
                  <th style="text-align: center;">{{ labels.consumption_tax }}</th>
                  <th style="text-align: right;">
                    <ValidationTextNumber
                      v-model="detail.consumption_tax"
                      item-name="consumption_tax"
                      integral="10"
                      classes="form-control numLength10"
                      :disabled="true"
                      :newLine="false"
                    />
                  </th>
                  <!-- 合計 -->
                  <th style="text-align: center;">{{ labels.total }}</th>
                  <th style="text-align: right;">
                    <ValidationTextNumber
                      v-model="detail.total"
                      item-name="total"
                      integral="10"
                      classes="form-control numLength10"
                      :disabled="true"
                      :newLine="false"
                    />
                  </th>
                </tr>
              </table>
              <!-- 見積明細のテーブル -->
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                class="table-width"
                border
                :data="detail.quotation_detail_list"
                :empty-text="$t('label.no_data')"
                ref="table"
                max-height="380"
              >
                <!-- 削除 -->
                <el-table-column :width="50" :label="$t('label.delete')" align="center">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input
                        type="checkbox"
                        :disabled="isReadOnly"
                        v-model="props.row.delete"
                        :class="isReadOnly ? 'gray2' : 'gray'"
                        @focus="deleteChanged(props.row.delete, props.$index)"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 品名 -->
                <el-table-column
                  :width="205"
                  :label="labels.product_code_ryaku === undefined ? '' : labels.product_code_ryaku"
                  align="center"
                >
                  <template slot-scope="props">
                    <ValidationSelectSimple
                      v-model="props.row.product_code"
                      item-name="product_code"
                      :placeholder="$t('label.select_description')"
                      classes="length0"
                      :options="comboData.product_list"
                      :disabled="isReadOnly || props.row.delete"
                      @change="productCodeChanged(props.row.product_code, props.$index)"
                    />
                  </template>
                </el-table-column>
                <!-- 単価 -->
                <el-table-column
                  :width="145"
                  :label="labels.unit_price_ryaku === undefined ? '' : labels.unit_price_ryaku"
                  align="center"
                >
                  <template slot-scope="props">
                    <div class="error-message-wrapper" v-show="props.row.error_unit_price && !props.row.unit_price">
                      <small class="text-danger">
                        {{ props.row.error_unit_price }}
                      </small>
                    </div>
                    <ValidationTextNumber
                      v-model="props.row.unit_price"
                      item-name="unit_price"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly || props.row.delete || !props.row.product_code ? '' : '1,000,000'"
                      :disabled="isReadOnly || props.row.delete || !props.row.product_code"
                      :newLine="false"
                      @blur="inputUnitPrice"
                    />
                  </template>
                </el-table-column>
                <!-- 個数 -->
                <el-table-column
                  :width="145"
                  :label="labels.quantity_ryaku === undefined ? '' : labels.quantity_ryaku"
                  align="center"
                >
                  <template slot-scope="props">
                    <div class="error-message-wrapper" v-show="props.row.error_quantity && !props.row.quantity">
                      <small class="text-danger">
                        {{ props.row.error_quantity }}
                      </small>
                    </div>
                    <ValidationTextNumber
                      v-model="props.row.quantity"
                      item-name="quantity"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly || props.row.delete || !props.row.product_code ? '' : '1,000'"
                      :disabled="isReadOnly || props.row.delete || !props.row.product_code"
                      :newLine="false"
                      @blur="inputQuantity"
                    />
                  </template>
                </el-table-column>
                <!-- 税種別 -->
                <el-table-column
                  :width="175"
                  :label="labels.tax_type_ryaku === undefined ? '' : labels.tax_type_ryaku"
                  align="center"
                >
                  <template slot-scope="props">
                    <div class="error-message-wrapper" v-show="props.row.error_tax_type && !props.row.tax_type">
                      <small class="text-danger">
                        {{ props.row.error_tax_type }}
                      </small>
                    </div>
                    <ValidationSelectSimple
                      v-model="props.row.tax_type"
                      item-name="tax_type"
                      :placeholder="$t('label.select_description')"
                      classes="length0"
                      :options="comboData.tax_type_list"
                      :disabled="isReadOnly || props.row.delete || !props.row.product_code"
                      @change="taxTypeChanged"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <ValidationTextArea
              v-model="detail.remark"
              item-name="remark"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.remark"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :rows="10"
            />
            <!-- 帳票 -->
            <ValidationSelectSimple
              v-model="detail.report_code"
              item-name="report_code"
              :placeholder="$t('label.select_description')"
              classes="length10"
              rules="required"
              :item-label="labels.report_code_ryaku"
              :options="comboData.report_code_list"
              :disabled="isReadOnly"
              @change="reportCodeChanged"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import QuotationDetailMixin from '@/components/Dashboard/Views/ProjectManagement/QuotationDetailMixin'

  export default {
    name: 'QuotationDetail',
    mixins: [QuotationDetailMixin]
  }
</script>
<style scoped>
</style>
