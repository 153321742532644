<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="detail" :draggable="true" :clickToClose="false" height="auto" :width="1500" :pivotX="0.7">
        <div class="modal-body">
          <!-- 警告一覧 -->
          <ScoreDetail :target-date="targetDate" :category="selectedCategory" @close="hide"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <button type="button" class="btn btn-primary btn-sm btn-default" @click="companyClicked" v-if="companyName">
        {{`${companyName}${$t('button.move_to')}`}}
      </button>
      <h4>{{rankingName}}</h4>
      <div style="margin: 30px 0 30px 0; font-size: 14pt;">
        <span style="margin-right: 10px; padding: 5px 10px; border: 1px solid #333; font-weight: bold;">{{!labels.total_ranking ? '' : labels.total_ranking.replace('ランキング', '')}}</span>{{totalRank}}位（{{companyCount}}社中）
        <span style="margin: 0 10px; padding: 5px 10px; border: 1px solid #333; font-weight: bold;">{{!labels.regional_ranking ? '' : labels.regional_ranking.replace('ランキング', '')}}</span>全国{{totalRank}}位&nbsp;／&nbsp;{{prefectureName + regionalRank}}位
        <span style="margin: 0 10px; padding: 5px 10px; border: 1px solid #333; font-weight: bold;">{{!labels.scale_ranking ? '' : labels.scale_ranking.replace('ランキング', '')}}</span>{{scaleName + scaleRank}}位
        <span style="margin: 0 10px; padding: 5px 10px; border: 1px solid #333; font-weight: bold;">{{!labels.industry_ranking ? '' : labels.industry_ranking.replace('ランキング', '')}}</span>{{industryName + industryRank}}位
      </div>
      <ValidationRadio v-model="years" @change="changeYear" />
      <ValidationRadio v-model="chartTypes" />
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <div class="card" style="height: 450px;">
            <div class="card-header">
              {{ selectedMonth }}
            </div>
            <div class="card-content">
              <PieChart :chartdata="pieChartdata1" :options="{legend: {display: false}}" :width="340" :height="395" v-show="chartTypes.value===1" />
              <PieChart :chartdata="pieChartdata2" :options="{legend: {display: false}}" :width="340" :height="395" v-show="chartTypes.value!==1" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card" style="height: 450px;">
            <div class="card-content">
              <BarChart :chartdata="chartdata" :options="options1" :width="600" :height="395" v-show="chartTypes.value===1" />
              <BarChart :chartdata="chartdata2" :options="options2" :width="600" :height="395" v-show="chartTypes.value!==1" />
            </div>
          </div>
          <div class="card" style="height: 230px;">
            <div class="card-content">
              <LineChart :chartdata="lineChartdata1" :options="lineOptions1" :width="600" :height="200" v-show="chartTypes.value===1" />
              <LineChart :chartdata="lineChartdata2" :options="lineOptions2" :width="600" :height="200" v-show="chartTypes.value!==1" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card" style="height: 330px;">
            <div class="card-content">
              <div class="row">
                <div class="col-md-6">
                  <div style="text-align: center; font-size: 14pt; font-weight: bold;">{{!labels.legal_compliance_status_score ? '' : labels.legal_compliance_status_score.replace(labels.score, '')}}</div>
                  <div class="gauge-container" style="width: 50%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value1"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle1"
                      :max-score="maxValue1"
                    />
                  </div>
                  <div class="gauge-container" style="width: 25%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value5"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle1"
                      :max-score="maxValue5"
                    />
                    業界別
                  </div>
                </div>
                <div class="col-md-6">
                  <div style="text-align: center; font-size: 14pt; font-weight: bold;">{{!labels.working_environment_score ? '' : labels.working_environment_score.replace(labels.score, '')}}</div>
                  <div class="gauge-container" style="width: 50%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value2"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle2"
                      :max-score="maxValue2"
                    />
                  </div>
                  <div class="gauge-container" style="width: 25%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value6"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle2"
                      :max-score="maxValue6"
                    />
                    業界別
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: 30px;"></div>
                <div class="col-md-6">
                  <div style="text-align: center; font-size: 14pt; font-weight: bold;">{{!labels.wage_status_score ? '' : labels.wage_status_score.replace(labels.score, '')}}</div>
                  <div class="gauge-container" style="width: 50%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value3"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle3"
                      :max-score="maxValue3"
                    />
                  </div>
                  <div class="gauge-container" style="width: 25%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value7"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle3"
                      :max-score="maxValue7"
                    />
                    業界別
                  </div>
                </div>
                <div class="col-md-6">
                  <div style="text-align: center; font-size: 14pt; font-weight: bold;">{{!labels.company_stability_score ? '' : labels.company_stability_score.replace(labels.score, '')}}</div>
                  <div class="gauge-container" style="width: 50%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value4"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle4"
                      :max-score="maxValue4"
                    />
                  </div>
                  <div class="gauge-container" style="width: 25%;">
                    <Gauge
                      :min="min"
                      :max="max"
                      v-model="value8"
                      :threshold="threshold"
                      :decimals="decimals"
                      color="arc below-needle4"
                      :max-score="maxValue8"
                    />
                    業界別
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card" style="height: 350px;">
            <div class="card-content">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="score">

                <!-- 評価項目 -->
                <el-table-column min-width="140" label="評価項目">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <span style="font-size: 12pt;" v-text="props.row.name" />
                  </template>
                </el-table-column>

                <!-- リンク -->
                <el-table-column min-width="60" label="">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="detailClicked(props.row)">{{$t('button.detail')}}</button>
                  </template>
                </el-table-column>

                <!-- スコア -->
                <el-table-column min-width="100" :label="'[全業種]'">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      <span v-text="props.row.score === 0 ? '-' : props.row.score" />
                    </div>
                  </template>
                </el-table-column>

                <!-- 業界別スコア -->
                <el-table-column min-width="100" :label="'[業界別]'">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      <span v-text="props.row.industry_score === 0 ? '-' : props.row.industry_score" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <ValidationRadio v-model="rankingTypes" @change="changeCategory" />
        </div>
        <div class="col-md-12">
          <div class="card" style="height: 330px;">
            <div class="card-content">
              <LineChart :chartdata="lineChartdata" :options="lineOptions" :width="1150" :height="300" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import BarChart from '@/components/UIComponents/Inputs/BarChart'
  import LineChart from '@/components/UIComponents/Inputs/LineChart'
  import PieChart from '@/components/UIComponents/Inputs/PieChart'
  import Gauge from '@/components/UIComponents/Inputs/Gauge'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ScoreDetail from './ScoreDetail'

  export default {
    mixins: [mixin],
    components: {
      BarChart,
      LineChart,
      PieChart,
      Gauge,
      ValidationRadio,
      ScoreDetail
    },
    computed: {
      companyName () {
        const child = this.$store.state.loginUser.sidebarLinks
          .flatMap((sidebarLink) => sidebarLink.children)
          .find((child) => child.code === '03-001')
        return child ? child.name : ''
      },
      rankingName () {
        for (let menu of this.$store.state.loginUser.sidebarLinks) {
          for (let child of menu.children) {
            if (child.code === '09-017') {
              return child.name
            }
          }
        }
        return ''
      }
    },
    data () {
      return {
        totalRank: '',
        companyCount: '',
        regionalRank: '',
        scaleRank: '',
        industryRank: '',
        prefectureName: '',
        scaleName: '',
        industryName: '',
        labels: {},
        comboData: {},
        years: {},
        chartTypes: {},
        rankingTypes: {},
        adviceList: [],
        chartdataList: [],
        workingEnvironmentBackgroundColor: 'rgba(216, 17, 89, 1.0)',
        wageStatusBackgroundColor: 'rgba(255, 188, 66, 1.0)',
        companyStabilityBackgroundColor: 'rgba(143, 45, 86, 1.0)',
        legalComplianceStatusBackgroundColor: 'rgba(33, 131, 128, 1.0)',
        chartdata: {
          labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
          datasets: [
            {
              label: '',
              backgroundColor: 'rgba(33, 131, 128, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(216, 17, 89, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(255, 188, 66, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(143, 45, 86, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        chartdata2: {
          labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
          datasets: [
            {
              label: '',
              backgroundColor: 'rgba(33, 131, 128, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(216, 17, 89, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(255, 188, 66, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: '',
              backgroundColor: 'rgba(143, 45, 86, 1.0)',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        lineChartdataList: [],
        lineChartdata: {
          labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
          datasets: [
            {
              label: '順位',
              data: [],
              tension: 0,
              fill: false,
              borderColor: '#fe4880',
              borderWidth: 4
            }
          ]
        },
        lineChartdata1: {
          labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
          datasets: [
            {
              label: 'スコア',
              data: [],
              tension: 0,
              fill: false,
              borderColor: '#fe4880',
              borderWidth: 4
            }
          ]
        },
        lineChartdata2: {
          labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
          datasets: [
            {
              label: 'スコア',
              data: [],
              tension: 0,
              fill: false,
              borderColor: '#fe4880',
              borderWidth: 4
            }
          ]
        },
        pieChartdata1: {
          labels: [ '', '', '', '' ],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: ['#218380', '#D81159', '#FFBC42', '#8F2D56']
            }
          ]
        },
        pieChartdata2: {
          labels: [ '', '', '', '' ],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: ['#218380', '#D81159', '#FFBC42', '#8F2D56']
            }
          ]
        },
        options1: {
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              // ticks: {
              //   min: 0,
              //   max: 100
              // },
              stacked: true
            }]
          },
          onClick: this.onclick
        },
        options2: {
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              // ticks: {
              //   min: 0,
              //   max: 100
              // },
              stacked: true
            }]
          },
          onClick: this.onclick
        },
        lineOptions: {
          responsive: false,
          legend: {
            display: false
          },
          tooltips: {
            intersect: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                fontSize: 14
              }
            }],
            yAxes: [{
              ticks: {
                reverse: true,
                min: 1,
                stepSize: 1
              }
            }]
          }
        },
        lineOptions1: {
          responsive: false,
          legend: {
            display: false
          },
          tooltips: {
            intersect: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                fontSize: 14
              }
            }],
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        },
        lineOptions2: {
          responsive: false,
          legend: {
            display: false
          },
          tooltips: {
            intersect: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                fontSize: 14
              }
            }],
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        },
        score: [],
        min: 0,
        max: 100,
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        value5: 0,
        value6: 0,
        value7: 0,
        value8: 0,
        maxValue1: 0,
        maxValue2: 0,
        maxValue3: 0,
        maxValue4: 0,
        maxValue5: 0,
        maxValue6: 0,
        maxValue7: 0,
        maxValue8: 0,
        tweenedValue: 0,
        threshold: 100,
        decimals: 0,
        targetDate: '',
        selectedCategory: 'A',
        selectedMonth: '1月',
        apiArea: {}
      }
    },
    methods: {
      companyClicked () {
        this.$router.push({name: 'companyView'})
      },
      onclick (point, event) {
        for (var index = 0; index < this.chartdata.labels.length; index++) {
          if (this.chartdata.labels[index] === event[0]._model.label) {
            this.createRankingData(this.apiArea.score_data[index])
            this.createGaugeChartData(this.apiArea.score_data[index], this.apiArea.score_setting_data)
            this.createPieChartData(this.apiArea.score_data[index], this.apiArea.score_setting_data)
            this.selectedMonth = this.chartdata.labels[index]
            this.targetDate = this.apiArea.score_data[index].target_date
            break
          }
        }
      },
      detailClicked (row) {
        this.selectedCategory = row.category
        this.$modal.show('detail')
      },
      hide () {
        this.$modal.hide('detail')
      },
      changeYear () {
        this.init(this.years.value)
      },
      changeCategory () {
        const index = parseInt(this.rankingTypes.value, 10) - 1
        this.lineChartdata = this.lineChartdataList[index]
      },
      createBarChartData (scoreData, scoreSettingData) {
        const chartData = {}
        chartData.labels = this.chartdata.labels
        chartData.datasets = []
        const chartData2 = {}
        chartData2.labels = this.chartdata.labels
        chartData2.datasets = []
        const categoryList = []
        for (const category of this.comboData.category_list) {
          if (category.value === 'D') {
            categoryList.push(category)
          }
        }
        for (const category of this.comboData.category_list) {
          if (category.value === 'A') {
            categoryList.push(category)
          }
        }
        for (const category of this.comboData.category_list) {
          if (category.value === 'B') {
            categoryList.push(category)
          }
        }
        for (const category of this.comboData.category_list) {
          if (category.value === 'C') {
            categoryList.push(category)
          }
        }
        for (const category of categoryList) {
          const dataset = {}
          dataset.label = category.label
          if (category.value === 'A') {
            dataset.backgroundColor = this.workingEnvironmentBackgroundColor
            dataset.label = this.labels.working_environment_score.replace(this.labels.score, '')
          } else if (category.value === 'B') {
            dataset.backgroundColor = this.wageStatusBackgroundColor
            dataset.label = this.labels.wage_status_score.replace(this.labels.score, '')
          } else if (category.value === 'C') {
            dataset.backgroundColor = this.companyStabilityBackgroundColor
            dataset.label = this.labels.company_stability_score.replace(this.labels.score, '')
          } else if (category.value === 'D') {
            dataset.backgroundColor = this.legalComplianceStatusBackgroundColor
            dataset.label = this.labels.legal_compliance_status_score.replace(this.labels.score, '')
          }
          const dataset2 = {...dataset}
          dataset.data = []
          dataset2.data = []
          for (let i = 1; i <= 12; i++) {
            let value = 0
            let value2 = 0
            for (const score of scoreData) {
              const yearMonth = this.years.value + ('0' + i).slice(-2)
              if (score.target_date === yearMonth) {
                if (category.value === 'A') {
                  value = score.working_environment_score
                  value2 = score.industry_working_environment_score
                } else if (category.value === 'B') {
                  value = score.wage_status_score
                  value2 = score.industry_wage_status_score
                } else if (category.value === 'C') {
                  value = score.company_stability_score
                  value2 = score.industry_company_stability_score
                } else if (category.value === 'D') {
                  value = score.legal_compliance_status_score
                  value2 = score.industry_legal_compliance_status_score
                }
                // value = value * 100 / scoreSettingData.total_score_max
                // value2 = value2 * 100 / scoreSettingData.total_score_max
                break
              }
            }
            dataset.data.push(value)
            dataset2.data.push(value2)
          }
          chartData.datasets.push(dataset)
          chartData2.datasets.push(dataset2)
        }
        this.options1.scales.yAxes[0].ticks = {min: 0, max: scoreSettingData.legal_compliance_status_score_max + scoreSettingData.working_environment_score_max + scoreSettingData.wage_status_score_max + scoreSettingData.company_stability_score_max}
        this.options2.scales.yAxes[0].ticks = {min: 0, max: scoreSettingData.industry_legal_compliance_status_score_max + scoreSettingData.industry_working_environment_score_max + scoreSettingData.industry_wage_status_score_max + scoreSettingData.industry_company_stability_score_max}
        this.chartdata = chartData
        this.chartdata2 = chartData2
        this.createLineChartdata1(scoreData, scoreSettingData)
      },
      createLineChartData (scoreData) {
        this.lineChartdataList = []
        for (const rankingType of this.rankingTypes.items) {
          const lineChartData = {}
          lineChartData.labels = this.lineChartdata.labels
          lineChartData.datasets = []
          const dataset = {}
          dataset.label = this.lineChartdata.datasets[0].label
          dataset.tension = this.lineChartdata.datasets[0].tension
          dataset.fill = this.lineChartdata.datasets[0].fill
          dataset.borderColor = this.lineChartdata.datasets[0].borderColor
          dataset.borderWidth = this.lineChartdata.datasets[0].borderWidth
          dataset.data = []
          for (let i = 1; i <= 12; i++) {
            let value = null
            for (const score of scoreData) {
              const yearMonth = this.years.value + ('0' + i).slice(-2)
              if (score.target_date === yearMonth) {
                if (rankingType.value === '1') {
                  value = score.total_ranking
                } else if (rankingType.value === '2') {
                  value = score.regional_ranking
                } else if (rankingType.value === '3') {
                  value = score.scale_ranking
                } else if (rankingType.value === '4') {
                  value = score.industry_ranking
                }
                break
              }
            }
            dataset.data.push(value)
          }
          lineChartData.datasets.push(dataset)
          this.lineChartdataList.push(lineChartData)
        }
        this.lineChartdata = this.lineChartdataList[0]
      },
      createLineChartdata1 (scoreData, scoreSettingData) {
        const lineChartdata1 = {}
        lineChartdata1.labels = this.lineChartdata1.labels
        lineChartdata1.datasets = []
        const dataset1 = {}
        dataset1.label = this.lineChartdata1.datasets[0].label
        dataset1.tension = this.lineChartdata1.datasets[0].tension
        dataset1.fill = this.lineChartdata1.datasets[0].fill
        dataset1.borderColor = this.lineChartdata1.datasets[0].borderColor
        dataset1.borderWidth = this.lineChartdata1.datasets[0].borderWidth
        dataset1.data = []
        const lineChartdata2 = {}
        lineChartdata2.labels = this.lineChartdata1.labels
        lineChartdata2.datasets = []
        const dataset2 = {}
        dataset2.label = this.lineChartdata1.datasets[0].label
        dataset2.tension = this.lineChartdata1.datasets[0].tension
        dataset2.fill = this.lineChartdata1.datasets[0].fill
        dataset2.borderColor = this.lineChartdata1.datasets[0].borderColor
        dataset2.borderWidth = this.lineChartdata1.datasets[0].borderWidth
        dataset2.data = []
        for (let i = 1; i <= 12; i++) {
          let value1 = 0
          let value2 = 0
          for (const score of scoreData) {
            const yearMonth = this.years.value + ('0' + i).slice(-2)
            if (score.target_date === yearMonth) {
              value1 += score.legal_compliance_status_score
              value1 += score.working_environment_score
              value1 += score.wage_status_score
              value1 += score.company_stability_score
              value2 += score.industry_legal_compliance_status_score
              value2 += score.industry_working_environment_score
              value2 += score.industry_wage_status_score
              value2 += score.industry_company_stability_score
              break
            }
          }
          if (value1 === 0) value1 = null
          dataset1.data.push(value1)
          if (value2 === 0) value2 = null
          dataset2.data.push(value2)
        }
        this.lineOptions1.scales.yAxes[0].ticks = {min: 0, max: scoreSettingData.legal_compliance_status_score_max + scoreSettingData.working_environment_score_max + scoreSettingData.wage_status_score_max + scoreSettingData.company_stability_score_max}
        this.lineOptions2.scales.yAxes[0].ticks = {min: 0, max: scoreSettingData.industry_legal_compliance_status_score_max + scoreSettingData.industry_working_environment_score_max + scoreSettingData.industry_wage_status_score_max + scoreSettingData.industry_company_stability_score_max}
        lineChartdata1.datasets.push(dataset1)
        this.lineChartdata1 = lineChartdata1
        lineChartdata2.datasets.push(dataset2)
        this.lineChartdata2 = lineChartdata2
      },
      createRankingData (scoreData) {
        this.totalRank = !scoreData.total_ranking ? '-' : scoreData.total_ranking
        this.regionalRank = !scoreData.regional_ranking ? '-' : scoreData.regional_ranking
        this.scaleRank = !scoreData.scale_ranking ? '-' : scoreData.scale_ranking
        this.industryRank = !scoreData.industry_ranking ? '-' : scoreData.industry_ranking
        for (const row of this.comboData.state_list) {
          if (row.value === scoreData.state_code) {
            this.prefectureName = row.label
            break
          }
        }
        this.companyCount = !scoreData.company_count ? '-' : scoreData.company_count
        let scaleName = this.labels.number_of_employees
        if (scoreData.number_of_employees_from > 0) {
          scaleName += this.formatNumber(scoreData.number_of_employees_from) + this.$t('label.people') + this.$t('label.more_than_or_equal_to')
        }
        if (scoreData.number_of_employees_to > 0) {
          scaleName += this.formatNumber(scoreData.number_of_employees_to) + this.$t('label.people') + this.$t('label.less_than_or_equal_to')
        }
        this.scaleName = scaleName
        for (const row of this.comboData.industry_code_big_list) {
          if (row.value === scoreData.industry_code_big) {
            this.industryName = row.label
            break
          }
        }
      },
      createGaugeChartData (scoreData, scoreSettingData) {
        const score = []
        score.push({
          name: this.labels.legal_compliance_status_score.replace(this.labels.score, ''),
          score: scoreData.legal_compliance_status_score,
          industry_score: scoreData.industry_legal_compliance_status_score,
          category: 'D'
        })
        score.push({
          name: this.labels.working_environment_score.replace(this.labels.score, ''),
          score: scoreData.working_environment_score,
          industry_score: scoreData.industry_working_environment_score,
          category: 'A'
        })
        score.push({
          name: this.labels.wage_status_score.replace(this.labels.score, ''),
          score: scoreData.wage_status_score,
          industry_score: scoreData.industry_wage_status_score,
          category: 'B'
        })
        score.push({
          name: this.labels.company_stability_score.replace(this.labels.score, ''),
          score: scoreData.company_stability_score,
          industry_score: scoreData.industry_company_stability_score,
          category: 'C'
        })
        this.score = score
        this.value1 = parseInt(scoreData.legal_compliance_status_score * 100 / scoreSettingData.legal_compliance_status_score_max, 10)
        this.value2 = parseInt(scoreData.working_environment_score * 100 / scoreSettingData.working_environment_score_max, 10)
        this.value3 = parseInt(scoreData.wage_status_score * 100 / scoreSettingData.wage_status_score_max, 10)
        this.value4 = parseInt(scoreData.company_stability_score * 100 / scoreSettingData.company_stability_score_max, 10)
        this.value5 = parseInt(scoreData.industry_legal_compliance_status_score * 100 / scoreSettingData.industry_legal_compliance_status_score_max, 10)
        this.value6 = parseInt(scoreData.industry_working_environment_score * 100 / scoreSettingData.industry_working_environment_score_max, 10)
        this.value7 = parseInt(scoreData.industry_wage_status_score * 100 / scoreSettingData.industry_wage_status_score_max, 10)
        this.value8 = parseInt(scoreData.industry_company_stability_score * 100 / scoreSettingData.industry_company_stability_score_max, 10)
        this.maxValue1 = scoreSettingData.legal_compliance_status_score_max
        this.maxValue2 = scoreSettingData.working_environment_score_max
        this.maxValue3 = scoreSettingData.wage_status_score_max
        this.maxValue4 = scoreSettingData.company_stability_score_max
        this.maxValue5 = scoreSettingData.industry_legal_compliance_status_score_max
        this.maxValue6 = scoreSettingData.industry_working_environment_score_max
        this.maxValue7 = scoreSettingData.industry_wage_status_score_max
        this.maxValue8 = scoreSettingData.industry_company_stability_score_max
      },
      createPieChartData (scoreData, scoreSettingData) {
        const pieChartdata1 = {
          labels: ['', '', '', ''],
          datasets: [{
            backgroundColor: this.pieChartdata1.datasets[0].backgroundColor,
            data: [0, 0, 0, 0]
          }]
        }
        const pieChartdata2 = {
          labels: ['', '', '', ''],
          datasets: [{
            backgroundColor: this.pieChartdata1.datasets[0].backgroundColor,
            data: [0, 0, 0, 0]
          }]
        }
        pieChartdata1.labels[0] = pieChartdata2.labels[0] = this.labels.legal_compliance_status_score.replace(this.labels.score, '')
        pieChartdata1.labels[1] = pieChartdata2.labels[1] = this.labels.working_environment_score.replace(this.labels.score, '')
        pieChartdata1.labels[2] = pieChartdata2.labels[2] = this.labels.wage_status_score.replace(this.labels.score, '')
        pieChartdata1.labels[3] = pieChartdata2.labels[3] = this.labels.company_stability_score.replace(this.labels.score, '')
        pieChartdata1.datasets[0].datalabels = {
          formatter: (value, context) => {
            if (value === 0) return ''
            const label = context.chart.data.labels[context.dataIndex]
            return `${label} (${value}%)`
          },
          align: 'end',
          color: 'white'
        }
        pieChartdata2.datasets[0].datalabels = {
          formatter: (value, context) => {
            if (value === 0) return ''
            const label = context.chart.data.labels[context.dataIndex]
            return `${label} (${value}%)`
          },
          color: 'white'
        }
        pieChartdata1.datasets[0].data[0] = parseInt(scoreData.legal_compliance_status_score * 100 / scoreData.total_score, 10)
        pieChartdata1.datasets[0].data[1] = parseInt(scoreData.working_environment_score * 100 / scoreData.total_score, 10)
        pieChartdata1.datasets[0].data[2] = parseInt(scoreData.wage_status_score * 100 / scoreData.total_score, 10)
        pieChartdata1.datasets[0].data[3] = parseInt(scoreData.company_stability_score * 100 / scoreData.total_score, 10)
        pieChartdata2.datasets[0].data[0] = parseInt(scoreData.industry_legal_compliance_status_score * 100 / scoreData.industry_total_score, 10)
        pieChartdata2.datasets[0].data[1] = parseInt(scoreData.industry_working_environment_score * 100 / scoreData.industry_total_score, 10)
        pieChartdata2.datasets[0].data[2] = parseInt(scoreData.industry_wage_status_score * 100 / scoreData.industry_total_score, 10)
        pieChartdata2.datasets[0].data[3] = parseInt(scoreData.industry_company_stability_score * 100 / scoreData.industry_total_score, 10)
        this.pieChartdata1 = pieChartdata1
        this.pieChartdata2 = pieChartdata2
      },
      init (targetDate = null) {
        let params = {
          screen_code: '09-017',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            target_date: targetDate
          }
        }
        this.send('/init/white_ranking', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          if (res.common_area.combo_data.get_ranking_target_year_list.length > 0) {
            const years = {
              value: targetDate !== null ? targetDate : res.common_area.combo_data.get_ranking_target_year_list[res.common_area.combo_data.get_ranking_target_year_list.length - 1],
              disabled: false,
              items: []
            }
            for (const row of res.common_area.combo_data.get_ranking_target_year_list) {
              years.items.push({
                label: row + this.$t('label.year'),
                value: row
              })
            }
            this.years = years
          }
          this.chartTypes = {
            value: 1,
            disabled: false,
            items: [{label: '総合', value: 1}, {label: '業界別', value: 2}]
          }
          const rankingTypes = {
            value: '1',
            disabled: false,
            items: []
          }
          rankingTypes.items.push({
            label: this.labels.total_ranking.replace('ランキング', ''),
            value: '1'
          })
          rankingTypes.items.push({
            label: this.labels.regional_ranking.replace('ランキング', ''),
            value: '2'
          })
          rankingTypes.items.push({
            label: this.labels.scale_ranking.replace('ランキング', ''),
            value: '3'
          })
          rankingTypes.items.push({
            label: this.labels.industry_ranking.replace('ランキング', ''),
            value: '4'
          })
          this.rankingTypes = rankingTypes
          if (targetDate === null) {
            this.targetDate = res.api_area.score_data[res.api_area.score_data.length - 1].target_date
            const adviceList = []
            for (const code of res.api_area.points_to_be_pointed_out_list) {
              for (const row of this.comboData.get_points_to_be_pointed_out_list) {
                if (code === parseInt(row.value, 10)) {
                  adviceList.push(row.label)
                  break
                }
              }
            }
            this.adviceList = adviceList
          }
          this.createRankingData(res.api_area.score_data[res.api_area.score_data.length - 1])
          this.createGaugeChartData(res.api_area.score_data[res.api_area.score_data.length - 1], res.api_area.score_setting_data)
          this.createPieChartData(res.api_area.score_data[res.api_area.score_data.length - 1], res.api_area.score_setting_data)
          this.createBarChartData(res.api_area.score_data, res.api_area.score_setting_data)
          this.createLineChartData(res.api_area.score_data)
          this.selectedMonth = this.chartdata.labels[res.api_area.score_data.length - 1]
          this.apiArea = res.api_area
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style scoped>
.gauge-container {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
}
</style>