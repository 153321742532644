<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <!-- 品名 -->
            <ValidationText
              v-model="detail.product_name"
              item-name="product_name"
              max-length="50"
              classes="form-control length50"
              rules="required"
              :item-label="labels.product_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.product_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 品名(カナ) -->
            <ValidationText
              v-model="detail.product_japanese_name"
              item-name="product_japanese_name"
              max-length="50"
              classes="form-control length50"
              rules="isKatakana"
              :item-label="labels.product_japanese_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.product_japanese_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 品名(英字) -->
            <ValidationText
              v-model="detail.product_english_name"
              item-name="product_english_name"
              max-length="50"
              classes="form-control length50"
              rules="isHalfWidthAlphanumeric"
              :item-label="labels.product_english_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.product_english_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 取扱開始日 -->
            <ValidationDate
              v-model="detail.start_of_handling_date"
              item-name="start_of_handling_date"
              rules="required"
              :item-label="labels.start_of_handling_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.start_date')"
              :disabled="isReadOnly"
            />
            <!-- 取扱終了日 -->
            <ValidationDate
              v-model="detail.end_of_handling_date"
              item-name="end_of_handling_date"
              :item-label="labels.end_of_handling_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.end_date')"
              :disabled="isReadOnly"
              :picker-options="futureday"
            />
            <!-- 単価 -->
            <ValidationTextNumber
              v-model="detail.unit_price"
              item-name="unit_price"
              integral="10"
              classes="form-control numLength10"
              :item-label="labels.unit_price"
              :placeholder="isReadOnly ? '' : '1,000,000'"
              :disabled="isReadOnly"
              :newLine="true"
              :help-text="$t('label.max_length_integral')"
            />
            <!-- 個数 -->
            <ValidationTextNumber
              v-model="detail.quantity"
              item-name="quantity"
              integral="10"
              classes="form-control numLength10"
              :item-label="labels.quantity"
              :placeholder="isReadOnly ? '' : '1,000'"
              :disabled="isReadOnly"
              :newLine="true"
              :help-text="$t('label.max_length_integral')"
            />
            <!-- 品名単位 -->
            <ValidationSelectSimple
              v-model="detail.product_item_unit"
              item-name="product_item_unit"
              :placeholder="$t('label.select_description')"
              classes="length0"
              :item-label="labels.product_item_unit"
              :options="comboData.product_item_unit_list"
              :disabled="isReadOnly"
              @change="productItemUnitChanged"
            />
            <!-- 税種別 -->
            <ValidationSelectSimple
              v-model="detail.tax_type"
              item-name="tax_type"
              :placeholder="$t('label.select_description')"
              classes="length0"
              :item-label="labels.tax_type"
              :options="comboData.tax_type_list"
              :disabled="isReadOnly"
              @change="taxTypeChanged"
            />
            <!-- 備考 -->
            <ValidationTextArea
              v-model="detail.remark"
              item-name="remark"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.remark"
              :placeholder="isReadOnly ? '' : ''"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :rows="10"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ProductDetailMixin from '@/components/Dashboard/Views/MasterProject/ProductDetailMixin'

  export default {
    name: 'ProductDetail',
    mixins: [ProductDetailMixin]
  }
</script>
<style scoped>
</style>
