<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal
      name="detail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidth"
      :pivotX="0.9"
    >
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" />
      </div>
    </modal>
    <modal
      name="bank"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthBank"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Bank :mode="mode" :params="params" @close="hideBank" />
      </div>
    </modal>
    <modal
      name="cashTransfer"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthCashTransfer"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <CashTransfer :mode="mode" :params="params" @close="hideCashTransfer" />
      </div>
    </modal>
    <modal
      name="journal"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthJournal"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Journal :mode="mode" :params="params" @close="hideJournal" />
      </div>
    </modal>
    <modal
      name="sendMail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthSendMail"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <SendMail :mode="mode" :params="params" :retirement="searchCond.retirement" @close="hideSendMail" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <Help
          class="pull-right"
          :help="help"
          :placeholders="[pageTitle]"
          movie-key="salary_tran"
          div-style="padding-bottom: 5px;"
        />
      </div>
    </div>
    <div ref="frame">
      <form method="#" action="#">
        <div class="card">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">{{$t('label.search_condition')}}</h4>
            </div>
            <div
              style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;"
            >
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-show="showSearchCondition">
            <EmployeesSearch
              ref="employeesSearch"
              :orgSearchCond="searchCond"
              :labels="labels"
              :comboData="comboData"
              :disabled="isChanged"
              :from-year-month="fromYearMonth"
              :to-year-month="toYearMonth"
              :current-year-month="currentYearMonth"
              :payslipMode="true"
              :show-search-all="false"
              @search="searchClicked"
            />
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" />
      <template v-if="tableData && tableData.length > 0">
        <template v-if="tableData.length">
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <div style="margin-top: 10px;">
                <!-- 一括ダウンロードボタン -->
                <template v-if="roles.print === 1">
                  <button
                    type="button"
                    class="btn btn-sm btn-default btn-warning"
                    @click="doPrintAll()"
                    :style="executeOperation(roles.print === 1)"
                  >{{$t('button.batch_print')}}</button>
                  <hr />
                </template>
                <!-- 対象月別で繰り返し表示 -->
                <div
                  class="col-sm-12"
                  style="margin-bottom: 20px;"
                  v-for="salary_data in tableData"
                  v-bind:key="salary_data.target_date"
                >
                  <div style="text-align: left;">
                    <h5>
                      <strong>{{salary_data.target_date.substring(0, 4)}}{{$t('label.year')}}{{salary_data.target_date.substring(4)}}{{$t('label.monthly')}}</strong>
                    </h5>
                    <!-- 一括登録 -->
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-default"
                      @click="addAll(salary_data.target_date)"
                      v-show="roles.entry === 1"
                    >{{$t('button.add_all')}}</button>
                    <!-- 給与明細送信 -->
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-default"
                      @click="showSendMail(salary_data.target_date)"
                      v-show="canShow('/SS/menuSSScreen025')"
                      :disabled="canAllPrint(salary_data.salary_closing_data)"
                    >{{$t('button.send_payslip')}}</button>
                    <!-- 銀行振込一覧ボタン -->
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-default"
                      @click="showBank(salary_data.target_date)"
                      v-show="canShow('/SS/menuSSScreen023')"
                      :disabled="canAllPrint(salary_data.salary_closing_data)"
                    >{{$t('button.bank_transfer_list')}}</button>
                    <!-- 現金一覧表ボタン -->
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-default"
                      @click="showCashTransfer(salary_data.target_date)"
                      v-show="canShow('/SS/menuSSScreen026')"
                      :disabled="canAllPrint(salary_data.salary_closing_data)"
                    >{{$t('button.cash_transfer')}}</button>
                    <!-- 仕訳データ一覧ボタン -->
                    <button
                      type="button"
                      class="btn btn-primary btn-sm btn-default"
                      @click="showJournal(salary_data.target_date)"
                      v-show="canShow('/SS/menuSSScreen024')"
                      :disabled="canAllPrint(salary_data.salary_closing_data)"
                    >{{$t('button.journal_list')}}</button>
                  </div>
                  <br />
                  <!-- 締め日別で繰り返し表示 -->
                  <div
                    class="col-sm-12"
                    v-for="salary_closing_data  in salary_data.salary_closing_data"
                    v-bind:key="salary_closing_data.salary_closing_name"
                  >
                    <strong>{{salary_closing_data.salary_closing_name}}</strong>
                    <hr />
                    <!-- Table開始 -->
                    <div :style="tableWrapperStyle">
                      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                        class="table-width"
                        border
                        :data="salary_closing_data.detail_data"
                        :empty-text="$t('label.no_data')"
                        :style="tableStyle"
                        ref="table"
                        :row-class-name="tableRowClassName"
                      >
                        <!-- 一括登録用選択チェックボックス -->
                        <el-table-column :width="50" fixed="left">
                          <template slot="header"> 
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">
                                <input type="checkbox" v-model="salary_closing_data.selected" @change="selectAll(salary_data.target_date, salary_closing_data.salary_closing_name)" class="gray">
                              </div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="margin-top: 10px; margin-bottom: 12px; text-align: center;">
                              <input type="checkbox" v-model="props.row.selected" class="gray" v-show="props.row.t_employee_pay_slip_exist !== 1 && dispFlg && (props.row.approverl_flg === 3 || props.row.approverl_flg === 8)">
                            </div>
                          </template>
                        </el-table-column>
                        <!-- 操作 -->
                        <el-table-column
                          :width="roles.print === 1 ? 230 : 130"
                          v-if="showOperation"
                          fixed="left"
                          :key="componentKey"
                          :label="$t('label.operation')"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <!-- 印刷 -->
                            <template v-if="roles.print === 1">
                              <button
                                type="button"
                                class="btn btn-sm btn-default btn-warning"
                                :style="executeOperation(canPrint(props.row))"
                                @click="doPrint(props.$index, props.row)"
                              >{{$t('button.print')}}</button>
                            </template>
                            <!-- 表示 -->
                            <a
                              class="btn btn-simple btn-icon like"
                              @click="handleView(props.$index, props.row, salary_closing_data.detail_data)"
                              :style="executeOperation(canPreview(props.row))"
                            >
                              <i class="ti-file" :title="$t('button.view')"></i>
                            </a>
                            <!-- 更新 -->
                            <template v-if="props.row.t_employee_pay_slip_exist === 1">
                              <a
                                class="btn btn-simple btn-icon edit"
                                @click="handleEdit(props.$index, props.row, salary_closing_data.detail_data)"
                                :style="executeOperation(canUpdate(props.row))"
                                v-show="props.row.approverl_flg === 3 || props.row.approverl_flg === 8 || props.row.t_employee_pay_slip_exist === 1"
                              >
                                <i class="ti-pencil-alt" :title="$t('button.update')"></i>
                              </a>
                            </template>
                            <template v-else-if="dispFlg">
                              <a
                                class="btn btn-simple btn-icon edit"
                                @click="handleAdd(props.$index, props.row, salary_closing_data.detail_data)"
                                :style="executeOperation(canEntry(props.row))"
                                v-show="props.row.approverl_flg === 3 || props.row.approverl_flg === 8 || props.row.t_employee_pay_slip_exist === 1"
                              >
                                <i class="ti-pencil-alt" :title="$t('button.add')"></i>
                              </a>
                            </template>
                            <!-- 削除 -->
                            <a
                              class="btn btn-simple btn-icon btn-danger remove"
                              @click="handleDelete(props.$index, props.row, salary_closing_data.detail_data)"
                              :style="executeOperation(canDelete(props.row))"
                              v-show="props.row.approverl_flg === 3 || props.row.approverl_flg === 8 || props.row.t_employee_pay_slip_exist === 1"
                            >
                              <i class="ti-close" :title="$t('button.delete')"></i>
                            </a>
                            <!--ダウンロードボタン -->
                          </template>
                        </el-table-column>
                        <!-- 従業員 -->
                        <el-table-column
                          :width="200"
                          :label="labels.employee === undefined ? '' : labels.employee"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div class="strong-text">{{props.row.employee_name}}</div>
                            <div style="text-align: left;">
                              <small>{{props.row.employee_code}}</small>
                            </div>
                            <div style="text-align: left; line-height: 100%;" v-if="searchCond.search_type!='3'">
                              <small>{{props.row.employee_classification_name}}</small>
                            </div>
                            <div style="text-align: left;" v-if="searchCond.search_type=='0'">
                              <small>{{props.row.office_name}}</small>
                            </div>
                            <div style="text-align: left;" v-if="searchCond.search_type=='1'">
                              <small>{{props.row.group_name}}</small>
                            </div>
                            <div style="text-align: left;" v-if="searchCond.search_type=='2'">
                              <small>{{props.row.team_name}}</small>
                            </div>
                          </template>
                        </el-table-column>
                        <!-- 勤怠の締め状態 -->
                        <el-table-column
                          :width="170"
                          :label="$t('label.approval_attendance') === undefined ? '' : $t('label.approval_attendance')"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <template v-if="props.row.attendance_management !== 2">
                              <div
                                class="strong-text"
                              >{{getLabel(getSelectName(props.row.approverl_flg, comboData.approval_flg_list))}}</div>
                            </template>
                            <template v-else>
                              <div
                                class="strong-text"
                              >{{$t('label.not_managed')}}</div>
                            </template>
                          </template>
                        </el-table-column>
                        <!-- 給与の締め状態 -->
                        <el-table-column
                          :width="100"
                          :label="$t('label.approval_salary') === undefined ? '' : $t('label.approval_salary')"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div
                              class="strong-text"
                            >{{getLabel(getSelectName(props.row.salary_is_close, comboData.is_close_list))}}</div>
                          </template>
                        </el-table-column>
                        <!-- 給与明細 -->
                        <el-table-column
                          :width="135"
                          :label="labels.date_of_sending_pay_slip === undefined ? '' : labels.date_of_sending_pay_slip"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div class="strong-text">
                              {{props.row.date_of_sending_pay_slip.split(' ')[0]}}
                              <br>
                              {{props.row.date_of_sending_pay_slip.split(' ')[1]}}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column :width="150" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              {{props.row.update_date}}
                            </div>
                            <div style="text-align: left;">
                              <small>{{ props.row.update_employee_name }}</small>
                            </div>
                            <div style="text-align: left; line-height: 100%;">
                              <small>{{ props.row.update_employee_code }}</small>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <br />
                  </div>
                </div>
                <div class="row" ref="pager"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <div style="margin-top: 10px;">{{$t('label.no_data')}}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
  import CreatePaySlipSearchMixin from '@/components/Dashboard/Views/Salary/CreatePaySlipSearchMixin'

  export default {
    name: 'CreatePaySlipSearch',
    mixins: [CreatePaySlipSearchMixin]
  }
</script>
<style>
  .el-table .closed-row {
    background-color: #f0f8ff !important;
  }
</style>
