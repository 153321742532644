<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="noticeDetail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
        <div class="modal-body">
          <!-- お知らせモーダル パラメータ：従業員番号 -->
          <NoticeDetail :mode="mode" :params="params" @close="hide"/>
        </div>
      </modal>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <StartEndDate
            v-model="searchCond.notice_date"
            item-name="notice_date"
            max-length=""
            classes="form-control length"
            :item-label="$t('label.term')"
            :disabled="false"
            :placeholder1="$t('placeholder.start_date')"
            :placeholder2="$t('placeholder.end_date')"
            frame-class="search-condition-item"
          />
          <ValidationCheckboxes
            v-model="searchCond.notice_type"
            :item-label="labels.notice_type"
            :is-search-condition="true"
          />
          <!-- 既読も含む用のチェックボックス -->
          <div class="form-group" style="display: inline-block; vertical-align: top;">
            <div>
              <input type="checkbox" v-model="searchCond.retirement" class="gray"/>
              <label>{{$t('label.including_read')}}</label>
            </div>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <el-select
            class="select-success length2"
            v-model="pagination.perPage">
            <el-option
              class="select-success"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div style="margin-top: 10px;">
            <button type="button" class="btn btn-sm btn-default btn-info" @click="multiUpdate(false)">{{$t('button.unread')}}</button>
            <button type="button" class="btn btn-sm btn-default btn-info" @click="multiUpdate(true)">{{$t('button.read')}}</button>
          </div>
          <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      :data="tableData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      row-key="notice_number"
                      :expand-row-keys="expandRowKeys"
                      :key="componentKey"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="50">
                <template slot="header"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <input type="checkbox" id="allSelect" @click="selectAll" class="gray">
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-top: 10px; margin-bottom: 12px; text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray">
                  </div>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div style="padding: 5px 20px 20px 40px;">
                    <div style="padding-top: 10px;">
                    <div v-for="(content, index) in (props.row.contents.split('\n'))" :key="index" style="text-align: left; font-size: medium; white-space: normal;">
                      <span v-html="getContents(content)"></span>
                    </div>
                  </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="700" :label="labels.notification_ryaku === undefined ? '': labels.notification_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div v-for="notification in (props.row.notification.split('\n'))" :key="notification" style="text-align: left; font-size: medium; font-weight: bold; white-space: normal;">
                    {{notification}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="200" :label="labels.notification_transmission_date_ryaku === undefined ? '': labels.notification_transmission_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="getFontStyle(props.row.is_read)">
                    {{props.row.notification_display_date_from}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import NoticeSearchMixin from '@/components/Dashboard/Views/Setting/NoticeSearchMixin'

  export default {
    name: 'EmployeeLunchSearch',
    methods: {
      convertSearchCond (searchCond) {
        let type = []
        for (const item of searchCond.notice_type.items) {
          if (item.value) {
            type.push(item.id)
          }
        }
        searchCond = {
          notice_date_from: searchCond.notice_date[0] === null || searchCond.notice_date[0] === '' ? '2000/01/01' : this.formatDate(searchCond.notice_date[0]),
          notice_date_to: searchCond.notice_date[1] === null || searchCond.notice_date[1] === '' ? '2999/12/31' : this.formatDate(searchCond.notice_date[1]),
          notice_type: type,
          is_unread: !searchCond.retirement
        }
        return searchCond
      }
    },
    mixins: [NoticeSearchMixin]
  }
</script>
<style scoped>
</style>
