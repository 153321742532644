<template>
  <div>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form @submit.prevent="login" method="POST" action="#">
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <h3 class="card-title">管理者ログイン</h3>
                    </div>
                    <div class="card-content">
                      <div class="form-group">
                        <label>{{$t('label.mail_address')}}</label>
                        <input
                          type="text"
                          :placeholder="$t('placeholder.mail_address')"
                          class="form-control input-no-border"
                          v-model="email"
                        />
                      </div>
                      <div class="form-group">
                        <label>{{$t('label.password')}}</label>
                        <input
                          :type="passwordType"
                          :placeholder="$t('placeholder.password')"
                          class="form-control input-no-border"
                          v-model="password"
                        />
                      </div>
                      <div class="form-group">
                        <div class="pull-right">
                          <input 
                            type="checkbox" 
                            v-model="showPassword" 
                            class="gray"
                            id="showPassword"
                          >
                          <label for="showPassword" style="cursor: pointer;">
                            {{$t('label.show_password')}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <br>
                    <br>
                    <div class="card-footer text-center">
                      <button type="submit" class="btn btn-sm btn-default btn-wd btn-warning">{{$t('button.login')}}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="buttons" style="text-align: center;">
              <a class="button" href="/static/felica/Artemis Collier.zip">{{$t('label.felica_download')}}</a>
              <a class="button" href="/#/login">{{$t('label.artemis_login')}}</a>
              <a class="button" href="/#/qrcode/login">{{$t('label.qrcode_login')}}</a>
              &nbsp;
              <a href="https://apps.apple.com/app/id1625521109" target="_blank"><img class="button_appstore" src="/static/img/app_store.png" /></a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=jp.co.pocketsoft.flutter.artemis" target="_blank"><img class="button_appstore" src="/static/img/google_play.png" /></a>
            </div>
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <div class="copyright">
                  &copy; 2020-2024 PocketSoft Co., Ltd.
                </div>
              </div>
              <div style="display: inline-block;">
                <a style="color: #666" href="/#/notation-on-law">{{$t('label.notation_on_law')}}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader
    },
    data () {
      return {
        email: undefined,
        password: undefined,
        showPassword: false,
        options: new Map(),
        res: {}
      }
    },
    computed: {
      passwordType () {
        return this.showPassword ? 'text' : 'password'
      }
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login () {
        let self = this
        if (this.email && this.email.length > 0 && this.password && this.password.length > 0) {
          this.$firebase.auth().signInWithEmailAndPassword(process.env.VUE_APP_FIREBASE_USER, process.env.VUE_APP_FIREBASE_PASSWORD)
          .then(userInfo => {
            let data = {
              mail_address: this.email,
              password: this.password
            }
            self.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/login/auth`, data)
            .then(async (data) => {
              const userLoginInfo = {
                accessMailAddress: data.api_area.access_mail_address,
                companyList: data.api_area.company_list,
                corporate: data.api_area.corporate,
                customerId: data.api_area.customer_id,
                defaultCompanyCode: data.api_area.default_company_code,
                mailAddress: data.api_area.mail_address,
                userName: data.api_area.user_name,
                workScheduleList: data.api_area.work_schedule_list,
                sidebarLinks: await self.getUserSidebarLinks(data.api_area)
              }
              self.$store.commit('userSave', userLoginInfo)
              const corpusCompanyCode = this.$route.query.corpusCompanyCode ? this.$route.query.corpusCompanyCode : ''
              const corpusCompanyName = this.$route.query.corpusCompanyName ? this.$route.query.corpusCompanyName : ''
              sessionStorage.setItem('corpusCompanyCode', corpusCompanyCode)
              sessionStorage.setItem('corpusCompanyName', corpusCompanyName)
              sessionStorage.setItem('accessMailAddress', this.email)
              if (data.api_area.customer_id !== null) {
                localStorage.setItem('customerId', data.api_area.customer_id)
              } else {
                localStorage.setItem('customerId', '')
              }
              self.$router.push({
                name: 'adminMenu',
                params: {
                  email: data.api_area.mail_address,
                  password: this.password,
                  companyList: data.api_area.company_list,
                  corporate: data.api_area.corporate,
                  firstCompanyCreateDate: data.api_area.first_company_create_date
                }
              })
            })
            .catch(error => {
              console.error(error)
            })
          })
          .catch(err => {
            alert(err.message)
          })
        }
      },
      async getUserSidebarLinks (apiArea) {
        let hasCustomerId = false
        let isValidCustomerId = false
        let paymentPageUrl = ''
        if (apiArea.customer_id) {
          hasCustomerId = true
          const res = await this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeSubscriptions?customerId=' + apiArea.customer_id)
          if (res.data && res.data.isSubscription) {
            isValidCustomerId = true
          } else if (res.data && res.data.paymentPageUrl) {
            paymentPageUrl = res.data.paymentPageUrl
          }
        }
        const sidebarLinks = []
        if (!isValidCustomerId && !paymentPageUrl) {
          sidebarLinks.push({
            name: this.$t('button.go_payment_page'),
            icon: 'ti-package',
            path: '/user/payment',
            code: '00-000'
          })
        }
        sidebarLinks.push({
          name: this.$t('label.change_mail_address'),
          icon: 'ti-package',
          path: '/user/mail_address_change',
          code: '00-000'
        })
        sidebarLinks.push({
          name: this.$t('label.change_password'),
          icon: 'ti-package',
          path: '/user/password_change',
          code: '00-000'
        })
        sidebarLinks.push({
          name: this.$t('button.company_add'),
          icon: 'ti-package',
          path: `/user/company-regist?mail_address=${apiArea.access_mail_address}&customer_id=${apiArea.customer_id}`,
          code: '00-000'
        })
        if (hasCustomerId && (isValidCustomerId || (!isValidCustomerId && !!paymentPageUrl))) {
          sidebarLinks.push({
            name: this.$t('button.update_credit_card'),
            icon: 'ti-package',
            path: '/user/credit_card',
            code: '00-000'
          })
        }
        if (hasCustomerId && !isValidCustomerId && !!paymentPageUrl) {
          sidebarLinks.push({
            name: this.$t('button.update_invoice'),
            icon: 'ti-package',
            path: '/user/invoice',
            code: '00-000'
          })
        }
        sidebarLinks.push({
          name: this.$t('button.delete_company_cancellation'),
          icon: 'ti-package',
          path: '/user/delete_company',
          code: '00-000'
        })
        this.$sidebar.userSidebarLinks = sidebarLinks
        return sidebarLinks
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }

</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .card input {
    background: #eee !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
</style>
