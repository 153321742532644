<template>
  <div>
    <div class="card">
      <div class="card-header search-result-title">
        <h4 class="card-title">
          {{$t('label.search_result')}}
        </h4>
      </div>
      <div class="card-content">
        <!-- テーブル表示 -->
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                  border
                  :data="tableData">

          <!-- お気に入りチェックボックス -->
          <el-table-column
            v-if="false"
            width="100"
            fixed="left"
            :label="$t('label.favorite')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: center;">
                <input type="checkbox" :disabled="disabled" v-model="props.row.selected" class="gray" />
              </div>
            </template>
          </el-table-column>

          <!-- メニュー -->
          <el-table-column :width="getColumnWidth(tableData, labels.menu_ryaku, 'menu_code', 'menu_name')" :label="labels.menu_ryaku ? labels.menu_ryaku : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                <span :class="props.row.icon_name"></span>
                &nbsp;
                {{getLabel(props.row.menu_name)}}
              </div>
              <div style="text-align: left;">
                <small>{{props.row.menu_code}}</small>
              </div>
            </template>
          </el-table-column>

          <!-- 画面 -->
          <el-table-column :width="300" :label="labels.screen_ryaku ? labels.screen_ryaku : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; font-size: medium; font-weight: bold; white-space: normal;">
                <a href="#" @click.prevent="screenNameClicked(props.row.vue_path)" style="color: #317FB8;">{{getLabel(props.row.screen_name)}}</a>
              </div>
              <div style="text-align: left;">
                <small>{{props.row.screen_code}}</small>
              </div>
            </template>
          </el-table-column>

          <!-- 補足説明 -->
          <el-table-column :width="700" :label="labels.contents_ryaku ? labels.contents_ryaku : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; font-size: medium; white-space: normal;">
                {{props.row.contents}}
              </div>
            </template>
          </el-table-column>

          <!-- ヘルプ -->
          <el-table-column :width="300" :label="labels.help_ryaku ? labels.help_ryaku : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; white-space: normal;">
                <a :href="props.row.url" target="_blank" style="color: #317FB8;">
                  {{props.row.text === 'None' ? '' : props.row.text}}
                </a>
              </div>
            </template>
          </el-table-column>
          
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object
    },
    data () {
      return {
        tableData: []
      }
    },
    methods: {
      screenNameClicked (path) {
        let vuePath = '/' + path.substring(4, 6) + '/' + path.replace('Menu', 'menu').replace('.vue', '')
        if (vuePath.indexOf('/DB/') > -1) {
          vuePath = '/DB/menuDBScreen001'
        }
        this.$router.push({path: vuePath})
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>
