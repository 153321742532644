<template>
  <div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose(null)">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
      </div>
      <ValidationObserver ref="observer">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title" style="margin: 0;">
              {{$t('label.message_details')}}
            </h4>
            <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
            <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
          </div>
          <div class="card-content" ref="frame">
            <div :key="componentKey">
                <div>
                  <template v-for="table in messageList">
                    <div :class="{'line_right':loginUserName === table.sending_employee_name, 'line_left':loginUserName !== table.sending_employee_name}">
                      <div class="name">{{table.sending_employee_name}}</div>
                      <div class="message">{{table.message}}
                        <template v-if="table.job_start !== '' && table.job_end !== ''">
                          <br>
                          <span class="autoDate">{{formatDateWith(table.target_date,'M/D(ddd)')}}&nbsp;{{table.job_start}}&nbsp;-&nbsp;{{table.job_end}}</span>
                        </template>
                      </div>
                      <span class="date">{{formatDateWith(table.sending_date,'M/D(ddd) hh:mm')}}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
        </div>
        <div>
          <ValidationTextArea
            v-model="detail.message"
            item-name="message"
            :item-label="labels.message"
            max-length="255"
            rules="required"
            classes="form-control"
            :disabled="isReadOnly || isDisabled"
          />
        </div>
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-warning" :style="isProcessing ? 'pointer-events: none;' : ''" :disabled="isReadOnly || isDisabled" @click="doClose(detail)" :title="$t('message.sending_information')">
            <span class="btn-label"></span>
            {{$t('button.send_reservation')}}
          </button>
        </div>
      </ValidationObserver>
    </form>
  </div>
</template>
<script>
  import ShiftDecisionMessageViewMixin from '@/components/Dashboard/Views/Monthly/ShiftDecisionMessageViewMixin'

  export default {
    name: 'ShiftDecisionMessageView',
    mixins: [ShiftDecisionMessageViewMixin]
  }
</script>
<style scoped>
.line_left {
    position: relative;
    display: block;
    margin-bottom: 5px;
    margin-right: 400px;
    clear: both;
}

.line_right {
    position: relative;
    display: block;
    margin-bottom: 5px;
    margin-left: 400px;
    clear: both;
}

.name {
  font-size: 80%;
}

.line_left .message {
  margin: 0;
  position: relative;
  padding: 10px;
  border-radius: 20px;
  font-size: medium;
  background-color: #E9EAF5;
}

.line_right .message {
  margin: 0;
  position: relative;
  padding: 10px;
  border-radius: 20px;
  background-color: #AD002D;
  font-size: medium;
  color: white;
}
.line_left .autoDate {
  font-size: small;
  color: #AD002D;
}
.line_right .autoDate {
  font-size: small;
  color: #E9EAF5;
}

.line_left .date {
  display: block;
  text-align: right;
  font-size: 80%;
}

.line_right .date {
  display: block;
  text-align: left;
  font-size: 80%;
}
</style>
