<template>
  <div class="row">
    <div class="col-md-12">
      <form method="#" action="#">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{$t('label.change_work_schedule')}}
            </h4>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-sm-5">
                <div style="text-align: center;">
                  <div>
                    <label>{{$t('label.before_change')}}</label>
                  </div>
                  <el-select class="select-success length5"
                              size="small"
                              placeholder=""
                              v-model="detail.beforeWorkSchedule"
                              @change="beforeChanged"
                              >
                    <el-option v-for="element in beforeList"
                                class="select-success"
                                :value="element.value"
                                :label="element.label_abbreviation"
                                :key="element.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-sm-2">
                <div style="text-align: center;">
                  <br>
                  <label><i class="ti-arrow-right"></i></label>
                </div>
              </div>
              <div class="col-sm-5">
                <div style="text-align: center;">
                  <div>
                    <label>{{$t('label.after_change')}}</label>
                  </div>
                  <el-select class="select-success length5"
                              size="small"
                              placeholder=""
                              v-model="detail.afterWorkSchedule">
                    <el-option v-for="element in workScheduleList"
                                class="select-success"
                                :value="element.value"
                                :label="element.label_abbreviation"
                                :key="element.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-sm-12" style="margin-top: 20px;">
                <div style="text-align: right;">
                  <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="beforeList.length === 0" @click="modify">{{$t('button.modify')}}</button>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    props: {
      beforeList: Array,
      afterList: Array,
      afterAllList: Array
    },
    data () {
      return {
        workScheduleList: [],
        detail: {
          isChanged: false,
          beforeWorkSchedule: '',
          afterWorkSchedule: ''
        }
      }
    },
    methods: {
      beforeChanged () {
        if (this.detail.beforeWorkSchedule === 'zcwfms4awh') {
          this.workScheduleList = this.afterAllList
        } else {
          this.workScheduleList = this.afterList
        }
      },
      modify () {
        this.detail.isChanged = true
        this.$emit('close', this.detail)
      },
      doClose () {
        this.$emit('close', this.detail)
      }
    },
    created () {
      this.workScheduleList = this.afterList
      if (this.beforeList.length > 0) {
        this.detail.beforeWorkSchedule = this.beforeList[0].value
        this.beforeChanged()
      }
      if (this.afterList.length > 0) {
        this.detail.afterWorkSchedule = this.afterList[0].value
      }
    }
  }
</script>
<style>
</style>
