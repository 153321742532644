<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
        <div style="margin-bottom: 5px;" v-if="$route.params.paramRoleCode">
          <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack">
            {{$t('button.back')}}
          </button>
        </div>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <ValidationObserver ref="observer">
        <Authority
          v-model="detail.authority"
          :param-role-code="$route.params ? $route.params.paramRoleCode : ''"
          :param-role-name="$route.params ? $route.params.paramRoleName : ''"
          :labels="labels"
          :role-list="comboData.role_list"
          :menu-list="comboData.menu_list"
          :screen-list="comboData.screen_list"
          :disabled="isReadOnly"
          :parent-message="message"
          ref="child"
          @init="init"
          @searchSucceeded="searchSucceeded"
        />
      </ValidationObserver>
      <div style="text-align: left;" v-if="detail.authority.length > 0">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
          <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.update')}}
        </button>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import AuthoritySheetMixin from '@/components/Dashboard/Views/Generated/AuthoritySheetMixin'

  export default {
    name: 'AuthoritySheet',
    methods: {
      afterInit (res) {
        this.comboData.role_list = res.common_area.combo_data.role_list
        this.comboData.menu_list = res.common_area.combo_data.menu_list
        this.comboData.menu_list.unshift({label: '', value: ''})
        this.comboData.screen_list = res.common_area.combo_data.screen_list
      },
      convertDetail (detail) {
        const apiArea = []
        if (this.searchCond.search_type === 4) {
          for (const role of detail.authority) {
            for (const menu of role.data1) {
              for (const screen of menu.data2) {
                let element = {
                  role_code: role.titleKey,
                  screen_code: screen.titleKey,
                  entry: screen.authority.entry,
                  update: screen.authority.update,
                  delete: screen.authority.delete,
                  print: screen.authority.print,
                  search: screen.authority.search,
                  upload: screen.authority.upload,
                  download: screen.authority.download,
                  preview: screen.authority.preview,
                  update_count: screen.update_count
                }
                apiArea.push(element)
              }
            }
          }
        } else if (this.searchCond.search_type === 5) {
          for (const menu of detail.authority) {
            for (const screen of menu.data1) {
              for (const role of screen.data2) {
                let element = {
                  role_code: role.titleKey,
                  screen_code: screen.titleKey,
                  entry: role.authority.entry,
                  update: role.authority.update,
                  delete: role.authority.delete,
                  print: role.authority.print,
                  search: role.authority.search,
                  upload: role.authority.upload,
                  download: role.authority.download,
                  preview: role.authority.preview,
                  update_count: role.update_count
                }
                apiArea.push(element)
              }
            }
          }
        }
        return {search_info: this.searchCond, authority_list: apiArea}
      }
    },
    mixins: [AuthoritySheetMixin]
  }
</script>
<style scoped>
</style>
