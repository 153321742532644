<template>
  <div style="margin-top: 20px; padding: 0 20px;">
    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <div style="display: inline-block; width: 49%;">
          <img src="static/img/corpus.png" style="width: 150px;">
          &nbsp;&nbsp;&nbsp;&nbsp;⇒&nbsp;&nbsp;&nbsp;&nbsp;
          <img src="static/img/LOGO_A2.png" style="width: 150px;">
        </div>
        <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
          <span v-html="getText"></span>
        </div>
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       title="CorpusよりArtemisへデータを引き継ぎます。"
                       subtitle=""
                       @on-change="onChange"
                       @on-complete="wizardComplete"
                       error-color="#EB5E28"
                       color="#C50052"
                       :key="componentKey">
            <tab-content title="会社情報"
                         :before-change="validateCompanyStep"
                         icon="ti-home">
              <company-step ref="companyStep"></company-step>
            </tab-content>

            <tab-content title="コード体系設定"
                         :before-change="validateCodeStep"
                         icon="ti-key">
              <code-step ref="codeStep"></code-step>
            </tab-content>

            <tab-content title="事業所情報"
                         :before-change="validateOfficeStep"
                         icon="ti-layout-column3-alt">
              <office-step ref="officeStep"></office-step>
            </tab-content>

            <tab-content title="従業員情報"
                         :before-change="validateEmployeeStep"
                         icon="ti-user">
              <employee-step ref="employeeStep"></employee-step>
            </tab-content>

            <template v-if="hasSalaryInfo === 'true'">
              <tab-content title="給与明細情報"
                          :before-change="validateSalaryStep"
                          icon="ti-money">
                <salary-step ref="salaryStep"></salary-step>
              </tab-content>
            </template>

            <tab-content title="移行実行確認"
                         icon="ti-check">
              <div style="padding: 150px 0; overflow-y: auto; height: calc(100vh - 415px);">

                <h4 class="text-center text-space">
                  CorpusからArtemisへのデータ引継ぎの準備が整いました。<br>
                  <!-- <template v-if="customerId"> -->
                    データ引継ぎを開始します。よろしければ、実行ボタンを押下してください。<br>
                  <!-- </template>
                  <template v-else>
                    データ引継ぎを開始します。よろしければ、支払い登録後に実行ボタンを押下してください。<br>
                  </template> -->
                  <br>
                  ※データ移行後、内容を修正したい場合、Artemisの画面より行うことができますのでご安心ください。
                  <!-- <template v-if="!customerId || customerId === 'null'">
                    <br>
                    <br>
                    <br>
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="goPaymentPage">
                      支払い画面へ
                    </button>
                  </template> -->
                </h4>
              </div>
            </tab-content>

            <button slot="prev" class="btn btn-warning btn-sm btn-default btn-wd btn-back">戻る</button>
            <button slot="next" class="btn btn-warning btn-sm btn-default btn-wd btn-next">次へ</button>
            <button slot="finish" class="btn btn-info btn-sm btn-default btn-wd">実行</button>
          </form-wizard>
        </div>
        <button class="btn btn-info btn-sm btn-default btn-wd btn-back" @click="saveTemporarily">一時保存</button>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import CompanyStep from './WizardForm/CompanyStep.vue'
  import EmployeeStep from './WizardForm/EmployeeStep.vue'
  import CodeStep from './WizardForm/CodeStep.vue'
  import LicenseStep from './WizardForm/LicenseStep.vue'
  import OfficeStep from './WizardForm/OfficeStep.vue'
  import SalaryStep from './WizardForm/SalaryStep.vue'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    data () {
      return {
        componentKey: 0,
        hasSalaryInfo: false,
        // customerId: '',
        wizardModel: {}
      }
    },
    components: {
      FormWizard,
      TabContent,
      CompanyStep,
      OfficeStep,
      EmployeeStep,
      CodeStep,
      SalaryStep,
      LicenseStep
    },
    computed: {
      getText () {
        return '<a href="' + 'https://linkpoint.zendesk.com/hc/ja/articles/4406529471001-移行画面' + '" target="_blank" style="color: #317FB8;">' + '移行画面の使い方' + '</a>'
      }
    },
    methods: {
      onChange () {
        if (this.$refs.employeeStep) {
          this.$refs.employeeStep.componentKey3 += 1
        }
      },
      // goPaymentPage () {
      //   const licenseCount = this.$refs.employeeStep.comboData.employees.length
      //   let paymentInfo = {
      //     licenseCount: licenseCount > 10 ? String(licenseCount) : '10',
      //     email: localStorage.getItem('migrationMailAddress')
      //   }
      //   localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo))
      //   const w = 900
      //   const h = 600
      //   const wTop = window.screenTop + (window.innerHeight / 2) - (h / 2)
      //   const wLeft = window.screenLeft + (window.innerWidth / 2) - (w / 2)
      //   window.open('/#/migration/waiting', 'checkout', `width=${w},height=${h},top=${wTop},left=${wLeft}`)
      // },
      saveTemporarily () {
        let migrationData = {
          company_info: {
            before: this.$refs.companyStep.before,
            after: this.$refs.companyStep.after
          },
          place_info: {
            office: this.$refs.officeStep.office,
            offices: this.$refs.officeStep.offices
          },
          employee_info: {
            before: this.$refs.employeeStep.before,
            after: this.$refs.employeeStep.after
          },
          office_info: this.$refs.codeStep.offices,
          business_info: this.$refs.codeStep.businesses,
          work_schedule_info: this.$refs.codeStep.workSchedules,
          salary_info: {
            layouts: this.$refs.salaryStep ? this.$refs.salaryStep.layouts : []
          },
          payment_info: {
            licenseCount: this.$refs.licenseStep.licenseCount,
            employeeCount: this.$refs.licenseStep.employeeCount,
            admin: this.$refs.licenseStep.admin,
            employees: this.$refs.licenseStep.employees
          },
          combo_data: {
            workSecondRoundType: this.$refs.companyStep.workSecondRoundType,
            firstWeekdays: this.$refs.companyStep.firstWeekdays,
            industries1: this.$refs.companyStep.industries1_full,
            industries2: this.$refs.companyStep.industries2_full,
            industries3: this.$refs.companyStep.industries3_full,
            prefectures: this.$refs.companyStep.prefectures,
            workMinuteRoundTypes: this.$refs.companyStep.workMinuteRoundTypes,
            industry_mapping_big_and_during: this.$refs.companyStep.industry_mapping_big_and_during,
            industry_mapping_during_and_small: this.$refs.companyStep.industry_mapping_during_and_small,
            businessTypeNumberList: this.$refs.companyStep.businessTypeNumberList,
            comboData: this.$refs.employeeStep.comboData,
            salaryCategoryList: this.$refs.salaryStep ? this.$refs.salaryStep.salaryCategoryList : [],
            attributeList: this.$refs.salaryStep ? this.$refs.salaryStep.attributeList : []
          }
        }
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(`migration/${sessionStorage.getItem('corpusCompanyCode')}.json`)
        httpsReference.putString(JSON.stringify(migrationData)).then(function (snapShot) {
          swal({
            type: 'success',
            title: 'ここまでの登録内容を一時保存しました。',
            text: '移行を再開する場合は、もう一度Corpusのリンクからアクセスしてください。',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      back () {
        this.$router.go(-1)
      },
      copyCompanyData () {
        this.$refs.officeStep.office.post_code = this.$refs.companyStep.after.post
        this.$refs.officeStep.office.prefectures = this.$refs.companyStep.after.state_code
        this.$refs.officeStep.office.city_code = this.$refs.companyStep.after.state_code + '_' + this.$refs.companyStep.after.municipality_code
        this.$refs.officeStep.office.town = this.$refs.companyStep.after.town
        this.$refs.officeStep.office.building = this.$refs.companyStep.after.building
        this.$refs.officeStep.office.telephone1 = this.$refs.companyStep.after.telephone1
        this.$refs.officeStep.office.fax = this.$refs.companyStep.after.fax
        this.$refs.officeStep.office.industry1 = this.$refs.companyStep.after.industry1
        this.$refs.officeStep.office.industry1Name = this.getSelectName(this.$refs.companyStep.after.industry1, this.$refs.companyStep.industries1_full)
        this.$refs.officeStep.office.industry2 = this.$refs.companyStep.after.industry2
        this.$refs.officeStep.office.industry2Name = this.getSelectName(this.$refs.companyStep.after.industry2, this.$refs.companyStep.industries2_full)
        this.$refs.officeStep.office.industry3 = this.$refs.companyStep.after.industry3
        this.$refs.officeStep.office.industry3Name = this.getSelectName(this.$refs.companyStep.after.industry3, this.$refs.companyStep.industries3_full)
        this.$refs.officeStep.componentKey += 1
      },
      validateCompanyStep () {
        this.$refs.companyStep.companyError = ''
        return this.$refs.companyStep.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            document.getElementsByName('companyFrame')[0].scrollTop = 0
            this.$emit('on-submit', this.registerForm, isValid)
          }
          this.copyCompanyData()
          return isValid
        }).catch(error => {
          console.log(error)
        })
      },
      validateOfficeStep () {
        this.$refs.officeStep.error = ''
        return this.$refs.officeStep.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            document.getElementsByName('officeFrame')[0].scrollTop = 0
            this.$emit('on-submit', this.registerForm, isValid)
          }
          var map = new Map()
          for (const office of this.$refs.officeStep.offices) {
            const duplicatePlace = map.get(office.place_code)
            if (duplicatePlace) {
              var isSamePlace = true
              isSamePlace = office.place_name === duplicatePlace.place_name && isSamePlace
              isSamePlace = office.post_code === duplicatePlace.post_code && isSamePlace
              isSamePlace = office.prefectures === duplicatePlace.prefectures && isSamePlace
              isSamePlace = office.city === duplicatePlace.city && isSamePlace
              isSamePlace = office.building === duplicatePlace.building && isSamePlace
              if (!isSamePlace) {
                this.$refs.officeStep.error = '場所コードが重複しています。'
                document.getElementsByName('officeFrame')[0].scrollTop = 0
                isValid = false
                break
              }
            } else {
              map.set(office.place_code, office)
            }
          }
          return isValid
        }).catch(error => {
          console.log(error)
        })
      },
      validateEmployeeStep () {
        this.$refs.employeeStep.error = ''
        if (this.$refs.employeeStep.after.length === 0) {
          this.$refs.employeeStep.error = '従業員情報を取得中です。このまましばらくお待ちください。'
          return false
        } else {
          for (let i = 0; i < this.$refs.employeeStep.after.length; i++) {
            if (!this.$refs.employeeStep.before[i].selected) {
              const employee = this.$refs.employeeStep.after[i]
              if (!employee.isSaved) {
                this.$refs.employeeStep.error = '必須項目が未設定の従業員がいます。'
                return false
              }
            }
          }
          if (!this.$refs.employeeStep.admin) {
            this.$refs.employeeStep.error = '管理者を選択してください。'
            return false
          }
          const warnEmployees = []
          for (let i = 0; i < this.$refs.employeeStep.after.length; i++) {
            if (!this.$refs.employeeStep.before[i].selected) {
              const employee = this.$refs.employeeStep.after[i]
              if (employee.dependent_detail.length === 0 && employee.support_relative_total > 0) {
                warnEmployees.push(employee.employee_name)
              }
            }
          }
          if (warnEmployees.length > 0) {
            let warnMsg = '<p>以下の従業員は扶養家族が未設定のため、所得税計算が正しく行えません。移行後に扶養家族の情報を設定してください。</p>'
            for (const warnEmployee of warnEmployees) {
              warnMsg += '<br>' + warnEmployee
            }
            swal({
              type: 'warning',
              html: warnMsg,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false,
              width: 550
            })
          }
        }
        return true
      },
      validateCodeStep () {
        this.$refs.codeStep.officeError = ''
        var map = new Map()
        for (const office of this.$refs.codeStep.offices) {
          if (!office.after.area_cd) {
            this.$refs.codeStep.officeError = '事業所コードが未設定の事業所があります。'
            break
          } else {
            if (!this.isHankaku(office.after.area_cd)) {
              this.$refs.codeStep.officeError = '事業所コードは半角で入力してください。'
              break
            }
            if (map.get(office.after.area_cd)) {
              this.$refs.codeStep.officeError = '事業所コードが重複しています。'
              break
            } else {
              map.set(office.after.area_cd, office)
            }
          }
        }
        this.$refs.codeStep.businessError = ''
        map = new Map()
        for (const business of this.$refs.codeStep.businesses) {
          if (!business.after.job_cd) {
            this.$refs.codeStep.businessError = '職種コードが未設定の職種があります。'
            break
          } else {
            if (!this.isHankaku(business.after.job_cd)) {
              this.$refs.codeStep.businessError = '職種コードは半角で入力してください。'
              break
            }
            if (map.get(business.after.job_cd)) {
              this.$refs.codeStep.businessError = '職種コードが重複しています。'
              break
            } else {
              map.set(business.after.job_cd, business)
            }
          }
        }
        this.$refs.codeStep.workScheduleError = ''
        map = new Map()
        for (const workSchedule of this.$refs.codeStep.workSchedules) {
          if (!workSchedule.after.work_system_cd) {
            this.$refs.codeStep.workScheduleError = '勤務体系コードが未設定の勤務体系があります。'
            break
          } else {
            if (!this.isHankaku(workSchedule.after.work_system_cd)) {
              this.$refs.codeStep.workScheduleError = '勤務体系コードは半角で入力してください。'
              break
            }
            if (map.get(workSchedule.after.work_system_cd)) {
              this.$refs.codeStep.workScheduleError = '勤務体系コードが重複しています。'
              break
            } else {
              map.set(workSchedule.after.work_system_cd, workSchedule)
            }
          }
        }
        let isValid = (this.$refs.codeStep.officeError + this.$refs.codeStep.businessError + this.$refs.codeStep.workScheduleError).length === 0
        if (!isValid) {
          document.getElementsByName('codeFrame')[0].scrollTop = 0
        }
        for (const office of this.$refs.officeStep.offices) {
          for (const row of this.$refs.codeStep.offices) {
            if (row.after.before_area_cd === office.area_cd) {
              office.before_area_cd = row.before.area_cd
              office.area_cd = row.after.area_cd
              office.place_code = row.after.area_cd
              break
            }
          }
        }
        return isValid
      },
      validateSalaryStep () {
        return this.$refs.salaryStep.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          return isValid
        }).catch(error => {
          console.log(error)
        })
      },
      // validateLicenseStep () {
      //   this.$refs.licenseStep.error = ''
      //   return this.$refs.licenseStep.$refs.observer.validate()
      //   .then(isValid => {
      //     if (!isValid) {
      //       this.$emit('on-submit', this.registerForm, isValid)
      //     }
      //     let customerId = localStorage.getItem('customerId')
      //     if (customerId === null) {
      //       this.$refs.licenseStep.error = '支払い情報が未登録です。'
      //       isValid = false
      //     } else {
      //       const self = this
      //       return this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions?customerId=' + customerId)
      //         .then(res => {
      //           if (!res.data.isSubscription) {
      //             self.$refs.licenseStep.error = '支払い情報が未登録です。'
      //           }
      //           return res.data.isSubscription
      //         })
      //     }
      //     return isValid
      //   }).catch(error => {
      //     console.log(error)
      //   })
      // },
      // async validateSubscription (customerId) {
      //   return this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions?customerId=' + customerId)
      // },
      async wizardComplete () {
        let customerId = localStorage.getItem('customerId')
        // if (!customerId) {
        //   swal({
        //     html: `<div style="text-align: center; font-size: 18px;">支払い情報が未登録です。</div>`,
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-warning btn-sm btn-default',
        //     buttonsStyling: false,
        //     allowOutsideClick: false
        //   })
        //   return
        // }
        // const res = await this.validateSubscription(customerId)
        // if (!res.data || !res.data.isSubscription) {
        //   swal({
        //     html: '<div style="text-align: center; font-size: 18px;">' + this.$t('message.payment_info_not_registered') + '</div>',
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-warning btn-sm btn-default',
        //     buttonsStyling: false,
        //     allowOutsideClick: false
        //   })
        //   return
        // }
        // we gather models from all steps into one model
        let companyData = {
          company_code: this.$refs.companyStep.after.company_code,
          company_nm: this.$refs.companyStep.after.company_nm,
          homepage: this.$refs.companyStep.after.homepage,
          post: this.$refs.companyStep.after.post,
          state_code: this.$refs.companyStep.after.state_code,
          municipality_code: this.$refs.companyStep.after.municipality_code,
          town: this.$refs.companyStep.after.town,
          building: this.$refs.companyStep.after.building,
          telephone1: this.$refs.companyStep.after.telephone1,
          fax: this.emptyToBlank(this.$refs.companyStep.after.fax),
          industry1: this.$refs.companyStep.after.industry1,
          industry1_name: this.getSelectName(this.$refs.companyStep.after.industry1, this.$refs.companyStep.industries1_full),
          industry2: this.$refs.companyStep.after.industry2,
          industry2_name: this.getSelectName(this.$refs.companyStep.after.industry2, this.$refs.companyStep.industries2_full),
          industry3: this.$refs.companyStep.after.industry3,
          industry3_name: this.getSelectName(this.$refs.companyStep.after.industry3, this.$refs.companyStep.industries3_full),
          start_day_of_the_week: this.$refs.companyStep.after.start_day_of_the_week,
          rounding_second: this.$refs.companyStep.after.roundingSecond.value,
          rounding_type: this.$refs.companyStep.after.workHourRoundType.value,
          rounding_month: this.$refs.companyStep.after.workHourRoundTypeMonthly.value,
          rounding_term: this.$refs.companyStep.after.rounding_term,
          rounding_term_over_work: this.$refs.companyStep.after.rounding_term_over_work,
          rounding_job_start: this.$refs.companyStep.after.workStartTimeRoundTypeDaily.value,
          rounding_job_end: this.$refs.companyStep.after.workEndTimeRoundTypeDaily.value,
          corporate_number: this.$refs.companyStep.after.corporate_number,
          labor_insurance_number: this.$refs.companyStep.after.labor_insurance_number,
          employment_insurance_existence: this.$refs.companyStep.after.employmentInsuranceExistence.value,
          health_insurance_existence: this.$refs.companyStep.after.healthInsuranceExistence.value,
          welfare_pension_existence: this.$refs.companyStep.after.welfarePensionExistence.value,
          employees_pension_fund_existence: this.$refs.companyStep.after.employeesPensionFundExistence.value,
          business_type_number: this.$refs.companyStep.after.business_type_number,
          workers_accident_compensation_rate: this.$refs.companyStep.after.workers_accident_compensation_rate
        }
        let placeList = []
        for (const after of this.$refs.officeStep.offices) {
          let place = {
            area_cd: after.before_area_cd,
            area_nm: after.area_nm,
            post_code: after.post_code,
            state_code: after.prefectures,
            municipality_code: after.city_code ? after.city_code.split('_')[1] : after.city_code,
            town: after.town,
            building: after.building,
            lat: after.lat,
            lng: after.lng,
            place_code: after.place_code,
            place_name: after.place_name,
            telephone1: after.telephone1,
            fax: this.emptyToBlank(after.fax),
            industry1: after.industry1,
            industry1_name: this.getSelectName(after.industry1, this.$refs.officeStep.industries1_full),
            industry2: after.industry2,
            industry2_name: this.getSelectName(after.industry2, this.$refs.officeStep.industries2_full),
            industry3: after.industry3,
            industry3_name: this.getSelectName(after.industry3, this.$refs.officeStep.industries3_full),
            special_measures: parseInt(after.special_measures.value, 10),
            regulatory_grace_exclusion: after.regulatory_grace_exclusion.value === '1',
            reference_number: after.reference_number,
            job_total_minutes: after.job_total_minutes
          }
          placeList.push(place)
        }
        let employeeData = []
        for (let i = 0; i < this.$refs.employeeStep.after.length; i++) {
          if (this.$refs.employeeStep.before[i].selected) continue
          const after = this.$refs.employeeStep.after[i]
          after.selected_prefecture = after.prefectures ? after.prefectures : ''
          after.selected_city = after.city ? after.city.split('_')[1] : ''
          let dependentDetail = []
          if (after.dependent_detail) {
            for (const element of after.dependent_detail) {
              if (element.delete.value === '1') continue
              let dependent = {
                dependent_name: element.dependent_name,
                birthday: element.birthday,
                relationship: element.relationship,
                relationship_question1: '',
                relationship_question2: '',
                relationship_question3: '',
                relationship_question4: '',
                living_together: element.living_together.value ? element.living_together.value : '1',
                dependent_relative_classification: element.dependent_relative_classification
              }
              if (dependent.relationship === '1' || dependent.relationship === '2') {
                dependent.relationship_question1 = element.relationshipQuestion1.value
                dependent.relationship_question2 = element.relationshipQuestion2.value
                dependent.relationship_question3 = element.relationshipQuestion3.value
                dependent.relationship_question4 = element.relationshipQuestion4.value
              }
              dependentDetail.push(dependent)
            }
          }
          let employee = {
            append_path: after.append_path,
            employee_code: after.employee_code,
            employee_name: after.employee_name,
            employee_classification_code: after.employee_classification_code,
            language: after.language,
            hire_date: after.hire_date,
            retirement_date: after.retirement_date,
            mail_address: after.mail_address,
            sex: after.sex,
            birthday: after.birthday,
            tel: after.tel,
            social_insurance: after.social_insurance,
            employment_insurance: after.employment_insurance,
            attendance_management: after.attendance_management,
            payroll_management: after.payroll_management,
            paid_leave_payment: after.paid_leave_payment,
            availability: after.availability,
            number_of_working_days_per_week: after.number_of_working_days_per_week,
            FeriCa: after.FeriCa,
            dependent_count: dependentDetail.length,
            health_insurance_sign: after.health_insurance_sign,
            health_insurance_no: after.health_insurance_no,
            basic_pension_number: after.basic_pension_number,
            employment_insurance_number: after.employment_insurance_number,
            my_number: after.my_number,
            work_schedule_code: after.work_schedule_code,
            salary_closing_code: after.salary_closing_code,
            post_code: after.post_code,
            state_code: after.prefectures,
            municipality_code: after.city ? after.city.split('_')[1] : after.city,
            town: after.town,
            building: after.building,
            dependent_detail: dependentDetail
          }
          employeeData.push(employee)
        }
        let officeList = []
        for (const office of this.$refs.codeStep.offices) {
          let element = {
            area_cd: office.after.area_cd,
            area_nm: office.after.area_nm,
            before_area_cd: office.after.before_area_cd
          }
          officeList.push(element)
        }
        let businessList = []
        for (const business of this.$refs.codeStep.businesses) {
          let element = {
            job_cd: business.after.job_cd,
            job_nm: business.after.job_nm,
            before_job_cd: business.after.before_job_cd
          }
          businessList.push(element)
        }
        let workScheduleList = []
        for (const workSchedule of this.$refs.codeStep.workSchedules) {
          let element = {
            work_system_cd: workSchedule.after.work_system_cd,
            work_system_nm: workSchedule.after.work_system_nm,
            before_work_system_cd: workSchedule.after.before_work_system_cd
          }
          workScheduleList.push(element)
        }
        let salaryLayoutList = []
        if (this.$refs.salaryStep) {
          for (const layout of this.$refs.salaryStep.layouts) {
            let element = {
              layout_code: layout.layout_cd,
              salary_category: layout.salary_category
            }
            salaryLayoutList.push(element)
          }
        }
        const email = localStorage.getItem('migrationMailAddress')
        let paymentData = {
          employee_cd: this.$refs.employeeStep.admin,
          mail_address: email,
          customer_id: customerId
        }
        localStorage.removeItem('customerId')
        let savedData = JSON.parse(sessionStorage.getItem('migrationData'))
        if (savedData) {
          const httpsReference = this.$firebase.storage()
            .ref()
            .child(`migration/${sessionStorage.getItem('corpusCompanyCode')}.json`)
          httpsReference.delete().then(function () {})
        }
        let migrationData = {
          screen_code: '123',
          company_code: this.$refs.companyStep.after.company_code,
          employee_code: 'admin',
          company_data: companyData,
          place_list: placeList,
          employee_data: employeeData,
          office_list: officeList,
          business_list: businessList,
          work_schedule_list: workScheduleList,
          salary_layout_list: salaryLayoutList,
          payment_data: paymentData
        }
        console.log(JSON.stringify(migrationData))
        let url = '/migration/mg_execute'
        this.$axios.post(`${process.env.VUE_APP_API_ORIGIN}${url}`, migrationData)
        swal({
          type: 'success',
          title: '移行を開始しました！',
          html: '契約者メールアドレスに移行完了通知が届くまで、しばらくお待ちください。',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(function (result) {
          window.location = '/#/login'
        })
      }
    },
    created () {
      this.hasSalaryInfo = localStorage.getItem('salaryExist')
      // this.customerId = localStorage.getItem('customerId')
      this.componentKey += 1
    }
  }
</script>
<style>
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #9A9A9A !important;
    color: white;
  }
  .vue-form-wizard.md .wizard-icon-circle.tab_shape {
    height: 60px;
  }
</style>
