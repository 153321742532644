<template>
  <div class="card">
    <template v-if="errMsgs.length > 0">
      <div class="card-header">
        <div v-for="errMsg in errMsgs" v-bind:key="errMsg">
          <small style="color: #C84513; margin-left: 5px;">{{errMsg}}</small>
        </div>
      </div>
    </template>
    <div class="card-content">
      <table :key="componentKey">
        <tr>
          <th></th>
          <th class="before-change">
            <div class="form-group">
              {{$t('label.before_change')}}
            </div>
          </th>
          <th class="after-change">
            <div class="form-group">
              {{$t('label.after_change')}}
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.post_code}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_post_code}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationPostalCode
                v-model="application.after_post_code"
                item-name="post_code"
                max-length="10"
                classes="form-control numLength10"
                rules="required"
                :item-label="labels.post_code"
                :placeholder="isReadOnly ? '' : $t('placeholder.post_code')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
                :button-label="$t('button.postal_code_search_simple')"
                @search="postalCodeSearch"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.state}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_prefectures}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationSearchSelect
                v-model="application.after_prefectures"
                item-name="prefectures"
                :placeholder="$t('label.select_description')"
                classes="length8"
                rules="required"
                :item-label="labels.state"
                :options="comboData.prefectures_list"
                :disabled="isReadOnly"
                @change="prefecturesChanged"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.municipality_name_ryaku}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_city}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationSearchSelect
                v-model="application.after_city"
                item-name="city"
                :placeholder="$t('label.select_description')"
                classes="length50"
                rules="required"
                :item-label="labels.municipality_name_ryaku"
                :options="comboData.city_list"
                :disabled="isReadOnly"
                ref="afterCity"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.town}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_town}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_town"
                item-name="town"
                max-length="50"
                classes="form-control length50"
                :item-label="labels.town"
                :placeholder="isReadOnly ? '' : $t('placeholder.town')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.building}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_building}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_building"
                item-name="building"
                max-length="30"
                classes="form-control length30"
                :item-label="labels.building"
                :placeholder="isReadOnly ? '' : $t('placeholder.building')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.tel}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_tel}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_tel"
                item-name="tel"
                max-length="20"
                classes="form-control numLength20"
                rules="tel"
                :item-label="labels && labels.tel ? labels.tel : ''"
                :placeholder="isReadOnly ? '' : $t('placeholder.tel')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.emergency_contact}}</label>
            </div>
          </th>
          <td class="before-change">
            <div class="form-group">
              <small>{{application.before_emergency_contact}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_emergency_contact"
                item-name="emergency_contact"
                max-length="20"
                classes="form-control numLength20"
                rules="tel"
                :item-label="labels.emergency_contact"
                :placeholder="isReadOnly ? '' : $t('placeholder.emergency_contact')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th style="vertical-align: top;">
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.other}}</label>
            </div>
          </th>
          <td class="before-change" style="vertical-align: top;">
            <div class="form-group">
              <div style="display: inline-block;">
                <small>{{application.before_other}}</small>
              </div>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationTextArea
                v-model="application.after_other"
                item-name="other"
                max-length="100"
                classes="form-control length30"
                :item-label="labels.other"
                :placeholder="isReadOnly ? '' : $t('placeholder.other')"
                :disabled="isReadOnly"
                :rows="3"
              />
            </div>
          </td>
        </tr>
      </table>
      <div class="form-group" style="margin-top: 10px;">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>
            {{$t('label.residents_card')}}
            <br>
            {{$t('label.residents_card2')}}
          </label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationImage
            v-model="application.append_path_list"
            :disabled="isReadOnly"
            :size="3 * 1024 * 1024"
          />
        </div>
      </div>
      <div class="form-group" style="margin-top: 10px;">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.address_change_supplement')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCodeHorizontal'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationImage from '@/components/UIComponents/Inputs/ValidationImagesHorizontal'
  import { Loader } from 'google-maps'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationPostalCode,
      ValidationText,
      ValidationTextArea,
      ValidationSelect,
      ValidationSearchSelect,
      ValidationDate,
      ValidationImage
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {},
        geocoder: {},
        google: null
      }
    },
    methods: {
      async setGeocoder () {
        if (!this.google) {
          this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
        }
        this.geocoder = new this.google.maps.Geocoder()
      },
      prefecturesChanged () {
        if (this.application.prefectures_before && this.application.prefectures_before !== this.application.after_prefectures) {
          this.application.after_city = ''
          const flags = {...this.$refs.afterCity.$children[0].flags}
          flags.validated = false
          this.$refs.afterCity.$children[0].setFlags(flags)
        }
        if (this.application.after_prefectures) {
          this.application.prefectures_before = this.application.after_prefectures
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.application.after_prefectures]) {
            for (const prefecture of this.comboData.prefectures_list) {
              if (prefecture.label === this.application.after_prefectures) {
                this.application.after_prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.application.after_prefectures]) {
            for (const code of this.comboData.mapping_list[this.application.after_prefectures]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.city_list = []
        }
      },
      postalCodeSearch (postalCode) {
        const self = this
        this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
          const myLatlng = results[0].geometry.location
          self.geocoder.geocode({location: myLatlng}, function (results, status) {
            if (status === 'OK') {
              for (const result of results) {
                if (result.types) {
                  let isFound = false
                  for (const type of result.types) {
                    if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                      const componentsLength = result.address_components.length
                      self.application.post_code = result.address_components[componentsLength - 1].long_name
                      for (const prefecture of self.comboData.prefectures_list) {
                        if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                          self.application.after_prefectures = prefecture.value
                          break
                        }
                      }
                      if (self.application.after_prefectures) {
                        self.prefecturesChanged()
                      }
                      for (const municipality of self.comboData.municipality_list) {
                        if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                          self.application.after_city = municipality.value
                          break
                        }
                      }
                      self.application.after_town = ''
                      for (var i = componentsLength - 5; i >= 0; i--) {
                        self.application.after_town = self.application.after_town + result.address_components[i].long_name
                      }
                      self.componentKey += 1
                      isFound = true
                      break
                    }
                  }
                  if (isFound) break
                }
              }
            }
          })
        })
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    created () {
      this.setGeocoder()
    },
    mounted () {
      this.application = this.value
      if (this.application.after_prefectures) {
        this.prefecturesChanged()
      }
    }
  }
</script>
<style scoped>
  .before-change {
    padding-right: 25px;
  }
  .after-change {
    border-left: 1px solid #999;
    padding-left: 25px;
  }
</style>
