import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'

export default {
  name: 'AnnualCalendarDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect,
    ValidationCheckboxes,
    ValidationRadio
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        office_code: '',
        office_name: '',
        target_date: '',
        jan_calender: '',
        feb_calender: '',
        mar_calender: '',
        apl_calender: '',
        may_calender: '',
        jun_calender: '',
        jly_calender: '',
        aug_calender: '',
        sep_calender: '',
        oct_calender: '',
        nov_calender: '',
        dec_calender: ''
      },
      copy: {
        copy_office_code: '',
        copy_office_name: ''
      },
      labels: {},
      comboData: {},
      showSearchCondition: true,
      holiday: {
        error: '',
        job_holiday: {
          disabled: false,
          color: 'gray',
          errMsg: '',
          items: []
        },
        legal_holiday: {
          disabled: false,
          color: 'gray',
          errMsg: '',
          items: []
        },
        national_holiday: {
          value: null,
          disabled: false,
          items: [
            {
              id: '1',
              label: this.$t('label.add_national_holiday'),
              value: '1'
            },
            {
              id: '2',
              label: this.$t('label.delete_national_holiday'),
              value: '2'
            }
          ]
        }
      },
      showResult: false,
      dayTypes: [],
      results: [],
      dayCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      orgDayOnCount: 0,
      dayOnCount: 0,
      dayOffCount: 0,
      dayUnknownCount: 0,
      dayUnknown2Count: 0,
      dayUnknown3Count: 0,
      holidayMessage: {},
      copyOfficeList: [],
      public_holiday_year_list: [],
      is_holiday: true,
      errMsg: ''
    }
  },
  computed: {
    screenCode () {
      return '07-001'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // 祝日表示判定
    changeTargetDate (targetDate) {
      this.is_holiday = false
      // 対象年がブランクの場合、表示
      if (targetDate === '') {
        this.is_holiday = true
      }
      // 対象年がリストに存在する場合、表示
      for (const publicHolidayYear of this.public_holiday_year_list) {
        if (targetDate === String(publicHolidayYear)) {
          this.is_holiday = true
        }
      }
    },
    // コピー用の事業所リストを取得
    getCopyOfficeList (copyOfficeList, officeCode) {
      let resultList = []
      for (const copyOffice of copyOfficeList) {
        // 事業所を選んでいない時は処理終了
        if (officeCode === '') {
          resultList.push(copyOffice)
          break
        }
        // 登録しようとしている事業所は除く
        if (copyOffice.value === officeCode) {
          continue
        }
        resultList.push(copyOffice)
      }
      return resultList
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    getColor (dayType) {
      if (dayType === '1' || dayType === '8' || dayType === '9') {
        return 'color: #000000;'
      } else if (dayType === '2' || dayType === '5' || dayType === '6' || dayType === '7') {
        return 'color: #0000FF;'
      } else if (dayType === '4') {
        return 'color: #FF8C00;'
      } else {
        return 'color: #FF0000;'
      }
    },
    updateResults () {
      let dayCount = [0, 0, 0, 0, 0, 0, 0, 0, 0]
      let dayOnCount = 0
      let dayOffCount = 0
      let dayUnknownCount = 0
      let dayUnknown2Count = 0
      let dayUnknown3Count = 0
      for (const result of this.results) {
        const res = this.updateResult(result)
        dayOnCount += res[0]
        dayOffCount += res[1]
        dayUnknownCount += res[2]
        dayUnknown2Count += res[3]
        dayUnknown3Count += res[4]
        for (let i = 0; i < res[5].length; i++) {
          dayCount[i] += res[5][i]
        }
      }
      this.dayOnCount = dayOnCount
      this.dayOffCount = dayOffCount
      this.dayUnknownCount = dayUnknownCount
      this.dayUnknown2Count = dayUnknown2Count
      this.dayUnknown3Count = dayUnknown3Count
      this.dayCount = dayCount
    },
    updateResult (result) {
      let dayCount = [0, 0, 0, 0, 0, 0, 0, 0, 0]
      let dayOnCount = 0
      let dayOffCount = 0
      let dayUnknownCount = 0
      let dayUnknown2Count = 0
      let dayUnknown3Count = 0
      for (const row of result.data) {
        if (row.day_of_sunday) {
          dayCount[parseInt(row.day_type_of_sunday) - 1]++
          if (row.day_type_of_sunday === '1' || row.day_type_of_sunday === '4' || row.day_type_of_sunday === '8' || row.day_type_of_sunday === '9') {
            dayOnCount++
            if (row.day_type_of_sunday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_sunday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_sunday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_monday) {
          dayCount[parseInt(row.day_type_of_monday) - 1]++
          if (row.day_type_of_monday === '1' || row.day_type_of_monday === '4' || row.day_type_of_monday === '8' || row.day_type_of_monday === '9') {
            dayOnCount++
            if (row.day_type_of_monday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_monday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_monday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_tuesday) {
          dayCount[parseInt(row.day_type_of_tuesday) - 1]++
          if (row.day_type_of_tuesday === '1' || row.day_type_of_tuesday === '4' || row.day_type_of_tuesday === '8' || row.day_type_of_tuesday === '9') {
            dayOnCount++
            if (row.day_type_of_tuesday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_tuesday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_tuesday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_wednesday) {
          dayCount[parseInt(row.day_type_of_wednesday) - 1]++
          if (row.day_type_of_wednesday === '1' || row.day_type_of_wednesday === '4' || row.day_type_of_wednesday === '8' || row.day_type_of_wednesday === '9') {
            dayOnCount++
            if (row.day_type_of_wednesday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_wednesday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_wednesday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_thursday) {
          dayCount[parseInt(row.day_type_of_thursday) - 1]++
          if (row.day_type_of_thursday === '1' || row.day_type_of_thursday === '4' || row.day_type_of_thursday === '8' || row.day_type_of_thursday === '9') {
            dayOnCount++
            if (row.day_type_of_thursday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_thursday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_thursday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_friday) {
          dayCount[parseInt(row.day_type_of_friday) - 1]++
          if (row.day_type_of_friday === '1' || row.day_type_of_friday === '4' || row.day_type_of_friday === '8' || row.day_type_of_friday === '9') {
            dayOnCount++
            if (row.day_type_of_friday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_friday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_friday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
        if (row.day_of_saturday) {
          dayCount[parseInt(row.day_type_of_saturday) - 1]++
          if (row.day_type_of_saturday === '1' || row.day_type_of_saturday === '4' || row.day_type_of_saturday === '8' || row.day_type_of_saturday === '9') {
            dayOnCount++
            if (row.day_type_of_saturday === '4') {
              dayUnknownCount++
            } else if (row.day_type_of_saturday === '8') {
              dayUnknown2Count++
            } else if (row.day_type_of_saturday === '9') {
              dayUnknown3Count++
            }
          } else {
            dayOffCount++
          }
        }
      }
      result.day_on_count = dayOnCount
      result.day_off_count = dayOffCount
      return [dayOnCount, dayOffCount, dayUnknownCount, dayUnknown2Count, dayUnknown3Count, dayCount]
    },
    isDataExist (data) {
      if (data.length === 0) {
        return false
      } else {
        const lastData = data[data.length - 1]
        if (lastData.day_of_saturday) {
          return false
        }
        return true
      }
    },
    search (updateMonth) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        const jobHoliday = []
        for (const item of this.holiday.job_holiday.items) {
          if (item.value) {
            // 所定休日と法定休日が同じ曜日の場合エラー
            for (const legal of this.holiday.legal_holiday.items) {
              if (legal.value) {
                if (item.id === legal.id) {
                  this.message.text = this.$t('message.job_or_legal')
                  return
                }
              }
            }
            jobHoliday.push(parseInt(item.id, 10))
          }
        }
        const legalHoliday = []
        for (const legal of this.holiday.legal_holiday.items) {
          if (legal.value) {
            legalHoliday.push(parseInt(legal.id, 10))
          }
        }
        let request = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            office_code: this.detail.office_code,
            office_name: this.getLabel(this.getSelectName(this.detail.office_code, this.comboData.office_list)),
            target_date: this.detail.target_date,
            copy_office_code: '',
            copy_office_name: '',
            job_holiday_list: jobHoliday,
            legal_holiday_list: legalHoliday,
            holiday_flag: this.holiday.national_holiday.value
          }
        }
        // コピーモードの場合、コピー事業所名の取得と入力できない項目を削除する
        if (this.copy.copy_office_code !== '') {
          request.api_area.copy_office_code = this.copy.copy_office_code
          request.api_area.copy_office_name = this.getLabel(this.getSelectName(this.copy.copy_office_code, this.comboData.office_list))
          request.api_area.job_holiday_list = []
          request.api_area.legal_holiday_list = []
          request.api_area.holiday_flag = null
        }
        this.send('/view/annual_calendar', request)
          .then(res => {
            this.labels = { ...this.labels, ...this.getLabels(res.common_area) }
            this.comboData = { ...this.comboData, ...this.getComboData(res.common_area.combo_data) }
            const results = this.createCalendar(res.api_area)
            if (typeof updateMonth === 'string') {
              for (const result of results) {
                if (result.title === updateMonth) {
                  for (let i = 0; i < this.results.length; i++) {
                    if (this.results[i].title === updateMonth) {
                      this.results[i] = result
                      break
                    }
                  }
                  break
                }
              }
            } else {
              this.results = results
            }
            this.updateResults()
            this.showResult = true
            // スクロールバー設定
            this.$nextTick(() => {
              if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
                this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
              }
            })
          })
      })
    },
    reflect () {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        const jobHoliday = []
        for (const item of this.holiday.job_holiday.items) {
          if (item.value) {
            // 所定休日と法定休日が同じ曜日の場合エラー
            for (const legal of this.holiday.legal_holiday.items) {
              if (legal.value) {
                if (item.id === legal.id) {
                  this.message.text = this.$t('message.job_or_legal')
                  return
                }
              }
            }
            jobHoliday.push(parseInt(item.id, 10))
          }
        }
        const legalHoliday = []
        for (const legal of this.holiday.legal_holiday.items) {
          if (legal.value) {
            legalHoliday.push(parseInt(legal.id, 10))
          }
        }
        const dayTypes = ['day_type_of_monday', 'day_type_of_tuesday', 'day_type_of_wednesday', 'day_type_of_thursday', 'day_type_of_friday', 'day_type_of_saturday', 'day_type_of_sunday']
        for (let i = 0; i < this.results.length; i++) {
          for (let j = 0; j < this.results[i].data.length; j++) {
            if (this.results[i].data[j].is_close === 2) {
              continue
            }
            for (let k = 0; k < dayTypes.length; k++) {
              if (['1', '2', '3'].includes(this.results[i].data[j][dayTypes[k]])) {
                if (this.holiday.legal_holiday.items[k].value) {
                  this.results[i].data[j][dayTypes[k]] = '3'
                } else if (this.holiday.job_holiday.items[k].value) {
                  this.results[i].data[j][dayTypes[k]] = '2'
                } else {
                  this.results[i].data[j][dayTypes[k]] = '1'
                }
              }
            }
          }
        }
        this.updateResults()
      })
    },
    createCalendar (apiArea) {
      const results = []
      for (const entry of Object.entries(apiArea)) {
        let month = 0
        switch (entry[0]) {
          case 'jan_calender':
            month = 1
            break
          case 'feb_calender':
            month = 2
            break
          case 'mar_calender':
            month = 3
            break
          case 'apl_calender':
            month = 4
            break
          case 'may_calender':
            month = 5
            break
          case 'jun_calender':
            month = 6
            break
          case 'jly_calender':
            month = 7
            break
          case 'aug_calender':
            month = 8
            break
          case 'sep_calender':
            month = 9
            break
          case 'oct_calender':
            month = 10
            break
          case 'nov_calender':
            month = 11
            break
          case 'dec_calender':
            month = 12
            break
        }
        // カレンダー以外は次の要素を処理
        if (month === 0) {
          continue
        }
        const result = {
          month: month,
          title: month + this.$t('label.month'),
          button_title: this.$t('button.reset_specific_month').replace('{month}', month),
          day_on_count: 0,
          day_off_count: 0,
          data: []
        }
        let lastData = {}
        for (const dateInfo of entry[1]) {
          switch (dateInfo.day_of_the_week) {
            case 6:
              lastData.day_of_sunday = dateInfo.date
              lastData.day_type_of_sunday = String(dateInfo.holiday)
              break
            case 0:
              lastData.day_of_monday = dateInfo.date
              lastData.day_type_of_monday = String(dateInfo.holiday)
              break
            case 1:
              lastData.day_of_tuesday = dateInfo.date
              lastData.day_type_of_tuesday = String(dateInfo.holiday)
              break
            case 2:
              lastData.day_of_wednesday = dateInfo.date
              lastData.day_type_of_wednesday = String(dateInfo.holiday)
              break
            case 3:
              lastData.day_of_thursday = dateInfo.date
              lastData.day_type_of_thursday = String(dateInfo.holiday)
              break
            case 4:
              lastData.day_of_friday = dateInfo.date
              lastData.day_type_of_friday = String(dateInfo.holiday)
              break
            case 5:
              lastData.day_of_saturday = dateInfo.date
              lastData.day_type_of_saturday = String(dateInfo.holiday)
              break
          }
          lastData.is_close = dateInfo.is_close
          if (dateInfo.day_of_the_week === 5) {
            result.data.push(lastData)
            lastData = {}
          }
        }
        if (lastData.day_of_sunday) {
          result.data.push(lastData)
        }
        results.push(result)
      }
      return results
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.labels.copy_office_code = this.$t('label.copy_role_code').replace('{item1}', this.labels.office).replace('{item2}', this.screenCodeTitle(this.screenCode))
    },
    // 独自バリデーション用
    customValidate () {
      this.errMsg = ''
      let isCloseExist = false
      for (const result of this.results) {
        for (const row of result.data) {
          if (row.is_close === 2) {
            isCloseExist = true
            break
          }
        }
      }
      if (isCloseExist) {
        if (this.orgDayOnCount !== this.dayOnCount) {
          this.errMsg = this.$t('message.invalid_working_day_count')
          return false
        }
      }
      return true
    },
    // 送信データ調整用
    convertDetail (detail) {
      // 事業所名を取得
      detail.office_name = this.getLabel(this.getSelectName(this.detail.office_code, this.comboData.office_list))
      // 削除モードの場合、処理を終了する
      if (this.mode === this.modes.delete) {
        return detail
      }
      for (const result of this.results) {
        const month = ('0' + result.month).slice(-2)
        let calendar = ''
        for (const week of result.data) {
          if (week.day_of_sunday) {
            calendar = calendar + String(week.day_type_of_sunday)
          }
          if (week.day_of_monday) {
            calendar = calendar + String(week.day_type_of_monday)
          }
          if (week.day_of_tuesday) {
            calendar = calendar + String(week.day_type_of_tuesday)
          }
          if (week.day_of_wednesday) {
            calendar = calendar + String(week.day_type_of_wednesday)
          }
          if (week.day_of_thursday) {
            calendar = calendar + String(week.day_type_of_thursday)
          }
          if (week.day_of_friday) {
            calendar = calendar + String(week.day_type_of_friday)
          }
          if (week.day_of_saturday) {
            calendar = calendar + String(week.day_type_of_saturday)
          }
        }
        switch (month) {
          case '01':
            detail.jan_calender = calendar
            break
          case '02':
            detail.feb_calender = calendar
            break
          case '03':
            detail.mar_calender = calendar
            break
          case '04':
            detail.apl_calender = calendar
            break
          case '05':
            detail.may_calender = calendar
            break
          case '06':
            detail.jun_calender = calendar
            break
          case '07':
            detail.jly_calender = calendar
            break
          case '08':
            detail.aug_calender = calendar
            break
          case '09':
            detail.sep_calender = calendar
            break
          case '10':
            detail.oct_calender = calendar
            break
          case '11':
            detail.nov_calender = calendar
            break
          case '12':
            detail.dec_calender = calendar
            break
        }
      }
      return detail
    },
    officeCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = { office_code: this.detail.office_code }
          detail.target_date = this.detail.target_date
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.nullToBlank(this.convertDetail(detail))
        }
        this.send(`/${operation}/annual_calendar`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code,
          target_date: this.params.target_date
        }
      }
      this.send('/init/annual_calendar', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        // コピー用の事業所
        this.copyOfficeList = this.comboData.office_list.map(list => ({ ...list }))
        this.copyOfficeList.unshift({ 'label': this.$t('label.new_add'), 'value': '' })
        // 所定休日
        this.holiday.job_holiday.items = this.comboData.day_of_the_week_list.map(list => ({ ...list }))
        for (const items of this.holiday.job_holiday.items) {
          items.id = items.value
          items.value = false
        }
        // 法定休日
        this.holiday.legal_holiday.items = this.comboData.day_of_the_week_list.map(list => ({ ...list }))
        for (const items of this.holiday.legal_holiday.items) {
          items.id = items.value
          items.value = false
        }
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            // _is_closeを含む文字列はdetailに設定しない
            if (entry[0].match(/_is_close/)) {
              continue
            } else if (entry[0] === 'public_holiday_year_list') {
              this.public_holiday_year_list = entry[1]
              continue
            }
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (Array.isArray(entry[1])) {
              for (const row of entry[1]) {
                const isCloseName = entry[0].replace('calender', 'is_close')
                row.is_close = res.api_area[isCloseName]
              }
              this.detail[entry[0]] = entry[1]
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
          // 新規登録時は行わない
          if (this.mode !== this.modes.add) {
            this.results = this.createCalendar(res.api_area)
            this.updateResults()
            this.orgDayOnCount = this.dayOnCount
            this.showResult = true
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 250px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    isClose (isClose) {
      // 締め済ならtrueを返却する
      return isClose === 2
    }
  },
  created () {
    this.init()
  }
}
