<template>
  <div>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
                  
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form method="#" action="#">
                  <div style="margin-bottom: 20px; font-size: 14px;">
                    Artemis Collierにようこそ！<br />
                    はじめにあなたのお名前とメールアドレス、パスワードを登録してください。<br />
                    折り返し、会社登録のご案内をご登録いただいたメールアドレス宛てに送付させていただきます。
                  </div>
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <div style="color: red;">{{newCompanyError}}</div>
                    </div>
                    <div class="card-content">
                      <ValidationObserver ref="observer">
                        <ValidationText
                          v-model="newCompany.employee_name"
                          item-name="user_name"
                          max-length="30"
                          classes="form-control form-background"
                          rules="required"
                          item-label="お名前"
                          placeholder="お名前を入力して下さい。"
                          :disabled="false"
                          help-text="30文字以内で入力して下さい。"
                        />
                        <ValidationText
                          v-model="newCompany.mail_address"
                          item-name="mail_address"
                          max-length="255"
                          classes="form-control form-background"
                          rules="required|mailaddress"
                          item-label="メールアドレス"
                          placeholder="メールアドレスを入力して下さい。"
                          :disabled="false"
                          help-text="255文字以内で入力して下さい。ここで登録されたメールアドレスはユーザーIDとなります。あとから変更はできません。"
                        />
                        <ValidationText
                          v-model="newCompany.password"
                          type="password"
                          item-name="password"
                          max-length="30"
                          classes="form-control form-background"
                          rules="required|min:8|isUnusable|isPasswordWeakly"
                          item-label="パスワード"
                          placeholder="パスワードを入力して下さい。"
                          :disabled="false"
                          help-text="英大文字小文字数字を含め、12文字以上30文字以内で入力して下さい。"
                          :is-password="true"
                        />
                        <VPasswordStrengthMeter 
                          :password="newCompany.password"
                          :enableFeedback="false"
                        />
                      </ValidationObserver>
                    </div>
                    <br>
                    <div class="recaptcha">
                      <vue-recaptcha
                        sitekey="6Lf4pLEaAAAAAP75kSQgMzu3E1f2B8Xa1b98USLp" 
                        @verify="onVerify"
                        @expired="onExpired"
                      ></vue-recaptcha>
                    </div>
                    <br>
                    <div class="card-footer text-center">
                      <button type="button" class="btn btn-sm btn-default btn-wd btn-warning" :disabled="!canSend" @click="temporalRegist">登録</button>
                      <!-- <button type="button" class="btn btn-sm btn-default btn-wd btn-warning" @click="temporalRegist">登録</button> -->
                      <div style="margin-top: 50px;">
                        <a class="button" :href="url">{{$t('label.registered_user')}}</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  import VueRecaptcha from 'vue-recaptcha'
  import VPasswordStrengthMeter from 'v-password-strength-meter'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader,
      ValidationText,
      ValidationSelect,
      VueRecaptcha,
      VPasswordStrengthMeter
    },
    data () {
      return {
        newCompanyError: undefined,
        newCompany: {
          employee_name: undefined,
          mail_address: undefined,
          password: ''
        },
        canSend: false,
        gRecaptchaResponse: ''
      }
    },
    computed: {
      url () {
        let url = '/#/admin/login'
        const corpusCompanyCode = sessionStorage.getItem('corpusCompanyCode')
        const corpusCompanyName = sessionStorage.getItem('corpusCompanyName')
        if (corpusCompanyCode) {
          url += '?corpusCompanyCode=' + corpusCompanyCode
          url += '&corpusCompanyName=' + corpusCompanyName
        }
        return url
      }
    },
    methods: {
      onVerify (response) {
        this.canSend = true
        this.gRecaptchaResponse = response
      },
      onExpired () {
        this.canSend = false
      },
      async temporalRegist () {
        this.$refs.observer.validate().then(isValid => {
          this.newCompanyError = ''
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          let newUser = {
            mail_address: this.newCompany.mail_address,
            api_area: {
              user_name: this.newCompany.employee_name,
              password: this.newCompany.password,
              corporate: 1,
              language: 'JPN',
              g_recaptcha_response: this.gRecaptchaResponse,
              access_mail_address: this.newCompany.mail_address
            }
          }
          const corpusCompanyCode = sessionStorage.getItem('corpusCompanyCode')
          const corpusCompanyName = sessionStorage.getItem('corpusCompanyName')
          if (corpusCompanyCode) {
            newUser.api_area.company_code = corpusCompanyCode
            newUser.api_area.company_name = corpusCompanyName
          }
          this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/insert/user`, newUser)
          .then(res => {
            localStorage.removeItem('customerId')
            swal({
              text: '仮登録しました。メールをご確認ください。',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              type: 'success',
              allowOutsideClick: false
            }).then(() => {
              window.location = '/#/login'
            })
          })
          .catch(error => {
            console.error(error)
            if (error.message !== 'システムエラー') {
              swal({
                text: '登録に失敗しました。',
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            }
          })
        })
      }
    },
    created () {
      // reCAPTCHAの読み込み
      const scriptElement = document.createElement('script')
      scriptElement.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
      const headElement = document.getElementsByTagName('body')[0]
      headElement.appendChild(scriptElement)
    }
  }
</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
  .recaptcha {
    padding-left: 28px;
    padding-right: 28px;
  }
</style>
<style>
  .form-background {
    background: #eee !important;
  }
</style>