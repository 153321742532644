<template>
  <div class="row" ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="Workflow" :draggable="true" :clickToClose="false" height="auto" :width="1400" :pivotX="0.6">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :approve-record="approveRecord" :is-modal="true" @close="hide" @show-attendance-record="showAttendanceRecord"/>
        </div>
      </modal>
      <modal name="approvalAttendanceRecord" :draggable="true" :clickToClose="false" height="auto" :width="1600" :pivotX="0.8">
        <div class="modal-body">
          <AttendanceRecordDetail @close="hideAttendanceRecord"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <!-- 申請番号 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_number}}</label>
              <input type="text" :placeholder="$t('placeholder.application_number')" maxlength="10" class="form-control numLength10" style="text-align:right" v-model="searchCond.application_number">
            </div>
            <!-- 申請分類 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.applicationClassification}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in comboData.application_classification_list" v-bind:key = item.value>
                <input type="checkbox" class="gray" :value="item.value" v-model="searchCond.application_classification" @change="applicationClassificationChanged"/>{{item.label}}&nbsp;
              </div>
            </div>
            <!-- 申請書 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_form_name_ryaku}}</label><br>
              <vSelect name="application_form_code"
                        class="style-chooser length16"
                        :options="comboData.application_form_list"
                        v-model="searchCond.application_form_code">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>
            </div>
            <!-- 状態 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.activity_status}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in comboData.activity_status4display_list" v-bind:key = item.value>
                <input type="checkbox" class="gray" :value="item.value" v-model="searchCond.activity_status4display"/>{{item.label}}&nbsp;
              </div>
            </div>
            <br>
            <!-- 申請日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.apply_date}}</label>
              <br>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.term_from" @change="dateSynchronizeFrom"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.term_to" @change="dateSynchronizeTo"></el-date-picker>
            </div>
            <!-- 到達日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <div style="display: inline-block">
                <label>{{labels.reaching_date}}</label>
                <br>
                <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.reaching_date_from" @change="reachingDateSynchronizeFrom"></el-date-picker>
                <label>&#12316;</label>
                <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.reaching_date_to" @change="reachingDateSynchronizeTo"></el-date-picker>
              </div>
            </div>
            <!-- 部署 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.group_ryaku}}</label><br>
              <vSelect name="application_form_code"
                        class="style-chooser length16"
                        :clearable="false"
                        :options="comboData.group_list"
                        v-model="searchCond.group_code">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>
            </div>
            <!-- 検索ボタン -->
            <template v-if="roles.search === 1">
              <div style="text-align: left;">
                <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </template>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
      <template v-if="tableData && tableData.length > 0">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content">
            <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
              <div style="padding-left: 10px;">
                <input type="checkbox" id="allSelect" @click="selectAll" class="gray" :disabled="isChanged">
                <label for="allSelect">{{ $t('label.check_all') }}</label>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" :data="pagedData"
                        :empty-text="$t('label.no_data')"
                        :style="tableStyle"
                        :show-header="false"
                        ref="table"
                        :row-class-name="tableRowClassName">
                <el-table-column :width="50">
                  <template slot-scope="props">
                    <div style="margin-top: 10px; margin-bottom: 12px; text-align: center;" v-show="props.row.screen_mode === 4 || props.row.screen_mode === 6">
                      <input type="checkbox" v-model="props.row.selected" class="gray" @change="rowSelected" :disabled="isChanged">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="1250">
                  <template slot-scope="props">
                    <a class="btn btn-simple btn-icon like" @click="show(props.$index, props.row)" :disabled="isChanged" :style="isChanged ? 'pointer-events: none;' : ''">
                      <div class="card" style="background-color: #F8F8F8; opacity: 0.9">
                        <div class="card-content">
                          <table>
                            <tr>
                              <td width="180" style="background-color: #F8F8F8; border: none;">
                                <div style="text-align: center; font-size: medium; font-weight: bold;">
                                  {{props.row.approverl_employee_name}}
                                </div>
                                <div style="text-align: center;">
                                  <small>{{props.row.approverl_employee_code}}</small>
                                </div>
                                <div style="text-align: center; line-height: 100%;">
                                  <small>{{props.row.approverl_group_name}}</small>
                                </div>
                                <div style="text-align: center;">
                                  <small>{{props.row.approverl_company_name}}</small>
                                </div>
                              </td>
                              <td width="200" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center; font-size: medium; font-weight: bold;">
                                  {{props.row.application_form_name}}
                                </div>
                                <div style="text-align: center;">
                                  <small>{{props.row.application_classification_name}}</small>
                                </div>
                              </td>
                              <td width="60" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center;">
                                  {{getLabel(getSelectName(props.row.activity_status, comboData.activity_status_list))}}
                                </div>
                              </td>
                              <td width="200" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center; font-size: small; font-weight: bold;">
                                  {{props.row.reaching_date}}
                                </div>
                                <div style="text-align: center;">
                                  {{labels.application_number + ' ' + props.row.application_number}}
                                </div>
                                <div style="text-align: center;" v-if="props.row.re_application_number">
                                  {{labels.re_application_number + ' ' + props.row.re_application_number}}
                                </div>
                              </td>
                              <td width="200" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center; font-size: small; font-weight: bold;">
                                  {{props.row.process_date}}
                                </div>
                                <div style="text-align: center;">
                                  {{getLabel(getSelectName(props.row.approval_function, comboData.approval_function_list))}}
                                </div>
                              </td>
                              <td width="180" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center; font-size: medium; font-weight: bold;">
                                  {{props.row.target_employee_name}}
                                  <template v-if="props.row.target_employee_code !== props.row.applicant_employee_code">
                                    {{"(" + props.row.applicant_employee_name + ")"}}
                                  </template>
                                </div>
                                <div style="text-align: center;">
                                  <small>{{props.row.target_employee_code}}</small>
                                  <template v-if="props.row.target_employee_code !== props.row.applicant_employee_code">
                                    <small>{{"(" + props.row.applicant_employee_code + ")"}}</small>
                                  </template>
                                </div>
                              </td>
                              <td width="200" style="background-color: #F8F8F8; border-bottom: none; border-left: 1px solid #999;">
                                <div style="text-align: center;">
                                  {{props.row.apply_date}}
                                </div>
                                <div style="text-align: center;">
                                  {{props.row.target_date ? '(' + $t('label.label_header_target_date') + ':' + props.row.target_date + ')' : ''}}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </a>
                  </template>
                </el-table-column>
              </el-table>
              <br>
              <button type="button" class="btn btn-sm btn-default btn-submit" @click="approveAll" :disabled="!isSelected || isChanged">
                <span class="btn-label">
                    <i class="fa fa-check"></i>
                </span>
                {{$t('button.approve_all')}}
              </button>
              <br>
              <br>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, DatePicker} from 'element-ui'
  import vSelect from 'vue-select'
  import Workflow from '@/components/Dashboard/Views/Working/Workflow/Workflow'
  import PPagination from '@/components/UIComponents/Pagination'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      vSelect,
      Workflow,
      AttendanceRecordDetail: () => import('@/components/Dashboard/Views/Working/AttendanceRecordDetail'),
      PPagination,
      'el-date-picker': DatePicker
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        draggable: true,
        isChanged: false,
        beforePerPage: 25,
        beforeCurrentPage: 1,
        showSearchCondition: true,
        modalWidth: 800,
        modalWidthSub: 1100,
        pagination: {
          perPage: 100,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        tableData: undefined,
        searchCond: {
          application_number: '',
          application_classification: [],
          activity_status4display: [],
          term_from: '',
          term_to: '',
          reaching_date_from: '',
          reaching_date_to: '',
          group_code: ''
        },
        searchedApiArea: {},
        showOperation: true,
        showApplicationClassification: true,
        showApplicationFormName: true,
        showActivityStatus: true,
        showReachingDate: true,
        showApprovalFunction: true,
        showProcessDate: true,
        showApplyDate: true,
        showApproverlCompanyName: true,
        showApproverlGroup: true,
        showApproverlEmployee: true,
        showTargetEmployee: true,
        showApplicantEmployee: true,
        showApplicationNumber: true,
        showReApplicationNumber: true,
        labels: {},
        roles: {},
        comboData: {},
        applicationFormCode: '',
        approveRecord: {},
        tableWrapperStyle: '',
        tableStyle: '',
        isSelected: false
      }
    },
    methods: {
      approveAll () {
        swal({
          html: this.$t('message.confirm_approve_all'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          allowOutsideClick: false,
          width: 550
        }).then(() => {
          const applicationInfoList = []
          for (const row of this.tableData) {
            if (row.selected) {
              applicationInfoList.push({
                application_number: row.application_number,
                application_form_code: row.application_form_code,
                screen_mode: row.screen_mode,
                route_type: row.route_type,
                route_number: row.route_number,
                target_company_code: row.target_company_code,
                target_group_code: row.target_group_code,
                target_employee_code: row.target_employee_code,
                approverl_company_code: row.approverl_company_code,
                approverl_group_code: row.approverl_group_code,
                approverl_employee_code: row.approverl_employee_code,
                approverl_role_code: row.approverl_role_code
              })
            }
          }
          const sendData = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              application_info_list: applicationInfoList,
              application_number: this.searchCond.application_number || null,
              application_classification_code_list: this.convertAssociative('application_classification_code', this.searchCond.application_classification),
              application_form: this.searchCond.application_form_code ? this.searchCond.application_form_code.value : '',
              activity_status4display_list: this.convertAssociative('activity_status4display', this.stringListToNumberList(this.searchCond.activity_status4display)),
              apply_date_from: this.searchCond.term_from,
              apply_date_to: this.searchCond.term_to,
              group_code: this.searchCond.group_code ? this.searchCond.group_code.value : '',
              reaching_date_from: this.searchCond.reaching_date_from,
              reaching_date_to: this.searchCond.reaching_date_to
            }
          }
          this.send('/workflow/approve/wf_approval_status/list', sendData)
            .then(res => {
              this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
              // this.isChanged = true
              document.getElementById('allSelect').checked = false
              this.afterSearch(res)
            })
        })
      },
      rowSelected () {
        var selected = false
        for (const row of this.tableData) {
          if (row.selected) {
            selected = true
            break
          }
        }
        this.isSelected = selected
      },
      selectAll () {
        const checked = document.getElementById('allSelect').checked
        for (const row of this.tableData) {
          if (row.screen_mode === 4 || row.screen_mode === 6) {
            row.selected = checked
            this.isSelected = checked
          }
        }
        this.tableData = [...this.tableData]
      },
      applicationClassificationChanged () {
        const applicationFormList = []
        for (const row of this.searchCond.application_classification) {
          if (this.comboData.application_classification_form_mapping_list[row]) {
            for (const row3 of this.comboData.application_classification_form_mapping_list[row]) {
              for (const row4 of this.comboData.org_application_form_list) {
                if (row3 === row4.value) {
                  applicationFormList.push(row4)
                  break
                }
              }
            }
          }
        }
        this.comboData.application_form_list = applicationFormList
      },
      showAttendanceRecord () {
        this.$modal.show('approvalAttendanceRecord')
      },
      hideAttendanceRecord () {
        this.$modal.hide('approvalAttendanceRecord')
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      customValidate (apiArea) {
        // 申請番号数字チェック
        // ブランクならnullに変換
        if (apiArea.application_number === '') {
          apiArea.application_number = null
          // 数字以外ならエラーメッセージを返却
        } else if (isNaN(apiArea.application_number)) {
          return this.$t('message.decimal_field_required').replace('{_field_}', this.labels.application_number)
          // 上記以外の場合、数字に変換
        } else {
          apiArea.application_number = parseInt(apiArea.application_number)
        }
        return null
      },
      afterInit (res) {
        this.comboData.application_classification_form_mapping_list = res.common_area.combo_data.application_classification_form_mapping_list.application_classification_form_mapping_list
        this.comboData.org_application_form_list = [...res.common_area.combo_data.application_classification_form_mapping_list.application_form_list]
        this.comboData.activity_status4display = this.comboData.activity_status4display_list
      },
      getDateColumnWidth (rows, columnNameValue, columnName1) {
        let maxBytes = this.getBytes(columnNameValue)
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1])
            if (bytes > maxBytes) {
              maxBytes = 14
              break
            }
          }
          if (maxBytes === 14) {
            break
          }
        }
        return maxBytes * 8 + 20
      },
      getTargetColumnWidth (rows, columnNameValue, columnName1) {
        let maxBytes = this.getBytes(columnNameValue)
        if (rows) {
          for (const row of rows) {
            if (row[columnName1]) {
              const bytes = this.getBytes(row[columnName1])
              if (bytes > maxBytes) {
                maxBytes = bytes
              }
            }
            if (row['target_employee_code'] !== row['applicant_employee_code']) {
              const bytes = this.getBytes(row['target_employee_name'] + row['applicant_employee_name'])
              if (bytes > maxBytes) {
                maxBytes = bytes
              }
            }
          }
        }
        return maxBytes > 30 ? maxBytes * 7 + 20 : maxBytes * 8 + 20
      },
      pageChanged () {
        this.isChanged = true
        // 一度も検索していない場合、init処理
        if (this.searchedApiArea.application_number === undefined) {
          this.init(false)
        } else {
          this.search(false)
        }
      },
      search (searchButtonFlag = true) {
        let apiArea
        // 検索ボタンの場合、検索条件を設定する
        if (searchButtonFlag) {
          apiArea = {
            application_number: this.searchCond.application_number,
            application_classification_code_list: this.convertAssociative('application_classification_code', this.searchCond.application_classification),
            application_form: this.searchCond.application_form_code ? this.searchCond.application_form_code.value : '',
            activity_status4display_list: this.convertAssociative('activity_status4display', this.stringListToNumberList(this.searchCond.activity_status4display)),
            apply_date_from: this.searchCond.term_from,
            apply_date_to: this.searchCond.term_to,
            group_code: this.searchCond.group_code ? this.searchCond.group_code.value : '',
            reaching_date_from: this.searchCond.reaching_date_from,
            reaching_date_to: this.searchCond.reaching_date_to
          }
          // ページングの場合、前回の検索条件にページカウントとリミットを設定する
        } else {
          // ダミーデータを設定する
          this.tableData = this.getDummyData(this.tableData, this.pagination.currentPage, this.pagination.perPage, this.beforeCurrentPage, this.beforePerPage)
          this.componentKey += 1
          apiArea = this.searchedApiArea
          apiArea.pagerCount = this.pagination.currentPage
          apiArea.limit = this.pagination.perPage
        }
        // APIに渡す検索条件を設定
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: apiArea
        }
        this.nullToBlank(searchCond.api_area)
        const message = this.customValidate(searchCond.api_area)
        if (message !== null) {
          this.message.text = message
          return
        }
        // 検索条件をディープコピー
        this.searchedApiArea = Object.assign({}, apiArea, {})
        // 検索API呼び出し
        this.send('/workflow/view/wf_approval_status/list', searchCond)
        .then(res => {
          if (searchButtonFlag) {
            this.pagination.currentPage = 1
          }
          this.afterSearch(res)
        })
        .catch(error => {
          console.error(error)
        })
      },
      afterSearch (res) {
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.approval_info_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        // 表示するページ以外を空配列で埋める
        this.tableData = this.getPageList(this.tableData, this.pagination.currentPage, this.pagination.perPage, res.api_area.total_count)
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.componentKey += 1
        })
        this.isChanged = false
        this.beforePerPage = this.pagination.perPage
        this.beforeCurrentPage = this.pagination.currentPage
      },
      indexCheck (list, code) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].code === code) {
            return i
          }
        }
        return -1
      },
      init (firstFlg = true) {
        // 初期表示
        // APIに渡すパラメータを設定
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {}
        }
        // 初期化API呼び出し
        this.send('/workflow/init/wf_approval_status/list', params)
        .then(res => {
          if (firstFlg) {
            // ラベル情報をthis.labelsに設定
            this.labels = this.getLabels(res.common_area)
            this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
            this.afterInit(res)
            // ボタン権限をthis.rolesに設定
            this.roles = res.common_area.button_role
            // 申請分類をチェック
            for (let result of this.comboData.application_classification_list) {
              this.searchCond.application_classification.push(result.value)
            }
            // 状態をチェック
            for (let result of this.comboData.activity_status4display_list) {
              // 承認待ち、確認待ちの場合
              if (result.value === '2' || result.value === '3') {
                this.searchCond.activity_status4display.push(result.value)
              }
            }
            // 部署の初期値をログイン部署に設定
            for (const group of this.comboData.group_list) {
              if (group.value === this.$store.state.loginUser.groupCode) {
                this.searchCond.group_code = group
                break
              }
            }
          }
          if (this.roles.search === 1) {
            this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.approval_info_data
            for (const row of this.tableData) {
              for (const entry of Object.entries(row)) {
                if (entry[1] === null) {
                  row[entry[0]] = ''
                }
              }
            }
          }
          // 表示するページ以外を空配列で埋める
          this.tableData = this.getPageList(this.tableData, this.pagination.currentPage, this.pagination.perPage, res.api_area.total_count)
          this.$nextTick(() => {
            let width = 0
            for (const child of this.$refs.table.$children) {
              if (child.width) {
                width += parseInt(child.width, 10)
              }
            }
            this.tableStyle = 'width: ' + (width + 1) + 'px'
            const maxWidth = window.innerWidth - 360
            if (width > maxWidth) {
              width = maxWidth
              this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
            } else {
              this.tableWrapperStyle = ''
            }
            if (this.$route.params.attendanceRecord) {
              for (const row of this.tableData) {
                if (row.application_number === parseInt(this.$route.params.attendanceRecord.application_number, 10)) {
                  this.show(0, row)
                  break
                }
              }
            }
            this.componentKey += 1
          })
          this.isChanged = false
        })
      },
      show (index, row) {
        this.applicationFormCode = row.application_form_code
        this.approveRecord = {
          screen_mode: row.screen_mode,
          target_company_code: row.target_company_code,
          target_group_code: row.target_group_code,
          target_employee_code: row.target_employee_code,
          approverl_company_code: row.approverl_company_code,
          approverl_group_code: row.approverl_group_code,
          approverl_employee_code: row.approverl_employee_code,
          approverl_role_code: row.approverl_role_code,
          application_number: row.application_number,
          route_type: row.route_type,
          route_number: row.route_number
        }
        this.$modal.show('Workflow')
      },
      hide (...args) {
        this.$modal.hide('Workflow')
        if (args[0]) {
          this.isChanged = true
          this.componentKey += 1
          this.search()
        }
      },
      tableRowClassName () {
        return ''
      },
      // 申請日のシンクロ(FROM)
      dateSynchronizeFrom () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_to = this.searchCond.term_from
        }
      },
      // 申請日のシンクロ(TO)
      dateSynchronizeTo () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_from = to = this.searchCond.term_to
        }
      },
      // 到達日のシンクロ(FROM)
      reachingDateSynchronizeFrom () {
        let from = this.searchCond.reaching_date_from
        let to = this.searchCond.reaching_date_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.reaching_date_from = today
        }
        if (to > today) {
          this.searchCond.reaching_date_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.reaching_date_to = this.searchCond.reaching_date_from
        }
      },
      // 到達日のシンクロ(TO)
      reachingDateSynchronizeTo () {
        let from = this.searchCond.reaching_date_from
        let to = this.searchCond.reaching_date_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.reaching_date_from = today
        }
        if (to > today) {
          this.searchCond.reaching_date_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.reaching_date_from = to = this.searchCond.reaching_date_to
        }
      },
      // stringのリストをnumberのリストに変換
      stringListToNumberList (list) {
        var resultList = []
        for (let tmp of list) {
          resultList.push(Number(tmp))
        }
        return resultList
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
