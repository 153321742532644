<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <!-- 職種 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <div>
              <label>{{labels.office}}</label>
              <div>
                <template v-if="officeCodeErrorMsg!==''">
                  <small class="text-danger">
                    <!-- エラーメッセージ -->
                    {{ officeCodeErrorMsg }}
                  </small>
                  <br>
                </template>
                <el-select class="select-success width300"
                            size="large"
                            placeholder=""
                            v-model="office_code">
                  <el-option v-for="office in comboData.office_list"
                              class="select-success"
                              :value="office.value"
                              :label="office.label"
                              :key="office.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <br>
          <!-- 事業所 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <div>
              <label>{{labels.business}}</label>
              <div>
                <template v-if="businessTypeErrorMsg!==''">
                  <small class="text-danger">
                    <!-- エラーメッセージ -->
                    {{ businessTypeErrorMsg }}
                  </small>
                  <br>
                </template>
                <el-select class="select-success width300"
                            size="large"
                            placeholder=""
                            v-model="business_type">
                  <el-option v-for="business in comboData.business_list"
                              class="select-success"
                              :value="business.value"
                              :label="business.label"
                              :key="business.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 検索ボタン -->
          <template>
            <div style="text-align: left;">
              <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </div>
          </template>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="search_flg">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('button.addMonitoringConditions')}}
          </h4>
        </div>
        <div :style="showSearchCondition ? 'overflow-y: auto; height: 390px;' : 'overflow-y: auto; height: 630px;'">
          <div class="card-content" ref="frame">
            <div class="card-content" ref="frame">
              <h5><b>{{$t('label.monthly_check_items')}}</b></h5>
              <br>
              <div v-for="contents in (labels.limit_legal_one_month_minutes.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_legal_one_month_minutes_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_legal_one_month_minutes_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_legal_one_month_minutes_select === '1'">
                  <input type="text" v-model="limit_legal_one_month_minutes" :placeholder="$t('placeholder.legal_time')" maxlength="3" class="form-control numLength3 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_legal_one_month_minutes_select === '2'">
                  <input type="text" v-model="limit_legal_one_month_minutes_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.limit_legal_one_month_minutes_all.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_legal_one_month_minutes_all_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_legal_one_month_minutes_all_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_legal_one_month_minutes_all_select === '1'">
                  <input type="text" v-model="limit_legal_one_month_minutes_all" :placeholder="$t('placeholder.legal_time')" maxlength="3" class="form-control numLength3 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_legal_one_month_minutes_all_select === '2'">
                  <input type="text" v-model="limit_legal_one_month_minutes_all_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.limit_month_over_industrial_safety_health_act.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_month_over_industrial_safety_health_act_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_month_over_industrial_safety_health_act_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_month_over_industrial_safety_health_act_select === '1'">
                  <input type="text" v-model="limit_month_over_industrial_safety_health_act" :placeholder="$t('placeholder.legal_time')" maxlength="3" class="form-control numLength3 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_month_over_industrial_safety_health_act_select === '2'">
                  <input type="text" v-model="limit_month_over_industrial_safety_health_act_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.limit_special_provisions_legal_one_month_minutes_all.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_special_provisions_legal_one_month_minutes_all_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_special_provisions_legal_one_month_minutes_all_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_special_provisions_legal_one_month_minutes_all_select === '1'">
                  <input type="text" v-model="limit_special_provisions_legal_one_month_minutes_all" :placeholder="$t('placeholder.legal_time')" maxlength="4" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_special_provisions_legal_one_month_minutes_all_select === '2'">
                  <input type="text" v-model="limit_special_provisions_legal_one_month_minutes_all_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
            </div>
            <div class="card-content" ref="frame">
              <h5><b>{{$t('label.year_check_items')}}</b></h5>
              <br>
              <div v-for="contents in (labels.limit_legal_one_year_minutes.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_legal_one_year_minutes_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_legal_one_year_minutes_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_legal_one_year_minutes_select === '1'">
                  <input type="text" v-model="limit_legal_one_year_minutes" :placeholder="$t('placeholder.legal_time')" maxlength="4" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_legal_one_year_minutes_select === '2'">
                  <input type="text" v-model="limit_legal_one_year_minutes_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.limit_job_one_year_minutes.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="limit_job_one_year_minutes_select" label="1" :inline="true">{{$t('label.set_by_time')}}</p-radio>
                  <p-radio v-model="limit_job_one_year_minutes_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="limit_job_one_year_minutes_select === '1'">
                  <input type="text" v-model="limit_job_one_year_minutes" :placeholder="$t('placeholder.legal_time')" maxlength="4" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_hours')}}
                  </div>
                </template>
                <template v-if="limit_job_one_year_minutes_select === '2'">
                  <input type="text" v-model="limit_job_one_year_minutes_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.number_of_legal_holidays_allowed_to_work.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="number_of_legal_holidays_allowed_to_work_select" label="1" :inline="true">{{$t('label.set_by_date')}}</p-radio>
                  <p-radio v-model="number_of_legal_holidays_allowed_to_work_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="number_of_legal_holidays_allowed_to_work_select === '1'">
                  <input type="text" v-model="number_of_legal_holidays_allowed_to_work" :placeholder="$t('placeholder.legal_day')" maxlength="4" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_days')}}
                  </div>
                </template>
                <template v-if="number_of_legal_holidays_allowed_to_work_select === '2'">
                  <input type="text" v-model="number_of_legal_holidays_allowed_to_work_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
              <br>
              <div v-for="contents in (labels.special_provisions_year_count.split('　'))" :key="contents">
                {{contents}}
              </div>
              <div style="display: flex">
                <div style="display: inline-block; padding-top: 8px;">
                  <p-radio v-model="special_provisions_year_count_select" label="1" :inline="true">{{$t('label.set_by_times')}}</p-radio>
                  <p-radio v-model="special_provisions_year_count_select" label="2" :inline="true">{{$t('label.set_by_ratio')}}</p-radio>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <template v-if="special_provisions_year_count_select === '1'">
                  <input type="text" v-model="special_provisions_year_count" :placeholder="$t('placeholder.legal_day')" maxlength="4" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_number')}}
                  </div>
                </template>
                <template v-if="special_provisions_year_count_select === '2'">
                  <input type="text" v-model="special_provisions_year_count_rate" :placeholder="$t('placeholder.legal_percent')" maxlength="5" class="form-control numLength4 number">
                  <div style="position: relative; top: 10px; left: 5px;">
                    {{$t('label.warning_after_percent')}}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-info" @click="submit">
        {{$t('button.update')}}
      </button>
    </template>
  </div>
</template>
<script>
  import LegalRuleSurveillanceConditionMixin from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceConditionMixin'

  export default {
    name: 'LegalRuleSurveillance',
    mixins: [LegalRuleSurveillanceConditionMixin]
  }
</script>
<style scoped>
.card .category {
  color: #000000;
}
blockquote {
  position: relative;
  left: -40px;
}
</style>
