<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.work_type_name"
              item-name="work_type_name"
              max-length="50"
              classes="form-control length100"
              rules="required"
              :item-label="labels.work_type_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.work_type_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import WorkTypeDetailMixin from '@/components/Dashboard/Views/Generated/WorkTypeDetailMixin'

  export default {
    name: 'WorkTypeDetail',
    methods: {
      convertDetail (detail) {
        if (this.mode === this.modes.add) {
          delete detail.work_type
        } else if (this.mode === this.modes.delete) {
          delete detail.work_type_name
        }
        return detail
      }
    },
    mixins: [WorkTypeDetailMixin]
  }
</script>
<style scoped>
</style>
