<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <div class="row">
        <div class="col-sm-6" style="margin-bottom: 5px;">
          <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="card">
            <div class="card-header" style="padding-top: 0;">
              <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
            </div>
            <div class="card-content">
              <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
              <ValidationObserver ref="observer">
                <div class="row">
                  <div class="col-md-6">
                    <!-- 健康保険一般保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.health_insurance_salary_general_insurance_rate"
                        item-name="health_insurance_salary_general_insurance_rate"
                        integral="2"
                        decimal="3"
                        rules="required|decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="labels.general_health_insurance.replace('　', '')"
                        :placeholder="isReadOnly ? '' : '1.000'"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_length_decimal')"
                        data-v-step="0"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>

                    <!-- 健康保険調整保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.health_insurance_salary_adjusted_insurance_rate"
                        item-name="health_insurance_salary_adjusted_insurance_rate"
                        integral="2"
                        decimal="3"
                        rules="required|decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="labels.health_insurance_adjustment.replace('　', '')"
                        :placeholder="isReadOnly ? '' :  '1.000'"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_length_decimal')"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>

                    <!-- 健康保険合計料率 -->
                    <div class="form-group" style="display: inline-block">
                      <label>{{$t('label.health_insurance_salary_total_rate')}}</label>
                      <br />
                      <br />
                      <input
                        type="text"
                        class="form-control length10"
                        :value="getHealth_insurance_salary_total_rate()"
                        disabled="disabled"
                        style="text-align: right;"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">&nbsp;/1000&nbsp;</div>
                    <br />
                    <!-- 介護保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.care_insurance_salary_fee_rate"
                        item-name="care_insurance_salary_fee_rate"
                        integral="2"
                        decimal="3"
                        rules="required|decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="labels.care_insurance + labels.rate"
                        :placeholder="isReadOnly ? '' :  '1.000'"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_length_decimal')"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>
                  </div>
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-12">
                    <hr style="border: 1px solid #ddd;"/>
                  </div>
                  <div class="col-md-6">
                    <!-- 厚生年金保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.welfare_pension_salary_insurance_rate"
                        item-name="welfare_pension_salary_insurance_rate"
                        integral="2"
                        decimal="3"
                        rules="required|decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="$t('label.welfare_pension_salary_insurance_rate')"
                        :placeholder="isReadOnly ? '' :  '1.000'"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_length_decimal')"
                        data-v-step="2"
                        @blur="calcEmployeePensionFundInsuranceRate"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>
                    <br/>
                    <!-- 厚生年金基金保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.employees_pension_fund_insurance_rate"
                        item-name="employees_pension_fund_insurance_rate"
                        integral="2"
                        decimal="3"
                        rules="decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="labels.employees_pension_fund_insurance_rate"
                        :placeholder="isReadOnly ? '' :  '1.000'"
                        :disabled="true"
                        :help-text="'　'"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>
                    <!-- 免税保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.duty_free_premium_rate"
                        item-name="duty_free_premium_rate"
                        integral="2"
                        decimal="1"
                        rules="required|decimal|min_value:0.0|max_value:50.0"
                        classes="form-control length10"
                        :item-label="labels.duty_free_premium_rate"
                        :placeholder="isReadOnly ? '' :  '50.0'"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_length_decimal')"
                        @blur="calcEmployeePensionFundInsuranceRate"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>
                  </div>
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-12">
                    <hr style="border: 1px solid #ddd;"/>
                  </div>
                  <div class="col-md-6">
                    <!-- 雇用保険料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.employment_insurance_rate"
                        item-name="employment_insurance_rate"
                        integral="10"
                        rules="required|decimal|min_value:1|max_value:9"
                        classes="form-control length10"
                        :item-label="labels.employment_insurance_rate"
                        :placeholder="isReadOnly ? '' :  '1'"
                        :disabled="true"
                        data-v-step="3"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>
                    <!-- 事業の種類 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationSelect
                        v-model="detail.type_of_business"
                        :item-label="labels.type_of_business"
                        :options="comboData.type_of_business_list"
                        :disabled="false"
                        item-name="type_of_business"
                        rules="required"
                        classes="length20"
                        @change="typeOfBusinessChanged"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- 労災料率 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationTextNumber
                        v-model="detail.workers_accident_compensation_rate"
                        item-name="workers_accident_compensation_rate"
                        integral="10"
                        rules="required|decimal|min_value:0.000|max_value:99.999"
                        classes="form-control length10"
                        :item-label="labels.workers_accident_compensation_rate"
                        :placeholder="isReadOnly ? '' :  '1.000'"
                        :disabled="true"
                        data-v-step="4"
                      />
                    </div>
                    <div class="form-group" style="display: inline-block">/1000&nbsp;</div>

                    <!-- 事業の種類の番号 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationSearchSelect
                        v-model="detail.business_type_number"
                        :item-label="labels.business_type_number"
                        :options="comboData.workers_accident_compensation_insurance_list"
                        :disabled="false"
                        item-name="business_type_number"
                        rules="required"
                        classes="length40"
                        @change="businessTypeNumberChanged"
                      />
                    </div>
                    <br />
                    <!-- 源泉徴収税額の計算方法 -->
                    <div class="form-group" style="display: inline-block">
                      <ValidationSelect
                        v-model="detail.calculate_withholding_tax"
                        :item-label="labels.calculate_withholding_tax"
                        :options="comboData.calculate_withholding_tax_list"
                        :disabled="false"
                        item-name="calculate_withholding_tax"
                        rules="required"
                        classes="length20"
                        data-v-step="5"
                      />
                    </div>
                  </div>
                </div>
                <Tour tour-name="myTour" :steps="steps"/>
              </ValidationObserver>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <label>{{$t('label.insurance_nursing_choice')}}</label>
              <br />
              <label>{{labels.health_insurance_nursing_care_fraction}}</label>
              <div class="form-group">
                <template v-for="item of comboData.health_insurance_nursing_care_fraction_list">
                  <p-radio
                    v-bind:key="item.value"
                    v-model="detail.health_insurance_nursing_care_fraction"
                    :label="item.value"
                    :inline="true"
                    :disabled="isReadOnly"
                  >{{item.label}}</p-radio>
                </template>
              </div>
              <label>{{labels.welfare_pension_fraction_classification}}</label>
              <div class="form-group">
                <template v-for="item of comboData.welfare_pension_fraction_classification_list">
                  <p-radio
                    v-bind:key="item.value"
                    v-model="detail.welfare_pension_fraction_classification"
                    :label="item.value"
                    :inline="true"
                    :disabled="isReadOnly"
                  >{{item.label}}</p-radio>
                </template>
              </div>
              <label>{{labels.employment_insurance_fraction_classification}}</label>
              <div class="form-group">
                <template v-for="item of comboData.employment_insurance_fraction_classification_list">
                  <p-radio
                    v-bind:key="item.value"
                    v-model="detail.employment_insurance_fraction_classification"
                    :label="item.value"
                    :inline="true"
                    :disabled="isReadOnly"
                  >{{item.label}}</p-radio>
                </template>
              </div>
            </div>
            <div class="card-footer">
              <template v-if="!this.isChanged">
                <div style="text-align: left;">
                  <button
                    type="button"
                    class="btn btn-sm btn-default btn-info"
                    @click="doUpdate"
                  >{{$t('button.update')}}</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import RateViewMixin from '@/components/Dashboard/Views/Master/RateViewMixin'
  export default {
    mixins: [RateViewMixin]
  }
</script>
<style scoped>
</style>

