<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.group_code"
              item-name="group_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.group_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.group_code')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <ValidationText
              v-model="detail.group_name"
              item-name="group_name"
              max-length="50"
              classes="form-control length100"
              rules="required"
              :item-label="labels.group_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.group_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="1"
            />
            <ValidationDate
              v-model="detail.term_from"
              item-name="term_from"
              rules="required"
              :item-label="labels.term_from"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
              :disabled="isReadOnly"
              data-v-step="2"
            />
            <ValidationDate
              v-model="detail.term_to"
              item-name="term_to"
              :item-label="labels.term_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_to')"
              :disabled="isReadOnly"
              :picker-options="futureday"
              v-show="mode==modes.edit || mode==modes.view || mode==modes.delete"
            />
            <ValidationSearchSelect
              v-model="detail.upper_group_code"
              item-name="upper_group_code"
              classes="length60"
              :item-label="labels.upper_group_name"
              :options="comboData.upper_group_list"
              :disabled="isReadOnly"
              @change="upperGroupCodeChanged"
            />
            <ValidationSelect
              v-model="detail.range"
              item-name="range"
              :placeholder="$t('label.select_description')"
              classes="length4"
              rules="required"
              :item-label="labels.range"
              :options="comboData.range_list"
              :disabled="isReadOnly"
              @change="rangeChanged"
              data-v-step="3"
            />
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in groupApplicationListErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <ValidationApplicationManagement
              v-model="detail.group_application_list"
              classes="select-success length24"
              :item-label1="labels.applicationForm"
              :item-label2="labels.office_application_control ? labels.office_application_control : labels.group_application_control ? labels.group_application_control : ''"
              :disabled="isReadOnly"
              :options="comboData.group_application_control_list"
              ref="applicationControl"
              @copyApplicationControl="copyApplicationControl"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import GroupDetailMixin from '@/components/Dashboard/Views/Generated/GroupDetailMixin'
  import swal from 'sweetalert2'

  export default {
    name: 'GroupDetail',
    data () {
      return {
        groupApplicationControlListWithUpper: [],
        groupApplicationControlListWithoutUpper: []
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.upper_group_list = []
        for (const row of res.common_area.combo_data.group_list) {
          if (row.value !== this.detail.group_code) {
            this.comboData.upper_group_list.push(row)
          }
        }
        this.comboData.upper_group_list.unshift({label: '　', value: ''})
        this.comboData.range_list = this.getLabelList(res.common_area.combo_data.range)
        if (this.mode === this.modes.add) {
          this.detail.range = '1'
        }
        this.labels.upper_group_name = this.$t('label.upper_group').replace('{item}', this.labels.group)
        if (this.mode === this.modes.add) {
          const groupApplicationList = []
          for (const row of res.common_area.combo_data.application_form_list) {
            const element = {
              application_form_code: row.value,
              application_form_name: row.label,
              application_control: '3'
            }
            groupApplicationList.push(element)
          }
          this.detail.group_application_list = groupApplicationList
          if (this.params.upper_group_code) {
            this.detail.upper_group_code = this.params.upper_group_code
          }
        } else {
          const groupApplicationList = []
          for (const row of this.detail.group_application_list) {
            for (const application of res.common_area.combo_data.application_form_list) {
              if (row.application_form_code === application.value) {
                const element = {
                  application_form_code: application.value,
                  application_form_name: application.label,
                  application_control: String(row.group_application_control)
                }
                groupApplicationList.push(element)
                break
              }
            }
          }
          this.detail.group_application_list = groupApplicationList
        }
        this.groupApplicationControlListWithUpper = [...this.comboData.group_application_control_list]
        this.groupApplicationControlListWithoutUpper = [...this.comboData.group_application_control_list]
        this.groupApplicationControlListWithoutUpper.splice(3, 1)
        this.upperGroupCodeChanged()
      },
      upperGroupCodeChanged () {
        if (this.detail.upper_group_code) {
          this.comboData.group_application_control_list = this.groupApplicationControlListWithUpper
        } else {
          this.comboData.group_application_control_list = this.groupApplicationControlListWithoutUpper
        }
      },
      copyApplicationControl () {
        this.groupApplicationListErrors = []
        if (!this.detail.upper_group_code && this.$refs.applicationControl.copyFrom === '4') {
          this.groupApplicationListErrors.push(this.$t('message.upper_group_error'))
        } else {
          this.$refs.applicationControl.copyApplicationControl()
        }
      },
      customValidate () {
        if (this.detail.upper_group_code) return true
        this.groupApplicationListErrors = []
        for (const row of this.detail.group_application_list) {
          if (row.application_control === '4') {
            this.groupApplicationListErrors.push(this.$t('message.upper_group_error'))
            return false
          }
        }
        return true
      },
      doAdd () {
        if (this.detail.range === '2') {
          swal({
            text: this.$t('message.confirm_to_select_all_authority'),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            this.submit('insert')
          }).catch(() => {})
        } else {
          this.submit('insert')
        }
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.range = parseInt(detail.range, 10)
          const groupApplicationList = []
          for (const row of detail.group_application_list) {
            const element = {
              group_application_control: parseInt(row.application_control, 10),
              application_form_code: row.application_form_code
            }
            groupApplicationList.push(element)
          }
          detail.group_application_list = groupApplicationList
          if (this.mode === this.modes.add) {
            delete detail.update_count
          }
          if (detail.upper_group_code === '') {
            detail.upper_group_code = null
          }
        }
        return detail
      }
    },
    mixins: [GroupDetailMixin]
  }
</script>
<style scoped>
</style>
