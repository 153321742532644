<template>
  <div class="card">
    <div class="card-header" v-if="errMsgs.length > 0">
      <small style="color: #C84513; margin-left: 5px;">{{errMsgs[0]}}</small>
    </div>
    <div class="card-content" :key="componentKey">
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.target_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.target_date"
            item-name="target_date"
            rules="required"
            :itemLabel="labels.target_date"
            placeholder="2020-01-01"
            :picker-options = "pickerOptions"
            :disabled="isReadOnly && isModal"
            @input="$emit('initApply', application.target_date, application.work_schedule_code)"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <ValidationSelect
          v-model="application.work_schedule_code"
          item-name="work_schedule_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          :item-label="labels.workSchedule"
          :options="application.work_schedule_list"
          :disabled="(isReadOnly || !application.target_date) && isModal"
          @change="$emit('initApply', application.target_date, application.work_schedule_code)"
        />
      </div>
      <div class="form-group" style="margin-bottom: 0;" v-show="!isReadOnly">
        <div class="length5" style="display: inline-block;">
          <label>&nbsp;</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationCheckboxes
            v-model="applyItems"
            :disabled="isReadOnly"
            @change="applyItemsChanged"
          />
        </div>
      </div>
      <div class="form-group" v-if="false">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.actual')}}</label>
        </div>
        <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_start_end_time">
          {{application.actual_start_end_time}}
        </div>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="$emit('showActual')">
          {{$t('button.show_actual')}}
        </button>        
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.imprint_correction_time')}}</label>
        </div>
        <template v-if="!isReadOnly || applyItems.items[0].value">
          <span>{{$t('label.job_start')}}&nbsp;&nbsp;</span>
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || !applyItems.items[0].value"
                      v-model="application.start_hours">
            <el-option v-for="hour in hours"
                        class="select-success"
                        :value="hour"
                        :label="hour"
                        :key="hour">
            </el-option>
          </el-select>&nbsp;{{$t('label.hour')}}&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || !applyItems.items[0].value"
                      v-model="application.start_minutes">
            <el-option v-for="minute in minutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
          <span>&nbsp;/&nbsp;&nbsp;</span>
        </template>
        <template v-if="!isReadOnly || applyItems.items[1].value">
          <span>{{$t('label.job_end')}}&nbsp;&nbsp;</span>
          <input type="checkbox" v-model="application.next_day" class="gray" id="nextDay" :disabled="isReadOnly || !applyItems.items[1].value">
          <label for="nextDay">{{$t('label.next_day')}}</label>&nbsp;&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || !applyItems.items[1].value"
                      v-model="application.end_hours">
            <el-option v-for="hour in hours"
                        class="select-success"
                        :value="hour"
                        :label="hour"
                        :key="hour">
            </el-option>
          </el-select>&nbsp;{{$t('label.hour')}}&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || !applyItems.items[1].value"
                      v-model="application.end_minutes">
            <el-option v-for="minute in minutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
        </template>
        <template v-if="!isReadOnly || applyItems.items[2].value">
          <input type="checkbox" v-model="application.telework_flg" class="gray" id="telework" :disabled="isReadOnly || !applyItems.items[2].value">
          <label for="telework">{{labels.telework_flg}}</label>
        </template>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.before_imprint_correction_time')}}</label>
        </div>
        <span>{{$t('label.job_start')}}&nbsp;&nbsp;</span>
        <template v-if="application.before_start_hours">
          <span>{{ application.before_start_hours }}</span>&nbsp;{{$t('label.hour')}}&nbsp;
          <span>{{ application.before_start_minutes }}</span>&nbsp;{{$t('label.minute')}}&nbsp;
        </template>
        <span>&nbsp;/&nbsp;&nbsp;{{$t('label.job_end')}}&nbsp;&nbsp;</span>
        <template v-if="application.before_end_hours">
          <span>{{ application.before_end_hours }}</span>&nbsp;{{$t('label.hour')}}&nbsp;
          <span>{{ application.before_end_minutes }}</span>&nbsp;{{$t('label.minute')}}&nbsp;
        </template>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{$t('label.imprint_correction_break_time')}}</label>
        </div>
        <div style="display: inline-block;">
          <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="!application.target_date" @click="addStartEndTextRow" v-show="!isReadOnly">{{$t('button.add_row')}}</button>
          <div style="color: #C84513; margin-bottom: 5px;" v-for="error in application.breakTimeListErrors" v-bind:key="error">
            <small>{{error}}</small>
          </div>
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"            border
            :data="application.break_time_list"
            :empty-text="$t('label.no_data')"
            style="width: 380px">
            <el-table-column
              width="60"
              :label="$t('label.delete')"
              align="center">
              <template slot-scope="props">
                <div style="text-align: center;">
                  <input type="checkbox" v-model="props.row.del_flag" class="gray" :disabled="isReadOnly"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              :label="labels.break_time_list ? labels.break_time_list : $t('label.imprint_correction_break_time')"
              align="center">
              <template slot-scope="props">
                <div style="text-align: left;">
                  <input
                    type="text"
                    class="input-text"
                    style="padding-right: 0.3em; width: 50px; text-align: right;"
                    :disabled="isReadOnly"
                    maxlength="5"
                    :placeholder="isReadOnly ? '' : $t('placeholder.break_time_start')"
                    v-model="props.row.start_time">
                  <label>～</label>
                  <input
                    type="text"
                    class="input-text"
                    style="padding-right: 0.3em; width: 50px; text-align: right;"
                    :disabled="isReadOnly"
                    maxlength="5"
                    :placeholder="isReadOnly ? '' : $t('placeholder.break_time_end')"
                    v-model="props.row.end_time">
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              :label="labels.break_time_list ? labels.break_time_list : $t('label.before_imprint_correction_break_time')"
              align="center">
              <template slot-scope="props">
                <div style="text-align: left;" v-show="props.row.re_start_time">
                  <label>{{props.row.re_start_time}}</label>
                  <label>～</label>
                  <label>{{props.row.re_end_time}}</label>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.imprint_correction_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.imprint_correction_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_content')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate,
      ValidationRadio,
      ValidationCheckboxes
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        hours: [
          '', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
        ],
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now()
          }
        },
        minutes: ['',
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        application: {},
        applyItems: {},
        breakTimeListErrors: []
      }
    },
    methods: {
      applyItemsChanged (obj) {
        for (const item of obj.items) {
          if (item.id === 1 && !item.value) {
            this.application.start_hours = ''
            this.application.start_minutes = ''
          } else if (item.id === 2 && !item.value) {
            this.application.next_day = false
            this.application.end_hours = ''
            this.application.end_minutes = ''
          } else if (item.id === 3 && !item.value) {
            this.application.telework_flg = this.application.org_telework_flg
          }
        }
      },
      addStartEndTextRow () {
        let lineNumber = 1
        for (const row of this.application.break_time_list) {
          if (lineNumber < row.line_number + 1) {
            lineNumber = row.line_number + 1
          }
        }
        const startEndTextRow = {
          del_flag: false,
          line_number: lineNumber,
          start_time: '',
          end_time: '',
          re_start_time: '',
          re_end_time: ''
        }
        this.application.break_time_list.push(startEndTextRow)
      }
    },
    watch: {
      value () {
        this.application = this.value
        this.application.apply_items = this.applyItems
        if (!this.applyItems.items[0].value && !this.applyItems.items[1].value && !this.applyItems.items[2].value) {
          if (this.application.imprint_correction_type === '1') {
            this.applyItems.items[0].value = true
          } else if (this.application.imprint_correction_type === '2') {
            this.applyItems.items[1].value = true
          } else if (this.application.imprint_correction_type === '3') {
            this.applyItems.items[2].value = true
          }
        }
      }
    },
    created () {
      this.applyItems = {
        color: 'gray',
        disabled: false,
        items: [
          {
            id: 1,
            label: this.$t('label.stamping_start_time_correction'),
            value: false
          },
          {
            id: 2,
            label: this.$t('label.stamping_end_time_correction'),
            value: false
          },
          {
            id: 3,
            label: this.$t('label.telework_flg_correction'),
            value: false
          }
        ]
      }
    },
    mounted () {
      this.application = this.value
      this.application.apply_items = this.applyItems
      if (this.application.imprint_correction_type === '1' || this.application.stamping_start_time_change_flg) {
        this.applyItems.items[0].value = true
      }
      if (this.application.imprint_correction_type === '2' || this.application.stamping_end_time_change_flg) {
        this.applyItems.items[1].value = true
      }
      if (this.application.imprint_correction_type === '3' || this.application.telework_change_flg) {
        this.applyItems.items[2].value = true
      }
    }
  }
</script>
<style scoped>
  input::placeholder {
    color: #ccc;
  }
</style>
