<template>
  <div class="form-group">
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" :bails="false" v-slot="{ valid, errors }" :rules="rules" :name="labels.months_of_service + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <div v-for="error in errors" v-bind:key="error">
            <small class="text-danger">
              <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
              {{ getMessage(error).replace('{item3}', labels.authority).replace('{item1}', labels.role).replace('{item2}', $t('label.approver')).replace('{item4}', $t('label.approval_route')) }}
            </small>
          </div>
        </div>
      </div>
      <ApprovalRoute
        v-model="value"
        :disabled="disabled"
        :combo-data="comboData"
        :labels="labels"
        @processing="processing"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import ApprovalRoute from '@/components/UIComponents/Inputs/ApprovalRoute'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ApprovalRoute
    },
    props: {
      rules: String,
      value: Array,
      comboData: Object,
      labels: Object,
      disabled: Boolean
    },
    methods: {
      processing (isProcessing) {
        this.$emit('processing', isProcessing)
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>