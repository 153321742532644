<template>
  <div>
    <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :width="1200" :pivotX="0.5">
      <div class="modal-body">
        <EmployeeUpdate :employee-info="employeeInfo" :combo-data="comboData" @close="hideEmployee"/>
      </div>
    </modal>
    <h5 class="text-center">従業員情報を設定してください</h5>
    <p class="text-center">
      現在のアカウント数は、{{totalEmployees}}名です。月額利用料は、{{formatNumber(totalAmount)}}円となります。もし移行する必要がない従業員がいれば、この画面にて選択してください。
      <br>
      ※移行対象外にされますと、選択された従業員について、Corpusからの移行は行いません。ご確認下さい。
    </p>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 510px);">
      <div class="col-md-12">
        <div style="margin-left: 20px; display: inline-block">
          <input type="checkbox" v-model="excludeRetired" class="gray" @change="onExclude" id="excludeRetired"/>
          <label for="excludeRetired">5年以上前に退職した従業員を移行対象外にする。</label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-content row">
            <div class="col-sm-12" style="margin-top: 10px;">
              <div class="card-header" style="font-size: 18px; margin-bottom: 27px;">
                <img src="static/img/corpus.png" style="height: 50px; width: auto;">
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                        :data="before"
                        style="width: 100%"
                        :key="componentKey"
                        :row-class-name="tableRowClassName">
                <el-table-column min-width="100" label="従業員">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.employee_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.employee_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="従業員区分">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.employee_classification_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.employee_classification_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="勤務体系">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.work_schedule_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.work_schedule_name}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-content row">
            <div class="col-sm-12" style="margin-top: 10px;">
              <div class="card-header" style="font-size: 18px; margin-bottom: 20px;">
                <div style="color: red;">{{error}}</div>
                <img src="static/img/LOGO_A2.png" style="height: 50px; width: auto;">
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                        :data="after"
                        style="width: 100%"
                        :key="componentKey2"
                        :row-class-name="tableRowClassName">
                <el-table-column min-width="100" label="従業員">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.employee_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.employee_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="従業員区分">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.employee_classification_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.employee_classification_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="勤務体系">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.work_schedule_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.work_schedule_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="60"
                  fixed="left"
                  label="移行対象外"
                  :key="componentKey4"
                  align="center">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="after.filter(item => !item.selected).length===1 && !props.row.selected" @change="onSelected(props.$index)"/>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :width="100"
                  fixed="left"
                  :key="componentKey3"
                  :label="$t('label.operation')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <button type="button" class="btn btn-warning btn-sm btn-default" @click="showEmployee(props.$index, props.row)" v-show="!props.row.selected">詳細</button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-12" style="margin-top: 20px;">
              <ValidationSelect
                v-model="admin"
                rules="required"
                item-label="管理者"
                :options="comboData.employees"
                :disabled="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import EmployeeUpdate from 'src/components/Dashboard/Views/Migration/WizardForm/EmployeeUpdate.vue'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    mixins: [mixin],
    components: {
      EmployeeUpdate,
      PPagination,
      ValidationText,
      ValidationDate,
      ValidationSelect,
      ValidationRadio
    },
    computed: {
      totalEmployees () {
        let totalEmployeeCount = 0
        for (let i = 0; i < this.before.length; i++) {
          if (!this.before[i].selected) {
            const employee = this.after[i]
            if (!employee.retirement_date) {
              totalEmployeeCount += 1
            }
          }
        }
        return totalEmployeeCount
      },
      totalAmount () {
        return this.totalEmployees < 10 ? 5000 : this.totalEmployees * 500
      },
      pagedBeforeData () {
        return this.before.slice(this.from, this.to)
      },
      pagedAfterData () {
        return this.after.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedBeforeData () {
        if (!this.searchQuery) {
          this.pagination.total = this.before.length
          return this.pagedBeforeData
        }
        let result = this.before
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      queriedAfterData () {
        if (!this.searchQuery) {
          this.pagination.total = this.after.length
          return this.pagedAfterData
        }
        let result = this.after
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.after.length
        return this.after.length
      }
    },
    data () {
      return {
        excludeRetired: false,
        error: '',
        componentKey: 0,
        componentKey2: 1000,
        componentKey3: 2000,
        componentKey4: 3000,
        selectedIndex: 0,
        labels: {
          label_search_total: '{total}件中{from}件目から{to}件目までを表示'
        },
        before: [],
        after: [],
        admin: '',
        employeeInfo: {},
        comboData: {},
        pagination: {
          perPageOptions: [25, 50, 100],
          currentPage: 1,
          perPage: 50,
          total: 15
        },
        searchQuery: '',
        propsToSearch: []
      }
    },
    methods: {
      onExclude () {
        for (let i = 0; i < this.after.length; i++) {
          const employee = this.after[i]
          if (employee.retirement_date) {
            const years = this.calcYears(employee.retirement_date, this.formatToday())
            if (years >= 5) {
              employee.selected = this.excludeRetired
              this.onSelected(i)
            }
          }
        }
        this.componentKey4 += 1
      },
      onSelected (index) {
        this.before[index].selected = this.after[index].selected
        this.refreshEmployees()
        this.componentKey3 += 1
      },
      refreshEmployees () {
        let adminExist = false
        const employees = []
        for (let i = 0; i < this.after.length; i++) {
          if (!this.before[i].selected) {
            const employee = this.after[i]
            if (!employee.retirement_date) {
              if (this.admin === employee.employee_code) adminExist = true
              let element = {
                label: employee.employee_name,
                value: employee.employee_code
              }
              employees.push(element)
            }
          }
        }
        this.comboData.employees = employees
        if (!adminExist) this.admin = ''
      },
      tableRowClassName ({row, rowIndex}) {
        if (row.isSaved === false && this.before[rowIndex].selected === false) {
          return 'unsaved-row'
        }
        return ''
      },
      showEmployee (index, row) {
        this.selectedIndex = index
        this.employeeInfo = {
          before: this.before[index],
          after: row
        }
        this.$modal.show('employee')
      },
      hideEmployee (isSaved, employee) {
        this.$modal.hide('employee')
        if (isSaved) {
          this.error = ''
          this.after[this.from + this.selectedIndex].isSaved = true
          this.after[this.from + this.selectedIndex].birthday = employee.birthday
          this.after[this.from + this.selectedIndex].employee_classification_code = employee.employee_classification_code
          this.after[this.from + this.selectedIndex].employee_classification_name = employee.employee_classification_name
          this.after[this.from + this.selectedIndex].my_number = employee.my_number
          this.after[this.from + this.selectedIndex].work_schedule_code = employee.work_schedule_code
          this.after[this.from + this.selectedIndex].work_schedule_name = employee.work_schedule_name
          this.after[this.from + this.selectedIndex].salary_closing_code = employee.salary_closing_code
          this.after[this.from + this.selectedIndex].post_code = employee.post_code
          this.after[this.from + this.selectedIndex].append_path = employee.append_path
          this.after[this.from + this.selectedIndex].post_code = employee.post_code
          this.after[this.from + this.selectedIndex].prefectures = employee.prefectures
          this.after[this.from + this.selectedIndex].city = employee.city
          this.after[this.from + this.selectedIndex].town = employee.town
          this.after[this.from + this.selectedIndex].building = employee.building
          this.after[this.from + this.selectedIndex].dependent_detail = employee.dependent_detail
          this.componentKey += 1
          this.componentKey2 += 1
          this.componentKey3 += 1
        }
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        this.before = migrationData.employee_info.before
        this.after = migrationData.employee_info.after
        this.comboData = migrationData.combo_data.comboData
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_employee/list', params)
        .then(res => {
          this.before = res.init_data.before_data.employee_list
          this.after = res.init_data.after_data.employee_list
          const employees = []
          for (let i = 0; i < this.after.length; i++) {
            if (!this.before[i].selected) {
              const employee = this.after[i]
              if (!employee.retirement_date) {
                let element = {
                  label: employee.employee_name,
                  value: employee.employee_code
                }
                employees.push(element)
              }
            }
          }
          res.common_init_data.combo_data.employees = employees
          this.comboData = res.common_init_data.combo_data
          for (const employee of this.before) {
            employee.selected = false
            for (const classification of res.common_init_data.combo_data.employee_classification_list[0]) {
              if (classification.value === employee.employee_classification_code) {
                employee.employee_classification_name = classification.label
                break
              }
            }
            for (const workingInfo of res.common_init_data.combo_data.working_info_list[0]) {
              if (workingInfo.value === employee.work_schedule_code) {
                employee.work_schedule_name = workingInfo.label
                break
              }
            }
            for (const row of res.common_init_data.combo_data.state_municipality_mapping_list[0].state_list) {
              if (row.value === employee.state_code) {
                employee.prefectures = row.label
                break
              }
            }
            for (const row of res.common_init_data.combo_data.state_municipality_mapping_list[0].municipality_list) {
              if (row.value === employee.state_code + '_' + employee.municipality_code) {
                employee.city = row.label
                break
              }
            }
          }
          for (const employee of this.after) {
            var isSaved = true
            if (employee.retirement_date) {
              // 退職者は勤務体系コードのみ必須
              if (employee.work_schedule_code === '') {
                isSaved = false
              }
            } else if (employee.payroll_management === 2) {
              // 給与管理しない場合は生年月日と給与締日は任意
              if (employee.employee_classification_code === '' || employee.work_schedule_code === '') {
                isSaved = false
              } else {
                for (const dependent of employee.dependent_detail) {
                  if (dependent.living_together === '' || dependent.dependent_relative_classification === '') {
                    isSaved = false
                    break
                  }
                }
              }
            } else {
              // 給与管理する場合は生年月日と給与締日は必須
              if (employee.employee_classification_code === '' || employee.work_schedule_code === '' || employee.birthday === '' || employee.salary_closing_code === '') {
                isSaved = false
              } else {
                for (const dependent of employee.dependent_detail) {
                  if (dependent.living_together === '' || dependent.dependent_relative_classification === '') {
                    isSaved = false
                    break
                  }
                }
              }
            }
            for (const dependent of employee.dependent_detail) {
              dependent.delete = {
                value: dependent.delete === undefined ? '2' : dependent.delete.value,
                disabled: false,
                items: [
                  {
                    id: 'NO',
                    label: 'いいえ',
                    value: '2'
                  },
                  {
                    id: 'YES',
                    label: 'はい',
                    value: '1'
                  }
                ]
              }
            }
            employee.isSaved = isSaved
            employee.isEditable = employee.work_schedule_code === ''
            employee.isSalaryClosingEditable = employee.salary_closing_code === '' && employee.payroll_management === 1
            for (const classification of res.common_init_data.combo_data.employee_classification_list[0]) {
              if (classification.value === employee.employee_classification_code) {
                employee.employee_classification_name = classification.label
                break
              }
            }
            for (const workingInfo of res.common_init_data.combo_data.working_info_list[0]) {
              if (workingInfo.value === employee.work_schedule_code) {
                employee.work_schedule_name = workingInfo.label
                break
              }
            }
            employee.prefectures = employee.state_code
            employee.city = employee.state_code + '_' + employee.municipality_code
          }
        })
      }
    }
  }
</script>
<style>
.el-table .unsaved-row {
  background-color: #ffc0cb !important;
}
</style>
