<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <!-- メッセージエリア -->
    <div class="col-md-12">
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <template v-if="year_data.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{pageTitle}}
            </h4>
          </div>
          <div class="card-content row" ref="frame">
            <div class="col-sm-12" style="margin-bottom: 20px;" v-for="data in year_data" v-bind:key="data.target_year">
              <p>{{data.target_year + $t('label.year')}}</p>
              <template v-if="data.closing_data.length!==0">
                <div style="padding-left: 20px;">
                  <p>{{$t('label.attendance')}}</p>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                            :data="data.closing_data"
                            style="width: 1280px">
                    <el-table-column width="200" :label="labels.closing_name_ryaku">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center; font-size: medium; font-weight: bold;">
                          {{props.row.name}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jan')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jan===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jan===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.feb')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.feb===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.feb===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.mar')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.mar===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.mar===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.apl')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.apl===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='release'">
                            <template v-if="roles.update===1">
                              <button type="button" v-if="props.row.closing_data.apl===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 2, props.row)">{{$t('button.release')}}</button>
                              <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 1, props.row)">{{$t('button.execute')}}</button>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.may')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.may===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.may===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jun')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jun===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jun===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jly')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jly===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jly===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.aug')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.aug===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.aug===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.sep')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.sep===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.sep===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.oct')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.oct===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.oct===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.nov')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.nov===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.nov===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.dec')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.dec===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.dec===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.yearly')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.year_is_close===1" class="btn btn-sm btn-default btn-primary" @click="yearExecute(props.row.type, data.target_year, '13', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="yearExecute(props.row.type, data.target_year, '13', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.year_is_close===2" class="btn btn-sm btn-default btn-primary" @click="yearExecute(props.row.type, data.target_year, '13', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="yearExecute(props.row.type, data.target_year, '13', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
              <template v-if="data.salary_closing_data.length!==0">
                <br>
                <div style="padding-left: 20px;">
                  <p>{{$t('label.salary')}}</p>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                            :data="data.salary_closing_data"
                            style="width: 1280px">
                    <el-table-column width="200" :label="labels.closing_name_ryaku">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center; font-size: medium; font-weight: bold;">
                          {{props.row.name}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jan')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jan===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '01', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jan===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '01', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.feb')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.feb===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '02', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.feb===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '02', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.mar')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.mar===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '03', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.mar===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '03', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.apl')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.apl===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '04', props.row.code)==='release'">
                            <template v-if="roles.update===1">
                              <button type="button" v-if="props.row.closing_data.apl===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 2, props.row)">{{$t('button.release')}}</button>
                              <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '04', 1, props.row)">{{$t('button.execute')}}</button>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.may')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.may===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '05', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.may===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '05', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jun')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jun===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '06', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jun===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '06', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.jly')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jly===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '07', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.jly===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '07', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.aug')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.aug===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '08', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.aug===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '08', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.sep')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.sep===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '09', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.sep===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '09', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.oct')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.oct===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '10', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.oct===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '10', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.nov')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.nov===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '11', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.nov===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '11', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.dec')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.dec===1" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '12', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.dec===2" class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="monthExecute(props.row.type, data.target_year, '12', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="80" :label="$t('label.yearly')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <template v-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='done'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              {{$t('label.already')}}
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='close'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.year_is_close===1" class="btn btn-sm btn-default btn-primary" @click="yearExecute(props.row.type, data.target_year, '13', 1, props.row)">{{$t('button.execute')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="yearExecute(props.row.type, data.target_year, '13', 2, props.row)">{{$t('button.release')}}</button>
                              </template>
                            </template>
                          </template>
                          <template v-else-if="getButtonName(props.row.type, data.target_year, '13', props.row.code)==='release'">
                            <template v-if="isHyphen(props.row.type)">
                              {{'-'}}
                            </template>
                            <template v-else>
                              <template v-if="roles.update===1">
                                <button type="button" v-if="props.row.closing_data.year_is_close===2" class="btn btn-sm btn-default btn-primary" @click="yearExecute(props.row.type, data.target_year, '13', 2, props.row)">{{$t('button.release')}}</button>
                                <button type="button" v-else class="btn btn-sm btn-default btn-info" @click="yearExecute(props.row.type, data.target_year, '13', 1, props.row)">{{$t('button.execute')}}</button>
                              </template>
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
    },
    data () {
      return {
        labels: {},
        year_data: [],
        closing_last: 0,
        salary_closing_last: 0,
        response_data: {},
        attendance_manage_count: 0
      }
    },
    methods: {
      isShow (type, targetYear) {
        let prev = ''
        let next = ''
        // 締め情報を回す
        for (const data of this.year_data) {
          // 対象年が同じ場合
          if (targetYear === data.target_year) {
            // 勤怠締め情報を回す
            for (const closingData of data.closing_data) {
              // typeが同じ場合
              if (type !== closingData.type) {
                next = this.getLastMonth(closingData.closing_data)
                if (prev !== '' && next !== prev) {
                  return false
                } else {
                  prev = next
                }
              }
            }
          }
        }
        return true
      },
      isHyphen (type) {
        if (type === 'closing') {
          if (this.attendance_manage_count === 0) {
            return true
          }
        }
        return false
      },
      forceUpdate (param) {
        param.api_area.is_forced = true
        // 更新API呼び出し
        this.send('/record_closing/update/month_closing', param, true, true)
        .then(res => {
          this.response_data = res
          this.setDisp(this.response_data)
        })
        .catch(error => {
          console.error(error)
        })
      },
      monthExecute (type, targetYear, targetMonth, isClose, row) {
        // APIに渡すパラメータを設定
        let param = {}
        let status = 0
        if (isClose === 1) {
          status = 2
        } else {
          status = 1
        }
        if (type === 'closing') {
          param = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              target_year: String(targetYear),
              month: targetMonth,
              closing_code: row.code,
              closing_name: row.name,
              is_close: status,
              closing_date_result_update_count: row.date_result_update_count
            }
          }
          // 更新API呼び出し
          this.send('/record_closing/update/month_closing', param, true, true)
          .then(res => {
            if (res.api_area.open_employee_list) {
              let message = this.$t('label.open_employee_list')
              for (const employee of res.api_area.open_employee_list) {
                message += '<br>' + employee.employee_code + ': ' + employee.employee_name
              }
              this.message = {text: message, correspondence_action: '', message_classification: 1}
              console.log(this.$refs.frame.parentNode.parentNode.parentNode)
              this.$refs.frame.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
              swal({
                html: `${res.api_area.message}<br>${res.api_area.correspondence_action}`,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('button.yes'),
                cancelButtonText: this.$t('button.no')
              }).then(() => {
                this.forceUpdate(param)
              }).catch(() => {})
            } else {
              this.response_data = res
              this.setDisp(this.response_data)
            }
          })
          .catch(error => {
            console.error(error)
          })
        } else {
          param = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              target_year: String(targetYear),
              month: targetMonth,
              salary_closing_code: row.code,
              salary_closing_name: row.name,
              is_close: status,
              salary_closing_date_update_count: row.date_result_update_count
            }
          }
          // 更新API呼び出し
          this.send('/record_closing/update/month_salary_closing', param, true, true)
          .then(res => {
            if (res.api_area.open_employee_list) {
              let message = this.$t('label.salary_open_employee_list')
              for (const employee of res.api_area.open_employee_list) {
                message += '<br>' + employee.employee_code + ': ' + employee.employee_name
              }
              this.message = {text: message, correspondence_action: '', message_classification: 1}
              console.log(this.$refs.frame.parentNode.parentNode.parentNode)
              this.$refs.frame.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
            } else {
              this.response_data = res
              this.setDisp(this.response_data)
            }
          })
          .catch(error => {
            console.error(error)
          })
        }
      },
      yearExecute (type, targetYear, targetMonth, isClose, row) {
        // APIに渡すパラメータを設定
        let param = {}
        let status = 0
        if (isClose === 1) {
          status = 2
        } else {
          status = 1
        }
        if (type === 'closing') {
          param = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              target_year: String(targetYear),
              closing_code: row.code,
              closing_name: row.name,
              is_close: status,
              closing_year_result_update_count: row.year_result_update_count
            }
          }
          // 更新API呼び出し
          this.send('/record_closing/update/year_closing', param)
          .then(res => {
            this.response_data = res
            this.setDisp(this.response_data)
          })
          .catch(error => {
            console.error(error)
          })
        } else {
          param = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              target_year: String(targetYear),
              salary_closing_code: row.code,
              salary_closing_name: row.name,
              is_close: status,
              salary_closing_year_update_count: row.year_result_update_count
            }
          }
          // 検索API呼び出し
          this.send('/record_closing/update/year_salary_closing', param)
          .then(res => {
            this.response_data = res
            this.setDisp(this.response_data)
          })
          .catch(error => {
            console.error(error)
          })
        }
      },
      init () {
        // 初期表示
        // APIに渡すパラメータを設定
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {}
        }
        // 初期化API呼び出し
        this.send('/record_closing/init/start/list', params)
        .then(res => {
          // ラベル情報をthis.labelsに設定
          this.labels = this.getLabels(res.common_area)
          // ボタン権限をthis.rolesに設定
          this.roles = res.common_area.button_role
          // 検索結果
          this.response_data = res
          this.setDisp(this.response_data)
          console.log(this.year_data)
        })
      },
      setDisp (res) {
        this.attendance_manage_count = res.api_area.attendance_manage_count
        this.closing_last = 0
        this.salary_closing_last = 0
        let yearData = res.api_area.year_data
        this.year_data = []
        this.closing_last = {}
        this.salary_closing_last = {}
        for (const data of yearData) {
          let oneYear = {
            target_year: data.target_year,
            closing_data: [],
            salary_closing_data: []
          }
          if (data.closing_data !== undefined && data.closing_data !== null) {
            for (const closingData of data.closing_data) {
              let oneRecord = {
                type: 'closing',
                code: closingData.closing_code,
                name: closingData.closing_name,
                year_result_update_count: closingData.closing_year_result_update_count,
                date_result_update_count: closingData.closing_date_result_update_count,
                closing_data: closingData.month_closing_data
              }
              oneYear.closing_data.push(oneRecord)
            }
          }
          if (data.salary_closing_data !== undefined && data.salary_closing_data !== null) {
            for (const closingData of data.salary_closing_data) {
              let oneRecord = {
                type: 'salary_closing',
                code: closingData.salary_closing_code,
                name: closingData.salary_closing_name,
                closing_code: closingData.closing_code,
                year_result_update_count: closingData.salary_closing_year_update_count,
                date_result_update_count: closingData.salary_closing_date_update_count,
                closing_data: closingData.month_salary_closing_data
              }
              oneYear.salary_closing_data.push(oneRecord)
            }
          }
          this.setClosingLast(data.target_year, data.closing_data)
          this.setSalaryClosingLast(data.target_year, data.salary_closing_data)
          this.year_data.push(oneYear)
        }
      },
      getTypeName (type) {
        if (type === 'closing') {
          return this.$t('label.attendance')
        } else {
          return this.$t('label.salary')
        }
      },
      isShowButton (month, prevMonth) {
        if (month === prevMonth) {
          return false
        } else {
          return true
        }
      },
      getButtonName (type, year, month, code) {
        let closingLast = this.closing_last[code]
        let salaryClosingLast = this.salary_closing_last[code]
        if (type === 'closing') {
          if (closingLast - ((Number(year) - 1) * 100) === 13) {
            closingLast = Number(String(year) + '00')
            salaryClosingLast = Number(String(year) + '00')
          }
          if ((closingLast) === Number(year + month)) {
            // 最終締年月が対象年月と同じ場合、解除ボタン表示
            return 'release'
          } else if (this.getClosingYear(closingLast) === Number(year + month)) {
            // 対象年月が最終締年月の翌月の場合、締めボタン表示
            return 'close'
          } else if ((closingLast) > Number(year + month)) {
            // 対象年月が最終締年月の前月以降の場合、済みを表示
            return 'done'
          } else {
            // それ以外の場合なにも表示しない
            return ''
          }
        } else {
          if (salaryClosingLast === Number(year + month)) {
            // 最終締年月が対象年月と同じ場合、解除ボタン表示
            return 'release'
          } else if (this.getClosingYear(salaryClosingLast) === Number(year + month)) {
            // 対象年月が最終締年月の翌月の場合
            if (this.isClosing(year, month, code)) {
              return 'close'
            } else {
              return ''
            }
          } else if ((salaryClosingLast) > Number(year + month)) {
            // 対象年月が最終締年月の前月以降の場合、済みを表示
            return 'done'
          } else {
            // それ以外の場合なにも表示しない
            return ''
          }
        }
      },
      getClosingYear (ClosingLast) {
        let strClosing = String(ClosingLast)
        let strMonth = strClosing.substring(4)
        if (strMonth === '13') {
          return ClosingLast + 88
        } else {
          return ClosingLast + 1
        }
      },
      getClosingCode (code, year) {
        for (const data of this.year_data) {
          if (year === data.target_year) {
            for (const closingData of data.closing_data) {
              if (closingData.type === 'salary_closing' && code === closingData.code) {
                return closingData.closing_code
              }
            }
            for (const closingData of data.salary_closing_data) {
              if (closingData.type === 'salary_closing' && code === closingData.code) {
                return closingData.closing_code
              }
            }
          }
        }
      },
      isClosing (year, month, code) {
        if (this.attendance_manage_count === 0) {
          // 勤怠管理されていない場合無条件でtrueを返却
          return true
        }
        let closingCode = this.getClosingCode(code, year)
        // 締め情報を回す
        for (const data of this.year_data) {
          // 対象年が同じ場合
          if (year === data.target_year) {
            // 締め情報を回す
            for (const closingData of data.closing_data) {
              // 勤怠締めのみ
              if (closingData.type === 'closing') {
                if (closingCode === closingData.code) {
                  if (month === '01' && closingData.closing_data.jan === 2) {
                    return true
                  } else if (month === '02' && closingData.closing_data.feb === 2) {
                    return true
                  } else if (month === '03' && closingData.closing_data.mar === 2) {
                    return true
                  } else if (month === '04' && closingData.closing_data.apl === 2) {
                    return true
                  } else if (month === '05' && closingData.closing_data.may === 2) {
                    return true
                  } else if (month === '06' && closingData.closing_data.jun === 2) {
                    return true
                  } else if (month === '07' && closingData.closing_data.jly === 2) {
                    return true
                  } else if (month === '08' && closingData.closing_data.aug === 2) {
                    return true
                  } else if (month === '09' && closingData.closing_data.sep === 2) {
                    return true
                  } else if (month === '10' && closingData.closing_data.oct === 2) {
                    return true
                  } else if (month === '11' && closingData.closing_data.nov === 2) {
                    return true
                  } else if (month === '12' && closingData.closing_data.dec === 2) {
                    return true
                  } else if (month === '13' && closingData.closing_data.year_is_close === 2) {
                    return true
                  } else {
                    return false
                  }
                }
              }
            }
          }
        }
      },
      getLastMonth (monthClosingData) {
        if (monthClosingData.jan === 1) {
          return '00'
        } else if (monthClosingData.feb === 1) {
          return '01'
        } else if (monthClosingData.mar === 1) {
          return '02'
        } else if (monthClosingData.apl === 1) {
          return '03'
        } else if (monthClosingData.may === 1) {
          return '04'
        } else if (monthClosingData.jun === 1) {
          return '05'
        } else if (monthClosingData.jly === 1) {
          return '06'
        } else if (monthClosingData.aug === 1) {
          return '07'
        } else if (monthClosingData.sep === 1) {
          return '08'
        } else if (monthClosingData.oct === 1) {
          return '09'
        } else if (monthClosingData.nov === 1) {
          return '10'
        } else if (monthClosingData.dec === 1) {
          return '11'
        } else if (monthClosingData.year_is_close === 1) {
          return '12'
        } else {
          return '13'
        }
      },
      setClosingLast (targetYear, closingData) {
        if (closingData === undefined || closingData === null) {
          return
        }
        for (const data of closingData) {
          let lastMonth = this.getLastMonth(data.month_closing_data)
          let year = targetYear
          if (lastMonth === '00') {
            year = year - 1
            lastMonth = '13'
            if (this.closing_last[data.closing_code] <= Number(year + '12')) {
              // 年次締めてない場合はスルー
              continue
            }
          }
          if (!this.closing_last[data.closing_code]) {
            this.closing_last[data.closing_code] = 0
          }
          if (this.closing_last[data.closing_code] < Number(year + lastMonth)) {
            this.closing_last[data.closing_code] = Number(year + lastMonth)
          } else if ((this.closing_last[data.closing_code] - 1) === Number(year + lastMonth)) {
            this.closing_last[data.closing_code] = Number(year + lastMonth)
          }
        }
      },
      setSalaryClosingLast (targetYear, salaryClosingData) {
        if (salaryClosingData === undefined || salaryClosingData === null) {
          return
        }
        for (const data of salaryClosingData) {
          let lastMonth = this.getLastMonth(data.month_salary_closing_data)
          let year = targetYear
          if (lastMonth === '00') {
            year = year - 1
            lastMonth = '13'
            if (this.salary_closing_last[data.salary_closing_code] <= Number(year + '12')) {
              // 年次締めてない場合はスルー
              continue
            }
          }
          if (!this.salary_closing_last[data.salary_closing_code]) {
            this.salary_closing_last[data.salary_closing_code] = 0
          }
          if (this.salary_closing_last[data.salary_closing_code] < Number(year + lastMonth)) {
            this.salary_closing_last[data.salary_closing_code] = Number(year + lastMonth)
          } else if ((this.salary_closing_last[data.salary_closing_code] - 1) === Number(year + lastMonth)) {
            this.salary_closing_last[data.salary_closing_code] = Number(year + lastMonth)
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style>
</style>
