<template>
  <div>
    <modal name="officeSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <OfficeSelect :table-data="res.office_list" @close="hide"/>
      </div>
    </modal>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form @submit.prevent="login" method="POST" action="#">
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <h3 class="card-title">QRコード打刻ログイン</h3>
                    </div>
                    <div class="card-content">
                      <div class="form-group">
                        <label>{{$t('label.company_code')}}</label>
                        <input
                          type="text"
                          :placeholder="$t('placeholder.company_code')"
                          class="form-control input-no-border"
                          v-model="companyCode"
                        />
                      </div>
                      <div class="form-group">
                        <label>{{$t('label.employee_code')}}</label>
                        <input
                          type="text"
                          :placeholder="$t('placeholder.employee_code')"
                          class="form-control input-no-border"
                          v-model="employeeCode"
                        />
                      </div>
                      <div class="form-group">
                        <label>{{$t('label.password')}}</label>
                        <input
                          :type="passwordType"
                          :placeholder="$t('placeholder.password')"
                          class="form-control input-no-border"
                          v-model="password"
                        />
                      </div>
                      <div class="form-group">
                        <div class="pull-right">
                          <input 
                            type="checkbox" 
                            v-model="showPassword" 
                            class="gray"
                            id="showPassword"
                          >
                          <label for="showPassword" style="cursor: pointer;">
                            {{$t('label.show_password')}}
                          </label>
                        </div>
                      </div>
                    </div>
                    <br>
                    <br>
                    <div class="card-footer text-center">
                      <button type="submit" class="btn btn-sm btn-default btn-wd btn-warning">{{$t('button.login')}}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="buttons" style="text-align: center;">
              <a class="button" href="/static/felica/Artemis Collier.zip">{{$t('label.felica_download')}}</a>
              <a class="button" href="/#/login">{{$t('label.artemis_login')}}</a>
              <a class="button" href="/#/admin/login">{{$t('label.admin_login')}}</a>
              &nbsp;
              <a href="https://apps.apple.com/app/id1625521109" target="_blank"><img class="button_appstore" src="/static/img/app_store.png" /></a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=jp.co.pocketsoft.flutter.artemis" target="_blank"><img class="button_appstore" src="/static/img/google_play.png" /></a>
            </div>
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <div class="copyright">
                  &copy; 2020-2024 PocketSoft Co., Ltd.
                </div>
              </div>
              <div style="display: inline-block;">
                <a style="color: #666" href="/#/notation-on-law">{{$t('label.notation_on_law')}}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import OfficeSelect from '@/components/Dashboard/Views/Qrcode/OfficeSelect'
  import mixin from '@/lib/mixin'
  // import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader,
      OfficeSelect
    },
    data () {
      return {
        companyCode: undefined,
        employeeCode: undefined,
        password: undefined,
        showPassword: false,
        options: new Map(),
        res: {}
      }
    },
    computed: {
      passwordType () {
        return this.showPassword ? 'text' : 'password'
      }
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login () {
        let self = this
        if (this.employeeCode && this.employeeCode.length > 0 && this.password && this.password.length > 0) {
          this.$firebase.auth().signInWithEmailAndPassword(process.env.VUE_APP_FIREBASE_USER, process.env.VUE_APP_FIREBASE_PASSWORD)
          .then(userInfo => {
            let data = {
              screen_code: '00-001',
              company_code: this.companyCode,
              employee_code: this.employeeCode,
              password: this.password
            }
            self.send('/login/auth/external', data)
            .then(data => {
              this.res = data.api_area
              this.$modal.show('officeSelect')
            })
            .catch(error => {
              console.error(error)
              // swal({
              //   text: 'ログインに失敗しました。',
              //   type: 'error',
              //   confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              //   buttonsStyling: false,
              //   allowOutsideClick: false
              // })
            })
          })
          .catch(err => {
            alert(err.message)
          })
        }
      },
      hide (selectedOffice) {
        this.$modal.hide('officeSelect')
        this.$router.push({ name: 'qrcodeStamp',
          params: {
            res: this.res,
            office: selectedOffice
          }
        })
      }
    },
    created () {
      const strLoginPageInfo = localStorage.getItem('loginPageInfo')
      if (strLoginPageInfo) {
        const loginPageInfo = JSON.parse(strLoginPageInfo)
        this.companyCode = loginPageInfo.companyCode
        this.employeeCode = loginPageInfo.employeeCode
        this.password = loginPageInfo.password
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }

</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .card input {
    background: #eee !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
</style>
