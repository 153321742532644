<template>
  <div>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="tableData"
              :key="componentKey"
              ref="table"
              :style="tableStyle">

      <!-- 役割(役職) -->
      <el-table-column width="200" fixed="left" :label="labels.role_ryaku === undefined ? '' : labels.role_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small></small>
            <br>
            {{props.row.role_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>

      <!-- 割当チェックボックス -->
      <template v-for="group in groups">
        <el-table-column
          width="150"
          :label="group.group_name"
          v-bind:key="group.group_code">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
              <input type="checkbox" :disabled="disabled" v-model="group.all_selected" class="gray" @click="selectAll(group.group_code, group.all_selected)" />
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" :disabled="disabled" v-model="props.row[group.group_code].selected" class="gray" />
            </div>
          </template>
        </el-table-column>
      </template>
      
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      groups: Array,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object
    },
    data () {
      return {
        componentKey: 0,
        tableData: [],
        tableStyle: 'width: 350px;'
      }
    },
    methods: {
      selectAll (groupCode, isSelected) {
        const tableData = [...this.tableData]
        for (const row of tableData) {
          row[groupCode].selected = !isSelected
        }
        this.tableData = tableData
      },
      adjust () {
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.componentKey += 1
        })
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        this.adjust()
      }
    },
    mounted () {
      this.tableData = this.value
      this.adjust()
    }
  }
</script>
