<template>
  <div>
    <span class="text-danger" v-show="error">
      {{ error }}
    </span>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="data.work_schedules"
              style="width: 500px">

      <!-- 勤務体系 -->
      <el-table-column min-width="150" :label="labels.workSchedule === undefined ? '' : labels.workSchedule">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>{{props.row.work_schedule_code}}</small>
            <br>
            {{props.row.work_schedule_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>
      
      <!-- 事業場外みなし労働時間制チェックボックス -->
      <el-table-column
        :min-width="80"
        width="80px"
        :label="labels.off_site_deemed_working_hours_flag_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.off_site_deemed_working_hours_flag" :disabled="!props.row.selected || disabled" class="gray" />
          </div>
        </template>
      </el-table-column>

      <!-- デフォルトの勤務体系 -->
      <el-table-column
        :min-width="90"
        width="90px"
        :label="$t('label.default_work_schedule')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">
              <span v-html="breakLabel(scope.column.label, 2)"></span>
            </div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center; padding: 10px 0 0 10px;">
            <p-radio 
              v-model="data.default_work_schedule" 
              :label="props.row.work_schedule_code"
              :disabled="!props.row.selected || disabled"
            >
            </p-radio>
          </div>
        </template>
      </el-table-column>
      
      <!-- 割当チェックボックス -->
      <el-table-column
        :min-width="80"
        width="80px"
        :label="$t('label.allocation')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.selected" :disabled="disabled" class="gray" @change="updateDefaultWorkSchedules(props.row)" />
          </div>
        </template>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Object,
      orgSelectedWorkSchedules: Array,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object,
      error: String
    },
    data () {
      return {
        data: {},
        selectedWorkSchedules: []
      }
    },
    methods: {
      updateDefaultWorkSchedules (row) {
        if (this.data.default_work_schedule === row.work_schedule_code && !row.selected) {
          this.data.default_work_schedule = ''
        }
        if (!row.selected) {
          row.off_site_deemed_working_hours_flag = false
        }
        const selectedWorkSchedules = []
        for (const workSchedule of this.data.work_schedules) {
          if (workSchedule.selected) {
            selectedWorkSchedules.push(workSchedule)
          }
        }
        this.selectedWorkSchedules = selectedWorkSchedules
      }
    },
    watch: {
      value () {
        this.data = this.value
        this.selectedWorkSchedules = this.orgSelectedWorkSchedules
      }
    },
    mounted () {
      this.data = this.value
      this.selectedWorkSchedules = this.orgSelectedWorkSchedules
    }
  }
</script>
