<template>
  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"    border
    :data="tableData"
    :empty-text="$t('label.no_data')">
    <el-table-column
      :label="itemLabel ? itemLabel : ''"
      align="center">
      <template slot-scope="props">
        <div style="text-align: left;">
          <el-select :class="itemClasses"
                      size="large"
                      :name="props.row.name"
                      :disabled="disabled"
                      v-model="props.row.value">
            <el-option v-for="option in options"
                        class="select-success"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
            </el-option>
          </el-select>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemLabel: String,
      options: Array,
      disabled: Boolean,
      classes: String
    },
    data () {
      return {
        tableData: [],
        itemClasses: 'select-success'
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        this.itemClasses = 'select-success ' + this.classes
      }
    },
    mounted () {
      this.tableData = this.value
      this.itemClasses = 'select-success ' + this.classes
    }
  }
</script>

<style scoped>
</style>
