<template>
  <div class="card">
    <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :width="1400" :pivotX="0.6">
      <div class="modal-body">
        <EmployeeList :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="card-header search-result-title">
      <h4 class="card-title">
        {{$t('label.search_result')}}
      </h4>
    </div>
    <div class="card-content">
      <div class="row" ref="summary">
        <div class="col-sm-6 pagination-info">
          <el-select
            class="select-success length2"
            :disabled="isChanged"
            v-model="pagination.perPage">
            <el-option
              class="select-success"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-table :data="pagedData"
                  :empty-text="$t('label.no_data')"
                  border
                  :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                  :style="tableStyle"
                  :max-height="maxHeight"
                  ref="table"
                  :row-class-name="tableRowClassName">
          <el-table-column :width="80"
                            v-if="showOperation"
                            :key="componentKey"
                            :label="$t('label.operation')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <button type="button" class="btn btn-warning btn-sm btn-default" @click="employeeClicked(props.$index, props.row)">{{$t('button.employee')}}</button>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.mail_address, 'mail_address')" :label="labels.mail_address">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.mail_address}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.company_code, 'company_code')" :label="labels.company_code">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.company_code}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.company_name, 'company_name')" :label="labels.company_name">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.company_name}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column :width="getColumnWidth(tableData, labels.append_path, 'append_path')" :label="labels.append_path">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.append_path}}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column :width="getColumnWidth(tableData, labels.language, 'language')" :label="labels.language">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.language}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.start_day_of_the_week, 'start_day_of_the_week')" :label="labels.start_day_of_the_week">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.start_day_of_the_week}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_second, 'rounding_second')" :label="labels.rounding_second">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_second}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_type, 'rounding_type')" :label="labels.rounding_type">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_type}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_month, 'rounding_month')" :label="labels.rounding_month">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_month}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_term, 'rounding_term')" :label="labels.rounding_term">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_term}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_term_over_work, 'rounding_term_over_work')" :label="labels.rounding_term_over_work">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_term_over_work}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_job_start, 'rounding_job_start')" :label="labels.rounding_job_start">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_job_start}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.rounding_job_end, 'rounding_job_end')" :label="labels.rounding_job_end">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.rounding_job_end}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.home_page, 'home_page')" :label="labels.home_page">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.home_page}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.post_code, 'post_code')" :label="labels.post_code">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.post_code}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.state_code, 'state_code')" :label="labels.state_code">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.state_code}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.municipality_code, 'municipality_code')" :label="labels.municipality_code">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.municipality_code}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.town, 'town')" :label="labels.town">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.town}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.building, 'building')" :label="labels.building">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.building}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.tel, 'tel')" :label="labels.tel">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.tel}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.fax, 'fax')" :label="labels.fax">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.fax}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.industry_code_big, 'industry_code_big')" :label="labels.industry_code_big">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.industry_code_big}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.industry_code_during, 'industry_code_during')" :label="labels.industry_code_during">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.industry_code_during}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.industry_code_small, 'industry_code_small')" :label="labels.industry_code_small">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.industry_code_small}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.system_company_flg, 'system_company_flg')" :label="labels.system_company_flg">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.system_company_flg}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.corporate_number, 'corporate_number')" :label="labels.corporate_number">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.corporate_number}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.labor_insurance_number, 'labor_insurance_number')" :label="labels.labor_insurance_number">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.labor_insurance_number}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.is_smile_mark, 'is_smile_mark')" :label="labels.is_smile_mark">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.is_smile_mark}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.attendance_management, 'attendance_management')" :label="labels.attendance_management">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.attendance_management}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.payroll_management, 'payroll_management')" :label="labels.payroll_management">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.payroll_management}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.disclosure_of_company, 'disclosure_of_company')" :label="labels.disclosure_of_company">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.disclosure_of_company}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.campaign_price, 'campaign_price')" :label="labels.campaign_price">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.campaign_price}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.number_of_working_hours_on_the_28th, 'number_of_working_hours_on_the_28th')" :label="labels.number_of_working_hours_on_the_28th">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.number_of_working_hours_on_the_28th}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.number_of_working_hours_on_the_29th, 'number_of_working_hours_on_the_29th')" :label="labels.number_of_working_hours_on_the_29th">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.number_of_working_hours_on_the_29th}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.number_of_working_hours_on_the_30th, 'number_of_working_hours_on_the_30th')" :label="labels.number_of_working_hours_on_the_30th">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.number_of_working_hours_on_the_30th}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.number_of_working_hours_on_the_31th, 'number_of_working_hours_on_the_31th')" :label="labels.number_of_working_hours_on_the_31th">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.number_of_working_hours_on_the_31th}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="getColumnWidth(tableData, labels.flextime_full_two_day_weekend_system, 'flextime_full_two_day_weekend_system')" :label="labels.flextime_full_two_day_weekend_system">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.flextime_full_two_day_weekend_system}}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="row" ref="pager">
          <div class="col-sm-4 pagination-info">
            <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
          </div>
          <div class="col-sm-8" style="padding: 0">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmployeeList from '@/components/Dashboard/Views/Components/EmployeeList'
  import PPagination from '@/components/UIComponents/Pagination'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      EmployeeList,
      PPagination
    },
    props: {
      value: Array,
      labels: Object,
      comboData: Object
    },
    computed: {
      screenCode () {
        return '99-013'
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        isChanged: false,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        tableData: [],
        showOperation: true,
        tableStyle: '',
        maxHeight: 0,
        params: {}
      }
    },
    methods: {
      hide () {
        this.$modal.hide('employee')
      },
      employeeClicked (index, row) {
        this.params = {
          tableData: row.Employees,
          labels: this.labels,
          comboData: this.comboData
        }
        this.$modal.show('employee')
      },
      tableRowClassName ({row}) {
        if (row.outdate_flag || row.is_future) {
          return 'out-row'
        } else if (row.update_date) {
          if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
            return 'update-row'
          }
        }
        return ''
      },
      canUpdate (row) {
        return true
      },
      canDelete (row) {
        return true
      },
      setWidth () {
        this.$nextTick(() => {
          const maxWidth = window.innerWidth - 360
          this.$refs.pager.style.width = maxWidth + 'px'
          this.$refs.summary.style.width = maxWidth + 'px'
          this.maxHeight = window.innerHeight - 530
          this.componentKey += 1
        })
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        this.setWidth()
      }
    },
    mounted () {
      this.tableData = this.value
      this.setWidth()
    }
  }
</script>
