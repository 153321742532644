<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ResidentTaxSetting
              v-model="detail.employee_resident_tax"
              item-name="employee_resident_tax"
              classes="form-control"
              :item-label="labels.employee_resident_tax"
              :disabled="isReadOnly"
              :labels="labels"
              :combo-data="comboData"
              :errors="errors"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ResidentTaxSettingDetailMixin from '@/components/Dashboard/Views/Generated/ResidentTaxSettingDetailMixin'

  export default {
    name: 'ResidentTaxSettingDetail',
    data () {
      return {
        errors: []
      }
    },
    methods: {
      afterInit (res) {
        this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        this.detail.employee_resident_tax.screen_code = this.screenCode
        this.detail.employee_resident_tax.mode = this.mode
        this.comboData.prefectures_list = this.comboData.state_list
        this.comboData.prefectures_list.unshift({label: '', value: ''})
        this.comboData.city_list = []
        this.comboData.mapping_list = res.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
        const yearList = []
        let currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()
        let year
        if (currentMonth === 11) currentYear += 1
        for (year = currentYear; year >= 2009; year--) {
          yearList.push({label: String(year), value: String(year)})
        }
        this.comboData.target_year_list = yearList
        this.detail.employee_resident_tax.target_year = yearList[0].value
        if (!res.api_area) return
        this.detail.employee_resident_tax.target_year = res.api_area.target_year
        this.detail.employee_resident_tax.prefectures = res.api_area.prefectures
        this.detail.employee_resident_tax.city = res.api_area.city
        this.detail.employee_resident_tax.resident_municipality_code = res.api_area.employee_resident_tax_list.length > 0 ? res.api_area.employee_resident_tax_list[0].resident_municipality_code : ''
        this.detail.employee_resident_tax.local_government_code = res.api_area.local_government_code
        this.detail.employee_resident_tax.employee_resident_tax_list = res.api_area.employee_resident_tax_list.map((row) => ({
          ...row,
          resident_tax_jan: this.formatNumber(row.resident_tax_jan),
          resident_tax_feb: this.formatNumber(row.resident_tax_feb),
          resident_tax_mar: this.formatNumber(row.resident_tax_mar),
          resident_tax_apl: this.formatNumber(row.resident_tax_apl),
          resident_tax_may: this.formatNumber(row.resident_tax_may),
          resident_tax_jun: this.formatNumber(row.resident_tax_jun),
          resident_tax_jly: this.formatNumber(row.resident_tax_jly),
          resident_tax_aug: this.formatNumber(row.resident_tax_aug),
          resident_tax_sep: this.formatNumber(row.resident_tax_sep),
          resident_tax_oct: this.formatNumber(row.resident_tax_oct),
          resident_tax_nov: this.formatNumber(row.resident_tax_nov),
          resident_tax_dec: this.formatNumber(row.resident_tax_dec)
        }))
      },
      doAdd () {
        this.submit('update')
      },
      customValidate () {
        this.errors = []
        if (this.mode === this.modes.add && this.detail.employee_resident_tax.employee_resident_tax_list.filter((row) => !row.selected).length === 0) {
          this.errors.push(this.$t('message.is_table_required').replace('{item}', this.$t('label.resident_tax_employee')))
        }
        for (var i = 0; i < this.detail.employee_resident_tax.employee_resident_tax_list.length; i++) {
          const row = this.detail.employee_resident_tax.employee_resident_tax_list[i]
          if (row.selected) continue
          if (!row.employee_name) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.employee_name))
          if (row.designated_number && isNaN(row.designated_number)) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.designated_number))
          if (row.addressee_number && isNaN(row.addressee_number)) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.addressee_number))
          if (row.beneficiary_number && isNaN(row.beneficiary_number)) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.beneficiary_number))
          if (!row.resident_tax_jun) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jun))
          else if (isNaN(row.resident_tax_jun.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jun))
          if (!row.resident_tax_jly) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jly))
          else if (isNaN(row.resident_tax_jly.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jly))
          if (!row.resident_tax_aug) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_aug))
          else if (isNaN(row.resident_tax_aug.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_aug))
          if (!row.resident_tax_sep) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_sep))
          else if (isNaN(row.resident_tax_sep.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_sep))
          if (!row.resident_tax_oct) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_oct))
          else if (isNaN(row.resident_tax_oct.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_oct))
          if (!row.resident_tax_nov) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_nov))
          else if (isNaN(row.resident_tax_nov.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_nov))
          if (!row.resident_tax_dec) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_dec))
          else if (isNaN(row.resident_tax_dec.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_dec))
          if (!row.resident_tax_jan) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jan))
          else if (isNaN(row.resident_tax_jan.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_jan))
          if (!row.resident_tax_feb) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_feb))
          else if (isNaN(row.resident_tax_feb.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_feb))
          if (!row.resident_tax_mar) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_mar))
          else if (isNaN(row.resident_tax_mar.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_mar))
          if (!row.resident_tax_apl) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_apl))
          else if (isNaN(row.resident_tax_apl.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_apl))
          if (!row.resident_tax_may) this.errors.push(this.$t('message.is_item_required_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_may))
          else if (isNaN(row.resident_tax_may.replaceAll(',', ''))) this.errors.push(this.$t('message.is_item_number_for_row').replace('{row}', i + 1).replace('{item}', this.labels.resident_tax_may))
          for (var j = 0; j < this.detail.employee_resident_tax.employee_resident_tax_list.length; j++) {
            const row2 = this.detail.employee_resident_tax.employee_resident_tax_list[j]
            if (row2.selected) continue
            if (i !== j && row.employee_code === row2.employee_code) {
              this.errors.push(this.$t('message.row_duplicate_error').replace('{row}', i + 1).replace('{item}', row.employee_name))
              break
            }
          }
        }
        return this.errors.length === 0
      },
      convertDetail (detail) {
        const apiArea = {}
        apiArea.employee_resident_tax_list = []
        for (const row of detail.employee_resident_tax.employee_resident_tax_list) {
          if (!row.new_row || !row.selected) {
            const residentTaxSetting = {
              employee_code: row.employee_code,
              designated_number: row.designated_number || null,
              addressee_number: row.addressee_number || null,
              resident_municipality_code: detail.employee_resident_tax.resident_municipality_code,
              beneficiary_number: row.beneficiary_number || null,
              is_taxable: row.is_taxable ? 1 : 2,
              resident_tax_jan: parseInt(row.resident_tax_jan.replaceAll(',', ''), 10),
              resident_tax_feb: parseInt(row.resident_tax_feb.replaceAll(',', ''), 10),
              resident_tax_mar: parseInt(row.resident_tax_mar.replaceAll(',', ''), 10),
              resident_tax_apl: parseInt(row.resident_tax_apl.replaceAll(',', ''), 10),
              resident_tax_may: parseInt(row.resident_tax_may.replaceAll(',', ''), 10),
              resident_tax_jun: parseInt(row.resident_tax_jun.replaceAll(',', ''), 10),
              resident_tax_jly: parseInt(row.resident_tax_jly.replaceAll(',', ''), 10),
              resident_tax_aug: parseInt(row.resident_tax_aug.replaceAll(',', ''), 10),
              resident_tax_sep: parseInt(row.resident_tax_sep.replaceAll(',', ''), 10),
              resident_tax_oct: parseInt(row.resident_tax_oct.replaceAll(',', ''), 10),
              resident_tax_nov: parseInt(row.resident_tax_nov.replaceAll(',', ''), 10),
              resident_tax_dec: parseInt(row.resident_tax_dec.replaceAll(',', ''), 10),
              remarks_others: row.remarks_others,
              delete_flag: row.selected || false,
              update_count: row.update_count
            }
            apiArea.employee_resident_tax_list.push(residentTaxSetting)
          }
        }
        apiArea.target_year = this.detail.employee_resident_tax.target_year
        apiArea.local_government_code = this.detail.employee_resident_tax.local_government_code
        apiArea.state_code = this.detail.employee_resident_tax.local_government_code ? undefined : this.detail.employee_resident_tax.prefectures
        apiArea.municipality_code = this.detail.employee_resident_tax.local_government_code ? undefined : this.detail.employee_resident_tax.city.split('_')[1]
        return apiArea
      }
    },
    mixins: [ResidentTaxSettingDetailMixin]
  }
</script>
<style scoped>
</style>
