<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationRadio
              v-model="detail.language"
              rules="required"
              :item-label="labels.language"
              :disabled="isReadOnly"
            />
            <ValidationText
              v-model="detail.message_id"
              item-name="message_id"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.message_id"
              :placeholder="isReadOnly ? '' : $t('placeholder.message_id')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.message"
              item-name="message"
              max-length="255"
              classes="form-control length255"
              rules="required"
              :item-label="labels.message"
              :placeholder="isReadOnly ? '' : $t('placeholder.message')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.correspondence_action"
              item-name="correspondence_action"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.correspondence_action"
              :placeholder="isReadOnly ? '' : $t('placeholder.correspondence_action')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationRadio
              v-model="detail.message_type"
              rules="required"
              :item-label="labels.message_type"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.message_classification"
              rules="required"
              :item-label="labels.message_classification"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.cause"
              rules="required"
              :item-label="labels.cause"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.correspondence"
              rules="required"
              :item-label="labels.correspondence"
              :disabled="isReadOnly"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import MessageDetailMixin from '@/components/Dashboard/Views/Generated/MessageDetailMixin'

  export default {
    name: 'MessageDetail',
    methods: {
      afterInit (res) {
        let items = []
        for (const row of this.comboData.language_type_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: row.value
          }
          items.push(item)
        }
        this.detail.language = {
          value: this.mode === this.modes.add ? 'JPN' : res.api_area.language,
          disabled: this.isReadOnly,
          items: items
        }
        items = []
        for (const row of this.comboData.message_type_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: row.value
          }
          items.push(item)
        }
        this.detail.message_type = {
          value: this.mode === this.modes.add ? 1 : res.api_area.message_type,
          disabled: this.isReadOnly,
          items: items
        }
        items = []
        for (const row of this.comboData.message_classification_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: row.value
          }
          items.push(item)
        }
        this.detail.message_classification = {
          value: this.mode === this.modes.add ? 1 : res.api_area.message_classification,
          disabled: this.isReadOnly,
          items: items
        }
        items = []
        for (const row of this.comboData.cause_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: row.value
          }
          items.push(item)
        }
        this.detail.cause = {
          value: this.mode === this.modes.add ? 0 : res.api_area.cause,
          disabled: this.isReadOnly,
          items: items
        }
        items = []
        for (const row of this.comboData.correspondence_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: row.value
          }
          items.push(item)
        }
        this.detail.correspondence = {
          value: this.mode === this.modes.add ? 0 : res.api_area.correspondence,
          disabled: this.isReadOnly,
          items: items
        }
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.choice_language = detail.language.value
          delete detail.language
          detail.message_type = parseInt(detail.message_type.value, 10)
          detail.message_classification = parseInt(detail.message_classification.value, 10)
          detail.cause = parseInt(detail.cause.value, 10)
          detail.correspondence = parseInt(detail.correspondence.value, 10)
          if (this.mode === this.modes.add) {
            delete detail.update_count
          }
        } else {
          detail.choice_language = this.detail.language.value
          detail.message_id = this.detail.message_id
          detail.message = this.detail.message
        }
        return detail
      }
    },
    mixins: [MessageDetailMixin]
  }
</script>
<style scoped>
</style>
