<template>
  <div class="row">
    <div class="col-md-12">
      <form method="#" action="#">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{$t('label.break_add')}}
            </h4>
          </div>
          <div class="card-content">
            <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
            <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{$t('label.date')}}</label>
                </div>
              </div>
              <div class="col-md-9">
                <div class="form-group">
                  <label>{{dispTargetDate}}</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{$t('label.total_break_time')}}</label>
                </div>
              </div>
              <div class="col-md-9">
                <div class="form-group">
                  <label>{{totalBreakTime}}</label>
                </div>
              </div>
            </div>
            <div class="col-sm-12" style="margin: 20px 0;">
              <br>
              <div style="text-align: left;" v-if="!isReadOnly">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{$t('button.add_row')}}</button>
              </div>
              <br>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="tableData"
                        style="width: 100%">
                <el-table-column width="50" :label="$t('label.delete')" align="center">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly || !props.row.delete">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="75" :label="$t('label.start_time')">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.start_time_next" class="gray" :id="'startNextDay' + props.$index" :disabled="isReadOnly || !props.row.delete">
                    <label :for="'startNextDay' + props.$index">{{$t('label.next_day')}}</label>
                    <input 
                      type="text" 
                      style="padding-right: 0.3em; width:50px; text-align: right;"
                      class="input-text"
                      maxlength="5"
                      v-model="props.row.start_time"
                      @blur="updateTimeBreaked(props.row)"
                      :disabled="isReadOnly || !props.row.delete">
                  </template>
                </el-table-column>
                <el-table-column min-width="75" :label="$t('label.end_time')">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.end_time_next" class="gray" :id="'endNextDay' + props.$index" :disabled="isReadOnly || !props.row.delete">
                    <label :for="'endNextDay' + props.$index">{{$t('label.next_day')}}</label>
                    <input 
                      type="text" 
                      style="padding-right: 0.3em; width:50px; text-align: right;"
                      class="input-text"
                      maxlength="5"
                      v-model="props.row.end_time"
                      @blur="updateTimeBreaked(props.row)"
                      :disabled="isReadOnly || !props.row.delete">
                  </template>
                </el-table-column>
                <!-- <el-table-column min-width="75" :label="$t('label.hours')">
                  <template slot-scope="props">
                    {{props.row.time_breaked}}
                  </template>
                </el-table-column> -->
              </el-table>
              <br>
              <div style="text-align: left;" v-if="!isReadOnly">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="breakAdd">
                  {{$t('button.save')}}
                </button>
              </div>
              <div style="text-align: left;" v-if="isStampCorrection">
                <button type="button" class="btn btn-sm btn-default btn-submit" @click="$emit('breakApply')">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.imprint_correction')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  // import swal from 'sweetalert2'

  export default{
    mixins: [mixin],
    props: {
      isView: Boolean,
      row: Object,
      isStampCorrection: Boolean,
      labels: Object
    },
    methods: {
      deleteRow (index, row) {
        this.tableData.splice(index, 1)
      },
      addRow () {
        this.tableData.push(
          { 'selected': false, 'start_time': '', 'end_time': '', 'time_breaked': '', 'delete': true }
        )
      },
      breakAdd () {
        this.$emit('close', this.tableData)
      },
      updateTimeBreaked (row) {
        if (row.start_time) {
          if (row.end_time) {
            const startTime = this.to48Time(row.start_time, row.start_time_next)
            const endTime = this.to48Time(row.end_time, row.end_time_next)
            row.time_breaked = this.calcTime(startTime, endTime)
          } else {
            row.time_breaked = ''
          }
        } else {
          row.time_breaked = ''
        }
        // this.updateTotalBreakTime()
      },
      doClose () {
        this.$emit('close', null)
      },
      updateTotalBreakTime () {
        let timesBreaked = []
        for (const data of this.tableData) {
          if (data.time_breaked.length > 0) {
            timesBreaked.push(data.time_breaked)
          }
        }
        // timesBreaked.push(this.formatDispMinute(this.mstBreakMinutes))
        this.totalBreakTime = this.sumTimes(timesBreaked)
      },
      search () {
        let searchCond = {
          screen_code: 'SS-020',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: this.selectedDay.employee_code,
            work_schedule_code: this.selectedDay.work_schedule_code_bk,
            target_date: this.selectedDay.target_date
          }
        }
        this.send('/view/break_time_record/list', searchCond)
        .then(res => {
          this.updateTableData(res)
          this.totalBreakTime = this.formatDispMinute(res.api_area.init_break_time_minute)
        })
      },
      updateTableData (apiArea) {
        this.tableData = []
        let tranBreakMinutes = 0
        for (const result of apiArea) {
          const startTime = !result.start_time ? '' : this.to24Time(result.start_time.substring(0, 5))
          const endTime = !result.end_time ? '' : this.to24Time(result.end_time.substring(0, 5))
          const startTimeNext = startTime !== result.start_time.substring(0, 5)
          const endTimeNext = endTime !== result.end_time.substring(0, 5)
          const startTime48 = this.to48Time(startTime, startTimeNext)
          const endTime48 = this.to48Time(endTime, endTimeNext)
          const timeBreaked = this.calcTime(startTime48, endTime48)
          const row = {
            selected: result.selected || false,
            start_time: startTime,
            end_time: endTime,
            start_time_next: startTimeNext,
            end_time_next: endTimeNext,
            time_breaked: timeBreaked,
            delete: result.delete,
            company_code: result.company_code,
            employee_code: result.employee_code,
            target_date: result.target_date,
            work_schedule_code: result.work_schedule_code
          }
          this.tableData.push(row)
          tranBreakMinutes = tranBreakMinutes + this.toMinutes(timeBreaked)
        }
        // this.mstBreakMinutes = this.selectedDay.sum_break_minutes - tranBreakMinutes
        if (!this.isReadOnly) {
          this.tableData.push({ selected: false, start_time: '', end_time: '', time_breaked: '', delete: true })
        }
      },
      init () {
        this.dispTargetDate = this.formatDispDateWithDateOfWeek(
          this.selectedDay.target_date,
          this.$t('label.year'),
          this.$t('label.month'),
          this.$t('label.day'),
          this.weekdayLabel)
        // this.search()
        this.updateTableData(this.row.break_time_data)
        this.totalBreakTime = this.formatDispMinute(this.row.break_minutes)
      }
    },
    data () {
      return {
        id: 0,
        isChanged: false,
        mstBreakMinutes: 0,
        dispTargetDate: '',
        office: {
          company_code: '',
          office_code: '',
          office_name: '',
          place_code: '',
          term_from: '',
          term_to: '',
          employee_code: ''
        },
        places: [],
        selectedDay: null,
        tableData: [],
        // tableData: [
        //   { start_time: '10:00', end_time: '11:00', time_breaked: '1:00' },
        //   { start_time: '15:30', end_time: '16:00', time_breaked: '0:30' },
        //   { start_time: '', end_time: '', time_breaked: '' }
        // ],
        totalBreakTime: '99:99'
      }
    },
    computed: {
      weekdayLabel () {
        let weekday = ''
        if (this.selectedDay.week_day === 0) {
          weekday = this.$t('label.monday_ryaku')
        } else if (this.selectedDay.week_day === 1) {
          weekday = this.$t('label.tuesday_ryaku')
        } else if (this.selectedDay.week_day === 2) {
          weekday = this.$t('label.wednesday_ryaku')
        } else if (this.selectedDay.week_day === 3) {
          weekday = this.$t('label.thursday_ryaku')
        } else if (this.selectedDay.week_day === 4) {
          weekday = this.$t('label.friday_ryaku')
        } else if (this.selectedDay.week_day === 5) {
          weekday = this.$t('label.saturday_ryaku')
        } else if (this.selectedDay.week_day === 6) {
          weekday = this.$t('label.sunday_ryaku')
        }
        return weekday
      },
      isReadOnly () {
        return this.isView || this.isChanged || this.labels.update === 2
      }
    },
    created () {
      this.selectedDay = this.row
      this.init()
      // this.updateTotalBreakTime()
    }
  }
</script>
<style>
</style>
