<template>
  <div>
    <!-- 従業員検索モーダル パラメータ：検索条件 -->
    <modal name="employeeSearch" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="searchCond" :salary-flag="true" :is-modal="true" @close="hideEmployeeSearch" />
      </div>
    </modal>
    <div class="form-group">
      <ValidationObserver ref="observer2">
        <template v-if="detail.mode === modes.add">
          <ValidationSelect
            v-model="detail.target_year"
            item-name="target_year"
            rules="required"
            :disabled="disabled"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.target_year_list)"
            :item-label="labels.target_year_ryaku === undefined ? labels.targetYear_ryaku === undefined ? '' : labels.targetYear_ryaku : labels.target_year_ryaku"
            :options="comboData.target_year_list"
            frame-class="search-condition-item"
            :unit="$t('label.unit_year')"
          />
          <div style="display: inline-block;">
            <ValidationSelect
              v-model="detail.prefectures"
              item-name="prefectures"
              rules="required"
              :disabled="disabled"
              :placeholder="$t('label.select_description')"
              :classes="getSelectLengthClass(comboData.prefectures_list)"
              :item-label="labels.prefectures_ryaku === undefined ? labels.prefectures_ryaku === undefined ? '' : labels.prefectures_ryaku : labels.prefectures_ryaku"
              :options="comboData.prefectures_list"
              frame-class="search-condition-item"
              @change="prefecturesChanged"
            />
          </div>
          <div style="display: inline-block;">
            <ValidationSelect
              v-model="detail.city"
              item-name="city"
              rules="required"
              :disabled="disabled"
              :placeholder="$t('label.select_description')"
              :classes="getSelectLengthClass(comboData.city_list)"
              :item-label="labels.city_ryaku === undefined ? labels.city_ryaku === undefined ? '' : labels.city_ryaku : labels.city_ryaku"
              :options="comboData.city_list"
              frame-class="search-condition-item"
            />
          </div>
        </template>
        <template v-else>
          <div style="display: inline-block; padding-right: 40px;">
            <h4 class="card-title" style="margin: 10px 0;">
              {{ detail.title }}
            </h4>
          </div>
        </template>
      </ValidationObserver>
      <div style="display: inline-block;">
        <ValidationText2
          v-model="detail.resident_municipality_code"
          item-name="resident_municipality_code"
          max-length="6"
          classes="form-control numLength6"
          :item-label="labels.resident_municipality_code"
          :placeholder="disabled ? '' : $t('placeholder.resident_municipality_code')"
          :disabled="disabled"
          rules="required|decimal"
        />
      </div>
      <div>
        <table border class="resident-tax-total">
          <tr style="border-top:none; border-right:none; border-left:none;">
            <td width="30"></td>
            <td width="70"></td>
            <td width="60"></td>
            <td width="100"></td>
            <td width="70"></td>
            <td width="60"></td>
            <td width="100"></td>
            <td width="70"></td>
            <td width="60"></td>
            <td width="100"></td>
          </tr>
          <tr>
            <td colspan="2">
              {{ labels.special_tax_collection_amount }}
            </td>
            <td colspan="2">
              <div style="text-align: right;">
                {{ formatNumber(detail.special_tax_collection_amount) + $t('label.unit_yen') }}
              </div>
            </td>
            <td colspan="2" style="text-align: center;">
              課税人員
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_taxable_employees) + $t('label.unit_people') }}
            </td>
            <td colspan="2" style="text-align: center;">
              非課税人員
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_untaxable_employees) + $t('label.unit_people') }}
            </td>
          </tr>
          <tr>
            <td rowspan="7">
              月<br>割<br>額
            </td>
            <td></td>
            <td style="text-align: center;">
              人数
            </td>
            <td style="text-align: center;">
              納付額
            </td>
            <td></td>
            <td style="text-align: center;">
              人数
            </td>
            <td style="text-align: center;">
              納付額
            </td>
            <td></td>
            <td style="text-align: center;">
              人数
            </td>
            <td style="text-align: center;">
              納付額
            </td>
          </tr>
          <tr>
            <td style="text-align: right;">
              {{ labels.resident_tax_jun }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_jun) + $t('label.people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_jun) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_oct }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_oct) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_oct) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_feb }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_feb) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_feb) + $t('label.yen') }}
            </td>
          </tr>
          <tr>
            <td style="text-align: right;">
              {{ labels.resident_tax_jly }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_jly) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_jly) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_nov }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_nov) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_nov) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_mar }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_mar) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_mar) + $t('label.yen') }}
            </td>
          </tr>
          <tr>
            <td style="text-align: right;">
              {{ labels.resident_tax_aug }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_aug) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_aug) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_dec }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_dec) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_dec) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_apl }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_apl) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_apl) + $t('label.yen') }}
            </td>
          </tr>
          <tr>
            <td style="text-align: right;">
              {{ labels.resident_tax_sep }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_sep) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_sep) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_jan }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_jan) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_jan) + $t('label.yen') }}
            </td>
            <td style="text-align: right;">
              {{ labels.resident_tax_may }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.number_of_employees_may) + $t('label.unit_people') }}
            </td>
            <td style="text-align: right;">
              {{ formatNumber(detail.payment_amount_may) + $t('label.yen') }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div style="color: #C84513; margin-bottom: 5px;" v-for="error in errors" v-bind:key="error">
      <small>{{error}}</small>
    </div>
    <div style="margin-bottom: 5px;">
      <div style="display: inline-block; width: 710px;">
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="disabled" @click="addRow">{{$t('button.add_row')}}</button>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="disabled || copied" @click="copyPreviousYear">{{$t('button.copy_previous_year')}}</button>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="disabled" @click="copyJulyAll">{{$t('button.copy_july')}}</button>
    </div>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="detail.employee_resident_tax_list"
              :max-height="480"
              style="width: 1366px">

      <!-- 削除 -->
      <el-table-column width="60" :label="$t('label.delete')" align="center">
        <template slot-scope="props">
          <template v-if="!props.row.isFixed">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="disabled">
            </div>
          </template>
        </template>
      </el-table-column>

      <!-- 従業員 -->
      <el-table-column width="200" :label="labels.employee_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <template v-if="props.row.new_row">
            <button type="button"
              class="btn btn-sm btn-default btn-warning"
              :disabled="disabled"
              @click="showEmployeeSearch(props.$index)"
            >
              <i class="ti-search"></i>
            </button>
          </template>
          <div style="text-align: left; font-size: medium; margin-top: 10px;">
            {{ props.row.employee_name }}
          </div>
          <div>
            <small>{{ props.row.employee_code }}</small>
          </div>
          <div style="white-space: normal">
            {{ (props.row.state_name || '') + (props.row.municipality_name || '') + (props.row.town || '') + (props.row.building || '') }}
          </div>
        </template>
      </el-table-column>

      <!-- 指定番号、宛名番号、受給者番号 -->
      <el-table-column width="230" :label="''">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div class="form-group">
            <div class="length1" style="display: inline-block;">
              <label>{{labels.designated_number}}</label>
            </div>
            <ValidationText
              v-model="props.row.designated_number"
              item-name="designated_number"
              max-length="10"
              classes="form-control numLength10"
              :item-label="labels.designated_number"
              :placeholder="disabled ? '' : $t('placeholder.designated_number')"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <div class="length1" style="display: inline-block;">
              <label>{{labels.addressee_number}}</label>
            </div>
            <ValidationText
              v-model="props.row.addressee_number"
              item-name="addressee_number"
              max-length="10"
              classes="form-control numLength10"
              :item-label="labels.addressee_number"
              :placeholder="disabled ? '' : $t('placeholder.addressee_number')"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <div class="length1" style="display: inline-block;">
              <label>{{labels.beneficiary_number}}</label>
            </div>
            <ValidationText
              v-model="props.row.beneficiary_number"
              item-name="beneficiary_number"
              max-length="10"
              classes="form-control numLength10"
              :item-label="labels.beneficiary_number"
              :placeholder="disabled ? '' : $t('placeholder.beneficiary_number')"
              :disabled="disabled"
            />
          </div>
        </template>
      </el-table-column>

      <!-- 特別徴収税額 -->
      <el-table-column width="140" :label="labels.special_tax_collection_amount === undefined ? '' : labels.special_tax_collection_amount">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="disabled" @click="copyJuly(props.row)">{{$t('button.copy_july')}}</button>
          </div>
          <div style="text-align: right;">
            &nbsp;{{ props.row.special_tax_collection_amount ? formatNumber(props.row.special_tax_collection_amount) + $t('label.unit_yen') : '' }}
          </div>
        </template>
      </el-table-column>

      <!-- 納付額 -->
      <el-table-column width="430" :label="$t('label.payment_amount') === undefined ? '' : $t('label.payment_amount')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="display: inline-block;">
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_jun}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_jun"
                item-name="resident_tax_jun"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_jun"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_jun')"
                :disabled="disabled"
                @blur="props.row.resident_tax_jun = calc(props.row.resident_tax_jun)"
                @focus="if (props.row.resident_tax_jun) props.row.resident_tax_jun = props.row.resident_tax_jun.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_jly}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_jly"
                item-name="resident_tax_jly"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_jly"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_jly')"
                :disabled="disabled"
                @blur="props.row.resident_tax_jly = calc(props.row.resident_tax_jly)"
                @focus="if (props.row.resident_tax_jly) props.row.resident_tax_jly = props.row.resident_tax_jly.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_aug}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_aug"
                item-name="resident_tax_aug"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_aug"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_aug')"
                :disabled="disabled"
                @blur="props.row.resident_tax_aug = calc(props.row.resident_tax_aug)"
                @focus="if (props.row.resident_tax_aug) props.row.resident_tax_aug = props.row.resident_tax_aug.replaceAll(',', '')"
              />
            </div>
            <div style="display: inline-block;">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_sep}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_sep"
                item-name="resident_tax_sep"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_sep"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_sep')"
                :disabled="disabled"
                @blur="props.row.resident_tax_sep = calc(props.row.resident_tax_sep)"
                @focus="if (props.row.resident_tax_sep) props.row.resident_tax_sep = props.row.resident_tax_sep.replaceAll(',', '')"
              />
            </div>
          </div>
          <div style="display: inline-block;">
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_oct}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_oct"
                item-name="resident_tax_oct"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_oct"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_oct')"
                :disabled="disabled"
                @blur="props.row.resident_tax_oct = calc(props.row.resident_tax_oct)"
                @focus="if (props.row.resident_tax_oct) props.row.resident_tax_oct = props.row.resident_tax_oct.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_nov}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_nov"
                item-name="resident_tax_nov"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_nov"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_nov')"
                :disabled="disabled"
                @blur="props.row.resident_tax_nov = calc(props.row.resident_tax_nov)"
                @focus="if (props.row.resident_tax_nov) props.row.resident_tax_nov = props.row.resident_tax_nov.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_dec}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_dec"
                item-name="resident_tax_dec"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_dec"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_dec')"
                :disabled="disabled"
                @blur="props.row.resident_tax_dec = calc(props.row.resident_tax_dec)"
                @focus="if (props.row.resident_tax_dec) props.row.resident_tax_dec = props.row.resident_tax_dec.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_jan}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_jan"
                item-name="resident_tax_jan"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_jan"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_jan')"
                :disabled="disabled"
                @blur="props.row.resident_tax_jan = calc(props.row.resident_tax_jan)"
                @focus="if (props.row.resident_tax_jan) props.row.resident_tax_jan = props.row.resident_tax_jan.replaceAll(',', '')"
              />
            </div>
          </div>
          <div style="display: inline-block;">
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_feb}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_feb"
                item-name="resident_tax_feb"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_feb"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_feb')"
                :disabled="disabled"
                @blur="props.row.resident_tax_feb = calc(props.row.resident_tax_feb)"
                @focus="if (props.row.resident_tax_feb) props.row.resident_tax_feb = props.row.resident_tax_feb.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_mar}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_mar"
                item-name="resident_tax_mar"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_mar"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_mar')"
                :disabled="disabled"
                @blur="props.row.resident_tax_mar = calc(props.row.resident_tax_mar)"
                @focus="if (props.row.resident_tax_mar) props.row.resident_tax_mar = props.row.resident_tax_mar.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_apl}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_apl"
                item-name="resident_tax_apl"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_apl"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_apl')"
                :disabled="disabled"
                @blur="props.row.resident_tax_apl = calc(props.row.resident_tax_apl)"
                @focus="if (props.row.resident_tax_apl) props.row.resident_tax_apl = props.row.resident_tax_apl.replaceAll(',', '')"
              />
            </div>
            <div class="form-group">
              <div style="display: inline-block; width: 40px; text-align: right; padding-right: 5px;">
                <label>{{labels.resident_tax_may}}</label>
              </div>
              <ValidationText
                v-model="props.row.resident_tax_may"
                item-name="resident_tax_sep"
                max-length="6"
                classes="form-control numLength6 text-right"
                :item-label="labels.resident_tax_may"
                :placeholder="disabled ? '' : $t('placeholder.resident_tax_may')"
                :disabled="disabled"
                @blur="props.row.resident_tax_may = calc(props.row.resident_tax_may)"
                @focus="if (props.row.resident_tax_may) props.row.resident_tax_may = props.row.resident_tax_may.replaceAll(',', '')"
              />
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 備考／その他 -->
      <el-table-column width="170" :label="labels.remarks_others === undefined ? '' : labels.remarks_others">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <ValidationTextArea
            v-model="props.row.remarks_others"
            item-name="remarks_others"
            max-length="100"
            classes="form-control width150"
            :item-label="labels.remarks_others"
            :placeholder="disabled ? '' : $t('placeholder.remarks_others')"
            :disabled="disabled"
            :rows="8"
          />
        </template>
      </el-table-column>
      
      <!-- 課税 -->
      <el-table-column width="120" :label="labels.is_taxable === undefined ? '' : labels.is_taxable" align="center">
        <template slot-scope="props">
          <template v-if="!props.row.isFixed">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.is_taxable" class="gray" @change="calc" :disabled="disabled">
            </div>
          </template>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationText2 from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationSelect,
      ValidationText,
      ValidationText2,
      ValidationTextArea,
      EmployeeSearch
    },
    props: {
      value: Object,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object,
      comboData: Object,
      errors: Array
    },
    data () {
      return {
        residentTax: {
          prefectures_before: undefined
        },
        detail: {
          employee_resident_tax_list: []
        },
        selectedIndex: 0,
        searchCond: {
          search_type: '1'
        },
        copied: false
      }
    },
    methods: {
      prefecturesChanged () {
        if (this.residentTax.prefectures_before) {
          this.detail.city = ''
        }
        if (this.detail.prefectures) {
          this.residentTax.prefectures_before = this.detail.prefectures
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.detail.prefectures]) {
            for (const prefecture of this.comboData.prefectures_list) {
              if (prefecture.label === this.detail.prefectures) {
                this.detail.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.detail.prefectures]) {
            for (const code of this.comboData.mapping_list[this.detail.prefectures]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
            this.comboData.city_list.unshift({label: '', value: ''})
          }
        } else {
          this.comboData.city_list = []
        }
      },
      addRow () {
        this.detail.employee_resident_tax_list.push({
          is_taxable: true,
          new_row: true
        })
      },
      showEmployeeSearch (index) {
        this.selectedIndex = index
        this.$modal.show('employeeSearch')
      },
      hideEmployeeSearch (selected) {
        if (selected) {
          this.detail.employee_resident_tax_list[this.selectedIndex].employee_code = selected.employee_code
          this.detail.employee_resident_tax_list[this.selectedIndex].employee_name = selected.employee_name
          this.detail.employee_resident_tax_list[this.selectedIndex].state_name = selected.state_name
          this.detail.employee_resident_tax_list[this.selectedIndex].municipality_name = selected.municipality_name
          this.detail.employee_resident_tax_list[this.selectedIndex].town = selected.town
          this.detail.employee_resident_tax_list[this.selectedIndex].building = selected.building
          this.detail.employee_resident_tax_list = [...this.detail.employee_resident_tax_list]
        }
        this.$modal.hide('employeeSearch')
      },
      copyPreviousYear () {
        this.$refs.observer2.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }
          let data = {
            screen_code: this.detail.screen_code,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              target_year: String(parseInt(this.detail.target_year, 10) - 1),
              local_government_code: this.detail.local_government_code,
              state_code: this.detail.local_government_code ? undefined : this.detail.prefectures,
              municipality_code: this.detail.local_government_code ? undefined : this.detail.city.split('_')[1]
            }
          }
          this.send('/init/resident_tax_maintenance', data)
          .then(res => {
            for (const row of res.api_area.employee_resident_tax_list) {
              this.detail.employee_resident_tax_list.push({
                ...row,
                resident_tax_jan: this.formatNumber(row.resident_tax_jan),
                resident_tax_feb: this.formatNumber(row.resident_tax_feb),
                resident_tax_mar: this.formatNumber(row.resident_tax_mar),
                resident_tax_apl: this.formatNumber(row.resident_tax_apl),
                resident_tax_may: this.formatNumber(row.resident_tax_may),
                resident_tax_jun: this.formatNumber(row.resident_tax_jun),
                resident_tax_jly: this.formatNumber(row.resident_tax_jly),
                resident_tax_aug: this.formatNumber(row.resident_tax_aug),
                resident_tax_sep: this.formatNumber(row.resident_tax_sep),
                resident_tax_oct: this.formatNumber(row.resident_tax_oct),
                resident_tax_nov: this.formatNumber(row.resident_tax_nov),
                resident_tax_dec: this.formatNumber(row.resident_tax_dec),
                new_row: true
              })
            }
            this.copied = true
            this.calc()
            this.componentKey += 1
          })
        })
      },
      copyJulyAll () {
        for (const row of this.detail.employee_resident_tax_list) {
          if (!row.resident_tax_jly || isNaN(row.resident_tax_jly.replaceAll(',', ''))) continue
          row.resident_tax_aug = row.resident_tax_jly
          row.resident_tax_sep = row.resident_tax_jly
          row.resident_tax_oct = row.resident_tax_jly
          row.resident_tax_nov = row.resident_tax_jly
          row.resident_tax_dec = row.resident_tax_jly
          row.resident_tax_jan = row.resident_tax_jly
          row.resident_tax_feb = row.resident_tax_jly
          row.resident_tax_mar = row.resident_tax_jly
          row.resident_tax_apl = row.resident_tax_jly
          row.resident_tax_may = row.resident_tax_jly
        }
        this.calc()
      },
      copyJuly (row) {
        if (!row.resident_tax_jly || isNaN(row.resident_tax_jly.replaceAll(',', ''))) return
        row.resident_tax_aug = row.resident_tax_jly
        row.resident_tax_sep = row.resident_tax_jly
        row.resident_tax_oct = row.resident_tax_jly
        row.resident_tax_nov = row.resident_tax_jly
        row.resident_tax_dec = row.resident_tax_jly
        row.resident_tax_jan = row.resident_tax_jly
        row.resident_tax_feb = row.resident_tax_jly
        row.resident_tax_mar = row.resident_tax_jly
        row.resident_tax_apl = row.resident_tax_jly
        row.resident_tax_may = row.resident_tax_jly
        this.calc()
      },
      toNumber (value = 0) {
        return !value || isNaN(value.replaceAll(',', '')) ? 0 : Number(value.replaceAll(',', ''))
      },
      toCount (value) {
        return !value || isNaN(value.replaceAll(',', '')) || value.replaceAll(',', '') === 0 ? 0 : 1
      },
      calc (value) {
        this.detail.special_tax_collection_amount = 0
        this.detail.number_of_taxable_employees = 0
        this.detail.number_of_untaxable_employees = 0
        this.detail.number_of_employees_jun = 0
        this.detail.payment_amount_jun = 0
        this.detail.number_of_employees_jly = 0
        this.detail.payment_amount_jly = 0
        this.detail.number_of_employees_aug = 0
        this.detail.payment_amount_aug = 0
        this.detail.number_of_employees_sep = 0
        this.detail.payment_amount_sep = 0
        this.detail.number_of_employees_oct = 0
        this.detail.payment_amount_oct = 0
        this.detail.number_of_employees_nov = 0
        this.detail.payment_amount_nov = 0
        this.detail.number_of_employees_dec = 0
        this.detail.payment_amount_dec = 0
        this.detail.number_of_employees_jan = 0
        this.detail.payment_amount_jan = 0
        this.detail.number_of_employees_feb = 0
        this.detail.payment_amount_feb = 0
        this.detail.number_of_employees_mar = 0
        this.detail.payment_amount_mar = 0
        this.detail.number_of_employees_apl = 0
        this.detail.payment_amount_apl = 0
        this.detail.number_of_employees_may = 0
        this.detail.payment_amount_may = 0
        for (const row of this.detail.employee_resident_tax_list) {
          if (row.selected) continue
          row.special_tax_collection_amount = 0
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_jun)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_jly)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_aug)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_sep)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_oct)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_nov)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_dec)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_jan)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_feb)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_mar)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_apl)
          row.special_tax_collection_amount += this.toNumber(row.resident_tax_may)
          this.detail.special_tax_collection_amount += row.special_tax_collection_amount
          if (row.is_taxable) {
            this.detail.number_of_taxable_employees += 1
            this.detail.number_of_employees_jun += this.toCount(row.resident_tax_jun)
            this.detail.payment_amount_jun += this.toNumber(row.resident_tax_jun)
            this.detail.number_of_employees_jly += this.toCount(row.resident_tax_jly)
            this.detail.payment_amount_jly += this.toNumber(row.resident_tax_jly)
            this.detail.number_of_employees_aug += this.toCount(row.resident_tax_aug)
            this.detail.payment_amount_aug += this.toNumber(row.resident_tax_aug)
            this.detail.number_of_employees_sep += this.toCount(row.resident_tax_sep)
            this.detail.payment_amount_sep += this.toNumber(row.resident_tax_sep)
            this.detail.number_of_employees_oct += this.toCount(row.resident_tax_oct)
            this.detail.payment_amount_oct += this.toNumber(row.resident_tax_oct)
            this.detail.number_of_employees_nov += this.toCount(row.resident_tax_nov)
            this.detail.payment_amount_nov += this.toNumber(row.resident_tax_nov)
            this.detail.number_of_employees_dec += this.toCount(row.resident_tax_dec)
            this.detail.payment_amount_dec += this.toNumber(row.resident_tax_dec)
            this.detail.number_of_employees_jan += this.toCount(row.resident_tax_jan)
            this.detail.payment_amount_jan += this.toNumber(row.resident_tax_jan)
            this.detail.number_of_employees_feb += this.toCount(row.resident_tax_feb)
            this.detail.payment_amount_feb += this.toNumber(row.resident_tax_feb)
            this.detail.number_of_employees_mar += this.toCount(row.resident_tax_mar)
            this.detail.payment_amount_mar += this.toNumber(row.resident_tax_mar)
            this.detail.number_of_employees_apl += this.toCount(row.resident_tax_apl)
            this.detail.payment_amount_apl += this.toNumber(row.resident_tax_apl)
            this.detail.number_of_employees_may += this.toCount(row.resident_tax_may)
            this.detail.payment_amount_may += this.toNumber(row.resident_tax_may)
          } else {
            this.detail.number_of_untaxable_employees += 1
          }
        }
        this.detail = {...this.detail}
        this.detail.employee_resident_tax_list = [...this.detail.employee_resident_tax_list]
        this.$emit('input', this.detail)
        return this.formatNumber(value)
      }
    },
    watch: {
      value () {
        this.detail = this.value
      }
    },
    mounted () {
      this.detail = this.value
      this.detail.title = this.detail.target_year ? this.detail.target_year + this.$t('label.unit_year') + ' ' + this.detail.prefectures + this.detail.city : ''
      if (!this.labels.resident_tax_jan) return
      // this.labels.designated_number = '指定番号'
      // this.labels.addressee_number = '宛名番号'
      // this.labels.resident_municipality_code = '住民税市町村コード'
      // this.labels.beneficiary_number = '受給者番号'
      // this.labels.is_taxable = '課税'
      this.labels.resident_tax_jan = this.labels.resident_tax_jan.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_feb = this.labels.resident_tax_feb.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_mar = this.labels.resident_tax_mar.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_apl = this.labels.resident_tax_apl.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_may = this.labels.resident_tax_may.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_jun = this.labels.resident_tax_jun.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_jly = this.labels.resident_tax_jly.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_aug = this.labels.resident_tax_aug.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_sep = this.labels.resident_tax_sep.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_oct = this.labels.resident_tax_oct.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_nov = this.labels.resident_tax_nov.replace(this.labels.residentTax, '').trim()
      this.labels.resident_tax_dec = this.labels.resident_tax_dec.replace(this.labels.residentTax, '').trim()
      // this.labels.remarks_others = '備考／その他'
      this.labels.special_tax_collection_amount = '特別徴収税額'
      // this.labels.payment_amount = '納付額'
      this.calc()
    }
  }
</script>
<style scoped>
.resident-tax-total tr td {
  padding: 0 10px;
}
</style>