<template>
  <div class="row">
    <div class="col-md-12">
      <form method="#" action="#">
        <ValidationObserver ref="observer">
          <div class="card" style="width: 100%;">
            <div class="card-header search-condition-title" @click="toggleSearchCondition">
              <div style="display: inline-block; width: 49%;">
                <h4 class="card-title">
                  {{$t('label.search_condition')}}
                </h4>
              </div>
              <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
                <template v-if="showSearchCondition">
                  <span class="ti-angle-up pull-right"></span>
                </template>
                <template v-else>
                  <span class="ti-angle-down pull-right"></span>
                </template>
              </div>
            </div>
            <div class="card-content" v-if="showSearchCondition">
              <div class="form-group" v-if="false">
                <!-- 検索条件ラジオボタン 4=役割（役職）で絞り込み 5=画面で絞り込み -->
                <p-radio v-model="searchCond.api_area.search_type" label="4" :inline="true">{{labels.role ?  $t('label.filter_by').replace('{item}', labels.role) : ''}}</p-radio>
                <p-radio v-model="searchCond.api_area.search_type" label="5" :inline="true">{{labels.screen ?  $t('label.filter_by').replace('{item}', labels.screen) : ''}}</p-radio>
              </div>
              <div class="form-group" style="vertical-align: top;">
                <!-- 役割（役職）プルダウン(検索条件：4の場合のみ表示) -->
                <template v-if="searchCond.api_area.search_type=='4'">  
                  <div class="form-group search-condition-item" style="vertical-align: top; margin-bottom: 0;">
                    <label>{{labels.role}}</label>
                    <div>
                      <el-select class="select-success width300"
                                  size="large"
                                  placeholder=""
                                  v-model="searchCond.api_area.role_code">
                        <el-option v-for="role in currentRoleList"
                                    class="select-success"
                                    :value="role.value"
                                    :label="role.label"
                                    :key="role.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
                <!-- メニュー名プルダウン、画面名プルダウン(検索条件：5の場合のみ表示) -->
                <template v-else-if="searchCond.api_area.search_type==='5'">
                  <!-- メニュー名 -->
                  <ValidationSelect
                    v-model="menu_code"
                    item-name="menu_code"
                    :placeholder="$t('label.select_description')"
                    classes="length14"
                    :item-label="menuLabel"
                    :options="menuList"
                    rules="required"
                    frame-class="search-condition-item inline-block"
                  />
                  <!-- 画面名 -->
                  <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
                    <label>{{labels.screen}}</label>
                    <div>
                      <el-select class="select-success width180"
                                  size="large"
                                  placeholder=""
                                  v-model="searchCond.api_area.search_screen_code">
                        <el-option v-for="screen in viewScreenList"
                                    class="select-success"
                                    :value="screen.value"
                                    :label="screen.label"
                                    :key="screen.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </div>

              <!-- 検索ボタン -->
              <template v-if="labels.search === 1">
                <div style="text-align: left;">
                  <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                    <span class="btn-label"><i class="ti-search"></i></span>
                    {{$t('button.search')}}
                  </button>
                </div>
              </template>
            </div>
          </div>
        </ValidationObserver>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <!-- 検索結果が1件以上の場合、以下を表示 -->
    <template v-if="results.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content row">
            <!-- 検索結果のタイトル毎に表示 -->
            <div class="col-sm-12" style="margin-bottom: 20px;" v-for="data in results" v-bind:key="data.title1">
              <!-- タイトル名 -->
              <p>{{data.title1}}</p>
              <div class="col-sm-12" style="margin-left: 20px;" v-for="result in data.data1" v-bind:key="result.title2">
                <p>{{result.title2}}</p>
                <!-- タイトルに紐付くデータを表で表示 -->
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                          :data="result.data2"
                          style="width: 1020px; margin-left: 20px; margin-bottom: 20px;">
                  <template>
                    <!-- 画面名 -->
                    <el-table-column min-width="110" :label="labels ? dispType=='4' ? labels.screen : labels.role : ''">
                      <template slot-scope="props">
                        {{props.row.title3}}
                      </template>
                    </el-table-column>
                    <!-- 登録 -->
                    <el-table-column min-width="60" :label="$t('button.add')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.entry" class="gray" v-show="props.row.authority.entry_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 更新 -->
                    <el-table-column min-width="60" :label="$t('button.update')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.update" class="gray" v-show="props.row.authority.update_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 削除 -->
                    <el-table-column min-width="60" :label="$t('button.delete')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.delete" class="gray" v-show="props.row.authority.delete_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- PDF作成 -->
                    <el-table-column min-width="60" :label="$t('button.print')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.print" class="gray" v-show="props.row.authority.print_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 検索 -->
                    <el-table-column min-width="60" :label="$t('button.search')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.search" class="gray" v-show="props.row.authority.search_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- アップロード -->
                    <el-table-column min-width="70" :label="$t('button.upload')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.upload" class="gray" v-show="props.row.authority.upload_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- ダウンロード -->
                    <el-table-column min-width="70" :label="$t('button.download')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.download" class="gray" v-show="props.row.authority.download_availability" />
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 確認 -->
                    <el-table-column min-width="60" :label="$t('button.view')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.authority.preview" class="gray" v-show="props.row.authority.preview_availability" />
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  // 役割登録用モーダルインポート
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      ValidationSelect
    },
    props: {
      value: Array,
      paramRoleCode: String,
      paramRoleName: String,
      roleList: Array,
      menuList: Array,
      screenList: Array,
      labels: Object,
      parentMessage: Object
    },
    computed: {
      currentRoleList () {
        return this.roleList ? this.roleList : [{label: this.paramRoleName, value: this.paramRoleCode}]
      }
    },
    data () {
      return {
        showSearchCondition: true,
        searchLabels: {},
        results: [],
        searchCond: {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 4,
            role_code: '',
            menu_code: '',
            search_screen_code: ''
          }
        },
        menu_code: '',
        roles: {},
        viewScreenList: [],
        mode: 0,
        modalRoles: [],
        permissions: [],
        modalLabels: [],
        params: {},
        nameHeader: '',
        failMsg: '',
        dispType: 0,
        menuLabel: ''
      }
    },
    watch: {
      labels () {
        this.menuLabel = this.labels.menu
      },
      value () {
        this.results = this.value
      },
      parentMessage () {
        this.message = this.parentMessage
      },
      menu_code () {
        // メニューが変更されたら画面一覧を更新する
        let viewScreenList = [
          {value: '', menu_code: '', label: ''}
        ]
        this.searchCond.api_area.search_screen_code = ''
        for (let screen of this.screenList) {
          // メニューコードが一致する場合、画面一覧に画面情報を追加
          if (screen.menu_code === this.menu_code) {
            viewScreenList.push(screen)
          }
        }
        this.viewScreenList = viewScreenList
      }
    },
    methods: {
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      search () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          const searchType = parseInt(this.searchCond.api_area.search_type, 10)
          if (searchType === 4 && this.searchCond.api_area.role_code === '') {
            swal({
              text: this.$t('message.field_required').replace('{_field_}', this.labels.role),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            })
            return
          } else if (searchType === 5 && this.menu_code === '') {
            swal({
              text: this.$t('message.field_required').replace('{_field_}', this.labels.menu),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            })
            return
          }

          // APIに渡す検索条件を設定
          let searchCond = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              search_type: searchType,
              role_code: this.searchCond.api_area.role_code,
              menu_code: this.menu_code,
              screen_code: this.searchCond.api_area.search_screen_code
            }
          }
          // 検索API呼び出し
          this.send('/view/authority/list', searchCond)
          .then(res => {
            this.dispType = searchCond.api_area.search_type
            this.$emit('searchSucceeded', searchCond.api_area)
            this.afterSearch(res, searchCond.api_area.search_type)
            this.toggleSearchCondition()
          })
        })
      },
      afterSearch (res, searchType) {
        this.results.splice(0)
        // api_areaから検索表示用のレコードを構築
        if (searchType === 4) {
          // 役割で絞り込みの場合
          for (let searchData of res.api_area.authority_data) {
            let searchResult = {
              'title1': searchData.role_name,
              'titleKey': searchData.role_code,
              'data1': []
            }
            for (let menuData of searchData.menu_data) {
              let authorityResult = {
                'title2': this.getLabel(menuData.menu_name),
                'titleKey': menuData.menu_code,
                'data2': []
              }
              for (let screenData of menuData.screen_data) {
                let pushData = {
                  authority: {},
                  titleKey: screenData.screen_code,
                  title3: this.getLabel(screenData.screen_name),
                  update_count: screenData.update_count
                }
                // 権限を数値からフラグに変更
                pushData.authority.entry = this.numToFlg(screenData.authority.entry)
                pushData.authority.entry_availability = this.numToFlg(screenData.authority.entry_availability)
                pushData.authority.update = this.numToFlg(screenData.authority.update)
                pushData.authority.update_availability = this.numToFlg(screenData.authority.update_availability)
                pushData.authority.delete = this.numToFlg(screenData.authority.delete)
                pushData.authority.delete_availability = this.numToFlg(screenData.authority.delete_availability)
                pushData.authority.print = this.numToFlg(screenData.authority.print)
                pushData.authority.print_availability = this.numToFlg(screenData.authority.print_availability)
                pushData.authority.search = this.numToFlg(screenData.authority.search)
                pushData.authority.search_availability = this.numToFlg(screenData.authority.search_availability)
                pushData.authority.upload = this.numToFlg(screenData.authority.upload)
                pushData.authority.upload_availability = this.numToFlg(screenData.authority.upload_availability)
                pushData.authority.download = this.numToFlg(screenData.authority.download)
                pushData.authority.download_availability = this.numToFlg(screenData.authority.download_availability)
                pushData.authority.preview = this.numToFlg(screenData.authority.preview)
                pushData.authority.preview_availability = this.numToFlg(screenData.authority.preview_availability)
                authorityResult.data2.push(pushData)
              }
              searchResult.data1.push(authorityResult)
            }
            this.results.push(searchResult)
          }
          this.nameHeader = this.labels.label_screen_name
        } else if (searchType === 5) {
          // 画面で絞り込みの場合
          for (let searchData of res.api_area.authority_data) {
            let searchResult = {
              'title1': this.getLabel(searchData.menu_name),
              'titleKey': searchData.menu_code,
              'data1': []
            }
            for (let screenData of searchData.screen_data) {
              let authorityResult = {
                'title2': this.getLabel(screenData.screen_name),
                'titleKey': screenData.screen_code,
                'data2': []
              }
              for (let roleData of screenData.role_data) {
                let pushData = {
                  authority: {},
                  titleKey: roleData.role_code,
                  title3: roleData.role_name,
                  update_count: roleData.update_count
                }
                // 権限を数値からフラグに変更
                pushData.authority.entry = this.numToFlg(roleData.authority.entry)
                pushData.authority.entry_availability = this.numToFlg(roleData.authority.entry_availability)
                pushData.authority.update = this.numToFlg(roleData.authority.update)
                pushData.authority.update_availability = this.numToFlg(roleData.authority.update_availability)
                pushData.authority.delete = this.numToFlg(roleData.authority.delete)
                pushData.authority.delete_availability = this.numToFlg(roleData.authority.delete_availability)
                pushData.authority.print = this.numToFlg(roleData.authority.print)
                pushData.authority.print_availability = this.numToFlg(roleData.authority.print_availability)
                pushData.authority.search = this.numToFlg(roleData.authority.search)
                pushData.authority.search_availability = this.numToFlg(roleData.authority.search_availability)
                pushData.authority.upload = this.numToFlg(roleData.authority.upload)
                pushData.authority.upload_availability = this.numToFlg(roleData.authority.upload_availability)
                pushData.authority.download = this.numToFlg(roleData.authority.download)
                pushData.authority.download_availability = this.numToFlg(roleData.authority.download_availability)
                pushData.authority.preview = this.numToFlg(roleData.authority.preview)
                pushData.authority.preview_availability = this.numToFlg(roleData.authority.preview_availability)
                authorityResult.data2.push(pushData)
              }
              searchResult.data1.push(authorityResult)
            }
            this.results.push(searchResult)
          }
          this.nameHeader = this.labels.label_role
        }
        if (res.common_area.combo_data.menu_screen_employee_list) {
          const employeeDashboardList = []
          for (const row of this.$store.state.dashboardList) {
            if (row.sort_number > 0) {
              employeeDashboardList.push(row)
            }
          }
          const sidebarLinks = this.getSidebarLinks(this, 2,
            res.common_area.combo_data.menu_screen_employee_list, employeeDashboardList, this.$store.state.dbList)
          const loginInfo = this.$store.state.loginUser
          loginInfo.sidebarLinks = sidebarLinks
          this.$store.commit('save', loginInfo)
        }
      },
      numToFlg (num) {
        if (num === 1) {
          return true
        } else {
          return false
        }
      }
    },
    created () {
      this.results = this.value
      this.message = this.parentMessage
      // 起動時にパラメータを受け取っていた場合、役割に値をセットして検索する
      if (this.paramRoleCode !== undefined && this.paramRoleCode !== '') {
        this.searchCond.api_area.search_type = '4'
        this.searchCond.api_area.role_code = this.paramRoleCode
        this.search()
        this.nameHeader = this.labels.label_screen_name
      } else {
        this.searchCond.api_area.search_type = '5'
      }
    }
  }
</script>

<style>
</style>
