import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
export default {
  name: 'BossMixin',
  mixins: [mixin],
  components: {
    EmployeeSearch
  },
  props: {
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        boss_data: []
      },
      currentIndex: 0,
      labels: {},
      comboData: {},
      mapping_company_and_group: [],
      mapping_group_and_employee: [],
      tempmessage: ''
    }
  },
  computed: {
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) { },
    // 独自バリデーション用
    customValidate (bossData) {
      let pk = {}
      for (const boss of bossData) {
        // 上司情報チェック
        if (boss.boss_company_code === '') {
          return this.$t('message.field_required').replace('{_field_}', this.$t('label.boss_company'))
        } else if (boss.boss_employee_code === '') {
          return this.$t('message.field_required').replace('{_field_}', this.$t('label.boss_employee'))
        } else if (boss.boss_group_code === '') {
          return this.$t('message.field_required').replace('{_field_}', this.$t('label.boss_group'))
        }
        // 主キー重複チェック
        if (pk[boss.group_code] | pk[boss.group_code] === '') {
          if (pk[boss.group_code] === boss.application_form_code) {
            return this.$t('message.boss_pk_check')
          }
        }
        pk[boss.group_code] = boss.application_form_code
      }
      return null
    },
    // 送信データ調整用
    convertDetail (detail) {
      let result = {
        boss_data: []
      }
      for (const tmp of detail.boss_data) {
        var delFlg = tmp.del_flag
        // 変更がなかったらfalse
        if (delFlg === null || delFlg === undefined) {
          delFlg = false
          // trueなら次の要素
        } else if (delFlg) {
          continue
        }
        // 必要なものだけ設定
        let bossData = {
          group_code: tmp.group_code,
          application_form_code: tmp.application_form_code,
          boss_company_code: tmp.boss_company_code,
          boss_group_code: tmp.boss_group_code,
          boss_employee_code: tmp.boss_employee_code,
          del_flag: delFlg
        }
        result.boss_data.push(bossData)
      }
      return result
    },
    showApproverSearch (index) {
      this.currentIndex = index
      this.$modal.show('approver')
    },
    hideApproverSearch (selected) {
      if (selected) {
        this.detail.boss_data[this.currentIndex].boss_group_code = selected.group_list[0].group_code
        this.detail.boss_data[this.currentIndex].boss_group_name = selected.group_list[0].group_name
        this.detail.boss_data[this.currentIndex].boss_employee_code = selected.employee_code
        this.detail.boss_data[this.currentIndex].boss_employee_name = selected.employee_name
        this.detail.boss_data[this.currentIndex].group_list = selected.group_list
        this.componentKey += 1
      }
      this.$modal.hide('approver')
    },
    addBossRow () {
      // 自社をデフォルトで設定
      let bossRow = {
        group_code: '',
        application_form_code: '',
        boss_company_code: this.$store.state.loginUser.companyCode,
        boss_group_code: '',
        boss_employee_code: '',
        del_flag: false
      }
      if (this.comboData.employee_group_list.length === 2) {
        bossRow.group_code = this.comboData.employee_group_list[1].value
      }
      this.detail.boss_data.unshift(bossRow)
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let detail = null
        detail = {...this.detail}
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.nullToBlank(this.convertDetail(detail))
        }
        // 独自バリデーション
        const message = this.customValidate(reqData.api_area.boss_data)
        if (message !== null) {
          this.message.text = message
          return
        }
        this.send(`/${operation}/boss`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            // 更新のコメントを退避
            this.tempmessage = this.message.text
            this.isChanged = true
            // 再描画
            this.init()
            this.isChanged = false
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/init/boss/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        // 更新時のコメントを表示
        if (this.tempmessage) {
          this.message.text = this.tempmessage
        }
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        // 部署リストの先頭にブランクを追加
        this.comboData.employee_group_list.unshift({ 'label': '', 'value': '' })
        // 申請書リストの先頭にブランクを追加
        this.comboData.application_form_list.unshift({ 'label': '', 'value': '' })
        // マッピングリストを設定
        this.mapping_company_and_group = res.common_area.combo_data.company_list.mapping_company_and_group
        this.mapping_group_and_employee = res.common_area.combo_data.company_list.mapping_group_and_employee
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    // 上司の従業員活性判定
    isEmployee (index) {
      return this.detail.boss_data[index].boss_company_code === ''
    },
    // 上司の部署活性判定
    isGroup (index) {
      return this.detail.boss_data[index].boss_employee_code === ''
    },
    // 上司の部署リスト取得
    getBossGrupList (index) {
      let groupList = this.detail.boss_data[index].group_list

      // 部署リストが存在する場合、部署リストを返却する。
      if (groupList) {
        return groupList
      }
      groupList = []
      let groupInfo = []
      // 会社と部署のマッピングリストから部署リストを取得する。
      for (const mappingList of this.mapping_company_and_group) {
        if (mappingList.company_code === this.detail.boss_data[index].boss_company_code) {
          groupInfo = mappingList.group_info
          break
        }
      }
      // 取得した部署リストから従業員が所属している部署リストを作成する。
      for (const group of groupInfo) {
        for (const employeeCode of this.mapping_group_and_employee[group.group_code]) {
          if (employeeCode === this.detail.boss_data[index].boss_employee_code) {
            groupList.push({ 'group_code': group.group_code, 'group_name': group.group_name })
          }
        }
      }
      return groupList
    },
    // 上司の会社変更
    changeBossCompany (index) {
      this.detail.boss_data[index].boss_group_code = ''
      this.detail.boss_data[index].boss_group_code = ''
      this.detail.boss_data[index].boss_employee_code = ''
      this.detail.boss_data[index].boss_employee_name = ''
    },
    // 上司の従業員入力
    approverSelected (index, searchType) {
      if ((searchType === 1 && this.detail.boss_data[index].boss_employee_code.length === 0) ||
        (searchType === 2 && this.detail.boss_data[index].boss_employee_name.length === 0)) {
        this.detail.boss_data[index].boss_group_code = ''
        this.detail.boss_data[index].boss_group_name = ''
        this.detail.boss_data[index].boss_employee_code = ''
        this.detail.boss_data[index].boss_employee_name = ''
        this.detail.boss_data[index].group_list = []
        return
      }
      if (!this.canShow('/SS/menuSSScreen018')) return
      let searchCond = {
        screen_code: 'SS-018',
        company_code: this.detail.boss_data[index].boss_company_code,
        employee_code: this.$store.state.loginUser.employeeCode,
        group_code: this.$store.state.loginUser.groupCode,
        api_area: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: '',
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          all_flag: true
        }
      }
      if (searchType === 1) {
        searchCond.api_area.employee_code = this.detail.boss_data[index].boss_employee_code
      } else {
        searchCond.api_area.employee_name = this.detail.boss_data[index].boss_employee_name
      }
      this.send('/view/search_employee/list', searchCond, false)
      .then(res => {
        if (res.api_area.employee_list.length === 1) {
          this.currentIndex = index
          this.hideApproverSearch(res.api_area.employee_list[0])
        } else {
          this.detail.boss_data[index].boss_group_code = ''
          this.detail.boss_data[index].boss_group_name = ''
          this.detail.boss_data[index].boss_employee_code = ''
          this.detail.boss_data[index].boss_employee_name = ''
          this.detail.boss_data[index].group_list = []
        }
      })
    }
  },
  created () {
    this.init()
  }
}
