<template>
  <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules ? rules + '|postalCode' : ''" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <div>
      <input 
        :type="type" 
        :name="itemName"
        :placeholder="placeholder"
        :class="classes" 
        style="display: inline-block;"
        v-model="itemValue" 
        :maxlength="maxLength"
        :disabled="disabled"
        @blur="$emit('input', itemValue)"
        @keypress.enter="$emit('input', itemValue)">
      <template v-if="viewButton">
        &nbsp;&nbsp;<button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-primary" @click="$emit('search', itemValue)">{{buttonLabel=='' ? $t('button.postal_code_search') : buttonLabel}}</button>
        &nbsp;&nbsp;<a href="https://www.post.japanpost.jp/zipcode/" target="_blank" style="color: #0000EE;">日本郵便の公式サイト</a>
      </template>
      <small class="text-danger" v-show="!valid">
        <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
        {{ getMessage(errors[0]) }}
      </small>
    </div>
  </ValidationProvider>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        require: false,
        'default': 'text'
      },
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      buttonLabel: {
        type: String,
        require: false,
        'default': ''
      },
      viewButton: {
        type: Boolean,
        require: false,
        'default': true
      }
    },
    data () {
      return {
        itemValue: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>