<template>
  <div class="user">
    <div class="photo">
      <img :src="iconUrl" />
    </div>
    <div class="info">
      <a data-toggle="collapse" @click="toggleMenu" href="javascript:void(0)">
        <span>
          {{employeeName}}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <li>
              <a href="javascript:void(0)" @click="goEmployee">
                <span class="sidebar-mini">{{$t('label.employee_info_ryaku')}}</span>
                <span class="sidebar-normal">{{$t('label.employee_info')}}</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="logout">
                <span class="sidebar-mini">{{$t('label.logout_ryaku')}}</span>
                <span class="sidebar-normal">{{$t('label.logout')}}</span>
              </a>
            </li>
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    computed: {
      iconUrl () {
        if (this.$store.state.iconUrl.length > 0) {
          return this.$store.state.iconUrl
        } else {
          if (this.$store.state.loginUser.sex === 1) {
            return 'static/img/faces/man.gif'
          } else if (this.$store.state.loginUser.sex === 2) {
            return 'static/img/faces/woman.gif'
          } else {
            return 'static/img/faces/unknown.gif'
          }
        }
      },
      employeeName () {
        return this.$store.state.loginUser.employeeName
      }
    },
    data () {
      return {
        isClosed: true
      }
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      },
      goEmployee () {
        this.$router.push({name: 'employeeInfo'})
      },
      logout () {
        this.$firebase.auth().signOut().then(() => {
          window.location = '/'
        })
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
