<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form @submit.prevent="login" method="POST" action="#">
									<div style="font-size: small;height:400px;">
											アルテミスは、クラウド環境を利用した「勤怠/給与計算システム」です。<br />
											インターネット上から会社登録をする事で、どなたでもすぐに勤怠/給与計算システムを導入して頂けます。<br />
											<br />
											主な機能としては、<br />
											<br />
											<ul>
												<li>タイムカードの打刻</li>
												<li>残業申請、休暇申請、遅刻早退申請 等の電子承認</li>
												<li>給与明細書の作成、印刷、メール配信</li>
												<li>労働三帳票（出勤簿、労働者名簿、賃金台帳）の作成</li>
												<li>各種帳票の作成、印刷</li>
											</ul>
											<br />
											があります。
									</div>
									<div class="maine_moji"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
</style>
