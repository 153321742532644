<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <modal name="agreement_parties_employee" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :is-modal="true" @close="hideAgreementPartiesEmployee"/>
      </div>
    </modal>
    <modal name="management_parties_employee" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :is-modal="true" @close="hideManagementPartiesEmployee"/>
      </div>
    </modal>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <!-- 既存の36協定書をコピーして作成 -->
            <ValidationSelect
              v-model="copy_legal_rule"
              item-name="copy_legal_rule"
              :placeholder="$t('label.select_description')"
              classes="length20"
              :item-label="$t('label.copy_legal_rule')"
              :options="legal_rule_list"
              :disabled="isReadOnly"
              @change="copyLegalRuleChanged"
            />
            <!-- 事業所 -->
            <ValidationSelect
              v-model="detail.office_code"
              item-name="office_code"
              :placeholder="$t('label.select_description')"
              classes="length10"
              rules="required"
              :item-label="labels.office_code_ryaku"
              :options="comboData.office_list"
              :disabled="isPrimaryKeyReadOnly"
              @change="officeCodeChanged"
            />
            <!-- 様式タイプ -->
            <ValidationSelect
              v-model="detail.document_style"
              item-name="document_style"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.document_style"
              :options="comboData.document_style_list"
              :disabled="isReadOnly"
              @change="documentStyleChanged"
            />
            <!-- 様式タイプが様式1の場合 -->
            <div v-show="detail.document_style==1 || detail.document_style==2">
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in commonErrors" :key="error">
                <small>{{error}}</small>
              </div>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in legalOverworkErrors" :key="error">
                <small>{{$t('label.overtime_work') + '①：' + error}}</small>
              </div>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in transformationTimeLaborErrors" :key="error">
                <small>{{$t('label.overtime_work') + '②：' + error}}</small>
              </div>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in legalHolidayOverworkErrors" :key="error">
                <small>{{$t('label.legal_holiday_overwork') + '：' + error}}</small>
              </div>
              <table border>
                <tbody>
                  <!-- 0段目 幅調整用 -->
                  <tr style="border-top:none; border-right:none; border-left:none;">
                    <td width="20"></td>
                    <td width="220"></td>
                    <td width="160"></td>
                    <td width="170"></td>
                    <td width="200"></td>
                    <td width="70"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="70"></td>
                    <td width="40"></td>
                    <td width="110"></td>
                  </tr>
                  <!-- 1段目 -->
                  <tr>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.office_type')}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.business_name')}}
                      </div>
                    </td>
                    <td colspan="5">
                      <div style="text-align: center;">
                        {{$t('label.business_address') + '（' + $t('label.tel_no') + '）'}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.agreement_validity_period')}}<span class="required">{{$t('label.required')}}</span>
                      </div>
                    </td>
                  </tr>
                  <!-- 2段目 -->
                  <tr>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{officeData.industry_small_name}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{officeData.company_office_name}}
                      </div>
                    </td>
                    <td colspan="5">
                      {{'（〒' + officeData.post_code + '）'}}<br>
                      {{officeData.address}}<br>
                      <div style="text-align: right;">
                        {{'（' + $t('label.tel_no') + '：' + officeData.tel + '）'}}
                      </div>
                    </td>
                    <!-- 協定の有効期間 -->
                    <td colspan="3">
                      <div style="text-align: center;">
                        <el-date-picker
                          type="date"
                          value-format="yyyy/MM/dd"
                          :placeholder="$t('placeholder.term_from')"
                          rules="required"
                          :disabled="isReadOnly"
                          v-model="detail.term_from"></el-date-picker>
                        <label>&#12316;</label>
                        <el-date-picker
                          type="date"
                          value-format="yyyy/MM/dd"
                          :placeholder="$t('placeholder.term_to')"
                          rules="required"
                          :disabled="isReadOnly"
                          :picker-options="futureday"
                          v-model="detail.term_to"></el-date-picker>
                      </div>
                    </td>
                  </tr>
                  <!-- 3段目 -->
                  <tr>
                    <td rowspan="12">
                      <div style="text-align: center;">
                        {{$t('label.overtime_work')}}
                      </div>
                    </td>
                    <td rowspan="4" style="background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogcmdiYSg1MSwgNTEsIDUxLCAwLjYyKTtzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');">
                    </td>
                    <td colspan="2" rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.reasons_over_work_contents_1_1')}}<br>
                        {{$t('label.reasons_over_work_contents_1_2')}}
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.business_type')}}
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.worker_count')}}<br>
                        {{'(' + $t('label.eighteen_year_old')}}<br>
                        {{$t('label.more_then') + ')'}}
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.limit_job_time')}}<br>
                        {{'（' + $t('label.one_day')+ '）'}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td colspan="7">
                      <div style="text-align: center;">
                        {{$t('label.extension_hour')}}
                      </div>
                    </td>
                  </tr>
                  <!-- 4段目 -->
                  <tr>
                    <td colspan="2" rowspan="2">
                      <div style="text-align: center;">
                        {{$t('label.one_day')}}
                      </div>
                    </td>
                    <td colspan="2" rowspan="2">
                      <div style="text-align: center;">
                        {{$t('label.one_month') + '（' + $t('label.extension_1').replace('{hour}', '45')}}<br>
                        {{$t('label.extension_2').replace('{hour}', '42') + '）'}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.one_year') + '（' + $t('label.extension_1').replace('{hour}', '360')}}<br>
                        {{$t('label.extension_2').replace('{hour}', '320') + '）'}}
                      </div>
                    </td>
                  </tr>
                  <!-- 5段目 -->
                  <tr>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.starting_date')}}<br>
                        {{'(' + $t('label.ymd') + ')'}}<br>
                        <span class="required">{{$t('label.required')}}</span>
                      </div>
                    </td>
                    <td colspan="2">
                      <!-- 起算日 -->
                      <div style="text-align: center;">
                        <ValidationDate
                          v-model="detail.starting_date"
                          item-name="starting_date"
                          :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
                          :disabled="isPrimaryKeyReadOnly"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 6段目 -->
                  <tr>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_legal')}}<br>
                        {{$t('label.over_time')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_job')}}<br>
                        {{$t('label.over_time')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_legal')}}<br>
                        {{$t('label.over_time')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_job')}}<br>
                        {{$t('label.over_time')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.limit_legal')}}<br>
                        {{$t('label.over_time')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_job')}}<br>
                        {{$t('label.over_time')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                  </tr>
                  <!-- 7～10段目 -->
                  <tr v-for="(legalOverwork, index) of legal_overwork" :key="`legalOverwork-${index}`">
                    <!-- ① 下記②に該当しない労働者 -->
                    <template v-if="index===0">
                      <td rowspan="4">
                        <div style="text-align: center;">
                          {{$t('label.overtime_work_1')}}
                        </div>
                      </td>
                    </template>
                    <!-- 時間外労働をさせる必要のある具体的事由 -->
                    <td colspan="2">
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isReadOnly"
                          maxlength="500"
                          :placeholder="isReadOnly ? '' : $t('placeholder.reasons_over_work_contents')"
                          v-model="legalOverwork.reasons_over_work_contents">
                      </div>
                    </td>
                    <!-- 業務の種類 -->
                    <td>
                      <div style="text-align: center;">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="legalOverwork.business_type"
                                    @change="businessTypeChangedLegalOverwork(index)">
                          <el-option v-for="business_type in comboData.business_list"
                                      class="select-success"
                                      :value="business_type.value"
                                      :label="business_type.label"
                                      :key="business_type.value">
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <!-- 労働者数(満18歳以上の者) -->
                    <td>
                      <div style="text-align: right;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="legalOverwork.employee_count">
                      </div>
                    </td>
                    <!-- 所定労働時間（１日） -->
                    <td>
                      <div style="text-align: right;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="legalOverwork.job_total_minutes">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                            v-model="legalOverwork.limit_legal_one_day_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_day_minutes')"
                            v-model="legalOverwork.limit_job_one_day_minutes">
                        </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_month_minutes')"
                            v-model="legalOverwork.limit_legal_one_month_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_month_minutes')"
                            v-model="legalOverwork.limit_job_one_month_minutes">
                        </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（年） -->
                    <td colspan="2">
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_year_minutes')"
                            v-model="legalOverwork.limit_legal_one_year_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（年） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_year_minutes')"
                            v-model="legalOverwork.limit_job_one_year_minutes">
                        </div>
                    </td>
                  </tr>
                  <!-- 11～14段目 -->
                  <tr v-for="(transformationTimeLabor, index) of transformation_time_labor" :key="`transformationTimeLabor-${index}`">
                    <!-- ① 下記②に該当しない労働者 -->
                    <template v-if="index===0">
                      <td rowspan="4">
                        <div style="text-align: center;">
                          {{$t('label.overtime_work_2_1')}}<br>
                          {{$t('label.overtime_work_2_2')}}
                        </div>
                      </td>
                    </template>
                    <!-- 時間外労働をさせる必要のある具体的事由 -->
                    <td colspan="2">
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isReadOnly"
                          maxlength="500"
                          :placeholder="isReadOnly ? '' : $t('placeholder.reasons_over_work_contents')"
                          v-model="transformationTimeLabor.reasons_over_work_contents">
                      </div>
                    </td>
                    <!-- 業務の種類 -->
                    <td>
                      <div style="text-align: center;">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="transformationTimeLabor.business_type"
                                    @change="businessTypeChangedTransformationTimeLabor(index)">
                          <el-option v-for="business_type in comboData.business_list"
                                      class="select-success"
                                      :value="business_type.value"
                                      :label="business_type.label"
                                      :key="business_type.value">
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <!-- 労働者数(満18歳以上の者) -->
                    <td>
                      <div style="text-align: right;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="transformationTimeLabor.employee_count">
                      </div>
                    </td>
                    <!-- 所定労働時間（１日） -->
                    <td>
                      <div style="text-align: right;">
                        <input
                        type="text"
                        class="form-control"
                        style="text-align:right"
                        :disabled="isReadOnly"
                        maxlength="5"
                        :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                        v-model="transformationTimeLabor.job_total_minutes">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                            v-model="transformationTimeLabor.limit_legal_one_day_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_day_minutes')"
                            v-model="transformationTimeLabor.limit_job_one_day_minutes">
                        </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_month_minutes')"
                            v-model="transformationTimeLabor.limit_legal_one_month_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_month_minutes')"
                            v-model="transformationTimeLabor.limit_job_one_month_minutes">
                        </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（年） -->
                    <td colspan="2">
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_year_minutes')"
                            v-model="transformationTimeLabor.limit_legal_one_year_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（年） -->
                    <td>
                      <div style="text-align: center;">
                          <input
                            type="text"
                            class="form-control"
                            style="text-align:right"
                            :disabled="isReadOnly"
                            maxlength="5"
                            :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_year_minutes')"
                            v-model="transformationTimeLabor.limit_job_one_year_minutes">
                        </div>
                    </td>
                  </tr>
                  <!-- 15段目 -->
                  <tr>
                    <td rowspan="12">
                      <div style="text-align: center;">
                        {{$t('label.legal_holiday_overwork')}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.reasons_over_work_contents_2')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.business_type')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.worker_count')}}<br>
                        {{'(' + $t('label.eighteen_year_old')}}<br>
                        {{$t('label.more_then') + ')'}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.job_holiday')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.number_of_legal_holidays_allowed_to_work_1')}}<br>
                        {{$t('label.number_of_legal_holidays_allowed_to_work_2')}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.legal_holiday_job_1')}}<br>
                        {{$t('label.legal_holiday_job_2')}}
                      </div>
                    </td>
                  </tr>
                  <!-- 16～17段目 -->
                  <tr v-for="(legalHolidayOverwork, index) of legal_holiday_overwork" :key="`legalHolidayOverwork-${index}`">
                    <!-- 休日労働をさせる必要のある具体的事由 -->
                    <td colspan="3">
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control length500"
                          :disabled="isReadOnly"
                          maxlength="500"
                          :placeholder="isReadOnly ? '' : $t('placeholder.reasons_over_work_contents')"
                          v-model="legalHolidayOverwork.reasons_over_work_contents">
                      </div>
                    </td>
                    <!-- 業務の種類 -->
                    <td>
                      <div style="text-align: center;">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="legalHolidayOverwork.business_type"
                                    @change="businessTypeChangedLegalHolidayOverwork(index)">
                          <el-option v-for="business_type in comboData.business_list"
                                      class="select-success"
                                      :value="business_type.value"
                                      :label="business_type.label"
                                      :key="business_type.value">
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <!-- 労働者数(満18歳以上の者) -->
                    <td>
                      <div style="text-align: right;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="legalHolidayOverwork.employee_count">
                      </div>
                    </td>
                    <!-- 所定休日 -->
                    <td colspan="3">
                      <div style="text-align: left;">
                        <input
                          type="text"
                          class="form-control"
                          :disabled="isReadOnly"
                          maxlength="500"
                          :placeholder="isReadOnly ? '' : '土日祝日'"
                          v-model="legalHolidayOverwork.job_holiday_comment">
                      </div>
                    </td>
                    <!-- 労働させることができる法定休日の日数 -->
                    <td colspan="2">
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="2"
                          :placeholder="isReadOnly ? '' : $t('placeholder.number_of_legal_holidays_allowed_to_work')"
                          v-model="legalHolidayOverwork.number_of_legal_holidays_allowed_to_work">
                      </div>
                    </td>
                    <!-- 労働させることができる法定休日における始業及び終業の時刻 -->
                    <td colspan="3">
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="padding-right: 0.3em; width:95px; text-align: right; display:inline;"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.legal_holiday_job_start')"
                          v-model="legalHolidayOverwork.legal_holiday_job_start">
                        <label>～</label>
                        <input
                          type="text"
                          class="form-control"
                          style="padding-right: 0.3em; width:95px; text-align: right; display:inline;"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.legal_holiday_job_end')"
                          v-model="legalHolidayOverwork.legal_holiday_job_end">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
            </div>
            <!-- 様式タイプが様式2の場合 -->
            <div v-show="detail.document_style==2">
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in limitOverworkErrors" :key="error">
                <small>{{$t('label.limit_overwork') + '：' + error}}</small>
              </div>
              <table border>
                <tbody>
                  <!-- 0段目 幅調整用 -->
                  <tr style="border-top:none; border-right:none; border-left:none;">
                    <td width="400"></td>
                    <td width="200"></td>
                    <td width="70"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="130"></td>
                    <td width="130"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                    <td width="110"></td>
                  </tr>
                  <!-- 1段目 -->
                  <tr>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.reasons_over_work_contents_3')}}
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.business_type')}}
                      </div>
                    </td>
                    <td rowspan="4">
                      <div style="text-align: center;">
                        {{$t('label.worker_count')}}<br>
                        {{'(' + $t('label.eighteen_year_old')}}<br>
                        {{$t('label.more_then') + ')'}}
                      </div>
                    </td>
                    <td rowspan="2" colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.one_day')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td rowspan="2" colspan="4">
                      <div style="text-align: center;">
                        {{$t('label.one_month')}}<br>
                        {{'（' + $t('label.limit_overwork_one_month') + '）'}}
                      </div>
                    </td>
                    <td colspan="3">
                      <div style="text-align: center;">
                        {{$t('label.one_year')}}<br>
                        {{'（' + $t('label.limit_overwork_one_year_1')}}<br>
                        {{$t('label.limit_overwork_one_year_2') + '）'}}
                      </div>
                    </td>
                  </tr>
                  <!-- 2段目 -->
                  <tr>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.starting_date')}}<br>
                        {{'(' + $t('label.ymd') + ')'}}
                      </div>
                    </td>
                    <td colspan="2">
                      <!-- 起算日 -->
                      <div style="text-align: center;">
                        <ValidationDate
                          v-model="detail.starting_date"
                          item-name="starting_date"
                          :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
                          :disabled="isPrimaryKeyReadOnly"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- 3段目 -->
                  <tr>
                    <td colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.extension_hour')}}
                      </div>
                    </td>
                    <td rowspan="2">
                      <div style="text-align: center;">
                        {{$t('label.limit_overwork_count_1')}}<br>
                        {{$t('label.limit_overwork_count_2')}}<br>
                        {{$t('label.limit_overwork_count_3')}}<br>
                        <p><font size="-2">{{$t('label.limit_overwork_count_4')}}</font></p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.extension_hour')}}<br>
                        {{$t('label.extension_hour_holiday')}}
                      </div>
                    </td>
                    <td rowspan="2">
                      <div style="text-align: center;">
                        {{$t('label.limit_overwork_rate_1')}}<br>
                        {{$t('label.limit_overwork_rate_2')}}<br>
                        {{$t('label.limit_overwork_rate_3')}}
                      </div>
                    </td>
                    <td colspan="2">
                      <div style="text-align: center;">
                        {{$t('label.extension_hour')}}
                      </div>
                    </td>
                    <td rowspan="2">
                      <div style="text-align: center;">
                        {{$t('label.limit_overwork_rate_1')}}<br>
                        {{$t('label.limit_overwork_rate_2')}}<br>
                        {{$t('label.limit_overwork_rate_3')}}
                      </div>
                    </td>
                  </tr>
                  <!-- 4段目 -->
                  <tr>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_legal')}}<br>
                        {{$t('label.over_time')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_job')}}<br>
                        {{$t('label.over_time')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_overwork_time_1_legal')}}<br>
                        {{$t('label.limit_overwork_time_2')}}<br>
                        {{$t('label.limit_overwork_time_3')}}<br>
                        {{$t('label.limit_overwork_time_4')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_overwork_time_1_job')}}<br>
                        {{$t('label.limit_overwork_time_2')}}<br>
                        {{$t('label.limit_overwork_time_3')}}<br>
                        {{$t('label.limit_overwork_time_4')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_legal')}}<br>
                        {{$t('label.over_time')}}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center;">
                        {{$t('label.limit_job')}}<br>
                        {{$t('label.over_time')}}<br>
                        {{'（' + $t('label.any') + '）'}}
                      </div>
                    </td>
                  </tr>
                  <!-- 5～12段目 -->
                  <tr v-for="(limitOverwork, index) of limit_overwork" :key="`limitOverwork-${index}`">
                    <!-- 臨時的に限度時間を超えて労働させることができる場合 -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control length500"
                          :disabled="isReadOnly"
                          maxlength="500"
                          :placeholder="isReadOnly ? '' : $t('placeholder.reasons_over_work_contents')"
                          v-model="limitOverwork.reasons_over_work_contents">
                      </div>
                    </td>
                    <!-- 業務の種類 -->
                    <td>
                      <div style="text-align: center;">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="limitOverwork.business_type"
                                    @change="businessTypeChangedLimitOverwork(index)">
                          <el-option v-for="business_type in comboData.business_list"
                                      class="select-success"
                                      :value="business_type.value"
                                      :label="business_type.label"
                                      :key="business_type.value">
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <!-- 労働者数(満18歳以上の者) -->
                    <td>
                      <div style="text-align: right;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="limitOverwork.employee_count">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_day_minutes')"
                          v-model="limitOverwork.limit_legal_one_day_minutes">
                        </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（日） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_day_minutes')"
                          v-model="limitOverwork.limit_job_one_day_minutes">
                        </div>
                    </td>
                    <!-- 限度時間を超えて労働させることができる回数 -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="1"
                          :placeholder="isReadOnly ? '' : $t('placeholder.special_provisions_year_count')"
                          v-model="limitOverwork.special_provisions_year_count">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_month_minutes')"
                          v-model="limitOverwork.limit_legal_one_month_minutes_all">
                      </div>
                    </td>
                    <!-- 所定労働時間を超える時間数（月） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_month_minutes')"
                          v-model="limitOverwork.limit_job_one_month_minutes">
                        </div>
                    </td>
                    <!-- 限度時間を超えた労働に係る割増賃金率（月） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.special_provisions_rate_month')"
                          v-model="limitOverwork.special_provisions_rate_month">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（年） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_legal_one_year_minutes')"
                          v-model="limitOverwork.limit_legal_one_year_minutes">
                      </div>
                    </td>
                    <!-- 法定労働時間を超える時間数（年） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.limit_job_one_year_minutes')"
                          v-model="limitOverwork.limit_job_one_year_minutes">
                      </div>
                    </td>
                    <!-- 限度時間を超えた労働に係る割増賃金率（年） -->
                    <td>
                      <div style="text-align: center;">
                        <input
                          type="text"
                          class="form-control"
                          style="text-align:right"
                          :disabled="isReadOnly"
                          maxlength="5"
                          :placeholder="isReadOnly ? '' : $t('placeholder.special_provisions_rate_year')"
                          v-model="limitOverwork.special_provisions_rate_year">
                      </div>
                    </td>
                  </tr>
                  <!-- 13段目 -->
                  <tr>
                    <!-- 限度時間を超えて労働させる場合における手続 -->
                    <td>
                      <div style="text-align: center;">
                        限度時間を超えて労働させる場合における手続
                      </div>
                    </td>
                    <td colspan="11">
                      <input
                        type="text"
                        class="form-control"
                        :disabled="isReadOnly"
                        maxlength="100"
                        :placeholder="isReadOnly ? '' : $t('placeholder.procedure_of_exceed_the_time_limit')"
                        v-model="detail.procedure_of_exceed_the_time_limit">
                    </td>
                  </tr>
                  <!-- 14段目 -->
                  <tr>
                    <td rowspan="10">
                      <div style="text-align: center;">
                        {{$t('label.procedure_health_1')}}<br>
                        {{$t('label.procedure_health_2')}}
                      </div>
                    </td>
                    <td style="border-bottom:none;">
                      {{'（' + $t('label.applicable_number') + '）'}}
                    </td>
                    <td colspan="10" style="border-bottom:none;">
                      {{'（' + $t('label.specific_content') + '）'}}
                    </td>
                  </tr>
                  <!-- 15段目 -->
                  <tr>
                    <!-- 該当する番号 -->
                    <td style="border-top:none;">
                      <div style="text-align: center;">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="detail.procedure_health_code_1">
                          <el-option v-for="procedure_health in comboData.procedure_health_list"
                                      class="select-success"
                                      :value="procedure_health.value"
                                      :label="procedure_health.label"
                                      :key="procedure_health.value">
                          </el-option>
                        </el-select>
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="detail.procedure_health_code_2">
                          <el-option v-for="procedure_health in comboData.procedure_health_list"
                                      class="select-success"
                                      :value="procedure_health.value"
                                      :label="procedure_health.label"
                                      :key="procedure_health.value">
                          </el-option>
                        </el-select>
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isReadOnly"
                                    v-model="detail.procedure_health_code_3">
                          <el-option v-for="procedure_health in comboData.procedure_health_list"
                                      class="select-success"
                                      :value="procedure_health.value"
                                      :label="procedure_health.label"
                                      :key="procedure_health.value">
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <!-- 具体的内容 -->
                    <td colspan="10" style="border-top:none;">
                      <input
                        type="text"
                        class="form-control"
                        :disabled="isReadOnly"
                        maxlength="100"
                        :placeholder="isReadOnly ? '' : $t('placeholder.specific_content')"
                        v-model="detail.specific_content">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 様式タイプが様式3の場合 -->
            <div v-show="detail.document_style==3">
              <!-- 変形時間労働 -->
              <div class="form-group">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="addTransformationTimeLaborRow" v-show="mode==modes.add || mode==modes.edit">
                  {{$t('button.add_row')}}
                </button>
                <div style="color: #C84513; margin-bottom: 5px;" v-for="error in transformationTimeLaborErrors" :key="error">
                  <small>{{error}}</small>
                </div>
                <el-table
                  border
                  :data="transformation_time_labor"
                  :empty-text="$t('label.no_data')"
                  style="width: 230px">
                  <el-table-column
                    width="60"
                    :label="$t('label.delete')"
                    align="center">
                    <template slot-scope="props">
                      <div style="text-align: center;">
                        <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-show="detail.document_style==1 || detail.document_style==2">
              <!-- 協定の成立年月日 -->
              <ValidationDate
                v-model="detail.agreement_date"
                item-name="agreement_date"
                rules="required"
                :item-label="labels.agreement_date"
                :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
                :disabled="isReadOnly"
              />
              <!-- 協定当事者の選択方法 -->
              <ValidationRadio
                rules="required"
                v-model="agreementPartiesSelectMethod"
                :item-label="$t('label.agreement_parties_select_method')"
                @change="agreementPartiesSelectMethodChanged"
              />
              <template v-if="agreementPartiesSelectMethod.value === 1">
                <!-- 協定当事者の職名 -->
                <ValidationSelect
                  v-model="detail.agreement_parties_job_title_code"
                  item-name="agreement_parties_job_title_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.agreement_parties_job_title_name')"
                  :options="job_employee_list"
                  :disabled="isReadOnly"
                  @change="agreementPartiesJobTitleCodeChanged"
                />
                <!-- 協定当事者の従業員番号 -->
                <ValidationSelect
                  v-model="detail.agreement_parties_employee_code"
                  item-name="agreement_parties_employee_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.agreement_parties_employee')"
                  :options="getEmployeeList(detail.agreement_parties_job_title_code)"
                  :disabled="isReadOnly"
                  @change="agreementPartiesEmployeeCodeChanged"
                />
              </template>
              <template v-else>
                <!-- 協定当事者の職名 -->
                <ValidationSelect
                  v-model="detail.agreement_parties_job_title_code"
                  item-name="agreement_parties_job_title_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.agreement_parties_job_title_name')"
                  :options="comboData.job_title_list"
                  :disabled="isReadOnly"
                />
                <!-- 協定当事者の従業員番号 -->
                <ValidationEmployeeText
                  v-model="detail.agreement_parties_employee"
                  item-name="agreement_parties_employee_code"
                  :item-label="$t('label.agreement_parties_employee')"
                  :placeholder1="$t('placeholder.employee_code')"
                  :placeholder2="$t('placeholder.employee_name')"
                  :disabled="isReadOnly"
                  rules="required|employeeRequired"
                  @showEmployeeSearch="showAgreementPartiesEmployee"
                >
                </ValidationEmployeeText>
              </template>
              <!-- 選出方法 -->
              <ValidationSelect
                v-model="detail.election_method"
                item-name="election_method"
                :placeholder="$t('label.select_description')"
                classes="length"
                rules="required"
                :item-label="labels.election_method"
                :options="comboData.election_method_list"
                :disabled="isReadOnly"
                @change="electionMethodChanged"
              />
              <!-- 提出日 -->
              <ValidationDate
                v-model="detail.filing_date"
                item-name="filing_date"
                rules="required"
                :item-label="labels.filing_date"
                :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
                :disabled="isReadOnly"
              />
              <!-- 使用者の選択方法 -->
              <ValidationRadio
                rules="required"
                v-model="managementPartiesSelectMethod"
                :item-label="$t('label.management_parties_select_method')"
                @change="managementPartiesSelectMethodChanged"
              />
              <template v-if="managementPartiesSelectMethod.value === 1">
                <!-- 使用者の職名 -->
                <ValidationSelect
                  v-model="detail.management_parties_job_title_code"
                  item-name="management_parties_job_title_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.management_parties_job_title_name')"
                  :options="job_employee_list"
                  :disabled="isReadOnly"
                  @change="managementPartiesJobTitleCodeChanged"
                />
                <!-- 使用者の従業員番号 -->
                <ValidationSelect
                  v-model="detail.management_parties_employee_code"
                  item-name="management_parties_employee_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.management_parties_employee')"
                  :options="getEmployeeList(detail.management_parties_job_title_code)"
                  :disabled="isReadOnly"
                  @change="managementPartiesEmployeeCodeChanged"
                />
              </template>
              <template v-else>
                <!-- 使用者の職名 -->
                <ValidationSelect
                  v-model="detail.management_parties_job_title_code"
                  item-name="management_parties_job_title_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.management_parties_job_title_name')"
                  :options="comboData.job_title_list"
                  :disabled="isReadOnly"
                />
                <!-- 使用者の従業員番号 -->
                <ValidationEmployeeText
                  v-model="detail.management_parties_employee"
                  item-name="management_parties_employee_code"
                  :item-label="$t('label.management_parties_employee')"
                  :placeholder1="$t('placeholder.employee_code')"
                  :placeholder2="$t('placeholder.employee_name')"
                  :disabled="isReadOnly"
                  rules="required|employeeRequired"
                  @showEmployeeSearch="showManagementPartiesEmployee"
                >
                </ValidationEmployeeText>
              </template>
              <!-- 労働基準監督署 -->
              <ValidationText
                v-model="detail.labor_standards_inspection_office"
                item-name="labor_standards_inspection_office"
                max-length="50"
                classes="form-control length50"
                rules="required"
                :item-label="labels.labor_standards_inspection_office"
                :placeholder="isReadOnly ? '' : $t('placeholder.labor_standards_inspection_office')"
                :disabled="isReadOnly"
                :help-text="$t('label.max_length')"
              />
            </div>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import LegalRuleDetailMixin from '@/components/Dashboard/Views/Annual/LegalRuleDetailMixin'

  export default {
    name: 'LegalRuleDetail',
    mixins: [LegalRuleDetailMixin]
  }
</script>
<style scoped>
</style>
