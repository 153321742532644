<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="900">
        <div class="modal-body">
          <!-- 従業員検索モーダル パラメータ：検索条件 -->
          <EmployeeSearch :orgSearchCond="searchCond" :is-modal="true" @close="hideEmployeeSearch"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="paidHolidaysModal" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="900">
        <div class="modal-body">
          <!-- 有給休暇モーダル パラメータ：従業員コード -->
          <PaidHolidaysDetail :params="params"  @close="hidePaidHolidaysModal" @showWorkflow="showWorkflow"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="Workflow" :draggable="true" :clickToClose="false" :height="900" :scrollable="true" :width="1400" :pivotX="0.6">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :apply-record="applyRecord" :is-modal="true" @close="hide"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <div class="form-group search-condition-item">
              <p-radio v-model="searchCond.search_type" label="0" :inline="true">{{labels.office_name ? $t('label.filter_by').replace('{item}', labels.office) : ''}}</p-radio>
              <p-radio v-model="searchCond.search_type" label="1" :inline="true">{{labels.group_name ? $t('label.filter_by').replace('{item}', labels.group) : ''}}</p-radio>
              <p-radio v-model="searchCond.search_type" label="2" :inline="true">{{labels.team_name ? $t('label.filter_by').replace('{item}', labels.team) : ''}}</p-radio>
              <p-radio v-model="searchCond.search_type" label="3" :inline="true">{{labels.employee_name ? $t('label.filter_by').replace('{item}', labels.employee) : ''}}</p-radio>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <!-- 事業所 -->
              <template v-if="searchCond.search_type=='0'">
                <label>{{labels.office}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.office_code">
                    <el-option v-for="office in offices"
                                class="select-success"
                                :value="office.value"
                                :label="office.label"
                                :key="office.value">
                    </el-option>
                  </el-select>
                </div>
              </template>
              <!-- 部署 -->
              <template v-else-if="searchCond.search_type=='1'">
                <label>{{labels.group}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.group_code">
                    <el-option v-for="group in groups"
                                class="select-success"
                                :value="group.value"
                                :label="group.label"
                                :key="group.value">
                    </el-option>
                  </el-select>
                </div>
              </template>
              <!-- チーム -->
              <template v-else-if="searchCond.search_type=='2'">
                <label>{{labels.team}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.team_code">
                    <el-option v-for="team in teams"
                                class="select-success"
                                :value="team.value"
                                :label="team.label"
                                :key="team.value">
                    </el-option>
                  </el-select>
                </div>
              </template>
              <!-- 従業員 -->
              <template v-else-if="searchCond.search_type==='3'">
                <div class="form-group" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
                  <label>{{labels.employee_code}}</label>
                  <div>
                    <input
                      type="text"
                      class="form-control width130"
                      style="display:inline-block;"
                      v-model="searchCond.employee_code">
                  </div>
                </div>
                <div class="form-group" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
                  <label>{{labels.employee_name}}</label>
                  <div>
                    <div style="display: inline-block;">
                      <input
                        type="text"
                        class="form-control width300"
                        style="display:inline-block;"
                        v-model="searchCond.employee_name">
                    </div>
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="show" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
                  </div>
                </div>
              </template>
            </div>
            <template v-if="searchCond.search_type!=='3'">
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{labels.employee_classification_code}}</label>
                <div>
                  <el-select class="select-success width130"
                              size="large"
                              placeholder=""
                              v-model="searchCond.employee_classification_code">
                    <el-option v-for="employee_type in employee_types"
                                class="select-success"
                                :value="employee_type.value"
                                :label="employee_type.label"
                                :key="employee_type.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <!-- 今年の有給利用日数 -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
              <label>{{$t('label.label_paid_holidays_of_use_this_year')}}</label>
              <div>
                <el-select class="select-success widthMonth"
                            size="large"
                            placeholder=""
                            v-model="searchCond.days_of_use"
                            @change="synchronizeTo">
                  <el-option v-for="day in paid_holidays_of_use_this_year"
                              class="select-success"
                              :value="day"
                              :label="day"
                              :key="day">
                  </el-option>
                </el-select>
                <label>{{$t('label.label_less_than_a_day')}}</label>
              </div>
            </div>
            
            <br>
            <!-- 退職者検索用のチェックボックス -->
            <div class="form-group" style="display: inline-block; vertical-align: top;">
              <div>
                <input type="checkbox" v-model="searchCond.retirement" class="gray" />
                <label>{{$t('label.search_all_employees')}}</label>
                <!--<label>退職者を含む</label> -->
              </div>
            </div>
            <template>
              <div style="text-align: left;">
                <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </template>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <div class="col-md-12">
      <!-- 検索結果が1件以上の場合、以下を表示 -->
      <template v-if="tableData && tableData.length > 0">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content">
            <div style="margin-bottom: 10px;" v-if="labels.print === 1 && tableData.length > 0">
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="printClicked">
                {{$t('button.print')}}
              </button>
            </div>
            <el-select
              class="select-success length2"
              v-model="pagination.perPage">
              <el-option
                class="select-success"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <div style="margin-top: 10px;">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                        border
                        :data="pagedData"
                        ref="table"
                        style="width: 100%">
                <!-- 操作 -->
                <el-table-column
                  width="70"
                  :label="$t('label.operation')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="labels.preview === 1"> 
                      <button type="button" class="btn btn-sm btn-default btn-warning" @click="detail(props.row)">{{$t('button.detail')}}</button>
                    </template> 
                  </template> 
                </el-table-column>
                <!-- 従業員 -->
                <el-table-column width="130" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <font size="3"><b>{{props.row.employee_name}}</b></font>
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.employee_code}}</small>
                    </div>
                    <div style="text-align: left; line-height: 100%;" v-if="orgSearchCond.search_type!='3'">
                      <small>{{props.row.employee_classification_name}}</small>
                    </div>
                    <div style="text-align: left;" v-if="orgSearchCond.search_type=='0'">
                      <small>{{props.row.office_name}}</small>
                    </div>
                    <div style="text-align: left;" v-if="orgSearchCond.search_type=='1'">
                      <small>{{props.row.group_name}}</small>
                    </div>
                    <div style="text-align: left;" v-if="orgSearchCond.search_type=='2'">
                      <small>{{props.row.team_name}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 支給年月日 -->
                <el-table-column width="110" :label="labels.payment_date">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      {{props.row.payment_date}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 支給日数 -->
                <el-table-column width="110" :label="labels.payment_days">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      {{props.row.payment_days + $t('label.unit_day')}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 取得日数 -->
                <el-table-column width="150" :label="$t('label.consumed_days')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="display: inline-block; vertical-align: middle; width: 70px">
                      <template v-if="props.row.get_all_days >= 5">
                        <img src="/static/img/approved.png" style="width: 68px;"/>
                      </template>
                    </div>
                    <div style="display: inline-block; vertical-align: middle; width: 50px;">
                      <div style="text-align: right;">
                        <font size="3"><b>{{ props.row.get_all_days + $t('label.unit_day') }}</b></font>
                      </div>
                      <div style="text-align: right;">
                        <small>{{ props.row.get_days + $t('label.unit_day') }}</small>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- 取得時間数 -->
                <el-table-column width="110" :label="$t('label.consumed_hours')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      <font size="3"><b>{{ props.row.get_all_times + $t('label.unit_hours') }}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <small>{{ String(parseInt(props.row.get_times, 10)) + $t('label.unit_hours') }}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 残日数 -->
                <el-table-column width="100" :label="$t('label.remaining_days')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      <font size="3"><b>{{ props.row.hold_days + $t('label.unit_day') + String(parseInt(props.row.hold_times, 10)) + $t('label.unit_hours') }}</b></font>
                    </div>
                  </template>
                </el-table-column>
                <!-- 消化率 -->
                  <el-table-column width="120" :label="$t('label.label_digestion_days')"> 
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="width: 80%; margin-left: auto; margin-right: auto;">
                      <Gauge
                        :min="0"
                        :max="100"
                        v-model="props.row.num_digestion_days"
                        :threshold="100"
                        :decimals="0"
                        color="arc below-needle3"
                        :max-score="100"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 次回有給付与日 -->
                <el-table-column width="130" :label="$t('label.label_paid_reference_date')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      {{props.row.paid_reference_date}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 本日までの出勤率 -->
                <el-table-column width="120" :label="labels.attendance_rate">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="width: 80%; margin-left: auto; margin-right: auto;">
                      <Gauge
                        :min="0"
                        :max="100"
                        v-model="props.row.num_attendance_rate"
                        :threshold="100"
                        :decimals="0"
                        color="arc below-needle3"
                        :max-score="100"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from '@/components/UIComponents/Pagination'
  import PaidHolidaysDetail from '@/components/Dashboard/Views/Working/PaidHolidaysDetail'
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import Workflow from '@/components/Dashboard/Views/Working/Workflow/Workflow'
  import Gauge from '@/components/UIComponents/Inputs/Gauge'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default {
    mixins: [mixin],
    components: {
      PPagination,
      EmployeeSearch,
      PaidHolidaysDetail,
      Workflow,
      offices: [{label: '', value: ''}],
      groups: [{label: '', value: ''}],
      teams: [{label: '', value: ''}],
      employee_types: [{label: '', value: ''}],
      paid_holidays_of_use_this_year: [],
      Gauge
    },
    data () {
      return {
        applicationFormCode: '',
        applyRecord: {},
        showSearchCondition: true,
        componentKey: 0,
        results: [],
        orgSearchCond: {},
        offices: [{label: '', value: ''}],
        groups: [{label: '', value: ''}],
        teams: [{label: '', value: ''}],
        employee_types: [{label: '', value: ''}],
        paid_holidays_of_use_this_year: ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
        roles: {},
        draggable: true,
        modalWidth: 600,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        tableData: undefined,
        searchCond: {
          search_type: '3',
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: '',
          employee_name: '',
          employee_classification_code: '',
          employee_group_code: '',
          retirement: false,
          days_of_use: ''
        },
        labels: {},
        mode: 1,
        params: {},
        comboData: {}
      }
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      // イベントを処理する場合はカスタマイズエリアに実装してください。
      // 初期処理カスタマイズ用
      afterInit (res) {
        if (this.$route.params.attendanceRecord) {
          this.search()
        }
      },
      printClicked () {
        let officeCode = this.searchCond.office_code
        let teamCode = this.searchCond.team_code
        let employeeCode = this.searchCond.employee_code
        let employeeName = this.searchCond.employee_name
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.searchCond.search_type, 10),
            office_code: officeCode,
            team_code: teamCode,
            employee_code: employeeCode,
            employee_name: employeeName,
            employee_classification_code: this.searchCond.employee_classification_code,
            employee_group_code: this.searchCond.group_code,
            retirement: this.searchCond.retirement,
            days_of_use: this.searchCond.days_of_use
          }
        }
        this.send('/print/paid_leave/list', params)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      },
      synchronizeTo () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
        if (from > thisMonth) {
          this.searchCond.from_year = this.currentYear
          this.searchCond.from_month = this.currentMonth
        }
        if (to > thisMonth) {
          this.searchCond.to_year = this.currentYear
          this.searchCond.to_month = this.currentMonth
        }
        if (from > to) {
          this.searchCond.from_year = this.searchCond.to_year
          this.searchCond.from_month = this.searchCond.to_month
        }
      },
      // 検索ボタン押下処理カスタマイズ用
      afterSearch (res) {
        for (const row of this.tableData) {
          row.num_digestion_days = parseFloat(row.digestion_days.replace('%', ''))
          row.num_attendance_rate = parseFloat(row.attendance_rate.replace('%', ''))
        }
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
          this.$refs.table.doLayout()
        })
      },
      canUpdate (row) {
        return true
      },
      canDelete (row) {
        return true
      },
      searchClicked (newSearchCond) {
        this.searchCond = newSearchCond
        this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
        this.search()
      },
      search () {
        this.results = []
        let officeCode = this.searchCond.office_code
        let teamCode = this.searchCond.team_code
        let employeeCode = this.searchCond.employee_code
        let employeeName = this.searchCond.employee_name
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.searchCond.search_type, 10),
            office_code: officeCode,
            team_code: teamCode,
            employee_code: employeeCode,
            employee_name: employeeName,
            employee_classification_code: this.searchCond.employee_classification_code,
            employee_group_code: this.searchCond.group_code,
            retirement: this.searchCond.retirement,
            days_of_use: this.searchCond.days_of_use
          }
        }
        this.orgSearchCond = Object.assign({}, this.searchCond, {})
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        this.send('/view/paid_leave/list', reqData)
        .then(res => {
          this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.paid_leave_data
          let labels = this.getLabels(res.common_area)
          this.labels = {...this.labels, ...labels}
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
          this.afterSearch(res)
          if (this.tableData.length > 0) {
            this.toggleSearchCondition()
          }
        })
      },
      init () {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/paid_leave/list', data)
        .then(res => {
          this.offices = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.office_list) {
            const office = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.offices.push(office)
          }
          this.groups = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.group_list) {
            const group = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.groups.push(group)
          }
          this.teams = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.team_list) {
            const team = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.teams.push(team)
          }
          this.employee_types = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.employee_classification_list) {
            const employeeType = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.employee_types.push(employeeType)
          }
          this.labels = this.getLabels(res.common_area)
          this.comboData = res.common_area.combo_data
          this.afterInit(res)
        })
      },
      detail (row) {
        // モーダルに渡す値を設定
        this.params = {}
        this.params.employee_code = row.employee_code
        this.params.employee_name = row.employee_name
        this.$modal.show('paidHolidaysModal')
      },
      show () {
        this.$modal.show('employee')
      },
      hidePaidHolidaysModal () {
        this.$modal.hide('paidHolidaysModal')
        // this.search()
      },
      hideEmployeeSearch (selected) {
        if (selected) {
          this.searchCond.employee_code = selected.employee_code
          this.searchCond.employee_name = selected.employee_name
        }
        this.$modal.hide('employee')
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      editDispHourOrMinutes (strTime) {
        // 引数が空の場合、ブランクを返す。空でない場合、桁数を調整する。
        if (strTime === '' || strTime === null) {
          return ''
        } else {
          // 引数が30以上の場合は分と判断し、時間に変換して返却する。
          if (strTime >= 30) {
            return strTime / 60
          // それ以外は時間と判断し、そのまま返却する。
          } else {
            return strTime
          }
        }
      },
      showWorkflow (row, employeeCode) {
        this.applicationFormCode = '0000000009'
        this.applyRecord = {
          screen_mode: '7',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: row.target_group_code,
          employee_code: employeeCode,
          application_number: row.application_number,
          route_type: null,
          route_number: null,
          is_paid_holiday: true
        }
        this.$modal.show('Workflow')
      },
      hide () {
        this.$modal.hide('Workflow')
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .table-width {
    width: 1450px;
  }
</style>

<style>
  .el-table .closed-row {
    background-color: #f0f8ff !important;
  }
</style>
