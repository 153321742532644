<template>
  <div>
    <Help :help="'https://linkpoint.zendesk.com/hc/ja/articles/4406551261337-会社登録の使い方'" :placeholders="[pageTitle]"/>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    data () {
      return {
        password: undefined,
        showPassword: false,
        corpusCompanyCode: undefined,
        corpusCompanyName: undefined,
        options: new Map(),
        tableData: [],
        res: {},
        isCorporate: true,
        isValidCustomerId: false,
        hasCustomerId: false,
        paymentPageUrl: undefined,
        accessMailAddress: undefined,
        labels: {}
      }
    },
    created () {
      const firstCompanyCreateDate = this.$route.params.firstCompanyCreateDate
      if (firstCompanyCreateDate) {
        sessionStorage.setItem('firstCompanyCreateDate', firstCompanyCreateDate)
      }
      this.corpusCompanyCode = sessionStorage.getItem('corpusCompanyCode')
      this.corpusCompanyName = sessionStorage.getItem('corpusCompanyName')
      this.accessMailAddress = sessionStorage.getItem('accessMailAddress')
      const email = this.$route.params.email
      if (email) {
        localStorage.setItem('migrationMailAddress', email)
      }
      this.isValidCustomerId = false
      const customerId = localStorage.getItem('customerId')
      if (customerId) {
        this.hasCustomerId = true
        this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeSubscriptions?customerId=' + customerId)
        .then(res => {
          if (res.data && res.data.isSubscription) {
            this.isValidCustomerId = true
          } else if (res.data && res.data.paymentPageUrl) {
            this.paymentPageUrl = res.data.paymentPageUrl
          }
        })
      }
      const companyList = this.$route.params.companyList
      if (companyList) {
        localStorage.setItem('companyList', JSON.stringify(companyList))
      }
      this.tableData = JSON.parse(localStorage.getItem('companyList'))
      const corporate = this.$route.params.corporate
      if (corporate) {
        this.isCorporate = corporate
      }
      const password = this.$route.params.password
      if (password) {
        this.password = password
      }
      const params = {}
      if (location.search) {
        const entries = decodeURIComponent(location.search).substring(1).split('&')
        for (const entry of entries) {
          const keyValue = entry.split('=')
          params[keyValue[0]] = keyValue[1]
        }
        console.log(params)
        this.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeUpdateCreditCard', params)
        .then(res => {
          console.log(res.data)
          swal({
            text: this.$t('message.credit_card_is_valid'),
            type: 'success',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        })
        .catch(error => {
          if (error.response.data.result === 'NG') {
            if (error.response.data.errorCode === 1) {
              swal({
                text: this.$t('message.credit_card_is_invalid'),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            } else {
              swal({
                text: this.$t('message.unexpected_error'),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            }
          }
        })
      }
      const request = {
        mail_address: localStorage.getItem('migrationMailAddress'),
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
      })
    }
  }

</script>
<style scoped>
</style>
