<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="approver" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :search-all="true" @close="hideApproverSearch"/>
      </div>
    </modal>
    <form method="#" action="#" :key="componentKey">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="boss" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="card">
            <div class="card-header" style="padding-top: 0;">
              <h4 class="card-title" style="margin: 0;">
                {{screenCodeTitle(screenCode)}}
              </h4>
            </div>
            <div class="card-content">
              <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
              <ValidationObserver ref="observer">
                <div class="form-group">
                  <div style="margin-bottom: 10px;">
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="addBossRow">{{$t('button.add_row')}}</button>
                  </div>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    border
                    :data="detail.boss_data"
                    :empty-text="$t('label.no_data')">
                    <!-- 削除 -->
                    <el-table-column
                      width="60"
                      :label="$t('label.delete')"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.del_flag" class="gray"/>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 部署 -->
                    <el-table-column
                      width="210"
                      :label="labels.group_ryaku"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <el-select class="select-success"
                                      placeholder=""
                                      v-model="props.row.group_code">
                            <el-option v-for="group_code in comboData.employee_group_list"
                                        class="select-success"
                                        :value="group_code.value"
                                        :label="group_code.label"
                                        :key="group_code.value">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 申請署名 -->
                    <el-table-column
                      width="210"
                      :label="labels.application_form_name_ryaku"
                      align="center">
                      <template slot-scope="props">
                        <el-select class="select-success"
                                    placeholder=""
                                    v-model="props.row.application_form_code">
                          <el-option v-for="application_form in comboData.application_form_list"
                                      class="select-success"
                                      :value="application_form.value"
                                      :label="application_form.label"
                                      :key="application_form.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <!-- 上司の所属する会社 -->
                    <el-table-column
                      width="210"
                      :label="$t('label.boss_company')"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <el-select class="select-success"
                                      placeholder=""
                                      v-model="props.row.boss_company_code"
                                      @change="changeBossCompany(props.$index)">
                            <el-option v-for="boss_company in comboData.company_list"
                                        class="select-success"
                                        :value="boss_company.value"
                                        :label="boss_company.label"
                                        :key="boss_company.value">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 上司の従業員番号 -->
                    <el-table-column
                      width="540"
                      :label="$t('label.boss_employee')"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="text"
                                class="form-control length10"
                                style="display: inline-block;"
                                maxlength="10"
                                :disabled="isEmployee(props.$index)"
                                v-model="props.row.boss_employee_code"
                                @blur="approverSelected(props.$index, 1)">
                          <input type="text"
                                  class="form-control length20"
                                  style="display: inline-block;"
                                  maxlength="30"
                                  :disabled="isEmployee(props.$index)"
                                  v-model="props.row.boss_employee_name"
                                  @blur="approverSelected(props.$index, 2)">
                          <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="isEmployee(props.$index)" @click="showApproverSearch(props.$index)" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 上司の所属する部署 -->
                    <el-table-column
                      width="210"
                      :label="$t('label.boss_group')"
                      align="center">
                      <template slot-scope="props">
                        <el-select class="select-success"
                                    placeholder=""
                                    :disabled="isGroup(props.$index)"
                                    v-model="props.row.boss_group_code"
                                    @change="changeBossGroup(props.$index)">
                          <el-option v-for="boss_group in getBossGrupList(props.$index)"
                                      class="select-success"
                                      :value="boss_group.group_code"
                                      :label="boss_group.group_name"
                                      :key="boss_group.group_code">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <div style="text-align: left;">
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.update')}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import BossMixin from '@/components/Dashboard/Views/Setting/BossMixin'

  export default {
    name: 'Boss',
    mixins: [BossMixin]
  }
</script>
<style scoped>
</style>
