<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <modal name="employee" :draggable="false" :clickToClose="false" height="auto">
      <div class="modal-body">
        <EmployeeSearch :salary-flag="true" :is-modal="true" @close="hideEmployeeSearch"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <ValidationSelect
            v-model="searchCond.target_year"
            item-name="target_year"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.target_year_list)"
            :item-label="labels.target_year_ryaku === undefined ? labels.targetYear_ryaku === undefined ? '' : labels.targetYear_ryaku : labels.target_year_ryaku"
            :options="comboData.target_year_list"
            frame-class="search-condition-item"
            :unit="$t('label.unit_year')"
            @change="targetYearChanged"
          />
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.local_government_code}}</label>
            <input type="text" :placeholder="$t('placeholder.local_government_code')" maxlength="6" class="form-control length6" v-model="searchCond.local_government_code">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
          <ValidationSelect
            v-model="searchCond.prefectures"
            item-name="prefectures"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.prefectures_list)"
            :item-label="labels.prefectures_ryaku === undefined ? labels.prefectures_ryaku === undefined ? '' : labels.prefectures_ryaku : labels.prefectures_ryaku"
            :options="comboData.prefectures_list"
            frame-class="search-condition-item"
            @change="prefecturesChanged"
          />
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
          <ValidationSelect
            v-model="searchCond.city"
            item-name="city"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.city_list)"
            :item-label="labels.city_ryaku === undefined ? labels.city_ryaku === undefined ? '' : labels.city_ryaku : labels.city_ryaku"
            :options="comboData.city_list"
            frame-class="search-condition-item"
            @change="cityChanged"
          />
          </div>
          <ValidationEmployeeText
            v-model="searchCond.employee"
            item-name="employee"
            :item-label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku"
            :placeholder1="$t('placeholder.employee_code')"
            :placeholder2="$t('placeholder.employee_name')"
            :disabled="false"
            :salary-flag="true"
            @showEmployeeSearch="showEmployeeSearch"
          >
          </ValidationEmployeeText>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
              <div class="text-right" style="padding-top: 20px;">{{summary}}</div>
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="100"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showLocalGovernmentCode" :width="getColumnWidth(tableData, labels.local_government_code_ryaku === undefined ? labels.localGovernmentCode_ryaku === undefined ? '' : labels.localGovernmentCode_ryaku : labels.local_government_code_ryaku, 'local_government_code')" :label="labels.local_government_code_ryaku === undefined ? labels.localGovernmentCode_ryaku === undefined ? '' : labels.localGovernmentCode_ryaku : labels.local_government_code_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.local_government_code}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPrefectures" :width="getColumnWidthStrong(tableData, labels.prefectures_ryaku === undefined ? labels.prefectures_ryaku === undefined ? '' : labels.prefectures_ryaku : labels.prefectures_ryaku, 'prefectures')" :label="labels.prefectures_ryaku === undefined ? labels.prefectures_ryaku === undefined ? '' : labels.prefectures_ryaku : labels.prefectures_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.prefectures}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCity" :width="getColumnWidthStrong(tableData, labels.city_ryaku === undefined ? labels.city_ryaku === undefined ? '' : labels.city_ryaku : labels.city_ryaku, 'city')" :label="labels.city_ryaku === undefined ? labels.city_ryaku === undefined ? '' : labels.city_ryaku : labels.city_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text-left">
                    {{props.row.city}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showAddress" :width="200" :label="$t('label.address')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{ props.row.prefectures + props.row.city }}
                  </div>
                  <div style="text-align: left;" v-if="props.row.person_in_charge">
                    <small>{{ props.row.person_in_charge }}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTotalResidentTax" :width="getColumnWidthStrong(tableData, labels.total_resident_tax_ryaku === undefined ? labels.totalResidentTax_ryaku === undefined ? '' : labels.totalResidentTax_ryaku : labels.total_resident_tax_ryaku, 'total_resident_tax')" :label="labels.total_resident_tax_ryaku === undefined ? labels.totalResidentTax_ryaku === undefined ? '' : labels.totalResidentTax_ryaku : labels.total_resident_tax_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text-right">
                    {{formatNumber(props.row.total_resident_tax)}}
                    {{$t('label.unit_yen')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNumberPeople" :width="getColumnWidth(tableData, labels.number_people_ryaku === undefined ? labels.numberPeople_ryaku === undefined ? '' : labels.numberPeople_ryaku : labels.number_people_ryaku, 'number_people')" :label="labels.number_people_ryaku === undefined ? labels.numberPeople_ryaku === undefined ? '' : labels.numberPeople_ryaku : labels.number_people_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{formatNumber(props.row.number_people)}}
                    {{$t('label.unit_mei')}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
      <template v-if="notSetEmployeeList && notSetEmployeeList.length > 0">
        <div class="card">
          <div class="card-content">
            <div>
              <div><label>{{ $t('label.not_set_employee_list') }}</label></div>
              <el-table class="table-width"
                        :data="notSetEmployeeList"
                        border
                        :header-cell-style="() => 'border-right: 1px; solid #ddd;'">
                <el-table-column :label="$t('button.employee')" width="150">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div>{{ props.row.employee_name }}</div>
                    <small>{{ props.row.employee_code }}</small>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import ResidentTaxSettingSearchMixin from '@/components/Dashboard/Views/Generated/ResidentTaxSettingSearchMixin'

  export default {
    name: 'ResidentTaxSettingSearch',
    data () {
      return {
        modalWidth: 1500,
        residentTax: {
          prefectures_before: undefined
        },
        notSetEmployeeList: [],
        summary: ''
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.prefectures_list = this.comboData.state_list
        this.comboData.prefectures_list.unshift({label: '', value: ''})
        this.comboData.city_list = []
        this.comboData.mapping_list = res.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
        const yearList = []
        let currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()
        let year
        if (currentMonth === 11) currentYear += 1
        for (year = currentYear; year >= 2009; year--) {
          yearList.push({label: String(year), value: String(year)})
        }
        this.comboData.target_year_list = yearList
        this.searchCond.target_year = yearList[0].value
        this.labels.prefectures_ryaku = this.$t('label.prefectures')
        this.labels.employee_ryaku = this.$t('button.employee')
      },
      prefecturesChanged () {
        if (this.residentTax.prefectures_before) {
          this.searchCond.city = ''
        }
        if (this.searchCond.prefectures) {
          this.residentTax.prefectures_before = this.searchCond.prefectures
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.searchCond.prefectures]) {
            for (const prefecture of this.comboData.prefectures_list) {
              if (prefecture.label === this.searchCond.prefectures) {
                this.searchCond.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.searchCond.prefectures]) {
            for (const code of this.comboData.mapping_list[this.searchCond.prefectures]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
            this.comboData.city_list.unshift({label: '', value: ''})
          }
        } else {
          this.comboData.city_list = []
        }
      },
      showEmployeeSearch () {
        this.$modal.show('employee')
      },
      hideEmployeeSearch (selected) {
        if (selected) {
          this.searchCond.employee = [selected.employee_code, selected.employee_name]
        }
        this.$modal.hide('employee')
      },
      afterSearch (res) {
        this.comboData.mapping_list = res.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
        this.labels.total_resident_tax_ryaku = '　' + this.$t('label.total_resident_tax') + '　'
        this.labels.number_people_ryaku = this.$t('label.number_people')
        let totalEmployees = 0
        for (const row of res.api_area.resident_tax_list) {
          totalEmployees += row.number_people
        }
        this.summary = `${this.labels.target_year} ${this.searchCond.target_year}${this.$t('label.unit_year')} ${this.$t('label.number_people')} ${totalEmployees}${this.$t('label.unit_mei')}`
        this.labels.update = 1
        this.labels = {...this.labels}
        this.notSetEmployeeList = res.api_area.not_set_employee_list
      },
      getlastDigit () {
        if (!this.searchCond.prefectures || !this.searchCond.city) return null
        const firstDigit = parseInt(this.searchCond.prefectures.substring(0, 1), 10)
        const secondDigit = parseInt(this.searchCond.prefectures.substring(1, 1), 10)
        const thirdDigit = parseInt(this.searchCond.city.substring(0, 1), 10)
        const fourthDigit = parseInt(this.searchCond.city.substring(1, 1), 10)
        const fifthDigit = parseInt(this.searchCond.city.substring(2, 1), 10)
        const mod = (firstDigit * 6 + secondDigit * 5 + thirdDigit * 4 + fourthDigit * 3 + fifthDigit * 2) % 11
        return String(11 - mod).slice(-1)
      },
      convertSearchCond (searchCond) {
        for (const row of this.comboData.state_list) {
          if (row.value === searchCond.prefectures) {
            searchCond.prefectures = row.label
          }
        }
        for (const row of this.comboData.municipality_list) {
          if (row.value === searchCond.city) {
            searchCond.city = row.label
          }
        }
        searchCond.target_employee_code = searchCond.employee[0]
        searchCond.target_employee_name = searchCond.employee[1]
        delete searchCond.employee
        return searchCond
      },
      printClicked () {
      }
    },
    mixins: [ResidentTaxSettingSearchMixin]
  }
</script>
<style scoped>
</style>
