<template>
  <div>
    <div class="form-group">
      <label>{{ itemLabel }}</label><span class="required">{{$t('label.required')}}</span>
      <ValidationProvider ref="provider" :bails="false" v-slot="{ valid, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
        <div class="error-message-wrapper" v-show="!valid">
          <div v-for="error in errors" v-bind:key="error">
            <small class="text-danger">
              {{ error ? error : '' }}
            </small>
          </div>
        </div>
        <TransformationMonthly
          v-model="itemValue"
          :item-name="itemName"
          :classes="classes"
          :disabled="disabled"
          :labels="labels"
          :err-msg="errMsg"
          @show-schedule="showSchedule"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import TransformationMonthly from '@/components/UIComponents/Inputs/TransformationMonthly'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      TransformationMonthly
    },
    props: {
      value: Object,
      itemLabel: String,
      itemName: String,
      classes: String,
      rules: String,
      disabled: Boolean,
      labels: Object,
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      errMsg: String
    },
    data () {
      return {
        itemValue: undefined
      }
    },
    computed: {
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    methods: {
      showSchedule (officeData) {
        this.$emit('show-schedule', officeData)
      }
    },
    watch: {
      itemValue () {
        this.$emit('input', this.itemValue)
      },
      value () {
        this.itemValue = this.value
      }
    },
    created () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>
