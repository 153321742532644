<template>
  <div>
    <div>
      <label v-if="itemLabel">{{ itemLabel }}</label>
      <input type="checkbox" v-model="startEndTimeHoliday[3]" class="gray" :id="`jobHoliday${itemName}`" :disabled="disabled" @change="checkChanged">
      <label :for="`jobHoliday${itemName}`">{{$t('label.job_holiday')}}</label>&nbsp;&nbsp;
      <input type="checkbox" v-model="startEndTimeHoliday[5]" class="gray" :id="`legalHoliday${itemName}`" :disabled="disabled" @change="checkLegalChanged">
      <label :for="`legalHoliday${itemName}`">{{$t('label.legal_holiday')}}</label>&nbsp;&nbsp;
    </div>
    <el-select :class="itemClasses"
                size="large"
                :name="itemName"
                :disabled="disabled"
                :placeholder="placeholder3"
                v-model="startEndTimeHoliday[4]"
                @change="changed">
      <el-option v-for="option in options"
                  class="select-success"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value">
      </el-option>
    </el-select>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <input 
      type="text" 
      :name="itemName"
      :placeholder="placeholder1"
      :class="classes" 
      style="display: inline-block;"
      v-model="startEndTimeHoliday[0]" 
      :maxlength="maxLength"
      :disabled="disabled || startEndTimeHoliday[3] || startEndTimeHoliday[5]"
      @blur="blur"
      @keypress.enter="$emit('input', startEndTimeHoliday)">
    <span>～</span>
    <input type="checkbox" v-model="startEndTimeHoliday[2]" class="gray" id="nextDay" :disabled="disabled || startEndTimeHoliday[3] || startEndTimeHoliday[5]">
    <label for="nextDay">{{$t('label.next_day')}}</label>&nbsp;&nbsp;
    <input 
      type="text" 
      :name="itemName"
      :placeholder="placeholder2"
      :class="classes" 
      style="display: inline-block;"
      v-model="startEndTimeHoliday[1]" 
      :maxlength="maxLength"
      :disabled="disabled || startEndTimeHoliday[3] || startEndTimeHoliday[5]"
      @blur="blur"
      @keypress.enter="$emit('input', startEndTimeHoliday)">
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemLabel: String,
      itemName: String,
      maxLength: String,
      classes: String,
      selectClasses: String,
      options: Array,
      placeholder1: String,
      placeholder2: String,
      placeholder3: String,
      disabled: Boolean
    },
    data () {
      return {
        startEndTimeHoliday: ['', '', false, false, '', false],
        itemClasses: 'select-success'
      }
    },
    watch: {
      value () {
        this.startEndTimeHoliday = this.value
        this.itemClasses = 'select-success ' + this.selectClasses
      }
    },
    mounted () {
      this.startEndTimeHoliday = this.value
      this.itemClasses = 'select-success ' + this.selectClasses
    },
    methods: {
      checkChanged () {
        if (this.startEndTimeHoliday[3]) {
          this.startEndTimeHoliday = ['', '', false, true, this.startEndTimeHoliday[4], false]
          this.$emit('input', this.startEndTimeHoliday)
        }
      },
      checkLegalChanged () {
        if (this.startEndTimeHoliday[5]) {
          this.startEndTimeHoliday = ['', '', false, false, this.startEndTimeHoliday[4], true]
          this.$emit('input', this.startEndTimeHoliday)
        }
      },
      changed () {
        if (this.startEndTimeHoliday[4]) {
          for (const row of this.options) {
            if (row.value === this.startEndTimeHoliday[4]) {
              if (!this.startEndTimeHoliday[3] && !this.startEndTimeHoliday[5]) {
                this.startEndTimeHoliday[0] = row.job_start
                const jobEnd24 = this.to24Time(row.job_end)
                this.startEndTimeHoliday[1] = jobEnd24
                this.startEndTimeHoliday[2] = jobEnd24 !== row.job_end
              }
              break
            }
          }
        }
        this.$emit('input', this.startEndTimeHoliday)
        this.$emit('change')
      },
      blur () {
        this.$emit('input', this.startEndTimeHoliday)
        this.$emit('blur')
      }
    }
  }
</script>
