<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div style="margin: 0; padding: 0;">
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                    :data="approveInfoDto.attached_files"
                    :empty-text="$t('label.no_data')"
                    style="margin-bottom: 10px;">
            <el-table-column min-width="500" :label="labels.append_title" align="center">
              <template slot-scope="props">
                <div style="text-align: left;">
                  <a
                    class="btn btn-simple btn-info"
                    style="padding: 0; color: blue; text-decoration: underline;"
                    @click="download(props.row)"
                  >{{props.row.append_title}}</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100" :label="labels.append_date" align="center">
              <template slot-scope="props">
                {{props.row.append_date}}
              </template>
            </el-table-column>
            <el-table-column min-width="100" :label="$t('label.attached_employee')" align="center">
              <template slot-scope="props">
                <div style="text-align: left;">
                  <small>{{props.row.employee_code}}</small>
                </div>
                <div style="text-align: left;">
                  {{props.row.employee_name}}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    props: {
      value: Object,
      isReadOnly: Boolean,
      labels: Object,
      comboData: Object
    },
    data () {
      return {
        approveInfoDto: {}
      }
    },
    methods: {
      download (row) {
        let self = this
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(row.append_path)
        httpsReference.getDownloadURL().then(function (url) {
          self.$axios.get(url, {
            'responseType': 'blob'
          }).then(res => {
            const fileName = row.append_path.substring(row.append_path.lastIndexOf('_') + 1)
            const extension = row.append_path.substring(row.append_path.lastIndexOf('.') + 1)
            let blob = new Blob([res.data], { type: self.getContentType(extension) })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
          })
        }).catch(function (error) {
          console.log(error)
        })
      }
    },
    watch: {
      value () {
        this.approveInfoDto = this.value
      }
    },
    mounted () {
      this.approveInfoDto = this.value
    }
  }
</script>
<style scoped>
</style>
