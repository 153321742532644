<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <div class="row">
                <div class="col-md-4">
                  <!-- 支給控除区分 -->
                  <ValidationSelect
                    v-model="detail.payment_deduction_category"
                    item-name="payment_deduction_category"
                    classes="length"
                    rules="required"
                    :item-label="labels.payment_deduction_category"
                    :options="isReadOnly ? comboData.payment_deduction_category_list_for_view : comboData.payment_deduction_category_list"
                    :disabled="isReadOnly"
                    data-v-step="0"
                    @change="paymentDeductionCategoryChanged"
                  />

                  <!-- 給与項目名 -->
                  <ValidationSalaryItemNameText
                    v-model="detail.salary_item_name"
                    item-name="salary_item_name"
                    max-length="7"
                    classes="form-control length20"
                    rules="required"
                    :item-label="labels.salary_item_name"
                    :placeholder="isAllowanceReadOnly ? '' : $t('placeholder.salary_item_name')"
                    :disabled="isAllowanceReadOnly"
                    :help-text="$t('label.max_length')"
                    :originalName="original_name"
                    data-v-step="1"
                  />

                  <!-- 支給区分 -->
                  <template v-if="!systemFlg">
                    <div class="form-group" data-v-step="2">
                      <label>{{labels.salary_bonus_classification}}</label>
                      <div>
                        <template v-for="item in comboData.salary_bonus_classification_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.salary_bonus_classification"
                            :label="item.value"
                            :inline="true"
                            :disabled="isReadOnly || detail.payment_deduction_category === '2'"
                            @change="salaryBonusClassificationChanged"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>
                    </div>

                    <!-- ↓支給項目かつ給与の場合のみ表示 -->
                    <template v-if="detail.payment_deduction_category === '1' && detail.salary_bonus_classification === '1'">
                      <!-- 基本給区分 -->
                      <label>{{labels.basic_salary}}</label>
                      <div class="form-group">
                        <template v-for="item in comboData.basic_salary_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.basic_salary"
                            :label="item.value"
                            :inline="true"
                            :disabled="isReadOnly"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>
                    </template>

                    <!-- ↓給与の場合のみ表示 -->
                    <template v-if="detail.salary_bonus_classification === '1'">
                      <!-- 給与支給方法 -->
                      <div class="form-group" data-v-step="3">
                        <label>{{labels.salary_category}}</label>
                        <template v-if="detail.salary_bonus_classification==='1'">
                          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                          border
                            :data="salaryPaymentMethod_list"
                            :empty-text="$t('label.no_data')"
                            style="width: 230px"
                          >
                            <el-table-column width="60" :label="$t('label.select')" align="center">
                              <template slot-scope="props">
                                <div style="text-align: center;">
                                  <input
                                    type="checkbox"
                                    v-model="props.row.selected"
                                    class="gray"
                                    :disabled="isReadOnly"
                                  />
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              min-width="150"
                              :label="labels.salary_category"
                              align="center"
                            >
                              <template slot-scope="props">{{props.row.label}}</template>
                            </el-table-column>
                          </el-table>
                        </template>
                        <template v-if="detail.salary_bonus_classification!=='1'">
                          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                          border
                            :data="bonusPaymentMethod_list"
                            :empty-text="$t('label.no_data')"
                            style="width: 230px"
                          >
                            <el-table-column width="60" :label="$t('label.select')" align="center">
                              <template slot-scope="props">
                                <div style="text-align: center;">
                                  <input
                                    type="checkbox"
                                    v-model="props.row.selected"
                                    class="gray"
                                    disabled="true"
                                  />
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              min-width="150"
                              :label="$t('label.salary_payment_method')"
                              align="center"
                            >
                              <template slot-scope="props">{{props.row.label}}</template>
                            </el-table-column>
                          </el-table>
                        </template>
                      </div>
                      <br />
                    </template>
                  </template>

                  <!-- ↓支給項目の場合のみ表示 -->
                  <template v-if="detail.payment_deduction_category === '1'">
                    <template v-if="!systemFlg">
                      <!-- 雇用保険対象区分 -->
                      <label>{{labels.employment_insurance_target_category}}</label>
                      <div class="form-group">
                        <template v-for="item in comboData.employment_insurance_target_category_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.employment_insurance_target_category"
                            :label="item.value"
                            :inline="true"
                            :disabled="isReadOnly"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>

                      <!-- 所得税対象区分 -->
                      <label>{{labels.tax_target_category}}</label>
                      <div class="form-group">
                        <template v-for="item in comboData.tax_target_category_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.tax_target_category"
                            :label="item.value"
                            :inline="true"
                            :disabled="isReadOnly"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>
                      <!-- 非課税枠[以下まで] -->
                      <template v-if="detail.tax_target_category === '1'">
                        <ValidationTextNumber
                          v-model="detail.tax_exemption_limit"
                          item-name="tax_exemption_limit"
                          integral="10"
                          step="1"
                          rules="decimal|min_value:0|max_value:9999999999"
                          classes="form-control length10"
                          :item-label="labels.tax_exemption_limit"
                          :placeholder="isReadOnly ? '' : '1'"
                          :disabled="isReadOnly"
                          :help-text="$t('label.max_length_integral')"
                        />
                        <br />
                      </template>
                    </template>
                    <!-- ↑支給項目の場合のみ表示 -->
                  </template>

                  <template v-if="!systemFlg">
                    <!-- 社会保険固定区分 -->
                    <label>{{labels.social_insurance_target_category}}</label>
                    <div class="form-group">
                      <template v-for="item in comboData.social_insurance_target_category_list">
                        <p-radio
                          v-bind:key="item.value"
                          v-model="detail.social_insurance_target_category"
                          :label="item.value"
                          :inline="true"
                          :disabled="isReadOnly"
                        >{{item.label}}</p-radio>
                      </template>
                    </div>
                    <!-- 社会保険料を決定する上での固定賃金に含める -->
                    <ValidationCheckboxes
                      v-model="detail.included_fixed_wages_social_security"
                      :item-label="$t('label.label_included_fixed_wages_social_security')"
                      :disabled="isReadOnly"
                      v-show="detail.social_insurance_target_category === '1'"
                    />
                    <!-- 給与の場合のみ表示 -->
                    <template v-if="detail.salary_bonus_classification === '1'">
                      <!-- 固定変動区分 -->
                      <label data-v-step="4">{{labels.fixed_fluctuation}}</label>
                      <div class="form-group">
                        <template v-for="item in comboData.fixed_fluctuation_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.fixed_fluctuation"
                            :label="item.value"
                            :inline="true"
                            :disabled="fixedFluctuationDisabled"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>
                    </template>
                  </template>
                  <!-- 共通手当 -->
                  <template v-if="systemFlg || detail.fixed_fluctuation==='1'">
                    <ValidationTextNumber
                      v-model="detail.allowance"
                      item-name="allowance"
                      integral="8"
                      step="1"
                      rules="decimal|min_value:0|max_value:99999999"
                      classes="form-control length10"
                      :item-label="labels.allowance"
                      :placeholder="isAllowanceReadOnly ? '' : '1'"
                      :disabled="isAllowanceReadOnly"
                      :help-text="$t('label.max_length_integral')"
                    />
                    <br />
                    <!-- 給与の場合のみ表示 -->
                    <template v-if="detail.salary_bonus_classification === '1'">
                      <!-- 日割り対象 -->
                      <label>{{labels.daily_division}}</label>
                      <div class="form-group">
                        <template v-for="item in comboData.daily_division_list">
                          <p-radio
                            v-bind:key="item.value"
                            v-model="detail.daily_division"
                            :label="item.value"
                            :inline="true"
                            :disabled="isReadOnly"
                          >{{item.label}}</p-radio>
                        </template>
                      </div>
                    </template>
                  </template>
                  <!-- 符号 -->
                  <ValidationSelect
                    v-model="detail.sign"
                    item-name="sign"
                    classes="length"
                    rules="required"
                    :item-label="labels.sign"
                    :options="comboData.sign_list"
                    :disabled="isReadOnly"
                    data-v-step="5"
                  />
                  <!-- 勘定科目コード -->
                  <ValidationSearchSelect
                    v-model="detail.account_code"
                    item-name="account_code"
                    classes="length"
                    rules="required"
                    :clearable="false"
                    :item-label="labels.account_code"
                    :options="comboData.account_list"
                    :disabled="isAllowanceReadOnly"
                    data-v-step="6"
                  />
                  <!-- 補助勘定科目コード -->
                  <ValidationSearchSelect
                    v-model="detail.sub_account_code"
                    item-name="sub_account_code"
                    classes="length"
                    rules="required"
                    :clearable="false"
                    :item-label="labels.sub_account_code"
                    :options="sub_account_list"
                    :disabled="isAllowanceReadOnly"
                  />
                  <!-- ↓支給項目の場合のみ表示 -->
                  <template v-if="detail.payment_deduction_category === '1'">
                    <!-- 支給方法 -->
                    <ValidationSelect
                      v-model="detail.payment_method"
                      item-name="payment_method"
                      classes="length"
                      rules="required"
                      :item-label="labels.payment_method"
                      :options="comboData.payment_method_list"
                      :disabled="isReadOnly"
                    />
                  </template>
                  <template v-if="!systemFlg">
                    <!-- 利用可否 -->
                    <label>{{labels.availability}}</label>
                    <div class="form-group">
                      <template v-for="item in comboData.availability_list">
                        <p-radio
                          v-bind:key="item.value"
                          v-model="detail.availability"
                          :label="item.value"
                          :inline="true"
                          :disabled="isReadOnly || mode===modes.add"
                          @change="availabilityChanged"
                        >{{item.label}}</p-radio>
                      </template>
                    </div>
                  </template>
                </div>

                <!-- 変動項目設定 -->
                <div class="col-md-8">
                  <template v-if="detail.fixed_fluctuation==='2'">
                    <!-- <div class="row">
                      <label>{{$t('label.variable_item_setting')}}</label>
                      <div class="col-md-12">
                        <p>{{$t('label.salaryItem_detail_description1')}}</p>
                        <div style="color: #c84513;">
                          <small>{{message_payment_notedit}}</small>
                        </div>
                        <div style="color: #c84513;">
                          <small>{{message_deduction_notedit}}</small>
                        </div>
                        <div class="col-md-4">
                          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                            :data="payment_list"
                            :empty-text="$t('label.no_data')"
                            style="width: 230px"
                          >
                            <el-table-column
                              :label="$t('label.salaryItem_detail_payment')"
                              align="center"
                            >
                              <template slot-scope="props">
                                <ValidationSelectMini
                                  v-model="props.row.salary_target_item_code"
                                  item-name="salary_target_item_code"
                                  classes="length8"
                                  unselected="true"
                                  :options="comboData.payment_list"
                                  :disabled="isReadOnly"
                                  :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                                />
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                        <div class="col-md-1">－</div>
                        <div class="col-md-4">
                          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                            :data="deduction_list"
                            :empty-text="$t('label.no_data')"
                            style="width: 230px"
                          >
                            <el-table-column
                              :label="$t('label.salaryItem_detail_deduction')"
                              align="center"
                            >
                              <template slot-scope="props">
                                <ValidationSelectMini
                                  v-model="props.row.salary_target_item_code"
                                  item-name="salary_target_item_code"
                                  classes="length8"
                                  unselected="true"
                                  :options="comboData.deduction_list"
                                  :disabled="isReadOnly"
                                  :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                                />
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                        <div class="col-md-1">
                          <nobr>＝（１）</nobr>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <br />
                        <br />
                        <div class="form-group" style="display: inline-block">
                          <p>{{$t('label.salaryItem_detail_description2')}}</p>
                          <div style="color: #c84513;">
                            <small>{{message_calc_notedit}}</small>
                          </div>
                        </div>
                        <br />
                        <div class="form-group" style="display: inline-block">
                          <template v-if="mode==modes.add ||mode==modes.edit">
                            <template>
                              <button
                                type="button"
                                class="btn btn-sm btn-default btn-primary"
                                @click="addRecord"
                              >{{$t('button.add_row')}}</button>
                            </template>
                          </template>
                        </div>

                        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                          :data="calculation_list"
                          style="width: 100%"
                        > -->
                          <!-- 操作 -->
                          <!-- <template v-if="mode==modes.add ||mode==modes.edit">
                            <el-table-column prop="address" :label="$t('label.delete')" width="60">
                              <template slot-scope="props">
                                <div style="text-align: center;">
                                  <input
                                    type="checkbox"
                                    v-model="props.row.deleted"
                                    class="gray"
                                    :disabled="isReadOnly"
                                  />
                                </div>
                              </template>
                            </el-table-column>
                          </template> -->
                          <!-- 符号 -->
                          <!-- <el-table-column prop="date" :label="$t('label.sign')" width="100">
                            <template slot-scope="props">
                              <ValidationSelectMini
                                v-model="props.row.division_multiplication_division"
                                item-name="division_multiplication_division"
                                classes="length"
                                rules="required"
                                :options="sign.options"
                                :disabled="isReadOnly"
                                :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                              />
                            </template>
                          </el-table-column> -->
                          <!-- 集計区分 -->
                          <!-- <el-table-column
                            prop="name"
                            :label="$t('label.aggregation_category')"
                            width="260"
                          >
                            <template slot-scope="props">
                              <ValidationSelectMini
                                v-model="props.row.aggregation_category"
                                item-name="aggregation_category"
                                classes="length"
                                :options="comboData.aggregation_category_list"
                                :disabled="isReadOnly"
                                unselected="true"
                                :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                                @change="onChangeAggregationCategory(props.$index, props.row)"
                              />
                            </template>
                          </el-table-column> -->
                          <!-- 固定値 -->
                          <!-- <el-table-column prop="name" :label="$t('label.fixed_value')">
                            <template slot-scope="props">
                              <input
                                type="number"
                                maxlength="8"
                                step="any"
                                :disabled="isReadOnly"
                                style="text-align:right"
                                class="form-control length8"
                                v-model="props.row.fixed_value"
                                :placeholder="mode==modes.add ||mode==modes.edit ?  $t('placeholder.fixed_value') : '' "
                                @change="onChangeFixedValue(props.$index, props.row)"
                              />
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent @drop.prevent="drop">
                          <ValidationTextArea
                            v-model="detail.formula"
                            item-name="supplement"
                            max-length="200"
                            classes="form-control length60 font20"
                            :item-label="$t('label.variable_item_setting')"
                            :placeholder="isReadOnly ? '' : $t('placeholder.formula')"
                            :disabled="isReadOnly"
                            :rows="3"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <ValidationSearchSelect
                          v-model="selectedFixedList"
                          item-name="fixed_fluctuation_list"
                          classes="length"
                          :clearable="false"
                          :item-label="comboData.fixed_fluctuation_list[0].label"
                          :options="comboData.fixed_list"
                          :disabled="isReadOnly"
                          @change="fixedListChanged"
                        />
                      </div>
                      <div class="col-md-6">
                        <ValidationSearchSelect
                          v-model="selectedAggregationCategoryList"
                          item-name="aggregation_category_list"
                          classes="length"
                          :clearable="false"
                          :item-label="$t('label.aggregation_category')"
                          :options="comboData.aggregation_category_list"
                          :disabled="isReadOnly"
                          @change="aggregationCategoryListChanged"
                        />
                      </div>
                      <div class="col-md-3" style="padding-top: 10px;">
                        <div>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('7')">
                            <span style="font-size: 16px;">７</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('8')">
                            <span style="font-size: 16px;">８</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('9')">
                            <span style="font-size: 16px;">９</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('/')">
                            <span style="font-size: 16px;">÷</span>
                          </button>
                        </div>
                        <div style="margin-top: 2px;">
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('4')">
                            <span style="font-size: 16px;">４</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('5')">
                            <span style="font-size: 16px;">５</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('6')">
                            <span style="font-size: 16px;">６</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('*')">
                            <span style="font-size: 16px;">×</span>
                          </button>
                        </div>
                        <div style="margin-top: 2px;">
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('1')">
                            <span style="font-size: 16px;">１</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('2')">
                            <span style="font-size: 16px;">２</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('3')">
                            <span style="font-size: 16px;">３</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('-')">
                            <span style="font-size: 16px;">ー</span>
                          </button>
                        </div>
                        <div style="margin-top: 2px;">
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('0')">
                            <span style="font-size: 16px;">０</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('00')">
                            <span style="font-size: 16px;">00</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('.')">
                            <span style="font-size: 16px;">．</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('+')">
                            <span style="font-size: 16px;">＋</span>
                          </button>
                        </div>
                        <div style="margin-top: 2px;">
                          <button type="button" class="btn-default btn-calc" @click="insertFormula('(')">
                            <span style="font-size: 16px;">（</span>
                          </button>
                          <button type="button" class="btn-default btn-calc" @click="insertFormula(')')">
                            <span style="font-size: 16px;">）</span>
                          </button>
                          <button type="button" class="btn-default btn-calc2" @click="deleteFormula()">
                            <span style="font-size: 16px;">DEL</span>
                          </button>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <label>{{comboData.fixed_fluctuation_list[0].label}}</label>
                      </div>
                      <div class="col-md-9">
                        <label>{{$t('label.aggregation_category')}}</label>
                      </div>
                      <div class="col-md-3">
                        <div v-for="item in comboData.fixed_list" :key="item.value" draggable="true" @mousedown="mouseDown" @dragstart="dragStart" style="cursor: grab;">
                          {{item.label}}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div v-for="item in comboData.aggregation_category_list.slice(0, 20)" :key="item.value" draggable="true" @mousedown="mouseDown" @dragstart="dragStart" style="cursor: grab;">
                          {{item.label}}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div v-for="item in comboData.aggregation_category_list.slice(20)" :key="item.value" draggable="true" @mousedown="mouseDown" @dragstart="dragStart" style="cursor: grab;">
                          {{item.label}}
                        </div>
                      </div> -->
                    </div>
                  </template>
                </div>
              </div>
              <Tour tour-name="myTour" :steps="steps"/>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doUpdate"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doDelete"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import SalaryItemDetailMixin from '@/components/Dashboard/Views/Master/SalaryItemDetailMixin'

  export default {
    name: 'SalaryItemDetail',
    mixins: [SalaryItemDetailMixin]
  }
</script>
<style scoped>
</style>
