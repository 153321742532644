<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.employee_classification_name"
              item-name="employee_classification_name"
              max-length="30"
              classes="form-control length30"
              rules="required"
              :item-label="labels.employee_classification_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.employee_classification_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <ValidationSelect
              v-model="detail.employee_classification_type"
              item-name="employee_classification_type"
              :placeholder="$t('label.select_description')"
              classes="length12"
              :item-label="labels.employee_classification_type"
              :options="comboData.employee_classification_type_list"
              :disabled="isReadOnly"
              @change="employeeClassificationTypeChanged"
              data-v-step="1"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import EmployeeClassificationDetailMixin from '@/components/Dashboard/Views/Generated/EmployeeClassificationDetailMixin'

  export default {
    name: 'EmployeeClassificationDetail',
    methods: {
      afterInit (res) {
        this.comboData.employee_classification_type_list.unshift({label: '', value: ''})
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.employee_classification_type = !detail.employee_classification_type ? null : parseInt(detail.employee_classification_type, 10)
        }
        return detail
      }
    },
    mixins: [EmployeeClassificationDetailMixin]
  }
</script>
<style scoped>
</style>
