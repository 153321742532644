<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="workSchedule" :draggable="true" :clickToClose="false" height="auto">
        <div class="modal-body">
          <BonusPaySlipDetail :mode="mode" :params="params" @close="hide"/>
        </div>
      </modal>
    </div>
    <!-- 検索条件 -->
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            <h4 class="card-title">
              {{labels.label_search_mes}}
            </h4>
          </div>
          <div class="card-content">
            <!-- 勤務体系コード -->
            <div class="form-group" style="display: inline-block">
              <label>{{labels.label_work_schedule_code}}</label>
              <br>
              <input type="text" :placeholder="labels.ph_code" class="form-control width130" maxlength="10" v-model="searchCond.api_area.work_schedule_code">
            </div>
            <!-- 勤務体系名 -->
            <div class="form-group" style="display: inline-block; margin-left: 20px;">
              <label>{{labels.label_work_schedule_name}}</label>
              <br>
              <input type="text" :placeholder="labels.ph_work_schedule" class="form-control width480" maxlength="30" v-model="searchCond.api_area.work_schedule_name">
            </div>
            <!-- 勤務体系略名 -->
            <div class="form-group" style="display: inline-block; margin-left: 20px;">
              <label>{{labels.label_working_system_abbreviation}}</label>
              <br>
              <input type="text" :placeholder="labels.ph_working_system_abbreviation" class="form-control width100" maxlength="4" v-model="searchCond.api_area.working_system_abbreviation">
            </div>
            <!-- 有効期間対象外も検索に含む -->
            <br>
            <input type="checkbox" v-model="searchCond.api_area.outdate_search_flag" class="gray">
            <label>{{labels.label_out_item}}</label>
            <!-- ボタン -->
            <div style="text-align: left; margin-top: 20px;">
              <template v-if="roles.entry === 1">
                <button type="button" class="btn btn-warning btn-sm btn-default" @click="showAdd">{{labels.button_to_entry}}</button>
              </template>
              <template v-if="roles.search === 1">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="search">{{labels.button_search}}</button>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template v-if="tableData && tableData.length > 0">
      <div class="col-md-12">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            <h4 class="card-title">
              {{labels.label_search_result}}
            </h4>
          </div>
          <div class="card-content row" style="width: 100%">
            <div class="col-sm-4">
              <el-select
                class="select-success"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 10px;">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="queriedData"
                        style="width: 1000px"
                        :row-class-name="tableRowClassName">
                <!-- 勤務体系 -->
                <el-table-column min-width="390" :label="labels.label_work_schedule">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.work_schedule_code}}
                      <br>
                      {{props.row.work_schedule_name}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 勤務体系略名 -->
                <el-table-column width="100" :label="labels.label_working_system_abbreviation">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.working_system_abbreviation}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 始業時間 -->
                <el-table-column width="100" :label=labels.label_job_start>
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.job_start}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 終業時間 -->
                <el-table-column width="100" :label=labels.label_job_end>
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.job_end}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 有効期間(開始) -->
                <el-table-column width="100" :label=labels.label_term_from>
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.term_from}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 有効期間(終了)) -->
                <el-table-column width="100" :label=labels.label_term_to>
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.term_to}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                  width="90"
                  fixed="left"
                  :label="labels.label_operation">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="roles.preview === 1">
                      <a class="btn btn-simple btn-xs btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="labels.button_preview"></i></a>
                    </template>
                    <template v-if="roles.update === 1">
                      <a class="btn btn-simple btn-xs btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="labels.button_update"></i></a>
                    </template>
                    <template v-if="roles.delete === 1">
                      <a class="btn btn-simple btn-xs btn-danger btn-icon remove"  @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="labels.button_delete"></i></a>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row" style="width: 1000px;">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{labels.label_search_total ? labels.label_search_total.replace('{total}', total).replace('{from}', from + 1).replace('{to}', to) : ''}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import BonusPaySlipDetail from '@/components/Dashboard/Views/Salary/BonusPaySlipDetail'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination,
      BonusPaySlipDetail
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: [],
        tableData: [],
        searchCond: {
          screen_code: '03-004',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            work_schedule_code: '',
            work_schedule_name: '',
            working_system_abbreviation: '',
            outdate_search_flag: false
          }
        },
        labels: {},
        mode: 1,
        params: {},
        roles: []
      }
    },
    methods: {
      print () {
        localStorage.setItem('work_schedule', JSON.stringify(this.searchCond))
        window.open('/#/DB/menuDBScreen003?request=work_schedule', '_blank')
      },
      search () {
        this.send('/view/work_schedule/list', this.searchCond)
        .then(res => {
          // 検索結果が0件だった場合エラー
          if (res.api_area.length === 0) {
            swal({
              text: this.labels.label_data_none.replace('{item}', this.pageTitle),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            })
            return
          }
          this.tableData = res.api_area
          this.labels = Object.assign({}, this.labels, this.getLabels(res.common_area))
        })
      },
      init () {
        let data = {
          screen_code: '03-004',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode
        }
        this.send('/init/work_schedule', data)
        .then(res => {
          // ラベル情報をthis.labelsに設定
          this.labels = this.getLabels(res.common_area)
          // ボタン権限をthis.rolesに設定
          this.roles = res.common_area.button_role
        })
      },
      handleView (index, row) {
        this.params = row
        this.mode = this.modes.view
        this.$modal.show('workSchedule')
      },
      handleEdit (index, row) {
        this.params = row
        this.mode = this.modes.edit
        this.$modal.show('workSchedule')
      },
      handleDelete (index, row) {
        // 削除可否チェック
        let data = {
          screen_code: '03-004',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            work_schedule_code: row.work_schedule_code
          }
        }
        this.send('/check/delete/work_schedule', data)
        .then(res => {
          this.params = row
          this.mode = this.modes.delete
          this.$modal.show('workSchedule')
        })
      },
      showAdd () {
        this.params = {work_schedule_code: 'ブランクの想定'}
        this.mode = this.modes.add
        this.$modal.show('workSchedule')
      },
      hide () {
        this.$modal.hide('workSchedule')
        this.search()
      },
      // 検索結果のクラス名取得
      tableRowClassName ({row}) {
        if (row.outdate_flag) {
          return 'out-row'
        }
        return ''
      }
    },
    created () {
      this.searchCond.screen_code = '03-004'
      this.init()
    }
  }
</script>
<style>
</style>
