<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :is-modal="true" @close="hideEmployeeSearch"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
            <StartEndDate
              v-model="searchCond.term"
              item-name="term"
              max-length=""
              classes="form-control length"
              :item-label="labels.term"
              :disabled="false"
              :placeholder1="$t('placeholder.start_date')"
              :placeholder2="$t('placeholder.end_date')"
              frame-class="search-condition-item"
              :unit="$t('label.existing_employee_between')"
            />
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="margin: 10px 0;" v-if="labels.update === 1">
            <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">
              {{$t('button.add_row')}}
            </button>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showDeleteRow" :width="50" :label="labels.delete_row_ryaku === undefined ? labels.deleteRow_ryaku === undefined ? '' : labels.deleteRow_ryaku : labels.delete_row_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" id="delete_row"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPaymentDate" :width="160" :label="labels.payment_date_ryaku === undefined ? labels.paymentDate_ryaku === undefined ? '' : labels.paymentDate_ryaku : labels.payment_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationDate
                    v-model="props.row.payment_date"
                    item-name="payment_date"
                    :item-label="labels.payment_date_ryaku === undefined ? labels.paymentDate_ryaku === undefined ? '' : labels.paymentDate_ryaku : labels.payment_date_ryaku"
                    :placeholder="$t('placeholder.payment_date')"
                  >
                  </ValidationDate>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployee" :width="400" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <EmployeeText
                    v-model="props.row.employee"
                    item-name="employee"
                    :item-label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku"
                    :placeholder1="$t('placeholder.employee_code')"
                    :placeholder2="$t('placeholder.employee_name')"
                    :disabled="false"
                    :index="props.$index"
                    @showEmployeeSearch="showEmployeeSearch"
                  >
                  </EmployeeText>
                </template>
              </el-table-column>
              <el-table-column v-if="showPaymentDays" :width="110" :label="labels.payment_days_ryaku === undefined ? labels.paymentDays_ryaku === undefined ? '' : labels.paymentDays_ryaku : labels.payment_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.payment_days"
                    item-name="payment_days"
                    max-length="2"
                    classes="form-control numLength2 text-right"
                    rules="required"
                    :item-label="labels.payment_days_ryaku === undefined ? labels.paymentDays_ryaku === undefined ? '' : labels.paymentDays_ryaku : labels.payment_days_ryaku"
                    :placeholder="$t('placeholder.payment_days')"
                    :help-text="$t('label.max_length')"
                    :unit="$t('label.unit_day')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showPaymentTimes" :width="110" :label="labels.payment_times_ryaku === undefined ? labels.paymentTimes_ryaku === undefined ? '' : labels.paymentTimes_ryaku : labels.payment_times_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.payment_times"
                    item-name="payment_times"
                    max-length="2"
                    classes="form-control numLength2 text-right"
                    rules="required"
                    :item-label="labels.payment_times_ryaku === undefined ? labels.paymentTimes_ryaku === undefined ? '' : labels.paymentTimes_ryaku : labels.payment_times_ryaku"
                    :placeholder="$t('placeholder.payment_times')"
                    :help-text="$t('label.max_length')"
                    :unit="$t('label.unit_hours')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showPaymentReason" :width="250" :label="labels.payment_reason_ryaku === undefined ? labels.paymentReason_ryaku === undefined ? '' : labels.paymentReason_ryaku : labels.payment_reason_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationSelectHorizontal
                    v-model="props.row.payment_reason"
                    item-name="payment_reason"
                    :placeholder="$t('label.select_description')"
                    classes="length"
                    rules="required"
                    :item-label="labels.payment_reason_ryaku === undefined ? labels.paymentReason_ryaku === undefined ? '' : labels.paymentReason_ryaku : labels.payment_reason_ryaku"
                    :options="comboData.payment_reason_list"
                  />
                </template>
              </el-table-column>
              <el-table-column v-if="showExpirationDays" :width="110" :label="labels.expiration_days_ryaku === undefined ? labels.expirationDays_ryaku === undefined ? '' : labels.expirationDays_ryaku : labels.expiration_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.expiration_days"
                    item-name="expiration_days"
                    max-length="2"
                    classes="form-control numLength2 text-right"
                    :item-label="labels.expiration_days_ryaku === undefined ? labels.expirationDays_ryaku === undefined ? '' : labels.expirationDays_ryaku : labels.expiration_days_ryaku"
                    :placeholder="$t('placeholder.expiration_days')"
                    :help-text="$t('label.max_length')"
                    :unit="$t('label.unit_day')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showExpirationTimes" :width="110" :label="labels.expiration_times_ryaku === undefined ? labels.expirationTimes_ryaku === undefined ? '' : labels.expirationTimes_ryaku : labels.expiration_times_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.expiration_times"
                    item-name="expiration_times"
                    max-length="2"
                    classes="form-control numLength2 text-right"
                    :item-label="labels.expiration_times_ryaku === undefined ? labels.expirationTimes_ryaku === undefined ? '' : labels.expirationTimes_ryaku : labels.expiration_times_ryaku"
                    :placeholder="$t('placeholder.expiration_times')"
                    :help-text="$t('label.max_length')"
                    :unit="$t('label.unit_hours')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showExpirationReason" :width="250" :label="labels.expiration_reason_ryaku === undefined ? labels.expirationReason_ryaku === undefined ? '' : labels.expirationReason_ryaku : labels.expiration_reason_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationSelectHorizontal
                    v-model="props.row.expiration_reason"
                    item-name="expiration_reason"
                    :placeholder="$t('label.select_description')"
                    classes="length"
                    :item-label="labels.expiration_reason_ryaku === undefined ? labels.expirationReason_ryaku === undefined ? '' : labels.expirationReason_ryaku : labels.expiration_reason_ryaku"
                    :options="comboData.expiration_reason_list"
                  />
                </template>
              </el-table-column>
              <el-table-column v-if="showHoldDays" :width="getColumnWidth(tableData, labels.hold_days_ryaku === undefined ? labels.holdDays_ryaku === undefined ? '' : labels.holdDays_ryaku : labels.hold_days_ryaku, 'hold_days')" :label="labels.hold_days_ryaku === undefined ? labels.holdDays_ryaku === undefined ? '' : labels.holdDays_ryaku : labels.hold_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.hold_days)}}
                    {{$t('label.unit_day')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showHoldTimes" :width="getColumnWidth(tableData, labels.hold_times_ryaku === undefined ? labels.holdTimes_ryaku === undefined ? '' : labels.holdTimes_ryaku : labels.hold_times_ryaku, 'hold_times')" :label="labels.hold_times_ryaku === undefined ? labels.holdTimes_ryaku === undefined ? '' : labels.holdTimes_ryaku : labels.hold_times_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.hold_times)}}
                    {{$t('label.unit_hours')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showAttendanceRatio" :width="getColumnWidth(tableData, labels.attendance_ratio_ryaku === undefined ? labels.attendanceRatio_ryaku === undefined ? '' : labels.attendanceRatio_ryaku : labels.attendance_ratio_ryaku, 'attendance_ratio')" :label="labels.attendance_ratio_ryaku === undefined ? labels.attendanceRatio_ryaku === undefined ? '' : labels.attendanceRatio_ryaku : labels.attendance_ratio_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.attendance_ratio)}}
                    {{$t('label.unit_ratio')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showWorkingMonths" :width="getColumnWidth(tableData, labels.working_months_ryaku === undefined ? labels.workingMonths_ryaku === undefined ? '' : labels.workingMonths_ryaku : labels.working_months_ryaku, 'working_months')" :label="labels.working_months_ryaku === undefined ? labels.workingMonths_ryaku === undefined ? '' : labels.workingMonths_ryaku : labels.working_months_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.working_months)}}
                    {{$t('label.unit_months')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNextPaymentDays" :width="getColumnWidth(tableData, labels.next_payment_days_ryaku === undefined ? labels.nextPaymentDays_ryaku === undefined ? '' : labels.nextPaymentDays_ryaku : labels.next_payment_days_ryaku, 'next_payment_days')" :label="labels.next_payment_days_ryaku === undefined ? labels.nextPaymentDays_ryaku === undefined ? '' : labels.nextPaymentDays_ryaku : labels.next_payment_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.next_payment_days)}}
                    {{$t('label.unit_day')}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanged" @click="doUpdate">
        <span class="btn-label"><i class="fa fa-check"></i></span>
        {{$t('button.update')}}
      </button>
      <br><br><br><br><br>
    </template>
    </div>
  </div>
</template>
<script>
  import PaidAnnualAccountEmployeeSearchMixin from '@/components/Dashboard/Views/Generated/PaidAnnualAccountEmployeeSearchMixin'

  export default {
    name: 'PaidAnnualAccountEmployeeSearch',
    data () {
      return {
        showOperation: false
      }
    },
    methods: {
      init () {
        const res = {
          api_area: null,
          common_area: {
            button_role: {
              delete: 2,
              download: 2,
              entry: 2,
              preview: 2,
              print: 2,
              search: 1,
              update: 1,
              upload: 2
            },
            label: {
              term: '期間,None'
            },
            combo_data: {},
            help: 'None',
            table_label: null
          },
          error_fields: null,
          error_log_id: null,
          message: null
        }
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.afterInit(res)
        this.componentKey += 1
      },
      afterInit (res) {
        this.labels.delete_row_ryaku = this.$t('label.delete')
      },
      search () {
        const res = {
          api_area: {
            employee_list: [
              {
                payment_date: '2021-04-01',
                employee_code: '001',
                employee_name: '従業員１',
                payment_days: 20,
                payment_times: 0,
                payment_reason: '001',
                expiration_days: 20,
                expiration_times: 0,
                expiration_reason: '001',
                hold_days: 20,
                hold_times: 0,
                attendance_ratio: 100.0,
                working_months: 120,
                next_payment_days: 20
              }
            ]
          },
          common_area: {
            button_role: {},
            label: {
              payment_date: 'None,支給年月日',
              Employee: 'None,従業員',
              payment_days: 'None,支給日数',
              payment_times: 'None,支給時間',
              payment_reason: 'None,支給理由',
              expiration_days: 'None,失効日数',
              expiration_times: 'None,失効時間',
              expiration_reason: 'None,失効理由',
              hold_days: 'None,有給残日数',
              hold_times: 'None,有給残時間',
              attendance_ratio: 'None,出勤率',
              working_months: 'None,勤続月数',
              next_payment_days: 'None,予定付与'
            },
            combo_data: {
              payment_reason_list: [
                {
                  label: '支給日のため。',
                  value: '001'
                }
              ],
              expiration_reason_list: [
                {
                  label: '失効日のため。',
                  value: '001'
                }
              ]
            },
            help: 'None',
            table_label: null
          },
          error_fields: null,
          error_log_id: null,
          message: null
        }
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.employee_list
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            } else if (typeof entry[1] === 'number') {
              row[entry[0]] = entry[1] == null ? '' : String(entry[1])
            }
          }
        }
        this.afterSearch(res)
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.$refs.summary.style.width = width + 'px'
          this.componentKey += 1
        })
      },
      afterSearch (res) {
        for (const row of this.tableData) {
          row.employee = [row.employee_code, row.employee_name]
        }
      },
      addRow () {
        this.tableData.unshift({
          payment_date: '',
          employee_code: '',
          employee_name: '',
          employee: ['', ''],
          payment_day: '',
          payment_time: '',
          payment_reason: '',
          expiration_day: '',
          expiration_time: '',
          expiration_reason: '',
          hold_day: '',
          hold_time: '',
          attendance_ratio: '',
          working_months: '',
          next_payment_day: ''
        })
      },
      employeeSelected (index, type) {
      },
      showEmployeeSearch (index) {
        this.selectedIndex = index
        this.$modal.show('employee')
      },
      hideEmployeeSearch (selected) {
        if (selected) {
          console.log(this.selectedIndex)
          this.tableData[this.selectedIndex].employee = [selected.employee_code, selected.employee_name]
        }
        this.$modal.hide('employee')
      },
      doUpdate () {
      }
    },
    mixins: [PaidAnnualAccountEmployeeSearchMixin]
  }
</script>
<style scoped>
</style>
