<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div class="error-message-wrapper" v-show="!valid">
        <small class="text-danger">
          {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
      <div>
        <el-date-picker 
          v-model="itemValue" 
          type="date" 
          :name="itemName"
          :placeholder="placeholder"
          :disabled="disabled"
          :picker-options="pickerOptions"
          @change="changed">
        </el-date-picker>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: String,
      itemName: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      pickerOptions: {
        type: Object,
        require: false,
        'default': () => ({
          disabledDate (time) {
            return false
          }
        })
      }
    },
    data () {
      return {
        itemValue: null
      }
    },
    methods: {
      changed () {
        this.$emit('input', this.formatDate(this.itemValue))
        this.$emit('change')
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>