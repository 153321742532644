<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <SalaryIncreaseSimulation
              v-model="detail.allocation"
              :disabled="isReadOnly"
              :combo-data="comboData"
              :labels="labels"
              :monthly-standard-working-time="160"
              @adjust="adjust"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import SalaryIncreaseSimulationDetailMixin from '@/components/Dashboard/Views/Generated/SalaryIncreaseSimulationDetailMixin'

  export default {
    name: 'SalaryIncreaseSimulationDetail',
    methods: {
      afterInit (res) {
        this.labels.salaryItem = '項目'
        this.labels.current_salary = '（現）給与'
        this.labels.new_salary = '（新）給与'
        this.labels.increase_amount = '昇給額'
        this.labels.is_basic_salary = '基本給'
        this.comboData.basic_salary_list = [
          { label: '対象', value: 1 },
          { label: '対象外', value: 2 }
        ]
      },
      adjust () {
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 250) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 250px);'
          }
        })
      }
    },
    mixins: [SalaryIncreaseSimulationDetailMixin]
  }
</script>
<style scoped>
</style>
