<template>
  <div>
    <!-- 全て割当を選択 -->
    <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="allSelect">{{$t('button.allAllocationSelect')}}</button>
    <!-- 全て割当を解除 -->
    <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="allRelease">{{$t('button.allAllocationRelease')}}</button>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="tableData"
              style="width: 350px">

      <!-- 役割(役職) -->
      <el-table-column min-width="100" :label="labels.role_ryaku === undefined ? '' : labels.role_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small></small>
            <br>
            {{props.row.role_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>

      <!-- 割当チェックボックス -->
      <el-table-column
        :min-width="80"
        width="80px"
        fixed="right"
        :label="$t('label.allocation')">
        <template slot-scope="props">
          <input type="checkbox" :disabled="isDisabled(props.row.role_code, disabled)" v-model="props.row.selected" class="gray" :style="getStyle(props.row.role_code)"/>
        </template>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      classes: String,
      disabled: Boolean,
      payrollManagement: String,
      attendanceManagement: String,
      labels: Object
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    methods: {
      allSelect () {
        for (const data of this.tableData) {
          if (data.role_code !== 'attendance_management' && data.role_code !== 'payroll_management') {
            data.selected = true
          }
        }
      },
      allRelease () {
        for (const data of this.tableData) {
          if (data.role_code !== 'attendance_management' && data.role_code !== 'payroll_management') {
            data.selected = false
          }
        }
      },
      isDisabled (roleCode, disabled) {
        if (disabled) {
          return true
        } else if (roleCode === 'attendance_management') {
          return true
        } else if (roleCode === 'payroll_management') {
          return true
        }
        return false
      },
      getStyle (roleCode) {
        if (roleCode === 'attendance_management' || roleCode === 'payroll_management') {
          return 'background: #cfcfcf !important; border: 2px solid #cfcfcf;'
        }
        return ''
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>