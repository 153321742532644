import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimes'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
import ValidationApplicationManagement from '@/components/UIComponents/Inputs/ValidationApplicationManagement'

export default {
  name: 'OfficeDetailMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationText,
    ValidationSelect,
    ValidationSearchSelect,
    ValidationDate,
    ValidationTimes,
    ValidationRadio,
    ValidationApplicationManagement
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    var self = this
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {
        office_code: '',
        reference_number: '',
        office_reference_number: '',
        office_name: '',
        copy_company_data: '',
        tel: '',
        fax: '',
        industry_code_big: '',
        industry_code_during: '',
        industry_code_small: '',
        special_measures: {},
        regulatory_grace_exclusion: {},
        place_code: '',
        industry_code: '',
        term_from: '',
        term_to: '',
        job_total_minutes: '',
        working_interval: '',
        limit_exemption_working_interval: '',
        handling_method_working_interval: '',
        office_application_list: []
      },
      futureday: {
        disabledDate (date) {
          let termFrom = self.formatDate(self.detail.term_from)
          let termFromDate = new Date(termFrom)
          return date <= termFromDate
        }
      },
      officeApplicationListErrors: [],
      steps: [
        {
          target: '[data-v-step="0"]',
          content: '管理用の番号です。後から変更することのできない項目です。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="1"]',
          content: '事業所の名前を入力してください。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="2"]',
          content: '事業所の電話番号を入力してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="3"]',
          content: '事業所の業種を選択してください。総務省 日本標準産業分類の項目が表示されます。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="4"]',
          content: '対象を選択した場合、1週間あたりの法定労働時間が44時間になります。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="5"]',
          content: '一部の業種について時間外労働の上限規制に猶予期間や適用除外規定が設けられています。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="6"]',
          content: '登録した事業所の場所(住所)を指定してください。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="7"]',
          content: 'この画面から、場所を新しく登録することもできます。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="8"]',
          content: '事業所を有効にする開始日を入力してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="9"]',
          content: '登録したい事業所の所定労働時間を入力してください。',
          params: {placement: 'bottom-start'}
        }
      ],
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '03-002'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    copyCompanyDataClicked () {},
    industryCodeBigChanged () {},
    industryCodeDuringChanged () {},
    industryCodeSmallChanged () {},
    placeCodeChanged () {},
    industryCodeChanged () {},
    handlingMethodWorkingIntervalChanged () {},
    replay () {
      this.$tours['myTour'].start()
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation, confirmed = null) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {office_code: this.detail.office_code}
          detail.office_name = this.detail.office_name
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        if (confirmed !== null) {
          reqData.api_area.confirmed = confirmed
        }
        this.send(`/${operation}/office`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code
        }
      }
      this.send('/init/office', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
