import { render, staticRenderFns } from "./ValidationRouteAllocation.vue?vue&type=template&id=10845a7e&scoped=true"
import script from "./ValidationRouteAllocation.vue?vue&type=script&lang=js"
export * from "./ValidationRouteAllocation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10845a7e",
  null
  
)

export default component.exports