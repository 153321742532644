<template>
  <ValidationObserver ref="observer">
  <div class="row" style="overflow-y: auto; height: 570px;" name="employeeFrame">
    <div class="col-md-12">
      <div class="card">
        <div class="card-content" style="padding-left: 20px;">
          <div class="row">
            <!-- 税法上の扶養家族人数 -->
            <div v-if="mode!=modes.add">
              <label for="planId">
                {{labels.dependent_count}}
              </label>
              <br/>
                {{dependent_count + $t('label.people')}}
              <br/>
              <br/>
            </div>
            <!-- 扶養家族 -->
            <label for="planId">
              {{$t('label.dependent')}}
            </label>
            <template>
              <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addDependent">{{$t('button.add_row')}}</button>
            </template>
            <br/>
            <div class="error-message-wrapper" v-for="errorMsg in dependent_error" v-bind:key="errorMsg">
              <small class="text-danger">
                <!-- エラーメッセージ -->
                {{ errorMsg }}
              </small>
            </div>
            <!-- 有給支給年別勘定テーブル -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="dependent_record" style="width: 1480px">
              <!-- 削除 -->
              <el-table-column min-width="50" :label="$t('label.delete')">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                  </div>
                </template>
              </el-table-column>
              <!-- 扶養家族名 -->
              <el-table-column min-width="400" :label="labels.dependent_name_ryaku ? labels.dependent_name_ryaku : ''">
                <template slot-scope="props">
                  <input
                    type="text"
                    v-model="props.row.dependent_name"
                    maxlength="30"
                    class="form-control length30"
                    style="display:inline"
                    :placeholder="$t('placeholder.dependent_name')"
                    :disabled="isReadOnly"
                  >
                </template>
              </el-table-column>
              <!-- 性別 -->
              <el-table-column min-width="230" :label="labels.sex_ryaku ? labels.sex_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.sex">
                    <el-option v-for="option in sex_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 生年月日 -->
              <el-table-column min-width="150" :label="labels.birthday_ryaku ? labels.birthday_ryaku : ''">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.birthday"
                      type="date"
                      :placeholder="$t('placeholder.start_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
              <!-- 続柄 -->
              <el-table-column min-width="230" :label="labels.relationship_ryaku ? labels.relationship_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.relationship">
                    <el-option v-for="option in relationship_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 死亡日 -->
              <el-table-column min-width="150" :label="labels.day_of_death_ryaku ? labels.day_of_death_ryaku : ''">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.day_of_death"
                      type="date"
                      :placeholder="$t('placeholder.start_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
              <!-- 控除対象配偶者 -->
              <el-table-column min-width="120" :label="labels.deductible_spouse_ryaku ? labels.deductible_spouse_ryaku : ''">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.deductible_spouse" class="gray" :disabled="isReadOnly"/>
                  </div>
                </template>
              </el-table-column>
              <!-- 障害者区分 -->
              <el-table-column min-width="200" :label="labels.disability_classification_ryaku ? labels.disability_classification_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.disability_classification">
                    <el-option v-for="option in disability_classification_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 同居区分 -->
              <el-table-column min-width="200" :label="labels.living_together_ryaku ? labels.living_together_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.living_together">
                    <el-option v-for="option in living_together_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 扶養親族区分 -->
              <el-table-column min-width="200" :label="labels.dependent_relative_classification_ryaku ? labels.dependent_relative_classification_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.dependent_relative_classification">
                    <el-option v-for="option in dependent_relative_classification_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- マイナンバー -->
              <el-table-column min-width="220" :label="labels.my_number_ryaku ? labels.my_number_ryaku : ''">
                <template slot-scope="props">
                  <input
                    type="text"
                    v-model="props.row.my_number"
                    maxlength="12"
                    class="form-control length12"
                    style="display:inline"
                    :placeholder="$t('placeholder.my_number')"
                    :disabled="isReadOnly"
                  >
                </template>
              </el-table-column>
            </el-table>
            <br>
            <br>
            <!-- 寡婦/ひとり親 -->
            <ValidationSelect
              v-model="is_widow"
              :item-label="labels.is_widow"
              :options="is_widow_list"
              classes="length20"
              rules=""
              :disabled="isReadOnly"
            />
            <div>
              <label for="planId">
                {{$t('label.is_working_student')}}
              </label>
              <input type="checkbox" v-model="is_working_student" class="gray" :disabled="isReadOnly"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      'el-date-picker': DatePicker
    },
    props: {
      params: Object
    },
    data () {
      return {
        labels: {},
        mode: 0,
        dependent_count: '',
        dependent_record: [],
        dependent_error: [],
        sex_list: [],
        relationship_list: [],
        disability_classification_list: [],
        living_together_list: [],
        dependent_relative_classification_list: [],
        is_widow: '',
        is_widow_list: [],
        is_widow_widow: false,
        is_widow_single_parent: false,
        is_working_student: false
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      allClear () {
        this.dependent_count = ''
        this.dependent_record = []
        this.dependent_error = []
        this.is_widow = ''
        this.is_widow_widow = false
        this.is_widow_single_parent = false
        this.is_working_student = false
      },
      init () {
      },
      // 追加ボタン
      addDependent () {
        let record = {
          selected: false,
          dependent_name: '',
          sex: '',
          birthday: '',
          relationship: '',
          day_of_death: '',
          deductible_spouse: false,
          disability_classification: '',
          living_together: '',
          dependent_relative_classification: '',
          my_number: ''
        }
        this.dependent_record.push(record)
      },
      setDetails (res, mode) {
        if (mode !== this.modes.add) {
          if (res.api_area.salary.tax === null) {
            this.dependent_count = '0'
          } else {
            this.dependent_count = String(res.api_area.salary.tax.dependent_count)
          }
          this.dependent_record = res.api_area.salary.dependent_list
          for (const record of this.dependent_record) {
            record.sex = String(record.sex)
            record.relationship = String(record.relationship)
            record.disability_classification = String(record.disability_classification)
            record.living_together = String(record.living_together)
            record.dependent_relative_classification = String(record.dependent_relative_classification)
          }
          this.is_widow = String(res.api_area.basic.is_widow)
          if (res.api_area.basic.is_working_student === null || res.api_area.basic.is_working_student === 2) {
            this.is_working_student = false
          } else if (res.api_area.basic.is_working_student === 1) {
            this.is_working_student = true
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
