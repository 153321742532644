<template>
  <div>
    <span>{{$t('label.salary_increase_simulation_description')}}</span>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      :data="tableData"
      :empty-text="$t('label.no_data')">
      <!-- 給与項目 -->
      <el-table-column
        width="150"
        :label="labels.salaryItem"
        align="center">
        <template slot-scope="props">
          <template v-if="props.row.salary_item_code">
            <div style="text-align: left;">
              {{props.row.salary_item_name}}
            </div>
          </template>
          <template v-else>
            <div style="text-align: left; line-height: 40px;">
              {{props.row.salary_item_name}}
            </div>
          </template>
        </template>
      </el-table-column>
      <!-- （現）給与 -->
      <el-table-column
        width="100"
        :label="labels.current_salary"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <template v-if="props.row.salary_item_code">
              <span>{{formatNumber(props.row.current_salary)}}</span>
            </template>
            <template v-else>
              <span>{{formatNumber(totalCurrentSalary)}}</span>
            </template>
          </div>
        </template>
      </el-table-column>
      <!-- （新）給与 -->
      <el-table-column
        width="120"
        :label="labels.new_salary"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <template v-if="props.row.salary_item_code">
              <input type="text"
                      class="form-control numLength7 text-right"
                      style="display: inline-block;"
                      :disabled="disabled || props.row.salary_item_code　===　407"
                      maxlength="7"
                      v-model="props.row.new_salary"
                      @blur="calc(props.row)">
            </template>
            <template v-else>
              <span>{{formatNumber(totalNewSalary)}}</span>
            </template>
          </div> 
        </template>
      </el-table-column>
      <!-- 昇給額 -->
      <el-table-column
        width="100"
        :label="labels.increase_amount"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <template v-if="props.row.salary_item_code">
              <span>{{formatNumber(props.row.increase_amount)}}</span>
            </template>
            <template v-else>
              <span>{{formatNumber(totalIncreaseAmount)}}</span>
            </template>
          </div>
        </template>
      </el-table-column>
      <!-- 基本給 -->
      <el-table-column
        width="120"
        :label="labels.is_basic_salary"
        align="center">
        <template slot-scope="props">
          <div v-if="props.row.salary_item_code">
            <el-select class="select-success width100"
                        size="large"
                        :disabled="disabled"
                        placeholder=""
                        v-model="props.row.is_basic_salary"
                        @change="updateHourlyWage">
              <el-option v-for="basic_salary in comboData.basic_salary_list"
                          class="select-success"
                          :value="basic_salary.value"
                          :label="basic_salary.label"
                          :key="basic_salary.value">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      :data="tableDataHourly"
      :empty-text="$t('label.no_data')">
      <!-- 時給項目 -->
      <el-table-column
        width="150"
        class-name="table-header"
        align="center">
        <template slot-scope="props">
          <div style="text-align: left;">
            {{props.row.hourly_wage_label}}
          </div>
        </template>
      </el-table-column>
      <!-- （現）給与 -->
      <el-table-column
        width="100"
        :label="labels.current_salary"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <span>{{formatNumber(props.row.current_salary)}}</span>
          </div>
        </template>
      </el-table-column>
      <!-- （新）給与 -->
      <el-table-column
        width="120"
        :label="labels.new_salary"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <span>{{formatNumber(props.row.new_salary)}}</span>
          </div>
        </template>
      </el-table-column>
      <!-- 昇給額 -->
      <el-table-column
        width="100"
        :label="labels.increase_amount"
        align="center">
        <template slot-scope="props">
          <div style="text-align: right;">
            <span>{{formatNumber(props.row.increase_amount)}}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <br>
    <div style="text-align: left;">
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="doCalc">{{$t('button.calc')}}</button>
    </div>
    <br>
    <template v-if="tableDataYearly.length > 0">
      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"        border
        :data="tableDataYearlyTotal"
        :empty-text="$t('label.no_data')">
        <!-- 年間実績 -->
        <el-table-column
          width="150"
          :label="$t('label.annual_result')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              {{props.row.annual_result}}
            </div>
          </template>
        </el-table-column>
        <!-- 今年度時間 -->
        <el-table-column
          width="70"
          align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <span>{{props.row.current_remarks}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          :label="$t('label.this_business_year')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{`${formatNumber(props.row.current_amount)}${$t('label.currency')}`}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- シミュレーション結果 -->
        <el-table-column
          width="70"
          align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <span>{{props.row.new_remarks}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="140"
          :label="$t('label.simulation_result')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{`${formatNumber(props.row.new_amount)}${$t('label.currency')}`}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 差額 -->
        <el-table-column
          width="100"
          :label="$t('label.difference')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{`${formatNumber(props.row.difference)}${$t('label.currency')}`}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <div style="display: inline-block;">
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"          border
          :data="tableDataYearly"
          :empty-text="$t('label.no_data')">
          <!-- 年間実績 -->
          <el-table-column
            width="150"
            :label="$t('label.annual_result')"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.annual_result}}
              </div>
            </template>
          </el-table-column>
          <!-- 今年度時間 -->
          <el-table-column
            width="70"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <span>{{props.row.current_remarks}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            :label="$t('label.this_business_year')"
            align="center">
            <template slot-scope="props">
              <div style="text-align: right;">
                <span>{{`${formatNumber(props.row.current_amount)}${$t('label.currency')}`}}</span>
              </div>
            </template>
          </el-table-column>
          <!-- シミュレーション結果 -->
          <el-table-column
            width="70"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <span>{{props.row.new_remarks}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            :label="$t('label.simulation_result')"
            align="center">
            <template slot-scope="props">
              <div style="text-align: right;">
                <span>{{`${formatNumber(props.row.new_amount)}${$t('label.currency')}`}}</span>
              </div>
            </template>
          </el-table-column>
          <!-- 差額 -->
          <el-table-column
            width="100"
            :label="$t('label.difference')"
            align="center">
            <template slot-scope="props">
              <div style="text-align: right;">
                <span>{{`${formatNumber(props.row.difference)}${$t('label.currency')}`}}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 600px; display: inline-block; margin-left: 20px; vertical-align: top;">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">今年度とシミュレーション結果の比較</h4>
          </div>
          <div class="card-content">
            <div id="chartStock" class="ct-chart"></div>
          </div>
        </div>
      </div>
      <br>
      {{$t('label.reference')}}
      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"        border
        :data="tableDataGrade"
        :empty-text="$t('label.no_data')">
        <!-- 等級 -->
        <el-table-column
          width="50"
          :label="$t('label.grade')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              {{props.row.grade}}
            </div>
          </template>
        </el-table-column>
        <!-- 月額 -->
        <el-table-column
          width="70"
          :label="$t('label.monthly_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.monthly_amount)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 円以上 -->
        <el-table-column
          width="70"
          :label="$t('label.amount_from')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.amount_from)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 円未満 -->
        <el-table-column
          width="70"
          :label="$t('label.amount_to')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.amount_to)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 全額 -->
        <el-table-column
          width="90"
          :label="$t('label.total_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatFloat(props.row.total_amount, 2)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 折半額 -->
        <el-table-column
          width="90"
          :label="$t('label.half_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatFloat(props.row.half_amount, 2)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 会社負担 -->
        <el-table-column
          width="70"
          :label="$t('label.company_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.company_amount)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 本人負担 -->
        <el-table-column
          width="70"
          :label="$t('label.employee_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.employee_amount)}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 増加額 -->
        <el-table-column
          width="70"
          :label="$t('label.increase_amount')"
          align="center">
          <template slot-scope="props">
            <div style="text-align: right;">
              <span>{{formatNumber(props.row.increase_amount)}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
  import Chartist from 'chartist'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      comboData: Object,
      labels: Object,
      disabled: Boolean,
      monthlyStandardWorkingTime: Number
    },
    data () {
      return {
        componentKey: 0,
        $Chartist: null,
        tableData: [],
        tableDataHourly: [],
        tableDataYearly: [],
        tableDataYearlyTotal: [],
        tableDataGrade: []
      }
    },
    computed: {
      totalCurrentSalary () {
        let total = 0
        for (const row of this.tableData) {
          if (!row.current_salary) continue
          total += parseInt(row.current_salary, 10)
        }
        return total
      },
      totalNewSalary () {
        let total = 0
        for (const row of this.tableData) {
          if (!row.new_salary) continue
          total += parseInt(row.new_salary, 10)
        }
        return total
      },
      totalIncreaseAmount () {
        let total = 0
        for (const row of this.tableData) {
          if (!row.increase_amount) continue
          total += parseInt(row.increase_amount, 10)
        }
        return total
      }
    },
    methods: {
      initStockChart () {
        const dataStock = {
          labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          series: [
            [40, 80, 120, 160, 200, 240, 280, 320, 360, 400, 440, 480],
            [41, 82, 123, 164, 205, 246, 287, 328, 369, 410, 451, 492]
          ]
        }
        const optionsStock = {
          lineSmooth: false,
          axisY: {
            offset: 60,
            labelInterpolationFnc (value) {
              return `${value}万円`
            }

          },
          low: 0,
          height: '300px',
          high: 500
        }
        this.$Chartist.Line('#chartStock', dataStock, optionsStock)
      },
      calc (row) {
        row.increase_amount = parseInt(row.new_salary, 10) - parseInt(row.current_salary, 10)
        this.updateHourlyWage()
      },
      updateHourlyWage () {
        let currentBasicSalary = 0
        let newBasicSalary = 0
        let increaseAmount = 0
        for (const row of this.tableData) {
          if (!row.is_basic_salary || row.is_basic_salary === 2) continue
          currentBasicSalary += parseInt(row.current_salary, 10)
          newBasicSalary += parseInt(row.new_salary, 10)
          increaseAmount += parseInt(row.increase_amount, 10)
        }
        this.tableDataHourly[0].current_salary = currentBasicSalary / this.monthlyStandardWorkingTime
        this.tableDataHourly[0].new_salary = newBasicSalary / this.monthlyStandardWorkingTime
        this.tableDataHourly[0].increase_amount = increaseAmount / this.monthlyStandardWorkingTime
        this.tableDataHourly[1].current_salary = this.tableDataHourly[0].current_salary * 0.25
        this.tableDataHourly[1].new_salary = this.tableDataHourly[0].new_salary * 0.25
        this.tableDataHourly[1].increase_amount = this.tableDataHourly[0].increase_amount * 0.25
        this.tableDataHourly[2].current_salary = this.tableDataHourly[0].current_salary * 0.35
        this.tableDataHourly[2].new_salary = this.tableDataHourly[0].new_salary * 0.35
        this.tableDataHourly[2].increase_amount = this.tableDataHourly[0].increase_amount * 0.35
        this.tableDataHourly[3].current_salary = this.tableDataHourly[0].current_salary * 0.5
        this.tableDataHourly[3].new_salary = this.tableDataHourly[0].new_salary * 0.5
        this.tableDataHourly[3].increase_amount = this.tableDataHourly[0].increase_amount * 0.5
      },
      doCalc () {
        this.tableDataYearly = [
          {
            annual_result: '勤続給',
            current_remarks: '',
            current_amount: 480000,
            new_remarks: '',
            new_amount: 480000,
            difference: 0
          },
          {
            annual_result: 'キャリア給',
            current_remarks: '',
            current_amount: 444000,
            new_remarks: '',
            new_amount: 444000,
            difference: 0
          },
          {
            annual_result: '職能給',
            current_remarks: '',
            current_amount: 2319600,
            new_remarks: '',
            new_amount: 2319600,
            difference: 0
          },
          {
            annual_result: '住宅手当',
            current_remarks: '',
            current_amount: 0,
            new_remarks: '',
            new_amount: 0,
            difference: 0
          },
          {
            annual_result: 'リーダー手当',
            current_remarks: '',
            current_amount: 0,
            new_remarks: '',
            new_amount: 0,
            difference: 0
          },
          {
            annual_result: '非課税通勤手当',
            current_remarks: '',
            current_amount: 207960,
            new_remarks: '',
            new_amount: 207960,
            difference: 0
          },
          {
            annual_result: '見込み残業手当',
            current_remarks: '30:00',
            current_amount: 750828,
            new_remarks: '20:00',
            new_amount: 519072,
            difference: 231756
          },
          {
            annual_result: '残業手当',
            current_remarks: '01:01',
            current_amount: 2120,
            new_remarks: '01:01',
            new_amount: 2120,
            difference: 0
          },
          {
            annual_result: '深夜割増分',
            current_remarks: '08:40',
            current_amount: 4017,
            new_remarks: '08:40',
            new_amount: 4017,
            difference: 0
          },
          {
            annual_result: '法定割増分',
            current_remarks: '03:00',
            current_amount: 1418,
            new_remarks: '03:00',
            new_amount: 1418,
            difference: 0
          },
          {
            annual_result: '生涯設計前払金',
            current_remarks: '',
            current_amount: 0,
            new_remarks: '',
            new_amount: 60000,
            difference: 60000
          },
          {
            annual_result: '健康保険',
            current_remarks: 'XX等級',
            current_amount: 213840,
            new_remarks: 'XX等級',
            new_amount: 213840,
            difference: 0
          },
          {
            annual_result: '厚生年金',
            current_remarks: 'XX等級',
            current_amount: 522846,
            new_remarks: 'XX等級',
            new_amount: 522846,
            difference: 0
          },
          {
            annual_result: '雇用保険',
            current_remarks: '',
            current_amount: 25306,
            new_remarks: '',
            new_amount: 25306,
            difference: 0
          },
          {
            annual_result: '労働保険',
            current_remarks: '',
            current_amount: 12630,
            new_remarks: '',
            new_amount: 11935,
            difference: -695
          }
        ]
        this.tableDataYearlyTotal = [
          {
            annual_result: '合計',
            current_remarks: '',
            current_amount: 4984565,
            new_remarks: '',
            new_amount: 4812114,
            difference: -172451
          }
        ]
        this.tableDataGrade = [
          {
            grade: 17,
            monthly_amount: 260000,
            amount_from: 250000,
            amount_to: 270000,
            total_amount: 47580.00,
            half_amount: 23790.00,
            company_amount: 23790,
            employee_amount: 23790,
            increase_amount: 0
          },
          {
            grade: 18,
            monthly_amount: 280000,
            amount_from: 270000,
            amount_to: 290000,
            total_amount: 51240.00,
            half_amount: 25620.00,
            company_amount: 25620,
            employee_amount: 25620,
            increase_amount: 1830
          },
          {
            grade: 19,
            monthly_amount: 300000,
            amount_from: 290000,
            amount_to: 310000,
            total_amount: 54900.00,
            half_amount: 27450.00,
            company_amount: 27450,
            employee_amount: 27450,
            increase_amount: 1830
          }
        ]
        this.$emit('adjust')
        this.$nextTick(() => {
          this.$Chartist = Chartist
          this.initStockChart()
        })
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
      this.tableData = [
        {
          salary_item_code: 400,
          salary_item_name: '勤続給',
          current_salary: 193300,
          new_salary: 193300,
          increase_amount: 0,
          is_basic_salary: 1
        },
        {
          salary_item_code: 401,
          salary_item_name: 'キャリア給',
          current_salary: 37000,
          new_salary: 37000,
          increase_amount: 0,
          is_basic_salary: 1
        },
        {
          salary_item_code: 402,
          salary_item_name: '職能給',
          current_salary: 40000,
          new_salary: 40000,
          increase_amount: 0,
          is_basic_salary: 1
        },
        {
          salary_item_code: 403,
          salary_item_name: '住宅手当',
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0,
          is_basic_salary: 2
        },
        {
          salary_item_code: 404,
          salary_item_name: 'リーダー手当',
          current_salary: 30000,
          new_salary: 30000,
          increase_amount: 0,
          is_basic_salary: 2
        },
        {
          salary_item_code: 405,
          salary_item_name: '非課税通勤手当',
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0,
          is_basic_salary: 2
        },
        {
          salary_item_code: 406,
          salary_item_name: '未払金',
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0,
          is_basic_salary: 2
        },
        {
          salary_item_code: 407,
          salary_item_name: '見込み残業手当',
          current_salary: 62569,
          new_salary: 43256,
          increase_amount: -19313,
          is_basic_salary: 2
        },
        {
          salary_item_code: 408,
          salary_item_name: '生涯設計前払金',
          current_salary: 5000,
          new_salary: 5000,
          increase_amount: 0,
          is_basic_salary: 2
        }
      ]
      this.tableData.push({
        salary_item_name: this.$t('label.total')
      })
      this.tableDataHourly = [
        {
          hourly_wage_label: this.$t('label.hourly_wage'),
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0
        },
        {
          hourly_wage_label: this.$t('label.hourly_wage_overtime_premium'),
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0
        },
        {
          hourly_wage_label: this.$t('label.hourly_wage_legal_holiday_premium'),
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0
        },
        {
          hourly_wage_label: this.$t('label.hourly_wage_midnight_premium'),
          current_salary: 0,
          new_salary: 0,
          increase_amount: 0
        }
      ]
      this.updateHourlyWage()
      this.$emit('adjust')
    }
  }
</script>

<style scoped>
</style>