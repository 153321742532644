import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationStartEndTime from '@/components/UIComponents/Inputs/ValidationStartEndTime'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
import MessageView from '@/components/Dashboard/Views/Monthly/ShiftDecisionMessageView'
export default {
  name: 'ShiftDecisionDetailMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationText,
    ValidationTextArea,
    ValidationDate,
    ValidationSelect,
    ValidationStartEndTime,
    EmployeeSearch,
    MessageView
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      componentKey2: 1000,
      componentKey3: 2000,
      componentKey4: 3000,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {},
      updateInfo: {},
      addRowData: {},
      labels: {},
      comboData: {},
      index: '',
      tableIndex: '',
      loginUserName: '',
      error_message: ''
    }
  },
  computed: {
    screenCode () {
      return '06-006'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.loginUserName = this.$store.state.loginUser.employeeName
      this.updateInfo.office_code = res.api_area.office_code
      this.updateInfo.target_date = res.api_area.target_date
      // 行追加のひな形作成
      this.addRowData.employee_code = ''
      this.addRowData.employee_name = ''
      this.addRowData.job_start = ''
      this.addRowData.job_end = ''
      this.addRowData.hope_job_start = ''
      this.addRowData.hope_job_end = ''
      this.addRowData.flex_time = ['', '', false]
      this.addRowData.before_flex_time = ['', '', false]
      this.addRowData.work_status = 1
      this.addRowData.shift_reflection_flg = ''
      this.addRowData.message_list = []
      this.addRowData.message = ''
      this.addRowData.is_read = 1
      this.addRowData.fixed_date = ''
      this.addRowData.update_count = 0
      this.addRowData.can_change_record = true
      this.addRowData.new_record = true

      // 職種、勤務体系の情報をループ
      for (const detail of this.detail) {
        // 存在しない場合、ブランクに変換
        detail.job_start = this.emptyToBlank(detail.job_start)
        detail.job_end = this.emptyToBlank(detail.job_end)
        // 勤務体系の勤務時間をゼロサプレス
        if (detail.job_start !== '' && detail.job_end !== '') {
          detail.job_start = this.zeroSuppress(detail.job_start)
          detail.job_end = this.zeroSuppress(detail.job_end)
        }
        // 従業員情報をループ
        for (const employee of detail.confirm_employee_list) {
          // 初期値設定
          employee.message = ''
          employee.new_record = false
          // 存在しない場合、ブランクに変換
          employee.job_start = this.emptyToBlank(employee.job_start)
          employee.job_end = this.emptyToBlank(employee.job_end)
          employee.hope_job_start = this.emptyToBlank(employee.hope_job_start)
          employee.hope_job_end = this.emptyToBlank(employee.hope_job_end)
          employee.fixed_date = this.emptyToBlank(employee.fixed_date)
          // 数値を文字列に変換
          employee.shift_reflection_flg = String(employee.shift_reflection_flg)
          // 希望時間をゼロサプレス
          if (employee.hope_job_start !== '' && employee.hope_job_end !== '') {
            employee.hope_job_start = this.zeroSuppress(employee.hope_job_start)
            employee.hope_job_end = this.zeroSuppress(employee.hope_job_end)
          } else {
            employee.hope_job_start = ''
            employee.hope_job_end = ''
          }

          // 調整時間がブランクで労働希望日な場合、希望時間を設定
          // if (employee.job_start === '' && employee.job_end === '' && employee.work_status === 1) {
          //   employee.job_start = employee.hope_job_start
          //   employee.job_end = employee.hope_job_end
          // }
          // 出勤ステータスが2の場合、所定休日をtrueを設定
          if (employee.work_status === 2) {
            employee.work_status_2 = true
          // 出勤ステータスが3の場合、法定休日をtrueを設定
          } else if (employee.work_status === 3) {
            employee.work_status_3 = true
          }

          // ゼロサプレス
          employee.job_start = this.zeroSuppress(employee.job_start)
          employee.job_end = this.zeroSuppress(employee.job_end)
          // 24時間表記
          const jobEnd = employee.job_end
          employee.job_end = this.zeroSuppress(this.to24Time(jobEnd))
          employee.flex_time = [employee.job_start, employee.job_end, employee.job_end !== jobEnd]
          // 変更前の希望時間を退避
          employee.before_flex_time = JSON.parse(JSON.stringify(employee.flex_time))
          // 既読フラグに既読を設定
          employee.is_read = 1
          for (const message of employee.message_list) {
            // 自分の場合、次の要素を処理
            if (this.loginUserName === message.sending_employee_name) {
              continue
            }
            // 未読がある場合、未読を設定
            if (message.is_read === 2) {
              employee.is_read = 2
              break
            }
          }
        }

        // 未定時間を設定
        detail.undecided_times = this.getUndecidedTimes(detail)

        // 表示件数になるまで行追加
        for (const undecidedTime of detail.undecided_times) {
          this.addRow(detail.confirm_employee_list)
          detail.confirm_employee_list[detail.confirm_employee_list.length - 1].flex_time = [undecidedTime.job_start, undecidedTime.job_end, this.toMinutes(undecidedTime.job_start) > this.toMinutes(undecidedTime.job_end)]
          detail.confirm_employee_list[detail.confirm_employee_list.length - 1].job_start = undecidedTime.job_start
          detail.confirm_employee_list[detail.confirm_employee_list.length - 1].job_end = undecidedTime.job_end
        }

        // 0件の場合、行追加
        if (detail.confirm_employee_list.length === 0) {
          this.addRow(detail.confirm_employee_list)
        }
        // 開始時間から終了時間までの未定時間の件数
        // let undecidedTimeCount = 0
        // for (const undecidedTime of detail.undecided_times) {
        //   if (undecidedTime.job_start === detail.job_start && undecidedTime.job_end === detail.job_end) {
        //     undecidedTimeCount++
        //   }
        // }
        //  未定時間を設定後、状態を設定
        // for (const employee of detail.confirm_employee_list) {
        //    未定時間の件数が0以外 かつ 状態がブランク かつ 希望時間＝勤務時間の場合、状態に確定を設定
        //   if (undecidedTimeCount !== 0 && employee.shift_reflection_flg === '' && employee.hope_job_start === detail.job_start && employee.hope_job_end === detail.job_end && employee.work_status === 1) {
        //     undecidedTimeCount--
        //     employee.shift_reflection_flg = '4'
        //     employee.fixed_date = this.getFixedDate()
        //      状態がブランクかつ労務費区分が労働希望日の場合、未調整を設定
        //   } else if (employee.shift_reflection_flg === '' && employee.work_status === 1 && !employee.new_record) {
        //     employee.shift_reflection_flg = '1'
        //   }
        // }
        //  状態を設定後、未定時間を再設定
        // detail.undecided_times = this.getUndecidedTimes(detail)
      }

      // 状態のプルダウンにcolorを追加
      this.comboData.shift_reflection_flg_list = this.comboData.shift_reflection_flg_list.map((row) => { return {...row, color: row.value === '1' ? '#e1f5e1' : row.value === '3' ? '#e1ebf5' : row.value === '4' ? '#999' : 'white'} })
      // 状態のプルダウンにブランクを追加
      this.comboData.shift_reflection_flg_list.unshift({'label': '', 'value': ''})
    },
    // 未定時間取得
    getUndecidedTimes (detail) {
      // 未定時間のリスト
      let undecidedTimes = []
      // 勤務時間が設定されていない場合、空リストを返却
      if (detail.job_start === '' || detail.job_end === '') {
        return undecidedTimes
      }
      // 勤務時間を分に変換
      const start = this.toMinutes(detail.job_start)
      const end = this.toMinutes(detail.job_end)
      // 必要人数
      const requiredNumberOfPeople = detail.required_number_of_people
      // 各時間(分単位)の稼働人数リスト
      const schedules = Array(end - start).fill(0)
      // 従業員の件数分処理を繰り返してスケジュールを作成

      for (const employee of detail.confirm_employee_list) {
        // 調整時間が設定されていない場合、次の要素を処理
        if (employee.flex_time[0] === '' || employee.flex_time[1] === '') {
          continue
        }
        // 状態＝ブランク以外の場合、次の要素を処理
        if (employee.shift_reflection_flg === '') {
          continue
        }
        // 勤務時間内の場合、スケジュールに一人追加
        const employeeStart = this.toMinutes(employee.flex_time[0])
        const employeeEnd = this.toMinutes(this.to48Time(('0' + employee.flex_time[1]).slice(-5), employee.flex_time[2]))
        for (let i = employeeStart - start; i < employeeEnd - start; i++) {
          schedules[i]++
        }
      }

      // 時間
      let time = start
      // 時間帯の人数
      let people = requiredNumberOfPeople
      // スケジュールの件数分処理を繰り返して未定時間リストを作成
      for (const schedule of schedules) {
        // 1分前の人数以上 かつ 初回以外の場合
        if (people <= schedule && time !== start) {
          // 増えた人数分処理
          for (let j = people; j < schedule; j++) {
            for (const undecidedTime of undecidedTimes) {
              // 未定時間の終了時間がブランクの場合、時間を設定して終了
              if (undecidedTime.job_end === '') {
                undecidedTime.job_end = this.formatDispMinute(time)
                break
              }
            }
          }
          // 1分前より人数が減った場合
        } else {
          // 必要人数より少ない または 初回の場合
          if (schedule < requiredNumberOfPeople || time === start) {
            // 足りない人数分未定時間を追加
            for (let k = schedule; k < people; k++) {
              undecidedTimes.push({'job_start': this.formatDispMinute(time), 'job_end': ''})
            }
          }
        }
        // 1分増加
        time++
        // 1分前の人数を退避
        people = schedule
      }

      // 未定時間の終了時間がブランクの場合、勤務時間の終了時間を設定
      for (const undecidedTime of undecidedTimes) {
        if (undecidedTime.job_end === '') {
          undecidedTime.job_end = this.formatDispMinute(end)
        }
      }

      // 未定時間を返却
      return undecidedTimes
    },
    // 独自バリデーション用
    customValidate () {
      let isSuccess = true
      let pkList = []
      for (const detail of this.detail) {
        for (const employee of detail.confirm_employee_list) {
          // メッセージ初期化
          employee.error_employee_code = ''
          employee.error_shift_reflection_flg = ''
          employee.error_flex_time = ''
          // 削除フラグがtrueの場合、次の要素を処理
          if (employee.delete) {
            continue
          }
          // 従業員コード、状態、勤務時間、メッセージがブランクの場合、次の要素を処理
          if (employee.employee_code === '' && employee.shift_reflection_flg === '' && employee.message === '') {
            continue
          }
          // 出勤ステータスが労働不可能日かつ状態が確定済で、勤務時間、メッセージがブランクの場合、次の要素を処理
          if (employee.work_status !== 1 && employee.shift_reflection_flg === '4' && employee.flex_time[0] === '' && employee.flex_time[1] === '' && employee.message === '') {
            continue
          }
          // 従業員コードがブランクの場合
          if (employee.employee_code === '') {
            employee.error_employee_code = this.$t('message.field_required_simple')
            isSuccess = false
          }

          // 状態がブランクの場合
          if (employee.shift_reflection_flg === '') {
            employee.error_shift_reflection_flg = this.$t('message.field_required_simple')
            isSuccess = false
          }

          // 勤務時間がブランクの場合
          if (employee.flex_time[0] === '' || employee.flex_time[1] === '') {
            if (employee.work_status === 1) {
              employee.error_flex_time = this.$t('message.field_required_simple')
              isSuccess = false
            }
          } else {
            // 形式チェック
            if (!employee.flex_time[0].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
              employee.error_flex_time = this.$t('message.is_time')
              isSuccess = false
            } else if (!employee.flex_time[1].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
              employee.error_flex_time = this.$t('message.is_time')
              isSuccess = false
            } else {
              // 前後チェック
              let start = this.toMinutes(employee.flex_time[0])
              let end = this.toMinutes(this.to48Time(('0' + employee.flex_time[1]).slice(-5), employee.flex_time[2]))
              if (start >= end) {
                employee.error_flex_time = this.$t('message.start_end_check')
                isSuccess = false
              }
            }
          }

          // PK(従業員コード)を設定
          pkList.push(employee.employee_code)
        }
      }
      // PKListと重複削除したPKListの件数が一致しない場合、エラー
      if (pkList.length !== new Set(pkList).size) {
        this.error_message = this.$t('message.shift_decision_pk_check')
        isSuccess = false
      } else {
        this.error_message = ''
      }
      return isSuccess
    },
    // 送信データ調整用
    convertDetail (details) {
      let apiArea = {}
      let confirmEmployeeList = []
      // detailの内容を更新APIに整形する
      for (const detail of details) {
        for (const employee of detail.confirm_employee_list) {
          // 削除フラグがtrueの場合、次の要素を処理する
          if (employee.delete) {
            continue
          }
          // 従業員コード、状態、メッセージがブランクの場合、次の要素を処理する
          if (employee.employee_code === '' && employee.shift_reflection_flg === '' && employee.message === '') {
            continue
          }
          let updateDetail = {}
          updateDetail.work_schedule_code = detail.work_schedule_code

          updateDetail.employee_code = employee.employee_code
          // updateDetail.hope_work_status = employee.hope_work_status
          updateDetail.hope_job_start = employee.hope_job_start === '' ? '' : ('0' + employee.hope_job_start).slice(-5)
          updateDetail.hope_job_end = employee.hope_job_end === '' ? '' : ('0' + employee.hope_job_end).slice(-5)
          updateDetail.work_status = employee.work_status
          if (updateDetail.work_status === 1) {
            updateDetail.job_start = employee.flex_time[0] === '' ? '' : ('0' + employee.flex_time[0]).slice(-5)
            updateDetail.job_end = employee.flex_time[1] === '' ? '' : this.to48Time(('0' + employee.flex_time[1]).slice(-5), employee.flex_time[2])
          }
          updateDetail.shift_reflection_flg = parseInt(employee.shift_reflection_flg)
          updateDetail.fixed_date = employee.fixed_date
          updateDetail.update_count = employee.update_count
          updateDetail.request_submission_flg = employee.request_submission_flg
          updateDetail.message = employee.message
          updateDetail.message_list = employee.message_list

          confirmEmployeeList.push(updateDetail)
        }
      }
      apiArea.confirm_employee_list = confirmEmployeeList
      apiArea.office_code = this.updateInfo.office_code
      apiArea.target_date = this.updateInfo.target_date

      return apiArea
    },
    // 状態変更時
    shiftReflectionFlgChanged (detail, employee) {
      employee.error_shift_reflection_flg = ''
      // 確定済の場合、確定日を設定
      if (employee.shift_reflection_flg === '4') {
        employee.fixed_date = this.getFixedDate()
      } else {
        employee.fixed_date = ''
      }
      // 調整中以外の場合、依頼送信にfalseを設定
      if (employee.shift_reflection_flg !== '3') {
        employee.request_submission_flg = false
      }
      // 未定時間を設定
      detail.undecided_times = this.getUndecidedTimes(detail)
    },
    // 調整時間入力時
    inputFlexTime (detail, employee) {
      employee.error_flex_time = ''
      // 調整時間がブランクで休みの場合、状態を確定済みに変更
      if (employee.flex_time[0] === '' && employee.flex_time[1] === '' && employee.work_status !== 1) {
        employee.shift_reflection_flg = '4'
        employee.fixed_date = this.getFixedDate()
      // ブランクの場合
      } else if (employee.flex_time[0] === '' || employee.flex_time[1] === '') {
        employee.flex_time[2] = false
      // 前回と内容が同じ場合、処理なし
      } else if (employee.flex_time[0] === employee.before_flex_time[0] && employee.flex_time[1] === employee.before_flex_time[1]) {
      // 開始時間が終了時間より大きい場合
      } else if (this.toMinutes(employee.flex_time[0]) > this.toMinutes(employee.flex_time[1])) {
        employee.flex_time[2] = true
        employee.shift_reflection_flg = '3'
        employee.request_submission_flg = true
        employee.fixed_date = ''
      } else {
        employee.flex_time[2] = false
        employee.shift_reflection_flg = '3'
        employee.request_submission_flg = true
        employee.fixed_date = ''
      }
      employee.before_flex_time = JSON.parse(JSON.stringify(employee.flex_time))
      this.detail = JSON.parse(JSON.stringify(this.detail))
      // 未定時間を設定
      detail.undecided_times = this.getUndecidedTimes(detail)
      this.componentKey2 += 1
      this.componentKey3 += 1
      this.componentKey4 += 1
    },
    // 所定休日変更時
    workStatus2Changed (employee) {
      // 状態を確定済みに変更
      employee.shift_reflection_flg = '4'
      // 法定休日がtrueの場合、falseを設定
      if (employee.work_status_3) {
        employee.work_status_3 = false
      }
      if (employee.work_status_2) {
        employee.work_status = 2
        // 状態を確定済みに変更
        employee.shift_reflection_flg = '4'
        employee.fixed_date = this.getFixedDate()
      } else {
        employee.work_status = 1
      }
      this.detail = JSON.parse(JSON.stringify(this.detail))
    },
    // 法定休日変更時
    workStatus3Changed (employee) {
      // 所定休日がtrueの場合、falseを設定
      if (employee.work_status_2) {
        employee.work_status_2 = false
      }
      if (employee.work_status_3) {
        employee.work_status = 3
        // 状態を確定済みに変更
        employee.shift_reflection_flg = '4'
        employee.fixed_date = this.getFixedDate()
      } else {
        employee.work_status = 1
      }
      this.detail = JSON.parse(JSON.stringify(this.detail))
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          this.componentKey += 1
          return
        }
        let detail = null
        detail = JSON.parse(JSON.stringify(this.detail))
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        this.send(`/${operation}/shift_decision`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
          // 再描画
          this.detail = Array.isArray(res.api_area) ? res.api_area : res.api_area.shift_schedule_detail_list
          let labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.labels = {...this.labels, ...labels}
          this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
          if (res.api_area) {
            for (const entry of Object.entries(this.detail)) {
              if (typeof entry[1] === 'number') {
                this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                this.detail[entry[0]] = entry[1]
              }
            }
          }
          this.afterInit(res)
          this.$nextTick(() => {
            if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
              this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
            }
          })
          this.componentKey += 1
        }).catch(error => {
          console.error(error)
        })
        this.componentKey += 1
      })
    },
    init () {
      const ymd = this.params.day.split('/')
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code,
          target_day: ymd[2],
          target_month: ymd[1],
          target_year: ymd[0]
        }
      }
      this.send('/init/shift_decision', data)
      .then(res => {
        this.detail = Array.isArray(res.api_area) ? res.api_area : res.api_area.shift_schedule_detail_list
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
        if (res.api_area) {
          for (const entry of Object.entries(this.detail)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    // 行追加
    addRow (list) {
      // 新規行追加
      list.push(JSON.parse(JSON.stringify(this.addRowData)))
      // スクロールバー設定
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
    },
    // 従業員番号入力
    employeeSelected (tableIndex, index, businessType, targetDate, workScheduleCode) {
      if (!this.detail[tableIndex].confirm_employee_list[index].employee_code) {
        return
      }
      this.detail[tableIndex].confirm_employee_list[index].employee_name = ''
      let isSuccess = true
      // 選択した従業員が既に存在する場合、エラー
      for (let i = 0; i < this.detail.length; i++) {
        for (let j = 0; j < this.detail[i].confirm_employee_list.length; j++) {
          // 削除フラグがtrueの場合、次の要素を処理
          if (this.detail[i].confirm_employee_list[j].delete) {
            continue
          }
          if (this.detail[i].confirm_employee_list[j].employee_code === this.detail[tableIndex].confirm_employee_list[index].employee_code && !(i === tableIndex && j === index)) {
            this.detail[tableIndex].confirm_employee_list[index].employee_code = ''
            isSuccess = false
            break
          }
        }
      }
      if (!isSuccess) {
        this.error_message = this.$t('message.shift_decision_pk_check')
        return
      }
      this.error_message = ''
      if (!this.canShow('/SS/menuSSScreen018')) {
        swal({
          title: this.$t('message.employee_not_found'),
          html: this.$t('message.employee_not_found_description'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.detail.responsible_employee_code = ''
          this.detail.responsible_employee_name = ''
        })
        return
      }
      const searchCond = {
        screen_code: '06-006',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          employee_code: this.detail[tableIndex].confirm_employee_list[index].employee_code,
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          business_type: businessType,
          target_date: targetDate,
          work_schedule_code: workScheduleCode
        }
      }
      this.tempHelp = this.help
      this.send('/view/shift_search_employee/list', searchCond, false, true)
      .then(res => {
        if (res.api_area.employee_list && res.api_area.employee_list.length === 1) {
          if (res.api_area.employee_list[0].is_input) {
            // 従業員番号と従業員名を設定
            this.detail[tableIndex].confirm_employee_list[index].employee_code = res.api_area.employee_list[0].employee_code
            this.detail[tableIndex].confirm_employee_list[index].employee_name = res.api_area.employee_list[0].employee_name
          } else {
            this.detail[tableIndex].confirm_employee_list[index].employee_code = ''
            this.detail[tableIndex].confirm_employee_list[index].employee_name = ''
          }
        } else {
          this.detail[tableIndex].confirm_employee_list[index].employee_code = ''
          this.detail[tableIndex].confirm_employee_list[index].employee_name = ''
        }
        this.help = this.tempHelp
      })
    },
    // 従業員検索表示
    showEmployeeSearchModal (tableIndex, index, businessType, targetDate, workScheduleCode) {
      this.tableIndex = tableIndex
      this.index = index
      // 親のshow-employeeを実施
      this.$emit('show-employee', businessType, targetDate, workScheduleCode)
    },
    // 従業員検索閉じる
    hideEmployeeSearchModal (selected) {
      // モーダルemployeeで従業員が選択された場合
      if (selected) {
        let isSuccess = true
        // 選択した従業員が既に存在する場合、エラー
        for (const detail of this.detail) {
          for (const employee of detail.confirm_employee_list) {
            // 削除フラグがtrueの場合、次の要素を処理
            if (employee.delete) {
              continue
            }
            if (employee.employee_code === selected.employee_code) {
              isSuccess = false
              break
            }
          }
        }
        if (!isSuccess) {
          this.error_message = this.$t('message.shift_decision_pk_check')
        } else {
          this.error_message = ''
          // 従業員番号と従業員名を設定
          this.detail[this.tableIndex].confirm_employee_list[this.index].employee_code = selected.employee_code
          this.detail[this.tableIndex].confirm_employee_list[this.index].employee_name = selected.employee_name
        }
        this.componentKey += 1
      }
    },
    // メッセージ一覧表示
    showMessageView (tableIndex, index, employee, messageList, message, isDisabled) {
      this.tableIndex = tableIndex
      this.index = index
      // 既読を設定
      employee.is_read = 1
      for (const message of messageList) {
        if (this.loginUserName === message.sending_employee_name) {
          continue
        }
        message.is_read = 1
      }
      this.componentKey += 1
      // 親のshow-messageを実施
      this.$emit('show-message', messageList, message, isDisabled, this.loginUserName, this.labels)
    },
    // メッセージ一覧閉じる
    hideMessageView (detail) {
      this.detail[this.tableIndex].confirm_employee_list[this.index].message = detail.message
      this.componentKey += 1
    },
    // 確定日取得
    getFixedDate () {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')
      const fixedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
      return fixedDate
    },
    // 時間表示
    viewTime (jobStart, jobEnd) {
      const viewTime = []
      if (jobStart !== '' && jobEnd !== '') {
        viewTime.push(this.zeroSuppress(jobStart))
        viewTime.push(' - ')
        viewTime.push(this.zeroSuppress(this.to24Time(jobEnd)))
      }
      return viewTime.join('')
    }
  },
  created () {
    this.init()
  }
}
