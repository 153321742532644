<template>
  <div class="row" style="overflow-y: auto; height: 570px;" name="employeeFrame">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="col-md-12">
      <modal name="groupRoleAllocation" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="500">
        <div class="modal-body">
          <GroupRoleAllocationDetail
            :mode="mode"
            :params="params"
            :selectRoles="selectRoles"
            :roleList="role_list"
            :groupSelectNumber="groupSelectNumber"
            :payrollManagement="payroll_management"
            :attendanceManagement="attendance_management"
            :labels="labels"
            @close="hideAllocation"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="GroupSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
        <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="groupSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
          <GroupSearch :mode="1" :params="params"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-content" style="padding-left: 20px;">
          <div class="row">
            <label for="planId">
              {{labels.group}}
            </label>
            <!-- 追加ボタン -->
            <template>
              <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" data-v-step="105" @click="addGroupRecord">{{$t('button.add_row')}}</button>
              <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" data-v-step="106" @click="groupSearchShowAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button>
              <label>
                <input type="checkbox" class="gray" v-model="group_hidden" :disabled="isReadOnly" @change="hiddenGroupList">
                {{'有効期間外も表示'}}
              </label>
            </template>
            <div class="error-message-wrapper" v-for="errorMsg in group_error" v-bind:key="errorMsg">
              <small class="text-danger">
                <!-- エラーメッセージ -->
                {{ errorMsg }}
              </small>
            </div>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"              border
              :data="group_record"
              :style="getStyle()">
              <!-- 削除 -->
              <el-table-column min-width="60" :label="$t('label.delete')">
                <template slot-scope="props">
                  <button :disabled="isReadOnly" type="button" class="btn btn-primary btn-sm" @click="deleteGroupRecord(props.$index)">{{$t('label.delete')}}</button>
                </template>
              </el-table-column>
              <!-- 部署 -->
              <el-table-column min-width="200" :label="labels.group_ryaku ? labels.group_ryaku : ''">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="100">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.group_code">
                    <el-option v-for="option in group_list"
                                :class="option.optionClass"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 標準部署コード -->
              <el-table-column min-width="80" :label="labels.default_group_code_ryaku ? labels.default_group_code_ryaku : '標準部署'" data-v-step="1">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="101">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center; padding: 10px 0 0 10px;">
                    <p-radio v-model="selectDefaultGroupCode" :label="props.row.random" :disabled="isReadOnly||isEnd(props.row.term_to)"></p-radio>
                  </div>
                </template>
              </el-table-column>
              <!-- 利用権限範囲 -->
              <el-table-column min-width="200" :label="labels.range_ryaku ? labels.range_ryaku : ''">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="102">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              v-model="props.row.range">
                    <el-option v-for="option in range_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 割当ボタン -->
              <el-table-column min-width="100" :label="labels.role_ryaku ? labels.role_ryaku : ''">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="103">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <button type="button" class="btn btn-warning btn-sm btn-default" @click="showAllocation(props.row.role_list, props.row.number)">{{$t('button.allocation')}}</button>
                </template>
              </el-table-column>
              <!-- 所属期間(開始) -->
              <el-table-column min-width="150" :label="$t('label.employee_group_term_from')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="104">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.term_from"
                      type="date"
                      :placeholder="$t('placeholder.start_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
              <!-- 所属期間(終了) -->
              <el-table-column min-width="150" :label="$t('label.employee_group_term_to')" v-if="!isAddMode()">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.term_to"
                      type="date"
                      :placeholder="$t('placeholder.end_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour100" :steps="steps"/>
  </div>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import GroupRoleAllocationDetail from '@/components/Dashboard/Views/Master/EmployeeGroupRoleAllocationDetail'
  import GroupSearch from '@/components/Dashboard/Views/Generated/GroupSearch.vue'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      GroupRoleAllocationDetail,
      GroupSearch,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        steps: [
          {
            target: '[data-v-step="100"]',
            content: '従業員の所属する部署を選択してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="101"]',
            content: '指定した部署が出勤簿に表示されます。必ず1つ選択してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="102"]',
            content: '従業員に付与する権限範囲の設定です。個人の場合、自身の情報のみ閲覧できます。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="103"]',
            content: '従業員に各画面の利用権限を与えるために選択してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="104"]',
            content: '部署に所属を開始する年月日を入力してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="105"]',
            content: '複数の部署に所属している場合、行追加で入力してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="106"]',
            content: '部署を新しく登録することもできます。',
            params: {placement: 'top'}
          }
        ],
        labels: {},
        group_record: [
          {
            selected: false,
            number: 0,
            group_code: '',
            range: '',
            term_from: '',
            term_to: '',
            role_list: [
              'general'
            ],
            addFlg: true
          }
        ],
        retirement_date: '',
        group_list: [],
        range_list: [],
        selectRoles: '',
        groupSelectNumber: 0,
        group_error: [],
        mode: 0,
        selectDefaultGroupCode: 'default',
        today: '',
        evacuation_group_record: [],
        group_hidden: false,
        payroll_management: '',
        attendance_management: ''
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      replay () {
        this.$tours['myTour100'].start()
      },
      init () {
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        this.today = Number(String(year) + month + day)
      },
      hiddenGroupList () {
        if (this.group_hidden) {
          // 有効期間外も表示
          this.group_record = this.group_record.concat(this.evacuation_group_record)
          this.evacuation_group_record = []
        } else {
          // 有効期限のみ表示
          let deleteIndexs = []
          let index = 0
          for (let group of this.group_record) {
            if (this.isEnd(group.term_to)) {
              // 期間が過ぎている場合
              this.evacuation_group_record.push(group)
              deleteIndexs.push(index)
            }
            index = index + 1
          }
          // 要素の削除
          for (let listIndex = deleteIndexs.length - 1; listIndex >= 0; listIndex--) {
            this.group_record.splice(deleteIndexs[listIndex], 1)
          }
        }
      },
      groupSearchShowAdd () {
        this.params = {}
        this.$modal.show('GroupSearch')
      },
      groupSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 部署
          this.group_list = res.common_area.combo_data.group_list
        })
        this.$modal.hide('GroupSearch')
      },
      isEnd (termTo) {
        if (typeof termTo === 'object') {
          termTo = this.formatDate(termTo)
        }
        if (termTo === null || termTo === '') {
          return false
        }
        termTo = termTo.split('/')
        termTo = termTo.join('')
        if (Number(termTo) < this.today) {
          return true
        }
      },
      // 部署の追加ボタン押下時の処理
      addGroupRecord () {
        let allGroup = this.group_record.concat(this.evacuation_group_record)
        let record = {
          selected: false,
          number: allGroup.length,
          group_code: '',
          range: '',
          term_from: '',
          term_to: '',
          role_list: [
            'general'
          ],
          addFlg: true,
          random: ''
        }
        let parent = this.$parent.$parent.$parent
        let payrollManagement = parent.$refs.employeeBasicInfo.payroll_management.value
        let attendanceManagement = parent.$refs.employeeBasicInfo.attendance_management.value
        if (payrollManagement === '1') {
          record.role_list.push('payroll_management')
        }
        if (attendanceManagement === '1') {
          record.role_list.push('attendance_management')
        }
        record.random = this.getRandomStr()
        this.group_record.push(record)
      },
      // 部署の削除ボタン押下時の処理
      deleteGroupRecord (index) {
        this.group_record.splice(index, 1)
      },
      showAllocation (param, number) {
        this.selectRoles = param
        this.groupSelectNumber = number
        let parent = this.$parent.$parent.$parent
        this.payroll_management = parent.$refs.employeeBasicInfo.payroll_management.value
        this.attendance_management = parent.$refs.employeeBasicInfo.attendance_management.value
        this.$modal.show('groupRoleAllocation')
      },
      hideAllocation (roleList, number) {
        for (const data of this.group_record) {
          if (data.number === number) {
            data.role_list = roleList
          }
        }
        this.$modal.hide('groupRoleAllocation')
      },
      getRange (range) {
        for (const data of this.range_list) {
          if (String(range) === data.value) {
            return this.getLabel(data.label)
          }
        }
        return ''
      },
      getRandomStr () {
        // 生成する文字列の長さ
        var l = 16

        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'

        var cl = c.length
        var r = ''
        for (var i = 0; i < l; i++) {
          r += c[Math.floor(Math.random() * cl)]
        }
        return r
      },
      setDetails (res, mode) {
        if ((mode !== this.modes.add) || (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '')) {
          this.group_record = res.api_area.group_list
          this.group_record = []
          let index = 0
          for (const group of res.api_area.group_list) {
            if ((mode === this.modes.add && sessionStorage.getItem('employee_code') !== '') && this.isEnd(group.term_to)) {
              continue
            }
            let record = {
              number: this.group_record.length,
              group_code: group.group_code,
              term_from: group.term_from,
              term_to: group.term_to,
              range: String(group.range),
              random: this.getRandomStr(),
              role_list: []
            }
            for (const role of res.api_area.employee_group_role_list) {
              if (group.group_code === role.group_code) {
                record.role_list.push(role.role_code)
              }
            }
            if (group.default_group_code === 1) {
              this.selectDefaultGroupCode = record.random
            }
            this.group_record.push(record)
            index = index + 1
          }
        }
        if (this.isEnd(this.retirement_date)) {
          this.group_hidden = true
        } else {
          this.hiddenGroupList()
        }
      },
      isAddMode () {
        return this.mode === this.modes.add
      },
      getStyle () {
        if (this.isAddMode()) {
          return 'width: 810px'
        } else {
          return 'width: 960px'
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .end-select {
    background-color: #d5d5d5;
  }
</style>
