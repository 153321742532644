<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="search_screen" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
      <ValidationObserver ref="observer">
        <ScreenList
          v-model="detail.screen_data"
          item-name="screen_data"
          classes="form-control"
          :item-label="labels.screen_data"
          :disabled="isReadOnly"
          :labels="labels"
        />
      </ValidationObserver>
      <div style="text-align: left; margin-top: 20px;" v-if="false">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
          <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.update')}}
        </button>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import SearchScreenSheetMixin from '@/components/Dashboard/Views/Generated/SearchScreenSheetMixin'

  export default {
    name: 'SearchScreenSheet',
    methods: {
      afterInit (res) {
        for (const row of res.api_area.screen_data) {
          const to = row.help.lastIndexOf('-')
          row.url = row.help.substring(0, to)
          row.text = row.help.substring(to + 1)
        }
      }
    },
    mixins: [SearchScreenSheetMixin]
  }
</script>
<style scoped>
</style>
