<template>
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <el-date-picker 
      v-model="itemValue" 
      type="date" 
      :name="itemName"
      :placeholder="placeholder"
      :disabled="disabled"
      :picker-options="pickerOptions"
      @change="onChange">
    </el-date-picker>
    <small class="text-danger" v-show="!valid">
      {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
    </small>
  </ValidationProvider>
</template>

<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: String,
      itemName: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      pickerOptions: {
        type: Object,
        require: false,
        'default': () => ({
          disabledDate (time) {
            return false
          }
        })
      }
    },
    data () {
      return {
        itemValue: null
      }
    },
    methods: {
      onChange () {
        this.$emit('input', this.formatDate(this.itemValue))
        this.$emit('change')
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>