<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            {{$t('label.change_language')}}
          </div>
          <div class="card-content">
            <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
            <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
            <ValidationRadio
              v-model="language"
              rules="required"
              :item-label="labels.language"
              :disabled="isChanged"
            />
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanged" @click="updateLanguage">
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.update')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationRadio
    },
    data () {
      return {
        labels: {},
        comboData: {},
        language: {
          value: 'JPN',
          disabled: false,
          items: []
        },
        updateCount: 1,
        isChanged: false
      }
    },
    methods: {
      updateLanguage () {
        let request = {
          screen_code: 'DB-014',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            language: this.language.value,
            update_count: this.updateCount
          }
        }
        this.send(`/update/change_language`, request)
        .then(res => {
          const employeeDashboardList = []
          for (const row of this.$store.state.dashboardList) {
            if (row.sort_number > 0) {
              employeeDashboardList.push(row)
            }
          }
          const sidebarLinks = this.getSidebarLinks(this, 2,
            res.common_area.combo_data.menu_screen_employee_list, employeeDashboardList, this.$store.state.dbList)
          const loginInfo = this.$store.state.loginUser
          loginInfo.sidebarLinks = sidebarLinks
          this.$store.commit('save', loginInfo)
          let language
          switch (this.language.value) {
            case 'EN':
              language = 'en'
              break
            default:
              language = 'ja'
              break
          }
          this.$i18n.locale = language
          localStorage.setItem('language', language)
          this.isChanged = true
        })
      },
      doClose () {
        this.$emit('close', this.isChanged)
      },
      init () {
        let request = {
          screen_code: 'DB-014',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {}
        }
        this.send(`/init/change_language`, request)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.language = {
            value: res.api_area.language,
            disabled: false,
            items: this.comboData.language_type_list
          }
          this.updateCount = res.api_area.update_count
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
