import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Annual/LegalRuleDetail'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'LegalRuleSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    ValidationSelect,
    PPagination
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1650,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        office_code: '',
        document_style: ''
      },
      showOperation: true,
      showOffice: true,
      showDocumentStyle: true,
      showStartingDate: true,
      showTermFrom: true,
      showTermTo: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      tableStyle: ''
    }
  },
  computed: {
    screenCode () {
      return '07-002'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    // 送信データ調整用
    convertSearchCond (searchCond) {
      if (searchCond.document_style === '') {
        searchCond.document_style = null
      } else {
        searchCond.document_style = parseInt(searchCond.document_style)
      }

      return searchCond
    },
    officeCodeChanged () {},
    documentStyleChanged () {},
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    doPrint (index, row) {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: row.office_code,
          starting_date: row.starting_date,
          document_style: row.document_style
        }
      }
      this.send('/print/legal_rule', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/legal_rule/list', reqData)
      .then(res => {
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.legal_rule_format_list
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)

        // TODO 様式タイプを上書き
        this.comboData.document_style_list = [
          {
            'label': '様式1',
            'value': '1'
          },
          {
            'label': '様式2',
            'value': '2'
          }
        ]
        this.comboData.office_list.unshift({ 'label': '', 'value': '' })
        this.comboData.document_style_list.unshift({ 'label': '', 'value': '' })
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.$refs.summary.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/legal_rule/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)

        // TODO 様式タイプを上書き
        this.comboData.document_style_list = [
          {
            'label': '様式1',
            'value': '1'
          },
          {
            'label': '様式2',
            'value': '2'
          }
        ]
        this.comboData.office_list.unshift({ 'label': '', 'value': '' })
        this.comboData.document_style_list.unshift({ 'label': '', 'value': '' })
        this.afterInit(res)
        if (this.$route.params.attendanceRecord) {
          this.search()
        }
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.office_code = row.office_code
      this.params.starting_date = row.starting_date
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.office_code = row.office_code
      this.params.starting_date = row.starting_date
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.office_code = row.office_code
      this.params.starting_date = row.starting_date
      this.params.document_style = row.document_style
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      } else if (row.update_date) {
        if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
          return 'update-row'
        }
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    }
  },
  created () {
    this.init()
  }
}
