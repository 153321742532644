<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <WorkScheduleAllocationTable
              v-model="detail.allocation"
              :org-selected-work-schedules="selectedWorkSchedules"
              item-name="allocation"
              classes="form-control"
              :item-label="labels.allocation"
              :disabled="isReadOnly"
              :labels="labels"
              :error="error"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import WorkScheduleAllocationDetailMixin from '@/components/Dashboard/Views/Generated/WorkScheduleAllocationDetailMixin'

  export default {
    name: 'WorkScheduleAllocationDetail',
    data () {
      return {
        error: ''
      }
    },
    methods: {
      afterInit (res) {
        const workSchedules = []
        const selectedWorkSchedules = []
        let defaultWorkSchedule = ''
        for (const workSchedule of res.api_area.work_schedule_list) {
          let selected = false
          let offSiteDeemedWorkingHoursFlag = false
          for (const employeeWorkSchedule of res.api_area.work_schedule_employee_data) {
            if (employeeWorkSchedule.work_schedule_code === workSchedule.value) {
              selected = true
              offSiteDeemedWorkingHoursFlag = employeeWorkSchedule.off_site_deemed_working_hours_flag
              if (employeeWorkSchedule.default_work_schedule_code === 1) {
                defaultWorkSchedule = employeeWorkSchedule.work_schedule_code
              }
              break
            }
          }
          const row = {
            work_schedule_code: workSchedule.value,
            work_schedule_name: workSchedule.label,
            off_site_deemed_working_hours_flag: offSiteDeemedWorkingHoursFlag,
            selected: selected
          }
          workSchedules.push(row)
          if (row.selected) {
            selectedWorkSchedules.push(row)
          }
        }
        this.detail.allocation = {
          default_work_schedule: defaultWorkSchedule,
          work_schedules: workSchedules
        }
        this.selectedWorkSchedules = selectedWorkSchedules
        this.componentKey += 1
      },
      customValidate () {
        this.error = this.$t('message.default_work_schdule_is_required')
        for (const row of this.detail.allocation.work_schedules) {
          if (row.selected) {
            if (row.work_schedule_code === this.detail.allocation.default_work_schedule) {
              this.error = ''
              break
            }
          }
        }
        return this.error === ''
      },
      convertDetail (detail) {
        const apiArea = {}
        apiArea.employee_code = this.params.employee_code
        apiArea.work_schedule_list = []
        for (const row of detail.allocation.work_schedules) {
          if (row.selected) {
            const workSchedule = {
              work_schedule_code: row.work_schedule_code,
              off_site_deemed_working_hours_flag: row.off_site_deemed_working_hours_flag,
              default_flag: row.work_schedule_code === detail.allocation.default_work_schedule ? 1 : 2
            }
            apiArea.work_schedule_list.push(workSchedule)
          }
        }
        return apiArea
      }
    },
    mixins: [WorkScheduleAllocationDetailMixin]
  }
</script>
<style scoped>
</style>
