<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottm: 5px; margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <ValidationObserver ref="observer">
        <div class="row">
          <div class="col-md-12">
            <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">
                  {{$t('label.paid_holiday_setting')}}
                </h4>
              </div>
              <div class="card-content">
                <PaidHolidaySetting
                  v-model="this.detail"
                  :labels="this.labels"
                  :combo-data="this.comboData"
                  :disabled="this.isReadOnly"
                  :err-msg="this.errMsg"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div style="text-align: left;">
              <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.update')}}
              </button>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import PaidHolidayManagementSheetMixin from '@/components/Dashboard/Views/Generated/PaidHolidayManagementSheetMixin'

  export default {
    name: 'PaidHolidayManagementSheet',
    data () {
      return {
        errMsg: ''
      }
    },
    methods: {
      afterInit (res) {
        if (res.api_area) {
          for (const row of res.api_area.paid_holiday_reference_list) {
            if (row.reference_month && row.reference_month.length > 0) {
              const referenceMonth = row.reference_month.split('/')
              row.reference_month_month = String(parseInt(referenceMonth[0], 10))
              row.reference_month_day = String(parseInt(referenceMonth[1], 10))
            }
            if (row.term_from && row.term_from.length > 0) {
              const termFrom = row.term_from.split('/')
              row.term_from_month = String(parseInt(termFrom[0], 10))
              row.term_from_day = String(parseInt(termFrom[1], 10))
            }
            if (row.term_to && row.term_to.length > 0) {
              const termTo = row.term_to.split('/')
              row.term_to_month = String(parseInt(termTo[0], 10))
              row.term_to_day = String(parseInt(termTo[1], 10))
            }
          }
        } else {
          this.detail.paid_holiday_reference_list = []
        }
        if (!this.detail.alternative_leave_flag) {
          this.detail.alternative_leave_flag = '2'
        }
        if (!this.detail.alternative_leave_rate) {
          this.detail.alternative_leave_rate = '0'
        }
        this.detail.alternative_leave_flag = {
          color: 'gray',
          disabled: false,
          items: [
            {
              id: 1,
              label: '',
              value: this.detail.alternative_leave_flag === '1'
            }
          ]
        }
      },
      customValidate () {
        if (this.detail.paid_leave_granted === '2') {
          this.errMsg = ''
          if (this.detail.paid_leave_payment_method === '2' || this.detail.paid_leave_payment_method === '3') {
            const monthDays = []
            if (this.detail.unified_payment_date1) monthDays.push(this.detail.unified_payment_date1)
            if (this.detail.unified_payment_date2) monthDays.push(this.detail.unified_payment_date2)
            if (this.detail.unified_payment_date3) monthDays.push(this.detail.unified_payment_date3)
            if (this.detail.unified_payment_date4) monthDays.push(this.detail.unified_payment_date4)
            if (this.detail.unified_payment_date5) monthDays.push(this.detail.unified_payment_date5)
            if (this.detail.unified_payment_date6) monthDays.push(this.detail.unified_payment_date6)
            if (this.detail.unified_payment_date7) monthDays.push(this.detail.unified_payment_date7)
            if (this.detail.unified_payment_date8) monthDays.push(this.detail.unified_payment_date8)
            if (this.detail.unified_payment_date9) monthDays.push(this.detail.unified_payment_date9)
            if (this.detail.unified_payment_date10) monthDays.push(this.detail.unified_payment_date10)
            if (this.detail.unified_payment_date11) monthDays.push(this.detail.unified_payment_date11)
            if (this.detail.unified_payment_date12) monthDays.push(this.detail.unified_payment_date12)
            const monthDaySet = new Set(monthDays)
            if (monthDaySet.size !== monthDays.length) {
              this.errMsg = this.$t('message.month_day_duplicated')
              this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
              return false
            }
          } else {
            if (!this.detail.paid_holiday_reference_list || this.detail.paid_holiday_reference_list.length === 0) {
              this.errMsg = this.$t('message.is_table_required').replace('{item}', this.labels.unified_payment_date1)
              this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
              return false
            }
          }
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          const paidHolidayReference = []
          if (detail.paid_holiday_reference_list) {
            for (const row of detail.paid_holiday_reference_list) {
              if (row.selected) continue
              const element = {
                reference_month: row.reference_month,
                number_of_working_days_per_week: parseInt(row.number_of_working_days_per_week, 10),
                term_from: row.term_from,
                term_to: row.term_to,
                grant_days: parseInt(row.grant_days, 10)
              }
              paidHolidayReference.push(element)
            }
          }
          detail.paid_holiday_reference_list = paidHolidayReference
          detail.attendance_rate = parseFloat(detail.attendance_rate)
          detail.available_in_one_year = parseInt(detail.available_in_one_year, 10)
          detail.digestion_order = parseInt(detail.digestion_order, 10)
          detail.fraction_of_attendance_rate = parseInt(detail.fraction_of_attendance_rate, 10)
          detail.handling_planned_grants = parseInt(detail.handling_planned_grants, 10)
          detail.limit = parseInt(detail.limit, 10)
          detail.maximum_days = parseInt(detail.maximum_days, 10)
          detail.paid_leave_granted = parseInt(detail.paid_leave_granted, 10)
          detail.paid_leave_payment_method = detail.paid_leave_payment_method ? parseInt(detail.paid_leave_payment_method, 10) : 1
          if (detail.paid_leave_granted === 1) {
            this.detail.paid_leave_payment_method = null
            this.detail.unified_payment_date1 = null
            this.detail.unified_payment_date2 = null
            this.detail.unified_payment_date3 = null
            this.detail.unified_payment_date4 = null
            this.detail.unified_payment_date5 = null
            this.detail.unified_payment_date6 = null
            this.detail.unified_payment_date7 = null
            this.detail.unified_payment_date8 = null
            this.detail.unified_payment_date9 = null
            this.detail.unified_payment_date10 = null
            this.detail.unified_payment_date11 = null
            this.detail.unified_payment_date12 = null
          } else if (detail.paid_leave_payment_method === 1) {
            this.detail.unified_payment_date2 = null
            this.detail.unified_payment_date3 = null
            this.detail.unified_payment_date4 = null
            this.detail.unified_payment_date5 = null
            this.detail.unified_payment_date6 = null
            this.detail.unified_payment_date7 = null
            this.detail.unified_payment_date8 = null
            this.detail.unified_payment_date9 = null
            this.detail.unified_payment_date10 = null
            this.detail.unified_payment_date11 = null
            this.detail.unified_payment_date12 = null
          }
          detail.roll_over = parseInt(detail.roll_over, 10)
          detail.update_count = detail.update_count ? parseInt(detail.update_count, 10) : 1
          detail.upper_limit = parseInt(detail.upper_limit, 10)
          detail.limit_apply_date = parseInt(detail.limit_apply_date, 10)
          detail.legal_60_overwork_rate = parseFloat(detail.legal_60_overwork_rate)
          detail.alternative_leave_flag = detail.alternative_leave_flag.items[0].value ? 1 : 2
          detail.alternative_leave_rate = detail.alternative_leave_flag === 2 ? 0 : parseFloat(detail.alternative_leave_rate)
          delete detail.paid_holiday_management
        }
        return detail
      }
    },
    mixins: [PaidHolidayManagementSheetMixin]
  }
</script>
<style scoped>
</style>
