<template>
  <div>
    <div class="row" :key="componentKey">
      <div class="col-sm-12">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            {{labels.label_dashboard_list}}
          </div>
          <div class="card-content">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      style="width: 100%">
              <el-table-column
                :width="90"
                fixed="left"
                :label="labels.label_data ? labels.label_data.label_view : ''"
                align="center">
                <template slot-scope="props">
                  <input type="checkbox" v-model="props.row.selected" class="gray">
                </template>
              </el-table-column>
              <el-table-column min-width="110" :label="labels.screen_code ? labels.screen_code : ''" align="center">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="110" align="center">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="up(props.$index, props.row)">↑</button>
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="down(props.$index, props.row)">↓</button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-footer">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-sm btn-default btn-submit" @click="updateDashboard">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.add')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    data () {
      return {
        componentKey: 0,
        tableData: [],
        labels: {}
      }
    },
    methods: {
      up (index, row) {
        if (index > 0) {
          this.tableData.splice(index, 1, this.tableData[index - 1])
          this.tableData.splice(index - 1, 1, row)
        }
      },
      down (index, row) {
        if (index < this.tableData.length - 1) {
          this.tableData.splice(index, 1, this.tableData[index + 1])
          this.tableData.splice(index + 1, 1, row)
        }
      },
      updateDashboard () {
        let dashboardList = []
        let sortNumber = 1
        for (const element of this.tableData) {
          if (element.selected) {
            dashboardList.push({screen_code: element.code, sort_number: sortNumber})
            sortNumber += 1
          }
        }
        let params = {
          screen_code: '00-000',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            dashboard_list: dashboardList
          }
        }
        this.send(`/dashboard/update/db_employee/list`, params)
        .then(res => {
          this.$store.commit('setDashboardList', dashboardList)
          this.$emit('close', true)
        })
        this.componentKey += 1
      },
      doClose () {
        this.$emit('close', false)
      }
    },
    created () {
      let params = {
        company_code: this.$store.state.loginUser.companyCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        group_code: this.$store.state.loginUser.groupCode,
        screen_code: '00-000'
      }
      this.send(`/dashboard/init/db_employee`, params)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.labels.entry = 1
        this.tableData = []
        for (const db of this.$store.state.dbList) {
          if (db.screen_code === 'DB-003' || db.screen_code === 'DB-004') {
            var selected = false
            for (const dashboard of this.$store.state.dashboardList) {
              if (dashboard.screen_code === db.screen_code) {
                selected = true
                break
              }
            }
            this.tableData.push({selected: selected, name: db.screen_name, code: db.screen_code})
          }
        }
      })
    }
  }
</script>
<style scoped>
</style>
