import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationTextTable from '@/components/UIComponents/Inputs/ValidationTextTable'
import ValidationTextWorkTime from '@/components/UIComponents/Inputs/ValidationTextWorkTime'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSelectMini from '@/components/UIComponents/Inputs/ValidationSelectMini'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'

export default {
  name: 'ReferencePaySlipDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationTextNumber,
    ValidationTextTable,
    ValidationTextWorkTime,
    ValidationSelect,
    ValidationSelectMini,
    ValidationTextArea,
    AttendanceRecordDetail: () => import('@/components/Dashboard/Views/Working/AttendanceRecordDetail')
  },
  data () {
    return {
      mode: 0,
      params: {},
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        target_date: '',
        term_from: '',
        salary_category: '',
        is_payment_hand_type: '000000000000000000000000000000',
        is_deduction_hand_type: '000000000000000000000000000000'
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      fixed_fluctuation_mapping_list: [{}],
      calcExeFlg: false,
      updateBeforeDetail: {},
      rowMaxLength: 0,
      t_employee_pay_slip_exist: 0,
      attendanceManagement: 1,
      fixedDailyDivisionList: [],
      outsideLayoutItemList: []
    }
  },
  computed: {
    screenCode () {
      return '02-003'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
    isPaymentHandType (index) {
      return this.detail.is_payment_hand_type.substring(index - 1, index) === '1'
    },
    updatePaymentHandType (index, value) {
      if (index === 1) {
        this.detail.is_payment_hand_type = value + this.detail.is_payment_hand_type.substring(1)
      } else if (index === this.detail.is_payment_hand_type.length) {
        this.detail.is_payment_hand_type = this.detail.is_payment_hand_type.substring(0, index - 1) + value
      } else {
        this.detail.is_payment_hand_type = this.detail.is_payment_hand_type.substring(0, index - 1) + value + this.detail.is_payment_hand_type.substring(index)
      }
      this.detail = {...this.detail}
      if (value === '0') {
        this.calc()
      }
    },
    isDeductionHandType (index) {
      return this.detail.is_deduction_hand_type.substring(index - 1, index) === '1'
    },
    updateDeductionHandType (index, value) {
      if (index === 1) {
        this.detail.is_deduction_hand_type = value + this.detail.is_deduction_hand_type.substring(1)
      } else if (index === this.detail.is_deduction_hand_type.length) {
        this.detail.is_deduction_hand_type = this.detail.is_deduction_hand_type.substring(0, index - 1) + value
      } else {
        this.detail.is_deduction_hand_type = this.detail.is_deduction_hand_type.substring(0, index - 1) + value + this.detail.is_deduction_hand_type.substring(index)
      }
      this.detail = {...this.detail}
      if (value === '0') {
        this.calc()
      }
    },
    // 出勤簿表示、非表示
    showAttendanceRecord (detail) {
      const params = {
        orgSearchCond: {},
        headers: {},
        row: {
          term_from: detail.term_from,
          term_to: detail.term_to,
          employee_code: detail.employee_code,
          target_date: detail.target_date
        },
        errors: []
      }
      localStorage.setItem('params', JSON.stringify(params))
      this.$modal.show('attendanceRecord')
    },
    closeAttendanceRecord () {
      this.$modal.hide('attendanceRecord')
    },
      // 勤怠
    workingDataTableBase () {
      return [{dummy: 'dummy'}]
    },
    // 支払額1~10
    payment_salary_item01 () {
      let result = [{}]
      let n = 1
      while (n <= 10) {
        // カンマ区切り設定
        this.formatComma('payment_salary_item_code' + n + '_amount')
        result[0]['payment_salary_item_code' + n] = this.detail['payment_salary_item_code' + n]
        result[0]['payment_salary_item_name' + n] = this.detail['payment_salary_item_name' + n]
        n++
      }
      return result
    },
    // 支払額11~20
    payment_salary_item11 () {
      let result = [{}]
      let n = 11
      while (n <= 20) {
        // カンマ区切り設定
        this.formatComma('payment_salary_item_code' + n + '_amount')
        result[0]['payment_salary_item_code' + n] = this.detail['payment_salary_item_code' + n]
        result[0]['payment_salary_item_name' + n] = this.detail['payment_salary_item_name' + n]
        n++
      }
      return result
    },
    // 支払額21~30
    payment_salary_item21 () {
      let result = [{}]
      let n = 21
      while (n <= 30) {
        // カンマ区切り設定
        this.formatComma('payment_salary_item_code' + n + '_amount')
        result[0]['payment_salary_item_code' + n] = this.detail['payment_salary_item_code' + n]
        result[0]['payment_salary_item_name' + n] = this.detail['payment_salary_item_name' + n]
        n++
      }
      return result
    },
    // 支払額21~30存在有無
    payment_salary_item21_exist () {
      let exist = false
      let n = 21
      while (n <= 30) {
        if (this.detail['payment_salary_item_code' + n]) {
          exist = true
          break
        }
        n++
      }
      return exist
    },
    // 控除額1~10
    deduction_salary_item01 () {
      let result = [{}]
      let n = 1
      while (n <= 10) {
        // カンマ区切り設定
        this.formatComma('deduction_salary_item_code' + n + '_amount')
        result[0]['deduction_salary_item_code' + n] = this.detail['deduction_salary_item_code' + n]
        result[0]['deduction_salary_item_name' + n] = this.detail['deduction_salary_item_name' + n]
        n++
      }
      return result
    },
    // 控除額11~20
    deduction_salary_item11 () {
      let result = [{}]
      let n = 11
      while (n <= 20) {
        // カンマ区切り設定
        this.formatComma('deduction_salary_item_code' + n + '_amount')
        result[0]['deduction_salary_item_code' + n] = this.detail['deduction_salary_item_code' + n]
        result[0]['deduction_salary_item_name' + n] = this.detail['deduction_salary_item_name' + n]
        n++
      }
      return result
    },
    // 控除額21~30
    deduction_salary_item21 () {
      let result = [{}]
      let n = 21
      while (n <= 30) {
        // カンマ区切り設定
        this.formatComma('deduction_salary_item_code' + n + '_amount')
        result[0]['deduction_salary_item_code' + n] = this.detail['deduction_salary_item_code' + n]
        result[0]['deduction_salary_item_name' + n] = this.detail['deduction_salary_item_name' + n]
        n++
      }
      return result
    },
    // 合計1
    payslipTotalTableBase () {
      return [{dummy: 'dummy'}]
    },
    // カンマ付与(detailを書き換えます)
    formatComma (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatNumber(String(this.detail[name]).replaceAll(',', ''))
      }
      return this.detail[name]
    },
    formatTime (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatDispMinute(parseInt(this.detail[name]))
      }
      if (this.detail[name] === 0) {
        this.detail[name] = '0'
      }
      return this.detail[name]
    },
    // サーバ送信用にnullをブランクに変換する
    servNullToBlank (target, name) {
      if (!target[name]) {
        target[name] = ''
      }
      return target[name]
    },
    // サーバ送信用に整数に変換する
    servInt (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    // サーバ送信用に数値（小数あり）に変換する
    servFloat (target, name) {
      if (target[name]) {
        target[name] = parseFloat(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    // サーバ送信用に時刻を10進数に変換する
    servTime (target, name) {
      // 0の場合は0で返却
      if (target[name] === 0 || target[name] === '0') {
        target[name] = 0
        return target[name]
      }
      if (target[name]) {
        const toHour = parseInt(target[name].split(':')[0], 10)
        const toMinute = parseInt(target[name].split(':')[1], 10)
        let ans = toHour * 60 + toMinute
        target[name] = ans
      } else {
        target[name] = null
      }
      return target[name]
    },
    // 固定変動区分が固定項目以外のときにtrueを返す
    // # 1 FIXED 固定項目
    // # 2 FLUCTUATION 変動項目
    // # 3 SYSTEM システム項目
    isNotFixedFluctuation (itemCode) {
      for (const entry of Object.entries(this.fixed_fluctuation_mapping_list)) {
        for (const fixedFluctuation of Object.entries(entry[1])) {
          if (fixedFluctuation[0] === itemCode) {
            return fixedFluctuation[1] !== 1
          }
        }
      }
    },
    showEditIcon (itemCode, canChange) {
      return this.isNotFixedFluctuation(itemCode) && canChange && !this.isReadOnly
    },
    isNotEditable (itemCode, handType) {
      return (this.isNotFixedFluctuation(itemCode) && !handType) || this.fixedDailyDivisionList.includes(itemCode)
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      // 支払額
      // this.payment_salary_item_list = res.common_area.combo_data.salary_category_salary_item_mapping_list.payment_salary_item_list
      // 控除額
      // this.deduction_salary_item_list = res.common_area.combo_data.salary_category_salary_item_mapping_list.deduction_salary_item_list
      // this.fixed_fluctuation_mapping_list =
      // res.common_area.combo_data.salary_category_salary_item_mapping_list.salary_item_code_fixed_fluctuation_mapping_list
      this.fixedDailyDivisionList = res.api_area.fixed_daily_division_list || []
      // レイアウト外の給与項目
      this.outsideLayoutItemList = res.api_area.outside_layout_item_list
      // ラベルの編集
      this.labels.layout_code = this.getLabel(res.common_area.label.Layout)// レイアウト
      // 勤怠項目の編集
      this.formatTime('real_total_minutes') // 労働時間
      this.formatTime('job_total_minutes') // 所定労働時間
      this.formatTime('job_overwork_minutes') // 所定外労働時間
      this.formatTime('legal_job_minutes') // 法定労働時間
      this.formatTime('legal_overwork_minutes') // 法定外労働時間
      this.formatTime('late_night_overwork_minutes') // 深夜労働時間
      this.formatTime('break_minutes') // 休憩時間
      this.formatTime('late_minutes') // 遅刻時間
      this.formatTime('early_departure_minutes') // 早退時間
      this.formatTime('job_holiday_hours') // 所定休日労働時間
      this.formatTime('legal_holiday_overwork_minutes') // 法定休日労働時間
      this.formatTime('legal_within_45_overwork_minutes') // 45時間以内の法定外労働時間
      this.formatTime('legal_45_overwork_minutes') // 45時間を超過した法定外労働時間
      this.formatTime('legal_60_overwork_minutes') // 60時間を超過した法定外労働時間
      this.formatTime('paid_holiday_hours') // 有給時間数
      this.formatTime('lack_minutes') // 集計期間の不足時間(給与控除が必要な時間)
      this.formatTime('standard_job_minutes') // 標準所定労働時間
      this.formatTime('standard_legal_minutes') // 標準法定労働時間
      // 対象額/社会保険額/合計
      this.formatComma('taxable_amount') // 所得税対象額
      this.formatComma('taxable_amount_2') // 課税所得額
      this.formatComma('social_insurance_amount') // 社会保険対象額
      this.formatComma('employement_insurance_amount') // 雇用保険対象額
      this.formatComma('general_health_insurance') // 健康保険 一般
      this.formatComma('health_insurance_adjustment') // 健康保険 調整
      this.formatComma('care_insurance') // 介護保険
      this.formatComma('social_insurance_total') // 社会保険計
      this.formatComma('total_payment_amount') // 支給額合計
      this.formatComma('total_deduction_amount') // 控除額合計
      this.formatComma('deduction_amount') // 差引支給額
      this.formatComma('first_transfer_amount') // 第一振込先 金額
      this.formatComma('second_transfer_amount') // 第二振込先 金額
      this.formatComma('cash_payment_amount') // 現金支給額
      this.formatComma('in_kind_payment_amount') // 現物支給額
      // 第一振込先 金額の設定がない場合0を設定する
      if (this.detail['first_transfer_amount'] === '') {
        this.detail['first_transfer_amount'] = '0'
      }
      let n = 1
      let isPaymentHandType = []
      let isDeductionHandType = []
      while (n <= 30) {
        isPaymentHandType.push(!this.detail['payment_salary_item_code' + n] ? '0' : this.isNotFixedFluctuation(this.detail['payment_salary_item_code' + n]) ? this.detail.is_payment_hand_type.substring(n - 1, n) : this.fixedDailyDivisionList.includes(this.detail['payment_salary_item_code' + n]) ? '0' : '1')
        isDeductionHandType.push(!this.detail['deduction_salary_item_code' + n] ? '0' : this.isNotFixedFluctuation(this.detail['deduction_salary_item_code' + n]) ? this.detail.is_deduction_hand_type.substring(n - 1, n) : '1')
        n++
      }
      this.detail.is_payment_hand_type = isPaymentHandType.join('')
      this.detail.is_deduction_hand_type = isDeductionHandType.join('')
      let detail = null
      detail = {...this.detail}
      this.updateBeforeDetail = this.convertDetail(detail)
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode === this.modes.delete) {
        return detail
      }
      let n = 1
      while (n <= 30) {
        if (detail['payment_salary_item_code' + n + '_amount'] === '') {
          detail['payment_salary_item_code' + n + '_amount'] = null
        } else {
          detail['payment_salary_item_code' + n + '_amount'] = parseInt(detail['payment_salary_item_code' + n + '_amount'].replaceAll(',', ''))
        }
        if (detail['deduction_salary_item_code' + n + '_amount'] === '') {
          detail['deduction_salary_item_code' + n + '_amount'] = null
        } else {
          detail['deduction_salary_item_code' + n + '_amount'] = parseInt(detail['deduction_salary_item_code' + n + '_amount'].replaceAll(',', ''))
        }
        n++
      }
      this.servTime(detail, 'real_total_minutes') // 労働時間
      this.servTime(detail, 'job_total_minutes') // 所定労働時間
      this.servTime(detail, 'job_overwork_minutes') // 所定外労働時間
      this.servTime(detail, 'legal_job_minutes') // 法定労働時間
      this.servTime(detail, 'legal_overwork_minutes') // 法定外労働時間
      this.servTime(detail, 'late_night_overwork_minutes') // 深夜労働時間
      this.servTime(detail, 'break_minutes') // 休憩時間
      this.servTime(detail, 'late_minutes') // 遅刻時間
      this.servTime(detail, 'early_departure_minutes') // 早退時間
      this.servTime(detail, 'job_holiday_hours') // 所定休日労働時間
      this.servTime(detail, 'legal_holiday_overwork_minutes') // 法定休日労働時間
      this.servTime(detail, 'legal_within_45_overwork_minutes') // 45時間以内の法定外労働時間
      this.servTime(detail, 'legal_45_overwork_minutes') // 45時間を超過した法定外労働時間
      this.servTime(detail, 'legal_60_overwork_minutes') // 60時間を超過した法定外労働時間
      this.servTime(detail, 'paid_holiday_hours') // 有給時間数
      this.servInt(detail, 'leave_days') // 休職日数
      this.servInt(detail, 'closed_days') // 休業日数
      this.servTime(detail, 'lack_minutes') // 集計期間の不足時間(給与控除が必要な時間)
      this.servInt(detail, 'estimated_overtime_hours') // 見込み残業時間
      this.servTime(detail, 'standard_job_minutes') // 標準所定労働時間
      this.servTime(detail, 'standard_legal_minutes') // 標準法定労働時間
      this.servInt(detail, 'telework_count') //  所定労働出勤日数
      this.servNullToBlank(detail, 'term_from') //  有効期間(開始)
      this.servNullToBlank(detail, 'salary_category') //  給与区分
      this.servNullToBlank(detail, 'target_date') //  対象年
      this.servInt(detail, 'job_total_days') //  所定労働出勤日数
      this.servInt(detail, 'late_days') //  遅刻回数
      this.servInt(detail, 'early_departure_days') //  早退回数
      this.servInt(detail, 'job_holiday_days') //  所定休日労働日数
      this.servInt(detail, 'legal_holiday_overwork_days') //  法定休日労働日数
      this.servFloat(detail, 'paid_holiday_days') //  有給日数
      this.servInt(detail, 'compensatory_holiday_days') //  代休日数
      this.servInt(detail, 'absent_total_days') //  欠勤日数
      this.servNullToBlank(detail, 'employee_code') //  従業員番号
      this.servNullToBlank(detail, 'employee_name') //  従業員名
      this.servNullToBlank(detail, 'term_to') //  有効期間(終了)
      this.servNullToBlank(detail, 'layout_code') //  レイアウトコード
      this.servInt(detail, 'taxable_amount') //  所得税対象額
      this.servInt(detail, 'taxable_amount_2') //  社会保険料控除後の給与額
      this.servInt(detail, 'employement_insurance_amount') //  雇用保険対象額
      this.servInt(detail, 'social_insurance_amount') //  社会保険対象額
      this.servInt(detail, 'social_insurance_total') //  社会保険計
      this.servInt(detail, 'general_health_insurance') // 健康保険 一般
      this.servInt(detail, 'health_insurance_adjustment') // 健康保険 調整
      this.servInt(detail, 'care_insurance') // 介護保険
      this.servInt(detail, 'total_payment_amount') //  支給額合計
      this.servInt(detail, 'total_deduction_amount') //  控除額合計
      this.servInt(detail, 'deduction_amount') //  差引支給額
      this.servInt(detail, 'first_transfer_amount') //  第一振込先 金額
      this.servInt(detail, 'second_transfer_amount') //  第二振込先 金額
      this.servInt(detail, 'cash_payment_amount') //  現金支給額
      this.servInt(detail, 'in_kind_payment_amount') //  現金支給額
      this.servInt(detail, 'child_time_leave_days') // 育児時間休暇数
      this.servInt(detail, 'child_time_leave_hours') // 育児時間休暇数
      this.nullToBlank('remark') //  備考

      delete detail.salary_category
      delete detail.date_of_sending_pay_slip
      delete detail.is_disp_late_time
      delete detail.is_flex
      delete detail.electoric_money_amount
      delete detail.fixed_daily_division_list
      for (var i = 0; i < 30; i++) {
        delete detail[`deduction_salary_item_code${i + 1}_can_change`]
        delete detail[`payment_salary_item_code${i + 1}_can_change`]
      }
      return detail
    },
    doBack () {
      this.$router.go(-1)
    },
    doAdd () {
      let detail = null
      detail = {...this.detail}
      detail = this.convertDetail(detail)
      delete detail.remark
      delete this.updateBeforeDetail.remark
      if (JSON.stringify(detail) !== JSON.stringify(this.updateBeforeDetail)) {
        swal({
          text: this.$t('message.no_calc'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'error',
          reverseButtons: true
        }).then(() => {
        }).catch(() => {})
        return
      }
      this.submit('insert')
    },
    doUpdate () {
      let detail = null
      detail = {...this.detail}
      detail = this.convertDetail(detail)
      delete detail.remark
      delete this.updateBeforeDetail.remark
      if (JSON.stringify(detail) !== JSON.stringify(this.updateBeforeDetail)) {
        swal({
          text: this.$t('message.no_calc'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'error',
          reverseButtons: true
        }).then(() => {
        }).catch(() => {})
        return
      }
      this.submit('update')
    },
    calc () {
      this.calcExeFlg = true
      this.presubmit('calc')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    presubmit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        this.message.text = ''
        this.isChanged = true
        let detail = null
        let count = parseInt(this.detail.update_count, 10)
        detail = {...this.detail}
        let data = {
          screen_code: '02-003',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        delete data.api_area.update_count
        data.api_area.blackout_days = Number(data.api_area.blackout_days)

        if (data.api_area.child_time_leave_hours) {
          data.api_area.child_time_leave_hours = data.api_area.child_time_leave_hours * 60
        }

        this.send('/calc/pay_slip', data)
        .then(res => {
          if (res.api_area) {
            if (res.api_area.child_time_leave_hours) {
              res.api_area.child_time_leave_hours = res.api_area.child_time_leave_hours / 60
            }
            for (const entry of Object.entries(res.api_area)) {
              if (typeof entry[1] === 'number') {
                this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                this.detail[entry[0]] = entry[1]
              }
            }
          }
          let detail = null
          detail = {...this.detail}
          this.updateBeforeDetail = this.convertDetail(detail)
          this.detail.update_count = count
          this.afterInit(res)
          this.$nextTick(() => {
            if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
              this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
            }
            this.isChanged = false
          })
          this.componentKey += 1
        })
        this.componentKey += 1
      })
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          if (this.t_employee_pay_slip_exist !== 1) {
            detail = {...this.detail}
            delete detail.update_count
            this.params.rows[this.params.index].t_employee_pay_slip_exist = 1
          } else {
            detail = {...this.detail}
            detail.update_count = parseInt(this.detail.update_count, 10)
          }
        } else if (this.mode === this.modes.edit) {
          if (this.t_employee_pay_slip_exist !== 1) {
            detail = {...this.detail}
            delete detail.update_count
            this.params.rows[this.params.index].t_employee_pay_slip_exist = 1
          } else {
            detail = {...this.detail}
            detail.update_count = parseInt(this.detail.update_count, 10)
          }
        } else if (this.mode === this.modes.delete) {
          detail = {employee_code: this.detail.employee_code}
          detail.employee_name = this.detail.employee_name
          detail.target_date = this.detail.target_date
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: '02-003',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        if (this.mode === this.modes.delete) {
          delete reqData.api_area.child_time_leave_hours
          delete reqData.api_area.blackout_days
        } else {
          if (reqData.api_area.child_time_leave_hours) {
            reqData.api_area.child_time_leave_hours = Number(reqData.api_area.child_time_leave_hours) * 60
          } else {
            reqData.api_area.child_time_leave_hours = 0
          }
          reqData.api_area.blackout_days = Number(reqData.api_area.blackout_days)
        }
        this.send(`/${operation}/reference_pay_slip`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      console.log(this.params)
      let rows = this.params.rows
      this.rowMaxLength = rows.length - 1
      let index = this.params.index
      // let employeeCode = rows[index].employee_code
      // let employeeName = rows[index].employee_name
      this.attendanceManagement = rows[index].attendance_management
      this.t_employee_pay_slip_exist = rows[index].t_employee_pay_slip_exist
      let data = {
        screen_code: '02-003',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          // employee_code: employeeCode,
          // employee_name: employeeName,
          target_date: this.params.target_date
        }
      }
      this.send('/init/reference_pay_slip', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          if (res.api_area.telework_count === null) {
            res.api_area.telework_count = 0
          }
          if (res.api_area.child_time_leave_hours === undefined || res.api_area.child_time_leave_hours === null) {
            res.api_area.child_time_leave_hours = 0
          } else {
            res.api_area.child_time_leave_hours = res.api_area.child_time_leave_hours / 60
          }
          if (res.api_area.child_time_leave_days === undefined || res.api_area.child_time_leave_days === null) {
            res.api_area.child_time_leave_days = 0
          }
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    nextData () {
      this.isChanged = false
      this.params.index = this.params.index + 1
      this.calcExeFlg = false
      this.init()
    },
    preData () {
      this.isChanged = false
      this.params.index = this.params.index - 1
      this.calcExeFlg = false
      this.init()
    }
  },
  created () {
    if (this.$route.params.params) {
      this.params = this.$route.params.params
      this.mode = this.$route.params.mode
      localStorage.setItem('payslipParams', JSON.stringify(this.$route.params))
    } else {
      const paramsString = localStorage.getItem('payslipParams')
      const params = JSON.parse(paramsString)
      this.params = params.params
      this.mode = params.mode
    }
    this.init()
  },
  mounted () {
    this.$refs.frame.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
  }
}
