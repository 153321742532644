<template>
  <div class="row">
    <div class="col-md-12">
      <form method="#" action="#">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{$t('label.shift_pattern_setting')}}
            </h4>
          </div>
          <div class="card-content">
            <ValidationObserver ref="observer">
            <div class="row">
              <div class="col-sm-12">
                <ValidationSelect
                  v-model="detail.shift_pattern_code"
                  item-name="shift_pattern"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.shift_pattern')"
                  :options="shiftPatternList"
                  :disabled="false"
                />
              </div>
              <div class="col-sm-12">
                <ValidationSelect
                  v-model="detail.shift_reflection_flg"
                  item-name="shift_reflection_flg"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="$t('label.status')"
                  :options="comboData.shift_reflection_flg_list"
                  :disabled="false"
                />
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <div><label>{{ $t('label.apply_date_range') }}</label></div>
                  <el-select class="select-success length2"
                            size="large"
                            placeholder=""
                            v-model="detail.from_date"
                            @change="fromDateChanged">
                    <el-option v-for="date in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]"
                                class="select-success"
                                :value="date"
                                :label="`${date}${$t('label.unit_days')}`"
                                :key="date">
                    </el-option>
                  </el-select>
                  {{ $t('label.date_from_description') }}
                  <el-select class="select-success length2"
                            size="large"
                            placeholder=""
                            v-model="detail.to_date"
                            @change="toDateChanged">
                    <el-option v-for="date in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 99]"
                                class="select-success"
                                :value="date"
                                :label="date === 99 ? $t('label.end_of_month') : `${date}${$t('label.unit_days')}`"
                                :key="date">
                    </el-option>
                  </el-select>
                  {{ $t('label.date_to_description') }}
                </div>
              </div>
              <div class="col-sm-12" style="margin-top: 20px;">
                <div style="text-align: right;">
                  <button type="button" class="btn btn-sm btn-default btn-primary" @click="apply">{{$t('button.apply_to')}}</button>
                </div>
              </div>
            </div>
            </ValidationObserver>
          </div>  
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    components: {
      ValidationSelect
    },
    props: {
      labels: Object,
      comboData: Object,
      workScheduleList: Array
    },
    data () {
      return {
        detail: {
          shift_pattern_code: '',
          shift_reflection_flg: null,
          from_date: '1',
          to_date: 99,
          changed: false
        },
        shiftPatternList: []
      }
    },
    methods: {
      fromDateChanged () {
        if (this.detail.from_date > this.detail.to_date) {
          this.detail.from_date = this.detail.to_date
        }
      },
      toDateChanged () {
        if (this.detail.from_date > this.detail.to_date) {
          this.detail.to_date = this.detail.from_date
        }
      },
      apply () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }

          this.detail.changed = true
          this.$emit('close', this.detail)
        })
      },
      doClose () {
        this.$emit('close', this.detail)
      }
    },
    created () {
      this.shiftPatternList = this.comboData.shift_pattern_list
      if (!this.detail.shift_reflection_flg) {
        this.detail.shift_reflection_flg = this.comboData.shift_reflection_flg_list[0].value
      }
    }
  }
</script>
<style>
</style>
