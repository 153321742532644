<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottm: 5px; margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="paid_holiday_payment" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{pageTitle}}
          </h4>
        </div>
        <div class="card-content">
          <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
          <ValidationObserver ref="observer">
            <ValidationPaidHolidayPayment
              v-model="detail.paid_holiday_payment"
              :labels="labels"
              rules="isPaidHolidayPaymentRequired|isPaidHolidayPaymentNumeric|isPaidHolidayPaymentRequiredWorkingDays|isPaidHolidayPaymentUnique"
              :disabled="isReadOnly"
            />
          </ValidationObserver>
        </div>
      </div>
      <div style="text-align: left;">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
          <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.update')}}
        </button>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import PaidHolidayPaymentSheetMixin from '@/components/Dashboard/Views/Generated/PaidHolidayPaymentSheetMixin'

  export default {
    name: 'PaidHolidayPaymentSheet',
    methods: {
      afterInit (res) {
        const map = new Map()
        for (const row of res.api_area.paid_holiday_payment_list) {
          let data = map.get(row.months_of_service)
          if (!data) {
            const year = Math.floor(row.months_of_service / 12)
            const month = row.months_of_service % 12
            let yearsOfService = year === 0 ? '' : year + this.$t('label.year')
            yearsOfService += month + this.$t('label.months')
            data = {months_of_service: row.months_of_service, years_of_service: yearsOfService}
          }
          if (row.number_of_working_days_per_week === 5) {
            data.working_days_5 = row.grant_days
          } else if (row.number_of_working_days_per_week === 4) {
            data.working_days_4 = row.grant_days
          } else if (row.number_of_working_days_per_week === 3) {
            data.working_days_3 = row.grant_days
          } else if (row.number_of_working_days_per_week === 2) {
            data.working_days_2 = row.grant_days
          } else if (row.number_of_working_days_per_week === 1) {
            data.working_days_1 = row.grant_days
          }
          map.set(row.months_of_service, data)
        }
        const paidHolidayPayment = []
        for (const entry of map.entries()) {
          paidHolidayPayment.push(entry[1])
        }
        this.detail.paid_holiday_payment = paidHolidayPayment
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          const apiArea = []
          for (const row of detail.paid_holiday_payment) {
            if (row.selected) continue
            if (row.working_days_5) {
              const element = {
                months_of_service: parseInt(row.months_of_service, 10),
                number_of_working_days_per_week: 5,
                grant_days: parseInt(row.working_days_5, 10)
              }
              apiArea.push(element)
            }
            if (row.working_days_4) {
              const element = {
                months_of_service: parseInt(row.months_of_service, 10),
                number_of_working_days_per_week: 4,
                grant_days: parseInt(row.working_days_4, 10)
              }
              apiArea.push(element)
            }
            if (row.working_days_3) {
              const element = {
                months_of_service: parseInt(row.months_of_service, 10),
                number_of_working_days_per_week: 3,
                grant_days: parseInt(row.working_days_3, 10)
              }
              apiArea.push(element)
            }
            if (row.working_days_2) {
              const element = {
                months_of_service: parseInt(row.months_of_service, 10),
                number_of_working_days_per_week: 2,
                grant_days: parseInt(row.working_days_2, 10)
              }
              apiArea.push(element)
            }
            if (row.working_days_1) {
              const element = {
                months_of_service: parseInt(row.months_of_service, 10),
                number_of_working_days_per_week: 1,
                grant_days: parseInt(row.working_days_1, 10)
              }
              apiArea.push(element)
            }
          }
          return {paid_holiday_payment_list: apiArea}
        }
      },
      afterSubmit (res) {
        for (let i = this.detail.paid_holiday_payment.length - 1; i >= 0; i--) {
          if (this.detail.paid_holiday_payment[i].selected) {
            this.detail.paid_holiday_payment.splice(i, 1)
          }
        }
        this.detail.paid_holiday_payment.sort((a, b) => parseInt(a.months_of_service, 10) - parseInt(b.months_of_service, 10))
      }
    },
    mixins: [PaidHolidayPaymentSheetMixin]
  }
</script>
<style scoped>
</style>
