<template>
  <div>
    <div :class="frameClass === '' ? 'form-group' : `form-group ${frameClass}`">
      <label>{{ isHide ? '　' : itemLabel }}</label>
      <span class="required" v-show="!isHide && rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span><a v-show="hasHelp" href="#" @click.prevent="$emit('help-clicked')" style="margin-left: 10px; color: #F00;"><span class="ti-help-alt"></span></a>
      <template v-if="addButtonFlg&&!disabled"><button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button></template>
      <ValidationProvider ref="provider" :debounce="100" v-slot="{ valid, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
          </small>
        </div>
        <div :style="isHide ? 'visibility: hidden;' : ''">
          <vSelect :class="itemClasses"
                      :name="itemName"
                      :disabled="disabled"
                      :clearable="clearable"
                      :options="options"
                      v-model="itemValue"
                      @input="changed">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>{{unit ? '&nbsp;' + unit : ''}}
          <template v-if="hasModal">
            <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" @click="$emit('show' + snakeToPascal(itemName))">{{buttonLabel}}</button>
          </template>
        </div>
        <div style="margin-top: 10px;" v-if="helpText">
          <small class="help-block">
            {{ helpText }}
          </small>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      vSelect
    },
    props: {
      value: [String, Number],
      itemName: String,
      rules: String,
      itemLabel: String,
      options: Array,
      modalName: String,
      disabled: Boolean,
      buttonLabel: String,
      classes: String,
      placeholder: String,
      frameClass: {
        type: String,
        require: false,
        'default': ''
      },
      isHide: {
        type: Boolean,
        require: false,
        'default': false
      },
      hasHelp: {
        type: Boolean,
        require: false,
        'default': false
      },
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      helpText: {
        type: String,
        require: false,
        'default': ''
      },
      addButtonFlg: {
        type: Boolean,
        require: false,
        'default': false
      },
      clearable: {
        type: Boolean,
        require: false,
        'default': true
      }
    },
    methods: {
      snakeToPascal (snake) {
        let answer = ''
        for (const row of snake.split('_')) {
          answer += row.substring(0, 1).toUpperCase() + row.substring(1)
        }
        console.log(answer)
        return answer
      },
      changed () {
        this.$emit('input', this.itemValue.value)
        this.$emit('change')
      },
      showAdd () {
        this.$emit('showAdd')
      }
    },
    data () {
      return {
        params: {},
        itemValue: null,
        itemClasses: 'style-chooser'
      }
    },
    computed: {
      hasModal () {
        return this.modalName != null && this.modalName !== ''
      },
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    watch: {
      value () {
        if (this.options && this.options.length > 0) {
          this.itemValue = ''
          for (const row of this.options) {
            if (row.value === this.value) {
              this.itemValue = row
              break
            }
          }
        }
        this.itemClasses = 'style-chooser ' + this.classes
      }
    },
    mounted () {
      if (this.options && this.options.length > 0) {
        this.itemValue = ''
        for (const row of this.options) {
          if (row.value === this.value) {
            this.itemValue = row
            break
          }
        }
      }
      this.itemClasses = 'style-chooser ' + this.classes
    }
  }
</script>

<style scoped>
</style>
