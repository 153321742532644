<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 330px);" name="holidayFrame" :key="componentKey">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="workScheduleMessage" :placeholders="[pageTitle]"/>
        <div class="card">
          <div class="card-header">
            <div style="color: red;">{{detail.error}}</div>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-md-12">
                <ValidationText
                  v-model="detail.work_schedule_code"
                  item-name="work_schedule_code"
                  max-length="10"
                  classes="form-control length10"
                  rules="required"
                  :item-label="labels.work_schedule_code"
                  :placeholder="$t('placeholder.work_schedule_code')"
                  :disabled="isReadOnly"
                  :help-text="$t('help.work_schedule_code')"
                />
                <ValidationText
                  v-model="detail.work_schedule_name"
                  item-name="work_schedule_name"
                  max-length="30"
                  classes="form-control length60"
                  rules="required"
                  :item-label="labels.work_schedule_name"
                  :placeholder="$t('placeholder.work_schedule_name')"
                  :disabled="isReadOnly"
                  :help-text="$t('label.max_length')"
                  @blur="setRyaku"
                />
                <ValidationText
                  v-model="detail.working_system_abbreviation"
                  item-name="working_system_abbreviation"
                  max-length="4"
                  classes="form-control length8"
                  rules="required"
                  :item-label="labels.working_system_abbreviation"
                  :placeholder="$t('placeholder.working_system_abbreviation')"
                  :disabled="isReadOnly"
                  :help-text="$t('label.max_length')"
                />
                <!-- <ValidationSelect
                  v-model="detail.working_system_type"
                  :item-label="labels.working_system_type"
                  :options="comboData.working_system_type_list"
                  :disabled="isReadOnly"
                  item-name="working_system_type"
                  rules="required"
                  classes="length8"
                  @change="workingSystemTypeChanged"
                />
                <ValidationRadio
                  v-model="detail.is_job_before_start_time"
                  rules="required"
                  :item-label="labels.is_job_before_start_time"
                  :help-text="$t('help.is_job_before_start_time')"
                  :disabled="isReadOnly"
                /> -->
                <ValidationStartEndText
                  v-model="detail.work_time"
                  item-name="work_time"
                  max-length="5"
                  classes="form-control length5 text-right"
                  rules="isStartTime|isEndTime"
                  :item-label="$t('label.work_time')"
                  :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
                  :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
                  :disabled="isReadOnly"
                  :help-text="$t('help.work_time')"
                  v-show="!showFlex"
                />
                <ValidationStartEndText
                  v-model="detail.core_time"
                  item-name="core_time"
                  max-length="5"
                  classes="form-control length5"
                  rules="isFlexStartTime|isFlexEndTime"
                  :item-label="$t('label.core_time')"
                  :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
                  :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
                  :disabled="isReadOnly"
                  :help-text="$t('label.max_length')"
                  v-show="showFlex"
                />
                <ValidationStartEndText
                  v-model="detail.flex_time"
                  item-name="flex_time"
                  max-length="5"
                  classes="form-control length5"
                  rules="isFlexStartTime|isFlexEndTime"
                  :item-label="$t('label.flex_time')"
                  :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
                  :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
                  :disabled="isReadOnly"
                  :help-text="$t('label.max_length')"
                  v-show="showFlex"
                />
                <div class="form-group">
                  <label>{{ $t('label.break_time') }}</label>
                  <br>
                  <small class="help-block">
                    {{ $t('help.break_time') }}
                  </small>
                  <button type="button" class="btn btn-sm btn-default btn-primary" @click="addStartEndTextRow">{{$t('button.add_row')}}</button>
                  <div style="color: #C84513; margin-bottom: 5px;" v-for="error in breakTimeListErrors" v-bind:key="error">
                    <small>{{error}}</small>
                  </div>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    border
                    :data="detail.break_time_list"
                    :empty-text="$t('label.no_data')"
                    style="width: 380px">
                    <el-table-column
                      width="80"
                      :label="$t('label.delete')"
                      align="center">
                      <template slot="header"> 
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">
                            {{ $t('label.delete') }}
                          </div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <button type="button" class="btn btn-sm btn-default btn-primary" @click="deleteRow(props.$index)">{{$t('label.delete')}}</button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      min-width="280"
                      :label="labels.break_time ? labels.break_time : ''"
                      align="center">
                      <template slot-scope="props">
                        <ValidationStartEndText
                          v-model="props.row.break_time"
                          item-name="break_time"
                          max-length="5"
                          classes="form-control length5 text-right"
                          rules="isStartTime|isEndTime"
                          :placeholder1="$t('placeholder.break_start_time')"
                          :placeholder2="$t('placeholder.break_end_time')"
                          :disabled="isReadOnly"
                          />
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <ValidationText
                  v-model="detail.closing_name"
                  item-name="closing_name"
                  max-length="30"
                  classes="form-control length60"
                  rules="required"
                  :item-label="labels.closing_name"
                  :placeholder="isReadOnly ? '' : $t('placeholder.closing_name')"
                  :disabled="isPrimaryKeyReadOnly"
                  :help-text="$t('help.closing_name')"
                />
                <div class="form-group">
                  <label>{{ labels.target_year }}</label>
                  <span>{{detail.target_date + $t('label.year')}}</span>
                </div>
                <div class="form-group">
                  <button type="button" :disabled="isReadOnly" class="btn btn-sm btn-default btn-primary" @click="calcButton2Clicked">{{$t('button.auto_input_with_end_of_month')}}</button>
                  <span>{{$t('label.or')}}</span>
                  <input 
                    type="text" 
                    :placeholder="$t('placeholder.closing')"
                    class="form-control length2"
                    style="display: inline-block;"
                    v-model="detail.closing_date"
                    maxlength="2"
                    :disabled="isReadOnly">
                  <span>{{$t('label.closing_date_unit')}}</span>
                  <button type="button" :disabled="isReadOnly" class="btn btn-sm btn-default btn-primary" @click="calcButtonClicked">{{$t('button.auto_input')}}</button>
                  <span class="required">{{$t('label.required')}}</span>
                </div>
                <ValidationClosingDateTable
                  v-model="detail.closing_date_table"
                  item-name="closing_date_table"
                  classes="form-control"
                  rules="closingDateRequired|closingDateContinuous"
                  :labels="labels"
                  :disabled="isReadOnly"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationStartEndText from '@/components/UIComponents/Inputs/ValidationStartEndText'
  import ValidationClosingDateTable from '@/components/UIComponents/Inputs/ValidationClosingDateTable'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationRadio,
      ValidationStartEndText,
      ValidationClosingDateTable
    },
    computed: {
      isReadOnly () {
        return false
      },
      isPrimaryKeyReadOnly () {
        return false
      }
    },
    data () {
      return {
        componentKey: 0,
        showFlex: false,
        detail: {
          workHourRoundType: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'none',
                label: '丸め処理をしない',
                value: '1'
              },
              {
                id: 'monthly',
                label: '月単位',
                value: '2'
              },
              {
                id: 'daily',
                label: '日単位',
                value: '3'
              }
            ]
          },
          workHourRoundTypeMonthly: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '30分未満は切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '30分未満は切り上げ',
                value: '2'
              }
            ]
          },
          workStartTimeRoundTypeDaily: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          },
          workEndTimeRoundTypeDaily: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          },
          is_job_before_start_time: {
            value: '2',
            disabled: false,
            items: [
              {
                id: 'include',
                label: '含める',
                value: '1'
              },
              {
                id: 'notInclude',
                label: '含めない',
                value: '2'
              }
            ]
          },
          work_time: ['', ''],
          core_time: ['', ''],
          flex_time: ['', ''],
          break_time_list: [],
          closing_date_table: []
        },
        workMinuteRoundTypes: [],
        workSecondRoundType: [],
        firstWeekdays: [],
        labels: {
          work_schedule_code: '勤務体系コード',
          work_schedule_name: '勤務体系名',
          working_system_abbreviation: '勤務体系略名',
          is_job_before_start_time: '始業時間前の労働時間を含む',
          break_time_list: '休憩時間',
          closing_name: '勤怠締日名',
          target_date: '対象年',
          jan: '1月',
          feb: '2月',
          mar: '3月',
          apl: '4月',
          may: '5月',
          jun: '6月',
          jly: '7月',
          aug: '8月',
          sep: '9月',
          oct: '10月',
          nov: '11月',
          dec: '12月'
        },
        breakTimeListErrors: [],
        headerSelected: false,
        comboData: {},
        workScheduleMessage: {}
      }
    },
    methods: {
      workingSystemTypeChanged () {
        if (this.detail.working_system_type === '2') {
          this.detail.work_time = ['09:00', '18:00']
          this.detail.core_time = ['', '']
          this.detail.flex_time = ['', '']
          this.showFlex = true
        } else {
          this.detail.work_time = ['', '']
          this.detail.core_time = ['11:00', '14:00']
          this.detail.flex_time = ['09:00', '18:00']
          this.showFlex = false
        }
      },
      deleteRow (index) {
        this.detail.break_time_list.splice(index, 1)
      },
      setRyaku () {
        if (this.detail.work_schedule_name) {
          if (this.detail.work_schedule_name.length < 5) {
            this.detail.working_system_abbreviation = this.detail.work_schedule_name
          } else {
            this.detail.working_system_abbreviation = this.detail.work_schedule_name.substring(0, 4)
          }
        }
        this.componentKey += 1
      },
      headerClicked () {
        for (const row of this.detail.break_time_list) {
          row.selected = !this.headerSelected
        }
      },
      addStartEndTextRow () {
        let startEndTextRow = {
          selected: false,
          break_time: []
        }
        this.detail.break_time_list.unshift(startEndTextRow)
      },
      deleteStartEndTextRow () {
        const breakTimeList = []
        for (const breakTime of this.detail.break_time_list) {
          if (!breakTime.selected) {
            breakTimeList.push(breakTime)
          }
        }
        this.detail.break_time_list = breakTimeList
      },
      calcButtonClicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.closing_date && this.detail.target_date) {
          const baseDate = new Date(this.detail.target_date, 0, this.detail.closing_date)
          for (const row of this.detail.closing_date_table) {
            row.left_startmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 2, baseDate.getDate() + 1)
            row.left_endmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 1, this.detail.closing_date)
            row.right_startmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 2, baseDate.getDate() + 1)
            row.right_endmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 1, this.detail.closing_date)
          }
        } else if (this.detail.closing_date) {
          console.log('締日が未入力です。')
        } else {
          console.log('対象年が未入力です。')
        }
      },
      calcButton2Clicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.target_date) {
          const baseDate = new Date(this.detail.target_date, 1, 1)
          for (const row of this.detail.closing_date_table) {
            row.left_startmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 1, baseDate.getDate())
            row.left_endmonth = new Date(this.detail.target_date, row.left_month.replace('月', ''), baseDate.getDate() - 1)
            row.right_startmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 1, baseDate.getDate())
            row.right_endmonth = new Date(this.detail.target_date, row.right_month.replace('月', ''), baseDate.getDate() - 1)
          }
        } else {
          console.log('対象年が未入力です。')
        }
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_work_schedule`, request)
        .then(res => {
          this.workScheduleMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          for (const roundingSecond of this.comboData.rounding_second_list) {
            roundingSecond.id = roundingSecond.value
          }
          this.detail.roundingSecond.items = this.comboData.rounding_second_list
          for (const roundingType of this.comboData.rounding_type_list) {
            roundingType.id = roundingType.value
          }
          this.detail.workHourRoundType.items = this.comboData.rounding_type_list
          this.detail.roundingSecond.items = this.comboData.rounding_second_list
          for (const roundingMonth of this.comboData.rounding_month_list) {
            roundingMonth.id = roundingMonth.value
          }
          this.detail.workHourRoundTypeMonthly.items = this.comboData.rounding_month_list
          for (const roundingJobStart of this.comboData.rounding_job_start_list) {
            roundingJobStart.id = roundingJobStart.value
          }
          this.detail.workStartTimeRoundTypeDaily.items = this.comboData.rounding_job_start_list
          for (const roundingJobEnd of this.comboData.rounding_job_end_list) {
            roundingJobEnd.id = roundingJobEnd.value
          }
          this.detail.workEndTimeRoundTypeDaily.items = this.comboData.rounding_job_end_list
          for (const jobBeforeStartTime of this.comboData.job_before_start_time_list) {
            jobBeforeStartTime.id = jobBeforeStartTime.value
          }
          this.detail.is_job_before_start_time.items = this.comboData.job_before_start_time_list
          this.detail.legal_job_minutes = res.api_area.legal_job_minutes
          this.detail.work_schedule_code = 'template'
          this.detail.work_schedule_name = 'テンプレート勤務'
          this.detail.working_system_abbreviation = 'テンプレ'
          this.detail.working_system_type = '1'
          this.detail.work_time = ['9:00', '18:00']
          this.detail.break_time_list = [
            {
              selected: false,
              break_time: ['12:00', '13:00']
            }
          ]
          this.detail.closing_name = '基本'
        })
      }
    },
    created () {
      this.init()
      this.detail.roundingSecond = {
        value: '2',
        disabled: false,
        items: [
          {
            id: 'roundDown',
            label: '労働時間を切り捨て',
            value: '1'
          },
          {
            id: 'roundUp',
            label: '労働時間を切り上げ',
            value: '2'
          }
        ]
      }
      this.detail.workHourRoundType = {
        value: '1',
        disabled: false,
        items: [
          {
            id: 'none',
            label: '丸め処理をしない',
            value: '1'
          },
          {
            id: 'monthly',
            label: '月単位',
            value: '2'
          },
          {
            id: 'daily',
            label: '日単位',
            value: '3'
          }
        ]
      }
      this.detail.workHourRoundTypeMonthly = {
        value: '',
        disabled: false,
        items: [
          {
            id: 'roundDown',
            label: '30分未満は切り捨て',
            value: '1'
          },
          {
            id: 'roundUp',
            label: '30分未満は切り上げ',
            value: '2'
          }
        ]
      }
      this.detail.workStartTimeRoundTypeDaily = {
        value: '',
        disabled: false,
        items: [
          {
            id: 'roundDown',
            label: '労働時間を切り捨て',
            value: '1'
          },
          {
            id: 'roundUp',
            label: '労働時間を切り上げ',
            value: '2'
          }
        ]
      }
      this.detail.workEndTimeRoundTypeDaily = {
        value: '',
        disabled: false,
        items: [
          {
            id: 'roundDown',
            label: '労働時間を切り捨て',
            value: '1'
          },
          {
            id: 'roundUp',
            label: '労働時間を切り上げ',
            value: '2'
          }
        ]
      }
      const res = {api_area: {}}
      this.detail.closing_date_table = [
        {
          left_month: this.labels.jan,
          left_startmonth: res.api_area.term_from_jan ? new Date(res.api_area.term_from_jan) : undefined,
          left_endmonth: res.api_area.term_to_jan ? new Date(res.api_area.term_to_jan) : undefined,
          right_month: this.labels.jly,
          right_startmonth: res.api_area.term_from_jly ? new Date(res.api_area.term_from_jly) : undefined,
          right_endmonth: res.api_area.term_to_jly ? new Date(res.api_area.term_to_jly) : undefined
        },
        {
          left_month: this.labels.feb,
          left_startmonth: res.api_area.term_from_feb ? new Date(res.api_area.term_from_feb) : undefined,
          left_endmonth: res.api_area.term_to_feb ? new Date(res.api_area.term_to_feb) : undefined,
          right_month: this.labels.aug,
          right_startmonth: res.api_area.term_from_aug ? new Date(res.api_area.term_from_aug) : undefined,
          right_endmonth: res.api_area.term_to_aug ? new Date(res.api_area.term_to_aug) : undefined
        },
        {
          left_month: this.labels.mar,
          left_startmonth: res.api_area.term_from_mar ? new Date(res.api_area.term_from_mar) : undefined,
          left_endmonth: res.api_area.term_to_mar ? new Date(res.api_area.term_to_mar) : undefined,
          right_month: this.labels.sep,
          right_startmonth: res.api_area.term_from_sep ? new Date(res.api_area.term_from_sep) : undefined,
          right_endmonth: res.api_area.term_to_sep ? new Date(res.api_area.term_to_sep) : undefined
        },
        {
          left_month: this.labels.apl,
          left_startmonth: res.api_area.term_from_apl ? new Date(res.api_area.term_from_apl) : undefined,
          left_endmonth: res.api_area.term_to_apl ? new Date(res.api_area.term_to_apl) : undefined,
          right_month: this.labels.oct,
          right_startmonth: res.api_area.term_from_oct ? new Date(res.api_area.term_from_oct) : undefined,
          right_endmonth: res.api_area.term_to_oct ? new Date(res.api_area.term_to_oct) : undefined
        },
        {
          left_month: this.labels.may,
          left_startmonth: res.api_area.term_from_may ? new Date(res.api_area.term_from_may) : undefined,
          left_endmonth: res.api_area.term_to_may ? new Date(res.api_area.term_to_may) : undefined,
          right_month: this.labels.nov,
          right_startmonth: res.api_area.term_from_nov ? new Date(res.api_area.term_from_nov) : undefined,
          right_endmonth: res.api_area.term_to_nov ? new Date(res.api_area.term_to_nov) : undefined
        },
        {
          left_month: this.labels.jun,
          left_startmonth: res.api_area.term_from_jun ? new Date(res.api_area.term_from_jun) : undefined,
          left_endmonth: res.api_area.term_to_jun ? new Date(res.api_area.term_to_jun) : undefined,
          right_month: this.labels.dec,
          right_startmonth: res.api_area.term_from_dec ? new Date(res.api_area.term_from_dec) : undefined,
          right_endmonth: res.api_area.term_to_dec ? new Date(res.api_area.term_to_dec) : undefined
        }
      ]
    }
  }
</script>
<style scoped>
</style>
