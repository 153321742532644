<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <div class="form-group" style="display: inline-block">
                <ValidationText
                  v-model="detail.layout_code"
                  item-name="layout_code"
                  max-length="10"
                  classes="form-control length10"
                  rules="required"
                  :item-label="labels.layout_code"
                  :placeholder="isReadOnly ? '' : $t('placeholder.layout_code')"
                  :disabled="isReadOnly || mode==modes.edit"
                  :help-text="$t('label.max_length')"
                  data-v-step="0"
                />
              </div>
              <div class="form-group" style="display: inline-block">
                <ValidationText
                  v-model="detail.layout_name"
                  item-name="layout_name"
                  max-length="255"
                  classes="form-control length186"
                  rules="required"
                  :item-label="labels.layout_name"
                  :placeholder="isReadOnly ? '' : $t('placeholder.layout_name')"
                  :disabled="isReadOnly"
                  :help-text="$t('label.max_length')"
                  data-v-step="1"
                />
              </div>
              <ValidationDate
                v-model="detail.term_from"
                item-name="term_from"
                rules="required"
                :item-label="labels.term_from"
                :placeholder="isPrimaryKeyReadOnly ? '' : $t('placeholder.term_from')"
                :disabled="isPrimaryKeyReadOnly"
                data-v-step="2"
              />
              <ValidationDate
                v-model="detail.term_to"
                item-name="term_to"
                :item-label="labels.term_to"
                :placeholder="isReadOnly ? '' : $t('placeholder.term_to')"
                :disabled="isReadOnly"
                :picker-options="futureday"
                v-show="mode==modes.edit || mode==modes.view || mode==modes.delete"
              />
              <el-row :gutter="20">
                <el-col :span="4">
                  <label>{{ $t('label.salaryItem_detail_payment') }}</label>
                  <div class="picker-sikyu"
                    @dragover="dragoverSikyu"
                    @drop="dropSikyu"
                  >
                    <el-tag v-for="item in sikyuItems" :key="item.id" class="sikyu-tag"
                      :data-id="item.id"
                      :draggable="!laidoutSikyu.includes(item)"
                      @dragstart.native="dragstartSikyu"
                      @dragend.native="dragendSikyu"
                    >{{item.name}}</el-tag>
                  </div>
                </el-col>
                <el-col :span="8">
                  <label>{{ $t('label.salaryItem_detail_deduction') }}</label>
                  <div class="picker-koujo"
                    @dragover="dragoverKoujo"
                    @drop="dropKoujo"
                  >
                    <el-tag v-for="item in koujoItems" :key="item.id" class="koujo-tag"
                      :data-id="item.id"
                      :draggable="!laidoutKoujo.includes(item)"
                      @dragstart.native="dragstartKoujo"
                      @dragend.native="dragendKoujo"
                    >{{item.name}}</el-tag>
                  </div>
                </el-col>
              </el-row>
              <br/>
              <el-row>
                <el-col :span="24">
                  <label data-v-step="4">{{$t('label.layout')}}</label>
                  <div class="layout-table">
                    <div><p><b>{{ $t('label.payment_salary_item_code') }}</b></p></div>
                    <div>
                      <div v-for="(item, i) in laidoutSikyu" :key="'cell-s' + i" class="layout-cell"
                        :data-index="i"
                        @dragover="dragoverSikyu"
                        @drop="dropSikyu"
                        @dragenter="dragenterSikyu"
                        @dragleave="dragleaveSikyu"
                      >
                        <el-tag v-if="!!item" class="sikyu-tag"
                          :data-id="item.id"
                          draggable
                          @dragstart.native="dragstartSikyu"
                          @dragend.native="dragendSikyu"
                          disable-transitions
                        >{{item.name}}</el-tag>
                      </div>
                    </div>
                  </div>

                  <div class="layout-table">
                    <div><p><b>{{ $t('label.deduction_salary_item_code') }}</b></p></div>
                    <div>
                      <div v-for="(item, i) in laidoutKoujo" :key="'cell-k' + i" class="layout-cell"
                        :data-index="i"
                        @dragover="dragoverKoujo"
                        @drop="dropKoujo"
                        @dragenter="dragenterKoujo"
                        @dragleave="dragleaveKoujo"
                      >
                        <el-tag v-if="!!item" class="koujo-tag"
                          :data-id="item.id"
                          draggable
                          @dragstart.native="dragstartKoujo"
                          @dragend.native="dragendKoujo"
                          disable-transitions
                        >{{item.name}}</el-tag>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <!-- <br />
              <label data-v-step="3">{{$t('label.layout')}}</label>
              <table border>
                <tbody>
                  <tr>
                    <th rowspan="2">
                      <b>{{$t('label.payment_salary_item_code')}}</b>
                    </th>
                    <td v-for="n of 10" :key="n" width="150">
                      <template v-if="mode==modes.add ||mode==modes.edit">
                        <ValidationSelectMini
                          v-model="detail['payment_salary_item_code' + n]"
                          classes="length8"
                          unselected="true"
                          :options="getPaymentList(n)"
                          :disabled="isReadOnly"
                          :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                          @change="setPaymentList"
                        />
                      </template>
                      <template
                        v-else
                      >{{getLabel(getSelectName(detail['payment_salary_item_code' + n], comboData.payment_list))}}&nbsp;</template>
                    </td>
                  </tr>
                  <tr>
                    <td v-for="n of 10" :key="n" width="150" class="bottom">
                      <template v-if="mode==modes.add ||mode==modes.edit">
                        <ValidationSelectMini
                          v-model="detail['payment_salary_item_code' + (n + 10)]"
                          classes="length8"
                          unselected="true"
                          :options="getPaymentList(n + 10)"
                          :disabled="isReadOnly"
                          :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                          @change="setPaymentList"
                        />
                      </template>
                      <template
                        v-else
                      >{{getLabel(getSelectName(detail['payment_salary_item_code' + (n + 10)], comboData.payment_list))}}&nbsp;</template>
                    </td>
                  </tr>
                  <tr>
                    <th rowspan="2">
                      <b>{{$t('label.deduction_salary_item_code')}}</b>
                    </th>
                    <td v-for="n of 10" :key="n" width="150">
                      <template v-if="mode==modes.add ||mode==modes.edit">
                        <ValidationSelectMini
                          v-model="detail['deduction_salary_item_code' + n]"
                          classes="length8"
                          unselected="true"
                          :options="getDeductionList(n)"
                          :disabled="isReadOnly"
                          :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                          @change="setDeductionList"
                        />
                      </template>
                      <template
                        v-else
                      >{{getLabel(getSelectName(detail['deduction_salary_item_code' + n], comboData.deduction_list))}}&nbsp;</template>
                    </td>
                  </tr>
                  <tr>
                    <td v-for="n of 10" :key="n" width="150">
                      <template v-if="mode==modes.add ||mode==modes.edit">
                        <ValidationSelectMini
                          v-model="detail['deduction_salary_item_code' + (n + 10)]"
                          classes="length8"
                          unselected="true"
                          :options="getDeductionList(n + 10)"
                          :disabled="isReadOnly"
                          :placeholder="mode==modes.add ||mode==modes.edit ?  $t('label.select_description') : '' "
                          @change="setDeductionList"
                        />
                      </template>
                      <template
                        v-else
                      >{{getLabel(getSelectName(detail['deduction_salary_item_code' + (n + 10)], comboData.deduction_list))}}&nbsp;</template>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <br />
              <Tour tour-name="myTour" :steps="steps"/>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="doAdd">{{$t('button.add')}}</button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-info"
            @click="doUpdate"
          >{{$t('button.update')}}</button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-info"
            @click="doDelete"
          >{{$t('button.delete')}}</button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import BonusLayoutDetailMixin from '@/components/Dashboard/Views/Master/BonusLayoutDetailMixin'

  export default {
    name: 'BonusLayoutDetail',
    mixins: [BonusLayoutDetailMixin]
  }
</script>
<style scoped>
table {
   border-collapse: collapse;
   border: 1px solid;
}
td {
   margin:4px 8px;
   padding:8px 2px;
   border: none;
}
td.bottom {
   border-bottom: 1px solid;
}
th {
  padding: 4px;
  border: 1px solid;
  writing-mode: tb-rl; /* 縦書き */
  text-align: center;
}
</style>
<style>
/* 支給項目枠と控除項目枠のデザイン */
.picker-sikyu,
.picker-koujo {
  align-content: flex-start;
  border: 1px silver solid;
  display: flex;
  gap: 6px 9px;
  height: 20rem;  
  padding: 15px 12px 0;
  position: relative;
}
.picker-sikyu {
  flex-flow: row wrap;
  overflow-y: auto;
}
.picker-koujo {
  flex-flow: column wrap;
  overflow-x: auto;
}

/* レイアウト枠のデザイン */
.layout-table {
  display: grid !important;
  grid-template-columns: 2.5rem 1fr !important;
  user-select: none !important;
}
.layout-table > div:first-child {
  border: 1px solid silver !important;
  display: grid !important;
  justify-content: center !important;
  text-align: center !important;
}
.layout-table > div:first-child > p {
  display: inline-block !important;
  letter-spacing: 0.4rem !important;
  margin: 0 !important;
  writing-mode: vertical-rl !important;
}
.layout-table > div:last-child {
  display: grid !important;
  grid-template-columns: repeat(10, 1fr) !important;
}
.layout-table > div:last-child > div {
  background-color: whitesmoke !important;
  border: solid silver !important;
  border-width: 1px 1px 1px 0 !important;
  display: grid !important;
  min-height: 6rem !important;
  min-width: 10rem !important;
  place-items: center !important;
}
.layout-table > div:last-child > div:nth-child(n+11) {
  border-width: 0 1px 1px 0 !important;
}

/* 支給項目と控除項目のデザイン */
.el-tag.sikyu-tag,
.el-tag.koujo-tag {
  border-color: white !important;
  border-radius: 16px !important;
  color: dimgray !important;
  user-select: none !important;
  width: 14rem !important;
}
.el-tag.sikyu-tag {
  background-color: #f6daba !important;
}
.el-tag.koujo-tag {
  background-color: #ccd3e7 !important;
}

/* ドラッグ操作のデザイン */
.el-tag.sikyu-tag[draggable=false],
.el-tag.koujo-tag[draggable=false] {
  background-color: white !important;
  color: silver !important;
}
.el-tag.sikyu-tag[draggable=true],
.el-tag.koujo-tag[draggable=true] {
  cursor: grab !important;
}
.el-tag.sikyu-tag.dragging,
.el-tag.koujo-tag.dragging {
  opacity: 0.6 !important;
}

.layout-cell.dropshadow::before {
  background-color: silver !important;
  border-radius: 16px !important;
  content: '' !important;
  height: 32px !important;
  width: 14rem !important;
}
.layout-cell:not(.dropshadow)::before {
  content: none !important;
}
</style>
