<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <!-- 仕入先名 -->
            <ValidationText
              v-model="detail.supplier_name"
              item-name="supplier_name"
              max-length="50"
              classes="form-control length50"
              rules="required"
              :item-label="$t('label.supplier_name')"
              :placeholder="isReadOnly ? '' : $t('placeholder.company_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 仕入先名(カナ) -->
            <ValidationText
              v-model="detail.supplier_japanese_name"
              item-name="supplier_japanese_name"
              max-length="50"
              classes="form-control length50"
              rules="isKatakana"
              :item-label="labels.supplier_japanese_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.company_japanese_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 仕入先名(英字) -->
            <ValidationText
              v-model="detail.supplier_english_name"
              item-name="supplier_english_name"
              max-length="50"
              classes="form-control length50"
              rules="isHalfWidthAlphanumeric"
              :item-label="labels.supplier_english_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.company_english_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 仕入先名(略名) -->
            <ValidationText
              v-model="detail.supplier_name_abbreviation"
              item-name="supplier_name_abbreviation"
              max-length="4"
              classes="form-control length4"
              rules="required"
              :item-label="labels.supplier_name_abbreviation"
              :placeholder="isReadOnly ? '' : $t('placeholder.company_name_abbreviation')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 取引開始日 -->
            <ValidationDate
              v-model="detail.transaction_start_date"
              item-name="transaction_start_date"
              :item-label="labels.transaction_start_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.start_date')"
              rules="required"
              :disabled="isReadOnly"
            />
            <!-- 取引終了日 -->
            <ValidationDate
              v-model="detail.transaction_end_date"
              item-name="transaction_end_date"
              :item-label="labels.transaction_end_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.end_date')"
              :disabled="isReadOnly"
              :picker-options="futureday"
            />
            <!-- 郵便番号 -->
            <ValidationPostalCode
              v-model="detail.post_code"
              item-name="post_code"
              max-length="10"
              classes="form-control length10"
              :item-label="labels.post_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.post_code')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :view-button="mode!==modes.view&&mode!==modes.delete"
              :button-label="$t('button.postal_code_search_simple')"
              @search="postalCodeSearch"
            />
            <!-- 都道府県 -->
            <ValidationSelectSimple
              v-model="detail.state_code"
              item-name="state_code"
              :placeholder="isReadOnly ? '' : $t('label.select_description')"
              classes="length6"
              :item-label="labels.state_code_ryaku"
              :options="comboData.prefectures_list"
              :disabled="isReadOnly"
              @change="stateCodeChanged"
            />
            <!-- 市/区 -->
            <ValidationSelectSimple
              v-model="detail.city"
              item-name="municipality_code"
              :placeholder="isReadOnly ? '' : $t('label.select_description')"
              classes="length50"
              :item-label="labels.municipality_code_ryaku"
              :options="comboData.city_list"
              :disabled="isReadOnly"
              @change="municipalityCodeChanged"
            />
            <!-- 町/村 -->
            <ValidationText
              v-model="detail.town"
              item-name="town"
              max-length="50"
              classes="form-control length50"
              :item-label="labels.town"
              :placeholder="isReadOnly ? '' : $t('placeholder.town')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- ビル/番地 -->
            <ValidationText
              v-model="detail.building"
              item-name="building"
              max-length="30"
              classes="form-control length30"
              :item-label="labels.building"
              :placeholder="isReadOnly ? '' : $t('placeholder.building')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 電話番号 -->
            <ValidationText
              v-model="detail.tel"
              item-name="tel"
              max-length="20"
              classes="form-control length20"
              :item-label="labels.tel"
              :placeholder="isReadOnly ? '' : $t('placeholder.tel')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- ファックス番号 -->
            <ValidationText
              v-model="detail.fax"
              item-name="fax"
              max-length="20"
              classes="form-control length20"
              :item-label="labels.fax"
              :placeholder="isReadOnly ? '' : $t('placeholder.fax')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <!-- 敬称 -->
            <ValidationSelectSimple
              v-model="detail.honorific"
              item-name="honorific"
              :placeholder="$t('label.select_description')"
              classes="length0"
              :item-label="labels.honorific"
              :options="comboData.honorific_list"
              :disabled="isReadOnly"
              @change="honorificChanged"
            />
            <!-- 従業員番号[担当] -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <label>{{labels.responsible_employee_code}}</label>
              <div>
                <input type="text"
                  class="form-control width130"
                  style="display:inline-block;"
                  :placeholder="labels.PH_CODE"
                  :disabled="isReadOnly"
                  v-model="detail.responsible_employee_code"
                  @blur="employeeSelected">
              </div>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <label>{{labels.employee_name}}</label>
              <div>
                <div style="display: inline-block; transform: translate(0px,1px);">
                  <input type="text"
                    class="form-control width300"
                    style="display:inline-block;"
                    :disabled="true"
                    v-model="detail.responsible_employee_name">
                </div>
                <button type="button"
                  class="btn btn-sm btn-default btn-warning"
                  style="transform: translate(0px,4px);"
                  :disabled="isReadOnly"
                  @click="showEmployeeSearchModal"
                  v-show="canShow('/SS/menuSSScreen018')">
                  <i class="ti-search"></i>
                </button>
              </div>
            </div>
            <!-- 補足説明 -->
            <ValidationTextArea
              v-model="detail.contents"
              item-name="contents"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.contents"
              placeholder=""
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :rows="10"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import SupplierDetailMixin from '@/components/Dashboard/Views/MasterProject/SupplierDetailMixin'

  export default {
    name: 'SupplierDetail',
    mixins: [SupplierDetailMixin]
  }
</script>
<style scoped>
</style>
