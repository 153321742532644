<template>
  <div>
    <div style="border-bottom: 1px solid #cfcfca; padding-bottom: 5px; margin-bottom: 10px;">
      <div style="display: inline-block; width: 49%;">
        <template v-for="nodashboard in nodashboardList">
          <button v-bind:key="nodashboard.screen_code" type="button" class="btn btn-sm btn-default btn-primary" style="margin-bottom: 5px;" @click="doAdd(nodashboard.screen_code, nodashboard.screen_name)">
            {{getLabel(nodashboard.screen_name)}}
          </button>
          &nbsp;
        </template>
      </div>
      <!-- <a :href="$t('movie.dashboard')" target="_blank" style="position: relative; z-index: 3;"><img src="/static/img/video_icon.png"/></a> -->
      <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
        <Help class="pull-right" :movie-key="'dashboard'" :help="'https://linkpoint.zendesk.com/hc/ja/articles/4409403581849-ダッシュボード画面の使い方'" :placeholders="[pageTitle]"/>
      </div>
    </div>
    
    <Dashboard :id="'dashExample'" ref="dash" :key="componentKey">
      <DashLayout v-for="layout in dlayouts" v-bind="layout" :key="layout.breakpoint">
        <DashItem v-for="dashboard in layout.items" v-bind="dashboard" :key="dashboard.id" :resizable="false" @moveEnd="moveEnd">
          <template v-if="dashboard.screen_code === 'DB-003'">
            <div class="card element-frame">
              <div class="card-header">
                <i class="ti-close pull-right dashboard-header-item" :title="$t('button.close')" @click="close('DB-003')"></i>
              </div>
              <div class="card-content dashboard-content-frame">
                <Stamp @stamped="stamped" />
              </div>
            </div>
          </template>
          <template v-else-if="dashboard.screen_code === 'DB-004'">
            <div class="card element-frame">
              <div class="card-header">
                <i class="ti-close pull-right dashboard-header-item" :title="$t('button.close')" @click="close('DB-004')"></i>
              </div>
              <div class="card-content dashboard-content-frame">
                <LunchOrder ref="lunchOrder" />
              </div>
            </div>
          </template>
          <template v-else-if="dashboard.screen_code === 'DB-008'">
            <div class="card element-frame">
              <div class="card-header">
                <i class="ti-close pull-right dashboard-header-item" :title="$t('button.close')" @click="close('DB-008')"></i>
              </div>
              <div class="card-content dashboard-content-frame">
                <PersonalCalendar ref="personalCalendar" />
              </div>
            </div>
          </template>
          <template v-else-if="dashboard.screen_code === 'DB-015'">
            <div class="card element-frame">
              <div class="card-header">
                <i class="ti-close pull-right dashboard-header-item" :title="$t('button.close')" @click="close('DB-015')"></i>
              </div>
              <div class="card-content">
                <AnalogClock ref="analogClock" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="element-frame"></div>
          </template>
        </DashItem>
      </DashLayout>
    </Dashboard>
    <Snackbar ref="snackbar" baseSize="100px" :wrapClass="''" :colors="colors" :holdTime="3000" :multiple="true" position="bottom-center" />
  </div>
</template>
<script>
  import { Dashboard, DashLayout, DashItem } from 'vue-responsive-dash'
  import Snackbar from 'vuejs-snackbar'
  import Stamp from 'src/components/Dashboard/Views/Dashboard/Stamp2.vue'
  import LunchOrder from 'src/components/Dashboard/Views/Dashboard/LunchOrder2.vue'
  import PersonalCalendar from 'src/components/Dashboard/Views/Dashboard/PersonalCalendar.vue'
  import AnalogClock from 'src/components/Dashboard/Views/Dashboard/DashboardAnalogClock.vue'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Dashboard,
      DashLayout,
      DashItem,
      Snackbar,
      Stamp,
      LunchOrder,
      PersonalCalendar,
      AnalogClock
    },
    data () {
      return {
        componentKey: 0,
        colors: {open: '#333', info: '#BC3053', error: '#FA7377', warn: '#FF6600'},
        nodashboardList: [],
        dlayouts: [
          {
            breakpoint: 'xl',
            breakpointWidth: 2140,
            numberOfCols: 12,
            items: []
          },
          {
            breakpoint: 'lg',
            breakpointWidth: 1500,
            numberOfCols: 10,
            items: []
          },
          {
            breakpoint: 'md',
            breakpointWidth: 946,
            numberOfCols: 6,
            items: []
          },
          {
            breakpoint: 'sm',
            breakpointWidth: 0,
            numberOfCols: 4,
            items: []
          }
        ]
      }
    },
    methods: {
      stamped (text) {
        this.$refs.snackbar.info(text)
      },
      moveEnd () {
        this.updateDashboard()
      },
      doAdd (screenCode, screenName) {
        for (const layout of this.$refs.dash.$children) {
          if (layout.$children.length === 0) continue
          let minSortNumber = 999
          let maxSortNumber = 0
          let maxWidth = 0
          for (const element of layout.$children) {
            if (element.item._id !== '-1Placeholder') {
              const sortNumber = element.item.y * 12 + element.item._x + 1
              if (sortNumber < minSortNumber) {
                minSortNumber = sortNumber
              }
              if (sortNumber > maxSortNumber) {
                maxSortNumber = sortNumber
                switch (element.item._id.split('_')[0]) {
                  case 'DB-003':
                    maxWidth = 6
                    break
                  case 'DB-004':
                    maxWidth = 4
                    break
                  case 'DB-008':
                    maxWidth = 10
                    break
                  case 'DB-015':
                    maxWidth = 2
                    break
                }
              }
            }
          }
          let thisSortNumber = 0
          let thisWidth = 0
          switch (screenCode) {
            case 'DB-003':
              thisWidth = 6
              break
            case 'DB-004':
              thisWidth = 4
              break
            case 'DB-008':
              thisWidth = 10
              break
            case 'DB-015':
              thisWidth = 2
              break
          }
          if (minSortNumber > thisWidth) {
            thisSortNumber = 1
          } else {
            thisSortNumber = maxSortNumber + maxWidth
          }
          for (const dlayout of this.dlayouts) {
            const row = this.getRow({screen_code: screenCode, screen_name: screenName, sort_number: thisSortNumber, number_of_cols: dlayout.numberOfCols})
            dlayout.items.push(row)
          }
          break
        }
        this.$nextTick(() => {
          this.updateDashboard()
        })
      },
      close (screenCode) {
        for (const layout of this.dlayouts) {
          let list = []
          for (const dashboard of layout.items) {
            if (dashboard.screen_code !== screenCode) {
              list.push(dashboard)
            }
          }
          layout.items = list
        }
        this.$nextTick(() => {
          this.updateDashboard()
        })
      },
      handleResize (e) {
        this.gridClass = window.innerWidth < 1366 ? 'col-sm-12' : window.innerWidth < 1920 ? 'col-sm-6' : window.innerWidth < 2560 ? 'col-sm-4' : 'col-sm-3'
      },
      updateDashboard () {
        const dashboardList = []
        const sortNumbers = []
        for (const layout of this.$refs.dash.$children) {
          if (layout.$children.length === 0) continue
          for (const element of layout.$children) {
            if (element.item._id !== '-1Placeholder') {
              const screenCode = element.item._id.split('_')[0]
              const orgSortNumber = element.item.y * layout.l._numberOfCols + element.item._x + 1
              const sortNumber = sortNumbers.includes(orgSortNumber) ? orgSortNumber + 1 : orgSortNumber
              dashboardList.push({screen_code: screenCode, sort_number: sortNumber})
              sortNumbers.push(sortNumber)
            }
          }
          break
        }
        const params = {
          screen_code: '00-000',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            dashboard_list: dashboardList
          }
        }
        const nodashboardList = []
        const newDashboardList = [...this.$store.state.dashboardList]
        for (const dashboard of newDashboardList) {
          let isExist = false
          for (const employeeDashboard of dashboardList) {
            if (dashboard.screen_code === employeeDashboard.screen_code) {
              isExist = true
              dashboard.selected = true
              dashboard.sort_number = employeeDashboard.sort_number
              break
            }
          }
          if (!isExist) {
            dashboard.selected = false
            dashboard.sort_number = 0
            if (dashboard.screen_code === 'DB-003' || dashboard.screen_code === 'DB-004' || dashboard.screen_code === 'DB-008' || dashboard.screen_code === 'DB-015') {
              nodashboardList.push(dashboard)
            }
          }
        }
        this.nodashboardList = nodashboardList
        this.$store.commit('setDashboardList', newDashboardList)
        this.send(`/dashboard/update/db_employee/list`, params)
      },
      getRow (row) {
        row.id = row.screen_code + '_' + new Date().getTime().toString(16)
        row.x = (row.sort_number - 1) % row.number_of_cols
        row.y = parseInt((row.sort_number - 1) / row.number_of_cols, 10)
        if (row.screen_code === 'DB-003') {
          row.width = 6
          row.height = 2
        } else if (row.screen_code === 'DB-004') {
          row.width = 4
          row.height = 4
        } else if (row.screen_code === 'DB-008') {
          row.width = 10
          row.height = 5
        } else if (row.screen_code === 'DB-015') {
          row.width = 2
          row.height = 2
        }
        // 右にはみ出る場合は次の段に表示する
        if (row.x + row.width > row.number_of_cols) {
          row.x = 0
          row.y += 1
        }
        return row
      }
    },
    created () {
      const params = {
        screen_code: '00-000',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/view/menu/list', params)
      .then(res => {
        const sidebarLinks = this.getSidebarLinks(this, 2,
          res.api_area.menu_list, res.api_area.employee_dashboard_list, this.$store.state.dbList)
        const loginInfo = this.$store.state.loginUser
        loginInfo.sidebarLinks = sidebarLinks
        this.$store.commit('save', loginInfo)
      })
    },
    mounted () {
      window.addEventListener('resize', this.handleResize)
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$nextTick(() => {
                for (const layout of this.dlayouts) {
                  const dashboardList = []
                  const nodashboardList = []
                  for (const row of this.$store.state.dashboardList) {
                    if (row.sort_number > 0) {
                      const thisRow = {...row, number_of_cols: layout.numberOfCols}
                      dashboardList.push(this.getRow(thisRow))
                    } else {
                      if (row.screen_code === 'DB-003' || row.screen_code === 'DB-004' || row.screen_code === 'DB-008' || row.screen_code === 'DB-015') {
                        nodashboardList.push(row)
                      }
                    }
                  }
                  layout.items = dashboardList
                  this.nodashboardList = nodashboardList
                }
              })
            })
          })
        })
      })
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.handleResize)
    }
}
</script>
<style scoped>
  .element-frame {
    width: 100%;
    height: 100%;
  }
  .dashboard-header-item {
    cursor: pointer; 
    padding: 0 10px;
  }
  .dashboard-content-frame {
    clear: both; 
    height: 100%;
  }
</style>
