<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <div>
        <div style="display: inline-block; width: 49%;">
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{pageTitle}}
          </h4>
        </div>
        <div class="card-content">
          <el-select
            class="select-success length2"
            v-model="pagination.perPage"
            placeholder="Per page">
            <el-option
              class="select-success length2"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div class="row" :style="'margin-top: 10px; ' + tableStyle">
            <div class="col-sm-6">
              <template v-if="roles.delete === 1">
                <button type="button" class="btn btn-sm btn-default btn-info" @click="multiDelete">{{$t('button.delete')}}</button>
              </template>
            </div>
            <div class="col-sm-6" style="padding-top: 15px;">
              <i class="ti-reload pull-right" :title="$t('button.reload')" @click="init" style="cursor: pointer;">
                <span class="dashboard-footer-item">&nbsp;{{$t('label.updated_now')}}</span>
              </i>
            </div>
          </div>
          <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      border
                      :data="queriedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="50">
                <template slot="header"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <input type="checkbox" id="allSelect" @click="selectAll" class="gray">
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-top: 10px; margin-bottom: 12px; text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray">
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :width="160"
                :key="componentKey"
                :label="$t('label.operation')">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      {{scope.column.label}}
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <template v-if="roles.download === 1">
                      <button type="button" v-if="props.row.report_status===2" class="btn btn-sm btn-default btn-warning" @click="download(props.row)">{{$t('button.download')}}</button>
                    </template>
                    <template v-if="roles.print === 1">
                      <button type="button" v-if="props.row.report_status===1 && false" class="btn btn-sm btn-default btn-warning" @click="createReport(props.row)">{{$t('button.reprint')}}</button>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.file_name_ryaku, 'file_name')" :label="labels.userReport_ryaku ? labels.userReport_ryaku : ''">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text-left">
                    {{getLabel(getSelectName(props.row.report_code, comboData.report_code_list))}}
                  </div>
                  <div style="text-align: left;">
                    {{props.row.file_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidthStrong(comboData.report_status_list, labels.report_status_ryaku, 'label')" :label="labels.report_status_ryaku">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{getLabel(getSelectName(props.row.report_status, comboData.report_status_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.file_size_ryaku + $t('label.unit_byte'), 'file_size')" :label="labels.file_size_ryaku">
                <template slot="header"  slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.file_size ? formatNumber(props.row.file_size) + $t('label.unit_byte') : ''}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidthStrong(tableData, labels.file_create_date_ryaku, 'file_create_date')" :label="labels.file_create_date_ryaku">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.file_create_date}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        labels: {},
        roles: {},
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: [],
        comboData: {},
        tableData: [],
        allSelect: false,
        tableWrapperStyle: '',
        tableStyle: '',
        processing: false,
        timer: null
      }
    },
    methods: {
      tableRowClassName ({row}) {
        if (row.outdate_flag || row.is_future) {
          return 'out-row'
        }
        return ''
      },
      selectAll () {
        const checked = document.getElementById('allSelect').checked
        for (const data of this.tableData) {
          data.selected = checked
        }
      },
      handleDelete (index, row) {
        swal({
          text: this.$t('message.confirm_to_delete'),
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          const requestData = {
            screen_code: '08-007',
            company_code: this.$store.state.loginUser.companyCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            group_code: this.$store.state.loginUser.groupCode,
            api_area: { delete_print_list: [{'id': row.id, 'file_name': row.file_name}] }
          }
          this.send('/delete/report/list', requestData)
          .then(res => {
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].file_name === row.file_name) {
                this.tableData.splice(i, 1)
                break
              }
            }
          })
        }).catch(error => {
          console.error(error)
        })
      },
      multiDelete () {
        const deleteList = []
        for (const row of this.tableData) {
          if (row.selected) {
            deleteList.push({'report_id': row.id})
          }
        }
        if (deleteList.length === 0) {
          swal({
            text: '削除する帳票を選択してください。',
            type: 'info',
            showCancelButton: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            confirmButtonText: 'OK',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        swal({
          text: this.$t('message.confirm_to_delete'),
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          const requestData = {
            screen_code: '08-007',
            company_code: this.$store.state.loginUser.companyCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            group_code: this.$store.state.loginUser.groupCode,
            api_area: { delete_print_list: deleteList }
          }
          this.send('/delete/report/list', requestData)
          .then(res => {
            for (let i = this.tableData.length - 1; i >= 0; i--) {
              if (this.tableData[i].selected) {
                this.tableData.splice(i, 1)
              }
            }
            document.getElementById('allSelect').checked = false
          })
        }).catch(error => {
          console.error(error)
        })
      },
      init () {
        if (!this.processing) {
          this.processing = true
          const searchCond = {
            screen_code: '08-007',
            company_code: this.$store.state.loginUser.companyCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            group_code: this.$store.state.loginUser.groupCode
          }
          this.send('/init/report/list', searchCond)
          .then(res => {
            this.labels = this.getLabels(res.common_area)
            this.comboData = this.getComboData(res.common_area.combo_data)
            this.roles = res.common_area.button_role
            this.createList(res.api_area)
            this.processing = false
          }).catch(() => {
            this.processing = false
          })
        }
      },
      createList (list) {
        const tableData = []
        for (let row of list) {
          let selected = false
          for (let tableRow of this.tableData) {
            if (tableRow.id === row.report_id) {
              selected = tableRow.selected
              break
            }
          }
          let data = {
            selected: selected,
            id: row.report_id,
            report_code: row.report_code,
            file_name: row.file_name,
            file_size: row.file_size,
            file_create_date: row.file_create_date,
            report_status: row.report_status,
            url: row.file_url
          }
          // this.tableData.unshift(data)
          tableData.push(data)
        }
        this.tableData = tableData
        this.$nextTick(() => {
          let width = 0
          if (this.$refs.table) {
            for (const child of this.$refs.table.$children) {
              if (child.width) {
                width += parseInt(child.width, 10)
              }
            }
            this.tableStyle = 'width: ' + (width + 1) + 'px'
            const maxWidth = window.innerWidth - 360
            if (width > maxWidth) {
              width = maxWidth
              this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
            } else {
              this.tableWrapperStyle = ''
            }
            this.$refs.pager.style.width = width + 'px'
            this.componentKey += 1
          }
        })
      },
      download (row) {
        let self = this
        let path = row.url.substring(5)
        path = path.substring(path.indexOf('/') + 1)
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(path)
        httpsReference.getDownloadURL().then(function (url) {
          self.$axios.get(url, {
            'responseType': 'blob'
          }).then(res => {
            let blob = new Blob([res.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = row.file_name
            link.click()
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      createReport (row) {
        const params = {
          screen_code: '08-007',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            report_id: row.id
          }
        }
        this.send('/print/report', params)
      }
    },
    created () {
      this.tableData = []
      this.init()
      window.addEventListener('message', (event) => {
        this.init()
      })
    },
    mounted () {
      this.timer = setInterval(this.init, 60000)
    },
    destroyed () {
      clearInterval(this.timer)
    }
  }
</script>
<style>
</style>
