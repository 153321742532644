<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-content">
            <div style="margin-bottom: 20px;">
              {{ $t('label.payment_notation') }}
            </div>
            <ValidationText
              type="text"
              v-model="campaignCode"
              classes="form-control length6"
              :disabled="isChanged"
              :item-label="$t('label.campaign_code')"
              :placeholder="$t('placeholder.campaign_code')"
              item-name="campaign_code"
              :help-text="$t('label.max_length')"
              max-length="6"
            />
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="goPaymentPage">
              {{$t('button.go_payment_page')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText
    },
    data () {
      return {
        labels: {},
        campaignCode: ''
      }
    },
    methods: {
      goPaymentPage () {
        let paymentInfo = {
          licenseCount: '10',
          email: localStorage.getItem('migrationMailAddress'),
          firstCompanyCreateDate: sessionStorage.getItem('firstCompanyCreateDate'),
          campaignCode: this.campaignCode
        }
        localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo))
        const w = 900
        const h = 600
        const wTop = window.screenTop + (window.innerHeight / 2) - (h / 2)
        const wLeft = window.screenLeft + (window.innerWidth / 2) - (w / 2)
        window.open('/#/migration/waiting', 'checkout', `width=${w},height=${h},top=${wTop},left=${wLeft}`)
      }
    },
    created () {
      const request = {
        mail_address: localStorage.getItem('migrationMailAddress'),
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        // this.goPaymentPage()
      })
    }
  }

</script>
<style scoped>
</style>
