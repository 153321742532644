<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <modal name="office" :draggable="false" :clickToClose="false" height="auto" width="800" >
      <div class="modal-body">
        <OfficeDetail :mode="modes.edit" :params="officeParams" @close="hideOffice"/>
      </div>
    </modal>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-header search-condition-title" @click="toggleDetailItems">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.open_or_close_detail_items')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showDetailItems">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <div v-show="showDetailItems">
                <!-- 事業所 -->
                <ValidationSelect
                  v-model="detail.office_code"
                  item-name="office_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="labels.office"
                  :options="comboData.office_list"
                  :disabled="mode!=modes.add || showResult"
                />
                <!-- 届出日 -->
                <ValidationDate
                  v-model="detail.notification_date"
                  item-name="notification_date"
                  rules="required"
                  :item-label="labels.notification_date"
                  :placeholder="isPrimaryKeyReadOnly ? '' : $t('placeholder.term_from')"
                  :disabled="isReadOnly"
                  :picker-options="thisYear"
                />
                <template v-if="mode==modes.add && !showResult">
                  <br>
                  <!-- 上記の内容で反映 -->
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="initDetail">{{$t('button.reflect_above_content')}}</button>
                  <br><br>
                </template>
              </div>
              <template v-if="showResult">
                <div v-show="showDetailItems">
                  <label>{{labels.office_reference_number}}</label><span class="required">{{$t('label.required')}}</span>
                  <!-- 事業所整理番号 -->
                  <template v-if="!isReadOnly">
                    <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                      <span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.office_master_setting')}}
                    </button>
                  </template>
                  <template v-if="errorsMap.office_reference_number!=='' && detail.office_reference_number===''">
                    <br>
                    <small class="text-danger">
                      &nbsp;{{ errorsMap.office_reference_number }}
                    </small>
                  </template>
                  <input type="text"
                    class="form-control length6"
                    disabled=false
                    maxlength="5"
                    v-model="detail.office_reference_number"
                  >
                  <br>
                  <!-- 事業主名 -->
                  <ValidationText
                    v-model="detail.owner_name"
                    item-name="owner_name"
                    max-length="30"
                    classes="form-control length30"
                    rules="required"
                    :item-label="labels.owner_name"
                    :placeholder="isReadOnly ? '' : $t('placeholder.employee_name')"
                    :disabled="isReadOnly"
                    :help-text="$t('label.max_length')"
                  />
                  <!-- 社労士名 -->
                  <ValidationText
                    v-model="detail.company_worker_name"
                    item-name="company_worker_name"
                    max-length="30"
                    classes="form-control length30"
                    :item-label="labels.company_worker_name"
                    :placeholder="isReadOnly ? '' : $t('placeholder.employee_name')"
                    :disabled="isReadOnly"
                    :help-text="$t('label.max_length')"
                  />
                </div>
                <template v-if="errorsMap.table!==''">
                  <div class="text-danger">
                    {{$t('message.check_error')}}<br>
                    {{ errorsMap.table }}
                  </div>
                </template>
                <div>
                  <!-- 項目名のテーブル -->
                  <table border style="background-color: sandybrown;">
                    <!-- 0段目 幅調整用 -->
                    <tr style="border-top:none; border-right:none; border-left:none;">
                      <td width="40"></td><!-- 項目名 -->
                      <td width="40"></td><!-- 削除 -->
                      <td width="40"></td><!-- 登録対象 -->
                      <td width="35"></td><!-- 健 -->
                      <td width="30"></td><!-- 給与支払月の後ろ -->
                      <td width="115"></td><!-- 従業員区分タイプ -->
                      <td width="90"></td><!-- 給与計算の基礎日数 -->
                      <td width="30"></td><!-- 厚 -->
                      <td width="160"></td><!-- 通貨によるものの額の後ろ -->
                      <td width="220"></td><!-- 現物によるものの額 -->
                      <td width="90"></td><!-- 昇(降)給の前 -->
                      <td width="90"></td><!-- 昇(降)給の後ろ -->
                      <td width="95"></td><!-- 生年月日の後ろ -->
                      <td width="145"></td><!-- 改定年月 -->
                      <td width="290"></td><!-- 個人番号 -->
                    </tr>
                    <!-- 1段目 -->
                    <tr>
                      <td rowspan="5" style="background-color: saddlebrown; color: white;">
                        <div style="text-align: center;">
                          項<br>
                          目<br>
                          名<br>
                        </div>
                      </td>
                      <td rowspan="5" style="background-color: grey; color: white;">
                        <div style="text-align: center;">
                          削<br>
                          除<br>
                        </div>
                      </td>
                      <td rowspan="5">
                        <div style="text-align: center;">
                          登<br>
                          録<br>
                          対<br>
                          象<br>
                        </div>
                      </td>
                      <td colspan="4">
                        <div style="text-align: center;">
                          ①&nbsp;被保険者整理番号
                        </div>
                      </td>
                      <td colspan="3">
                        <div style="text-align: center;">
                          ②&nbsp;被保険者氏名
                        </div>
                      </td>
                      <td colspan="3">
                        <div style="text-align: center;">
                          ③&nbsp;生年月日
                        </div>
                      </td>
                      <td>
                        <div style="text-align: center;">
                          ④&nbsp;適用年月
                        </div>
                      </td>
                      <td>
                        <div style="text-align: center;">
                          ⑰&nbsp;個人番号[基礎年金番号]<br>
                          ※70歳以上被用者の場合のみ
                        </div>
                      </td>
                    </tr>
                    <!-- 2段目 -->
                    <tr>
                      <td colspan="6">
                        <div style="text-align: center;">
                          ⑤&nbsp;従前の標準報酬月額
                        </div>
                      </td>
                      <td>
                        <div style="text-align: center;">
                          ⑥&nbsp;従前改定月
                        </div>
                      </td>
                      <td colspan="2">
                        <div style="text-align: center;">
                          ⑦&nbsp;昇(降)給
                        </div>
                      </td>
                      <!-- 協定の有効期間 -->
                      <td colspan="2">
                        <div style="text-align: center;">
                          ⑧&nbsp;遡及支払額
                        </div>
                      </td>
                      <td rowspan="4">
                        <div style="text-align: center;">
                          ⑱&nbsp;備考
                        </div>
                      </td>
                    </tr>
                    <!-- 3段目 -->
                    <tr>
                      <td colspan="3" rowspan="3">
                        <div style="text-align: left;">
                          ⑨<br>
                        </div>
                        <div style="text-align: center;">
                          給与<br>
                          支給月
                        </div>
                      </td>
                      <td rowspan="3">
                        <div style="text-align: left;">
                          ⑩<br>
                        </div>
                        <div style="text-align: center;">
                          給与計算の<br>
                          基礎日数
                        </div>
                      </td>
                      <td colspan="5">
                        <div style="text-align: center;">
                          報酬月額
                        </div>
                      </td>
                      <td colspan="2">
                        <div style="text-align: center;">
                          ⑭&nbsp;総計
                        </div>
                      </td>
                    </tr>
                    <!-- 4段目 -->
                    <tr>
                      <td colspan="2" rowspan="2">
                        <div style="text-align: center;">
                          ⑪&nbsp;通貨によるものの額
                        </div>
                      </td>
                      <td rowspan="2">
                        <div style="text-align: center;">
                          ⑫&nbsp;現物によるものの額
                        </div>
                      </td>
                      <td colspan="2" rowspan="2">
                        <div style="text-align: center;">
                          ⑬&nbsp;合計(⑪+⑫)
                        </div>
                      </td>
                      <td colspan="2">
                        <div style="text-align: center;">
                          ⑮&nbsp;平均額
                        </div>
                      </td>
                    </tr>
                    <!-- 5段目 -->
                    <tr>
                      <td colspan="2">
                        <div style="text-align: center;">
                          ⑯&nbsp;修正平均額
                        </div>
                      </td>
                    </tr>
                  </table>

                  <!-- 1のテーブル -->
                  <div style="max-height:500px; overflow-y:scroll;">
                  <table border  v-for="(norenore, index) of detail.detail_list" :key="index" :class="{'nonChange-row':detail.detail_list[index].is_non_change, 'pink-row':mode!=modes.add && detail.detail_list[index].id==='' && !detail.detail_list[index].is_regist}">
                    <!-- 0段目 幅調整用 -->
                    <tr style="border-top:none; border-right:none; border-left:none;">
                      <td width="40"></td><!-- 項目名 -->
                      <td width="40"></td><!-- 削除 -->
                      <td width="40"></td><!-- 登録対象 -->
                      <td width="35"></td><!-- 健 -->
                      <td width="30"></td><!-- 給与支払月の後ろ -->
                      <td width="115"></td><!-- 従業員区分タイプ -->
                      <td width="90"></td><!-- 給与計算の基礎日数 -->
                      <td width="30"></td><!-- 厚 -->
                      <td width="160"></td><!-- 通貨によるものの額の後ろ -->
                      <td width="220"></td><!-- 現物によるものの額 -->
                      <td width="90"></td><!-- 昇(降)給の前 -->
                      <td width="90"></td><!-- 昇(降)給の後ろ -->
                      <td width="95"></td><!-- 生年月日の後ろ -->
                      <td width="145"></td><!-- 改定年月 -->
                      <td width="290"></td><!-- 個人番号 -->
                    </tr>
                    <!-- 1段目 番号、項目名、必須 -->
                    <tr>
                      <td rowspan="10" style="background-color: saddlebrown; color: white;">
                        <div style="text-align: center;">
                          {{ index + 1 }}
                        </div>
                      </td>
                      <td rowspan="10" style="background-color: gainsboro; color: white;">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="detail.detail_list[index].is_non_change" class="gray" :disabled="isReadOnly" @focus="isNonChangeChanged(index)" title="削除"/>
                        </div>
                      </td>
                      <td rowspan="10">
                        <div style="text-align: center;" :key="isRegistKey">
                          <input type="checkbox" v-model="detail.detail_list[index].is_regist" class="gray" :disabled="isReadOnly || detail.detail_list[index].is_non_change"  @focus="isRegistChanged(index)" title="登録対象">
                        </div>
                      </td>
                      <td colspan="4" style="border-bottom: none;">
                        <small>①
                          <span class="required">{{$t('label.required')}}</span>
                        </small>
                      </td>
                      <td colspan="3" style="border-bottom: none;">
                        <small>②</small>
                      </td>
                      <td colspan="3" style="border-bottom: none;">
                        <small>③</small>
                      </td>
                      <td style="border-bottom: none;">
                        <small>④</small>
                      </td>
                      <td style="border-bottom: none;">
                        <small>⑰</small>
                      </td>
                    </tr>
                    <!-- 2段目 ①②③④⑰ -->
                    <tr>
                      <!-- ①被保険者整理番号 -->
                      <td colspan="4" style="border-top: none;">
                        <template v-if="errorsList[index].insured_person_reference_number!=='' && detail.detail_list[index].insured_person_reference_number===''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].insured_person_reference_number }}
                          </small>
                        </template>
                        <input type="text"
                          class="form-control numLength10"
                          :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                          maxlength="10"
                          oninput="value = value.replace(/[^0-9]/g, '')"
                          :placeholder="isReadOnly ? '' : '123'"
                          v-model="detail.detail_list[index].insured_person_reference_number"
                          @blur="detailChanged(index)"
                        >
                      </td>
                      <!-- ②被保険者氏名 -->
                      <td colspan="3" style="border-top: none;">
                        {{detail.detail_list[index].insured_person_name}}
                      </td>
                      <!-- ③生年月日 -->
                      <td colspan="3" style="border-top: none;">
                        {{convertBirthday(detail.detail_list[index].birthday)}}
                      </td>
                      <!-- ④適用年月 -->
                      <td style="border-top: none;">
                        <div style="text-align: right;">
                          {{seirekiToWareki(detail.detail_list[index].modification_date, 'narrow')}}
                        </div>
                      </td>
                      <!-- ⑰個人番号[基礎年金番号] -->
                      <td style="border-top: none;">
                        <div v-show="detail.detail_list[index].decision_reason==1">
                          {{detail.detail_list[index].basic_pension_number}}
                        </div>
                      </td>
                    </tr>
                    <!-- 3段目 番号、項目名、必須 -->
                    <tr>
                      <td style="border-bottom: none; border-right: none;">
                        <small>⑤</small>
                      </td>
                      <td colspan="3" style="border-bottom: none; border-left: none;">
                        <small><span class="required">{{$t('label.required')}}</span></small>
                      </td>
                      <td style="border-left: dashed 1px; border-bottom: none; border-right: none;"></td>
                      <td style="border-bottom: none; border-left: none;">
                        <small><span class="required">{{$t('label.required')}}</span></small>
                      </td>
                      <td style="border-bottom: none;">
                        <small>⑥
                          <span class="required">{{$t('label.required')}}</span>
                        </small>
                        <span style="float: right;">
                          {{detail.detail_list[index].change_before_modification_date_wareki}}&nbsp;&nbsp;
                        </span>
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                        <small>⑦昇(降)給
                        </small>
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                        <small>⑧遡及支払額</small>
                      </td>
                      <td style="border-bottom: none;">
                        <small>⑱
                        </small>
                      </td>
                    </tr>
                    <!-- 4段目 ⑤⑥⑦⑧⑱ -->
                    <tr>
                      <!-- ⑤健 -->
                      <td style="border-top: none; border-right: none;">
                        <div style="text-align: center;">
                          健
                        </div>
                      </td>
                      <!-- 従前の健康保険標準報酬月額 -->
                      <td colspan="3" style="border-top: none; border-left: none;">
                        <template v-if="errorsList[index].change_before_health_insurance_standard_monthly_salary!=='' && detail.detail_list[index].change_before_health_insurance_standard_monthly_salary===''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].change_before_health_insurance_standard_monthly_salary }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].change_before_health_insurance_standard_monthly_salary"
                            integral="10"
                            classes="form-control numLength10"
                            :placeholder="isReadOnly ? '' : '100'"
                            unit="千円"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="detailChanged(index)"
                          />
                        </div>
                      </td>
                      <!-- 厚 -->
                      <td style="border-left: dashed 1px; border-top: none; border-right: none;">
                        <div style="text-align: center;">
                          厚
                        </div>
                      </td>
                      <!-- 従前の厚生年金標準報酬月額 -->
                      <td style="border-top: none; border-left: none;">
                        <template v-if="errorsList[index].change_before_welfare_pension_standard_monthly_salary!=='' && detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary===''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].change_before_welfare_pension_standard_monthly_salary }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary"
                            integral="10"
                            classes="form-control numLength10"
                            :placeholder="isReadOnly ? '' : '100'"
                            unit="千円"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="detailChanged(index)"
                          />
                        </div>
                      </td>
                      <!-- ⑥従前改定年月 -->
                      <td style="border-top: none;">
                        <template v-if="errorsList[index].change_before_modification_date_year!=='' && (detail.detail_list[index].change_before_modification_date_year==='' || detail.detail_list[index].change_before_modification_date_month==='')">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].change_before_modification_date_year }}
                          </small>
                        </template>
                        <div :key="changeBeforeModificationDateYearKey">
                          <el-select class="select-success widthYear"
                                      :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                      size="large"
                                      placeholder=""
                                      :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                       @change="changeBeforeModificationDateYearChanged(index)"
                                      v-model="detail.detail_list[index].change_before_modification_date_year">
                            <el-option v-for="year in years"
                                        class="select-success"
                                        :value="year"
                                        :label="year"
                                        :key="year">
                            </el-option>
                          </el-select>&nbsp;{{$t('label.year')}}&nbsp;
                          <el-select class="select-success widthMonth"
                                      :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                      size="large"
                                      placeholder=""
                                      :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                       @change="changeBeforeModificationDateMonthChanged(index)"
                                      v-model="detail.detail_list[index].change_before_modification_date_month">
                            <el-option v-for="month in months"
                                        class="select-success"
                                        :value="month"
                                        :label="month"
                                        :key="month">
                            </el-option>
                          </el-select>&nbsp;{{$t('label.month')}}&nbsp;
                        </div>
                      </td>
                      <!-- 昇(降)給月 -->
                      <td colspan="2" style="border-top: none; border-right: none;">
                        <template v-if="errorsList[index].salary_change_month!=='' && ((detail.detail_list[index].salary_change_month!=='' && detail.detail_list[index].salary_change==='') || (detail.detail_list[index].salary_change_month==='' && detail.detail_list[index].salary_change!==''))">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].salary_change_month }}
                          </small>
                        </template>
                        <div :key="salaryChangeMonthKey">
                          <el-select class="select-success widthMonth"
                                      :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                      size="large"
                                      placeholder=""
                                      :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                       @change="salaryChangeMonthChanged(index)"
                                      v-model="detail.detail_list[index].salary_change_month">
                            <el-option v-for="month in monthsBlank"
                                        class="select-success"
                                        :value="month"
                                        :label="month"
                                        :key="month">
                            </el-option>
                          </el-select>&nbsp;{{$t('label.month')}}&nbsp;
                          <!-- 昇(降)給 -->
                          <el-select class="select-success length2" :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                      placeholder="" :disabled="isReadOnly || detail.detail_list[index].is_non_change" @change="salaryChangeChanged(index)"
                            v-model="detail.detail_list[index].salary_change">
                            <el-option v-for="salary_change in comboData.salary_change_list" class="select-success"
                              :value="salary_change.value" :label="salary_change.label" :key="salary_change.value">
                            </el-option>
                          </el-select>
                        </div>
                      </td>
                      <!-- 遡及支払月 -->
                      <td colspan="2" style="border-top: none;">
                        <template v-if="errorsList[index].retroactive_payment_month!=='' && ((detail.detail_list[index].retroactive_payment_month!=='' && detail.detail_list[index].retroactive_payment_amount==='') || (detail.detail_list[index].retroactive_payment_month==='' && detail.detail_list[index].retroactive_payment_amount!==''))">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].retroactive_payment_month }}
                          </small>
                        </template>
                        <div :key="retroactivePaymentMonthKey">
                          <el-select class="select-success widthMonth"
                                      :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                      size="large"
                                      placeholder=""
                                      :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                       @change="retroactivePaymentMonthChanged(index)"
                                      v-model="detail.detail_list[index].retroactive_payment_month">
                            <el-option v-for="month in monthsBlank"
                                        class="select-success"
                                        :value="month"
                                        :label="month"
                                        :key="month">
                            </el-option>
                          </el-select>&nbsp;{{$t('label.month')}}&nbsp;
                          <!-- 遡及支払額 -->
                          <ValidationTextNumber
                              v-model="detail.detail_list[index].retroactive_payment_amount"
                              style="display: inline-block;"
                              integral="10"
                              classes="form-control numLength9"
                              rules="min_value:0"
                              :placeholder="isReadOnly ? '' : '10,000'"
                              :unit="$t('label.currency')"
                              :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                              @blur="retroactivePaymentAmountChanged(index)"
                            />
                        </div>
                      </td>
                      <!-- 備考 -->
                      <td rowspan="7" style="border-top: none; vertical-align: top;">
                        <!-- <template v-if="errorsList[index].decision_reason!=='' && detail.detail_list[index].decision_reason===''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].decision_reason }}
                          </small>
                        </template> -->
                        <div :key="decisionReasonKey">
                          <el-select class="select-success length20" :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                            placeholder="" :disabled="isReadOnly || detail.detail_list[index].is_non_change" @change="decisionReasonChanged(index)" v-model="detail.detail_list[index].decision_reason">
                            <el-option v-for="decision_reason in comboData.decision_reason_list" class="select-success"
                              :value="decision_reason.value" :label="decision_reason.label" :key="decision_reason.value">
                            </el-option>
                          </el-select>
                        </div>
                        <br>
                        <!-- 70歳以上被用者算定 -->
                        <div v-show="detail.detail_list[index].decision_reason==1">
                          <div :key="remarksReason12Key">
                            算定基礎月：
                            <el-select class="select-success widthMonth"
                                        :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                        size="large"
                                        placeholder=""
                                        :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                        @change="remarksReason12Changed"
                                        v-model="detail.detail_list[index].remarks_reason1">
                              <el-option v-for="month in monthsBlank"
                                          class="select-success"
                                          :value="month"
                                          :label="month"
                                          :key="month">
                              </el-option>
                            </el-select>&nbsp;{{$t('label.month')}}&nbsp;
                            <el-select class="select-success widthMonth"
                                        :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                                        size="large"
                                        placeholder=""
                                        :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                                        @change="remarksReason12Changed(index)"
                                        v-model="detail.detail_list[index].remarks_reason2">
                              <el-option v-for="month in monthsBlank"
                                          class="select-success"
                                          :value="month"
                                          :label="month"
                                          :key="month">
                              </el-option>
                            </el-select>&nbsp;{{$t('label.month')}}&nbsp;
                          </div>
                          <br>
                        </div>
                        <!-- その他 -->
                        <div>
                          その他
                          <input type="text" class="form-control length20" :disabled="isReadOnly || detail.detail_list[index].is_non_change" maxlength="255"
                            :placeholder="isReadOnly ? '' : '6月 一般'" v-model="detail.detail_list[index].remarks_others" @blur="detailChanged(index)">
                        </div>
                      </td>
                    </tr>
                    <!-- 5段目 番号、項目名、必須 -->
                    <tr>
                      <td colspan="2" style="border-top: solid 3px; border-left: solid 3px; border-bottom: none; border-right: none;">
                        <small>⑨支給月</small>
                      </td>
                      <td style="border-top: solid 3px; border-bottom: none; border-left: none; text-align: center;">
                        <template v-if="detail.detail_list[index].is_target1">
                          <small>計算対象</small>
                        </template>
                        <template v-if="!detail.detail_list[index].is_target1">
                          <small>計算対象外</small>
                        </template>
                      </td>
                      <td style="border-top: solid 3px; border-bottom: none;">
                        <small>⑩日数</small>
                      </td>
                      <td colspan="2" style="border-top: solid 3px; border-bottom: none;">
                        <small>⑪通貨</small>
                      </td>
                      <td style="border-top: solid 3px; border-bottom: none;">
                        <small>⑫現物</small>
                      </td>
                      <td colspan="2" style="border-top: solid 3px; border-bottom: none;">
                        <small>⑬合計(⑪+⑫)</small>
                      </td>
                      <td colspan="2" style="border-top: solid 3px; border-right: solid 3px; border-bottom: none;">
                        <small>⑭総計</small>
                      </td>
                    </tr>
                    <!-- 6段目 ⑨⑩⑪⑫⑬の1段目⑭-->
                    <tr>
                      <!-- 支給月1 -->
                      <td colspan="3" style="border-left: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].employee_classification_type1!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].employee_classification_type1 }}
                          </small>
                        </template>
                        <div :key="employeeClassificationType1Key" style="text-align: right;">
                          &nbsp;{{formatDispMonth(detail.detail_list[index].payroll_month1, $t('label.month'))}}&nbsp;
                          <el-select class="select-success length7" :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                            placeholder="" :disabled="isReadOnly || detail.detail_list[index].is_non_change" @change="employeeClassificationType1Changed(index)" v-model="detail.detail_list[index].employee_classification_type1">
                            <el-option v-for="employee_classification_type1 in comboData.employee_classification_type_list" class="select-success"
                              :value="employee_classification_type1.value" :label="employee_classification_type1.label" :key="employee_classification_type1.value">
                            </el-option>
                          </el-select>
                        </div>
                      </td>
                      <!-- 日数1 -->
                      <td style="border-top: none;">
                        <template v-if="errorsList[index].job_total_days1!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].job_total_days1 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].job_total_days1"
                            integral="2"
                            classes="form-control numLength2"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '20'"
                            :unit="$t('label.unit_day')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '1')"
                          />
                        </div>
                      </td>
                      <!-- 通貨1 -->
                      <td colspan="2" style="border-top: none;">
                        <template v-if="errorsList[index].cash_payment_amount1!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].cash_payment_amount1 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].cash_payment_amount1"
                            integral="10"
                            classes="form-control numLength10"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '10,000'"
                            :unit="$t('label.currency')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '1')"
                          />
                        </div>
                      </td>
                      <!-- 現物1 -->
                      <td style="border-top: none;">
                        <template v-if="errorsList[index].in_kind_payment_amount1!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].in_kind_payment_amount1 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                              v-model="detail.detail_list[index].in_kind_payment_amount1"
                              integral="10"
                              classes="form-control numLength10"
                              rules="min_value:0"
                              :placeholder="isReadOnly ? '' : '10,000'"
                              :unit="$t('label.currency')"
                              :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                              @blur="changeAmount(index, '1')"
                            />
                        </div>
                      </td>
                      <!-- 合計1 -->
                      <td colspan="2" style="border-top: none;">
                        <div style="text-align: right;" :key="changeAmountKey">
                          {{detail.detail_list[index].total_payment_amount1}}{{$t('label.currency')}}
                        </div>
                      </td>
                      <!-- 総計 -->
                      <td colspan="2" style="border-right: solid 3px; border-top: none;">
                        <div style="text-align: right;" :key="changeAmountKey">
                          {{detail.detail_list[index].total_payment_amount_summary}}{{$t('label.currency')}}
                        </div>
                      </td>
                    </tr>
                    <!-- 7段目 番号、項目名、必須 -->
                    <tr>
                      <td colspan="2" style="border-left: solid 3px; border-bottom: none; border-right: none;">
                      </td>
                      <td style="border-bottom: none; border-left: none; text-align: center;">
                        <template v-if="detail.detail_list[index].is_target2">
                          <small>計算対象</small>
                        </template>
                        <template v-if="!detail.detail_list[index].is_target2">
                          <small>計算対象外</small>
                        </template>
                      </td>
                      <td style="border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                      </td>
                      <td style="border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-right: solid 3px; border-bottom: none;">
                        <small>⑮平均額</small>
                      </td>
                    </tr>
                    <!-- 8段目 -->
                    <tr>
                      <!-- 支給月2 -->
                      <td colspan="3" style="border-left: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].employee_classification_type2!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].employee_classification_type2 }}
                          </small>
                        </template>
                        <div :key="employeeClassificationType2Key" style="text-align: right;">
                          &nbsp;{{formatDispMonth(detail.detail_list[index].payroll_month2, $t('label.month'))}}&nbsp;
                          <el-select class="select-success length7" :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                            placeholder="" :disabled="isReadOnly || detail.detail_list[index].is_non_change" @change="employeeClassificationType2Changed(index)" v-model="detail.detail_list[index].employee_classification_type2">
                            <el-option v-for="employee_classification_type2 in comboData.employee_classification_type_list" class="select-success"
                              :value="employee_classification_type2.value" :label="employee_classification_type2.label" :key="employee_classification_type2.value">
                            </el-option>
                          </el-select>
                        </div>
                      </td>
                      <!-- 日数2 -->
                      <td style="border-top: none;">
                        <template v-if="errorsList[index].job_total_days2!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].job_total_days2 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].job_total_days2"
                            integral="2"
                            classes="form-control numLength2"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '20'"
                            :unit="$t('label.unit_day')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '2')"
                          />

                        </div>
                      </td>
                      <!-- 通貨2 -->
                      <td colspan="2" style="border-top: none;">
                        <template v-if="errorsList[index].cash_payment_amount2!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].cash_payment_amount2 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].cash_payment_amount2"
                            integral="10"
                            classes="form-control numLength10"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '10,000'"
                            :unit="$t('label.currency')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '2')"
                          />
                        </div>
                      </td>
                      <!-- 現物2 -->
                      <td style="border-top: none;">
                        <template v-if="errorsList[index].in_kind_payment_amount2!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].in_kind_payment_amount2 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                              v-model="detail.detail_list[index].in_kind_payment_amount2"
                              integral="10"
                              classes="form-control numLength10"
                              rules="min_value:0"
                              :placeholder="isReadOnly ? '' : '10,000'"
                              :unit="$t('label.currency')"
                              :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                              @blur="changeAmount(index, '2')"
                            />
                        </div>
                      </td>
                      <!-- 合計2 -->
                      <td colspan="2" style="border-top: none;">
                        <div style="text-align: right;" :key="changeAmountKey">
                          {{detail.detail_list[index].total_payment_amount2}}{{$t('label.currency')}}
                        </div>
                      </td>
                      <!-- 平均額 -->
                      <td colspan="2" style="border-right: solid 3px; border-bottom: solid 3px; border-top: none;">
                        <div style="text-align: right;" :key="changeAmountKey">
                          {{detail.detail_list[index].total_payment_amount_average}}{{$t('label.currency')}}
                        </div>
                      </td>
                    </tr>
                    <!-- 9段目 番号、項目名、必須 -->
                    <tr>
                      <td colspan="2" style="border-left: solid 3px; border-bottom: none; border-right: none;">
                      </td>
                      <td style="border-bottom: none; border-left: none; text-align: center;">
                        <template v-if="detail.detail_list[index].is_target3">
                          <small>計算対象</small>
                        </template>
                        <template v-if="!detail.detail_list[index].is_target3">
                          <small>計算対象外</small>
                        </template>
                      </td>
                      <td style="border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                      </td>
                      <td style="border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-right: solid 3px; border-bottom: none;">
                      </td>
                      <td colspan="2" style="border-bottom: none;">
                        <small>⑯修正平均額</small>
                      </td>
                    </tr>
                    <!-- 10段目 -->
                    <tr>
                      <!-- 支給月3 -->
                      <td colspan="3" style="border-left: solid 3px; border-bottom: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].employee_classification_type3!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].employee_classification_type3 }}
                          </small>
                        </template>
                        <div :key="employeeClassificationType3Key" style="text-align: right;">
                          &nbsp;{{formatDispMonth(detail.detail_list[index].payroll_month3, $t('label.month'))}}&nbsp;
                          <el-select class="select-success length7" :class="{'white-box':!detail.detail_list[index].is_non_change, 'gray-box':detail.detail_list[index].is_non_change}"
                            placeholder="" :disabled="isReadOnly || detail.detail_list[index].is_non_change" @change="employeeClassificationType3Changed(index)" v-model="detail.detail_list[index].employee_classification_type3">
                            <el-option v-for="employee_classification_type3 in comboData.employee_classification_type_list" class="select-success"
                              :value="employee_classification_type3.value" :label="employee_classification_type3.label" :key="employee_classification_type3.value">
                            </el-option>
                          </el-select>
                        </div>
                      </td>
                      <!-- 日数3 -->
                      <td style="border-bottom: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].job_total_days3!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].job_total_days3 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].job_total_days3"
                            integral="2"
                            classes="form-control numLength2"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '20'"
                            :unit="$t('label.unit_day')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '3')"
                          />
                        </div>
                      </td>
                      <!-- 通貨3 -->
                      <td colspan="2" style="border-bottom: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].cash_payment_amount3!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].cash_payment_amount3 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].cash_payment_amount3"
                            integral="10"
                            classes="form-control numLength10"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '10,000'"
                            :unit="$t('label.currency')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="changeAmount(index, '3')"
                          />
                        </div>
                      </td>
                      <!-- 現物3 -->
                      <td style="border-bottom: solid 3px; border-top: none;">
                        <template v-if="errorsList[index].in_kind_payment_amount3!==''">
                          <small class="text-danger">
                            &nbsp;{{ errorsList[index].in_kind_payment_amount3 }}
                          </small>
                        </template>
                        <div style="text-align: right;">
                          <ValidationTextNumber
                              v-model="detail.detail_list[index].in_kind_payment_amount3"
                              integral="10"
                              classes="form-control numLength10"
                              rules="min_value:0"
                              :placeholder="isReadOnly ? '' : '10,000'"
                              :unit="$t('label.currency')"
                              :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                              @blur="changeAmount(index, '3')"
                            />
                        </div>
                      </td>
                      <!-- 合計3 -->
                      <td colspan="2" style="border-right: solid 3px; border-bottom: solid 3px; border-top: none;">
                        <div style="text-align: right;" :key="changeAmountKey">
                          {{detail.detail_list[index].total_payment_amount3}}{{$t('label.currency')}}
                        </div>
                      </td>
                      <!-- 修正平均額 -->
                      <td colspan="2" style="border-top: none;">
                        <div style="text-align: right;">
                          <ValidationTextNumber
                            v-model="detail.detail_list[index].modified_average_amount"
                            integral="10"
                            classes="form-control numLength10"
                            rules="min_value:0"
                            :placeholder="isReadOnly ? '' : '10,000'"
                            :unit="$t('label.currency')"
                            :disabled="isReadOnly || detail.detail_list[index].is_non_change"
                            @blur="detailChanged(index)"
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                  </div>
                </div>
              </template>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged && showResult">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import CalculationBasisDetailMixin from '@/components/Dashboard/Views/Annual/CalculationBasisDetailMixin'

  export default {
    name: 'CalculationBasisDetail',
    mixins: [CalculationBasisDetailMixin]
  }
</script>
<style scoped>
.pink-row {
  background:pink !important;
}
.nonChange-row {
  background: gainsboro !important;
}

.white-box {
  background: #FFFFFF !important;
}
.gray-box {
  background: gainsboro !important;
}

</style>
