<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-show="showSearchCondition">
          <EmployeesSearch :orgSearchCond="searchCond" :labels="labels" :comboData="comboData" :disabled="isChanged" @search="searchClicked" :current-year-month="currentYearMonth"/>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      border
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <!-- 従業員 -->
              <el-table-column width="150" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium; font-weight: bold;">
                    {{props.row.employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 労働日 -->
              <el-table-column width="150" :label="$t('label.working_day')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center; font-size: medium; font-weight: bold;">
                    {{props.row.target_date}}
                  </div>
                </template>
              </el-table-column>
              <!-- 出勤時間 -->
              <el-table-column width="150" :label="labels.stamping_start_time">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.typing_stamping_start_time===props.row.original_stamping_start_time">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.original_stamping_start_time}}
                    </div>
                    {{getEntryFlgLabel(props.row.start_time_entry_flg)}}
                  </template>
                  <template v-else>
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.typing_stamping_start_time + '-->' + props.row.original_stamping_start_time}}
                    </div>
                    {{getEntryFlgLabel(props.row.start_time_entry_flg)}}
                  </template>
                </template>
              </el-table-column>
              <!-- パッチした日、従業員[出勤] -->
              <el-table-column width="200" :label="labels.patch_start_time_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.patch_start_time_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_name === props.row.patch_start_time_employee_name ? '-' : props.row.patch_start_time_employee_name}}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{props.row.employee_code === props.row.patch_start_time_employee_code ? '' : props.row.patch_start_time_employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 退勤時間 -->
              <el-table-column width="150" :label="labels.stamping_end_time">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.typing_stamping_end_time===props.row.original_stamping_end_time">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.original_stamping_end_time}}
                    </div>
                    {{getEntryFlgLabel(props.row.end_time_entry_flg)}}
                  </template>
                  <template v-else>
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.typing_stamping_end_time + '-->' + props.row.original_stamping_end_time}}
                    </div>
                    {{getEntryFlgLabel(props.row.end_time_entry_flg)}}
                  </template>
                </template>
              </el-table-column>
              <!-- パッチした日、従業員[退勤] -->
              <el-table-column width="200" :label="labels.patch_end_time_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.patch_end_time_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_name === props.row.patch_end_time_employee_name ? '-' : props.row.patch_end_time_employee_name}}</small>
                    <br>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{props.row.employee_code === props.row.patch_end_time_employee_code ? '' : props.row.patch_end_time_employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import WfImprintCorrectionSearchMixin from '@/components/Dashboard/Views/Working/WfImprintCorrectionSearchMixin'

  export default {
    name: 'WfImprintCorrectionSearch',
    data () {
      return {
        modalWidth: 500,
        showOperation: false
      }
    },
    methods: {
      afterSearch (res) {
        if (this.searchCond.search_type === 0) {
          this.showOffice = true
          this.showGroup = false
          this.showTeam = false
        } else if (this.searchCond.search_type === 2) {
          this.showOffice = false
          this.showGroup = false
          this.showTeam = true
        } else {
          this.showOffice = false
          this.showGroup = true
          this.showTeam = false
        }
      },
      allocationClicked (index, row) {
        this.params.group_code = row.group_code
        this.params.employee_code = row.employee_code
        this.mode = this.modes.edit
        this.$modal.show('detail')
      }
    },
    mixins: [WfImprintCorrectionSearchMixin]
  }
</script>
<style scoped>
</style>
