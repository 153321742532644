<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="employeeDetail" :draggable="true" :clickToClose="false" height="920" :scrollable="true" :width="1575" style="top: 0px; left: 125px;">
        <div class="modal-body">
          <!-- 従業員モーダル パラメータ：従業員番号 -->
          <EmployeeDetail :mode="mode" :params="params" @close="hide" @paySlipLayoutSearchShowAdd="paySlipLayoutSearchShowAdd" ref="employeeDetail"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="PaySlipLayoutSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
        <div class="modal-body">
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="paySlipLayoutSearchHide">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
          <PaySlipLayoutSearch :mode="1" :params="params"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <EmployeesSearch
              :orgSearchCond="employeeSearchCond"
              :labels="labels"
              :comboData="comboData"
              :disabled="isChanged"
              :show-search-all="true"
              :showAddBtn="true"
              :hireYearMode="true"
              @search="searchClicked"
              @showAdd="showAdd"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <input type="file" ref="upload_all" style="display: none;" @change="doUploadAll"/>
    <!-- 入社年度順に表示モード -->
    <template v-if="hireYearData&&searchCond.hireYearMode">
      <div class="col-md-12">
        <div class="card" style="width: 100%;">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <template v-if="roles.download === 1">
            <button 
              type="button"
              class="btn btn-sm btn-default btn-warning"
              @click="doPrintAll()"
              style="margin-left: 20px;">
              {{$t('button.create_csv')}}
            </button>
            <template v-if="testCompany">
              <button 
                type="button"
                class="btn btn-sm btn-default btn-warning"
                @click="doDownloadFormat()"
                style="margin-left: 0px;">
                {{$t('button.download_format')}}
              </button>
              <button 
                type="button"
                class="btn btn-sm btn-default btn-warning"
                @click="$refs.upload_all.click()"
                style="margin-left: 0px;">
                {{$t('button.upload_all')}}
              </button>
            </template>
            <hr />
          </template>
          <div class="card-content row">
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 10px;">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="hireYearData"
                        style="width: 1200px">
                <!-- 年度 -->
                <el-table-column width="60" :label="$t('label.fiscal_year')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{props.row.year + $t('label.year')}}
                  </template>
                </el-table-column>
                <!-- 入社メンバー -->
                <el-table-column width="530" :label="$t('label.hire')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <tamplate v-for="data in props.row.hire" :key="data.append_path">
                        <table align="left" @click="handleViewImage(data.employee_code)" style="cursor: pointer;">
                          <tr>
                            <td>
                              <img :src="data.append_path" style="height: 100px; margin-bottom: 10px;"/>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align:center">
                              {{data.employee_name}}
                            </td>
                          </tr>
                        </table>
                      </tamplate>
                    </div>
                  </template>
                </el-table-column>
                <!-- 退社メンバー -->
                <el-table-column width="530" :label="$t('label.retirement')" class-name="image-table">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <tamplate v-for="data in props.row.retirement" :key="data.append_path">
                        <table align="left" @click="handleViewImage(data.employee_code)" style="cursor: pointer;">
                          <tr>
                            <td>
                              <img :src="data.append_path" style="height: 100px; margin-bottom: 10px;"/>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align:center">
                              {{data.employee_name}}
                            </td>
                          </tr>
                        </table>
                      </tamplate>
                    </div>
                  </template>
                </el-table-column>
                <!-- 在籍人数 -->
                <el-table-column width="80" :label="$t('label.number_of_enrolled')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{props.row.enrolled + $t('label.people')}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 通常検索モード -->
    <template v-if="tableData&&!searchCond.hireYearMode">
      <div class="col-md-12">
        <div class="card" style="width: 100%;">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <template v-if="roles.download === 1">
            <button 
              type="button"
              class="btn btn-sm btn-default btn-warning"
              @click="doPrintAll()"
              style="margin-left: 20px;">
              {{$t('button.create_csv')}}
            </button>
            <template v-if="testCompany">
              <button 
                type="button"
                class="btn btn-sm btn-default btn-warning"
                @click="doDownloadFormat()"
                style="margin-left: 0px;">
                {{$t('button.download_format')}}
              </button>
              <button 
                type="button"
                class="btn btn-sm btn-default btn-warning"
                @click="$refs.upload_all.click()"
                style="margin-left: 0px;">
                {{$t('button.upload_all')}}
              </button>
            </template>
            <hr />
          </template>
          <div class="card-content row">
            <div class="col-sm-4">
              <!-- <el-select
                class="select-success width90"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select> -->
            </div>
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 10px;">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="queriedData"
                        :cell-class-name="tableCellClassName"
                        :style="getTableStyle()">
                <el-table-column
                  :min-width="100"
                  :label="$t('label.operation')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <template v-if="labels.preview === 1">
                        <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row, 1)"><i class="ti-file" :title="labels.button_preview"></i></a>
                      </template>
                      <template v-if="labels.update === 1">
                        <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row, 1)"><i class="ti-pencil-alt" :title="labels.button_update"></i></a>
                      </template>
                      <template v-if="labels.delete === 1">
                        <a class="btn btn-simple btn-icon btn-danger remove"  @click="handleDelete(props.$index, props.row, 1)"><i class="ti-close" :title="labels.button_delete"></i></a>
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getLabelRyaku(tableLabels.Employee)">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <div style="font-size: medium; font-weight: bold;">
                        {{props.row.employee_name1}}
                      </div>
                        {{props.row.employee_code1}}
                      <br>
                        {{props.row.employee_classification_name1}}
                      <br>
                      <template v-if="search_type===0">
                        {{props.row.office_name1}}
                      </template>
                      <template v-else-if="search_type===1">
                        {{props.row.group_name1}}
                      </template>
                      <template v-else-if="search_type===2">
                        {{props.row.team_name1}}
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120" label="写真">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <img :src="props.row.append_path1" style="margin-bottom: 10px; width: 100px;"/>
                    <template v-if="labels.entry === 1">
                      <a class="btn btn-simple btn-icon" :title="$t('label.coppy_entry')" @click="handleCopyAdd(props.$index, props.row, 1)"><i class="ti-layers" :title="labels.button_update"></i></a>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="70" label="" v-if="employeeCount >= 2">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  :label="$t('label.operation')"
                  v-if="employeeCount >= 2">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <template v-if="labels.preview === 1 && props.row.employee_code2">
                        <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row, 2)"><i class="ti-file" :title="labels.button_preview"></i></a>
                      </template>
                      <template v-if="labels.update === 1 && props.row.employee_code2">
                        <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row, 2)"><i class="ti-pencil-alt" :title="labels.button_update"></i></a>
                      </template>
                      <template v-if="labels.delete === 1 && props.row.employee_code2">
                        <a class="btn btn-simple btn-icon btn-danger remove"  @click="handleDelete(props.$index, props.row, 2)"><i class="ti-close" :title="labels.button_delete"></i></a>
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getLabelRyaku(tableLabels.Employee)" v-if="employeeCount >= 2">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <div style="font-size: medium; font-weight: bold;">
                        {{props.row.employee_name2}}
                      </div>
                        {{props.row.employee_code2}}
                      <br>
                        {{props.row.employee_classification_name2}}
                      <br>
                      <template v-if="search_type===0">
                        {{props.row.office_name2}}
                      </template>
                      <template v-else-if="search_type===1">
                        {{props.row.group_name2}}
                      </template>
                      <template v-else-if="search_type===2">
                        {{props.row.team_name2}}
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120" label="写真" v-if="employeeCount >= 2">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <img :src="props.row.append_path2" style="margin-bottom: 10px; width: 100px;"/>
                    <template v-if="labels.entry === 1 && props.row.employee_code2">
                      <a class="btn btn-simple btn-icon" :title="$t('label.coppy_entry')" @click="handleCopyAdd(props.$index, props.row, 2)"><i class="ti-layers" :title="labels.button_update"></i></a>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="70" label="" v-if="employeeCount >= 3">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="100"
                  :label="$t('label.operation')"
                  v-if="employeeCount >= 3">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <template v-if="labels.preview === 1 && props.row.employee_code3">
                        <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row, 3)"><i class="ti-file" :title="labels.button_preview"></i></a>
                      </template>
                      <template v-if="labels.update === 1 && props.row.employee_code3">
                        <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row, 3)"><i class="ti-pencil-alt" :title="labels.button_update"></i></a>
                      </template>
                      <template v-if="labels.delete === 1 && props.row.employee_code3">
                        <a class="btn btn-simple btn-icon btn-danger remove"  @click="handleDelete(props.$index, props.row, 3)"><i class="ti-close" :title="labels.button_delete"></i></a>
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getLabelRyaku(tableLabels.Employee)" v-if="employeeCount >= 3">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <div style="font-size: medium; font-weight: bold;">
                        {{props.row.employee_name3}}
                      </div>
                        {{props.row.employee_code3}}
                      <br>
                        {{props.row.employee_classification_name3}}
                      <br>
                      <template v-if="search_type===0">
                        {{props.row.office_name3}}
                      </template>
                      <template v-else-if="search_type===1">
                        {{props.row.group_name3}}
                      </template>
                      <template v-else-if="search_type===2">
                        {{props.row.team_name3}}
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120" label="写真" v-if="employeeCount >= 3">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <img :src="props.row.append_path3" style="margin-bottom: 10px; width: 100px;"/>
                    <template v-if="labels.entry === 1 && props.row.employee_code3">
                      <a class="btn btn-simple btn-icon" :title="$t('label.coppy_entry')" @click="handleCopyAdd(props.$index, props.row, 3)"><i class="ti-layers" :title="labels.button_update"></i></a>
                    </template>
                  </template>
                </el-table-column>

              </el-table>
              <div class="row" :style="getTableStyle()">
                <div class="col-sm-6 pagination-info">
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total">
                  </p-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import EmployeeDetail from '@/components/Dashboard/Views/Master/EmployeeDetail'
  import EmployeesSearch from '@/components/UIComponents/Inputs/EmployeesSearch'
  import PaySlipLayoutSearch from '@/components/Dashboard/Views/Master/PaySlipLayoutSearch.vue'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination,
      EmployeeDetail,
      EmployeesSearch,
      PaySlipLayoutSearch
    },
    computed: {
      testCompany () {
        return ['37439408', 'A992186970'].includes(this.$store.state.loginUser.companyCode)
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: [],
        tableColumns: [],
        tableData: undefined,
        searchCond: {
          company_code: '',
          employee_code: this.$store.state.loginUser.employeeCode,
          business_type: '',
          business_name: '',
          search_type: '0',
          office_code: '',
          team_code: '',
          search_employee_code: '',
          search_employee_name: '',
          search_retirement: false,
          hireYearMode: false
        },
        employeeSearchCond: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: '',
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          hireYear: false
        },
        employee_types: [],
        roles: [],
        labels: {},
        mode: 1,
        params: {},
        search_type: '',
        offices: [],
        groups: [],
        tableLabels: [],
        isChanged: false,
        comboData: {},
        url: '',
        hireYearData: [],
        showSearchCondition: true,
        employeeCount: 0
      }
    },
    methods: {
      paySlipLayoutSearchShowAdd () {
        this.params = {}
        this.$modal.show('PaySlipLayoutSearch')
      },
      paySlipLayoutSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // レイアウト
          this.$refs.employeeDetail.$refs.employeeSalaryInfo.layout_list_all = res.common_area.combo_data.layout_list
          for (let layout of this.$refs.employeeDetail.$refs.employeeSalaryInfo.layout_list_all) {
            layout.value = layout.value + '-=-' + layout.label
          }
          this.$refs.employeeDetail.$refs.employeeSalaryInfo.salary_category_layout_mapping_list = res.common_area.combo_data.salary_category_layout_mapping_list.salary_category_layout_mapping_list
          // レイアウト情報
          this.$refs.employeeDetail.$refs.employeeSalaryInfo.layout_inf_list = res.api_area.layout_format_list
          this.$refs.employeeDetail.$refs.employeeSalaryInfo.changeSalaryCategory()
        })
        this.$modal.hide('PaySlipLayoutSearch')
      },
      search () {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: Number(this.searchCond.search_type),
            office_code: this.searchCond.office_code,
            team_code: this.searchCond.team_code,
            employee_code: this.searchCond.search_employee_code,
            employee_name: this.searchCond.search_employee_name,
            employee_classification_code: this.searchCond.employee_classification_code,
            group_code: this.searchCond.group_code,
            retirement: this.searchCond.search_retirement,
            hireYearMode: this.searchCond.hireYearMode
          }
        }
        this.send('/view/employee/list', searchCond)
        .then(res => {
          this.tableColumns = [
            {prop: 'office', label: res.common_area.label.label_office, minWidth: 100},
            {prop: 'group', label: res.common_area.label.label_group, minWidth: 100},
            {prop: 'team', label: res.common_area.label.label_team, minWidth: 100},
            {prop: 'employee', label: res.common_area.label.label_employee, minWidth: 100},
            {prop: 'employee_classification', label: res.common_area.label.label_employee_classification, minWidth: 100}
          ]
          this.tableLabels = res.common_area.label
          if (this.searchCond.hireYearMode) {
            this.hireYearData = res.api_area.employee_data
            for (const data of this.hireYearData) {
              if (data.hire !== undefined) {
                for (const hire of data.hire) {
                  hire.random = this.getRandomStr()
                  this.getEmployeeImg(hire.append_path, hire.random, false, true, false, hire.sex)
                }
              }
              if (data.retirement !== undefined) {
                for (const retirement of data.retirement) {
                  retirement.random = this.getRandomStr()
                  this.getEmployeeImg(retirement.append_path, retirement.random, false, false, false, retirement.sex)
                }
              }
            }
          } else {
            this.tableData = res.api_area.employee_data
            for (let data of this.tableData) {
              if (data.append_path === '') {
                if (data.sex === 1) {
                  data.append_path = 'static/img/faces/man.gif'
                } else if (data.sex === 2) {
                  data.append_path = 'static/img/faces/woman.gif'
                } else {
                  data.append_path = 'static/img/faces/unknown.gif'
                }
              } else {
                data.random = this.getRandomStr()
                this.getEmployeeImg(data.append_path, data.random, true, false, false, data.sex)
              }
            }
            if (this.tableData.length > 0) {
              this.toggleSearchCondition()
            }
            this.employeeCount = this.tableData.length
            // tableの構成を変更する
            let newTableData = []
            let newData = {}
            for (let num = 0; num < this.tableData.length; num++) {
              if (num % 3 === 0) {
                newData = {}
                newData.employee_name1 = this.tableData[num].employee_name
                newData.employee_code1 = this.tableData[num].employee_code
                newData.employee_classification_name1 = this.tableData[num].employee_classification_name
                newData.office_name1 = this.tableData[num].office_name
                newData.group_name1 = this.tableData[num].group_name
                newData.team_name1 = this.tableData[num].team_name
                newData.append_path1 = this.tableData[num].append_path
                newData.random1 = this.tableData[num].random
                newData.outdate_flag1 = this.tableData[num].outdate_flag
              } else if (num % 3 === 1) {
                newData.employee_name2 = this.tableData[num].employee_name
                newData.employee_code2 = this.tableData[num].employee_code
                newData.employee_classification_name2 = this.tableData[num].employee_classification_name
                newData.office_name2 = this.tableData[num].office_name
                newData.group_name2 = this.tableData[num].group_name
                newData.team_name2 = this.tableData[num].team_name
                newData.append_path2 = this.tableData[num].append_path
                newData.random2 = this.tableData[num].random
                newData.outdate_flag2 = this.tableData[num].outdate_flag
              } else if (num % 3 === 2) {
                newData.employee_name3 = this.tableData[num].employee_name
                newData.employee_code3 = this.tableData[num].employee_code
                newData.employee_classification_name3 = this.tableData[num].employee_classification_name
                newData.office_name3 = this.tableData[num].office_name
                newData.group_name3 = this.tableData[num].group_name
                newData.team_name3 = this.tableData[num].team_name
                newData.append_path3 = this.tableData[num].append_path
                newData.random3 = this.tableData[num].random
                newData.outdate_flag3 = this.tableData[num].outdate_flag
                newTableData.push(newData)
              }
            }
            if (this.tableData.length % 3 !== 0) {
              newTableData.push(newData)
            }
            this.tableData = newTableData
          }
          if (this.$route.params.attendanceRecord) {
            this.$route.params.attendanceRecord = undefined
            this.handleEdit(0, this.tableData[0], 1)
          }
        })
        this.search_type = this.searchCond.search_type
      },
      searchClicked (newSearchCond) {
        console.log(this.$refs)
        this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
        this.searchCond.office_code = newSearchCond.office_code
        this.searchCond.group_code = newSearchCond.group_code
        this.searchCond.team_code = newSearchCond.team_code
        this.searchCond.search_employee_code = newSearchCond.employee_code
        this.searchCond.search_employee_name = newSearchCond.employee_name
        this.searchCond.employee_classification_code = newSearchCond.employee_classification_code
        this.searchCond.search_retirement = newSearchCond.retirement
        this.searchCond.hireYearMode = newSearchCond.hireYear
        this.employeeSearchCond = newSearchCond
        this.search()
      },
      doPrintAll () {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: Number(this.searchCond.search_type),
            office_code: this.searchCond.office_code,
            team_code: this.searchCond.team_code,
            employee_code: this.searchCond.search_employee_code,
            employee_name: this.searchCond.search_employee_name,
            employee_classification_code: this.searchCond.employee_classification_code,
            group_code: this.searchCond.group_code,
            retirement: this.searchCond.search_retirement,
            hireYearMode: this.searchCond.hireYearMode
          }
        }
        this.send('/download/employee', searchCond)
        .then(() => {
          swal({
            text: this.$t('message.csv_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      },
      doDownloadFormat () {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {}
        }
        this.send('/downloadformat/employee', searchCond)
        .then(() => {
          swal({
            text: this.$t('message.download_list_confirmation').replace('{process}', this.$t('button.download_format')),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      },
      doUploadAll (e) {
        let self = this
        const files = e.target.files || e.dataTransfer.files
        const file = files[0]
        const path = `tmp/${this.$store.state.loginUser.companyCode}/${file.name}`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(path)
        httpsReference.put(file).then(function () {
          self.upload(path)
        }).catch(function (error) {
          console.log(error)
        })
      },
      upload (path) {
        let searchCond = {
          screen_code: '00-000',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            file_path: path
          }
        }
        this.send('/upload/upload_file', searchCond)
        .then(() => {
          swal({
            text: this.$t('message.upload_list_confirmation').replace('{process}', this.$t('button.upload_all')),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage('/#/08/menu08Screen010')
          })
        })
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      init () {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode
        }
        this.send('/init/employee/list', data)
        .then(res => {
          // 検索条件の事業所名プルダウンの値を編集
          this.offices = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.office_list) {
            const office = {
              label: data.label,
              value: data.value
            }
            this.offices.push(office)
          }
          // 検索条件の部署名プルダウンの値を編集
          this.groups = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.group_list) {
            const group = {
              label: data.label,
              value: data.value
            }
            this.groups.push(group)
          }
          // 検索条件のチーム名プルダウンの値を編集
          this.teams = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.team_list) {
            const team = {
              label: data.label,
              value: data.value
            }
            this.teams.push(team)
          }
          // 検索条件の従業員区分プルダウンの値を編集
          this.employee_types = [{label: '', value: ''}]
          for (const data of res.common_area.combo_data.employee_classification_list) {
            const employeeType = {
              label: data.label,
              value: data.value
            }
            this.employee_types.push(employeeType)
          }
          // ラベル情報をthis.labelsに設定
          this.labels = this.getLabels(res.common_area)
          // ボタン権限をthis.rolesに設定
          this.roles = res.common_area.button_role
          // コンボデータ情報をthis.comboDataに設定
          this.comboData = {
            office_list: this.offices,
            group_list: this.groups,
            team_list: this.teams,
            employee_classification_list: this.employee_types
          }
          if (this.$route.params.attendanceRecord) {
            this.searchCond.search_type = 3
            this.searchCond.search_employee_code = this.$route.params.attendanceRecord.target_employee_code
            this.search()
          }
        })
      },
      getEmployeeImg (appendPath, random, normalFlg, hireFlg, retryFlg, sex) {
        if (appendPath === '') {
          let url = ''
          if (sex === 1) {
            url = 'static/img/faces/man.gif'
          } else if (sex === 2) {
            url = 'static/img/faces/woman.gif'
          } else {
            url = 'static/img/faces/unknown.gif'
          }
          if (normalFlg) {
            for (const data of this.tableData) {
              if (data.random === random) {
                data.append_path = url
              }
            }
          } else {
            if (hireFlg) {
              for (const data of this.hireYearData) {
                if (data.hire !== undefined) {
                  for (const hire of data.hire) {
                    if (hire.random === random) {
                      hire.append_path = url
                    }
                  }
                }
              }
            } else {
              for (const data of this.hireYearData) {
                if (data.retirement !== undefined) {
                  for (const retirement of data.retirement) {
                    if (retirement.random === random) {
                      retirement.append_path = url
                    }
                  }
                }
              }
            }
          }
          return
        }
        let self = this
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.getDownloadURL().then(function (url) {
          if (normalFlg) {
            for (const data of self.tableData) {
              if (data.random1 === random) {
                data.append_path1 = url
              } else if (data.random2 === random) {
                data.append_path2 = url
              } else if (data.random3 === random) {
                data.append_path3 = url
              }
            }
          } else {
            if (hireFlg) {
              for (const data of self.hireYearData) {
                if (data.hire !== undefined) {
                  for (const hire of data.hire) {
                    if (hire.random === random) {
                      hire.append_path = url
                    }
                  }
                }
              }
            } else {
              for (const data of self.hireYearData) {
                if (data.retirement !== undefined) {
                  for (const retirement of data.retirement) {
                    if (retirement.random === random) {
                      retirement.append_path = url
                    }
                  }
                }
              }
            }
          }
        }).catch(() => {
          if (!retryFlg) {
            this.getEmployeeImg('', random, normalFlg, hireFlg, true, sex)
          }
        })
      },
      getRandomStr () {
        // 生成する文字列の長さ
        var l = 16

        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'

        var cl = c.length
        var r = ''
        for (var i = 0; i < l; i++) {
          r += c[Math.floor(Math.random() * cl)]
        }
        return r
      },
      getTableStyle () {
        if (this.employeeCount === 1) {
          return 'width: 550px;'
        } else if (this.employeeCount === 2) {
          return 'width: 950px;'
        } else if (this.employeeCount >= 3) {
          return 'width: 1400px;'
        }
      },
      handleView (index, row, num) {
        this.params = row
        this.mode = this.modes.view
        if (num === 1) {
          sessionStorage.setItem('employee_code', row.employee_code1)
        } else if (num === 2) {
          sessionStorage.setItem('employee_code', row.employee_code2)
        } else if (num === 3) {
          sessionStorage.setItem('employee_code', row.employee_code3)
        }
        sessionStorage.setItem('mode', this.mode)
        this.$modal.show('employeeDetail')
      },
      handleViewImage (employeeCode) {
        this.params = {}
        this.params.employee_code = employeeCode
        this.mode = this.modes.view
        sessionStorage.setItem('employee_code', employeeCode)
        sessionStorage.setItem('mode', this.mode)
        this.$modal.show('employeeDetail')
      },
      handleEdit (index, row, num) {
        this.params = row
        this.mode = this.modes.edit
        if (num === 1) {
          sessionStorage.setItem('employee_code', row.employee_code1)
        } else if (num === 2) {
          sessionStorage.setItem('employee_code', row.employee_code2)
        } else if (num === 3) {
          sessionStorage.setItem('employee_code', row.employee_code3)
        }
        sessionStorage.setItem('mode', this.mode)
        this.$modal.show('employeeDetail')
      },
      handleDelete (index, row, num) {
        this.params = row
        this.mode = this.modes.delete
        if (num === 1) {
          sessionStorage.setItem('employee_code', row.employee_code1)
        } else if (num === 2) {
          sessionStorage.setItem('employee_code', row.employee_code2)
        } else if (num === 3) {
          sessionStorage.setItem('employee_code', row.employee_code3)
        }
        sessionStorage.setItem('mode', this.mode)
        this.$modal.show('employeeDetail')
      },
      handleCopyAdd (index, row, num) {
        this.params = row
        this.mode = this.modes.add
        if (num === 1) {
          sessionStorage.setItem('employee_code', row.employee_code1)
        } else if (num === 2) {
          sessionStorage.setItem('employee_code', row.employee_code2)
        } else if (num === 3) {
          sessionStorage.setItem('employee_code', row.employee_code3)
        }
        sessionStorage.setItem('mode', this.mode)
        this.$modal.show('employeeDetail')
      },
      showSearch () {
        this.$modal.show('employeeSearch')
      },
      hideSearch () {
        this.$modal.show('employeeSearch')
      },
      showAdd () {
        this.params = {}
        sessionStorage.setItem('employee_code', '')
        sessionStorage.setItem('mode', this.modes.add)
        this.$modal.show('employeeDetail')
      },
      show () {
        // 本実装では修正が必要
      },
      hide (submitFlg) {
        this.$modal.hide('employeeDetail')
        this.search()
      },
      tableCellClassName ({row, column, rowIndex, columnIndex}) {
        if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
          if (row.outdate_flag1) {
            return 'outdate-row'
          }
        }
        if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8) {
          if (row.outdate_flag2) {
            return 'outdate-row'
          }
        }
        if (columnIndex === 9 || columnIndex === 10 || columnIndex === 11 || columnIndex === 12) {
          if (row.outdate_flag3) {
            return 'outdate-row'
          }
        }
        return ''
      }
    },
    created () {
      this.searchCond.company_code = this.$store.state.loginUser.companyCode
      this.searchCond.business_type = ''
      this.searchCond.business_name = ''
      this.init()
    }
  }
</script>
<style>
  .image-table{
    vertical-align: top !important;
  }
  .el-table .outdate-row {
    color: #CCC !important;
  }
</style>
