<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3" style="text-align: center;">
                <h4>ライセンスのお申込みありがとうございました。</h4>
                <p>このウィンドウを閉じて処理を進めてください。</p>
                <div style="margin-top: 50px;">
                  <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
                    <span class="btn-label"><i class="ti-close"></i></span>
                    {{$t('button.close')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    methods: {
      close () {
        window.close()
      }
    },
    created () {
      const mailAddress = localStorage.getItem('migrationMailAddress')
      const customerId = localStorage.getItem('customerId')
      const campaignCode = localStorage.getItem('campaignCode')
      const request = {
        mail_address: mailAddress,
        customer_id: customerId,
        campaign_code: campaignCode,
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/start`, request)
      .then(res => {
      }).catch(() => {
      })
    }
  }
</script>
<style scoped>
  .card {
    background: #eee !important;
  }
  .full-page:after {
    background: white !important;
  }
</style>
