<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.group_code"
              item-name="group_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              rules="required"
              :item-label="labels.group_name"
              :options="comboData.group_list"
              :disabled="isPrimaryKeyReadOnly"
              @change="groupCodeChanged"
            />
            <ValidationDate
              v-model="detail.division_reservation_date"
              item-name="division_reservation_date"
              rules="required"
              :item-label="labels.division_reservation_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.division_reservation_date')"
              :disabled="isReadOnly"
            />
            <ValidationDivisionGroup
              v-model="detail.division_group"
              :labels="labels"
              :combo-data="comboData"
              :disabled="isReadOnly"
              rules="isDivisionGroupRequired"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import GroupDivisionDetailMixin from '@/components/Dashboard/Views/Generated/GroupDivisionDetailMixin'

  export default {
    name: 'GroupDivisionDetail',
    methods: {
      afterInit (res) {
        this.labels.group_code = this.$t('label.dividing_group').replace('{item}', this.labels.group)
        this.labels.division_group = this.$t('label.division_group').replace('{item}', this.labels.group)
        this.comboData.division_group_list = [...res.common_area.combo_data.group_code_list]
        this.comboData.division_group_list.unshift({})
      },
      convertDetail (detail) {
        if (this.mode === this.modes.delete) {
          detail.group_code = this.detail.group_code
        }
        return detail
      }
    },
    mixins: [GroupDivisionDetailMixin]
  }
</script>
<style scoped>
</style>
