<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame" :key="componentKey">
          <ValidationObserver ref="observerEditAll">
            <table style="margin-bottom: 0px;">
              <!-- 通知 -->
              <td style="width: 80px; vertical-align: middle;">
                <input type="checkbox" v-model="bulk.alert_management_control" class="gray" id="bulkAlertManagementControl" :disabled="isReadOnly"/>
                <label for="bulkAlertManagementControl">{{labels.alertJunlManagementControl_NOTICE}}</label>
              </td>
              <!-- アラート通知方法 -->
              <td style="width: 250px">
                <ValidationSelect
                  v-model="bulk.alert_notification_method"
                  item-name="alert_notification_method"
                  :placeholder="$t('label.select_description')"
                  classes="length15"
                  :disabled="isReadOnly"
                  :isErrorPositionTop=true
                  :options="comboData.alert_notification_method_list"
                  :item-label="labels.alert_notification_method"
                />
              </td>
              <!-- メールアドレス -->
              <td style="width: 300px">
                <ValidationText
                  v-model="bulk.mail_address"
                  item-name="mail_address"
                  max-length="255"
                  classes="form-control"
                  :isErrorPositionTop=true
                  rules="mailaddress"
                  :disabled="isReadOnly"
                  :placeholder="$t('placeholder.mail_address')"
                  :item-label="labels.mail_address"
                />
              </td>
              <!-- 一括編集 -->
              <td style="width: 90px; vertical-align: middle;">
                <button
                  type="button"
                  class="btn btn-sm btn-default btn-primary"
                  :disabled="isReadOnly"
                  @click="bulkEdit()"
                >{{$t('button.edit_all')}}</button>
              </td>
            </table>
          </ValidationObserver>
          <ValidationObserver ref="observer">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                  border
                  class="table-width"
                  :max-height="maxHeight"
                  :data="detail.employee_artemis_alert_management_list">
                <el-table-column :width="getColumnWidth(detail.employee_artemis_alert_management_list, labels.alert_junl_type_ryaku, 'alert_junl_type_name')" :label="labels.alert_junl_type_ryaku">
                  <!-- アラートタイプ -->
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.alert_junl_type_name}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 通知 -->
                <el-table-column width="50" :label="labels.alertJunlManagementControl_NOTICE">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <!-- 通知 -->
                    <div style="text-align: left;">
                      <input type="checkbox" v-model="props.row.alert_management_control" class="gray" :id="`alertManagementControl-${props.$index}`" :disabled="isReadOnly"/>
                    </div>
                  </template>
                </el-table-column>
                <!-- 通知タイミング -->
                <el-table-column width="130" :label="$t('label.notification_timing')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <!-- 通知間隔 -->
                    <template  v-if="props.row.alert_management_control && props.row.alert_term!==null">
                      <div style="text-align: left;">
                          <ValidationTextNumberHorizontal
                            v-model="props.row.alert_term"
                            integral="3"
                            classes="form-control numLength3"
                            rules="required_simple||min_value:0"
                            :placeholder="isReadOnly ? '' : '30'"
                            :isErrorPositionTop=true
                            :unit="getUnit(props.row.alert_term_unit, props.row.before_after_flag)"
                            :disabled="isReadOnly"
                          />
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <!-- アラートの通知方法 -->
                <el-table-column width="250" :label="labels.alert_notification_method">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="props.row.alert_management_control">
                      <div style="text-align: left; width:230px;">
                        <ValidationSelectHorizontal
                          v-model="props.row.alert_notification_method"
                          item-name="alert_notification_method"
                          :placeholder="$t('label.select_description')"
                          classes="length15"
                          rules="required_simple"
                          :disabled="isReadOnly"
                          :isErrorPositionTop=true
                          :options="comboData.alert_notification_method_list"
                          @change="alertNotificationMethodChanged"
                        />
                      </div>
                    </template>
                  </template>
                </el-table-column>
              <!-- メールアドレス -->
              <el-table-column width="300" :label="labels.mail_address">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="props.row.alert_management_control && (props.row.alert_notification_method==='1' | props.row.alert_notification_method==='9')">
                      <div style="text-align: left;">
                        <ValidationTextHorizontal
                          v-model="props.row.mail_address"
                          item-name="mail_address"
                          max-length="255"
                          classes="form-control"
                          :isErrorPositionTop=true
                          rules="required_simple|mailaddress"
                          :disabled="isReadOnly"
                          :placeholder="$t('placeholder.mail_address')"
                        />
                      </div>
                    </template>
                  </template>
                </el-table-column>
            </el-table>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="!this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import EmployeeArtemisAlertManagementMixin from '@/components/Dashboard/Views/Setting/EmployeeArtemisAlertManagementMixin'

  export default {
    name: 'EmployeeArtemisAlertManagement',
    mixins: [EmployeeArtemisAlertManagementMixin]
  }
</script>
<style scoped>
</style>
