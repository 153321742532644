<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ResidentTax
              v-model="detail.resident_tax_list"
              item-name="resident_tax_list"
              classes="form-control"
              :item-label="labels.resident_tax_list"
              :disabled="isReadOnly"
              :labels="labels"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ResidentTaxDetailMixin from '@/components/Dashboard/Views/Generated/ResidentTaxDetailMixin'

  export default {
    name: 'ResidentTaxDetail',
    methods: {
      afterInit (res) {
      },
      convertDetail (detail) {
        const apiArea = {}
        apiArea.employee_code = this.params.employee_code
        apiArea.work_schedule_list = []
        for (const row of detail.allocation.work_schedules) {
          if (row.selected) {
            const workSchedule = {
              work_schedule_code: row.work_schedule_code,
              default_flag: row.work_schedule_code === detail.allocation.default_work_schedule ? 1 : 2
            }
            apiArea.work_schedule_list.push(workSchedule)
          }
        }
        return apiArea
      }
    },
    mixins: [ResidentTaxDetailMixin]
  }
</script>
<style scoped>
</style>
