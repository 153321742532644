<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
          <Help :help="help" :placeholders="[pageTitle]" movie-key="bonus" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <!-- 対象年月日 -->
              <div class="form-group">
                <label>{{$t('label.target_year_month')}}</label>
                <div>
                  <el-select
                    class="select-success widthYear"
                    size="large"
                    placeholder
                    v-model="target_year"
                    :disabled="isPrimaryKeyReadOnly"
                  >
                    <el-option
                      v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.year')}}&nbsp;
                  <el-select
                    class="select-success widthMonth"
                    size="large"
                    placeholder
                    v-model="target_month"
                    :disabled="isPrimaryKeyReadOnly"
                  >
                    <el-option
                      v-for="month in months"
                      class="select-success"
                      :value="month"
                      :label="month"
                      :key="month"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.month')}}&nbsp;
                </div>
              </div>

              <!-- 対象年月 -->
              <div class="form-group">
                <label>{{$t('label.evaluation_period')}}</label><span class="required">{{$t('label.required')}}</span>
                <div>
                  <el-date-picker
                    v-model="detail.term_from"
                    type="date"
                    value-format="yyyy/MM/dd"
                    placeholder="2021-01-01"
                    :disabled="isReadOnly"
                     @change="dateSynchronizeFrom"
                  ></el-date-picker>&nbsp;～
                  <el-date-picker
                    v-model="detail.term_to"
                    type="date"
                    value-format="yyyy/MM/dd"
                    placeholder="2021-12-31"
                    :picker-options="futureday"
                    :disabled="isReadOnly"
                  ></el-date-picker>
                </div>
              </div>

              <!-- 名称 -->
              <div class="form-group">
                <div></div>
                <ValidationText
                  v-model="detail.bonus_pay_slip_name"
                  classes="form-control length30"
                  :item-label="labels.bonus_pay_slip_name"
                  item-name="bonus_pay_slip_name"
                  :placeholder="$t('placeholder.bonus_pay_slip_name')"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                  :disabled="isReadOnly"
                  rules="required"
                />
              </div>

              <!-- 直近 -->
              <template v-if="mode !== modes.add">
                <div class="form-group">
                  <label>{{labels.employee_pay_slip_target_date}}</label>
                  <div>
                    <el-select
                      class="select-success widthYear"
                      size="large"
                      placeholder
                      v-model="recent_year"
                      :disabled="isReadOnly"
                    >
                      <el-option
                        v-for="year in years"
                        class="select-success"
                        :value="year"
                        :label="year"
                        :key="year"
                      ></el-option>
                    </el-select>
                    &nbsp;{{$t('label.year')}}&nbsp;
                    <el-select
                      class="select-success widthMonth"
                      size="large"
                      placeholder
                      v-model="recent_month"
                      :disabled="isReadOnly"
                    >
                      <el-option
                        v-for="month in months"
                        class="select-success"
                        :value="month"
                        :label="month"
                        :key="month"
                      ></el-option>
                    </el-select>
                    &nbsp;{{$t('label.month')}}&nbsp;
                  </div>
                </div>
              </template>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doUpdate"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doDelete"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import CreateBonusPaySlipKeyTranMixin from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipKeyTranMixin'
  export default {
    name: 'CreateBonusPaySlipKeyTran',
    mixins: [CreateBonusPaySlipKeyTranMixin]
  }
</script>
<style scoped>
</style>
