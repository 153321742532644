<template>
  <div>
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel1 + '|' + new Date().getTime().toString(16)">
      <div class="form-group" style="display: inline-block; vertical-align: top;">
        <label>{{ itemLabel1 }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
        <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
        <div>
          <small class="help-block" v-show="valid | !validated">
            {{ helpText1 ? helpText1.replace('{maxlength}', maxLength1) : ''}}
          </small>
          <div class="error-message-wrapper" v-show="!valid">
            <small class="text-danger">
              <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
              {{ getMessage(errors[0]) }}
            </small>
          </div>
        </div>
        <div>
          <input 
            type="text" 
            :name="itemName1"
            :placeholder="placeholder1"
            :class="classes1" 
            style="display: inline-block;"
            v-model="itemValue[0]" 
            :maxlength="maxLength1"
            :disabled="disabled1">{{unit1 ? '&nbsp;' + unit1 : ''}}
        </div>
      </div>
    </ValidationProvider>
    <div class="form-group" style="display: inline-block; vertical-align: top; margin-left: 20px;">
      <label>{{ itemLabel2 }}</label>
      <div>
        <small class="help-block">
          {{ helpText2 ? helpText2.replace('{maxlength}', maxLength2) : ''}}
        </small>
      </div>
      <div>
        <input 
          type="text" 
          :name="itemName2"
          :placeholder="placeholder2"
          :class="classes2" 
          style="display: inline-block;"
          v-model="itemValue[1]" 
          :maxlength="maxLength2"
          :disabled="disabled2">{{unit2 ? '&nbsp;' + unit2 : ''}}
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName1: String,
      maxLength1: String,
      classes1: String,
      rules: String,
      itemLabel1: String,
      placeholder1: String,
      disabled1: Boolean,
      helpText1: String,
      unit1: {
        type: String,
        require: false,
        'default': ''
      },
      itemName2: String,
      maxLength2: String,
      classes2: String,
      itemLabel2: String,
      placeholder2: String,
      disabled2: Boolean,
      helpText2: String,
      unit2: {
        type: String,
        require: false,
        'default': ''
      }
    },
    data () {
      return {
        itemValue: ['', '']
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>