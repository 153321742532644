<template>
  <div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="doClose">
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{$t('label.role_allocation')}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <RoleAllocationTable
              v-model="detail.allocation"
              item-name="allocation"
              classes="form-control"
              :item-label="labels.allocation"
              :disabled="isReadOnly"
              :payrollManagement="payrollManagement"
              :attendanceManagement="attendanceManagement"
              :labels="labels"
            />
          </ValidationObserver>
        </div></div>
      </div>
    </form>
  </div>
</template>
<script>
  import GroupRoleAllocationDetailMixin from '@/components/Dashboard/Views/Master/EmployeeGroupRoleAllocationDetailMixin'

  export default {
    name: 'GroupRoleAllocationDetail',
    methods: {
      afterInit () {
        const allocation = []
        for (const role of this.roleList) {
          let selected = false
          for (const employeeRole of this.selectRoles) {
            if (employeeRole === role.value) {
              selected = true
              break
            }
          }
          const row = {
            role_code: role.value,
            role_name: role.label,
            selected: selected
          }
          allocation.push(row)
        }
        this.detail.allocation = allocation
        this.componentKey += 1
      },
      convertDetail (detail) {
        const apiArea = {}
        apiArea.group_code = this.params.group_code
        apiArea.employee_code = this.params.employee_code
        apiArea.role_list = []
        for (const row of detail.allocation) {
          const role = {
            role_code: row.role_code,
            allocation_flag: row.selected
          }
          apiArea.role_list.push(role)
        }
        return apiArea
      }
    },
    mixins: [GroupRoleAllocationDetailMixin]
  }
</script>
<style scoped>
</style>
