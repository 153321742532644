<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="form-group search-condition-item">
            <label>{{labels.supplier_name}}</label>
            <input type="text" :placeholder="$t('placeholder.supplier_name')" maxlength="30" class="form-control length60" v-model="searchCond.supplier_name">
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showSupplierName$homePage" :width="getColumnWidthStrong(tableData, labels.supplier_name_ryaku === undefined ? labels.supplierName_ryaku === undefined ? '' : labels.supplierName_ryaku : labels.supplier_name_ryaku, 'supplier_name', 'home_page')" :label="labels.supplier_name_ryaku === undefined ? labels.supplierName_ryaku === undefined ? '' : labels.supplierName_ryaku : labels.supplier_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{ props.row.supplier_name}}
                  </div>
                  <div style="text-align: left;" v-if="props.row.home_page">
                    <small><a :href="props.row.home_page" target="_blank" style="color: #317FB8;">{{ props.row.home_page}}</a></small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPostCode" :width="300" :label="$t('label.registered_address')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{'〒' + props.row.post_code}}
                  </div>
                  <div style="text-align: left;">
                    {{ props.row.state_name + props.row.municipality_name + props.row.town + ' ' + props.row.building }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTel" :width="getColumnWidthStrong(tableData, labels.tel_ryaku === undefined ? labels.tel_ryaku === undefined ? '' : labels.tel_ryaku : labels.tel_ryaku, 'tel')" :label="labels.tel_ryaku === undefined ? labels.tel_ryaku === undefined ? '' : labels.tel_ryaku : labels.tel_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.tel}}
                  </div>
                  <div style="text-align: left;" v-if="props.row.fax">
                    <small>{{ '(' + labels.fax_ryaku + ') ' + props.row.fax }}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showMailAddress" :width="getColumnWidthStrong(tableData, labels.mail_address_ryaku === undefined ? labels.mailAddress_ryaku === undefined ? '' : labels.mailAddress_ryaku : labels.mail_address_ryaku, 'mail_address')" :label="labels.mail_address_ryaku === undefined ? labels.mailAddress_ryaku === undefined ? '' : labels.mailAddress_ryaku : labels.mail_address_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.mail_address}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showDateOfCollection" :width="getColumnWidthStrong(tableData, labels.date_of_collection_ryaku === undefined ? labels.dateOfCollection_ryaku === undefined ? '' : labels.dateOfCollection_ryaku : labels.date_of_collection_ryaku, 'date_of_collection')" :label="labels.date_of_collection_ryaku === undefined ? labels.dateOfCollection_ryaku === undefined ? '' : labels.dateOfCollection_ryaku : labels.date_of_collection_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.date_of_collection}}
                    {{$t('label.unit_day')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCollectionDay" :width="getColumnWidthStrong(tableData, labels.collection_day_ryaku === undefined ? labels.collectionDay_ryaku === undefined ? '' : labels.collectionDay_ryaku : labels.collection_day_ryaku, 'collection_day')" :label="labels.collection_day_ryaku === undefined ? labels.collectionDay_ryaku === undefined ? '' : labels.collectionDay_ryaku : labels.collection_day_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.collection_day}}
                    {{$t('label.unit_day')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{ props.row.update_employee_name }}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{ props.row.update_employee_code }}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showLunch" :width="getButtonColumnWidth([$t('button.lunch')])" label="">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <button type="button" class="btn btn-warning btn-sm btn-default" @click="lunchClicked(props.$index, props.row)">{{$t('button.lunch')}}</button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import LunchSupplierSearchMixin from '@/components/Dashboard/Views/Generated/LunchSupplierSearchMixin'

  export default {
    name: 'LunchSupplierSearch',
    methods: {
      afterInit (res) {
        if (window.previousUrl === '/03/menu03Screen014/lunch' && window.currentUrl === '/03/menu03Screen014/search') {
          this.searchCond = JSON.parse(localStorage.getItem('orgSearchCond'))
          this.search()
        }
      },
      afterSearch (res) {
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
      },
      lunchClicked (index, row) {
        this.$router.push({name: 'generatedLunchSearch', params: {paramSupplierCode: row.supplier_code, paramSupplierName: row.supplier_name}})
      }
    },
    mixins: [LunchSupplierSearchMixin]
  }
</script>
<style scoped>
</style>
