<template>
  <div>
    <input type="text"
      class="form-control numLength10"
      style="display: inline-block;"
      maxlength="10"
      :placeholder="placeholder1"
      v-model="itemValue[0]"
      :disabled="disabled"
      @blur="employeeSelected(1)">
    <input type="text" 
      class="form-control length10"
      style="display: inline-block;"
      maxlength="30"
      :placeholder="placeholder2"
      v-model="itemValue[1]"
      :disabled="disabled"
      @blur="employeeSelected(2)">
    <button type="button" class="btn btn-sm btn-default btn-warning" @click="$emit('showEmployeeSearch', index)" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      salaryFlag: {
        type: Boolean,
        require: false,
        'default': undefined
      },
      index: Number
    },
    data () {
      return {
        itemValue: ['', '']
      }
    },
    computed: {
      companyCode () {
        return this.$store.state.loginUser.companyCode
      }
    },
    methods: {
      employeeSelected (searchType) {
        if ((searchType === 1 && this.itemValue[0].length === 0) ||
        (searchType === 2 && this.itemValue[1].length === 0)) {
          this.itemValue = ['', '']
          this.$emit('input', this.itemValue)
          return
        }
        if (!this.canShow('/SS/menuSSScreen018')) return
        let searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: '',
            employee_name: '',
            employee_classification_code: '',
            retirement: true,
            all_flag: false,
            salary_flag: this.salaryFlag
          }
        }
        if (searchType === 1) {
          searchCond.api_area.employee_code = this.itemValue[0]
        } else {
          searchCond.api_area.employee_name = this.itemValue[1]
        }
        this.send('/view/search_employee/list', searchCond)
        .then(res => {
          if (res.api_area.employee_list.length === 1) {
            this.itemValue = [res.api_area.employee_list[0].employee_code, res.api_area.employee_list[0].employee_name]
          } else {
            this.itemValue = ['', '']
          }
          this.$emit('input', this.itemValue)
        })
      }
    },
    watch: {
      value () {
        this.itemValue = [...this.value]
        if (!!this.itemValue[0] && !this.itemValue[1]) {
          this.employeeSelected(1)
        }
      }
    },
    mounted () {
      this.itemValue = [...this.value]
      if (!!this.itemValue[0] && !this.itemValue[1]) {
        this.employeeSelected(1)
      }
    }
  }
</script>
