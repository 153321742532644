<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="parlance" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <ValidationSelect
            v-model="searchCond.language"
            item-name="language"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.language_type_list)"
            :item-label="labels.language"
            :options="comboData.language_type_list"
            frame-class="search-condition-item"
          />
          <div class="form-group search-condition-item">
            <label>{{labels.field_name}}</label>
            <input type="text" :placeholder="$t('placeholder.field_name')" maxlength="128" class="form-control length128" v-model="searchCond.field_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="form-group search-condition-item">
            <label>{{labels.label_name}}</label>
            <input type="text" :placeholder="$t('placeholder.label_name')" maxlength="128" class="form-control length128" v-model="searchCond.label_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="form-group search-condition-item">
            <label>{{labels.abbreviated_name}}</label>
            <input type="text" :placeholder="$t('placeholder.abbreviated_name')" maxlength="128" class="form-control length128" v-model="searchCond.abbreviated_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-primary length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      border
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="50" :label="$t('button.delete')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="false"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(comboData.language_type_list, labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku, 'label', 'value')" :label="labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.language_language, comboData.language_type_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.field_name_ryaku === undefined ? labels.fieldName_ryaku === undefined ? '' : labels.fieldName_ryaku : labels.field_name_ryaku, 'language_field_name')" :label="labels.field_name_ryaku === undefined ? labels.fieldName_ryaku === undefined ? '' : labels.fieldName_ryaku : labels.field_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium; font-weight: bold;">
                    {{props.row.language_label_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.language_field_name}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="410" :label="labels.label_name_ryaku === undefined ? labels.labelName_ryaku === undefined ? '' : labels.labelName_ryaku : labels.label_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.parlance_label_name"
                    item-name="parlance_label_name"
                    max-length="128"
                    classes="form-control length30"
                    :is-error-position-top="true"
                    :item-label="labels.label_name_ryaku === undefined ? labels.labelName_ryaku === undefined ? '' : labels.labelName_ryaku : labels.label_name_ryaku"
                    :placeholder="$t('placeholder.label_name')"
                    :help-text="$t('label.max_length')"
                    @input="labelNameChanged(props.row)"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column :width="410" :label="labels.abbreviated_name_ryaku === undefined ? labels.abbreviatedName_ryaku === undefined ? '' : labels.abbreviatedName_ryaku : labels.abbreviated_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.parlance_abbreviated_name"
                    item-name="parlance_abbreviated_name"
                    max-length="128"
                    classes="form-control length30"
                    :is-error-position-top="true"
                    :item-label="labels.abbreviated_name_ryaku === undefined ? labels.abbreviatedName_ryaku === undefined ? '' : labels.abbreviatedName_ryaku : labels.abbreviated_name_ryaku"
                    :placeholder="$t('placeholder.abbreviated_name')"
                    :help-text="$t('label.max_length')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanged" @click="doUpdate">
        <span class="btn-label"><i class="fa fa-check"></i></span>
        {{$t('button.update')}}
      </button>
      <br><br><br><br><br>
    </template>
    </div>
  </div>
</template>
<script>
  import ParlanceSearchMixin from '@/components/Dashboard/Views/Master/ParlanceSearchMixin'

  export default {
    name: 'ParlanceSearch',
    data () {
      return {
        modalWidth: 1475
      }
    },
    methods: {
      labelNameChanged (row) {
        row.parlance_abbreviated_name = row.parlance_label_name
      },
      afterInit (res) {
        this.searchCond.language = 'JPN'
      },
      afterSearch (res) {
        // const tableData = []
        // for (const row of this.tableData) {
        //   const item = {
        //     route_type: row.individual_route_name ? 1 : 2,
        //     route_code: row.individual_route_code ? row.individual_route_code : row.common_route_code,
        //     route_name: row.individual_route_name ? row.individual_route_name : row.common_route_name,
        //     update_date: row.update_date,
        //     update_employee_code: row.update_employee_code,
        //     update_employee_name: row.update_employee_name
        //   }
        //   tableData.push(item)
        // }
        // this.tableData = tableData
      },
      convertSearchCond (searchCond) {
        searchCond.route_type = this.toSelectedArray(searchCond.route_type)
        return searchCond
      }
    },
    mixins: [ParlanceSearchMixin]
  }
</script>
<style scoped>
</style>
