<template>
  <div>
    <modal name="groupDivisionGroup" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <GroupDivisionGroupDetail :params="dialogParams" :mode="dialogMode" @close="hideGroupDivisionGroup"/>
      </div>
    </modal>
    <div style="margin-bottom: 10px;" v-if="!disabled">
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="showAdd">
        <span class="btn-label"><i class="ti-settings"></i></span>
        {{$t('button.new')}}
      </button>
    </div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      :data="tableData"
      :empty-text="$t('label.no_data')"
      :key="componentKey">
      <el-table-column :width="130"
                        v-if="showOperation"
                        fixed="left"
                        :label="$t('label.operation')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <template v-if="labels.preview === 1">
            <a class="btn btn-simple btn-icon like" :disabled="disabled" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
          </template>
          <template v-if="labels.update === 1 && canUpdate(props.row)">
            <a class="btn btn-simple btn-icon edit" :disabled="disabled" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
          </template>
          <template v-if="labels.delete === 1 && canDelete(props.row)">
            <a class="btn btn-simple btn-icon btn-danger remove" :disabled="disabled" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
          </template>
        </template>
      </el-table-column>
      <!-- 部署名 -->
      <el-table-column
        width="300"
        :label="labels.group"
        align="center">
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>{{props.row.group_code}}</small>
          </div>
          <div style="text-align: left;">
            {{props.row.group_name}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import GroupDivisionGroupDetail from '@/components/Dashboard/Views/Generated/GroupDivisionGroupDetail'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      GroupDivisionGroupDetail
    },
    props: {
      value: Object,
      comboData: Object,
      labels: Object,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        dialogParams: {},
        dialogMode: undefined,
        currentIndex: 0,
        tableData: [],
        showOperation: true
      }
    },
    watch: {
      value () {
        this.tableData = this.value.tableData
      }
    },
    mounted () {
      this.tableData = this.value.tableData
    },
    methods: {
      showAdd () {
        this.dialogParams = {}
        this.dialogMode = this.modes.add
        this.$modal.show('groupDivisionGroup')
      },
      handleView (index, row) {
        this.currentIndex = index
        this.dialogParams = {}
        this.dialogParams.group_division_group_code = row.group_code
        this.dialogMode = this.modes.view
        this.$modal.show('groupDivisionGroup')
      },
      handleEdit (index, row) {
        this.currentIndex = index
        this.dialogParams = {}
        this.dialogParams.group_division_group_code = row.group_code
        this.dialogMode = this.modes.edit
        this.$modal.show('groupDivisionGroup')
      },
      handleDelete (index, row) {
        this.currentIndex = index
        this.dialogParams = {}
        this.dialogParams.group_division_group_code = row.group_code
        this.dialogMode = this.modes.delete
        this.$modal.show('groupDivisionGroup')
      },
      canUpdate (index, row) {
        return true
      },
      canDelete (index, row) {
        return true
      },
      hideGroupDivisionGroup (...args) {
        const [isChanged, detail] = args
        this.$modal.hide('groupDivisionGroup')
        if (isChanged) {
          if (this.dialogMode === this.modes.add) {
            this.tableData.push(detail)
          } else if (this.dialogMode === this.modes.edit) {
            this.tableData[this.currentIndex] = detail
          } else if (this.dialogMode === this.modes.delete) {
            delete this.tableData[this.currentIndex]
          }
          this.componentKey++
        }
      }
    }
  }
</script>

<style scoped>
</style>