import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import {DatePicker} from 'element-ui'

export default {
  name: 'CreateBonusPaySlipKeyTranMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    'el-date-picker': DatePicker
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    let self = this
    return {
      isCurrentMonth: false,
      componentKey: 0,
      id: 0,
      isChanged: false,
      currentYear: '',
      currentMonth: '',
      target_year: '',
      target_month: '',
      recent_year: '',
      recent_month: '',
      detail: {
        target_date: '',
        employee_pay_slip_target_date: '',
        term_from: '',
        term_to: '',
        bonus_pay_slip_name: ''
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      fixed_fluctuation_mapping_list: [{}],
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      futureday: {
        disabledDate (date) {
          let termFrom = self.formatDate(self.detail.term_from)
          let termFromDate = new Date(termFrom)
          return date <= termFromDate
        }
      }
    }
  },
  computed: {
    screenCode () {
      return '02-002'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
    // 日付のシンクロ(FROM)
    dateSynchronizeFrom () {
      let from = this.detail.term_from
      let to = this.detail.term_to
      let today = this.formatToday()
      if (from > today) {
        this.detail.term_from = today
      }
      if (to > today) {
        this.detail.term_to = today
      }
      if (from > to && to !== '') {
        this.detail.term_to = this.detail.term_from
      }
    },
    // 日付のシンクロ(TO)
    dateSynchronizeTo () {
      let from = this.detail.term_from
      let to = this.detail.term_to
      let today = this.formatToday()
      if (from > today) {
        this.detail.term_from = today
      }
      if (to > today) {
        this.detail.term_to = today
      }
      if (from > to && to !== '') {
        this.detail.term_from = to = this.detail.term_to
      }
    },
    // カンマ付与(detailを書き換えます)
    formatComma (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatNumber(String(this.detail[name]).replaceAll(',', ''))
      }
      return this.detail[name]
    },
    formatTime (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatDispMinute(parseInt(this.detail[name]))
      }
      return this.detail[name]
    },
    // サーバ送信用にnullをブランクに変換する
    servNullToBlank (target, name) {
      if (!target[name]) {
        target[name] = ''
      }
      return target[name]
    },
    // サーバ送信用に数値に変換する
    servInt (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    // サーバ送信用に時刻を10進数に変換する
    servTime (target, name) {
      if (target[name]) {
        const toHour = parseInt(target[name].split(':')[0], 10)
        const toMinute = parseInt(target[name].split(':')[1], 10)
        let ans = toHour * 60 + toMinute
        target[name] = ans
      } else {
        target[name] = null
      }
      return target[name]
    },
    // 固定変動区分が固定項目以外のときにtrueを返す
    // # 1 FIXED 固定項目
    // # 2 FLUCTUATION 変動項目
    // # 3 SYSTEM システム項目
    isFixedFluctuation (itemCode) {
      for (const entry of Object.entries(this.fixed_fluctuation_mapping_list)) {
        for (const fixedFluctuation of Object.entries(entry[1])) {
          if (fixedFluctuation[0] === itemCode) {
            return fixedFluctuation[1] !== 1
          }
        }
      }
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      if (this.mode === this.modes.add) {
        this.currentYear = String(new Date().getFullYear())
        this.currentMonth = String(new Date().getMonth() + 1)
        this.target_year = this.target_year ? this.target_year : this.currentYear
        this.target_month = this.target_month ? this.target_month : this.currentMonth
        this.recent_year = this.recent_year ? this.recent_year : this.currentYear
        this.recent_month = this.recent_month ? this.recent_month : this.currentMonth
      } else {
        this.target_year = this.detail.target_date.substring(0, 4)
        this.target_month = this.detail.target_date.substring(4, 6)
        this.recent_year = this.detail.employee_pay_slip_target_date.substring(0, 4)
        this.recent_month = this.detail.employee_pay_slip_target_date.substring(4, 6)
      }
      this.labels.bonus_pay_slip_name = this.$t('label.bonus_pay_slip_name')
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode === this.modes.add) {
        detail.target_date = this.target_year + ('00' + this.target_month).slice(-2)
      }
      detail.employee_pay_slip_target_date = this.recent_year + ('00' + this.recent_month).slice(-2)
      if (this.mode !== this.modes.edit) {
        delete detail.employee_pay_slip_target_date
      }
      delete detail.company_code // 念のため
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    calc () {
      this.presubmit('calc')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
          delete detail.update_count
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {employee_code: this.detail.employee_code}
          detail.employee_name = this.detail.employee_name
          detail.target_date = this.detail.target_date
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: '02-002',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        this.send(`/bonus/${operation}/employee_bonus_pay_slip_header`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: '02-002',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.employee_code,
          employee_name: this.params.employee_name,
          target_date: this.params.target_date
        }
      }
      this.send('/bonus/init/employee_bonus_pay_slip_header', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
    this.init()
  }
}
