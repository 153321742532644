<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <h5>{{formatDateWith(params.day,'YYYY/M/D(ddd)')}}</h5>
            <div class="error-message-wrapper" v-show="detail.message !== ''">
              <small class="text-danger">
                {{ error_message }}
              </small>
            </div>
            <table boder="1" style="border:1px solid #EBEEF5">
              <tbody>
                <tr style="border-top:none; border-right:none; border-left:none;">
                  <th width="100"></th><!-- 職種 -->
                  <th width="100"></th><!-- 勤務体系 -->
                  <th width="100"></th><!-- 未定時間 -->
                  <th width="40"></th><!-- 削除 -->
                  <th width="350"></th><!-- 従業員 -->
                  <th width="100"></th><!-- 希望時間 -->
                  <th width="265"></th><!-- 勤務時間 -->
                  <th width="140"></th><!-- 状態 -->
                  <th width="295"></th><!-- メッセージ -->
                  <th width="35"></th><!-- メッセージボタン -->
                  <th width="5"></th><!-- 未読 -->
                  <th width="75"></th><!-- 確定日 -->
                </tr>
                <!-- 1段目 ヘッダ-->
                <tr style="background-color: gainsboro;">
                  <th><div>{{labels.business === undefined ? '' : labels.business}}</div></th>
                  <th><div>{{labels.workSchedule === undefined ? '' : labels.workSchedule}}</div></th>
                  <th><div>{{$t('label.undecided_time') === 'label.undecided_time' ? '' : $t('label.undecided_time')}}</div></th>
                  <th><div>{{$t('label.delete') === 'label.delete' ? '' : $t('label.delete')}}</div></th>
                  <th><div>{{labels.employee === undefined ? '' : labels.employee}}</div></th>
                  <th><div>{{$t('label.desired_time') === 'label.desired_time' ? '' : $t('label.desired_time')}}</div></th>
                  <th><div>{{$t('label.adjustment_time') === 'label.adjustment_time' ? '' : $t('label.adjustment_time')}}</div></th>
                  <th><div>{{$t('label.status') === 'label.status' ? '' : $t('label.status')}}</div></th>
                  <th colspan="3"><div>{{labels.message === undefined ? '' : labels.message}}</div></th>
                  <th><div>{{labels.fixed_date === undefined ? '' : labels.fixed_date}}</div></th>
                </tr>
                <template v-for="(tableData, tableIndex) in detail">
                  <tr v-for="(employee, index) of tableData.confirm_employee_list" :key="`tableData-${tableData.business_type}-${tableData.work_schedule_code}-${index}`">
                    <template v-if="index===0">
                      <!-- 職種-->
                      <td :rowspan="tableData.confirm_employee_list.length">
                        <div style="text-align: center;">
                          {{getLabel(getSelectName(tableData.business_type, comboData.business_list))}}<br>
                          <template v-if="!isReadOnly">
                            <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow(tableData.confirm_employee_list)">{{$t('button.add_row')}}</button>
                          </template>
                        </div>
                      </td>
                      <!-- 勤務体系-->
                      <td :rowspan="tableData.confirm_employee_list.length">
                        <div style="text-align: center;">
                          {{tableData.work_schedule_name}}<br>
                          {{viewTime(tableData.job_start, tableData.job_end)}}
                        </div>
                      </td>
                      <!-- 未定時間-->
                      <td :rowspan="tableData.confirm_employee_list.length">
                        <div style="text-align: center; color: red;">
                          <template v-for="(undecided_time) in tableData.undecided_times">
                            {{viewTime(undecided_time.job_start, undecided_time.job_end)}}<br>
                          </template>
                        </div>
                      </td>
                    </template>
                    <!-- 削除-->
                    <td>
                      <div style="text-align: center;">
                        <input type="checkbox" v-model="employee.delete" :class="isReadOnly || !employee.can_change_record ? 'gray2' : 'gray'" :disabled="isReadOnly || !employee.can_change_record"/>
                      </div>
                    </td>
                    <!-- 従業員 -->
                    <td>
                      <div class="error-message-wrapper" v-show="employee.error_shift_reflection_flg !== undefined && employee.error_employee_code !== '' && employee.employee_code === ''">
                        <small class="text-danger">
                          &nbsp;&nbsp;{{ employee.error_employee_code }}
                        </small>
                      </div>
                      <!-- 従業員番号 -->
                      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                        <div>
                          <input type="text"
                            class="form-control numLength9"
                            style="display:inline-block;"
                            :disabled="isReadOnly || employee.delete || !employee.can_change_record || !employee.new_record"
                            v-model="employee.employee_code"
                            @blur="employeeSelected(tableIndex, index, tableData.business_type, formatDateWith(params.day,'YYYY/MM/DD'), tableData.work_schedule_code)">
                        </div>
                      </div>
                      <!-- 従業員名 -->
                      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                        <div>
                          <div style="display: inline-block; transform: translate(-3px,1px);">
                            <input type="text"
                              class="form-control length9"
                              style="display:inline-block;"
                              :disabled="true"
                              v-model="employee.employee_name">
                          </div>
                          <button type="button"
                            class="btn btn-sm btn-default btn-warning"
                            style="transform: translate(-1px,4px);"
                            @click="showEmployeeSearchModal(tableIndex, index, tableData.business_type, formatDateWith(params.day,'YYYY/MM/DD'), tableData.work_schedule_code)"
                            :disabled="isReadOnly || employee.delete || !employee.can_change_record || !employee.new_record"
                            v-show="canShow('/SS/menuSSScreen018')">
                            <i class="ti-search"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                    <!-- 希望時間-->
                    <td>
                      <div style="text-align: center;">
                        <template v-if="employee.hope_work_status === 1">
                          {{viewTime(employee.hope_job_start, employee.hope_job_end)}}
                        </template>
                        <template v-else>
                          {{getLabel(getSelectName(employee.hope_work_status, comboData.work_status_list))}}
                        </template>
                      </div>
                    </td>
                    <!-- 調整時間-->
                    <td>
                      <div class="error-message-wrapper" style="transform: translate(0px, 7px);"  v-show="employee.error_flex_time !== undefined && employee.error_flex_time !== ''">
                        <small class="text-danger">
                          &nbsp;&nbsp;{{ employee.error_flex_time }}
                        </small>
                      </div>
                      <!-- 所定休日 -->
                      <input
                            type="checkbox"
                            v-model="employee.work_status_2"
                            class="gray"
                            style="vertical-align: middle;"
                            :disabled="isReadOnly || employee.delete || !employee.can_change_record"
                            :id="`work_status_${employee.employee_code}_${index}_2`"
                            :title="getLabel(getSelectName(2, comboData.work_status_list))"
                            @change="workStatus2Changed(employee)"
                          >
                      <label :for="`work_status_${employee.employee_code}_${index}_2`" :class="isReadOnly || employee.delete || !employee.can_change_record ? 'requestSubmissionFlg2' : 'requestSubmissionFlg'">
                        <small>{{getLabel(getSelectName(2, comboData.work_status_list))}}</small>
                      </label>
                      &nbsp;&nbsp;
                      <!-- 法定休日 -->
                      <input
                            type="checkbox"
                            v-model="employee.work_status_3"
                            class="gray"
                            style="vertical-align: middle;"
                            :disabled="isReadOnly || employee.delete || !employee.can_change_record"
                            :id="`work_status_${employee.employee_code}_${index}_3`"
                            :title="getLabel(getSelectName(3, comboData.work_status_list))"
                            @change="workStatus3Changed(employee)"
                          >
                      <label :for="`work_status_${employee.employee_code}_${index}_3`" :class="isReadOnly || employee.delete || !employee.can_change_record ? 'requestSubmissionFlg2' : 'requestSubmissionFlg'">
                        <small>{{getLabel(getSelectName(3, comboData.work_status_list))}}</small>
                      </label>
                      <!-- 調整時間 -->
                      <div style="text-align: center;">
                        <ValidationStartEndTime
                          v-model="employee.flex_time"
                          item-name="flex_time"
                          max-length="5"
                          classes="form-control numLength4"
                          :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
                          :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
                          :disabled="isReadOnly || employee.delete || !employee.can_change_record || employee.work_status_2 || employee.work_status_3"
                          @blur="inputFlexTime(tableData, employee)"
                        />
                      </div>
                    </td>
                    <!-- 状態-->
                    <td :key="componentKey2">
                      <div class="error-message-wrapper" style="transform: translate(0px, 17px);" v-show="employee.error_shift_reflection_flg !== undefined && employee.error_shift_reflection_flg !== ''">
                        <small class="text-danger">
                          &nbsp;&nbsp;{{ employee.error_shift_reflection_flg }}
                        </small>
                      </div>
                      <div style="text-align: center;">
                        <ValidationSelect
                          v-model="employee.shift_reflection_flg"
                          item-name="shift_reflection_flg"
                          placeholder=""
                          classes="numLength9"
                          :options="comboData.shift_reflection_flg_list"
                          :disabled="isReadOnly || employee.delete || !employee.can_change_record || employee.work_status !== 1"
                          @change="shiftReflectionFlgChanged(tableData, employee)"
                        />
                      </div>
                    </td>
                    <!-- メッセージ-->
                    <td style="border-right:none;" :key="componentKey3">
                      <div>
                        <!-- <template v-if="employee.message_list.length !== 0 && employee.message_list[employee.message_list.length - 1].employee_name !== loginUserName">
                          {{employee.message_list[employee.message_list.length - 1].message}}
                        </template> -->
                        <input type="checkbox" v-model="employee.request_submission_flg" class="gray" :id="`requestSubmissionFlg-${index}`" :disabled="isReadOnly || employee.delete || !employee.can_change_record || employee.shift_reflection_flg !== '3'"/>
                        <label :for="`requestSubmissionFlg-${index}`" :class="isReadOnly || employee.delete || !employee.can_change_record || employee.shift_reflection_flg !== '3' ? 'requestSubmissionFlg2' : 'requestSubmissionFlg'">{{$t('label.request_submission_flg')}}</label>
                        <ValidationTextArea
                          v-model="employee.message"
                          item-name="message"
                          max-length="255"
                          classes="form-control"
                          :disabled="isReadOnly || employee.delete || !employee.can_change_record"
                        />
                      </div>
                    </td>
                    <!-- メッセージボタン-->
                    <td style="border-left:none; border-right:none;">
                      <div style="text-align: center;">
                        <template v-if="employee.message_list.length !== 0">
                          <a class="btn btn-simple btn-icon edit" @click="showMessageView(tableIndex, index, employee, employee.message_list, employee.message, isReadOnly || employee.delete || !employee.can_change_record)"><i class="ti-comment-alt" title="メッセージ詳細"></i></a>
                        </template>
                      </div>
                    </td>
                    <!-- 未読 -->
                    <td style="border-left:none;">
                      <template v-if="employee.is_read === 2">
                        <p class="akamaru"></p>
                      </template>
                    </td>
                    <!-- 確定日-->
                    <td :key="componentKey4">
                      <div style="text-align: center;">
                        <template v-if="employee.fixed_date !== ''">
                          {{ formatDateWith(employee.fixed_date, 'YYYY/M/D') }}<br>
                          {{ formatDateWith(employee.fixed_date, 'H:mm:ss') }}
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ShiftDecisionDetailMixin from '@/components/Dashboard/Views/Monthly/ShiftDecisionDetailMixin'

  export default {
    name: 'ShiftDecisionDetail',
    mixins: [ShiftDecisionDetailMixin]
  }
</script>
<style scoped>
.akamaru {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #e82a2a;
  transform: translate(-9px,0px);
}
table td {
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
table th {
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid rgb(221, 221, 221);
  background-color: #EEE;
  color: #666;
  text-align: center;
  font-weight: bold;
}
.requestSubmissionFlg {
  color: #666;
  font-weight: bold;
}
.requestSubmissionFlg2 {
  color: #777;
  font-weight:100;
}
</style>