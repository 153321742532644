<template>
  <div class="form-group">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel1 + '|' + new Date().getTime().toString(16)">
      <label>{{itemLabel1 ? itemLabel1 : ''}}</label>
      <div style="margin-bottom: 10px;">
        <el-select :class="classes"
                    size="large"
                    :disabled="disabled"
                    v-model="copyFrom">
          <el-option v-for="option in options"
                      class="select-success"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
          </el-option>
        </el-select>
        <template v-if="!disabled">
          <button type="button" class="btn btn-sm btn-default btn-primary" @click="$emit('copyApplicationControl')">{{$t('label.all_employee_application_control')}}</button>
        </template>
      </div>
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"        border
        style="width: 601px;"
        :data="filteredData"
        :empty-text="$t('label.no_data')">
        <el-table-column
          width="200"
          :label="itemLabel1 ? itemLabel1 : ''"
          align="center">
          <template slot-scope="props">
            <div style="text-align: left; height: 40px; line-height: 350%;">
              {{props.row.application_form_name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="400"
          :label="itemLabel2 ? itemLabel2 : ''"
          align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <template v-if="disableApplicationForms.includes(props.row.application_form_code)">
                {{ options.find((row) => row.value === props.row.application_control).label }}
              </template>
              <template v-else>
                <el-select :class="classes"
                            size="large"
                            :disabled="disabled"
                            @change="applicationControlChanged(props.row)"
                            v-model="props.row.application_control">
                  <el-option v-for="option in options"
                              class="select-success"
                              :value="option.value"
                              :label="option.label"
                              :key="option.value">
                  </el-option>
                </el-select>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </ValidationProvider>
  </div>
</template>

<script>
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      options: Array,
      rules: String,
      itemLabel1: String,
      itemLabel2: String,
      classes: String,
      disabled: Boolean
    },
    computed: {
      filteredData () {
        return !this.tableData ? [] : this.tableData.filter(e => e.application_form_code !== '0000000017' && e.application_form_code !== '0000000021')
      }
    },
    data () {
      return {
        tableData: [],
        copyFrom: '3'
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        for (const row of this.tableData) {
          if (this.disableApplicationForms.includes(row.application_form_code)) {
            row.application_control = '1'
          }
        }
      }
    },
    mounted () {
      this.tableData = this.value
      for (const row of this.tableData) {
        if (this.disableApplicationForms.includes(row.application_form_code)) {
          row.application_control = '1'
        }
      }
    },
    methods: {
      applicationControlChanged (row) {
        if (row.application_form_code === '0000000016' && row.application_control === '2') {
          swal({
            html: this.$t('message.attendance_application_change_warning'),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            for (const data of this.tableData) {
              if (data.application_form_code === '0000000004' || // 深夜
                data.application_form_code === '0000000007' || // 休日出勤
                data.application_form_code === '0000000002' || // 残業
                data.application_form_code === '0000000005' || // 遅刻
                data.application_form_code === '0000000006' // 早退
              ) {
                data.application_control = '2'
              }
            }
          }).catch(() => {})
        }
        this.$emit('input', this.tableData)
      },
      copyApplicationControl () {
        if (this.copyFrom) {
          for (const row of this.tableData) {
            if (!this.disableApplicationForms.includes(row.application_form_code)) {
              row.application_control = this.copyFrom
            }
          }
        }
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>