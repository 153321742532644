import mixin from '@/lib/mixin'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'

export default {
  name: 'LegalRuleSurveillanceWarningDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect
  },
  props: {
    mode: Number,
    params: Object,
    record: Array,
    legal_rule_url: String,
    labels: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        notice_number: '',
        notification: '',
        contents: '',
        notification_transmission_date: '',
        notification_display_date_from: '',
        notification_display_date_to: '',
        notice_type: ''
      },
      comboData: {},
      showSearchCondition: true,
      office_code: '',
      business_type: '',
      search_flg: false,
      limit_legal_one_month_minutes: '0:00',
      limit_legal_one_month_minutes_rate: '0',
      limit_legal_one_month_minutes_all: '0:00',
      limit_legal_one_month_minutes_all_rate: '0',
      limit_month_over_industrial_safety_health_act: '0:00',
      limit_month_over_industrial_safety_health_act_rate: '0',
      limit_legal_one_year_minutes: '0:00',
      limit_legal_one_year_minutes_rate: '0',
      limit_job_one_year_minutes: '0:00',
      limit_job_one_year_minutes_rate: '0',
      number_of_legal_holidays_allowed_to_work: '0',
      number_of_legal_holidays_allowed_to_work_rate: '0',
      special_provisions_year_count: '0',
      special_provisions_year_count_rate: '0',
      limit_special_provisions_legal_one_month_minutes_all: '0:00',
      limit_special_provisions_legal_one_month_minutes_all_rate: '0'
    }
  },
  computed: {
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    individualRouteCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    submit () {
      let data = {
        screen_code: 'SS-015',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.office_code,
          business_type: this.business_type,
          limit_legal_one_month_minutes: this.getMinutes(this.limit_legal_one_month_minutes),
          limit_legal_one_month_minutes_rate: this.getRate(this.limit_legal_one_month_minutes_rate),
          limit_legal_one_month_minutes_all: this.getMinutes(this.limit_legal_one_month_minutes_all),
          limit_legal_one_month_minutes_all_rate: this.getRate(this.limit_legal_one_month_minutes_all_rate),
          limit_month_over_industrial_safety_health_act: this.getMinutes(this.limit_month_over_industrial_safety_health_act),
          limit_month_over_industrial_safety_health_act_rate: this.getRate(this.limit_month_over_industrial_safety_health_act_rate),
          limit_legal_one_year_minutes: this.getMinutes(this.limit_legal_one_year_minutes),
          limit_legal_one_year_minutes_rate: this.getRate(this.limit_legal_one_year_minutes_rate),
          limit_job_one_year_minutes: this.getMinutes(this.limit_job_one_year_minutes),
          limit_job_one_year_minutes_rate: this.getRate(this.limit_job_one_year_minutes_rate),
          number_of_legal_holidays_allowed_to_work: Number(this.number_of_legal_holidays_allowed_to_work),
          number_of_legal_holidays_allowed_to_work_rate: this.getRate(this.number_of_legal_holidays_allowed_to_work_rate),
          limit_special_provisions_legal_one_month_minutes_all: this.getMinutes(this.limit_special_provisions_legal_one_month_minutes_all),
          limit_special_provisions_legal_one_month_minutes_all_rate: this.getRate(this.limit_special_provisions_legal_one_month_minutes_all_rate),
          special_provisions_year_count: Number(this.special_provisions_year_count),
          special_provisions_year_count_rate: this.getRate(this.special_provisions_year_count_rate)
        }
      }
      this.send('/update/legal_rule_alert', data)
      .then(res => {
      })
    },
    search () {
      let data = {
        screen_code: 'SS-015',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.office_code,
          business_type: this.business_type
        }
      }
      this.send('/view/legal_rule_alert', data)
      .then(res => {
        if (res.api_area.limit_legal_one_month_minutes !== null) {
          this.limit_legal_one_month_minutes = this.getHour(res.api_area.limit_legal_one_month_minutes)
          this.limit_legal_one_month_minutes_rate = this.getDispRate(res.api_area.limit_legal_one_month_minutes_rate)
          this.limit_legal_one_month_minutes_all = this.getHour(res.api_area.limit_legal_one_month_minutes_all)
          this.limit_legal_one_month_minutes_all_rate = this.getDispRate(res.api_area.limit_legal_one_month_minutes_all_rate)
          this.limit_month_over_industrial_safety_health_act = this.getHour(res.api_area.limit_month_over_industrial_safety_health_act)
          this.limit_month_over_industrial_safety_health_act_rate = this.getDispRate(res.api_area.limit_month_over_industrial_safety_health_act_rate)
          this.limit_legal_one_year_minutes = this.getHour(res.api_area.limit_legal_one_year_minutes)
          this.limit_legal_one_year_minutes_rate = this.getDispRate(res.api_area.limit_legal_one_year_minutes_rate)
          this.limit_job_one_year_minutes = this.getHour(res.api_area.limit_job_one_year_minutes)
          this.limit_job_one_year_minutes_rate = this.getDispRate(res.api_area.limit_job_one_year_minutes_rate)
          this.number_of_legal_holidays_allowed_to_work = res.api_area.number_of_legal_holidays_allowed_to_work
          this.number_of_legal_holidays_allowed_to_work_rate = this.getDispRate(res.api_area.number_of_legal_holidays_allowed_to_work_rate)
          this.special_provisions_year_count = res.api_area.special_provisions_year_count
          this.special_provisions_year_count_rate = this.getDispRate(res.api_area.special_provisions_year_count_rate)
          this.limit_special_provisions_legal_one_month_minutes_all = this.getHour(res.api_area.limit_special_provisions_legal_one_month_minutes_all)
          this.limit_special_provisions_legal_one_month_minutes_all_rate = this.getDispRate(res.api_area.limit_special_provisions_legal_one_month_minutes_all_rate)
        }
        this.search_flg = true
      })
    },
    init () {
      // let data = {
      //   screen_code: this.screenCode,
      //   company_code: this.$store.state.loginUser.companyCode,
      //   group_code: this.$store.state.loginUser.groupCode,
      //   employee_code: this.$store.state.loginUser.employeeCode,
      //   api_area: {}
      // }
      // this.send('/init/legal_rule_alert', data)
      // .then(res => {
      //   this.labels = this.getLabels(res.common_area)
      //   this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
      //   if (res.api_area) {
      //     for (const entry of Object.entries(res.api_area)) {
      //       if (typeof entry[1] === 'number') {
      //         this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
      //       } else if (typeof this.detail[entry[0]] === 'string') {
      //         this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
      //       } else {
      //         this.detail[entry[0]] = entry[1]
      //       }
      //     }
      //   }
      //   this.afterInit(res)
      //   this.componentKey += 1
      // })
      // this.componentKey += 1
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 === 1) {
        if (columnIndex === 0) {
          return [1, 4]
        } else if (columnIndex === 1) {
          return [0, 0]
        }
      }
    },
    getTitle (record) {
      let result = record[0].column1
      result = result + ' '
      result = result + record[0].column4
      return result
    },
    getRegulationAndActual (record) {
      let result = ' [' + this.$t('label.rules') + ']'
      result = result + record[0].column2
      result = result + ' [' + this.$t('label.achievement') + ']'
      result = result + record[0].column3
      return result
    },
    getMinutes (hour) {
      let splitHour = hour.split(':')
      return Number(splitHour[0]) * 60 + Number(splitHour[1])
    },
    getHour (minute) {
      let hour = parseInt(minute / 60, 10)
      minute = minute % 60
      let strMinute = ''
      if (minute <= 9) {
        strMinute = '0' + String(minute)
      } else {
        strMinute = String(minute)
      }
      return hour + ':' + strMinute
    },
    getRate (rate) {
      return Number(rate) / 100
    },
    getDispRate (rate) {
      return parseInt(rate * 100, 10)
    },
    headerClicked (oneRecord) {
      if (this.record.length === 1) return
      oneRecord.showDetail = !oneRecord.showDetail
      this.componentKey += 1
    }
  },
  created () {
    this.init()
    if (this.record.length === 1) {
      this.record[0].showDetail = true
    }
  }
}
