import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'

export default {
  name: 'TaskDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        task_name: '',
        message: '',
        messages: []
      },
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '03-012'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doSend () {
      const db = this.$firebase.firestore()
      db.collection('companies')
      .doc(this.$store.state.loginUser.companyCode)
      .collection('rooms')
      .doc(this.params.id)
      .collection('messages')
      .add({
        message: this.detail.message,
        name: this.$store.state.loginUser.employeeName,
        time: this.$firebase.firestore.Timestamp.now()
      })
    },
    doAdd () {
      const db = this.$firebase.firestore()
      db.collection('companies')
      .doc(this.$store.state.loginUser.companyCode)
      .collection('rooms')
      .add({
        name: this.detail.task_name
      })
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
    },
    init () {
      this.labels = {
        entry: 1,
        update: 1,
        delete: 1,
        task_name: 'ルーム名',
        message: 'メッセージ'
      }
      this.detail.task_name = this.params.task_name

      if (this.mode === this.modes.add) return

      const self = this
      const db = this.$firebase.firestore()
      const query = db.collection('companies')
      .doc(this.$store.state.loginUser.companyCode)
      .collection('rooms')
      .doc(this.params.id)
      .collection('messages')
      .orderBy('time')

      query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data()
          if (change.type === 'added') {
            self.detail.messages.unshift({
              id: change.doc.id,
              message: data.message,
              name: data.name,
              time: data.time.toDate()
            })
          } else if (change.type === 'modified') {
            for (const message of self.detail.messsages) {
              if (message.id === change.doc.id) {
                message.message = data.message
                message.name = data.name
                message.time = data.time
                break
              }
            }
          } else if (change.type === 'removed') {
            for (const message of self.detail.messsages) {
              if (message.id === change.doc.id) {
                self.detail.messages.remove(message)
                break
              }
            }
          }
        })
      })
    }
  },
  created () {
    this.init()
  }
}
