<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.shift_pattern_name"
              item-name="shift_pattern_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.shift_pattern_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.shift_pattern_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.mon"
              item-name="mon"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.mon')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.tue"
              item-name="tue"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.tue')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.wed"
              item-name="wed"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.wed')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.thu"
              item-name="thu"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.thu')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.fri"
              item-name="fri"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.fri')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.sat"
              item-name="sat"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.sat')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationStartEndTimeHoliday
              v-model="detail.sun"
              item-name="sun"
              max-length="5"
              classes="form-control length5"
              select-classes="length10"
              :options="comboData.work_schedule_detail_list"
              rules="isStartTime|isEndTime|isStartEndTime|isStartEndTimeWorkSchedule"
              :item-label="$t('label.sun')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :placeholder3="isReadOnly ? '' : $t('label.select_description')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ShiftPatternDetailMixin from '@/components/Dashboard/Views/Generated/ShiftPatternDetailMixin'

  export default {
    name: 'ShiftPatternDetail',
    methods: {
      afterInit (res) {
        const now = this.formatToday()
        const workScheduleList = [{label: '', value: ''}]
        for (const row of res.common_area.combo_data.work_schedule_detail_list) {
          if (this.compareDate(row.term_from, now) <= 0) {
            if (!row.term_to || this.compareDate(row.term_to, now) >= 0) {
              workScheduleList.push(row)
            }
          }
        }
        this.comboData.work_schedule_detail_list = workScheduleList
        this.labels.vueLabel_mon = this.$t('label.monday_ryaku')
        this.labels.vueLabel_tue = this.$t('label.tuesday_ryaku')
        this.labels.vueLabel_wed = this.$t('label.wednesday_ryaku')
        this.labels.vueLabel_thu = this.$t('label.thursday_ryaku')
        this.labels.vueLabel_fri = this.$t('label.friday_ryaku')
        this.labels.vueLabel_sat = this.$t('label.saturday_ryaku')
        this.labels.vueLabel_sun = this.$t('label.sunday_ryaku')
        this.setVueLanguage(this.labels)
        this.detail.mon = this.getWeekday(res.api_area.job_start_mon, res.api_area.job_end_mon, res.api_area.work_status_mon, res.api_area.work_schedule_code_mon)
        this.detail.tue = this.getWeekday(res.api_area.job_start_tue, res.api_area.job_end_tue, res.api_area.work_status_tue, res.api_area.work_schedule_code_tue)
        this.detail.wed = this.getWeekday(res.api_area.job_start_wed, res.api_area.job_end_wed, res.api_area.work_status_wed, res.api_area.work_schedule_code_wed)
        this.detail.thu = this.getWeekday(res.api_area.job_start_thu, res.api_area.job_end_thu, res.api_area.work_status_thu, res.api_area.work_schedule_code_thu)
        this.detail.fri = this.getWeekday(res.api_area.job_start_fri, res.api_area.job_end_fri, res.api_area.work_status_fri, res.api_area.work_schedule_code_fri)
        this.detail.sat = this.getWeekday(res.api_area.job_start_sat, res.api_area.job_end_sat, res.api_area.work_status_sat, res.api_area.work_schedule_code_sat)
        this.detail.sun = this.getWeekday(res.api_area.job_start_sun, res.api_area.job_end_sun, res.api_area.work_status_sun, res.api_area.work_schedule_code_sun)
      },
      getWeekday (jobStart, jobEnd, workStatus, workScheduleCode) {
        const jobEnd24 = this.to24Time(jobEnd)
        return [
          jobStart,
          jobEnd24,
          jobEnd !== jobEnd24,
          workStatus === 2,
          workScheduleCode && workScheduleCode.includes('_SHIFT_') ? undefined : workScheduleCode,
          workStatus === 3
        ]
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          if (this.mode === this.modes.add) {
            delete detail.shift_pattern_code
          }
          detail.job_start_mon = detail.mon[0] || ''
          detail.job_end_mon = !detail.mon[1] ? '' : this.to48Time(('0' + detail.mon[1]).slice(-5), detail.mon[2])
          detail.work_status_mon = detail.mon[3] ? 2 : detail.mon[5] ? 3 : 1
          detail.work_schedule_code_mon = detail.mon[4] || ''
          delete detail.mon
          detail.job_start_tue = detail.tue[0] || ''
          detail.job_end_tue = !detail.tue[1] ? '' : this.to48Time(('0' + detail.tue[1]).slice(-5), detail.tue[2])
          detail.work_status_tue = detail.tue[3] ? 2 : detail.tue[5] ? 3 : 1
          detail.work_schedule_code_tue = detail.tue[4] || ''
          delete detail.tue
          detail.job_start_wed = detail.wed[0] || ''
          detail.job_end_wed = !detail.wed[1] ? '' : this.to48Time(('0' + detail.wed[1]).slice(-5), detail.wed[2])
          detail.work_status_wed = detail.wed[3] ? 2 : detail.wed[5] ? 3 : 1
          detail.work_schedule_code_wed = detail.wed[4] || ''
          delete detail.wed
          detail.job_start_thu = detail.thu[0] || ''
          detail.job_end_thu = !detail.thu[1] ? '' : this.to48Time(('0' + detail.thu[1]).slice(-5), detail.thu[2])
          detail.work_status_thu = detail.thu[3] ? 2 : detail.thu[5] ? 3 : 1
          detail.work_schedule_code_thu = detail.thu[4] || ''
          delete detail.thu
          detail.job_start_fri = detail.fri[0] || ''
          detail.job_end_fri = !detail.fri[1] ? '' : this.to48Time(('0' + detail.fri[1]).slice(-5), detail.fri[2])
          detail.work_status_fri = detail.fri[3] ? 2 : detail.fri[5] ? 3 : 1
          detail.work_schedule_code_fri = detail.fri[4] || ''
          delete detail.fri
          detail.job_start_sat = detail.sat[0] || ''
          detail.job_end_sat = !detail.sat[1] ? '' : this.to48Time(('0' + detail.sat[1]).slice(-5), detail.sat[2])
          detail.work_status_sat = detail.sat[3] ? 2 : detail.sat[5] ? 3 : 1
          detail.work_schedule_code_sat = detail.sat[4] || ''
          delete detail.sat
          detail.job_start_sun = detail.sun[0] || ''
          detail.job_end_sun = !detail.sun[1] ? '' : this.to48Time(('0' + detail.sun[1]).slice(-5), detail.sun[2])
          detail.work_status_sun = detail.sun[3] ? 2 : detail.sun[5] ? 3 : 1
          detail.work_schedule_code_sun = detail.sun[4] || ''
          delete detail.sun
        }
        return detail
      }
    },
    mixins: [ShiftPatternDetailMixin]
  }
</script>
<style scoped>
</style>
