<template>
  <div class="dialog-frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" :key="componentKey">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card" style="height: 140px; vertical-align:bottom;">
        <!-- 有給休暇 -->
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
        </div>
        <div class="card-content">
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <div class="col-sm-3" style="text-align: left; font-size: medium; font-weight: bold; color: #666;">
            {{employee_code}}
            <br>
            {{employee_name}}
          </div>
          <div class="col-sm-3" style="text-align: center; font-size: medium; font-weight: bold; color: #666;">
            <!-- 次回有給付与日 -->
            {{labels.label_paid_reference_date}}
            <br>
            <template v-if="paid_reference_date">
              {{formatDate(paid_reference_date)}}
            </template>
          </div>
          <div class="col-sm-3" style="text-align: right; font-size: medium; font-weight: bold; color: #666;">
            <!-- 次回付与日数 -->
            {{labels.label_grant_days}}
            <br>
            {{grant_days}}
            {{labels.label_day}}
          </div>
          <div class="col-sm-3" style="text-align: right; font-size: medium; font-weight: bold; color: #666;">
            <!-- 次回予定勤続月数 -->
            {{labels.label_estimated_months_of_service}}
            <br>
            {{estimated_months_of_service}}
            {{labels.label_months}}
          </div>
        </div>
      </div>
      <div class="card row" ref="frame">
        <div class="card-content">
          <div class="paid-history-title" @click="showPaidHistory = !showPaidHistory">
            <div style="display: inline-block; color: #666; width: 49%;" class="strong-text">
              {{$t('label.paid_history')}}
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showPaidHistory">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="col-sm-12" style="margin-bottom: 20px;" v-if="showPaidHistory">
            <!-- テーブル表示(上部) -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData2"
                      style="width: 100%">
              <!-- 支給年月日 -->
              <template>
                <el-table-column min-width="45" :label="labels.payment_date_ryaku === undefined ? '' : labels.payment_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div class="strong-text-center">
                      {{formatDate(props.row.payment_date)}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 支給日数 -->
              <el-table-column min-width="35" :label="labels.payment_days_ryaku === undefined ? '' : labels.payment_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{editHyphen(props.row.payment_days) + $t('label.unit_day')}}
                    {{formatDispMinuteWithUnitForHours(props.row.payment_times)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 支給理由 -->
              <template>
                <el-table-column min-width="60" :label="labels.payment_reason_ryaku === undefined ? '' : labels.payment_reason_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{editHyphen(getLabel(getSelectName(props.row.payment_reason, comboData.paid_leave_reason_list)))}}
                  </template>
                </el-table-column>
              </template>
              <!-- 失効年月日 -->
              <template>
                <el-table-column min-width="45" :label="labels.expiration_date_ryaku === undefined ? '' : labels.expiration_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div class="strong-text-center">
                      {{editHyphen(formatDate(props.row.expiration_date))}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 失効日数 -->
              <template>
                <el-table-column min-width="35" :label="labels.expiration_days_ryaku === undefined ? '' : labels.expiration_days_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{props.row.expiration_days + $t('label.unit_day')}}
                      {{formatDispMinuteWithUnitForHours(props.row.expiration_times)}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 失効理由 -->
              <template>
                <el-table-column min-width="60" :label="labels.expiration_reason_ryaku === undefined ? '' : labels.expiration_reason_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{editHyphen(getLabel(getSelectName(props.row.expiration_reason, comboData.paid_leave_reason_list)))}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 有給保持日数 -->
              <template>
                <el-table-column min-width="50" :label="labels.hold_days_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div class="strong-text-right">
                      {{props.row.hold_days + $t('label.unit_day')}}
                      {{formatDispMinuteWithUnitForHours(props.row.hold_times)}}
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="col-sm-12">
            <!-- テーブル表示(下部) -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData">
              <!-- 取得開始日 -->
              <template>
                <!-- 項目名はTranslationsJPから取得 -->
                <el-table-column min-width="150" :label="$t('label.paid_holiday_date')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div class="strong-text">
                      {{formatDate(props.row.term_from)}}
                      <span v-if="props.row.term_from !== props.row.term_to">
                        {{'-' + formatDate(props.row.term_to)}}
                      </span>
                    </div>
                    <div style="text-align: left;" v-if="props.row.get_days > 0">
                      <small>{{editHyphen(props.row.get_days) + $t('label.unit_day')}}</small>
                    </div>
                    <div style="text-align: left;" v-if="props.row.term_time_from !== props.row.term_time_to">
                      <small>{{formatDispTimeRange(props.row.term_time_from, props.row.term_time_to, props.row.get_times)}}</small>
                    </div>
                    <div style="text-align: left; line-height: 100%;" v-if="labels.preview === 1">
                      <small>
                        {{ props.row.application_number ? labels.application_number_ryaku + ': ' : '' }}
                        <a 
                          class="CompleteStyle" 
                          @click="$emit('showWorkflow', props.row, employee_code)" 
                          :disabled="false" :style="false ? 'pointer-events: none;' : 'cursor: pointer;'">
                          {{props.row.application_number ? editHyphen(props.row.application_number) : ''}}
                        </a>
                      </small>
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 有給休暇の種類 -->
              <template>
                <el-table-column min-width="110" :label="labels.paid_holiday_type_ryaku === undefined ? '' : labels.paid_holiday_type_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{editHyphen(getLabel(getSelectName(props.row.paid_holiday_type, comboData.paid_holiday_type_list)))}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 補足説明 -->
              <el-table-column min-width="300" :label="labels.contents_ryaku === undefined ? '' : labels.contents_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; white-space: normal;">
                    {{editHyphen(props.row.contents)}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'

  export default {
    mixins: [mixin],
    components: {
      ValidationText
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        componentKey: 0,
        id: 0,
        employee_code: '',
        employee_name: '',
        estimated_months_of_service: '',
        paid_reference_date: '',
        grant_days: '',
        tableData: undefined,
        tableData2: undefined,
        paid_leave_data: {},
        labels: {},
        comboData: {},
        applicationFormCode: '',
        applyRecord: {},
        showPaidHistory: false
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
      },
      isPrimaryKeyReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete
      }
    },
    methods: {
      // イベントを処理する場合はカスタマイズエリアに実装してください。
      // 初期処理カスタマイズ用
      afterInit (res) {
      },
      // 独自バリデーション用
      customValidate () { return true },
      // 送信データ調整用
      convertDetail (detail) { return detail },
      doClose () {
        this.$emit('close')
      },
      editHyphen (num) {
        // 引数が空の場合、有給休暇
        if (num === '' || num === null || num === 'Invalid date') {
          return '-'
        } else {
          return num
        }
      },
      editDispTime (strHour) {
        // 引数が空の場合、ブランクを返す。空でない場合、桁数を調整する。
        if (strHour === '' || strHour === null) {
          return ''
        } else {
          // 一文字目が'0'の場合、一文字目を削る。
          if (strHour.slice(0, 1) === '0') {
            return strHour.slice(1, 5)
          } else {
            return strHour
          }
        }
      },
      editDispHourOrMinutes (strTime) {
        // 引数が空の場合、ブランクを返す。空でない場合、桁数を調整する。
        if (strTime === '' || strTime === null) {
          return ''
        } else {
          // 引数が30以上の場合は分と判断し、時間に変換して返却する。
          if (strTime >= 30) {
            return strTime / 60
          // それ以外は時間と判断し、そのまま返却する。
          } else {
            return strTime
          }
        }
      },
      init () {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: this.params.employee_code
          }
        }
        this.send('/init/paid_leave', data)
        .then(res => {
          this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.paid_leave_employee_list
          this.tableData2 = Array.isArray(res.api_area) ? res.api_area : res.api_area.paid_annual_account_employee_list
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          this.employee_code = this.params.employee_code
          this.employee_name = this.params.employee_name
          this.estimated_months_of_service = res.api_area.estimated_months_of_service
          this.paid_reference_date = res.api_area.paid_reference_date
          this.grant_days = res.api_area.grant_days
          // テーブル用の名前
          this.labels.label_paid_reference_date = this.$t('label.label_paid_reference_date')
          this.labels.label_estimated_months_of_service = this.$t('label.label_estimated_months_of_service')
          this.labels.label_day = this.$t('label.day')
          this.labels.label_months = this.$t('label.months')
          this.labels.label_grant_days = this.$t('label.label_grant_days')
          this.labels.label_term_from = this.$t('label.label_term_from')
          this.labels.label_term_to = this.$t('label.label_term_to')
          this.labels.label_term_time_from = this.$t('label.label_term_time_from')
          this.labels.label_term_time_to = this.$t('label.label_term_time_to')
          // テーブル作成用
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
          this.afterInit(res)
          this.$nextTick(() => {
            if (window.innerHeight - this.$refs.frame.clientHeight < 320) {
              this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 270px);'
            }
          })
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
.table-scroll {
  overflow-y:scroll
}
.table-height {
  height: 490px;
}
.table-height2 {
  height: 130px;
}
.CompleteStyle {
  padding: 0 !important; 
  color: black !important; 
  text-decoration: underline;    
}
</style>
