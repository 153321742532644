import { render, staticRenderFns } from "./Invoice.vue?vue&type=template&id=a3f5ed38&scoped=true"
import script from "./Invoice.vue?vue&type=script&lang=js"
export * from "./Invoice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f5ed38",
  null
  
)

export default component.exports