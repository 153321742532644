<template>
  <div>
    <div class="card">
      <div class="card-header">
        <b>{{routeType === '1' ? labels.individualRoute : routeType === '2' ? labels.commonRoute : labels.routeHistory}}</b>
      </div>
      <div class="card-content">
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                  :data="route"
                  :empty-text="$t('label.no_data')">
          <el-table-column width="30" label="" align="center">
            <template slot-scope="props">
              <template v-if="props.row.applicant_employee_code !== ''">
                <div style="text-align: center;">
                  <span class="ti-minus"></span>
                </div>
              </template>
              <template v-else-if="props.row.process_date">
                <div style="text-align: center;">
                  <span class="ti-check"></span>
                </div>
              </template>
              <template v-else-if="props.row.arrival_date">
                <div style="text-align: center;">
                  <span class="ti-control-play"></span>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column width="40" :label="$t('label.order')" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.route_order}}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80" :label="$t('label.status')" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.status_label}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" :label="labels.company ? labels.company : '会社'" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>{{props.row.company_code}}</small></div>
                <div><span>{{props.row.company_name}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" :label="labels.group ? labels.group : ''" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>{{props.row.group_code}}</small></div>
                <div><span>{{props.row.group_name}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="105" :label="route && route[0] ? route[0].applicant_employee_code === route[0].target_employee_code ? $t('label.target_person') : $t('label.deputy_target_person') : ''" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <template v-if="route && route[0] && route[0].applicant_employee_code !== route[0].target_employee_code">
                  <div><span>{{props.row.target_employee_name}}</span></div>
                  <div><span>{{'(' + props.row.applicant_employee_name + ')'}}</span></div>
                </template>
                <template v-else>
                  <div><small>{{props.row.target_employee_code}}</small></div>
                  <div><span>{{props.row.target_employee_name}}</span></div>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" :label="labels.apply_date ? labels.apply_date : ''" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>&nbsp;</small></div>
                <div><span>{{props.row.apply_date}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="105" :label="labels.approverl_employee_role ? labels.approverl_employee_role : '承認者 / ロール'" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>{{props.row.employee_code ? props.row.employee_code : '&nbsp;'}}</small></div>
                <div><span>{{props.row.employee_name ? props.row.employee_name : '&nbsp;'}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" :label="labels.reaching_date ? labels.reaching_date : ''" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>&nbsp;</small></div>
                <div><span>{{props.row.arrival_date}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="130" :label="labels.process_date ? labels.process_date : ''" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div><small>&nbsp;</small></div>
                <div><span>{{props.row.process_date}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="65" :label="labels.authority ? labels.authority : '権限'" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.auth_name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="65" :label="$t('label.comment')" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <a
                  class="btn btn-simple btn-info"
                  style="padding: 0; color: blue; text-decoration: underline;"
                  @click="$emit('showComment', props.row)"
                >{{props.row.label_comment_exist}}</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    props: {
      routeType: String,
      value: Array,
      labels: Object
    },
    data () {
      return {
        selectedComment: '',
        route: []
      }
    },
    watch: {
      value () {
        this.route = this.value
      }
    },
    mounted () {
      this.route = this.value
    }
  }
</script>
<style scoped>
  .el-table .cell {
    white-space: nowrap !important;
  }  
  .errorBorder {
    border: 1px solid red;
  }
  .errorSelectBorder.el-select .el-input input {
    border-color: red !important;
  }
</style>
