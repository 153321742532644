<template>
  <div>
    <Loading
      :message="description"
      :loading="loading"
      :show-button2="!loading"
    />
  </div>
</template>
<script>
  import Loading from '@/components/Dashboard/Views/Components/Loading'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Loading
    },
    data () {
      return {
        loading: true,
        description: 'しばらくお待ちください。'
      }
    },
    created () {
      const corpusCompanyCode = this.$route.query.company_code ? this.$route.query.company_code : '37439408'
      const corpusCompanyName = this.$route.query.company_name ? this.$route.query.company_name : '株式会社PocketSoft'
      const mailAddress = this.$route.query.mail_address ? this.$route.query.mail_address : ''
      const customerId = this.$route.query.customer_id ? this.$route.query.customer_id : ''
      sessionStorage.setItem('corpusCompanyCode', corpusCompanyCode)
      sessionStorage.setItem('corpusCompanyName', corpusCompanyName)
      this.$i18n.locale = 'ja'
      localStorage.setItem('language', this.$i18n.locale)
      if (!mailAddress) {
        this.$router.push({name: 'newCompany'})
        return
      }
      const request = {
        mail_address: mailAddress,
        customer_id: customerId,
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/start`, request)
      .then(res => {
        if (res.api_area.check_result) {
          let params = {
            screen_code: '123',
            company_code: corpusCompanyCode,
            employee_code: 'admin'
          }
          this.send('/migration/init/mg_start', params)
          .then(res => {
            localStorage.setItem('salaryExist', res.init_data.salary_exist)
            if (mailAddress) {
              localStorage.setItem('migrationMailAddress', mailAddress)
              if (customerId) {
                localStorage.setItem('customerId', customerId)
              }
              let self = this
              this.$firebase.auth().signInWithEmailAndPassword(process.env.VUE_APP_FIREBASE_USER, process.env.VUE_APP_FIREBASE_PASSWORD)
              .then(userInfo => {
                const httpsReference = this.$firebase.storage()
                  .ref()
                  .child(`migration/${sessionStorage.getItem('corpusCompanyCode')}.json`)
                httpsReference.getDownloadURL().then(function (url) {
                  // 一時保存データあり
                  self.$axios.get(url).then(res => {
                    sessionStorage.setItem('migrationData', JSON.stringify(res.data))
                  }).catch(function (error) {
                    console.error(error)
                  })
                  self.$router.push({name: 'wizardForm'})
                }).catch(function (error) {
                  if (error.code === 'storage/object-not-found') {
                    // 一時保存データなし
                    self.$router.push({name: 'agreement'})
                  } else {
                    // エラー
                    console.log(error)
                  }
                })
              })
            } else {
              this.$router.push({name: 'newCompany'})
            }
          })
        } else {
          this.loading = false
          this.description = '支払い情報が見つかりませんでした。先に支払い情報を登録してください。'
        }
      }).catch(() => {
        this.loading = false
        this.description = '支払い情報が見つかりませんでした。先に支払い情報を登録してください。'
      })
    }
  }
</script>
<style scoped>
  .card {
    background: #eee !important;
  }
  .full-page:after {
    background: white !important;
  }
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #999999;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
