import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import RoleAllocationTable from '@/components/UIComponents/Inputs/EmployeeRoleAllocationTable'

export default {
  name: 'GroupRoleAllocationDetailMixin',
  mixins: [mixin],
  components: {
    RoleAllocationTable
  },
  props: {
    mode: Number,
    params: Object,
    selectRoles: Array,
    roleList: Array,
    groupSelectNumber: Number,
    payrollManagement: String,
    attendanceManagement: String,
    labels: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        allocation: []
      },
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '03-016'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    doClose () {
      let resultRole = []
      for (const data of this.detail.allocation) {
        if (data.selected) {
          resultRole.push(data.role_code)
        }
      }
      this.$emit('close', resultRole, this.groupSelectNumber)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {employee_role_allocation_code: this.detail.employee_role_allocation_code}
          detail.employee_role_allocation_name = this.detail.employee_role_allocation_name
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.nullToBlank(this.convertDetail(detail))
        }
        this.send(`/${operation}/employee_role_allocation/list`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      this.afterInit()
    }
  },
  created () {
    this.init()
  }
}
