<template>
  <div>
    <div style="width: 100%; overflow: hidden;" :key="componentKey" v-if="initialized">
      <div>
        <div style="display: inline-block; width: 49%;">
          <div style="margin-bottom: 10px;" v-if="isDialog">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="$emit('close')">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
          </div>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
        </div>
      </div>
      <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
      <div style="margin-top: 5px;">
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                  :data="pagedData"
                  :empty-text="$t('label.no_data')"
                  style="position: relative; z-index: 3;"
                  ref="table"
                  max-height="400"
                  :row-class-name="tableRowClassName">
          <el-table-column :min-width="150" :label="labels.picture_uri === undefined ? labels.pictureUri === undefined ? '' : labels.pictureUri : labels.picture_uri">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                <img v-show="props.row.imageUrl" :src="props.row.imageUrl" style="height: 100px;"/>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="labels.lunch_name === undefined ? labels.lunchName === undefined ? '' : labels.lunchName : labels.lunch_name">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; white-space: normal;">
                {{props.row.lunch_name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="50" :label="labels.unit_price === undefined ? labels.unitPrice === undefined ? '' : labels.unitPrice : labels.unit_price">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: right;">
                {{props.row.unit_price}}
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="labels.quantity === undefined ? '' : labels.quantity">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: center;">
                {{props.row.quantity}}
                <div class="btn-group">
                  <button type="button" class="btn btn-sm" style="position: relative; z-index: 3;" @click="minus(props.row)" :disabled="props.row.acceptable !== 1">-</button>
                  <button type="button" class="btn btn-sm" style="position: relative; z-index: 3;" @click="plus(props.row)" :disabled="props.row.acceptable !== 1">+</button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot-scope="props">
              <div style="text-align: center;">
                <button type="button" class="btn btn-info btn-sm btn-default" style="position: relative; z-index: 3;" @click="reserve(props.$index, props.row)" :disabled="props.row.acceptable !== 1">{{$t('button.reserve_simple')}}</button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="row" ref="pager">
        <div class="col-sm-6 pagination-info">
          <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        style="position: relative; z-index: 3;"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from '@/components/UIComponents/Pagination'
  import LunchOrderConfirm from '@/components/Dashboard/Views/Dashboard/LunchOrderConfirm'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default {
    name: 'LunchOrder',
    mixins: [mixin],
    components: {
      LunchOrderConfirm,
      PPagination
    },
    props: {
      isDialog: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        componentKey: 0,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [3, 5, 10],
          total: 0
        },
        tableData: [],
        tableRowClassName: '',
        tableWrapperStyle: '',
        tableStyle: '',
        labels: {},
        selectedRow: {}
      }
    },
    computed: {
      screenCode () {
        return 'DB-004'
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      minus (row) {
        if (row.quantity > 1) {
          row.quantity -= 1
          this.componentKey += 1
        }
      },
      plus (row) {
        if (row.quantity < 100) {
          row.quantity += 1
          this.componentKey += 1
        }
      },
      reserve (index, row) {
        swal({
          text: this.$t('message.confirm_to_order'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no')
        }).then(() => {
          let params = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              supplier_code: row.supplier_code,
              lunch_code: row.lunch_code,
              purchase_date: this.formatToday(),
              unit_price: parseInt(row.unit_price, 10),
              quantity: row.quantity
            }
          }
          this.send('/insert/employee_lunch', params)
        }).catch(() => {})
      },
      showConfirm (index, row) {
        this.selectedRow = row
        this.$modal.show('lunchOrderConfirm')
      },
      hideConfirm () {
        this.$modal.hide('lunchOrderConfirm')
      },
      init () {
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/employee_lunch/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.tableData = res.api_area.lunch_data
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
            row.quantity = 1
          }
          this.setImage()
        })
      },
      setImage () {
        const self = this
        for (const row of this.tableData) {
          if (row.picture_uri) {
            this.$firebase.storage()
              .ref()
              .child(row.picture_uri)
              .getDownloadURL()
              .then(function (url) {
                row.imageUrl = url
                self.componentKey += 1
              })
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
