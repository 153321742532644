<template>
  <div class="row" style="margin-top: -20px;" ref="frame2">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="workflow" :draggable="true" :clickToClose="false" height="auto" :width="1400" :pivotX="0.6">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :attendance-record="selectedRow" :is-modal="true" @close="hideWorkflow"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="break" :draggable="true" :clickToClose="false" height="auto" :width="400">
        <div class="modal-body">
          <BreakEdit :is-view="isReadOnly || stampCorrectionFlag" :is-stamp-correction="stampCorrectionFlag" :row="selectedRow" :labels="labels" :is-modal="true" @close="hideBreak" @breakApply="breakApply"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="changeWorkSchedule" :draggable="true" :clickToClose="false" height="auto" :width="400">
        <div class="modal-body">
          <ChangeWorkSchedule :before-list="beforeList" :after-list="afterList" :after-all-list="afterAllList" @close="hideChangeWorkSchedule"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="changeGround" :draggable="true" :clickToClose="false" height="auto" :width="400">
        <div class="modal-body">
          <ChangeGround :before-list="beforeList" :after-list="afterList" @close="hideChangeGround"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <div>
        <div style="display: inline-block; width: 49%;">
          <template v-if="isWorkflow && !isDeleted">
            <div style="padding-top: 10px;">
              <button type="button" class="btn btn-sm btn-default btn-danger" @click="$emit('close')">
                <span class="btn-label"><i class="ti-close"></i></span>
                {{$t('button.close')}}
              </button>
            </div>
          </template>
          <template v-else>
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="back">{{$t('button.back')}}</button>
          </template>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
        </div>
      </div>
      <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
      <div class="card">
        <div class="card-header">
          <div style="width: 230px; display: inline-block;">
            <h4 class="card-title">
              {{dispYearMonth}}&nbsp;&nbsp;&nbsp;<img src="/static/img/approved.png" style="width: 98px;" v-if="isApproved"/><img src="/static/img/under_review.jpeg" style="width: 98px;" v-if="isUnderReview"/>
            </h4>
          </div>
          <div style="padding-left: 50px; color: red; display: inline-block;">
            {{deleteAttendanceMessage}}
          </div>
        </div>
        <div class="card-content row">
          <div class="col-sm-12 attendance-detail" style="margin-top: 0px;">
            <div style="margin: 0 0 10px 0" v-if="!isWorkflow">
              <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="!beforeMonthData" @click="prevMonth">{{$t('button.previous_month')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="nowMonth">{{$t('button.this_month')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="!afterMonthData" @click="nextMonth">{{$t('button.next_month')}}</button>
            </div>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :key="tableKey"
                      :data="monthData"
                      style="width: 100%">
              <el-table-column width="126" :label="aggHeaders.employee">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      {{scope.column.label}}
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <small>{{props.row.employee_code}}</small>
                  <br>
                  {{props.row.employee_name}}
                </template>
              </el-table-column>
              <el-table-column width="50" :label="aggHeaders.telework_count">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.telework_count + $t('label.unit_times')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="isReadOnly ? 440 : 490" :label="detailHeaders.ground">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      {{scope.column.label}}
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="white-space: normal;">
                    {{props.row.totalGround}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="80" :label="aggHeaders.real_total_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.real_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.job_total_minutes" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.job_total_days + $t('label.day')}}
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.job_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.job_total_minutes" v-if="monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.job_total_days + $t('label.day')}}
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.standard_job_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="aggHeaders.job_overwork_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div :style="overtime && props.row.job_overwork_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.job_overwork_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.legal_job_minutes" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.legal_job_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.legal_job_minutes" v-if="monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.standard_legal_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="aggHeaders.legal_overwork_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div :style="overtime && props.row.legal_overwork_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.legal_overwork_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.late_night_overwork_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div :style="lateNight && props.row.late_night_overwork_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.late_night_overwork_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.break_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.break_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.late_minutes" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="lateEarlyDeparture && props.row.late_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.late_days ? props.row.late_days + $t('label.count') : '　'}}
                  </div>
                  <div :style="lateEarlyDeparture && props.row.late_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.late_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.early_departure_minutes" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="lateEarlyDeparture && props.row.early_departure_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.early_departure_days ? props.row.early_departure_days + $t('label.count') : '　'}}
                  </div>
                  <div :style="lateEarlyDeparture && props.row.early_departure_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.early_departure_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" :label="aggHeaders.lack_minutes" v-if="monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div :style="lateEarlyDeparture && props.row.lack_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.lack_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="aggHeaders.job_holiday_hours" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="overtime && props.row.job_holiday_hours > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.job_holiday_days + $t('label.day')}}
                  </div>
                  <div :style="overtime && props.row.job_holiday_hours > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.job_holiday_hours)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="aggHeaders.legal_holiday_overwork_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="overtime && props.row.legal_holiday_overwork_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.legal_holiday_overwork_days + $t('label.day')}}
                  </div>
                  <div :style="overtime && props.row.legal_holiday_overwork_minutes > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="$t('label.paid_holiday_ryaku')">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="paidHoliday && props.row.paid_holiday_days ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.paid_holiday_days ? props.row.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div :style="paidHoliday && props.row.paid_holiday_hours > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.child_time_leave_hours">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.child_time_leave_days ? props.row.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div style="text-align: right;">
                    {{parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="aggHeaders.compensatory_holiday_days" v-if="!monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.compensatory_holiday_days + $t('label.unit_days')}}
                  </div>
                  <div style="text-align: right;">
                    &nbsp;
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="70" :label="aggHeaders.closed_days">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.closed_days + $t('label.unit_days')}}
                  </div>
                  <div style="text-align: right;">
                    &nbsp;
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="17">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    &nbsp;
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-12 attendance-detail" style="margin: 20px 0;">
            <div style="display: inline-block; width: 1040px;">
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="toggleDispMode">
                <span v-show="!isShowSummary">{{$t('button.show_working_day')}}</span>
                <span v-show="isShowSummary">{{$t('button.show_working_day_summary')}}</span>
              </button>
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="toggleColumns" v-show="isShowSummary">
                <span v-show="!isShowAll">{{$t('button.expand_attendance_record')}}</span>
                <span v-show="isShowAll">{{$t('button.shrink_attendance_record')}}</span>
              </button>
              <div style="display: inline-block; width: 10px;"></div>
              <div :style="isPersonal ? 'display: inline-block;' : 'display: none;'">
                <button type="button" class="btn btn-sm btn-default btn-primary" v-show="!isReadOnly && isShowSummary" @click="addRow">{{$t('button.add_row')}}</button>
              </div>
              <button type="button" class="btn btn-sm btn-default btn-primary" v-show="!isReadOnly && isShowSummary" @click="showChangeWorkSchedule">{{$t('button.change_work_schedule')}}</button>
              <button type="button" class="btn btn-sm btn-default btn-primary" v-show="!isReadOnly && isShowSummary && !applicationControl.is_ground_confirm_employee" @click="showChangeGround">{{$t('button.change_ground')}}</button>
            </div>
            <div style="display: inline-block; width: 400px;" v-if="!isShowAll">
              <input type="checkbox" v-model="timeline.work" class="green" id="timeline-work" @change="updateTimeline"><label for="timeline-work">{{$t('label.timeline_work')}}</label>
              &nbsp;&nbsp;
              <input type="checkbox" v-model="timeline.overwork" class="blue" id="timeline-overwork" @change="updateTimeline"><label for="timeline-overwork">{{$t('label.timeline_overwork')}}</label>
            </div>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      :cell-class-name="tableCellClassName"
                      :height="tableHeight"
                      style="width: 100%"
                      :key="tableKey2"
            >
              <el-table-column width="50" :label="$t('label.delete')" align="center" v-if="!isReadOnly">
                <template slot-scope="props">
                  <div style="text-align: center;" :key="tableKey3">
                    <input type="checkbox" v-model="props.row.selected" :class="props.row.selectedColor" :title="props.row.selectedMsg" @change="selectedChanged(props.$index, props.row)" v-show="props.row.is_duplicated_target_date && isShowSummary">
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="76" :label="detailHeaders.target_date">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="typeof(props.row.target_date) === 'string'">
                    <template v-if="props.row.target_date_link && isShowSummary">
                      <div style="text-align: left;">
                        <a @click="showTransferHoliday(props.$index, props.row)">
                          <span :class="getDayClass(props.row.day_color)" style="font-weight: bold; text-decoration: underline; text-decoration-thickness: 2px; cursor: pointer;">
                            {{getDispDay(props.row.target_date.replaceAll('/', '-'))}}
                            <template v-if="props.row.week_day !== ''">
                              <span>({{getDispDayOfWeek(props.row.week_day)}})</span>
                            </template>
                          </span>
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: left;" v-show="props.row.is_first_target_date || props.row.id === 0">
                        <span :class="getDayClass(props.row.day_color)">
                          {{getDispDay(props.row.target_date.replaceAll('/', '-'))}}
                          <template v-if="props.row.week_day !== ''">
                            <span>({{getDispDayOfWeek(props.row.week_day)}})</span>
                          </template>
                        </span>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <el-date-picker 
                      v-model="props.row.target_date"
                      style="width: 20px;"
                      type="date"
                      ref="target_date"
                      :picker-options = "pickerOptions"
                      @change="targetDateChanged(props.row)">
                    </el-date-picker>
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="120" :label="detailHeaders.work_schedule" v-if="isShowSummary">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-bottom: 1px;">
                    <el-select :class="!props.row.imprint_correction_application_group_class ? 'select-success width100' : 'select-success width100 ' + props.row.imprint_correction_application_group_class"
                                size="small"
                                placeholder=""
                                v-tooltip.right-end="props.row.work_schedule_detail"
                                v-model="props.row.work_schedule_code"
                                @change="updateRow(props.row)"
                                :disabled="isReadOnly || monthData[0].flex">
                      <el-option v-for="work_schedule in props.row.work_schedule_list"
                                  class="select-success"
                                  :value="work_schedule.value"
                                  :label="work_schedule.label_abbreviation"
                                  :key="work_schedule.value">
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="50" :label="detailHeaders.telework_flg" align="center">
                <template slot-scope="props">
                  <div style="text-align: center;" :key="tableKey3" v-show="props.row.is_first_target_date || props.row.id === 0">
                    <input type="checkbox" v-model="props.row.telework_flg" :class="!props.row.imprint_correction_application_telework_group_class ? 'gray' : props.row.imprint_correction_application_telework_group_class" :disabled="(!isPersonal && props.row.imprint_correction_application_telework_group_class !== 'CompleteStyle') || !isApply || isWorkflow || !isShowSummary" @click="teleworkClicked($event, props.$index, props.row)">
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="isShowSummary ? 120 : 490" :label="detailHeaders.ground">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-top: 1px;" v-show="props.row.is_first_target_date || props.row.id === 0">
                    <el-select :class="!props.row.ground_application_group_class ? 'select-success width100' : 'select-success width100 ' + props.row.ground_application_group_class"
                                size="small"
                                placeholder=""
                                :title="props.row.ground_code_error || props.row.ground_name"
                                v-model="props.row.ground_code"
                                @change="groundChanged(props.$index, props.row)"
                                :disabled="isReadOnly || props.row.application_group.ground_application === 2 || !isShowSummary">
                      <el-option v-for="ground in props.row.grounds"
                                  :class="ground.optionClass"
                                  :value="ground.value"
                                  :label="ground.label"
                                  :key="ground.value">
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="55" :label="detailHeaders.job_start" v-if="isShowSummary">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.job_start}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="55" :label="detailHeaders.job_end" v-if="isShowSummary">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{props.row.job_end}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="70" :label="detailHeaders.stamping_start_time" v-if="isShowSummary">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="!isPersonal">
                    <div style="text-align: right; font-size: x-small;">
                      {{props.row.stamping_start_time}}
                    </div>
                    <template v-if="['CompleteStyle', 'OngoingStyle'].includes(props.row.imprint_correction_application_start_time_group_class)">
                      <div style="text-align: right;" :title="props.row.stamping_start_time_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 1)"
                        >
                          {{props.row.original_stamping_start_time}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div 
                        :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'NormalStyle'"                      
                        style="text-align: right; font-size: small; font-weight: bold;"
                      >
                        {{props.row.original_stamping_start_time}}
                      </div>
                    </template>
                  </template>
                  <template v-else-if="stampCorrectionFlag">
                    <div style="text-align: right; font-size: x-small;">
                      {{props.row.stamping_start_time}}
                    </div>
                    <template v-if="(isUnderReview || isApproved) && !props.row.application_group.imprint_correction_application_start_time_number">
                      <div class="CompleteStyle" style="text-align: right; font-size: small; font-weight: bold;">
                        {{props.row.original_stamping_start_time}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: right;" :title="props.row.stamping_start_time_error">
                        <template v-if="props.row.original_stamping_start_time">
                          <a
                            class="btn btn-simple btn-info"
                            :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'CompleteStyle'"
                            @click="showStampCorrection(props.$index, props.row, 1)"
                          >
                            {{props.row.original_stamping_start_time}}
                          </a>
                        </template>
                        <template v-else-if="props.row.id !== 0 && compareDate(props.row.target_date, formatToday()) <= 0 && !props.row.stamping_time_disabled">
                          <a
                            class="btn btn-simple btn-info ImprintCorrectionStyle"
                            @click="showStampCorrection(props.$index, props.row, 1)"
                          >
                            {{$t('label.imprint_correction')}}
                          </a>
                        </template>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div style="text-align: right; font-size: x-small;">
                      &nbsp;{{props.row.stamping_start_time}}
                    </div>
                    <template v-if="props.row.application_group.imprint_correction_application_start_time_group !== 1 && props.row.application_group.imprint_correction_application_start_time_number">
                      <div style="text-align: right;">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 1)"
                        >
                          {{props.row.original_stamping_start_time}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="isUnderReview || isApproved || (props.row.application_group.imprint_correction_application_end_time_group !== 1 && props.row.application_group.imprint_correction_application_end_time_number)">
                        <div class="NormalStyle" style="text-align: right; font-size: small; font-weight: bold;">
                          {{props.row.original_stamping_start_time}}
                        </div>
                      </template>
                      <template v-else>
                        <div :title="props.row.stamping_start_time_error">
                          <input 
                            type="text" 
                            :title="props.row.startTimeErrorMsg"
                            :class="{'errorBorder': props.row.isStartTimeError, 'imprintCorrectionColor': props.row.isStartTimeCorrected}"
                            style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                            v-model="props.row.original_stamping_start_time"
                            :disabled="props.row.stamping_time_disabled"
                            v-bind:readonly="isReadOnly"
                            @input="tableData = [...tableData]">
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="70" :label="detailHeaders.job_end ? detailHeaders.stamping_end_time : ''" v-if="isShowSummary">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="!isPersonal">
                    <div style="text-align: right; font-size: x-small;">
                      {{props.row.stamping_end_time}}
                    </div>
                    <template v-if="['CompleteStyle', 'OngoingStyle'].includes(props.row.imprint_correction_application_end_time_group_class)">
                      <div style="text-align: right;" :title="props.row.stamping_end_time_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 2)"
                        >
                          {{props.row.original_stamping_end_time}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div 
                        :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'NormalStyle'"
                        style="text-align: right; font-size: small; font-weight: bold;" v-tooltip.right-end="props.row.original_stamping_end_time_title"
                      >
                        {{props.row.original_stamping_end_time}}
                      </div>
                    </template>
                  </template>
                  <template v-else-if="stampCorrectionFlag">
                    <div style="text-align: right; font-size: x-small;">
                      {{props.row.stamping_end_time}}
                    </div>
                    <template v-if="(isUnderReview || isApproved) && !props.row.application_group.imprint_correction_application_end_time_number">
                      <div class="CompleteStyle" style="text-align: right; font-size: small; font-weight: bold;" v-tooltip.right-end="props.row.original_stamping_end_time_title">
                        {{props.row.original_stamping_end_time}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: right;" :title="props.row.stamping_end_time_error">
                        <template v-if="props.row.original_stamping_end_time">
                          <a
                            class="btn btn-simple btn-info"
                            :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'CompleteStyle'"
                            @click="showStampCorrection(props.$index, props.row, 2)"
                            v-tooltip.right-end="props.row.original_stamping_end_time_title"
                          >
                            {{props.row.original_stamping_end_time}}
                          </a>
                        </template>
                        <template v-else-if="props.row.id !== 0 && compareDate(props.row.target_date, formatToday()) <= 0 && !props.row.stamping_time_disabled">
                          <a
                            class="btn btn-simple btn-info ImprintCorrectionStyle"
                            @click="showStampCorrection(props.$index, props.row, 2)"
                            v-tooltip.right-end="props.row.original_stamping_end_time_title"
                          >
                            {{$t('label.imprint_correction')}}
                          </a>
                        </template>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div style="text-align: right; font-size: x-small;">
                      &nbsp;{{props.row.stamping_end_time}}
                    </div>
                    <template v-if="props.row.application_group.imprint_correction_application_end_time_group !== 1 && props.row.application_group.imprint_correction_application_end_time_number">
                      <div style="text-align: right;">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 1)"
                        >
                          {{props.row.original_stamping_end_time}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="isUnderReview || isApproved || (props.row.application_group.imprint_correction_application_start_time_group !== 1 && props.row.application_group.imprint_correction_application_start_time_number)">
                        <div class="NormalStyle" style="text-align: right; font-size: small; font-weight: bold;">
                          {{props.row.original_stamping_end_time}}
                        </div>
                      </template>
                      <template v-else>
                        <div :title="props.row.stamping_end_time_error">
                          <input 
                            type="text" 
                            :title="props.row.endTimeErrorMsg"
                            :class="{'errorBorder': props.row.isEndTimeError, 'imprintCorrectionColor': props.row.isEndTimeCorrected}"
                            style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                            v-model="props.row.original_stamping_end_time"
                            :disabled="props.row.stamping_time_disabled"
                            v-tooltip.right-end="props.row.original_stamping_end_time_title"
                            v-bind:readonly="isReadOnly"
                            @input="tableData = [...tableData]">
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </el-table-column>
              <el-table-column min-width="80" :label="detailHeaders.real_total_minutes">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right; font-size: small; font-weight: bold; color: black;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.real_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.job_total_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(monthData[0].flex ? props.row.standard_job_minutes : props.row.job_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="detailHeaders.job_overwork_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.job_overtime_link && isShowSummary">
                      <div :title="props.row.job_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.overtime_application_group_class"
                          @click="showOvertimeWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.job_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.legal_job_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(monthData[0].flex ? props.row.standard_legal_minutes : props.row.legal_job_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="detailHeaders.legal_overwork_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.legal_overtime_link && isShowSummary">
                      <div :title="props.row.legal_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.overtime_application_group_class"
                          @click="showOvertimeWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.late_night_overwork_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.late_night_overwork_link && isShowSummary">
                      <div :title="props.row.late_night_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.late_night_application_group_class"
                          @click="showLateNightWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.break_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="!canShow('/SS/menuSSScreen020') || (isReadOnly && props.row.break_minutes===0) || (!isReadOnly && !props.row.breaktime_link_flag) || !isShowSummary">
                      {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.break_minutes)}}
                    </template>
                    <template v-else>
                      <a
                        class="btn btn-simple btn-info"
                        style="padding: 0; color: black; text-decoration: underline;"
                        @click="showBreak(props.$index, props.row)"
                      >
                        {{formatDispMinute(props.row.break_minutes)}}
                      </a>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.late_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.late_link && isShowSummary">
                      <div :title="props.row.late_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.late_application_group_class"
                          @click="showLate(props.$index, props.row)"
                        >
                          {{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.late_minutes_error">{{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.early_departure_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.early_departure_link && isShowSummary">
                      <div :title="props.row.early_departure_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.early_departure_application_group_class"
                          @click="showEarlyDeparture(props.$index, props.row)"
                        >
                          {{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.early_departure_minutes_error">{{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.early_departure_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="detailHeaders.job_holiday_hours" v-if="isShowAll && !monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.job_holiday_work_link && false">
                      <div :title="props.row.job_holiday_hours_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.job_holiday_application_group_class"
                          @click="showHolidayWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.job_holiday_hours_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_holiday_hours)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" :label="detailHeaders.legal_holiday_overwork_minutes" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.legal_holiday_work_link && isShowSummary">
                      <div :title="props.row.legal_holiday_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.legal_holiday_application_group_class"
                          @click="showHolidayWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_holiday_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="$t('label.paid_holiday_ryaku')" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.paid_holiday_link && isShowSummary">
                    <div style="text-align: right;" :title="props.row.paid_holiday_error">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.paid_holiday_application_group_class"
                        @click="showPaidHoliday(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time && !props.row.paid_holiday_days && props.row.paid_holiday_hours === 0 ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </a>
                    </div>
                    <div style="text-align: right;" :title="props.row.paid_holiday_error">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.paid_holiday_application_group_class"
                        @click="showPaidHoliday(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time && !props.row.paid_holiday_days && props.row.paid_holiday_hours === 0 ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;" :title="props.row.paid_holiday_error">
                      {{!props.row.original_stamping_end_time && !props.row.paid_holiday_days && props.row.paid_holiday_hours === 0 ? '　' : props.row.paid_holiday_days ? props.row.paid_holiday_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                    </div>
                    <div style="text-align: right;" :title="props.row.paid_holiday_error">
                      {{!props.row.original_stamping_end_time && !props.row.paid_holiday_days && props.row.paid_holiday_hours === 0 ? '　' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                    </div>
                  </template>                    
                </template>
              </el-table-column>
              <el-table-column min-width="50" :label="detailHeaders.child_time_leave_hours" v-if="isShowAll">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.child_time_leave_link && isShowSummary">
                    <div style="text-align: right;" :title="props.row.child_time_leave_error">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.child_time_leave_application_group_class"
                        @click="showChildTimeLeave(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time && !props.row.child_time_leave_days && props.row.child_time_leave_hours === 0 ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </a>
                    </div>
                    <div style="text-align: right;" :title="props.row.child_time_leave_error">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.child_time_leave_application_group_class"
                        @click="showChildTimeLeave(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time && !props.row.child_time_leave_days && props.row.child_time_leave_hours === 0 ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;" :title="props.row.child_time_leave_error">
                      {{!props.row.original_stamping_end_time && !props.row.child_time_leave_days && props.row.child_time_leave_hours === 0 ? '　' : props.row.child_time_leave_days ? props.row.child_time_leave_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                    </div>
                    <div style="text-align: right;" :title="props.row.child_time_leave_error">
                      {{!props.row.original_stamping_end_time && !props.row.child_time_leave_days && props.row.child_time_leave_hours === 0 ? '　' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column min-width="120" :label="detailHeaders.compensatory_holiday_days" v-if="isShowAll && !monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <!-- <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div> -->
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <!-- {{!props.row.original_stamping_end_time ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}} -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="70" :label="detailHeaders.closed_days" v-if="isShowAll && monthData[0].flex">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <!-- <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div> -->
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <!-- {{props.row.closed_days === 0 ? '' : props.row.closed_days + $t('label.unit_days')}} -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="750" v-if="!isShowAll">
                <template slot="header">
                  <div class="phase-header tableHeader" style="text-align: left;">
                    <div class="phase-header__title" style="text-align: left;">
                      <div class="timeline-header" v-for="element in timelineHeader" v-bind:key="element">{{element}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :class="'timeline-box ' + element.label + ' ' + element.borderClass" v-for="element in props.row.timeline" v-bind:key="element.value"></div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--
          <div style="text-align: left; margin: 0 0 0 20px;">
            <button type="button" class="btn btn-sm btn-default btn-secondary" @click="deleteRow">選択行を削除</button>
          </div>
          -->
          <div style="text-align: left; margin: 20px 20px 0 20px;" v-if="!isReadOnly">
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="deleteWorking" :disabled="isSelected">
              <span class="btn-label">
                  <i class="fa fa-check"></i>
              </span>
              {{$t('button.delete')}}
            </button>
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="addWorking(true)" :disabled="!isShowSummary">
              <span class="btn-label">
                  <i class="fa fa-check"></i>
              </span>
              {{$t('button.update')}}
            </button>
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="apply" :disabled="isModified" v-show="isAttendanceRecordApplication" :title="isModified ? $t('message.require_update') : ''">
              <span class="btn-label">
                  <i class="fa fa-check"></i>
              </span>
              {{$t('button.apply')}}
            </button>
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="fix" :disabled="isModified" v-show="!isAttendanceRecordApplication" :title="isModified ? $t('message.require_update') : ''">
              <span class="btn-label">
                  <i class="fa fa-check"></i>
              </span>
              {{$t('button.fix')}}
            </button>
          </div>
          <div style="text-align: left; margin: 20px 20px 0 20px;" v-if="isReadOnly && !isClosed && !isAttendanceRecordApplication && !isWorkflow && $store.state.loginUser.attendanceManagement">
            <button type="button" class="btn btn-sm btn-default btn-submit" @click="cancelFix" v-show="!isAttendanceRecordApplication">
              <span class="btn-label">
                  <i class="fa fa-check"></i>
              </span>
              {{$t('button.cancel_fix')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, DatePicker} from 'element-ui'
  import VTooltip from 'v-tooltip'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import Workflow from '@/components/Dashboard/Views/Working/Workflow/Workflow'
  import BreakEdit from '@/components/Dashboard/Views/Working/BreakEdit'
  import ChangeWorkSchedule from '@/components/Dashboard/Views/Working/ChangeWorkSchedule'
  import ChangeGround from '@/components/Dashboard/Views/Working/ChangeGround'
  import mixin from '@/lib/mixin'
  import moment from 'moment'
  import swal from 'sweetalert2'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(VTooltip)
  export default{
    mixins: [mixin],
    components: {
      PPagination,
      Workflow,
      BreakEdit,
      ChangeWorkSchedule,
      ChangeGround,
      'el-date-picker': DatePicker
    },
    computed: {
      isWorkflow () {
        return this.screenCode === '04-004' || this.screenCode === '01-005' || this.screenCode === '01-006' || this.screenCode === '01-009' || this.screenCode === '01-010' || this.screenCode === '02-001' || this.screenCode === '09-012' || this.isDeleted
      },
      isPersonal () {
        return this.screenCode === '01-003' || this.isWorkflow
      },
      previousUrl () {
        return window.previousUrl
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      isReadOnly () {
        return !this.isPersonal || !this.isApply || this.isWorkflow || !this.$store.state.loginUser.attendanceManagement
      },
      tableHeight () {
        return this.isReadOnly ? this.isApproved ? this.isPersonal ? 'calc(100vh - 540px)' : 'calc(100vh - 500px)' : 'calc(100vh - 460px)' : 'calc(100vh - 500px)'
      },
      isModified () {
        if (this.tableData.length !== this.tableDataOrg.length) return true
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].id !== this.tableDataOrg[i].id) return true
          if (this.tableData[i].selected) return true
          if (this.tableData[i].work_schedule_code !== this.tableDataOrg[i].work_schedule_code) return true
          if (this.tableData[i].telework_flg !== this.tableDataOrg[i].telework_flg) return true
          if (this.tableData[i].ground_code !== this.tableDataOrg[i].ground_code) return true
          if (this.tableData[i].stamping_start_time !== this.tableDataOrg[i].stamping_start_time) return true
          if (this.tableData[i].stamping_end_time !== this.tableDataOrg[i].stamping_end_time) return true
          if ((!!this.tableData[i].original_stamping_start_time || !!this.tableDataOrg[i].original_stamping_start_time) && this.tableData[i].original_stamping_start_time !== this.tableDataOrg[i].original_stamping_start_time) return true
          if ((!!this.tableData[i].original_stamping_end_time || !!this.tableDataOrg[i].original_stamping_end_time) && this.tableData[i].original_stamping_end_time !== this.tableDataOrg[i].original_stamping_end_time) return true
        }
        return false
      }
    },
    data () {
      let vm = this
      return {
        beforeList: [],
        afterList: [],
        afterAllList: [],
        tableKey: 0,
        tableKey2: 1000,
        tableKey3: 2000,
        dispYearMonth: '',
        dispAttendanceApplicationStatus: '',
        labels: {},
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: [],
        monthColumns: [],
        monthData: [],
        tableColumns: [],
        tableData: [],
        tableDataOrg: [],
        searchCond: {
          company_code: '',
          employee_code: '',
          employee_type: '',
          from_year: '',
          from_month: '',
          to_year: '',
          to_month: '',
          search_type: '1',
          all_search_flag: false
        },
        years: [],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        employee_types: [],
        employees: [],
        selectedRow: null,
        selectedIndex: undefined,
        aggHeaders: {},
        detailHeaders: {},
        errMsgs: [],
        minDate: Date.now(),
        maxDate: Date.now(),
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() < vm.minDate || time.getTime() > vm.maxDate
          }
        },
        isAttendanceRecordApplication: true,
        isUnderReview: false,
        isApproved: false,
        isClosed: false,
        isApply: true,
        isDeleted: false,
        isSelected: false,
        beforeMonthData: true,
        afterMonthData: true,
        thisMonth: '',
        attendanceApplicationNumber: undefined,
        applicationFormCode: '',
        stampCorrectionFlag: 1,
        applicationControl: {},
        approveRecord: {},
        applyErrors: [],
        overtime: false,
        lateNight: false,
        lateEarlyDeparture: false,
        paidHoliday: false,
        isShowAll: true,
        isShowSummary: true,
        timelineHeader: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00',
          '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
        timeline: {
          work: true,
          overwork: true,
          break: true,
          late: true
        },
        graphData: [],
        pullbackMessage: '',
        deleteAttendanceMessage: '',
        deleteStampMessage: '',
        deleteStampMessageAction: '',
        workScheduleList: [],
        groundAllList: [],
        beforeGroundCode: undefined,
        beforeGroundCodeIndex: null
      }
    },
    methods: {
      teleworkClicked (event, index, row) {
        if (!this.isPersonal) {
          if (row.imprint_correction_application_telework_group_class === 'CompleteStyle') {
            event.preventDefault()
            event.stopPropagation()
            this.showStampCorrection(index, row, 3)
          }
        } else if (this.stampCorrectionFlag) {
          event.preventDefault()
          event.stopPropagation()
          this.showStampCorrection(index, row, 3)
        } else {
          for (let i = 0; i < this.tableData.length; i++) {
            if (i !== index && this.tableData[i].target_date === this.tableData[index].target_date) {
              console.log(this.tableData[i].telework_flg, this.tableData[index].telework_flg)
              // clickイベントのため、変更前の値で呼ばれる。変更後の値で同期したいため、true/falseを反転した値を設定
              this.tableData[i].telework_flg = !this.tableData[index].telework_flg
              break
            }
          }
        }
      },
      toggleColumns () {
        this.isShowAll = !this.isShowAll
        this.tableKey2 += 1
      },
      toggleDispMode () {
        if (!this.isShowSummary) {
          this.search()
        } else {
          let searchCond = {
            screen_code: this.isWorkflow ? '01-003' : this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              employee_code: this.monthData[0].employee_code,
              target_month: this.monthData[0].target_date.substring(0, 4) + '/' + this.monthData[0].target_date.substring(4)
            }
          }
          this.send('/summary/attendance_employee', searchCond, true, true)
          .then(res => {
            this.isShowSummary = false
            const targetDateMap = new Map()
            for (var i = this.tableData.length - 1; i >= 0; i--) {
              const targetDate = this.tableData[i].target_date
              if (Array.from(targetDateMap.keys()).includes(targetDate)) {
                this.tableData.splice(targetDateMap.get(targetDate), 1)
                this.tableDataOrg.splice(targetDateMap.get(targetDate), 1)
              }
              targetDateMap.set(targetDate, i)
            }
            for (const row of this.tableData) {
              for (const summary of res.api_area.working_data) {
                if (row.target_date === summary.target_date) {
                  row.real_total_minutes = summary.real_total_minutes
                  row.standard_job_minutes = summary.standard_job_minutes
                  row.job_total_minutes = summary.job_total_minutes
                  row.job_overwork_minutes = summary.job_overwork_minutes
                  row.job_holiday_hours = summary.job_holiday_hours
                  row.standard_legal_minutes = summary.standard_legal_minutes
                  row.legal_job_minutes = summary.legal_job_minutes
                  row.legal_overwork_minutes = summary.legal_overwork_minutes
                  row.legal_holiday_overwork_minutes = summary.legal_holiday_overwork_minutes
                  row.late_night_overwork_minutes = summary.late_night_overwork_minutes
                  row.break_minutes = summary.break_minutes
                  row.late_minutes = summary.late_minutes
                  row.early_departure_minutes = summary.early_departure_minutes
                  row.paid_holiday_days = summary.paid_holiday_days
                  row.paid_holiday_hours = summary.paid_holiday_hours
                  row.child_time_leave_days = summary.child_time_leave_days
                  row.child_time_leave_hours = summary.child_time_leave_hours
                  row.compensatory_holiday_days = summary.compensatory_holiday_days
                  row.leave_days = summary.leave_days
                  row.closed_days = summary.closed_days
                  row.telework_flg = summary.telework_flg
                }
              }
            }
            this.tableKey += 1
            this.tableKey2 += 1
          })
        }
      },
      selectedChanged (index, row) {
        this.tableData = [...this.tableData]
        this.isSelected = false
        for (const row of this.tableData) {
          if (row.selected) {
            this.isSelected = true
            break
          }
        }
        if (row.selected) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (i !== index && this.tableData[i].target_date === row.target_date) {
              this.tableData[i].selected = false
            }
          }
        }
      },
      targetDateChanged (selectedRow) {
        for (const row of this.tableData) {
          if (row.target_date === this.formatDate(selectedRow.target_date) && row.work_schedule_code !== '') {
            if (row.is_shift_publish) {
              swal({
                text: this.$t('message.shift_published'),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              }).then(() => {
                this.tableData = this.tableData.filter((row2) => row2.target_date !== selectedRow.target_date)
              })
              return
            }
          }
        }
        selectedRow.target_date = this.formatDate(selectedRow.target_date)
        for (const row of this.tableData) {
          if (row.target_date === selectedRow.target_date && row.work_schedule_code !== '') {
            row.is_duplicated_target_date = true
            selectedRow.is_duplicated_target_date = true
            selectedRow.day_color = row.day_color
            selectedRow.week_day = row.week_day
            selectedRow.work_schedule_list = row.work_schedule_list
            selectedRow.ground_code = row.ground_code
            for (const ground of row.grounds) {
              if (ground.value === row.ground_code) {
                selectedRow.grounds = [ground]
                row.grounds = [ground]
                break
              }
            }
            break
          }
        }
        if (!selectedRow.is_duplicated_target_date) {
          selectedRow.is_first_target_date = true
        }
      },
      addRow () {
        this.tableData.unshift({
          id: 0,
          selected: false,
          selectedColor: 'gray',
          company_code: this.tableData[0].company_code,
          employee_code: this.tableData[0].employee_code,
          target_date: null,
          work_schedule_code: '',
          telework_flg: false,
          ground_code: '',
          week_day: '',
          stamping_start_time: '',
          stamping_end_time: '',
          job_start: '',
          job_end: '',
          real_total_minutes: '',
          job_total_minutes: '',
          job_overwork_minutes: '',
          job_holiday_hours: '',
          legal_job_minutes: '',
          legal_overwork_minutes: '',
          legal_holiday_overwork_minutes: '',
          late_night_overwork_minutes: '',
          break_minutes: '',
          sum_break_minutes: '',
          late_minutes: '',
          early_departure_minutes: '',
          paid_holiday_days: '',
          paid_holiday_hours: '',
          compensatory_holiday_days: '',
          child_time_leave_days: '',
          child_time_leave_hours: '',
          grounds: this.grounds,
          application_group: {},
          is_duplicated_target_date: false,
          is_first_target_date: false,
          break_time_data: []
        })
        this.$nextTick(() => {
          this.$refs.target_date.focus()
        })
      },
      deleteRow () {
        for (let i = this.tableData.length - 1; i >= 0; i--) {
          if (this.tableData[i].selected) {
            this.tableData.splice(i, 1)
          }
        }
      },
      handleView (index, row) {
        this.$router.push({ name: 'officeView', params: row })
      },
      handleEdit (index, row) {
        this.$router.push({ name: 'officeEdit', params: row })
      },
      handleDelete (index, row) {
        this.$router.push({ name: 'officeDelete', params: row })
      },
      search () {
        const path = this.isPersonal ? '/init/attendance_employee' : '/init/attendance_list'
        this.execSearch(path)
      },
      execSearch (url) {
        let searchCond = {
          screen_code: this.isWorkflow ? '01-003' : this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: this.monthData[0].employee_code,
            target_month: this.monthData[0].target_date.substring(0, 4) + '/' + this.monthData[0].target_date.substring(4)
          }
        }
        this.send(url, searchCond, true, true)
        .then(res => {
          this.isShowSummary = true
          if (!res.api_area.working_sum_data) {
            this.back()
          }
          this.setWorkingSumData(res)
          this.minDate = new Date(res.api_area.working_data[0].target_date)
          this.maxDate = new Date(res.api_area.working_data[res.api_area.working_data.length - 1].target_date)
          let headerLabels = this.getLabels(res.common_area)
          this.detailHeaders.target_date = headerLabels.target_date_ryaku
          this.detailHeaders.work_schedule = headerLabels.workSchedule_ryaku
          this.detailHeaders.telework_flg = headerLabels.telework_flg_ryaku
          this.detailHeaders.ground = headerLabels.ground_ryaku
          this.detailHeaders.job_start = headerLabels.job_start_ryaku
          this.detailHeaders.stamping_start_time = headerLabels.stamping_start_time_ryaku
          this.detailHeaders.job_end = headerLabels.job_end_ryaku
          this.detailHeaders.stamping_end_time = headerLabels.stamping_end_time_ryaku
          this.detailHeaders.real_total_minutes = headerLabels.real_total_minutes_ryaku
          this.detailHeaders.job_total_minutes = headerLabels.job_total_minutes_ryaku
          this.detailHeaders.job_overwork_minutes = headerLabels.job_overwork_minutes_ryaku
          this.detailHeaders.job_holiday_hours = headerLabels.job_holiday_hours_ryaku
          this.detailHeaders.legal_job_minutes = headerLabels.legal_job_minutes_ryaku
          this.detailHeaders.legal_overwork_minutes = headerLabels.legal_overwork_minutes_ryaku
          this.detailHeaders.legal_holiday_overwork_minutes = headerLabels.legal_holiday_overwork_minutes_ryaku
          this.detailHeaders.late_night_overwork_minutes = headerLabels.late_night_overwork_minutes_ryaku
          this.detailHeaders.break_minutes = headerLabels.break_minutes_ryaku
          this.detailHeaders.late_minutes = headerLabels.late_minutes_ryaku
          this.detailHeaders.lack_minutes = headerLabels.lack_minutes_ryaku
          this.detailHeaders.early_departure_minutes = headerLabels.early_departure_minutes_ryaku
          this.detailHeaders.paid_holiday_days = headerLabels.paid_holiday_days_ryaku
          this.detailHeaders.paid_holiday_hours = headerLabels.paid_holiday_hours_ryaku
          this.detailHeaders.compensatory_holiday_days = headerLabels.compensatory_holiday_days_ryaku
          this.detailHeaders.child_time_leave_hours = headerLabels.child_time_leave_hours_ryaku
          this.detailHeaders.closed_days = headerLabels.closed_days_ryaku
          this.detailHeaders.delete = this.$t('label.delete')
          this.aggHeaders = this.detailHeaders
          this.aggHeaders.employee = headerLabels.employee_ryaku
          this.aggHeaders.telework_count = headerLabels.telework_count_ryaku
          this.labels = {}
          this.labels.year = headerLabels.year
          this.labels.month = headerLabels.month
          this.labels.monday = this.$t('label.monday_ryaku')
          this.labels.tuesday = this.$t('label.tuesday_ryaku')
          this.labels.wednesday = this.$t('label.wednesday_ryaku')
          this.labels.thursday = this.$t('label.thursday_ryaku')
          this.labels.friday = this.$t('label.friday_ryaku')
          this.labels.saturday = this.$t('label.saturday_ryaku')
          this.labels.sunday = this.$t('label.sunday_ryaku')

          this.beforeMonthData = res.api_area.before_month_data
          this.afterMonthData = res.api_area.after_month_data
          this.thisMonth = res.api_area.this_month
          this.attendanceApplicationNumber = res.api_area.attendance_application_number
          this.applicationControl = res.api_area.application_control
          if (this.applicationControl === null) {
            this.applicationControl = {
              is_attendance_record_application: true,
              is_early_departure_time_application: true,
              is_ground_confirm_employee: true,
              is_holiday_work_application: true,
              is_holiday_work_cancel_application: true,
              is_imprint_correction_application: false,
              is_late_night_overwork_application: true,
              is_late_time_application: true,
              is_overtime_application: true,
              is_paid_leave_employee: true,
              is_paid_leave_employee_cancel: true,
              is_parental_leave_application: true,
              is_transfer_holiday_workCancel_application: true,
              is_transfer_holiday_work_application: true
            }
          }
          this.isAttendanceRecordApplication = this.applicationControl.is_attendance_record_application
          this.workScheduleList = res.common_area.combo_data.work_schedule_list
          this.groundAllList = res.common_area.combo_data.ground_all_list
          this.dispYearMonth = this.formatDispYearMonth(this.monthData[0].target_date, this.$t('label.year'), this.$t('label.month'))
          // 出勤簿申請後に出勤簿を管理しないにした場合の対応
          if (res.api_area.application_control && res.api_area.application_control.is_attendance_record_application) {
            if (res.api_area.attendance_application_group === 4) {
              res.api_area.attendance_application_group = 3
            }
          } else {
            if (res.api_area.attendance_application_group === 2) {
              res.api_area.attendance_application_group = 1
            } else if (res.api_area.attendance_application_group === 3) {
              res.api_area.attendance_application_group = 4
            }
          }
          if (res.api_area.attendance_application_group === 2 || res.api_area.attendance_application_group === 3 || res.api_area.attendance_application_group === 4) {
            for (const row of res.common_area.combo_data.application_group_list) {
              if (row.value === res.api_area.attendance_application_group) {
                this.dispAttendanceApplicationStatus = `[${this.getLabel(row.label)}]`
                this.isApply = false
                break
              }
            }
          } else if (res.api_area.attendance_application_group === 8) {
            this.dispAttendanceApplicationStatus = ''
            this.isApply = false
          } else {
            this.dispAttendanceApplicationStatus = ''
            this.isApply = true
          }
          if (res.api_area.attendance_application_group === 3 || res.api_area.attendance_application_group === 4) {
            this.isApproved = true
          } else {
            this.isApproved = false
          }
          if (res.api_area.attendance_application_group === 2) {
            this.isUnderReview = true
          } else {
            this.isUnderReview = false
          }
          if (res.api_area.attendance_application_group === 3) {
            this.isClosed = true
          } else {
            this.isClosed = false
          }
          this.stampCorrectionFlag = this.applicationControl.is_imprint_correction_application
          this.graphData = res.api_area.graph_data
          this.pullbackMessage = res.api_area.pull_back_message
          this.deleteAttendanceMessage = !res.api_area.delete_attendance_message ? '' : res.api_area.delete_attendance_message.split(',')[0]
          this.deleteStampMessage = res.api_area.delete_stamp_message
          this.deleteStampMessageAction = res.api_area.delete_stamp_message_action
          this.tableData = res.api_area.working_data
          this.tableDataOrg = []
          for (const row of this.tableData) {
            row.select_month = res.api_area.select_month
            this.tableDataOrg.push({...row})
          }
          this.editTableData()
          this.setApplyErrors()
          this.tableKey += 1
          this.tableKey2 += 1
        })
      },
      editTableData () {
        const targetDateList = []
        for (let data of this.tableData) {
          data.is_first_target_date = true
          data.is_duplicated_target_date = false
          for (const targetDate of targetDateList) {
            if (targetDate.target_date === data.target_date) {
              data.is_first_target_date = false
              targetDate.is_duplicated_target_date = true
              data.is_duplicated_target_date = true
              break
            }
          }
          if (data.is_first_target_date) {
            targetDateList.push(data)
          }
        }
        var daysByGround = new Map()
        const groundTargetDateList = []
        const groundList = []
        var index = 0
        for (let data of this.tableData) {
          data.before_ground_code = data.ground_code
          if (data.ground_code) {
            let isExist = false
            for (const targetDate of groundTargetDateList) {
              if (targetDate === data.target_date) {
                isExist = true
                break
              }
            }
            if (!isExist) {
              groundTargetDateList.push(data.target_date)
              var count = daysByGround.get(data.ground_code)
              if (count) {
                daysByGround.set(data.ground_code, count + 1)
              } else {
                daysByGround.set(data.ground_code, 1)
              }
            }
            isExist = false
            for (const ground of groundList) {
              if (ground.value === data.ground_code) {
                isExist = true
                break
              }
            }
            if (!isExist) {
              groundList.push({label: data.ground_name, value: data.ground_code})
            }
          }
          // 事由
          if (data.timecard_available_flg !== 9) {
            for (const ground of data.ground_list) {
              if (ground.workflow_flg === 1) {
                ground.optionClass = 'select-success ground-application-style'
              } else {
                ground.optionClass = 'select-success'
              }
            }
            let isExist = false
            for (const ground of data.ground_list) {
              if (ground.value === data.ground_code) {
                isExist = true
                data.stamping_time_disabled = !!data.stamping_time_disabled || ground.non_stamp_flg === 2
                break
              }
            }
            if (!isExist) {
              for (const ground of this.groundAllList) {
                if (ground.value === data.ground_code) {
                  data.stamping_time_disabled = !!data.stamping_time_disabled || ground.non_stamp_flg === 2
                  break
                }
              }
              data.ground_list.push({label: data.ground_name, value: data.ground_code})
            }
            if (index === this.beforeGroundCodeIndex) {
              if (!data.ground_list.find((ground) => ground.value === this.beforeGroundCode)) {
                const beforeGround = this.groundAllList.find((ground) => ground.value === this.beforeGroundCode)
                if (beforeGround) {
                  data.ground_list.push({label: beforeGround.label, value: beforeGround.value})
                }
              }
            }
            data.grounds = data.ground_list
          } else {
            for (const ground of this.groundAllList) {
              if (ground.value === data.ground_code) {
                data.stamping_time_disabled = !!data.stamping_time_disabled || ground.non_stamp_flg === 2
                break
              }
            }
            data.grounds = [{label: data.ground_name, value: data.ground_code}]
          }
          // 退勤時刻ツールチップ
          if (data.original_stamping_end_time) {
            const hour = parseInt(data.original_stamping_end_time.split(':')[0])
            if (hour >= 24) {
              data.original_stamping_end_time_title = this.$t('label.next_day') + String(hour - 24) + data.original_stamping_end_time.substring(2)
            }
          }
          // タイムライン
          data.timeline = this.getTimeline(data.target_date)
          index++
        }
        const sortedEntries = [...daysByGround.entries()].sort((a, b) => {
          return parseInt(a[0], 10) - parseInt(b[0], 10)
        })
        var totalGround = ''
        for (let entry of sortedEntries) {
          for (let ground of groundList) {
            if (ground.value === entry[0]) {
              if (totalGround.length > 0) {
                totalGround += ' / '
              }
              totalGround += ground.label + '：' + entry[1] + this.$t('label.day')
            }
          }
        }
        this.monthData[0].totalGround = totalGround
        for (let data of this.tableData) {
          data.selected = false
          data.selectedColor = 'gray'
          data.isStartTimeError = false
          data.isEndTimeError = false
          data.isStartTimeCorrected = false
          data.isEndTimeCorrected = false
          data.isAvailable = true
          // 勤務体系
          data.work_schedule_code_bk = data.work_schedule_code
          if (this.isReadOnly) {
            data.work_schedule_code = data.work_schedule_name
            for (const workScheduleRow of data.work_schedule_list) {
              if (workScheduleRow.value === data.work_schedule_code_bk) {
                if (workScheduleRow.label_abbreviation) {
                  data.work_schedule_code = workScheduleRow.label_abbreviation
                } else {
                  data.work_schedule_code = data.work_schedule_name
                }
                break
              }
            }
          }
          if (data.is_shift_publish) {
            data.work_schedule_list = data.work_schedule_list.filter((workSchedule) => workSchedule.value === data.work_schedule_code)
          }
          let workScheduleDetail = ''
          for (const row of data.work_schedule_list) {
            if (row.value === data.work_schedule_code || row.label_abbreviation === data.work_schedule_code) {
              workScheduleDetail += row.label
              if (data.is_time_labor) {
                if (row.job_start) {
                  workScheduleDetail += '<br>[' + this.$t('label.transformation_working_time') + ']  ' + row.job_start + '-' + row.job_end
                }
              } else {
                if (row.working_system_type === 2) {
                  if (row.core_start) {
                    workScheduleDetail += '<br>[' + this.$t('label.core_time') + ']  ' + row.core_start + '-' + row.core_end
                  }
                  if (row.flex_start) {
                    workScheduleDetail += '<br>[' + this.$t('label.flex_time') + ']  ' + row.flex_start + '-' + row.flex_end
                  }
                } else {
                  if (row.job_start) {
                    workScheduleDetail += '<br>[' + this.$t('label.normal_working_time') + ']  ' + row.job_start + '-' + row.job_end
                  }
                }
              }
              if (row.break_time_list && row.break_time_list.length > 0) {
                workScheduleDetail += '<br>' + this.$t('label.break_time')
                for (const breakTime of row.break_time_list) {
                  workScheduleDetail += '<br>' + breakTime
                }
                if (row.break_time_range_list && row.break_time_range_list.length > 0) {
                  for (const breakTime of row.break_time_range_list) {
                    const rangeBreakHour = String(parseInt(breakTime.range_break_minutes / 60, 10))
                    const rangeBreakMinute = ('0' + String(parseInt(breakTime.range_break_minutes % 60, 10))).slice(-2)
                    workScheduleDetail += '<br>' + `${rangeBreakHour}${this.$t('label.hours')}${rangeBreakMinute}${this.$t('label.breaktime_range_message')}：${breakTime.break_minutes}${this.$t('label.minute')}`
                  }
                }
              } else {
                if (row.break_time_range_list && row.break_time_range_list.length > 0) {
                  workScheduleDetail += '<br>' + this.$t('label.break_time')
                  for (const breakTime of row.break_time_range_list) {
                    const rangeBreakHour = String(parseInt(breakTime.range_break_minutes / 60, 10))
                    const rangeBreakMinute = ('0' + String(parseInt(breakTime.range_break_minutes % 60, 10))).slice(-2)
                    workScheduleDetail += '<br>' + `${rangeBreakHour}${this.$t('label.hours')}${rangeBreakMinute}${this.$t('label.breaktime_range_message')}：${breakTime.break_minutes}${this.$t('label.minute')}`
                  }
                }
              }
              data.stamping_time_disabled = !!data.stamping_time_disabled || row.off_site_deemed_working_hours_flag
              break
            }
          }
          data.work_schedule_detail = workScheduleDetail
          data.stamping_time_disabled = !!data.stamping_time_disabled || this.compareDate(data.target_date, this.$store.state.loginUser.hireDate) < 0
          // 事由
          if (data.application_group.ground_application === 1 && !!data.application_group.ground_application_number) {
            data.ground_application_group_class = 'CompleteStyle'
          } else if (data.application_group.ground_application === 2) {
            data.ground_application_group_class = 'CompleteStyle'
          } else if (data.application_group.ground_application === 3) {
            if (this.applicationControl.is_ground_confirm_employee) {
              data.ground_application_group_class = 'RequiredStyle'
            }
          } else if (data.application_group.ground_application === 4) {
            data.ground_application_group_class = 'InvalidStyle'
          }
          // 打刻補正（出勤時刻）
          if (data.application_group.imprint_correction_application_start_time_group === 1 && !!data.application_group.imprint_correction_application_start_time_number) {
            data.imprint_correction_application_start_time_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_start_time_group === 2 && !!data.application_group.imprint_correction_application_start_time_number) {
            data.imprint_correction_application_start_time_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_start_time_group === 3) {
            if (this.applicationControl.is_imprint_correction_application) {
              data.imprint_correction_application_start_time_group_class = 'RequiredStyle'
            }
          } else if (data.application_group.imprint_correction_application_start_time_group === 4) {
            data.imprint_correction_application_start_time_group_class = 'InvalidStyle'
          }
          data.isStartTimeCorrected = data.application_group.imprint_correction_application_start_time_group === 2
          // 打刻補正（退勤時刻）
          if (data.application_group.imprint_correction_application_end_time_group === 1 && !!data.application_group.imprint_correction_application_end_time_number) {
            data.imprint_correction_application_end_time_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_end_time_group === 2 && !!data.application_group.imprint_correction_application_end_time_number) {
            data.imprint_correction_application_end_time_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_end_time_group === 3) {
            if (this.applicationControl.is_imprint_correction_application) {
              data.imprint_correction_application_end_time_group_class = 'RequiredStyle'
            }
          } else if (data.application_group.imprint_correction_application_end_time_group === 4) {
            data.imprint_correction_application_end_time_group_class = 'InvalidStyle'
          }
          data.isEndTimeCorrected = data.application_group.imprint_correction_application_end_time_group === 2
          // 打刻補正（テレワーク）
          if (data.application_group.imprint_correction_application_telework_group === 1 && !!data.application_group.imprint_correction_application_telework_number) {
            data.imprint_correction_application_telework_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_telework_group === 2 && !!data.application_group.imprint_correction_application_telework_number) {
            data.imprint_correction_application_telework_group_class = 'CompleteStyle'
          } else if (data.application_group.imprint_correction_application_telework_group === 3) {
            if (this.applicationControl.is_imprint_correction_application) {
              data.imprint_correction_application_telework_group_class = 'RequiredStyle'
            }
          } else if (data.application_group.imprint_correction_application_telework_group === 4) {
            data.imprint_correction_application_telework_group_class = 'InvalidStyle'
          }
          // 所定外労働時間、法定外労働時間
          if (data.application_group.overtime_application === 1) {
            if (data.job_overwork_minutes && data.job_overwork_minutes > 0) {
              data.overtime_application_group_class = 'CompleteStyle'
              data.job_overtime_link = true
            }
            if (data.legal_overwork_minutes && data.legal_overwork_minutes > 0) {
              data.overtime_application_group_class = 'CompleteStyle'
              data.legal_overtime_link = true
            }
          } else if (data.application_group.overtime_application === 2) {
            if (data.job_overwork_minutes && data.job_overwork_minutes > 0) {
              data.overtime_application_group_class = 'CompleteStyle'
              data.job_overtime_link = true
            }
            if (data.legal_overwork_minutes && data.legal_overwork_minutes > 0) {
              data.overtime_application_group_class = 'CompleteStyle'
              data.legal_overtime_link = true
            }
          } else if (data.application_group.overtime_application === 3) {
            if (this.applicationControl.is_overtime_application) {
              if (data.job_overwork_minutes && data.job_overwork_minutes > 0) {
                data.overtime_application_group_class = 'RequiredStyle'
                data.job_overtime_link = !this.isReadOnly
              }
              if (data.legal_overwork_minutes && data.legal_overwork_minutes > 0) {
                data.overtime_application_group_class = 'RequiredStyle'
                data.legal_overtime_link = !this.isReadOnly
              }
            }
          } else if (data.application_group.overtime_application === 4) {
            if (data.job_overwork_minutes && data.job_overwork_minutes > 0) {
              data.overtime_application_group_class = 'InvalidStyle'
              data.job_overtime_link = true
            }
            if (data.legal_overwork_minutes && data.legal_overwork_minutes > 0) {
              data.overtime_application_group_class = 'InvalidStyle'
              data.legal_overtime_link = true
            }
          }
          // 深夜労働時間
          if (data.application_group.late_night_overwork_application === 1) {
            if (data.late_night_overwork_minutes && data.late_night_overwork_minutes > 0) {
              data.late_night_application_group_class = 'CompleteStyle'
              data.late_night_overwork_link = true
            }
          } else if (data.application_group.late_night_overwork_application === 2) {
            data.late_night_application_group_class = 'CompleteStyle'
            data.late_night_overwork_link = true
          } else if (data.application_group.late_night_overwork_application === 3) {
            if (this.applicationControl.is_late_night_overwork_application) {
              data.late_night_application_group_class = 'RequiredStyle'
              data.late_night_overwork_link = !this.isReadOnly
            }
          } else if (data.application_group.late_night_overwork_application === 4) {
            data.late_night_application_group_class = 'InvalidStyle'
            data.late_night_overwork_link = true
          }
          // 遅刻時間
          if (data.application_group.late_application === 1) {
            if (data.late_minutes && data.late_minutes > 0) {
              data.late_application_group_class = 'CompleteStyle'
              data.late_link = true
            }
          } else if (data.application_group.late_application === 2) {
            data.late_application_group_class = 'CompleteStyle'
            data.late_link = true
          } else if (data.application_group.late_application === 3) {
            if (this.applicationControl.is_late_time_application) {
              data.late_application_group_class = 'RequiredStyle'
              data.late_link = !this.isReadOnly
            }
          } else if (data.application_group.late_application === 4) {
            data.late_application_group_class = 'InvalidStyle'
            data.late_link = true
          }
          // 早退時間
          if (data.application_group.early_departure_application === 1) {
            if (data.early_departure_minutes && data.early_departure_minutes > 0) {
              data.early_departure_application_group_class = 'CompleteStyle'
              data.early_departure_link = true
            }
          } else if (data.application_group.early_departure_application === 2) {
            data.early_departure_application_group_class = 'CompleteStyle'
            data.early_departure_link = true
          } else if (data.application_group.early_departure_application === 3) {
            if (this.applicationControl.is_early_departure_time_application) {
              data.early_departure_application_group_class = 'RequiredStyle'
              data.early_departure_link = !this.isReadOnly
            }
          } else if (data.application_group.early_departure_application === 4) {
            data.early_departure_application_group_class = 'InvalidStyle'
            data.early_departure_link = true
          }
          // 所定休日労働時間、法定休日労働時間
          if (data.application_group.job_holiday_application === 1) {
            if (data.job_holiday_hours && data.job_holiday_hours > 0) {
              data.job_holiday_application_group_class = 'CompleteStyle'
              data.job_holiday_work_link = true
            }
            if (data.legal_holiday_overwork_minutes && data.legal_holiday_overwork_minutes > 0) {
              data.legal_holiday_application_group_class = 'CompleteStyle'
              data.legal_holiday_work_link = true
            }
          } else if (data.application_group.job_holiday_application === 2) {
            if (data.job_holiday_hours && data.job_holiday_hours > 0) {
              data.job_holiday_application_group_class = 'CompleteStyle'
              data.job_holiday_work_link = true
            }
            if (data.legal_holiday_overwork_minutes && data.legal_holiday_overwork_minutes > 0) {
              data.legal_holiday_application_group_class = 'CompleteStyle'
              data.legal_holiday_work_link = true
            }
          } else if (data.application_group.job_holiday_application === 3) {
            if (this.applicationControl.is_overtime_application) {
              if (data.job_holiday_hours && data.job_holiday_hours > 0) {
                data.job_holiday_application_group_class = 'RequiredStyle'
                data.job_holiday_work_link = !this.isReadOnly
              }
              if (data.legal_holiday_overwork_minutes && data.legal_holiday_overwork_minutes > 0) {
                data.legal_holiday_application_group_class = 'RequiredStyle'
                data.legal_holiday_work_link = !this.isReadOnly
              }
            }
          } else if (data.application_group.job_holiday_application === 4) {
            if (data.job_holiday_hours && data.job_holiday_hours > 0) {
              data.job_holiday_application_group_class = 'InvalidStyle'
              data.job_holiday_work_link = true
            }
            if (data.legal_holiday_overwork_minutes && data.legal_holiday_overwork_minutes > 0) {
              data.legal_holiday_application_group_class = 'InvalidStyle'
              data.legal_holiday_work_link = true
            }
          }
          // 有休日数、時間
          if (data.application_group.paid_holiday_application === 1) {
            if (data.paid_holiday_days > 0 || data.paid_holiday_hours > 0) {
              data.paid_holiday_application_group_class = 'CompleteStyle'
              if (data.application_group.paid_holiday_application_number !== null) {
                data.paid_holiday_link = true
              }
            }
          } else if (data.application_group.paid_holiday_application === 2) {
            data.paid_holiday_application_group_class = 'CompleteStyle'
            if (data.application_group.paid_holiday_application_number !== null) {
              data.paid_holiday_link = true
            }
          } else if (data.application_group.paid_holiday_application === 3) {
            if (this.applicationControl.is_paid_holiday_application) {
              data.paid_holiday_application_group_class = 'RequiredStyle'
              if (data.application_group.paid_holiday_application_number !== null) {
                data.paid_holiday_link = !this.isReadOnly
              }
            }
          } else if (data.application_group.paid_holiday_application === 4) {
            data.paid_holiday_application_group_class = 'InvalidStyle'
            if (data.application_group.paid_holiday_application_number !== null) {
              data.paid_holiday_link = true
            }
          }
          // 育休日数、時間
          if (data.application_group.parental_leave_application === 1) {
            if (data.child_time_leave_days > 0 || data.child_time_leave_hours > 0) {
              data.child_time_leave_application_group_class = 'CompleteStyle'
              if (data.application_group.parental_leave_application_number !== null) {
                data.child_time_leave_link = true
              }
            }
          } else if (data.application_group.parental_leave_application === 2) {
            data.child_time_leave_application_group_class = 'CompleteStyle'
            if (data.application_group.parental_leave_application_number !== null) {
              data.child_time_leave_link = true
            }
          } else if (data.application_group.parental_leave_application === 3) {
            if (this.applicationControl.is_parental_leave_application) {
              data.child_time_leave_application_group_class = 'RequiredStyle'
              if (data.application_group.parental_leave_application_number !== null) {
                data.child_time_leave_link = !this.isReadOnly
              }
            }
          } else if (data.application_group.parental_leave_application === 4) {
            data.child_time_leave_application_group_class = 'InvalidStyle'
            if (data.application_group.parental_leave_application_number !== null) {
              data.child_time_leave_link = true
            }
          }
          // 振休日数、時間
          if (data.application_group.transfer_work_application_number || data.application_group.transfer_holiday_application_number) {
            data.target_date_link = true
          } else {
            data.target_date_link = false
          }
        }
      },
      updateTimeline () {
        for (let data of this.tableData) {
          data.timeline = this.getTimeline(data.target_date)
        }
        this.tableKey2 += 1
      },
      getTimeline (targetDate) {
        const timeline = []
        const values = [0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330, 345, 360,
          375, 390, 405, 420, 435, 450, 465, 480, 495, 510, 525, 540, 555, 570, 585, 600, 615, 630, 645, 660, 675, 690, 705, 720,
          735, 750, 765, 780, 795, 810, 825, 840, 855, 870, 885, 900, 915, 930, 945, 960, 975, 990, 1005, 1020, 1035, 1050, 1065, 1080,
          1095, 1110, 1125, 1140, 1155, 1170, 1185, 1200, 1215, 1230, 1245, 1260, 1275, 1290, 1305, 1320, 1335, 1350, 1365, 1380, 1395, 1410, 1425]
        if (this.graphData) {
          for (const row of this.graphData) {
            if (!row || targetDate !== row.target_date) continue
            for (const value of values) {
              let color = 0
              const normalTime = row.graph_data[0].graph_time
              const overTime = row.graph_data[1].graph_time
              if (this.timeline.work) {
                if (value < normalTime) {
                  color = row.graph_data[0].color
                } else if (value < normalTime + overTime) {
                  color = row.graph_data[1].color
                }
              } else {
                if (value < overTime) {
                  color = row.graph_data[1].color
                }
              }
              let label = ''
              switch (color) {
                case 0:
                  label = 'timeline-box-none'
                  break
                case 1:
                  label = this.timeline.work ? 'timeline-box-work' : 'timeline-box-none'
                  break
                case 2:
                  label = this.timeline.overwork ? 'timeline-box-overwork' : 'timeline-box-none'
                  break
                case 3:
                  label = this.timeline.late ? 'timeline-box-late' : 'timeline-box-none'
                  break
                case 4:
                  label = this.timeline.break ? 'timeline-box-break' : 'timeline-box-none'
                  break
              }
              let borderClass = ''
              if (value % 60 === 0) {
                borderClass = 'timeline-border-left'
              } else if (value === 1425) {
                borderClass = 'timeline-border-right'
              } else {
                borderClass = 'timeline-border-center'
              }
              timeline.push({label: label, value: value, borderClass: borderClass})
            }
            break
          }
        }
        return timeline
      },
      tableCellClassName ({row, column, rowIndex, columnIndex}) {
        const index = this.isReadOnly ? columnIndex : columnIndex - 1
        switch (index) {
          case 3:
            if (row.ground_code_error) {
              return 'error-cell'
            }
            break
          case 6:
            if (row.stamping_start_time_error) {
              return 'error-cell'
            }
            break
          case 7:
            if (row.stamping_end_time_error) {
              return 'error-cell'
            }
            break
          case 10:
            if (row.job_overwork_minutes_error) {
              return 'error-cell'
            }
            break
          case 12:
            if (row.legal_overwork_minutes_error) {
              return 'error-cell'
            }
            break
          case 13:
            if (row.late_night_overwork_minutes_error) {
              return 'error-cell'
            }
            break
          case 15:
            if (row.late_minutes_error) {
              return 'error-cell'
            }
            break
          case 16:
            if (row.early_departure_minutes_error) {
              return 'error-cell'
            }
            break
          case 17:
            if (row.job_holiday_hours_error) {
              return 'error-cell'
            }
            break
          case 18:
            if (row.legal_holiday_overwork_minutes_error) {
              return 'error-cell'
            }
            break
          case 19:
            if (row.paid_holiday_error) {
              return 'error-cell'
            }
            break
          case 20:
            if (row.child_time_leave_error) {
              return 'error-cell'
            }
            break
        }
        return ''
      },
      setApplyErrors () {
        if (!this.applyErrors || this.applyErrors.length === 0) return
        if (!this.isShowAll) {
          this.toggleColumns()
        }
        for (const error of this.applyErrors) {
          for (let data of this.tableData) {
            if (error.target_date === data.target_date && (!error.work_schedule_code || error.work_schedule_code === data.work_schedule_code)) {
              for (const item of error.target_attendance_item_list) {
                switch (item) {
                  case 'ground_code':
                    data.ground_code_error = error.message
                    break
                  case 'stamping_start_time':
                    data.stamping_start_time_error = error.message
                    break
                  case 'stamping_end_time':
                    data.stamping_end_time_error = error.message
                    break
                  case 'job_overwork_minutes':
                    data.job_overwork_minutes_error = error.message
                    break
                  case 'legal_overwork_minutes':
                    data.legal_overwork_minutes_error = error.message
                    break
                  case 'late_night_overwork_minutes':
                    data.late_night_overwork_minutes_error = error.message
                    break
                  case 'late_minutes':
                    data.late_minutes_error = error.message
                    break
                  case 'early_departure_minutes':
                    data.early_departure_minutes_error = error.message
                    break
                  case 'job_holiday_hours':
                    data.job_holiday_hours_error = error.message
                    break
                  case 'legal_holiday_overwork_minutes':
                    data.legal_holiday_overwork_minutes_error = error.message
                    break
                  case 'paid_holiday_days':
                  case 'paid_holiday_hours':
                    data.paid_holiday_error = error.message
                    break
                  case 'child_time_leave_days':
                  case 'child_time_leave_hours':
                    data.child_time_leave_error = error.message
                    break
                }
              }
              if (error.target_date === data.target_date && error.work_schedule_code === data.work_schedule_code) {
                break
              }
            }
          }
        }
      },
      push () {
        this.$router.push({ name: 'officeAdd' })
      },
      showGround (index, row) {
        this.applicationFormCode = '0000000003'
        const path = '/04/menu04Screen013'
        this.showWorkflow(index, row, path)
      },
      showStampCorrection (index, row, stampType) {
        let hasApplicationNumber = row.application_group.imprint_correction_application_start_time_number
        hasApplicationNumber = hasApplicationNumber || row.application_group.imprint_correction_application_end_time_number
        hasApplicationNumber = hasApplicationNumber || row.application_group.imprint_correction_application_telework_number
        if (!hasApplicationNumber && row.work_schedule_code !== this.tableDataOrg[index].work_schedule_code) {
          swal({
            text: this.$t('message.work_schedule_code_changed'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        this.applicationFormCode = '0000000001'
        const path = '/04/menu04Screen005'
        this.showWorkflow(index, row, path, stampType)
      },
      showOvertimeWorking (index, row) {
        this.applicationFormCode = '0000000002'
        const path = '/04/menu04Screen007'
        this.showWorkflow(index, row, path)
      },
      showLateNightWorking (index, row) {
        this.applicationFormCode = '0000000004'
        const path = '/04/menu04Screen008'
        this.showWorkflow(index, row, path)
      },
      showHolidayWorking (index, row) {
        this.applicationFormCode = '0000000007'
        const path = '/04/menu04Screen009'
        this.showWorkflow(index, row, path)
      },
      showLate (index, row) {
        this.applicationFormCode = '0000000005'
        const path = '/04/menu04Screen006'
        this.showWorkflow(index, row, path)
      },
      showEarlyDeparture (index, row) {
        this.applicationFormCode = '0000000006'
        const path = '/04/menu04Screen006'
        this.showWorkflow(index, row, path)
      },
      showChildTimeLeave (index, row) {
        this.applicationFormCode = '0000000008'
        const path = '/04/menu04Screen011'
        this.showWorkflow(index, row, path)
      },
      showPaidHoliday (index, row) {
        this.applicationFormCode = '0000000009'
        const path = '/04/menu04Screen014'
        this.showWorkflow(index, row, path)
      },
      showTransferHoliday (index, row) {
        this.applicationFormCode = '0000000011'
        const path = '/04/menu04Screen010'
        this.showWorkflow(index, row, path)
      },
      showWorkflow (index, row, path, stampType = null) {
        if (this.canShow(path)) {
          this.selectedRow = row
          this.selectedRow.group_code = this.monthData[0].group_code
          this.selectedRow.employee_name = this.monthData[0].employee_name
          this.selectedRow.stamp_type = stampType
          this.$modal.show('workflow')
        } else {
          swal({
            text: this.$t('message.not_authorized'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        }
      },
      hideWorkflow (isChanged, attendanceRecordErrors) {
        this.selectedRow.ground_code = this.selectedRow.before_ground_code
        this.$modal.hide('workflow')
        if (isChanged) {
          if (this.applicationFormCode === '0000000016') {
            this.applyErrors = attendanceRecordErrors
          }
          this.search()
        }
      },
      showBreak (index, row) {
        if (this.canShow('/SS/menuSSScreen020')) {
          row.min_date = this.formatDate(this.minDate)
          row.max_date = this.formatDate(this.maxDate)
          this.selectedRow = row
          this.selectedIndex = index
          this.$modal.show('break')
        } else {
          swal({
            text: this.$t('message.not_authorized'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        }
      },
      hideBreak (breakTimeData) {
        this.$modal.hide('break')
        if (!breakTimeData) return
        for (const row of this.tableData) {
          if (row.target_date === this.selectedRow.target_date && row.work_schedule_code === this.selectedRow.work_schedule_code) {
            var breakMinutes = 0
            const breakTimeList = []
            for (const breakTime of breakTimeData) {
              if (!breakTime.start_time || !breakTime.end_time) continue
              const startTime = !breakTime.start_time ? '' : this.to48Time(this.formatTime(breakTime.start_time), breakTime.start_time_next)
              const endTime = !breakTime.end_time ? '' : this.to48Time(this.formatTime(breakTime.end_time), breakTime.end_time_next)
              if (!breakTime.selected) {
                const timeBreaked = this.calcTime(startTime, endTime)
                breakMinutes += this.toMinutes(timeBreaked)
              }
              breakTimeList.push({
                company_code: breakTime.company_code,
                employee_code: breakTime.employee_code,
                target_date: breakTime.target_date,
                work_schedule_code: breakTime.work_schedule_code,
                start_time: startTime,
                end_time: endTime,
                delete: breakTime.delete,
                selected: breakTime.selected
              })
            }
            row.break_minutes = breakMinutes
            row.break_time_data = breakTimeList
            break
          }
        }
      },
      breakApply () {
        this.$modal.hide('break')
        this.showStampCorrection(this.selectedIndex, this.selectedRow, 4)
      },
      back () {
        this.$router.go(-1)
      },
      groundChanged (index, row) {
        this.beforeGroundCode = undefined
        this.beforeGroundCodeIndex = null
        if (row.is_pullback_popup) {
          let applicationNumber = null
          if (row.application_group.ground_application_number) {
            applicationNumber = row.application_group.ground_application_number
          } else if (row.application_group.paid_holiday_application_number) {
            applicationNumber = row.application_group.paid_holiday_application_number
          } else if (row.application_group.parental_leave_application_number) {
            applicationNumber = row.application_group.parental_leave_application_number
          }
          swal({
            text: this.pullbackMessage,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            width: 550,
            allowOutsideClick: false
          }).then(() => {
            let sendData = {
              screen_code: this.screenCode,
              company_code: this.tableData[0].company_code,
              group_code: this.$store.state.loginUser.groupCode,
              employee_code: this.$store.state.loginUser.employeeCode,
              api_area: {
                application_number: applicationNumber
              }
            }
            this.send('/pullback/attendance_employee', sendData)
            .then(() => {
              for (const ground of row.grounds) {
                if (row.ground_code === ground.value) {
                  if (ground.workflow_flg === 1 && this.applicationControl.is_ground_confirm_employee) {
                    row.new_ground_code = row.ground_code
                    row.ground_code = row.before_ground_code
                    row.application_group.ground_application = 3
                    row.is_pullback_popup = false
                    this.showGround(index, row)
                  }
                  break
                }
              }
            })
          }).catch(() => {
            row.ground_code = row.before_ground_code
          })
          return
        }
        let isNonStamp = false
        for (const ground of row.grounds) {
          if (row.ground_code === ground.value) {
            isNonStamp = ground.non_stamp_flg === 2
            break
          }
        }
        if (isNonStamp && row.is_delete_stamp_popup) {
          swal({
            title: this.deleteStampMessage,
            text: this.deleteStampMessageAction,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            width: 550,
            allowOutsideClick: false
          }).then(() => {
            this.updateRow(row)
          }).catch(() => {
            row.ground_code = row.before_ground_code
          })
          return
        }
        for (const ground of row.grounds) {
          if (row.ground_code === ground.value) {
            if (ground.workflow_flg === 1 && this.applicationControl.is_ground_confirm_employee) {
              this.showGround(index, row)
            } else {
              this.beforeGroundCode = row.before_ground_code
              this.beforeGroundCodeIndex = index
              this.updateRow(row)
            }
            break
          }
        }
      },
      updateRow (row) {
        if (row.id === 0) return
        this.addWorking(false)
      },
      setWorkingSumData (res) {
        this.monthData[0].flex = res.api_area.working_sum_data.working_system_type === 2
        this.monthData[0].lack_minutes = res.api_area.working_sum_data.lack_minutes
        this.monthData[0].telework_count = res.api_area.working_sum_data.telework_count
        this.monthData[0].real_total_minutes = res.api_area.working_sum_data.real_total_minutes
        this.monthData[0].job_total_minutes = res.api_area.working_sum_data.job_total_minutes
        this.monthData[0].job_overwork_minutes = res.api_area.working_sum_data.job_overwork_minutes
        this.monthData[0].job_holiday_hours = res.api_area.working_sum_data.job_holiday_hours
        this.monthData[0].legal_job_minutes = res.api_area.working_sum_data.legal_job_minutes
        this.monthData[0].legal_overwork_minutes = res.api_area.working_sum_data.legal_overwork_minutes
        this.monthData[0].legal_holiday_overwork_minutes = res.api_area.working_sum_data.legal_holiday_overwork_minutes
        this.monthData[0].late_night_overwork_minutes = res.api_area.working_sum_data.late_night_overwork_minutes
        this.monthData[0].break_minutes = res.api_area.working_sum_data.break_minutes
        this.monthData[0].late_minutes = res.api_area.working_sum_data.late_minutes
        this.monthData[0].early_departure_minutes = res.api_area.working_sum_data.early_departure_minutes
        this.monthData[0].paid_holiday_days = res.api_area.working_sum_data.paid_holiday_days
        this.monthData[0].paid_holiday_hours = res.api_area.working_sum_data.paid_holiday_hours
        this.monthData[0].compensatory_holiday_days = res.api_area.working_sum_data.compensatory_holiday_days
        this.monthData[0].closed_days = res.api_area.working_sum_data.closed_days
        this.monthData[0].job_total_days = res.api_area.working_sum_data.job_total_days
        this.monthData[0].late_days = res.api_area.working_sum_data.late_days
        this.monthData[0].early_departure_days = res.api_area.working_sum_data.early_departure_days
        this.monthData[0].job_holiday_days = res.api_area.working_sum_data.job_holiday_days
        this.monthData[0].legal_holiday_overwork_days = res.api_area.working_sum_data.legal_holiday_overwork_days
        this.monthData[0].child_time_leave_days = res.api_area.working_sum_data.child_time_leave_days
        this.monthData[0].child_time_leave_hours = res.api_area.working_sum_data.child_time_leave_hours
      },
      addWorking (updateFlag) {
        let sendData = {
          screen_code: this.screenCode,
          company_code: this.tableData[0].company_code,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: this.tableData[0].employee_code,
            target_month: this.monthData[0].target_date.substring(0, 4) + '/' + this.monthData[0].target_date.substring(4),
            update_flag: updateFlag,
            working_data: []
          }
        }
        this.errMsgs = []
        let copyData = []
        for (let data of this.tableData) {
          copyData.push(Object.assign({}, data, {}))
        }
        for (let data of copyData) {
          data.isStartTimeError = false
          data.isEndTimeError = false
          data.startTimeErrorMsg = ''
          data.endTimeErrorMsg = ''
          data.selectedColor = 'gray'
          data.selectedMsg = ''
          const beforeCount = this.errMsgs.length
          if (!data.selected) {
            const isStartBlank = !data.original_stamping_start_time || data.original_stamping_start_time.length === 0
            const isEndBlank = !data.original_stamping_end_time || data.original_stamping_end_time.length === 0
            const isStartTime = this.isTime(data.original_stamping_start_time)
            const isEndTime = this.isTime(data.original_stamping_end_time)
            if (!isStartTime) {
              data.isStartTimeError = true
              this.errMsgs.push(this.$t('message.invalid_start_time_at').replace('{date}', data.target_date))
              data.startTimeErrorMsg = this.$t('message.invalid_start_time')
            }
            if (!isEndTime) {
              data.isEndTimeError = true
              this.errMsgs.push(this.$t('message.invalid_end_time_at').replace('{date}', data.target_date))
              data.endTimeErrorMsg = this.$t('message.invalid_end_time')
            }
            if (!isStartBlank && !isEndBlank && isStartTime && isEndTime) {
              if (this.compareTime(data.original_stamping_start_time, data.original_stamping_end_time) > 0) {
                data.isStartTimeError = true
                data.isEndTimeError = true
                this.errMsgs.push(this.$t('message.inconsistent_time_at').replace('{date}', data.target_date))
                data.startTimeErrorMsg = this.$t('message.inconsistent_time')
                data.endTimeErrorMsg = this.$t('message.inconsistent_time')
              }
            }
          }
          // 削除可能チェック
          if (data.id !== 0 && data.selected) {
            // 同一日存在チェック
            let canDelete = false
            for (const tmp of this.tableData) {
              if (data.id === tmp.id) continue
              if (data.target_date === tmp.target_date) {
                canDelete = true
                break
              }
            }
            if (canDelete) {
              // 事由コードチェック
              for (const ground of data.grounds) {
                if (ground.ground_code === data.ground_code) {
                  if (ground.timecard_available_flg !== 1) {
                    this.errMsgs.push(this.$t('message.ground_not_deletable_at').replace('{date}', data.target_date))
                    data.selectedColor = 'red'
                    data.selectedMsg = this.$t('message.ground_not_deletable_at').replace('{date}', data.target_date)
                  }
                }
              }
            } else {
              this.errMsgs.push(this.$t('message.day_not_deletable_at').replace('{date}', data.target_date))
              data.selectedColor = 'red'
              data.selectedMsg = this.$t('message.day_not_deletable_at').replace('{date}', data.target_date)
            }
          }
          if (beforeCount === this.errMsgs.length) {
            if (data.id === 0 && data.selected) {
              // 新規行で削除の場合は送信しない
            } else {
              // 同一日の場合は表示されているテレワークフラグと事由を設定する。
              if (!data.is_first_target_date) {
                for (const tmp of this.tableData) {
                  if (tmp.is_first_target_date && tmp.target_date === data.target_date) {
                    data.telework_flg = tmp.telework_flg
                    data.ground_code = tmp.ground_code
                    // 同一日に同一勤務体系は選択不可
                    if (data.work_schedule_code === tmp.work_schedule_code) {
                      this.errMsgs.push(this.$t('message.duplicate_work_schedule_at').replace('{date}', data.target_date))
                    }
                    break
                  }
                }
              }
              const breakTimeList = []
              for (const breakTime of data.break_time_data) {
                if (breakTime.start_time.length === 0 && breakTime.end_time.length === 0) continue
                if (!breakTime.delete) continue
                const startTime = !breakTime.start_time ? '' : breakTime.start_time + ':00'
                const endTime = !breakTime.end_time ? '' : breakTime.end_time + ':00'
                const breakTimeData = {
                  del_flag: breakTime.selected || false,
                  start_time: startTime,
                  end_time: endTime
                }
                breakTimeList.push(breakTimeData)
              }
              sendData.api_area.working_data.push({
                id: data.id,
                target_date: data.id === 0 ? this.formatDate(data.target_date) : data.target_date,
                work_schedule_code: data.work_schedule_code,
                telework_flg: data.telework_flg,
                ground_code: data.ground_code,
                stamping_start_time: data.original_stamping_start_time ? this.formatTime(data.original_stamping_start_time) : '',
                stamping_end_time: data.original_stamping_end_time ? this.formatTime(data.original_stamping_end_time) : '',
                del_flag: data.selected,
                break_time_data: breakTimeList
              })
            }
          }
        }
        if (this.errMsgs.length === 0) {
          let workScheduleChanged = false
          const thisTableData = this.tableData.filter((row) => row.id > 0)
          for (let i = 0; i < thisTableData.length; i++) {
            if (thisTableData[i].work_schedule_code !== this.tableDataOrg[i].work_schedule_code) {
              workScheduleChanged = true
              break
            }
          }
          if (updateFlag && workScheduleChanged) {
            swal({
              html: this.$t('message.application_cancel_check'),
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              type: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('button.yes'),
              cancelButtonText: this.$t('button.no'),
              allowOutsideClick: false,
              width: 550
            }).then(() => {
              this.execAddWorking(sendData, updateFlag)
            }).catch(() => {
              for (const row of this.tableData) {
                if (row.id > 0) {
                  row.work_schedule_code = this.tableDataOrg.find((rowOrg) => row.id === rowOrg.id).work_schedule_code
                }
              }
              this.tableData = [...this.tableData]
            })
            return
          }
          this.execAddWorking(sendData, updateFlag)
        } else {
          let message = ''
          for (const row of this.errMsgs) {
            if (message === '') {
              message += row
            } else {
              message += '<br>' + row
            }
          }
          swal({
            html: message,
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.tableData = copyData
          })
        }
      },
      execAddWorking (sendData, updateFlag) {
        this.send('/update/attendance_employee/list', sendData, true, true)
          .then(res => {
            if (res.message.message_classification === null || res.message.message_classification === 1) {
              this.setWorkingSumData(res)
              this.graphData = res.api_area.graph_data
              this.tableData = res.api_area.working_data
              if (updateFlag) {
                this.tableDataOrg = []
                for (const row of this.tableData) {
                  this.tableDataOrg.push({...row})
                }
              }
              this.editTableData()
            } else {
              for (const row of this.tableData) {
                row.ground_code = row.before_ground_code
              }
            }
            this.tableKey3 += 1
          })
      },
      deleteWorking () {
        swal({
          html: this.$t('message.attendance_delete_check') + '<br>' + this.$t('message.attendance_delete_check_ryaku'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          allowOutsideClick: false,
          width: 550
        }).then(() => {
          let sendData = {
            screen_code: this.screenCode,
            company_code: this.tableData[0].company_code,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              employee_code: this.tableData[0].employee_code,
              target_month: this.monthData[0].target_date.substring(0, 4) + '/' + this.monthData[0].target_date.substring(4),
              is_delete_timestamp: true
            }
          }
          this.send('/delete/attendance_employee', sendData)
          .then(() => {
            this.isDeleted = true
          })
        }).catch(() => {})
      },
      getDispDay (date) {
        return moment(date).format('M/D')
      },
      getDispDayOfWeek (weekDay) {
        let weekdayLabel = ''
        if (weekDay === 0) {
          weekdayLabel = this.labels.monday
        } else if (weekDay === 1) {
          weekdayLabel = this.labels.tuesday
        } else if (weekDay === 2) {
          weekdayLabel = this.labels.wednesday
        } else if (weekDay === 3) {
          weekdayLabel = this.labels.thursday
        } else if (weekDay === 4) {
          weekdayLabel = this.labels.friday
        } else if (weekDay === 5) {
          weekdayLabel = this.labels.saturday
        } else if (weekDay === 6) {
          weekdayLabel = this.labels.sunday
        }
        return weekdayLabel
      },
      getDayClass (dayType) {
        if (dayType === 1) {
          return 'weekday'
        } else if (dayType === 2) {
          return 'saturday'
        } else if (dayType === 3) {
          return 'sunday'
        }
        return ''
      },
      prevMonth () {
        let dTermFrom = new Date(this.monthData[0].term_from.substring(0, 4), this.monthData[0].term_from.substring(5, 7) - 1, this.monthData[0].term_from.substring(8, 10))
        dTermFrom.setMonth(dTermFrom.getMonth() - 1)
        let dTermTo = new Date(this.monthData[0].term_to.substring(0, 4), this.monthData[0].term_to.substring(5, 7) - 1, this.monthData[0].term_to.substring(8, 10))
        dTermTo.setMonth(dTermTo.getMonth() - 1)
        let dTargetDate = new Date(this.monthData[0].target_date.substring(0, 4), this.monthData[0].target_date.substring(4, 6) - 1, '1')
        dTargetDate.setMonth(dTargetDate.getMonth() - 1)
        this.monthData[0].term_from = dTermFrom.getFullYear() + '/' + ('0' + (dTermFrom.getMonth() + 1)).slice(-2) + '/' + ('0' + dTermFrom.getDate()).slice(-2)
        this.monthData[0].term_to = dTermTo.getFullYear() + '/' + ('0' + (dTermTo.getMonth() + 1)).slice(-2) + '/' + ('0' + dTermTo.getDate()).slice(-2)
        this.monthData[0].target_date = dTargetDate.getFullYear() + ('0' + (dTargetDate.getMonth() + 1)).slice(-2)
        this.search()
      },
      nowMonth () {
        this.monthData[0].target_date = this.thisMonth.replace('/', '')
        this.search()
      },
      nextMonth () {
        let dTermFrom = new Date(this.monthData[0].term_from.substring(0, 4), this.monthData[0].term_from.substring(5, 7) - 1, this.monthData[0].term_from.substring(8, 10))
        dTermFrom.setMonth(dTermFrom.getMonth() + 1)
        let dTermTo = new Date(this.monthData[0].term_to.substring(0, 4), this.monthData[0].term_to.substring(5, 7) - 1, this.monthData[0].term_to.substring(8, 10))
        dTermTo.setMonth(dTermTo.getMonth() + 1)
        let dTargetDate = new Date(this.monthData[0].target_date.substring(0, 4), this.monthData[0].target_date.substring(4, 6) - 1, '1')
        dTargetDate.setMonth(dTargetDate.getMonth() + 1)
        this.monthData[0].term_from = dTermFrom.getFullYear() + '/' + ('0' + (dTermFrom.getMonth() + 1)).slice(-2) + '/' + ('0' + dTermFrom.getDate()).slice(-2)
        this.monthData[0].term_to = dTermTo.getFullYear() + '/' + ('0' + (dTermTo.getMonth() + 1)).slice(-2) + '/' + ('0' + dTermTo.getDate()).slice(-2)
        this.monthData[0].target_date = dTargetDate.getFullYear() + ('0' + (dTargetDate.getMonth() + 1)).slice(-2)
        this.search()
      },
      isWithinRangeDays (targetDate, rangeStartDate, rangeEndDate) {
        var targetDateTime
        var rangeStartTime
        var rangeEndTime
        var startFlag = false
        var endFlag = false

        if (!targetDate) return false

        var isArray = function (targetObject) {
          return Object.prototype.toString.call(targetObject) === '[object Array]'
        }

        // 日時をミリ秒で取得する関数
        var getDateTime = function (dateObj) {
          if (!dateObj) return

          if (typeof dateObj.getTime !== 'undefined') {
            return dateObj.getTime()
          } else if (isArray(dateObj)) {
            if (dateObj.length === 3) {
              return new Date(dateObj[0], Number(dateObj[1]) - 1, dateObj[2]).getTime()
            } else {
              return new Date(dateObj[0], Number(dateObj[1]) - 1, dateObj[2], dateObj[3], dateObj[4], dateObj[5]).getTime()
            }
          }
        }

        targetDateTime = getDateTime(targetDate)
        rangeStartTime = getDateTime(rangeStartDate)
        rangeEndTime = getDateTime(rangeEndDate)

        if (!targetDateTime) return false

        if (rangeStartDate) {
          if (rangeStartTime && targetDateTime >= rangeStartTime) {
            startFlag = true
          }
        } else {
          startFlag = true
        }

        if (rangeEndDate) {
          if (rangeEndTime && targetDateTime <= rangeEndTime) {
            endFlag = true
          }
        } else {
          endFlag = true
        }

        if (startFlag && endFlag) return true

        return false
      },
      apply () {
        this.applicationFormCode = '0000000016'
        const path = '/04/menu04Screen004'
        this.showWorkflow(0, this.tableData[0], path)
      },
      fix () {
        if (!this.applicationControl.is_absent_application || !this.applicationControl.is_ground_confirm_employee) {
          for (const row of this.tableData) {
            if ((row.ground_code === '0000000001' || row.ground_code === '0000000036') && !row.stamping_start_time && !row.stamping_end_time) {
              var isOffsite = false
              for (const workSchedule of row.work_schedule_list) {
                if (workSchedule.value === row.work_schedule_code && workSchedule.off_site_deemed_working_hours_flag) {
                  isOffsite = true
                  break
                }
              }
              if (isOffsite) continue
              swal({
                text: this.$t('message.confirm_to_replace_ground'),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('button.yes'),
                cancelButtonText: this.$t('button.no'),
                width: 550,
                allowOutsideClick: false
              }).then(() => {
                this.execFix()
              })
              return
            }
          }
        }
        this.execFix()
      },
      execFix (popupFlag = undefined) {
        let params = {
          screen_code: '04-004',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            job_month: this.monthData[0].target_date,
            screen_mode: null,
            target_group_code: this.$store.state.loginUser.groupCode,
            target_group_name: this.$store.state.loginUser.groupName,
            target_employee_code: this.$store.state.loginUser.employeeCode,
            target_employee_name: this.$store.state.loginUser.employeeName,
            popup_flag: popupFlag
          }
        }
        this.send('/workflow/apply/wf_attendance_record', params, true, true)
        .then(res => {
          if (res.api_area) {
            if (res.api_area.message && res.api_area.correspondence_action) {
              swal({
                title: res.api_area.message,
                text: res.api_area.correspondence_action,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: res.common_area.label.VueButton_change_work_day.split(',')[0],
                cancelButtonText: res.common_area.label.VueButton_change_absent_day.split(',')[0],
                width: 650,
                allowOutsideClick: false
              }).then(() => {
                this.execFix(false)
              }).catch(() => {
                this.execFix(true)
              })
              return
            } else if (res.api_area.length > 0) {
              this.applyErrors = res.api_area
            }
          }
          this.search()
        })
      },
      cancelFix () {
        let params = {
          screen_code: '04-004',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            application_number: this.attendanceApplicationNumber,
            job_month: this.monthData[0].target_date,
            screen_mode: 9,
            target_group_code: this.$store.state.loginUser.groupCode,
            target_group_name: this.$store.state.loginUser.groupName,
            target_employee_code: this.$store.state.loginUser.employeeCode,
            target_employee_name: this.$store.state.loginUser.employeeName
          }
        }
        this.send('/workflow/pullback/wf_attendance_record', params)
        .then(res => {
          if (res.api_area && res.api_area.length > 0) {
            this.applyErrors = res.api_area
          }
          this.search()
        })
      },
      init () {
        let currentYear = new Date().getFullYear()
        let i
        for (i = 0; i < 10; i++) {
          this.years.push(currentYear - 8 + i)
        }
        moment.locale('ja')
        if (this.$route.params.row) {
          this.monthData = [this.$route.params.row]
          this.aggHeaders = this.$route.params.headers
          this.applyErrors = this.$route.params.errors
          this.overtime = this.$route.params.overtime
          this.lateNight = this.$route.params.lateNight
          this.lateEarlyDeparture = this.$route.params.lateEarlyDeparture
          this.paidHoliday = this.$route.params.paidHoliday
          localStorage.setItem('params', JSON.stringify(this.$route.params))
        } else {
          const paramsString = localStorage.getItem('params')
          const params = JSON.parse(paramsString)
          this.monthData = [params.row]
          this.aggHeaders = params.headers
          this.applyErrors = params.errors
          this.overtime = params.overtime
          this.lateNight = params.lateNight
          this.lateEarlyDeparture = params.lateEarlyDeparture
          this.paidHoliday = params.paidHoliday
        }
        this.minDate = new Date(this.monthData[0].term_from)
        this.maxDate = new Date(this.monthData[0].term_to)
      },
      showChangeWorkSchedule () {
        var beforeWorkScheduleCodeList = []
        var beforeWorkScheduleList = []
        var afterWorkScheduleCodeList = []
        var afterWorkScheduleList = []
        var afterAllWorkScheduleCodeList = []
        var afterAllWorkScheduleList = []
        for (const row of this.tableData) {
          if (!beforeWorkScheduleCodeList.includes(row.work_schedule_code) && row.work_schedule_list.length > 1) {
            beforeWorkScheduleCodeList.push(row.work_schedule_code)
            for (const row2 of row.work_schedule_list) {
              if (row2.value === row.work_schedule_code) {
                beforeWorkScheduleList.push(row2)
                break
              }
            }
          }
          if (row.work_schedule_list.length > 1) {
            for (const row2 of row.work_schedule_list) {
              if (!afterWorkScheduleCodeList.includes(row2.value)) {
                afterWorkScheduleCodeList.push(row2.value)
                afterWorkScheduleList.push(row2)
                afterAllWorkScheduleCodeList.push(row2.value)
                afterAllWorkScheduleList.push(row2)
              }
            }
          }
        }
        beforeWorkScheduleList.push({
          label: this.$t('label.all'),
          label_abbreviation: this.$t('label.all'),
          value: 'zcwfms4awh'
        })
        for (const row of this.workScheduleList) {
          if (row.working_system_type === 1) {
            if (!afterWorkScheduleCodeList.includes(row.value)) {
              afterWorkScheduleCodeList.push(row.value)
              afterWorkScheduleList.push(row)
              afterAllWorkScheduleCodeList.push(row.value)
              afterAllWorkScheduleList.push(row)
            }
          } else if (row.working_system_type === 2) {
            if (!afterAllWorkScheduleCodeList.includes(row.value)) {
              afterAllWorkScheduleCodeList.push(row.value)
              afterAllWorkScheduleList.push(row)
            }
          }
        }
        this.beforeList = beforeWorkScheduleList
        this.afterList = afterWorkScheduleList
        this.afterAllList = afterAllWorkScheduleList
        this.$modal.show('changeWorkSchedule')
      },
      hideChangeWorkSchedule (detail) {
        this.$modal.hide('changeWorkSchedule')
        if (detail.isChanged) {
          for (const row of this.tableData) {
            for (const row2 of row.work_schedule_list) {
              if (row2.value === detail.afterWorkSchedule) {
                if (['zcwfms4awh', row.work_schedule_code].includes(detail.beforeWorkSchedule) && !row.is_shift_publish) {
                  row.work_schedule_code = detail.afterWorkSchedule
                }
                break
              }
            }
          }
          this.addWorking(false)
        }
      },
      showChangeGround () {
        var beforeGroundCodeList = []
        var beforeGroundList = []
        var afterGroundCodeList = []
        var afterGroundList = []
        for (const row of this.tableData) {
          if (!beforeGroundCodeList.includes(row.ground_code) && row.grounds.length > 1) {
            beforeGroundCodeList.push(row.ground_code)
            for (const row2 of row.grounds) {
              if (row2.value === row.ground_code) {
                beforeGroundList.push(row2)
                break
              }
            }
          }
          for (const row2 of row.ground_list) {
            if (!this.applicationControl.is_ground_confirm_employee || row2.workflow_flg !== 1) {
              if (!afterGroundCodeList.includes(row2.value)) {
                afterGroundCodeList.push(row2.value)
                afterGroundList.push(row2)
              }
            }
          }
        }
        this.beforeList = beforeGroundList
        this.afterList = afterGroundList
        this.$modal.show('changeGround')
      },
      hideChangeGround (detail) {
        this.$modal.hide('changeGround')
        if (detail.isChanged) {
          for (const row of this.tableData) {
            if (row.ground_code === detail.beforeGround) {
              for (const row2 of row.ground_list) {
                if (row2.value === detail.afterGround) {
                  row.ground_code = detail.afterGround
                }
              }
            }
          }
          this.addWorking(false)
        }
      }
    },
    created () {
      this.searchCond.company_code = this.$store.state.loginUser.companyCode
      this.searchCond.office_code = ''
      this.searchCond.office_name = ''
      this.searchCond.place_code = ''
      this.searchCond.place_name = ''
      this.searchCond.all_search_flag = false
      this.init()
      if (this.monthData[0].button_insert === 1) {
        this.execSearch('/insert/attendance_employee')
      } else {
        this.search()
      }
    }
  }
</script>

<style scoped>
  .errorBorder {
    border: 1px solid red;
  }
  .imprintCorrectionColor {
    color: #0000FF;
  }
  .RequiredStyle {
    padding: 0 !important; 
    color: red !important; 
    text-decoration: underline;    
  }
  .OngoingStyle {
    padding: 0 !important; 
    color: blue !important; 
    text-decoration: underline;    
  }
  .CompleteStyle {
    padding: 0 !important; 
    color: black !important; 
    text-decoration: underline;    
  }
  .NormalStyle {
    padding: 0 !important; 
    color: black !important; 
  }
  .InvalidStyle {
    padding: 0 !important; 
    color: green !important; 
    text-decoration: underline;    
  }
  .ImprintCorrectionStyle {
    padding: 0 !important; 
    color: #317FB8 !important; 
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .ground-application-style {
    background-color: lightpink;
  }
  .timeline-header {
    display: inline-block; 
    width: 4%;
    font-size: x-small; 
    text-align: left !important;
  }
  .timeline-box {
    display: inline-block; 
    width: 1%; 
    height: 20px; 
    border: none; 
    margin-top: 5px;    
  }
  .timeline-box-none {
    background-color: transparent; 
  }
  .timeline-box-work {
    background-color: #2f4f4f; 
  }
  .timeline-box-break {
    background-color: #b0c4de; 
  }
  .timeline-box-overwork {
    background-color: #c71585; 
  }
  .timeline-box-late {
    background-color: #ffa500; 
  }
  .timeline-border-left {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    border-left: 1px solid #CCC;
  }
  .timeline-border-center {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
  }
  .timeline-border-right {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
  }
  .attendance-detail >>> .el-table th > .cell {
    padding-left: 5px !important;
  }
  .attendance-detail >>> .el-table td > .cell {
    padding-left: 5px !important;
  }
  .tooltip.right-end {
    width: 100px !important;
  }
</style>
