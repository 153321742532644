<template>
  <div :class="isSearchCondition ? 'form-group search-condition-item' : 'form-group'">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <template v-if="isEmailCheck">
      <input type="checkbox" id="is_send_registration_completion_email" v-model="is_send_registration_completion_email" class="gray"/>
      <label for="is_send_registration_completion_email" style="font-size: 12px;">
        {{'従業員に登録完了通知を送信する'}}
      </label>
    </template>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <small class="help-block" v-show="valid | !validated">
          {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
        </small>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div>
        <input 
          :type="type" 
          :name="itemName"
          :placeholder="placeholder"
          :class="classes" 
          style="display: inline-block;"
          v-model="itemValue" 
          :maxlength="maxLength"
          :disabled="disabled"
          autocomplete="off"
          @blur="blur"
          @input="$emit('input', itemValue)">{{unit ? '&nbsp;' + unit : ''}}
        <template v-if="isPassword">
          <span class="field-icon">
            <i toggle="password-field" class="mdi mdi-eye toggle-password">
            </i>
          </span>
        </template>
        <template v-if="isClosingDate">
          <span>日締めで</span>
          <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-primary" @click="$emit('calcClosingDate')">
            {{$t('button.auto_input')}}
          </button>
          <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-primary" @click="$emit('calcClosingDateWithEndOfMonth')">
            {{$t('button.auto_input_with_end_of_month')}}
          </button>
        </template>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import $ from 'jquery'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        require: false,
        'default': 'text'
      },
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      isClosingDate: {
        type: Boolean,
        require: false,
        'default': false
      },
      isPassword: {
        type: Boolean,
        require: false,
        'default': false
      },
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      isSearchCondition: {
        type: Boolean,
        require: false,
        'default': false
      },
      isEmailCheck: {
        type: Boolean,
        require: false,
        'default': false
      },
      isSendRegistrationCompletionEmail: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        itemValue: null,
        is_send_registration_completion_email: this.isSendRegistrationCompletionEmail
      }
    },
    computed: {
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      },
      'is_send_registration_completion_email': function (newVal, oldVal) {
        this.$emit('setEmailFlg', newVal)
      },
      isSendRegistrationCompletionEmail () {
        this.is_send_registration_completion_email = this.isSendRegistrationCompletionEmail
      }
    },
    mounted () {
      this.itemValue = this.value
      if (this.isPassword) {
        // 目のマークの処理
        $('.toggle-password').click(function () {
          $(this).toggleClass('mdi-eye mdi-eye-off')
          var input = $(this).parent().prev('input')
          if (input.attr('type') === 'password') {
            input.attr('type', 'text')
          } else {
            input.attr('type', 'password')
          }
        })
      }
    },
    methods: {
      blur () {
        this.$emit('input', this.itemValue)
        this.$emit('blur')
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
  /*パスワードの非表示*/
  .mdi-eye:before {
    background: url(/static/img/toggleicons/eye-off.png);
    background-size: contain;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top:10px;
    right: 20px;
  }
  /*パスワードの表示*/
  .mdi-eye-off:before {
    background: url(/static/img/toggleicons/eye.png);
    background-size: contain;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top:10px;
    right: 20px;
  }
  .field-icon {
    position: absolute;
  }
</style>