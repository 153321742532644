import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser: {
      companyCode: undefined,
      companyName: undefined,
      groupCode: undefined,
      groupName: undefined,
      employeeCode: undefined,
      employeeName: undefined,
      userCode: undefined,
      sidebarLinks: [],
      groupList: [],
      officeCode: undefined,
      officeName: undefined,
      officeList: [],
      workScheduleList: [],
      isSmileMark: undefined,
      attendanceManagement: undefined,
      companyLogo: undefined,
      hireDate: undefined
    },
    userLoginUser: {
      accessMailAddress: undefined,
      companyList: [],
      corporate: undefined,
      customerId: undefined,
      defaultCompanyCode: undefined,
      mailAddress: undefined,
      userName: undefined,
      workScheduleList: [],
      sidebarLinks: []
    },
    dashboardList: [],
    dbList: [],
    ssList: [],
    iconUrl: '',
    logoUrl: '',
    noticeList: [],
    alertList: [],
    deputyApproverlList: []
  },
  mutations: {
    save (state, loginUser) {
      state.loginUser = loginUser
      localStorage.setItem('loginUser', JSON.stringify(loginUser))
    },
    userSave (state, userLoginUser) {
      state.userLoginUser = userLoginUser
      localStorage.setItem('userLoginUser', JSON.stringify(userLoginUser))
    },
    load (state) {
      const loginUser = localStorage.getItem('loginUser')
      if (loginUser && loginUser !== 'undefined') {
        state.loginUser = JSON.parse(loginUser)
      }
      const userLoginUser = localStorage.getItem('userLoginUser')
      if (userLoginUser && userLoginUser !== 'undefined') {
        state.userLoginUser = JSON.parse(userLoginUser)
      }
      const dashboardList = localStorage.getItem('dashboardList')
      if (dashboardList && dashboardList !== 'undefined') {
        state.dashboardList = JSON.parse(dashboardList)
      }
      const dbList = localStorage.getItem('dbList')
      if (dbList && dbList !== 'undefined') {
        state.dbList = JSON.parse(dbList)
      }
      const ssList = localStorage.getItem('ssList')
      if (ssList && ssList !== 'undefined') {
        state.ssList = JSON.parse(ssList)
      }
      const iconUrl = localStorage.getItem('iconUrl')
      if (iconUrl) {
        state.iconUrl = iconUrl
      }
      const logoUrl = localStorage.getItem('logoUrl')
      if (logoUrl) {
        state.logoUrl = logoUrl
      }
      const noticeList = localStorage.getItem('noticeList')
      if (noticeList && noticeList !== 'undefined') {
        state.noticeList = JSON.parse(noticeList)
      }
      const alertList = localStorage.getItem('alertList')
      if (alertList && alertList !== 'undefined') {
        state.alertList = JSON.parse(alertList)
      }
      const deputyApproverlList = localStorage.getItem('deputyApproverlList')
      if (deputyApproverlList && deputyApproverlList !== 'undefined') {
        state.deputyApproverlList = JSON.parse(deputyApproverlList)
      }
    },
    setDashboardList (state, dashboardList) {
      state.dashboardList = dashboardList
      localStorage.setItem('dashboardList', JSON.stringify(dashboardList))
    },
    setDbList (state, dbList) {
      state.dbList = dbList
      localStorage.setItem('dbList', JSON.stringify(dbList))
    },
    setSsList (state, ssList) {
      state.ssList = ssList
      localStorage.setItem('ssList', JSON.stringify(ssList))
    },
    setIconUrl (state, iconUrl) {
      state.iconUrl = iconUrl
      localStorage.setItem('iconUrl', iconUrl)
    },
    setLogoUrl (state, logoUrl) {
      state.logoUrl = logoUrl
      localStorage.setItem('logoUrl', logoUrl)
    },
    setNoticeList (state, noticeList) {
      state.noticeList = noticeList
      localStorage.setItem('noticeList', JSON.stringify(noticeList))
    },
    setAlertList (state, alertList) {
      state.alertList = alertList
      localStorage.setItem('alertList', JSON.stringify(alertList))
    },
    setDeputyApproverlList (state, deputyApproverlList) {
      state.deputyApproverlList = deputyApproverlList
      localStorage.setItem('deputyApproverlList', JSON.stringify(deputyApproverlList))
    }
  }
})
