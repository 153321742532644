import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Monthly/ShiftDecisionDetail'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import Send from '@/components/Dashboard/Views/Monthly/DesiredShiftSend'
import EmployeeSearch from '@/components/Dashboard/Views/Components/ShiftEmployeeSearch'
import MessageView from '@/components/Dashboard/Views/Monthly/ShiftDecisionMessageView'
import vSelect from 'vue-select'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

// ダミー従業員コード
const DUMMYEMPLOYEECODE = 'XXXXXXXXXXX'

export default {
  name: 'ShiftDecisionSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    PPagination,
    ValidationSelect,
    Send,
    EmployeeSearch,
    MessageView,
    vSelect
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1635,
      modalSendWidth: 1000,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      dayInfo: undefined,
      addRowData: {},
      searchCond: {
        office_code: '',
        target_year: '',
        target_month: ''
      },
      labels: {},
      mode: 1,
      params: {},
      sendParams: {},
      messageViewParams: {},
      comboData: {},
      tableWrapperStyle: '',
      tableStyle: '',
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dayWidth: 80,
      detailWidth: 80,
      searchResult: {
        office_code: '',
        business_type: '',
        target_year: '',
        target_month: '',
        shift_publish_flg: 0,
        term_from: '',
        term_to: '',
        unset_holiday_flg: true,
        update_count: 0,
        isAddRow: false,
        isDeleteCheck: false,
        isSeletChange: false,
        isButtonSimple: true,
        employee_code: '',
        message: ''
      },
      workScheduleList: [],
      maxHeight: 440,
      employeeSearchCond: {},
      showNotify: false
    }
  },
  computed: {
    screenCode () {
      return '06-006'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    // 編集中(行追加、プルダウンの変更、削除にチェック)の場合、モーダル編集不可
    isModalEdit () {
      return !this.searchResult.isAddRow && !this.searchResult.isSeletChange && !this.searchResult.isDeleteCheck
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    shiftTerm () {
      const shiftTerm = []
      shiftTerm.push(this.labels.office)
      shiftTerm.push('：')
      shiftTerm.push(this.getLabel(this.getSelectName(this.searchResult.office_code, this.comboData.office_list)))
      shiftTerm.push('　')
      shiftTerm.push(this.$t('label.term'))
      shiftTerm.push('：')
      shiftTerm.push(this.formatDateWith(this.searchResult.term_from, 'YYYY/M/D(ddd)'))
      shiftTerm.push(' - ')
      shiftTerm.push(this.formatDateWith(this.searchResult.term_to, 'YYYY/M/D(ddd)'))
      return shiftTerm.join('')
    },
    disableButton () {
      if (this.searchResult.shift_publish_flg === 2) return true
      for (const data of this.tableData) {
        // 2段目の場合、次の要素を処理
        if (data.row === 2) {
          continue
        }
        // 削除がtrueの場合、次の要素を処理
        if (data.delete_check) {
          continue
        }
        // 公開の場合、確定済以外が存在する場合エラー
        if (this.searchResult.shift_publish_flg === 1) {
          // 1カ月分の情報をループ
          for (const dailyDetail of data.daily_details) {
            if (dailyDetail.count !== 0) {
              return false
            }
          }
        }
      }
      return true
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      if (this.comboData.office_list.length !== 0) {
        this.searchCond.office_code = this.comboData.office_list[0].value
      }
      let today = new Date()
      // 期間に来月を設定
      if (today.getMonth() !== 11) {
        this.searchCond.target_year = today.getFullYear()
        this.searchCond.target_month = today.getMonth() + 2
      // 12月の場合、翌年の1月を設定
      } else {
        this.searchCond.target_year = today.getFullYear() + 1
        this.searchCond.target_month = 1
      }
      this.workScheduleList = JSON.parse(JSON.stringify(res.common_area.combo_data.work_schedule_list))
      // アラートから遷移
      if (this.$route.params.attendanceRecord) {
        this.searchCond.office_code = this.$route.params.attendanceRecord.office_code
        this.searchCond.target_year = this.$route.params.attendanceRecord.target_year
        this.searchCond.target_month = this.$route.params.attendanceRecord.target_month
        this.search()
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res, isButtonSimple) {
      // 更新で非活性になったのを解除
      this.isChanged = false
      // 検索結果を設定
      this.searchResult.office_code = res.api_area.office_code
      this.searchResult.target_year = res.api_area.target_year
      this.searchResult.target_month = res.api_area.target_month
      this.searchResult.term_from = res.api_area.term_from
      this.searchResult.term_to = res.api_area.term_to
      this.searchResult.unset_holiday_flg = res.api_area.unset_holiday_flg
      this.searchResult.update_count = res.api_area.update_count
      this.searchResult.isAddRow = false
      this.searchResult.isSeletChange = false
      this.searchResult.isDeleteCheck = false
      this.searchResult.employee_code = ''
      this.searchResult.messageList = []
      this.searchResult.shift_publish_flg = res.api_area.shift_publish_flg
      this.searchResult.isButtonSimple = isButtonSimple
      // 簡易表示の場合
      if (isButtonSimple) {
        // 日付の横幅を80に設定
        this.dayWidth = 80
        // 詳細表示の場合
      } else {
        // 日付の横幅を詳細用横幅に設定
        this.dayWidth = this.detailWidth
      }
      // シフト公開フラグが公開の場合、viewを設定
      if (this.searchResult.shift_publish_flg === 1) {
        this.mode = this.modes.view
      } else {
        this.mode = this.modes.update
      }
      // 1カ月分の日付リスト
      this.dayInfo = res.api_area.day_info
      // 行追加のひな形作成
      this.addRowData.business_type = ''
      this.addRowData.work_schedule_code = ''
      this.addRowData.business_type_before = ''
      this.addRowData.work_schedule_code_before = ''
      this.addRowData.business_type_error = ''
      this.addRowData.work_schedule_code_error = ''
      this.addRowData.row = 0
      this.addRowData.adjustment_for_added_line_flag = true
      this.addRowData.is_row_edit = true
      this.addRowData.daily_details = []
      for (const date of this.dayInfo) {
        let dayInfo = {}
        dayInfo.date = date
        dayInfo.count = 0
        dayInfo.employee_list = []
        dayInfo.total_status = ''
        this.addRowData.daily_details.push(dayInfo)
      }
      // 0件の場合、行追加
      if (this.tableData.length === 0) {
        this.addRow()
      }
      // 1段目2段目判定用
      let count = 0
      // 詳細横幅用の最大バイト数
      let maxBytes = 0
      // テーブルデータをループ
      for (const data of this.tableData) {
        // 検索結果の職種、勤務体系を退避
        data.business_type_before = data.business_type
        data.work_schedule_code_before = data.work_schedule_code
        // エラーを初期化
        data.business_type_error = ''
        data.work_schedule_code_error = ''
        count++
        // 1段目か2段目の情報を追加
        if (count % 2 !== 0) {
          data.row = 1
        } else {
          data.row = 2
        }
        // 1段目の場合
        if (data.row === 1) {
          // 編集可能フラグ
          let isRowEdit = true

          // 1カ月分の情報をループ
          for (const dailyDetail of data.daily_details) {
            // 1件でもcountが存在する場合、編集不可
            if (dailyDetail.count !== 0) {
              isRowEdit = false
            }

            // 1日分の従業員をループ
            for (const employee of dailyDetail.employee_list) {
              // 詳細横幅用の最大バイト数を取得(名前＋時間)
              let bytes = this.getBytes(employee.employee_name + employee.work_time)
              if (maxBytes < bytes) {
                maxBytes = bytes
              }
              // 従業員コードがブランクの場合、ダミー従業員コードを設定
              if (employee.employee_code === '') {
                employee.employee_code = DUMMYEMPLOYEECODE
              }
            }
          }
          data.is_row_edit = isRowEdit
        }
      }
      // 詳細表示の横幅を設定
      this.detailWidth = maxBytes * 6 <= 80 ? 80 : maxBytes * 6

      // 従業員リストを設定
      let employeeList = []
      for (const employee of res.api_area.employee_list) {
        let employeeData = {}
        employeeData.value = employee.employee_code
        employeeData.label = employee.employee_name
        employeeList.push(employeeData)
      }
      this.comboData.employee_list = employeeList
      // アラートから遷移、target_dayがある場合サブ画面を開く
      if (this.$route.params.attendanceRecord && this.$route.params.attendanceRecord.target_day) {
        const day = this.dayInfo.find((d) => d.substring(d.lastIndexOf('/') + 1) === this.$route.params.attendanceRecord.target_day)
        this.handleEdit(day, this.isReadOnly || !this.isModalEdit)
        this.$route.params.attendanceRecord = undefined
      }
    },
    officeCodeChanged () {},
    deleteCheckChanged () {
      this.searchResult.isDeleteCheck = false
      for (const data of this.tableData) {
        if (data.delete_check) {
          this.searchResult.isDeleteCheck = true
          break
        }
      }
    },
    businessTypeChanged (row) {
      if (row.business_type === row.business_type_before && row.work_schedule_code === row.work_schedule_code_before) {
        this.searchResult.isSeletChange = false
      } else {
        this.searchResult.isSeletChange = true
      }
    },
    workScheduleCodeChanged (row) {
      if (row.business_type === row.business_type_before && row.work_schedule_code === row.work_schedule_code_before) {
        this.searchResult.isSeletChange = false
      } else {
        this.searchResult.isSeletChange = true
      }
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      // String変換、0埋め
      searchCond.target_year = String(searchCond.target_year)
      searchCond.target_month = ('00' + searchCond.target_month).slice(-2)
      return searchCond
    },
    doUpdate (shiftPublishFlg) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate(shiftPublishFlg)
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.componentKey += 1
          return
        }
        if (shiftPublishFlg !== this.searchResult.shift_publish_flg && shiftPublishFlg === 1) {
          swal({
            title: this.$t('message.shift_publish_confirmation_title'),
            html: this.$t('message.shift_publish_confirmation_html'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false,
            width: '600px'
          }).then(() => {
            this.update(shiftPublishFlg)
          })
        } else {
          this.update(shiftPublishFlg)
        }
      })
    },
    update (shiftPublishFlg) {
      let detail = JSON.parse(JSON.stringify(this.tableData))
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertDetail(detail, shiftPublishFlg)
      }
      this.message = {}
      this.showNotify = false
      this.send('/update/shift_schedule_adjustment/list', reqData)
      .then(res => {
        // 再描画
        this.pagination.currentPage = 1
        this.tableData = res.api_area.shift_schedule_adjustment_list
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res, this.searchResult.isButtonSimple)
        this.componentKey += 1
        if (res.message.message_classification === 1) {
          this.showNotify = true
        }
      }).catch(error => {
        console.error(error)
      })
    },
    // 独自バリデーション用
    customValidate (shiftPublishFlg) {
      let isSuccess = true
      // メッセージ初期化
      this.searchResult.messageList = []
      // PKチェック用
      let pkList = []
      // 公開フラグ
      let isPublic = shiftPublishFlg === 1 && this.searchResult.shift_publish_flg !== shiftPublishFlg
      // 公開チェック用
      let publicDateList = []
      for (const data of this.tableData) {
        // 2段目の場合、次の要素を処理
        if (data.row === 2) {
          continue
        }
        // メッセージ初期化
        data.business_type_error = ''
        data.work_schedule_code_error = ''
        // 削除がtrueの場合、次の要素を処理
        if (data.delete_check) {
          continue
        }
        // 職種は必須
        if (data.business_type === '') {
          data.business_type_error = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // 勤務体系は必須
        if (data.work_schedule_code === '') {
          data.work_schedule_code_error = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // PK(職種+勤務体系)を設定
        if (data.business_type !== '' && data.work_schedule_code !== '') {
          pkList.push(data.business_type + '_' + data.work_schedule_code)
        }
        // 公開の場合、確定済以外が存在する場合エラー
        if (isPublic) {
          // 1カ月分の情報をループ
          for (const dailyDetail of data.daily_details) {
            // 1日分の従業員をループ
            for (const employee of dailyDetail.employee_list) {
              // 従業員コードがブランクの場合、次の要素を処理
              if (employee.employee_code === '') {
                continue
              }
              // ステータスがブランク以外が存在する場合、エラー対象日を設定
              if (employee.status !== '') {
                publicDateList.push(dailyDetail.date)
                break
              }
            }
          }
        }
      }
      // PKListと重複削除したPKListの件数が一致しない場合、エラー
      if (pkList.length !== new Set(pkList).size) {
        this.searchResult.messageList.push(this.$t('message.shift_pk_check'))
        isSuccess = false
      }
      // publicDateListが0件以外の場合、エラー
      if (publicDateList.length !== 0) {
        let item = ''
        for (const publicDate of publicDateList) {
          if (item === '') {
            item = this.formatDateWith(publicDate, 'M/D(ddd)')
          } else {
            item += '、 ' + this.formatDateWith(publicDate, 'M/D(ddd)')
          }
        }
        this.searchResult.messageList.push(this.$t('message.is_public').replace('{item}', item))
        isSuccess = false
      }

      return isSuccess
    },
    // 送信データ調整用
    convertDetail (details, shiftPublishFlg) {
      let apiArea = {}
      let shiftScheduleAdjustmentList = []
      // detailの内容を更新APIに整形する
      let row1DeleteCheck = null
      let row1BusinessType = null
      let row1WorkScheduleCode = null
      for (const shiftScheduleAdjustment of details) {
        let deleteCheck = shiftScheduleAdjustment.delete_check
        // 2段目にも削除フラグを反映
        if (shiftScheduleAdjustment.row === 1) {
          row1DeleteCheck = deleteCheck
        } else {
          deleteCheck = row1DeleteCheck
        }
        // 削除フラグがtrueの場合、次の要素を処理する
        if (deleteCheck) {
          continue
        }
        let businessType = shiftScheduleAdjustment.business_type
        let workScheduleCode = shiftScheduleAdjustment.work_schedule_code
        // 2段目にも職種、勤務体系を反映
        if (shiftScheduleAdjustment.row === 1) {
          row1BusinessType = businessType
          row1WorkScheduleCode = workScheduleCode
        } else {
          businessType = row1BusinessType
          workScheduleCode = row1WorkScheduleCode
        }

        let dailyDetails = []
        for (const dailyDetail of shiftScheduleAdjustment.daily_details) {
          for (const employee of dailyDetail.employee_list) {
            // ダミー従業員コードをブランクに戻す
            if (employee.employee_code === DUMMYEMPLOYEECODE) {
              employee.employee_code = ''
            }
          }
          dailyDetails.push(dailyDetail)
        }

        // 1行の情報を設定
        let updateshiftScheduleAdjustment = {}
        updateshiftScheduleAdjustment.business_type = businessType
        updateshiftScheduleAdjustment.work_schedule_code = workScheduleCode
        updateshiftScheduleAdjustment.daily_details = dailyDetails
        shiftScheduleAdjustmentList.push(updateshiftScheduleAdjustment)
      }

      // api_areaの情報を設定
      apiArea.shift_publish_flg = shiftPublishFlg
      apiArea.target_year = this.searchResult.target_year
      apiArea.target_month = this.searchResult.target_month
      apiArea.office_code = this.searchResult.office_code
      apiArea.shift_schedule_adjustment_list = shiftScheduleAdjustmentList
      apiArea.unset_holiday_flg = this.searchResult.unset_holiday_flg
      apiArea.update_count = parseInt(this.searchResult.update_count, 10)

      return apiArea
    },
    print () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_month: String(this.searchResult.target_year) + ('0' + String(this.searchResult.target_month)).slice(-2),
          office_code: this.searchResult.office_code
        }
      }
      this.send('/print/shift_schedule_adjustment/list', reqData)
      .then(res => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
      .catch(error => {
        console.error(error)
      })
    },
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search (isButtonSimple = true, scrollY = 0) {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/shift_schedule_adjustment/list', reqData)
      .then(res => {
        this.showNotify = false
        this.pagination.currentPage = 1
        this.tableData = res.api_area.shift_schedule_adjustment_list
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res, isButtonSimple)
        if (this.tableData.length > 0 && this.showSearchCondition) {
          this.toggleSearchCondition()
        }
        this.componentKey += 1
        this.$nextTick(() => {
          let width = 0
          if (!this.$refs.table) return
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
          }
          this.$refs.pager.style.width = width + 'px'
          this.maxHeight = window.innerHeight - 485
          this.$refs.table.$refs.bodyWrapper.scrollLeft = scrollY
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.employee_code
        }
      }
      this.send('/init/shift_schedule_adjustment/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    handleView (day, isDisabled) {
      // 非活性の場合、処理を終了
      if (isDisabled) {
        return
      }
      this.params = {}
      this.params.office_code = this.searchResult.office_code
      this.params.day = day
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (day, isDisabled) {
      // 非活性の場合、処理を終了
      if (isDisabled) {
        return
      }
      this.params = {}
      this.params.office_code = this.searchResult.office_code
      this.params.day = day
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.wage_ledger_code = row.wage_ledger_code
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search(this.searchResult.isButtonSimple, parseInt(this.$refs.table.$refs.bodyWrapper.scrollLeft, 10))
      }
    },
    handleSend () {
      this.sendParams = {
        office_code: this.searchResult.office_code,
        target_month: String(this.searchResult.target_year) + ('0' + String(this.searchResult.target_month)).slice(-2)
      }
      this.mode = this.modes.edit
      this.$modal.show('send')
    },
    sendHide (...args) {
      this.$modal.hide('send')
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      } else if (row.update_date) {
        if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
          return 'update-row'
        }
      }
      return ''
    },
    // 従業員検索表示(子用)
    showEmployeeSearchModal (businessType, targetDate, workScheduleCode) {
      this.employeeSearchCond.search_type = '1'
      // this.employeeSearchCond.office_code = this.searchCond.office_code
      this.employeeSearchCond.business_type = businessType
      this.employeeSearchCond.target_date = targetDate
      this.employeeSearchCond.work_schedule_code = workScheduleCode
      this.$modal.show('employeeSearchModal')
    },
    // 従業員検索閉じる(子用)
    hideEmployeeSearchModal (selected) {
      // 従業員が選ばれた場合、子のhideEmployeeSearchModalを実施
      if (selected) {
        this.$refs.detail.hideEmployeeSearchModal(selected)
      }
      this.$modal.hide('employeeSearchModal')
    },
    // メッセージ一覧表示(子用)
    showMessageView (messageList, message, isDisabled, loginUserName, labels) {
      this.messageViewParams.messageList = messageList
      this.messageViewParams.message = message
      this.messageViewParams.isDisabled = isDisabled
      this.messageViewParams.loginUserName = loginUserName
      this.messageViewParams.labels = labels
      this.$modal.show('messageView')
    },
    // メッセージ一覧閉じる(子用)
    hideMessageView (detail) {
      // detailが存在する場合、子のhideMessageViewを実施
      if (detail != null) {
        this.$refs.detail.hideMessageView(detail)
      }
      this.$modal.hide('messageView')
    },
    getDate (value) {
      if (!value.endsWith('a')) {
        const date = new Date(value)
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${month}/${day}`
      }
    },
    isArray (data) {
      return data instanceof Array
    },
    // テーブル結合処理
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      // 詳細表示の場合、全ての項目を結合
      if (!this.searchResult.isButtonSimple) {
        // 1段目を縦に結合、2段目は表示しない
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      // 簡易表示の場合、先頭の3項目(削除、職種、勤務体系)を結合
      if (columnIndex < 3) {
        // 1段目を縦に結合、2段目は表示しない
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    getBusinessAndWorkSchedule (key) {
      let keySplit = key.split('AnD')
      let businessType = keySplit[0]
      let workScheduleCode = keySplit[1]
      let businessName = ''
      for (let business of this.comboData.business_list) {
        if (business.value === businessType) {
          businessName = business.label
          break
        }
      }
      let workScheduleName = ''
      for (let workSchedule of this.workScheduleList) {
        if (workSchedule.value === workScheduleCode) {
          workScheduleName = workSchedule.label
          break
        }
      }
      return workScheduleName + '<br>' + businessName
    },
    // 行追加
    addRow () {
      // 行追加フラグをtrue
      this.searchResult.isAddRow = true
      // 1段目追加
      let addRowData1 = JSON.parse(JSON.stringify(this.addRowData))
      addRowData1.row = 1
      this.tableData.push(addRowData1)
      // 2段目追加
      let addRowData2 = JSON.parse(JSON.stringify(this.addRowData))
      addRowData2.row = 2
      this.tableData.push(addRowData2)
      // スクロールバー設定
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
    },
    // 表示切替
    switchView (flag) {
      // 現在の表示
      this.searchResult.isButtonSimple = flag
      // 切替前のスクロール情報
      let scrollLeftBefore = this.$refs.table.$refs.bodyWrapper.scrollLeft
      let widthBefore = parseInt(this.$refs.table.$refs.bodyWrapper.firstChild.style.width, 10)
      // 簡易表示の場合
      if (flag) {
        // 日付の横幅を80に設定
        this.dayWidth = 80
        // 詳細表示の場合
      } else {
        // 日付の横幅を詳細用横幅に設定
        this.dayWidth = this.detailWidth
      }
      this.componentKey += 1
      this.$nextTick(() => {
        this.$refs.table.$refs.bodyWrapper.scrollLeft = parseInt(parseInt(this.$refs.table.$refs.bodyWrapper.firstChild.style.width, 10) / widthBefore * scrollLeftBefore)
      })
    },
    // 勤務時間取得
    getWorkScheduleTime (workScheduleCode) {
      // 選択された値のindex取得
      const index = this.workScheduleList.findIndex(option => option.value === workScheduleCode)
      // 存在しない場合、ブランクを返却
      if (index === -1) {
        return '　'
      }
      const jobStart = this.workScheduleList[index].job_start
      const jobEnd = this.workScheduleList[index].job_end
      if (!jobStart || !jobEnd) {
        return '　'
      }
      const wrkScheduleTime = []
      wrkScheduleTime.push(this.zeroSuppress(jobStart))
      wrkScheduleTime.push(' - ')
      wrkScheduleTime.push(this.zeroSuppress(this.to24Time(jobEnd)))
      return wrkScheduleTime.join('')
    }
  },
  created () {
    this.init()
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
  }
}
