<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-12 col-sm-12" id="notation-on-law">
                <table style="margin: 0 auto;">
                  <caption>特定商取引法に基づく表記</caption>
                  <tr>
                    <th>
                      販売事業者
                    </th>
                    <td>
                      株式会社PocketSoft
                    </td>
                  </tr>
                  <tr>
                    <th>
                      代表取締役
                    </th>
                    <td>
                      三鬼　竜太
                    </td>
                  </tr>
                  <tr>
                    <th>
                      所在地
                    </th>
                    <td>
                      〒461-0001　名古屋市東区泉１－１６－７
                    </td>
                  </tr>
                  <tr>
                    <th>
                      電話番号
                    </th>
                    <td>
                      052-265-6881
                    </td>
                  </tr>
                  <tr>
                    <th>
                      連絡先メールアドレス
                    </th>
                    <td>
                      artemis-support@pocket-soft.co.jp
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ホームページURL
                    </th>
                    <td>
                      https://cloud-artemis.com
                    </td>
                  </tr>
                  <tr>
                    <th>
                      販売価格
                    </th>
                    <td>
                      最低月額5,000円(税抜)
                      <br>
                      ※最低ライセンス料は10名&nbsp;&nbsp;1名500円
                    </td>
                  </tr>
                  <tr>
                    <th>
                      商品代金以外の必要料金
                    </th>
                    <td>
                      なし
                    </td>
                  </tr>
                  <tr>
                    <th>
                      引き渡し時期
                    </th>
                    <td>
                      即時
                    </td>
                  </tr>
                  <tr>
                    <th>
                      お支払い方法
                    </th>
                    <td>
                      クレジットカード決済
                    </td>
                  </tr>
                  <tr>
                    <th>
                      お支払い時期
                    </th>
                    <td>
                      各カード会社引き落とし日
                    </td>
                  </tr>
                  <tr>
                    <th>
                      サービス購読後のキャンセルについて
                    </th>
                    <td>
                      お客様のご都合によるキャンセルの返金は対応していません。
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
  #notation-on-law > table > caption {
    font-size: large;
    padding: 20px 0;
  }
  #notation-on-law > table > tr > th {
    background-color: #364f6b;
    color: #CCC;
    border: 1px solid #CCC;
    padding: 10px 20px;
  }
  #notation-on-law > table > tr > td {
    border: 1px solid #CCC;
    padding: 10px 20px;
  }
</style>
