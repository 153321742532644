<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="attendanceRecord" :draggable="true" :clickToClose="false" height="auto" :width="1600" :pivotX="0.9">
      <div class="modal-body">
        <AttendanceRecordDetail @close="closeAttendanceRecord"/>
      </div>
    </modal>
    <form method="#" action="#">
      <div class="col-sm-6">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack">{{$t('button.back_to_list_page')}}</button><br><br>
      </div>
      <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="salary_tran" div-style="padding-bottom: 5px;" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button type="button" v-if="0<this.params.index" class="btn btn-sm btn-default btn-warning" @click="preData">{{$t('button.back_employee')}}</button>
            <button type="button" v-if="this.params.index<rowMaxLength" class="btn btn-sm btn-default btn-warning" @click="nextData">{{$t('button.next_employee')}}</button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <!-- 対象月 -->
              <!-- 労働月の範囲(開始) -->
              <!-- 労働月の範囲(終了) -->
              <h5>
                <strong>
                  {{detail.target_date.substring(0, 4)}}{{$t('label.year')}}{{detail.target_date.substring(4)}}{{$t('label.monthly')}}
                  ({{detail.term_from}}-{{detail.term_to}})
                </strong>
              </h5>
              {{labels.layout_code}}:{{detail.layout_code}}
              <br />
              {{labels.employee_name}}:{{detail.employee_code}}&nbsp;{{detail.employee_name}}
              <br />
              <br />
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="showAttendanceRecord(detail)" v-show="attendanceManagement!==2">
                {{$t('button.show_attendance_record')}}
              </button>
              <hr />
              <label>{{$t('label.attendance')}}</label>
              <br />
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="workingDataTableBase()"
                ref="table"
                style="width: 1000;"
              >
                <!-- 労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.real_total_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.real_total_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="real_total_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 所定労働出勤日数 -->
                <el-table-column align="center" min-width="120" :label="labels.job_total_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.job_total_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="job_total_days"
                    rules="required_simple|decimal"
                    max-length="6"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 所定労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.job_total_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.job_total_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="job_total_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>

                <!-- 所定外労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.job_overwork_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.job_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="job_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 法定労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.legal_job_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.legal_job_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_job_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 法定外労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.legal_overwork_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.legal_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 深夜労働時間 -->
                <el-table-column align="center"  min-width="120" :label="labels.late_night_overwork_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.late_night_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="late_night_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>

                <!-- 所定休日労働日数 -->
                <el-table-column align="center"  min-width="120" :label="labels.job_holiday_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.job_holiday_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="job_holiday_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 所定休日労働時間 -->
                <el-table-column align="center"  min-width="120" :label="labels.job_holiday_hours_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.job_holiday_hours"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="job_holiday_hours"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 法定休日労働日数 -->
                <el-table-column align="center"  min-width="120" :label="labels.legal_holiday_overwork_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.legal_holiday_overwork_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="legal_holiday_overwork_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 法定休日労働時間 -->
                <el-table-column
                  align="center"
                  min-width="120"
                  :label="labels.legal_holiday_overwork_minutes_ryaku"
                >
                  <ValidationTextWorkTime
                    v-model="detail.legal_holiday_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_holiday_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 休憩時間 -->
                <el-table-column align="center" min-width="120" :label="labels.break_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.break_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="break_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
              </el-table>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="workingDataTableBase()"
                ref="table"
                style="width: 1000"
              >
                <!-- 45時間以内の法定外労働時間 -->
                <el-table-column
                  min-width="120"
                  align="center"
                  :label="labels.legal_within_45_overwork_minutes_ryaku"
                >
                  <ValidationTextWorkTime
                    v-model="detail.legal_within_45_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_within_45_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 45時間を超過した法定外労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.legal_45_overwork_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.legal_45_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_45_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 60時間を超過した法定外労働時間 -->
                <el-table-column align="center" min-width="120" :label="labels.legal_60_overwork_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.legal_60_overwork_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="legal_60_overwork_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 遅刻回数 -->
                <el-table-column align="center" min-width="120" :label="labels.late_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.late_days"
                    :classes="detail.is_disp_late_time && !detail.is_flex ? 'form-control numLength3' : 'form-control numLength3 white-color'"
                    :disabled="isReadOnly"
                    item-name="late_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.count')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 遅刻時間 -->
                <el-table-column align="center" min-width="120" :label="labels.late_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.late_minutes"
                    :classes="detail.is_disp_late_time && !detail.is_flex ? 'form-control numLength6' : 'form-control numLength6 white-color'"
                    :disabled="isReadOnly"
                    item-name="late_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 早退回数 -->
                <el-table-column align="center" min-width="120" :label="labels.early_departure_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.early_departure_days"
                    :classes="detail.is_disp_late_time && !detail.is_flex ? 'form-control numLength3' : 'form-control numLength3 white-color'"
                    :disabled="isReadOnly"
                    item-name="early_departure_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.count')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 早退時間 -->
                <el-table-column align="center" min-width="120" :label="labels.early_departure_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.early_departure_minutes"
                    :classes="detail.is_disp_late_time && !detail.is_flex ? 'form-control numLength6' : 'form-control numLength6 white-color'"
                    :disabled="isReadOnly"
                    item-name="early_departure_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 有給日数 -->
                <el-table-column align="center" min-width="120" :label="labels.paid_holiday_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.paid_holiday_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="paid_holiday_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 有給時間数 -->
                <el-table-column align="center" min-width="120" :label="labels.paid_holiday_hours_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.paid_holiday_hours"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="paid_holiday_hours"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 代休日数 -->
                <el-table-column align="center" min-width="120" :label="labels.compensatory_holiday_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.compensatory_holiday_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="compensatory_holiday_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>

                <!-- 欠勤日数 -->
                <el-table-column align="center" min-width="120" :label="labels.absent_total_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.absent_total_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="absent_total_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 休職日数 -->
                <el-table-column align="center" min-width="120" :label="labels.leave_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.leave_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="leave_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
              </el-table>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="workingDataTableBase()"
                ref="table"
                style="width: 1000"
              >
                <!-- 休職日数 -->
                <el-table-column align="center" min-width="120" :label="labels.closed_days_ryaku">
                  <ValidationTextTable
                    v-model="detail.closed_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="closed_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 集計期間の不足時間(給与控除が必要な時間) -->
                <el-table-column align="center" min-width="120" :label="labels.lack_minutes_ryaku">
                  <ValidationTextWorkTime
                    v-model="detail.lack_minutes"
                    classes="form-control numLength6"
                    :disabled="isReadOnly"
                    item-name="lack_minutes"
                    rules="required_simple|isWorkTime"
                    max-length="6"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 見込み残業時間 -->
                <el-table-column align="center" min-width="120" :label="labels.estimated_overtime_hours_ryaku">
                  <ValidationTextTable
                    v-model="detail.estimated_overtime_hours"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="estimated_overtime_hours"
                    rules="required_simple|decimal"
                    max-length="2"
                    :unit="$t('label.hours')"
                    @input="detail={...detail}"
                  />
                </el-table-column>

                <!-- テレワーク回数 -->
                <el-table-column align="center" min-width="120" :label="labels.telework_count_ryaku">
                  <ValidationTextTable
                    v-model="detail.telework_count"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="telework_count"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.count')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 育児時間休暇数(日) -->
                <el-table-column align="center" min-width="120" :label="$t('label.child_time_leave_days')">
                  <ValidationTextTable
                    v-model="detail.child_time_leave_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="child_time_leave_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 育児時間休暇数 -->
                <el-table-column align="center" min-width="120" :label="$t('label.child_time_leave_hours')">
                  <ValidationTextTable
                    v-model="detail.child_time_leave_hours"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="child_time_leave_hours"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.hours')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <!-- 除外日 -->
                <el-table-column align="center" min-width="120" :label="labels.blackout_days">
                  <ValidationTextTable
                    v-model="detail.blackout_days"
                    classes="form-control numLength3"
                    :disabled="isReadOnly"
                    item-name="blackout_days"
                    rules="required_simple|decimal"
                    max-length="3"
                    :unit="$t('label.day')"
                    @input="detail={...detail}"
                  />
                </el-table-column>
                <el-table-column align="center" min-width="120" label></el-table-column>
                <el-table-column align="center" min-width="120" label></el-table-column>
                <el-table-column align="center" min-width="120" label></el-table-column>
                <el-table-column align="center" min-width="120" label></el-table-column>
                <el-table-column align="center" min-width="120" label></el-table-column>
              </el-table>
              <br />
              <br />
              <hr />
              <!-- 支給額 -->
              <label>{{$t('label.payment_salary_item_code')}}</label>
              <br />
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="payment_salary_item01()"
                ref="table"
                style="width: 1000"
              >
                <el-table-column
                  align="center"
                  v-for="n of 10"
                  :key="n"
                  min-width="144"
                  :label="payment_salary_item01()[0]['payment_salary_item_name' + n ]"
                >
                  <div style="text-align: right;">
                    <template v-if="detail['payment_salary_item_code' + n  ] === '' ">
                      &nbsp;
                      <br />&nbsp;
                      <br />
                    </template>
                    <template v-else>
                      <ValidationTextNumber
                        v-model="detail['payment_salary_item_code' + n +'_amount']"
                        integral="10"
                        :rules="isNotEditable(detail['payment_salary_item_code' + n ], isPaymentHandType(n)) ? '' : 'required_simple|decimal_simple|min_value:-999999999|max_value:9999999999'"
                        classes="form-control numLength10"
                        :placeholder="detail['payment_salary_item_code' + n ] === '' ? ''  :  '10,000'"
                        :disabled="isReadOnly || isNotEditable(detail['payment_salary_item_code' + n ], isPaymentHandType(n))"
                        :is-replace="false"
                        @input="detail={...detail}"
                      />
                      <div style="text-align: center;">
                        <template v-if="showEditIcon(detail['payment_salary_item_code' + n ], detail['payment_salary_item_code' + n + '_can_change'])">
                          <a href="#" @click.prevent="updatePaymentHandType(n, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isPaymentHandType(n)"><i class="ti-pencil-alt"></i></a>
                          <a href="#" @click.prevent="updatePaymentHandType(n, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isPaymentHandType(n)"><i class="ti-back-left"></i></a>
                        </template>
                        <template v-else>
                          &nbsp;
                        </template>
                      </div>
                    </template>
                  </div>
                </el-table-column>
              </el-table>

              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="payment_salary_item11()"
                ref="table"
                style="width: 1000"
              >
                <el-table-column
                  align="center"
                  v-for="n of 10"
                  :key="n"
                  min-width="144"
                  :label="payment_salary_item11()[0]['payment_salary_item_name' + (n + 10) ]"
                >
                  <div style="text-align: right;">
                    <template v-if="detail['payment_salary_item_code' + (n + 10) ] === '' ">
                      &nbsp;
                      <br />&nbsp;
                      <br />
                    </template>
                    <template v-else>
                      <ValidationTextNumber
                        v-model="detail['payment_salary_item_code' + (n + 10) +'_amount']"
                        integral="10"
                        :rules="isNotEditable(detail['payment_salary_item_code' + (n + 10) ], isPaymentHandType(n + 10)) ? '' : 'required_simple|decimal_simple|min_value:-999999999|max_value:9999999999'"
                        classes="form-control numLength10"
                        :placeholder="detail['payment_salary_item_code' + (n + 10) ] === '' ? ''  :  '10,000'"
                        :disabled="isReadOnly || isNotEditable(detail['payment_salary_item_code' + (n + 10) ], isPaymentHandType(n + 10))"
                        :is-replace="false"
                        @input="detail={...detail}"
                      />
                      <div style="text-align: center;">
                        <template v-if="showEditIcon(detail['payment_salary_item_code' + (n + 10) ], detail['payment_salary_item_code' + (n + 10) + '_can_change'])">
                          <a href="#" @click.prevent="updatePaymentHandType(n + 10, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isPaymentHandType(n + 10)"><i class="ti-pencil-alt"></i></a>
                          <a href="#" @click.prevent="updatePaymentHandType(n + 10, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isPaymentHandType(n + 10)"><i class="ti-back-left"></i></a>
                        </template>
                        <template v-else>
                          &nbsp;
                        </template>
                      </div>
                    </template>
                  </div>
                </el-table-column>
              </el-table>

              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="payment_salary_item21()"
                v-show="payment_salary_item21_exist ()"
                ref="table"
                style="width: 1000"
              >
                <el-table-column
                  align="center"
                  v-for="n of 10"
                  :key="n"
                  min-width="144"
                  :label="payment_salary_item21()[0]['payment_salary_item_name' + (n + 20) ]"
                >
                  <div style="text-align: right;">
                    <template v-if="detail['payment_salary_item_code' + (n + 20) ] === '' ">
                      &nbsp;
                      <br />&nbsp;
                      <br />
                    </template>
                    <template v-else>
                      <ValidationTextNumber
                        v-model="detail['payment_salary_item_code' + (n + 20) +'_amount']"
                        integral="10"
                        :rules="isNotEditable(detail['payment_salary_item_code' + (n + 20) ], isPaymentHandType(n + 20)) ? '' : 'required_simple|decimal_simple|min_value:-999999999|max_value:9999999999'"
                        classes="form-control numLength10"
                        :placeholder="detail['payment_salary_item_code' + (n + 20) ] === '' ? ''  :  '10,000'"
                        :disabled="isReadOnly || isNotEditable(detail['payment_salary_item_code' + (n + 20) ], isPaymentHandType(n + 20))"
                        :is-replace="false"
                        @input="detail={...detail}"
                      />
                      <div style="text-align: center;">
                        <template v-if="showEditIcon(detail['payment_salary_item_code' + (n + 20) ], detail['payment_salary_item_code' + (n + 20) + '_can_change'])">
                          <a href="#" @click.prevent="updatePaymentHandType(n + 20, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isPaymentHandType(n + 20)"><i class="ti-pencil-alt"></i></a>
                          <a href="#" @click.prevent="updatePaymentHandType(n + 20, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isPaymentHandType(n + 20)"><i class="ti-back-left"></i></a>
                        </template>
                        <template v-else>
                          &nbsp;
                        </template>
                      </div>
                    </template>
                  </div>
                </el-table-column>
              </el-table>
              <br />
              <br />
              <hr />
              <!-- 控除額 -->
              <label>{{$t('label.deduction_salary_item_code')}}</label>
              <br />
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="deduction_salary_item01()"
                ref="table"
                style="width: 1000"
              >
                <el-table-column
                  align="center"
                  v-for="n of 10"
                  :key="n"
                  min-width="144"
                  :label="deduction_salary_item01()[0]['deduction_salary_item_name' + n ]"
                >
                  <div style="text-align: right;">
                    <template v-if="detail['deduction_salary_item_code' + n ] === '' ">
                      &nbsp;
                      <br />&nbsp;
                      <br />
                    </template>
                    <template v-else>
                      <ValidationTextNumber
                        v-model="detail['deduction_salary_item_code' + n +'_amount']"
                        integral="10"
                        :rules="isNotEditable(detail['deduction_salary_item_code' + n ], isDeductionHandType(n)) ? '' : 'required_simple|decimal_simple|min_value:-999999999|max_value:9999999999'"
                        classes="form-control numLength10"
                        :placeholder="detail['deduction_salary_item_code' + n ] === '' ? '' : '10,000'"
                        :disabled="isReadOnly || isNotEditable(detail['deduction_salary_item_code' + n ], isDeductionHandType(n))"
                        :is-replace="false"
                        @input="detail={...detail}"
                      />
                      <div style="text-align: center;">
                        <template v-if="showEditIcon(detail['deduction_salary_item_code' + n ], detail['deduction_salary_item_code' + n + '_can_change'])">
                          <a href="#" @click.prevent="updateDeductionHandType(n, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isDeductionHandType(n)"><i class="ti-pencil-alt"></i></a>
                          <a href="#" @click.prevent="updateDeductionHandType(n, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isDeductionHandType(n)"><i class="ti-back-left"></i></a>
                        </template>
                        <template v-else>
                          &nbsp;
                        </template>
                      </div>
                    </template>
                  </div>
                </el-table-column>
              </el-table>

              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="deduction_salary_item11()"
                ref="table"
                style="width: 1000"
              >
                <el-table-column
                  align="center"
                  v-for="n of 10"
                  :key="n"
                  min-width="144"
                  :label="deduction_salary_item11()[0]['deduction_salary_item_name' + (n + 10) ]"
                >
                  <div style="text-align: right;">
                    <template v-if="detail['deduction_salary_item_code' + (n + 10) ] === '' ">
                      &nbsp;
                      <br />&nbsp;
                      <br />
                    </template>
                    <template v-else>
                      <ValidationTextNumber
                        v-model="detail['deduction_salary_item_code' + (n + 10) +'_amount']"
                        integral="10"
                        :rules="isNotEditable(detail['deduction_salary_item_code' + (n + 10) ], isDeductionHandType(n + 10)) ? '' : 'required_simple|decimal_simple|min_value:-999999999|max_value:9999999999'"
                        classes="form-control numLength10"
                        :placeholder="detail['deduction_salary_item_code' + (n + 10) ] === '' ? ''  :  '10,000'"
                        :disabled="isReadOnly || isNotEditable(detail['deduction_salary_item_code' + (n + 10) ], isDeductionHandType(n + 10))"
                        :is-replace="false"
                        @input="detail={...detail}"
                      />
                      <div style="text-align: center;">
                        <template v-if="showEditIcon(detail['deduction_salary_item_code' + (n + 10) ], detail['deduction_salary_item_code' + (n + 10) + '_can_change'])">
                          <a href="#" @click.prevent="updateDeductionHandType(n + 10, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isDeductionHandType(n + 10)"><i class="ti-pencil-alt"></i></a>
                          <a href="#" @click.prevent="updateDeductionHandType(n + 10, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isDeductionHandType(n + 10)"><i class="ti-back-left"></i></a>
                        </template>
                        <template v-else>
                          &nbsp;
                        </template>
                      </div>
                    </template>
                  </div>
                </el-table-column>
              </el-table>

              <template v-if="outsideLayoutItemList.length > 0">
                <br />
                <br />
                <hr />
                <!-- レイアウト外の給与項目 -->
                <label>
                  {{'レイアウト外の給与項目'}}
                </label>
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                  :data="outsideLayoutItemList"
                  style="width: 610px">
                  <!-- 給与項目 -->
                  <el-table-column min-width="200" :label="$t('label.item_name')">
                    <template slot-scope="props">
                      {{ props.row.salary_item_name }}
                    </template>
                  </el-table-column>
                  <!-- 金額 -->
                  <el-table-column min-width="200" :label="$t('label.amount_of_money')">
                    <template slot-scope="props">
                      {{ formatNumber(props.row.basic_price) }}
                    </template>
                  </el-table-column>
                  <!-- 労働時間の範囲 -->
                  <el-table-column min-width="150" :label="$t('label.working_time')">
                    <template slot-scope="props">
                      {{ props.row.working_time }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>

              <br />
              <br />
              <hr />
              <!-- 対象額 -->
              <label>{{$t('label.target_amount')}}</label>
              <br />
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="payslipTotalTableBase()"
                ref="table"
                style="width: 300"
              >
                <!-- 社会保険対象額 -->
                <el-table-column align="center" min-width="140" :label="labels.social_insurance_amount_ryaku">
                  <ValidationTextNumber
                    v-model="detail.social_insurance_amount"
                    integral="10"
                    classes="form-control numLength10"
                    :placeholder="isReadOnly ? '' : '10000'"
                    :disabled="true"
                  />
                </el-table-column>
                <!-- 所得税対象額 -->
                <el-table-column align="center" min-width="140" :label="labels.taxable_amount_2_ryaku">
                  <ValidationTextNumber
                    v-model="detail.taxable_amount_2"
                    integral="10"
                    classes="form-control numLength10"
                    :placeholder="isReadOnly ? '' : '10000'"
                    :disabled="true"
                  />
                </el-table-column>
                <!-- 雇用保険対象額 -->
                <el-table-column align="center" min-width="140" :label="labels.employement_insurance_amount_ryaku">
                  <ValidationTextNumber
                    v-model="detail.employement_insurance_amount"
                    integral="10"
                    classes="form-control numLength10"
                    :placeholder="isReadOnly ? '' : '10000'"
                    :disabled="true"
                  />
                </el-table-column>
              </el-table>
              <br />
              <table height="40">
                <tr>
                  <td>
                    &nbsp;
                    <!-- 健康保険 一般 -->
                    {{labels.general_health_insurance}}:{{detail.general_health_insurance ? detail.general_health_insurance :0 }}{{$t('label.currency')}}&nbsp;/&nbsp;
                    <!-- 健康保険 調整 -->
                    {{labels.health_insurance_adjustment}}:{{detail.health_insurance_adjustment ? detail.health_insurance_adjustment :0 }}{{$t('label.currency')}}&nbsp;/&nbsp;
                    <!-- 介護保険 -->
                    {{labels.care_insurance}}:{{detail.care_insurance ? detail.care_insurance :0 }}{{$t('label.yen')}}&nbsp;/&nbsp;
                    <!-- 社会保険計 -->
                    ({{labels.social_insurance_total}})&nbsp;{{detail.social_insurance_total ? detail.social_insurance_total :0 }}{{$t('label.currency')}}
                  </td>
                </tr>
              </table>
              <br />
              <br />
              <hr />
              <!-- 合計 -->
              <div class="form-group" style="display: inline-block">
                <label>{{$t('label.total')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payslipTotalTableBase()"
                  ref="table"
                  style="width: 800"
                >
                  <!-- 支給額合計 -->
                  <el-table-column align="center" min-width="144" :label="labels.total_payment_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.total_payment_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                  <!-- 控除額合計 -->
                  <el-table-column align="center" min-width="144" :label="labels.total_deduction_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.total_deduction_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                  <!-- 差引支給額 -->
                  <el-table-column align="center" min-width="144" :label="labels.deduction_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.deduction_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                </el-table>
              </div>
              <!-- 振込先 -->
              <div class="form-group" style="display: inline-block">
                <br />
                <label>{{$t('label.bank_transfer')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payslipTotalTableBase()"
                  ref="table"
                  style="width: 800"
                >
                  <!-- 第一振込先 金額 -->
                  <el-table-column align="center" min-width="144" :label="labels.first_transfer_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.first_transfer_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                  <!-- 第二振込先 金額 -->
                  <el-table-column align="center" min-width="144" :label="labels.second_transfer_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.second_transfer_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                </el-table>
              </div>
              <!-- 現金支給額 -->
              <div class="form-group" style="display: inline-block">
                <br />
                <label>{{$t('label.cash')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payslipTotalTableBase()"
                  ref="table"
                  style="width: 800"
                >
                  <!-- 現金支給額 ※表示項目-->
                  <el-table-column align="center" min-width="144" :label="labels.cash_payment_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.cash_payment_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                </el-table>
              </div>
              <!-- 現物支給額 -->
              <div class="form-group" style="display: inline-block">
                <br />
                <label>{{$t('label.in_kind')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payslipTotalTableBase()"
                  ref="table"
                  style="width: 800"
                >
                  <!-- 現物支給額 ※表示項目-->
                  <el-table-column align="center" min-width="144" :label="labels.in_kind_payment_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.in_kind_payment_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                </el-table>
              </div>
              <br />
              <hr />
              <!-- 備考 -->
              <table border>
                <th
                  colspan="10"
                  style="background-color:#778899; color: #ccc; padding: 2px 0 !important; font-size: 12px;"
                >
                  <b>{{$t('label.remark')}}</b>
                </th>
                <tr>
                  <div style="display: inline-block; margin: 0; padding: 0;">
                    <ValidationTextArea
                      v-model="detail.remark"
                      item-name="other"
                      max-length="255"
                      classes="form-control"
                      style="width: 1440px;"
                      rules='remarkMax9Rows'
                      :rows="9"
                      :disabled="isReadOnly"
                      :help-text="$t('label.max_9row_and_max_length')"
                    />
                  </div>
                </tr>
              </table>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <div style="text-align: left;">
        <template v-if="!isReadOnly && !this.isChanged">
          <!-- 計算ボタン -->
          <button
            type="button"
            class="btn btn-primary btn-sm btn-default"
            @click="calc"
            :disabled="!isModified"
            v-tooltip.top-center="(isModified ? $t('message.require_calc') : '') + $t('label.description_of_payslip_calculation')"
          >{{$t('button.calculation')}}</button>
          &nbsp;&nbsp;
        </template>
        <template v-if="mode==modes.add && !this.isChanged">
          <template v-if="t_employee_pay_slip_exist!==1">
            <button 
              type="button" 
              class="btn btn-sm btn-default btn-submit" 
              @click="doAdd" 
              :disabled="isModified" 
              v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
            >
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.add')}}
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-sm btn-default btn-submit"
              @click="doUpdate"
              :disabled="isModified"
              v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
            >
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.update')}}
            </button>
          </template>
        </template>
        <template v-if="mode==modes.edit && !this.isChanged">
          <template v-if="t_employee_pay_slip_exist!==1">
            <button 
              type="button" 
              class="btn btn-sm btn-default btn-submit" 
              @click="doAdd"
              :disabled="isModified" 
              v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
            >
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.add')}}
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-sm btn-default btn-submit"
              @click="doUpdate"
              :disabled="isModified"
              v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
            >
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.update')}}
            </button>
          </template>
        </template>
        <template v-if="mode==modes.delete && !this.isChanged">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doDelete"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </template>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import CreatePaySlipDetailMixin from '@/components/Dashboard/Views/Salary/CreatePaySlipDetailMixin'

  export default {
    name: 'CreatePaySlipDetail',
    mixins: [CreatePaySlipDetailMixin]
  }
</script>
<style scoped>
</style>
