<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <h4>{{categoryName ? categoryName + '（' + parseInt(targetDate.substring(4), 10) + $t('label.month') + '）' : ''}}</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="card" style="height: 600px;">
            <div class="card-content">
              <BarChart :nonce="row.chartdata.labels[0]" :chartdata="row.chartdata" :options="row.options" :width="220" :height="140" :styles="{display: 'inline-block', cursor: 'pointer'}" v-for="row in chartdata1" :key="row.chartdata.label" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card" style="height: 600px;">
            <div class="card-content">
              <BarChart :chartdata="row.chartdata" :options="row.options" :width="220" :height="140" :styles="{display: 'inline-block'}" v-for="row in chartdata2" :key="row.chartdata.label" />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div ref="frame">
            <div v-for="tableData in tableDataList" :key="tableData.title" style="margin-bottom: 20px;">
              {{tableData.title}}
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="tableData.tableData"
                        :height="tableHeight">

                <!-- スコア -->
                <el-table-column min-width="60" label="スコア">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      <span v-text="props.row.score" />
                    </div>
                  </template>
                </el-table-column>

                <!-- 説明 -->
                <el-table-column min-width="100" label="説明">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold; white-space: normal;">
                      <span v-text="props.row.description" />
                    </div>
                  </template>
                </el-table-column>

                <!-- アドバイス -->
                <el-table-column min-width="200" label="アドバイス">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold; white-space: normal;">
                      <span v-text="props.row.advice" />
                    </div>
                  </template>
                </el-table-column>

                <!-- 関連画面 -->
                <el-table-column min-width="100" label="関連画面">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold; height: 50px;">
                      <span v-text="props.row.url" />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import BarChart from '@/components/UIComponents/Inputs/BarChart'

  export default {
    mixins: [mixin],
    components: {
      BarChart
    },
    props: {
      targetDate: String,
      category: String
    },
    data () {
      var self = this
      return {
        selectedChartCaption: '',
        chartdata1: [],
        options1: {
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0,
                max: 100
              }
            }]
          },
          onClick: function (e, el) {
            self.barChartClicked(el[0]._model.label)
          }
        },
        chartdata2: {},
        options2: {
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0,
                max: 100,
                callback: function (value, index, ticks) {
                  return value + '%'
                }
              }
            }]
          }
        },
        totalBackgroundColor: 'rgba(216, 17, 89, 1.0)',
        industryBackgroundColor: 'rgba(255, 188, 66, 1.0)',
        totalBackgroundColor2: 'rgba(143, 45, 86, 1.0)',
        industryBackgroundColor2: 'rgba(33, 131, 128, 1.0)',
        tableHeight: '',
        labels: {},
        comboData: {},
        tableDataList: [
          {
            title: '',
            tableData: []
          },
          {
            title: '',
            tableData: []
          }
        ],
        categoryName: '',
        categoryData: {}
      }
    },
    methods: {
      doClose () {
        this.$emit('close')
      },
      barChartClicked (label) {
        for (const row of this.categoryData.score_type_data_list) {
          if (row.score_type_name === label) {
            this.createBarChartData2(row)
            this.tableDataList = this.createTableData(row)
            break
          }
        }
        this.selectedChartCaption = label
        this.createBarChartData1(this.categoryData)
      },
      createBarChartData1 (scoreData) {
        const self = this
        const chartdata1 = []
        for (const row of scoreData.score_type_data_list) {
          const chartData = {}
          const options = {responsive: false}
          const labels = []
          labels.push(row.score_type_name)
          chartData.labels = labels
          chartData.datasets = []
          const dataset1 = {}
          dataset1.label = '総合'
          dataset1.backgroundColor = row.score_type_name === this.selectedChartCaption ? this.totalBackgroundColor : undefined
          dataset1.stack = 'Stack 0'
          dataset1.yAxisID = 'A'
          dataset1.maxBarThickness = 20
          dataset1.data = []
          const dataset2 = {}
          dataset2.label = '業界別'
          dataset2.backgroundColor = row.score_type_name === this.selectedChartCaption ? this.industryBackgroundColor : undefined
          dataset2.stack = 'Stack 1'
          dataset2.yAxisID = 'B'
          dataset2.maxBarThickness = 20
          dataset2.data = []
          let score = 0
          let industryScore = 0
          let highScore = 0
          let industryHighScore = 0
          for (const row2 of row.score_detail_data_list) {
            if (row2.score) {
              score += row2.score
            }
            highScore += row2.high_score
            if (row2.industry_score) {
              industryScore += row2.industry_score
            }
            industryHighScore += row2.industry_high_score
          }
          if (highScore === 0) highScore = 1
          if (industryHighScore === 0) industryHighScore = 1
          const fontColor = row.score_type_name === this.selectedChartCaption ? 'blue' : 'gray'
          options.scales = {
            xAxes: [{
              ticks: {
                fontColor: fontColor
              }
            }],
            yAxes: [
              {
                id: 'A',
                position: 'left',
                ticks: {
                  min: 0,
                  max: highScore
                }
              },
              {
                id: 'B',
                position: 'right',
                display: false,
                ticks: {
                  min: 0,
                  max: industryHighScore
                }
              }
            ]
          }
          options.onClick = function (e, el) {
            self.barChartClicked(e.srcElement.parentNode.nonce)
          }
          dataset1.data.push(score)
          dataset2.data.push(industryScore)
          chartData.datasets.push(dataset1)
          chartData.datasets.push(dataset2)
          chartdata1.push({
            chartdata: chartData,
            options: options // this.options1
          })
        }
        // for (var i = scoreData.score_type_data_list.length; i < 8; i++) {
        //   const chartData = {}
        //   const labels = []
        //   labels.push('')
        //   chartData.labels = labels
        //   chartData.datasets = []
        //   chartdata1.push({
        //     chartdata: chartData,
        //     options: {responsive: false}
        //   })
        // }
        this.chartdata1 = chartdata1
      },
      createBarChartData2 (scoreData) {
        const chartdata2 = []
        for (const row of scoreData.score_detail_data_list) {
          const chartData = {}
          const options = {responsive: false}
          const labels = []
          labels.push(row.score_detail_name)
          chartData.labels = labels
          chartData.datasets = []
          const dataset1 = {}
          dataset1.label = '総合'
          dataset1.backgroundColor = this.totalBackgroundColor2
          dataset1.stack = 'Stack 0'
          dataset1.maxBarThickness = 20
          dataset1.data = []
          const dataset2 = {}
          dataset2.label = '業界別'
          dataset2.backgroundColor = this.industryBackgroundColor2
          dataset2.stack = 'Stack 1'
          dataset2.maxBarThickness = 20
          dataset2.data = []
          dataset1.data.push(row.score)
          dataset2.data.push(row.industry_score)
          chartData.datasets.push(dataset1)
          chartData.datasets.push(dataset2)
          if (row.high_score === 0) row.high_score = 1
          if (row.industry_high_score === 0) row.industry_high_score = 1
          options.scales = {
            yAxes: [
              {
                id: 'A',
                position: 'left',
                ticks: {
                  min: 0,
                  max: row.high_score
                }
              },
              {
                id: 'B',
                position: 'right',
                display: false,
                ticks: {
                  min: 0,
                  max: row.industry_high_score
                }
              }
            ]
          }
          chartdata2.push({
            chartdata: chartData,
            options: options
          })
        }
        // for (var i = scoreData.score_detail_data_list.length; i < 8; i++) {
        //   const chartData = {}
        //   const labels = []
        //   labels.push('')
        //   chartData.labels = labels
        //   chartData.datasets = []
        //   chartdata2.push({
        //     chartdata: chartData,
        //     options: {responsive: false}
        //   })
        // }
        this.chartdata2 = chartdata2
      },
      createTableData (scoreTypeData) {
        const tableData1 = []
        const tableData2 = []
        for (const detail of scoreTypeData.score_detail_data_list) {
          if (detail.score) {
            tableData1.push({
              score: detail.score + '/' + detail.high_score,
              score_type: scoreTypeData.score_type_name,
              description: detail.score_detail_name,
              advice: detail.message
            })
          }
          if (detail.industry_score) {
            tableData2.push({
              score: detail.industry_score + '/' + detail.high_score,
              score_type: scoreTypeData.score_type_name,
              description: detail.score_detail_name,
              advice: detail.industry_message
            })
          }
        }
        return [
          {
            title: '[総合]',
            tableData: tableData1
          },
          {
            title: '[業界別]',
            tableData: tableData2
          }
        ]
      },
      init () {
        let params = {
          screen_code: '09-017',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            target_date: this.targetDate,
            category: this.category
          }
        }
        this.send('/init/white_ranking_detail/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          for (const row of this.comboData.category_list) {
            if (row.value === this.category) {
              this.categoryName = row.label
              break
            }
          }
          this.categoryData = res.api_area.category_data_list[0]
          this.createBarChartData1(this.categoryData)
          for (var i = 0; i < this.chartdata1.length; i++) {
            if (this.chartdata1[i].chartdata.datasets[0].data[0] > 0) {
              this.barChartClicked(this.chartdata1[i].chartdata.labels[0])
              break
            }
          }
          this.$nextTick(() => {
            if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
              this.tableHeight = 'calc(100vh - 220px)'
            } else {
              this.tableHeight = ''
            }
          })
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style scoped>
.gauge-container {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>