<template>
  <div class="form-group">
    <div style="margin-bottom: 5px;">
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{$t('button.add_row')}}</button>
    </div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      :class="tableClasses"
      :data="tableData"
      :empty-text="$t('label.no_data')"
      border>
      <el-table-column
        width="60"
        :label="$t('label.delete')"
        align="center">
        <template slot="header"> 
          <div class="phase-header tableHeader">
            <div class="phase-header__title">
              <input type="checkbox" v-model="headerSelected" @click="headerClicked" class="gray" :disabled="false"/>
            </div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="false"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="itemLabel ? itemLabel : ''"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <input
              type="text"
              :placeholder="placeholder"
              :class="classes"
              v-model="props.row.name"
              :maxlength="maxLength"
              :disabled="disabled">
          </div>
        </template>
      </el-table-column>
    </el-table>
    <br>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemLabel: String,
      placeholder: String,
      classes: String,
      maxLength: String,
      disabled: Boolean
    },
    computed: {
      tableClasses () {
        return 'length' + (this.maxLength * 2 + 20)
      }
    },
    data () {
      return {
        headerSelected: false,
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        if (this.tableData.length === 0) {
          this.addRow()
        }
      }
    },
    mounted () {
      this.tableData = this.value
      if (this.tableData.length === 0) {
        this.addRow()
      }
    },
    methods: {
      headerClicked () {
        for (const row of this.tableData) {
          row.selected = !this.headerSelected
        }
      },
      addRow () {
        let row = {
          selected: false,
          name: ''
        }
        this.tableData.unshift(row)
      },
      deleteRow () {
        for (let i = this.tableData.length - 1; i >= 0; i--) {
          if (this.tableData[i].selected) {
            this.tableData.splice(i, 1)
          }
        }
      }
    }
  }
</script>

<style scoped>
</style>