<template>
<div class="row">
  <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
  <div class="col-md-12">
    <modal name="WithholdingTaxAmount" :draggable="true" :clickToClose="false" height="auto" width="1300px">
      <div class="modal-body">
        <WithholdingTaxAmountDetail :mode="mode" :labels="labels" :params="params" @close="hide" />
      </div>
    </modal>
  </div>
  <!-- 検索条件 -->
  <div class="col-md-12">
    <form method="#" action="#">
      <div class="card">

        <!-- ↓実装時にこれを使う -->
        <!-- <template v-if="mode==modes.edit"> -->
        <template>
          <div style="text-align: left;">

            <vue-tabs>
              <!-- 源泉徴収税額表(給与所得の税額表) -->
              <v-tab title="源泉徴収税額表(給与所得の月額表)">
                <div class="card-header">
                  <h4 class="card-title">
                    ファイル選択
                  </h4>
                </div>
                <div class="form-group" style="display: inline-block; margin-left: 20px;">
                  <br />
                  <input type="file">
                  <br />
                  　 <button type="button" class="btn btn-warning btn-sm btn-default" @click="doChange">取込</button>
                  <br><br>
                  <p>参考ホームページ：</p>
                  <a href="https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2019/02.htm" target="_blank">国税庁のHP</a><br>
                  <a href="https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2019/data/01-07.xls">取込用データをダウンロード(給与所得の源泉徴収税額表（月額表）（1から7ページ）)</a><br>
                  <br>

                  <div class="form-group" style="margin-top: 20px;">
                    <div class="card-content row" style="height: 640px; overflow: auto">
                      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="tableData" style="width: 700">
                        　<el-table-column min-width="100" label="操作">
                          　　　 <template slot-scope="props">
                            <a class="btn btn-simple btn-xs btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" title="labels.button_preview"></i></a>
                            <a class="btn btn-simple btn-xs btn-danger btn-icon remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" title="labels.button_delete"></i></a>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="300" label="税務表区分">
                          源泉徴収税額表(給与所得の月額表)
                        </el-table-column>
                        <el-table-column min-width="150" label="更新日">
                          2020-10-25
                          　 </el-table-column>
                        <el-table-column min-width="150" label="更新者">
                          石田 敦士
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </v-tab>

              <!-- 源泉徴収税額表(賞与) -->
              <v-tab title="源泉徴収税額表(賞与)">
                <div class="card-header">
                  <h4 class="card-title">
                    ファイル選択
                  </h4>
                </div>
                <div class="form-group" style="display: inline-block; margin-left: 20px;">
                  <br />
                  <input type="file">
                  <br />
                  　 <button type="button" class="btn btn-warning btn-sm btn-default" @click="doChange">取込</button>
                  <br><br>
                  <p>参考ホームページ：</p>
                  <a href="https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2019/02.htm" target="_blank">国税庁のHP</a><br>
                  <a href="https://www.nta.go.jp/publication/pamph/gensen/zeigakuhyo2019/data/15-16.xls">取込用データをダウンロード(賞与に対する源泉徴収税額の算出率の表（15から16ページ）)</a><br>
                  <br>

                  <div class="form-group" style="margin-top: 20px;">
                    <div class="card-content row" style="height: 640px; overflow: auto">
                      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="tableData" style="width: 700">
                        　<el-table-column min-width="100" label="操作">
                          　　　 <template slot-scope="props">
                            <a class="btn btn-simple btn-xs btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" title="labels.button_preview"></i></a>
                            <a class="btn btn-simple btn-xs btn-danger btn-icon remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" title="labels.button_delete"></i></a>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="300" label="税務表区分">
                          源泉徴収税額表(賞与)
                        </el-table-column>
                        <el-table-column min-width="150" label="更新日">
                          2020-10-25
                          　 </el-table-column>
                        <el-table-column min-width="150" label="更新者">
                          石田 敦士
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </v-tab>
            </vue-tabs>

          </div>
        </template>

      </div>
    </form>

  </div>
</div>
</template>

<script>
import mixin from '@/lib/mixin'
// import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextAfter from '@/components/UIComponents/Inputs/ValidationTextAfter'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
import ViewLabel from '@/components/UIComponents/Inputs/ViewLabel'
import WithholdingTaxAmountDetail from '@/components/Dashboard/Views/Operation/WithholdingTaxAmountDetail'

export default {
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationTextAfter,
    ValidationSelect,
    ValidationDate,
    ValidationCheckboxes,
    ValidationRadio,
    ViewLabel,
    WithholdingTaxAmountDetail
  },
  methods: {
    doChange () {
      this.$router.push({
        name: 'licenseUpdate'
      })
    },
    doClose () {
      this.$emit('close', {})
    },
    handleView (index, row) {
      this.params = row
      this.mode = this.modes.view
      this.$modal.show('WithholdingTaxAmount')
    },
    handleDelete (index, row) {
      // 削除可否チェック

    },
    hide () {
      this.$modal.hide('WithholdingTaxAmount')
    }
  },
  data () {
    return {
      tableData: [{
        approval_route_classification_name: '共通ルート',
        approval_route_code: '002',
        approval_route_name: 'テスト共通ルート'
      }]
    }
  }
}
</script>

<style>
</style>
