<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 330px);" name="holidayFrame" :key="componentKey">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="holidayMessage" :placeholders="[pageTitle]"/>
        <template v-if="showResult">
          <div style="font-weight: bold;">
            {{$t('label.type_of_day_summary1').replace('{total_working_day}', dayCount[0] + dayCount[3] + dayCount[7] + dayCount[8]).replace('{total_attendance_day}', dayCount[0]).replace('{total_unknown_day}', dayCount[3])}}
            {{$t('label.type_of_day_summary2').replace('{total_unknown2_day}', dayCount[7]).replace('{total_unknown3_day}', dayCount[8])}}
          </div>
          <div style="font-weight: bold; margin-bottom: 10px;">
            {{$t('label.type_of_day_summary3').replace('{total_holiday}', dayCount[1] + dayCount[2] + dayCount[6] + dayCount[5] + dayCount[4]).replace('{total_job_holiday}', dayCount[1]).replace('{total_legal_holiday}', dayCount[2])}}
            {{$t('label.type_of_day_summary4').replace('{total_national_holiday}', dayCount[6]).replace('{total_new_year_holiday}', dayCount[5]).replace('{total_summer_holiday}', dayCount[4])}}
          </div>
        </template>
        <div class="card">
          <div class="card-content" style="font-weight: bold;">
            {{ $t('label.target_year') }}&nbsp;&nbsp;&nbsp;&nbsp;{{ holiday.target_year + $t ('label.year') }}
          </div>
        </div>
        <div class="card" style="display: none;">
          <div class="card-header">
            <div style="color: red;">{{holiday.error}}</div>
          </div>
          <div class="card-content">
            <ValidationText
              v-model="holiday.target_year"
              classes="form-control length4"
              :disabled="false"
              :item-label="$t('label.target_year')"
              item-name="target_year"
              rules="required|decimal|min_value:2000|max_value:2100"
              :help-text="$t('label.max_length')"
              max-length="4"
              unit="年"
            />
            <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
              <span class="btn-label"><i class="ti-search"></i></span>
              {{$t('button.search')}}
            </button>
          </div>
        </div>
        <template v-if="showResult">
          <div class="card">
            <div class="card-content">
              <ValidationCheckboxes
                v-model="holiday.job_holiday"
                :item-label="labels.job_holiday"
              />
              <ValidationCheckboxes
                v-model="holiday.legal_holiday"
                :item-label="labels.legal_holiday"
              />
              <ValidationRadio
                v-model="holiday.national_holiday"
                :item-label="$t('label.national_holiday')"
                :disabled="false"
              />
              <button type="button" class="btn btn-sm btn-default btn-warning" @click="search">{{$t('button.reset_all_months')}}</button>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div style="margin-bottom: 20px;" v-for="result in results" v-bind:key="result.title">
                <div style="display: inline-block; width: 500px;">
                  <span>{{result.title}}</span>
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="search(result.title)">{{result.button_title}}</button>
                </div>
                <div style="display: inline-block; width: 750px; text-align: right;">
                  <span>{{$t('label.type_of_day_monthly_summary').replace('{total_working_day}', result.day_on_count).replace('{total_holiday}', result.day_off_count)}}</span>
                </div>
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                          :data="result.data">
                  <el-table-column width="180" :label="$t('label.sunday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_sunday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_sunday)">{{props.row.day_of_sunday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_sunday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.monday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_monday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_monday)">{{props.row.day_of_monday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_monday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.tuesday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_tuesday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_tuesday)">{{props.row.day_of_tuesday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_tuesday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.wednesday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_wednesday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_wednesday)">{{props.row.day_of_wednesday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_wednesday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.thursday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_thursday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_thursday)">{{props.row.day_of_thursday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_thursday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.friday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_friday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_friday)">{{props.row.day_of_friday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_friday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column width="180" :label="$t('label.saturday')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.day_of_saturday">
                        <div style="display: inline-block; width: 20px; text-align: center;">
                          <span :style="getColor(props.row.day_type_of_saturday)">{{props.row.day_of_saturday}}</span>
                        </div>
                        <div style="display: inline-block; width: 130px;">
                          <el-select class="select-success length6"
                                      size="large"
                                      :disabled="false"
                                      v-model="props.row.day_type_of_saturday"
                                      @change="updateResults">
                            <el-option v-for="dayType in comboData.holiday_list"
                                        class="select-success"
                                        :value="dayType.value"
                                        :label="dayType.label"
                                        :key="dayType.label">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationCheckboxes,
      ValidationRadio
    },
    data () {
      return {
        componentKey: 0,
        holiday: {
          error: '',
          job_holiday: {
            disabled: false,
            color: 'gray',
            errMsg: '',
            items: [
              {
                id: '0',
                label: this.$t('label.monday'),
                value: false
              },
              {
                id: '1',
                label: this.$t('label.tuesday'),
                value: false
              },
              {
                id: '2',
                label: this.$t('label.wednesday'),
                value: false
              },
              {
                id: '3',
                label: this.$t('label.thursday'),
                value: false
              },
              {
                id: '4',
                label: this.$t('label.friday'),
                value: false
              },
              {
                id: '5',
                label: this.$t('label.saturday'),
                value: false
              },
              {
                id: '6',
                label: this.$t('label.sunday'),
                value: false
              }
            ]
          },
          legal_holiday: {
            disabled: false,
            color: 'gray',
            errMsg: '',
            items: [
              {
                id: '0',
                label: this.$t('label.monday'),
                value: false
              },
              {
                id: '1',
                label: this.$t('label.tuesday'),
                value: false
              },
              {
                id: '2',
                label: this.$t('label.wednesday'),
                value: false
              },
              {
                id: '3',
                label: this.$t('label.thursday'),
                value: false
              },
              {
                id: '4',
                label: this.$t('label.friday'),
                value: false
              },
              {
                id: '5',
                label: this.$t('label.saturday'),
                value: false
              },
              {
                id: '6',
                label: this.$t('label.sunday'),
                value: false
              }
            ]
          },
          national_holiday: {
            value: null,
            disabled: false,
            items: [
              {
                id: '1',
                label: this.$t('label.add_national_holiday'),
                value: '1'
              },
              {
                id: '2',
                label: this.$t('label.delete_national_holiday'),
                value: '2'
              }
            ]
          }
        },
        showResult: false,
        dayTypes: [
          {label: '平日', value: '1'},
          {label: '所定', value: '2'},
          {label: '法定', value: '3'},
          {label: '奨励', value: '4'}
        ],
        results: [
          {
            title: '1月',
            button_title: '1月のみ反映',
            day_on_count: 0,
            day_off_count: 0,
            data: [
              {
                day_of_sunday: undefined,
                day_type_of_sunday: undefined,
                day_of_monday: undefined,
                day_type_of_monday: undefined,
                day_of_tuesday: undefined,
                day_type_of_tuesday: undefined,
                day_of_wednesday: undefined,
                day_type_of_wednesday: undefined,
                day_of_thursday: undefined,
                day_type_of_thursday: undefined,
                day_of_friday: '1',
                day_type_of_friday: '1',
                day_of_saturday: '2',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '3',
                day_type_of_sunday: '3',
                day_of_monday: '4',
                day_type_of_monday: '1',
                day_of_tuesday: '5',
                day_type_of_tuesday: '1',
                day_of_wednesday: '6',
                day_type_of_wednesday: '1',
                day_of_thursday: '7',
                day_type_of_thursday: '1',
                day_of_friday: '8',
                day_type_of_friday: '1',
                day_of_saturday: '9',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '10',
                day_type_of_sunday: '3',
                day_of_monday: '11',
                day_type_of_monday: '1',
                day_of_tuesday: '12',
                day_type_of_tuesday: '1',
                day_of_wednesday: '13',
                day_type_of_wednesday: '1',
                day_of_thursday: '14',
                day_type_of_thursday: '1',
                day_of_friday: '15',
                day_type_of_friday: '1',
                day_of_saturday: '16',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '17',
                day_type_of_sunday: '3',
                day_of_monday: '18',
                day_type_of_monday: '1',
                day_of_tuesday: '19',
                day_type_of_tuesday: '1',
                day_of_wednesday: '20',
                day_type_of_wednesday: '1',
                day_of_thursday: '21',
                day_type_of_thursday: '1',
                day_of_friday: '22',
                day_type_of_friday: '1',
                day_of_saturday: '23',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '24',
                day_type_of_sunday: '3',
                day_of_monday: '25',
                day_type_of_monday: '1',
                day_of_tuesday: '26',
                day_type_of_tuesday: '1',
                day_of_wednesday: '27',
                day_type_of_wednesday: '1',
                day_of_thursday: '28',
                day_type_of_thursday: '1',
                day_of_friday: '29',
                day_type_of_friday: '1',
                day_of_saturday: '30',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '31',
                day_type_of_sunday: '3',
                day_of_monday: undefined,
                day_type_of_monday: undefined,
                day_of_tuesday: undefined,
                day_type_of_tuesday: undefined,
                day_of_wednesday: undefined,
                day_type_of_wednesday: undefined,
                day_of_thursday: undefined,
                day_type_of_thursday: undefined,
                day_of_friday: undefined,
                day_type_of_friday: undefined,
                day_of_saturday: undefined,
                day_type_of_saturday: undefined
              }
            ]
          },
          {
            title: '2月',
            button_title: '2月のみ反映',
            day_on_count: 0,
            day_off_count: 0,
            data: [
              {
                day_of_sunday: undefined,
                day_type_of_sunday: undefined,
                day_of_monday: '1',
                day_type_of_monday: '1',
                day_of_tuesday: '2',
                day_type_of_tuesday: '1',
                day_of_wednesday: '3',
                day_type_of_wednesday: '1',
                day_of_thursday: '4',
                day_type_of_thursday: '1',
                day_of_friday: '5',
                day_type_of_friday: '1',
                day_of_saturday: '6',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '7',
                day_type_of_sunday: '3',
                day_of_monday: '8',
                day_type_of_monday: '1',
                day_of_tuesday: '9',
                day_type_of_tuesday: '1',
                day_of_wednesday: '10',
                day_type_of_wednesday: '1',
                day_of_thursday: '11',
                day_type_of_thursday: '1',
                day_of_friday: '12',
                day_type_of_friday: '1',
                day_of_saturday: '13',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '14',
                day_type_of_sunday: '3',
                day_of_monday: '15',
                day_type_of_monday: '1',
                day_of_tuesday: '16',
                day_type_of_tuesday: '1',
                day_of_wednesday: '17',
                day_type_of_wednesday: '1',
                day_of_thursday: '18',
                day_type_of_thursday: '1',
                day_of_friday: '19',
                day_type_of_friday: '1',
                day_of_saturday: '20',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '21',
                day_type_of_sunday: '3',
                day_of_monday: '22',
                day_type_of_monday: '1',
                day_of_tuesday: '23',
                day_type_of_tuesday: '1',
                day_of_wednesday: '24',
                day_type_of_wednesday: '1',
                day_of_thursday: '25',
                day_type_of_thursday: '1',
                day_of_friday: '26',
                day_type_of_friday: '1',
                day_of_saturday: '27',
                day_type_of_saturday: '2'
              },
              {
                day_of_sunday: '28',
                day_type_of_sunday: '3',
                day_of_monday: undefined,
                day_type_of_monday: undefined,
                day_of_tuesday: undefined,
                day_type_of_tuesday: undefined,
                day_of_wednesday: undefined,
                day_type_of_wednesday: undefined,
                day_of_thursday: undefined,
                day_type_of_thursday: undefined,
                day_of_friday: undefined,
                day_type_of_friday: undefined,
                day_of_saturday: undefined,
                day_type_of_saturday: undefined
              }
            ]
          }
        ],
        dayCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        dayOnCount: 0,
        dayOffCount: 0,
        dayUnknownCount: 0,
        dayUnknown2Count: 0,
        dayUnknown3Count: 0,
        holidayMessage: {}
      }
    },
    methods: {
      getColor (dayType) {
        if (dayType === '1') {
          return 'color: #000000;'
        } else if (dayType === '2') {
          return 'color: #0000FF;'
        } else if (dayType === '3') {
          return 'color: #FF0000;'
        } else {
          return 'color: #FF8C00;'
        }
      },
      updateResults () {
        let dayCount = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        let dayOnCount = 0
        let dayOffCount = 0
        let dayUnknownCount = 0
        let dayUnknown2Count = 0
        let dayUnknown3Count = 0
        for (const result of this.results) {
          const res = this.updateResult(result)
          dayOnCount += res[0]
          dayOffCount += res[1]
          dayUnknownCount += res[2]
          dayUnknown2Count += res[3]
          dayUnknown3Count += res[4]
          for (let i = 0; i < res[5].length; i++) {
            dayCount[i] += res[5][i]
          }
        }
        this.dayOnCount = dayOnCount
        this.dayOffCount = dayOffCount
        this.dayUnknownCount = dayUnknownCount
        this.dayUnknown2Count = dayUnknown2Count
        this.dayUnknown3Count = dayUnknown3Count
        this.dayCount = dayCount
      },
      updateResult (result) {
        let dayCount = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        let dayOnCount = 0
        let dayOffCount = 0
        let dayUnknownCount = 0
        let dayUnknown2Count = 0
        let dayUnknown3Count = 0
        for (const row of result.data) {
          if (row.day_of_sunday) {
            dayCount[parseInt(row.day_type_of_sunday) - 1]++
            if (row.day_type_of_sunday === '1' || row.day_type_of_sunday === '4' || row.day_type_of_sunday === '8' || row.day_type_of_sunday === '9') {
              dayOnCount++
              if (row.day_type_of_sunday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_sunday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_sunday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_monday) {
            dayCount[parseInt(row.day_type_of_monday) - 1]++
            if (row.day_type_of_monday === '1' || row.day_type_of_monday === '4' || row.day_type_of_monday === '8' || row.day_type_of_monday === '9') {
              dayOnCount++
              if (row.day_type_of_monday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_monday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_monday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_tuesday) {
            dayCount[parseInt(row.day_type_of_tuesday) - 1]++
            if (row.day_type_of_tuesday === '1' || row.day_type_of_tuesday === '4' || row.day_type_of_tuesday === '8' || row.day_type_of_tuesday === '9') {
              dayOnCount++
              if (row.day_type_of_tuesday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_tuesday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_tuesday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_wednesday) {
            dayCount[parseInt(row.day_type_of_wednesday) - 1]++
            if (row.day_type_of_wednesday === '1' || row.day_type_of_wednesday === '4' || row.day_type_of_wednesday === '8' || row.day_type_of_wednesday === '9') {
              dayOnCount++
              if (row.day_type_of_wednesday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_wednesday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_wednesday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_thursday) {
            dayCount[parseInt(row.day_type_of_thursday) - 1]++
            if (row.day_type_of_thursday === '1' || row.day_type_of_thursday === '4' || row.day_type_of_thursday === '8' || row.day_type_of_thursday === '9') {
              dayOnCount++
              if (row.day_type_of_thursday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_thursday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_thursday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_friday) {
            dayCount[parseInt(row.day_type_of_friday) - 1]++
            if (row.day_type_of_friday === '1' || row.day_type_of_friday === '4' || row.day_type_of_friday === '8' || row.day_type_of_friday === '9') {
              dayOnCount++
              if (row.day_type_of_friday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_friday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_friday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
          if (row.day_of_saturday) {
            dayCount[parseInt(row.day_type_of_saturday) - 1]++
            if (row.day_type_of_saturday === '1' || row.day_type_of_saturday === '4' || row.day_type_of_saturday === '8' || row.day_type_of_saturday === '9') {
              dayOnCount++
              if (row.day_type_of_saturday === '4') {
                dayUnknownCount++
              } else if (row.day_type_of_saturday === '8') {
                dayUnknown2Count++
              } else if (row.day_type_of_saturday === '9') {
                dayUnknown3Count++
              }
            } else {
              dayOffCount++
            }
          }
        }
        result.day_on_count = dayOnCount
        result.day_off_count = dayOffCount
        return [dayOnCount, dayOffCount, dayUnknownCount, dayUnknown2Count, dayUnknown3Count, dayCount]
      },
      isDataExist (data) {
        if (data.length === 0) {
          return false
        } else {
          const lastData = data[data.length - 1]
          if (lastData.day_of_saturday) {
            return false
          }
          return true
        }
      },
      search (updateMonth) {
        this.holiday.error = ''
        this.holidayMessage.text = ''
        const jobHoliday = []
        for (const item of this.holiday.job_holiday.items) {
          if (item.value) {
            // 所定休日と法定休日が同じ曜日の場合エラー
            for (const legal of this.holiday.legal_holiday.items) {
              if (legal.value) {
                if (item.id === legal.id) {
                  this.holidayMessage.text = this.$t('message.job_or_legal')
                  return
                }
              }
            }
            jobHoliday.push(parseInt(item.id, 10))
          }
        }
        const legalHoliday = []
        for (const legal of this.holiday.legal_holiday.items) {
          if (legal.value) {
            legalHoliday.push(parseInt(legal.id, 10))
          }
        }
        const nationalHoliday = this.holiday.national_holiday.value
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress,
          api_area: {
            target_date: parseInt(this.holiday.target_year, 10),
            job_holiday_list: jobHoliday,
            legal_holiday_list: legalHoliday,
            holiday_flag: nationalHoliday
          }
        }
        request.api_area = this.nullToBlank(request.api_area)
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/view/corporate_holiday`, request)
        .then(res => {
          this.labels = {...this.labels, ...this.getLabels(res.common_area)}
          this.comboData = {...this.comboData, ...this.getComboData(res.common_area.combo_data)}
          this.holiday.searched_year = this.holiday.target_year
          const results = []
          for (const entry of Object.entries(res.api_area)) {
            let month = 0
            switch (entry[0]) {
              case 'jan':
                month = 1
                break
              case 'feb':
                month = 2
                break
              case 'mar':
                month = 3
                break
              case 'apl':
                month = 4
                break
              case 'may':
                month = 5
                break
              case 'jun':
                month = 6
                break
              case 'jly':
                month = 7
                break
              case 'aug':
                month = 8
                break
              case 'sep':
                month = 9
                break
              case 'oct':
                month = 10
                break
              case 'nov':
                month = 11
                break
              case 'dec':
                month = 12
                break
            }
            const result = {
              month: month,
              title: month + this.$t('label.month'),
              button_title: this.$t('button.reset_specific_month').replace('{month}', month),
              day_on_count: 0,
              day_off_count: 0,
              data: []
            }
            let lastData = {}
            for (const dateInfo of entry[1]) {
              switch (dateInfo.day_of_the_week) {
                case 6:
                  lastData.day_of_sunday = dateInfo.date
                  lastData.day_type_of_sunday = String(dateInfo.holiday)
                  break
                case 0:
                  lastData.day_of_monday = dateInfo.date
                  lastData.day_type_of_monday = String(dateInfo.holiday)
                  break
                case 1:
                  lastData.day_of_tuesday = dateInfo.date
                  lastData.day_type_of_tuesday = String(dateInfo.holiday)
                  break
                case 2:
                  lastData.day_of_wednesday = dateInfo.date
                  lastData.day_type_of_wednesday = String(dateInfo.holiday)
                  break
                case 3:
                  lastData.day_of_thursday = dateInfo.date
                  lastData.day_type_of_thursday = String(dateInfo.holiday)
                  break
                case 4:
                  lastData.day_of_friday = dateInfo.date
                  lastData.day_type_of_friday = String(dateInfo.holiday)
                  break
                case 5:
                  lastData.day_of_saturday = dateInfo.date
                  lastData.day_type_of_saturday = String(dateInfo.holiday)
                  break
              }
              if (dateInfo.day_of_the_week === 5) {
                result.data.push(lastData)
                lastData = {}
              }
            }
            if (lastData.day_of_sunday) {
              result.data.push(lastData)
            }
            results.push(result)
          }
          if (typeof updateMonth === 'string') {
            for (const result of results) {
              if (result.title === updateMonth) {
                for (let i = 0; i < this.results.length; i++) {
                  if (this.results[i].title === updateMonth) {
                    this.results[i] = result
                    break
                  }
                }
                break
              }
            }
          } else {
            this.results = results
          }
          this.updateResults()
          this.showResult = true
        })
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_holiday`, request)
        .then(res => {
          this.holidayMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          this.holiday.target_year = new Date().getFullYear()
          this.search()
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
