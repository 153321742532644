<template>
  <div class="form-group">
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="labels.months_of_service + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <PaidHolidayPayment
        v-model="value"
        :disabled="disabled"
        :labels="labels"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import PaidHolidayPayment from '@/components/UIComponents/Inputs/PaidHolidayPayment'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      PaidHolidayPayment
    },
    props: {
      rules: String,
      value: Array,
      labels: Object,
      disabled: Boolean
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>