<template>
  <div>
    <span style="font-weight: bold;">{{ $t('label.threshold_break_time_label') }}</span>
    <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="disabled" @click="addRow" v-show="mode==modes.add || mode==modes.edit">{{$t('button.add_row')}}</button>
    <div style="color: #C84513; margin-bottom: 5px;" v-for="error in breakTimeRangeListErrors" v-bind:key="error">
      <small>{{error}}</small>
    </div>
    <el-table
      border
      :data="tableData"
      :empty-text="$t('label.no_data')"
      style="width: 511px">
      <el-table-column
        width="60"
        :label="$t('label.delete')"
        align="center">
        <template slot-scope="props">
          <div style="text-align: left;">
            <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="disabled"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="350"
        :label="$t('label.breaktime_conditions')"
        align="center">
        <template slot-scope="props">
          <div style="display: inline-block;">
            <el-select class="select-success width70"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.range_break_hour"
                        @change="hourChanged"
            >
              <el-option v-for="hour in hours"
                          class="select-success"
                          :value="hour"
                          :label="hour"
                          :key="hour">
              </el-option>
            </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
            <el-select class="select-success width70"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.range_break_minute"
                        @change="minuteChanged"
            >
              <el-option v-for="minute in minutes"
                          class="select-success"
                          :value="minute"
                          :label="minute"
                          :key="minute">
              </el-option>
            </el-select>&nbsp;{{$t('label.breaktime_range_message')}}&nbsp;
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="100"
        :label="labels.break_minutes"
        align="center">
        <template slot-scope="props">
          <input
            type="text"
            class="input-text"
            style="padding-right: 0.3em; width: 40px; text-align: right;"
            :disabled="disabled"
            maxlength="3"
            :placeholder="disabled ? '' : '60'"
            v-model="props.row.break_minutes">
          <!-- <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="disabled"
                      v-model="props.row.break_minutes">
            <el-option v-for="minute in breakMinutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select> -->
          &nbsp;{{$t('label.minute')}}&nbsp;
        </template>
      </el-table-column>
    </el-table>
    <br/>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      disabled: Boolean,
      mode: Number,
      labels: Object,
      breakTimeRangeListErrors: Array
    },
    data () {
      return {
        tableData: [],
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        breakMinutes: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'
        ]
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      hourChanged () {
        this.tableData = [...this.tableData]
        this.$emit('input', this.tableData)
      },
      minuteChanged () {
        this.tableData = [...this.tableData]
        this.$emit('input', this.tableData)
      },
      addRow () {
        this.tableData.push({
          range_break_hour: '0',
          range_break_minute: '00',
          break_minutes: '0'
        })
        this.$emit('input', this.tableData)
      }
    }
  }
</script>
