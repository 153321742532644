<template>
  <div class="row" name="employeeFrame">
    <div class="col-md-12">
      <modal name="WorkScheduleSearch" :width="1600" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :pivotX="0.85">
        <div class="modal-body" style="overflow-y: auto; height: 800px;">
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="hide">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
          <WorkScheduleSearch :mode="1" :params="params"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottom: 5px;" @click="replay" :title="$t('label.quick_tour')">
        <i class="ti-help"></i>
      </button>
      <div class="card">
        <div class="card-content" style="padding-left: 20px;">
          <div class="row" style="overflow-y: auto; height: 491px;">
            <label for="planId">
              {{$t('label.working_schedule')}}
            </label>
            <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button>
            <br>
            <!-- 全て割当を選択 -->
            <button v-if="!isReadOnly&&!allSelectFlg" type="button" class="btn btn-sm btn-default btn-primary" @click="allSelect">{{$t('button.allAllocationSelect')}}</button>
            <!-- 全て割当を解除 -->
            <button v-if="!isReadOnly&&allSelectFlg" type="button" class="btn btn-sm btn-default btn-primary" @click="allRelease">{{$t('button.allAllocationRelease')}}</button>
            <div class="error-message-wrapper" v-for="errorMsg in working_schedule_error" v-bind:key="errorMsg">
              <small class="text-danger">
                <!-- エラーメッセージ -->
                {{ errorMsg }}
              </small>
            </div>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"              border
              :data="working_schedule_record"
              height="431px"
              style="width: 680px"
              :row-class-name="tableRowClassName">
              <!-- 勤務体系 -->
              <el-table-column min-width="150" :label="$t('label.working_schedule')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <small>{{props.row.work_schedule_code}}</small>
                  </div>
                  <div style="text-align: left;">
                    {{props.row.work_schedule_name}}
                  </div>
                </template>
              </el-table-column>
              <!-- 事業場外みなし労働時間制フラグ -->
              <el-table-column min-width="100" :label="labels.off_site_deemed_working_hours_flag_ryaku ? labels.off_site_deemed_working_hours_flag_ryaku : ''">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <span v-html="breakLabel(scope.column.label, 2)"></span>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.off_site_deemed_working_hours_flag" class="gray" :disabled="!props.row.selected || isReadOnly" />
                  </div>
                </template>
              </el-table-column>
              <!-- デフォルト勤務体系 -->
              <el-table-column min-width="70" :label="$t('label.default_work_schedule')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="601">
                    <div class="phase-header__title">
                      <span v-html="breakLabel(scope.column.label, 2)"></span>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center; padding: 10px 0 0 10px;">
                    <p-radio v-model="selectDefaultWorkingSchedule" :label="props.row.work_schedule_code" :disabled="!props.row.selected || isReadOnly || isEnd(props.row.term_to)"></p-radio>
                  </div>
                </template>
              </el-table-column>
              <!-- 割当 -->
              <el-table-column min-width="40" :label="$t('label.allocation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="600">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly" @change="changeAllocation(props.row)" />
                  </div>
                </template>
              </el-table-column>
              <!-- GPS打刻必須 -->
              <el-table-column min-width="60" :label="labels.is_need_gps_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader" data-v-step="602">
                    <div class="phase-header__title">
                      <span v-html="breakLabel(scope.column.label, 2)"></span>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.is_need_gps" class="gray" :disabled="isReadOnly||!props.row.selected"/>
                  </div>
                </template>
              </el-table-column>
              <!-- 遅刻/早退を計算しますか -->
              <el-table-column min-width="100" :label="$t('label.is_hidden_late_time')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <span v-html="breakLabel(scope.column.label, 2)"></span>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.is_disp_late_time" class="gray" :disabled="isReadOnly||!props.row.selected"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <Tour tour-name="myTour600" :steps="steps"/>
    </div>
  </div>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import WorkScheduleSearch from 'src/components/Dashboard/Views/Generated/WorkScheduleSearch.vue'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      WorkScheduleSearch,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        steps: [
          {
            target: '[data-v-step="600"]',
            content: '必ず1つ以上選択してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="601"]',
            content: '基本で利用する勤務体系を1つ選択してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="602"]',
            content: '事業所に設定された場所の範囲で打刻を制御できます。',
            params: {placement: 'top'}
          }
        ],
        labels: {},
        working_schedule_record: [],
        selectDefaultWorkingSchedule: '',
        working_schedule_error: [],
        allSelectFlg: false,
        original_working_schedule_employee_list: [],
        today: ''
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      replay () {
        this.$tours['myTour600'].start()
      },
      init () {
        this.isAllSelect()
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        this.today = Number(String(year) + month + day)
      },
      allClear () {
        this.selectDefaultWorkingSchedule = ''
        for (const record of this.working_schedule_record) {
          record.selected = false
          record.off_site_deemed_working_hours_flag = false
        }
      },
      setDetails (res, mode) {
        if ((mode !== this.modes.add) || (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '')) {
          this.original_working_schedule_employee_list = res.api_area.working_schedule_employee_list
          for (const data of res.api_area.working_schedule_employee_list) {
            for (const record of this.working_schedule_record) {
              if (data.work_schedule_code === record.work_schedule_code) {
                record.selected = true
                record.off_site_deemed_working_hours_flag = data.off_site_deemed_working_hours_flag
                record.is_need_gps = data.is_need_gps
                if (data.is_disp_late_time === null) {
                  record.is_disp_late_time = false
                } else {
                  record.is_disp_late_time = !data.is_disp_late_time
                }
              }
            }
            if (data.default_work_schedule_code === 1) {
              this.selectDefaultWorkingSchedule = data.work_schedule_code
            }
          }
        }
      },
      tableRowClassName ({row}) {
        if (this.isEnd(row.term_to)) {
          return 'out-row'
        }
        return ''
      },
      showAdd () {
        this.params = {}
        this.$modal.show('WorkScheduleSearch')
      },
      hide (...args) {
        this.working_schedule_record = []
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 勤務体系
          for (const data of res.api_area.work_schedule_list) {
            let record = {
              selected: false,
              work_schedule_code: data.work_schedule_code,
              work_schedule_name: data.work_schedule_name,
              term_from: data.term_from,
              term_to: data.term_to,
              off_site_deemed_working_hours_flag: false,
              default_work_schedule_code: ''
            }
            this.working_schedule_record.push(record)
          }
          res.api_area.working_schedule_employee_list = this.original_working_schedule_employee_list
          this.setDetails(res, this.mode)
        })
        this.$modal.hide('WorkScheduleSearch')
      },
      changeAllocation (row) {
        if (row.work_schedule_code === this.selectDefaultWorkingSchedule) {
          this.selectDefaultWorkingSchedule = ''
        }
        if (!row.selected) {
          row.off_site_deemed_working_hours_flag = row.selected
          row.is_need_gps = row.selected
          row.is_disp_late_time = row.selected
        }
        this.isAllSelect()
      },
      isAllSelect () {
        let allSelect = true
        for (const data of this.working_schedule_record) {
          if (!data.selected) {
            allSelect = false
          }
        }
        this.allSelectFlg = allSelect
      },
      allSelect () {
        for (const data of this.working_schedule_record) {
          data.selected = true
        }
        this.allSelectFlg = true
      },
      allRelease () {
        this.selectDefaultWorkingSchedule = ''
        for (const data of this.working_schedule_record) {
          data.selected = false
        }
        this.allSelectFlg = false
      },
      isEnd (termTo) {
        if (typeof termTo === 'object') {
          termTo = this.formatDate(termTo)
        }
        if (termTo === null || termTo === '') {
          return false
        }
        termTo = termTo.split('/')
        termTo = termTo.join('')
        if (Number(termTo) < this.today) {
          return true
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
