<template>
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <template v-for="item in obj.items">
      <p-radio 
        v-bind:key="item.id"
        v-model="obj.value" 
        :label="item.value" 
        :inline="true"
        :disabled="obj.disabled || disabled"
        @click="$emit('input', obj)"
        @change="$emit('change')"
      >
        {{item.label}}　
      </p-radio>
    </template>
    <small class="text-danger" v-show="!valid">
      {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
    </small>
  </ValidationProvider>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      rules: String,
      value: Object,
      itemLabel: String,
      disabled: Boolean
    },
    computed: {
      isExist () {
        return this.obj !== undefined && this.obj.items !== undefined
      }
    },
    data () {
      return {
        obj: {
          value: undefined,
          disabled: true,
          items: []
        }
      }
    },
    watch: {
      value () {
        this.obj = this.value
      }
    },
    mounted () {
      if (this.value) {
        this.obj = this.value
      }
    }
  }
</script>

<style scoped>
  .radio.radio-inline {
      margin-top: 6px !important;
  }
</style>