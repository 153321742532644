<template>
  <div>
     <div class="form-group" style="margin-bottom: 0;">
      <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
        <small class="text-danger" v-show="!valid">
          {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
        <div>
          <el-select :class="itemClasses"
                      size="small"
                      :name="itemName"
                      :disabled="disabled"
                      :placeholder="placeholder"
                      v-model="itemValue"
                      @change="changed">
            <template v-if="Boolean(unselected)">
              <el-option value="" class="select-success">{{placeholder}}</el-option>
            </template>
            <el-option v-for="option in options"
                        class="select-success"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
            </el-option>
          </el-select>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      itemName: String,
      rules: String,
      itemLabel: String,
      options: Array,
      disabled: Boolean,
      buttonLabel: String,
      classes: String,
      placeholder: String,
      unselected: String
    },
    data () {
      return {
        itemValue: null,
        itemClasses: 'select-success'
      }
    },
    methods: {
      changed () {
        this.$emit('input', this.itemValue)
        this.$emit('change')
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
        this.itemClasses = 'select-success ' + this.classes
      }
    },
    mounted () {
      this.itemValue = this.value
      this.itemClasses = 'select-success ' + this.classes
    }
  }
</script>

<style scoped>
</style>
