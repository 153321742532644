<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <div class="col-sm-12">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="change_password" div-style="padding-bottom: 5px;"/>
        </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{pageTitle}}
            </h4>
          </div>
          <div class="card-content">
            <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
            <!-- 検索条件 -->
            <!-- 現在のパスワード -->
            <div>
              <label>{{$t('label.label_old_password')}}</label>
            </div>
            <div>
              <input 
                type="password" 
                v-model="oldPassword"
                name="oldPassword" 
                placeholder=""
                :maxlength="60"
                class="formItem length70"
              />
              <span class="field-icon">
                <i toggle="password-field" class="mdi mdi-eye toggle-password">
                </i>
              </span>
            </div>
            <!--　新しいパスワード -->
            <div style="margin-top: 20px;">
              <label>{{$t('label.label_new_password')}}</label>
            </div>
            <div class="error-message-wrapper" v-show="noChangePassword!==''">
              <small class="text-danger">
                <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
                {{ noChangePassword }}
              </small>
            </div>
            <div class="pw-form">
              <p>
                <input
                  type="password"
                  v-model="newPassword"
                  :secureLength="12"
                  @score="showScore"
                  class="formItem length70"
                  name="newPassword"
                  :maxlength="60"
                />
                <span class="field-icon">
                  <i toggle="password-field" class="mdi mdi-eye toggle-password">
                  </i>
                </span>
              </p>
              <VPasswordStrengthMeter 
                :password="newPassword"
                :enableFeedback="false"
                @strengthChange="showScore"
                class="length70"
              />
            </div>
            <!-- ヒント -->
            <div>
              <label>{{$t('label.hint')}}</label>
            </div>
            <div>
              <input 
                type="text" 
                v-model="hint"
                name="hint" 
                placeholder=""
                :maxlength="255"
                class="formItem length70"
              />
            </div>
            <div style="text-align: left; margin-top: 20px;">
              <!-- 変更ボタン -->
              <button 
                type="button" 
                class="btn btn-info btn-sm btn-default" 
                @click="changePassword(oldPassword, newPassword, updateCount)"
              >
              {{$t('button.button_change_password')}}
              </button>
            </div>
            <!-- 注釈エリア -->
            <div style="margin-top: 20px;">
              <span>{{$t('label.change_password_annotation1')}}</span>
              <br>
              <span>{{$t('label.change_password_annotation2')}}</span>
              <br>
              <span>&nbsp;&nbsp;&nbsp;{{$t('label.change_password_annotation3')}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import VPasswordStrengthMeter from 'v-password-strength-meter'
import mixin from '@/lib/mixin'
import $ from 'jquery'

export default {
  mixins: [mixin],
  components: {
    VPasswordStrengthMeter
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      hint: '',
      updateCount: 0,
      isCheck: false,
      passwordScore: 0,
      noChangePassword: ''
    }
  },
  computed: {
  },
  methods: {
    // 初期表示
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/init/change_password', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = res.common_area.combo_data
        this.hint = res.api_area.hint
        this.updateCount = res.api_area.update_count
      })
    },
    showScore (score) {
      this.passwordScore = score
    },
    // 変更ボタン押下処理
    changePassword (oldPassword, newPassword, updateCount) {
      // パスワードが同じ場合、処理を終了
      if (this.noChangePassword !== '') {
        return
      }
      // メッセージを初期化
      this.message = {}
      this.isCheck = true
      // 新しいパスワードが空ならエラー
      if (newPassword === '') {
        this.message.text = this.$t('message.is_entered_new_password')
        this.isCheck = false
      }
      // 現在のパスワードが空ならエラー
      if (oldPassword === '') {
        this.message.text = this.$t('message.is_entered_old_password')
        this.isCheck = false
      }
      if (this.isCheck) {
        // 新しいパスワードの強度チェック、強度4未満はエラー(0～4の5段階)
        if (this.passwordScore < 3) {
          this.message.text = this.$t('message.is_weakly_new_password')
          this.isCheck = false
        }
        // 新しいパスワードの文字列が許可している文字以外ならエラー
        // （0-9,A-Z,a-z,-,_,@,!,#,$, ）のみ許可
        if (!newPassword.match(/^[a-zA-Z0-9_@!#$-\s]+$/)) {
          this.message.text = this.$t('message.is_unusable_string_new_password')
          this.isCheck = false
        }
        // 12文字以上でない場合はエラー
        if (newPassword.length < 12) {
          this.message.text = this.$t('message.is_word_count_new_password')
          this.isCheck = false
        }
      }
      // チェックエラーが出ていない場合変更処理を実施する。
      if (this.isCheck) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            old_password: oldPassword,
            new_password: newPassword,
            hint: this.hint,
            update_count: updateCount
          }
        }
        this.send('/update/change_password', data)
        .then(res => {
          // 更新成功or失敗を受け取る
          this.message.text = res.message.text
          this.updateCount += 1
        })
      }
    }
  },
  watch: {
    'newPassword': function (newVal, oldVal) {
      if (this.oldPassword === this.newPassword) {
        this.noChangePassword = this.$t('message.no_change_password')
      } else {
        this.noChangePassword = ''
      }
    },
    'oldPassword': function (newVal, oldVal) {
      if (this.oldPassword === this.newPassword) {
        this.noChangePassword = this.$t('message.no_change_password')
      } else {
        this.noChangePassword = ''
      }
    }
  },
  created () {
    this.init()
  },
  // 目のマークの処理
  mounted: function () {
    $('.toggle-password').click(function () {
      $(this).toggleClass('mdi-eye mdi-eye-off')
      var input = $(this).parent().prev('input')
      if (input.attr('type') === 'password') {
        input.attr('type', 'text')
      } else {
        input.attr('type', 'password')
      }
    })
  }
}
</script>
<style scoped>
/*テキストボックス・アイコンの位置調整*/
.formItem {
  font-size: 14px;
}
/*パスワードの非表示*/
.mdi-eye:before {
   background: url(../../../../../public/static/img/toggleicons/eye-off.png);
   background-size: contain;
   content: '';
   display: block;
   width: 20px;
   height: 20px;
   position: absolute;
   top:3px;
   right: 5px;
}
/*パスワードの表示*/
.mdi-eye-off:before {
   background: url(../../../../../public/static/img/toggleicons/eye.png);
   background-size: contain;
   content: '';
   display: block;
   width: 20px;
   height: 20px;
   position: absolute;
   top:3px;
   right: 5px;
}
.field-icon {
   position: absolute;
}
</style>
