<template>
  <div>
    <Loading
      :message="description"
      :loading="loading"
      :show-button2="!loading"
    />
  </div>
</template>
<script>
  import Loading from '@/components/Dashboard/Views/Components/Loading'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Loading
    },
    data () {
      return {
        loading: true,
        description: 'しばらくお待ちください。'
      }
    },
    created () {
      const self = this
      const mailAddress = this.$route.query.mail_address ? this.$route.query.mail_address : ''
      this.$firebase.auth().signInWithEmailAndPassword(process.env.VUE_APP_FIREBASE_USER, process.env.VUE_APP_FIREBASE_PASSWORD)
      .then(() => {
        self.$i18n.locale = 'ja'
        localStorage.setItem('language', self.$i18n.locale)
        sessionStorage.setItem('mailAddress', mailAddress)
        self.$router.push({name: 'userCompanyWizard'})
      })
    }
  }
</script>
<style scoped>
</style>
