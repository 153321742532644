import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
export default {
  name: 'EmployeeAlertManagementMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect,
    ValidationRadio
  },
  props: {
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        management_data_list: []
      },
      labels: {},
      comboData: {},
      tempmessage: ''
    }
  },
  computed: {
    screenCode () {
      return '11-006'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      // 更新時のコメントを表示
      if (this.tempmessage) {
        this.message.text = this.tempmessage
      }
      const items = []
      // アラート管理のラジオ作成
      for (const row of this.comboData.alert_management_control_list) {
        items.push({
          id: row.value,
          label: this.getLabel(row.label),
          value: String(row.value)
        })
      }
      for (const managementData of this.detail.management_data_list) {
        // アラート管理にラジオを設定
        managementData.alert_management_control = {
          value: managementData.alert_management_control,
          items: items
        }
        // アラート通知方法を文字列に変換
        managementData.alert_notification_method = String(managementData.alert_notification_method)
      }
    },
    // 独自バリデーション用
    customValidate () {
      return true
    },
    // 送信データ調整用
    convertDetail (detail) {
      for (const managementData of detail.management_data_list) {
        // 文字列を数値に変換
        managementData.alert_management_control = parseInt(managementData.alert_management_control.value, 10)
        managementData.alert_notification_method = parseInt(managementData.alert_notification_method, 10)
        // アラート管理しない場合、アラート通知方法はアルテミストップページ
        if (managementData.alert_management_control === 2) {
          managementData.alert_notification_method = 2
        }
        // アラート通知方法がメールアドレスまたは全て以外はメールアドレスはブランク
        if (!(managementData.alert_notification_method === 1 | managementData.alert_notification_method === 9)) {
          managementData.mail_address = ''
        }
      }
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = JSON.parse(JSON.stringify(this.detail))
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        this.send(`/${operation}/employee_alert_management`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/employee_alert_management', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.detail.management_data_list = Array.isArray(res.api_area) ? res.api_area : res.api_area.management_data_list
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    alertManagementControlChanged () {
    },
    alertNotificationMethodChanged () {
    }
  },
  created () {
    this.init()
    this.componentKey += 1
  }
}
