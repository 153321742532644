<template>
  <OrganizationChart 
    :datasource="dataSource" 
    :pan="false" 
    ref="chart"
  >
    <template slot-scope="{ nodeData }">
      <div class="my-node-body">
        <div class="my-node-title">
          <a style="color: #FFF;" href="#" @click.prevent.stop="nodeClicked(nodeData.code)">
            {{ nodeData.name }}
          </a>
        </div>
      </div>
    </template>
  </OrganizationChart>
</template>
<script>
  import OrganizationChart from 'vue-organization-chart'
  import 'vue-organization-chart/dist/orgchart.css'
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    components: {
      OrganizationChart
    },
    props: {
      params: Object
    },
    computed: {
    },
    data () {
      return {
        id: 1,
        dataSource: {}
      }
    },
    methods: {
      nodeClicked (groupCode) {
        this.$emit('nodeClicked', groupCode)
      },
      getChildren (upperGroupCode) {
        const children = []
        for (const row of this.params.group_list) {
          if (row.upper_group_code === upperGroupCode) {
            const child = {
              id: this.id++,
              code: row.group_code,
              name: row.group_name,
              children: this.getChildren(row.group_code)
            }
            children.push(child)
          }
        }
        return children
      },
      init () {
        const dataSource = {}
        dataSource.id = this.id++
        dataSource.name = this.$store.state.loginUser.companyName
        dataSource.children = this.getChildren(null)
        console.log(dataSource)
        this.dataSource = dataSource
      },
      close () {
        this.$emit('close')
      }
    },
    watch: {
      params () {
        if (this.params && this.params.group_list) {
          this.init()
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .my-node-body {
    border: 2px solid #364F6B;
    border-radius: 5px;
    background: #364F6B;
    padding: 5px 10px;
  }
  .my-node-title {
    color: #FFF;
  }
</style>
