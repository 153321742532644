<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div style="color: #C84513; margin-bottom: 5px;" v-for="errMsg in errMsgs" v-bind:key="errMsg">
          <small>{{errMsg}}</small>
        </div>
        <b>{{routeType==='1' ? labels.individualRoute : labels.commonRoute}}</b>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="addRow" v-show="routeType!=='1' || labels.is_individual_route">{{$t('button.add_row')}}</button>
      </div>
      <div class="card-content">
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                  :data="route"
                  :empty-text="$t('label.no_data')">
          <el-table-column width="60" :label="$t('label.delete')" align="center">
            <template slot-scope="props">
              <template v-if="!props.row.isFixed">
                <div style="text-align: center;">
                  <input type="checkbox" v-model="props.row.selected" class="gray">
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column width="120" :label="$t('label.order')" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.route_order}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" :label="labels.company ? labels.company : '会社'" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <small>{{props.row.company_code}}</small>
                <br>
                {{props.row.company_name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="300" :label="labels.approverl_employee_role ? labels.approverl_employee_role : '承認者 / ロール'" align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <div style="display: inline-block;">
                  <input 
                    type="text"
                    class="form-control width110" 
                    :class="{'errorBorder': props.row.isEmployeeError}"
                    v-model="props.row.employee_code"
                    :disabled="props.row.isFixed"
                    @blur="approverSelected(props.$index, 1)">
                </div>
                <div style="display: inline-block;">
                  <input 
                    type="text"
                    class="form-control width150" 
                    v-model="props.row.employee_name" 
                    :disabled="props.row.isFixed"
                    @blur="approverSelected(props.$index, 2)">
                </div>
                <template v-if="!props.row.isFixed">
                  <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="isReadOnly" @click="showApproverSearch(props.$index)" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="220" :label="labels.group ? labels.group : ''" align="center">
            <template slot-scope="props">
              <el-select class="select-success width210"
                          size="large"
                          placeholder=""
                          :class="{'errorSelectBorder': props.row.isGroupError}"
                          v-model="props.row.group_code"
                          :disabled="props.row.isFixed || isReadOnly">
                <el-option v-for="group in props.row.groups"
                            class="select-success"
                            :value="group.group_code"
                            :label="group.group_name"
                            :key="group.key">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column min-width="120" :label="labels.authority ? labels.authority : '権限'" align="center">
            <template slot-scope="props">
              <el-select class="select-success width110"
                          size="large"
                          placeholder=""
                          :class="{'errorSelectBorder': props.row.isAuthError}"
                          v-model="props.row.auth_code"
                          :disabled="props.row.isFixed || isReadOnly">
                <template v-if="props.row.isFixed">
                  <el-option v-for="approvalFunction in comboData.approval_function_list"
                              class="select-success"
                              :value="approvalFunction.value"
                              :label="approvalFunction.label"
                              :key="approvalFunction.value">
                  </el-option>
                </template>
                <template v-else-if="routeType==='1'">
                  <el-option v-for="approvalFunction in comboData.direct_approval_function_list"
                              class="select-success"
                              :value="approvalFunction.value"
                              :label="approvalFunction.label"
                              :key="approvalFunction.value">
                  </el-option>
                </template>
                <template v-else>
                  <el-option v-for="approvalFunction in comboData.indirect_approval_function_list"
                              class="select-success"
                              :value="approvalFunction.value"
                              :label="approvalFunction.label"
                              :key="approvalFunction.value">
                  </el-option>
                </template>
              </el-select>                    
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'

  export default{
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect
    },
    props: {
      routeType: String,
      value: Array,
      comboData: Object,
      labels: Object,
      isReadOnly: Boolean,
      errMsgs: Array
    },
    data () {
      return {
        searchCond: {
          company_code: this.$store.state.loginUser.companyCode,
          office_code: '',
          group_code: '',
          team_code: '',
          office_range: 2,
          group_range: 2,
          team_range: 2,
          search_employee_code: '',
          employee_classification_code: '',
          employee_code: '',
          search_type: '1',
          retirement: false
        },
        currentIndex: 0,
        route: []
      }
    },
    methods: {
      showApproverSearch (index) {
        this.currentIndex = index
        this.$emit('showApproverSearch', this.routeType)
      },
      hideApproverSearch (selected) {
        if (selected) {
          // this.route[this.currentIndex].company_code = selected.company_code
          // this.route[this.currentIndex].company_name = selected.company_name
          this.route[this.currentIndex].company_code = this.$store.state.loginUser.companyCode
          this.route[this.currentIndex].company_name = this.$store.state.loginUser.companyName
          this.route[this.currentIndex].group_code = selected.group_list[0].group_code
          this.route[this.currentIndex].group_name = selected.group_list[0].group_name
          this.route[this.currentIndex].employee_code = selected.employee_code
          this.route[this.currentIndex].employee_name = selected.employee_name
          this.route[this.currentIndex].groups = selected.group_list
        }
      },
      approverSelected (index, searchType) {
        if ((searchType === 1 && this.route[index].employee_code.length === 0) ||
        (searchType === 2 && this.route[index].employee_name.length === 0)) {
          this.route[index].group_code = ''
          this.route[index].group_name = ''
          this.route[index].employee_code = ''
          this.route[index].employee_name = ''
          this.route[index].groups = []
          return
        }
        if (!this.canShow('/SS/menuSSScreen018')) return
        let searchCond = {
          screen_code: 'SS-018',
          company_code: this.route[index].company_code,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: '',
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: true
          }
        }
        if (searchType === 1) {
          searchCond.api_area.employee_code = this.route[index].employee_code
        } else {
          searchCond.api_area.employee_name = this.route[index].employee_name
        }
        this.send('/view/search_employee/list', searchCond)
        .then(res => {
          if (res.api_area.employee_list.length === 1) {
            this.currentIndex = index
            this.hideApproverSearch(res.api_area.employee_list[0])
          } else {
            this.route[index].group_code = ''
            this.route[index].group_name = ''
            this.route[index].employee_code = ''
            this.route[index].employee_name = ''
            this.route[index].groups = []
          }
        })
      },
      addRow () {
        const additionalRoute = {
          isFixed: false,
          selected: false,
          route_order: '',
          company_code: this.$store.state.loginUser.companyCode,
          company_name: this.$store.state.loginUser.companyName,
          group_code: '',
          group_name: '',
          employee_code: '',
          employee_name: '',
          role_code: '',
          role_name: '',
          auth_code: '1',
          groups: [],
          roles: []
        }
        this.route.splice(0, 0, additionalRoute)
      },
      deleteRow () {
        for (let i = this.route.length - 1; i >= 0; i--) {
          if (this.route[i].selected) {
            this.route.splice(i, 1)
          }
        }
      }
    },
    watch: {
      value () {
        this.route = this.value
      }
    },
    mounted () {
      this.route = this.value
    }
  }
</script>
<style scoped>
  .el-table .cell {
    white-space: nowrap !important;
  }  
  .errorBorder {
    border: 1px solid red;
  }
  .errorSelectBorder.el-select .el-input input {
    border-color: red !important;
  }
</style>
