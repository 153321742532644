<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @show-employee="showEmployeeSearchModal" @close="hide" ref="detail"/>
      </div>
    </modal>
    <!-- 従業員検索モーダルサブ パラメータ：検索条件 -->
    <modal name="employeeSearchModal" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="convertSearchCond" :is-modal="true" @close="hideEmployeeSearchModal" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack" style="margin-bottom: 2px;">
          {{$t('button.back')}}
        </button>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <!-- 部門名 -->
              <el-table-column v-if="showDepartmentName" :width="getColumnWidthStrong(tableData, labels.supplierDepartment_ryaku, 'department_name')" :label="labels.supplierDepartment_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" :title="props.row.department_name">
                    {{props.row.department_name}}
                  </div>
                </template>
              </el-table-column>
              <!-- 従業員番号[担当] -->
              <el-table-column v-if="showResponsibleEmployeeCode" :width="getColumnWidthStrong(tableData, labels.responsible_employee_code_ryaku === undefined ? labels.responsibleEmployeeCode_ryaku === undefined ? '' : labels.responsibleEmployeeCode_ryaku : labels.responsible_employee_code_ryaku, 'responsible_employee_code')" :label="labels.responsible_employee_code_ryaku === undefined ? labels.responsibleEmployeeCode_ryaku === undefined ? '' : labels.responsibleEmployeeCode_ryaku : labels.responsible_employee_code_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" :title="props.row.responsible_employee_name">
                    {{props.row.responsible_employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.responsible_employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 更新日-->
              <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{ props.row.update_employee_name }}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{ props.row.update_employee_code }}</small>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import SupplierDepartmentSearchMixin from '@/components/Dashboard/Views/MasterProject/SupplierDepartmentSearchMixin'

  export default {
    name: 'SupplierDepartmentSearch',
    mixins: [SupplierDepartmentSearchMixin]
  }
</script>
<style scoped>
</style>
