<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" @showPlaceCode="showPlaceCode" ref="detail"/>
      </div>
    </modal>
    <modal name="place" :draggable="false" :clickToClose="false" height="auto">
      <div class="modal-body">
        <PlaceDetail :params="params" :mode="modes.add" :is-from-office="true" @close="hidePlaceCode"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.office_code}}</label>
            <input type="text" :placeholder="$t('placeholder.office_code')" maxlength="10" class="form-control length10" v-model="searchCond.office_code">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.office_name}}</label>
            <input type="text" :placeholder="$t('placeholder.office_name')" maxlength="30" class="form-control length60" v-model="searchCond.office_name">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.tel}}</label>
            <input type="text" :placeholder="$t('placeholder.tel')" maxlength="20" class="form-control length20" v-model="searchCond.tel">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.fax}}</label>
            <input type="text" :placeholder="$t('placeholder.fax')" maxlength="20" class="form-control length20" v-model="searchCond.fax">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.place_name}}</label>
            <input type="text" :placeholder="$t('placeholder.place_name')" maxlength="30" class="form-control length60" v-model="searchCond.place_name">
          </div>
          <div class="form-group">
            <input type="checkbox" class="gray" v-model="searchCond.outdate_search_flag" id="outdate_search_flag">
            <label for="outdate_search_flag">{{$t('label.search_all')}}</label>
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showOffice" :width="getColumnWidthStrong(tableData, labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku, 'office_code', 'office_name')" :label="labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.office_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.office_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTel" :width="getColumnWidthStrong(tableData, labels.tel_ryaku === undefined ? labels.tel_ryaku === undefined ? '' : labels.tel_ryaku : labels.tel_ryaku, 'tel')" :label="labels.tel_ryaku === undefined ? labels.tel_ryaku === undefined ? '' : labels.tel_ryaku : labels.tel_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.tel}}
                  </div>
                  <div style="text-align: left;" v-if="props.row.fax">
                    <small>{{ '(' + labels.fax_ryaku + ') ' + props.row.fax }}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPlaceName" :width="getColumnWidthStrong(tableData, labels.place_name_ryaku === undefined ? labels.placeName_ryaku === undefined ? '' : labels.placeName_ryaku : labels.place_name_ryaku, 'place_name')" :label="labels.place_name_ryaku === undefined ? labels.placeName_ryaku === undefined ? '' : labels.placeName_ryaku : labels.place_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.place_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showJobTotalMinutes" :width="getColumnWidthStrong(tableData, labels.job_total_minutes_ryaku === undefined ? labels.jobTotalMinutes_ryaku === undefined ? '' : labels.jobTotalMinutes_ryaku : labels.job_total_minutes_ryaku, 'job_total_minutes')" :label="labels.job_total_minutes_ryaku === undefined ? labels.jobTotalMinutes_ryaku === undefined ? '' : labels.jobTotalMinutes_ryaku : labels.job_total_minutes_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{formatDispMinute(props.row.job_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermFrom" :width="getColumnWidthStrong(tableData, labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku, 'term_from')" :label="labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.term_from}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermTo" :width="getColumnWidthStrong(tableData, labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku, 'term_to')" :label="labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.term_to}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{ props.row.update_employee_name }}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{ props.row.update_employee_code }}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCalendar" :width="getButtonColumnWidth([$t('button.calendar')])" label="">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <button type="button" class="btn btn-warning btn-sm btn-default" @click="calendarClicked(props.$index, props.row)">{{$t('button.calendar')}}</button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import OfficeSearchMixin from '@/components/Dashboard/Views/Generated/OfficeSearchMixin'

  export default {
    name: 'OfficeSearch',
    methods: {
      afterInit (res) {
        if (window.previousUrl === '/07/menu07Screen001') {
          const orgOfficeSearchCond = sessionStorage.getItem('orgOfficeSearchCond')
          if (orgOfficeSearchCond) {
            sessionStorage.removeItem('orgOfficeSearchCond')
            this.searchCond = JSON.parse(orgOfficeSearchCond)
            this.search()
          }
        }
      },
      afterSearch (res) {
        sessionStorage.setItem('orgOfficeSearchCond', JSON.stringify(this.searchCond))
        for (const row of res.api_area.office_data) {
          row.industry_code_big = row.industry_name_big
          row.industry_code_during = row.industry_name_during
          row.industry_code_small = row.industry_name_small
          row.outdate_flag = row.is_future
        }
      },
      showPlaceCode () {
        this.$modal.show('place')
      },
      hidePlaceCode (changed, added) {
        this.$modal.hide('place')
        if (changed) {
          this.$refs.detail.comboData.place_list.push({
            label: added.place_name,
            value: added.place_code
          })
          this.$refs.detail.detail.place_code = added.place_code
        }
      },
      calendarClicked (index, row) {
        this.$router.push({name: 'annualCalendarSearch', params: {paramOfficeCode: row.office_code, paramYear: row.calender}})
      }
    },
    mixins: [OfficeSearchMixin]
  }
</script>
<style scoped>
</style>
