<template>
  <ValidationObserver ref="observer">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottom: 5px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="row" style="overflow-y: auto; height: 537px;" name="companyFrame">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 社会保険 -->
              <ValidationRadio
                rules="required"
                v-model="social_insurance"
                :item-label="labels.social_insurance"
                :disabled="isReadOnly||company_social_insurance===2"
                data-v-step="200"
              />
              <template v-if="social_insurance_flg">
                <!-- 社会保険取得日 -->
                <ValidationDate
                  v-model="social_insurance_date"
                  item-name="social_insurance_date"
                  rules="required"
                  :itemLabel="labels.social_insurance_date"
                  :placeholder="$t('placeholder.social_insurance_date')"
                  :disabled="isReadOnly"
                  data-v-step="201"
                />
                <!-- 健康保険記号 -->
                <ValidationText
                  v-model="health_insurance_sign"
                  item-name="health_insurance_sign"
                  max-length="8"
                  classes="form-control length8"
                  :item-label="labels.health_insurance_sign"
                  :placeholder="$t('placeholder.health_insurance_sign')"
                  :help-text="$t('label.max_length')"
                  :disabled="isReadOnly"
                />
                <!-- 健康保険No -->
                <ValidationText
                  v-model="health_insurance_no"
                  item-name="health_insurance_no"
                  max-length="8"
                  classes="form-control length8"
                  :item-label="labels.health_insurance_no"
                  :placeholder="$t('placeholder.health_insurance_no')"
                  :help-text="$t('label.max_length')"
                  :disabled="isReadOnly"
                />
                <!-- 介護保険対象区分 -->
                <ValidationSelect
                  v-model="is_long_term_care_insurance_care_category"
                  :item-label="labels.is_long_term_care_insurance_care_category"
                  :options="is_long_term_care_insurance_care_category_list"
                  classes="length12"
                  rules="required"
                  :disabled="isReadOnly"
                  data-v-step="202"
                />
                <!-- 基礎年金番号 -->
                <ValidationText
                  v-model="basic_pension_number"
                  item-name="basic_pension_number"
                  max-length="11"
                  classes="form-control length11"
                  :item-label="labels.basic_pension_number"
                  :placeholder="$t('placeholder.basic_pension_number')"
                  :help-text="$t('label.max_length')"
                  rules="basicPensionNumber"
                  :disabled="isReadOnly"
                />
                <!-- 社会保険料免除 -->
                <ValidationSelect
                  v-model="premium_exemption_during_childcare_leave"
                  :item-label="labels.premium_exemption_during_childcare_leave"
                  :options="premium_exemption_during_childcare_leave_list"
                  classes="length12"
                  rules="required"
                  :disabled="isReadOnly"
                  data-v-step="202"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour200" :steps="steps"/>
  </ValidationObserver>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        steps: [],
        stepsBk: [
          {
            target: '[data-v-step="200"]',
            content: '社会保険に加入する場合「加入」を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="201"]',
            content: '社会保険の加入要件に該当した日を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="202"]',
            content: '介護保険対象の場合「対象」を選択してください。',
            params: {placement: 'bottom-start'}
          }
        ],
        labels: {},
        social_insurance: {},
        social_insurance_date: '',
        health_insurance_sign: '',
        health_insurance_no: '',
        is_long_term_care_insurance_care_category: '',
        is_long_term_care_insurance_care_category_list: [],
        basic_pension_number: '',
        social_insurance_flg: false,
        company_social_insurance: 0,
        mode: 0,
        premium_exemption_during_childcare_leave: '2'
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      replay () {
        if (this.social_insurance.value === '1') {
          this.steps = this.stepsBk
        } else {
          this.steps = [this.stepsBk[0]]
        }
        this.$nextTick(() => {
          this.$tours['myTour200'].start()
        })
      },
      init () {
      },
      allClear () {
        this.social_insurance.value = '2'
        this.social_insurance_flg = false
        this.social_insurance_date = ''
        this.health_insurance_sign = ''
        this.health_insurance_no = ''
        this.is_long_term_care_insurance_care_category = ''
        this.basic_pension_number = ''
      },
      // 学歴の追加ボタン押下時の処理
      addEmployeeEducationalBackgroundRecord () {
        let record = {
          selected: false,
          event_date: '',
          event: ''
        }
        this.employee_educational_background_record.push(record)
      },
      setDetails (res, mode) {
        if (mode !== this.modes.add) {
          this.social_insurance.value = String(res.api_area.basic.social_insurance)
          this.social_insurance_date = res.api_area.basic.social_insurance_date
          this.health_insurance_sign = res.api_area.basic.health_insurance_sign
          this.health_insurance_no = res.api_area.basic.health_insurance_no
          this.is_long_term_care_insurance_care_category = String(res.api_area.basic.is_long_term_care_insurance_care_category)
          this.basic_pension_number = res.api_area.basic.basic_pension_number
          if (res.api_area.salary.tax === null || res.api_area.salary.tax.premium_exemption_during_childcare_leave === null) {
            this.premium_exemption_during_childcare_leave = '2'
          } else {
            this.premium_exemption_during_childcare_leave = String(res.api_area.salary.tax.premium_exemption_during_childcare_leave)
          }
        } else if (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '') {
          this.social_insurance.value = String(res.api_area.basic.social_insurance)
          this.health_insurance_sign = res.api_area.basic.health_insurance_sign
        }
        this.company_social_insurance = res.api_area.company_social_insurance
        if (this.company_social_insurance === 2) {
          this.social_insurance.value = '2'
        }
      }
    },
    watch: {
      'social_insurance.value': function () {
        if (this.social_insurance.value === '1') {
          // 会社保険が加入の場合
          // 社会保険取得日を表示する
          this.social_insurance_flg = true
        } else {
          // 社会保険が未加入の場合
          // 社会保険取得日をブランクにし、非表示にする
          this.social_insurance_flg = false
          this.social_insurance_date = ''
          this.health_insurance_sign = ''
          this.health_insurance_no = ''
          this.is_long_term_care_insurance_care_category = ''
          this.basic_pension_number = ''
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
