<template>
  <div class="row" style="overflow-y: auto; height: 800px">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <form method="#" action="#">
        <button
          type="button"
          class="btn btn-sm btn-default btn-warning"
          @click="doClose"
        >{{labels.button_close}}</button>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{pageTitle}}</h4>
          </div>
          <div class="card-content">
            <ValidationObserver ref="observer">
              <!-- 勤務体系コード -->
              <ValidationText
                v-model="workSchedule.api_area.work_schedule_code"
                item-name="work_schedule_code"
                max-length="10"
                classes="form-control width130"
                rules="required"
                :item-label="labels.label_work_schedule_code"
                :placeholder="labels.ph_code"
                :disabled="isReadOnly_Pk"
                :help-text="labels.label_input_message"
              />
              <!-- 勤務体系名 -->
              <ValidationText
                v-model="workSchedule.api_area.work_schedule_name"
                item-name="work_schedule_name"
                max-length="30"
                classes="form-control width480"
                rules="required"
                :item-label="labels.label_work_schedule_name"
                :placeholder="labels.ph_work_schedule"
                :disabled="isReadOnly"
                :help-text="labels.label_input_message"
              />
              <!-- 勤務体系略名 -->
              <ValidationText
                v-model="workSchedule.api_area.working_system_abbreviation"
                item-name="working_system_abbreviation"
                max-length="4"
                classes="form-control width100"
                rules="required"
                :item-label="labels.label_working_system_abbreviation"
                :placeholder="labels.ph_working_system_abbreviation"
                :disabled="isReadOnly"
                :help-text="labels.label_input_message"
              />
              <!-- 始業時間前の労働時間を含む -->
              <ValidationRadio
                rules="required"
                v-model="is_job_before_start_time_radio"
                :item-label="labels.label_is_job_before_start_time"
                :disabled="isReadOnly"
              />
              <!-- 就業時間 -->
              <label>{{labels.label_work_time}}</label>
              <div style="text-align: left;">
                <input type="text" 
                        style="padding-right: 0.3em; width:60px; text-align: right; display:inline;"
                        class="form-control width100"
                        :disabled="isReadOnly"
                        :placeholder="labels.ph_time_9"
                        maxlength="5"
                        v-model="workSchedule.api_area.job_start">
                <label>～</label>
                <input type="text" 
                        style="padding-right: 0.3em; width:60px; text-align: right; display:inline;"
                        class="form-control width100"
                        :disabled="isReadOnly"
                        :placeholder="labels.ph_time_18"
                        maxlength="5"
                        v-model="workSchedule.api_area.job_end">
              </div>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="errMsg1 in errMsgs1" v-bind:key="errMsg1">
                <small>{{errMsg1}}</small>
              </div>
              <template v-if="mode==modes.add || mode==modes.edit">
                <br />
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{labels.button_insert}}</button>
              </template>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="errMsg2 in errMsgs2" v-bind:key="errMsg2">
                <small>{{errMsg2}}</small>
              </div>
              <div class="card-content">
                <template>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    border
                    :data="break_time_list_screen"
                    :empty-text="labels.label_data_none"
                    style="width: 380px">
                    <el-table-column
                      width="60"
                      :label="labels.label_delete ? labels.label_delete : ''"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: center;">
                          <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      min-width="300"
                      :label="labels.label_breaktime ? labels.label_breaktime : ''"
                      align="center">
                      <template slot-scope="props">
                         <div style="text-align: center;">
                          <input type="text" 
                                 style="padding-right: 0.3em; width:50px; text-align: right;"
                                 :disabled="isReadOnly"
                                 maxlength="5"
                                 :placeholder="isReadOnly ? '' : labels.ph_time_12"
                                 v-model="props.row.start_time">
                          <label>～</label>
                          <input type="text" 
                                 style="padding-right: 0.3em; width:50px; text-align: right;"
                                 :disabled="isReadOnly"
                                 maxlength="5"
                                 :placeholder="isReadOnly ? '' : labels.ph_time_13"
                                 v-model="props.row.end_time">
                          </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
              <!-- 有効期間（開始） -->
              <ValidationDate
                v-model="workSchedule.api_area.term_from"
                item-name="term_from"
                rules="required"
                :item-label="labels.label_term_from"
                :placeholder="labels.ph_start_date"
                :disabled="isReadOnly"
              />
              <!-- 有効期間（終了） -->
              <template v-if="mode!=modes.add">
                <ValidationDate
                  v-model="workSchedule.api_area.term_to"
                  item-name="term_to"
                  :item-label="labels.label_term_to"
                  :placeholder="isReadOnly ? '' : labels.ph_end_date"
                  :disabled="isReadOnly"
                />
              </template>
            </ValidationObserver>
          </div>
        </div>
        <template v-if="mode==modes.add">
          <div style="text-align: left;">
            <button
              type="button"
              class="btn btn-sm btn-default btn-info"
              @click="doAdd"
            >{{labels.button_entry}}</button>
          </div>
        </template>
        <template v-else-if="mode==modes.edit">
          <div style="text-align: left;">
            <button
              type="button"
              class="btn btn-sm btn-default btn-info"
              @click="doUpdate"
            >{{labels.button_update}}</button>
          </div>
        </template>
        <template v-else-if="mode==modes.delete">
          <div style="text-align: left;">
            <button
              type="button"
              class="btn btn-sm btn-default btn-info"
              @click="doDelete"
            >{{labels.button_delete}}</button>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'

  export default{
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationDate,
      ValidationCheckboxes,
      ValidationRadio
    },
    props: {
      mode: Number,
      params: Object
    },
    methods: {
      addRow () {
        const addBreakTime = {
          selected: false,
          start_time: '',
          end_time: ''
        }
        this.break_time_list_screen.splice(0, 0, addBreakTime)
      },
      doClose () {
        this.$emit('close', {})
      },
      doAdd () {
        this.submit('insert', this.labels.label_add_finished, this.labels.label_add_unfinished)
      },
      doUpdate () {
        this.submit('update', this.labels.label_update_finished, this.labels.label_update_unfinished)
      },
      doDelete () {
        // 削除確認ボタン表示
        swal({
          text: this.labels.label_delete_message,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-info btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.labels.button_yes,
          cancelButtonText: this.labels.button_no
        }).then(() => {
          this.submit('delete', this.labels.label_delete_finished, this.labels.label_delete_unfinished)
        }).catch(() => {
        })
      },
      submit (operation, successMsg, failMsg) {
        this.errMsgs1 = []
        this.errMsgs2 = []
        this.$refs.observer.validate().then(isValid => {
          // 就業時間チェック
          // 開始時間hh:mm形式チェック（48:00まで）
          if (!this.workSchedule.api_area.job_start.match(/^([0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
            if (this.workSchedule.api_area.job_start !== '48:00') {
              this.errMsgs1.push(this.labels.label_start_time_error)
            }
          }
          // 終了時間hh:mm形式チェック（48:00まで）
          if (!this.workSchedule.api_area.job_end.match(/^([0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
            if (this.workSchedule.api_area.job_end !== '48:00') {
              this.errMsgs1.push(this.labels.label_end_time_error)
            }
          }
          let workSchedule = this.workSchedule
          workSchedule.api_area.is_job_before_start_time = Number(this.is_job_before_start_time_radio.value)
          let tmpList = []
          let count = 0
          // break_time_list_screenを値渡しする
          for (let breakTime of JSON.parse(JSON.stringify(this.break_time_list_screen))) {
            count++
            // チェックボックスがfalseの場合
            if (breakTime.selected === false && (breakTime.start_time || breakTime.end_time)) {
              // selectedの要素を削除
              delete breakTime.selected
              // 休憩時間チェック
              // 開始時間hh:mm形式チェック（48:00まで）
              if (!breakTime.start_time.match(/^([0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
                if (this.workSchedule.api_area.start_time !== '48:00') {
                  this.errMsgs2.push(count + this.labels.label_row_start_time_error)
                }
              }
              // 終了時間hh:mm形式チェック（48:00まで）
              if (!breakTime.end_time.match(/^([0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
                if (this.workSchedule.api_area.end_time !== '48:00') {
                  this.errMsgs2.push(count + this.labels.label_row_end_time_error)
                }
              }
              tmpList.push(breakTime)
            }
          }
          workSchedule.api_area.break_time_list = tmpList
          // エラーがあれば処理終了
          if (!isValid || this.errMsgs1.length !== 0 || this.errMsgs2.length !== 0) {
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          if (this.mode === this.modes.delete) {
            workSchedule = {
              screen_code: '03-004',
              company_code: this.$store.state.loginUser.companyCode,
              employee_code: this.$store.state.loginUser.employeeCode,
              group_code: this.$store.state.loginUser.groupCode,
              api_area: {
                work_schedule_code: this.params.work_schedule_code // 勤務体系コード
              }
            }
          }
          // nullをブランクに変換
          this.convertNullToBlank(workSchedule.api_area)
          this.send(`/${operation}/work_schedule`, workSchedule)
          .then(res => {
            if (res.result_code === 0) {
              swal({
                text: this.getLabel(res.message),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                type: 'success'
              }).then(() => {
                this.doClose()
              })
            } else {
              swal({
                text: this.getLabel(res.message),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false
              }).then(() => {
                this.doClose()
              })
            }
          })
          .catch(error => {
            console.error(error)
            if (this.getLabel(error.message) !== 'システムエラー') {
              swal({
                text: this.getLabel(error.message),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            }
          })
        })
      }
    },
    data () {
      return {
        labels: [],
        workSchedule: {
          screen_code: '03-004',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            work_schedule_code: '', // 勤務体系コード
            work_schedule_name: '', // 勤務体系名
            working_system_abbreviation: '', // 勤務体系略名
            is_job_before_start_time: '', // 始業時間前の労働時間を含む
            job_start: '', // 始業時間
            job_end: '', // 終業時間
            term_from: '', // 有効期間(開始)
            term_to: '', // 有効期間(終了)
            break_time_list: [] // 休憩時間
          }
        },
        break_time_list_screen: [], // 休憩時間（画面用）
        is_job_before_start_time_radio: {}, // 始業時間前の労働時間を含むのラジオボタン
        errMsgs1: [], // エラーメッセージ（就業時間）
        errMsgs2: [] // エラーメッセージ（休憩時間）
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        return this.mode === this.modes.view || this.mode === this.modes.delete || this.mode === this.modes.edit
      }
    },
    created () {
      // 勤務体系情報初期化
      this.workSchedule.screen_code = '03-004'
      this.workSchedule.company_code = this.$store.state.loginUser.companyCode
      this.workSchedule.employee_code = this.$store.state.loginUser.employeeCode
      this.workSchedule.group_code = this.$store.state.loginUser.groupCode
      let schema = {
        screen_code: '03-004',
        company_code: this.$store.state.loginUser.companyCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        group_code: this.$store.state.loginUser.groupCode,
        api_area: {
          work_schedule_code: this.params.work_schedule_code
        }
      }
      this.send('/view/work_schedule', schema)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.is_job_before_start_time_radio = {
          value: null,
          disabled: this.mode === this.modes.view || this.mode === this.modes.delete,
          items: [
            {
              id: 'JobBeforeStart1',
              label: this.getLabel(this.labels.label_target[0].name),
              value: this.labels.label_target[0].value
            },
            {
              id: 'JobBeforeStart2',
              label: this.getLabel(this.labels.label_target[1].name),
              value: this.labels.label_target[1].value
            }
          ]
        }
        // 新規追加の場合
        if (res.api_area === null) {
          this.workSchedule.api_area.work_schedule_code = ''
          this.workSchedule.api_area.work_schedule_name = ''
          this.workSchedule.api_area.working_system_abbreviation = ''
          this.workSchedule.api_area.job_start = ''
          this.workSchedule.api_area.job_end = ''
          this.workSchedule.api_area.term_from = ''
          this.workSchedule.api_area.term_to = ''
          this.break_time_list_screen.push({selected: false, start_time: '', end_time: ''})
        // 詳細、更新、削除の場合
        } else {
          this.workSchedule.api_area.work_schedule_code = res.api_area.work_schedule_code
          this.workSchedule.api_area.work_schedule_name = res.api_area.work_schedule_name
          this.workSchedule.api_area.working_system_abbreviation = res.api_area.working_system_abbreviation
          this.is_job_before_start_time_radio.value = String(res.api_area.is_job_before_start_time)
          this.workSchedule.api_area.job_start = res.api_area.job_start
          this.workSchedule.api_area.job_end = res.api_area.job_end
          this.workSchedule.api_area.term_from = res.api_area.term_from
          this.workSchedule.api_area.term_to = res.api_area.term_to === null ? '' : res.api_area.term_to
          this.break_time_list_screen = res.api_area.break_time_list
          // 休憩時間がない場合
          if (this.break_time_list_screen.length === 0) {
            this.break_time_list_screen.push({start_time: '', end_time: ''})
          }
          // 休憩時間にselectedの要素追加
          for (let breakTime of this.break_time_list_screen) {
            breakTime.selected = false
          }
        }
      })
    }
  }
</script>
<style>
::placeholder{
  color: #cfcfca;
  opacity: 1;
  filter: alpha(opacity=100);
}
</style>
