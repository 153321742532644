<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <br>
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <small class="help-block" v-show="valid | !validated">
        {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
      </small>
      <small class="text-danger" v-show="!valid">
        {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
      </small>
      <input 
        type="text" 
        :name="itemName"
        :placeholder="placeholder"
        :class="classes" 
        v-model="itemValue" 
        :maxlength="maxLength"
        :disabled="disabled"
        style="display:inline;"
        @blur="$emit('input', itemValue)"
        @keypress.enter="$emit('input', itemValue)">
      {{ afterLabel }}
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      afterLabel: String
    },
    data () {
      return {
        itemValue: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>