<template>
  <div>
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled"
                v-model="hour"
                @change="emitIfValidHour"
    >
      <el-option v-for="hour in hours"
                  class="select-success"
                  :value="hour"
                  :label="hour"
                  :key="hour">
      </el-option>
    </el-select>&nbsp;{{$t('label.hour')}}&nbsp;
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled"
                v-model="minute"
                @change="emitIfValidMinute"
    >
      <el-option v-for="minute in minutes"
                  class="select-success"
                  :value="minute"
                  :label="minute"
                  :key="minute">
      </el-option>
    </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      disabled: Boolean,
      classes: String,
      labels: Object
    },
    methods: {
      emitIfValidHour () {
        if (this.hour === '') {
          this.minute = ''
        } else if (this.minute === '') {
          this.minute = '00'
        }
        this.emitIfValid()
      },
      emitIfValidMinute () {
        if (this.minute === '') {
          this.hour = ''
        } else if (this.hour === '') {
          this.hour = '0'
        }
        this.emitIfValid()
      },
      emitIfValid () {
        const time = this.hour ? ('0' + this.hour).slice(-2) + ':' + this.minute : ''
        this.$emit('input', time)
      }
    },
    data () {
      return {
        hours: ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23'],
        minutes: ['', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
        hour: '',
        minute: ''
      }
    },
    watch: {
      value () {
        if (this.value) {
          const times = this.value.split(':')
          this.hour = String(parseInt(times[0], 10))
          this.minute = times[1]
        }
      }
    },
    mounted () {
      if (this.value) {
        const times = this.value.split(':')
        this.hour = String(parseInt(times[0], 10))
        this.minute = times[1]
      }
    }
  }
</script>

<style scoped>
</style>
