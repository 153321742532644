<template>
  <div style="overflow-y: auto; height: 800px;">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="detailWarning" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidthSub" :pivotX="0.6">
        <div class="modal-body">
          <!-- 警告一覧 -->
          <LegalRuleSurveillanceWarningDetail :mode="mode" :params="params" :record="errorList" :legal_rule_url="legal_rule_url" @close="hideWarning"/>
        </div>
      </modal>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="btn-group" style="padding: 15px 15px 10px 15px;">
          <button type="button" class="btn btn-default" @click="changeRealTotalMinutes">{{'労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeJobTotalMinutes">{{'所定労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeJobOverworkMinutes">{{'所定外労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeJobHolidayHours">{{'所定休日'}}<br>{{'労働時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeLegalJobMinutes">{{'法定労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeLegalOverworkMinutes">{{'法定外労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeLegalHolidayOverworkMinutes">{{'法定休日'}}<br>{{'労働時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeLateNightOverworkMinutes">{{'深夜労働'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changeBreakMinutes">{{'休憩'}}<br>{{'時間'}}</button>
          <button type="button" class="btn btn-default" @click="changePaidHolidayHours">{{'有給'}}<br>{{'時間数'}}</button>
          <button type="button" class="btn btn-default" @click="changeChildTimeLeaveHours">{{'育児時間'}}<br>{{'休暇数'}}</button>
        </div>
        <div class="card-content">
          <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
            <h5 class="card-title">
              <b>{{dispDataLabel}}</b>
            </h5>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="tableData"
                      :key="componentKey"
                      :cell-class-name="tableCellClassName"
                      height="450px"
                      :style="getTableWidth(maxLength)">
              <el-table-column min-width="140" label="" fixed="left">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <div style="font-size: medium; font-weight: bold;">
                    {{props.row.employee_name}}
                    </div>
                    {{props.row.employee_code}}
                    <br>
                    {{props.row.employee_classification_name}}
                    <br>
                    {{props.row.office_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="" v-for="n of maxLength" :key="n">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title" style="text-align: center;">
                      <span v-html="getDispTargetMonth(n - 1)"></span>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <template v-if="isError(getDispTargetMonth(n - 1), props.row)">
                      <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewWarning(getErrorList(getDispTargetMonth(n - 1), props.row.dispData), props.row.legal_rule_url)">
                        {{getDipsParam(getDispTargetMonth(n - 1), props.row.dispData)}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="font-size: medium; font-weight: bold;">
                        {{getDipsParam(getDispTargetMonth(n - 1), props.row.dispData)}}
                      </div>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import LegalRuleSurveillanceDayDataMixin from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceDayDataMixin'

  export default {
    name: 'EmployeeLunchSearch',
    methods: {
      convertSearchCond (searchCond) {
        let type = []
        for (const item of searchCond.notice_type.items) {
          if (item.value) {
            type.push(item.id)
          }
        }
        searchCond = {
          notice_date_from: searchCond.notice_date[0] === null || searchCond.notice_date[0] === '' ? '2000/01/01' : this.formatDate(searchCond.notice_date[0]),
          notice_date_to: searchCond.notice_date[1] === null || searchCond.notice_date[1] === '' ? '2999/12/31' : this.formatDate(searchCond.notice_date[1]),
          notice_type: type,
          is_unread: !searchCond.retirement
        }
        return searchCond
      }
    },
    mixins: [LegalRuleSurveillanceDayDataMixin]
  }
</script>
<style>
  .el-table .red-row {
    background-color: #f0f8ff !important;
  }
  .el-table__body tr.hover-row .error-cell {
    background-color: #ffe0eb !important;
  }
  .el-table__body tr.hover-row .warning-cell {
    background-color: #faffc5 !important;
  }
</style>
