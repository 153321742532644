<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.notification"
              item-name="notification"
              max-length="4096"
              classes="form-control length8192"
              rules="required"
              :item-label="labels.notification"
              :placeholder="isReadOnly ? '' : $t('placeholder.notification')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationTextArea
              v-model="detail.contents"
              item-name="contents"
              max-length="4096"
              classes="form-control length8192"
              rules="required"
              :item-label="labels.contents"
              :placeholder="isReadOnly ? '' : $t('placeholder.contents')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :rows="10"
            />
            <ValidationDate
              v-model="detail.notification_transmission_date"
              item-name="notification_transmission_date"
              rules="required"
              :item-label="labels.notification_transmission_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.notification_transmission_date')"
              :disabled="isReadOnly"
            />
            <ValidationDate
              v-model="detail.notification_display_date_from"
              item-name="notification_display_date_from"
              rules="required"
              :item-label="labels.notification_display_date_from"
              :placeholder="isReadOnly ? '' : $t('placeholder.notification_display_date_from')"
              :disabled="isReadOnly"
            />
            <ValidationDate
              v-model="detail.notification_display_date_to"
              item-name="notification_display_date_to"
              :item-label="labels.notification_display_date_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.notification_display_date_to')"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.notice_type"
              rules="required"
              :item-label="labels.notice_type"
              :disabled="isReadOnly"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import OperationNoticeDetailMixin from '@/components/Dashboard/Views/Generated/OperationNoticeDetailMixin'

  export default {
    name: 'OperationNoticeDetail',
    methods: {
      afterInit (res) {
        const items = []
        for (const noticeType of res.common_area.combo_data.notice_type_list) {
          items.push({
            id: noticeType.value,
            label: this.getLabel(noticeType.label),
            value: noticeType.value
          })
        }
        this.detail.notice_type = {
          value: res.api_area ? String(res.api_area.notice_type) : '',
          disabled: this.isReadOnly,
          items: items
        }
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.notice_number = parseInt(detail.notice_number, 10)
          detail.notice_type = parseInt(detail.notice_type.value, 10)
          delete detail.notification_transmission_date
          if (this.mode === this.modes.add) {
            delete detail.notice_number
          }
        } else {
          detail.notice_number = parseInt(this.detail.notice_number, 10)
        }
        return detail
      }
    },
    mixins: [OperationNoticeDetailMixin]
  }
</script>
<style scoped>
</style>
