<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.office_code"
              item-name="office_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.office_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.office_code')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <ValidationText
              v-model="detail.reference_number"
              item-name="reference_number"
              max-length="4"
              classes="form-control length4"
              :item-label="labels.reference_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.reference_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.office_reference_number"
              item-name="office_reference_number"
              max-length="6"
              classes="form-control length6"
              :item-label="labels.office_reference_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.office_reference_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.help_text_office_reference_number')"
            />
            <ValidationText
              v-model="detail.office_name"
              item-name="office_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.office_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.office_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="1"
            />
            <div class="form-group" v-show="mode==modes.add">
              <button type="button" class="btn btn-sm btn-default btn-primary" @click="copyCompanyDataClicked">
                {{ $t('button.copy_company_data') }}
              </button>
            </div>
            <ValidationText
              v-model="detail.tel"
              item-name="tel"
              max-length="20"
              classes="form-control length20"
              rules="required"
              :item-label="labels.tel"
              :placeholder="isReadOnly ? '' : $t('placeholder.tel')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="2"
            />
            <ValidationText
              v-model="detail.fax"
              item-name="fax"
              max-length="20"
              classes="form-control length20"
              :item-label="labels.fax"
              :placeholder="isReadOnly ? '' : $t('placeholder.fax')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationSearchSelect
              v-model="detail.industry_code_big"
              item-name="industry_code_big"
              classes="length40"
              rules="required"
              :clearable="false"
              :item-label="labels.industry_code_big"
              :options="comboData.industry_code_big_list"
              :disabled="isReadOnly"
              @change="industryCodeBigChanged"
              data-v-step="3"
            />
            <ValidationSearchSelect
              v-model="detail.industry_code_during"
              item-name="industry_code_during"
              classes="length40"
              rules="required"
              :clearable="false"
              :item-label="labels.industry_code_during"
              :options="comboData.industry_code_during_list"
              :disabled="isReadOnly"
              @change="industryCodeDuringChanged"
            />
            <ValidationSearchSelect
              v-model="detail.industry_code_small"
              item-name="industry_code_small"
              classes="length40"
              rules="required"
              :clearable="false"
              :item-label="labels.industry_code_small"
              :options="comboData.industry_code_small_list"
              :disabled="isReadOnly"
              help-text="総務省　日本標準産業分類に準拠"
              @change="industryCodeSmallChanged"
            />
            <ValidationRadio
              v-model="detail.special_measures"
              rules="required"
              :item-label="labels.special_measures"
              :disabled="isReadOnly"
              data-v-step="4"
            />
            <table class="special-measures" style="margin: -20px 0 20px 0;" v-show="detail.special_measures && detail.special_measures.value==='1'">
              <caption>特例措置対象事業場の対象となる業種</caption>
              <tr><td></td><td style="text-align: center;">該当する業種</td></tr>
              <tr><td>商業</td><td>卸売業、小売業、理美容業、倉庫業、駐車場業、不動産管理業、出版業（印刷部門を除く。）その他の商業</td></tr>
              <tr><td>映画・演劇業</td><td>映画の映写、演劇、その他興業の事業（映画製作・ビデオ制作の事業を除く。）</td></tr>
              <tr><td>保健衛生業</td><td>病院、診療所、保育園、老人ホーム等の社会福祉施設、浴場業（個室付浴場業を除く。）、その他の保健衛生業</td></tr>
              <tr><td>接客娯楽業</td><td>旅館、飲食店、ゴルフ場、公園・遊園地、その他の娯楽業</td></tr>
            </table>
            <ValidationRadio
              v-model="detail.regulatory_grace_exclusion"
              rules="required"
              :item-label="labels.regulatory_grace_exclusion"
              :disabled="isReadOnly"
              data-v-step="5"
            />
            <ValidationSelect
              v-model="detail.place_code"
              item-name="place_code"
              :placeholder="$t('label.select_description')"
              classes="length10"
              rules="required"
              :item-label="labels.place_name"
              :options="comboData.place_list"
              :disabled="isReadOnly"
              @change="placeCodeChanged"
              :modal-name="isReadOnly ? '' : 'place'"
              :button-label="$t('button.new')"
              @showPlaceCode="$emit('showPlaceCode')"
              tourSteps="6,7"
            />
            <ValidationSelect
              v-model="detail.industry_code"
              item-name="industry_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              :item-label="labels.industry_name"
              :options="comboData.industry_list"
              :disabled="isReadOnly"
              :help-text="$t('label.industry_code_description')"
              @change="industryCodeChanged"
            />
            <ValidationDate
              v-model="detail.term_from"
              item-name="term_from"
              rules="required"
              :item-label="labels.term_from"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
              :disabled="isReadOnly"
              data-v-step="8"
            />
            <ValidationDate
              v-model="detail.term_to"
              item-name="term_to"
              :item-label="labels.term_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_to')"
              :disabled="isReadOnly"
              :picker-options="futureday"
              v-show="mode==modes.edit || mode==modes.view || mode==modes.delete"
            />
            <ValidationTimes
              v-model="detail.job_total_minutes"
              item-name="job_total_minutes"
              classes="select-success length2"
              rules="required"
              :item-label="labels.job_total_minutes"
              :disabled="isReadOnly"
              :labels="labels"
              data-v-step="9"
            />
            <ValidationText
              v-model="detail.working_interval"
              item-name="working_interval"
              max-length="2"
              classes="form-control numLength2 text-right"
              rules="max_value: 24"
              :item-label="labels.working_interval"
              :placeholder="isReadOnly ? '' : $t('placeholder.working_interval')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :unit="$t('label.unit_hours')"
            />
            <ValidationText
              v-model="detail.limit_exemption_working_interval"
              item-name="limit_exemption_working_interval"
              max-length="3"
              classes="form-control numLength3 text-right"
              rules="max_value: 365"
              :item-label="labels.limit_exemption_working_interval"
              :placeholder="isReadOnly ? '' : $t('placeholder.limit_exemption_working_interval')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :unit="$t('label.unit_times')"
            />
            <ValidationSelect
              v-model="detail.handling_method_working_interval"
              item-name="handling_method_working_interval"
              :placeholder="$t('label.select_description')"
              classes="length24"
              :item-label="labels.handling_method_working_interval"
              :options="comboData.handling_method_working_interval_list"
              :disabled="isReadOnly"
              @change="handlingMethodWorkingIntervalChanged"
            />
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in officeApplicationListErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <ValidationApplicationManagement
              v-model="detail.office_application_list"
              classes="select-success length24"
              :item-label1="labels.applicationForm"
              :item-label2="labels.office_application_control ? labels.office_application_control : labels.group_application_control ? labels.group_application_control : ''"
              :disabled="isReadOnly"
              :options="comboData.office_application_control_list"
              ref="applicationControl"
              @copyApplicationControl="copyApplicationControl"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import OfficeDetailMixin from '@/components/Dashboard/Views/Generated/OfficeDetailMixin'

  export default {
    name: 'OfficeDetail',
    data () {
      return {
        office: {
          industry_code_big_before: undefined,
          industry_code_during_before: undefined
        }
      }
    },
    methods: {
      afterInit (res) {
        this.labels.industry_name = this.$t('label.industry')
        this.comboData.industry_mapping_big_and_during = res.common_area.combo_data.industry_list.industry_mapping_big_and_during
        this.comboData.industry_mapping_during_and_small = res.common_area.combo_data.industry_list.industry_mapping_during_and_small
        this.comboData.industry_code_during_full = [...this.comboData.industry_code_during_list]
        this.comboData.industry_code_small_full = [...this.comboData.industry_code_small_list]
        this.comboData.state_industry_code_name_list = res.common_area.combo_data.state_industry_mapping_list.state_industry_code_name_list
        this.comboData.state_industry_mapping = res.common_area.combo_data.state_industry_mapping_list.state_industry_mapping
        this.comboData.place_state_mapping_list = res.common_area.combo_data.place_state_mapping_list
        if (this.mode === this.modes.add) {
          const officeApplicationList = []
          for (const row of res.common_area.combo_data.application_form_list) {
            const element = {
              application_form_code: row.value,
              application_form_name: row.label,
              application_control: '3'
            }
            officeApplicationList.push(element)
          }
          this.detail.office_application_list = officeApplicationList
        } else {
          const officeApplicationList = []
          for (const row of this.detail.office_application_list) {
            for (const application of res.common_area.combo_data.application_form_list) {
              if (row.application_form_code === application.value) {
                const element = {
                  application_form_code: application.value,
                  application_form_name: application.label,
                  application_control: String(row.office_application_control)
                }
                officeApplicationList.push(element)
                break
              }
            }
          }
          this.detail.office_application_list = officeApplicationList
        }
        const items = []
        for (const row of this.comboData.special_measures_list) {
          items.push({
            id: row.value,
            label: this.getLabel(row.label),
            value: String(row.value)
          })
        }
        this.detail.special_measures = {
          value: res.api_area && res.api_area.special_measures ? String(res.api_area.special_measures) : '2',
          disabled: this.isReadOnly,
          items: items
        }
        this.detail.regulatory_grace_exclusion = {
          value: res.api_area ? res.api_area.regulatory_grace_exclusion ? '1' : '2' : '2',
          disabled: this.isReadOnly,
          items: items
        }
        if (this.mode !== this.modes.add) {
          this.industryCodeBigChanged()
          this.industryCodeDuringChanged()
          this.placeCodeChanged()
        }
      },
      copyCompanyDataClicked () {
        this.detail.tel = this.detail.Company.tel
        this.detail.fax = this.detail.Company.fax
        this.detail.industry_code_big = this.detail.Company.industry_code_big
        this.detail.industry_code_during = this.detail.Company.industry_code_during
        this.detail.industry_code_small = this.detail.Company.industry_code_small
      },
      placeCodeChanged () {
        this.comboData.industry_list = []
        let stateCode = ''
        for (const row of this.comboData.place_state_mapping_list) {
          if (row.place_code === this.detail.place_code) {
            stateCode = row.state_code
            break
          }
        }
        if (!this.comboData.state_industry_mapping[stateCode]) return
        if (!!this.detail.industry_code && this.detail.industry_code.indexOf('_') === -1) {
          this.detail.industry_code = stateCode + '_' + this.detail.industry_code
        } else {
          this.detail.industry_code = ''
        }
        const industryList = [{label: '', value: ''}]
        for (const industryCode of this.comboData.state_industry_mapping[stateCode]) {
          industryList.push({
            label: this.comboData.state_industry_code_name_list[stateCode + '_' + industryCode][0],
            value: stateCode + '_' + industryCode
          })
        }
        this.comboData.industry_list = industryList
      },
      copyApplicationControl () {
        this.$refs.applicationControl.copyApplicationControl()
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          if (this.mode === this.modes.add) {
            delete detail.update_count
          }
          detail.job_total_minutes = parseInt(detail.job_total_minutes, 10)
          delete detail.Company
          delete detail.copy_company_data
          delete detail.outdate_flag
          delete detail.place_name
          delete detail.industry_mapping_big_and_during
          delete detail.industry_mapping_during_and_small
          const officeApplicationList = []
          for (const row of detail.office_application_list) {
            const element = {
              office_application_control: parseInt(row.application_control, 10),
              application_form_code: row.application_form_code
            }
            officeApplicationList.push(element)
          }
          detail.office_application_list = officeApplicationList
          detail.special_measures = parseInt(this.detail.special_measures.value, 10)
          detail.regulatory_grace_exclusion = this.detail.regulatory_grace_exclusion.value === '1'
          detail.working_interval = this.detail.working_interval ? parseInt(this.detail.working_interval, 10) : null
          detail.limit_exemption_working_interval = this.detail.limit_exemption_working_interval ? parseInt(this.detail.limit_exemption_working_interval, 10) : null
          detail.handling_method_working_interval = this.detail.handling_method_working_interval ? parseInt(this.detail.handling_method_working_interval, 10) : null
          detail.industry_code = !this.detail.industry_code ? '' : this.detail.industry_code.substring(this.detail.industry_code.indexOf('_') + 1)
        }
        return detail
      },
      industryCodeBigChanged () {
        if (this.office.industry_code_big_before) {
          this.detail.industry_code_during = ''
          this.detail.industry_code_small = ''
        }
        this.office.industry_code_big_before = this.detail.industry_code_big
        this.comboData.industry_code_during_list = []
        for (const code of this.comboData.industry_mapping_big_and_during[this.detail.industry_code_big]) {
          for (const entry of this.comboData.industry_code_during_full) {
            if (code === entry.value) {
              this.comboData.industry_code_during_list.push(entry)
            }
          }
        }
      },
      industryCodeDuringChanged () {
        if (this.office.industry_code_during_before) {
          this.detail.industry_code_small = ''
        }
        if (this.detail.industry_code_during) {
          this.office.industry_code_during_before = this.detail.industry_code_during
          this.comboData.industry_code_small_list = []
          for (const code of this.comboData.industry_mapping_during_and_small[this.detail.industry_code_during]) {
            for (const entry of this.comboData.industry_code_small_full) {
              if (code === entry.value) {
                this.comboData.industry_code_small_list.push(entry)
              }
            }
          }
        } else {
          this.comboData.industry_code_small = [...this.industry_code_small_full]
        }
      }
    },
    mixins: [OfficeDetailMixin]
  }
</script>
<style scoped>
</style>
