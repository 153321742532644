<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.transfer_work_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.transfer_work_date"
            item-name="transfer_work_date"
            rules="required"
            :itemLabel="labels.transfer_work_date"
            placeholder="2020-01-01"
            :disabled="isReadOnly && isModal"
            @input="$emit('initApply', application.transfer_work_date)"
            @change="transferDateChanged"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.transfer_holiday_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.transfer_holiday_date"
            item-name="transfer_holiday_date"
            rules="required"
            :itemLabel="labels.transfer_holiday_date"
            placeholder="2020-01-01"
            :disabled="isReadOnly || !application.transfer_work_date"
            @change="transferDateChanged"
          />
        </div>
      </div>
      <!-- <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <div style="display: inline-block;">
          <ValidationSelect
            v-model="application.work_schedule_code"
            item-name="work_schedule_code"
            :placeholder="$t('label.select_description')"
            classes="length20"
            :item-label="labels.workSchedule"
            :options="comboData.work_schedule_list"
            :disabled="isReadOnly || application.is_fixed || !application.transfer_work_date"
          />
          <div class="strong-text-left" style="margin: 20px 0 10px 0;" v-show="remarks">{{remarks}}</div>
        </div>
      </div> -->
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.business_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.business_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_content')"
            :disabled="isReadOnly || !application.transfer_work_date"
            :rows="3"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
            :disabled="isReadOnly || !application.transfer_work_date"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTextArea,
      ValidationDate,
      ValidationSelect
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        remarks: '',
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        application: {}
      }
    },
    methods: {
      transferDateChanged () {
        if (this.application.transfer_work_date && this.application.transfer_holiday_date) {
          this.remarks = this.$t('label.tranfer_date_remarks').replace('{work_date}', this.application.transfer_work_date).replace('{holiday_date}', this.application.transfer_holiday_date)
        } else {
          this.remarks = ''
        }
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
