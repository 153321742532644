import mixin from '@/lib/mixin'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'

export default {
  name: 'NoticeDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        notice_number: '',
        notification: '',
        contents: '',
        notification_transmission_date: '',
        notification_display_date_from: '',
        notification_display_date_to: '',
        notice_type: ''
      },
      labels: {},
      comboData: {}
    }
  },
  computed: {
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    individualRouteCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    submit () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          notice_number: this.params.notice_number
        }
      }
      this.send('/update/employee_notice', data)
      .then(res => {
        this.isChanged = true
        if (this.params.is_read) {
          // 追加
          let addNotice = {
            contents: this.params.contents,
            is_read: !this.params.is_read,
            notice_number: this.params.notice_number,
            notice_type: this.params.notice_type,
            notification: this.params.notification,
            notification_display_date_from: this.params.notification_display_date_from,
            notification_display_date_to: this.params.notification_display_date_to,
            notification_transmission_date: this.params.notification_transmission_date
          }
          this.$store.state.noticeList.push(addNotice)
        } else {
          // 削除
          let result = this.$store.state.noticeList.filter((noticeList) => {
            return (noticeList.notice_number !== this.params.notice_number)
          })
          this.$store.state.noticeList = result
        }
        this.$store.commit('setNoticeList', this.$store.state.noticeList)
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          notice_number: this.params.notice_number
        }
      }
      this.send('/init/employee_notice', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    getContents (content) {
      if (content.indexOf('http') === -1) return content
      return content.replace(/(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/i, '<a href="$1" target="_blank" style="color: #317FB8; word-break: break-all;">$1</a>')
    }
  },
  created () {
    this.init()
  }
}
