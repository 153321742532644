<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: 570px;" name="companyFrame">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 郵便番号 -->
              <ValidationPostalCode
                v-model="post_code"
                item-name="post_code"
                max-length="10"
                classes="form-control length10"
                rules="postalCode"
                :item-label="labels.post_code"
                :placeholder="$t('placeholder.post_code')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
                :view-button="mode!==modes.view&&mode!==modes.delete"
                :button-label="$t('button.postal_code_search_simple')"
                @search="postalCodeSearch"
              />
              <!-- 都道府県 -->
              <ValidationSearchSelect
                v-model="state"
                :item-label="labels.state"
                :options="state_list"
                classes="length18"
                :disabled="isReadOnly"
              />
              <!-- 市区 -->
              <ValidationSearchSelect
                v-model="city"
                :item-label="labels.city"
                :options="citys"
                classes="length18"
                :disabled="isReadOnly"
              />
              <!-- 町村 -->
              <ValidationText
                v-model="town"
                item-name="town"
                max-length="50"
                classes="form-control length50"
                :item-label="labels.town"
                :placeholder="$t('placeholder.town')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
              />
              <!-- ビル/番地 -->
              <ValidationText
                v-model="building"
                item-name="building"
                max-length="30"
                classes="form-control length30"
                :item-label="labels.building"
                :placeholder="$t('placeholder.building')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
              />
              <!-- 電話番号 -->
              <ValidationText
                v-model="tel"
                item-name="tel"
                max-length="20"
                classes="form-control length20"
                rules="tel"
                :item-label="labels.tel"
                :placeholder="$t('placeholder.tel')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
              />
              <!-- 緊急連絡先 -->
              <ValidationText
                v-model="emergency_contact"
                item-name="emergency_contact"
                max-length="20"
                classes="form-control length20"
                :item-label="labels.emergency_contact"
                :placeholder="$t('placeholder.tel')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
              />
              <!-- その他 -->
              <ValidationText
                v-model="other"
                item-name="other"
                max-length="254"
                classes="form-control length254"
                :item-label="labels.other"
                :placeholder="$t('placeholder.other')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly"
              />
              <!-- 退職理由 -->
              <template v-if="mode !== modes.add">
                <ValidationText
                  v-model="reason_for_retirement"
                  item-name="reason_for_retirement"
                  max-length="254"
                  classes="form-control length254"
                  :item-label="labels.reason_for_retirement"
                  :placeholder="$t('placeholder.reason_for_retirement')"
                  :help-text="$t('label.max_length')"
                  :disabled="isReadOnly"
                />
              </template>
              <!-- 学歴 -->
              <label for="planId">
                {{labels.employeeEducationalBackground}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addEmployeeEducationalBackgroundRecord">{{$t('button.add_row')}}</button>
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in employee_educational_background_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="employee_educational_background_record"
                style="width: 1450px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- イベント年月日 -->
                <el-table-column min-width="150" :label="labels.event_date_ryaku ? labels.event_date_ryaku : ''">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <el-date-picker
                        min-width="150"
                        v-model="props.row.event_date"
                        type="date"
                        :placeholder="$t('placeholder.start_date')"
                        :disabled="isReadOnly"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- イベント -->
                <el-table-column min-width="1200" :label="labels.event_ryaku ? labels.event_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.event"
                      maxlength="254"
                      class="form-control length254"
                      style="display:inline"
                      :placeholder="$t('placeholder.event')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br>
              <!-- スキル -->
              <label for="planId">
                {{labels.skill}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addSkillRecord">{{$t('button.add_row')}}</button>
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in skill_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="skill_record"
                style="width: 1450px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- イベント年月日 -->
                <el-table-column min-width="150" :label="labels.event_date_ryaku ? labels.event_date_ryaku : ''">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <el-date-picker
                        min-width="150"
                        v-model="props.row.event_date"
                        type="date"
                        :placeholder="$t('placeholder.start_date')"
                        :disabled="isReadOnly"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- スキル -->
                <el-table-column min-width="1200" :label="labels.skill_ryaku ? labels.skill_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.skill"
                      maxlength="254"
                      class="form-control length254"
                      style="display:inline"
                      :placeholder="$t('placeholder.skill')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br>
              <!-- 職歴 -->
              <label for="planId">
                {{labels.work_history}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addWorkHistoryRecord">{{$t('button.add_row')}}</button>
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in work_history_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="work_history_record"
                style="width: 1450px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- イベント年月日 -->
                <el-table-column min-width="150" :label="labels.event_date_ryaku ? labels.event_date_ryaku : ''">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <el-date-picker
                        min-width="150"
                        v-model="props.row.event_date"
                        type="date"
                        :placeholder="$t('placeholder.start_date')"
                        :disabled="isReadOnly"
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 職歴 -->
                <el-table-column min-width="1200" :label="labels.work_history_ryaku ? labels.work_history_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.work_history"
                      maxlength="254"
                      class="form-control length254"
                      style="display:inline"
                      :placeholder="$t('placeholder.work_history')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import { Loader } from 'google-maps'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationSearchSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        labels: {},
        mode: 0,
        post_code: '',
        state: '',
        city: '',
        town: '',
        building: '',
        tel: '',
        other: '',
        emergency_contact: '',
        reason_for_retirement: '',
        employee_educational_background_record: [],
        employee_educational_background_error: [],
        state_list: [],
        geocoder: undefined,
        citys: [],
        municipality_list_all: [],
        state_municipality_mapping_list: [],
        skill_record: [],
        skill_error: [],
        work_history_record: [],
        work_history_error: [],
        google: null
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      init () {
        this.setGeocoder()
      },
      async setGeocoder () {
        if (!this.google) {
          this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
        }
        this.geocoder = new this.google.maps.Geocoder()
      },
      // 学歴の追加ボタン押下時の処理
      addEmployeeEducationalBackgroundRecord () {
        let record = {
          selected: false,
          event_date: '',
          event: ''
        }
        this.employee_educational_background_record.push(record)
      },
      // スキルの追加ボタン押下時の処理
      addSkillRecord () {
        let record = {
          selected: false,
          event_date: '',
          skill: ''
        }
        this.skill_record.push(record)
      },
      // 学歴の追加ボタン押下時の処理
      addWorkHistoryRecord () {
        let record = {
          selected: false,
          event_date: '',
          work_history: ''
        }
        this.work_history_record.push(record)
      },
      getPrefecturesValue (label) {
        for (const data of this.state_list) {
          if (data.label === label) {
            return data.value
          }
        }
        return ''
      },
      getCityValue (label) {
        if (label === null || label === '') {
          return ''
        }
        for (const data of this.state_municipality_mapping_list[this.state]) {
          for (const municipality of this.municipality_list_all) {
            if (municipality.value === data && municipality.label === label) {
              return municipality.value
            }
          }
        }
        return ''
      },
      postalCodeSearch (postalCode) {
        this.state = ''
        this.city = ''
        const self = this
        this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
          const myLatlng = results[0].geometry.location
          self.geocoder.geocode({location: myLatlng}, function (results, status) {
            if (status === 'OK') {
              for (const result of results) {
                if (result.types) {
                  let isFound = false
                  for (const type of result.types) {
                    if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                      const componentsLength = result.address_components.length
                      self.post_code = result.address_components[componentsLength - 1].long_name
                      for (const prefecture of self.state_list) {
                        if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                          self.state = prefecture.value
                          break
                        }
                      }
                      console.log(result.address_components)
                      // 市区
                      let city = result.address_components[componentsLength - 4].long_name + result.address_components[componentsLength - 5].long_name
                      let foundFlg = false
                      for (const municipality of self.municipality_list_all) {
                        if (municipality.value.slice(0, 2) === self.state) {
                          if (municipality.label === city) {
                            self.city = municipality.value
                            foundFlg = true
                          }
                        }
                      }
                      // 市区では無い場合の検索
                      if (!foundFlg) {
                        for (const municipality of self.municipality_list_all) {
                          if (municipality.value.slice(0, 2) === self.state) {
                            if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                              self.city = municipality.value
                            }
                          }
                        }
                      }
                      self.town = ''
                      let minusNum = 5
                      if (foundFlg) {
                        minusNum = 6
                      }
                      for (var i = componentsLength - minusNum; i >= 0; i--) {
                        self.town = self.town + result.address_components[i].long_name
                      }
                      self.componentKey += 1
                      isFound = true
                      break
                    }
                  }
                  if (isFound) break
                }
              }
            }
          })
        })
      },
      setDetails (res, mode) {
        if (mode !== this.modes.add && res.api_area.history !== null) {
          this.post_code = res.api_area.history.post_code
          this.state = this.getPrefecturesValue(res.api_area.history.prefectures)
          this.city = this.getCityValue(res.api_area.history.city)
          this.town = res.api_area.history.town
          this.building = res.api_area.history.building
          this.tel = res.api_area.history.tel
          this.emergency_contact = res.api_area.history.emergency_contact
          this.other = res.api_area.history.other
          this.reason_for_retirement = res.api_area.history.reason_for_retirement
          this.employee_educational_background_record = res.api_area.history.employee_educational_background
          this.skill_record = res.api_area.employee_skill_list
          this.work_history_record = res.api_area.employee_work_history_list
        }
      }
    },
    watch: {
      'state': function () {
        this.citys = []
        for (const data of this.state_municipality_mapping_list[this.state]) {
          for (const municipality of this.municipality_list_all) {
            if (municipality.value === data) {
              this.citys.push(municipality)
            }
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
