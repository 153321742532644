<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 364px);" name="holidayFrame" :key="componentKey">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="businessMessage" :placeholders="[pageTitle]"/>
        <div class="card">
          <div class="card-content">
            <div class="form-group">
              <label>{{$t('label.business_description')}}</label>
              <ValidationNameTable
                v-model="detail.business_names"
                rules="namesRequired|nameRequired"
                classes="form-control length60"
                :disabled="false"
                :item-label="labels.business_name"
                :placeholder="$t('placeholder.business_name')"
                item-name="business_name"
                max-length="30"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationNameTable from '@/components/UIComponents/Inputs/ValidationNameTableNew'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationNameTable
    },
    data () {
      return {
        componentKey: 0,
        detail: {
          business_names: []
        },
        labels: {
          business_name: '職種名'
        },
        businessMessage: {}
      }
    },
    methods: {
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_business`, request)
        .then(res => {
          this.businessMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
