import mixin from '@/lib/mixin'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'

export default {
  name: 'RateViewMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationTextNumber,
    ValidationSelect,
    ValidationSearchSelect
  },
  props: {
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        health_insurance_salary_general_insurance_rate: '',
        health_insurance_salary_adjusted_insurance_rate: '',
        care_insurance_salary_fee_rate: '',
        welfare_pension_salary_insurance_rate: '',
        employees_pension_fund_insurance_rate: '',
        health_insurance_bonus_general_insurance_rate: '',
        health_insurance_bonus_adjusted_insurance_rate: '',
        care_insurance_bonus_fee_rate: '',
        welfare_pension_bonus_insurance_rate: '',
        employment_insurance_rate: '',
        duty_free_premium_rate: '',
        employment_insurance_fraction_classification: '1',
        health_insurance_nursing_care_fraction: '1',
        welfare_pension_fraction_classification: '1',
        business_labor_burden: '',
        workers_accident_compensation_rate: '',
        business_type_number: '',
        type_of_business: '',
        calculate_withholding_tax: ''
      },
      steps: [
        {
          target: '[data-v-step="0"]',
          content: '加入している健康保険の給与に対する保険料率を入力してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="1"]',
          content: '加入している健康保険の賞与に対する保険料率を入力してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="2"]',
          content: '初期設定は91.500が入力されています。必要に応じて変更してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="3"]',
          content: '事業の種類を選択すると保険料率が入力されます。',
          params: {placement: 'top-start'}
        },
        {
          target: '[data-v-step="4"]',
          content: '事業の種類を選択すると保険料率が入力されます。',
          params: {placement: 'top-start'}
        },
        {
          target: '[data-v-step="5"]',
          content: '給与で控除する源泉所得税の計算方法を選択してください。',
          params: {placement: 'top-start'}
        }
      ],
      labels: {},
      comboData: {},
      tempmessage: ''
    }
  },
  computed: {

    screenCode () {
      return '03-009'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    getHealth_insurance_salary_total_rate () {
      if (this.detail.health_insurance_salary_general_insurance_rate && this.detail.health_insurance_salary_adjusted_insurance_rate) {
        var n = parseFloat(this.detail.health_insurance_salary_general_insurance_rate.replaceAll(',', '')) +
        parseFloat(this.detail.health_insurance_salary_adjusted_insurance_rate.replaceAll(',', ''))
        return this.formatFloat(String(n.toFixed(3)), 3)
      }
      return ''
    },
    getHealth_insurance_bonus_total_rate () {
      if (this.detail.health_insurance_bonus_general_insurance_rate && this.detail.health_insurance_bonus_adjusted_insurance_rate) {
        var n = parseFloat(this.detail.health_insurance_bonus_general_insurance_rate.replaceAll(',', '')) +
        parseFloat(this.detail.health_insurance_bonus_adjusted_insurance_rate.replaceAll(',', ''))
        return this.formatFloat(String(n.toFixed(3)), 3)
      }
      return ''
    },
    // 初期処理カスタマイズ用
    afterInit (res) {
      if (this.tempmessage) {
        this.message.text = this.tempmessage
      }
      this.comboData.workers_accident_compensation_insurance_mapping_list = res.common_area.combo_data.workers_accident_compensation_insurance_mapping_list.workers_accident_compensation_insurance_mapping_list
      this.comboData.type_of_business_mapping_list = res.common_area.combo_data.type_of_business_mapping_list.type_of_business_mapping_list
      if (this.detail.health_insurance_nursing_care_fraction === '') {
        this.detail.health_insurance_nursing_care_fraction = 2
      }
      if (this.detail.welfare_pension_fraction_classification === '') {
        this.detail.welfare_pension_fraction_classification = 2
      }
      if (this.detail.employment_insurance_fraction_classification === '') {
        this.detail.employment_insurance_fraction_classification = 2
      }
      if (this.detail.welfare_pension_salary_insurance_rate === '') {
        this.detail.welfare_pension_salary_insurance_rate = '91.500'
      }
      if (this.detail.welfare_pension_bonus_insurance_rate === '') {
        this.detail.welfare_pension_bonus_insurance_rate = '91.500'
      }
      if (this.detail.welfare_pension_salary_insurance_rate === '') {
        this.detail.welfare_pension_salary_insurance_rate = '91.500'
      }
      if (this.detail.welfare_pension_bonus_insurance_rate === '') {
        this.detail.welfare_pension_bonus_insurance_rate = '91.500'
      }
      this.calcEmployeePensionFundInsuranceRate()
      this.typeOfBusinessChanged()
      this.businessTypeNumberChanged()
    },
    calcEmployeePensionFundInsuranceRate () {
      this.detail.employees_pension_fund_insurance_rate = this.formatFloat(this.detail.welfare_pension_salary_insurance_rate - this.detail.duty_free_premium_rate, 3)
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      detail.health_insurance_salary_general_insurance_rate = parseFloat(detail.health_insurance_salary_general_insurance_rate)
      detail.health_insurance_salary_adjusted_insurance_rate = parseFloat(detail.health_insurance_salary_adjusted_insurance_rate)
      detail.care_insurance_salary_fee_rate = parseFloat(detail.care_insurance_salary_fee_rate)
      detail.welfare_pension_salary_insurance_rate = parseFloat(detail.welfare_pension_salary_insurance_rate)
      detail.employees_pension_fund_insurance_rate = parseFloat(detail.employees_pension_fund_insurance_rate)
      detail.health_insurance_bonus_general_insurance_rate = parseFloat(detail.health_insurance_salary_general_insurance_rate)
      detail.health_insurance_bonus_adjusted_insurance_rate = parseFloat(detail.health_insurance_salary_adjusted_insurance_rate)
      detail.care_insurance_bonus_fee_rate = parseFloat(detail.care_insurance_salary_fee_rate)
      detail.welfare_pension_bonus_insurance_rate = parseFloat(detail.welfare_pension_salary_insurance_rate)
      detail.employment_insurance_rate = parseFloat(detail.employment_insurance_rate)
      detail.duty_free_premium_rate = parseFloat(detail.duty_free_premium_rate)
      detail.employment_insurance_fraction_classification = parseFloat(detail.employment_insurance_fraction_classification)
      detail.health_insurance_nursing_care_fraction = parseFloat(detail.health_insurance_nursing_care_fraction)
      detail.welfare_pension_fraction_classification = parseFloat(detail.welfare_pension_fraction_classification)
      detail.workers_accident_compensation_rate = parseFloat(detail.workers_accident_compensation_rate)
      detail.business_type_number = parseFloat(detail.business_type_number)
      detail.calculate_withholding_tax = parseInt(detail.calculate_withholding_tax)
      delete detail.business_labor_burden
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
    },
    doUpdate () {
      this.mode = this.modes.edit
      this.submit('update')
    },
    doDelete () {
    },
    // 事業の種類の番号⇒労災保険料率の設定
    businessTypeNumberChanged () {
      this.detail.workers_accident_compensation_rate = String(this.comboData.workers_accident_compensation_insurance_mapping_list[this.detail.business_type_number])
    },
    // 事業の種類⇒雇用保険料率の設定
    typeOfBusinessChanged () {
      this.detail.employment_insurance_rate = String(this.comboData.type_of_business_mapping_list[this.detail.type_of_business])
    },
    doEmploymentInsuranceRate () {
      if (this.detail.business_labor_burden) {
        this.detail.employment_insurance_rate = this.comboData.type_of_business_mapping_list[this.detail.type_of_business]
      } else {
        this.detail.business_labor_burden = '0'
      }
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
          detail.insurance_enrollment_update_count = parseInt(this.detail.insurance_enrollment_update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail.update_count = parseInt(this.detail.update_count, 10)
          detail.insurance_enrollment_update_count = parseInt(this.detail.insurance_enrollment_update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.nullToBlank(this.convertDetail(detail))
        }
        if (reqData.api_area.update_count === '') {
          reqData.api_area.update_count = null
        }
        if (this.mode === this.modes.edit) {
          delete reqData.api_area.employment_insurance_rate
        }
        this.send(`/${operation}/rate`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.tempmessage = this.message.text
            this.isChanged = true
            this.init()
            this.isChanged = false
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/rate', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
