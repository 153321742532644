<template>
  <div>
    <div class="form-group">
      <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            {{ getMessage(errors[0]).replace('{item}', itemLabel) }}
          </small>
        </div>
        <IntegratedGroup
          v-model="value"
          :item-label="itemLabel"
          :options="options"
          :disabled="disabled"
          :classes="classes"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import IntegratedGroup from '@/components/UIComponents/Inputs/IntegratedGroup'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      IntegratedGroup
    },
    props: {
      value: Array,
      rules: String,
      itemLabel: String,
      options: Array,
      disabled: Boolean,
      classes: String
    },
    data () {
      return {
        tableData: [],
        itemClasses: 'select-success'
      }
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        this.itemClasses = 'select-success ' + this.classes
      }
    },
    mounted () {
      this.tableData = this.value
      this.itemClasses = 'select-success ' + this.classes
    }
  }
</script>

<style scoped>
</style>
