<template>
  <div class="row" ref="frame">
    <div style="padding-right: 20px;">
      <i class="ti-close pull-right" :title="$t('button.close')" @click="$emit('close')"></i>
    </div>
    <br clear="all"/>
    <div class="col-md-12" style="text-align: center; padding-top: 40px;">
      <el-table :data="tableData"
          :empty-text="$t('label.no_data')"
          border
          :header-cell-style="() => 'border-right: 1px solid #ddd;'"
          ref="table">
        <el-table-column width="60" :label="$t('button.select')" fixed="left">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <p-radio v-model="selectedTemplateNumber" :label="props.row.is_format ? props.row.template_number : props.row.template_number + 1000"></p-radio>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="407" :label="labels.template_name" fixed="left">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: left;">
              {{props.row.template_name}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="col-md-12" style="margin-top: 20px;">
      <button type="button" class="btn btn-sm btn-default" @click="$emit('close', tableData.filter((row) => row.is_format ? row.template_number === selectedTemplateNumber : row.template_number + 1000 === selectedTemplateNumber)[0])" :disabled="!selectedTemplateNumber">
        {{$t('button.create_csv')}}
      </button>
    </div>
  </div>
</template>
<script>
  export default{
    props: {
      labels: Object,
      tableData: Array
    },
    data () {
      return {
        selectedTemplateNumber: null
      }
    }
  }
</script>
<style>
</style>
