<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
          <template v-if="showResult">
            <div style="font-weight: bold;">
              {{$t('label.type_of_day_summary1').replace('{total_working_day}', dayCount[0] + dayCount[3] + dayCount[7] + dayCount[8]).replace('{total_attendance_day}', dayCount[0]).replace('{total_unknown_day}', dayCount[3])}}
              {{$t('label.type_of_day_summary2').replace('{total_unknown2_day}', dayCount[7]).replace('{total_unknown3_day}', dayCount[8])}}
            </div>
            <div style="font-weight: bold; margin-bottom: 10px;">
              {{$t('label.type_of_day_summary3').replace('{total_holiday}', dayCount[1] + dayCount[2] + dayCount[6] + dayCount[5] + dayCount[4]).replace('{total_job_holiday}', dayCount[1]).replace('{total_legal_holiday}', dayCount[2])}}
              {{$t('label.type_of_day_summary4').replace('{total_national_holiday}', dayCount[6]).replace('{total_new_year_holiday}', dayCount[5]).replace('{total_summer_holiday}', dayCount[4])}}
            </div>
          </template>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <div class="card">
              <div class="card-content">
                <!-- 事業所 -->
                <ValidationSelect
                  v-model="detail.office_code"
                  item-name="office_code"
                  :placeholder="$t('label.select_description')"
                  classes="length10"
                  rules="required"
                  :item-label="labels.office"
                  :options="comboData.office_list"
                  :disabled="showResult"
                  @change="officeCodeChanged"
                />
                <!-- 対象年 -->
                <ValidationText
                  v-model="detail.target_date"
                  item-name="target_date"
                  max-length="4"
                  classes="form-control numLength4"
                  rules="required||decimal"
                  :item-label="labels.target_year"
                  :placeholder="isReadOnly ? '' : $t('placeholder.target_date')"
                  :disabled="showResult"
                  :help-text="$t('label.max_length')"
                  @blur="changeTargetDate(detail.target_date)"
                />
                <template v-if="mode === modes.add">
                  <!-- コピー事業所 -->
                  <ValidationSelect
                    v-model="copy.copy_office_code"
                    item-name="office_code"
                    placeholder=""
                    classes="length10"
                    rules=""
                    :item-label="labels.copy_office_code"
                    :options="getCopyOfficeList(copyOfficeList, detail.office_code)"
                    :disabled="isReadOnly"
                    @change="officeCodeChanged"
                  />
                  <template v-if="copy.copy_office_code === ''">
                    <!-- 所定休日 -->
                    <ValidationCheckboxes
                      v-model="holiday.job_holiday"
                      :item-label="labels.job_holiday"
                    />
                    <!-- 法定休日 -->
                    <ValidationCheckboxes
                      v-model="holiday.legal_holiday"
                      :item-label="labels.legal_holiday"
                    />
                    <!-- 祝日 -->
                    <template v-if="is_holiday">
                      <ValidationRadio
                        v-model="holiday.national_holiday"
                        :item-label="$t('label.national_holiday')"
                        :disabled="false"
                      />
                    </template>
                    <template v-else>
                      <div>{{$t('message.no_holiday').replace('{_item_}', detail.target_date)}}</div><br>
                    </template>
                  </template>
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="search">{{$t('button.reflect_all_months')}}</button>
                </template>
                <template v-else-if="mode === modes.edit">
                  <!-- 所定休日 -->
                  <ValidationCheckboxes
                    v-model="holiday.job_holiday"
                    :item-label="labels.job_holiday"
                  />
                  <!-- 法定休日 -->
                  <ValidationCheckboxes
                    v-model="holiday.legal_holiday"
                    :item-label="labels.legal_holiday"
                  />
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="reflect">{{$t('button.reflect_not_closed_months')}}</button>
                  {{$t('label.reflect_not_closed_months_description')}}
                </template>
              </div>
            </div>
            <template v-if="showResult">
              <div v-show="showResult" class="card">
                <div class="card-header">
                  <div v-if="errMsg" style="color: #C84513; margin-bottom: 5px;">
                    <small>{{errMsg}}</small>
                  </div>
                </div>
                <div class="card-content">
                  <div style="margin-bottom: 20px;" v-for="result in results" v-bind:key="result.title">
                    <div style="display: inline-block; width: 500px;">
                      <span>{{result.title}}</span>
                      <template v-if="!isPrimaryKeyReadOnly">
                        <button type="button" class="btn btn-sm btn-default btn-warning" @click="search(result.title)">{{result.button_title}}</button>
                      </template>
                    </div>
                    <div style="display: inline-block; width: 750px; text-align: right;">
                      <span>{{$t('label.type_of_day_monthly_summary').replace('{total_working_day}', result.day_on_count).replace('{total_holiday}', result.day_off_count)}}</span>
                    </div>
                    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                              :data="result.data">
                      <el-table-column width="180" :label="$t('label.sunday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_sunday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_sunday)">{{props.row.day_of_sunday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_sunday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.monday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_monday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_monday)">{{props.row.day_of_monday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_monday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.tuesday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_tuesday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_tuesday)">{{props.row.day_of_tuesday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_tuesday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.wednesday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_wednesday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_wednesday)">{{props.row.day_of_wednesday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_wednesday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.thursday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_thursday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_thursday)">{{props.row.day_of_thursday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_thursday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.friday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_friday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_friday)">{{props.row.day_of_friday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_friday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column width="180" :label="$t('label.saturday')">
                        <template slot="header" slot-scope="scope">
                          <div class="phase-header tableHeader">
                            <div class="phase-header__title">{{scope.column.label}}</div>
                          </div>
                        </template>
                        <template slot-scope="props">
                          <template v-if="props.row.day_of_saturday">
                            <div style="display: inline-block; width: 20px; text-align: center;">
                              <span :style="getColor(props.row.day_type_of_saturday)">{{props.row.day_of_saturday}}</span>
                            </div>
                            <div style="display: inline-block; width: 130px;">
                              <el-select class="select-success length6"
                                          size="large"
                                          :disabled="isReadOnly || props.row.is_close === 2"
                                          v-model="props.row.day_type_of_saturday"
                                          @change="updateResults">
                                <el-option v-for="dayType in comboData.holiday_list"
                                            class="select-success"
                                            :value="dayType.value"
                                            :label="dayType.label"
                                            :key="dayType.label">
                                </el-option>
                              </el-select>
                            </div>
                          </template>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </template>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged && showResult">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import AnnualCalendarDetailMixin from '@/components/Dashboard/Views/Annual/AnnualCalendarDetailMixin'

  export default {
    name: 'AnnualCalendarDetail',
    mixins: [AnnualCalendarDetailMixin]
  }
</script>
<style scoped>
</style>
