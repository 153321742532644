import Vue from 'vue'
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  mixins: [mixin],
  components: {
    PPagination,
    'el-date-picker': DatePicker
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      showSearchCondition: true,
      draggable: true,
      modalWidth: 600,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        term_from: '',
        term_to: '',
        work_schedule_code: ''
      },
      isCurrentMonth: false,
      showOperation: true,
      showTargetDate: true,
      showWorkSchedule: true,
      showEmployee: true,
      showStampingStartTime: true,
      showStampingEndTime: true,
      showStartTime: true,
      showEndTime: true,
      showSmileMark: true,
      labels: {},
      comboData: {},
      geocoder: {},
      tableStyle: '',
      thisMonthFrom: '',
      thisMonthTo: ''
    }
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    doClose () {
      this.$emit('close')
    },
    afterSearch (res) {
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.searchCond
      }
      if (reqData.api_area.term_from === null) {
        reqData.api_area.term_from = ''
      }
      if (reqData.api_area.term_to === null) {
        reqData.api_area.term_to = ''
      }
      this.send('/init/timecard', reqData)
      .then(res => {
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.timecard_history
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    }
  },
  created () {
    this.searchCond = this.params.orgSearchCond
    this.search()
  }
}
