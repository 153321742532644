<template>
  <div class="card">
    <div class="card-content">
      <button type="button" class="btn btn-search btn-sm btn-default" style="margin-bottom: 20px;" @click="$emit('search')">
        <span class="btn-label"><i class="ti-reload"></i></span>
        {{$t('button.reload2')}}
      </button>
      <div style="margin-bottom: 20px;" v-for="tableData in data" v-bind:key="tableData.target_date">
        <div style="margin-bottom: 10px; font-size: 18px;">{{formatDateWith(tableData.target_date.replace('/', '-'), 'YYYY/MM/DD(ddd)')}}</div>
        <!-- テーブル表示 -->
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                  :data="tableData.employee_list"
                  style="width: 800px">

          <!-- 従業員 -->
          <el-table-column width="150" :label="labels.employee ? labels.employee : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div :style="props.row.color_code===3 ? 'color: red; text-align: left; font-weight: bold; font-size: medium;' : 'text-align: left; font-weight: bold; font-size: medium;'">
                {{props.row.employee_name}}
              </div>
              <div :style="props.row.color_code===3 ? 'color: red; text-align: left;' : 'text-align: left;'">
                {{props.row.employee_code}}
              </div>
              <div :style="props.row.color_code===3 ? 'color: red; text-align: left;' : 'text-align: left;'">
                {{props.row.office_name}}
              </div>
            </template>
          </el-table-column>
 
          <!-- 事由 -->
          <el-table-column width="150" :label="labels.ground ? labels.ground : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div :style="props.row.ground_code!=='0000000001' ? 'color: blue; text-align: left;' : 'text-align: left;'">
                {{props.row.ground_name}}
              </div>
            </template>
          </el-table-column>
 
          <!-- 出勤 -->
          <el-table-column width="150" :label="labels.typing_stamping_start_time ? labels.typing_stamping_start_time : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <template v-if="props.row.non_stamp_flg === 2">
                <div style="text-align: left; font-weight: bold; font-size: medium;">
                  <span>−</span>
                </div>
              </template>
              <template v-else>
                <div style="text-align: left; font-size: x-small;">
                  {{props.row.schedule_job_start}}
                </div>
                <div style="text-align: left; font-weight: bold; font-size: medium;">
                  {{props.row.stamping_start_time ? props.row.stamping_start_time : '　'}}
                </div>
                <div style="text-align: left;">
                  <img src="/static/img/destination.png" @click="createURL(props.row.start_lat, props.row.start_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="props.row.stamping_start_time && props.row.start_lat"/>
                </div>
              </template>
            </template>
          </el-table-column>
 
          <!-- 退勤 -->
          <el-table-column width="150" :label="labels.typing_stamping_end_time ? labels.typing_stamping_end_time : ''">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <template v-if="props.row.non_stamp_flg === 2">
                <div style="text-align: left; font-weight: bold; font-size: medium;">
                  <span>−</span>
                </div>
              </template>
              <template v-else>
                <div style="text-align: left; font-size: x-small;">
                  {{props.row.schedule_job_end}}
                </div>
                <div style="text-align: left; font-weight: bold; font-size: medium;">
                  {{props.row.stamping_end_time ? props.row.stamping_end_time : '　'}}
                </div>
                <div style="text-align: left;">
                  <img src="/static/img/destination.png" @click="createURL(props.row.end_lat, props.row.end_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="props.row.stamping_end_time && props.row.end_lat"/>
                </div>
              </template>
            </template>
          </el-table-column>
 
          <!-- 勤務体系 -->
          <el-table-column width="200" :label="$t('label.work_schedule')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; white-space: normal;">
                {{props.row.work_schedule_name}}
              </div>
              <div style="text-align: left;">
                {{props.row.work_schedule_code}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      labels: Object
    },
    data () {
      return {
        data: []
      }
    },
    methods: {
      createURL (lat, lng) {
        window.open('https://www.google.co.jp/maps/place/' + lat + ',' + lng + '/@' + lat + ',' + lng + ',18z?hl=ja/', '_blank')
      }
    },
    watch: {
      value () {
        this.data = this.value
      }
    },
    mounted () {
      this.data = this.value
    }
  }
</script>
