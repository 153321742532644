<template>
  <div style="margin-top: 0; padding: 0;">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       @on-complete="wizardComplete"
                       error-color="#EB5E28"
                       color="#C50052">

            <tab-content :title="$t('label.company_info')"
                         :before-change="validateCompanyInfo"
                         icon="ti-home">
              <company-info ref="companyInfo" :future-google="futureGoogle"></company-info>
            </tab-content>

            <tab-content :title="$t('label.office_info')"
                         :before-change="validateOfficeInfo"
                         icon="ti-layout-column3">
              <office-info ref="officeInfo"></office-info>
            </tab-content>

            <tab-content :title="$t('label.holiday_info')"
                         :before-change="validateHolidayInfo"
                         icon="ti-calendar">
              <holiday-info ref="holidayInfo"></holiday-info>
            </tab-content>

            <tab-content :title="$t('label.work_schedule_info')"
                         :before-change="validateWorkScheduleInfo"
                         icon="ti-alarm-clock">
              <work-schedule-info ref="workScheduleInfo"></work-schedule-info>
            </tab-content>

            <!-- <tab-content :title="$t('label.business_info')"
                         :before-change="validateBusinessInfo"
                         icon="ti-hand-open">
              <business-info ref="businessInfo"></business-info>
            </tab-content> -->

            <tab-content :title="$t('label.administrator')"
                         :before-change="validateEmployeeInfo"
                         icon="ti-user">
              <employee-info ref="employeeInfo" :future-google="futureGoogle"></employee-info>
            </tab-content>

            <!-- <tab-content :title="$t('label.payment_info')"
                        :before-change="validatePaymentInfo"
                        icon="ti-money">
              <payment-info ref="paymentInfo"></payment-info>
            </tab-content> -->

            <tab-content :title="$t('label.confirm_company_regist')"
                         icon="ti-check">
              <div style="padding: 150px 0; overflow-y: auto; height: calc(100vh - 415px);">
                <h4 class="text-center text-space">
                  {{$t('label.confirm_company_regist_description1')}}
                  <br>
                  {{$t('label.confirm_company_regist_description2')}}
                  <!-- <template v-if="!isSubscription">
                    <br>
                    <br>
                    <br>
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="goPaymentPage">
                      {{$t('button.go_payment_page')}}
                    </button>
                  </template> -->
                </h4>
              </div>
            </tab-content>

            <button slot="prev" class="btn btn-warning btn-sm btn-default btn-wd btn-back">戻る</button>
            <button slot="next" class="btn btn-warning btn-sm btn-default btn-wd btn-next">次へ</button>
            <button slot="finish" class="btn btn-info btn-sm btn-default btn-wd">{{$t('button.execute')}}</button>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import CompanyInfo from './CompanyWizardForm/CompanyInfo'
  import HolidayInfo from './CompanyWizardForm/HolidayInfo'
  import WorkScheduleInfo from './CompanyWizardForm/WorkScheduleInfo'
  import OfficeInfo from './CompanyWizardForm/OfficeInfo'
  import BusinessInfo from './CompanyWizardForm/BusinessInfo'
  import EmployeeInfo from './CompanyWizardForm/EmployeeInfo'
  import PaymentInfo from './CompanyWizardForm/PaymentInfo.vue'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  import { Loader } from 'google-maps'

  export default {
    mixins: [mixin],
    data () {
      return {
        // customerId: '',
        // isSubscription: false,
        wizardModel: {},
        futureGoogle: new Loader(process.env.VUE_APP_API_KEY).load()
      }
    },
    components: {
      FormWizard,
      TabContent,
      CompanyInfo,
      HolidayInfo,
      WorkScheduleInfo,
      OfficeInfo,
      BusinessInfo,
      EmployeeInfo,
      PaymentInfo
    },
    methods: {
      back () {
        this.$router.go(-1)
      },
      // goPaymentPage () {
      //   let paymentInfo = {
      //     licenseCount: '10',
      //     email: sessionStorage.getItem('mailAddress')
      //   }
      //   localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo))
      //   const w = 900
      //   const h = 600
      //   const wTop = window.screenTop + (window.innerHeight / 2) - (h / 2)
      //   const wLeft = window.screenLeft + (window.innerWidth / 2) - (w / 2)
      //   window.open('/#/migration/waiting', 'checkout', `width=${w},height=${h},top=${wTop},left=${wLeft}`)
      // },
      validateCompanyInfo () {
        // return true
        return this.$refs.companyInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            this.$refs.companyInfo.$refs.companyFrame.scrollTop = 0
          }
          this.$refs.officeInfo.detail.industry_code_big = this.$refs.companyInfo.company.industry_code_big
          this.$refs.officeInfo.detail.industry_code_during = this.$refs.companyInfo.company.industry_code_during
          this.$refs.officeInfo.detail.industry_code_small = this.$refs.companyInfo.company.industry_code_small
          this.$refs.officeInfo.placeCodeChanged(this.$refs.companyInfo.company.prefectural)
          this.$refs.officeInfo.componentKey += 1
          return isValid
        })
      },
      validateHolidayInfo () {
        this.$refs.holidayInfo.holiday.error = ''
        return this.$refs.holidayInfo.$refs.observer.validate()
        .then(isValid => {
          if (isValid && !this.$refs.holidayInfo.holiday.searched_year) {
            this.$refs.holidayInfo.holiday.error = this.$t('message.holiday_info_not_searched')
            isValid = false
          }
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          this.$refs.workScheduleInfo.detail.target_date = this.$refs.holidayInfo.holiday.searched_year
          this.$refs.workScheduleInfo.componentKey += 1
          return isValid
        })
      },
      isBreakTimeError () {
        let breakTimeCount = 0
        for (const breakTime of this.$refs.workScheduleInfo.detail.break_time_list) {
          if (!breakTime.selected && breakTime.break_time[0]) {
            breakTimeCount++
          }
        }
        if (this.$refs.workScheduleInfo.detail.working_system_type === '2' && !this.$refs.workScheduleInfo.detail.core_time[0] && breakTimeCount > 0) {
          return true
        }
        return false
      },
      calcBreakMinutes (start, end, breakStart, breakEnd) {
        const startMinute = parseInt(start.split(':')[0], 10) * 60 + parseInt(start.split(':')[1], 10)
        const endMinute = parseInt(end.split(':')[0], 10) * 60 + parseInt(end.split(':')[1], 10)
        const breakStartMinute = parseInt(breakStart.split(':')[0], 10) * 60 + parseInt(breakStart.split(':')[1], 10)
        const breakEndMinute = parseInt(breakEnd.split(':')[0], 10) * 60 + parseInt(breakEnd.split(':')[1], 10)
        if (breakStartMinute < startMinute) {
          if (breakEndMinute < startMinute) {
            return 0
          } else if (breakEndMinute > endMinute) {
            return endMinute - startMinute
          } else {
            return breakEndMinute - startMinute
          }
        } else if (breakStartMinute > endMinute) {
          return 0
        } else {
          if (breakEndMinute < endMinute) {
            return breakEndMinute - breakStartMinute
          } else {
            return endMinute - breakStartMinute
          }
        }
      },
      validateWorkScheduleInfo () {
        // return true
        return this.$refs.workScheduleInfo.$refs.observer.validate()
        .then(isValid => {
          if (isValid && this.isBreakTimeError()) {
            this.$refs.workScheduleInfo.detail.error = this.$t('message.flex_break_time_error')
            this.$refs.workScheduleInfo.componentKey += 1
            isValid = false
          }
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          return isValid
        })
      },
      validateOfficeInfo () {
        return this.$refs.officeInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          if (this.$refs.officeInfo.detail.job_total_minutes === '480') {
            this.$refs.workScheduleInfo.detail.work_time = ['9:00', '18:00']
            this.$refs.workScheduleInfo.detail.break_time_list = [
              {
                selected: false,
                break_time: ['12:00', '13:00']
              }
            ]
          } else {
            this.$refs.workScheduleInfo.detail.work_time = ['', '']
            this.$refs.workScheduleInfo.detail.break_time_list = [
              {
                selected: false,
                break_time: ['', '']
              }
            ]
          }
          return isValid
        })
      },
      validateBusinessInfo () {
        return this.$refs.businessInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          this.$refs.employeeInfo.comboData.business_list = []
          for (const businessName of this.$refs.businessInfo.detail.business_names) {
            if (!businessName.selected) {
              const element = {
                label: businessName.name,
                value: businessName.name
              }
              this.$refs.employeeInfo.comboData.business_list.push(element)
            }
          }
          this.$refs.employeeInfo.componentKey += 1

          return isValid
        })
      },
      validateEmployeeInfo () {
        return this.$refs.employeeInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          return isValid
        })
      },
      // validatePaymentInfo () {
      //   return this.$refs.paymentInfo.$refs.observer.validate()
      //   .then(isValid => {
      //     if (!isValid) {
      //       this.$emit('on-submit', this.registerForm, isValid)
      //     }
      //     let customerId = localStorage.getItem('customerId')
      //     if (customerId === null) {
      //       this.$refs.paymentInfo.error = this.$t('message.payment_info_not_registered')
      //       isValid = false
      //     } else {
      //       const self = this
      //       return this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions?customerId=' + customerId)
      //         .then(res => {
      //           if (!res.data.isSubscription) {
      //             self.$refs.paymentInfo.error = this.$t('message.payment_info_not_registered')
      //           }
      //           return res.data.isSubscription
      //         })
      //     }
      //     return isValid
      //   }).catch(error => {
      //     console.log(error)
      //   })
      // },
      // async validateSubscription (customerId) {
      //   return this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions?customerId=' + customerId)
      // },
      async wizardComplete () {
        // let customerId = localStorage.getItem('customerId')
        // if (!customerId) {
        //   swal({
        //     html: '<div style="text-align: center; font-size: 18px;">' + this.$t('message.payment_info_not_registered') + '</div>',
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-warning btn-sm btn-default',
        //     buttonsStyling: false,
        //     allowOutsideClick: false
        //   })
        //   return
        // }
        // const res = await this.validateSubscription(customerId)
        // if (!res.data || !res.data.isSubscription) {
        //   swal({
        //     html: '<div style="text-align: center; font-size: 18px;">' + this.$t('message.payment_info_not_registered') + '</div>',
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-warning btn-sm btn-default',
        //     buttonsStyling: false,
        //     allowOutsideClick: false
        //   })
        //   return
        // }
        // we gather models from all steps into one model
        if (this.$refs.companyInfo.company.payroll_management.value === '2') {
          this.$refs.companyInfo.company.labor_insurance_number = ''
          this.$refs.companyInfo.company.social_insurance = '2'
          this.$refs.companyInfo.company.employment_insurance = '2'
          this.$refs.companyInfo.company.pension_fund_contributions = '2'
          this.$refs.companyInfo.company.type_of_business = '1'
          this.$refs.companyInfo.company.business_type_number = '94'
        }
        const companyData = {
          company_name: this.$refs.companyInfo.company.company_nm,
          append_path: this.emptyToBlank(this.$refs.companyInfo.company.company_logo),
          language: this.$refs.companyInfo.company.language,
          home_page: this.emptyToBlank(this.$refs.companyInfo.company.homepage),
          post_code: this.$refs.companyInfo.company.post_code,
          state_code: this.$refs.companyInfo.company.prefectural,
          municipality_code: this.$refs.companyInfo.company.city ? this.$refs.companyInfo.company.city.split('_')[1] : this.$refs.companyInfo.company.city,
          town: this.$refs.companyInfo.company.town,
          building: this.emptyToBlank(this.$refs.companyInfo.company.building),
          tel: this.$refs.companyInfo.company.telephone1,
          fax: this.emptyToBlank(this.$refs.companyInfo.company.fax),
          industry_code_big: this.$refs.companyInfo.company.industry_code_big,
          industry_code_during: this.$refs.companyInfo.company.industry_code_during,
          industry_code_small: this.$refs.companyInfo.company.industry_code_small,
          corporate_number: this.emptyToBlank(this.$refs.companyInfo.company.corporate_number),
          labor_insurance_number: this.emptyToBlank(this.$refs.companyInfo.company.labor_insurance_number),
          start_day_of_the_week: parseInt(this.$refs.companyInfo.company.start_day_of_the_week, 10),
          rounding_second: parseInt(this.$refs.workScheduleInfo.detail.roundingSecond.value, 10),
          rounding_type: parseInt(this.$refs.workScheduleInfo.detail.workHourRoundType.value, 10),
          rounding_month: parseInt(this.$refs.workScheduleInfo.detail.workHourRoundTypeMonthly.value, 10),
          rounding_term: this.$refs.workScheduleInfo.detail.rounding_term,
          rounding_term_over_work: this.$refs.workScheduleInfo.detail.rounding_term_over_work,
          rounding_job_start: parseInt(this.$refs.workScheduleInfo.detail.workStartTimeRoundTypeDaily.value, 10),
          rounding_job_end: parseInt(this.$refs.workScheduleInfo.detail.workEndTimeRoundTypeDaily.value, 10),
          attendance_management: 1,
          payroll_management: parseInt(this.$refs.companyInfo.company.payroll_management.value, 10)
        }
        const insuranceData = {
          social_insurance: parseInt(this.$refs.companyInfo.company.social_insurance, 10),
          employment_insurance: parseInt(this.$refs.companyInfo.company.employment_insurance, 10),
          pension_fund_contributions: parseInt(this.$refs.companyInfo.company.pension_fund_contributions, 10),
          type_of_business: parseInt(this.$refs.companyInfo.company.type_of_business, 10),
          business_type_number: parseInt(this.$refs.companyInfo.company.business_type_number, 10)
        }
        const placeData = {
          place_name: this.$refs.companyInfo.company.place_name,
          lat: parseFloat(this.$refs.companyInfo.company.lat),
          lng: parseFloat(this.$refs.companyInfo.company.lng),
          post_code: this.$refs.companyInfo.company.post_code,
          state_code: this.$refs.companyInfo.company.prefectural,
          municipality_code: this.$refs.companyInfo.company.city ? this.$refs.companyInfo.company.city.split('_')[1] : this.$refs.companyInfo.company.city,
          town: this.$refs.companyInfo.company.town,
          building: this.emptyToBlank(this.$refs.companyInfo.company.building)
        }
        const year = this.$refs.holidayInfo.holiday.searched_year
        const holidayData = {
          target_date: year,
          jan_calender: '',
          feb_calender: '',
          mar_calender: '',
          apl_calender: '',
          may_calender: '',
          jun_calender: '',
          jly_calender: '',
          aug_calender: '',
          sep_calender: '',
          oct_calender: '',
          nov_calender: '',
          dec_calender: ''
        }
        for (const result of this.$refs.holidayInfo.results) {
          const month = ('0' + result.month).slice(-2)
          let calendar = ''
          for (const week of result.data) {
            if (week.day_of_sunday) {
              calendar = calendar + String(week.day_type_of_sunday)
            }
            if (week.day_of_monday) {
              calendar = calendar + String(week.day_type_of_monday)
            }
            if (week.day_of_tuesday) {
              calendar = calendar + String(week.day_type_of_tuesday)
            }
            if (week.day_of_wednesday) {
              calendar = calendar + String(week.day_type_of_wednesday)
            }
            if (week.day_of_thursday) {
              calendar = calendar + String(week.day_type_of_thursday)
            }
            if (week.day_of_friday) {
              calendar = calendar + String(week.day_type_of_friday)
            }
            if (week.day_of_saturday) {
              calendar = calendar + String(week.day_type_of_saturday)
            }
          }
          switch (month) {
            case '01':
              holidayData.jan_calender = calendar
              break
            case '02':
              holidayData.feb_calender = calendar
              break
            case '03':
              holidayData.mar_calender = calendar
              break
            case '04':
              holidayData.apl_calender = calendar
              break
            case '05':
              holidayData.may_calender = calendar
              break
            case '06':
              holidayData.jun_calender = calendar
              break
            case '07':
              holidayData.jly_calender = calendar
              break
            case '08':
              holidayData.aug_calender = calendar
              break
            case '09':
              holidayData.sep_calender = calendar
              break
            case '10':
              holidayData.oct_calender = calendar
              break
            case '11':
              holidayData.nov_calender = calendar
              break
            case '12':
              holidayData.dec_calender = calendar
              break
          }
        }
        let isJobBeforeStartTime = parseInt(this.$refs.workScheduleInfo.detail.is_job_before_start_time.value, 10)
        if (this.$refs.workScheduleInfo.detail.core_time && !this.$refs.workScheduleInfo.detail.flex_time) {
          isJobBeforeStartTime = 1
        }
        const workScheduleData = {
          work_schedule_code: this.$refs.workScheduleInfo.detail.work_schedule_code,
          work_schedule_name: this.$refs.workScheduleInfo.detail.work_schedule_name,
          working_system_abbreviation: this.$refs.workScheduleInfo.detail.working_system_abbreviation,
          working_system_type: parseInt(this.$refs.workScheduleInfo.detail.working_system_type, 10),
          is_job_before_start_time: isJobBeforeStartTime,
          job_start: this.$refs.workScheduleInfo.detail.working_system_type === '2' ? '' : !this.$refs.workScheduleInfo.detail.work_time[0] ? '' : this.zenkaku2Hankaku(('0' + this.$refs.workScheduleInfo.detail.work_time[0]).slice(-5)),
          job_end: this.$refs.workScheduleInfo.detail.working_system_type === '2' ? '' : !this.$refs.workScheduleInfo.detail.work_time[1] ? '' : this.zenkaku2Hankaku(this.$refs.workScheduleInfo.detail.work_time[1]),
          core_start: this.$refs.workScheduleInfo.detail.working_system_type !== '2' ? '' : !this.$refs.workScheduleInfo.detail.core_time[0] ? '' : this.zenkaku2Hankaku(('0' + this.$refs.workScheduleInfo.detail.core_time[0]).slice(-5)),
          core_end: this.$refs.workScheduleInfo.detail.working_system_type !== '2' ? '' : !this.$refs.workScheduleInfo.detail.core_time[1] ? '' : this.zenkaku2Hankaku(this.$refs.workScheduleInfo.detail.core_time[1]),
          flex_start: this.$refs.workScheduleInfo.detail.working_system_type !== '2' ? '' : !this.$refs.workScheduleInfo.detail.flex_time[0] ? '' : this.zenkaku2Hankaku(('0' + this.$refs.workScheduleInfo.detail.flex_time[0]).slice(-5)),
          flex_end: this.$refs.workScheduleInfo.detail.working_system_type !== '2' ? '' : !this.$refs.workScheduleInfo.detail.flex_time[1] ? '' : this.zenkaku2Hankaku(this.$refs.workScheduleInfo.detail.flex_time[1])
        }
        const breakTimeList = []
        for (const breakTime of this.$refs.workScheduleInfo.detail.break_time_list) {
          if (!breakTime.selected) {
            const element = {
              start_time: this.zenkaku2Hankaku(('0' + breakTime.break_time[0]).slice(-5)),
              end_time: this.zenkaku2Hankaku(breakTime.break_time[1])
            }
            breakTimeList.push(element)
          }
        }
        const closingDateData = {
          closing_name: this.$refs.workScheduleInfo.detail.closing_name,
          closing_target_date: this.$refs.workScheduleInfo.detail.target_date
        }
        for (const closingDate of this.$refs.workScheduleInfo.detail.closing_date_table) {
          switch (closingDate.left_month) {
            case '1月':
              closingDateData.term_from_jan = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_jan = this.formatDate(closingDate.left_endmonth)
              break
            case '2月':
              closingDateData.term_from_feb = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_feb = this.formatDate(closingDate.left_endmonth)
              break
            case '3月':
              closingDateData.term_from_mar = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_mar = this.formatDate(closingDate.left_endmonth)
              break
            case '4月':
              closingDateData.term_from_apl = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_apl = this.formatDate(closingDate.left_endmonth)
              break
            case '5月':
              closingDateData.term_from_may = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_may = this.formatDate(closingDate.left_endmonth)
              break
            case '6月':
              closingDateData.term_from_jun = this.formatDate(closingDate.left_startmonth)
              closingDateData.term_to_jun = this.formatDate(closingDate.left_endmonth)
              break
          }
        }
        for (const closingDate of this.$refs.workScheduleInfo.detail.closing_date_table) {
          switch (closingDate.right_month) {
            case '7月':
              closingDateData.term_from_jly = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_jly = this.formatDate(closingDate.right_endmonth)
              break
            case '8月':
              closingDateData.term_from_aug = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_aug = this.formatDate(closingDate.right_endmonth)
              break
            case '9月':
              closingDateData.term_from_sep = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_sep = this.formatDate(closingDate.right_endmonth)
              break
            case '10月':
              closingDateData.term_from_oct = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_oct = this.formatDate(closingDate.right_endmonth)
              break
            case '11月':
              closingDateData.term_from_nov = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_nov = this.formatDate(closingDate.right_endmonth)
              break
            case '12月':
              closingDateData.term_from_dec = this.formatDate(closingDate.right_startmonth)
              closingDateData.term_to_dec = this.formatDate(closingDate.right_endmonth)
              break
          }
        }
        const officeData = {
          office_code: this.$refs.officeInfo.detail.office_code,
          reference_number: '',
          office_name: this.$refs.officeInfo.detail.office_name,
          tel: this.$refs.companyInfo.company.telephone1,
          fax: this.emptyToBlank(this.$refs.companyInfo.company.fax),
          industry_code_big: this.$refs.companyInfo.company.industry_code_big,
          industry_code_during: this.$refs.companyInfo.company.industry_code_during,
          industry_code_small: this.$refs.companyInfo.company.industry_code_small,
          industry_code: !this.$refs.officeInfo.detail.industry_code ? '' : this.$refs.officeInfo.detail.industry_code.substring(this.$refs.officeInfo.detail.industry_code.indexOf('_') + 1),
          special_measures: parseInt(this.$refs.officeInfo.detail.special_measures.value, 10),
          regulatory_grace_exclusion: this.$refs.officeInfo.detail.regulatory_grace_exclusion.value === '1',
          job_total_minutes: parseInt(this.$refs.officeInfo.detail.job_total_minutes, 10)
        }
        // const businessNameList = []
        // for (const businessName of this.$refs.businessInfo.detail.business_names) {
        //   if (!businessName.selected) {
        //     const element = {
        //       business_name: businessName.name
        //     }
        //     businessNameList.push(element)
        //   }
        // }
        // const businessList = businessNameList
        const employeeData = {
          employee_code: this.$refs.employeeInfo.detail.employee_code,
          employee_name: this.$refs.employeeInfo.detail.employee_name,
          employee_classification_code: this.$refs.employeeInfo.detail.employee_classification_code,
          employee_language: this.$refs.employeeInfo.detail.language,
          employee_append_path: this.$refs.employeeInfo.detail.employee_photo,
          mail_address: this.$refs.employeeInfo.detail.mail_address,
          hire_date: this.$refs.employeeInfo.detail.hire_date,
          birthday: this.$refs.employeeInfo.detail.birthday,
          recruitment_category_code: this.$refs.employeeInfo.detail.recruitment_category_code,
          attendance_management: parseInt(this.$refs.employeeInfo.detail.attendance_management, 10),
          number_of_working_days_per_week: parseInt(this.$refs.employeeInfo.detail.number_of_working_days_per_week, 10),
          password: this.$refs.employeeInfo.detail.password,
          post_code: this.emptyToBlank(this.$refs.employeeInfo.detail.post_code),
          state_code: this.$refs.employeeInfo.detail.prefectures ? this.$refs.employeeInfo.detail.prefectures : '',
          municipality_code: this.$refs.employeeInfo.detail.city ? this.$refs.employeeInfo.detail.city.split('_')[1] : '',
          town: this.emptyToBlank(this.$refs.employeeInfo.detail.town),
          building: this.emptyToBlank(this.$refs.employeeInfo.detail.building),
          is_visible: 1,
          business_name: this.$refs.employeeInfo.detail.business_name
        }
        let companyRegistData = {
          mail_address: sessionStorage.getItem('mailAddress'),
          api_area: {
            company_data: companyData,
            insurance_data: insuranceData,
            place_data: placeData,
            holiday_data: holidayData,
            work_schedule_data: workScheduleData,
            break_time_list: breakTimeList,
            closing_date_data: closingDateData,
            office_data: officeData,
            business_list: [{business_name: '一般'}],
            employee_data: employeeData
          }
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/insert/corporate_confirmation`, companyRegistData)
        swal({
          type: 'success',
          title: this.$t('message.company_regist_complete_title'),
          html: this.$t('message.company_regist_complete_description'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(function (result) {
          window.location = '/#/login'
        })
      },
      async init () {
        // this.customerId = localStorage.getItem('customerId')
        // const res = await this.validateSubscription(this.customerId)
        // this.isSubscription = res.data.isSubscription
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    color: white;
  }
  .vue-form-wizard .wizard-nav-pills>li>a {
    color: #000000 !important;
  }
  .card-wizard {
    background-color: #EEEEEE !important;
  }
</style>
