<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.country_code"
              item-name="country_code"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.country_name"
              :options="comboData.country_list"
              :disabled="isPrimaryKeyReadOnly"
              @change="countryCodeChanged"
            />
            <ValidationDate
              v-model="detail.public_holiday"
              item-name="public_holiday"
              rules="required"
              :item-label="labels.public_holiday"
              :placeholder="isReadOnly ? '' : $t('placeholder.public_holiday')"
              :disabled="isReadOnly"
            />
            <ValidationText
              v-model="detail.public_holiday_name"
              item-name="public_holiday_name"
              max-length="30"
              classes="form-control length30"
              rules="required"
              :item-label="labels.public_holiday_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.public_holiday_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import PublicHolidayDetailMixin from '@/components/Dashboard/Views/Generated/PublicHolidayDetailMixin'

  export default {
    name: 'PublicHolidayDetail',
    methods: {
      afterInit (res) {
        this.comboData.country_list = this.comboData.country_code_list
        this.detail.country_code = String(res.api_area.country_code)
        this.detail.public_holiday = res.api_area.public_holiday[0]
      },
      convertDetail (detail) {
        if (this.mode === this.modes.delete) {
          detail.country_code = parseInt(this.detail.country_code, 10)
          detail.public_holiday = this.detail.public_holiday
          // delete detail.public_holiday_name
        } else {
          detail.country_code = parseInt(detail.country_code, 10)
        }
        return detail
      }
    },
    mixins: [PublicHolidayDetailMixin]
  }
</script>
<style scoped>
</style>
