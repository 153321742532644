<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="copyright pull-left" style="padding-left: 10px;">
            {{loginUserInfo}}
          </div>
        </div>
        <div class="col-md-4">
          <div class="copyright pull-right" style="padding-right: 140px;">
            &copy; 2020-2024 PocketSoft Co., Ltd.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data () {
      return {
        loginUserInfo: ''
      }
    },
    created () {
      if (this.$store.state.loginUser.groupList) {
        this.loginUserInfo = this.$t('label.user_login_user_info')
          .replace('{mailAddress}', this.$store.state.userLoginUser.accessMailAddress)
          .replace('{userName}', this.$store.state.userLoginUser.userName)
      }
    }
  }
</script>
<style>
</style>
