import mixin from '@/lib/mixin'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'

export default {
  name: 'LegalRuleSurveillanceDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        notice_number: '',
        notification: '',
        contents: '',
        notification_transmission_date: '',
        notification_display_date_from: '',
        notification_display_date_to: '',
        notice_type: ''
      },
      labels: {},
      comboData: {},
      showSearchCondition: true,
      office_code: '',
      business_type: '',
      search_flg: false,
      limit_legal_one_month_minutes_select: '1',
      limit_legal_one_month_minutes: '0',
      limit_legal_one_month_minutes_rate: '0',
      limit_legal_one_month_minutes_all_select: '1',
      limit_legal_one_month_minutes_all: '0',
      limit_legal_one_month_minutes_all_rate: '0',
      limit_month_over_industrial_safety_health_act_select: '1',
      limit_month_over_industrial_safety_health_act: '0',
      limit_month_over_industrial_safety_health_act_rate: '0',
      limit_legal_one_year_minutes_select: '1',
      limit_legal_one_year_minutes: '0',
      limit_legal_one_year_minutes_rate: '0',
      limit_job_one_year_minutes_select: '1',
      limit_job_one_year_minutes: '0',
      limit_job_one_year_minutes_rate: '0',
      number_of_legal_holidays_allowed_to_work_select: '1',
      number_of_legal_holidays_allowed_to_work: '0',
      number_of_legal_holidays_allowed_to_work_rate: '0',
      special_provisions_year_count_select: '1',
      special_provisions_year_count: '0',
      special_provisions_year_count_rate: '0',
      limit_special_provisions_legal_one_month_minutes_all_select: '1',
      limit_special_provisions_legal_one_month_minutes_all: '0',
      limit_special_provisions_legal_one_month_minutes_all_rate: '0',
      officeCodeErrorMsg: '',
      businessTypeErrorMsg: ''
    }
  },
  computed: {
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  watch: {
    'office_code': function () {
      this.errorFieldCheck('office')
    },
    'business_type': function () {
      this.errorFieldCheck('business')
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    individualRouteCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    submit () {
      let data = {
        screen_code: 'SS-015',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.office_code,
          business_type: this.business_type,
          limit_legal_one_month_minutes: this.limit_legal_one_month_minutes_select === '2' ? 0 : this.getMinutes(this.limit_legal_one_month_minutes),
          limit_legal_one_month_minutes_rate: this.limit_legal_one_month_minutes_select === '1' ? 0 : this.getRate(this.limit_legal_one_month_minutes_rate),
          limit_legal_one_month_minutes_all: this.limit_legal_one_month_minutes_all_select === '2' ? 0 : this.getMinutes(this.limit_legal_one_month_minutes_all),
          limit_legal_one_month_minutes_all_rate: this.limit_legal_one_month_minutes_all_select === '1' ? 0 : this.getRate(this.limit_legal_one_month_minutes_all_rate),
          limit_month_over_industrial_safety_health_act: this.limit_month_over_industrial_safety_health_act_select === '2' ? 0 : this.getMinutes(this.limit_month_over_industrial_safety_health_act),
          limit_month_over_industrial_safety_health_act_rate: this.limit_month_over_industrial_safety_health_act_select === '1' ? 0 : this.getRate(this.limit_month_over_industrial_safety_health_act_rate),
          limit_legal_one_year_minutes: this.limit_legal_one_year_minutes_select === '2' ? 0 : this.getMinutes(this.limit_legal_one_year_minutes),
          limit_legal_one_year_minutes_rate: this.limit_legal_one_year_minutes_select === '1' ? 0 : this.getRate(this.limit_legal_one_year_minutes_rate),
          limit_job_one_year_minutes: this.limit_job_one_year_minutes_select === '2' ? 0 : this.getMinutes(this.limit_job_one_year_minutes),
          limit_job_one_year_minutes_rate: this.limit_job_one_year_minutes_select === '1' ? 0 : this.getRate(this.limit_job_one_year_minutes_rate),
          number_of_legal_holidays_allowed_to_work: this.number_of_legal_holidays_allowed_to_work_select === '2' ? 0 : Number(this.number_of_legal_holidays_allowed_to_work),
          number_of_legal_holidays_allowed_to_work_rate: this.number_of_legal_holidays_allowed_to_work_select === '1' ? 0 : this.getRate(this.number_of_legal_holidays_allowed_to_work_rate),
          limit_special_provisions_legal_one_month_minutes_all: this.limit_special_provisions_legal_one_month_minutes_all_select === '2' ? 0 : this.getMinutes(this.limit_special_provisions_legal_one_month_minutes_all),
          limit_special_provisions_legal_one_month_minutes_all_rate: this.limit_special_provisions_legal_one_month_minutes_all_select === '1' ? 0 : this.getRate(this.limit_special_provisions_legal_one_month_minutes_all_rate),
          special_provisions_year_count: this.special_provisions_year_count_select === '2' ? 0 : Number(this.special_provisions_year_count),
          special_provisions_year_count_rate: this.special_provisions_year_count_select === '1' ? 0 : this.getRate(this.special_provisions_year_count_rate)
        }
      }
      this.send('/update/legal_rule_alert', data)
      .then(res => {
      })
    },
    errorCheck () {
      let errorFlg = false
      this.officeCodeErrorMsg = ''
      this.businessTypeErrorMsg = ''
      if (this.office_code === '') {
        errorFlg = true
        this.officeCodeErrorMsg = this.$t('message.field_required').replace('{_field_}', this.labels.office_code)
      }
      if (this.business_type === '') {
        errorFlg = true
        this.businessTypeErrorMsg = this.$t('message.field_required').replace('{_field_}', this.labels.business_type)
      }
      return errorFlg
    },
    errorFieldCheck (field) {
      let errorFlg = false
      if (field === 'office') {
        this.officeCodeErrorMsg = ''
        if (this.office_code === '') {
          errorFlg = true
          this.officeCodeErrorMsg = this.$t('message.field_required').replace('{_field_}', this.labels.office_code)
        }
      } else if (field === 'business') {
        this.businessTypeErrorMsg = ''
        if (this.business_type === '') {
          errorFlg = true
          this.businessTypeErrorMsg = this.$t('message.field_required').replace('{_field_}', this.labels.business_type)
        }
      }
      return errorFlg
    },
    search () {
      if (this.errorCheck()) {
        return
      }
      let data = {
        screen_code: 'SS-015',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.office_code,
          business_type: this.business_type
        }
      }
      this.send('/view/legal_rule_alert', data)
      .then(res => {
        if (res.api_area.limit_legal_one_month_minutes === null) {
          this.limit_legal_one_month_minutes_select = '1'
          this.limit_legal_one_month_minutes = 0
          this.limit_legal_one_month_minutes_rate = 0
          this.limit_legal_one_month_minutes_all_select = '1'
          this.limit_legal_one_month_minutes_all = 0
          this.limit_legal_one_month_minutes_all_rate = 0
          this.limit_month_over_industrial_safety_health_act_select = '1'
          this.limit_month_over_industrial_safety_health_act = 0
          this.limit_month_over_industrial_safety_health_act_rate = 0
          this.limit_legal_one_year_minutes_select = '1'
          this.limit_legal_one_year_minutes = 0
          this.limit_legal_one_year_minutes_rate = 0
          this.limit_job_one_year_minutes_select = '1'
          this.limit_job_one_year_minutes = 0
          this.limit_job_one_year_minutes_rate = 0
          this.number_of_legal_holidays_allowed_to_work_select = '1'
          this.number_of_legal_holidays_allowed_to_work = 0
          this.number_of_legal_holidays_allowed_to_work_rate = 0
          this.special_provisions_year_count_select = '1'
          this.special_provisions_year_count = 0
          this.special_provisions_year_count_rate = 0
          this.limit_special_provisions_legal_one_month_minutes_all_select = '1'
          this.limit_special_provisions_legal_one_month_minutes_all = 0
          this.limit_special_provisions_legal_one_month_minutes_all_rate = 0
        } else {
          this.limit_legal_one_month_minutes_select = res.api_area.limit_legal_one_month_minutes_rate !== 0 ? '2' : '1'
          this.limit_legal_one_month_minutes = this.getHour(res.api_area.limit_legal_one_month_minutes)
          this.limit_legal_one_month_minutes_rate = this.getDispRate(res.api_area.limit_legal_one_month_minutes_rate)
          this.limit_legal_one_month_minutes_all_select = res.api_area.limit_legal_one_month_minutes_all_rate !== 0 ? '2' : '1'
          this.limit_legal_one_month_minutes_all = this.getHour(res.api_area.limit_legal_one_month_minutes_all)
          this.limit_legal_one_month_minutes_all_rate = this.getDispRate(res.api_area.limit_legal_one_month_minutes_all_rate)
          this.limit_month_over_industrial_safety_health_act_select = res.api_area.limit_month_over_industrial_safety_health_act_rate !== 0 ? '2' : '1'
          this.limit_month_over_industrial_safety_health_act = this.getHour(res.api_area.limit_month_over_industrial_safety_health_act)
          this.limit_month_over_industrial_safety_health_act_rate = this.getDispRate(res.api_area.limit_month_over_industrial_safety_health_act_rate)
          this.limit_legal_one_year_minutes_select = res.api_area.limit_legal_one_year_minutes_rate !== 0 ? '2' : '1'
          this.limit_legal_one_year_minutes = this.getHour(res.api_area.limit_legal_one_year_minutes)
          this.limit_legal_one_year_minutes_rate = this.getDispRate(res.api_area.limit_legal_one_year_minutes_rate)
          this.limit_job_one_year_minutes_select = res.api_area.limit_job_one_year_minutes_rate !== 0 ? '2' : '1'
          this.limit_job_one_year_minutes = this.getHour(res.api_area.limit_job_one_year_minutes)
          this.limit_job_one_year_minutes_rate = this.getDispRate(res.api_area.limit_job_one_year_minutes_rate)
          this.number_of_legal_holidays_allowed_to_work_select = res.api_area.number_of_legal_holidays_allowed_to_work_rate !== 0 ? '2' : '1'
          this.number_of_legal_holidays_allowed_to_work = res.api_area.number_of_legal_holidays_allowed_to_work
          this.number_of_legal_holidays_allowed_to_work_rate = this.getDispRate(res.api_area.number_of_legal_holidays_allowed_to_work_rate)
          this.special_provisions_year_count_select = res.api_area.special_provisions_year_count_rate !== 0 ? '2' : '1'
          this.special_provisions_year_count = res.api_area.special_provisions_year_count
          this.special_provisions_year_count_rate = this.getDispRate(res.api_area.special_provisions_year_count_rate)
          this.limit_special_provisions_legal_one_month_minutes_all_select = res.api_area.limit_special_provisions_legal_one_month_minutes_all_rate !== 0 ? '2' : '1'
          this.limit_special_provisions_legal_one_month_minutes_all = this.getHour(res.api_area.limit_special_provisions_legal_one_month_minutes_all)
          this.limit_special_provisions_legal_one_month_minutes_all_rate = this.getDispRate(res.api_area.limit_special_provisions_legal_one_month_minutes_all_rate)
        }
        this.toggleSearchCondition()
        this.search_flg = true
      })
    },
    init () {
      let data = {
        screen_code: 'SS-015',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/legal_rule_alert', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    getMinutes (hour) {
      return Number(hour) * 60
    },
    getHour (minute) {
      let hour = parseInt(minute / 60, 10)
      return hour
    },
    getRate (rate) {
      return Number(rate) / 100
    },
    getDispRate (rate) {
      return parseInt(rate * 100, 10)
    }
  },
  created () {
    this.init()
  }
}
