<template>
  <div class="form-group">
    <div>
      <label v-if="itemLabel">{{ itemLabel }}</label>
    </div>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" :bails="false" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <small class="help-block" v-show="valid | !validated">
        {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
      </small>
      <template v-if="!valid">
        <div style="margin: 0; padding: 0; line-height: 120%;" v-for="error in errors" v-bind:key="error">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(error) }}
          </small>
        </div>
      </template>
      <StartEndText 
        v-model="startEndText"
        :item-name="itemName"
        :max-length="maxLength"
        :classes="classes"
        :placeholder1="placeholder1"
        :placeholder2="placeholder2"
        :disabled="disabled"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import StartEndText from '@/components/UIComponents/Inputs/StartEndText'

  export default {
    mixins: [mixin],
    components: {
      StartEndText
    },
    props: {
      value: Array,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      helpText: String
    },
    data () {
      return {
        startEndText: ['', '']
      }
    },
    watch: {
      startEndText () {
        this.$emit('input', this.startEndText)
      },
      value () {
        this.startEndText = this.value
      }
    },
    mounted () {
      this.startEndText = this.value
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>