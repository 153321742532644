<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label>
    <br>
    <template v-if="unit1 || unit2">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ value1 + unit1 + value2 + unit2 }}
    </template>
    <template v-else>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ value }}{{ unit }}
    </template>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: [String, Number],
      itemLabel: String,
      unit: String,
      value1: [String, Number],
      unit1: String,
      value2: [String, Number],
      unit2: String
    },
    data () {
      return {
        itemValue: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>