<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{$t('label.cash_transfer_list')}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <h5>
              <strong>{{params.target_date.substring(0, 4)}}{{$t('label.year')}}{{params.target_date.substring(4, 6)}}{{$t('label.monthly')}}{{ params.salary_bonus_classification === 2 ? bonusPaySlipName : '' }}</strong>
            </h5>
            <!-- 締め日別で繰り返し表示 -->
            <div
              v-for="salary_closing_data  in salary_closing_data"
              v-bind:key="salary_closing_data.salary_closing_name"
            >
              <table>
                <tr>
                  <td width="100">
                    <label>{{salary_closing_data.salary_closing_name}}</label>
                  </td>
                  <td width="250">
                    件数：{{ salary_closing_data.cash_data ? salary_closing_data.cash_data.length + $t('label.unit_count') : '' }}
                    &nbsp;&nbsp;
                    合計：{{ formatNumber(salary_closing_data.salary_closing_total_amount) + $t('label.unit_yen') }}
                  </td>
                </tr>
              </table>
              <hr />

              <!-- Table開始 -->
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="salary_closing_data.cash_data"
                :empty-text="$t('label.no_data')"
                header-cell-class-name="border-style-none"
                ref="table"
              >
                <!-- 従業員 -->
                <el-table-column :width="150" :label="labels.employee_name">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{ props.row.employee_name }}
                      <br>
                      <small>{{ props.row.employee_code }}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 現金支給額 -->
                <el-table-column :width="150" :label="labels.price">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left">
                      {{ formatNumber(props.row.cash_payment_amount) + $t('label.unit_yen') }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;" v-if="labels.print === 1 && salary_closing_data.length > 0">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="printClicked">
          {{$t('button.print')}}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
  import CashTransferListMixin from '@/components/Dashboard/Views/Salary/CashTransferListMixin'

  export default {
    name: 'CashTransferList',
    mixins: [CashTransferListMixin]
  }
</script>
<style>
  .border-style-none {
    border-style: none !important;
  }
</style>
