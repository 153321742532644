<template>
  <div :key="componentKey">
    <button type="button" class="btn btn-sm btn-default btn-primary" @click="setStandardValues">{{$t('button.set_standard_values')}}</button>
    <br><br>
    <ValidationSelect
      v-model="detail.paid_leave_granted"
      item-name="paid_leave_granted"
      rules="required"
      :item-label="labels.paid_leave_granted"
      :options="comboData.paid_leave_granted_list"
      @change="change"
    />
    <ValidationSelect
      v-model="detail.paid_leave_payment_method"
      item-name="paid_leave_payment_method"
      classes="length30"
      rules="required"
      v-if="detail.paid_leave_granted === '2'"
      :item-label="labels.paid_leave_payment_method"
      :options="comboData.paid_leave_payment_method_list"
      @change="change"
    />
    <small class="text-danger">{{errMsg}}</small>
    <ValidationMonthDay
      v-model="detail.unified_payment_date1"
      classes="select-success length2"
      item-name="unified_payment_date1"
      rules="required"
      v-if="detail.paid_leave_granted === '2' && (detail.paid_leave_payment_method === '2' || detail.paid_leave_payment_method === '3')"
      :item-label="labels.unified_payment_date1"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date2"
      classes="select-success length2"
      item-name="unified_payment_date2"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date2"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date3"
      classes="select-success length2"
      item-name="unified_payment_date3"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date3"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date4"
      classes="select-success length2"
      item-name="unified_payment_date4"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date4"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date5"
      classes="select-success length2"
      item-name="unified_payment_date5"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date5"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date6"
      classes="select-success length2"
      item-name="unified_payment_date6"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date6"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date7"
      classes="select-success length2"
      item-name="unified_payment_date7"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date7"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date8"
      classes="select-success length2"
      item-name="unified_payment_date8"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date8"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date9"
      classes="select-success length2"
      item-name="unified_payment_date9"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date9"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date10"
      classes="select-success length2"
      item-name="unified_payment_date10"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date10"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date11"
      classes="select-success length2"
      item-name="unified_payment_date11"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date11"
    />
    <ValidationMonthDay
      v-model="detail.unified_payment_date12"
      classes="select-success length2"
      item-name="unified_payment_date12"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '2'"
      :item-label="labels.unified_payment_date12"
    />
    <ValidationBaseDateSetting
      v-model="detail.paid_holiday_reference_list"
      rules="isPaidHolidayManagementRequired|isPaidHolidayManagementNumeric|isSetAllDays"
      :labels="labels"
      :disabled="false"
      classes="select-success length2"
      v-if="detail.paid_leave_granted === '2' && detail.paid_leave_payment_method === '1'"
      :item-label="labels.unified_payment_date1"
    />
    <ValidationText
      v-model="detail.attendance_rate"
      item-name="attendance_rate"
      max-length="5"
      classes="form-control numLength5 text-right"
      rules="required|min_value: 0|max_value: 100"
      :item-label="labels.attendance_rate"
      unit="%"
      :tooltip="showTooltip ? tooltip.attendance_rate : ''"
      data-v-step="0"
    />
    <ValidationSelect
      v-model="detail.fraction_of_attendance_rate"
      item-name="fraction_of_attendance_rate"
      rules="required"
      :item-label="labels.fraction_of_attendance_rate"
      :options="comboData.fraction_of_attendance_rate_list"
      :tooltip="showTooltip ? tooltip.fraction_of_attendance_rate : ''"
      data-v-step="1"
    />
    <ValidationSelect
      v-model="detail.roll_over"
      item-name="roll_over"
      rules="required"
      :item-label="labels.roll_over"
      :options="comboData.roll_over_list"
      :tooltip="showTooltip ? tooltip.roll_over : ''"
      data-v-step="2"
      @change="change"
    />
    <ValidationText
      v-model="detail.upper_limit"
      item-name="upper_limit"
      max-length="2"
      v-if="detail.roll_over === '2'"
      classes="form-control numLength2 text-right"
      rules="required|min_value: 0|max_value: 99"
      :item-label="labels.upper_limit"
      :unit="$t('label.day')"
      :tooltip="showTooltip ? tooltip.upper_limit : ''"
      data-v-step="3"
    />
    <ValidationText
      v-model="detail.maximum_days"
      item-name="maximum_days"
      max-length="2"
      classes="form-control numLength2 text-right"
      rules="required|min_value: 0|max_value: 99"
      :item-label="labels.maximum_days"
      :unit="$t('label.day')"
      :tooltip="showTooltip ? tooltip.maximum_days : ''"
      data-v-step="4"
    />
    <ValidationSelect
      v-model="detail.digestion_order"
      item-name="digestion_order"
      rules="required"
      :item-label="labels.digestion_order"
      :options="comboData.digestion_order_list"
      data-v-step="5"
    />
    <ValidationText
      v-model="detail.limit"
      item-name="limit"
      max-length="2"
      classes="form-control numLength2 text-right"
      rules="required|min_value: 0|max_value: 99"
      :item-label="labels.limit"
      :unit="$t('label.year')"
      :tooltip="showTooltip ? tooltip.limit : ''"
      data-v-step="6"
    />
    <ValidationText
      v-model="detail.available_in_one_year"
      item-name="available_in_one_year"
      max-length="3"
      classes="form-control numLength3 text-right"
      rules="required|min_value: 0|max_value: 365"
      :item-label="labels.available_in_one_year"
      :unit="$t('label.day')"
      :tooltip="showTooltip ? tooltip.available_in_one_year : ''"
      data-v-step="7"
    />
    <ValidationSelect
      v-model="detail.handling_planned_grants"
      classes="length30"
      item-name="handling_planned_grants"
      rules="required"
      :item-label="labels.handling_planned_grants"
      :options="comboData.handling_planned_grants_list"
      data-v-step="8"
    />
    <ValidationText
      v-model="detail.limit_apply_date"
      item-name="limit_apply_date"
      max-length="2"
      classes="form-control numLength2 text-right"
      rules="required|min_value: 0|max_value: 99"
      :item-label="labels.limit_apply_date"
      :unit="$t('label.day_before')"
      data-v-step="9"
    />
    <ValidationText
      v-model="detail.legal_60_overwork_rate"
      item-name="legal_60_overwork_rate"
      max-length="5"
      classes="form-control numLength5 text-right"
      rules="required|min_value: 0|max_value: 100"
      :item-label="labels.legal_60_overwork_rate"
      unit="%"
      :tooltip="showTooltip ? tooltip.legal_60_overwork_rate : ''"
      data-v-step="10"
    />
    <ValidationCheckboxes
      v-model="detail.alternative_leave_flag"
      :item-label="labels.alternative_leave_flag"
      :disabled="false"
      data-v-step="11"
      @change="changed"
    />
    <ValidationText
      v-model="detail.alternative_leave_rate"
      item-name="alternative_leave_rate"
      max-length="5"
      classes="form-control numLength5 text-right"
      rules="required|min_value: 0|max_value: 100"
      :item-label="labels.alternative_leave_rate"
      v-show="isAvailable"
      unit="%"
    />
    <Tour tour-name="myTour" :steps="steps"/>
  </div>
</template>

<script>
  import Vue from 'vue'
  import mixin from '@/lib/mixin'
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationTimes from './ValidationTimes.vue'
  import ValidationText from './ValidationText.vue'
  import ValidationSelect from './ValidationSelect.vue'
  import ValidationMonthDay from './ValidationMonthDay.vue'
  import ValidationBaseDateSetting from './ValidationBaseDateSetting.vue'
  import ValidationCheckboxes from './ValidationCheckboxes.vue'
  import VTooltip from 'v-tooltip'

  Vue.use(VTooltip)

  export default {
    components: {
      Tour,
      ValidationTimes,
      ValidationText,
      ValidationSelect,
      ValidationMonthDay,
      ValidationBaseDateSetting,
      ValidationCheckboxes
    },
    mixins: [mixin],
    props: {
      value: Object,
      labels: Object,
      comboData: Object,
      disabled: Boolean,
      errMsg: String
    },
    data () {
      return {
        showTooltip: false,
        tooltip: {
          attendance_rate: '全労働日の8割以上出勤した労働者に支給が義務付けられています。（労働基準法　第39条1項）',
          fraction_of_attendance_rate: '法律上、端数処理はありません。',
          roll_over: '時効まで繰り越す義務があります。（労働基準法　第115条）',
          upper_limit: '労働基準法での最大付与日数は20日のため、その分の繰り越し義務があります。（労働基準法　第39条1項、第115条）',
          maximum_days: '繰越した日数と今年付与された日数を合わせ最大40日を保有できます。（労働基準法　第39条1項、第115条）',
          limit: '発生の日から2年間で時効により消滅します。（労働基準法　第115条）',
          available_in_one_year: '労使協定により、5日の範囲内で1時間を単位として与えることができます。（労働基準法　第39条4項）',
          legal_60_overwork_rate: 'Artemisではすべての法定外労働の割増率を２５％で計算しているため、残りの２５％を設定します。（労働基準法　第37条）'
        },
        detail: {},
        componentKey: 0,
        steps: [
          {
            target: '[data-v-step="0"]',
            content: '有給休暇自動付与の対象となる出勤率を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="1"]',
            content: '小数点以下の端数処理を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="2"]',
            content: '付与された年の翌年に繰り越すことができるか否かを選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="3"]',
            content: '繰り越すことのできる有給休暇日数の上限を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="4"]',
            content: '保持できる有給休暇の最大日数を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="5"]',
            content: '有給休暇を消化する順序を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="6"]',
            content: '有給休暇が消滅する年数を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="7"]',
            content: '有給休暇を時間単位で取得できる日数を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="8"]',
            content: '有給休暇の計画的付与の対象日に、有給日数が不足している場合の扱いを選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="9"]',
            content: '有給休暇申請の申請期限を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="10"]',
            content: '60時間を超える法定外労働の割増率を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="11"]',
            content: '代替休暇を利用する場合にチェックします。利用する場合は割増分の代替休暇を取得させた時に、実際に支払う割増賃金率を入力してください。',
            params: {placement: 'bottom-start'}
          }
        ],
        isAvailable: false
      }
    },
    watch: {
      value () {
        this.detail = this.value
        this.isAvailable = (this.detail.alternative_leave_flag && this.detail.alternative_leave_flag.items && this.detail.alternative_leave_flag.items[0].value)
      }
    },
    mounted () {
      this.detail = this.value
      this.isAvailable = (this.detail.alternative_leave_flag && this.detail.alternative_leave_flag.items && this.detail.alternative_leave_flag.items[0].value)
    },
    methods: {
      setStandardValues () {
        this.detail.attendance_rate = '80.0'
        this.detail.fraction_of_attendance_rate = '1'
        this.detail.roll_over = '2'
        this.detail.upper_limit = '20'
        this.detail.maximum_days = '40'
        this.detail.limit = '2'
        this.detail.available_in_one_year = '5'
        this.detail.legal_60_overwork_rate = '25'
        this.showTooltip = true
        this.componentKey += 1
      },
      change () {
        if (this.detail.roll_over === '1') {
          this.detail.upper_limit = '0'
        }
        this.componentKey += 1
      },
      changed () {
        this.isAvailable = (this.detail.alternative_leave_flag && this.detail.alternative_leave_flag.items && this.detail.alternative_leave_flag.items[0].value)
      }
    }
  }
</script>

<style scoped>
</style>