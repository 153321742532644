<template>
  <div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <a class="pull-right" href="https://elaws.e-gov.go.jp/document?lawid=322AC0000000049" target="_blank" style="color: #317FB8;">{{$t('label.law_search')}}</a>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
    <template>
      <div class="card">
        <h4 class="card-title">
          {{labels.legalRuleAlert}}
        </h4>
        <div style="overflow-y: auto; max-height: 750px;" :key="componentKey">
          <div v-for="oneRecord in record" :key="oneRecord[1].column1">
            <div class="card" style="background-color: #F9F7F3; margin: 10px 0;">
              <div class="card-header" style="cursor: pointer;" @click="headerClicked(oneRecord)">
                <div style="display: inline-block; width: 95%;">
                  <h4 class="card-title">{{getTitle(oneRecord)}}</h4>
                </div>
                <div style="display: inline-block; width: 4%;">
                  <template v-if="oneRecord.showDetail">
                    <span class="ti-angle-up pull-right"></span>
                  </template>
                  <template v-else>
                    <span class="ti-angle-down pull-right"></span>
                  </template>
                </div>
              </div>
              <div class="card-content" v-if="oneRecord.showDetail">
                <h5 class="card-title">{{getRegulationAndActual(oneRecord)}}</h5>
                <div style="color: #6a6a6a;">
                  {{oneRecord[1].column1}}
                  <template v-if="oneRecord[1].column2!==null">
                    <br>
                    {{oneRecord[1].column2}}
                  </template>
                  <template v-if="oneRecord[1].column3!==null">
                    <br>
                    {{oneRecord[1].column3}}
                  </template>
                  <template v-if="oneRecord[1].column4!==null">
                    <br>
                    {{oneRecord[1].column4}}
                  </template>
                </div>
              </div>
              <div class="card-footer" v-if="!oneRecord.showDetail"></div>
            </div>
            <!-- <el-collapse>
              <el-collapse-item :title="getTitle(oneRecord)" :name="oneRecord[1].column1">
                <div style="color: #6a6a6a;">
                  {{oneRecord[1].column1}}
                  <template v-if="oneRecord[1].column2!==null">
                    <br>
                    {{oneRecord[1].column2}}
                  </template>
                  <template v-if="oneRecord[1].column3!==null">
                    <br>
                    {{oneRecord[1].column3}}
                  </template>
                  <template v-if="oneRecord[1].column4!==null">
                    <br>
                    {{oneRecord[1].column4}}
                  </template>
                </div>
              </el-collapse-item>
            </el-collapse> -->
            <!-- <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="oneRecord[1].row"
                      style="width: 1570px">
              <el-table-column width="76" label="対象日">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.target_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" label="勤務体系">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-bottom: 1px;">
                    <el-select :class="!props.row.imprint_correction_application_group_class ? 'select-success width100' : 'select-success width100 ' + props.row.imprint_correction_application_group_class"
                                size="small"
                                placeholder=""
                                v-tooltip.right-end="props.row.work_schedule_detail"
                                v-model="props.row.work_schedule_code"
                                @input="updateRow(props.row)"
                                :disabled="isReadOnly || monthData[0].flex">
                      <el-option v-for="work_schedule in props.row.work_schedule_list"
                                  class="select-success"
                                  :value="work_schedule.value"
                                  :label="work_schedule.label"
                                  :key="work_schedule.value">
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="50" label="TW" align="center">
                <template slot-scope="props">
                  <div style="text-align: center;" :key="tableKey3" v-show="props.row.is_first_target_date">
                    <input type="checkbox" v-model="props.row.telework_flg" :class="!props.row.imprint_correction_application_telework_group_class ? 'gray' : props.row.imprint_correction_application_telework_group_class" :disabled="isReadOnly" @click="teleworkClicked($event, props.$index, props.row)">
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" label="事由">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="margin-top: 1px;" v-show="props.row.is_first_target_date">
                    <el-select :class="!props.row.ground_application_group_class ? 'select-success width100' : 'select-success width100 ' + props.row.ground_application_group_class"
                                size="small"
                                placeholder=""
                                :title="props.row.ground_name"
                                v-model="props.row.ground_code"
                                @input="groundChanged(props.$index, props.row)"
                                :disabled="isReadOnly || props.row.timecard_available_flg === 2">
                      <el-option v-for="ground in props.row.grounds"
                                  :class="ground.optionClass"
                                  :value="ground.value"
                                  :label="ground.label"
                                  :key="ground.value">
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="55" label="始業">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.job_start}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="55" label="就業">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.job_end}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="70" label="出勤">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="!isPersonal">
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_start_time}}
                    </div>
                    <div 
                      :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'CompleteStyle'"
                      style="text-align: center; font-size: small; font-weight: bold;"
                    >
                      {{props.row.original_stamping_start_time}}
                    </div>
                  </template>
                  <template v-else-if="stampCorrectionFlag">
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_start_time}}
                    </div>
                    <template v-if="isApproved && !props.row.application_group.imprint_correction_application_start_time_number">
                      <div class="CompleteStyle" style="text-align: center; font-size: small; font-weight: bold;">
                        {{props.row.original_stamping_start_time}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: center;" :title="props.row.stamping_start_time_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_start_time_group_class ? props.row.imprint_correction_application_start_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 1)"
                        >
                          {{props.row.original_stamping_start_time}}
                        </a>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_start_time}}
                    </div>
                    <div :title="props.row.stamping_start_time_error">
                      <input 
                        type="text" 
                        :title="props.row.startTimeErrorMsg"
                        :class="{'errorBorder': props.row.isStartTimeError}"
                        style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                        v-model="props.row.original_stamping_start_time"
                        :disabled="props.row.stamping_time_disabled"
                        v-bind:readonly="isReadOnly">
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="70" label="退勤">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="!isPersonal">
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_end_time}}
                    </div>
                    <div 
                      :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'CompleteStyle'"
                      style="text-align: center; font-size: small; font-weight: bold;" v-tooltip.right-end="props.row.original_stamping_end_time_title"
                    >
                      {{props.row.original_stamping_end_time}}
                    </div>
                  </template>
                  <template v-else-if="stampCorrectionFlag">
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_end_time}}
                    </div>
                    <template v-if="isApproved && !props.row.application_group.imprint_correction_application_start_time_number">
                      <div class="CompleteStyle" style="text-align: center; font-size: small; font-weight: bold;" v-tooltip.right-end="props.row.original_stamping_end_time_title">
                        {{props.row.original_stamping_end_time}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: center;" :title="props.row.stamping_end_time_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.imprint_correction_application_end_time_group_class ? props.row.imprint_correction_application_end_time_group_class : 'CompleteStyle'"
                          @click="showStampCorrection(props.$index, props.row, 2)"
                          v-tooltip.right-end="props.row.original_stamping_end_time_title"
                        >
                          {{props.row.original_stamping_end_time}}
                        </a>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div style="text-align: center; font-size: x-small;">
                      {{props.row.stamping_end_time}}
                    </div>
                    <div :title="props.row.stamping_end_time_error">
                      <input 
                        type="text" 
                        :title="props.row.endTimeErrorMsg"
                        :class="{'errorBorder': props.row.isEndTimeError}"
                        style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                        v-model="props.row.original_stamping_end_time"
                        :disabled="props.row.stamping_time_disabled"
                        v-tooltip.right-end="props.row.original_stamping_end_time_title"
                        v-bind:readonly="isReadOnly">
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column width="70" label="労働時間">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right; font-size: small; font-weight: bold; color: black;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.real_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="65" label="所定">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(monthData[0].flex ? props.row.standard_job_minutes : props.row.job_total_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="90" label="所定（外）">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.job_overtime_link">
                      <div :title="props.row.job_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.overtime_application_group_class"
                          @click="showOvertimeWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.job_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="60" label="法定">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : formatDispMinute(monthData[0].flex ? props.row.standard_legal_minutes : props.row.legal_job_minutes)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="90" label="法定（外）">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.legal_overtime_link">
                      <div :title="props.row.legal_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.overtime_application_group_class"
                          @click="showOvertimeWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="深夜">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.late_night_overwork_link">
                      <div :title="props.row.late_night_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.late_night_application_group_class"
                          @click="showLateNightWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="休憩">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="!canShow('/SS/menuSSScreen020') || (isReadOnly && props.row.break_minutes===0) || (!isReadOnly && !props.row.stamping_start_time)">
                      {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.break_minutes)}}
                    </template>
                    <template v-else>
                      <a
                        class="btn btn-simple btn-info"
                        style="padding: 0; color: black; text-decoration: underline;"
                        @click="showBreak(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.break_minutes)}}
                      </a>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="遅刻">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.late_link">
                      <div :title="props.row.late_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.late_application_group_class"
                          @click="showLate(props.$index, props.row)"
                        >
                          {{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.late_minutes_error">{{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.late_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="早退">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.early_departure_link">
                      <div :title="props.row.early_departure_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.early_departure_application_group_class"
                          @click="showEarlyDeparture(props.$index, props.row)"
                        >
                          {{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.early_departure_minutes_error">{{monthData[0].flex ? '' : !props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.early_departure_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="90" label="所定（休）">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.job_holiday_work_link && false">
                      <div :title="props.row.job_holiday_hours_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.job_holiday_application_group_class"
                          @click="showHolidayWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.job_holiday_hours_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.job_holiday_hours)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="90" label="法定（休）">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    <template v-if="props.row.legal_holiday_work_link">
                      <div :title="props.row.legal_holiday_overwork_minutes_error">
                        <a
                          class="btn btn-simple btn-info"
                          :class="props.row.legal_holiday_application_group_class"
                          @click="showHolidayWorking(props.$index, props.row)"
                        >
                          {{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div :title="props.row.legal_holiday_overwork_minutes_error">{{!props.row.original_stamping_end_time ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}</div>
                    </template>                    
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" :label="$t('label.paid_holiday_ryaku')">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.paid_holiday_link">
                    <div style="text-align: right;">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.paid_holiday_application_group_class"
                        @click="showPaidHoliday(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </a>
                    </div>
                    <div style="text-align: right;">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.paid_holiday_application_group_class"
                        @click="showPaidHoliday(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{!props.row.original_stamping_end_time ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                    </div>
                    <div style="text-align: right;">
                      {{!props.row.original_stamping_end_time ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                    </div>
                  </template>                    
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="代休">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="55" label="育休">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.child_time_leave_link">
                    <div style="text-align: right;">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.child_time_leave_application_group_class"
                        @click="showChildTimeLeave(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </a>
                    </div>
                    <div style="text-align: right;">
                      <a
                        class="btn btn-simple btn-info"
                        :class="props.row.child_time_leave_application_group_class"
                        @click="showChildTimeLeave(props.$index, props.row)"
                      >
                        {{!props.row.original_stamping_end_time ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{!props.row.original_stamping_end_time ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days.toFixed(1) + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                    </div>
                    <div style="text-align: right;">
                      {{!props.row.original_stamping_end_time ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column min-width="65" label="休業">
                <template slot="header" slot-scope="scope"> 
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{!props.row.original_stamping_end_time ? '' : props.row.closed_days + $t('label.unit_days')}}
                  </div>
                </template>
              </el-table-column>
            </el-table> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import LegalRuleSurveillanceWarningDetailMixin from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceWarningDetailMixin'

  export default {
    name: 'LegalRuleSurveillance',
    mixins: [LegalRuleSurveillanceWarningDetailMixin]
  }
</script>
<style scoped>
.card .category {
  color: #000000;
}
blockquote {
  position: relative;
  left: -40px;
}
</style>
