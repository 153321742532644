<template>
  <div>
    <Loading
      :message="description"
      :loading="loading"
      :showButton="showButton"
    />
  </div>
</template>
<script>
  import Loading from '@/components/Dashboard/Views/Components/Loading'

  // stripe公式ライブラリのロード
  let scriptElement = document.createElement('script')
  scriptElement.src = '//js.stripe.com/v3/'
  let headElement = document.getElementsByTagName('head')[0]
  headElement.appendChild(scriptElement)

  export default {
    components: {
      Loading
    },
    data () {
      return {
        loading: false,
        showButton: false,
        description: 'しばらくお待ちください。',
        stripe: {
          plan_id: '',
          quantity: '',
          email: ''
        }
      }
    },
    methods: {
      callStripe () {
        const json = localStorage.getItem('paymentInfo')
        const paymentInfo = JSON.parse(json)
        this.stripe.plan_id = process.env.VUE_APP_STRIPE_PLAN_ID
        this.stripe.quantity = paymentInfo.licenseCount
        this.stripe.email = paymentInfo.email

        let self = this
        let stripe
        self.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripePublicKey')
        .then(res => {
          stripe = window.Stripe(res.data.publicKey)
        })
        .then(res => {
          let postData = {
            email: self.stripe.email,
            groupId: self.$store.state.loginUser.companyCode
          }
          self.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeCustomers', postData)
          .then(res => {
            let customerId = res.data.customerId
            let postData = {
              planId: self.stripe.plan_id,
              customerId: customerId,
              quantity: self.stripe.quantity,
              successUrl: `${process.env.VUE_APP_ORIGIN}/#/migration/paymentComplete`,
              cancelUrl: process.env.VUE_APP_ORIGIN,
              registrationDate: paymentInfo.firstCompanyCreateDate,
              campaignCode: paymentInfo.campaignCode
            }
            self.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeSubscriptions?customerId=' + customerId)
            .then(res => {
              if (res.data && res.data.isSubscription) {
                self.loading = false
                self.description = '既に契約中ですので、支払い登録は必要ありません。'
                localStorage.setItem('customerId', customerId)
              } else {
                self.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeCreateCheckoutSession', postData)
                .then(res => {
                  if (res.data.checkoutSessionId) {
                    localStorage.setItem('customerId', customerId)
                    localStorage.setItem('campaignCode', paymentInfo.campaignCode)
                    stripe.redirectToCheckout({
                      sessionId: res.data.checkoutSessionId
                    })
                  } else {
                    this.loading = false
                    this.description = '既に契約中のメールアドレスは使用できません。'
                  }
                }).catch(e => {
                  this.loading = false
                  this.description = e.response.data.message
                })
              }
            })
          })
        })
      }
    },
    created () {
      this.loading = true

      const customerId = localStorage.getItem('customerId')
      if (customerId === null) {
        this.callStripe()
      } else {
        // customerIdが存在するため、少なくとも一度契約画面に遷移している。
        const self = this
        this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeSubscriptions?customerId=' + customerId)
        .then(res => {
          // まだ契約が完了していない場合のみ契約画面に遷移する。
          if (res.data.isSubscription) {
            self.loading = false
            self.description = '支払い情報が既に登録されています。支払い先の変更をご希望の場合は、もう一度管理者画面にログインし直してから「クレジットカード番号更新」を選択してください。'
            self.showButton = true
          } else {
            self.callStripe()
          }
        })
      }
    }
  }
</script>
<style scoped>
  .card {
    background: #eee !important;
  }
  .full-page:after {
    background: white !important;
  }
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #999999;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
