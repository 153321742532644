<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <!-- 従業員数設定モーダル パラメータ：ラベル -->
    <modal name="numberOfEmployeesSetting" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <NumberOfEmployeesSetting :labels="labels" :is-modal="true" @close="hideNumberOfEmployeesSetting" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <!-- 検索条件 -->
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <!-- 事業所 -->
          <ValidationSelect
            v-model="searchCond.office_code"
            item-name="office_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.office_list)"
            :item-label="labels.office"
            :options="comboData.office_list"
            frame-class="search-condition-item"
            @change="officeCodeChanged"
          />
          <!-- 期間 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label>{{$t('label.term')}}</label>
            <div>
              <vSelect name="from_year"
                        class="style-chooser length5"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="years"
                        v-model="searchCond.target_year">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
              <vSelect name="from_month"
                        class="style-chooser length3"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="months"
                        v-model="searchCond.target_month">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
            </div>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" :notification="showNotify" />
    <template v-if="days">
      <div class="card">
        <!-- <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div> -->
        <div class="card-content"><ValidationObserver ref="observer">
          <div style="margin-bottom: 10px;">
            {{ shiftSettingTerm }}
          </div>
          <div class="form-group search-condition-item shift-setting-work-schedule" style="display: inline-block; vertical-align: top; width: 225px;">
            <!-- 売上計画 -->
            <ValidationTextNumber
              v-model="detail.sales_plan"
              :item-label="labels.sales_plan"
              integral="10"
              classes="form-control numLength10"
              rules="min_value:0"
              :placeholder="isReadOnly ? '' : '1,200,000'"
              :unit="$t('label.yen')"
              :disabled="isReadOnly"
              @blur="inputSalesPlan"
            />
          </div>
          <!-- 粗利計画 -->
          <div class="form-group search-condition-item shift-setting-work-schedule" style="display: inline-block; vertical-align: top; width: 175px;">
            <ValidationTextNumber
              v-model="detail.gross_profit_plan"
              :item-label="labels.gross_profit_plan"
              integral=2
              decimal=1
              classes="form-control numLength5"
              rules="decimal_simple|min_value:0.0|max_value:99.9"
              :placeholder="isReadOnly ? '' : '30.5'"
              unit="％"
              :disabled="isReadOnly"
              @blur="inputGrossProfitPlan"
            />
          </div>
          <!-- 人件費計画 -->
          <div class="form-group search-condition-item shift-setting-work-schedule" style="display: inline-block; vertical-align: top; width: 195px;">
            <ValidationTextNumber
              v-model="detail.personnel_cost_plan"
              :item-label="labels.personnel_cost_plan"
              integral="10"
              classes="form-control numLength10"
              :unit="$t('label.yen')"
              :disabled=true
            />
          </div>
          <!-- 予定粗利 -->
          <div class="form-group search-condition-item shift-setting-work-schedule" style="display: inline-block; vertical-align: top; width: 140px;">
            <div>
              <ValidationTextNumber
              v-model="detail.planned_gross_profit"
              :item-label="labels.planned_gross_profit"
              integral="2"
              decimal="1"
              classes="form-control numLength5"
              unit="％"
              :disabled=true
            />
            </div>
            <div class="numLength5" style="text-align: right;">
              <small>
                {{ $t('label.different_amount') }}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </small>
              <small :style="differentGrossProfit < 0 ? 'color: red;' : 'color: blue;'">
                {{  differentGrossProfit }}
              </small>
            </div>
          </div>
          <!-- 予定人件費 -->
          <div class="form-group search-condition-item shift-setting-work-schedule" style="display: inline-block; vertical-align: top;">
            <div>
              <ValidationTextNumber
                v-model="detail.planned_labor_cost"
                :item-label="labels.planned_labor_cost"
                integral="10"
                classes="form-control numLength10"
                :unit="$t('label.yen')"
                :disabled=true
              />
            </div>
            <div class="numLength10" style="text-align: right;">
              <small :style="differentLaborCost > 0 ? 'color: red;' : 'color: blue;'">
                {{ formatNumber(differentLaborCost) }}
              </small>
            </div>
          </div>
          <!-- メッセージ -->
          <div class="error-message-wrapper" v-show="detail.message !== ''">
            <small class="text-danger">
              {{ detail.message }}
            </small>
          </div>
          <div>
            <div style="display: inline-block; width: 690px;">
              <!-- 行追加 -->
              <div v-show="labels.update === 1 && !isChanged">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{$t('button.add_row')}}</button>
              </div>
            </div>
            <div style="display: inline-block; width: 65px;" v-tooltip.top-center="$t('label.tooltip_horizontal_scroll')">
              <!-- 横スクロール -->
              <template v-if="scrollRight">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="toggleScrollRight">{{$t('button.scroll_left')}}</button>
              </template>
              <template v-else>
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="toggleScrollRight">{{$t('button.scroll_right')}}</button>
              </template>
            </div>
            <div style="display: inline-block;" v-tooltip.top-center="$t('label.tooltip_clear')">
              <!-- クリア -->
              <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isChanged" @click="clearValues">{{$t('button.clear')}}</button>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <!-- テーブル -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="detail.shift_setting_info_list"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      :max-height="maxHeight"
                      :span-method="arraySpanMethod"
                      ref="table"
                      :key="componentKey"
                      :cell-style="getCellStyle"
                      :row-class-name="tableRowClassName">
              <el-table-column width="50" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{$t('label.delete')}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <!-- 削除 -->
                  <div style="text-align: center;">
                    <template v-if="props.$index < detail.shift_setting_info_list.length - 2">
                      <input type="checkbox" v-model="props.row.delete_check" class="gray" :disabled="isReadOnly || props.row.confirm_flag" @change="isDeleteCheckChanged"/>
                    </template>
                    <template v-else>
                      <div style="text-align: center; font-size: medium;">
                        {{ $t('label.total') }}
                      </div>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="180" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{labels.business}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <!-- 職種 -->
                  <div class="error-message-wrapper" style="transform: translate(0px,15px)" v-show="props.row.business_type === ''">
                    <small class="text-danger">
                      {{ props.row.business_type_error }}
                    </small>
                  </div>
                  <ValidationSelect
                    v-model="props.row.business_type"
                    :placeholder="$t('label.select_description')"
                    classes="length9"
                    :options="comboData.business_list"
                    :disabled="isReadOnly || props.row.confirm_flag"
                    :title="getLabel(getSelectName(props.row.business_type, comboData.business_list))"
                  />
                </template>
              </el-table-column>
              <el-table-column width="180" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{labels.workSchedule}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="error-message-wrapper" style="transform: translate(0px,15px)" v-show="props.row.work_schedule_code === ''">
                    <small class="text-danger">
                      {{ props.row.work_schedule_code_error }}
                    </small>
                  </div>
                  <!-- 勤務体系 -->
                  <ValidationSelect
                    v-model="props.row.work_schedule_code"
                    :placeholder="$t('label.select_description')"
                    classes="length9"
                    :options="comboData.work_schedule_list"
                    :disabled="isReadOnly || props.row.confirm_flag"
                    frame-class="shift-setting-work-schedule"
                    :title="getLabel(getSelectName(props.row.work_schedule_code, comboData.work_schedule_list))"
                    @change="workScheduleCodeChanged(props.row)"
                  />
                  {{ getWorkScheduleLabel(props.row) }}
                </template>
              </el-table-column>
              <el-table-column width="90" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{labels.vueLabel_hourly_wage}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <!-- 時給 -->
                  <ValidationTextNumber
                    v-model="props.row.unit_price"
                    integral="5"
                    classes="form-control shift-setting-unit-price"
                    rules="min_value:0"
                    :placeholder="isReadOnly ? '' : '1,000'"
                    :unit="$t('label.yen')"
                    :disabled="isReadOnly"
                    @blur="inputUnitPrice(props.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column width="110" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{$t('label.labor_cost')}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <!-- 人件費 -->
                  <template v-if="props.$index < detail.shift_setting_info_list.length - 2">
                    <div style="text-align: right; font-size: medium;">
                      {{ formatNumber(props.row.total) + labels.vueLabel_yen }}
                    </div>
                  </template>
                  <template v-else-if="props.$index === detail.shift_setting_info_list.length - 2">
                    <div style="text-align: left; font-size: medium; line-height: 250%;">
                      {{ `${$t('label.number_people')}（${$t('label.people')}）` }}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: left; font-size: medium; line-height: 270%;">
                      {{ `${$t('label.labor_cost')}（${labels.vueLabel_yen}）` }}
                    </div>
                  </template>
                </template>
              </el-table-column>
              <!-- 人数 -->
              <el-table-column width="80" fixed="left" :label="$t('label.number_people')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.$index < detail.shift_setting_info_list.length - 2">
                    <div style="text-align: left; font-size: medium;">
                      <!-- 選択 -->
                      <div style="margin: 5px 0; text-align: center;">
                        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isChanged" @click="showNumberOfEmployeesSetting(props.$index, props.row)">{{$t('button.select')}}</button>
                      </div>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <template v-for="(day, index) in days">
                <!-- 日付 -->
                <el-table-column width="65">
                  <template slot="header">
                    <div class="phase-header tableHeader">
                      <div style="text-align: center; font-size: medium; font-weight: bold; line-height: 140%;">
                        {{`${parseInt(day.date.substring(0, 2), 10)}/${parseInt(day.date.substring(3, 5), 10)}`}}<br/>{{ day.date.substring(5) }}
                      </div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="props.$index < detail.shift_setting_info_list.length - 2">
                      <div style="text-align: right; font-size: medium;">
                        <!-- 人数 -->
                        <ValidationTextNumber
                          v-model="props.row.days[index].number_of_people"
                          integral="2"
                          classes="form-control shift-setting-number-of-people"
                          rules="min_value:0"
                          :placeholder="isReadOnly ? '' : '2'"
                          :disabled="isReadOnly"
                          @blur="inputNumberOfPeople(index, props.row)"
                        />
                        <!-- 人数×時給 -->
                        <div style="text-align: right; font-size: 14px;">
                          <small>{{formatNumber(props.row.days[index].daily_cost)}}</small>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="props.$index === detail.shift_setting_info_list.length - 2">
                      <div style="text-align: right; font-size: medium;">
                          <small>{{formatNumber(props.row.days[index].sum_of_people)}}</small>
                        </div>
                    </template>
                    <template v-else>
                      <div style="text-align: right; font-size: 14px;">
                        <small>{{formatNumber(props.row.days[index].sum_of_daily_cost)}}</small>
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <template v-if="labels.update === 1 && !this.isChanged">
              <div style="text-align: left; margin-top: 10px;">
                <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.update')}}
                </button>
                <br>
              </div>
            </template>
          </div>
        </ValidationObserver></div>
        <div class="row" ref="pager"></div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
import ShiftSettingSearchMixin from '@/components/Dashboard/Views/Monthly/ShiftSettingSearchMixin'
export default {
  name: 'ShiftSettingSearchMixin',
  mixins: [ShiftSettingSearchMixin]
}
</script>
<style scoped>
</style>

