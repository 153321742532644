<template>
  <div>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="tableData"
              style="width: 500px">

      <!-- 事業所 -->
      <el-table-column min-width="100" :label="labels.office_ryaku === undefined ? '' : labels.office_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>{{props.row.office_code}}</small>
            <br>
            {{props.row.office_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>

      <!-- 従業員区分 -->
      <el-table-column min-width="100" :label="labels.employee_classification_name_ryaku === undefined ? '' : labels.employee_classification_name_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>&nbsp;</small>
            <br>
            {{props.row.employee_classification_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>

      <!-- 従業員 -->
      <el-table-column min-width="100" :label="labels.employee_ryaku === undefined ? '' : labels.employee_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>{{props.row.employee_code}}</small>
            <br>
            {{props.row.employee_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>

      <!-- 納付額 -->
      <el-table-column min-width="100" :label="$t('label.payment_amount')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: right;">
            <small>&nbsp;</small>
            <br>
            {{formatNumber(props.row.payment_amount) + $t('label.unit_yen')}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>
