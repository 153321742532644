<template>
  <div class="card">
    <template v-if="errMsgs.length > 0">
      <div class="card-header">
        <template v-for="errMsg in errMsgs">
          <div v-bind:key="errMsg">
            <small style="color: #C84513; margin-left: 5px;">{{errMsg}}</small>
          </div>
        </template>
      </div>
    </template>
    <div class="card-content">
      <table>
        <tr>
          <th></th>
          <th>
            <div class="form-group">
              {{$t('label.before_change')}}
            </div>
          </th>
          <th class="after-change">
            <div class="form-group">
              {{$t('label.after_change')}}
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.employee_name}}</label>
            </div>
          </th>
          <td>
            <div class="form-group">
              <small>{{application.before_employee_name}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_employee_name"
                item-name="after_employee_name"
                max-length="100"
                classes="form-control width480"
                rules="required"
                :item-label="labels.employee_name"
                :placeholder="isReadOnly ? '' : $t('placeholder.employee_name')"
                :disabled="isReadOnly"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.mail_address}}</label>
            </div>
          </th>
          <td>
            <div class="form-group">
              <small>{{application.before_mail_address}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationText
                v-model="application.after_mail_address"
                item-name="after_mail_address"
                max-length="255"
                classes="form-control width480"
                :item-label="labels.mail_address"
                :placeholder="isReadOnly ? '' : $t('placeholder.mail_address')"
                :disabled="isReadOnly"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="form-group length5" style="display: inline-block;">
              <label>{{labels.sex}}</label>
            </div>
          </th>
          <td>
            <div class="form-group">
              <small>{{application.before_sex_name}}</small>
            </div>
          </td>
          <td class="after-change">
            <div class="form-group">
              <ValidationSelect
                v-model="application.after_sex"
                item-name="after_sex"
                :placeholder="$t('label.select_description')"
                classes="length4"
                :item-label="labels.sex"
                :options="comboData.sex_list"
                :disabled="isReadOnly"
              />
            </div>
          </td>
        </tr>
      </table>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.personal_information_change_supplement')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationText,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
  .after-change {
    padding-left: 50px;
  }
</style>
