<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.94">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" @show-employee="showEmployeeSearchModal" @show-message="showMessageView" ref="detail"/>
      </div>
    </modal>
    <!-- シフト送信モーダル -->
    <modal name="send" :draggable="draggable" :clickToClose="false" height="auto" :width="modalSendWidth" :pivotX="0.6">
      <div class="modal-body">
        <Send :mode="mode" :params="sendParams" @close="sendHide"/>
      </div>
    </modal>
    <!-- 従業員検索モーダルサブ パラメータ：検索条件 -->
    <modal name="employeeSearchModal" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="810">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="employeeSearchCond" :is-modal="true" @close="hideEmployeeSearchModal" />
      </div>
    </modal>
    <!-- メッセージ表示モーダルサブ パラメータ：メッセージリスト -->
    <modal name="messageView" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
      <div class="modal-body">
        <MessageView :messageViewParams="messageViewParams" @close="hideMessageView" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <!-- 検索条件 -->
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <!-- 事業所 -->
          <ValidationSelect
            v-model="searchCond.office_code"
            item-name="office_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.office_list)"
            :item-label="labels.office"
            :options="comboData.office_list"
            frame-class="search-condition-item"
            @change="officeCodeChanged"
          />
          <!-- 期間 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label>{{$t('label.term')}}</label>
            <div>
              <vSelect name="from_year"
                        class="style-chooser length5"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="years"
                        v-model="searchCond.target_year">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
              <vSelect name="from_month"
                        class="style-chooser length3"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="months"
                        v-model="searchCond.target_month">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
            </div>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" :notification="showNotify" />
    <template v-if="dayInfo">
      <div class="card">
        <!-- <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div> -->
        <div class="card-content"><ValidationObserver ref="observer">
          <div style="margin-bottom: 10px;">
            {{ shiftTerm }}
          </div>
          <!-- メッセージ -->
          <div class="error-message-wrapper" v-show="searchResult.messageList.length !== 0">
            <small class="text-danger">
              <template v-for="message in searchResult.messageList">
                {{ message }}<br>
              </template>
            </small>
          </div>
          <div style="height: 40px;">
            <template v-if="labels.print === 1 && !this.isChanged">
              <!-- シフト送信 -->
              <div style="text-align: left; display: inline-block; width: 90px;" v-tooltip.top-center="searchResult.shift_publish_flg === 2 ? $t('label.tooltip_shift') : null">
                <div style="margin-top: 10px;">
                  <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="disableButton" @click="handleSend">
                    {{$t('button.shift_send')}}
                  </button>
                </div>
              </div>
              <!-- PDF作成 -->
              <div style="text-align: left; display: inline-block; width: 80px;" v-tooltip.top-center="searchResult.shift_publish_flg === 2 ? $t('label.tooltip_shift') : null">
                <div style="margin-top: 10px;">
                  <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="disableButton" @click="print">
                    {{$t('button.print')}}
                  </button>
                </div>
              </div>
            </template>
            <template v-if="labels.update === 1 && !this.isChanged">
              <!-- 行追加 -->
              <div style="display: inline-block; width: 70px;">
                <div style="margin-top: 10px;">
                  <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="searchResult.shift_publish_flg === 1" @click="addRow">{{$t('button.add_row')}}</button>
                </div>
              </div>
            </template>
            <!-- 表示切替 -->
            <div style="display: inline-block; width: 120px;">
              <div v-show="this.searchResult.isButtonSimple" style="margin-top: 10px;">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="switchView(false)">{{$t('button.switch_to_employee_display')}}</button>
              </div>
              <div v-show="!this.searchResult.isButtonSimple" style="margin-top: 10px;">
                <button type="button" class="btn btn-sm btn-default btn-primary" @click="switchView(true)">{{$t('button.switch_to_number_of_people_display')}}</button>
              </div>
            </div>
            <div style="display: inline-block; width: 10px;">
              <!-- 従業員 -->
              <div v-show="!this.searchResult.isButtonSimple">
                <vSelect v-model="searchResult.employee_code"
                          :placeholder="$t('placeholder.highlight_employees')"
                          class="style-chooser length9"
                          style="display: inline-block;"
                          :options="comboData.employee_list"
                          :clearable="true"
                          name="employee_code">
                  <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                </vSelect>
              </div>
            </div>
            <div style="display: inline-block; width: 280px;">
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; background-color: #e1f5e1; border: 1px solid #999;"></div>：未調整、
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; background-color: #e1ebf5; border: 1px solid #999;"></div>：調整中、
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; border: 1px solid #999;"></div>：確定
            </div>
          </div>
          <div style="margin-top: 10px;">
            <!-- テーブル -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      :max-height="maxHeight"
                      :span-method="objectSpanMethod"
                      ref="table"
                      :key="componentKey"
                      :row-class-name="tableRowClassName">
              <!-- 削除 -->
              <el-table-column width="50" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{$t('label.delete')}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <div style="text-align: center; font-size: medium;">
                      <input type="checkbox" v-model="props.row.delete_check" class="gray" :disabled="isReadOnly || !props.row.is_row_edit" @change="deleteCheckChanged()"/>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 職種 -->
              <el-table-column width="180" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{labels.business}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.row === 1">
                    <div class="error-message-wrapper" style="transform: translate(0px,15px)" v-show="props.row.business_type === ''">
                      <small class="text-danger">
                        {{ props.row.business_type_error }}
                      </small>
                    </div>
                    <ValidationSelect
                      v-model="props.row.business_type"
                      :placeholder="$t('label.select_description')"
                      classes="length9"
                      :options="comboData.business_list"
                      :disabled="isReadOnly || !props.row.is_row_edit || !props.row.adjustment_for_added_line_flag || props.row.delete_check"
                      :title="getLabel(getSelectName(props.row.business_type, comboData.business_list))"
                      @change="businessTypeChanged(props.row)"
                    />
                    &nbsp;
                  </template>
                </template>
              </el-table-column>
              <!-- 勤務体系 -->
              <el-table-column width="180" fixed="left">
                <template slot="header">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">
                      <div class="phase-header__title">{{$t('label.work_schedule')}}</div>
                    </div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="props.row.row === 1">
                    <div class="error-message-wrapper" style="transform: translate(0px,15px)" v-show="props.row.work_schedule_code === ''">
                      <small class="text-danger">
                        {{ props.row.work_schedule_code_error }}
                      </small>
                    </div>
                    <ValidationSelect
                      v-model="props.row.work_schedule_code"
                      :placeholder="$t('label.select_description')"
                      classes="length9"
                      :options="comboData.work_schedule_list"
                      :disabled="isReadOnly || !props.row.is_row_edit || !props.row.adjustment_for_added_line_flag || props.row.delete_check"
                      :title="getLabel(getSelectName(props.row.work_schedule_code, comboData.work_schedule_list))"
                      @change="workScheduleCodeChanged(props.row)"
                    />
                    {{getWorkScheduleTime(props.row.work_schedule_code)}}
                  </template>
                </template>
              </el-table-column>
              <!-- 計画と実績 -->
              <template v-if="searchResult.isButtonSimple">
                <el-table-column width="50" fixed="left">
                  <template slot="header">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">
                        <div class="phase-header__title"></div>
                      </div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="props.row.row === 1">
                      <div style="font-size: small;">{{$t('label.achievements')}}</div>
                    </template>
                    <template v-if="props.row.row === 2">
                      <div style="font-size: small;">{{$t('label.plan')}}</div>
                    </template>
                  </template>
                </el-table-column>
              </template>
              <template v-for="(day, index) in dayInfo">
                <!-- 日付 -->
                <el-table-column :width="dayWidth">
                  <template slot="header">
                    <div class="phase-header tableHeader">
                      <!-- 1～9日 または 1～9月-->
                      <template v-if="searchResult.isButtonSimple || index < 9 || day.charAt(5) === '0'">
                        <div style="text-align: center; font-size: medium; line-height: 140%;">
                          {{formatDateWith(day,'M/D(ddd)')}}<br>
                        </div>
                      </template>
                      <!-- 2桁月2桁日の場合、少し左にずらす -->
                      <template v-else>
                        <div style="text-align: center; font-size: medium; line-height: 140%; transform: translate(-4px,0px);">
                          {{formatDateWith(day,'M/D(ddd)')}}<br>
                        </div>
                      </template>
                      <template v-if="labels.update === 1 && searchResult.shift_publish_flg === 2">
                        <a class="btn btn-simple btn-icon edit" @click="handleEdit(day, isReadOnly || !isModalEdit)" :disabled="isReadOnly || !isModalEdit"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                      </template>
                      <template v-else-if="labels.preview === 1">
                        <a class="btn btn-simple btn-icon like" @click="handleView(day, isChanged)" :disabled="isChanged"><i class="ti-file" :title="$t('button.view')"></i></a>
                      </template>
                    </div>
                  </template>
                  <!-- 件数 -->
                  <template slot-scope="props">
                    <!-- 1段目 詳細表示 -->
                    <template v-if="props.row.row === 1 && !searchResult.isButtonSimple">
                      <template v-for="(employee, index) in props.row.daily_details[index].employee_list">
                        <div style="font-size: 10px;" :class="{'blue':employee.status === 'blue', 'red':employee.status === 'red', 'curious': searchResult.employee_code && employee.employee_code === searchResult.employee_code.value}">
                          <div style="text-align: left; float: left;">{{employee.employee_name}}</div>
                          <div style="text-align: right; min-height: 23px;">{{employee.work_time}}</div>
                        </div>
                      </template>
                    </template>
                    <!-- 1段目 簡易表示 -->
                    <template v-else-if="props.row.row === 1 && searchResult.isButtonSimple">
                      <div style="text-align: center; font-size: large;" :class="{'blue':props.row.daily_details[index].total_status === 'blue', 'red':props.row.daily_details[index].total_status === 'red'}">
                        {{props.row.daily_details[index].count}}
                      </div>
                    </template>
                    <!-- 2段目 簡易表示 -->
                    <template v-else-if="props.row.row === 2 && searchResult.isButtonSimple">
                      <div style="text-align: center; font-size: small;">
                        {{props.row.daily_details[index].count}}
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <template v-if="labels.update === 1 && !this.isChanged">
              <!-- 更新 -->
              <div style="text-align: left; margin-top: 10px; display: inline-block; width: 70px;" v-if="searchResult.shift_publish_flg === 2">
                <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate(searchResult.shift_publish_flg)">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.update')}}
                </button>
                <br>
              </div>
              <!-- 公開 -->
              <div style="text-align: left; margin-top: 10px; display: inline-block; width: 75px;" v-if="searchResult.shift_publish_flg === 2">
                <button type="button" class="btn btn-sm btn-default btn-submit" :disabled=!isModalEdit @click="doUpdate(1)">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.public')}}
                </button>
              </div>
              <!-- 非公開 -->
              <div style="text-align: left; margin-top: 10px; display: inline-block; width: 85px;" v-if="searchResult.shift_publish_flg === 1">
                <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate(2)">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.private')}}
                </button>
              </div>
              <div style="display: inline-block; width: 270px;">
                <input type="checkbox" v-model="searchResult.unset_holiday_flg" class="gray" id="unsetHolidayFlg" :disabled="isReadOnly || searchResult.shift_publish_flg === 1"/>
                <label for="unsetHolidayFlg" :class="isReadOnly || searchResult.shift_publish_flg === 1 ? 'grayLabel2' : 'grayLabel'">未設定を所定休日とする</label>
              </div>
            </template>
          </div>
        </ValidationObserver></div>
        <div class="row" ref="pager"></div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
import ShiftDecisionSearchMixin from '@/components/Dashboard/Views/Monthly/ShiftDecisionSearchMixin'
export default {
  name: 'ShiftDecisionSearchMixin',
  mixins: [ShiftDecisionSearchMixin]
}
</script>
<style scoped>
.blue {
  background-color: #e1ebf5;
}
.red {
  background-color: #e1f5e1;
}

.curious {
  font-weight: bold;
  font-style: italic;
}

.grayLabel {
  color: #666;
  font-weight: bold;
}
.grayLabel2 {
  color: #777;
  font-weight:100;
}
</style>

