<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.menu_code"
              item-name="menu_code"
              :placeholder="$t('label.select_description')"
              classes="length20"
              rules="required"
              :item-label="labels.menu_name"
              :options="comboData.menu_list"
              :disabled="isReadOnly"
              @change="menuCodeChanged"
            />
            <ValidationText
              v-model="detail.choice_screen_code"
              item-name="choice_screen_code"
              max-length="6"
              classes="form-control length6"
              :item-label="labels.choice_screen_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.choice_screen_code')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.screen_name"
              item-name="screen_name"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.screen_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.screen_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.abbreviated_name"
              item-name="abbreviated_name"
              max-length="128"
              classes="form-control length128"
              rules="required"
              :item-label="labels.abbreviated_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.abbreviated_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.keyword"
              item-name="keyword"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.keyword"
              :placeholder="isReadOnly ? '' : $t('placeholder.keyword')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.icon_name"
              item-name="icon_name"
              max-length="50"
              classes="form-control length50"
              rules="required"
              :item-label="labels.icon_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.icon_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.sort_number"
              item-name="sort_number"
              max-length="3"
              classes="form-control numLength3 text-right"
              rules="required"
              :item-label="labels.sort_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.sort_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.vue_path"
              item-name="vue_path"
              max-length="255"
              classes="form-control length255"
              rules="required"
              :item-label="labels.vue_path"
              :placeholder="isReadOnly ? '' : $t('placeholder.vue_path')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationCheckboxes
              v-model="detail.authority"
              :item-label="labels.authority"
              :disabled="isReadOnly"
            />
            <RoleAuthority
              v-model="detail.role_authority"
              rules="namesRequired|nameRequired"
              classes="form-control length60"
              :disabled="isReadOnly"
              :item-label="$t('label.api')"
              :placeholder="$t('placeholder.api')"
              item-name="role_authority"
              max-length="60"
              :authority="detail.authority"
            />
            <ValidationSelect
              v-model="detail.system_screen_flg"
              item-name="system_screen_flg"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.system_screen_flg"
              :options="comboData.system_screen_flg_list"
              :disabled="isReadOnly"
              @change="systemScreenFlgChanged"
            />
            <ValidationSelect
              v-model="detail.screen_type"
              item-name="screen_type"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.screen_type"
              :options="comboData.screen_type_list"
              :disabled="isReadOnly"
              @change="screenTypeChanged"
            />
            <ValidationText
              v-model="detail.contents"
              item-name="contents"
              max-length="255"
              classes="form-control length255"
              rules="required"
              :item-label="labels.contents"
              :placeholder="isReadOnly ? '' : $t('placeholder.contents')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.release_version"
              item-name="release_version"
              max-length="5"
              classes="form-control numLength5 text-right"
              rules="required"
              :item-label="labels.release_version"
              :placeholder="isReadOnly ? '' : $t('placeholder.release_version')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationCheckboxes
              v-model="detail.is_notice"
              :item-label="labels.is_notice"
              :disabled="isReadOnly"
            />
            <div class="form-group">
              <label>{{$t('label.api')}}</label>
              <ValidationNameTable
                v-model="detail.api"
                rules="namesRequired|nameRequired"
                classes="form-control length60"
                :disabled="isReadOnly"
                :item-label="$t('label.api')"
                :placeholder="$t('placeholder.api')"
                item-name="api"
                max-length="60"
              />
            </div>
            <ValidationText
              v-model="detail.help1"
              item-name="help1"
              max-length="255"
              classes="form-control length255"
              rules="required"
              :item-label="labels.help1"
              :placeholder="isReadOnly ? '' : $t('placeholder.help1')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help2"
              item-name="help2"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help2"
              :placeholder="isReadOnly ? '' : $t('placeholder.help2')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help3"
              item-name="help3"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help3"
              :placeholder="isReadOnly ? '' : $t('placeholder.help3')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help4"
              item-name="help4"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help4"
              :placeholder="isReadOnly ? '' : $t('placeholder.help4')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help5"
              item-name="help5"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help5"
              :placeholder="isReadOnly ? '' : $t('placeholder.help5')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help6"
              item-name="help6"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help6"
              :placeholder="isReadOnly ? '' : $t('placeholder.help6')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help7"
              item-name="help7"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help7"
              :placeholder="isReadOnly ? '' : $t('placeholder.help7')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help8"
              item-name="help8"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help8"
              :placeholder="isReadOnly ? '' : $t('placeholder.help8')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help9"
              item-name="help9"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help9"
              :placeholder="isReadOnly ? '' : $t('placeholder.help9')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help10"
              item-name="help10"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help10"
              :placeholder="isReadOnly ? '' : $t('placeholder.help10')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help11"
              item-name="help11"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help11"
              :placeholder="isReadOnly ? '' : $t('placeholder.help11')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help12"
              item-name="help12"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help12"
              :placeholder="isReadOnly ? '' : $t('placeholder.help12')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help13"
              item-name="help13"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help13"
              :placeholder="isReadOnly ? '' : $t('placeholder.help13')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help14"
              item-name="help14"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help14"
              :placeholder="isReadOnly ? '' : $t('placeholder.help14')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.help15"
              item-name="help15"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.help15"
              :placeholder="isReadOnly ? '' : $t('placeholder.help15')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ScreenDetailMixin from '@/components/Dashboard/Views/Generated/ScreenDetailMixin'

  export default {
    name: 'ScreenDetail',
    methods: {
      afterInit (res) {
        this.labels.choice_screen_code = this.labels.screen_code
        this.labels.menu_name = this.labels.menu_code_ryaku
        this.labels.screen_name = this.labels.screen
        this.labels.authority = this.$t('label.authority_setting')
        const items = []
        items.push({id: 1, label: this.getLabel(res.common_area.table_label.entry), value: res.api_area.entry === 1})
        items.push({id: 2, label: this.getLabel(res.common_area.table_label.update), value: res.api_area.update === 1})
        items.push({id: 3, label: this.getLabel(res.common_area.table_label.delete), value: res.api_area.delete === 1})
        items.push({id: 4, label: this.getLabel(res.common_area.table_label.print), value: res.api_area.print === 1})
        items.push({id: 5, label: this.getLabel(res.common_area.table_label.search), value: res.api_area.search === 1})
        items.push({id: 6, label: this.getLabel(res.common_area.table_label.upload), value: res.api_area.upload === 1})
        items.push({id: 7, label: this.getLabel(res.common_area.table_label.download), value: res.api_area.download === 1})
        items.push({id: 8, label: this.getLabel(res.common_area.table_label.preview), value: res.api_area.preview === 1})
        this.detail.authority = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: items
        }
        const apiList = []
        for (const row of this.detail.api_list) {
          apiList.push({
            selected: false,
            name: row
          })
        }
        this.detail.api = apiList
        const roleAuthorityList = []
        for (const row of res.api_area.role_list) {
          const roleItems = []
          roleItems.push({id: 1, label: this.getLabel(res.common_area.table_label.entry), value: row.entry === 1})
          roleItems.push({id: 2, label: this.getLabel(res.common_area.table_label.update), value: row.update === 1})
          roleItems.push({id: 3, label: this.getLabel(res.common_area.table_label.delete), value: row.delete === 1})
          roleItems.push({id: 4, label: this.getLabel(res.common_area.table_label.print), value: row.print === 1})
          roleItems.push({id: 5, label: this.getLabel(res.common_area.table_label.search), value: row.search === 1})
          roleItems.push({id: 6, label: this.getLabel(res.common_area.table_label.upload), value: row.upload === 1})
          roleItems.push({id: 7, label: this.getLabel(res.common_area.table_label.download), value: row.download === 1})
          roleItems.push({id: 8, label: this.getLabel(res.common_area.table_label.preview), value: row.preview === 1})
          const roleAuthority = {
            color: 'gray',
            disabled: this.isReadOnly,
            items: roleItems
          }
          roleAuthorityList.push({
            role_code: row.role_code,
            role_name: row.role_name,
            role_authority: roleAuthority
          })
        }
        this.detail.role_authority = roleAuthorityList
        this.detail.is_notice = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 1,
              label: this.$t('label.is_notice'),
              value: res.api_area ? !!res.api_area.is_notice : false
            }
          ]
        }
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.release_version = parseFloat(detail.release_version)
          detail.sort_number = parseInt(detail.sort_number, 10)
          detail.system_screen_flg = parseInt(detail.system_screen_flg, 10)
          detail.screen_type = parseInt(detail.screen_type, 10)
          detail.entry = detail.authority.items[0].value ? 1 : 2
          detail.update = detail.authority.items[1].value ? 1 : 2
          detail.delete = detail.authority.items[2].value ? 1 : 2
          detail.print = detail.authority.items[3].value ? 1 : 2
          detail.search = detail.authority.items[4].value ? 1 : 2
          detail.upload = detail.authority.items[5].value ? 1 : 2
          detail.download = detail.authority.items[6].value ? 1 : 2
          detail.preview = detail.authority.items[7].value ? 1 : 2
          const apiList = []
          for (const row of this.detail.api) {
            if (row.selected) continue
            apiList.push({api_name: row.name})
          }
          detail.api_list = apiList
          delete detail.api
          const roleAuthorityList = []
          for (const row of this.detail.role_authority) {
            roleAuthorityList.push({
              role_code: row.role_code,
              entry: detail.authority.items[0].value ? row.role_authority.items[0].value ? 1 : 2 : 2,
              update: detail.authority.items[1].value ? row.role_authority.items[1].value ? 1 : 2 : 2,
              delete: detail.authority.items[2].value ? row.role_authority.items[2].value ? 1 : 2 : 2,
              print: detail.authority.items[3].value ? row.role_authority.items[3].value ? 1 : 2 : 2,
              search: detail.authority.items[4].value ? row.role_authority.items[4].value ? 1 : 2 : 2,
              upload: detail.authority.items[5].value ? row.role_authority.items[5].value ? 1 : 2 : 2,
              download: detail.authority.items[6].value ? row.role_authority.items[6].value ? 1 : 2 : 2,
              preview: detail.authority.items[7].value ? row.role_authority.items[7].value ? 1 : 2 : 2
            })
          }
          detail.role_list = roleAuthorityList
          delete detail.authority
          delete detail.role_authority
          detail.is_notice = detail.is_notice.items[0].value
          if (this.mode === this.modes.add) {
            detail.choice_screen_code = detail.choice_screen_code
            delete detail.update_count
          }
        }
        return detail
      }
    },
    mixins: [ScreenDetailMixin]
  }
</script>
<style scoped>
</style>
