<template>
  <div class="row" ref="frame">
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <form method="#" action="#" @submit.prevent="">
              <ValidationObserver ref="observer">
                <ValidationCheckboxes
                  v-model="searchCond.is_long_term_care_insurance_care_category"
                  :item-label="labels.is_long_term_care_insurance_care_category"
                  :is-search-condition="true"
                />
                <ValidationText
                  v-model="searchCond.age"
                  item-name="age"
                  max-length="2"
                  classes="form-control numLength2 text-right"
                  rules="min_value: 0"
                  :item-label="$t('label.age')"
                  :placeholder="$t('placeholder.age')"
                  :unit="$t('label.older_than')"
                  :is-search-condition="true"
                />
                <!-- 退職者検索用のチェックボックス -->
                <div class="form-group" style="vertical-align: top;">
                  <input type="checkbox" v-model="searchCond.retirement" class="gray" id="retirement" />
                  <label for="retirement">{{$t('label.search_all_employees')}}</label>
                </div>
                <!-- 検索ボタン -->
                <template v-if="labels.search === 1">
                  <div style="text-align: left;">
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="search">
                      <span class="btn-label"><i class="ti-search"></i></span>
                      {{$t('button.search')}}
                    </button>
                  </div>
                </template>
              </ValidationObserver>
            </form>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div style="margin-bottom: 10px;">
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="printClicked" v-show="labels.print === 1">
              {{$t('button.print')}}
            </button>
          </div>
          <div style="display: inline-block">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      :style="tableStyle"
                      ref="table"
                      :key="componentKey"
                      :row-class-name="tableRowClassName">
              <template>
                <!-- 従業員名 -->
                <el-table-column :width="250" :label="labels.employee_ryaku ? labels.employee_ryaku : ''">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <font size="3"><b>{{props.row.employee_name}}</b></font>
                    </div>
                    <div style="text-align: left;">
                      <small>({{props.row.mail_address}})</small>
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.employee_code}}</small>
                    </div>
                    <!-- 年齢 -->
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.age ? props.row.age + $t('label.unit_age') : ''}}</b></font>
                    </div>
                    <!-- 生年月日 -->
                    <div style="text-align: right;">
                      {{props.row.birthday}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 介護保険加入 -->
                <el-table-column :width="200" :label="labels.is_long_term_care_insurance_care_category_ryaku ? labels.is_long_term_care_insurance_care_category_ryaku : ''">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center;" v-if="props.row.is_long_term_care_insurance_care_category">
                      <ValidationSelectHorizontal
                        v-model="props.row.is_long_term_care_insurance_care_category"
                        :item-name="`is_long_term_care_insurance_care_category${props.$index}`"
                        :placeholder="$t('label.select_description')"
                        classes="length5"
                        :item-label="labels.is_long_term_care_insurance_care_category"
                        :options="comboData.is_long_term_care_insurance_care_category_list.map((element) => ({label: element.label, value: parseInt(element.value, 10)}))"
                        :disabled="isChanged"
                        :title="getLabel(getSelectName(props.row.is_long_term_care_insurance_care_category, comboData.is_long_term_care_insurance_care_category_list))"
                      />
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="p-homeChart">
            <h4>{{$t('label.pie_chart_title')}}</h4>
            <h5>{{$t('label.pie_chart_subtitle').replace('{age}', this.averageAge)}}</h5>
            <div class="p-pieChart"></div>
          </div>
          <div class="p-homeChart">
            <h4>{{$t('label.pie_chart_title2')}}</h4>
            <h5>&nbsp;</h5>
            <div class="p-pieChart2"></div>
          </div>
          <br>
          <br>
          <div style="text-align: left;">
            <button
              type="button"
              class="btn btn-sm btn-default btn-submit"
              @click="doUpdate"
            >
              <span class="btn-label">
                <i class="fa fa-check"></i>
              </span>
              {{$t('button.update')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelectHorizontal from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  import Chartist from 'chartist'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    props: {
      value: Array,
      labels: Object,
      comboData: Object,
      parentMessage: Object,
      searchCond: Object
    },
    components: {
      ValidationCheckboxes,
      ValidationText,
      ValidationSelectHorizontal
    },
    data () {
      return {
        componentKey: 0,
        showSearchCondition: true,
        $Chartist: null,
        averageAge: 0,
        searchLabels: {},
        orgSearchCond: {},
        tableData: [],
        tableStyle: '',
        menu_code: '',
        viewScreenList: [],
        mode: 0,
        modalRoles: [],
        permissions: [],
        modalLabels: [],
        params: {},
        nameHeader: '',
        failMsg: '',
        dispType: 0,
        isChanged: false
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      },
      parentMessage () {
        this.message = this.parentMessage
      }
    },
    methods: {
      initPieChart () {
        let employeeCount = 0
        let averageAge = 0
        const labels = [
          this.$t('label.generation10'),
          this.$t('label.generation20'),
          this.$t('label.generation30'),
          this.$t('label.generation40'),
          this.$t('label.generation50'),
          this.$t('label.generation60')
        ]
        const series = [0, 0, 0, 0, 0, 0]
        for (const row of this.tableData) {
          if (row && !isNaN(row.age)) {
            const generation = parseInt(row.age / 10, 10)
            if (generation < 2) {
              series[0] += 1
            } else if (generation < 6) {
              series[generation - 1] += 1
            } else {
              series[5] += 1
            }
            employeeCount += 1
            averageAge += row.age
          }
        }
        this.averageAge = employeeCount === 0 ? 0 : Math.round(10 * averageAge / employeeCount) / 10

        for (let i = 5; i >= 0; i--) {
          if (series[i] === 0) {
            labels.splice(i, 1)
            series.splice(i, 1)
          } else {
            labels[i] += ' (' + String(parseInt(100 * series[i] / employeeCount, 10)) + '%)'
          }
        }

        const pieData = {
          labels: labels,
          series: series
        }
        const pieOptions = {
          width: '100%',
          height: '300px'
        }
        const responsiveOptions = [
          ['screen and (min-width: 640px)', {
            labelOffset: 20,
            chartPadding: 0
          }]
        ]

        this.$Chartist.Pie('.p-pieChart', pieData, pieOptions, responsiveOptions)
        this.initPieChart2()
      },
      initPieChart2 () {
        let employeeCount = 0
        const labels = [
          this.$t('label.male'),
          this.$t('label.female')
        ]
        const series = [0, 0]
        for (const row of this.tableData) {
          if (row && !isNaN(row.age)) {
            if (row.sex === 1) {
              series[0] += 1
            } else {
              series[1] += 1
            }
            employeeCount += 1
          }
        }

        for (let i = 1; i >= 0; i--) {
          if (employeeCount === 0) {
            labels[i] = '　'
          } else {
            labels[i] += ' (' + String(parseInt(100 * series[i] / employeeCount, 10)) + '%)'
          }
        }

        const pieData = {
          labels: labels,
          series: series
        }
        const pieOptions = {
          width: '100%',
          height: '300px'
        }

        this.$Chartist.Pie('.p-pieChart2', pieData, pieOptions)
      },
      tableRowClassName ({row}) {
        if (row.retirement_date) {
          return 'out-row'
        }
        return ''
      },
      search () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }
          // APIに渡す検索条件を設定
          const isLongTermCareInsuranceCareCategoryList = []
          for (const row of this.searchCond.is_long_term_care_insurance_care_category.items) {
            if (row.value) {
              isLongTermCareInsuranceCareCategoryList.push({is_long_term_care_insurance_care_category: parseInt(row.id, 10)})
            }
          }
          let searchCond = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              is_long_term_care_insurance_care_category_list: isLongTermCareInsuranceCareCategoryList,
              retirement: this.searchCond.retirement,
              age: this.searchCond.age ? parseInt(this.searchCond.age, 10) : null
            }
          }
          // 検索API呼び出し
          this.send('/view/employee_age/list', searchCond)
          .then(res => {
            this.isChanged = false
            this.orgSearchCond = {...this.searchCond}
            this.tableData = res.api_area.employee_data
            this.setAge()
            this.initPieChart()
          })
        })
      },
      setAge () {
        for (const row of this.tableData) {
          if (row.birthday) {
            row.age = this.calcYears(row.birthday, this.formatToday())
          }
        }
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.componentKey += 1
        })
        this.componentKey += 1
      },
      doUpdate () {
        const employeeData = []
        for (const row of this.tableData) {
          employeeData.push({
            employee_code: row.employee_code,
            is_long_term_care_insurance_care_category: !row.is_long_term_care_insurance_care_category ? 2 : row.is_long_term_care_insurance_care_category,
            update_count: row.update_count
          })
        }
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_data: employeeData
          }
        }
        this.send('/update/employee_age', params)
        .then(res => {
          this.isChanged = true
          this.$refs.frame.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
        })
      },
      printClicked () {
        console.log(this.orgSearchCond)
        const isLongTermCareInsuranceCareCategoryList = []
        for (const row of this.orgSearchCond.is_long_term_care_insurance_care_category.items) {
          if (row.value) {
            isLongTermCareInsuranceCareCategoryList.push({is_long_term_care_insurance_care_category: parseInt(row.id, 10)})
          }
        }
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            retirement: this.orgSearchCond.retirement,
            is_long_term_care_insurance_care_category_list: isLongTermCareInsuranceCareCategoryList,
            age: this.searchCond.age ? parseInt(this.searchCond.age, 10) : null
          }
        }
        this.send('/print/employee_age', params)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      }
    },
    created () {
      this.tableData = this.value
      this.message = this.parentMessage
      this.orgSearchCond = this.searchCond
    },
    mounted () {
      this.$nextTick(() => {
        this.$Chartist = Chartist
        this.initPieChart()
      })
    }
  }
</script>

<style scoped>
  .p-homeChart {
    display: inline-block;
    vertical-align: top;
    padding-left: 100px;
  }
</style>
<style>
  .ct-label {
    font-size: 1.5rem !important;
  }
  .ct-series-a .ct-slice-pie {
    fill: #E3E3E3 !important;
  }
  .ct-series-b .ct-slice-pie {
    fill: #4ACCCD !important;
  }
  .ct-series-c .ct-slice-pie {
    fill: #FCC468 !important;
  }
  .ct-series-d .ct-slice-pie {
    fill: #9BCBF8 !important;
  }
  .ct-series-e .ct-slice-pie {
    fill: #BBBBBB !important;
  }
  .ct-series-f .ct-slice-pie {
    fill: #BBEEBB !important;
  }
</style>