<template>
  <div class="card">
    <div class="card-content" :key="componentKey">
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{labels.paid_holiday_type}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationRadio
            v-model="application.paid_holiday_type"
            rules="required"
            :item-label="labels.paid_holiday_type"
            :disabled="isReadOnly"
            @change="paidHolidayTypeChanged"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{$t('label.label_header_target_date')}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.term_from"
            item-name="term_from"
            rules="required"
            :itemLabel="$t('label.label_header_target_date')"
            placeholder="2020-01-01"
            :disabled="isReadOnly && isModal"
            @change="synchronizeFrom"
          />
          <template v-if="application.paid_holiday_type && application.paid_holiday_type.value === 1">
            <span>~</span>
            <ValidationDate
              v-model="application.term_to"
              item-name="term_to"
              rules="required"
              :itemLabel="$t('label.label_header_target_date')"
              placeholder="2020-01-01"
              :disabled="isReadOnly"
              @change="synchronizeTo"
            />
            <span class="strong-text" style="margin-left: 20px;">{{application.holiday_days}}</span>
          </template>
          <span class="strong-text" style="margin-left: 20px;">{{application.total_hold_days || application.total_hold_times ? $t('label.remaining_paid_holiday') + '　' + application.total_hold_days + $t('label.unit_day') + (' ' + application.total_hold_times).slice(-2) + $t('label.unit_hours') : ''}}</span>
        </div>
      </div>
      <template v-if="application.paid_holiday_type && application.paid_holiday_type.value !== 1">
        <div class="form-group">
          <div class="length7" style="display: inline-block;">
            <label>{{$t('label.unit_hours')}}</label>
          </div>
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      v-model="application.term_time_from_hour"
                      @change="synchronizeTimeFrom">
            <el-option v-for="hour in hours"
                        class="select-success"
                        :value="hour"
                        :label="hour"
                        :key="hour">
            </el-option>
          </el-select>&nbsp;{{$t('label.hour')}}&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      v-model="application.term_time_from_minute"
                      @change="synchronizeTimeFrom">
            <el-option v-for="minute in minutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
          ～
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      v-model="application.term_time_to_hour"
                      @change="synchronizeTimeTo">
            <el-option v-for="hour in hours"
                        class="select-success"
                        :value="hour"
                        :label="hour"
                        :key="hour">
            </el-option>
          </el-select>&nbsp;{{$t('label.hour')}}&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      v-model="application.term_time_to_minute"
                      @change="synchronizeTimeTo">
            <el-option v-for="minute in minutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
          <small style="color: #C84513; margin-left: 5px;">{{errMsgs.length > 0 ? errMsgs[0] : ''}}</small>
        </div>
      </template>
      <div class="form-group">
        <div class="length7" style="display: inline-block; vertical-align: top;">
          <label>{{labels.contents}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.contents"
            :placeholder="isReadOnly ? '' : $t('placeholder.paid_holiday_content')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate,
      ValidationRadio
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        holidayDays: '',
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        application: {}
      }
    },
    methods: {
      paidHolidayTypeChanged () {
        if (this.application.paid_holiday_type.value === 2) {
          this.synchronizeTimeFrom()
        }
      },
      synchronizeFrom () {
        if (!this.application.term_from) return
        if (!this.application.term_to || this.application.paid_holiday_type.value !== 1) this.application.term_to = this.application.term_from
        let from = parseInt(this.application.term_from.replaceAll('/', ''))
        let to = parseInt(this.application.term_to.replaceAll('/', ''))
        if (from > to) {
          this.application.term_to = this.application.term_from
        }
        this.$emit('initApply', this.application.term_from, null)
      },
      synchronizeTo () {
        if (!this.application.term_to) return
        if (!this.application.term_from) this.application.term_from = this.application.term_to
        let from = parseInt(this.application.term_from.replaceAll('/', ''))
        let to = parseInt(this.application.term_to.replaceAll('/', ''))
        if (from > to) {
          this.application.term_from = this.application.term_to
        }
        this.$emit('initApply', this.application.term_from, null)
      },
      synchronizeTimeFrom () {
        if (!this.application.term_time_from_hour) return
        if (!this.application.term_time_from_minute) this.application.term_time_from_minute = '00'
        if (!this.application.term_time_to_hour) return
        if (this.application.paid_holiday_type.value === 4) return
        this.application.term_time_to_minute = this.application.term_time_from_minute
        let from = parseInt(this.application.term_time_from_hour, 10) * 60 + parseInt(this.application.term_time_from_minute, 10)
        let to = parseInt(this.application.term_time_to_hour, 10) * 60 + parseInt(this.application.term_time_to_minute, 10)
        if (from > to) {
          this.application.term_time_to_hour = this.application.term_time_from_hour
          this.application.term_time_to_minute = this.application.term_time_from_minute
        }
        this.componentKey += 1
      },
      synchronizeTimeTo () {
        if (!this.application.term_time_to_hour) return
        if (!this.application.term_time_to_minute) this.application.term_time_to_minute = '00'
        if (!this.application.term_time_from_hour) return
        if (this.application.paid_holiday_type.value === 4) return
        this.application.term_time_from_minute = this.application.term_time_to_minute
        let from = parseInt(this.application.term_time_from_hour, 10) * 60 + parseInt(this.application.term_time_from_minute, 10)
        let to = parseInt(this.application.term_time_to_hour, 10) * 60 + parseInt(this.application.term_time_to_minute, 10)
        if (from > to) {
          this.application.term_time_from_hour = this.application.term_time_to_hour
          this.application.term_time_from_minute = this.application.term_time_to_minute
        }
        this.componentKey += 1
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
