<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                <div class="row">
                  <div class="col-md-5">
                    <div style="margin-top: -110px; margin-bottom: 40px; text-align: center;">
                      <img src="static/img/corpus.png" style="width: 100%;">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div style="margin-top: -90px; margin-bottom: 40px; text-align: center; font-size: 16pt;">
                      ⇒
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div style="margin-top: -120px; margin-bottom: 40px; text-align: center;">
                      <img src="static/img/LOGO_A2.png" style="width: 100%;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-md-offset-1">
                <div class="card" data-background="color" data-color="blue">
                  <div class="card-header" style="font-size: 16pt; text-align: center;">
                    ご利用頂いている[Corpus]の登録情報を全て[Artemis]に移行します。
                  </div>
                  <form class="form-horizontal">
                    <div class="card-content text-center">
                      <div class="form-group">
                        <div style="width: 840px; margin: 0 50px; padding: 5px; border: 1px solid black; text-align: left; background-color: white;">
                          Corpusからの変更点は以下のとおりです。
                          <ol style="padding: 0 10px 0 30px;">
                            <li>これまで遅刻/早退として管理されていましたデータを遅刻と早退それぞれで集計できるようになります。</li>
                            <li>コーパスで一元管理されていた学歴と職歴が、それぞれ管理できるようになります。</li>
                            <li>法定労働時間(8時間)内でも残業の管理が行えるようになります。(所定外労働時間対応)</li>
                            <li>一日に複数回の出勤/退勤が行えるようになります。(日勤と夜勤の同一出勤対応)</li>
                            <li>出勤打刻、退勤打刻した場所を管理できるようになります。(本社で出勤打刻し営業所で退勤打刻、等)</li>
                            <li>日々の情報として事由を持つことができるようになります。(育児休暇、夜勤勤務明け休暇、等)</li>
                            <li>ワークフローに「Laubeエンジン」を採用。より細やかな承認ルートが利用できるようになります。</li>
                            <li>事業所単位、部署単位でしか管理できなかった従業員を新たな仕組み「チーム」でも管理できるようになります。</li>
                            <li>有給休暇の管理機能を強化。</li>
                            <li>お弁当管理機能。会社で利用しているお弁当業者の注文状況の管理及び給与明細からの天引き控除がご利用できるようになります。</li>
                            <li>従業員の皆様が給与明細を閲覧できるようになります。</li>
                            <li>顧問契約している社会保険労務士の方に御社のデータ管理を委託できるようになります。</li>
                            <li>スマートフォン打刻、タブレット打刻、静脈認証打刻、FeliCa打刻に加え、指紋認証打刻、Alexa打刻がご利用できます。(順次対応予定)</li>
                            <li>現在の就業規則が労働基準法と照らし合わせた場合、問題がないかを常にチェックでき、御社のホワイト企業度が判定できるようになります。</li>
                            <li>昇給シミュレーション機能の追加。一昨年の残業時間数などを元に従業員ごとの昇給額を決定する際のサポート機能が利用できるようになります。</li>
                            <li>転職予備軍を見つけ出すAI機能搭載。(2022年度中のリリース予定)</li>
                            <li>チャット機能。勤務に関する会話をチャットできる機能が利用できるようになります。(2022年度中のリリース予定)</li>
                            <li>豊富な帳票機能。扱える帳票類はCorpusの10倍になりました。</li>
                            <li>シフト管理機能。アルバイトのシフト管理にAI機能を搭載。シフト管理がより便利になります。(2022年度中のリリース予定)</li>
                            <li>これまではメールでのお問い合わせのみでしたが、チャット問い合わせにも対応しました。</li>
                          </ol>
                        </div>
                      </div>
                      <div style="font-size: 16pt;">
                        Artemisのご利用には最低月額5,000円(税抜)が必要になります。
                      </div>
                      <div><small>※最低ライセンス料は10名&nbsp;&nbsp;1名500円</small></div>
                      <div style="padding: 20px 0;">
                        移行は<span style="color: red;">何度でも</span>やり直しできますので安心してご利用下さい。
                        <br>
                        (注意：再度移行した場合、Artemisのデータは初期化されますのでご注意下さい)
                      </div>
                      しばらくはCorpusとArtemisを併用することを推奨いたします。圧倒的なArtemisの機能をご堪能下さい。
                    </div>
                    <div class="card-footer row" style="padding-top: 20px;">
                      <div class="col-md-12 text-right">
                        <button type="button" class="btn btn-sm btn-default btn-wd btn-warning" @click="cancel">移行を中止します</button>
                        <button class="btn btn-sm btn-default btn-wd btn-warning" @click="agree">同意し移行を開始します</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      cancel () {
        window.location = 'https://s2corpus.ps-corpus.com'
      },
      agree () {
        this.$router.push({name: 'wizardForm'})
      }
    }
  }
</script>
<style scoped>
  .card {
    background: #eee !important;
  }
  .full-page:after {
    background: white !important;
  }
  textarea:read-only {
    background-color: white !important;
    color: black !important;
  }
</style>
