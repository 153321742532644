<template>
  <div v-if="help" :style="divStyle">
    <span v-html="getMovie"></span>
    <span v-html="getText"></span>
  </div>
</template>
<script>
  export default {
    props: {
      help: String,
      placeholders: Array,
      divStyle: String,
      movieKey: {
        type: String,
        require: false,
        'default': undefined
      }
    },
    computed: {
      getMovie () {
        if (!this.movieKey || this.$t('movie.' + this.movieKey) === 'movie.' + this.movieKey) return ''
        return '<a href="' + this.$t('movie.' + this.movieKey) + '" target="_blank" style="position: relative; z-index: 3;"><img src="/static/img/video_icon.png" style="width: 52px;"/></a>'
      },
      getText () {
        const elements = this.help.split('-')
        const url = elements[0]
        let text = ''
        if (elements.length === 1) {
          text = elements[0]
        } else {
          for (let i = 1; i < elements.length; i++) {
            if (i === 1) {
              text = elements[i]
            } else {
              text = text + '-' + elements[i]
            }
          }
          text.replace('{item1}', this.placeholders[0])
        }
        if (text.indexOf('None') > -1) text = ''
        return '<a href="' + url + '" target="_blank" style="color: #317FB8; position: relative; z-index: 3;">' + text + '</a>'
      }
    }
  }
</script>
<style scoped>
</style>
