<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame" :key="componentKey">
          <ValidationObserver ref="observer">
            <div  v-for="(management_data) of detail.management_data_list" :key="management_data.alert_junl_management_control">
              <!-- アラート通知区分 -->
              <big><strong>{{getLabel(getSelectName(management_data.alert_junl_management_control, comboData.alert_junl_management_control_list))}}</strong></big>
              <!-- アラート管理 -->
              <ValidationRadio
                v-model="management_data.alert_management_control"
                :item-label="labels.alert_management_control"
                rules="required"
                :disabled="isReadOnly"
                @change="alertManagementControlChanged"
              />
              <!-- アラート通知方法 -->
              <template v-if="management_data.alert_management_control.value===1 | management_data.alert_management_control.value==='1'">
                <ValidationSelect
                  v-model="management_data.alert_notification_method"
                  item-name="alert_notification_method"
                  :item-label="labels.alert_notification_method"
                  :placeholder="$t('label.select_description')"
                  classes="length14"
                  rules="required"
                  :disabled="isReadOnly"
                  :options="comboData.alert_notification_method_list"
                  @change="alertNotificationMethodChanged"
                />
                <!-- メールアドレス -->
                <template v-if="management_data.alert_notification_method==='1' | management_data.alert_notification_method==='9'">
                  <ValidationText
                    v-model="management_data.mail_address"
                    item-name="mail_address"
                    max-length="255"
                    classes="form-control"
                    rules="required|mailaddress"
                    :disabled="isReadOnly"
                    :item-label="labels.mail_address"
                    :placeholder="$t('placeholder.mail_address')"
                    :help-text="$t('label.max_length')"
                  />
                </template>
              </template>
              <hr width="300" align="left" style="border: 1px solid #ddd;">
            </div>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="!this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import EmployeeAlertManagementMixin from '@/components/Dashboard/Views/Setting/EmployeeAlertManagementMixin'

  export default {
    name: 'EmployeeAlertManagement',
    mixins: [EmployeeAlertManagementMixin]
  }
</script>
<style scoped>
</style>
