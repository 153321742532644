<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page">
        <div class="content">
          <div class="row">
            <div class="col-md-12" style="text-align: center;">
              <h1 v-html="message"></h1>
              <div v-show="loading" class="loader" style="text-align: center;">Loading...</div>
            </div>
            <div class="col-md-12">
              <template v-if="showButton">
                <div style="margin-top: 50px; text-align: center;">
                  <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
                    <span class="btn-label"><i class="ti-close"></i></span>
                    {{$t('button.close')}}
                  </button>
                </div>
              </template>
              <template v-if="showButton2">
                <div style="margin-top: 50px; text-align: center;">
                  <button type="submit" class="btn btn-sm btn-default btn-wd btn-warning" @click="toLogin">ログイン画面へ</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      message: String,
      loading: Boolean,
      showButton: {
        type: Boolean,
        require: false,
        'default': false
      },
      showButton2: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    methods: {
      toLogin () {
        window.location = '/#/admin/login'
      },
      close () {
        window.close()
      }
    }
  }
</script>
<style scoped>
.full-page:after {
  background: white !important;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #999999;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
