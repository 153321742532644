import mixin from '@/lib/mixin'
import BarChart from '@/components/UIComponents/Inputs/BarChart'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'

export default {
  name: 'LegalRuleSurveillanceDetailMixin',
  mixins: [mixin],
  components: {
    BarChart,
    ValidationText,
    ValidationSelect
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      detail: {},
      weekdayChartdata: {
        labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
        datasets: [
          {
            type: 'bar',
            label: '所定労働時間',
            backgroundColor: 'rgba(33, 131, 128, 1.0)',
            order: 2,
            data: []
          },
          {
            type: 'bar',
            label: '所定外労働時間',
            backgroundColor: 'rgba(216, 17, 89, 1.0)',
            order: 3,
            data: []
          },
          {
            type: 'bar',
            label: '法定外労働時間',
            backgroundColor: 'rgba(255, 188, 66, 1.0)',
            order: 4,
            data: []
          },
          {
            type: 'line',
            label: '36協定時間',
            backgroundColor: 'rgba(255, 0, 0, 1.0)',
            borderColor: 'rgba(255, 0, 0, 1.0)',
            order: 1,
            data: [],
            fill: false,
            pointRadius: 3
          }
        ]
      },
      weekdayOptions: {
        responsive: false,
        title: {
          display: true,
          text: '労働状況',
          fontSize: 16
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            id: 'A',
            ticks: {
              callback: function (value, index, ticks) {
                return value + 'h'
              }
            },
            stacked: true
          }]
        }
      },
      weekdaySubChartList: [],
      weekendChartdata: {
        labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
        datasets: [
          {
            type: 'bar',
            label: '所定労働時間',
            backgroundColor: 'rgba(33, 131, 128, 1.0)',
            order: 2,
            data: []
          },
          {
            type: 'bar',
            label: '所定外労働時間',
            backgroundColor: 'rgba(216, 17, 89, 1.0)',
            order: 3,
            data: []
          },
          {
            type: 'bar',
            label: '法定外労働時間',
            backgroundColor: 'rgba(255, 188, 66, 1.0)',
            order: 4,
            data: []
          },
          {
            type: 'bar',
            label: '法定休日労働時間',
            backgroundColor: 'rgba(116, 117, 189, 1.0)',
            order: 5,
            data: []
          },
          {
            type: 'line',
            label: '36協定時間',
            backgroundColor: 'rgba(255, 0, 0, 1.0)',
            borderColor: 'rgba(255, 0, 0, 1.0)',
            order: 1,
            data: [],
            fill: false,
            pointRadius: 3
          }
        ]
      },
      weekendOptions: {
        responsive: false,
        title: {
          display: true,
          text: '労働状況[法定休日も含む]',
          fontSize: 16
        },
        elements: {
          point: {
            radius: 0
          }
        },
        legend: {
          labels: {
            filter: function (item, chart) {
              return item.text !== '36協定時間'
            }
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            id: 'A',
            ticks: {
              callback: function (value, index, ticks) {
                return value + 'h'
              }
            },
            stacked: true
          }]
        }
      },
      weekendSubChartList: [],
      legalRuleSurveillanceMessage: []
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      let firstMonth = parseInt(this.params.target_term.split('@')[0].split('/')[1], 10)
      let months = []
      for (let i = 0; i < 12; i++) {
        let month = ((firstMonth - 1 + i) % 12) + 1
        months.push(month + '月')
      }
      res.api_area.legal_rule_surveillance_list.sort((a, b) => parseInt(a.target_month, 10) - parseInt(b.target_month, 10))
      this.weekdayChartdata.labels = months
      this.weekendChartdata.labels = months
      this.weekdayChartdata = this.getWeekdayChartdata(res)
      this.weekdaySubChartList = this.getWeekdaySubChartdataList(res)
      this.weekendChartdata = this.getWeekendChartdata(res)
      this.weekendSubChartList = this.getWeekendSubChartdataList(res)
      res.api_area.legal_rule_surveillance_message.sort((a, b) => parseInt(a.target_date.replace('/', ''), 10) - parseInt(b.target_date.replace('/', ''), 10))
      this.legalRuleSurveillanceMessage = res.api_area.legal_rule_surveillance_message
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    individualRouteCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    getLegalRuleSurveilanceList (res) {
      const legalRuleSurveilanceList = []
      for (const label of this.weekendChartdata.labels) {
        const labelMonth = parseInt(label.replace('月', ''), 10)
        let legalRuleSurveilance = {}
        for (const row of res.api_area.legal_rule_surveillance_list) {
          const targetMonth = parseInt(row.target_month.substring(4), 10)
          if (labelMonth === targetMonth) {
            legalRuleSurveilance = row
            break
          }
        }
        legalRuleSurveilanceList.push(legalRuleSurveilance)
      }
      return legalRuleSurveilanceList
    },
    getWeekendChartdata (res) {
      const chartdata = {}
      chartdata.labels = this.weekendChartdata.labels
      chartdata.datasets = this.weekendChartdata.datasets
      for (const dataset of chartdata.datasets) {
        dataset.data = []
      }
      for (const row of this.getLegalRuleSurveilanceList(res)) {
        chartdata.datasets[0].data.push(!row.job_total_minuts ? null : this.round(row.job_total_minuts / 60, 10))
        chartdata.datasets[1].data.push(!row.job_overwork_minutes ? null : this.round(row.job_overwork_minutes / 60, 10))
        chartdata.datasets[2].data.push(!row.legal_overwork_minutes ? null : this.round(row.legal_overwork_minutes / 60, 10))
        chartdata.datasets[3].data.push(!row.legal_holiday_overwork_minutes ? null : this.round(row.legal_holiday_overwork_minutes / 60, 10))
      }
      for (let i = 0; i < res.api_area.limit_legal_one_month_minutes_with_holiday.length; i++) {
        chartdata.datasets[4].data.push(this.round((res.api_area.limit_legal_one_month_minutes_with_holiday[i]) / 60, 10))
      }
      return chartdata
    },
    getWeekendSubChartdataList (res) {
      const subChartdataList = [{}]
      for (let i = 0; i < subChartdataList.length; i++) {
        const subChartdata = {}
        subChartdata.labels = this.weekendChartdata.labels
        const dataset1 = {...this.weekendChartdata.datasets[2]}
        const dataset2 = {...this.weekendChartdata.datasets[1]}
        dataset2.label = '回数'
        dataset2.yAxisID = 'A'
        dataset2.data = []
        dataset2.type = 'bar'
        dataset2.fill = false
        dataset2.backgroundColor = dataset1.backgroundColor
        dataset2.borderColor = dataset1.backgroundColor
        dataset2.borderWidth = 3
        subChartdata.datasets = [dataset2]
        subChartdataList[i].chartdata = subChartdata
        const options = {
          responsive: false,
          title: {
            display: true,
            text: '休日出勤'
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            yAxes: [
              {
                id: 'A',
                position: 'left',
                ticks: {
                  max: 30,
                  maxTicksLimit: 5,
                  callback: function (value, index, ticks) {
                    return value + '日'
                  }
                }
              }
            ]
          }
        }
        subChartdataList[i].options = options
      }
      for (const row of this.getLegalRuleSurveilanceList(res)) {
        for (let i = 0; i < subChartdataList.length; i++) {
          subChartdataList[i].chartdata.datasets[0].data.push(!row.legal_holiday_overwork_days ? null : row.legal_holiday_overwork_days)
        }
      }
      return subChartdataList
    },
    getWeekdayChartdata (res) {
      const chartdata = {}
      chartdata.labels = this.weekdayChartdata.labels
      chartdata.datasets = this.weekdayChartdata.datasets
      for (const dataset of chartdata.datasets) {
        dataset.data = []
      }
      for (const row of this.getLegalRuleSurveilanceList(res)) {
        chartdata.datasets[0].data.push(!row.job_total_minuts ? null : this.round(row.job_total_minuts / 60, 10))
        chartdata.datasets[1].data.push(!row.job_overwork_minutes ? null : this.round(row.job_overwork_minutes / 60, 10))
        chartdata.datasets[2].data.push(!row.legal_overwork_minutes ? null : this.round(row.legal_overwork_minutes / 60, 10))
      }
      for (let i = 0; i < res.api_area.limit_legal_one_month_minutes_without_holiday.length; i++) {
        chartdata.datasets[3].data.push(this.round((res.api_area.limit_legal_one_month_minutes_without_holiday[i]) / 60, 10))
      }
      return chartdata
    },
    getWeekdaySubChartdataList (res) {
      const subChartdataList = [{}, {}, {}, {}, {}, {}, {}, {}]
      for (let i = 0; i < subChartdataList.length; i++) {
        const subChartdata = {}
        subChartdata.labels = this.weekdayChartdata.labels
        const dataset1 = {...this.weekdayChartdata.datasets[2]}
        const dataset2 = {...this.weekdayChartdata.datasets[1]}
        const dataset3 = {...this.weekdayChartdata.datasets[0]}
        dataset2.type = 'bar'
        dataset2.fill = false
        if (i === 5 || i === 6 || i === 7) {
          dataset2.backgroundColor = dataset1.backgroundColor
          dataset2.borderColor = dataset1.backgroundColor
        } else {
          dataset2.borderColor = dataset2.backgroundColor
        }
        dataset2.borderWidth = 3
        let titleText = ''
        switch (i) {
          case 0:
            dataset2.label = '回数'
            titleText = '遅刻'
            break
          case 1:
            dataset2.label = '回数'
            titleText = '早退'
            break
          case 2:
            dataset2.label = '日数'
            titleText = '欠勤'
            break
          case 3:
            dataset2.label = '回数'
            titleText = '駆け込み出勤'
            break
          case 4:
            dataset2.label = '回数'
            titleText = '定時ダッシュ'
            break
          case 5:
            dataset2.label = '回数'
            titleText = '残業'
            break
          case 6:
            dataset2.label = '日数'
            titleText = '有給休暇'
            break
          case 7:
            dataset2.label = '日数'
            titleText = '休日出勤'
            break
        }
        dataset1.yAxisID = 'A'
        dataset2.yAxisID = 'A'
        dataset3.yAxisID = 'A'
        dataset1.data = []
        dataset2.data = []
        dataset3.data = []
        subChartdata.datasets = [dataset2]
        subChartdataList[i].chartdata = subChartdata
        let unit = '回'
        if (i === 2 || i === 6 || i === 7) {
          unit = '日'
        }
        const options = {
          responsive: false,
          title: {
            display: true,
            text: titleText
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                maxTicksLimit: 6
              }
            }],
            yAxes: [
              {
                id: 'A',
                position: 'left',
                ticks: {
                  beginAtZero: true,
                  max: 30,
                  maxTicksLimit: 5,
                  callback: function (value, index, ticks) {
                    return value + unit
                  }
                }
              }
            ]
          }
        }
        subChartdataList[i].options = options
      }
      for (const row of this.getLegalRuleSurveilanceList(res)) {
        for (let i = 0; i < subChartdataList.length; i++) {
          switch (i) {
            case 0:
              subChartdataList[i].chartdata.datasets[0].data.push(row.late_days)
              break
            case 1:
              subChartdataList[i].chartdata.datasets[0].data.push(row.early_departure_days)
              break
            case 2:
              subChartdataList[i].chartdata.datasets[0].data.push(row.lack_days)
              break
            case 3:
              subChartdataList[i].chartdata.datasets[0].data.push(row.rush_to_work_days)
              break
            case 4:
              subChartdataList[i].chartdata.datasets[0].data.push(row.dash_on_job_end_days)
              break
            case 5:
              subChartdataList[i].chartdata.datasets[0].data.push(row.overwork_days)
              break
            case 6:
              subChartdataList[i].chartdata.datasets[0].data.push(row.paid_holiday_days)
              break
            case 7:
              subChartdataList[i].chartdata.datasets[0].data.push(row.legal_holiday_overwork_days)
          }
        }
      }
      return subChartdataList
    },
    init () {
      let data = {
        screen_code: '09-011',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.row.employee_code,
          business_type: this.params.row.business_type,
          target_month_from: this.params.target_term.split('@')[0],
          target_month_to: this.params.target_term.split('@')[1]
        }
      }
      this.send('/init/legal_rule_violator', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
    }
  },
  created () {
    this.init()
  }
}
