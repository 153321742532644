<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.supplier_name"
              item-name="supplier_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.supplier_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.supplier_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationPostalCode
              v-model="detail.post_code"
              item-name="post_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.post_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.post_code')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :button-label="$t('button.postal_code_search_simple')"
              @search="postalCodeSearch"
              @pin="putPin"
            />
            <ValidationSearchSelect
              v-model="detail.prefectures"
              item-name="prefectures"
              classes="length7"
              :item-label="labels.prefectures"
              :options="comboData.prefectures_list"
              :disabled="isReadOnly"
              @change="prefecturesChanged"
            />
            <ValidationSearchSelect
              v-model="detail.city"
              item-name="city"
              classes="length50"
              rules="required"
              :clearable="false"
              :item-label="labels.city"
              :options="comboData.city_list"
              :disabled="isReadOnly"
              @change="cityChanged"
            />
            <ValidationText
              v-model="detail.town"
              item-name="town"
              max-length="50"
              classes="form-control length50"
              :item-label="labels.town"
              :placeholder="isReadOnly ? '' : $t('placeholder.town')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.building"
              item-name="building"
              max-length="30"
              classes="form-control length30"
              :item-label="labels.building"
              :placeholder="isReadOnly ? '' : $t('placeholder.building')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.home_page"
              item-name="home_page"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.home_page"
              :placeholder="isReadOnly ? '' : $t('placeholder.home_page')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.tel"
              item-name="tel"
              max-length="20"
              classes="form-control length20"
              rules="required"
              :item-label="labels.tel"
              :placeholder="isReadOnly ? '' : $t('placeholder.tel')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.fax"
              item-name="fax"
              max-length="20"
              classes="form-control length20"
              :item-label="labels.fax"
              :placeholder="isReadOnly ? '' : $t('placeholder.fax')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.mail_address"
              item-name="mail_address"
              max-length="255"
              classes="form-control length255"
              rules="mailaddress"
              :item-label="labels.mail_address"
              :placeholder="isReadOnly ? '' : $t('placeholder.mail_address')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.date_of_collection"
              item-name="date_of_collection"
              max-length="2"
              classes="form-control numLength2 text-right"
              rules="required|min_value: 1|max_value: 31"
              :item-label="labels.date_of_collection"
              :placeholder="isReadOnly ? '' : $t('placeholder.date_of_collection')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.collection_day"
              item-name="collection_day"
              max-length="2"
              classes="form-control numLength2 text-right"
              rules="required|min_value: 1|max_value: 31"
              :item-label="labels.collection_day"
              :placeholder="isReadOnly ? '' : $t('placeholder.collection_day')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationTime
              v-model="detail.entry_start_time"
              item-name="entry_start_time"
              classes="select-success length5"
              :item-label="labels.entry_start_time"
              :disabled="isReadOnly"
              :labels="labels"
            />
            <ValidationTime
              v-model="detail.entry_end_time"
              item-name="entry_end_time"
              classes="select-success length5"
              :item-label="labels.entry_end_time"
              :disabled="isReadOnly"
              :labels="labels"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import LunchSupplierDetailMixin from '@/components/Dashboard/Views/Generated/LunchSupplierDetailMixin'

  export default {
    name: 'LunchSupplierDetail',
    data () {
      return {
        lunchSupplier: {
          prefectures_before: undefined
        }
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.prefectures_list = this.comboData.state_list
        this.comboData.city_list = []
        this.comboData.mapping_list = res.common_area.combo_data.state_municipality_list.state_municipality_mapping_list
        this.detail.prefectures = this.detail.state_code
        if (this.detail.prefectures) this.prefecturesChanged()
        this.detail.city = this.detail.municipality_code ? this.detail.state_code + '_' + this.detail.municipality_code : this.detail.municipality_code
        this.labels.latlng = this.labels.add_place
        this.labels.prefectures = this.labels.state_code_ryaku
        this.labels.city = this.labels.municipality_code_ryaku
        this.setGeocoder()
      },
      prefecturesChanged () {
        if (this.lunchSupplier.prefectures_before) {
          this.detail.city = ''
        }
        if (this.detail.prefectures) {
          this.lunchSupplier.prefectures_before = this.detail.prefectures
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.detail.prefectures]) {
            for (const prefecture of this.comboData.prefectures_list) {
              if (prefecture.label === this.detail.prefectures) {
                this.detail.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.detail.prefectures]) {
            for (const code of this.comboData.mapping_list[this.detail.prefectures]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.city_list = []
        }
      },
      postalCodeSearch (postalCode) {
        const self = this
        this.geocoder2.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
          const myLatlng = results[0].geometry.location
          self.geocoder2.geocode({location: myLatlng}, function (results, status) {
            if (status === 'OK') {
              for (const result of results) {
                if (result.types) {
                  let isFound = false
                  for (const type of result.types) {
                    if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                      const componentsLength = result.address_components.length
                      self.detail.post_code = result.address_components[componentsLength - 1].long_name
                      for (const prefecture of self.comboData.prefectures_list) {
                        if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                          self.detail.prefectures = prefecture.value
                          break
                        }
                      }
                      if (self.detail.prefectures) {
                        self.prefecturesChanged()
                      }
                      for (const municipality of self.comboData.municipality_list) {
                        if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                          self.detail.city = municipality.value
                          break
                        }
                      }
                      self.detail.town = ''
                      for (var i = componentsLength - 5; i >= 0; i--) {
                        self.detail.town = self.detail.town + result.address_components[i].long_name
                      }
                      self.componentKey += 1
                      isFound = true
                      break
                    }
                  }
                  if (isFound) break
                }
              }
            }
          })
        })
      },
      convertDetail (detail) {
        if (this.mode === this.modes.add) {
          delete detail.supplier_code
          delete detail.update_count
        }
        if (this.mode !== this.modes.delete) {
          detail.state_code = detail.prefectures
          delete detail.prefectures
          delete detail.state_name
          detail.municipality_code = detail.city ? detail.city.split('_')[1] : detail.city
          delete detail.city
          delete detail.municipality_name
          detail.date_of_collection = parseInt(detail.date_of_collection, 10)
          detail.collection_day = parseInt(detail.collection_day, 10)
        } else {
          detail.supplier_name = this.detail.supplier_name
        }
        return detail
      }
    },
    mixins: [LunchSupplierDetailMixin]
  }
</script>
<style scoped>
</style>
