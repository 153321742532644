<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div>
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
    </div>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <el-select
            class="select-success length2"
            v-model="pagination.perPage">
            <el-option
              class="select-success"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div style="margin-top: 10px;">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="pagedData"
                      :key="componentKey"
                      :style="tableStyle"
                      :max-height="600"
                      ref="table">
              <!-- 労働日 -->
              <el-table-column v-if="showTargetDate" width="150" :label="labels.week_day_ryaku === undefined ? labels.weekDay_ryaku === undefined ? '' : labels.weekDay_ryaku : labels.week_day_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium; font-weight: bold;">
                    {{props.row.target_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{getLabel(getSelectName(props.row.work_schedule_code, comboData.work_schedule_list))}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 区分 -->
              <el-table-column :width="getColumnWidth(tableData, $t('label.stamping_type'))" :label="$t('label.stamping_type')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.stamping_type, comboData.stamping_type_list))}}
                  </div>
                </template>
              </el-table-column>
              <!-- 打刻時間 -->
              <el-table-column width="150" :label="$t('label.typing_stamping_time')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="'text-align: center; font-size: medium; font-weight: bold; margin: 5px 0;' + (props.row.color===3 ? ' color: red;' : '')" :title="props.row.error_reason">
                    {{props.row.typing_stamping_time}}
                  </div>
                </template>
              </el-table-column>
              <!-- ステータス -->
              <el-table-column :width="getColumnWidth(tableData, $t('label.status_kana'))" :label="$t('label.status_kana')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="props.row.is_success ? 'text-align: center; font-size: medium; font-weight: bold;' : 'text-align: center; font-size: medium; font-weight: bold; color: red'">
                    {{props.row.is_success ? $t('label.success') : $t('label.failure')}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import TimeCardHistoryMixin from '@/components/Dashboard/Views/Working/TimeCardHistoryMixin'

export default {
  mixins: [TimeCardHistoryMixin]
}
</script>
<style scoped>
  a {
    white-space: normal;
  }
</style>
