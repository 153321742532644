<template>
  <div>
    <div v-if="showCheckAll">
      <input type="checkbox" v-model="checkAll" class="gray" id="checkAll" @change="checkAllWeekday()"/>
      <label for="checkAll">{{$t('label.check_all')}}</label>
    </div>
    <template v-if="obj && obj.items">
      <template v-for="(item, index) in obj.items">
        <template v-if="index !== 0 && index % columns === 0">
          <br v-bind:key="item.id">
        </template>
        <label :for="itemLabel ? itemLabel + item.id : item.id" v-bind:key="item.id">
          <input 
            type="checkbox" 
            v-bind:key="item.id"
            v-model="item.value" 
            :id="itemLabel ? itemLabel + item.id : item.id"
            :class="obj.color" 
            :title="obj.errMsg"
            :disabled="obj.disabled || disabled"
            @change="$emit('change', obj)">
          {{item.label}}
        </label>
      </template>
    </template>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Object,
      disabled: Boolean,
      columns: Number,
      itemLabel: String,
      showCheckAll: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        obj: {},
        checkAll: false
      }
    },
    methods: {
      checkAllWeekday () {
        for (const item of this.obj.items) {
          item.value = this.checkAll
        }
      }
    },
    watch: {
      value () {
        this.obj = this.value
      }
    },
    mounted () {
      this.obj = this.value
    }
  }
</script>

<style scoped>
</style>