import okubo from './TranslationsOkubo'
import takeuchi from './TranslationsTakeuchi'
import miki from './TranslationsMiki'
import googleapis from './TranslationsGoogleApis'

export default {
  en: {
    message: {
      ...okubo.en.message,
      ...takeuchi.en.message,
      ...miki.en.message,
      ...googleapis.en.message,
      boss_pk_check: 'Combination of department and request form name cannot overlap.',
      both_field_required: 'Either {_field1_} or {_field2_} is mandatory. Please enter. ',
      business_type_pk_check: 'Job type cannot overlap.',
      cancellation_complete: 'Artemis was canceled. Thank you for using.',
      cancellation_description: 'This operation cannot be undone. Please take care about the operation. ',
      closing_date_required: 'Enter all the closing dates. ',
      closing_date_continuous: 'Provide consecutive dates for all the closing dates. ',
      closing_message: 'Are you sure you want to close the attendance record.',
      communication_error: 'Communication with the server failed. Please check the internet connection. ',
      company_delete_complete: 'You have deleted {companyName}.',
      company_regist_complete_title: 'Company registration process started!',
      company_regist_complete_description: 'An email notification will be sent to you once registration has finished. Please wait for a while. ',
      confirm_to_cancellation: 'Artemis will be canceled. This operation cannot be undone. Do you want to continue?',
      confirm_to_delete: 'Are you sure you want to delete it?',
      confirm_to_delete_company: '{companyName} will be deleted. This operation cannot be undone. Do you want to continue?',
      confirm_to_order: 'Are you sure you want to place an order?',
      confirm_to_select_all_authority: '[All] is specified to the authority scope. In this case, all employees in this department can run a search. Are you sure?',
      contract_already_end: 'The service has been canceled. Please contact the administrator.',
      contract_not_enabled: 'The service contract is invalid. Please contact the administrator.',
      day_not_deletable_at: 'Unable to delete since there is no line for the same date on {date}.',
      decimal_field_required: '{_field_} must be provided in numeric form.',
      decimal_field_higher: '{_field_} must be a numeric value more than {_value_}.',
      decimal_required: '{_field_} must be provided in numeric form.',
      field_required: '{_field_} is mandatory. Please fill in. ',
      field_required_simple: 'This is a mandatory field.',
      field_work_time: 'Provide time(XX:XX).',
      ground_not_deletable_at: 'A reason that won\'t allow for deletion is selected for {date}.',
      holiday_info_not_searched: 'Click the [Search] button and set holiday information. ',
      inconsistent_time: 'For the clock-out time, provide a later time than the clock-in time.   ',
      inconsistent_time_at: 'For the clock-out time on {date}, provider a later time than the clock-in time.   ',
      incorrect_year_month_order: 'For the end month, select the same month as the start month or later month than the start month. ',
      input_error: 'There is an error in the input value. ',
      invalid_attendance_record: 'The request failed to complete due to an error in the attendance record. Please check the attendance record for more information.',
      invalid_end_time: 'Clock-out time is invalid. ',
      invalid_end_time_at: 'Clock-out time on {date} is invalid. ',
      invalid_holiday_work_hours: 'Working hours on holiday are incorrect. ',
      invalid_late_night_overtime: 'Late night work hours are incorrect.',
      invalid_overtime: 'Overtime hours are incorrect. ',
      invalid_start_time: 'Clock-in time is invalid. ',
      invalid_start_time_at: 'Clock-in time on {date} is invalid. ',
      is_approval_route_required: '{item4} is missing. Please fill in.',
      is_authority_required: 'There is a line missing {item3}. Please fill in. ',
      is_confirm_last: 'Configure confirmation after completing a review. ',
      is_different_integrated_group_selected: 'Same {item} are selected. ',
      is_division_group_required: 'Register more than two items in {item}.',
      is_duplicate_group_code_selected: 'The same department is selected.',
      is_entered_new_check_password: 'No entry is made in the new password (confirmation) field.',
      is_entered_new_password: 'No entry is made in the new password field.',
      is_entered_old_password: 'No entry is made in the current password field.',
      is_end_time: 'Provide end time in hh:mm format. ',
      is_integrated_group_required: 'Select more than two items for {item}.',
      is_item_number_for_row: '{item} in Line {row} must be provided in numeric form.',
      is_item_required_for_row: '{item} in Line {row} is mandatory.',
      is_mismatch_new_password: 'The new password and new password (confirmation) do not match.',
      is_paid_holiday_management_required: 'There is a line where one of {item1}, {item2}, {item3}, {item4} and {item5} is missing. Please fill in all.  ',
      is_paid_holiday_management_numeric: 'There is a line where either of {item2} or {item5} is not a numeric value. Please provide a numeric value. ',
      is_paid_holiday_payment_required: 'There is a line where {_field_} is missing. Please fill in.',
      is_paid_holiday_payment_required_working_days: 'Provide at least one day of paid leaves granted for every consecutive month of service',
      is_paid_holiday_payment_numeric: 'There is a line where a non-numeric value is provided. ',
      is_paid_holiday_payment_unique: 'The number of consecutive months of service overlap. Provide a different number of months.',
      is_role_or_approver_required: 'There is a line where either {item1} or {item2} is missing. Please fill in.',
      is_select_default_working_schedule_required: 'Select one item for {item}.',
      is_start_time: 'Provide the start time in hh:mm format. ',
      is_table_required: 'Register at least one item in {item}.',
      is_unusable_string_new_password: 'The new password contains invalid characters.',
      is_unusable_string_password: 'Invalid characters are used.',
      is_weakly_new_password: 'The new password strength is lower than the standard.',
      is_weakly_password: 'The strength is lower than the standard.',
      is_word_count_new_password: 'New password must be at least twelve characters long.',
      is_word_count_password: 'Set more than twelve characters. ',
      is_working_schedule_required: 'Select at least one item for {item}.',
      job_or_legal: 'Same days of week cannot be set to regular holidays and statutory holidays.',
      login_input_error: 'Provide all of Company code, employee number, and password. ',
      max_value: 'Provide less than or equal to {max} in numeric form.',
      min: 'Requires at least {length} characters.',
      min_value: 'Provide more than or equal to {min} in numeric form.',
      month_day_duplicated: 'Integrated payment date overlaps. Select a different date. ',
      names_required: 'Register at least one item in {item}. ',
      name_required: 'Fill out all {item}.',
      not_authorized: 'Authority not registered. Please contact the administrator.',
      no_holiday: 'Public holiday Reflect is not available since public holiday data for year {_item_} has not been registered.',
      num_value: '{item} must be provided in numeric form.',
      password_pattern: 'Email address format is incorrect. ',
      password_text: 'It must be between 12-30 characters in length with uppercase and lowercase letters and numbers.',
      payment_info_not_registered: 'Payment information has not been registered.',
      postalCode: 'Provide in postal code format. (e.g. 100-0001)',
      release_message: 'Do you want to cancel the closing of the attendance record?',
      report_confirmation: 'PDF creation started. Do you want to move to the form list?',
      row_start_time_error: 'The start time on line {row} is invalid. ',
      row_end_time_error: 'The end time on line {row} is invalid. ',
      salaryItem_detail_calc_notedit: 'Provide at least one calculation information.',
      salaryItem_detail_payment_notedit: 'Provide at least one paid item.',
      salaryItem_detail_system_notedit: 'Cannot edit system items.',
      system_error_title: 'System Error',
      tel: 'Provide in phone(fax) number format. (e.g. 03-1000-0001)',
      unexpected_error: 'Unexpected error occurred. Please try again after a while.',
      unselectable_authority: 'Unable to select Review after confirmation. ',
      upper_group_error: 'Settings in line with the upper department are only selectable when an upper department is set. ',
      // 以下未翻訳
      confirm_to_delete_attendance: 'Whole month data will be deleted. Continue?',
      limit_legal_one_weeks_minutes_required: 'Limit legal one week hour is required.',
      transformation_term_from_required: 'Transformation term from is required.'
    },
    label: {
      ...okubo.en.label,
      ...takeuchi.en.label,
      ...miki.en.label,
      ...googleapis.en.label,
      about_artemis: 'What is Artemis?',
      account_classification: 'By account type',
      account_number: 'Account number',
      achievement: 'Achievement',
      active_employee_count: 'Number of people belonging',
      actual: 'Actual record',
      add_national_holiday: 'Reflect public holidays in the calendar',
      add_pin: 'Put a pin on the location you want to register. ',
      administrator: 'Administrator',
      admin_login: 'Here for Administrator log-in',
      age: 'Age',
      aggregation_category: 'Summary section',
      agreement_parties_employee_name: 'Employee name of the party to the agreement',
      agreement_parties_job_title_name: 'Job name of the party to the agreement',
      allocation: 'Allocation',
      all_employee_application_control: 'Reflect at once to the request information',
      already: 'Done',
      amount_from: 'yen or more',
      amount_of_money: 'Amount',
      amount_to: 'yen or less',
      annual_holidays: 'Number of annual holidays',
      annual_result: '[Annual actual record]',
      apl: 'April',
      application_type: 'Request category',
      apply_employee: 'Employees of the requester',
      apply_person: 'Requester',
      applicant_employee: 'Employees of the requester',
      application: 'Request form',
      application_classification: 'Request classification',
      application_inf: 'Request information',
      approval_attendance: 'Attendance approval',
      approval_route: 'Approval route',
      approval_salary: 'Salary approval',
      approverl_company: 'Company of the approver',
      approverl_employee: 'Employees of the approver',
      approverl_employee_name: 'Employee names of the approver',
      approverl_group: 'Department of the approver',
      approverl_role_code: 'Role code of the approver',
      approved: 'Approved',
      approver: 'Approver',
      app_store_logo_path: '/static/img/app_store_en.png',
      apr: 'April',
      artemis_login: 'Here for login to Artemis',
      artemis_system_admin: 'Artemis system administrator',
      assign_to_employee: 'Allocate employees',
      attached_file: 'Attached by',
      attached_employee: 'Attachment',
      attendance: 'Attendance',
      attendance_record: 'Attendance record',
      aug: 'August',
      auto_approverl_flag: 'Automatically approved when the same {item} appears twice in the approval route.',
      available: 'Yes',
      average: 'Average',
      average_age: 'Average age',
      average_enrolled_months: 'Average number of months people belonging',
      average_overtime_hours: 'Average overtime hours',
      average_paid_holidays: 'Average of annual paid leave acquisitions',
      average_wage: 'Average wage',
      balance: 'Remaining',
      bank: 'Bank',
      bank_code: 'Bank code',
      bank_search_description: 'Click the name of the financial institution. If not listed, please select the first letter of the bank name and execute a search. ',
      bank_transfer: 'Bank transfer',
      bank_transfer_list: 'Bank transfer list',
      base_date_setting: 'Set base date',
      basic_information: 'Basic information',
      boss: 'Supervisor',
      boss_company: 'Company of the supervisor',
      boss_employee: 'Employees of the supervisor',
      boss_group: 'Department of the supervisor',
      branch: 'Branch',
      branch_code: 'Branch code',
      branch_search_description: 'Select the first letter of the branch name and execute a search. ',
      break_add: 'Register break',
      break_time: 'Break time',
      business_description: 'Required for employees\' 36 Agreement management',
      business_info: 'Job type', // 名称変更可能な項目のため、会社登録以外での使用不可
      career_inf: 'Career information',
      cash: 'Cash',
      catch_flg: 'Received',
      change_language: 'Change language',
      change_password: 'Chagne password',
      change_password_annotation1: '・Click the [Change] button to complete the change procedure. ',
      change_password_annotation2: '・The password can contain uppercase lowercase letters (A-Z, a-z), numbers, underscores(_), at sign ',
      change_password_annotation3: '  (@), exclamation mark(!), and hash (#). It must be at least eight characters long.',
      closing: 'Closing day',
      closing_date_unit: 'at the closing day',
      closing_term1: 'Closing days (January to June)',
      closing_term2: 'Closing days (July to December)',
      comment: 'Comments',
      common_route: 'Indirect department',
      commute_target_date: 'Payment month',
      commuting_costs: 'Commutation cost',
      company_address_description: 'Provide the company address.',
      company_amount: 'At company expense',
      company_code: 'Company code',
      company_description: 'Provide the basic information.',
      company_info: 'Company information',
      company_logo: 'Company logo',
      company_regist: 'Company registration',
      company_regist_description: 'Register information on the company and payment information.',
      confirm_company_regist: 'Company registration confirmation',
      confirm_company_regist_description1: 'Company registration preparation completed.',
      confirm_company_regist_description2: 'Are you sure you want to register?',
      confirm_employee_delete: 'Employee deletion confirmation',
      confirm_employee_delete_description1: 'Employee deletion preparation completed.',
      confirm_employee_delete_description2: 'Are you sure you want to delete?',
      confirm_employee_regist: 'Employee registration confirmation',
      confirm_employee_regist_description1: 'Employee registration preparation completed.',
      confirm_employee_regist_description2: 'Are you sure you want to register?',
      confirm_employee_update: 'Employee update confirmation',
      confirm_employee_update_description1: 'Employee update preparation completed.',
      confirm_employee_update_description2: 'Are you sure you want to update?',
      copy_role_code: 'New {item2} will be created based on the following {item1}.',
      corporate_number_page: 'National Tax Agency   Corporate Number Publication Site',
      costs_from_the_next_month: 'Cost for the next month and after',
      count: 'times',
      created_salary: 'Descriptions created',
      credit: 'Credit',
      cumulative_number_of_special_provisions: 'Number of accumulated times when the special clause is applied',
      cumulative_time_of_special_provisions: 'Accumulated hours of the special clause applied',
      currency: 'Yen',
      dashboard: 'Dashboard',
      data_none: 'No data',
      data_type: 'Category',
      date: 'Date',
      day: 'Day',
      days_5: '5 days',
      days_4: '4 days',
      days_3: '3 days',
      days_2: '2 days',
      days_1: '1 day',
      debit: 'Debit',
      dec: 'December',
      deduction: 'Deduction',
      deduction_salary_item_code: 'Deduction amount',
      default: 'Default',
      default_work_schedule: 'Default shift pattern',
      delete: 'Delete',
      delete_national_holiday: 'Delete public holidays from the calendar',
      dependent: 'Dependent',
      deputy_apply_employee: 'Employees of the proxy-requester',
      deputy_approver_info: 'Information on the proxy-requester',
      deputy_approver_info_ryaku: 'D',
      deputy_target_person: 'Object person (requester)',
      description_of_payslip_calculation: 'Amount will be recalculated based on the above attendance data.',
      difference: 'Difference',
      display_date: 'Announcement registration data',
      display_range: 'Display {from} to {to} out of {total} results.',
      dividing_group: 'Source{item}',
      division_group: 'Target{item}',
      early_departure_application: 'Early leave request',
      elapsed_date: 'Stagnation date',
      employee_amount: 'Employee number',
      employee_code: 'Employee code',
      employee_count: 'Number of employees',
      employee_description: '*Register information of the person who proceeds with the setup of Artemis after starting to use.',
      employee_group_term_from: 'Belonging period (start)',
      employee_group_term_to: 'Belonging period (end)',
      employee_info: 'Employee\'s information',
      employee_info_ryaku: 'E',
      employee_lunch: 'Bento order placement',
      employee_team_term_from: 'Belonging period (start)',
      employee_team_term_to: 'Belonging period (end)',
      employment_insurance_rate_link: 'About employment insurance premium rates (link to the MHLW)',
      employment_insurance_subscription: 'Presence of employment insurance coverage',
      end: 'End',
      end_time: 'End time',
      enrolled_count: 'Number of employees',
      existing_closing_name: '{item} (when adding the target year to the existing {item} ) ',
      existing_employee_between: ' Employees belonging to xx',
      face_image: 'Specify an image file if you want to show the employee\'s face photo.',
      favorite: 'Favorite',
      feb: 'February',
      felica_download: 'Here for the FeliCa app download',
      filter_by: 'Search with {item}',
      first: 'First',
      first_month_cost: 'First month cost',
      fiscal_year: 'FY',
      fixed_value: 'Fixed cost',
      friday: 'Friday',
      friday_ryaku: 'Fri',
      google_play_logo_path: '/static/img/google_play_en.png',
      grade: 'Grade',
      group: 'Department', // ToDo サーバーから取得できるようになったら削除する。
      group_by_belongs: 'By department',
      group_by_day: 'By day',
      group_by_employee: 'By employee',
      group_by_month: 'By month',
      group_by_week: 'By week',
      group_by_year: 'By year',
      group_inf: 'Department information',
      half_amount: 'Half amount',
      health_insurance_salary_total_rate: 'Total of health insurance premium rate',
      help: 'Help',
      hire: 'Hire fiscal year',
      hire_employee_count: 'Number of new employees',
      hire_year: 'Show in the order of hire fiscal year',
      holiday_info: 'Holiday information',
      hour: 'Hour',
      hourly_wage: 'Hourly wage',
      hourly_wage_overtime_premium: 'Hourly wage[extra wage for non-statutory working]',
      hourly_wage_legal_holiday_premium: 'Hourly wage[extra wage for working on statutory holiday]',
      hourly_wage_midnight_premium: 'Hourly wage[extra wage for working late night]',
      hours: 'Hour',
      icon: 'Icon',
      image_file: 'Image file',
      immediate_transmission: 'Send immediately',
      imprint_correction_time: 'Clock-in/-out corrected time',
      imprint_correction_type: 'Clock-in/-out correction category',
      including_read: 'Including read',
      increase_amount: 'Increase',
      industry_description: 'Select the business type',
      insurance_nursing_choice: 'When the insurance premium covered by the insured has any fractions of less than one yen',
      insurance_situation_description: 'Provide the current insurance coverage status.',
      integrated_group: 'Integration source{item}',
      in_kind: 'In kind',
      jan: 'January',
      jly: 'July',
      job_end: 'Leave',
      job_start: 'Arrival',
      journal_list: 'Journal data list',
      jun: 'June',
      jul: 'July',
      label_approverl_data: 'My proxy-approver list',
      label_delete: 'Delete',
      label_deputy_approverl_data: 'A list of those who appoint me as their proxy-approver',
      label_digestion_days: 'Use rate',
      label_estimated_months_of_service: 'Next expected months of service',
      label_grant_days: 'Number of days granted next time',
      label_header_target_date: 'Target date',
      label_less_than_a_day: 'Less than a day',
      label_new_check_password: 'New password (confirmation)',
      label_new_password: 'Current password',
      label_old_password: 'New password',
      label_paid_holiday: 'Regular annual leave',
      label_paid_holidays_of_use_this_year: 'Number of days of paid leave used this year',
      label_paid_reference_date: 'Next paid leave granting date',
      label_special_paid_holiday: 'Scheduled annual leave',
      label_term_from: 'Acquisition start date',
      label_term_to: 'Acquisition end date',
      label_term_time_from: 'Acquisition start time',
      label_term_time_to: 'Acquisition end time',
      labor_insurance_number_text: 'Provide the labor insurance number to be output to the 36 Agreement report',
      late_application: 'Request for late arrival',
      late_early_departure_type: 'Request category',
      latlng: 'Location',
      layout: 'layout',
      ledger: 'List',
      legal_holiday_job: 'Non-statutory working ',
      legal_holiday_overwork: 'Work start/ end times allowed on statutory holidays',
      legal_overwork: 'Work on statutory holidays',
      license_count: 'Number of license',
      limit_job_one_day_minutes: 'Number of hours exceeding regular working hours (optional)',
      limit_job_one_month_minutes: 'Number of hours exceeding regular working hours (optional)',
      limit_job_one_month_minutes_sp: 'Total of the number of hours exceeding regular working hours and working hours on statutory holidays (optional)',
      limit_job_one_year_minutes: 'Number of hours exceeding regular working hours (optional)',
      limit_legal_one_day_minutes: 'Number of hours exceeding statutory working hours ',
      limit_legal_one_month_minutes: 'Number of hours exceeding statutory working hours ',
      limit_legal_one_month_minutes_sp: 'Total of the number of hours exceeding statutory working hours and working hours on statutory holidays',
      limit_legal_one_year_minutes: 'Number of hours exceeding statutory working hours ',
      limit_overwork: 'Time limit exceeded',
      login: 'Log in',
      login_page: 'Login page',
      login_user_info: '[Login information]　Company code: {companyCode}　Company name: {companyName}　Employee number: {employeeCode}　Employee name: {employeeName}',
      logout: 'Log out',
      logout_ryaku: 'L',
      lunch: 'Bento (lunch box)',
      mail_address: 'Email address',
      mail_address_notset: 'Email address has not been set.',
      management_parties_employee_name: 'Employee name of the user',
      management_parties_job_title_name: 'Job name of the user',
      mar: 'March',
      max_length: 'You can enter up to {maxlength} characters',
      max_length_decimal: 'You can enter integer up to {integral}-digit and value down to {decimal} decimal places. ',
      max_length_integral: 'You can enter integer up to {integral}-digit.',
      may: 'May',
      minute: 'Minutes',
      mitsui: 'Mitsui Sumitomo',
      mizuho: 'Mizuho',
      monday: 'Monday',
      monday_ryaku: 'Mon',
      month: '',
      monthly: 'For the month of',
      monthly_amount: 'Monthly amount',
      monthly_check_items: 'Monthly check items',
      months: 'Months',
      national_holiday: 'Public holiday',
      new_add: 'Newly register',
      new_closing_name: '{item} (When creating new)',
      new_company: 'New registration',
      non_statutory_working_hours: 'Non-statutory working hours',
      notice_message: 'You have news. [{count} news]',
      not_managed: 'Out of management scope',
      nov: 'November',
      no_data: 'No data',
      number_of_legal_holidays_allowed_to_work: 'Number of statutory holidays allowed for working',
      number_of_enrolled: 'Number of people belonging',
      number_people: 'Number of people',
      oct: 'October',
      office_description: 'All employees managed by Artemis shall necessarily belong to one office. Here, you shall register the office.',
      office_info: 'Office', // 名称変更可能な項目のため、会社登録以外での使用不可
      one_day: '1 day',
      one_month: '1 month',
      one_year: '1 year',
      operating_company: 'Operating company',
      operation: 'Operations',
      or: 'Or',
      order: 'Order',
      order_history_option: 'Order history option',
      owner_mail_address: 'Contractor\'s email address',
      paid_annual_account: 'Account by paid leave grant year',
      paid_holiday_inf: 'Paid leave information ',
      paid_holiday_setting: 'Paid leave settings',
      partner_account_name: 'Ledger account name of the other party',
      partner_sub_account_name: 'Auxiliary ledger account name of the other party',
      password: 'Password',
      payment_amount: 'Payment amount',
      payment_flg: 'Already paid',
      payment: 'Payment',
      payment_description: 'Set payment information.',
      payment_info: 'Payment information',
      payment_salary_item_code: 'Payment amount',
      payslip_number: 'Count',
      payslip_explanation: 'Notes',
      pending: 'In review',
      pension_fund_contributions_subscription: 'Presence of Employees\' pension fund coverage',
      people: 'People',
      privacy_policy: 'Privacy policy',
      qrcode_login: 'Here for QR code clock-in/-out screen',
      read: 'Read',
      reasons_over_work_contents_1: 'Concrete reason to have the employee work overtime ',
      reasons_over_work_contents_2: 'Concrete reason to have the employee work on a holiday',
      reasons_over_work_contents_3: 'When to allow for working temporarily exceeding the time limit',
      reference: '(Reference)',
      registered_user: 'Here for those who registered',
      remark: 'Remarks',
      reservation_transmission: 'Reservation tarnsmission',
      retirement: 'Resignation fiscal year',
      retirement_employee_count: 'Number of resignees',
      re_ground: 'Current reason',
      risona: 'Risona',
      role_allocation: 'Allocate authority',
      role_or_approver: '{item}/approver',
      route_code: 'Route code',
      route_flag: 'Yes',
      route_name: 'Route name',
      rules: 'Rules',
      salary: 'Salary',
      salaryItem_detail_payment: 'Payment item',
      salaryItem_detail_deduction: 'Deduction item',
      salaryItem_detail_description1: 'Payment items and deduction items will be set.',
      salaryItem_detail_description2: 'Following calculation will be made for (1).',
      salaryItem_detail_description3: 'Sign for the calculation result will be set.',
      salary_closing: 'Salary closing',
      salary_increase_simulation_description: 'It\'s the current pay slip. ',
      salary_inf: 'Salary information',
      salary_payment_method: 'Salary payment method',
      saturday: 'Saturday',
      saturday_ryaku: 'Sat',
      scheduled_transmission_date: 'Scheduled send date',
      screen_search: 'Search for intended screen ',
      search_all: 'Including those out of the  validity period in the search',
      search_all_employees: 'Including resignees',
      search_bank: 'Search for bank',
      search_condition: 'Search criteria',
      search_result: 'Search results',
      second: 'Second',
      select: 'Selection',
      selected_bank: 'Selected financial institution:',
      select_description: 'Select',
      send: 'Send',
      sending_method: 'Send method',
      send_mail_payslip: 'Send pay slip',
      send_mail_bonuspayslip: 'Send bonus pay slip',
      sep: 'September',
      serial_number: 'Serial number',
      show_catch: 'Received',
      show_password: 'Show password',
      show_payment: 'Paid',
      sign: 'Sign',
      simulation_result: 'Simulation result',
      skip_apply_employee: 'Remove the requester from the approval. ',
      smile_mark_normal: 'As usual',
      special_provisions_year_count: 'Maximum times allowed to have workers to work exceeding the time limit. (Maximum up to 6 times)',
      special_provisions_rate_month: 'Premium wage rate related to work exceeding the time limit',
      special_provisions_rate_year: 'Premium wage rate related to work exceeding the time limit',
      start: 'Start',
      start_time: 'Start time',
      status: 'State',
      sunday: 'Sunday',
      sunday_ryaku: 'Sun',
      target: 'Target',
      target_amount: 'Target amount',
      target_employee: 'Target employees',
      target_person: 'Target employee',
      target_year: 'Target year',
      target_year_month: 'Target year and month',
      tax_cost: 'Inhabitant tax',
      team_inf: 'Team information',
      term: 'Period',
      terms_of_use: 'Terms of use',
      this_business_year: 'This fiscal year',
      this_month: 'This month',
      this_year: 'This year',
      thursday: 'Thursday',
      thursday_ryaku: 'Thu',
      today_stamp_time: 'Today\'s clock-in/-out times',
      total: 'Total',
      total_amount: 'Total amount',
      total_amount_of_money: 'Total amount',
      total_break_time: 'Total break time',
      total_payment_amount: 'Total payment amount',
      total_time: 'Total hours',
      transfer: 'Transfer destination',
      transfer_information: 'Transfer destination information',
      transfer_amount_of_money: 'Transfer amount',
      transfer_method: 'Transfer method',
      tuesday: 'Tuesday',
      tuesday_ryaku: 'Tue',
      type: 'Kind',
      type_of_day_monthly_summary: 'Working day: {total_working_day}days,  Holidays: {total_holiday}days',
      type_of_day_summary: 'Working day: {total_working_day}days,  Holidays: {total_holiday}days, Paid leave acquisition promotion day: {total_unknown_day}days',
      ufj: 'Mitsubishi UFJ',
      unit_age: 'years old',
      unit_byte: 'Bytes',
      unit_day: 'day',
      unit_days_between: 'days',
      unit_hours: 'hours',
      unit_mei: 'people',
      unit_minutes: 'minutes',
      unit_months: 'months',
      unit_people: 'people',
      unit_ratio: '%',
      unit_times: 'times',
      unit_yen: 'yen',
      unknown_screen: 'Unknown screen',
      unread: 'Unread',
      updated_now: 'Update now',
      upper_group: 'Top {item}',
      variable_item_setting: 'Settings of fluctuating items',
      violation: 'Violation',
      warning: 'Warning',
      warning_after_hours: 'Warn when exceeding xx hours',
      warning_after_percent: 'Warn when exceeding xx %',
      warning_after_days: 'Warn when exceeding xx days',
      warning_after_number: 'Warn when exceeding xx times',
      warning_content: 'Warning',
      wednesday: 'Wednesday',
      wednesday_ryaku: 'Wed',
      week: '{week} week',
      working_days_5: 'Number of days (5 days a week)',
      working_days_4: 'Number of days (4 days a week)',
      working_days_3: 'Number of days (3 days a week)',
      working_days_2: 'Number of days (2 days a week)',
      working_days_1: 'Number of days (1 day a week)',
      working_schedule: 'Shift pattern',
      work_schedule_info: 'Work schedule info', // 名称変更可能な項目のため、会社登録以外での使用不可
      work_time: 'Work hours',
      year: '/',
      yearly: 'Yearly',
      years: 'Years',
      years_of_service: 'Years of service',
      year_check_items: 'Fiscal year check items',
      yen: 'Yen',
      yucho: 'Japan Post Bank',
      // 以下未翻訳
      update_employee_ryaku: 'Update employee',
      before_change: 'Before',
      after_change: 'After',
      unit_stop_at: 'Stop at this job title employee',
      older_than: 'or older',
      next_day: 'Next day',
      term_from_ryaku: 'Hired date[From]',
      term_to_ryaku: 'Hired date[To]',
      management_parties_employee: 'Management parties employee',
      agreement_parties_employee: 'Agreement parties employee',
      normal_working_time: 'Normal working time',
      telework_flg: 'Telework',
      language_search_type1: 'Search unregistered languages',
      language_search_type2: 'Search all languages',
      can_set: '〇',
      cannot_set: '-',
      authority_setting: 'Authority setting',
      total_amount_of_workers: 'Total amount of workers',
      target_amount_of_workers: 'Target amount of workers',
      over_18_target_amount_of_workers: 'Over 18 target amount of workers',
      working_hours_in_a_week: 'Working hours in a week',
      transform_labor_time_term: 'Transform labor time term',
      transformation_weekly: 'Weekly transformation labor time application',
      apply_weekday: 'Weekdays to apply',
      over18_workers: '（number of over 18 workers）',
      less18_workers: '（number of less 18 workers）',
      transformation_monthly: 'Monthly transformation labor time application',
      transformation_yearly: 'Yearly transformation labor time application',
      transformation_term: 'Transformation term',
      working_hours_in_a_month: 'Working hours in a month',
      longest_working_hours_in_a_day: 'Longest working hours in a day',
      longest_working_hours_in_a_week: 'Longest working hours in a week',
      valid_term_of_application: 'Valid term of application',
      working_day_hours_in_a_month: 'Working hours in a month',
      average_working_hours_in_a_week: 'Average working hours in a week',
      transform_labor_time_term_year: 'Transform labor time term year',
      other_paper: '(Other paper)',
      total_working_days_in_a_term: 'Total working days in a term',
      over_48_hours_longest_weeks: 'Over 48 hours logest weeks',
      longest_working_days_in_target_term: 'Longest working days in target term',
      over_48_hours_weeks: 'Over 48 hours weeks',
      longest_working_days_in_specific_term: 'Longest working days in specific term',
      unit_year: 'Years',
      unit_weeks: 'Weeks',
      unit_days: 'Days',
      maximum_consecutive_weeks_with_more_than_48_hours_worked: 'Maximum consecutive weeks with more than 48 hours worked',
      weeks_with_more_than_48_hours_worked: 'Weeks with more than 48 hours worked',
      target_number_of_consecutive_working_days: 'Target number of consecutive working days',
      special_number_of_consecutive_working_days: 'Special number of consecutive working days',
      old_term: 'Old term',
      old_longest_working_day_working_time: 'Old longest working day working time',
      old_longest_working_week_working_time: 'Old longest working week working time',
      old_total_working_days: 'Old total working days',
      consumed_paid_holidays_count: 'Paid holidays',
      label_monthly_overwork_minutes: 'Monthly overwork minutes（{hour}hour{minutes}minute）',
      label_limit_legal_one_month_minutes: 'Limit legal one month minutes（{hour}hour{minutes}minute）',
      regulatory_grace_exclusion_help: 'Help'
    },
    button: {
      ...okubo.en.button,
      ...takeuchi.en.button,
      ...miki.en.button,
      ...googleapis.en.button,
      accept_end: 'Acceptance ends',
      accept_start: 'Acceptance starts',
      add: 'Registration',
      addMonitoringConditions: 'Register monitoring conditions',
      add_row: 'Add line',
      add_to_dashboard: 'Add to dashboard',
      agree: 'Agree',
      allAllocationSelect: 'Select allocate all',
      allAllocationRelease: 'Remove allocate all',
      allocation: 'Allocate',
      apply: 'Request',
      approve: 'Approve',
      assign_to_screen: 'Allocate screen',
      assign_to_employee: 'Allocate employees',
      auto_input: 'Auto fill',
      auto_input_with_end_of_month: 'Set automatically with end-of-month closing',
      back: 'Back',
      bank_transfer_list: 'Bank transfer list',
      batch_print: 'Mass download',
      break_end: 'Break ends',
      break_start: 'Break starts',
      button_change_password: 'Change',
      calc: 'Calculate',
      calculation: 'Calculate',
      cancel: 'Cancel',
      cancellation: 'Cancel the service',
      clear: 'Clear',
      close: 'Close',
      closing_attendance_record_close: 'Closing',
      closing_attendance_record_release: 'Remove',
      company_add: 'Add company',
      confirm: 'Confirm',
      copy_company_data: 'Set the telephone number, fax number, and business type in company information.',
      delete: 'Delete',
      delete_company: 'Delete {companyName}',
      delete_row: 'Delete line',
      deny: 'Denial',
      detail: 'Detail',
      disagree: 'Disagree',
      download: 'Download',
      draft: 'Save draft',
      drop: 'Withdraw',
      duplicate: 'Copy and create new',
      employment_insurance_rate: 'Update the employment insurance premium rate to the latest',
      execute: 'Run',
      expand: 'Enlarge',
      forced_approve: 'Forced approval',
      go_payment_page: 'To Payment screen',
      hold: 'Suspend',
      job_end: 'Leave',
      job_start: 'Arrive',
      journal_list: 'Journal data list',
      judge: 'Review',
      login: 'Log in',
      logout: 'Log out',
      lunch: 'Bento (lunch box)',
      migrate_from_corpus: 'Migrate {companyName} from corpus',
      move: 'Move',
      next: 'Next',
      next_month: 'Next month',
      new: 'Add new',
      no: 'No',
      order_history: 'Order history',
      popup_cancel: 'Cancel',
      postal_code_search: 'Search by postal code and change the pin on the map. ',
      postal_code_search_simple: 'Search by postal code.',
      precede_approve: 'Pre-empted approval',
      previous_month: 'Previous month',
      print: 'Create PDF',
      print_all: 'Mass create PDF',
      pull_back: 'Pull back',
      reapply: 'Reapply',
      reflect_all_months: 'Reflect to all the months with the following information',
      release: 'Clear',
      reload: 'Update',
      remand: 'Reject',
      reserve: 'Reservation',
      reserve_simple: 'Order',
      reset_all_months: 'Reset all the months with the following information.',
      reset_specific_month: 'Reset only {month}',
      search: 'Search',
      select: 'Select',
      send: 'Send',
      send_bonuspayslip: 'Send bonus pay slip',
      send_payslip: 'Send pay slip',
      show_actual: 'Show the actual record',
      show_current_ground: 'Show the current reason',
      skip_approve: 'Approval by one level higher approver',
      this_month: 'This month',
      understand: 'Accepted',
      unread: 'Mark read',
      update: 'Update',
      upload: 'Upload',
      view: 'Show',
      yes: 'Yes',
      // 以下未翻訳
      update_credit_card: 'Update credit card',
      reprint: 'Reprint',
      fix: 'Fix',
      cancel_fix: 'Cancel fix',
      show_attendance_record: 'Show attendance record',
      edit: 'Edit',
      edit_all: 'Edit all',
      apply_to: 'Apply',
      clear_all: 'Clear all'
    },
    placeholder: {
      ...okubo.en.placeholder,
      ...takeuchi.en.placeholder,
      ...miki.en.placeholder,
      ...googleapis.en.placeholder,
      account_number: '0000000',
      amount: '12,000',
      append_title: 'Handwriting request form',
      application_classification_code: '001',
      application_classification_name: 'Attendance-related',
      application_form_name: 'Overtime request',
      application_number: '1000000000',
      basic_pension_number: '00000000000',
      bank_code: '0000',
      branch_code: '000',
      break_end_time: '13:00',
      break_start_time: '12:00',
      break_time_start: '12:00',
      break_time_end: '13:00',
      building: 'Kintai Corporation building',
      business_type: '001',
      business_name: 'Non-career position',
      cancel_contents: 'Because response no longer required. ',
      city: '1-99-99 Otemachi, Chiyoda-ku',
      closing_code: '001',
      closing: '10',
      closing_name: 'Closing on 10th',
      collection_day: '15',
      company_code: '00000001',
      company_name: 'Kintai Corporation Co., Ltd.',
      contents: 'Service will not be available between 2 p.m. and 4 p.m. on October 3, 2021, (Sunday) due to maintenance. We apologize for any inconvenience. Thank you for your understanding and cooperation. ',
      contract_renewal_date: '2021-01-01',
      corporate_number: '00000001',
      date_of_collection: '10',
      day: '10',
      dependent_code: '001',
      dependent_count: '3',
      dependent_name: 'Taro Kintai',
      display_date: '2021-01-01',
      division_reservation_date: '2021-01-01',
      elapsed_date: '10',
      emergency_contact: '052-265-6883',
      employee_classification_code: '01',
      employee_classification_name: 'Full-time worker',
      employee_code: '00001',
      employee_name: 'Taro Kintai',
      employment_insurance_date: '2021-01-01',
      employment_insurance_number: '0000000000000',
      end_date: '2021-12-31',
      end_section: 'Toyohashi',
      end_time: '18:00',
      estimated_months_of_service: '18',
      estimated_overtime_hours: '40',
      event: 'Graduated from the Department of Business Administration, Faculty of Business Administration, Kintai University',
      executed_after_approverl: 'Interoffice mail',
      expiration_days: '10',
      expiration_times: '4',
      extension_number: '00001',
      fax: '052-265-6884',
      felica: '0000000aaa0000aa',
      first_month_cost: '10000',
      fixed_value: '1.25',
      ground_code: '001',
      ground_name: 'Work day',
      group_code: '001',
      group_name: 'Sales Department',
      health_insurance_sign: '00000000',
      health_insurance_no: '00000000',
      hire_date: '2001-04-01',
      hold_days: '10',
      hold_times: '4',
      homepage: 'http://www.suzuki-shoji.co.jp',
      home_page: 'http://www.yamada-rice.co.jp',
      input_password: 'Enter password',
      integrated_reservation_date: '2021-01-01',
      job_start: '09:00',
      job_end: '18:00',
      job_title_name: 'Department manager',
      password: 'password',
      labor_insurance_number: '01203000004005',
      labor_standards_inspection_office: 'Nagoya kita',
      lat: '35.17565',
      late_early_departure_content: 'Due to bad physical condition',
      late_early_departure_supplement: 'Reported to the field supervisor',
      layout_code: 'A0001',
      layout_name: 'Layout name',
      legal_holiday_job_end: '18:00',
      legal_holiday_job_start: '9:00',
      legal_time: '4',
      legal_day: '5',
      legal_percent: '20',
      limit_job_one_day_minutes: '4',
      limit_job_one_month_minutes: '40',
      limit_job_one_year_minutes: '370',
      limit_legal_one_day_minutes: '3',
      limit_legal_one_month_minutes: '30',
      limit_legal_one_year_minutes: '250',
      lng: '136.90979',
      local_government_code: '102016',
      lunch_name: 'Today\'s Bento (lunch box)',
      mail_address: 't.suzuki@suzuki-shoji.co.jp',
      message: 'Message',
      my_number: '000000000000',
      need_to_exceed: 'Due to busy period',
      notice_number: '100',
      notification: 'Maintenance announcement',
      notification_display_date_from: '2021-01-01',
      notification_display_date_to: '2021-12-31',
      notification_transmission_date: '2021-01-01',
      number_of_legal_holidays_allowed_to_work: '1',
      number_of_working_days_per_week: '5',
      office_code: '001',
      office_name: 'Head office',
      other: 'Other',
      overtime_content: 'Create test specifications',
      overtime_reason: 'In order to complete tasks with due date today',
      payment_date: '2022-04-01',
      payment_days: '10',
      payment_times: '4',
      pension_fund_contributions_date: '2021-01-01',
      place_code: '001',
      place_name: 'Tokyo',
      post_code: '461-0001',
      postal_code: '461-0001',
      price: '500',
      procedure_of_exceed_the_time_limit: 'Advance overtures to the worker representative',
      purchase_date: '2021-07-01',
      quantity: '1',
      reasons_over_work_contents: 'Order concentration',
      reason_for_retirement: 'Reason for resignation',
      reference_number: '0001',
      role_code: '001',
      role_name: 'General',
      rounding_term: '15',
      rounding_term_over_work: '30',
      route_code: '001',
      route_name: 'Sales route',
      salary_closing_name: 'Salary closing day in 2021',
      salary_closing: '10',
      salary_item_name: 'Base salary',
      social_insurance_date: '2021-01-01',
      sort_number: '1',
      special_provisions_rate_month: '35',
      special_provisions_rate_year: '35',
      special_provisions_year_count: '3',
      specific_content: 'Provide medical consultation to the target workers by a doctor ',
      start_date: '2021-01-01',
      start_section: 'Nagoya',
      start_time: '09:00',
      supplier_name: 'Kintai Rice shop',
      target_date: '2021',
      task_name: 'Meeting',
      team_code: '001',
      team_name: 'Sales team',
      tel: '052-265-6883',
      term_from: '2021-01-01',
      term_to: '2029-12-31',
      time: '40',
      town: '1-99-99 Otemachi',
      transfer_amount_of_money: '15,000',
      transportation_name: 'JR Kintai',
      unit_price: '500,000',
      unit_price2: '500',
      work_schedule_code: '001',
      work_schedule_name: 'Default',
      working_system_abbreviation: 'Default',
      // 以下未翻訳
      age: '40',
      address_change_supplement: 'for moving.',
      commuting_route_change_supplement: 'for moving.',
      personal_information_change_supplement: 'for getting married.',
      field_name: 'language',
      label_name: 'Language',
      abbreviated_name: 'Lang',
      menu_code: '01',
      choice_screen_code: '001',
      keyword: 'Timecard',
      vue_path: 'Menu01Screen001.vue',
      contents2: 'You can check stamp time',
      release_version: '1',
      screen_name: 'Timecard',
      icon_name: 'ti-01-001',
      starting_date: '2022/01/01',
      agreement_date: '2022/01/01',
      filing_date: '2022/01/01',
      limit_legal_one_weeks_minutes: '10'
    },
    help: {
      ...okubo.en.help,
      ...takeuchi.en.help,
      ...miki.en.help,
      ...googleapis.en.help,
      work_schedule_code: 'Artemis allows for clock-in/-out with multiple shift patterns, such as 9 a.m. to 6 p.m. and 6 p.m. to 11 p.m. Codes are required to identify such shift patterns. Please set them freely containing 10 characters or less. (e.g. EIGYO, HONSYA, RINJI etc. )',
      work_time: 'Provide in 09:00 format. ',
      is_job_before_start_time: 'If you want to include the time from the clock-in time to the start of work in working hours, select [Include].',
      break_time: '(e.g.) 12 p.m. to 1 p.m. ',
      closing_name: 'Names are required to identify the closing date of attendance. Enter names that are easy to manage, such as "Closing on the 10th” and "Closing at the end of the month."',
      target_year: 'Closing day master will be created. Provide fiscal year when the master will be created for. ',
      office_code: 'Codes are required to identify offices. Please set them freely containing 10 characters or less. (e.g. HONSYA , NAGOYA, 0000000001, etc. )',
      reference_number: 'It is a health insurance office reference number. If unknown or you are not covered by health insurance yet, you can skip the step. ',
      special_measures: 'Although the Labor Standards Act prescribes that the statutory working hours are "8 hours, a day, 40 hours a week",  working up to "8 hours, a day, 44 hours a week" is permitted at the following workplaces. This type of workplace is called "Special measure applied workplace." *Only workplaces with up to 10 regular employees.',
      employee_code: 'Codes are required to identify employees. Use the employee number allocated by the company. Please set them freely containing 10 characters or less.'
    },
    movie: {
      ...okubo.en.movie,
      ...takeuchi.en.movie,
      ...miki.en.movie,
      ...googleapis.en.movie
    }
  }
}
