<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame">
          <div style="margin-left:25px; padding: 10px;">
            <blockquote>
              <p v-for="notification in (detail.notification.split('\n'))" :key="notification" style="font-style: normal; font-weight:bold;">
                {{notification}}
              </p>
            </blockquote>
          </div>
          <hr>
          <div>
            <p v-for="(contents, index) of (detail.contents.split('\n'))" :key="index">
              <span v-html="getContents(contents)"></span>
            </p>
          </div>
        </div>
      </div>
      <template v-if="params.is_read && !isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="submit">
            {{$t('button.unread')}}
          </button>
        </div>
      </template>
      <template v-else-if="!params.is_read && !isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="submit">
            {{$t('button.understand')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import NoticeDetailMixin from '@/components/Dashboard/Views/Setting/NoticeDetailMixin'

  export default {
    name: 'Notice',
    mixins: [NoticeDetailMixin]
  }
</script>
<style scoped>
.card .category {
  color: #000000;
}
blockquote {
  position: relative;
  left: -40px;
  border-left: 5px solid #f00 !important;
}
hr {
  margin-top: 0px !important;
}
</style>
