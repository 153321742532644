<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.salary_closing_code"
              item-name="salary_closing_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              :item-label="labels.salary_closing_code"
              :options="comboData.salary_closing_list"
              :disabled="isReadOnly"
              @change="salaryClosingCodeChanged"
              v-show="mode==modes.add"
            />
            <ValidationText
              v-model="detail.salary_closing_name"
              item-name="salary_closing_name"
              max-length="30"
              classes="form-control length60"
              :item-label="labels.salary_closing_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.salary_closing_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="showClosingName"
              data-v-step="0"
            />
            <ValidationSelect
              v-model="detail.closing_code"
              item-name="closing_code"
              :placeholder="$t('label.select_description')"
              classes="length120"
              rules="required"
              :item-label="labels.closing_code"
              :options="comboData.closing_list"
              :disabled="isReadOnly"
              @change="closingCodeChanged"
              data-v-step="1"
            />
            <ValidationText
              v-model="detail.target_date"
              item-name="target_date"
              max-length="4"
              classes="form-control numLength4 text-right"
              rules="required"
              :item-label="labels.target_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.target_date')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="2"
            />
            <SalaryClosingText
              v-model="detail.salary_closing"
              item-name="salary_closing"
              max-length="2"
              classes="form-control numLength2 text-right"
              :item-label="labels.salary_closing"
              :placeholder="isReadOnly ? '' : $t('placeholder.salary_closing')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="3"
              :isClosingDate="true"
              @calcClosingDate="calcButtonClicked"
              @calcClosingDateWithEndOfMonth="calcButton2Clicked"
            />
            <ValidationSalaryClosingDateTable
              v-model="detail.salary_closing_date_table"
              item-name="salary_closing_date_table"
              classes="form-control"
              :labels="labels"
              rules="salaryClosingDateRequired"
              :item-label="labels.salary_closing_date_table"
              :disabled="isReadOnly"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import SalaryClosingDetailMixin from '@/components/Dashboard/Views/Generated/SalaryClosingDetailMixin'

  export default {
    name: 'SalaryClosingDetail',
    data () {
      return {
        showClosingName: true
      }
    },
    methods: {
      afterInit (res) {
        if (this.mode === this.modes.add) {
          this.labels.salary_closing_code = this.$t('label.existing_closing_name').replaceAll('{item}', this.labels.salary_closing_name)
          this.labels.org_closing_name = this.labels.salary_closing_name
          this.labels.salary_closing_name = this.$t('label.new_closing_name').replace('{item}', this.labels.salary_closing_name)
          this.comboData.salary_closing_list.unshift({label: '', value: ''})
        }
        this.labels.salary_closing = this.$t('label.salary_closing')
        this.labels.target_date = this.$t('label.target_year')
        this.labels.closing_code = this.labels.closing_code_ryaku
        if (!res.api_area) {
          res.api_area = {}
        }
        this.detail.salary_closing_date_table = [
          {
            left_month: this.$t('label.jan'),
            left_salary_month: res.api_area.payment_due_date_jan ? new Date(res.api_area.payment_due_date_jan) : undefined,
            right_month: this.$t('label.jul'),
            right_salary_month: res.api_area.payment_due_date_jly ? new Date(res.api_area.payment_due_date_jly) : undefined
          },
          {
            left_month: this.$t('label.feb'),
            left_salary_month: res.api_area.payment_due_date_feb ? new Date(res.api_area.payment_due_date_feb) : undefined,
            right_month: this.$t('label.aug'),
            right_salary_month: res.api_area.payment_due_date_aug ? new Date(res.api_area.payment_due_date_aug) : undefined
          },
          {
            left_month: this.$t('label.mar'),
            left_salary_month: res.api_area.payment_due_date_mar ? new Date(res.api_area.payment_due_date_mar) : undefined,
            right_month: this.$t('label.sep'),
            right_salary_month: res.api_area.payment_due_date_sep ? new Date(res.api_area.payment_due_date_sep) : undefined
          },
          {
            left_month: this.$t('label.apr'),
            left_salary_month: res.api_area.payment_due_date_apl ? new Date(res.api_area.payment_due_date_apl) : undefined,
            right_month: this.$t('label.oct'),
            right_salary_month: res.api_area.payment_due_date_oct ? new Date(res.api_area.payment_due_date_oct) : undefined
          },
          {
            left_month: this.$t('label.may'),
            left_salary_month: res.api_area.payment_due_date_may ? new Date(res.api_area.payment_due_date_may) : undefined,
            right_month: this.$t('label.nov'),
            right_salary_month: res.api_area.payment_due_date_nov ? new Date(res.api_area.payment_due_date_nov) : undefined
          },
          {
            left_month: this.$t('label.jun'),
            left_salary_month: res.api_area.payment_due_date_jun ? new Date(res.api_area.payment_due_date_jun) : undefined,
            right_month: this.$t('label.dec'),
            right_salary_month: res.api_area.payment_due_date_dec ? new Date(res.api_area.payment_due_date_dec) : undefined
          }
        ]
      },
      salaryClosingCodeChanged () {
        this.showClosingName = this.detail.salary_closing_code === ''
      },
      calcButtonClicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.salary_closing[0] && this.detail.target_date) {
          for (const row of this.detail.salary_closing_date_table) {
            const leftMonth = this.detail.salary_closing[1] ? row.left_month.replace('月', '') : row.left_month.replace('月', '') - 1
            const rightMonth = this.detail.salary_closing[1] ? row.right_month.replace('月', '') : row.right_month.replace('月', '') - 1
            row.left_salary_month = new Date(this.detail.target_date, leftMonth, this.detail.salary_closing[0])
            row.right_salary_month = new Date(this.detail.target_date, rightMonth, this.detail.salary_closing[0])
          }
        } else if (this.detail.closing[0]) {
          console.log('対象年が未入力です。')
        } else {
          console.log('締日が未入力です。')
        }
      },
      calcButton2Clicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.target_date) {
          const baseDate = new Date(this.detail.target_date, 1, 1)
          for (const row of this.detail.salary_closing_date_table) {
            const leftMonth = this.detail.salary_closing[1] ? parseInt(row.left_month.replace('月', ''), 10) + 1 : row.left_month.replace('月', '')
            const rightMonth = this.detail.salary_closing[1] ? parseInt(row.right_month.replace('月', ''), 10) + 1 : row.right_month.replace('月', '')
            row.left_salary_month = new Date(this.detail.target_date, leftMonth, baseDate.getDate() - 1)
            row.right_salary_month = new Date(this.detail.target_date, rightMonth, baseDate.getDate() - 1)
          }
        } else {
          console.log('締日が未入力です。')
        }
      },
      customValidate () {
        if (this.mode === this.modes.add) {
          if (this.message.text_bk) {
            this.message.message_classification = this.message.message_classification_bk
            this.message.text = this.message.text_bk
          }
          if ((this.detail.salary_closing_code.length === 0 && this.detail.salary_closing_name.length === 0) ||
            (this.detail.salary_closing_code.length > 0 && this.detail.salary_closing_name.length > 0)) {
            if (!this.message.text_bk) {
              this.message.message_classification_bk = this.message.message_classification
              this.message.text_bk = this.message.text
            }
            this.message.message_classification = 2
            this.message.text = this.labels.org_closing_name + 'はいずれか一方を選択、または入力してください。'
            this.message = {...this.message}
            return false
          }
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          delete detail.salary_closing
          delete detail.calc_button
          detail.payment_due_date_jan = this.formatDate(detail.salary_closing_date_table[0].left_salary_month)
          detail.payment_due_date_feb = this.formatDate(detail.salary_closing_date_table[1].left_salary_month)
          detail.payment_due_date_mar = this.formatDate(detail.salary_closing_date_table[2].left_salary_month)
          detail.payment_due_date_apl = this.formatDate(detail.salary_closing_date_table[3].left_salary_month)
          detail.payment_due_date_may = this.formatDate(detail.salary_closing_date_table[4].left_salary_month)
          detail.payment_due_date_jun = this.formatDate(detail.salary_closing_date_table[5].left_salary_month)
          detail.payment_due_date_jly = this.formatDate(detail.salary_closing_date_table[0].right_salary_month)
          detail.payment_due_date_aug = this.formatDate(detail.salary_closing_date_table[1].right_salary_month)
          detail.payment_due_date_sep = this.formatDate(detail.salary_closing_date_table[2].right_salary_month)
          detail.payment_due_date_oct = this.formatDate(detail.salary_closing_date_table[3].right_salary_month)
          detail.payment_due_date_nov = this.formatDate(detail.salary_closing_date_table[4].right_salary_month)
          detail.payment_due_date_dec = this.formatDate(detail.salary_closing_date_table[5].right_salary_month)
          delete detail.salary_closing_date_table
          delete detail.update_count
          if (this.mode === this.modes.edit) {
            detail.salary_closing_date_update_count = parseInt(detail.salary_closing_date_update_count, 10)
            detail.salary_closing_update_count = parseInt(detail.salary_closing_update_count, 10)
          }
        } else {
          detail.salary_closing_code = this.detail.salary_closing_code
          detail.target_date = this.detail.target_date
          detail.salary_closing_update_count = parseInt(this.detail.salary_closing_update_count, 10)
          detail.salary_closing_date_update_count = parseInt(this.detail.salary_closing_date_update_count, 10)
          delete detail.update_count
        }
        return detail
      }
    },
    mixins: [SalaryClosingDetailMixin]
  }
</script>
<style scoped>
</style>
