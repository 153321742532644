<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal
      name="detail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidth"
      :pivotX="0.9"
    >
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" />
      </div>
    </modal>
    <modal
      name="bank"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthBank"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Bank :mode="mode" :params="params" @close="hideBank" />
      </div>
    </modal>
    <modal
      name="cashTransfer"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthCashTransfer"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <CashTransfer :mode="mode" :params="params" @close="hideCashTransfer" />
      </div>
    </modal>
    <modal
      name="journal"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthJournal"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Journal :mode="mode" :params="params" @close="hideJournal" />
      </div>
    </modal>
    <modal
      name="sendMail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthSendMail"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <SendMail :mode="mode" :params="params" @close="hideSendMail" />
      </div>
    </modal>
    <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack">{{$t('button.back')}}</button>

    <form method="#" action="#">
      <div class="row">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="bonus" div-style="padding-bottom: 5px;" />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode) + ' - ' + bonus_pay_slip_name}}</h4>
        </div>
        <div ref="frame">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">{{$t('label.search_condition')}}</h4>
            </div>
            <div
              style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;"
            >
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <EmployeesSearch
              :orgSearchCond="searchCond"
              :labels="labels"
              :comboData="comboData"
              :disabled="isChanged"
              :show-search-all="false"
              :bonus-mode="true"
              @search="searchClicked"
            />
          </div>

          <!-- メッセージ -->
          <div class="card">
            <MessageArea
              :page-type="pageType.search"
              :message="message"
              :placeholders="[pageTitle]"
            />
          </div>
          <template v-if="tableData && tableData.length > 0">
            <template v-if="tableData.length">
              <div class="card">
                <div class="card-header search-result-title">
                  <h4 class="card-title">{{$t('label.search_result')}}</h4>
                </div>
                <div class="card-content">
                  <div style="margin-top: 10px;">
                    <!-- 一括ダウンロードボタン -->
                    <template v-if="roles.print === 1">
                      <button
                        type="button"
                        class="btn btn-sm btn-default btn-warning"
                        @click="doPrintAll()"
                        :style="executeOperation(roles.print === 1)"
                      >{{$t('button.batch_print')}}</button>
                      <hr />
                    </template>
                    <!-- 対象月別で繰り返し表示 -->
                    <div style="text-align: left;">
                      <!-- 給与明細送信 -->
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-default"
                        @click="showSendMail(target_date)"
                        v-show="canShow('/SS/menuSSScreen025')"
                      >{{$t('button.send_bonuspayslip')}}</button>
                      <!-- 銀行振込一覧ボタン -->
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-default"
                        @click="showBank(target_date)"
                        v-show="canShow('/SS/menuSSScreen023')"
                      >{{$t('button.bank_transfer_list')}}</button>
                      <!-- 現金一覧表ボタン -->
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-default"
                        @click="showCashTransfer(target_date)"
                        v-show="canShow('/SS/menuSSScreen026')"
                      >{{$t('button.cash_transfer')}}</button>
                      <!-- 仕訳データ一覧ボタン -->
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-default"
                        @click="showJournal(target_date)"
                        v-show="canShow('/SS/menuSSScreen024')"
                      >{{$t('button.journal_list')}}</button>
                    </div>
                    <hr />
                    <h5>{{ bonusPaySlipName }}</h5>
                    <!-- Table開始 -->
                    <div :style="tableWrapperStyle">
                      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                        class="table-width"
                        border
                        :data="tableData"
                        :empty-text="$t('label.no_data')"
                        :style="tableStyle"
                        ref="table"
                        :row-class-name="tableRowClassName"
                      >
                        <!-- 操作 -->
                        <el-table-column
                          :width="roles.print === 1 ? 230 : 130"
                          v-if="showOperation"
                          fixed="left"
                          :key="componentKey"
                          :label="$t('label.operation')"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <!-- 印刷 -->
                            <template v-if="roles.print === 1">
                              <button
                                type="button"
                                class="btn btn-sm btn-default btn-warning"
                                :style="executeOperation(canPrint(props.row))"
                                @click="doPrint(props.$index, props.row)"
                              >{{$t('button.print')}}</button>
                            </template>
                            <!-- 表示 -->
                            <a
                              class="btn btn-simple btn-icon like"
                              @click="handleView(props.$index, props.row)"
                              :style="executeOperation(canPreview(props.row))"
                            >
                              <i class="ti-file" :title="$t('button.view')"></i>
                            </a>
                            <!-- 更新 -->
                            <template v-if="props.row.t_employee_pay_slip_exist === 1">
                              <a
                                class="btn btn-simple btn-icon edit"
                                @click="handleEdit(props.$index, props.row)"
                                :style="executeOperation(canUpdate(props.row))"
                              >
                                <i class="ti-pencil-alt" :title="$t('button.update')"></i>
                              </a>
                            </template>
                            <template v-else>
                              <a
                                class="btn btn-simple btn-icon edit"
                                @click="handleAdd(props.$index, props.row)"
                                :style="executeOperation(canEntry(props.row))"
                              >
                                <i class="ti-pencil-alt" :title="$t('button.add')"></i>
                              </a>
                            </template>
                            <!-- 削除 -->
                            <a
                              class="btn btn-simple btn-icon btn-danger remove"
                              @click="handleDelete(props.$index, props.row)"
                              :style="executeOperation(canDelete(props.row))"
                            >
                              <i class="ti-close" :title="$t('button.delete')"></i>
                            </a>
                            <!--ダウンロードボタン -->
                          </template>
                        </el-table-column>
                        <!-- レイアウト -->
                        <el-table-column
                          :width="180"
                          :label="labels.layout === undefined ? '' : labels.layout"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <!-- レイアウト -->
                            <div style="text-align: left;">
                              <template v-if="props.row.t_employee_pay_slip_exist === 1">
                                {{ getLabel(getSelectName(props.row.layout_code, comboData.bonus_layout_list)) }}
                              </template>
                              <template v-else>
                                <ValidationSelectHorizontal
                                  v-model="props.row.layout_code"
                                  item-name="payment_deduction_category"
                                  classes="length"
                                  :item-label="labels.layout"
                                  :options="comboData.bonus_layout_list"
                                />
                              </template>
                            </div>
                          </template>
                        </el-table-column>
                        <!-- 対象年月日 -->
                        <el-table-column
                          :width="100"
                          :label="labels.target_date === undefined ? '' : labels.target_date"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              <template
                                v-if="props.row.target_date"
                              >{{props.row.target_date.substring(0, 4)}}{{$t('label.year')}}{{props.row.target_date.substring(4, 6)}}{{$t('label.monthly')}}</template>
                            </div>
                          </template>
                        </el-table-column>
                        <!-- 作成済み -->
                        <el-table-column
                          :width="100"
                          :label="$t('label.created_salary') === undefined ? '' : $t('label.created_salary')"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div
                              style="text-align: left;"
                            >{{getLabel(getSelectName(props.row.t_employee_pay_slip_exist, comboData.t_employee_pay_slip_exist_list))}}</div>
                          </template>
                        </el-table-column>

                        <!-- 事業所 -->
                        <el-table-column
                          v-if="searchCond.search_type === 0"
                          :width="200"
                          :label="labels.office === undefined ? '' : labels.office"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              <small>{{props.row.office_code}}</small>
                            </div>
                            <div style="text-align: left;">{{props.row.office_name}}</div>
                          </template>
                        </el-table-column>
                        <!-- 部署 -->
                        <el-table-column
                          v-if="searchCond.search_type === 1"
                          :width="200"
                          :label="labels.team === undefined ? '' : labels.group"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              <small>{{props.row.group_code}}</small>
                            </div>
                            <div style="text-align: left;">{{props.row.group_name}}</div>
                          </template>
                        </el-table-column>
                        <!-- チーム -->
                        <el-table-column
                          v-if="searchCond.search_type === 2"
                          :width="200"
                          :label="labels.team === undefined ? '' : labels.team"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              <small>{{props.row.team_code}}</small>
                            </div>
                            <div style="text-align: left;">{{props.row.team_name}}</div>
                          </template>
                        </el-table-column>
                        <!-- 従業員区分 -->
                        <el-table-column
                          v-if="searchCond.search_type !== 3"
                          :width="150"
                          :label="labels.employeeClassification === undefined ? '' : labels.employeeClassification"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div
                              style="text-align: left;"
                            >{{props.row.employee_classification_name}}</div>
                          </template>
                        </el-table-column>
                        <!-- 従業員 -->
                        <el-table-column
                          :width="200"
                          :label="labels.employee === undefined ? '' : labels.employee"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              <small>{{props.row.employee_code}}</small>
                            </div>
                            <div style="text-align: left;">{{props.row.employee_name}}</div>
                          </template>
                        </el-table-column>
                        <!-- 給与明細 -->
                        <el-table-column
                          :width="135"
                          :label="labels.date_of_sending_pay_slip === undefined ? '' : labels.date_of_sending_pay_slip"
                        >
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">{{props.row.date_of_sending_pay_slip}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column :width="150" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                          <template slot="header" slot-scope="scope">
                            <div class="phase-header tableHeader">
                              <div class="phase-header__title">{{scope.column.label}}</div>
                            </div>
                          </template>
                          <template slot-scope="props">
                            <div style="text-align: left;">
                              {{props.row.update_date}}
                            </div>
                            <div style="text-align: left;">
                              <small>{{ props.row.update_employee_name }}</small>
                            </div>
                            <div style="text-align: left; line-height: 100%;">
                              <small>{{ props.row.update_employee_code }}</small>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <br />
                    <div class="row" ref="pager"></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="card">
                <div class="card-header search-result-title">
                  <h4 class="card-title">{{$t('label.search_result')}}</h4>
                </div>
                <div class="card-content">
                  <div style="margin-top: 10px;">{{$t('label.no_data')}}</div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import CreateBonusPaySlipSummaryMixin from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipSummaryMixin'

  export default {
    name: 'CreateBonusPaySlipSummary',
    mixins: [CreateBonusPaySlipSummaryMixin]
  }
</script>
<style scoped>
</style>
