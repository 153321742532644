<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div :key="componentKey">
        <div style="margin-bottom: 20px;">
          <input type="file" ref="file_upload" style="display: none;" @change="onFileChange"/>
          <div class="drop_area" :style="dropAreaStyle" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent @drop.prevent="dropFile" :class="{enter: isEnter}" type="button">
            <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" @click="$refs.file_upload.click()">{{$t('button.select_file')}}</button>
          </div>
        </div>
        <template v-if="srcPath && srcPath.substring(0, 4) === 'http'">
          <img :src="srcPath" style="height: 100px; margin-bottom: 10px;"/>
          <div>
            <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" @click="deleteImage">{{$t('button.delete')}}</button>
          </div>
        </template>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      rules: String,
      itemLabel: String,
      disabled: Boolean,
      size: {
        type: Number,
        require: false,
        'default': 100 * 1024 * 1024
      },
      width: {
        type: Number,
        require: false,
        'default': 500
      },
      height: {
        type: Number,
        require: false,
        'default': 100
      }
    },
    data () {
      return {
        isEnter: false,
        files: [],
        componentKey: 0,
        itemValue: null,
        srcPath: ''
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
        this.showImage()
      }
    },
    computed: {
      dropAreaStyle () {
        return `max-width: ${this.width}px; min-height: ${this.height}px;`
      }
    },
    methods: {
      dragEnter () {
        this.isEnter = true
      },
      dragLeave () {
        this.isEnter = false
      },
      dragOver () {
      },
      dropFile (event) {
        this.createImage(event.dataTransfer.files[0])
        this.isEnter = false
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createImage(files[0])
      },
      createImage (file) {
        if (file.size > this.size) {
          swal({
            text: this.$t('message.file_size_over').replace('{size}', this.formatNumber(this.size / 1024)),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        let self = this
        // 生成する文字列の長さ
        var l = 16
        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'
        var cl = c.length
        var r = ''
        for (var i = 0; i < l; i++) {
          r += c[Math.floor(Math.random() * cl)]
        }
        const extension = file.name.substring(file.name.lastIndexOf('.'))
        this.itemValue = `tmp/${this.$store.state.loginUser.companyCode}/${r}${extension}`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(this.itemValue)
        httpsReference.put(file).then(function (snapShot) {
          httpsReference.getDownloadURL().then(function (url) {
            self.srcPath = url
            self.$emit('input', self.itemValue)
            self.componentKey += 1
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      deleteImage () {
        this.srcPath = ''
        this.itemValue = ''
        this.$emit('input', this.itemValue)
        this.componentKey += 1
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      },
      showImage () {
        if (this.itemValue) {
          const self = this
          this.$firebase.storage()
            .ref()
            .child(this.itemValue)
            .getDownloadURL()
            .then(function (url) {
              self.srcPath = url
              self.componentKey += 1
            })
        }
      }
    },
    mounted () {
      this.itemValue = this.value
      this.showImage()
    }
  }
</script>

<style scoped>
  .drop_area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 5px dashed #6d6d6d;
    background-color: #f3f3f3;
    color: var(--text-color-dark);
    border-radius: 20px;
    box-sizing: border-box;
    transition: background-color 160ms ease;
    font-weight:bold;
  }</style>