<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <form method="#" action="#">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{$t('label.search_condition')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="form-group" style="display: inline-block">
            <label>{{labels.task_name}}</label>
            <input type="text" :placeholder="$t('placeholder.task_name')" maxlength="30" class="form-control length30" v-model="searchCond.task_name">
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-warning btn-sm btn-default" @click="showAdd">{{$t('button.new')}}</button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="search">{{$t('button.search')}}</button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <el-select
            class="select-success length2"
            :disabled="isChanged"
            v-model="pagination.perPage">
            <el-option
              class="select-success"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div style="margin-top: 10px;">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               fixed="left"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployeeClassificationName" :width="getColumnWidth(tableData, labels.task_name === undefined ? labels.taskName === undefined ? '' : labels.taskName : labels.task_name, 'task_name')" :label="labels.task_name === undefined ? labels.taskName === undefined ? '' : labels.taskName : labels.task_name" :key="componentKey">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: left;">
                    {{props.row.task_name}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import TaskSearchMixin from '@/components/Dashboard/Views/Master/TaskSearchMixin'

  export default {
    name: 'TaskSearch',
    mixins: [TaskSearchMixin]
  }
</script>
<style scoped>
</style>
