<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>

              <div class="col-md-9 col-md-offset-3">									
                <div>
                    専属のサポートメンバーが、<b>サポート時間(平日の10:00-17:00)にチャットにてサポート致します。</b><br />
                    仮にサポート時間を超えている場合でもメールで問い合わせできる運用になっています。（<a href="https://linkpoint.zendesk.com/hc/ja/requests/new" target="_blank" style="color: #317FB8;">https://linkpoint.zendesk.com/hc/ja/requests/new</a>）<br />
                    <br />
                    メールでのお問い合わせにつきましては、次の営業日にご回答いたしますのでご了承ください。
                </div>
                <div class="maine_moji"></div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
</style>
