<template>
  <div class="row" ref="frame">
    <div class="col-md-12">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-content" :key="componentKey">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="margin-top: 10px;">
            <el-table :data="pagedData"
                :empty-text="$t('label.no_data')"
                border
                :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                :max-height="maxHeight"
                ref="table">
              <el-table-column :width="getColumnWidth(tableData, labels.employee_code, 'employee_code')" :label="labels.employee_code" fixed="left">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.employee_code}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.employee_name, 'employee_name')" :label="labels.employee_name" fixed="left">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.employee_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.pseudonym_reading, 'pseudonym_reading')" :label="labels.pseudonym_reading">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.pseudonym_reading}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.employee_classification_code, 'employee_classification_code')" :label="labels.employee_classification_code">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.employee_classification_code}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.recruitment_category_code, 'recruitment_category_code')" :label="labels.recruitment_category_code">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.recruitment_category_code}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.language, 'language')" :label="labels.language">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.language}}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column :width="getColumnWidth(tableData, labels.append_path, 'append_path')" :label="labels.append_path">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.append_path}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.wall_paper_append_path, 'wall_paper_append_path')" :label="labels.wall_paper_append_path">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.wall_paper_append_path}}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column :width="getColumnWidth(tableData, labels.hire_date, 'hire_date')" :label="labels.hire_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.hire_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.retirement_date, 'retirement_date')" :label="labels.retirement_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.retirement_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.mail_address, 'mail_address')" :label="labels.mail_address">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.mail_address}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.sex, 'sex')" :label="labels.sex">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.sex}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.birthday, 'birthday')" :label="labels.birthday">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.birthday}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.tel, 'tel')" :label="labels.tel">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.tel}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.extension_number, 'extension_number')" :label="labels.extension_number">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.extension_number}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.contract_renewal_date, 'contract_renewal_date')" :label="labels.contract_renewal_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.contract_renewal_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.attendance_management, 'attendance_management')" :label="labels.attendance_management">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.attendance_management}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.payroll_management, 'payroll_management')" :label="labels.payroll_management">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.payroll_management}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.paid_leave_payment, 'paid_leave_payment')" :label="labels.paid_leave_payment">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.paid_leave_payment}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.paid_reference_date, 'paid_reference_date')" :label="labels.paid_reference_date">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.paid_reference_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.estimated_months_of_service, 'estimated_months_of_service')" :label="labels.estimated_months_of_service">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.estimated_months_of_service}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.available_in_one_year, 'available_in_one_year')" :label="labels.available_in_one_year">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.available_in_one_year}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.availability, 'availability')" :label="labels.availability">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.availability}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.number_of_working_days_per_week, 'number_of_working_days_per_week')" :label="labels.number_of_working_days_per_week">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.number_of_working_days_per_week}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.feliCa, 'feliCa')" :label="labels.feliCa">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.feliCa}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.my_number, 'my_number')" :label="labels.my_number">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.my_number}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.estimated_overtime_hours, 'estimated_overtime_hours')" :label="labels.estimated_overtime_hours">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.estimated_overtime_hours}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.salary_category, 'salary_category')" :label="labels.salary_category">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.salary_category}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.layout_code, 'layout_code')" :label="labels.layout_code">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.layout_code}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.unit_price, 'unit_price')" :label="labels.unit_price">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.unit_price}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.is_visible, 'is_visible')" :label="labels.is_visible">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.is_visible}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.white_collar_exemption, 'white_collar_exemption')" :label="labels.white_collar_exemption">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.white_collar_exemption}}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column :width="getColumnWidth(tableData, labels.google_token, 'google_token')" :label="labels.google_token">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.google_token}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.color_pattern, 'color_pattern')" :label="labels.color_pattern">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.color_pattern}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.face_data, 'face_data')" :label="labels.face_data">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.face_data}}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column :width="getColumnWidth(tableData, labels.logical_deletion, 'logical_deletion')" :label="labels.logical_deletion">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.logical_deletion}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.attendance_status, 'attendance_status')" :label="labels.attendance_status">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.attendance_status}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.memo, 'memo')" :label="labels.memo">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.memo}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination
    },
    props: {
      params: {
        type: Object,
        require: true
      }
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        showSearchCondition: true,
        labels: {},
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        comboData: {},
        tableData: [],
        maxHeight: 0
      }
    },
    methods: {
      init () {
        this.tableData = this.params.tableData
        this.labels = this.params.labels
        this.comboData = this.params.comboData
        this.maxHeight = window.innerHeight - 280
      },
      close () {
        this.$emit('close', null)
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
