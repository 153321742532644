<template>
  <div>
    <h5 class="text-center">Artemisでは下記の情報を事業所単位に管理しています。</h5>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 460px);" name="officeFrame" :key="componentKey">
      <div class="col-md-11 col-md-offset-1">
        <div class="card">
          <div class="card-content">
            住所：{{office.post_code}}&nbsp;{{office.prefectures}}{{office.city}}&nbsp;{{office.building}}
            <br>
            電話番号：{{office.telephone1}}
            <br>
            ファックス番号：{{office.fax}}
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-primary" style="font-size: medium;" @click="copyAddress">
              この住所、電話番号、ファックス番号をすべての事業所に設定する
            </button>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            業種：{{office.industry1Name}}&nbsp;{{office.industry2Name}}&nbsp;{{office.industry3Name}}
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-primary" style="font-size: medium;" @click="copyIndustry">
              この業種をすべての事業所に設定する
            </button>
          </div>
        </div>
        <hr>
        <ValidationObserver ref="observer" v-slot="{ errors }">
          <div style="margin-bottom: 5px;">
            <template v-for="(error, index) in errors">
              <template v-if="error.length > 0">
                <div v-bind:key="index">
                  <small class="text-danger" v-if="error.length > 0">
                    {{error[0].indexOf('|') === -1 ? error[0] : error[0].replace(error[0].substring(error[0].indexOf('|'), error[0].indexOf('|') + 12), '')}}
                  </small>
                </div>
              </template>
            </template>
          </div>
          <div class="card" v-for="(office, index) in offices" v-bind:key="office.office_code">
            <div class="card-content">
              <div class="form-group">
                <div>
                  <label>事業所</label>
                </div>
                {{office.area_cd}}&nbsp;&nbsp;{{office.area_nm}}
              </div>
              <div class="form-group">
                <div>
                  <label>場所</label>
                </div>
                <input 
                  type="text" 
                  name="placeCode"
                  class="form-control width110" 
                  style="display: inline-block;"
                  v-model="office.place_code" 
                  maxlength="10"
                  :disabled="false">
                <input 
                  type="text" 
                  name="placeName"
                  class="form-control width300" 
                  style="display: inline-block;"
                  v-model="office.place_name" 
                  maxlength="30"
                  :disabled="false">
                <small class="text-danger" v-show="false">
                </small>
              </div>
              <ValidationText
                v-model="office.post_code"
                max-length="10"
                classes="form-control width100"
                :disabled="false"
                item-label="郵便番号"
                :label-prefix="office.area_nm"
                rules="required|min:7"
                @blur="postalCodeSearch(office.post_code, index)"
              />
              <ValidationSelect
                v-model="office.prefectures"
                item-label="都道府県"
                :label-prefix="office.area_nm"
                placeholder=""
                :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list[0].state_list : []"
                :disabled="false"
                classes="width110"
                rules="required"
                @change="prefecturesChanged(index)"
              />
              <ValidationSelect
                v-model="office.city_code"
                item-name="city_code"
                classes="length30"
                item-label="市区"
                :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list[0].city_list : []"
                :disabled="false"
              />
              <ValidationText
                v-model="office.town"
                max-length="50"
                classes="form-control width300"
                :disabled="false"
                item-label="町村"
                :label-prefix="office.area_nm"
                rules="required"
              />
              <ValidationText
                v-model="office.building"
                max-length="30"
                classes="form-control width300"
                :disabled="false"
                item-label="ビル／番地"
                :label-prefix="office.area_nm"
              />
              <ValidationText
                v-model="office.telephone1"
                max-length="20"
                classes="form-control length10"
                :disabled="false"
                item-label="電話番号"
                :label-prefix="office.area_nm"
                item-name="tel"
                rules="required"
              />
              <ValidationText
                v-model="office.fax"
                max-length="20"
                classes="form-control length10"
                :disabled="false"
                item-label="ファックス番号"
                :label-prefix="office.area_nm"
                item-name="fax"
              />
              <ValidationSelect
                v-model="office.industry1"
                item-label="業種（大分類）"
                :label-prefix="office.area_nm"
                :options="industries1_full"
                :disabled="false"
                item-name="industry1"
                rules="required"
                @change="industry1Changed(index)"
              />
              <ValidationSelect
                v-model="office.industry2"
                item-label="業種（中分類）"
                :label-prefix="office.area_nm"
                :options="office.industries2"
                :disabled="false"
                item-name="industry2"
                rules="required"
                @change="industry2Changed(index)"
                :key="componentKey2"
               />
              <ValidationSelect
                v-model="office.industry3"
                item-label="業種（小分類）"
                :label-prefix="office.area_nm"
                :options="office.industries3"
                :disabled="false"
                item-name="industry3"
                help-text="総務省　日本標準産業分類に準拠"
                rules="required"
                :key="componentKey3"
              />
              <ValidationRadio
                v-model="office.special_measures"
                rules="required"
                item-label="特例措置対象事業場"
                :label-prefix="office.area_nm"
                :help-text="$t('help.special_measures')"
                :disabled="false"
              />
              <table class="special-measures" style="margin: -20px 0 20px 0;">
                <caption>特例措置対象事業場の対象となる業種</caption>
                <tr><td></td><td style="text-align: center;">該当する業種</td></tr>
                <tr><td>商業</td><td>卸売業、小売業、理美容業、倉庫業、駐車場業、不動産管理業、出版業（印刷部門を除く。）その他の商業</td></tr>
                <tr><td>映画・演劇業</td><td>映画の映写、演劇、その他興業の事業（映画製作・ビデオ制作の事業を除く。）</td></tr>
                <tr><td>保健衛生業</td><td>病院、診療所、保育園、老人ホーム等の社会福祉施設、浴場業（個室付浴場業を除く。）、その他の保健衛生業</td></tr>
                <tr><td>接客娯楽業</td><td>旅館、飲食店、ゴルフ場、公園・遊園地、その他の娯楽業</td></tr>
              </table>
              <ValidationRadio
                v-model="office.regulatory_grace_exclusion"
                rules="required"
                item-label="上限規制の適用を猶予・除外"
                :label-prefix="office.area_nm"
                :disabled="false"
              />
              <ValidationText
                v-model="office.reference_number"
                classes="form-control width100"
                max-length="4"
                :disabled="false"
                item-label="整理番号（税務署から付与された番号）"
                :label-prefix="office.area_nm"
                rules="decimal"
              />
              <ValidationTimes
                v-model="office.job_total_minutes"
                item-name="job_total_minutes"
                classes="select-success length2"
                rules="required"
                item-label="所定労働時間"
                :label-prefix="office.area_nm"
                :disabled="false"
                :labels="labels"
              />
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimes'
  import mixin from '@/lib/mixin'
  import { Loader } from 'google-maps'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationRadio,
      ValidationTimes
    },
    data () {
      return {
        labels: {},
        error: '',
        componentKey: 0,
        componentKey2: 1000,
        componentKey3: 2000,
        geocoder: {},
        office: {},
        offices: [],
        industries1_full: [],
        industries2_full: [],
        industries3_full: [],
        industry_mapping_big_and_during: [],
        industry_mapping_during_and_small: [],
        prefectures: [{label: '愛知', value: '22'}, {label: '岐阜', value: '23'}, {label: '三重', value: '24'}],
        comboData: {},
        google: null
      }
    },
    methods: {
      copyAddress () {
        for (let i = 0; i < this.offices.length; i++) {
          this.offices[i].post_code = this.office.post_code
          this.offices[i].prefectures = this.office.prefectures
          this.offices[i].city_code = this.office.city_code
          this.offices[i].town = this.office.town
          this.offices[i].building = this.office.building
          this.offices[i].telephone1 = this.office.telephone1
          this.offices[i].fax = this.office.fax
          this.postalCodeSearch(this.offices[i].post_code, i)
        }
        this.componentKey += 1
      },
      copyIndustry () {
        for (const place of this.offices) {
          place.industry1 = this.office.industry1
          place.industry2 = this.office.industry2
          place.industry3 = this.office.industry3
          place.industry1_before = place.industry1
          place.industry2_before = place.industry2
        }
        this.componentKey += 1
      },
      industry1Changed (index) {
        if (this.offices[index].industry1_before) {
          this.offices[index].industry2 = ''
          this.offices[index].industry3 = ''
        }
        this.offices[index].industry1_before = this.offices[index].industry1
        this.offices[index].industries2 = []
        for (const code of this.industry_mapping_big_and_during[this.offices[index].industry1]) {
          for (const entry of this.industries2_full) {
            if (code === entry.value) {
              this.offices[index].industries2.push(entry)
            }
          }
        }
        this.componentKey2 += 1
      },
      industry2Changed (index) {
        if (this.offices[index].industry2_before) {
          this.offices[index].industry3 = ''
        }
        if (this.offices[index].industry2) {
          this.offices[index].industry2_before = this.offices[index].industry2
          this.offices[index].industries3 = []
          for (const code of this.industry_mapping_during_and_small[this.offices[index].industry2]) {
            for (const entry of this.industries3_full) {
              if (code === entry.value) {
                this.offices[index].industries3.push(entry)
              }
            }
          }
        } else {
          this.industries3 = [...this.industries3_full]
        }
        this.componentKey3 += 1
      },
      postalCodeSearch (postalCode, index) {
        if (!postalCode) return
        let self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
            if (status === 'OK') {
              const myLatLng = results[0].geometry.location
              self.offices[index].lat = String(myLatLng.lat())
              self.offices[index].lng = String(myLatLng.lng())
            }
          })
        } else {
          this.offices[index].lat = '0.000000'
          this.offices[index].lng = '0.000000'
        }
      },
      prefecturesChanged (index) {
        if (this.offices[index].prefectures_before) {
          this.offices[index].city = ''
        }
        if (this.offices[index].prefectures) {
          this.offices[index].prefectures_before = this.offices[index].prefectures
          this.comboData.state_municipality_mapping_list[0].city_list = []
          if (!this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.offices[index].prefectures]) {
            for (const prefecture of this.comboData.state_codes_list) {
              if (prefecture.label === this.offices[index].prefectures) {
                this.offices[index].prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.offices[index].prefectures]) {
            for (const code of this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.offices[index].prefectures]) {
              for (const entry of this.comboData.state_municipality_mapping_list[0].municipality_list) {
                if (code === entry.value) {
                  this.comboData.state_municipality_mapping_list[0].city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.state_municipality_list.city_list = []
        }
      },
      initGecoder () {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async function (position) {
              if (!this.google) {
                this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
              }
              this.geocoder = new this.google.maps.Geocoder()
            }.bind(this),
            function () {}
          )
        }
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        // this.office = migrationData.place_info.office
        this.offices = migrationData.place_info.offices
        this.industries1_full = migrationData.combo_data.industries1
        this.industries2_full = migrationData.combo_data.industries2
        this.industries3_full = migrationData.combo_data.industries3
        this.prefectures = migrationData.combo_data.prefectures
        this.industry_mapping_big_and_during = migrationData.combo_data.industry_mapping_big_and_during
        this.industry_mapping_during_and_small = migrationData.combo_data.industry_mapping_during_and_small
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_place', params)
        .then(res => {
          // this.office = res.init_data.baes_data
          this.offices = res.init_data.after_data.place_list
          this.prefectures = res.common_init_data.combo_data.prefectural_list
          this.comboData = res.common_init_data.combo_data
          this.comboData.state_municipality_mapping_list[0].city_list = res.common_init_data.combo_data.state_municipality_mapping_list[0].municipality_list
          this.industries1_full = res.common_init_data.combo_data.industry_codes_big_list
          this.industries2_full = res.common_init_data.combo_data.industry_codes_during_list
          this.industries3_full = res.common_init_data.combo_data.industry_codes_small_list
          this.industry_mapping_big_and_during = res.init_data.industry_mapping_big_and_during
          this.industry_mapping_during_and_small = res.init_data.industry_mapping_during_and_small
          for (const element of this.offices) {
            element.industries2 = [...this.industries2_full]
            element.industries3 = [...this.industries3_full]
            const items = []
            for (const row of res.common_init_data.combo_data.special_measures_list) {
              items.push({
                id: row.value,
                label: this.getLabel(row.label),
                value: String(row.value)
              })
            }
            element.special_measures = {
              value: res.api_area ? String(res.api_area.special_measures) : '2',
              disabled: false,
              items: items
            }
            element.regulatory_grace_exclusion = {
              value: res.api_area ? res.api_area.regulatory_grace_exclusion ? '1' : '2' : '2',
              disabled: false,
              items: items
            }
            element.job_total_minutes = '480'
          }
          this.componentKey += 1
        })
      }
      this.initGecoder()
    }
  }
</script>
<style scoped>
  .special-measures, .special-measures td {
    border-collapse: collapse;
    border: 1px solid #333;
    padding: 2px 10px;
  }
</style>
