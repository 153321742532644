<template>
  <div class="form-group">
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" :bails="false" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <small class="help-block" v-show="valid | !validated">
        {{ '　'}}
      </small>
      <template v-if="!valid">
        <div style="margin: 0; padding: 0; line-height: 120%;" v-for="error in errors" v-bind:key="error">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(error) }}
          </small>
        </div>
      </template>
      <StartEndTimeHoliday
        v-model="startEndTimeHoliday"
        :item-label="itemLabel"
        :item-name="itemName"
        :max-length="maxLength"
        :classes="classes"
        :select-classes="selectClasses"
        :options="options"
        :placeholder1="placeholder1"
        :placeholder2="placeholder2"
        :disabled="disabled"
        @blur="blur"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import StartEndTimeHoliday from '@/components/UIComponents/Inputs/StartEndTimeHoliday'

  export default {
    mixins: [mixin],
    components: {
      StartEndTimeHoliday
    },
    props: {
      value: Array,
      itemName: String,
      maxLength: String,
      classes: String,
      selectClasses: String,
      options: Array,
      rules: String,
      itemLabel: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      helpText: String
    },
    data () {
      return {
        startEndTimeHoliday: ['', '', false, false, '']
      }
    },
    watch: {
      startEndTimeHoliday () {
        this.$emit('input', this.startEndTimeHoliday)
      },
      value () {
        this.startEndTimeHoliday = this.value
      }
    },
    mounted () {
      this.startEndTimeHoliday = this.value
    },
    methods: {
      blur () {
        this.$emit('input', this.startEndTimeHoliday)
        this.$emit('blur')
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>