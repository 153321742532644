import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

export default {
  name: 'JournalListMixin',
  mixins: [mixin],
  components: {
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        debit_total_amount: 0,
        credit_total_amount: 0
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      payment_data: [{}],
      deduction_data: [{}],
      salary_closing_data: [{}],
      bonusPaySlipName: ''
    }
  },
  computed: {
    screenCode () {
      return 'SS-024'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
    csvClicked () {
      let params = {
        screen_code: 'SS-024',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_date,
          salary_bonus_classification: this.params.salary_bonus_classification,
          salary_closing_code: this.params.salary_closing_code
        }
      }
      this.send('/print/journal_list', params)
      .then(res => {
        swal({
          text: this.$t('message.report_confirmation').replace('PDF', 'CSV'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    // カンマ付与(detailを書き換えます)
    formatComma (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatNumber(String(this.detail[name]).replaceAll(',', ''))
      }
      return this.detail[name]
    },
    formatTime (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatDispMinute(parseInt(this.detail[name]))
      }
      return this.detail[name]
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.salary_closing_data = res.api_area.salary_closing_data
      this.labels.Account = this.getLabel(res.common_area.label.Account)
      this.labels.SubAccount = this.getLabel(res.common_area.label.SubAccount)
      this.bonusPaySlipName = res.api_area.bonus_pay_slip_name
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    init () {
      let data = {
        screen_code: 'SS-024',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_date,
          salary_bonus_classification: this.params.salary_bonus_classification,
          salary_closing_code: this.params.salary_closing_code
        }
      }
      this.send('/init/journal_list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
