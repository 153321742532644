<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.application_classification_name"
              item-name="application_classification_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.application_classification_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.application_classification_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.sort_number"
              item-name="sort_number"
              max-length="5"
              classes="form-control numLength5 text-right"
              rules="required"
              :item-label="labels.sort_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.sort_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ApplicationClassificationDetailMixin from '@/components/Dashboard/Views/Operation/ApplicationClassificationDetailMixin'

  export default {
    name: 'ApplicationClassificationDetail',
    data () {
      return {
        errMsg: ''
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.group_list.unshift({label: '', value: ''})
        this.comboData.job_title_list.unshift({label: '', value: ''})
        this.detail.skip_apply_employee = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 1,
              label: this.$t('label.skip_apply_employee'),
              value: res.api_area.skip_apply_employee
            }
          ]
        }
        this.detail.auto_approverl_flag = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 2,
              label: this.$t('label.auto_approverl_flag').replace('{item}', this.labels.employee),
              value: res.api_area.auto_approverl_flag === 1
            }
          ]
        }
        this.detail.route_flag = {
          value: String(res.api_area.route_flag),
          disabled: this.isReadOnly,
          items: [
            {
              id: this.comboData.route_flag_list[0].value,
              label: this.getLabel(this.comboData.route_flag_list[0].label),
              value: this.comboData.route_flag_list[0].value
            },
            {
              id: this.comboData.route_flag_list[1].value,
              label: this.getLabel(this.comboData.route_flag_list[1].label),
              value: this.comboData.route_flag_list[1].value
            },
            {
              id: this.comboData.route_flag_list[2].value,
              label: this.getLabel(this.comboData.route_flag_list[2].label),
              value: this.comboData.route_flag_list[2].value
            }
          ]
        }
        this.detail.pulling_flag = {
          value: String(res.api_area.pulling_flag),
          disabled: this.isReadOnly,
          items: [
            {
              id: this.comboData.pulling_flag_list[0].value,
              label: this.getLabel(this.comboData.pulling_flag_list[0].label),
              value: this.comboData.pulling_flag_list[0].value
            },
            {
              id: this.comboData.pulling_flag_list[1].value,
              label: this.getLabel(this.comboData.pulling_flag_list[1].label),
              value: this.comboData.pulling_flag_list[1].value
            },
            {
              id: this.comboData.pulling_flag_list[2].value,
              label: this.getLabel(this.comboData.pulling_flag_list[2].label),
              value: this.comboData.pulling_flag_list[2].value
            }
          ]
        }
        this.detail.auto_approverl_flag = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 2,
              label: this.$t('label.auto_approverl_flag').replace('{item}', this.labels.employee),
              value: res.api_area.auto_approverl_flag === 1
            }
          ]
        }
        for (const row of res.api_area.application_form_route_data) {
          row.isNew = false
        }
        this.detail.route_allocation = res.api_area.application_form_route_data
        this.comboData.individual_route_list.unshift({label: '', value: ''})
        this.comboData.common_route_list.unshift({label: '', value: ''})
      }
    },
    mixins: [ApplicationClassificationDetailMixin]
  }
</script>
<style scoped>
</style>
