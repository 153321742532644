<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.target_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.target_date"
            item-name="target_date"
            rules="required"
            :itemLabel="labels.target_date"
            placeholder="2020-01-01"
            :disabled="(isReadOnly || application.is_fixed) && isModal"
            @input="$emit('initApply', application.target_date)"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <ValidationSelect
          v-model="application.work_schedule_code"
          item-name="work_schedule_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          :item-label="labels.workSchedule"
          :options="comboData.work_schedule_list"
          :disabled="(isReadOnly || application.is_fixed) && isModal"
          @change="$emit('initApply', application.target_date, application.work_schedule_code)"
        />
      </div>
      <div class="form-group" v-if="false" :key="componentKey">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.actual')}}</label>
        </div>
        <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_overwork_minutes">
          {{application.actual_overwork_minutes}}
        </div>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="$emit('showActual')">
          {{$t('button.show_actual')}}
        </button>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.overwork_minutes}}</label>
        </div>
        <div style="display: inline-block;">
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || application.is_fixed"
                      v-model="application.overtime_hours"
                      @change="hoursChanged">
            <el-option v-for="hour in hours"
                        class="select-success"
                        :value="hour"
                        :label="hour"
                        :key="hour">
            </el-option>
          </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
          <el-select class="select-success width70"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly || application.is_fixed || application.overtime_hours === '16'"
                      v-model="application.overtime_minutes">
            <el-option v-for="minute in minutes"
                        class="select-success"
                        :value="minute"
                        :label="minute"
                        :key="minute">
            </el-option>
          </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
          <small style="color: #C84513; margin-left: 5px;">{{errMsgs.length > 0 ? errMsgs[0] : ''}}</small>
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.reasons_over_work_contents}}</label>
        </div>
        <div style="display: inline-block;">
          <el-select class="select-success length50"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      @change="reasonChanged"
                      v-model="application.reasons_over_work_contents">
            <el-option v-for="row in comboData.reasons_over_work_contents_list"
                        class="select-success"
                        :value="row"
                        :label="row"
                        :key="row">
            </el-option>
          </el-select>
          <div style="margin-top: 20px;">
            <span class="strong-text">{{monthlyOverworkMinutes}}</span>
            <template v-if="!!application.reasons_over_work_contents">
              <br>
              <span class="strong-text">{{$t('label.label_limit_legal_one_month_minutes').replace('{hour}', String(parseInt(!application.limit_legal_one_month_minutes ? 0 : application.limit_legal_one_month_minutes / 60, 10))).replace('{minutes}', ('0' + String(!application.limit_legal_one_month_minutes ? 0 : application.limit_legal_one_month_minutes % 60)).slice(-2))}}</span>
              <br>
              <span class="strong-text">{{$t('label.label_remaining_monthly_overwork_minutes').replace('{hour}', !application.remaining_monthly_overwork_minutes ? '0' : (application.remaining_monthly_overwork_minutes >= 0 ? '' : '-') + String(parseInt(Math.abs(application.remaining_monthly_overwork_minutes) / 60, 10))).replace('{minutes}', ('0' + String(!application.remaining_monthly_overwork_minutes ? 0 : application.remaining_monthly_overwork_minutes % 60)).slice(-2))}}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.business_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.business_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_content')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    computed: {
      monthlyOverworkMinutes () {
        let monthlyOverworkMinutes = this.$t('label.label_monthly_overwork_minutes')
        monthlyOverworkMinutes = monthlyOverworkMinutes.replace('{hour}', String(parseInt(this.application.monthly_overwork_minutes / 60, 10)))
        monthlyOverworkMinutes = monthlyOverworkMinutes.replace('{minutes}', ('0' + String(this.application.monthly_overwork_minutes % 60)).slice(-2))
        return monthlyOverworkMinutes
      }
    },
    data () {
      return {
        componentKey: 0,
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16'
        ],
        minutes: [],
        orgMinutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        application: {}
      }
    },
    methods: {
      hoursChanged () {
        if (this.application.overtime_hours === '16') {
          this.application.overtime_minutes = '00'
        }
      },
      reasonChanged () {
        if (this.application.reasons_over_work_contents === this.application.reasons_over_work_contents_style1) {
          this.application.limit_legal_one_month_minutes = this.application.limit_legal_one_month_minutes_style1
        } else if (this.application.reasons_over_work_contents === this.application.reasons_over_work_contents_style2) {
          this.application.limit_legal_one_month_minutes = this.application.limit_legal_one_month_minutes_style2
        } else {
          this.application.limit_legal_one_month_minutes = 0
        }
        this.application.remaining_monthly_overwork_minutes = this.application.limit_legal_one_month_minutes - this.application.monthly_overwork_minutes
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
      if (this.application.overtime_hours === '0' && this.application.overtime_minutes === '00') {
        this.minutes = ['00', '15', '30', '45']
      } else {
        this.minutes = this.orgMinutes
      }
    }
  }
</script>
<style scoped>
</style>
