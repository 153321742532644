import MessageArea from '@/components/Dashboard/Views/Components/MessageArea'
import Help from '@/components/Dashboard/Views/Components/Help'
import swal from 'sweetalert2'
import { Loading } from 'element-ui'

export default {
  components: {
    MessageArea,
    Help
  },
  methods: {
    logout () {
      return this.$firebase.auth().signOut()
    },
    send (path, data, isOverwrite = true, isForceSuccess = false) {
      const url = `${process.env.VUE_APP_API_ORIGIN}${path}`
      return this.sendDirect(url, data, isOverwrite, isForceSuccess)
    },
    sendDirect (url, data, isOverwrite = true, isForceSuccess = false) {
      if (this.mixinProcessing && url === this.prevUrl) return Promise.reject(new Error('double submitted'))
      // 同一セッションに複数のアカウントで同時にログインした場合の対応
      const localStorageLoginUser = JSON.parse(localStorage.getItem('loginUser'))
      if (localStorageLoginUser && !this.$publicPaths.includes(window.currentUrl)) {
        if (localStorageLoginUser.companyCode !== this.$store.state.loginUser.companyCode ||
          localStorageLoginUser.employeeCode !== this.$store.state.loginUser.employeeCode) {
          swal({
            title: 'ログイン情報が変更されました',
            html: 'お手数ですがもう一度ログインをお願いします。',
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            window.location = '/'
          })
          return
        }
      }
      this.mixinProcessing = true
      this.prevUrl = url
      let self = this
      return new Promise(function (resolve, reject) {
        console.log('リクエスト', url, JSON.stringify(data))
        document.body.style.cursor = self.noLoadingPages.includes(url) ? 'default' : 'wait'
        let loadingInstance = self.noLoadingPages.includes(url) ? null : Loading.service({ fullscreen: true })
        const axiosWithCookies = self.$axios.create({
          withCredentials: url.indexOf(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN) === -1
        })
        axiosWithCookies.post(url, data)
        .then(response => {
          document.body.style.cursor = 'default'
          if (loadingInstance) {
            loadingInstance.close()
          }
          self.mixinProcessing = false
          self.initialized = true
          let res
          if (typeof response.data === 'object') {
            if (response.data.root) {
              res = response.data.root
              console.log('レスポンス', url, {...res})
              if (res.result_code) {
                console.error('result_codeが返却されています。')
                if (res.result_code === 0 || res.result_code === -1) {
                  if (res.result_code === -1) {
                    self.setMessage(res)
                  }
                  resolve(res)
                } else {
                  reject(new Error(res.message))
                }
              } else {
                self.help = res.common_area.help
                self.$store.commit('setAlertList', res.common_area.alert_list)
                if (res.message.message_classification === null || res.message.message_classification === 1) {
                  if (isOverwrite) {
                    if (res.message.correspondence_action === ' ') res.message.correspondence_action = null
                    self.message = res.message
                  }
                  resolve(res)
                } else {
                  let text = res.message.text.replace('{next}', '<br>')
                  if (res.api_area && res.api_area.open_employee_list && res.api_area.open_employee_list.length > 0) {
                    // 月末締め処理の場合は未承認の従業員リストを表示する。
                    let employeeList = ''
                    for (const row of res.api_area.open_employee_list) {
                      if (employeeList !== '') {
                        employeeList += '\n'
                      }
                      employeeList += row.employee_code + ' ' + row.employee_name
                    }
                    text = '<span title="' + employeeList + '">' + text + '</span>'
                  }
                  if (res.message.correspondence_action.length > 0) {
                    text = text + '<br>' + res.message.correspondence_action
                  }
                  swal({
                    html: `<div style="text-align: left; font-size: 18px;">${text}</div>`,
                    type: 'error',
                    confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                    buttonsStyling: false,
                    allowOutsideClick: false,
                    width: 600
                  }).then(() => {
                    if (url === `${process.env.VUE_APP_API_ORIGIN}/record_closing/update/month_closing` || url === `${process.env.VUE_APP_API_ORIGIN}/record_closing/update/month_salary_closing`) {
                      self.$refs.frame.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
                      return
                    }
                    reject(new Error('システムエラー'))
                  })
                  if (isForceSuccess) {
                    resolve(res)
                  }
                }
              }
            } else if (response.data.json_data) {
              console.error('rootがありません。')
              res = JSON.parse(response.data.json_data)
              console.log('レスポンス', url, res)
              if (res.result_code === '0') {
                resolve(res)
              } else {
                reject(new Error(res.message))
              }
            } else {
              console.error('rootがありません。')
              console.log('レスポンス', url, response.data)
              resolve(response.data)
            }
          } else {
            console.error('レスポンスがオブジェクトではありません。')
            if (response.data.substring(0, 1) === '{') {
              res = JSON.parse(response.data)
              console.log('レスポンス', url, res)
              if (res.result_code) {
                if (res.result_code === '0') {
                  resolve(res)
                } else {
                  reject(new Error(res.message))
                }
              } else {
                resolve(res)
              }
            } else {
              console.log('レスポンス', url, response.data)
              resolve(response.data)
            }
          }
        })
        .catch(error => {
          document.body.style.cursor = 'default'
          if (loadingInstance) {
            loadingInstance.close()
          }
          self.mixinProcessing = false
          self.initialized = true
          console.log('エラー', error)
          let msg = ''
          if (error.response) {
            if (error.response.status === 400) {
              // ToDo 入力エラー処理
              console.log(error.response.data)
              if (error.response.data.root) {
                let errObj = error.response.data.root
                if (errObj.message !== undefined && errObj.message !== null) {
                  if (typeof errObj.message === 'object') {
                    self.logout().then(() => {
                      self.$nextTick(() => {
                        self.$router.push({ name: 'internalServerError' })
                      })
                    })
                    return
                  } else {
                    let none = '<div style="text-align:left;">' + errObj.message.split(',')[0].replace('{next}', '</div><div style="text-align:left;">') + '</div>'
                    if (errObj.count_message_list !== undefined && errObj.count_message_list !== null) {
                      for (let i = 0; i < errObj.count_message_list.length; i++) {
                        none = none + '<br>' + errObj.count_message_list[i]
                      }
                    }
                    swal({
                      html: none,
                      type: 'error',
                      confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                      buttonsStyling: false,
                      allowOutsideClick: false
                    }).then(() => {
                      reject(new Error('リクエストエラー'))
                    })
                  }
                } else {
                  reject(new Error(JSON.stringify(error.response.data.root)))
                }
              } else {
                let errObj = error.response.data
                if (errObj.error_field.error_message !== undefined && errObj.error_field.error_message !== null) {
                  let none = '<div style="text-align:left;">' + errObj.error_field.error_message.replace('{next}', '</div><div style="text-align:left;">') + '</div>'
                  if (errObj.error_field.count_message_list !== undefined && errObj.error_field.count_message_list !== null) {
                    for (let i = 0; i < errObj.error_field.count_message_list.length; i++) {
                      none = none + '<br>' + errObj.error_field.count_message_list[i]
                    }
                  }
                  swal({
                    html: none,
                    type: 'error',
                    confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                    buttonsStyling: false,
                    allowOutsideClick: false
                  }).then(() => {
                    reject(new Error('システムエラー'))
                  })
                } else {
                  reject(new Error(JSON.stringify(error.response.data)))
                }
              }
              return
            } else if (error.response.status === 401) {
              swal({
                title: 'Artemis Collierへの接続が切断されました',
                html: '一定時間操作がありませんでしたのでネットワークを切断させて頂きました。お手数ですがもういちどログインをお願いします。',
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false,
                width: 600
              }).then(() => {
                window.location = '/'
              })
              return
            } else if (error.response.status === 409) {
              reject(new Error(JSON.stringify(error.response.data)))
              return
            } else if (error.response.status === 500) {
              console.log(error.response.data)
              // self.logout().then(() => {
              //   self.$nextTick(() => {
              //     self.$router.push({ name: 'internalServerError' })
              //   })
              // })
              swal({
                title: 'ただいまシステムエラーが発生しております',
                html: '復旧対応を進めておりますので今しばらくお待ちください。<br>ご迷惑をおかけして申し訳ございません。',
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false,
                width: 600
              })
              return
            } else {
              msg = `${error.response.status}:${error.response.statusText}`
            }
          } else if (error.request) {
            msg = self.$t('message.communication_error')
          } else {
            msg = self.$t('message.unexpected_error')
          }
          swal({
            title: self.$t('message.system_error_title'),
            text: msg,
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            reject(new Error('システムエラー'))
          })
        })
      })
    }
  },
  data () {
    return {
      noLoadingPages: [
        `${process.env.VUE_APP_API_ORIGIN}/init/report/list`,
        `${process.env.VUE_APP_API_ORIGIN}/init/work_status/list`
      ],
      prevUrl: '',
      mixinProcessing: false,
      initialized: false,
      pageType: {
        search: 1, // 検索画面
        detail: 2, // 詳細ダイアログ
        sheet: 3, // 単票形式
        proxyApproval: 4 // 代理承認者設定
      },
      message: {},
      help: ''
    }
  }
}
