<template>
  <div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      style="width: 900px;"
      :data="tableData"
      :empty-text="labels.label_data_none"
      data-v-step="0"
    >
      <el-table-column
        min-width="70"
        :label="labels.months_of_service_ryaku ? labels.months_of_service_ryaku : ''"
        align="center"
        :key="componentKey"
      >
        <template slot-scope="props">
          <div style="text-align: left;">
            <div style="display: inline-block;">
              <span class="strong-text">{{props.row.months_of_service + ' ' + $t('label.months')}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="50"
        :label="$t('label.years_of_service')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: left;">{{props.row.years_of_service}}</div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="80"
        :label="$t('label.working_days_5')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;">
            <div style="display: inline-block;">
              <input
                type="text"
                maxlength="2"
                class="form-control numLength2 text-right"
                :class="{'errorBorder': props.row.isEmployeeError}"
                style="display: inline-block;"
                v-model="props.row.working_days_5"
                :disabled="props.row.isFixed"
              />&nbsp;{{$t('label.day')}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="80"
        :label="$t('label.working_days_4')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;">
            <div style="display: inline-block;">
              <input
                type="text"
                maxlength="2"
                class="form-control numLength2 text-right"
                :class="{'errorBorder': props.row.isEmployeeError}"
                style="display: inline-block;"
                v-model="props.row.working_days_4"
                :disabled="props.row.isFixed"
              />&nbsp;{{$t('label.day')}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="80"
        :label="$t('label.working_days_3')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;">
            <div style="display: inline-block;">
              <input
                type="text"
                maxlength="2"
                class="form-control numLength2 text-right"
                :class="{'errorBorder': props.row.isEmployeeError}"
                style="display: inline-block;"
                v-model="props.row.working_days_3"
                :disabled="props.row.isFixed"
              />&nbsp;{{$t('label.day')}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="80"
        :label="$t('label.working_days_2')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;">
            <div style="display: inline-block;">
              <input
                type="text"
                maxlength="2"
                class="form-control numLength2 text-right"
                :class="{'errorBorder': props.row.isEmployeeError}"
                style="display: inline-block;"
                v-model="props.row.working_days_2"
                :disabled="props.row.isFixed"
              />&nbsp;{{$t('label.day')}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="80"
        :label="$t('label.working_days_1')"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;">
            <div style="display: inline-block;">
              <input
                type="text"
                maxlength="2"
                class="form-control numLength2 text-right"
                :class="{'errorBorder': props.row.isEmployeeError}"
                style="display: inline-block;"
                v-model="props.row.working_days_1"
                :disabled="props.row.isFixed"
              />&nbsp;{{$t('label.day')}}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Tour tour-name="myTour" :steps="steps"/>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import Tour from '@/components/UIComponents/Inputs/Tour'

  export default {
    mixins: [mixin],
    components: {
      Tour
    },
    props: {
      value: Array,
      labels: Object,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        tableData: [],
        steps: [
          {
            target: '[data-v-step="0"]',
            content: '年次付与する有給日数を入力してください。ルール設定から自動付与されます。',
            params: {placement: 'top'}
          }
        ]
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      calcYearsOfService (row) {
        if (isNaN(row.months_of_service)) {
          row.years_of_service = ''
        } else {
          const year = Math.floor(row.months_of_service / 12)
          const month = row.months_of_service % 12
          let yearsOfService = year === 0 ? '' : year + this.$t('label.year')
          yearsOfService += month + this.$t('label.months')
          row.years_of_service = yearsOfService
        }
        this.componentKey += 1
      },
      addRow () {
        this.tableData.unshift({})
      }
    }
  }
</script>

<style scoped>
</style>