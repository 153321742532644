import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
import ValidationTransformationMonthly from '@/components/UIComponents/Inputs/ValidationTransformationMonthly'

export default {
  name: 'TransformationMonthlyDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect,
    ValidationDate,
    ValidationRadio,
    ValidationTransformationMonthly
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {
        office_code: '',
        business_type: '',
        transformation_monthly: {},
        agreement_date: '',
        agreement_parties_job_title_code: '',
        agreement_parties_employee_code: '',
        election_method: '',
        filing_date: '',
        management_parties_job_title_code: '',
        management_parties_employee_code: '',
        labor_standards_inspection_office: '',
        is_job_before_start_time: {}
      },
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '07-004'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    officeCodeChanged () {},
    businessTypeChanged () {},
    agreementPartiesJobTitleCodeChanged () {},
    agreementPartiesEmployeeCodeChanged () {},
    electionMethodChanged () {},
    managementPartiesJobTitleCodeChanged () {},
    managementPartiesEmployeeCodeChanged () {},
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation, confirmed = null) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {transformation_monthly_code: this.detail.transformation_monthly_code}
          detail.transformation_monthly_name = this.detail.transformation_monthly_name
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        if (confirmed !== null) {
          reqData.api_area.confirmed = confirmed
        }
        this.send(`/transformationTimeLabor/${operation}/monthly`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code,
          starting_date: this.params.starting_date,
          business_type: this.params.business_type
        }
      }
      this.send('/transformationTimeLabor/init/monthly', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
