<template>
  <div :class="isSearchCondition ? 'form-group search-condition-item' : 'form-group'">
    <label v-if="itemLabel">{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <small class="text-danger" v-show="!valid">
          {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
        <ValidationCheckbox
          v-model="value"
          :disabled="disabled"
          :columns="columns"
          :item-label="itemLabel"
          @change="checkboxChanged"
          :show-check-all="showCheckAll"
        />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import ValidationCheckbox from '@/components/UIComponents/Inputs/ValidationCheckbox'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationCheckbox
    },
    props: {
      rules: String,
      value: Object,
      itemLabel: String,
      disabled: Boolean,
      columns: {
        type: Number,
        require: false,
        'default': 99
      },
      isSearchCondition: {
        type: Boolean,
        require: false,
        'default': false
      },
      showCheckAll: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        obj: {}
      }
    },
    methods: {
      checkboxChanged (obj) {
        this.$emit('change', obj)
      }
    },
    watch: {
      itemArray () {
        this.obj = this.value
      }
    },
    mounted () {
      this.obj = this.value
    }
  }
</script>

<style scoped>
</style>