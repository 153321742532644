<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <ValidationSelect
            v-model="searchCond.menu_code"
            item-name="menu_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.menu_list)"
            :item-label="labels.menu_code_ryaku === undefined ? labels.menuCode_ryaku === undefined ? '' : labels.menuCode_ryaku : labels.menu_code_ryaku"
            :options="comboData.menu_list"
            frame-class="search-condition-item"
            @change="menuCodeChanged"
          />
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.choice_screen_code}}</label>
            <input type="text" :placeholder="$t('placeholder.choice_screen_code')" maxlength="6" class="form-control length6" v-model="searchCond.choice_screen_code">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.keyword}}</label>
            <input type="text" :placeholder="$t('placeholder.keyword')" maxlength="30" class="form-control length60" v-model="searchCond.keyword">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.vue_path}}</label>
            <input type="text" :placeholder="$t('placeholder.vue_path')" maxlength="30" class="form-control length30" v-model="searchCond.vue_path">
          </div>
          <ValidationSelect
            v-model="searchCond.system_screen_flg"
            item-name="system_screen_flg"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.system_screen_flg_list)"
            :item-label="labels.system_screen_flg_ryaku === undefined ? labels.systemScreenFlg_ryaku === undefined ? '' : labels.systemScreenFlg_ryaku : labels.system_screen_flg_ryaku"
            :options="comboData.system_screen_flg_list"
            frame-class="search-condition-item"
            @change="systemScreenFlgChanged"
          />
          <ValidationSelect
            v-model="searchCond.screen_type"
            item-name="screen_type"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.screen_type_list)"
            :item-label="labels.screen_type_ryaku === undefined ? labels.screenType_ryaku === undefined ? '' : labels.screenType_ryaku : labels.screen_type_ryaku"
            :options="comboData.screen_type_list"
            frame-class="search-condition-item"
            @change="screenTypeChanged"
          />
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.contents}}</label>
            <input type="text" :placeholder="$t('placeholder.contents2')" maxlength="30" class="form-control length60" v-model="searchCond.contents">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.release_version}}</label>
            <input type="text" :placeholder="$t('placeholder.release_version')" maxlength="5" class="form-control length5" v-model="searchCond.release_version">
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showMenu" :width="getColumnWidthStrong(tableData, labels.menu_ryaku === undefined ? labels.menu_ryaku === undefined ? '' : labels.menu_ryaku : labels.menu_ryaku, 'menu_code', 'menu_name')" :label="labels.menu_ryaku === undefined ? labels.menu_ryaku === undefined ? '' : labels.menu_ryaku : labels.menu_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.menu_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.menu_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showChoiceScreen" :width="240" :label="labels.choice_screen_ryaku === undefined ? labels.choiceScreen_ryaku === undefined ? '' : labels.choiceScreen_ryaku : labels.choice_screen_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text" style="text-align: left;">
                    {{props.row.screen_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.choice_screen_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showIconName" :width="getColumnWidth(tableData, labels.icon_name_ryaku === undefined ? labels.iconName_ryaku === undefined ? '' : labels.iconName_ryaku : labels.icon_name_ryaku, 'icon_name')" :label="labels.icon_name_ryaku === undefined ? labels.iconName_ryaku === undefined ? '' : labels.iconName_ryaku : labels.icon_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.icon_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showSortNumber" :width="getColumnWidth(tableData, labels.sort_number_ryaku === undefined ? labels.sortNumber_ryaku === undefined ? '' : labels.sortNumber_ryaku : labels.sort_number_ryaku, 'sort_number')" :label="labels.sort_number_ryaku === undefined ? labels.sortNumber_ryaku === undefined ? '' : labels.sortNumber_ryaku : labels.sort_number_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.sort_number)}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showEntry" :width="getColumnWidth(tableData, labels.entry_ryaku === undefined ? labels.entry_ryaku === undefined ? '' : labels.entry_ryaku : labels.entry_ryaku, 'entry')" :label="labels.entry_ryaku === undefined ? labels.entry_ryaku === undefined ? '' : labels.entry_ryaku : labels.entry_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.entry}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpdate" :width="getColumnWidth(tableData, labels.update_ryaku === undefined ? labels.update_ryaku === undefined ? '' : labels.update_ryaku : labels.update_ryaku, 'update')" :label="labels.update_ryaku === undefined ? labels.update_ryaku === undefined ? '' : labels.update_ryaku : labels.update_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showDelete" :width="getColumnWidth(tableData, labels.delete_ryaku === undefined ? labels.delete_ryaku === undefined ? '' : labels.delete_ryaku : labels.delete_ryaku, 'delete')" :label="labels.delete_ryaku === undefined ? labels.delete_ryaku === undefined ? '' : labels.delete_ryaku : labels.delete_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.delete}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPrint" :width="getColumnWidth(tableData, labels.print_ryaku === undefined ? labels.print_ryaku === undefined ? '' : labels.print_ryaku : labels.print_ryaku, 'print')" :label="labels.print_ryaku === undefined ? labels.print_ryaku === undefined ? '' : labels.print_ryaku : labels.print_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.print}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showSearch" :width="getColumnWidth(tableData, labels.search_ryaku === undefined ? labels.search_ryaku === undefined ? '' : labels.search_ryaku : labels.search_ryaku, 'search')" :label="labels.search_ryaku === undefined ? labels.search_ryaku === undefined ? '' : labels.search_ryaku : labels.search_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.search}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpload" :width="getColumnWidth(tableData, labels.upload_ryaku === undefined ? labels.upload_ryaku === undefined ? '' : labels.upload_ryaku : labels.upload_ryaku, 'upload')" :label="labels.upload_ryaku === undefined ? labels.upload_ryaku === undefined ? '' : labels.upload_ryaku : labels.upload_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.upload}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showDownload" :width="getColumnWidth(tableData, labels.download_ryaku === undefined ? labels.download_ryaku === undefined ? '' : labels.download_ryaku : labels.download_ryaku, 'download')" :label="labels.download_ryaku === undefined ? labels.download_ryaku === undefined ? '' : labels.download_ryaku : labels.download_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.download}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showPreview" :width="getColumnWidth(tableData, labels.preview_ryaku === undefined ? labels.preview_ryaku === undefined ? '' : labels.preview_ryaku : labels.preview_ryaku, 'preview')" :label="labels.preview_ryaku === undefined ? labels.preview_ryaku === undefined ? '' : labels.preview_ryaku : labels.preview_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.preview}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showSystemScreenFlg" :width="getColumnWidth(comboData.system_screen_flg_list, labels.system_screen_flg_ryaku === undefined ? labels.systemScreenFlg_ryaku === undefined ? '' : labels.systemScreenFlg_ryaku : labels.system_screen_flg_ryaku, 'label', 'value')" :label="labels.system_screen_flg_ryaku === undefined ? labels.systemScreenFlg_ryaku === undefined ? '' : labels.systemScreenFlg_ryaku : labels.system_screen_flg_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.system_screen_flg, comboData.system_screen_flg_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showScreenType" :width="getColumnWidth(comboData.screen_type_list, labels.screen_type_ryaku === undefined ? labels.screenType_ryaku === undefined ? '' : labels.screenType_ryaku : labels.screen_type_ryaku, 'label', 'value')" :label="labels.screen_type_ryaku === undefined ? labels.screenType_ryaku === undefined ? '' : labels.screenType_ryaku : labels.screen_type_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.screen_type, comboData.screen_type_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showContents" :width="getColumnWidth(tableData, labels.contents_ryaku === undefined ? labels.contents_ryaku === undefined ? '' : labels.contents_ryaku : labels.contents_ryaku, 'contents')" :label="labels.contents_ryaku === undefined ? labels.contents_ryaku === undefined ? '' : labels.contents_ryaku : labels.contents_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.contents}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showReleaseVersion" :width="getColumnWidth(tableData, labels.release_version_ryaku === undefined ? labels.releaseVersion_ryaku === undefined ? '' : labels.releaseVersion_ryaku : labels.release_version_ryaku, 'release_version')" :label="labels.release_version_ryaku === undefined ? labels.releaseVersion_ryaku === undefined ? '' : labels.releaseVersion_ryaku : labels.release_version_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.release_version}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import ScreenSearchMixin from '@/components/Dashboard/Views/Generated/ScreenSearchMixin'

  export default {
    name: 'ScreenSearch',
    methods: {
      afterInit (res) {
        this.labels.choice_screen_code = this.labels.screen_code
        this.comboData.menu_list.unshift({label: '', value: ''})
        this.comboData.system_screen_flg_list.unshift({label: '', value: ''})
        this.comboData.screen_type_list.unshift({label: '', value: ''})
      },
      convertSearchCond (searchCond) {
        if (searchCond.release_version === '') {
          searchCond.release_version = null
        }
        if (searchCond.screen_type === '') {
          searchCond.screen_type = null
        } else {
          searchCond.screen_type = parseInt(searchCond.screen_type, 10)
        }
        if (searchCond.system_screen_flg === '') {
          searchCond.system_screen_flg = null
        } else {
          searchCond.system_screen_flg = parseInt(searchCond.system_screen_flg, 10)
        }
        searchCond.release_version = parseFloat(searchCond.release_version)
        return searchCond
      },
      afterSearch (res) {
        this.labels.choice_screen_ryaku = this.labels.screen
        for (const row of this.tableData) {
          row.entry = row.entry === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.update = row.update === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.delete = row.delete === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.print = row.print === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.search = row.search === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.upload = row.upload === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.download = row.download === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
          row.preview = row.preview === 1 ? this.$t('label.can_set') : this.$t('label.cannot_set')
        }
      }
    },
    mixins: [ScreenSearchMixin]
  }
</script>
<style scoped>
</style>
