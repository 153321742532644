<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{$t('label.journal_list')}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <h5>
              <strong>{{params.target_date.substring(0, 4)}}{{$t('label.year')}}{{params.target_date.substring(4, 6)}}{{$t('label.monthly')}}{{ params.salary_bonus_classification === 2 ? bonusPaySlipName : '' }}</strong>
            </h5>
            <!-- 締め日別で繰り返し表示 -->
            <div
              v-for="salary_closing_data  in salary_closing_data"
              v-bind:key="salary_closing_data.salary_closing_name"
            >
              <table>
                <tr>
                  <td colspan="5" width="450">
                    <label>{{salary_closing_data.salary_closing_name}}</label>
                  </td>
                  <td width="150"></td>
                  <td width="100"></td>
                </tr>
              </table>
              <hr />

              <!-- Table開始 -->
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="salary_closing_data.journal_data"
                :empty-text="$t('label.no_data')"
                header-cell-class-name="border-style-none"
                ref="table"
              >
                <!-- 借方 -->
                <!-- 勘定科目・補助科目 -->
                <el-table-column :label="$t('label.debit')">
                  <el-table-column
                    :label="labels.account_name === undefined ? '' : labels.account_name"
                  >
                    <el-table-column
                      :width="200"
                      :label=" labels.sub_account_name === undefined ? '' : labels.sub_account_name"
                    >
                      <template slot-scope="props">
                        <template
                          v-if="props.row.account_code && props.row.account_name"
                        >{{props.row.account_code}}:{{props.row.account_name}}</template>
                        <template v-else>&nbsp;</template>
                        <br />
                        <template
                          v-if="props.row.sub_account_code && props.row.sub_account_name"
                        >{{props.row.sub_account_code}}:{{props.row.sub_account_name}}</template>
                        <template v-else>&nbsp;</template>
                        <br />
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 税区分・部門 -->
                  <el-table-column :label="labels.tax_type === undefined ? '' : labels.tax_type">
                    <el-table-column
                      :width="150"
                      :label="labels.department === undefined ? '' : labels.department"
                    >
                      <template slot-scope="props">
                        {{props.row.tax_type ? getLabel(getSelectName(props.row.tax_type, comboData.tax_type_list)) : '&nbsp;'}}
                        <br />
                        {{props.row.department ? props.row.department : '&nbsp;'}}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 金額 -->
                  <el-table-column
                    :width="100"
                    :label="labels.debit_amount === undefined ? '' : labels.debit_amount"
                  >
                    <template slot-scope="props">
                      <div style="text-align: right;">{{formatNumber(props.row.debit_amount)}}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <!-- 貸方 -->
                <el-table-column :label="$t('label.credit')">
                  <!-- 勘定科目・補助科目 -->
                  <el-table-column :label="$t('label.partner_account_name')">
                    <el-table-column :width="200" :label="$t('label.partner_sub_account_name')">
                      <template slot-scope="props">
                        <template
                          v-if="props.row.partner_account_code && props.row.partner_account_name"
                        >{{props.row.partner_account_code}}:{{props.row.partner_account_name}}</template>
                        <template v-else>&nbsp;</template>
                        <br />
                        <template
                          v-if="props.row.partner_sub_account_code && props.row.partner_sub_account_name"
                        >{{props.row.partner_sub_account_code}}:{{props.row.partner_sub_account_name}}</template>
                        <template v-else>&nbsp;</template>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 税区分・部門 -->
                  <el-table-column
                    :label="labels.partner_tax_type === undefined ? '' : labels.partner_tax_type "
                  >
                    <el-table-column
                      :width="150"
                      :label="labels.partner_department === undefined ? '' : labels.partner_department"
                    >
                      <template slot-scope="props">
                        {{props.row.partner_tax_type ? getLabel(getSelectName(props.row.partner_tax_type, comboData.tax_type_list)) : '&nbsp;'}}
                        <br />
                        {{props.row.partner_department ? props.row.partner_department : '&nbsp;'}}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <!-- 金額 -->
                  <el-table-column
                    :width="100"
                    :label="labels.credit_amount === undefined ? '' : labels.credit_amount"
                  >
                    <template slot-scope="props">
                      <div style="text-align: right;">{{formatNumber(props.row.credit_amount)}}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <!-- メモ -->
                <el-table-column
                  :label="labels.description === undefined ? '' : labels.description"
                >
                  <el-table-column :width="300">
                    <template slot-scope="props">
                      <div style="text-align: right;">{{props.row.description}}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
              <br />
              <br />
              <table>
                <tr>
                  <td width="150">
                    <label>{{$t('label.debit')}}&nbsp;{{$t('label.total_amount_of_money')}}</label>
                  </td>
                  <td width="300">
                    <div
                      style="text-align: right;"
                    >{{formatNumber(salary_closing_data.salary_closing_total_debit_amount)}}{{$t('label.yen')}}&nbsp;</div>
                  </td>
                  <td width="150">
                    <label>{{$t('label.credit')}}&nbsp;{{$t('label.total_amount_of_money')}}</label>
                  </td>
                  <td width="300">
                    <div
                      style="text-align: right;"
                    >{{formatNumber(salary_closing_data.salary_closing_total_credit_amount)}}{{$t('label.yen')}}&nbsp;</div>
                  </td>
                </tr>
              </table>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;" v-if="labels.print === 1 && salary_closing_data.length > 0">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="csvClicked">
          {{$t('button.csv')}}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
  import JournalListMixin from '@/components/Dashboard/Views/Salary/JournalListMixin'

  export default {
    name: 'JournalListMixin',
    mixins: [JournalListMixin]
  }
</script>
<style>
  .border-style-none {
    border-style: none !important;
  }
</style>
