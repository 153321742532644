import mixin from '@/lib/mixin'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import Detail from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipDetail'
import Bank from '@/components/Dashboard/Views/Salary/BankTransferList'
import CashTransfer from '@/components/Dashboard/Views/Salary/CashTransferList'
import Journal from '@/components/Dashboard/Views/Salary/JournalList'
import SendMail from '@/components/Dashboard/Views/Salary/SendMail'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSelectHorizontal from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
import EmployeesSearch from '@/components/UIComponents/Inputs/EmployeesSearch'
import swal from 'sweetalert2'

export default {
  name: 'CreatePaySlipSummaryMixin',
  mixins: [mixin],
  components: {
    Detail,
    Bank,
    CashTransfer,
    Journal,
    SendMail,
    ValidationText,
    ValidationSelect,
    ValidationSelectHorizontal,
    EmployeesSearch
  },
  data () {
    return {
      mode: 0,
      params: {},
      componentKey: 0,
      id: 0,
      isChanged: false,
      draggable: true,
      showSearchCondition: true,
      modalWidth: 1600,
      modalWidthBank: 800,
      modalWidthCashTransfer: 400,
      modalWidthJournal: 1400,
      modalWidthSendMail: 1400,
      modalWidthSub: 1100,
      tableStyle: '',
      tableWrapperStyle: '',
      showOperation: true,
      summary: {},
      target_date: '',
      bonus_pay_slip_name: '',
      employee_pay_slip_target_date: '',
      term_from: '',
      term_to: '',
      salary_category: '',
      searchCond: {
        search_type: '3',
        office_code: '',
        team_code: '',
        employee_code: '',
        employee_name: '',
        employee_classification_code: '',
        group_code: '',
        retirement: true,
        hireYear: false,
        target_year: '',
        target_month: '',
        salary_closing_code: '',
        including_outside_period: false
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      fixed_fluctuation_mapping_list: [{}],
      bonusPaySlipName: ''
    }
  },
  computed: {
    screenCode () {
      return '02-002'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
     // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.target_date = this.params.target_date
      this.bonus_pay_slip_name = this.params.bonus_pay_slip_name
      this.employee_pay_slip_target_date = this.params.employee_pay_slip_target_date
      this.term_from = this.params.term_from
      this.term_to = this.params.term_to
      this.comboData.t_employee_pay_slip_exist_list = [
        {label: '', value: '0'},
        {label: this.$t('label.already'), value: '1'}]
      this.roles = res.common_area.button_role // 権限
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      this.roles = res.common_area.button_role // 権限
      this.bonusPaySlipName = res.api_area.bonus_pay_slip_name
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      searchCond.target_date = this.params.target_date
      delete searchCond.from_year
      delete searchCond.from_month
      delete searchCond.to_year
      delete searchCond.to_month
      delete searchCond.hireYear
      return searchCond
    },
    doUpdate () {},
    canPreview (row) {
      return this.roles.preview === 1 && row.t_employee_pay_slip_exist === 1
    },
    canEntry (row) {
      return this.roles.entry === 1 && row.t_employee_pay_slip_exist !== 1
    },
    canUpdate (row) {
      return this.roles.update === 1 && row.t_employee_pay_slip_exist === 1
    },
    canDelete (row) {
      return this.roles.delete === 1 && row.t_employee_pay_slip_exist === 1
    },
    canPrint (row) {
      return this.roles.print === 1 && row.t_employee_pay_slip_exist === 1
    },
    executeOperation (execute) {
      return execute ? '' : 'visibility:hidden;'
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/bonus/view/employee_bonus_pay_slip/list', reqData)
      .then(res => {
        localStorage.setItem('orgSearchCondSummary', JSON.stringify(this.searchCond))
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.bonus_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_date,
          bonus_pay_slip_name: this.params.bonus_pay_slip_name,
          employee_pay_slip_target_date: this.params.employee_pay_slip_target_date,
          term_from: this.params.term_from,
          term_to: this.params.term_to
        }
      }
      this.send('/bonus/init/employee_bonus_pay_slip/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.summary[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.summary[entry[0]] === 'string') {
              this.summary[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.summary[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    doPrintAll () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          term_from: this.term_from.replaceAll('/', '').substring(0, 6),
          term_to: this.term_to.replaceAll('/', '').substring(0, 6),
          retirement: false,
          target_date: this.target_date
        }
      }
      this.send('/bonus/print/employee_bonus_pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    doPrint (index, row) {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          employee_code: row.employee_code,
          employee_name: row.employee_name,
          term_from: this.term_from.replaceAll('/', '').substring(0, 6),
          term_to: this.term_to.replaceAll('/', '').substring(0, 6),
          retirement: false,
          target_date: this.target_date
        }
      }
      this.send('/bonus/print/employee_bonus_pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = this.target_date
      this.params.bonus_pay_slip_name = this.bonus_pay_slip_name
      this.params.employee_pay_slip_target_date = this.employee_pay_slip_target_date
      this.params.term_from = this.term_from
      this.params.term_to = this.term_to
      this.mode = this.modes.view
      // this.$modal.show('detail')
      this.$router.push({ name: 'createBonusPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: this.target_date,
            bonus_pay_slip_name: this.bonus_pay_slip_name,
            employee_pay_slip_target_date: this.employee_pay_slip_target_date,
            term_from: this.term_from,
            term_to: this.term_to
          },
          mode: this.modes.view
        }
      })
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = this.target_date
      this.params.bonus_pay_slip_name = this.bonus_pay_slip_name
      this.params.employee_pay_slip_target_date = this.employee_pay_slip_target_date
      this.params.term_from = this.term_from
      this.params.term_to = this.term_to
      this.mode = this.modes.edit
      // this.$modal.show('detail')
      this.$router.push({ name: 'createBonusPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: this.target_date,
            bonus_pay_slip_name: this.bonus_pay_slip_name,
            employee_pay_slip_target_date: this.employee_pay_slip_target_date,
            term_from: this.term_from,
            term_to: this.term_to
          },
          mode: this.modes.edit
        }
      })
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = this.target_date
      this.params.bonus_pay_slip_name = this.bonus_pay_slip_name
      this.params.employee_pay_slip_target_date = this.employee_pay_slip_target_date
      this.params.term_from = this.term_from
      this.params.term_to = this.term_to
      this.mode = this.modes.delete
      // this.$modal.show('detail')
      this.$router.push({ name: 'createBonusPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: this.target_date,
            bonus_pay_slip_name: this.bonus_pay_slip_name,
            employee_pay_slip_target_date: this.employee_pay_slip_target_date,
            term_from: this.term_from,
            term_to: this.term_to
          },
          mode: this.modes.delete
        }
      })
    },
    handleAdd (index, row) {
      if (!row.layout_code) {
        swal({
          text: this.$t('message.layout_required'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        })
        return
      }
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = this.target_date
      this.params.bonus_pay_slip_name = this.bonus_pay_slip_name
      this.params.employee_pay_slip_target_date = this.employee_pay_slip_target_date
      this.params.term_from = this.term_from
      this.params.term_to = this.term_to
      this.mode = this.modes.add
      // this.$modal.show('detail')
      this.$router.push({ name: 'createBonusPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: this.target_date,
            bonus_pay_slip_name: this.bonus_pay_slip_name,
            employee_pay_slip_target_date: this.employee_pay_slip_target_date,
            term_from: this.term_from,
            term_to: this.term_to,
            layout_code: row.layout_code
          },
          mode: this.modes.add
        }
      })
    },
    showBank (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 2 // 2:賞与
      this.mode = this.modes.view
      this.$modal.show('bank')
    },
    showCashTransfer (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 2 // 2:賞与
      this.mode = this.modes.view
      this.$modal.show('cashTransfer')
    },
    showJournal (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 2 // 2:賞与
      this.mode = this.modes.view
      this.$modal.show('journal')
    },
    showSendMail (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 2 // 2:賞与
      this.mode = this.modes.view
      this.$modal.show('sendMail')
    },
    doClose () {
      this.$router.go(-1)
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    hideBank (...args) {
      this.$modal.hide('bank')
      if (args[0]) {
        this.search()
      }
    },
    hideCashTransfer (...args) {
      this.$modal.hide('cashTransfer')
      if (args[0]) {
        this.search()
      }
    },
    hideJournal (...args) {
      this.$modal.hide('journal')
      if (args[0]) {
        this.search()
      }
    },
    hideSendMail (...args) {
      this.$modal.hide('sendMail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    }
  },
  created () {
    if (this.$route.params.params) {
      this.params = this.$route.params.params
      this.mode = this.$route.params.mode
      localStorage.setItem('payslipParams', JSON.stringify(this.$route.params))
    } else {
      const paramsString = localStorage.getItem('payslipParams')
      const params = JSON.parse(paramsString)
      this.params = params.params
      this.mode = params.mode
    }
    if (window.previousUrl === '/02/menu02Screen002/detail') {
      const orgSearchCond = localStorage.getItem('orgSearchCondSummary')
      this.searchCond = JSON.parse(orgSearchCond)
      this.search()
    }

    this.init()
  }
}
