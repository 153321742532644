<template>
  <div>
    <h5 class="text-center">給与明細につきましてご確認をお願いいたします。</h5>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 460px);" :key="componentKey">
      <div class="col-md-11 col-md-offset-1">
        <ValidationObserver ref="observer">
          <div class="card" v-for="layout in layouts" v-bind:key="layout.layout_code">
            <div class="card-content">
              <div class="form-group">
                <div><label style="font-size: large;">{{layout.layout_cd}}&nbsp;&nbsp;{{layout.layout_name}}</label></div>
              </div>
              <ValidationSelect
                v-model="layout.salary_category"
                item-label="給与区分"
                placeholder=""
                :options="salaryCategoryList"
                :disabled="false"
                classes="width150"
                rules="required"
              />
              <div class="form-group">
                <label>レイアウト</label>
                <table style="border-collapse: collapse; border: 1px solid #999999;">
                  <tr style="border: 1px solid #999999;">
                    <th style="border: 1px solid #999999;">支<br>給<br>額</th>
                    <td>
                      <table>
                        <tr>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide1, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide2, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide3, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide4, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide5, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide6, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide7, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide8, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide9, attributeList)}}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.provide10, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide11, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide12, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide13, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide14, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide15, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide16, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide17, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.provide18, attributeList)}}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th style="border: 1px solid #999999;">控<br>除<br>額</th>
                    <td>
                      <table>
                        <tr>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction1, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction2, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction3, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction4, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction5, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction6, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction7, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction8, attributeList)}}
                            </div>
                          </td>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction9, attributeList)}}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 120px;">
                            <div class="form-group">
                              {{getSelectName(layout.deduction10, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction11, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction12, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction13, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction14, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction15, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction16, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction17, attributeList)}}
                            </div>
                          </td>
                          <td>
                            <div class="form-group">
                              {{getSelectName(layout.deduction18, attributeList)}}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect
    },
    data () {
      return {
        componentKey: 0,
        layouts: [],
        salaryCategoryList: [],
        attributeList: []
      }
    },
    methods: {
      getAttributeName (attributeCode) {
        for (let attribute of this.attributeList) {
          if (attributeCode === attribute.attributeCode) {
            return attribute.item_name
          }
        }
        return ''
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        this.layouts = migrationData.salary_info.layouts
        this.salaryCategoryList = migrationData.combo_data.salaryCategoryList
        this.attributeList = migrationData.combo_data.attributeList
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_salary_layout', params)
        .then(res => {
          this.layouts = []
          for (const salaryLayout of res.init_data.after_data.salary_layout_list) {
            salaryLayout.salary_category = String(salaryLayout.salary_category)
            this.layouts.push(salaryLayout)
          }
          this.salaryCategoryList = []
          for (let category of res.common_init_data.combo_data.salary_category_list) {
            let salaryCategory = {
              label: this.getLabel(category.label),
              value: String(category.value)
            }
            this.salaryCategoryList.push(salaryCategory)
          }
          this.attributeList = []
          for (const element of res.common_init_data.combo_data.m_attribute_list) {
            let attribute = {
              label: element.item_name,
              value: String(element.attribute_cd)
            }
            this.attributeList.push(attribute)
          }
          this.componentKey += 1
        })
      }
    }
  }
</script>
<style scoped>
th {
  padding: 10px;
}
td {
  padding: 5px;
}
</style>
