import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelectSimple from '@/components/UIComponents/Inputs/ValidationSelectSimple'
import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
import { Loader } from 'google-maps'

export default {
  name: 'SupplierDepartmentDetailMixin',
  mixins: [mixin],
  components: {
    EmployeeSearch,
    ValidationText,
    ValidationSelectSimple,
    ValidationPostalCode
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {
        supplier_code: '',
        department_name: '',
        post_code: '',
        state_code: '',
        municipality_code: '',
        town: '',
        building: '',
        customer_contact_name: '',
        position: '',
        tel: '',
        fax: '',
        mail_address: '',
        responsible_employee_code: ''
      },
      place: {
        prefectures_before: undefined
      },
      geocoder2: {},
      google: null,
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '03-044'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      // 仕入先コード
      this.detail.supplier_code = this.params.supplier_code
      // 住所の設定
      this.comboData.prefectures_list = res.common_area.combo_data.state_municipality_mapping_list.state_list
      this.comboData.city_list = []
      this.comboData.mapping_list = res.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
      if (this.detail.state_code) {
        this.stateCodeChanged()
      }
      this.detail.city = this.detail.municipality_code ? this.detail.state_code + '_' + this.detail.municipality_code : this.detail.municipality_code
      this.setGeocoder()
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      // 削除の場合、そのまま返却
      if (this.mode === this.modes.delete) {
        return detail
      }
      // 市町村コードを設定
      detail.municipality_code = detail.city ? detail.city.split('_')[1] : detail.city
      // 不要な項目削除
      delete detail.responsible_employee_name
      delete detail.city
      delete detail.update_date
      delete detail.update_employee_code
      delete detail.update_employee_name
      return detail
    },
    stateCodeChanged () {
      if (this.place.prefectures_before && this.place.prefectures_before !== this.detail.state_code) {
        this.detail.city = ''
      }
      if (this.detail.state_code) {
        this.place.prefectures_before = this.detail.state_code
        this.comboData.city_list = []
        if (!this.comboData.mapping_list[this.detail.state_code]) {
          for (const prefecture of this.comboData.prefectures_list) {
            if (prefecture.label === this.detail.state_code) {
              this.detail.state_code = prefecture.value
              break
            }
          }
        }
        if (this.comboData.mapping_list[this.detail.state_code]) {
          for (const code of this.comboData.mapping_list[this.detail.state_code]) {
            for (const entry of this.comboData.municipality_list) {
              if (code === entry.value) {
                this.comboData.city_list.push(entry)
              }
            }
          }
        }
      } else {
        this.comboData.city_list = []
      }
      this.componentKey += 1
    },
    municipalityCodeChanged () {},
    postalCodeSearch (postalCode) {
      const self = this
      this.geocoder2.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
        const myLatlng = results[0].geometry.location
        self.geocoder2.geocode({location: myLatlng}, function (results, status) {
          if (status === 'OK') {
            for (const result of results) {
              if (result.types) {
                let isFound = false
                for (const type of result.types) {
                  if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                    const componentsLength = result.address_components.length
                    self.detail.post_code = result.address_components[componentsLength - 1].long_name
                    for (const prefecture of self.comboData.prefectures_list) {
                      if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                        self.detail.state_code = prefecture.value
                        break
                      }
                    }
                    if (self.detail.state_code) {
                      self.stateCodeChanged()
                    }
                    for (const municipality of self.comboData.municipality_list) {
                      if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                        self.detail.city = municipality.value
                        break
                      }
                    }
                    self.detail.town = ''
                    for (var i = componentsLength - 5; i >= 0; i--) {
                      self.detail.town = self.detail.town + result.address_components[i].long_name
                    }
                    self.componentKey += 1
                    isFound = true
                    break
                  }
                }
                if (isFound) break
              }
            }
          }
        })
      })
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {supplier_code: this.detail.supplier_code}
          detail.department_code = this.detail.department_code
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        this.send(`/${operation}/supplier_department`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    async setGeocoder () {
      if (!this.google) {
        this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
      }
      this.geocoder2 = new this.google.maps.Geocoder()
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          supplier_code: this.params.supplier_code,
          department_code: this.params.department_code
        }
      }
      this.send('/init/supplier_department', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    // 従業員番号入力
    employeeSelected () {
      this.detail.responsible_employee_name = ''
      if (!this.canShow('/SS/menuSSScreen018')) {
        swal({
          title: this.$t('message.employee_not_found'),
          html: this.$t('message.employee_not_found_description'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.detail.responsible_employee_code = ''
          this.detail.responsible_employee_name = ''
        })
        return
      }
      if (!this.detail.responsible_employee_code) {
        return
      }
      const searchCond = {
        screen_code: 'SS-018',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: this.detail.responsible_employee_code,
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          all_flag: false
        }
      }
      this.tempHelp = this.help
      this.send('/view/search_employee/list', searchCond)
      .then(res => {
        if (res.api_area.employee_list && res.api_area.employee_list.length > 0) {
          this.detail.responsible_employee_code = res.api_area.employee_list[0].employee_code
          this.detail.responsible_employee_name = res.api_area.employee_list[0].employee_name
        } else {
          swal({
            title: this.$t('message.employee_not_found'),
            html: this.$t('message.employee_not_found_description'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.detail.responsible_employee_code = ''
            this.detail.responsible_employee_name = ''
          })
        }
        this.help = this.tempHelp
      })
    },
    // 従業員検索表示
    showEmployeeSearchModal () {
      // 親のshow-employeeを実施
      this.$emit('show-employee')
    },
    // 従業員検索閉じる
    hideEmployeeSearchModal (selected) {
      // モーダルemployeeで従業員が選択された場合
      if (selected) {
        // 従業員番号と従業員名を設定
        this.detail.responsible_employee_code = selected.employee_code
        this.detail.responsible_employee_name = selected.employee_name
        this.componentKey += 1
      }
    }
  },
  created () {
    this.init()
  }
}
