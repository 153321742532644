<template>
  <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
  <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <div>
      <textarea 
        :name="itemName"
        :placeholder="placeholder" 
        :class="classes" 
        style="display: inline-block;"
        v-model="itemValue" 
        :maxlength="maxLength"
        :disabled="disabled"
        :rows="rows"
        @blur="blur">
      </textarea>
      <div class="error-message-wrapper" v-show="!valid" style="display: inline-block; vertical-align: top;">
        <small class="text-danger">
          <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
          {{ getMessage(errors[0]) }}
        </small>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      rows: Number
    },
    data () {
      return {
        itemValue: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      blur () {
        this.$emit('input', this.itemValue)
        this.$emit('blur')
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>