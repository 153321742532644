<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.target_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.target_date"
            item-name="target_date"
            rules="required"
            :itemLabel="labels.target_date"
            placeholder="2020-01-01"
            :disabled="(isReadOnly || application.is_fixed) && isModal"
            @input="$emit('initApply', application.target_date)"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <ValidationSelect
          v-model="application.work_schedule_code"
          item-name="work_schedule_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          :item-label="labels.workSchedule"
          :options="comboData.work_schedule_list"
          :disabled="(isReadOnly || application.is_fixed) && isModal"
          @input="$emit('initApply', application.target_date, application.work_schedule_code)"
        />
      </div>
      <div class="form-group" v-if="false" :key="componentKey">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.actual')}}</label>
        </div>
        <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_overwork_minutes">
          {{application.actual_overwork_minutes}}
        </div>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="$emit('showActual')">
          {{$t('button.show_actual')}}
        </button>        
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.late_night_overwork_minutes}}</label>
        </div>
        <ValidationTimes
          v-model="application.late_night_overwork_minutes"
          item-name="late_night_minutes"
          classes="select-success length2"
          rules="required"
          :item-label="labels.late_night_overwork_minutes"
          :disabled="isReadOnly || application.is_fixed"
          :labels="labels"
          :is-late-night="true"
        />
        <small style="color: #C84513; margin-left: 5px;">{{errMsgs.length > 0 ? errMsgs[0] : ''}}</small>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.business_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.business_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_content')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimesHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTimes,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
