<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal
      name="summary"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidth"
      :pivotX="0.9"
    >
      <div class="modal-body">
        <Summary :mode="mode" :params="params" @close="hideSummary" />
      </div>
    </modal>
    <modal
      name="keyTran"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthkeyTran"
      :pivotX="0.9"
    >
      <div class="modal-body">
        <KeyTran :mode="mode" :params="params" @close="hideKeyTran" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <Help
          class="pull-right"
          :help="help"
          :placeholders="[pageTitle]"
          movie-key="bonus"
          div-style="padding-bottom: 5px;"
        />
      </div>
    </div>
    <div ref="frame">
      <form method="#" action="#">
        <div class="card">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">{{$t('label.search_condition')}}</h4>
            </div>
            <div
              style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;"
            >
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <div class="form-group search-condition-item">
              <div>
                <label>{{$t('label.target_year_month')}}</label>
                <div>
                  <el-select
                    class="select-success widthYear"
                    size="large"
                    placeholder
                    v-model="searchCond.from_year"
                    @change="synchronizeFrom"
                  >
                    <el-option
                      v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.year')}}&nbsp;
                  <el-select
                    class="select-success widthMonth"
                    size="large"
                    placeholder
                    v-model="searchCond.from_month"
                    ref="fromMonth"
                    @change="synchronizeFrom"
                  >
                    <el-option
                      v-for="month in months"
                      class="select-success"
                      :value="month"
                      :label="month"
                      :key="month"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.month')}}&nbsp;
                  ～
                  <el-select
                    class="select-success widthYear"
                    size="large"
                    placeholder
                    v-model="searchCond.to_year"
                    @change="synchronizeTo"
                  >
                    <el-option
                      v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.year')}}&nbsp;
                  <el-select
                    class="select-success widthMonth"
                    size="large"
                    placeholder
                    ref="toMonth"
                    v-model="searchCond.to_month"
                    @change="synchronizeTo"
                  >
                    <el-option
                      v-for="month in months"
                      class="select-success"
                      :value="month"
                      :label="month"
                      :key="month"
                    ></el-option>
                  </el-select>
                  &nbsp;{{$t('label.month')}}&nbsp;
                </div>
              </div>
            </div>
            <div class="form-group search-condition-item">
              <label>{{labels.bonus_pay_slip_name}}</label>
              <input type="text" :placeholder="$t('placeholder.bonus_pay_slip_name')" maxlength="30" class="form-control length30" v-model="searchCond.bonus_pay_slip_name">
            </div>
            <div class="search-button-area">
              <!-- 新規 A画面 -->
              <template v-if="labels.entry === 1">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-default"
                  @click="showAdd"
                >
                <span class="btn-label"><i class="ti-settings"></i></span>
                  {{$t('button.new')}}
                </button>
              </template>
              <!-- 検索 集計画面 -->
              <template v-if="labels.search === 1">
                <button
                  type="button"
                  class="btn btn-search btn-sm btn-default"
                  @click="search"
                >
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </template>
            </div>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" />
      <template v-if="tableData && tableData.length > 0">
        <template v-if="tableData.length">
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <!-- Table開始 -->
              <div :style="tableWrapperStyle">
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="tableData"
                  :empty-text="$t('label.no_data')"
                  :style="tableStyle"
                  ref="table"
                  :row-class-name="tableRowClassName"
                >
                  <!-- 操作 -->
                  <el-table-column
                    width="230"
                    v-if="showOperation"
                    fixed="left"
                    :key="componentKey"
                    :label="$t('label.operation')"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <button
                        type="button"
                        class="btn btn-sm btn-default btn-warning"
                        :style="executeOperation(canPreview(props.row))"
                        @click="changeCreateBonusPaySlipSummary(props.$index, props.row)"
                      >{{$t('button.create_bonus')}}</button>
                      <!-- 表示 -->
                      <a
                        class="btn btn-simple btn-icon like"
                        @click="handleView(props.$index, props.row)"
                        :style="executeOperation(canPreview(props.row))"
                      >
                        <i class="ti-file" :title="$t('button.view')"></i>
                      </a>
                      <!-- 更新 -->
                      <a
                        class="btn btn-simple btn-icon edit"
                        @click="handleEdit(props.$index, props.row)"
                        :style="executeOperation(canUpdate(props.row))"
                      >
                        <i class="ti-pencil-alt" :title="$t('button.update')"></i>
                      </a>
                      <!-- 削除 -->
                      <a
                        class="btn btn-simple btn-icon btn-danger remove"
                        @click="handleDelete(props.$index, props.row)"
                        :style="executeOperation(canDelete(props.row))"
                      >
                        <i class="ti-close" :title="$t('button.delete')"></i>
                      </a>
                      <!--ダウンロードボタン -->
                    </template>
                  </el-table-column>
                  <!-- 対象年月 -->
                  <el-table-column
                    :width="100"
                    :label="$t('label.target_year_month')"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div
                        style="text-align: left;"
                      >{{props.row.target_date.substring(0, 4)}}{{$t('label.year')}}{{props.row.target_date.substring(4, 6)}}{{$t('label.monthly')}}</div>
                    </template>
                  </el-table-column>
                  <!-- 名称 -->
                  <el-table-column
                    :width="getColumnWidth(tableData, labels.bonus_pay_slip_name === undefined ? '' : labels.bonus_pay_slip_name, 'bonus_pay_slip_name')"
                    :label="labels.bonus_pay_slip_name === undefined ? '' : labels.bonus_pay_slip_name"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">{{props.row.bonus_pay_slip_name}}</div>
                    </template>
                  </el-table-column>

                  <!-- 有効終了日 -->
                  <el-table-column
                    :width="50"
                    :label="$t('label.number_people')"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">{{props.row.bonus_pay_slip_count}}</div>
                    </template>
                  </el-table-column>

                  <!-- 査定期間 -->
                  <el-table-column
                    :width="200"
                    :label="$t('label.evaluation_period')"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">{{props.row.term_from}} ～ {{props.row.term_to}}</div>
                    </template>
                  </el-table-column>



                  <!-- 直近給与月 -->
                  <el-table-column
                    :width="100"
                    :label="labels.employee_pay_slip_target_date === undefined ? '' : labels.employee_pay_slip_target_date"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div
                        style="text-align: left;"
                      >{{props.row.employee_pay_slip_target_date.substring(0, 4)}}{{$t('label.year')}}{{props.row.employee_pay_slip_target_date.substring(4)}}{{$t('label.monthly')}}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="row" ref="pager"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <div style="margin-top: 10px;">{{$t('label.no_data')}}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
  import CreateBonusPaySlipSearchMixin from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipSearchMixin'

  export default {
    name: 'CreateBonusPaySlipSearch',
    mixins: [CreateBonusPaySlipSearchMixin]
  }
</script>
<style>
  .el-table .closed-row {
    background-color: #f0f8ff !important;
  }
</style>
