import mixin from '@/lib/mixin'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
export default {
  name: 'ProxyApprovalSettingMixin',
  mixins: [mixin],
  components: {
    EmployeeSearch
  },
  data () {
    return {
      currentIndex: 0,
      componentKey: 0,
      id: 0,
      detail: {
        deputy_approverl_data: [],
        approverl_data: []
      },
      tableDatas: undefined,
      tableData: undefined,
      tableData2: undefined,
      approverl_data: [],
      deputy_approverl_data: [],
      labels: {},
      comboData: {},
      mapping_company_and_group: [],
      mapping_group_and_employee: []
    }
  },
  computed: {
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.$nextTick(() => {
        let width = 0
        for (const child of this.$refs.table.$children) {
          if (child.width) {
            width += parseInt(child.width, 10)
          }
        }
        this.tableStyle = 'width: ' + (width + 1) + 'px'
        // this.$refs.pager.style.width = width + 'px'
        // this.componentKey += 1
        this.$refs.table.doLayout()
        this.$refs.table2.doLayout()
      })
    },
    initDetail (res) {
      var deputyApproverlDataList = []
      const detail = {
        deputy_approverl_data: [],
        approverl_data: []
      }
      // apiから代理承認リストを取得する。
      if (this.detail.deputy_approverl_data !== undefined && this.detail.deputy_approverl_data.length !== 0) {
        for (const deputyDataList of Object.entries(this.detail.deputy_approverl_data)) {
          deputyApproverlDataList.push(deputyDataList[1])
        }
      }
      // apiから代理承認リストを取得する。
      if (deputyApproverlDataList !== undefined && deputyApproverlDataList.length !== 0) {
        for (const deputyApproverlData of Object.entries(deputyApproverlDataList)) {
          let deputyData = {
            company_code: deputyApproverlData[1].company_code,
            company_name: deputyApproverlData[1].company_name,
            group_code: deputyApproverlData[1].group_code,
            group_name: deputyApproverlData[1].group_name,
            employee_code: deputyApproverlData[1].employee_code,
            employee_name: deputyApproverlData[1].employee_name,
            deputy_approverl_company_code: deputyApproverlData[1].deputy_approverl_company_code,
            deputy_approverl_company_name: deputyApproverlData[1].deputy_approverl_company_name,
            deputy_approverl_group_code: deputyApproverlData[1].deputy_approverl_group_code,
            deputy_approverl_group_name: deputyApproverlData[1].deputy_approverl_group_name,
            deputy_approverl_employee_code: deputyApproverlData[1].deputy_approverl_employee_code,
            deputy_approverl_employee_name: deputyApproverlData[1].deputy_approverl_employee_name,
            deputy_contents: deputyApproverlData[1].deputy_contents,
            del_flag: false
          }
          detail.deputy_approverl_data.push(deputyData)
        }
      }
      // 部署リスト分ループする
      for (const employeeGroup of this.comboData.employee_group_list) {
        var groupCd = employeeGroup.value
        var groupName = employeeGroup.label
        var approverlDataList = []
        var isDataExsist = false

        // apiから代理承認リストを取得する。
        if (this.detail.approverl_data !== undefined && this.detail.approverl_data.length !== 0) {
          for (const dataList of Object.entries(this.detail.approverl_data)) {
            approverlDataList.push(dataList[1])
          }
        }
        // 代理承認リストを部署リストと突き合わせ、あれば代理承認リストから作成、なければ部署リストから新規作成。
        if (approverlDataList !== undefined && approverlDataList.length !== 0) {
          for (const approverlData of Object.entries(approverlDataList)) {
            // 部署コードが存在したら代理承認リストのデータを返す
            if (approverlData[1].group_code === groupCd) {
              let data = {
                company_code: approverlData[1].company_code,
                company_name: approverlData[1].company_name,
                group_code: approverlData[1].group_code,
                group_name: groupName,
                employee_code: approverlData[1].employee_code,
                employee_name: approverlData[1].employee_name,
                deputy_approverl_company_code: approverlData[1].deputy_approverl_company_code,
                deputy_approverl_company_name: approverlData[1].deputy_approverl_company_name,
                deputy_approverl_group_code: approverlData[1].deputy_approverl_group_code,
                deputy_approverl_group_name: approverlData[1].deputy_approverl_group_name,
                deputy_approverl_employee_code: approverlData[1].deputy_approverl_employee_code,
                deputy_approverl_employee_name: approverlData[1].deputy_approverl_employee_name,
                deputy_contents: approverlData[1].deputy_contents
              }
              detail.approverl_data.push(data)
              isDataExsist = true
              break
            }
          }
        }
        if (!isDataExsist) {
          // 初期設定
          let data = {
            company_code: this.$store.state.loginUser.companyCode,
            company_name: this.$store.state.loginUser.companyName,
            group_code: groupCd,
            group_name: groupName,
            employee_code: this.$store.state.loginUser.employeeCode,
            employee_name: this.$store.state.loginUser.employeeName,
            deputy_approverl_company_code: '',
            deputy_approverl_company_name: '',
            deputy_approverl_group_code: '',
            deputy_approverl_group_name: '',
            deputy_approverl_employee_code: '',
            deputy_approverl_employee_name: '',
            deputy_contents: ''
          }
          detail.approverl_data.push(data)
        }
      }
      this.detail = detail
      return detail
    },
    // 独自バリデーション用
    customValidate (apiArea) {
      // 必須入力チェック
      for (const approverlData of Object.entries(apiArea.approverl_data)) {
        let blankCount = 0
        let wkErroItemList = []
        // 自分の代理承認設定の項目のいずれかに入力があり、いずれか一つでもブランクの場合エラー
        // 代理承認者の会社コードがブランクの場合
        if (approverlData[1].deputy_approverl_company_code === '') {
          wkErroItemList.push(this.labels.deputy_approverl_company_code)
          blankCount++
        }
        // 代理承認者の部署コードがブランクの場合
        if (approverlData[1].deputy_approverl_group_code === '') {
          wkErroItemList.push(this.labels.deputy_approverl_group_code)
          blankCount++
        }
        // 代理承認者の従業員コードがブランクの場合
        if (approverlData[1].deputy_approverl_employee_code === '') {
          wkErroItemList.push(this.labels.deputy_approverl_employee_code)
          blankCount++
        }
        // 依頼理由がブランクの場合
        if (approverlData[1].deputy_contents === '') {
          wkErroItemList.push(this.labels.deputy_contents)
          blankCount++
        }
        // blankCountが0か4以外の場合エラー
        if (blankCount !== 0 || blankCount !== 4) {
          // blankCountが1の場合未入力項目1つ用のメッセージを出力
          if (blankCount === 1) {
            return this.$t('message.field_required').replace('{_field_}', wkErroItemList[0])
          // blankCountが2の場合未入力項目2つ用のメッセージを出力
          } else if (blankCount === 2) {
            return this.$t('message.is_role_or_approver_required').replace('{item1}', wkErroItemList[0]).replace('{item2}', wkErroItemList[1])
          // blankCountが3の場合未入力項目3つ用のメッセージを出力
          } else if (blankCount === 3) {
            // return this.$t('message.is_paid_holiday_management_required').replace('{item1}', wkErroItemList[0]).replace('{item2}', wkErroItemList[1]).replace('{item3}', wkErroItemList[2])
            return this.$t('message.is_paid_holiday_management_required').replace('、{item4}、{item5}', '').replace('{item1}', wkErroItemList[0]).replace('{item2}', wkErroItemList[1]).replace('{item3}', wkErroItemList[2])
          // それ以外の場合nullを返す。
          } else {
            return null
          }
        // blankCount0か4の場合nullを返す。
        } else {
          return null
        }
      }
    },
    // クリアボタン押下処理
    tabeleRowClear (index, row) {
      // tableData2（自分の代理承認者）にデータがある場合
      if (this.tableData2) {
        for (const table2Row of this.tableData2) {
          // detailと同じキーのレコードを画面上からクリアする。
          if ((table2Row.company_code === row.company_code) &&
              (table2Row.group_code === row.group_code) &&
              (table2Row.employee_code === row.employee_code)) {
            table2Row.deputy_approverl_company_code = ''
            table2Row.deputy_approverl_company_name = ''
            table2Row.deputy_approverl_group_code = ''
            table2Row.deputy_approverl_group_name = ''
            table2Row.deputy_approverl_employee_code = ''
            table2Row.deputy_approverl_employee_name = ''
            table2Row.deputy_contents = ''
          }
        }
      }
    },
    showApproverSearch (index) {
      this.currentIndex = index
      this.$modal.show('approver')
    },
    hideApproverSearch (selected) {
      var self = this
      if (selected) {
        // ひとまず同じ会社を設定
        self.detail.approverl_data[self.currentIndex].deputy_approverl_company_code = this.$store.state.loginUser.companyCode
        self.detail.approverl_data[self.currentIndex].deputy_approverl_company_name = this.$store.state.loginUser.companyName
        self.detail.approverl_data[self.currentIndex].deputy_approverl_group_code = selected.group_list[0].group_code
        self.detail.approverl_data[self.currentIndex].deputy_approverl_group_name = selected.group_list[0].group_name
        self.detail.approverl_data[self.currentIndex].deputy_approverl_employee_code = selected.employee_code
        self.detail.approverl_data[self.currentIndex].deputy_approverl_employee_name = selected.employee_name
        self.detail.approverl_data[self.currentIndex].group_list = selected.group_list
      }
      self.$modal.hide('approver')
    },
    // 代理承認者の従業員活性判定
    isEmployee (index) {
      return this.detail.approverl_data[index].deputy_approverl_employee_code === ''
    },
    // 代理承認者の部署活性判定
    isGroup (index) {
      return this.detail.approverl_data[index].deputy_approverl_employee_code === ''
    },
    // 代理承認者の部署リスト取得
    getApproverlGrupList (index) {
      let groupList = this.detail.approverl_data[index].group_list

      // 部署リストが存在する場合、部署リストを返却する。
      if (groupList) {
        return groupList
      }
      groupList = []
      let groupInfo = []
      // 会社と部署のマッピングリストから部署リストを取得する。
      for (const mappingList of this.mapping_company_and_group) {
        if (mappingList.company_code === this.detail.approverl_data[index].deputy_approverl_company_code) {
          groupInfo = mappingList.group_info
          break
        }
      }
      // 取得した部署リストから従業員が所属している部署リストを作成する。
      for (const group of groupInfo) {
        for (const employeeCode of this.mapping_group_and_employee[group.group_code] || []) {
          if (employeeCode === this.detail.approverl_data[index].deputy_approverl_employee_code) {
            groupList.push({ 'group_code': group.group_code, 'group_name': group.group_name })
          }
        }
      }
      return groupList
    },
    // 代理承認者の会社変更
    changeApproverlCompany (index) {
      this.detail.approverl_data[index].approverl_group_code = ''
      this.detail.approverl_data[index].approverl_group_code = ''
      this.detail.approverl_data[index].approverl_employee_code = ''
      this.detail.approverl_data[index].approverl_employee_name = ''
    },
    // 代理承認者の従業員情報入力
    approverSelected (index, searchType) {
      if ((searchType === 1 && this.detail.approverl_data[index].deputy_approverl_employee_code.length === 0) ||
        (searchType === 2 && this.detail.approverl_data[index].deputy_approverl_employee_name.length === 0)) {
        this.detail.approverl_data[index].deputy_approverl_group_code = ''
        this.detail.approverl_data[index].deputy_approverl_group_name = ''
        // this.detail.approverl_data[index].deputy_approverl_company_code = ''
        // this.detail.approverl_data[index].deputy_approverl_company_name = ''
        this.detail.approverl_data[index].group_list = []
        return
      }
      if (!this.canShow('/SS/menuSSScreen018')) return
      let searchCond = {
        screen_code: 'SS-018',
        company_code: this.$store.state.loginUser.companyCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        group_code: this.$store.state.loginUser.groupCode,
        api_area: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: '',
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          all_flag: true
        }
      }
      if (searchType === 1) {
        searchCond.api_area.employee_code = this.detail.approverl_data[index].deputy_approverl_employee_code
      } else {
        searchCond.api_area.employee_name = this.detail.approverl_data[index].deputy_approverl_employee_name
      }
      this.send('/view/search_employee/list', searchCond, false)
      .then(res => {
        if (res.api_area.employee_list.length === 1) {
          this.currentIndex = index
          this.hideApproverSearch(res.api_area.employee_list[0])
        } else {
          this.detail.approverl_data[index].deputy_approverl_group_code = ''
          this.detail.approverl_data[index].deputy_approverl_group_name = ''
          this.detail.approverl_data[index].deputy_approverl_employee_code = ''
          this.detail.approverl_data[index].deputy_approverl_employee_name = ''
          this.detail.approverl_data[index].group_list = []
        }
      })
    },
    // 送信データ調整用
    convertDetail (detail) {
      let result = {
        deputy_approverl_data: [],
        approverl_data: []
      }
      // 自分を代理承認者にしている設定があり、delFlgがtrueなら削除する
      if (detail.deputy_approverl_data) {
        for (const tmp of detail.deputy_approverl_data) {
          var delFlg = tmp.del_flag
          // 変更がなかったらfalse
          if (delFlg === null || delFlg === undefined) {
            delFlg = false
            // falseなら次の要素
          } else if (!delFlg) {
            continue
          }
          // 必要なものだけ設定
          let deputyApproverlData = {
            approverl_company_code: tmp.company_code,
            approverl_group_code: tmp.group_code,
            approverl_employee_code: tmp.employee_code,
            del_flag: delFlg
          }
          result.deputy_approverl_data.push(deputyApproverlData)
        }
      } else {
        result.deputy_approverl_data = null
      }
      // 自分の代理承認者を編集
      if (detail.approverl_data) {
        for (const tmp of detail.approverl_data) {
          let approverlData = {
            group_code: tmp.group_code,
            deputy_approverl_company_code: tmp.deputy_approverl_company_code,
            deputy_approverl_group_code: tmp.deputy_approverl_group_code,
            deputy_approverl_employee_code: tmp.deputy_approverl_employee_code,
            deputy_contents: tmp.deputy_contents
          }
          result.approverl_data.push(approverlData)
        }
      }
      return result
    },
    // 検索処理カスタマイズ用
    afterSearch (res) {
      this.$nextTick(() => {
        let width = 0
        if (this.$refs.tableData) {
          for (const child of this.$refs.tableData.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
          this.$refs.tableData.doLayout()
        }
      })
    },
    doUpdate () {
      this.submit('update')
    },
    doBack () {
      this.$router.go(-1)
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        if (!isValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        detail = {...this.detail}
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        this.nullToBlank(reqData.api_area)
        const message = this.customValidate(reqData.api_area)
        if (message !== null) {
          this.message = {text: message}
          return
        }
        this.send(`/${operation}/deputy_approverl/list`, reqData)
        .then(res => {
          this.componentKey += 1
          if (reqData.api_area.deputy_approverl_data.length > 0) {
            const deputyApproverlList = [...this.$store.state.deputyApproverlList]
            for (const row of reqData.api_area.deputy_approverl_data) {
              for (let i = 0; i < deputyApproverlList.length; i++) {
                if (row.approverl_company_code === deputyApproverlList[i].company_code &&
                    row.approverl_group_code === deputyApproverlList[i].group_code &&
                    row.approverl_employee_code === deputyApproverlList[i].employee_code) {
                  deputyApproverlList.splice(i, 1)
                  break
                }
              }
            }
            this.$store.commit('setDeputyApproverlList', deputyApproverlList)
          }
          if (res.message.message_classification === 1) {
            // 更新のコメント及び属性を退避
            this.temp_message = this.message.text
            this.temp_cause = this.message.cause
            this.temp_correspondence_action = this.message.correspondence_action
            this.temp_message_classification = this.message.message_classification
            this.temp_message_type = this.message.message_type
            // 再描画
            this.init()
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: '11-002',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_key: this.$route.params ? this.$route.params.search_key : ''
        }
      }
      const detail = {
        deputy_approverl_data: [],
        approverl_data: []
      }
      // 初期化
      this.detail = detail
      this.send('/init/deputy_approverl/list', data)
      .then(res => {
        // 更新時のコメントを表示
        if (this.temp_message) {
          this.message.text = this.temp_message
          this.message.cause = this.temp_cause
          this.message.correspondence_action = this.temp_correspondence_action
          this.message.message_classification = this.temp_message_classification
          this.message.message_type = this.temp_message_type
        }
        this.labels = this.getLabels(res.common_area)
        this.labels.label_deputy_approverl_data = this.$t('label.label_deputy_approverl_data')
        this.labels.label_approverl_data = this.$t('label.label_approverl_data')
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)

        // マッピングリストを設定
        this.mapping_company_and_group = res.common_area.combo_data.company_list.mapping_company_and_group
        this.mapping_group_and_employee = res.common_area.combo_data.company_list.mapping_group_and_employee
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
          this.componentKey += 1
        }
        this.tableDatas = Array.isArray(res.api_area) ? res.api_area : this.initDetail(res)
        // テーブル(自分を代理承認者にしている一覧)
        this.tableData = Array.isArray(this.tableDatas) ? this.tableDatas : this.tableDatas.deputy_approverl_data
        // テーブル(自分の代理承認者一覧)
        this.tableData2 = Array.isArray(this.tableDatas) ? this.tableDatas : this.tableDatas.approverl_data
        this.afterInit(res)
        this.afterSearch(res)
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
