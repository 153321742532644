import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Summary from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipSummary'
import KeyTran from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipKeyTran'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'CreateBonusPaySlipSearchMixin',
  mixins: [mixin],
  components: {
    Summary,
    KeyTran,
    ValidationSelect,
    PPagination
  },
  data () {
    return {
      isCurrentMonth: false,
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1600,
      modalWidthkeyTran: 1600,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        from_year: '',
        from_month: '',
        to_year: '',
        to_month: ''
      },
      showOperation: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: '',
      tableWrapperStyle: '',
      roles: {
        preview: 0,
        update: 0,
        entry: 0,
        delete: 0,
        print: 0
      },
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  computed: {
    screenCode () {
      return '02-002'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    synchronizeFrom () {
      let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
      let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
      if (from > to) {
        this.searchCond.to_year = this.searchCond.from_year
        this.searchCond.to_month = this.searchCond.from_month
      }
    },
    synchronizeTo () {
      let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
      let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
      if (from > to) {
        this.searchCond.from_year = this.searchCond.to_year
        this.searchCond.from_month = this.searchCond.to_month
      }
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.currentYear = String(new Date().getFullYear())
      this.searchCond.from_year = this.searchCond && this.searchCond.from_year ? this.searchCond.from_year : this.currentYear
      this.searchCond.from_month = this.searchCond && this.searchCond.from_month ? this.searchCond.from_month : '1'
      this.searchCond.to_year = this.searchCond && this.searchCond.to_year ? this.searchCond.to_year : this.currentYear
      this.searchCond.to_month = this.searchCond && this.searchCond.to_month ? this.searchCond.to_month : '12'
      this.roles = res.common_area.button_role // 権限
      this.labels.bonus_pay_slip_name = this.$t('label.bonus_pay_slip_name')
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      this.roles = res.common_area.button_role // 権限
      this.labels.bonus_pay_slip_name = this.$t('label.bonus_pay_slip_name')
    },
    doUpdate () {},
    canPreview (row) {
      return this.roles.preview === 1
    },
    canEntry (row) {
      return this.roles.entry === 1
    },
    canUpdate (row) {
      return this.roles.update === 1
    },
    canDelete (row) {
      return this.roles.delete === 1
    },
    executeOperation (execute) {
      return execute ? '' : 'visibility:hidden;'
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date_from: this.searchCond.from_year + ('00' + this.searchCond.from_month).slice(-2),
          target_date_to: this.searchCond.to_year + ('00' + this.searchCond.to_month).slice(-2),
          bonus_pay_slip_name: this.searchCond.bonus_pay_slip_name
        }
      }
      this.send('/bonus/view/bonus_pay_slip/list', reqData)
      .then(res => {
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.bonus_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/bonus/init/bonus_pay_slip/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
        if (window.previousUrl === '/02/menu02Screen002/summary') {
          const orgSearchCond = localStorage.getItem('orgSearchCond')
          this.searchCond = JSON.parse(orgSearchCond)
          this.search()
        }
      })
    },
    changeCreateBonusPaySlipSummary (index, row) {
      console.log(row)
      this.params = {}
      this.params.target_date = row.target_date
      this.params.bonus_pay_slip_name = row.bonus_pay_slip_name
      this.params.employee_pay_slip_target_date = row.employee_pay_slip_target_date
      this.params.term_from = row.term_from
      this.params.term_to = row.term_to
      this.mode = this.modes.view
      // this.$modal.show('summary')
      this.$router.push({ name: 'createBonusPaySlipSummary',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            bonus_pay_slip_name: row.bonus_pay_slip_name,
            employee_pay_slip_target_date: row.employee_pay_slip_target_date,
            term_from: row.term_from,
            term_to: row.term_to
          },
          mode: this.modes.view
        }
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.view
      this.$modal.show('keyTran')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.edit
      this.$modal.show('keyTran')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.delete
      this.$modal.show('keyTran')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('keyTran')
    },
    hideSummary (...args) {
      this.$modal.hide('summary')
      if (args[0]) {
        this.search()
      }
    },
    hideKeyTran (...args) {
      this.$modal.hide('keyTran')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    }
  },
  created () {
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
    this.init()
  }
}
