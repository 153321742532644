<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <EmployeesSearch :orgSearchCond="searchCond" :labels="labels" :comboData="comboData" :disabled="isChanged" @search="searchClicked" />
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showAllocation" :width="getButtonColumnWidth([$t('button.allocation')])" :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <template v-if="labels.update === 1">
                      <button type="button" class="btn btn-warning btn-sm btn-default" @click="allocationClicked(props.$index, props.row)">{{$t('button.allocation')}}</button>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployee" width="180" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium; font-weight: bold;">
                    {{props.row.employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;" v-if="searchCond.search_type!='3'">
                    <small>{{props.row.employee_classification_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='0'">
                    <small>{{props.row.office_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='1'">
                    <small>{{props.row.group_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='2'">
                    <small>{{props.row.team_name}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showWorkSchedule" width="270" :label="labels.work_schedule_ryaku === undefined ? labels.workSchedule_ryaku === undefined ? '' : labels.workSchedule_ryaku : labels.work_schedule_ryaku">
                <template slot="header" slot-scope="scope">
                  {{scope.column.label}}
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div v-for="(row, index) in props.row.work_schedule_list" :key="index">
                      {{row.label}}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import WorkScheduleAllocationSearchMixin from '@/components/Dashboard/Views/Generated/WorkScheduleAllocationSearchMixin'

  export default {
    name: 'WorkScheduleAllocationSearch',
    data () {
      return {
        modalWidth: 500,
        showOperation: false
      }
    },
    methods: {
      afterSearch (res) {
        if (this.searchCond.search_type === 0) {
          this.showOffice = true
          this.showGroup = false
          this.showTeam = false
        } else if (this.searchCond.search_type === 2) {
          this.showOffice = false
          this.showGroup = false
          this.showTeam = true
        } else {
          this.showOffice = false
          this.showGroup = true
          this.showTeam = false
        }
      },
      allocationClicked (index, row) {
        this.params.group_code = row.group_code
        this.params.employee_code = row.employee_code
        this.mode = this.modes.edit
        this.$modal.show('detail')
      }
    },
    mixins: [WorkScheduleAllocationSearchMixin]
  }
</script>
<style scoped>
</style>
