<template>
  <div class="card">
    <div class="card-content">
      <template v-if="application.holiday_work_application_type === undefined ||
        application.holiday_work_application_type.value == 1">
        <div class="form-group">
          <div class="length5" style="display: inline-block;">
            <label>{{labels.target_date}}</label>
          </div>
          <div style="display: inline-block; margin: 0; padding: 0;">
            <ValidationDate
              v-model="application.target_date"
              item-name="target_date"
              rules="required"
              :itemLabel="labels.target_date"
              placeholder="2020-01-01"
              :disabled="(isReadOnly || application.is_fixed) && isModal"
              @input="$emit('initApply', application.target_date)"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="length5" style="display: inline-block;">
            <label>{{labels.workSchedule}}</label>
          </div>
          <ValidationSelect
            v-model="application.work_schedule_code"
            item-name="work_schedule_code"
            :placeholder="$t('label.select_description')"
            classes="length20"
            :item-label="labels.workSchedule"
            :options="comboData.work_schedule_list"
            :disabled="isReadOnly || application.is_fixed"
            @input="$emit('initApply', application.target_date, application.work_schedule_code)"
          />
        </div>
        <div class="form-group" v-if="false" :key="componentKey">
          <div class="length5" style="display: inline-block;">
            <label>{{$t('label.actual')}}</label>
          </div>
          <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_holiday_minutes">
            {{application.actual_holiday_minutes}}
          </div>
          <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="$emit('showActual')">
            {{$t('button.show_actual')}}
          </button>          
        </div>
        <div class="form-group">
          <div class="length5" style="display: inline-block;">
            <label>{{labels.holiday_work_hours}}</label>
          </div>
          <ValidationTimes
            v-model="application.holiday_minutes"
            item-name="holiday_minutes"
            classes="select-success length2"
            rules="required"
            :item-label="labels.holiday_work_hours"
            :disabled="isReadOnly || application.is_fixed"
            :labels="labels"
          />
          <small style="color: #C84513; margin-left: 5px;">{{errMsgs.length > 0 ? errMsgs[0] : ''}}</small>
        </div>
        <div class="form-group">
          <div class="length5" style="display: inline-block; vertical-align: top;">
            <label>{{labels.business_content}}</label>
          </div>
          <div style="display: inline-block; margin: 0; padding: 0;">
            <ValidationTextArea
              v-model="application.business_content"
              item-name="business_content"
              max-length="100"
              classes="form-control width480"
              :item-label="labels.business_content"
              :placeholder="isReadOnly ? '' : $t('placeholder.overtime_content')"
              :disabled="isReadOnly"
              :rows="3"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="length5" style="display: inline-block; vertical-align: top;">
            <label>{{labels.supplement}}</label>
          </div>
          <div style="display: inline-block; margin: 0; padding: 0;">
            <ValidationTextArea
              v-model="application.supplement"
              item-name="supplement"
              max-length="100"
              classes="form-control width480"
              :item-label="labels.supplement"
              :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
              :disabled="isReadOnly"
              :rows="3"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="form-group">
          <div class="length5" style="display: inline-block;">
            <label>{{labels.target_application_number}}</label>
          </div>
          <el-select class="select-success length10"
                      size="large"
                      placeholder=""
                      :disabled="isReadOnly"
                      v-model="application.target_application_number">
            <el-option v-for="application in comboData.target_application_number_list"
                        class="select-success"
                        :value="application.value"
                        :label="application.label"
                        :key="application.value">
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <div class="length5" style="display: inline-block; vertical-align: top;">
            <label>{{labels.cancel_contents}}</label>
          </div>
          <div style="display: inline-block; margin: 0; padding: 0;">
            <ValidationTextArea
              v-model="application.cancel_contents"
              item-name="cancel_contents"
              max-length="20"
              classes="form-control width480"
              rules="required"
              :item-label="labels.cancel_contents"
              :placeholder="isReadOnly ? '' : $t('placeholder.cancel_contents')"
              :disabled="isReadOnly"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimesHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTimes,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
