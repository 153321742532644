<template>
  <ValidationObserver ref="observer">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottom: 5px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="row" style="overflow-y: auto; height: 537px;" name="companyFrame">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <template v-if="paid_leave_payment_on_count===0&&!exist_paid_holiday_management&&!isReadOnly">
                <h5>{{$t('message.employee_paid_msg')}}</h5>
              </template>
              <!-- 有給休暇 -->
              <ValidationRadio
                rules="required"
                v-model="paid_leave_payment"
                :item-label="labels.paid_leave_payment"
                :disabled="isReadOnly||(paid_leave_payment_on_count===0&&!exist_paid_holiday_management)"
                data-v-step="500"
              />
              <template v-if="paid_leave_payment_flg">
                <!-- 次回有給付与日 -->
                <ValidationDate
                  v-model="paid_reference_date"
                  item-name="paid_reference_date"
                  rules="required|isPaidEnd"
                  :itemLabel="labels.paid_reference_date"
                  :placeholder="$t('placeholder.start_date')"
                  :picker-options="futureday"
                  :disabled="isReadOnly"
                  data-v-step="501"
                />
                <template v-if="paid_leave_payment_flg">
                  <!-- 勤続月数 -->
                  <ValidationSelect
                    v-model="estimated_months_of_service"
                    :item-label="labels.estimated_months_of_service"
                    :options="months_of_service_list"
                    classes="length4"
                    rules="required"
                    :disabled="isReadOnly"
                    data-v-step="502"
                  />
                </template>
                <!-- 週の労働日数 -->
                <ValidationRadio
                  :rules="number_of_working_days_per_week_rule"
                  v-model="number_of_working_days_per_week"
                  :item-label="labels.number_of_working_days_per_week"
                  :disabled="isReadOnly"
                  data-v-step="503"
                />
                <template v-if="mode !== modes.add">
                  <label for="planId">
                    {{labels.available_in_one_year}}
                  </label>
                  <br>
                  {{available_in_one_year + $t('label.day')}}
                </template>
                <br>
                <br>
                <label for="planId" data-v-step="504">
                  {{$t('label.paid_annual_account')}}
                </label>
                <!-- 行追加ボタン -->
                <template>
                  <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" data-v-step="505" @click="addPaidAnnualAccountRecord">{{$t('button.add_row')}}</button>
                </template>
                <div class="error-message-wrapper" v-for="errorMsg in paid_annual_account_error" v-bind:key="errorMsg">
                  <small class="text-danger">
                    <!-- エラーメッセージ -->
                    {{ errorMsg }}
                  </small>
                </div>
                <!-- 有給支給年別勘定テーブル -->
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="paid_annual_account_record" style="width: 1200px">
                  <!-- 削除 -->
                  <el-table-column min-width="40" :label="$t('label.delete')">
                    <template slot-scope="props">
                      <div style="text-align: center;">
                        <input type="checkbox" v-model="props.row.selected" @click="isDelete(props.row)" class="gray" :disabled="isReadOnly"/>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 支給年月日 -->
                  <el-table-column min-width="140" :label="labels.payment_date_ryaku ? labels.payment_date_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <el-date-picker
                          min-width="100"
                          v-model="props.row.payment_date"
                          type="date"
                          :placeholder="$t('placeholder.start_date')"
                          :picker-options="paidDay"
                          :disabled="isReadOnly||props.row.selectFlg"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 支給日数 -->
                  <el-table-column min-width="75" :label="labels.payment_days_ryaku ? labels.payment_days_ryaku : ''">
                    <template slot-scope="props">
                      <input
                        type="text"
                        v-model="props.row.payment_days"
                        maxlength="4"
                        class="form-control numLength4 number"
                        style="display:inline"
                        :placeholder="$t('placeholder.day')"
                        :disabled="isReadOnly||props.row.selectFlg"
                        @change="changePaid(props.$index)"
                      >
                    </template>
                  </el-table-column>
                  <!-- 支給時間 -->
                  <el-table-column min-width="75" :label="labels.payment_times_ryaku ? labels.payment_times_ryaku : ''">
                    <template slot-scope="props">
                      <input
                        type="text"
                        v-model="props.row.payment_times"
                        maxlength="2"
                        class="form-control numLength2 number"
                        style="display:inline"
                        :placeholder="$t('placeholder.time')"
                        :disabled="isReadOnly||props.row.selectFlg"
                        @change="changePaid(props.$index)"
                      >
                    </template>
                  </el-table-column>
                  <!-- 支給理由 -->
                  <el-table-column min-width="140" :label="labels.payment_reason_ryaku ? labels.payment_reason_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: center;">
                        {{getPaidLeaveReason(props.row.payment_reason)}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 失効年月日 -->
                  <el-table-column min-width="140" :label="labels.expiration_date_ryaku ? labels.expiration_date_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <el-date-picker
                          min-width="150"
                          v-model="props.row.expiration_date"
                          type="date"
                          :placeholder="isReadOnly||props.row.selectFlg ? '' : $t('placeholder.end_date')"
                          :picker-options="pastDay"
                          :disabled="isReadOnly||props.row.selectFlg"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 失効日数 -->
                  <el-table-column min-width="75" :label="labels.expiration_days_ryaku ? labels.expiration_days_ryaku : ''">
                    <template slot-scope="props">
                      <input
                        type="text"
                        v-model="props.row.expiration_days"
                        maxlength="4"
                        class="form-control numLength4 number"
                        style="display:inline"
                        :placeholder="$t('placeholder.day')"
                        :disabled="isReadOnly||props.row.selectFlg"
                        @change="changePaid(props.$index)"
                      >
                    </template>
                  </el-table-column>
                  <!-- 失効時間 -->
                  <el-table-column min-width="75" :label="labels.expiration_times_ryaku ? labels.expiration_times_ryaku : ''">
                    <template slot-scope="props">
                      <input
                        type="text"
                        v-model="props.row.expiration_times"
                        maxlength="2"
                        class="form-control numLength2 number"
                        style="display:inline"
                        :placeholder="$t('placeholder.time')"
                        :disabled="isReadOnly||props.row.selectFlg"
                        @change="changePaid(props.$index)"
                      >
                    </template>
                  </el-table-column>
                  <!-- 失効理由 -->
                  <el-table-column min-width="140" :label="labels.expiration_reason_ryaku ? labels.expiration_reason_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: center;">
                        {{getPaidLeaveReason(props.row.expiration_reason)}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 有給保持日数 -->
                  <el-table-column min-width="90" :label="labels.hold_days_ryaku ? labels.hold_days_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        {{props.row.hold_days + $t('label.day')}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 有給保持時間数 -->
                  <el-table-column min-width="100" :label="labels.hold_times_ryaku ? labels.hold_times_ryaku : ''">
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        {{props.row.hold_times + $t('label.hours')}}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour500" :steps="steps"/>
  </ValidationObserver>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      'el-date-picker': DatePicker
    },
    props: {
      params: Object
    },
    data () {
      var self = this
      return {
        steps: [],
        stepsBk: [
          {
            target: '[data-v-step="500"]',
            content: '有給休暇の有無を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="501"]',
            content: '次に年次有給休暇が付与される日を入力します。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="502"]',
            content: '有給付与日数の設定と紐付けるために設定します。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="503"]',
            content: '労働日数を指定します。有給付与日数の設定と紐付けるために設定します。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="504"]',
            content: '有給休暇の年次付与/失効の記録が表示されます。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="505"]',
            content: 'アルテミス利用開始前に支給/失効している場合、行追加で登録します。',
            params: {placement: 'top'}
          }
        ],
        labels: {},
        mode: 0,
        paid_leave_payment: '',
        paid_leave_payment_flg: false,
        paid_reference_date: '',
        number_of_working_days_per_week: {},
        paid_annual_account_record: [],
        paid_annual_account_error: [],
        paid_leave_reason_list: [],
        available_in_one_year: '',
        estimated_months_of_service: '',
        paid_leave_payment_on_count: 0,
        exist_paid_holiday_management: false,
        estimated_months_of_service_hidden_flg: true,
        months_of_service_list: [],
        init_flg: true,
        paid_holiday_management_maximum_days: 0,
        paid_leave_granted: 0,
        paid_leave_payment_method: 0,
        execFlag: true,
        paidReferenceDateErrorMsg: '',
        limit: '',
        limit_date: '',
        exist_applying_paidLeave_application: false,
        futureday: {
          disabledDate (date) {
            return date <= Date.now()
          }
        },
        pastDay: {
          disabledDate (date) {
            return date >= Date.now()
          }
        },
        paidDay: {
          disabledDate (date) {
            let limit = self.formatDate(self.limit_date)
            let limitDate = new Date(limit)
            let employeeDetail = self.$parent.$parent.$parent
            let hireDate = new Date(employeeDetail.$refs.employeeBasicInfo.hire_date)
            return date < limitDate || date < hireDate || date >= Date.now()
          }
        },
        disableFlg: false,
        watchSkip: false
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        return this.mode === this.modes.view || this.mode === this.modes.delete || this.mode === this.modes.edit
      }
    },
    methods: {
      replay () {
        if (this.paid_leave_payment.value === '1') {
          this.steps = this.stepsBk
        } else {
          this.steps = [this.stepsBk[0]]
        }
        this.$nextTick(() => {
          this.$tours['myTour500'].start()
        })
      },
      // 有給支給年別勘定の追加ボタン押下時の処理
      addPaidAnnualAccountRecord () {
        let record = {
          selected: false,
          payment_date: '',
          payment_days: 0,
          payment_times: 0,
          payment_reason: '5',
          expiration_date: '',
          expiration_days: 0,
          expiration_times: 0,
          expiration_reason: '6',
          hold_days: '0',
          hold_times: '0',
          selectFlg: false,
          number_of_working_days_per_week_rule: 'required'
        }
        this.paid_annual_account_record.push(record)
      },
      // 有給休暇 理由コード取得
      getPaidLeaveReason (value) {
        for (const data of this.paid_leave_reason_list) {
          if (data.value === String(value)) {
            return data.label
          }
        }
        return ''
      },
      setDetails (res, mode) {
        this.paid_leave_payment_on_count = res.api_area.paid_leave_payment_on_count
        this.exist_paid_holiday_management = res.api_area.exist_paid_holiday_management
        this.paid_holiday_management_maximum_days = res.api_area.paid_holiday_management_maximum_days
        this.paid_leave_granted = res.api_area.paid_leave_granted
        this.paid_leave_payment_method = res.api_area.paid_leave_payment_method
        this.limit = res.api_area.limit
        this.limit_date = res.api_area.limit_date
        this.exist_applying_paidLeave_application = res.api_area.exist_applying_paidLeave_application
        if (this.paid_leave_payment_on_count === 0 && !this.exist_paid_holiday_management) {
          this.paid_leave_payment.value = '2'
        }
        if (mode !== this.modes.add) {
          this.paid_leave_payment.value = String(res.api_area.basic.paid_leave_payment)
          if (mode === this.modes.edit && this.paid_leave_payment.value === '1') {
            this.execFlag = false
            this.disableFlg = true
          }
          this.paid_reference_date = res.api_area.basic.paid_reference_date
          this.number_of_working_days_per_week.value = res.api_area.basic.number_of_working_days_per_week
          this.paid_annual_account_record = []
          if (mode !== this.modes.add) {
            for (let data of res.api_area.paid_annual_account_employee_list) {
              data['selectFlg'] = true
              this.paid_annual_account_record.push(data)
            }
          }
          if (res.api_area.basic.available_in_one_year === null) {
            this.available_in_one_year = '0'
          } else {
            this.available_in_one_year = res.api_area.basic.available_in_one_year
          }
          this.estimated_months_of_service = res.api_area.basic.estimated_months_of_service
        }
      },
      init () {
      },
      allClear () {
        this.paid_leave_payment.value = '2'
      },
      changePaid (rowIndex) {
        let paymentDays = this.paid_annual_account_record[rowIndex].payment_days === '' ? 0 : this.paid_annual_account_record[rowIndex].payment_days
        let expirationDays = this.paid_annual_account_record[rowIndex].expiration_days === '' ? 0 : this.paid_annual_account_record[rowIndex].expiration_days
        let paymentTimes = this.paid_annual_account_record[rowIndex].payment_times === '' ? 0 : this.paid_annual_account_record[rowIndex].payment_times
        let expirationTimes = this.paid_annual_account_record[rowIndex].expiration_times === '' ? 0 : this.paid_annual_account_record[rowIndex].expiration_times
        let plusHoldDays = paymentDays - expirationDays
        let plusHoldTimes = paymentTimes - expirationTimes
        this.paid_annual_account_record[rowIndex].hold_days = plusHoldDays
        this.paid_annual_account_record[rowIndex].hold_times = plusHoldTimes
      },
      isDelete (rowData) {
        let numPaymentDays = isNaN(rowData.payment_days) ? 0 : Number(rowData.payment_days)
        let numExpirationDays = isNaN(rowData.expiration_days) ? 0 : Number(rowData.expiration_days)
        let numPaymentTimes = isNaN(rowData.payment_times) ? 0 : Number(rowData.payment_times)
        let numExpirationTimes = isNaN(rowData.expiration_times) ? 0 : Number(rowData.expiration_times)
        let totalDays = numPaymentDays - numExpirationDays
        let totalTimes = numPaymentTimes - numExpirationTimes
        if (this.exist_applying_paidLeave_application) {
          swal({
            html: '申請中の有給休暇申請がある場合、削除できません。<br>削除するには対象の有給休暇申請を取り下げてください。',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            width: '600px'
          }).then(() => {
            rowData.selected = false
          }).catch(() => {
          })
          return
        }
        if (String(totalDays) !== String(rowData.hold_days) || String(totalTimes) !== String(rowData.hold_times)) {
          swal({
            html: '有給休暇を取得済みの行は削除できません。<br>削除するには対象の有給休暇申請を取り下げてください。',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            width: '600px'
          }).then(() => {
            rowData.selected = false
          }).catch(() => {
          })
        }
      }
    },
    watch: {
      'paid_leave_payment.value': function () {
        if (this.paid_leave_payment.value === '1') {
          // 有給休暇がありの場合
          // 有給休暇を表示する
          this.paid_leave_payment_flg = true
          let employeeDetail = this.$parent.$parent.$parent
          if (employeeDetail.$refs.employeeBasicInfo.hire_date) {
            // 実行判断
            if (employeeDetail.$refs.employeeBasicInfo.mode === this.modes.view || employeeDetail.$refs.employeeBasicInfo.mode === this.modes.delete || !this.execFlag) {
              return
            }
            // 入社年月日＋6ヶ月とシステム日付を比較
            let hireDate = this.formatDate(employeeDetail.$refs.employeeBasicInfo.hire_date)
            let nowData = this.formatDate(new Date())
            hireDate = hireDate.split('/')
            let dt = new Date(Number(hireDate[0]), Number(hireDate[1]) - 1, Number(hireDate[2]))
            dt.setMonth(dt.getMonth() + 6)
            let sixMonth = this.formatDate(dt)
            let numNowData = Number(nowData.replace(/\//g, ''))
            let numSixMonth = Number(sixMonth.replace(/\//g, ''))
            if (numSixMonth < numNowData) {
              this.disableFlg = false
            } else {
              if (this.paid_leave_granted === 2) {
                this.disableFlg = true
              } else {
                this.disableFlg = false
              }
            }
            this.execFlag = false
            if (this.exist_paid_holiday_management) {
              let numberOfWorkingDaysPerWeek = this.number_of_working_days_per_week
              if (numberOfWorkingDaysPerWeek.value === '') {
                for (const item of this.number_of_working_days_per_week.items) {
                  if (!item.disabled) {
                    numberOfWorkingDaysPerWeek.value = item.value
                    break
                  }
                }
                if (numberOfWorkingDaysPerWeek.value === '') {
                  numberOfWorkingDaysPerWeek.value = 5
                }
              } else {
                for (const item of this.number_of_working_days_per_week.items) {
                  if (item.value === numberOfWorkingDaysPerWeek.value) {
                    if (item.disabled) {
                      // 週の労働日数が非活性の場合、未選択を指定して処理終了
                      numberOfWorkingDaysPerWeek.value = ''
                      this.execFlag = true
                      return
                    }
                  }
                }
              }
              let data = {
                screen_code: this.screenCode,
                company_code: this.$store.state.loginUser.companyCode,
                group_code: this.$store.state.loginUser.groupCode,
                employee_code: this.$store.state.loginUser.employeeCode,
                api_area: {
                  hire_date: this.formatDate(employeeDetail.$refs.employeeBasicInfo.hire_date),
                  number_of_working_days_per_week: numberOfWorkingDaysPerWeek.value ? numberOfWorkingDaysPerWeek.value : 5
                }
              }
              this.send('/exec/employee', data)
              .then(res => {
                if (res.api_area.paid_reference_date === '') {
                  this.disableFlg = false
                } else {
                  if (this.paid_leave_granted === 2) {
                    let paidReferenceDate = this.formatDate(res.api_area.paid_reference_date)
                    let numPaidReferenceDate = Number(paidReferenceDate.replace(/\//g, ''))
                    if (numPaidReferenceDate >= numNowData) {
                      this.disableFlg = true
                    } else {
                      this.disableFlg = false
                    }
                  } else {
                    this.disableFlg = false
                  }
                  this.paid_reference_date = res.api_area.paid_reference_date
                  this.estimated_months_of_service = res.api_area.estimated_months_of_service
                }
                if (res.api_area.paid_holiday_reference_list && this.paid_leave_granted === 2 && this.paid_leave_payment_method === 1) {
                  // 値がある場合チェック
                  for (const paidHolidayReference of res.api_area.paid_holiday_reference_list) {
                    if (this.number_of_working_days_per_week.value === paidHolidayReference.number_of_working_days_per_week) {
                      if (!paidHolidayReference.paid_holiday_reference_exist) {
                        // エラー
                        this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:false'
                      } else {
                        // 正常
                        this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:true'
                      }
                    }
                  }
                } else {
                  // 正常
                  this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:true'
                }
                if (res.api_area.paid_annual_account_employee_list !== null) {
                  // 有給支給年別勘定に追加する必要がある
                  for (const paidAnnualAccountEmployee of res.api_area.paid_annual_account_employee_list) {
                    let paymentData = this.formatDateWith(paidAnnualAccountEmployee.payment_date, 'YYYY-MM-DD')
                    let record = {
                      selected: false,
                      payment_date: paymentData,
                      payment_days: paidAnnualAccountEmployee.payment_days,
                      payment_times: 0,
                      payment_reason: '5',
                      expiration_date: '',
                      expiration_days: 0,
                      expiration_times: 0,
                      expiration_reason: '6',
                      hold_days: '0',
                      hold_times: '0',
                      selectFlg: false,
                      number_of_working_days_per_week_rule: 'required'
                    }
                    this.paid_annual_account_record.push(record)
                  }
                }
              })
            }
          }
        } else {
          // 有給休暇がなしの場合
          // 非表示にする
          this.paid_leave_payment_flg = false
        }
        this.init_flg = false
      },
      'number_of_working_days_per_week.value': function () {
        let employeeDetail = this.$parent.$parent.$parent
        if (employeeDetail.$refs.employeeBasicInfo.hire_date && this.paid_leave_payment.value === '1') {
          // 実行判断
          if (employeeDetail.$refs.employeeBasicInfo.mode === this.modes.view || employeeDetail.$refs.employeeBasicInfo.mode === this.modes.delete || !this.execFlag) {
            return
          }
          // 入社年月日＋6ヶ月とシステム日付を比較
          let hireDate = this.formatDate(employeeDetail.$refs.employeeBasicInfo.hire_date)
          let nowData = this.formatDate(new Date())
          hireDate = hireDate.split('/')
          let dt = new Date(Number(hireDate[0]), Number(hireDate[1]) - 1, Number(hireDate[2]))
          dt.setMonth(dt.getMonth() + 6)
          let sixMonth = this.formatDate(dt)
          let numNowData = Number(nowData.replace(/\//g, ''))
          let numSixMonth = Number(sixMonth.replace(/\//g, ''))
          if (numSixMonth < numNowData) {
            this.disableFlg = false
          } else {
            if (this.paid_leave_granted === 2) {
              this.disableFlg = true
            } else {
              this.disableFlg = false
            }
          }
          this.execFlag = false
          if (this.exist_paid_holiday_management) {
            let numberOfWorkingDaysPerWeek = this.number_of_working_days_per_week
            if (numberOfWorkingDaysPerWeek.value === '') {
              for (const item of this.number_of_working_days_per_week.items) {
                if (!item.disabled) {
                  numberOfWorkingDaysPerWeek.value = item.value
                  break
                }
              }
              if (numberOfWorkingDaysPerWeek.value === '') {
                numberOfWorkingDaysPerWeek.value = 5
              }
            }
            let data = {
              screen_code: this.screenCode,
              company_code: this.$store.state.loginUser.companyCode,
              group_code: this.$store.state.loginUser.groupCode,
              employee_code: this.$store.state.loginUser.employeeCode,
              api_area: {
                hire_date: this.formatDate(employeeDetail.$refs.employeeBasicInfo.hire_date),
                number_of_working_days_per_week: numberOfWorkingDaysPerWeek.value ? numberOfWorkingDaysPerWeek.value : 5
              }
            }
            this.send('/exec/employee', data)
            .then(res => {
              if (res.api_area.paid_reference_date === '') {
                this.disableFlg = false
              } else {
                if (this.paid_leave_granted === 2) {
                  let paidReferenceDate = this.formatDate(res.api_area.paid_reference_date)
                  let numPaidReferenceDate = Number(paidReferenceDate.replace(/\//g, ''))
                  if (numPaidReferenceDate >= numNowData) {
                    this.disableFlg = true
                  } else {
                    this.disableFlg = false
                  }
                } else {
                  this.disableFlg = false
                }
                this.paid_reference_date = res.api_area.paid_reference_date
                this.estimated_months_of_service = res.api_area.estimated_months_of_service
              }
              if (res.api_area.paid_holiday_reference_list && this.paid_leave_granted === 2 && this.paid_leave_payment_method === 1) {
                // 値がある場合チェック
                for (const paidHolidayReference of res.api_area.paid_holiday_reference_list) {
                  if (this.number_of_working_days_per_week.value === paidHolidayReference.number_of_working_days_per_week) {
                    if (!paidHolidayReference.paid_holiday_reference_exist) {
                      // エラー
                      this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:false'
                    } else {
                      // 正常
                      this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:true'
                    }
                  }
                }
              } else {
                // 正常
                this.number_of_working_days_per_week_rule = 'required|paidHolidayReferenceCheck:true'
              }
              if (res.api_area.paid_annual_account_employee_list !== null) {
                // 有給支給年別勘定に追加する必要がある
                for (const paidAnnualAccountEmployee of res.api_area.paid_annual_account_employee_list) {
                  let paymentData = this.formatDateWith(paidAnnualAccountEmployee.payment_date, 'YYYY-MM-DD')
                  let record = {
                    selected: false,
                    payment_date: paymentData,
                    payment_days: paidAnnualAccountEmployee.payment_days,
                    payment_times: 0,
                    payment_reason: '5',
                    expiration_date: '',
                    expiration_days: 0,
                    expiration_times: 0,
                    expiration_reason: '6',
                    hold_days: '0',
                    hold_times: '0',
                    selectFlg: false,
                    number_of_working_days_per_week_rule: 'required'
                  }
                  this.paid_annual_account_record.push(record)
                }
              }
            })
          }
        }
      },
      'paid_reference_date': function (newParam, oldParam) {
        if (!this.watchSkip) {
          if (newParam !== oldParam && oldParam !== '' && this.disableFlg) {
            swal({
              html: this.$t('message.employee_paid_confirmation').replace('{item1}', this.labels.paid_reference_date).replace('{item2}', this.labels.estimated_months_of_service),
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              type: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('button.do_change'),
              cancelButtonText: this.$t('button.no_change'),
              width: '600px'
            }).then(() => {
              this.disableFlg = false
            }).catch(() => {
              this.watchSkip = true
              this.paid_reference_date = oldParam
            })
          }
        } else {
          this.watchSkip = false
        }
      },
      'estimated_months_of_service': function (newParam, oldParam) {
        if (!this.watchSkip) {
          if (newParam !== oldParam && oldParam !== '' && oldParam !== null && this.disableFlg) {
            swal({
              html: this.$t('message.employee_paid_confirmation').replace('{item1}', this.labels.paid_reference_date).replace('{item2}', this.labels.estimated_months_of_service),
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
              type: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('button.do_change'),
              cancelButtonText: this.$t('button.no_change'),
              width: '600px'
            }).then(() => {
              this.disableFlg = false
            }).catch(() => {
              this.watchSkip = true
              this.estimated_months_of_service = oldParam
            })
          }
        } else {
          this.watchSkip = false
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
