<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.closing_code"
              item-name="closing_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              :item-label="labels.closing_code"
              :options="comboData.closing_list"
              :disabled="isReadOnly"
              @change="closingCodeChanged"
              v-show="mode==modes.add"
            />
            <ValidationText
              v-model="detail.closing_name"
              item-name="closing_name"
              max-length="30"
              classes="form-control length60"
              :item-label="labels.closing_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.closing_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="showClosingName"
              data-v-step="0"
            />
            <ValidationText
              v-model="detail.target_date"
              item-name="target_date"
              max-length="4"
              classes="form-control numLength4 text-right"
              rules="required"
              :item-label="labels.target_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.target_date')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="1"
            />
            <ValidationText
              v-model="detail.closing"
              item-name="closing"
              max-length="2"
              classes="form-control numLength2 text-right"
              :item-label="labels.closing"
              :placeholder="isReadOnly ? '' : $t('placeholder.closing')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :isClosingDate="true"
              @calcClosingDate="calcButtonClicked"
              @calcClosingDateWithEndOfMonth="calcButton2Clicked"
              tourSteps="2,3"
            />
            <ValidationClosingDateTable
              v-model="detail.closing_date_table"
              item-name="closing_date_table"
              classes="form-control"
              :labels="labels"
              rules="closingDateRequired|closingDateContinuous"
              :item-label="labels.closing_date_table"
              :disabled="isReadOnly"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ClosingDetailMixin from '@/components/Dashboard/Views/Generated/ClosingDetailMixin'

  export default {
    name: 'ClosingDetail',
    data () {
      return {
        showClosingName: true
      }
    },
    methods: {
      afterInit (res) {
        if (this.mode === this.modes.add) {
          this.labels.closing_code = this.$t('label.existing_closing_name').replaceAll('{item}', this.labels.closing_name)
          this.labels.org_closing_name = this.labels.closing_name
          this.labels.closing_name = this.$t('label.new_closing_name').replace('{item}', this.labels.closing_name)
          this.comboData.closing_list.unshift({label: '', value: ''})
        }
        this.labels.target_date = this.$t('label.target_year')
        this.detail.closing = ''
        if (!res.api_area) {
          res.api_area = {}
        }
        this.detail.closing_date_table = [
          {
            left_month: this.$t('label.jan'),
            left_month_disabled: res.api_area.attendance_record_count_jan > 0,
            left_startmonth: res.api_area.term_from_jan ? new Date(res.api_area.term_from_jan) : undefined,
            left_endmonth: res.api_area.term_to_jan ? new Date(res.api_area.term_to_jan) : undefined,
            right_month: this.$t('label.jul'),
            right_month_disabled: res.api_area.attendance_record_count_jly > 0,
            right_startmonth: res.api_area.term_from_jly ? new Date(res.api_area.term_from_jly) : undefined,
            right_endmonth: res.api_area.term_to_jly ? new Date(res.api_area.term_to_jly) : undefined
          },
          {
            left_month: this.$t('label.feb'),
            left_month_disabled: res.api_area.attendance_record_count_feb > 0,
            left_startmonth: res.api_area.term_from_feb ? new Date(res.api_area.term_from_feb) : undefined,
            left_endmonth: res.api_area.term_to_feb ? new Date(res.api_area.term_to_feb) : undefined,
            right_month: this.$t('label.aug'),
            right_month_disabled: res.api_area.attendance_record_count_aug > 0,
            right_startmonth: res.api_area.term_from_aug ? new Date(res.api_area.term_from_aug) : undefined,
            right_endmonth: res.api_area.term_to_aug ? new Date(res.api_area.term_to_aug) : undefined
          },
          {
            left_month: this.$t('label.mar'),
            left_month_disabled: res.api_area.attendance_record_count_mar > 0,
            left_startmonth: res.api_area.term_from_mar ? new Date(res.api_area.term_from_mar) : undefined,
            left_endmonth: res.api_area.term_to_mar ? new Date(res.api_area.term_to_mar) : undefined,
            right_month: this.$t('label.sep'),
            right_month_disabled: res.api_area.attendance_record_count_sep > 0,
            right_startmonth: res.api_area.term_from_sep ? new Date(res.api_area.term_from_sep) : undefined,
            right_endmonth: res.api_area.term_to_sep ? new Date(res.api_area.term_to_sep) : undefined
          },
          {
            left_month: this.$t('label.apr'),
            left_month_disabled: res.api_area.attendance_record_count_apl > 0,
            left_startmonth: res.api_area.term_from_apl ? new Date(res.api_area.term_from_apl) : undefined,
            left_endmonth: res.api_area.term_to_apl ? new Date(res.api_area.term_to_apl) : undefined,
            right_month: this.$t('label.oct'),
            right_month_disabled: res.api_area.attendance_record_count_oct > 0,
            right_startmonth: res.api_area.term_from_oct ? new Date(res.api_area.term_from_oct) : undefined,
            right_endmonth: res.api_area.term_to_oct ? new Date(res.api_area.term_to_oct) : undefined
          },
          {
            left_month: this.$t('label.may'),
            left_month_disabled: res.api_area.attendance_record_count_may > 0,
            left_startmonth: res.api_area.term_from_may ? new Date(res.api_area.term_from_may) : undefined,
            left_endmonth: res.api_area.term_to_may ? new Date(res.api_area.term_to_may) : undefined,
            right_month: this.$t('label.nov'),
            right_month_disabled: res.api_area.attendance_record_count_nov > 0,
            right_startmonth: res.api_area.term_from_nov ? new Date(res.api_area.term_from_nov) : undefined,
            right_endmonth: res.api_area.term_to_nov ? new Date(res.api_area.term_to_nov) : undefined
          },
          {
            left_month: this.$t('label.jun'),
            left_month_disabled: res.api_area.attendance_record_count_jun > 0,
            left_startmonth: res.api_area.term_from_jun ? new Date(res.api_area.term_from_jun) : undefined,
            left_endmonth: res.api_area.term_to_jun ? new Date(res.api_area.term_to_jun) : undefined,
            right_month: this.$t('label.dec'),
            right_month_disabled: res.api_area.attendance_record_count_dec > 0,
            right_startmonth: res.api_area.term_from_dec ? new Date(res.api_area.term_from_dec) : undefined,
            right_endmonth: res.api_area.term_to_dec ? new Date(res.api_area.term_to_dec) : undefined
          }
        ]
      },
      closingCodeChanged () {
        this.showClosingName = this.detail.closing_code === ''
      },
      calcButtonClicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.closing && this.detail.target_date) {
          const baseDate = new Date(this.detail.target_date, 0, this.detail.closing)
          for (const row of this.detail.closing_date_table) {
            row.left_startmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 2, baseDate.getDate() + 1)
            row.left_endmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 1, this.detail.closing)
            row.right_startmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 2, baseDate.getDate() + 1)
            row.right_endmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 1, this.detail.closing)
          }
        } else if (this.detail.closing) {
          console.log('対象年が未入力です。')
        } else {
          console.log('締日が未入力です。')
        }
      },
      calcButton2Clicked () {
        /* 引数の締日を元に1月～12月までの開始日と終了日を設定する。 */
        if (this.detail.target_date) {
          const baseDate = new Date(this.detail.target_date, 1, 1)
          for (const row of this.detail.closing_date_table) {
            row.left_startmonth = new Date(this.detail.target_date, row.left_month.replace('月', '') - 1, baseDate.getDate())
            row.left_endmonth = new Date(this.detail.target_date, row.left_month.replace('月', ''), baseDate.getDate() - 1)
            row.right_startmonth = new Date(this.detail.target_date, row.right_month.replace('月', '') - 1, baseDate.getDate())
            row.right_endmonth = new Date(this.detail.target_date, row.right_month.replace('月', ''), baseDate.getDate() - 1)
          }
        } else {
          console.log('締日が未入力です。')
        }
      },
      customValidate () {
        if (this.mode === this.modes.add) {
          if (this.message.text_bk) {
            this.message.message_classification = this.message.message_classification_bk
            this.message.text = this.message.text_bk
          }
          if ((this.detail.closing_code.length === 0 && this.detail.closing_name.length === 0) ||
            (this.detail.closing_code.length > 0 && this.detail.closing_name.length > 0)) {
            if (!this.message.text_bk) {
              this.message.message_classification_bk = this.message.message_classification
              this.message.text_bk = this.message.text
            }
            this.message.message_classification = 2
            this.message.text = this.labels.org_closing_name + 'はいずれか一方を選択、または入力してください。'
            this.message = {...this.message}
            return false
          }
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          delete detail.closing
          delete detail.calc_button
          detail.term_from_jan = this.formatDate(detail.closing_date_table[0].left_startmonth)
          detail.term_to_jan = this.formatDate(detail.closing_date_table[0].left_endmonth)
          detail.term_from_feb = this.formatDate(detail.closing_date_table[1].left_startmonth)
          detail.term_to_feb = this.formatDate(detail.closing_date_table[1].left_endmonth)
          detail.term_from_mar = this.formatDate(detail.closing_date_table[2].left_startmonth)
          detail.term_to_mar = this.formatDate(detail.closing_date_table[2].left_endmonth)
          detail.term_from_apl = this.formatDate(detail.closing_date_table[3].left_startmonth)
          detail.term_to_apl = this.formatDate(detail.closing_date_table[3].left_endmonth)
          detail.term_from_may = this.formatDate(detail.closing_date_table[4].left_startmonth)
          detail.term_to_may = this.formatDate(detail.closing_date_table[4].left_endmonth)
          detail.term_from_jun = this.formatDate(detail.closing_date_table[5].left_startmonth)
          detail.term_to_jun = this.formatDate(detail.closing_date_table[5].left_endmonth)
          detail.term_from_jly = this.formatDate(detail.closing_date_table[0].right_startmonth)
          detail.term_to_jly = this.formatDate(detail.closing_date_table[0].right_endmonth)
          detail.term_from_aug = this.formatDate(detail.closing_date_table[1].right_startmonth)
          detail.term_to_aug = this.formatDate(detail.closing_date_table[1].right_endmonth)
          detail.term_from_sep = this.formatDate(detail.closing_date_table[2].right_startmonth)
          detail.term_to_sep = this.formatDate(detail.closing_date_table[2].right_endmonth)
          detail.term_from_oct = this.formatDate(detail.closing_date_table[3].right_startmonth)
          detail.term_to_oct = this.formatDate(detail.closing_date_table[3].right_endmonth)
          detail.term_from_nov = this.formatDate(detail.closing_date_table[4].right_startmonth)
          detail.term_to_nov = this.formatDate(detail.closing_date_table[4].right_endmonth)
          detail.term_from_dec = this.formatDate(detail.closing_date_table[5].right_startmonth)
          detail.term_to_dec = this.formatDate(detail.closing_date_table[5].right_endmonth)
          delete detail.closing_date_table
          delete detail.update_count
          delete detail.attendance_record_count_jan
          delete detail.attendance_record_count_feb
          delete detail.attendance_record_count_mar
          delete detail.attendance_record_count_apl
          delete detail.attendance_record_count_may
          delete detail.attendance_record_count_jun
          delete detail.attendance_record_count_jly
          delete detail.attendance_record_count_aug
          delete detail.attendance_record_count_sep
          delete detail.attendance_record_count_oct
          delete detail.attendance_record_count_nov
          delete detail.attendance_record_count_dec
          if (this.mode === this.modes.edit) {
            detail.closing_date_update_count = parseInt(detail.closing_date_update_count, 10)
            detail.closing_update_count = parseInt(detail.closing_update_count, 10)
          }
        } else {
          detail.closing_code = this.detail.closing_code
          detail.target_date = this.detail.target_date
          detail.closing_update_count = parseInt(this.detail.closing_update_count, 10)
          detail.closing_date_update_count = parseInt(this.detail.closing_date_update_count, 10)
          delete detail.update_count
        }
        return detail
      }
    },
    mixins: [ClosingDetailMixin]
  }
</script>
<style scoped>
</style>
