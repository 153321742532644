<template>
  <div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="$emit('close')">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            弁当注文
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.supplier_name"
              item-name="supplier_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.supplier_name ? labels.supplier_name : ''"
              :placeholder="isReadOnly ? '' : $t('placeholder.supplier_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.lunch_name"
              item-name="lunch_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.lunch_name ? labels.lunch_name : ''"
              :placeholder="isReadOnly ? '' : $t('placeholder.lunch_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="dispUnitPrice"
              item-name="unit_price"
              max-length="5"
              classes="form-control numLength5 text-right"
              rules="required|min_value: 1|max_value: 9999"
              :item-label="labels.unit_price"
              :placeholder="isReadOnly ? '' : $t('placeholder.unit_price2')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.quantity"
              item-name="quantity"
              max-length="2"
              classes="form-control numLength2 text-right"
              rules="required|min_value: 1|max_value: 99"
              :item-label="labels.quantity"
              :placeholder="isReadOnly ? '' : $t('placeholder.quantity')"
              :disabled="isChanged"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="price"
              item-name="price"
              max-length="6"
              classes="form-control numLength6 text-right"
              rules="required|min_value: 1|max_value: 99999"
              :item-label="labels.price"
              :placeholder="isReadOnly ? '' : $t('placeholder.price')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationDate
              v-model="detail.purchase_date"
              item-name="purchase_date"
              rules="required"
              :picker-options="pickerOptions"
              :item-label="labels.purchase_date"
              :placeholder="isChanged ? '' : $t('placeholder.purchase_date')"
              :disabled="isChanged"
            />
          </ValidationObserver>
        </div>
      </div>
      <template v-if="!this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationDate
    },
    props: {
      params: Object
    },
    data () {
      return {
        isChanged: false,
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          }
        },
        detail: {},
        labels: {}
      }
    },
    computed: {
      screenCode () {
        return 'DB-004'
      },
      isReadOnly () {
        return true
      },
      price () {
        return this.detail.unit_price ? this.formatNumber(parseInt(this.detail.unit_price, 10) * parseInt(this.detail.quantity, 10)) : '0'
      },
      dispUnitPrice () {
        return this.formatNumber(this.detail.unit_price)
      }
    },
    methods: {
      doAdd () {
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            supplier_code: this.params.supplier_code,
            lunch_code: this.params.lunch_code,
            purchase_date: this.detail.purchase_date,
            unit_price: parseInt(this.detail.unit_price, 10),
            quantity: parseInt(this.detail.quantity, 10)
          }
        }
        this.send('/insert/employee_lunch', params)
        .then(res => {
          this.isChanged = true
        })
      },
      init () {
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            supplier_code: this.params.supplier_code,
            lunch_code: this.params.lunch_code
          }
        }
        this.send('/init/employee_lunch', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          if (res.api_area) {
            const detail = {}
            for (const entry of Object.entries(res.api_area)) {
              if (typeof entry[1] === 'number') {
                detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                detail[entry[0]] = entry[1]
              }
            }
            this.detail = detail
          }
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
