<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length6" style="display: inline-block;">
          <label>{{labels.leave_job_start_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.leave_job_start_date"
            item-name="leave_job_start_date"
            rules="required"
            :itemLabel="labels.leave_job_start_date"
            placeholder="2020-01-01"
            :disabled="isReadOnly"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length6" style="display: inline-block;">
          <label>{{labels.leave_job_end_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.leave_job_end_date"
            item-name="leave_job_end_date"
            rules="required"
            :itemLabel="labels.leave_job_end_date"
            placeholder="2020-01-01"
            :disabled="isReadOnly"
          />
        </div>
      </div>
      <div class="form-group" style="margin-top: 10px;">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>
            {{$t('label.residents_card')}}
            <br>
            {{$t('label.residents_card3')}}
          </label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationImage
            v-model="application.append_list"
            :disabled="isReadOnly"
            :size="3 * 1024 * 1024"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length6" style="display: inline-block;">
          <label>{{labels.leave_job_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationText
            v-model="application.leave_job_content"
            item-name="overtime_content"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.business_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.leave_job_content')"
            :disabled="isReadOnly"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length6" style="display: inline-block;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationText
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.supplement')"
            :disabled="isReadOnly"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'
  import ValidationImage from '@/components/UIComponents/Inputs/ValidationImagesHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationText,
      ValidationDate,
      ValidationRadio,
      ValidationImage
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      isReadOnly: Boolean
    },
    data () {
      return {
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        application: {}
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
