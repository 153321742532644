<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row" style="width: 1600px;">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
      <ValidationObserver ref="observer">
        <Ground
          v-model="detail.ground"
          :disabled="isReadOnly"
          :combo-data="comboData"
          :labels="labels"
        />
      </ValidationObserver>
      <div style="margin-top: 10px; text-align: left;">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
          <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.update')}}
        </button>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import GroundSheetMixin from '@/components/Dashboard/Views/Generated/GroundSheetMixin'

  export default {
    name: 'GroundSheet',
    methods: {
      afterInit (res) {
        for (const row of res.api_area.ground_list) {
          row.labor_day_classification = {
            value: String(row.labor_day_classification),
            disabled: this.isReadOnly,
            items: [
              {
                id: this.comboData.labor_day_classification_list[0].value,
                label: this.getLabel(this.comboData.labor_day_classification_list[0].label),
                value: this.comboData.labor_day_classification_list[0].value
              },
              {
                id: this.comboData.labor_day_classification_list[1].value,
                label: this.getLabel(this.comboData.labor_day_classification_list[1].label),
                value: this.comboData.labor_day_classification_list[1].value
              }
            ]
          }
          row.attendance_date_classification = {
            value: String(row.attendance_date_classification),
            disabled: this.isReadOnly,
            items: [
              {
                id: this.comboData.attendance_date_classification_list[0].value,
                label: this.getLabel(this.comboData.attendance_date_classification_list[0].label),
                value: this.comboData.attendance_date_classification_list[0].value
              },
              {
                id: this.comboData.attendance_date_classification_list[1].value,
                label: this.getLabel(this.comboData.attendance_date_classification_list[1].label),
                value: this.comboData.attendance_date_classification_list[1].value
              }
            ]
          }
          row.timecard_available_flg = {
            value: String(row.timecard_available_flg),
            disabled: this.isReadOnly,
            items: [
              {
                id: this.comboData.timecard_available_flg_list[0].value,
                label: this.getLabel(this.comboData.timecard_available_flg_list[0].label),
                value: this.comboData.timecard_available_flg_list[0].value
              },
              {
                id: this.comboData.timecard_available_flg_list[1].value,
                label: this.getLabel(this.comboData.timecard_available_flg_list[1].label),
                value: this.comboData.timecard_available_flg_list[1].value
              }
            ]
          }
          row.wage_classification = {
            value: String(row.wage_classification),
            disabled: this.isReadOnly,
            items: [
              {
                id: this.comboData.wage_classification_list[0].value,
                label: this.getLabel(this.comboData.wage_classification_list[0].label),
                value: this.comboData.wage_classification_list[0].value
              },
              {
                id: this.comboData.wage_classification_list[1].value,
                label: this.getLabel(this.comboData.wage_classification_list[1].label),
                value: this.comboData.wage_classification_list[1].value
              }
            ]
          }
          row.workflow_flg = {
            value: String(row.workflow_flg),
            disabled: this.isReadOnly,
            items: [
              {
                id: this.comboData.workflow_flg_list[0].value,
                label: this.getLabel(this.comboData.workflow_flg_list[0].label),
                value: this.comboData.workflow_flg_list[0].value
              },
              {
                id: this.comboData.workflow_flg_list[1].value,
                label: this.getLabel(this.comboData.workflow_flg_list[1].label),
                value: this.comboData.workflow_flg_list[1].value
              }
            ]
          }
          row.calender = String(row.calender)
        }
        this.detail.ground = res.api_area.ground_list
      },
      convertDetail (detail) {
        delete detail.ground_list
        detail.ground_list = []
        for (const row of detail.ground) {
          detail.ground_list.push({
            ground_code: row.ground_code,
            ground_name: row.ground_name,
            labor_day_classification: parseInt(row.labor_day_classification.value, 10),
            attendance_date_classification: parseInt(row.attendance_date_classification.value, 10),
            wage_classification: parseInt(row.wage_classification.value, 10),
            timecard_available_flg: parseInt(row.timecard_available_flg.value, 10),
            non_stamp_flg: row.non_stamp_flg,
            workflow_flg: parseInt(row.workflow_flg.value, 10),
            color: row.color,
            default_flg: row.default_flg,
            calender: parseInt(row.calender, 10),
            update_count: row.update_count
          })
        }
        delete detail.ground
        return detail
      },
      afterSubmit (res) {
        for (const row of this.detail.ground) {
          row.update_count += 1
        }
        delete this.detail.update_count
      }
    },
    mixins: [GroundSheetMixin]
  }
</script>
<style scoped>
</style>
