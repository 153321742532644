import Vue from 'vue'
import {Row, Col, Tag} from 'element-ui'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationSelectMini from '@/components/UIComponents/Inputs/ValidationSelectMini'

Vue.use(Row)
Vue.use(Col)
Vue.use(Tag)

export default {
  name: 'BonusLayoutDetailMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationText,
    ValidationDate,
    ValidationSelectMini
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    var self = this
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        layout_code: '',
        layout_name: '',
        term_from: '',
        term_to: '',
        salary_category: '8',
        payment_salary_item_code1: '',
        payment_salary_item_code2: '',
        payment_salary_item_code3: '',
        payment_salary_item_code4: '',
        payment_salary_item_code5: '',
        payment_salary_item_code6: '',
        payment_salary_item_code7: '',
        payment_salary_item_code8: '',
        payment_salary_item_code9: '',
        payment_salary_item_code10: '',
        payment_salary_item_code11: '',
        payment_salary_item_code12: '',
        payment_salary_item_code13: '',
        payment_salary_item_code14: '',
        payment_salary_item_code15: '',
        payment_salary_item_code16: '',
        payment_salary_item_code17: '',
        payment_salary_item_code18: '',
        payment_salary_item_code19: '',
        payment_salary_item_code20: '',
        deduction_salary_item_code1: '',
        deduction_salary_item_code2: '',
        deduction_salary_item_code3: '',
        deduction_salary_item_code4: '',
        deduction_salary_item_code5: '',
        deduction_salary_item_code6: '',
        deduction_salary_item_code7: '',
        deduction_salary_item_code8: '',
        deduction_salary_item_code9: '',
        deduction_salary_item_code10: '',
        deduction_salary_item_code11: '',
        deduction_salary_item_code12: '',
        deduction_salary_item_code13: '',
        deduction_salary_item_code14: '',
        deduction_salary_item_code15: '',
        deduction_salary_item_code16: '',
        deduction_salary_item_code17: '',
        deduction_salary_item_code18: '',
        deduction_salary_item_code19: '',
        deduction_salary_item_code20: ''
      },
      tableData: undefined,
      payment_lists: {
        payment_list1: [],
        payment_list2: [],
        payment_list3: [],
        payment_list4: [],
        payment_list5: [],
        payment_list6: [],
        payment_list7: [],
        payment_list8: [],
        payment_list9: [],
        payment_list10: [],
        payment_list11: [],
        payment_list12: [],
        payment_list13: [],
        payment_list14: [],
        payment_list15: [],
        payment_list16: [],
        payment_list17: [],
        payment_list18: [],
        payment_list19: [],
        payment_list20: []
      },
      deduction_lists: {
        deduction_list1: [],
        deduction_list2: [],
        deduction_list3: [],
        deduction_list4: [],
        deduction_list5: [],
        deduction_list6: [],
        deduction_list7: [],
        deduction_list8: [],
        deduction_list9: [],
        deduction_list10: [],
        deduction_list11: [],
        deduction_list12: [],
        deduction_list13: [],
        deduction_list14: [],
        deduction_list15: [],
        deduction_list16: [],
        deduction_list17: [],
        deduction_list18: [],
        deduction_list19: [],
        deduction_list20: []
      },
      steps: [
        {
          target: '[data-v-step="0"]',
          content: '管理用の番号です。後から変更することのできない項目です。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="1"]',
          content: 'レイアウトの名前を入力してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="2"]',
          content: 'レイアウトを有効にする開始日を選択してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="3"]',
          content: '賞与明細に表示する給与項目を指定してください。',
          params: {placement: 'top-start'}
        }
      ],
      labels: {},
      comboData: {},
      futureday: {
        disabledDate (date) {
          let termFrom = self.formatDate(self.detail.term_from)
          let termFromDate = new Date(termFrom)
          return date <= termFromDate
        }
      },
      sikyuItems: [],
      koujoItems: [],
      draggingItem: null // ドラッグ操作中の項目
    }
  },
  computed: {
    laidoutSikyu: function () {
      const layout = Array(20)
      for (const item of this.sikyuItems) {
        if (item.layoutIndex !== null) {
          layout[item.layoutIndex] = item
        }
      }
      return layout
    },
    laidoutKoujo: function () {
      const layout = Array(20)
      for (const item of this.koujoItems) {
        if (item.layoutIndex !== null) {
          layout[item.layoutIndex] = item
        }
      }
      return layout
    },
    screenCode () {
      return '03-011'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
    /* 支給項目のドラッグ操作 */
    dragstartSikyu: function (event) {
      const item = this.sikyuItems.find(item => item.id === (event.target.dataset ? event.target.dataset.id : null))
      if (item) {
        this.draggingItem = item
        event.dataTransfer.setData('text/plain', JSON.stringify(item))
        event.dataTransfer.effectAllowed = 'move'
        // ドラッグ操作の移動元を半透明にする。
        event.target.classList.add('dragging')
      } else {
        event.dataTransfer.effectAllowed = 'none'
      }
    },
    dragendSikyu: function (event) {
      this.draggingItem = null
      // ドラッグ操作のデザインを解除する。
      document.querySelectorAll('.layout-cell').forEach(tag => {
        tag.classList.remove('dropshadow')
      })
      event.target.classList.remove('dragging')
    },
    dragoverSikyu: function (event) {
      if (this.sikyuItems.includes(this.draggingItem)) {
        // レイアウト枠の番号を確認する。
        let layoutIndex = null
        for (const tag of event.composedPath()) {
          if (tag.dataset && tag.dataset.index !== undefined) {
            layoutIndex = tag.dataset.index
            break
          }
        }
        if (layoutIndex !== null) {
          // レイアウト済みの枠でなければドロップを許可する。
          const item = this.sikyuItems.find(item => item.layoutIndex === layoutIndex)
          if (!item || item === this.draggingItem) {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'move'
          }
        } else {
          // 項目一覧へのドロップを許可する。
          event.preventDefault()
          event.dataTransfer.dropEffect = 'move'
        }
      }
    },
    dropSikyu: function (event) {
      if (this.sikyuItems.includes(this.draggingItem)) {
        event.preventDefault()
        // レイアウト枠の番号を確認する。
        let layoutIndex = null
        for (const tag of event.composedPath()) {
          if (tag.dataset && tag.dataset.index !== undefined) {
            layoutIndex = tag.dataset.index
            // ドラッグ操作のデザインを解除する。
            tag.classList.remove('dropshadow')
            break
          }
        }
        if (layoutIndex !== null) {
          // レイアウト済みの枠でなければ配置する。
          const item = this.sikyuItems.find(item => item.layoutIndex === layoutIndex)
          if (!item) {
            this.draggingItem.layoutIndex = layoutIndex
          }
        } else {
          // 項目一覧に戻す。
          this.draggingItem.layoutIndex = null
        }
      }
    },
    dragenterSikyu: function (event) {
      if (this.sikyuItems.includes(this.draggingItem)) {
        // レイアウト枠に入る際にドロップシャドウを表示する。
        if (!event.target.querySelector('.el-tag')) {
          event.target.classList.add('dropshadow')
        }
      }
    },
    dragleaveSikyu: function (event) {
      if (this.sikyuItems.includes(this.draggingItem)) {
        // レイアウト枠を出る際にドロップシャドウを消す。
        if (event.target.nodeType === Node.ELEMENT_NODE) {
          event.target.classList.remove('dropshadow')
        }
      }
    },
    /* 控除項目のドラッグ操作 */
    dragstartKoujo: function (event) {
      const item = this.koujoItems.find(item => item.id === (event.target.dataset ? event.target.dataset.id : null))
      if (item) {
        this.draggingItem = item
        event.dataTransfer.setData('text/plain', JSON.stringify(item))
        event.dataTransfer.effectAllowed = 'move'
        // ドラッグ操作の移動元を半透明にする。
        event.target.classList.add('dragging')
      } else {
        event.dataTransfer.effectAllowed = 'none'
      }
    },
    dragendKoujo: function (event) {
      this.draggingItem = null
      // ドラッグ操作のデザインを解除する。
      document.querySelectorAll('.layout-cell').forEach(tag => {
        tag.classList.remove('dropshadow')
      })
      event.target.classList.remove('dragging')
    },
    dragoverKoujo: function (event) {
      if (this.koujoItems.includes(this.draggingItem)) {
        // レイアウト枠の番号を確認する。
        let layoutIndex = null
        for (const tag of event.composedPath()) {
          if (tag.dataset && tag.dataset.index !== undefined) {
            layoutIndex = tag.dataset.index
            break
          }
        }
        if (layoutIndex !== null) {
          // レイアウト済みの枠でなければドロップを許可する。
          const item = this.koujoItems.find(item => item.layoutIndex === layoutIndex)
          if (!item || item === this.draggingItem) {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'move'
          }
        } else {
          // 項目一覧へのドロップを許可する。
          event.preventDefault()
          event.dataTransfer.dropEffect = 'move'
        }
      }
    },
    dropKoujo: function (event) {
      if (this.koujoItems.includes(this.draggingItem)) {
        event.preventDefault()
        // レイアウト枠の番号を確認する。
        let layoutIndex = null
        for (const tag of event.composedPath()) {
          if (tag.dataset && tag.dataset.index !== undefined) {
            layoutIndex = tag.dataset.index
            // ドラッグ操作のデザインを解除する。
            tag.classList.remove('dropshadow')
            break
          }
        }
        if (layoutIndex !== null) {
          // レイアウト済みの枠でなければ配置する。
          const item = this.koujoItems.find(item => item.layoutIndex === layoutIndex)
          if (!item) {
            this.draggingItem.layoutIndex = layoutIndex
          }
        } else {
          // 項目一覧に戻す。
          this.draggingItem.layoutIndex = null
        }
      }
    },
    dragenterKoujo: function (event) {
      if (this.koujoItems.includes(this.draggingItem)) {
        // レイアウト枠に入る際にドロップシャドウを表示する。
        if (!event.target.querySelector('.el-tag')) {
          event.target.classList.add('dropshadow')
        }
      }
    },
    dragleaveKoujo: function (event) {
      if (this.koujoItems.includes(this.draggingItem)) {
        // レイアウト枠を出る際にドロップシャドウを消す。
        if (event.target.nodeType === Node.ELEMENT_NODE) {
          event.target.classList.remove('dropshadow')
        }
      }
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.setPaymentList()
      this.setDeductionList()
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode !== this.modes.delete) {
        detail.salary_category = 8 // 賞与固定
        for (var i = 0; i < 30; i++) {
          detail[`deduction_salary_item_code${i + 1}`] = ''
          detail[`payment_salary_item_code${i + 1}`] = ''
        }
        for (const item of this.koujoItems) {
          if (item.layoutIndex === null) continue
          const layoutIndex = parseInt(item.layoutIndex, 10)
          for (var j = 0; j < 30; j++) {
            if (j === layoutIndex) {
              detail[`deduction_salary_item_code${j + 1}`] = item.id
            }
          }
        }
        for (const item of this.sikyuItems) {
          if (item.layoutIndex === null) continue
          const layoutIndex = parseInt(item.layoutIndex, 10)
          for (var k = 0; k < 30; k++) {
            if (k === layoutIndex) {
              detail[`payment_salary_item_code${k + 1}`] = item.id
            }
          }
        }
      }
      delete detail.availability
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      console.log('a')
      this.$refs.observer.validate().then(isValid => {
        console.log('ab')
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        console.log('ac')
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {layout_code: this.detail.layout_code}
          detail.layout_name = this.detail.layout_name
          detail.term_from = this.detail.term_from
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        console.log('b')
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.nullToBlank(this.convertDetail(detail))
        }
        console.log('c')
        this.send(`/${operation}/bonus_layout`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    setPaymentList () {
      this.sikyuItems = []
      for (let paymentData of this.comboData.payment_list) {
        this.sikyuItems.push({name: paymentData.label, id: paymentData.value, layoutIndex: this.getLayoutIndex(paymentData.value)})
      }
    },
    getPaymentList (index) {
      return this.sikyuItems[index]
    },
    setDeductionList () {
      this.koujoItems = []
      for (let deductionData of this.comboData.deduction_list) {
        this.koujoItems.push({name: deductionData.label, id: deductionData.value, layoutIndex: this.getLayoutIndex(deductionData.value)})
      }
    },
    getDeductionList (index) {
      return this.koujoItems[index]
    },
    getLayoutIndex (salaryItemCode) {
      if (!salaryItemCode) return null
      for (var i = 0; i < 30; i++) {
        if (salaryItemCode === this.detail[`deduction_salary_item_code${i + 1}`]) {
          return i
        }
        if (salaryItemCode === this.detail[`payment_salary_item_code${i + 1}`]) {
          return i
        }
      }
      return null
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          layout_code: this.params.layout_code,
          term_from: this.params.term_from
        }
      }
      this.send('/init/bonus_layout', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
