<template>
  <div>
    <button type="button" class="btn btn-sm btn-default btn-warning" @click="close">閉じる</button>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          スマイルマーク選択
        </h4>
      </div>
      <div class="card-content row">
        <template v-for="emoticon in emoticons">
          <div class="col-md-4" v-bind:key="emoticon.value" style="text-align: center;">
            <img width="100" :src="`/static/img/emoticons/${emoticon.value}`" style="cursor: pointer" @click="select(emoticon)"/>
            <br>
            {{emoticon.label}}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  export default{
    mixins: [mixin],
    data () {
      return {
        emoticons: [
          {value: 'well.png', label: '調子が良い'},
          {value: 'normal.png', label: 'いつも通り'},
          {value: 'lonely.png', label: '調子が悪い'},
          {value: 'glad.png', label: 'うれしい'},
          {value: 'sad.png', label: 'かなしい'},
          {value: 'mad.png', label: 'ムカムカ'},
          {value: 'sleepy.png', label: 'ねむい'},
          {value: 'nervous.png', label: 'ストレス'},
          {value: 'sick.png', label: 'ヘトヘト'}
        ]
      }
    },
    methods: {
      select (selected) {
        this.$emit('close', selected)
      },
      close () {
        this.$emit('close')
      }
    }
  }
</script>
<style>
</style>
