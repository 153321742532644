<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="doClose">
        {{$t('button.back')}}
      </button>
      <div class="card">
        <div class="card-header">
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame">
          <div style="margin-left:25px; padding: 10px;">
            <blockquote>
              <p v-for="notification in (detail.notification.split('\n'))" :key="notification" style="font-style: normal; font-weight:bold;">
                {{notification}}
              </p>
            </blockquote>
          </div>
          <hr>
          <div>
            <p v-for="contents in (detail.contents.split('\n'))" :key="contents">
              <span v-html="getContents(contents)"></span>
            </p>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      border
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table">
              <el-table-column :width="getColumnWidthStrong(tableData, '会社名', 'company_name')" label='会社名'>
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.company_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.company_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidthStrong(tableData, '従業員数', 'notice_number_count')" label='従業員数'>
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.employee_Count + '人'}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidthStrong(tableData, '既読人数', 'employee_Count')" label='既読人数'>
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.notice_number_count + '人'}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidthStrong(tableData, '最終既読日時', 'read_date')" label='最終既読日時'>
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.read_date}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import NoticeConfirmationMixin from '@/components/Dashboard/Views/Operation/NoticeConfirmationMixin'

  export default {
    name: 'Notice',
    mixins: [NoticeConfirmationMixin]
  }
</script>
<style scoped>
.card .category {
  color: #000000;
}
blockquote {
  position: relative;
  left: -40px;
  border-left: 5px solid #f00 !important;
}
hr {
  margin-top: 0px !important;
}
</style>
