<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 330px);" name="holidayFrame" :key="componentKey">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="employeeMessage" :placeholders="[pageTitle]"/>
        <div style="margin-bottom: 10px">
          <p>{{$t('label.artemis_system_admin')}}</p>
          <span>{{$t('label.employee_description')}}</span>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="row">
              <div class="col-md-6">
                <ValidationText
                  v-model="detail.employee_code"
                  classes="form-control length10"
                  :disabled="false"
                  :item-label="labels.employee_code"
                  :placeholder="$t('placeholder.employee_code')"
                  item-name="employee_code"
                  rules="required"
                  :help-text="$t('help.employee_code')"
                  max-length="10"
                />
                <ValidationText
                  v-model="detail.employee_name"
                  classes="form-control length60"
                  :disabled="false"
                  :item-label="labels.employee_name"
                  :placeholder="$t('placeholder.employee_name')"
                  item-name="employee_name"
                  rules="required"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                />
                <ValidationText
                  type="password"
                  v-model="detail.password"
                  classes="form-control"
                  :disabled="false"
                  :item-label="$t('label.password')"
                  :placeholder="$t('placeholder.password')"
                  item-name="password"
                  rules="required|min:12|isUnusable|isPasswordWeakly"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                  :is-password="true"
                />
                <VPasswordStrengthMeter 
                  :password="detail.password"
                  :enableFeedback="false"
                />
                <ValidationSelect
                  v-model="detail.employee_classification_code"
                  :item-label="labels.employee_classification_code"
                  :options="comboData.employee_classification_list"
                  :disabled="false"
                  item-name="employee_classification_code"
                  rules="required"
                  classes="length16"
                />
                <!-- <ValidationSelect
                  v-model="detail.language"
                  :item-label="labels.language"
                  :options="comboData.language_type_list"
                  :disabled="true"
                  item-name="language"
                  rules="required"
                  classes="length16"
                /> -->
                <ValidationText
                  v-model="detail.mail_address"
                  classes="form-control"
                  :disabled="false"
                  :item-label="labels.mail_address"
                  item-name="mail_address"
                  :placeholder="$t('placeholder.mail_address')"
                  rules="mailaddress"
                  :help-text="$t('label.max_length')"
                  max-length="255"
                />
                <ValidationDate
                  v-model="detail.hire_date"
                  classes="form-control length10"
                  :disabled="false"
                  :item-label="labels.hire_date"
                  :placeholder="$t('placeholder.start_date')"
                  item-name="hire_date"
                  :picker-options = "pickerOptions"
                  rules="required"
                />
                <ValidationDate
                  v-model="detail.birthday"
                  classes="form-control length10"
                  :disabled="false"
                  :item-label="labels.birthday"
                  :placeholder="$t('placeholder.start_date')"
                  item-name="birthday"
                  :picker-options = "pickerOptions"
                  rules="required"
                />
                <ValidationSelect
                  v-model="detail.recruitment_category_code"
                  :item-label="labels.recruitment_category_code"
                  :options="comboData.recruitment_category_format_list"
                  :disabled="false"
                  item-name="recruitment_category"
                  rules="required"
                  classes="length16"
                />
                <!-- <ValidationSelect
                  v-model="detail.attendance_management"
                  :item-label="labels.attendance_management"
                  :options="comboData.attendance_management_list"
                  :disabled="false"
                  item-name="attendance_management"
                  rules="required"
                  classes="length16"
                />
                <ValidationSelect
                  v-model="detail.number_of_working_days_per_week"
                  :item-label="labels.number_of_working_days_per_week"
                  :options="workingDays"
                  :disabled="false"
                  item-name="number_of_working_days_per_week"
                  rules="required"
                  classes="length16"
                />
                <ValidationSelect
                  v-model="detail.business_name"
                  :item-label="labels.business"
                  :options="comboData.business_list"
                  :disabled="false"
                  item-name="business_name"
                  rules="required"
                  classes="length16"
                /> -->
              </div>
              <div class="col-md-6">
                <!-- <div class="form-group" :key="componentKey1">
                  <div><label>{{$t('label.face_image')}}</label></div>
                  <div style="margin-bottom: 20px;">
                    <input type="file" @change="onEmployeeFileChange"/>
                  </div>
                  <img v-show="detail.employee_photo" :src="detail.employee_photo" style="height: 100px; margin-bottom: 10px;"/>
                  <div>
                    <button type="button" v-show="detail.employee_photo" class="btn btn-sm btn-default btn-warning" @click="deleteEmployeeImage">{{$t('button.delete')}}</button>
                  </div>
                </div>
                <ValidationPostalCode
                  v-model="detail.post_code"
                  classes="form-control length8"
                  :disabled="false"
                  :item-label="labels.post_code"
                  item-name="postal_code"
                  :placeholder="$t('placeholder.postal_code')"
                  :help-text="$t('label.max_length')"
                  max-length="8"
                  :button-label="$t('button.postal_code_search_simple')"
                  @search="postalCodeSearch"
                />
                <ValidationSelect
                  v-model="detail.prefectures"
                  :item-label="labels.state_name_ryaku"
                  :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list.state_list : []"
                  :disabled="false"
                  item-name="language"
                  classes="width110"
                  @change="prefecturesChanged"
                />
                <ValidationSelect
                  v-model="detail.city"
                  item-name="city"
                  classes="length60"
                  :item-label="labels.municipality_name_ryaku"
                  :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list.city_list : []"
                  :disabled="false"
                />
                <ValidationText
                  v-model="detail.town"
                  classes="form-control length60"
                  :disabled="false"
                  :item-label="labels.town"
                  item-name="town"
                  :placeholder="$t('placeholder.town')"
                  :help-text="$t('label.max_length')"
                  max-length="50"
                />
                <ValidationText
                  v-model="detail.building"
                  item-name="building"
                  max-length="30"
                  classes="form-control length60"
                  :item-label="labels.building"
                  :placeholder="$t('placeholder.building')"
                  :help-text="$t('label.max_length')"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import mixin from '@/lib/mixin'
  import VPasswordStrengthMeter from 'v-password-strength-meter'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationDate,
      ValidationSelect,
      ValidationRadio,
      ValidationPostalCode,
      VPasswordStrengthMeter
    },
    props: {
      futureGoogle: Promise
    },
    data () {
      return {
        componentKey: 0,
        componentKey1: 1000,
        geocoder: {},
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now()
          }
        },
        detail: {
          password: ''
        },
        labels: {
          employee_code: '従業員コード',
          employee_name: '氏名',
          employee_classification_code: '従業員区分',
          language: '言語種類',
          mail_address: 'メールアドレス',
          hire_date: '入社年月日',
          attendance_management: '労働管理',
          payroll_management: '賃金管理',
          paid_leave_payment: '有給休暇',
          number_of_working_days_per_week: '週の労働日数'
        },
        comboData: {
          employee_classification_code_list: [],
          language_list: [],
          attendance_management_list: [],
          payroll_management_list: [],
          paid_leave_payment: []
        },
        employeeMessage: {},
        workingDays: [
          {label: '1日', value: '1'},
          {label: '2日', value: '2'},
          {label: '3日', value: '3'},
          {label: '4日', value: '4'},
          {label: '5日以上', value: '5'}
        ],
        size: 256 * 1024
      }
    },
    methods: {
      prefecturesChanged () {
        if (this.detail.prefectures_before) {
          this.detail.city = ''
        }
        if (this.detail.prefectures) {
          this.detail.prefectures_before = this.detail.prefectures
          this.comboData.state_municipality_mapping_list.city_list = []
          if (!this.comboData.state_municipality_mapping_list.state_municipality_mapping_list[this.detail.prefectures]) {
            for (const prefecture of this.comboData.state_municipality_mapping_list.state_list) {
              if (prefecture.label === this.detail.prefectures) {
                this.detail.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.state_municipality_mapping_list.state_municipality_mapping_list[this.detail.prefectures]) {
            for (const code of this.comboData.state_municipality_mapping_list.state_municipality_mapping_list[this.detail.prefectures]) {
              for (const entry of this.comboData.state_municipality_mapping_list.municipality_list) {
                if (code === entry.value) {
                  this.comboData.state_municipality_mapping_list.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.state_municipality_mapping_list.city_list = []
        }
      },
      postalCodeSearch (postalCode) {
        const self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
            const myLatlng = results[0].geometry.location
            self.geocoder.geocode({location: myLatlng}, function (results, status) {
              if (status === 'OK') {
                for (const result of results) {
                  if (result.types) {
                    let isFound = false
                    for (const type of result.types) {
                      if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                        const componentsLength = result.address_components.length
                        self.detail.post_code = result.address_components[componentsLength - 1].long_name
                        for (const prefecture of self.comboData.state_municipality_mapping_list.state_list) {
                          if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                            self.detail.prefectures = prefecture.value
                            break
                          }
                        }
                        if (self.detail.prefectures) {
                          self.prefecturesChanged()
                        }
                        for (const municipality of self.comboData.state_municipality_mapping_list.city_list) {
                          if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                            self.detail.city = municipality.value
                            break
                          }
                        }
                        self.detail.town = ''
                        for (var i = componentsLength - 5; i >= 0; i--) {
                          self.detail.town = self.detail.town + result.address_components[i].long_name
                        }
                        self.componentKey += 1
                        isFound = true
                        break
                      }
                    }
                    if (isFound) break
                  }
                }
              }
            })
          })
        }
      },
      onEmployeeFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createEmployeeImage(files[0])
      },
      createEmployeeImage (file) {
        if (file.size > this.size) {
          swal({
            text: this.$t('message.file_size_over').replace('{size}', this.formatNumber(this.size / 1024)),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        let self = this
        const mailAddress = sessionStorage.getItem('mailAddress')
        let appendPath = `new-company/${mailAddress}/employeePhoto.jpg`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.put(file, {contentType: 'image/jpeg'}).then(function () {
          self.detail.employee_append_path = appendPath
          httpsReference.getDownloadURL().then(function (url) {
            self.detail.employee_photo = url
            self.componentKey1 += 1
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      deleteEmployeeImage () {
        this.detail.employee_photo = undefined
        this.componentKey1 += 1
      },
      async setGeocoder () {
        this.futureGoogle.then((google) => {
          this.geocoder = new google.maps.Geocoder()
        })
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_employee`, request)
        .then(res => {
          this.employeeMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          if (!res.common_area.combo_data.state_municipality_mapping_list) {
            res.common_area.combo_data.state_municipality_mapping_list = {}
            res.common_area.combo_data.state_municipality_mapping_list.state_list = []
            res.common_area.combo_data.state_municipality_mapping_list.municipality_list = []
          }
          res.common_area.combo_data.state_municipality_mapping_list.city_list = []
          this.comboData.state_municipality_mapping_list = res.common_area.combo_data.state_municipality_mapping_list
          this.detail.language = 'JPN'
          this.detail.attendance_management = '1'
          this.detail.number_of_working_days_per_week = '5'
          this.detail.business_name = '一般'
          this.setGeocoder()
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
