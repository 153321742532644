<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" @show-schedule="showSchedule" ref="detail"/>
      </div>
    </modal>
    <modal name="schedule" :draggable="true" :clickToClose="false" height="auto">
      <div class="modal-body">
        <TransformationDetail :mode="mode" :params="params" @close="hideSchedule" @edit="showScheduleEdit" ref="schedule"/>
      </div>
    </modal>
    <modal name="scheduleEdit" :draggable="true" :clickToClose="false" height="auto">
      <div class="modal-body">
        <TransformationDetailEdit :params="editParams" @close="hideScheduleEdit"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <ValidationSelect
            v-model="searchCond.office_code"
            item-name="office_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.office_list)"
            :item-label="labels.office_code_ryaku === undefined ? labels.officeCode_ryaku === undefined ? '' : labels.officeCode_ryaku : labels.office_code_ryaku"
            :options="comboData.office_list"
            frame-class="search-condition-item"
            @change="officeCodeChanged"
          />
          <ValidationSelect
            v-model="searchCond.business_type"
            item-name="business_type"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.business_type_list)"
            :item-label="labels.business_type_ryaku === undefined ? labels.businessType_ryaku === undefined ? '' : labels.businessType_ryaku : labels.business_type_ryaku"
            :options="comboData.business_type_list"
            frame-class="search-condition-item"
            @change="businessTypeChanged"
          />
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="200"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.print === 1">
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="handlePrint(props.$index, props.row)">
                      {{$t('button.print')}}
                    </button>
                  </template>
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showOffice" :width="getColumnWidthStrong(tableData, labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku, 'office_code', 'office_name')" :label="labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.office_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.office_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showStartingDate" :width="getColumnWidth(tableData, labels.starting_date_ryaku === undefined ? labels.startingDate_ryaku === undefined ? '' : labels.startingDate_ryaku : labels.starting_date_ryaku, 'starting_date')" :label="labels.starting_date_ryaku === undefined ? labels.startingDate_ryaku === undefined ? '' : labels.startingDate_ryaku : labels.starting_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.starting_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showBusinessName" :width="getColumnWidth(tableData, labels.business_name_ryaku === undefined ? labels.businessName_ryaku === undefined ? '' : labels.businessName_ryaku : labels.business_name_ryaku, 'business_name')" :label="labels.business_name_ryaku === undefined ? labels.businessName_ryaku === undefined ? '' : labels.businessName_ryaku : labels.business_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.business_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermFrom" :width="getColumnWidth(tableData, labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku, 'term_from')" :label="labels.term_from_ryaku === undefined ? labels.termFrom_ryaku === undefined ? '' : labels.termFrom_ryaku : labels.term_from_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.term_from}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTermTo" :width="getColumnWidth(tableData, labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku, 'term_to')" :label="labels.term_to_ryaku === undefined ? labels.termTo_ryaku === undefined ? '' : labels.termTo_ryaku : labels.term_to_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.term_to}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showAgreementDate" :width="getColumnWidth(tableData, labels.agreement_date_ryaku === undefined ? labels.agreementDate_ryaku === undefined ? '' : labels.agreementDate_ryaku : labels.agreement_date_ryaku, 'agreement_date')" :label="labels.agreement_date_ryaku === undefined ? labels.agreementDate_ryaku === undefined ? '' : labels.agreementDate_ryaku : labels.agreement_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.agreement_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{ props.row.update_employee_name }}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{ props.row.update_employee_code }}</small>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import TransformationYearlySearchMixin from '@/components/Dashboard/Views/Generated/TransformationYearlySearchMixin'
  import swal from 'sweetalert2'

  export default {
    name: 'TransformationYearlySearch',
    data () {
      return {
        params: {},
        editParams: {},
        modalWidth: 1100
      }
    },
    methods: {
      afterInit (res) {
        this.labels.office_code_ryaku = this.labels.office
        this.labels.business_type_ryaku = this.labels.business
        this.comboData.business_type_list = this.comboData.business_list
        this.comboData.office_list.unshift({label: '', value: ''})
        this.comboData.business_type_list.unshift({label: '', value: ''})
      },
      afterSearch (res) {
        this.labels.business_name_ryaku = this.labels.business
        this.labels.agreement_date_ryaku = this.labels.agreement_date
        this.comboData.office_list.unshift({label: '', value: ''})
      },
      showSchedule (officeData) {
        this.params = officeData
        this.$modal.show('schedule')
      },
      hideSchedule (schedules) {
        this.$modal.hide('schedule')
        this.$refs.detail.detail.work_schedule_list = schedules
      },
      showScheduleEdit (editParams) {
        this.editParams = editParams
        this.$modal.show('scheduleEdit')
      },
      hideScheduleEdit (editParams) {
        this.$modal.hide('scheduleEdit')
        if (editParams) {
          for (const row of this.$refs.schedule.tableData) {
            let isApply = false
            if (editParams.target_date) {
              if (editParams.target_date === row.target_date) {
                isApply = true
              }
            } else {
              const weekday = new Date(row.target_date).getDay()
              if (editParams.apply_weekday.items[(weekday + 6) % 7].value) {
                isApply = true
              }
            }
            if (isApply) {
              row.ground_code = editParams.ground_code
              row.ground_name = ''
              for (const groundRow of editParams.combo_data.ground_list) {
                if (groundRow.value === editParams.ground_code) {
                  row.ground_name = groundRow.label
                  break
                }
              }
              row.job_start = editParams.work_time[0]
              row.job_end = editParams.work_time[1]
              row.break_time_list = editParams.break_time_list
              row.break_time_range_list = editParams.break_time_range_list
              let breakMinutes = ''
              for (const breakRow of row.break_time_list) {
                if (breakMinutes !== '') {
                  breakMinutes += ', '
                }
                if (!breakRow.selected) {
                  breakMinutes += breakRow.start_time
                  breakMinutes += '-'
                  breakMinutes += breakRow.end_time
                }
              }
              for (const breakRow of row.break_time_range_list) {
                if (breakMinutes !== '') {
                  breakMinutes += ', '
                }
                if (!breakRow.selected) {
                  breakMinutes += `${breakRow.range_break_hour}${this.$t('label.hours')}${breakRow.range_break_minute}${this.$t('label.breaktime_range_message')}：${breakRow.break_minutes}${this.$t('label.minute')}`
                }
              }
              row.break_minutes = breakMinutes
              this.$refs.schedule.componentKey += 1
            }
          }
        }
      },
      handlePrint (index, row) {
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            office_code: row.office_code,
            starting_date: row.starting_date,
            business_type: row.business_type
          }
        }
        this.send('/transformationTimeLabor/print/year', params)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      }
    },
    mixins: [TransformationYearlySearchMixin]
  }
</script>
<style scoped>
</style>
