<template>
  <div>
    <div class="text-center" style="font-size: 16px; margin-bottom: 20px;">
      Artemisを利用するには月額利用料が発生します。支払情報を登録お願いします。<br>
      登録された情報を元に毎月、利用料が引き落としされます。<br>
      {{`現在、${employeeCount}名の方が利用されています。`}}
    </div>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 500px);" :key="componentKey">
      <div class="col-md-11 col-md-offset-1">
        <div style="color: red;">{{error}}</div>
        <div class="card">
          <div class="card-content">
            <ValidationObserver ref="observer">
              <ValidationSelect
                v-model="admin"
                item-label="管理者"
                :options="employees"
                :disabled="false"
                @change="adminSelected"
              />
              <ValidationText
                v-model="email"
                item-name="email"
                max-length="100"
                classes="form-control width300"
                rules="required|mailaddress"
                :item-label="$t('label.owner_mail_address')"
              />
            </ValidationObserver>
          </div>
          <div class="card-footer">
            <br>
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="goPaymentPage">
              支払い画面へ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect
    },
    data () {
      return {
        componentKey: 0,
        error: '',
        admin: undefined,
        email: undefined,
        employeeCount: '10',
        licenseCount: '10',
        employees: [],
        allEmployees: []
      }
    },
    methods: {
      adminSelected () {
        for (const row of this.allEmployees) {
          if (this.admin === row.employee_code) {
            this.email = row.mail_address
            break
          }
        }
      },
      goPaymentPage () {
        if (this.admin === undefined) {
          swal({
            type: 'warning',
            title: '管理者を選択してください',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        this.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          } else {
            let paymentInfo = {
              licenseCount: parseInt(this.licenseCount, 10) > 10 ? this.licenseCount : '10',
              email: this.email
            }
            localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo))
            window.open('/#/migration/waiting', 'checkout', 'width=900,height=600')
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    watch: {
      allEmployees () {
        this.employees = []
        for (const element of this.allEmployees) {
          let employee = {
            label: element.employee_name,
            value: element.employee_code
          }
          this.employees.push(employee)
        }
        // this.licenseCount = this.allEmployees.length
        // this.employeeCount = this.allEmployees.length
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        this.licenseCount = migrationData.payment_info.licenseCount
        this.employeeCount = migrationData.payment_info.employeeCount
        this.admin = migrationData.payment_info.admin
        this.employees = migrationData.payment_info.employees
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_payment', params)
        .then(res => {
          // this.licenseCount = String(res.init_data.number_of_license)
          // this.employeeCount = res.init_data.number_of_employees
          this.employees = []
          for (const element of res.common_init_data.combo_data.employee_list) {
            let employee = {
              label: element.name,
              value: element.employee_cd
            }
            this.employees.push(employee)
          }
          if (this.employees.length === 1) {
            this.admin = this.employees[0].value
            this.email = res.common_init_data.combo_data.employee_list[0].mail_address
          }
          this.componentKey += 1
        })
      }
    }
  }
</script>
<style>
</style>
