<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-minimize" style="padding-top: 5px;">
        <button class="btn btn-sm btn-default btn-icon" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'ti-shift-right' : 'ti-shift-left'"></i>
        </button>
      </div>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <a class="navbar-brand">{{pageName}}</a>
      </div>
      <div class="collapse navbar-collapse">
        <form class="navbar-form navbar-left navbar-search-form" role="search" style="padding-top: 8px;">
          <span v-if="isTest" style="background-color: #C33; color: white; padding: 2px 10px; margin: 0 20px;">＠テスト</span>
          <span v-if="!isProd && !isTest" style="background-color: #080; color: white; padding: 2px 10px; margin: 0 20px;">＠開発</span>
        </form>
        <ul class="nav navbar-nav navbar-right">
          <drop-down tag="li" icon="ti-settings">
            <!-- <li><a @click="showLanguageSelect">{{$t('label.change_language')}}</a></li> -->
            <li><a @click="showChangePassword">{{$t('label.change_password')}}</a></li>
            <li><a href="https://linkpoint.zendesk.com/hc/ja/" target="_blank">{{$t('label.help')}}</a></li>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    methods: {
      showChangePassword () {
        this.$router.push({name: 'userPasswordChange'})
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    },
    computed: {
      pageName () {
        var name = this.$t('label.unknown_screen')
        if (window.currentUrl === '/user/menu') {
          name = this.$t('label.menu')
        }
        if (window.currentUrl === '/user/payment') {
          name = this.$t('button.go_payment_page')
        }
        if (window.currentUrl === '/user/mail_address_change') {
          name = this.$t('label.change_mail_address')
        }
        if (window.currentUrl === '/user/password_change') {
          name = this.$t('label.change_password')
        }
        if (window.currentUrl === '/user/company-wizard') {
          name = this.$t('button.company_add')
        }
        if (window.currentUrl === '/user/credit_card') {
          name = this.$t('button.update_credit_card')
        }
        if (window.currentUrl === '/user/invoice') {
          name = this.$t('button.update_invoice')
        }
        if (window.currentUrl === '/user/delete_company') {
          name = this.$t('button.delete_company_cancellation')
        }
        return name
      },
      deputyApproverlDescription () {
        const deputyApproverlList = this.$store.state.deputyApproverlList
        if (deputyApproverlList.length === 1) {
          return this.$t('label.deputy_approverl_description1').replace('{employeeCode}', deputyApproverlList[0].employee_code).replace('{employeeName}', deputyApproverlList[0].employee_name)
        } else {
          return this.$t('label.deputy_approverl_description2').replace('{employeeCode}', deputyApproverlList[0].employee_code).replace('{employeeName}', deputyApproverlList[0].employee_name).replace('{num}', deputyApproverlList.length - 1)
        }
      },
      alertList () {
        return this.$store.state.alertList ? this.$store.state.alertList : []
      }
    },
    created () {
      if (this.$store.state.loginUser.groupList) {
        this.groups = this.$store.state.loginUser.groupList
        this.group = this.$store.state.loginUser.groupCode
      }
    }
  }

</script>
<style scoped>
  .alert-row {
    cursor: none;
    text-decoration: none;
  }
  .alert-row:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .btn, .btn:focus, .btn:hover {
    color: #C50052;
    border-color: #C50052;
    background-color: transparent;
  }
</style>
