<template>
  <div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    data () {
      return {
        labels: {}
      }
    },
    methods: {
      goInvoicePage () {
        const customerId = localStorage.getItem('customerId')
        if (customerId) {
          this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeSubscriptions?customerId=' + customerId)
          .then(res => {
            if (res.data && !res.data.isSubscription && res.data.paymentPageUrl) {
              window.open(res.data.paymentPageUrl)
            }
          })
        }
      }
    },
    created () {
      const request = {
        mail_address: localStorage.getItem('migrationMailAddress'),
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.goInvoicePage()
      })
    }
  }
</script>
<style scoped>
</style>
