<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{$t('label.late_early_departure_type')}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationRadio
            v-model="application.late_early_departure_type"
            rules="required"
            :item-label="$t('label.late_early_departure_type')"
            :disabled="isReadOnly || application.is_fixed"
            @change="$emit('applicationTypeChanged', application.late_early_departure_type.value)"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{labels.target_date}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.target_date"
            item-name="target_date"
            rules="required"
            :itemLabel="labels.target_date"
            placeholder="2020-01-01"
            :disabled="(isReadOnly || application.is_fixed) && isModal"
            @input="$emit('initApply', application.target_date, application.work_schedule_code)"
          />
        </div>
      </div>
      <div class="form-group" v-if="false" :key="componentKey">
        <div class="length7" style="display: inline-block;">
          <label>{{$t('label.actual')}}</label>
        </div>
        <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_late_early_minutes">
          {{application.actual_late_early_minutes}}
        </div>
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="$emit('showActual')">
          {{$t('button.show_actual')}}
        </button>        
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{isLateApplication ? labels.late_minutes : labels.early_departure_minutes}}</label>
        </div>
        <ValidationTimes
          v-model="application.late_early_departure_minutes"
          item-name="late_early_departure_minutes"
          classes="select-success length2"
          rules="required"
          :item-label="isLateApplication ? labels.late_minutes : labels.early_departure_minutes"
          :disabled="isReadOnly || application.is_fixed"
          :labels="labels"
          :is-late-early-departure="true"
        />
        <div>
          <div class="length7" style="display: inline-block;">
            <label>&nbsp;</label>
          </div>
          {{ application.late_early_departure_minutes ? jobStartEnd : '' }}
        </div>
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <ValidationSelect
          v-model="application.work_schedule_code"
          item-name="work_schedule_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          :item-label="labels.workSchedule"
          :options="comboData.work_schedule_list"
          :disabled="isReadOnly || application.is_fixed"
          @input="$emit('initApply', application.target_date, application.work_schedule_code)"
        />
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block; vertical-align: top;">
          <label>{{isLateApplication ? labels.late_content : labels.early_departure_content}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.business_content"
            item-name="business_content"
            max-length="100"
            classes="form-control width480"
            :item-label="isLateApplication ? labels.late_content : labels.early_departure_content"
            :placeholder="isReadOnly ? '' : $t('placeholder.late_early_departure_content')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length7" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.late_early_departure_supplement')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimesHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTimes,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate,
      ValidationRadio
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    computed: {
      isLateApplication () {
        return !this.application.late_early_departure_type || this.application.late_early_departure_type.value === '1'
      },
      jobStartEnd () {
        if (this.application.late_early_departure_type.value === '1') {
          if (!this.application.job_start) return ''
          const jobStart = parseInt(this.application.job_start.split(':')[0], 10) * 60 + parseInt(this.application.job_start.split(':')[1], 10)
          const workStartTime = jobStart + parseInt(this.application.late_early_departure_minutes, 10)
          return this.$t('label.actual_work_start').replace('{hour}', parseInt(workStartTime / 60, 10)).replace('{minute}', ('0' + String(workStartTime % 60)).slice(-2))
        } else {
          console.log(this.application)
          if (!this.application.job_end) return ''
          const jobEnd = parseInt(this.application.job_end.split(':')[0], 10) * 60 + parseInt(this.application.job_end.split(':')[1], 10)
          const workEndTime = jobEnd - parseInt(this.application.late_early_departure_minutes, 10)
          return this.$t('label.actual_work_end').replace('{hour}', parseInt(workEndTime / 60, 10)).replace('{minute}', ('0' + String(workEndTime % 60)).slice(-2))
        }
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
