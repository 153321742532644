<template>
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <template v-if="isErrorPositionTop">
      <div v-if="!valid">
        <small class="text-danger">
          {{ errors[0] ? errors[0].indexOf('|') === -1 ? errors[0] : errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
    </template>
    <el-select :class="itemClasses"
                size="large"
                :name="itemName"
                :disabled="disabled"
                :placeholder="placeholder"
                v-model="itemValue"
                @change="changed">
      <el-option v-for="option in options"
                  class="select-success"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value">
      </el-option>
    </el-select>
    <template v-if="!isErrorPositionTop">
      <div class="error-message-wrapper" v-show="!valid" style="display: inline-block;">
        <small class="text-danger">
          {{ errors[0] ? errors[0].indexOf('|') === -1 ? errors[0] : errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
    </template>
  </ValidationProvider>
</template>

<script>
  import PlaceDetail from '@/components/Dashboard/Views/Generated/PlaceDetail'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      PlaceDetail
    },
    props: {
      value: [String, Number],
      itemName: String,
      rules: String,
      itemLabel: String,
      options: Array,
      disabled: Boolean,
      classes: String,
      placeholder: String,
      isErrorPositionTop: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    methods: {
      show () {
        this.$modal.show(this.modalName)
      },
      hide (added) {
        this.$modal.hide(this.modalName)
        if (added.value) {
          this.options.push(added)
          this.itemValue = added.value
          this.$emit('input', this.itemValue)
        }
      },
      changed () {
        this.$emit('input', this.itemValue)
        this.$emit('change')
      }
    },
    data () {
      return {
        params: {},
        itemValue: null,
        itemClasses: 'select-success'
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
        this.itemClasses = 'select-success ' + this.classes
      }
    },
    mounted () {
      this.itemValue = this.value
      this.itemClasses = 'select-success ' + this.classes
    }
  }
</script>

<style scoped>
</style>
