<template>
  <div>
    <div class="form-group">
      <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="labels.division_group + '|' + new Date().getTime().toString(16)">
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            {{ getMessage(errors[0]).replace('{item}', labels.division_group) }}
          </small>
        </div>
        <DivisionGroup
          v-model="tableData"
          :combo-data="comboData"
          :labels="labels"
          :disabled="disabled"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import DivisionGroup from '@/components/UIComponents/Inputs/DivisionGroup'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      DivisionGroup
    },
    props: {
      value: Array,
      rules: String,
      labels: Object,
      comboData: Object,
      disabled: Boolean
    },
    data () {
      return {
        tableData: {}
      }
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    },
    watch: {
      value () {
        this.tableData = {tableData: this.value}
      }
    },
    mounted () {
      this.tableData = {tableData: this.value}
    }
  }
</script>

<style scoped>
</style>
