<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <EmployeesSearch :orgSearchCond="searchCond" :labels="labels" :comboData="comboData" :disabled="isChanged" @search="searchClicked" />
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showOffice" :width="getColumnWidthStrong(tableData, labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku, 'office_code', 'office_name')" :label="labels.office_ryaku === undefined ? labels.office_ryaku === undefined ? '' : labels.office_ryaku : labels.office_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.office_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.office_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTeam" :width="getColumnWidthStrong(tableData, labels.team_ryaku === undefined ? labels.team_ryaku === undefined ? '' : labels.team_ryaku : labels.team_ryaku, 'team_code', 'team_name')" :label="labels.team_ryaku === undefined ? labels.team_ryaku === undefined ? '' : labels.team_ryaku : labels.team_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.team_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.team_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showGroup" :width="getColumnWidthStrong(tableData, labels.group_ryaku === undefined ? labels.group_ryaku === undefined ? '' : labels.group_ryaku : labels.group_ryaku, 'group_code', 'group_name')" :label="labels.group_ryaku === undefined ? labels.group_ryaku === undefined ? '' : labels.group_ryaku : labels.group_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.group_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.group_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployeeClassificationName" :width="getColumnWidth(tableData, labels.employee_classification_ryaku === undefined ? labels.employeeClassification_ryaku === undefined ? '' : labels.employeeClassification_ryaku : labels.employee_classification_ryaku, 'employee_classification_name')" :label="labels.employee_classification_ryaku === undefined ? labels.employeeClassification_ryaku === undefined ? '' : labels.employeeClassification_ryaku : labels.employee_classification_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.employee_classification_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployee" :width="getColumnWidthStrong(tableData, labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku, 'employee_code', 'employee_name')" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showSalaryIncreaseAmount" :width="getColumnWidth(tableData, labels.salary_increase_amount_ryaku === undefined ? labels.salaryIncreaseAmount_ryaku === undefined ? '' : labels.salaryIncreaseAmount_ryaku : labels.salary_increase_amount_ryaku, 'salary_increase_amount')" :label="labels.salary_increase_amount_ryaku === undefined ? labels.salaryIncreaseAmount_ryaku === undefined ? '' : labels.salaryIncreaseAmount_ryaku : labels.salary_increase_amount_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.salary_increase_amount}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showLaborCosts" :width="getColumnWidth(tableData, labels.labor_costs_ryaku === undefined ? labels.laborCosts_ryaku === undefined ? '' : labels.laborCosts_ryaku : labels.labor_costs_ryaku, 'labor_costs')" :label="labels.labor_costs_ryaku === undefined ? labels.laborCosts_ryaku === undefined ? '' : labels.laborCosts_ryaku : labels.labor_costs_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{formatNumber(props.row.labor_costs)}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import SalaryIncreaseSimulationSearchMixin from '@/components/Dashboard/Views/Generated/SalaryIncreaseSimulationSearchMixin'

  export default {
    name: 'SalaryIncreaseSimulationSearch',
    data () {
      return {
        modalWidth: 1400
      }
    },
    methods: {
      afterInit (res) {
        this.labels.search = 1
      },
      convertSearchCond (searchCond) {
        return {}
      }
    },
    mixins: [SalaryIncreaseSimulationSearchMixin]
  }
</script>
<style scoped>
</style>
