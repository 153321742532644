import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Salary/CreatePaySlipDetail'
import Bank from '@/components/Dashboard/Views/Salary/BankTransferList'
import CashTransfer from '@/components/Dashboard/Views/Salary/CashTransferList'
import Journal from '@/components/Dashboard/Views/Salary/JournalList'
import SendMail from '@/components/Dashboard/Views/Salary/SendMail'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import EmployeesSearch from '@/components/UIComponents/Inputs/EmployeesSearch'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'CreatePaySlipSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    Bank,
    CashTransfer,
    Journal,
    SendMail,
    ValidationSelect,
    PPagination,
    EmployeesSearch
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1600,
      modalWidthBank: 800,
      modalWidthCashTransfer: 400,
      modalWidthJournal: 1400,
      modalWidthSendMail: 1400,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        search_type: '3',
        office_code: '',
        team_code: '',
        employee_code: '',
        employee_name: '',
        employee_classification_code: '',
        group_code: '',
        retirement: true,
        hireYear: false,
        salary_closing_code: ''
      },
      showOperation: true,
      showLayoutCode: true,
      showLayoutName: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: '',
      tableWrapperStyle: '',
      roles: {
        preview: 0,
        update: 0,
        entry: 0,
        delete: 0,
        print: 0
      },
      fromYearMonth: '',
      toYearMonth: '',
      currentYearMonth: '',
      dispFlg: true
    }
  },
  computed: {
    screenCode () {
      return '02-001'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    addAll (targetDate) {
      const employeeCodeList = []
      for (const salaryData of this.tableData) {
        if (salaryData.target_date === targetDate) {
          for (const closingData of salaryData.salary_closing_data) {
            for (const data of closingData.detail_data) {
              if (data.selected) {
                employeeCodeList.push(data.employee_code)
              }
            }
          }
          break
        }
      }
      if (employeeCodeList.length === 0) {
        swal({
          html: this.$t('message.no_employees_selected'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
        })
        return
      }
      const reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: targetDate,
          employee_code_list: employeeCodeList
        }
      }
      this.send('/insert/pay_slip/list', reqData)
      .then(res => {
        swal({
          title: res.message.text,
          text: res.message.correspondence_action,
          type: 'info',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          reverseButtons: true,
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
        })
      })
    },
    selectAll (targetDate, salaryClosingName) {
      for (const salaryData of this.tableData) {
        if (salaryData.target_date === targetDate) {
          for (const closingData of salaryData.salary_closing_data) {
            if (closingData.salary_closing_name === salaryClosingName) {
              const checked = closingData.selected
              for (const data of closingData.detail_data) {
                if (data.t_employee_pay_slip_exist !== 1 && this.dispFlg && (data.approverl_flg === 3 || data.approverl_flg === 8)) {
                  data.selected = checked
                }
              }
              closingData.detail_data = [...closingData.detail_data]
              break
            }
          }
          salaryData.salary_closing_data = [...salaryData.salary_closing_data]
          break
        }
      }
      this.tableData = [...this.tableData]
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.comboData.t_employee_pay_slip_exist_list = [
        {label: '', value: '0'},
        {label: this.$t('label.already'), value: '1'}
      ]
      this.comboData.salary_closing_list.unshift({label: '', value: ''})
      this.roles = res.common_area.button_role // 権限
      if (this.$route.params.attendanceRecord) {
        this.searchCond.search_type = parseInt(this.$route.params.attendanceRecord.search_type, 10)
        this.searchCond.retirement = this.$route.params.attendanceRecord.retirement === 'true'
        this.searchCond.from_year = this.$route.params.attendanceRecord.term_from.substring(0, 4)
        this.searchCond.from_month = this.$route.params.attendanceRecord.term_from.substring(4)
        this.searchCond.to_year = this.$route.params.attendanceRecord.term_to.substring(0, 4)
        this.searchCond.to_month = this.$route.params.attendanceRecord.term_to.substring(4)
        this.search()
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      this.comboData.salary_closing_list.unshift({label: '', value: ''})
      this.roles = res.common_area.button_role // 権限
      // 給与承認リスト
      this.comboData.is_close_list = [
        {label: this.$t('label.pay_slip_unapproved'), value: '1'},
        {label: this.$t('label.pay_slip_approved'), value: '2'}
      ]
      for (let salary of res.api_area.salary_data) {
        for (let salaryClosing of salary.salary_closing_data) {
          for (let detail of salaryClosing.detail_data) {
            if (detail.residentTaxErrMsg !== '') {
              swal({
                html: detail.residentTaxErrMsg,
                type: 'warning',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                reverseButtons: true,
                buttonsStyling: false,
                allowOutsideClick: false
              }).then(() => {
              })
              break
            }
          }
        }
      }
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      searchCond.term_from = searchCond.from_year + ('00' + parseInt(searchCond.from_month)).slice(-2)
      searchCond.term_to = searchCond.to_year + ('00' + parseInt(searchCond.to_month)).slice(-2)
      delete searchCond.from_year
      delete searchCond.from_month
      delete searchCond.to_year
      delete searchCond.to_month
      delete searchCond.hireYear
      return searchCond
    },
    doUpdate () {},
    canPreview (row) {
      return this.roles.preview === 1 && row.t_employee_pay_slip_exist === 1
    },
    canEntry (row) {
      return this.roles.entry === 1 && row.t_employee_pay_slip_exist !== 1 && row.salary_is_close === 1
    },
    canUpdate (row) {
      return this.roles.update === 1 && row.t_employee_pay_slip_exist === 1 && row.salary_is_close === 1
    },
    canDelete (row) {
      return this.roles.delete === 1 && row.t_employee_pay_slip_exist === 1 && row.salary_is_close === 1
    },
    canPrint (row) {
      return this.roles.print === 1 && row.t_employee_pay_slip_exist === 1
    },
    canAllPrint (salaryClosingDatas) {
      let result = true
      for (const salaryClosingData of salaryClosingDatas) {
        for (const row of salaryClosingData.detail_data) {
          if (row.t_employee_pay_slip_exist === 1) {
            result = false
            break
          }
        }
      }
      return result
    },
    executeOperation (execute) {
      return execute ? '' : 'visibility:hidden;'
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/pay_slip/list', reqData)
      .then(res => {
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.salary_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          if (window.previousUrl === '/02/menu02Screen001/detail') {
            const scrollTop = parseInt(sessionStorage.getItem('orgScrollTop'), 10)
            this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop = scrollTop
          }
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/init/pay_slip/list', data)
      .then(res => {
        this.fromYearMonth = res.common_area.combo_data.select_date_from
        this.toYearMonth = res.common_area.combo_data.select_date_to
        this.currentYearMonth = res.common_area.combo_data.select_date
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    doPrintAll () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/print/pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    doPrint (index, row) {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      data.api_area.search_type = 3
      data.api_area.employee_code = row.employee_code
      data.api_area.employee_name = row.employee_name
      data.api_area.term_from = row.target_date
      data.api_area.term_to = row.target_date
      this.send('/print/pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    getRowData (detailData) {
      let result = []
      for (const row of detailData) {
        if (row.approverl_flg === 3 || row.approverl_flg === 8 || row.t_employee_pay_slip_exist === 1) {
          if (this.canPrint(row) || this.canPreview(row) || this.canUpdate(row) || this.canEntry(row) || this.canDelete(row)) {
            result.push(row)
          }
        }
      }
      return result
    },
    getDeleteRowData (detailData) {
      let result = []
      for (const row of detailData) {
        if (row.approverl_flg === 3 || row.approverl_flg === 8 || row.t_employee_pay_slip_exist === 1) {
          if (this.canDelete(row)) {
            result.push(row)
          }
        }
      }
      return result
    },
    getIndex (rowData, employeeCode) {
      let index = 0
      for (index = 0; index < rowData.length; index++) {
        if (rowData[index].employee_code === employeeCode) {
          return index
        }
      }
    },
    handleView (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.view
      let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'createPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.view
        }
      })
    },
    handleEdit (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.edit
      let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'createPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.edit
        }
      })
    },
    handleDelete (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.delete
      let sendRowData = this.getDeleteRowData(detailData)
      this.$router.push({ name: 'createPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.delete
        }
      })
    },
    handleAdd (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.add
      let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'createPaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.add
        }
      })
    },
    showBank (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('bank')
    },
    showCashTransfer (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('cashTransfer')
    },
    showJournal (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('journal')
    },
    showSendMail (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('sendMail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    hideBank (...args) {
      this.$modal.hide('bank')
      if (args[0]) {
        this.search()
      }
    },
    hideCashTransfer (...args) {
      this.$modal.hide('cashTransfer')
      if (args[0]) {
        this.search()
      }
    },
    hideJournal (...args) {
      this.$modal.hide('journal')
      if (args[0]) {
        this.search()
      }
    },
    hideSendMail (...args) {
      this.$modal.hide('sendMail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    }
  },
  mounted () {
    if (window.previousUrl === '/02/menu02Screen001/detail') {
      const orgSearchCond = localStorage.getItem('orgSearchCond')
      this.searchCond = JSON.parse(orgSearchCond)
      this.$refs.employeesSearch.searchCond = this.searchCond
      this.search()
    }
    this.init()
  }
}
