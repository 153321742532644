<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>

              <div class="col-md-10 col-md-offset-1">
								<div style="font-size: small">
									<h3>Google API Serviceユーザーデータポリシー</h3><br />
									<br />
									Google API から受信した情報のArtemis Collierでの使用および他のアプリへの転送は、限定使用要件を含む <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" style="color: #317FB8;">Google API サービスのユーザー データ ポリシー</a>に準拠します。
									<br /><br />
									Artemis Collier app’s use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" style="color: #317FB8;">Google API Services User Data Policy</a>, including the Limited Use requirements.
								</div>
								<div class="maine_moji"></div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
</style>
