<template>
  <div class="row" ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div style="display: inline-block; width: 49%;">
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
        </div>
        <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
        </div>
        <div class="card" style="margin-top: 2px;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 79%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 20%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <div class="form-group search-condition-item">
              <p-radio v-model="searchCond.search_type" label="1" :inline="true">{{labels.group ? $t('label.filter_by').replace('{item}', labels.group) : ''}}</p-radio>
              <p-radio v-model="searchCond.search_type" label="2" :inline="true">{{labels.team ? $t('label.filter_by').replace('{item}', labels.team) : ''}}</p-radio>
            </div>
            <template v-if="searchCond.search_type==='0'">
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{labels.office}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.office_code">
                    <el-option v-for="office in comboData.office_list"
                                class="select-success"
                                :value="office.value"
                                :label="office.label"
                                :key="office.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <template v-else-if="searchCond.search_type==='1'">
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{labels.group}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.group_code">
                    <el-option v-for="group in comboData.group_list"
                                class="select-success"
                                :value="group.value"
                                :label="group.label"
                                :key="group.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <template v-else-if="searchCond.search_type==='2'">
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{labels.team}}</label>
                <div>
                  <el-select class="select-success width300"
                              size="large"
                              placeholder=""
                              v-model="searchCond.team_code">
                    <el-option v-for="team in comboData.team_list"
                                class="select-success"
                                :value="team.value"
                                :label="team.label"
                                :key="team.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <div class="form-group search-condition-item" style="display: inline-block;">
              <label>{{labels.employeeClassification}}</label>
              <div>
                <div style="display: inline-block;">
                  <el-select class="select-success width130"
                              size="large"
                              placeholder=""
                              v-model="searchCond.employee_classification_code">
                    <el-option v-for="employee_type in comboData.employee_classification_list"
                                class="select-success"
                                :value="employee_type.value"
                                :label="employee_type.label"
                                :key="employee_type.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- 退職者検索用のチェックボックス -->
            <div class="form-group" v-show="showRetirement">
              <div>
                <input type="checkbox" v-model="searchCond.retirement" class="gray" id="retired" />
                <label for="retired">{{$t('label.search_all_employees')}}</label>
              </div>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <template v-if="results.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title" style="margin: 0;">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content" :key="componentKey">
            <div class="row" v-for="(result, index) in results" v-bind:key="result.code">
              <div class="col-sm-4">
                <el-select
                  class="select-success length2"
                  v-model="result.pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-success"
                    v-for="item in result.pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-sm-4">
              </div>
              <div class="col-sm-4">
                <div class="pull-right">
                </div>
              </div>
              <div class="col-sm-12" style="margin-top: 10px;">
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                          :data="queriedData(index)"
                          style="width: 100%">
                  <el-table-column
                    :width="90"
                    fixed="left"
                    :label="$t('label.operation')"
                    align="center">
                    <template slot-scope="props">
                      <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="!props.row.is_input" @click="select(props.$index, props.row)">{{$t('button.select')}}</button>
                    </template>
                  </el-table-column>
                  <template v-if="searchedType==='0'">
                    <el-table-column min-width="110" :label="labels.office_ryaku" align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <small>{{props.row.office_code}}</small>
                          <br>
                          {{props.row.office_name}}
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <template v-if="searchedType==='1'">
                    <el-table-column min-width="110" :label="labels.group_ryaku" align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <small>{{props.row.group_code}}</small>
                          <br>
                          {{props.row.group_name}}
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <template v-if="searchedType==='2'">
                    <el-table-column min-width="110" :label="labels.team_ryaku" align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <small>{{props.row.team_code}}</small>
                          <br>
                          {{props.row.team_name}}
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column min-width="110" :label="labels.employeeClassification_ryaku ? labels.employeeClassification_ryaku : ''" align="center">
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small></small>
                        <br>
                        {{props.row.employee_classification_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="110" :label="labels.employee_ryaku ? labels.employee_ryaku : ''" align="center">
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.employee_code}}</small>
                        <br>
                        {{props.row.employee_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="110" :label="labels.ground_name_ryaku ? labels.ground_name_ryaku : ''" align="center">
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        {{getLabel(getSelectName(props.row.ground_code, comboData.ground_list))}}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6 pagination-info">
                <p class="category">{{dispRecords(index)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="result.pagination.currentPage"
                              :per-page="result.pagination.perPage"
                              :total="result.pagination.total"
                              @input="componentKey += 1">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination
    },
    props: {
      orgSearchCond: {
        type: Object,
        require: false,
        'default': () => ({
          search_type: '0',
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: '',
          retirement: false,
          employee_name: '',
          employee_classification_code: '',
          business_type: ''
        })
      },
      companyCode: {
        type: String,
        require: false,
        'default': undefined
      },
      showRetirement: {
        type: Boolean,
        require: false,
        'default': false
      },
      searchAll: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    computed: {
      pageTitle () {
        for (let menu of this.$store.state.loginUser.sidebarLinks) {
          for (let child of menu.children) {
            if (this.$route.path.startsWith(child.path)) {
              return child.name
            }
          }
        }
        return '不明な画面'
      },
      pagedData: function () {
        return function (i) {
          return this.results[i].tableData.slice(this.from(i), this.to(i))
        }
      },
      queriedData: function () {
        return function (i) {
          this.results[i].pagination.total = this.results[i].tableData.length
          return this.pagedData(i)
        }
      },
      to: function () {
        return function (i) {
          let highBound = this.from(i) + this.results[i].pagination.perPage
          if (this.total(i) < highBound) {
            highBound = this.total(i)
          }
          return highBound
        }
      },
      from: function () {
        return function (i) {
          return this.results[i].pagination.perPage * (this.results[i].pagination.currentPage - 1)
        }
      },
      total: function () {
        return function (i) {
          this.results[i].pagination.total = this.results[i].tableData.length
          return this.results[i].tableData.length
        }
      },
      dispRecords: function () {
        return function (i) {
          return this.$t('label.display_range').replace('{total}', this.total(i)).replace('{from}', this.from(i) + 1).replace('{to}', this.to(i))
        }
      }
    },
    data () {
      return {
        componentKey: 0,
        showSearchCondition: true,
        labels: {},
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        searchQuery: '',
        propsToSearch: [],
        headers: {},
        tableData: [],
        comboData: {},
        searchCond: {
          company_code: '',
          office_code: '',
          group_code: '',
          team_code: '',
          employee_type: '',
          employee_code: '',
          retirement: false
        },
        results: [],
        searchedType: 0
      }
    },
    methods: {
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      search () {
        let searchCond = {
          screen_code: '06-006',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.searchCond.search_type, 10),
            office_code: this.searchCond.office_code,
            group_code: this.searchCond.group_code,
            team_code: this.searchCond.team_code,
            employee_code: '',
            employee_name: '',
            employee_classification_code: this.searchCond.employee_classification_code,
            retirement: this.searchCond.retirement,
            business_type: this.searchCond.business_type,
            target_date: this.searchCond.target_date,
            work_schedule_code: this.searchCond.work_schedule_code
          }
        }
        this.send('/view/shift_search_employee/list', searchCond)
        .then(res => {
          let labels = this.getLabels(res.common_area)
          this.labels = {...this.labels, ...labels}
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.pagination.currentPage = 1
          this.searchedType = this.searchCond.search_type
          switch (this.searchedType) {
            case '0':
              const results0 = res.api_area.office_list
              for (const result of results0) {
                result.pagination = {...this.pagination}
                result.code = result.office_code
                result.name = result.office_name
                for (const employee of result.employee_list) {
                  employee.office_code = result.office_code
                  employee.office_name = result.office_name
                }
                result.tableData = result.employee_list
              }
              this.results = results0
              break
            case '1':
              const results1 = res.api_area.group_list
              for (const result of results1) {
                result.pagination = {...this.pagination}
                result.code = result.group_code
                result.name = result.group_name
                for (const employee of result.employee_list) {
                  employee.group_code = result.group_code
                  employee.group_name = result.group_name
                }
                result.tableData = result.employee_list
              }
              this.results = results1
              break
            case '2':
              const results2 = res.api_area.team_list
              for (const result of results2) {
                result.pagination = {...this.pagination}
                result.code = result.team_code
                result.name = result.team_name
                for (const employee of result.employee_list) {
                  employee.team_code = result.team_code
                  employee.team_name = result.team_name
                }
                result.tableData = result.employee_list
              }
              this.results = results2
              break
          }
          if (this.results.length === 0) {
            const result = {
              pagination: {...this.pagination},
              code: '1',
              name: 'dummy',
              tableData: []
            }
            this.results.push(result)
          } else {
            this.toggleSearchCondition()
          }
          this.$nextTick(() => {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 100px);'
          })
        })
      },
      init () {
        // 初期表示
        let params = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {}
        }
        this.send('/init/search_employee/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.comboData.office_list.unshift({label: '', value: ''})
          this.comboData.group_list.unshift({label: '', value: ''})
          this.comboData.team_list.unshift({label: '', value: ''})
          this.comboData.employee_classification_list.unshift({label: '', value: ''})
        })
      },
      push () {
        this.$router.push({ name: 'officeAdd' })
      },
      show () {
        this.$modal.show('office')
      },
      hide (added) {
        this.$modal.hide('office')
      },
      close () {
        this.$emit('close', null)
      },
      select (index, row) {
        this.$emit('close', row)
      }
    },
    created () {
      if (this.orgSearchCond) {
        this.searchCond = Object.assign({}, this.orgSearchCond, {})
        if (!this.searchCond.retirement) {
          this.searchCond.retirement = false
        }
      }
      this.init()
    }
  }
</script>
<style>
</style>
