import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelectSimple from '@/components/UIComponents/Inputs/ValidationSelectSimple'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'

export default {
  name: 'QuotationDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationTextNumber,
    ValidationSelectSimple,
    ValidationDate,
    ValidationRadio,
    ValidationTextArea
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {
        client_code: '',
        honorific: '',
        subject: '',
        issue_date: '',
        request_quote_term_to: '',
        report_code: '',
        rounding_off_for_each_line_item: {},
        rounding_of_consumption_tax: {},
        tax_inclusive_exclusive: {},
        quotation_detail_list: [],
        subtotal: '0',
        consumption_tax: '0',
        total: '0',
        employee_code: '',
        remark: ''
      },
      quotationDetailListErrors: [],
      addRowData: {},
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '13-004'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      // オリジナルのコンボデータを退避
      this.comboData.original_customer_list = res.common_area.combo_data.customer_department_mapping_list.customer_list
      this.comboData.original_product_list = res.common_area.combo_data.product_list
      // コンボデータ作成
      this.createCustomerList(this.detail.issue_date)
      this.createProductList(this.detail.issue_date)
      // 明細毎の端数処理のラジオ作成
      const roundingOffForEachLineItemItems = []
      for (const row of this.comboData.rounding_off_for_each_line_item_list) {
        roundingOffForEachLineItemItems.push({
          id: row.value,
          label: this.getLabel(row.label),
          value: String(row.value)
        })
      }
      this.detail.rounding_off_for_each_line_item = {
        value: this.mode === this.modes.add ? '1' : this.detail.rounding_off_for_each_line_item,
        items: roundingOffForEachLineItemItems
      }
      // 消費税の端数処理のラジオ作成
      const roundingOfConsumptionTaxItems = []
      for (const row of this.comboData.rounding_of_consumption_tax_list) {
        roundingOfConsumptionTaxItems.push({
          id: row.value,
          label: this.getLabel(row.label),
          value: String(row.value)
        })
      }
      this.detail.rounding_of_consumption_tax = {
        value: this.mode === this.modes.add ? '1' : this.detail.rounding_of_consumption_tax,
        items: roundingOfConsumptionTaxItems
      }
      // 消費税[内税/外税]のラジオ作成
      const taxInclusiveExclusiveItems = []
      for (const row of this.comboData.tax_inclusive_exclusive_list) {
        taxInclusiveExclusiveItems.push({
          id: row.value,
          label: this.getLabel(row.label),
          value: String(row.value)
        })
      }
      this.detail.tax_inclusive_exclusive = {
        value: this.mode === this.modes.add ? '1' : this.detail.tax_inclusive_exclusive,
        items: taxInclusiveExclusiveItems
      }

      // 行追加のひな形作成
      this.addRowData.product_code = ''
      this.addRowData.product_name = ''
      this.addRowData.unit_price = ''
      this.addRowData.quantity = ''
      this.addRowData.tax_type = ''
      // 登録の場合、1行追加
      if (this.mode === this.modes.add) {
        this.addRow(this.detail.quotation_detail_list)
      }

      // カンマ付与
      this.detail.subtotal = this.formatNumber(this.detail.subtotal)
      this.detail.consumption_tax = this.formatNumber(this.detail.consumption_tax)
      this.detail.total = this.formatNumber(this.detail.total)

      // 見積詳細の件数分、実施
      for (let quotationDetail of this.detail.quotation_detail_list) {
        // カンマ付与
        quotationDetail.unit_price = this.formatNumber(quotationDetail.unit_price)
        quotationDetail.quantity = this.formatNumber(quotationDetail.quantity)
        quotationDetail.tax_type = String(quotationDetail.tax_type)
      }
    },
    // 独自バリデーション用
    customValidate () {
      let isSuccess = true
      let pkList = []
      let isQuotationDetail = false
      // メッセージ初期化
      this.quotationDetailListErrors = []
      // 見積詳細の件数分、実施
      for (let quotationDetail of this.detail.quotation_detail_list) {
        // メッセージ初期化
        quotationDetail.error_unit_price = ''
        quotationDetail.error_quantity = ''
        quotationDetail.error_tax_type = ''
        // 削除がtrueの場合、次の要素を処理
        if (quotationDetail.delete) {
          continue
        }
        // 全て未入力の場合、次の要素を処理
        if (!quotationDetail.product_code && !quotationDetail.unit_price && !quotationDetail.quantity && !quotationDetail.tax_type) {
          continue
        }
        // 見積詳細あり
        isQuotationDetail = true
        // 単価がブランクの場合
        if (!quotationDetail.unit_price) {
          quotationDetail.error_unit_price = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // 個数がブランクの場合
        if (!quotationDetail.quantity) {
          quotationDetail.error_quantity = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // 税種別がブランクの場合
        if (!quotationDetail.tax_type) {
          quotationDetail.error_tax_type = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // PK(品名コード)を設定
        pkList.push(quotationDetail.product_code)
      }
      // PKListと重複削除したPKListの件数が一致しない場合、エラー
      if (pkList.length !== new Set(pkList).size) {
        this.quotationDetailListErrors.push(this.$t('message.pk_check').replace('{item}', this.labels.product_code_ryaku))
        isSuccess = false
      }
      // エラーがないが見積詳細が0件の場合、エラー
      if (isSuccess && !isQuotationDetail) {
        this.quotationDetailListErrors.push(this.$t('message.is_table_required').replace('{item}', this.labels.quotationDetail))
        isSuccess = false
      }
      return isSuccess
    },
    // 送信データ調整用
    convertDetail (detail) {
      // 登録以外の場合、見積番号、更新回数を数値に変換
      if (this.mode !== this.modes.add) {
        detail.request_quote_number = parseInt(detail.request_quote_number, 10)
        detail.update_count = parseInt(this.detail.update_count, 10)
      }
      // 削除の場合、返却
      if (this.mode === this.modes.delete) {
        return detail
      }
      // 数値に変換
      detail.honorific = parseInt(detail.honorific, 10)
      detail.subtotal = this.covertNumber(detail.subtotal)
      detail.consumption_tax = this.covertNumber(detail.consumption_tax)
      detail.total = this.covertNumber(detail.total)
      // ラジオボタンの設定
      detail.rounding_off_for_each_line_item = parseInt(detail.rounding_off_for_each_line_item.value, 10)
      detail.rounding_of_consumption_tax = parseInt(detail.rounding_of_consumption_tax.value, 10)
      detail.tax_inclusive_exclusive = parseInt(detail.tax_inclusive_exclusive.value, 10)
      // 見積詳細で不要な行削除
      detail.quotation_detail_list = detail.quotation_detail_list.filter(function (quotationDetail) {
        // 削除がtrueの場合、削除
        if (quotationDetail.delete) {
          return false
        }
        // 全て未入力の場合、削除
        if (!quotationDetail.product_code && !quotationDetail.unit_price && !quotationDetail.quantity && !quotationDetail.tax_type) {
          return false
        }
        return true
      })
      // 見積詳細の件数分、実施
      for (let quotationDetail of detail.quotation_detail_list) {
        // 数値に変換
        quotationDetail.unit_price = this.covertNumber(quotationDetail.unit_price)
        quotationDetail.quantity = this.covertNumber(quotationDetail.quantity)
        quotationDetail.tax_type = this.covertNumber(quotationDetail.tax_type)
        // 不要な項目削除
        delete quotationDetail.delete
        delete quotationDetail.error_unit_price
        delete quotationDetail.error_quantity
        delete quotationDetail.error_tax_type
      }
      // 不要な項目削除
      delete detail.employee_name
      delete detail.update_date
      delete detail.update_employee_code
      delete detail.update_employee_name
      return detail
    },
    // 取引先コンボデータ作成
    createCustomerList (issueDate) {
      let customerList = []
      for (let customer of this.comboData.original_customer_list) {
        // 発行日が開始日より前の場合、次の要素を処理
        if (issueDate < customer.transaction_start_date) {
          continue
        }
        // 発行日が終了日より後の場合、次の要素を処理
        if (customer.transaction_end_date !== '' && issueDate > customer.transaction_end_date) {
          continue
        }
        customerList.push(customer)
      }
      this.comboData.customer_list = customerList
    },
    // 品名コンボデータ作成
    createProductList (issueDate) {
      let productList = []
      for (let product of this.comboData.original_product_list) {
        // 発行日が開始日より前の場合、次の要素を処理
        if (issueDate < product.start_of_handling_date) {
          continue
        }
        // 発行日が終了日より後の場合、次の要素を処理
        if (product.end_of_handling_date !== '' && issueDate > product.end_of_handling_date) {
          continue
        }
        productList.push(product)
      }
      this.comboData.product_list = productList
    },
    // 発行日入力時
    inputIssueDate (issueDate) {
      // 発行日を基準にコンボデータ作成
      this.createCustomerList(issueDate)
      this.createProductList(issueDate)
    },
    // 取引先変更時
    clientCodeChanged (clientCode) {
      for (let customer of this.comboData.customer_list) {
        if (clientCode === customer.value) {
          this.detail.honorific = customer.honorific === null ? '' : String(customer.honorific)
          this.detail.employee_code = customer.responsible_employee_code
          this.detail.employee_name = customer.responsible_employee_name
          this.componentKey += 1
          break
        }
      }
    },
    honorificChanged () {},
    reportCodeChanged () {},
    // 明細毎の端数処理変更時
    roundingOffForEachLineItemChanged () {
      this.calc()
    },
    // 消費税の端数処理変更時
    roundingOfConsumptionTaxChanged () {
      this.calc()
    },
    // 消費税[内税/外税]変更時
    taxInclusiveExclusiveChanged () {
      this.calc()
    },
    // 削除チェック時
    deleteChanged (isDelete, index) {
      let quotationDetail = this.detail.quotation_detail_list[index]
      if (!quotationDetail.product_code || !quotationDetail.unit_price || !quotationDetail.quantity || !quotationDetail.tax_type) {
        return
      }
      quotationDetail.delete = !isDelete
      this.calc()
    },
    // 品名変更時
    productCodeChanged (productCode, index) {
      for (let product of this.comboData.product_list) {
        if (productCode === product.value) {
          let quotationDetail = this.detail.quotation_detail_list[index]
          quotationDetail.product_name = product.label
          quotationDetail.unit_price = this.formatNumber(product.unit_price)
          quotationDetail.quantity = this.formatNumber(product.quantity)
          quotationDetail.tax_type = this.formatNumber(product.tax_type)
          this.calc()
          break
        }
      }
    },
    // 単価入力時
    inputUnitPrice () {
      this.calc()
    },
    // 個数入力時
    inputQuantity () {
      this.calc()
    },
    // 税種別変更時
    taxTypeChanged () {
      this.calc()
    },
    calc () {
      for (let quotationDetail of this.detail.quotation_detail_list) {
        // 削除がtrueの場合、次の要素を処理
        if (quotationDetail.delete) {
          continue
        }
        // 全て未入力の場合、次の要素を処理
        if (!quotationDetail.product_code && !quotationDetail.unit_price && !quotationDetail.quantity && !quotationDetail.tax_type) {
          continue
        }
        // 一部が入力していない場合、処理終了
        if (!quotationDetail.product_code || !quotationDetail.unit_price || !quotationDetail.quantity || !quotationDetail.tax_type) {
          return
        }
      }
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertDetail(this.nullToBlank(JSON.parse(JSON.stringify(this.detail))))
      }
      this.send('/calc/quotation', reqData)
      .then(res => {
        if (res.api_area) {
          this.detail.subtotal = this.formatNumber(res.api_area.subtotal)
          this.detail.consumption_tax = this.formatNumber(res.api_area.consumption_tax)
          this.detail.total = this.formatNumber(res.api_area.total)
        }
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
    },
    addRow (list) {
      list.push(JSON.parse(JSON.stringify(this.addRowData)))
      this.$nextTick(() => {
        if (this.$refs.table.$el.style.height < 220) {
          this.$refs.table.$el.style.height = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          this.componentKey += 1
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = JSON.parse(JSON.stringify(this.detail))
        } else if (this.mode === this.modes.edit) {
          detail = JSON.parse(JSON.stringify(this.detail))
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {request_quote_number: this.detail.request_quote_number}
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        this.send(`/${operation}/quotation`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          request_quote_number: this.params.request_quote_number
        }
      }
      this.send('/init/quotation', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    // 従業員番号入力
    employeeSelected () {
      this.detail.employee_name = ''
      if (!this.canShow('/SS/menuSSScreen018')) {
        swal({
          title: this.$t('message.employee_not_found'),
          html: this.$t('message.employee_not_found_description'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.detail.employee_code = ''
          this.detail.employee_name = ''
        })
        return
      }
      if (!this.detail.employee_code) {
        return
      }
      const searchCond = {
        screen_code: 'SS-018',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: this.detail.employee_code,
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          all_flag: false
        }
      }
      this.tempHelp = this.help
      this.send('/view/search_employee/list', searchCond)
      .then(res => {
        if (res.api_area.employee_list && res.api_area.employee_list.length > 0) {
          this.detail.employee_code = res.api_area.employee_list[0].employee_code
          this.detail.employee_name = res.api_area.employee_list[0].employee_name
        } else {
          swal({
            title: this.$t('message.employee_not_found'),
            html: this.$t('message.employee_not_found_description'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.detail.employee_code = ''
            this.detail.employee_name = ''
          })
        }
        this.help = this.tempHelp
      })
    },
    // 従業員検索表示
    showEmployeeSearchModal () {
      // 親のshow-employeeを実施
      this.$emit('show-employee')
    },
    // 従業員検索閉じる
    hideEmployeeSearchModal (selected) {
      // モーダルemployeeで従業員が選択された場合
      if (selected) {
        // 従業員番号と従業員名を設定
        this.detail.employee_code = selected.employee_code
        this.detail.employee_name = selected.employee_name
        this.componentKey += 1
      }
    }
  },
  created () {
    this.init()
  }
}
