<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.group_code"
              item-name="group_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.group_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.group_code')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.group_name"
              item-name="group_name"
              max-length="50"
              classes="form-control length100"
              rules="required"
              :item-label="labels.group_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.group_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationDate
              v-model="detail.integrated_reservation_date"
              item-name="integrated_reservation_date"
              rules="required"
              :item-label="labels.integrated_reservation_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.integrated_reservation_date')"
              :disabled="isReadOnly"
            />
            <ValidationDate
              v-model="detail.term_from"
              item-name="term_from"
              rules="required"
              :item-label="labels.term_from"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
              :disabled="isReadOnly"
            />
            <ValidationDate
              v-model="detail.term_to"
              item-name="term_to"
              :item-label="labels.term_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_to')"
              :disabled="isReadOnly"
              :picker-options="futureday"
              v-show="mode==modes.edit || mode==modes.view || mode==modes.delete"
            />
            <ValidationSelect
              v-model="detail.upper_group_code"
              item-name="upper_group_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              :item-label="labels.upper_group_name"
              :options="comboData.upper_group_list"
              :disabled="isReadOnly"
              @change="upperGroupCodeChanged"
            />
            <ValidationSelect
              v-model="detail.range"
              item-name="range"
              :placeholder="$t('label.select_description')"
              classes="length4"
              rules="required"
              :item-label="labels.range"
              :options="comboData.range_list"
              :disabled="isReadOnly"
              @change="rangeChanged"
            />
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in applicationErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <ValidationApplicationManagement
              v-model="detail.application"
              classes="select-success length24"
              :item-label1="labels.applicationForm"
              :item-label2="labels.office_application_control ? labels.office_application_control : labels.group_application_control ? labels.group_application_control : ''"
              :disabled="isReadOnly"
              :options="comboData.group_management_application_control_list"
              ref="applicationControl"
              @copyApplicationControl="copyApplicationControl"
            />
            <ValidationIntegratedGroup
              v-model="detail.integrated_group"
              :item-label="labels.integrated_group"
              :options="comboData.integrated_group_list"
              :disabled="isReadOnly"
              classes="length60"
              rules="isIntegratedGroupRequired|isDifferentIntegratedGroupSelected"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import GroupManagementDetailMixin from '@/components/Dashboard/Views/Generated/GroupManagementDetailMixin'

  export default {
    name: 'GroupManagementDetail',
    methods: {
      afterInit (res) {
        this.labels.integrated_group = this.$t('label.integrated_group').replace('{item}', this.labels.group)
        this.comboData.upper_group_code_list = [...res.common_area.combo_data.group_code_list]
        this.comboData.upper_group_code_list.unshift({})
        this.comboData.integrated_group_list = [...res.common_area.combo_data.group_code_list]
        this.comboData.integrated_group_list.unshift({})
        this.detail.integrated_group = [
          {name: 'group1', value: ''},
          {name: 'group2', value: ''},
          {name: 'group3', value: ''},
          {name: 'group4', value: ''},
          {name: 'group5', value: ''}
        ]
        if (res.api_area.integrated_group && res.api_area.integrated_group.length > 0) {
          for (let i = 0; i < res.api_area.integrated_group.length; i++) {
            this.detail.integrated_group[i].value = res.api_area.integrated_group[i]
          }
        }
      },
      convertDetail (detail) {
        if (this.mode === this.modes.delete) {
          detail.group_code = this.detail.group_code
        } else {
          const integratedGroup = []
          for (const row of detail.integrated_group) {
            if (row.value && row.value.length > 0) {
              integratedGroup.push(row.value)
            }
          }
          detail.integrated_group = integratedGroup
        }
        return detail
      }
    },
    mixins: [GroupManagementDetailMixin]
  }
</script>
<style scoped>
</style>
