<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <template v-if="params.salary_bonus_classification===1">
            <h4 class="card-title" style="margin: 0;">{{$t('label.send_mail_payslip')}}</h4>
          </template>
          <template v-else>
            <h4 class="card-title" style="margin: 0;">{{$t('label.send_mail_bonuspayslip')}}</h4>
          </template>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <h5>
              <strong>{{params.target_date.substring(0, 4)}}{{$t('label.year')}}{{params.target_date.substring(4, 6)}}{{$t('label.monthly')}}{{ params.salary_bonus_classification === 2 ? bonusPaySlipName : '' }}</strong>
            </h5>
            <ValidationObserver ref="observer">
              <label>{{$t('label.sending_method')}}</label>
              <br />
              <p-radio
                v-model="detail.send_type"
                label="0"
                :inline="true"
              >{{$t('label.immediate_transmission')}}</p-radio>
              <p-radio
                v-model="detail.send_type"
                label="1"
                :inline="true"
              >{{$t('label.reservation_transmission')}}</p-radio>
              <br />
              <template v-if="detail.send_type==='1'">
                <ValidationDate
                  v-model="detail.datetime"
                  item-name="datetime"
                  rules="required"
                  :itemLabel="$t('label.scheduled_transmission_date')"
                  :placeholder="$t('placeholder.term_from')"
                  :picker-options="pickerOptions"
                />
              </template>
            </ValidationObserver>
            <br />
            <br />
            <hr />
            <!-- 締め日別で繰り返し表示 -->
            <div
              v-for="salary_closing_data  in salary_closing_data"
              v-bind:key="salary_closing_data.salary_closing_name"
            >
              <table>
                <tr>
                  <td colspan="5" width="450">
                    <label>{{salary_closing_data.salary_closing_name}}</label>
                    <br>
                    <button
                      type="button"
                      class="btn btn-sm btn-default btn-primary"
                      @click="selectButtonClick(salary_closing_data.detail_data)"
                    >{{getSelectButtonLabel(salary_closing_data.detail_data)}}</button>
                    <button
                      type="button"
                      class="btn btn-sm btn-default btn-primary"
                      v-if="cancelReservationTargetCount(salary_closing_data.detail_data)!==0"
                      @click="cancelReservationButtonClick(salary_closing_data.detail_data)"
                    >{{cancelReservationButtonLabel(salary_closing_data.detail_data)}}</button>
                  </td>
                  <td width="150">
                    <div style="text-align: right;"></div>
                  </td>
                  <td width="100">
                    <div style="text-align: right;"></div>
                  </td>
                </tr>
              </table>
              <hr />
              <!-- Table開始 -->
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                class="table-width"
                border
                :data="salary_closing_data.detail_data"
                :empty-text="$t('label.no_data')"
                ref="table"
              >
                <!-- 事業所 -->
                <el-table-column
                  :width="getColumnWidth(salary_closing_data.detail_data, labels.office_name === undefined ? '' : labels.office_name, 'office_code', 'office_name')"
                  :label="labels.office_name === undefined ? '' : labels.office_name"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.office_code}}</small>
                    </div>
                    <div style="text-align: left;">{{props.row.office_name}}</div>
                  </template>
                </el-table-column>
                <!-- 従業員区分 -->
                <el-table-column
                  :width="150"
                  :label="labels.employee_classification_name === undefined ? '' : labels.employee_classification_name"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">{{props.row.employee_classification_name}}</div>
                  </template>
                </el-table-column>
                <!-- 従業員名 -->
                <el-table-column
                  :width="150"
                  :label="labels.employee_name === undefined ? '' : labels.employee_name"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.employee_code}}</small>
                    </div>
                    <div style="text-align: left;">{{props.row.employee_name}}</div>
                  </template>
                </el-table-column>
                <!-- 作成区分	 -->
                <!-- メールアドレス -->
                <el-table-column
                  :width="300"
                  :label="labels.mail_address === undefined ? '' : labels.mail_address"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small></small>
                    </div>
                    <template v-if="props.row.mail_address">
                      <div style="text-align: left;">{{props.row.mail_address}}</div>
                    </template>
                    <template v-else>
                      <div style="text-align: left;">{{$t('label.mail_address_notset')}}</div>
                    </template>
                  </template>
                </el-table-column>
                <!-- 送信日 -->
                <el-table-column
                  :width="135"
                  :label="labels.date_of_sending_pay_slip === undefined ? '' : labels.date_of_sending_pay_slip"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small></small>
                    </div>
                    <div style="text-align: left;">{{props.row.date_of_sending_pay_slip}}</div>
                  </template>
                </el-table-column>
                <!-- 送信予定日 -->
                <el-table-column
                  :width="100"
                  :label="$t('label.reserve_date_of_sending_pay_slip') === undefined ? '' : $t('label.reserve_date_of_sending_pay_slip')"
                >
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small></small>
                    </div>
                    <div style="text-align: left;">{{props.row.reserve_date_of_sending_pay_slip}}</div>
                  </template>
                </el-table-column>
                <!-- 勤怠の締め状態 -->
                <el-table-column
                  :width="90"
                  :label="$t('label.created_salary') === undefined ? '' : $t('label.created_salary')"
                >
                  <template slot-scope="props">
                    <div
                      style="text-align: left;"
                    >{{props.row.t_employee_pay_slip_exist === 1 ? $t('label.already'): ""}}</div>
                  </template>
                </el-table-column>
                <!-- 送信 -->
                <el-table-column width="50" :label="$t('label.send')" align="center">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <template v-if="props.row.mail_address && props.row.t_employee_pay_slip_exist === 1">
                        <input
                          type="checkbox"
                          :disabled="isNull(props.row.update_count)||props.row.t_employee_pay_slip_exist===2"
                          v-model="props.row.selected"
                          class="gray"
                        />
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <!-- 予約解除 -->
                <el-table-column width="75" :label="$t('label.cancel_reservation')" align="center">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <template v-if="!(isNull(props.row.reserve_date_of_sending_pay_slip)||props.row.reserve_date_of_sending_pay_slip==='')">
                        <input
                          type="checkbox"
                          v-model="props.row.cancel_reservation_selected"
                          class="gray"
                        />
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <div style="text-align: left;">
        <template v-if="roles.update === 1">
          <button
            type="button"
            v-if="!send_flg"
            class="btn btn-sm btn-default btn-primary"
            @click="doSend"
          >{{$t('button.send')}}</button>
        </template>
        <template v-if="roles.delete === 1">
          <button
            type="button"
            v-if="!send_flg"
            class="btn btn-sm btn-default btn-primary"
            @click="doRelease"
          >{{$t('button.cancel_reservation')}}</button>
        </template>
      </div>
    </form>
  </div>
</template>
<script>
  import SendMailMixin from '@/components/Dashboard/Views/Salary/SendMailMixin'

  export default {
    name: 'SendMail',
    mixins: [SendMailMixin]
  }
</script>
<style scoped>
</style>
