<template>
  <div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      style="width: 500px;"
      :data="tableData"
      :empty-text="labels.label_data_none"
    >
      <el-table-column
        min-width="100"
        :label="labels.supplier_name_ryaku ? labels.supplier_name_ryaku : ''"
        align="center"
        :key="componentKey"
      >
        <template slot-scope="props">
          <div class="strong-text">
            {{props.row.supplier_name}}
          </div>
          <div style="text-align: left;">
            <small>{{props.row.home_page}}</small>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="50"
        :label="labels.tel_ryaku"
        align="center"
      >
        <template slot-scope="props">
          <div class="strong-text">
            {{props.row.tel}}
          </div>
          <div style="text-align: left;" v-if="props.row.fax">
            {{'(' + labels.fax_ryaku + ') ' + props.row.fax}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="50"
        :label="labels.acceptable_ryaku"
        align="center"
      >
        <template slot-scope="props">
          <div style="text-align: center;" v-if="roles.update === 1">
            <button type="button" v-show="props.row.acceptable !== 1" class="btn btn-sm btn-default btn-info" @click="$emit('update', props.row, 1)">{{$t('button.accept_start')}}</button>
            <button type="button" v-show="props.row.acceptable !== 2" class="btn btn-sm btn-default btn-info" @click="$emit('update', props.row, 2)">{{$t('button.accept_end')}}</button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      labels: Object,
      roles: Object,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>

<style scoped>
</style>