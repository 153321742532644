<template>
  <div v-if="message && !notification">
    <template v-if="pageType===1">
      <div class="card" v-if="this.message && this.message.text && this.message.text.trim()">
        <div class="card-content">
          <span v-html="getText"></span>
          <template v-if="message.correspondence_action && message.correspondence_action.length > 0">
            <br>
            <span style="color: #000000;">{{message.correspondence_action}}</span>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="pageType===4">
      <div class="message-area">
        <span v-html="getText"></span>
        <template v-if="message.correspondence_action && message.correspondence_action.length > 0">
          <br>
          <span style="color: #000000;">{{message.correspondence_action}}</span>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      pageType: Number,
      message: Object,
      placeholders: Array,
      notification: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    computed: {
      getText () {
        if (this.message && this.message.text) {
          if (this.message.text.indexOf('http') === -1) {
            const color = this.message.message_classification === 1 ? '#000000' : '#c84513'
            return '<span style="color: ' + color + ';">' + this.message.text.replace('{item1}', this.placeholders[0]) + '</span>'
          } else {
            const from = this.message.text.indexOf(' ') + 1
            const to = this.message.text.lastIndexOf('-')
            const url = this.message.text.substring(from, to)
            const text = this.message.text.substring(to + 1).replace('{item1}', this.placeholders[0])
            return '<a href="' + url + '" target="_blank" style="color: #317FB8;">' + text + '</a>'
          }
        } else {
          return ''
        }
      }
    },
    methods: {
      notifyVue (title, message) {
        this.$notify({
          title: title,
          message: message,
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'info',
          icon: 'ti-check'
        })
      }
    },
    watch: {
      message () {
        if ((this.pageType === 2 || this.pageType === 3) && this.message.text && this.message.text.trim()) {
          this.notifyVue(this.message.text, this.message.correspondence_action)
        }
      },
      notification () {
        if (this.notification) {
          this.notifyVue(this.message.text, this.message.correspondence_action)
        }
      }
    }
  }
</script>
<style scoped>
  .message-area {
    margin-bottom: 10px;
  }
</style>
