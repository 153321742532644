export default {
  ja: {
    message: {
    },
    label: {
    },
    button: {
      google_oauth_pass: 'Google 認証済み',
      google_oauth_fail: 'Google 未認証',
      google_oauth_deauthorization: 'Google 認証解除'
    },
    placeholder: {
    },
    help: {
    }
  },
  en: {
    message: {
    },
    label: {
    },
    button: {
      google_oauth_pass: 'Google Authenticated',
      google_oauth_fail: 'Google Unauthenticated',
      google_oauth_deauthorization: 'Google Deauthorization'
    },
    placeholder: {
    },
    help: {
    }
  }
}
