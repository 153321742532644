<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: 570px;" name="companyFrame">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 都道府県コード -->
              <ValidationSearchSelect
                v-model="state_code"
                :item-label="labels.state"
                :options="state_list"
                classes="length8"
                :disabled="isReadOnly"
              />
              <!-- 全国地方公共団体コード -->
              <ValidationSearchSelect
                v-model="local_government_code"
                :item-label="labels.localGovernment"
                :options="municipality_list"
                classes="length18"
                :disabled="isReadOnly"
              />
              <!-- <template v-if="this.mode === this.modes.add"> -->
              <template v-if="true">
                <!-- 初月費用 -->
                <ValidationText
                  v-model="first_month_cost"
                  item-name="first_month_cost"
                  max-length="6"
                  classes="form-control numLength6 number"
                  :item-label="$t('label.first_month_cost')"
                  :placeholder="$t('placeholder.first_month_cost')"
                  :help-text="$t('label.max_length')"
                  rules="min_value: 0|max_value: 999999"
                  :disabled="isReadOnly"
                />
                <!-- 翌月以降の費用 -->
                <ValidationText
                  v-model="costs_from_the_next_month"
                  item-name="costs_from_the_next_month"
                  max-length="6"
                  classes="form-control numLength6 number"
                  :item-label="$t('label.costs_from_the_next_month')"
                  :placeholder="$t('placeholder.first_month_cost')"
                  :help-text="$t('label.max_length')"
                  rules="min_value: 0|max_value: 999999"
                  :disabled="isReadOnly"
                />
              </template>
              <template v-else>
                <label for="planId">
                  {{$t('label.first_month_cost')}}
                </label>
                <br/>
                  &nbsp;&nbsp;{{getAmountOfMoney(first_month_cost)}}
                <br/>
                <br/>
                <label for="planId">
                  {{$t('label.costs_from_the_next_month')}}
                </label>
                <br/>
                  &nbsp;&nbsp;{{getAmountOfMoney(costs_from_the_next_month)}}
                <br/>
                <br/>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationSearchSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        labels: {},
        state_code: '',
        local_government_code: '',
        first_month_cost: '',
        costs_from_the_next_month: '',
        municipality_list: [],
        municipality_list_all: [],
        state_municipality_mapping_list: []
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      init () {
      },
      allClear () {
        this.state_code = ''
        this.local_government_code = ''
        this.first_month_cost = ''
        this.costs_from_the_next_month = ''
      },
      setDetails (res, mode) {
        if (mode !== this.modes.add) {
          if (res.api_area.salary.tax === null) {
            this.state_code = ''
            this.local_government_code = ''
            this.first_month_cost = ''
            this.costs_from_the_next_month = ''
          } else {
            this.state_code = String(res.api_area.salary.tax.state)
            this.local_government_code = String(res.api_area.salary.tax.local_government_code)
            this.first_month_cost = res.api_area.salary.tax.first_month_cost
            this.costs_from_the_next_month = res.api_area.salary.tax.costs_from_the_next_month
          }
        }
      },
      getStateName (stateCode) {
        for (const data of this.state_list) {
          if (data.value === stateCode) {
            return data.label
          }
        }
        return ''
      },
      getLocalGovernmentName (localGovernmentCode) {
        for (const data of this.municipality_list) {
          if (data.value === localGovernmentCode) {
            return data.label
          }
        }
        return ''
      },
      getAmountOfMoney (amount) {
        if (amount === undefined || amount === null || amount === '') {
          return '0' + this.$t('label.currency')
        }
        return this.formatNumber(amount) + this.$t('label.currency')
      }
    },
    watch: {
      'state_code': function () {
        this.municipality_list = []
        for (const data of this.state_municipality_mapping_list[this.state_code]) {
          for (const municipality of this.municipality_list_all) {
            if (municipality.value === data) {
              this.municipality_list.push(municipality)
            }
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
