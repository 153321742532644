<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationSelect
              v-model="detail.office_code"
              item-name="office_code"
              :placeholder="$t('label.select_description')"
              classes="length12"
              rules="required"
              :item-label="labels.office_name"
              :options="comboData.office_list"
              :disabled="isPrimaryKeyReadOnly"
              @change="officeCodeChanged"
            />
            <ValidationSelect
              v-model="detail.business_type"
              item-name="business_type"
              :placeholder="$t('label.select_description')"
              classes="length12"
              rules="required"
              :item-label="labels.business_type"
              :options="comboData.business_type_list"
              :disabled="isPrimaryKeyReadOnly"
              @change="businessTypeChanged"
            />
            <ValidationTransformationYearly
              v-model="detail.transformation_yearly"
              item-name="transformation_yearly"
              classes="form-control"
              :item-label="$t('label.transformation_yearly')"
              :disabled="isReadOnly"
              :labels="labels"
              :err-msg="errMsg"
              rules="transformationYearlyRequired"
              @show-schedule="showSchedule"
            />
            <ValidationDate
              v-model="detail.agreement_date"
              item-name="agreement_date"
              rules="required"
              :item-label="labels.agreement_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.agreement_date')"
              :disabled="isReadOnly"
            />
            <ValidationSelect
              v-model="detail.agreement_parties_job_title_code"
              item-name="agreement_parties_job_title_code"
              :placeholder="$t('label.select_description')"
              classes="length12"
              :item-label="labels.agreement_parties_job_title_name"
              :options="comboData.agreement_parties_job_title_list"
              :disabled="isReadOnly"
              @change="agreementPartiesJobTitleCodeChanged"
            />
            <ValidationSelect
              v-model="detail.agreement_parties_employee_code"
              item-name="agreement_parties_employee_code"
              :placeholder="$t('label.select_description')"
              classes="length12"
              :item-label="labels.agreement_parties_employee_name"
              :options="comboData.agreement_parties_employee_list"
              :disabled="isReadOnly"
              @change="agreementPartiesEmployeeCodeChanged"
            />
            <ValidationSelect
              v-model="detail.election_method"
              item-name="election_method"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.election_method"
              :options="comboData.election_method_list"
              :disabled="isReadOnly"
              @change="electionMethodChanged"
            />
            <ValidationDate
              v-model="detail.filing_date"
              item-name="filing_date"
              rules="required"
              :item-label="labels.filing_date"
              :placeholder="isReadOnly ? '' : $t('placeholder.filing_date')"
              :disabled="isReadOnly"
            />
            <ValidationSelect
              v-model="detail.management_parties_job_title_code"
              item-name="management_parties_job_title_code"
              :placeholder="$t('label.select_description')"
              classes="length12"
              :item-label="labels.management_parties_job_title_name"
              :options="comboData.management_parties_job_title_list"
              :disabled="isReadOnly"
              @change="managementPartiesJobTitleCodeChanged"
            />
            <ValidationSelect
              v-model="detail.management_parties_employee_code"
              item-name="management_parties_employee_code"
              :placeholder="$t('label.select_description')"
              classes="length12"
              :item-label="labels.management_parties_employee_name"
              :options="comboData.management_parties_employee_list"
              :disabled="isReadOnly"
              @change="managementPartiesEmployeeCodeChanged"
            />
            <ValidationText
              v-model="detail.labor_standards_inspection_office"
              item-name="labor_standards_inspection_office"
              max-length="50"
              classes="form-control length50"
              rules="required"
              :item-label="labels.labor_standards_inspection_office"
              :placeholder="isReadOnly ? '' : $t('placeholder.labor_standards_inspection_office')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import TransformationYearlyDetailMixin from '@/components/Dashboard/Views/Generated/TransformationYearlyDetailMixin'

  export default {
    name: 'TransformationYearlyDetail',
    data () {
      return {
        errMsg: ''
      }
    },
    methods: {
      afterInit (res) {
        this.labels.office_name = this.labels.office
        this.labels.business_type = this.labels.business
        this.labels.agreement_parties_employee_name = this.$t('label.agreement_parties_employee_name')
        this.labels.agreement_parties_job_title_name = this.$t('label.agreement_parties_job_title_name')
        this.labels.management_parties_employee_name = this.$t('label.management_parties_employee_name')
        this.labels.management_parties_job_title_name = this.$t('label.management_parties_job_title_name')
        this.comboData.business_type_list = this.comboData.business_list
        const jobEmployeeList = []
        for (const row of res.api_area.job_employee_list) {
          jobEmployeeList.push({label: row.job_title_name, value: row.job_title_code})
        }
        this.comboData.management_parties_job_title_list = jobEmployeeList
        this.comboData.job_employee_list = res.api_area.job_employee_list
        this.comboData.office_job_title_employee_mapping_list = res.api_area.office_job_title_employee_mapping_list
        this.comboData.office_data = res.api_area.office_data
        if (this.mode !== this.modes.add) {
          res.api_area.transformation_time_labor_info.election_method = String(res.api_area.transformation_time_labor_info.election_method)
          this.detail = res.api_area.transformation_time_labor_info
          this.changed()
          this.agreementPartiesJobTitleCodeChanged()
          this.managementPartiesJobTitleCodeChanged()
          this.detail.transformation_yearly.starting_date = res.api_area.transformation_time_labor_info.starting_date
          this.detail.transformation_yearly.term_from = res.api_area.transformation_time_labor_info.term_from
          this.detail.transformation_yearly.term_to = res.api_area.transformation_time_labor_info.term_to
          this.detail.transformation_yearly.average_working_hours = res.api_area.transformation_time_labor_info.average_working_time / 60
          this.detail.transformation_yearly.average_working_minutes = ('0' + res.api_area.transformation_time_labor_info.average_working_time % 60).slice(-2)
          this.detail.transformation_yearly.longest_working_day_working_hours = res.api_area.transformation_time_labor_info.longest_working_day_working_time / 60
          this.detail.transformation_yearly.longest_working_day_working_minutes = ('0' + res.api_area.transformation_time_labor_info.longest_working_day_working_time % 60).slice(-2)
          this.detail.transformation_yearly.longest_working_day_working_hours_under_18 = !res.api_area.transformation_time_labor_info.longest_working_day_working_time_under_18 ? '' : res.api_area.transformation_time_labor_info.longest_working_day_working_time_under_18 / 60
          this.detail.transformation_yearly.longest_working_day_working_minutes_under_18 = !res.api_area.transformation_time_labor_info.longest_working_day_working_time_under_18 ? '' : ('0' + res.api_area.transformation_time_labor_info.longest_working_day_working_time_under_18 % 60).slice(-2)
          this.detail.transformation_yearly.longest_working_week_working_hours = res.api_area.transformation_time_labor_info.longest_working_week_working_time / 60
          this.detail.transformation_yearly.longest_working_week_working_minutes = ('0' + res.api_area.transformation_time_labor_info.longest_working_week_working_time % 60).slice(-2)
          this.detail.transformation_yearly.longest_working_week_working_hours_under_18 = !res.api_area.transformation_time_labor_info.longest_working_week_working_time_under_18 ? '' : res.api_area.transformation_time_labor_info.longest_working_week_working_time_under_18 / 60
          this.detail.transformation_yearly.longest_working_week_working_minutes_under_18 = !res.api_area.transformation_time_labor_info.longest_working_week_working_time_under_18 ? '' : ('0' + res.api_area.transformation_time_labor_info.longest_working_week_working_time_under_18 % 60).slice(-2)
          this.detail.transformation_yearly.total_working_days = res.api_area.transformation_time_labor_info.total_working_days
          this.detail.transformation_yearly.maximum_consecutive_weeks_with_more_than_48_hours_worked = String(res.api_area.transformation_time_labor_info.maximum_consecutive_weeks_with_more_than_48_hours_worked)
          this.detail.transformation_yearly.target_number_of_consecutive_working_days = res.api_area.transformation_time_labor_info.target_number_of_consecutive_working_days
          this.detail.transformation_yearly.weeks_with_more_than_48_hours_worked = String(res.api_area.transformation_time_labor_info.weeks_with_more_than_48_hours_worked)
          this.detail.transformation_yearly.special_number_of_consecutive_working_days = res.api_area.transformation_time_labor_info.special_number_of_consecutive_working_days
          this.detail.transformation_yearly.old_term_from = res.api_area.transformation_time_labor_info.old_term_from
          this.detail.transformation_yearly.old_term_to = res.api_area.transformation_time_labor_info.old_term_to
          this.detail.transformation_yearly.old_longest_working_day_working_hours = res.api_area.transformation_time_labor_info.old_longest_working_day_working_time / 60
          this.detail.transformation_yearly.old_longest_working_day_working_minutes = ('0' + res.api_area.transformation_time_labor_info.old_longest_working_day_working_time % 60).slice(-2)
          this.detail.transformation_yearly.old_longest_working_week_working_hours = res.api_area.transformation_time_labor_info.old_longest_working_week_working_time / 60
          this.detail.transformation_yearly.old_longest_working_week_working_minutes = ('0' + res.api_area.transformation_time_labor_info.old_longest_working_week_working_time % 60).slice(-2)
          this.detail.transformation_yearly.old_total_working_days = res.api_area.transformation_time_labor_info.old_total_working_days
          for (const row of res.api_area.work_schedule_transformation_time_labor) {
            row.ground_name = ''
            for (const groundRow of res.common_area.combo_data.ground_list) {
              if (groundRow.value === row.ground_code) {
                row.ground_name = groundRow.label
                break
              }
            }
            row.break_time_list = row.break_list
            let breakMinutes = ''
            for (const breakRow of row.break_time_list) {
              if (breakMinutes !== '') {
                breakMinutes += ', '
              }
              breakMinutes += breakRow.start_time + '-' + breakRow.end_time
              breakRow.start_time = breakRow.start_time
              breakRow.end_time = breakRow.end_time
            }
            row.break_time_range_list = row.break_range_list
            for (const breakRow of row.break_time_range_list) {
              breakRow.range_break_hour = String(parseInt(breakRow.range_break_minutes / 60, 10))
              breakRow.range_break_minute = ('0' + String(parseInt(breakRow.range_break_minutes % 60, 10))).slice(-2)
              breakRow.break_minutes = String(breakRow.break_minutes)
              if (breakMinutes !== '') {
                breakMinutes += ', '
              }
              breakMinutes += `${breakRow.range_break_hour}${this.$t('label.hours')}${breakRow.range_break_minute}${this.$t('label.breaktime_range_message')}：${breakRow.break_minutes}${this.$t('label.minute')}`
            }
            row.break_minutes = breakMinutes
          }
          this.detail.transformation_yearly.work_schedule_list = res.api_area.work_schedule_transformation_time_labor
          this.detail.work_schedule_list = res.api_area.work_schedule_transformation_time_labor
        }
      },
      officeCodeChanged () {
        this.changed()
      },
      businessTypeChanged () {
        this.changed()
      },
      changed () {
        for (const row of this.comboData.office_data) {
          if (row.office_code === this.detail.office_code) {
            row.business_type = this.detail.business_type
            for (const businessRow of this.comboData.business_list) {
              if (businessRow.value === this.detail.business_type) {
                row.business_name = businessRow.label
                break
              }
            }
            for (const state of this.comboData.state_list) {
              if (state.value === row.state_code) {
                row.prefectures = state.label
                break
              }
            }
            const municipalityCode = row.state_code + '_' + row.municipality_code
            for (const municipality of this.comboData.municipality_list) {
              if (municipality.value === municipalityCode) {
                row.city = municipality.label
                break
              }
            }
            this.detail.transformation_yearly = row
            break
          }
        }
        if (!this.detail.office_code) return
        const jobEmployeeList = []
        for (const row of this.comboData.office_job_title_employee_mapping_list.office_job_title_list[this.detail.office_code] || []) {
          jobEmployeeList.push({label: row.job_title_name, value: row.job_title_code})
        }
        this.comboData.agreement_parties_job_title_list = jobEmployeeList
      },
      showSchedule (officeData) {
        officeData.labels = this.labels
        officeData.combo_data = this.comboData
        this.$emit('show-schedule', officeData)
      },
      agreementPartiesJobTitleCodeChanged () {
        if (!this.detail.office_code || !this.detail.agreement_parties_job_title_code) return
        const employeeList = []
        for (const row of this.comboData.office_job_title_employee_mapping_list.office_job_title_employee_list[this.detail.office_code + '_' + this.detail.agreement_parties_job_title_code]) {
          employeeList.push({label: row.employee_name, value: row.employee_code})
        }
        this.comboData.agreement_parties_employee_list = employeeList
      },
      managementPartiesJobTitleCodeChanged () {
        for (const row of this.comboData.job_employee_list) {
          if (row.job_title_code === this.detail.management_parties_job_title_code) {
            const employeeList = []
            for (const employeeRow of row.employee_list) {
              employeeList.push({label: employeeRow.employee_name, value: employeeRow.employee_code})
            }
            this.comboData.management_parties_employee_list = employeeList
            break
          }
        }
      },
      customValidate () {
        this.errMsg = ''
        if (!this.detail.work_schedule_list) {
          this.errMsg = this.$t('message.transformation_detail_is_required')
          return false
        } else {
          for (const row of this.detail.work_schedule_list) {
            if (!row.ground_code) {
              this.errMsg = this.$t('message.transformation_detail_ground_is_required')
              return false
            } else if (row.ground_code === '0000000001') {
              if (!row.job_start || !row.job_end) {
                this.errMsg = this.$t('message.transformation_detail_job_start_end_is_required')
                return false
              }
            }
          }
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          for (const row of this.comboData.business_list) {
            if (row.value === detail.business_type) {
              detail.business_name = row.label
              break
            }
          }
          detail.election_method = parseInt(detail.election_method, 10)
          delete detail.job_employee_list
          delete detail.office_job_title_employee_mapping_list
          detail.limit_legal_one_weeks_minutes = parseInt(detail.transformation_yearly.limit_legal_one_weeks_minutes, 10)
          delete detail.office_data
          for (const row of this.comboData.office_list) {
            if (row.value === detail.office_code) {
              detail.office_name = row.label
              break
            }
          }
          if (this.mode !== this.modes.add) {
            delete detail.office_name
            delete detail.business_name
          }
          delete detail.transformation_time_labor_info
          const workScheduleList = []
          for (const row of detail.work_schedule_list) {
            const breakList = []
            for (const breakRow of row.break_time_list) {
              if (!breakRow.selected) {
                breakList.push({
                  start_time: ('0' + breakRow.start_time).slice(-5),
                  end_time: ('0' + breakRow.end_time).slice(-5)
                })
              }
            }
            const breakRangeList = []
            for (const breakRow of row.break_time_range_list) {
              if (!breakRow.selected) {
                breakRangeList.push({
                  range_break_minutes: parseInt(breakRow.range_break_hour, 10) * 60 + parseInt(breakRow.range_break_minute, 10),
                  break_minutes: parseInt(breakRow.break_minutes, 10)
                })
              }
            }
            workScheduleList.push({
              target_date: row.target_date,
              ground_code: row.ground_code,
              job_start: row.job_start ? ('0' + row.job_start).slice(-5) : '',
              job_end: row.job_end ? ('0' + row.job_end).slice(-5) : '',
              break_list: breakList,
              break_range_list: breakRangeList
            })
          }
          detail.work_schedule_list = workScheduleList
          delete detail.break_time_transformation_time_labor
          delete detail.limit_legal_one_weeks_minutes
          delete detail.work_schedule_transformation_time_labor
          detail.starting_date = detail.transformation_yearly.starting_date
          detail.term_from = detail.transformation_yearly.term_from
          detail.term_to = this.formatDate(detail.transformation_yearly.term_to)
          detail.average_working_time = parseInt(detail.transformation_yearly.average_working_hours, 10) * 60 + parseInt(detail.transformation_yearly.average_working_minutes, 10)
          detail.longest_working_day_working_time = parseInt(detail.transformation_yearly.longest_working_day_working_hours, 10) * 60 + parseInt(detail.transformation_yearly.longest_working_day_working_minutes, 10)
          detail.longest_working_day_working_time_under_18 = (!detail.transformation_yearly.longest_working_day_working_hours_under_18 || !detail.transformation_yearly.longest_working_day_working_minutes_under_18) ? 0 : parseInt(detail.transformation_yearly.longest_working_day_working_hours_under_18, 10) * 60 + parseInt(detail.transformation_yearly.longest_working_day_working_minutes_under_18, 10)
          detail.longest_working_week_working_time = parseInt(detail.transformation_yearly.longest_working_week_working_hours, 10) * 60 + parseInt(detail.transformation_yearly.longest_working_week_working_minutes, 10)
          detail.longest_working_week_working_time_under_18 = (!detail.transformation_yearly.longest_working_week_working_hours_under_18 || !detail.transformation_yearly.longest_working_week_working_minutes_under_18) ? 0 : parseInt(detail.transformation_yearly.longest_working_week_working_hours_under_18, 10) * 60 + parseInt(detail.transformation_yearly.longest_working_week_working_minutes_under_18, 10)
          detail.maximum_consecutive_weeks_with_more_than_48_hours_worked = parseInt(detail.transformation_yearly.maximum_consecutive_weeks_with_more_than_48_hours_worked, 10)
          detail.weeks_with_more_than_48_hours_worked = parseInt(detail.transformation_yearly.weeks_with_more_than_48_hours_worked, 10)
          detail.old_longest_working_day_working_time = parseInt(detail.transformation_yearly.old_longest_working_week_working_hours, 10) * 60 + parseInt(detail.transformation_yearly.old_longest_working_week_working_minutes, 10)
          detail.old_longest_working_week_working_time = parseInt(detail.transformation_yearly.old_longest_working_week_working_hours, 10) * 60 + parseInt(detail.transformation_yearly.old_longest_working_week_working_minutes, 10)
          detail.old_term_from = detail.transformation_yearly.old_term_from
          detail.old_term_to = this.formatDate(detail.transformation_yearly.old_term_to)
          detail.old_total_working_days = parseInt(detail.transformation_yearly.old_total_working_days, 10)
          detail.special_number_of_consecutive_working_days = parseInt(detail.transformation_yearly.special_number_of_consecutive_working_days, 10)
          detail.target_number_of_consecutive_working_days = parseInt(detail.transformation_yearly.target_number_of_consecutive_working_days, 10)
          detail.total_working_days = parseInt(detail.transformation_yearly.total_working_days, 10)
          delete detail.transformation_yearly
        } else {
          detail.office_code = this.detail.office_code
          detail.starting_date = this.detail.transformation_yearly.starting_date
          detail.business_type = this.detail.business_type
        }
        return detail
      }
    },
    mixins: [TransformationYearlyDetailMixin]
  }
</script>
<style scoped>
</style>
