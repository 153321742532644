<template>
  <v-tour :name="tourName" :steps="steps">
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <div slot="header">
            <div>
              <i class="ti-close pull-right" style="cursor: pointer;" :title="$t('button.close')" @click="tour.skip"></i>
              <br clear="all"/>
            </div>
          </div>
          <template v-if="tour.isFirst">
            <template v-if="tour.isLast">
              <div slot="actions" class="v-step__buttons">
                <button @click.prevent.stop="tour.finish" class="v-step__button btn btn-fill">{{$t('label.finish_step')}}</button>
              </div>
            </template>
            <template v-else>
              <div slot="actions" class="v-step__buttons">
                <button @click.prevent.stop="tour.nextStep" class="v-step__button btn btn-fill">{{$t('label.next_step')}}</button>
              </div>
            </template>
          </template>
          <template v-else-if="tour.isLast">
            <div slot="actions" class="v-step__buttons">
              <button @click.prevent.stop="tour.previousStep" class="v-step__button">{{$t('label.prev_step')}}</button>
              <button @click.prevent.stop="tour.finish" class="v-step__button btn btn-fill">{{$t('label.finish_step')}}</button>
            </div>
          </template>
          <template v-else>
            <div slot="actions" class="v-step__buttons">
              <button @click.prevent.stop="tour.previousStep" class="v-step__button">{{$t('label.prev_step')}}</button>
              <button @click.prevent.stop="tour.nextStep" class="v-step__button btn btn-fill">{{$t('label.next_step')}}</button>
            </div>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>          
</template>

<script>
import Vue from 'vue'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

export default {
  props: {
    tourName: String,
    steps: Array
  }
}
</script>

<style lang="scss" scoped>
</style>