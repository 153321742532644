<template>
  <div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      style="width: 1581px;"
      :data="tableData"
      :empty-text="$t('label.no_data')">
      <!-- 事由名 -->
      <el-table-column width="180" :label="labels.ground_name_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div class="strong-text-left" style="text-align: left; white-space: normal;">
            {{props.row.ground_name}}
          </div>
        </template>
      </el-table-column>
      <!-- 補足説明 -->
      <el-table-column width="230" :label="labels.contents_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left; white-space: normal;">
            {{ props.row.contents }}
          </div>
        </template>
      </el-table-column>
      <!-- 所定労働日区分 -->
      <el-table-column width="180" :label="labels.labor_day_classification_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <ValidationRadio
              v-model="props.row.labor_day_classification"
              rules="required"
              :item-label="labels.labor_day_classification"
              :disabled="disabled"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 出勤日扱い区分 -->
      <el-table-column width="180" :label="labels.attendance_date_classification_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <ValidationRadio
              v-model="props.row.attendance_date_classification"
              rules="required"
              :item-label="labels.attendance_date_classification"
              :disabled="disabled"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 出勤簿利用可能フラグ -->
      <el-table-column width="230" :label="labels.timecard_available_flg_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <template v-if="props.row.ground_code === '0000000002'">
            {{ $t('label.use_paid_holiday') }}
          </template>
          <template v-else-if="props.row.ground_code === '0000000003'">
            {{ $t('label.use_parental_leave') }}
          </template>
          <template v-else-if="props.row.ground_code === '0000000030'">
            {{ $t('label.use_leave_job') }}
          </template>
          <template v-else>
            <div style="text-align: left;" v-show="props.row.timecard_available_flg && props.row.timecard_available_flg.value !== '9'">
              <ValidationRadio
                v-model="props.row.timecard_available_flg"
                rules="required"
                :item-label="labels.timecard_available_flg"
                :disabled="disabled"
                @change="timecardAvailableFlgChanged(props.row)"
              />
            </div>
          </template>
        </template>
      </el-table-column>
      <!-- 賃金支給区分 -->
      <el-table-column width="260" :label="labels.wage_classification_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;" v-show="props.row.timecard_available_flg && props.row.timecard_available_flg.value === '1'">
            <ValidationRadio
              v-model="props.row.wage_classification"
              rules="required"
              :item-label="labels.wage_classification"
              :disabled="disabled"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 申請対象フラグ -->
      <el-table-column width="200" :label="labels.workflow_flg_ryaku">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;" v-show="props.row.timecard_available_flg && props.row.timecard_available_flg.value === '1' && props.row.workflow_flg && props.row.workflow_flg.value !== '9'">
            <ValidationRadio
              v-model="props.row.workflow_flg"
              rules="required"
              :item-label="labels.workflow_flg"
              :disabled="disabled"
            />
          </div>
        </template>
      </el-table-column>
      <!-- カレンダーフラグ -->
      <el-table-column width="120" :label="$t('label.calendar')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left; white-space: normal;">
            {{getLabel(getSelectName(props.row.calender, comboData.calender_list))}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationRadio
    },
    props: {
      value: Array,
      comboData: Object,
      labels: Object,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      timecardAvailableFlgChanged (row) {
        if (row.timecard_available_flg.value === '1') {
          row.workflow_flg.value = '1'
        } else if (row.timecard_available_flg.value === '2') {
          row.workflow_flg.value = '2'
        }
      }
    }
  }
</script>

<style scoped>
</style>