<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <!-- <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal> -->
    <form method="#" action="#">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label>{{labels.vaccination}}</label>
            <div>
              <el-select class="select-success width200"
                          size="large"
                          placeholder=""
                          v-model="searchCond.vaccination">
                <el-option v-for="vaccination in vaccination_list"
                            class="select-success"
                            :value="vaccination.value"
                            :label="vaccination.label"
                            :key="vaccination.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <div>
              <label>{{$t('label.number_of_inoculations')}}</label>
              <div>
                <input
                  type="text"
                  class="form-control length1"
                  style="display:inline-block;"
                  :placeholder="$t('placeholder.number_of_inoculations')"
                  v-model="searchCond.number_of_inoculations">
                {{$t('label.more_than_once')}}
              </div>
            </div>
          </div>
          <br>
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <div>
              <label>{{$t('label.vaccination_serial_number')}}</label>
              <div>
                <input
                  type="text"
                  class="form-control width130"
                  style="display:inline-block;"
                  :placeholder="$t('placeholder.serial_number')"
                  v-model="searchCond.serial_number">
              </div>
            </div>
          </div>
          <br>
          <div class="form-group search-condition-item">
            <div>
              <label>{{$t('label.inoculation_venue')}}</label>
              <div>
                <input
                  type="text"
                  class="form-control width130"
                  style="display:inline-block;"
                  :placeholder="$t('placeholder.inoculation_venue')"
                  v-model="searchCond.inoculation_venue">
              </div>
            </div>
          </div>
          <!-- 退職者検索用のチェックボックス -->
          <div class="form-group">
            <div>
              <input type="checkbox" v-model="searchCond.retirement" class="gray" id="retirement" />
              <label for="retirement">{{$t('label.search_all_employees')}}</label>
            </div>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <el-select
            class="select-success length2"
            :disabled="isChanged"
            v-model="pagination.perPage">
            <el-option
              class="select-success"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <br>
          <br>
          <template v-if="labels.print === 1">
            <button type="button" class="btn btn-warning btn-sm btn-default" @click="printAll">{{$t('button.print')}}</button>
          </template>
          <div style="margin-top: 10px;">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                      border
                      :data="tableData"
                      :empty-text="$t('label.no_data')"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="getColumnWidth(tableData, '従業員', 'employee_name')" label="従業員">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.employee_name}}
                    </div>
                    <small>{{props.row.employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, $t('label.vaccine'), 'vaccination')" :label="$t('label.vaccine')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{getVaccinationName(props.row.vaccination)}}
                    </div>
                    <small>{{getMakerName(props.row.maker)}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.inoculation_first_date_ryaku, 'inoculation_first_date')+50" :label="labels.inoculation_first_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <template v-if="getInoculation(props.row.inoculation_first_date)!==''">
                      <div style="text-align: left; font-size: medium; font-weight: bold;">
                        {{getInoculation(props.row.inoculation_first_date)}}
                      </div>
                    </template>
                    <template v-if="props.row.first_inoculation_venue!==null">
                      <small>{{props.row.first_inoculation_venue}}</small>
                      <br>
                    </template>
                    <template v-if="getSerial(props.row.first_serial_number)!==''">
                      <small>{{getSerial(props.row.first_serial_number)}}</small>
                      <br>
                    </template>
                    <template v-if="getTicket(props.row.first_inoculation_ticket_number)!==''">
                      <small>{{getTicket(props.row.first_inoculation_ticket_number)}}</small>
                      <br>
                    </template>
                    <template v-if="props.row.application_number!==null">
                      <small>{{props.row.application_number}}</small>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.inoculation_second_date_ryaku, 'inoculation_second_date')+50" :label="labels.inoculation_second_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <template v-if="getInoculation(props.row.inoculation_second_date)!==''">
                      <div style="text-align: left; font-size: medium; font-weight: bold;">
                        {{getInoculation(props.row.inoculation_second_date)}}
                      </div>
                    </template>
                    <template v-if="props.row.second_inoculation_venue!==null">
                      <small>{{props.row.second_inoculation_venue}}</small>
                      <br>
                    </template>
                    <template v-if="getSerial(props.row.second_serial_number)!==''">
                      <small>{{getSerial(props.row.second_serial_number)}}</small>
                      <br>
                    </template>
                    <template v-if="getTicket(props.row.second_inoculation_ticket_number)!==''">
                      <small>{{getTicket(props.row.second_inoculation_ticket_number)}}</small>
                      <br>
                    </template>
                    <template v-if="props.row.application_number!==null">
                      <small>{{props.row.application_number}}</small>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.inoculation_third_date_ryaku, 'inoculation_third_date')+50" :label="labels.inoculation_third_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <template v-if="getInoculation(props.row.inoculation_third_date)!==''">
                      <div style="text-align: left; font-size: medium; font-weight: bold;">
                        {{getInoculation(props.row.inoculation_third_date)}}
                      </div>
                    </template>
                    <template v-if="props.row.third_inoculation_venue!==null">
                      <small>{{props.row.third_inoculation_venue}}</small>
                      <br>
                    </template>
                    <template v-if="getSerial(props.row.third_serial_number)!==''">
                      <small>{{getSerial(props.row.third_serial_number)}}</small>
                      <br>
                    </template>
                    <template v-if="getTicket(props.row.third_inoculation_ticket_number)!==''">
                      <small>{{getTicket(props.row.third_inoculation_ticket_number)}}</small>
                      <br>
                    </template>
                    <template v-if="props.row.application_number!==null">
                      <small>{{props.row.application_number}}</small>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="getColumnWidth(tableData, labels.first_physical_condition_ryaku, 'first_physical_condition')" :label="labels.first_physical_condition_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getCondition(props.row.first_physical_condition, props.row.second_physical_condition, props.row.third_physical_condition)}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="row" ref="pager">
              <div class="col-sm-6 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-6">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import VaccinationSearchMixin from '@/components/Dashboard/Views/Labor/VaccinationSearchMixin'

  export default {
    name: 'VaccinationSearch',
    mixins: [VaccinationSearchMixin]
  }
</script>
<style scoped>
</style>
