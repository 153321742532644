<template>
  <div class="row" ref="frame">
    <div class="col-md-12">
      <form method="#" action="#">
        <ValidationObserver ref="observer">
          <div style="margin-bottom: 10px;">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
              {{$t('button.close')}}
            </button>
          </div>
          <div class="form-group" v-if="detail.target_date">
            <label>{{$t('label.date')}}</label>
            <div>
              <span>{{detail.disp_target_date}}</span>
            </div>
          </div>
          <ValidationSelect
            v-model="detail.ground_code"
            item-name="ground_code"
            :placeholder="$t('label.select_description')"
            classes="length"
            :item-label="labels.ground"
            :options="comboData.ground_list"
            :disabled="isReadOnly"
            rules="required"
          />
          <ValidationStartEndText
            v-model="detail.work_time"
            item-name="work_time"
            max-length="5"
            classes="form-control time-length5 text-right"
            rules="isStartTime|isEndTime"
            :item-label="$t('label.work_time')"
            :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
            :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
            :disabled="isReadOnly"
            :help-text="$t('label.max_length')"
          />
          <div class="form-group">
            <div>
              <label>{{ $t('label.fixed_break_time_label') }}</label>
            </div>
            <span style="font-weight: bold;">{{ $t('label.range_break_time_label') }}</span>
            <button type="button" class="btn btn-sm btn-default btn-primary" @click="addStartEndTextRow">{{$t('button.add_row')}}</button>
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in breakTimeListErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"              border
              :data="detail.break_time_list"
              :empty-text="$t('label.no_data')"
              style="width: 230px">
              <el-table-column
                width="60"
                :label="$t('label.delete')"
                align="center">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                :label="labels.break_time_list ? labels.break_time_list : $t('label.break_time')"
                align="center">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <input
                      type="text"
                      class="input-text"
                      style="padding-right: 0.3em; width: 50px; text-align: right;"
                      :disabled="isReadOnly"
                      maxlength="5"
                      :placeholder="isReadOnly ? '' : $t('placeholder.break_time_start')"
                      v-model="props.row.start_time">
                    <label>～</label>
                    <input
                      type="text"
                      class="input-text"
                      style="padding-right: 0.3em; width: 50px; text-align: right;"
                      :disabled="isReadOnly"
                      maxlength="5"
                      :placeholder="isReadOnly ? '' : $t('placeholder.break_time_end')"
                      v-model="props.row.end_time">
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <BreakTimeList
            v-model="detail.break_time_range_list"
            :disabled="isReadOnly"
            :mode="isReadOnly ? modes.view : modes.edit"
            :labels="labels"
            :break-time-range-list-errors="breakTimeRangeListErrors"
          />
          <template v-if="isEditAll">
            <ValidationCheckboxes
              v-model="detail.apply_weekday"
              :item-label="$t('label.apply_weekday')"
            />
          </template>
          <div style="text-align: left;">
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="doUpdate">
              {{$t('button.apply_to')}}
            </button>
          </div>
        </ValidationObserver>
      </form>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationStartEndText from '@/components/UIComponents/Inputs/ValidationStartEndText'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import BreakTimeList from '@/components/UIComponents/Inputs/BreakTimeList'
  import moment from 'moment'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      ValidationSelect,
      ValidationStartEndText,
      ValidationCheckboxes,
      BreakTimeList
    },
    props: {
      params: Object
    },
    computed: {
      isEditAll () {
        return !this.params.target_date
      }
    },
    data () {
      return {
        componentKey: 0,
        detail: {
          work_schedule_code: '',
          work_schedule_name: '',
          working_system_abbreviation: '',
          working_system_type: '',
          is_job_before_start_time: {},
          work_time: ['', ''],
          break_time_list: [],
          break_time_range_list: [],
          term_from: '',
          term_to: '',
          apply_weekday: {
            color: 'gray',
            disabled: false,
            items: []
          }
        },
        breakTimeListErrors: [],
        breakTimeRangeListErrors: [],
        labels: {},
        comboData: {},
        isReadOnly: false
      }
    },
    methods: {
      customValidate () {
        let row = 1
        this.breakTimeListErrors = []
        for (const breakTime of this.detail.break_time_list) {
          if (!breakTime.selected) {
            // 休憩時間チェック
            // 開始時間hh:mm形式チェック（48:00まで）
            if (!breakTime.start_time.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
              if (breakTime.start_time !== '48:00') {
                this.breakTimeListErrors.push(this.$t('message.row_start_time_error').replace('{row}', row))
              }
            }
            // 終了時間hh:mm形式チェック（48:00まで）
            if (!breakTime.end_time.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
              if (breakTime.end_time !== '48:00') {
                this.breakTimeListErrors.push(this.$t('message.row_end_time_error').replace('{row}', row))
              }
            }
          }
          row++
        }
        row = 1
        this.breakTimeRangeListErrors = []
        for (const tmpBreakTime of this.detail.break_time_range_list) {
          if (!tmpBreakTime.selected) {
            if (isNaN(tmpBreakTime.break_minutes)) {
              this.breakTimeRangeListErrors.push(this.$t('message.is_item_number_for_row').replace('{row}', row).replace('{item}', this.labels.break_minutes))
              continue
            }
            const rangeBreakTime = parseInt(tmpBreakTime.range_break_hour, 10) * 60 + parseInt(tmpBreakTime.range_break_minute, 10)
            const breakTime = parseInt(tmpBreakTime.break_minutes, 10)
            if (rangeBreakTime < breakTime) {
              this.breakTimeRangeListErrors.push(this.$t('message.range_break_time_error').replace('{row}', row))
            }
          }
          row++
        }
        return this.breakTimeListErrors.length + this.breakTimeRangeListErrors.length === 0
      },
      addStartEndTextRow () {
        let startEndTextRow = {
          selected: false,
          start_time: '',
          end_time: ''
        }
        this.detail.break_time_list.unshift(startEndTextRow)
      },
      doUpdate () {
        this.$refs.observer.validate().then(isValid => {
          let isCustomValid = this.customValidate()
          if (!isValid || !isCustomValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }
          this.$emit('close', this.detail)
        })
      },
      close () {
        this.$emit('close')
      }
    },
    created () {
      moment.locale(localStorage.getItem('language'))
      this.labels = this.params.labels
      this.comboData = this.params.combo_data
      this.detail = {...this.detail, ...this.params}
      if (this.detail.target_date) {
        this.detail.disp_target_date = moment(this.detail.target_date).format('M/D(ddd)')
      }
      this.detail.work_time = [this.params.job_start, this.params.job_end]
      const items = []
      items.push({
        id: 2,
        label: this.$t('label.monday_ryaku'),
        value: true
      })
      items.push({
        id: 3,
        label: this.$t('label.tuesday_ryaku'),
        value: true
      })
      items.push({
        id: 4,
        label: this.$t('label.wednesday_ryaku'),
        value: true
      })
      items.push({
        id: 5,
        label: this.$t('label.thursday_ryaku'),
        value: true
      })
      items.push({
        id: 6,
        label: this.$t('label.friday_ryaku'),
        value: true
      })
      items.push({
        id: 7,
        label: this.$t('label.saturday_ryaku'),
        value: true
      })
      items.push({
        id: 1,
        label: this.$t('label.sunday_ryaku'),
        value: true
      })
      this.detail.apply_weekday = {
        color: 'gray',
        disabled: false,
        items: items
      }
    }
  }
</script>
<style>
</style>
