<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            事業所選択
          </h4>
        </div>
        <div class="card-content row">
          <div class="col-sm-4">
            <el-select
              class="select-success"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-success"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4">
          </div>
          <div class="col-sm-4">
            <div class="pull-right">
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 10px;">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="queriedData"
                      style="width: 100%">
              <el-table-column
                :width="90"
                fixed="left"
                label="操作"
                align="center">
                <template slot-scope="props">
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="select(props.$index, props.row)">選択</button>
                </template>
              </el-table-column>
              <el-table-column min-width="110" label="事業所" align="center">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <small>{{props.row.office_code}}</small>
                    <br>
                    {{props.row.office_name}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">{{dispRecords}}</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination
    },
    props: {
      tableData: Array
    },
    computed: {
      pageTitle () {
        for (let menu of this.$store.state.loginUser.sidebarLinks) {
          for (let child of menu.children) {
            if (this.$route.path.startsWith(child.path)) {
              return child.name
            }
          }
        }
        return '不明な画面'
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      dispRecords () {
        return this.labels.label_disp_records.replace('{total}', this.total).replace('{from}', this.from + 1).replace('{to}', this.to)
      }
    },
    data () {
      return {
        labels: {
          label_disp_records: ''
        },
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        }
      }
    },
    methods: {
      select (index, row) {
        this.$emit('close', row)
      }
    }
  }
</script>
<style>
</style>
