<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.role_name"
              item-name="role_name"
              max-length="30"
              classes="form-control length30"
              rules="required"
              :item-label="labels.role_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.role_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <ValidationSelect
              v-model="detail.permission"
              item-name="permission"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.permission"
              :options="comboData.permission_list"
              :disabled="isReadOnly"
              @change="permissionChanged"
              data-v-step="1"
            />
            <ValidationSelect
              v-model="detail.copy_role_code"
              item-name="copy_role_code"
              :placeholder="$t('label.select_description')"
              classes="length"
              :item-label="labels.copy_role_code"
              :options="comboData.copy_role_list"
              :disabled="isReadOnly"
              @change="copyRoleCodeChanged"
              v-show="mode==modes.add"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import RoleDetailMixin from '@/components/Dashboard/Views/Generated/RoleDetailMixin'

  export default {
    name: 'RoleDetail',
    methods: {
      afterInit (res) {
        this.labels.copy_role_code = this.$t('label.copy_role_code').replace('{item1}', this.labels.authority).replace('{item2}', this.labels.role)
        this.comboData.copy_role_list = this.comboData.role_list
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          delete detail.id
          delete detail.company_code
          if (this.mode !== this.modes.add) {
            delete detail.copy_role_code
          }
          detail.permission = parseInt(detail.permission, 10)
        }
        return detail
      }
    },
    mixins: [RoleDetailMixin]
  }
</script>
<style scoped>
</style>
