<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <div class="row">
        <div class="col-sm-6">
          <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack">{{$t('button.back')}}</button>
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="bonus" div-style="padding-bottom: 5px;" />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{screenCodeTitle(screenCode)}}</h4>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <ValidationObserver ref="observer">
              <!-- 対象月 -->
              <!-- 労働月の範囲(開始) -->
              <!-- 労働月の範囲(終了) -->

              <h5>
                <strong>{{detail.target_date.substring(0, 4)}}{{$t('label.year')}}{{detail.target_date.substring(4, 6)}}{{$t('label.monthly')}}{{ detail.bonus_pay_slip_name }}</strong>
                ({{detail.term_from}}-{{detail.term_to}})
              </h5>
              {{labels.employee_name}}:{{detail.employee_code}}&nbsp;{{detail.employee_name}}
              <br />
              <br />
              <!-- レイアウト -->
               {{ labels.layout + ': ' + getLabel(getSelectName(detail.layout_code || params.layout_code, comboData.bonus_layout_list)) }}
              <!-- <ValidationSelect
                v-model="detail.layout_code"
                item-name="payment_deduction_category"
                classes="length"
                rules="required"
                :item-label="labels.layout"
                :options="comboData.bonus_layout_list"
                :disabled="isReadOnly"
              /> -->
              <!-- 計算ボタン -->
              <!-- <div style="text-align: left;">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-default"
                  @click="layout_view"
                  v-if="!isReadOnly && !this.isChanged"
                >{{$t('button.select')}}</button>
              </div> -->
              <template v-if="isViewDetail">
                <hr />
                <!-- 支給額 -->
                <label>{{$t('label.payment_salary_item_code')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payment_salary_item01()"
                  ref="table"
                  style="width: 1000"
                >
                  <el-table-column
                    v-for="n of 10"
                    :key="n"
                    min-width="144"
                    :label="payment_salary_item01()[0]['payment_salary_item_name' + n ]"
                  >
                    <div style="text-align: right;">
                      <template v-if="detail['payment_salary_item_code' + n  ] === '' ">
                        &nbsp;
                        <br />&nbsp;
                        <br />
                      </template>
                      <template v-else>
                        <ValidationTextNumber
                          v-model="detail['payment_salary_item_code' + n +'_amount']"
                          integral="10"
                          rules="required_simple|decimal_simple|min_value:0|max_value:9999999999"
                          classes="form-control numLength10"
                          :placeholder="detail['payment_salary_item_code' + n ] === '' ? ''  :  '10,000'"
                          :disabled="isReadOnly || isNotEditable(detail['payment_salary_item_code' + n ], isPaymentHandType(n))"
                          :is-replace="false"
                          @input="detail={...detail}"
                        />
                        <div style="text-align: center;">
                          <template v-if="showEditIcon(detail['payment_salary_item_code' + n ], detail['payment_salary_item_code' + n + '_can_change'])">
                            <a href="#" @click.prevent="updatePaymentHandType(n, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isPaymentHandType(n)"><i class="ti-pencil-alt"></i></a>
                            <a href="#" @click.prevent="updatePaymentHandType(n, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isPaymentHandType(n)"><i class="ti-back-left"></i></a>
                          </template>
                          <template v-else>
                            &nbsp;
                          </template>
                        </div>
                      </template>
                    </div>
                  </el-table-column>
                </el-table>

                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payment_salary_item11()"
                  ref="table"
                  style="width: 1000"
                >
                  <el-table-column
                    v-for="n of 10"
                    :key="n"
                    min-width="144"
                    :label="payment_salary_item11()[0]['payment_salary_item_name' + (n + 10) ]"
                  >
                    <div style="text-align: right;">
                      <template v-if="detail['payment_salary_item_code' + (n + 10) ] === '' ">
                        &nbsp;
                        <br />&nbsp;
                        <br />
                      </template>
                      <template v-else>
                        <ValidationTextNumber
                          v-model="detail['payment_salary_item_code' + (n + 10) +'_amount']"
                          integral="10"
                          rules="required_simple|decimal_simple|min_value:0|max_value:9999999999"
                          classes="form-control numLength10"
                          :placeholder="detail['payment_salary_item_code' + (n + 10) ] === '' ? ''  :  '10,000'"
                          :disabled="isReadOnly || isNotEditable(detail['payment_salary_item_code' + (n + 10) ], isPaymentHandType(n + 10))"
                          :is-replace="false"
                          @input="detail={...detail}"
                        />
                        <div style="text-align: center;">
                          <template v-if="showEditIcon(detail['payment_salary_item_code' + (n + 10) ], detail['payment_salary_item_code' + (n + 10) + '_can_change'])">
                            <a href="#" @click.prevent="updatePaymentHandType(n + 10, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isPaymentHandType(n + 10)"><i class="ti-pencil-alt"></i></a>
                            <a href="#" @click.prevent="updatePaymentHandType(n + 10, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isPaymentHandType(n + 10)"><i class="ti-back-left"></i></a>
                          </template>
                          <template v-else>
                            &nbsp;
                          </template>
                        </div>
                      </template>
                    </div>
                  </el-table-column>
                </el-table>
                <br />
                <br />
                <hr />
                <!-- 控除額 -->
                <label>{{$t('label.deduction_salary_item_code')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="deduction_salary_item01()"
                  ref="table"
                  style="width: 1000"
                >
                  <el-table-column
                    v-for="n of 10"
                    :key="n"
                    min-width="144"
                    :label="deduction_salary_item01()[0]['deduction_salary_item_name' + n ]"
                  >
                    <div style="text-align: right;">
                      <template v-if="detail['deduction_salary_item_code' + n ] === '' ">
                        &nbsp;
                        <br />&nbsp;
                        <br />
                      </template>
                      <template v-else>
                        <ValidationTextNumber
                          v-model="detail['deduction_salary_item_code' + n +'_amount']"
                          integral="10"
                          rules="required_simple|decimal_simple|min_value:0|max_value:9999999999"
                          classes="form-control numLength10"
                          :placeholder="detail['deduction_salary_item_code' + n ] === '' ? '' : '10,000'"
                          :disabled="isReadOnly || isNotEditable(detail['deduction_salary_item_code' + n ], isDeductionHandType(n))"
                          :is-replace="false"
                          @input="detail={...detail}"
                        />
                        <div style="text-align: center;">
                          <template v-if="showEditIcon(detail['deduction_salary_item_code' + n ], detail['deduction_salary_item_code' + n + '_can_change'])">
                            <a href="#" @click.prevent="updateDeductionHandType(n, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isDeductionHandType(n)"><i class="ti-pencil-alt"></i></a>
                            <a href="#" @click.prevent="updateDeductionHandType(n, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isDeductionHandType(n)"><i class="ti-back-left"></i></a>
                          </template>
                          <template v-else>
                            &nbsp;
                          </template>
                        </div>
                      </template>
                    </div>
                  </el-table-column>
                </el-table>

                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="deduction_salary_item11()"
                  ref="table"
                  style="width: 1000"
                >
                  <el-table-column
                    v-for="n of 10"
                    :key="n"
                    min-width="144"
                    :label="deduction_salary_item11()[0]['deduction_salary_item_name' + (n + 10) ]"
                  >
                    <div style="text-align: right;">
                      <template v-if="detail['deduction_salary_item_code' + (n + 10) ] === '' ">
                        &nbsp;
                        <br />&nbsp;
                        <br />
                      </template>
                      <template v-else>
                        <ValidationTextNumber
                          v-model="detail['deduction_salary_item_code' + (n + 10) +'_amount']"
                          integral="10"
                          rules="required_simple|decimal_simple|min_value:0|max_value:9999999999"
                          classes="form-control numLength10"
                          :placeholder="detail['deduction_salary_item_code' + (n + 10) ] === '' ? ''  :  '10,000'"
                          :disabled="isReadOnly || isNotEditable(detail['deduction_salary_item_code' + (n + 10) ], isDeductionHandType(n + 10))"
                          :is-replace="false"
                          @input="detail={...detail}"
                        />
                        <div style="text-align: center;">
                          <template v-if="showEditIcon(detail['deduction_salary_item_code' + (n + 10) ], detail['deduction_salary_item_code' + (n + 10) + '_can_change'])">
                            <a href="#" @click.prevent="updateDeductionHandType(n + 10, '1')" style="color: #555;" :title="$t('label.salary_edit_description')" v-show="!isDeductionHandType(n + 10)"><i class="ti-pencil-alt"></i></a>
                            <a href="#" @click.prevent="updateDeductionHandType(n + 10, '0')" style="color: #555;" :title="$t('label.salary_edit_cancel_description')" v-show="isDeductionHandType(n + 10)"><i class="ti-back-left"></i></a>
                          </template>
                          <template v-else>
                            &nbsp;
                          </template>
                        </div>
                      </template>
                    </div>
                  </el-table-column>
                </el-table>
                <br />
                <br />
                <hr />
                <!-- 対象額 -->
                <label>{{$t('label.target_amount')}}</label>
                <br />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="payslipTotalTableBase()"
                  ref="table"
                  style="width: 300"
                >
                  <!-- 社会保険対象額 -->
                  <el-table-column min-width="140" :label="labels.social_insurance_amount_ryaku">
                    <ValidationTextNumber
                      v-model="detail.social_insurance_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                  <!-- 所得税対象額 -->
                  <el-table-column min-width="140" :label="labels.taxable_amount_2_ryaku">
                    <ValidationTextNumber
                      v-model="detail.taxable_amount_2"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                  <!-- 雇用保険対象額 -->
                  <el-table-column
                    min-width="140"
                    :label="labels.employement_insurance_amount_ryaku"
                  >
                    <ValidationTextNumber
                      v-model="detail.employement_insurance_amount"
                      integral="10"
                      classes="form-control numLength10"
                      :placeholder="isReadOnly ? '' : '10000'"
                      :disabled="true"
                    />
                  </el-table-column>
                </el-table>
                <br />
                <table height="40">
                  <tr>
                    <td>
                      &nbsp;
                      <!-- 健康保険 一般 -->
                      {{labels.general_health_insurance}}:{{detail.general_health_insurance ? detail.general_health_insurance :0 }}{{$t('label.currency')}}&nbsp;/&nbsp;
                      <!-- 健康保険 調整 -->
                      {{labels.health_insurance_adjustment}}:{{detail.health_insurance_adjustment ? detail.health_insurance_adjustment :0 }}{{$t('label.currency')}}&nbsp;/&nbsp;
                      <!-- 介護保険 -->
                      {{labels.care_insurance}}:{{detail.care_insurance ? detail.care_insurance :0 }}{{$t('label.yen')}}&nbsp;/&nbsp;
                      <!-- 社会保険計 -->
                      ({{labels.social_insurance_total}})&nbsp;{{detail.social_insurance_total ? detail.social_insurance_total :0 }}{{$t('label.currency')}}
                    </td>
                  </tr>
                </table>
                <hr />
                <!-- 合計 -->
                <div class="form-group" style="display: inline-block">
                  <label>{{$t('label.total')}}</label>
                  <br />
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    class="table-width"
                    border
                    :data="payslipTotalTableBase()"
                    ref="table"
                    style="width: 800"
                  >
                    <!-- 支給額合計 -->
                    <el-table-column min-width="144" :label="labels.total_payment_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.total_payment_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                    <!-- 控除額合計 -->
                    <el-table-column min-width="144" :label="labels.total_deduction_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.total_deduction_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                    <!-- 差引支給額 -->
                    <el-table-column min-width="144" :label="labels.deduction_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.deduction_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 振込先 -->
                <div class="form-group" style="display: inline-block">
                  <br />
                  <label>{{$t('label.bank_transfer')}}</label>
                  <br />
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    class="table-width"
                    border
                    :data="payslipTotalTableBase()"
                    ref="table"
                    style="width: 800"
                  >
                    <!-- 第一振込先 金額 -->
                    <el-table-column min-width="144" :label="labels.first_transfer_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.first_transfer_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                    <!-- 第二振込先 金額 -->
                    <el-table-column min-width="144" :label="labels.second_transfer_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.second_transfer_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 現金支給額 -->
                <div class="form-group" style="display: inline-block">
                  <br />
                  <label>{{$t('label.cash')}}</label>
                  <br />
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    class="table-width"
                    border
                    :data="payslipTotalTableBase()"
                    ref="table"
                    style="width: 800"
                  >
                    <!-- 現金支給額 ※表示項目-->
                    <el-table-column min-width="144" :label="labels.cash_payment_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.cash_payment_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 現物支給額 -->
                <div class="form-group" style="display: inline-block">
                  <br />
                  <label>{{$t('label.in_kind')}}</label>
                  <br />
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    class="table-width"
                    border
                    :data="payslipTotalTableBase()"
                    ref="table"
                    style="width: 800"
                  >
                    <!-- 現物支給額 ※表示項目-->
                    <el-table-column min-width="144" :label="labels.in_kind_payment_amount_ryaku">
                      <ValidationTextNumber
                        v-model="detail.in_kind_payment_amount"
                        integral="10"
                        classes="form-control numLength10"
                        :placeholder="isReadOnly ? '' : '10000'"
                        :disabled="true"
                      />
                    </el-table-column>
                  </el-table>
                </div>
                <br />
                <hr />
                <!-- 備考 -->
                <table border>
                  <th
                    colspan="10"
                    style="background-color:#364f6b;color: #ccc; padding: 2px 0 !important; font-size: 12px;"
                  >
                    <b>{{$t('label.remark')}}</b>
                  </th>
                  <tr>
                    <div style="display: inline-block; margin: 0; padding: 0;">
                      <ValidationTextArea
                        v-model="detail.remark"
                        item-name="other"
                        max-length="255"
                        classes="form-control"
                        style="width: 1440px;"
                        rules='remarkMax9Rows'
                        :rows="9"
                        :disabled="isReadOnly"
                        :help-text="$t('label.max_9row_and_max_length')"
                      />
                    </div>
                  </tr>
                </table>
              </template>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <div style="text-align: left;">
        <template v-if="!isReadOnly && !this.isChanged">
          <!-- 計算ボタン -->
          <button
            type="button"
            class="btn btn-primary btn-sm btn-default"
            v-if="isViewDetail"
            @click="calc"
            :disabled="!isModified"
            v-tooltip.top-center="(isModified ? $t('message.require_calc') : '') + $t('label.description_of_payslip_calculation')"
          >{{$t('button.calculation')}}</button>
          &nbsp;&nbsp;
        </template>
        <template v-if="mode==modes.add && !this.isChanged">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            v-if="isViewDetail"
            @click="doAdd"
            :disabled="isModified"
            v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </template>
        <template v-if="mode==modes.edit && !this.isChanged">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            v-if="isViewDetail"
            @click="doUpdate"
            :disabled="isModified"
            v-tooltip.top-center="isModified ? $t('message.calc_before_update') : undefined"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </template>
        <template v-if="mode==modes.delete && !this.isChanged">
          <button
            type="button"
            class="btn btn-sm btn-default btn-submit"
            @click="doDelete"
          >
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </template>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import CreateBonusPaySlipDetailMixin from '@/components/Dashboard/Views/Salary/CreateBonusPaySlipDetailMixin'

  export default {
    name: 'CreateBonusPaySlipDetail',
    mixins: [CreateBonusPaySlipDetailMixin]
  }
</script>
<style scoped>
</style>
