<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationRadio
              v-model="detail.route_type"
              rules="required"
              :item-label="labels.route_type"
              vid="routeType"
              :disabled="isPrimaryKeyReadOnly"
              data-v-step="0"
            />
            <ValidationText
              v-model="detail.route_name"
              item-name="route_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.route_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.route_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="1"
            />
            <ValidationApprovalRoute
              v-model="detail.approval_route"
              :disabled="isReadOnly"
              :combo-data="comboData"
              :labels="labels"
              rules="isRoleOrApproverRequired|isAuthorityRequired|isApprovalRouteRequired|isExaminationRequired:@routeType|isNotDuplicatedApprover|isConfirmLast"
              data-v-step="2"
              @processing="processing"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ApprovalRouteDetailMixin from '@/components/Dashboard/Views/Generated/ApprovalRouteDetailMixin'

  export default {
    name: 'ApprovalRouteDetail',
    methods: {
      afterInit (res) {
        this.labels.route_name = this.$t('label.route_name')
        this.comboData.mapping_company_and_group = res.common_area.combo_data.company_list.mapping_company_and_group
        this.comboData.mapping_company_and_role = res.common_area.combo_data.company_list.mapping_company_and_role
        const approvalRoute = res.api_area === null ? [] : res.api_area.individual_activity_list ? res.api_area.individual_activity_list : res.api_area.common_activity_list
        for (const row of approvalRoute) {
          row.function = String(row.function)
        }
        this.detail.approval_route = approvalRoute
        this.detail.route_code = res.api_area ? res.api_area.individual_route_code ? res.api_area.individual_route_code : res.api_area.common_route_code : ''
        this.detail.route_name = res.api_area ? res.api_area.individual_route_name ? res.api_area.individual_route_name : res.api_area.common_route_name : ''
        this.detail.route_type = {
          value: this.mode === this.modes.add ? '' : res.api_area.individual_route_code ? '1' : '2',
          disabled: this.isReadOnly,
          items: [
            {
              id: this.comboData.route_type_list[0].value,
              label: this.getLabel(this.comboData.route_type_list[0].label),
              value: this.comboData.route_type_list[0].value
            },
            {
              id: this.comboData.route_type_list[1].value,
              label: this.getLabel(this.comboData.route_type_list[1].label),
              value: this.comboData.route_type_list[1].value
            }
          ]
        }
        const approvalFunctionList = []
        for (const row of this.comboData.approval_function_list) {
          if (row.value !== '2') {
            approvalFunctionList.push(row)
          }
        }
        this.comboData.approval_function_list = approvalFunctionList
      },
      processing (isProcessing) {
        this.isProcessing = isProcessing
      },
      convertDetail (detail) {
        if (this.mode === this.modes.delete) {
          detail.route_type = parseInt(this.detail.route_type.value, 10)
          detail.route_code = this.detail.route_code
        } else {
          let routeNumber = 1
          const approvalRoute = []
          for (const row of detail.approval_route) {
            if (row.selected) continue
            const element = {
              activity_code: routeNumber,
              approverl_company_code: row.approverl_company_code,
              approverl_group_code: row.approverl_group_code,
              approverl_role_code: this.emptyToBlank(row.approverl_role_code),
              approverl_employee_code: row.approverl_employee_code,
              function: parseInt(row.function, 10)
            }
            approvalRoute.push(element)
            routeNumber += 1
          }
          detail.approval_route = approvalRoute
          detail.route_type = parseInt(this.detail.route_type.value, 10)
          if (this.mode === this.modes.add) {
            delete detail.route_code
          }
          delete detail.company_code
          delete detail.individual_route_code
          delete detail.individual_route_name
          delete detail.individual_activity_list
          delete detail.common_route_code
          delete detail.common_route_name
          delete detail.common_activity_list
        }
        return detail
      }
    },
    mixins: [ApprovalRouteDetailMixin]
  }
</script>
<style scoped>
</style>
