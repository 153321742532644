import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
// import Detail from '@/components/Dashboard/Views/Labor/VaccinationDetail'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'VaccinationSearchMixin',
  mixins: [mixin],
  components: {
    // Detail,
    PPagination
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      pageTableData: undefined,
      searchCond: {
        vaccination: '',
        number_of_inoculations: '',
        serial_number: '',
        inoculation_venue: '',
        retirement: false
      },
      showSearchCondition: true,
      showOperation: true,
      showEmployeeClassificationName: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: '',
      vaccination_list: [],
      maker_list: [],
      number_of_inoculations: ''
    }
  },
  computed: {
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.vaccination_list = this.comboData.vaccination_list
      this.vaccination_list.unshift({value: '', label: ''})
      this.maker_list = this.comboData.maker_list
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    search () {
      let searchCond = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          vaccination: this.searchCond.vaccination === '' ? null : Number(this.searchCond.vaccination),
          count: this.searchCond.number_of_inoculations === '' ? null : Number(this.searchCond.number_of_inoculations),
          serial_number: this.searchCond.serial_number,
          inoculation_venue: this.searchCond.inoculation_venue,
          retirement: this.searchCond.retirement
        }
      }
      this.send('/view/employee_vaccination/list', searchCond)
      .then(res => {
        this.tableData = res.api_area.employee_vaccinationd_data
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
      })
    },
    pageChanged () {

    },
    getCondition (first, second, third) {
      if (third !== null) {
        return third
      } else if (second !== null) {
        return second
      } else if (first) {
        return first
      } else {
        return ''
      }
    },
    getInoculation (inoculation) {
      if (inoculation !== null && inoculation !== '') {
        return inoculation + ' ' + this.$t('label.inoculation')
      }
      return ''
    },
    getSerial (serial) {
      if (serial !== null && serial !== '') {
        return this.labels.first_serial_number_ryaku + ' ' + serial
      }
      return ''
    },
    getTicket (ticket) {
      if (ticket !== null && ticket !== '') {
        return this.labels.first_inoculation_ticket_number_ryaku + ' ' + ticket
      }
      return ''
    },
    getVaccinationName (code) {
      for (const data of this.vaccination_list) {
        if (Number(data.value) === Number(code)) {
          return data.label
        }
      }
    },
    getMakerName (code) {
      for (const data of this.maker_list) {
        if (Number(data.value) === Number(code)) {
          return data.label
        }
      }
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode
      }
      this.send('/init/employee_vaccination/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    },
    printAll () {
      let searchCond = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          vaccination: this.searchCond.vaccination === '' ? null : Number(this.searchCond.vaccination),
          serial_number: this.searchCond.serial_number,
          inoculation_venue: this.searchCond.inoculation_venue,
          count: this.searchCond.number_of_inoculations === '' ? null : Number(this.searchCond.number_of_inoculations)
        }
      }
      this.send('/print/employee_vaccination', searchCond)
      .then(res => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
      .catch(error => {
        console.error(error)
      })
    }
  },
  created () {
    this.init()
  }
}
