<template>
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <vSelect :class="itemClasses"
                :name="itemName"
                :disabled="disabled"
                :options="options"
                v-model="itemValue"
                @input="changed">
      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
    </vSelect>
    <div class="error-message-wrapper" v-show="!valid" style="display: inline-block;">
      <small class="text-danger">
        {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
      </small>
    </div>
  </ValidationProvider>
</template>

<script>
  import vSelect from 'vue-select'
  import PlaceDetail from '@/components/Dashboard/Views/Generated/PlaceDetail'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      vSelect,
      PlaceDetail
    },
    props: {
      value: [String, Number],
      itemName: String,
      rules: String,
      itemLabel: String,
      options: Array,
      disabled: Boolean,
      classes: String,
      placeholder: String
    },
    methods: {
      show () {
        this.$modal.show(this.modalName)
      },
      hide (added) {
        this.$modal.hide(this.modalName)
        if (added.value) {
          this.options.push(added)
          this.itemValue = added.value
          this.$emit('input', this.itemValue)
        }
      },
      changed () {
        this.$emit('input', this.itemValue.value)
        this.$emit('change')
      }
    },
    data () {
      return {
        params: {},
        itemValue: null,
        itemClasses: 'style-chooser'
      }
    },
    watch: {
      value () {
        if (this.options && this.options.length > 0) {
          this.itemValue = ''
          for (const row of this.options) {
            if (row.value === this.value) {
              this.itemValue = row
              break
            }
          }
        }
        this.itemClasses = 'style-chooser ' + this.classes
      }
    },
    mounted () {
      if (this.options && this.options.length > 0) {
        this.itemValue = ''
        for (const row of this.options) {
          if (row.value === this.value) {
            this.itemValue = row
            break
          }
        }
      }
      this.itemClasses = 'style-chooser ' + this.classes
    }
  }
</script>

<style scoped>
</style>
