<template>
  <div>
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled"
                v-model="month"
                @change="emitIfValid(month, true)"
    >
      <el-option v-for="month in months"
                  class="select-success"
                  :value="month"
                  :label="month"
                  :key="month">
      </el-option>
    </el-select>&nbsp;{{$t('label.month')}}&nbsp;
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled"
                v-model="day"
                @change="emitIfValid(day, false)"
    >
      <el-option v-for="day in days"
                  class="select-success"
                  :value="day"
                  :label="day"
                  :key="day">
      </el-option>
    </el-select>&nbsp;{{$t('label.day')}}&nbsp;
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      disabled: Boolean,
      classes: String,
      labels: Object
    },
    methods: {
      setDays () {
        if (this.months28.includes(this.month)) {
          this.days = this.days28
        } else if (this.months30.includes(this.month)) {
          this.days = this.days30
        } else {
          this.days = this.days31
        }
      },
      emitIfValid (selected, isMonthChanged) {
        if (selected) {
          if (this.month === '') {
            this.month = '1'
          }
          if (this.day === '') {
            this.day = '1'
          }
          if (isMonthChanged) {
            this.setDays()
          }
          var monthDay = ('0' + this.month).slice(-2) + '/' + ('0' + this.day).slice(-2)
          this.$emit('input', monthDay)
        } else {
          this.month = ''
          this.day = ''
          this.$emit('input', '')
        }
      }
    },
    data () {
      return {
        months: ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        months28: ['2'],
        months30: ['4', '6', '9', '11'],
        months31: ['1', '3', '5', '7', '8', '10', '12'],
        days: ['', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31'],
        days28: [
          '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28'
        ],
        days29: [
          '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'
        ],
        days30: ['1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30'
        ],
        days31: ['1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31'
        ],
        month: '',
        day: ''
      }
    },
    watch: {
      value () {
        if (this.value) {
          this.month = String(parseInt(this.value.split('/')[0], 10))
          this.day = String(parseInt(this.value.split('/')[1], 10))
          this.setDays()
        }
      }
    },
    mounted () {
      if (this.value) {
        this.month = String(parseInt(this.value.split('/')[0], 10))
        this.day = String(parseInt(this.value.split('/')[1], 10))
        this.setDays()
      }
    }
  }
</script>

<style scoped>
</style>
