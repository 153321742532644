<template>
  <div :class="isSearchCondition ? 'form-group search-condition-item' : 'form-group'">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <div>
      <input type="checkbox" v-model="itemValue[1]" class="gray" id="nextMonth" :disabled="disabled">
      <label for="nextMonth">{{$t('label.next_month')}}</label>&nbsp;&nbsp;
      <input 
        :type="type" 
        :name="itemName"
        :placeholder="placeholder"
        :class="classes" 
        style="display: inline-block;"
        v-model="itemValue[0]" 
        :maxlength="maxLength"
        :disabled="disabled">{{unit ? '&nbsp;' + unit : ''}}
      <template v-if="isClosingDate">
        <span>{{$t('label.closed_at')}}</span>
        <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-primary" @click="$emit('calcClosingDate')">
          {{$t('button.auto_input')}}
        </button>
        <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-primary" @click="$emit('calcClosingDateWithEndOfMonth')">
          {{$t('button.auto_input_with_end_of_month')}}
        </button>
        <span class="required">{{$t('label.required')}}</span>
      </template>
    </div>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        require: false,
        'default': 'text'
      },
      value: Array,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      isClosingDate: {
        type: Boolean,
        require: false,
        'default': false
      },
      isPassword: {
        type: Boolean,
        require: false,
        'default': false
      },
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      isSearchCondition: {
        type: Boolean,
        require: false,
        'default': false
      },
      pattern: {
        type: String,
        require: false,
        'default': ''
      }
    },
    data () {
      return {
        itemValue: ['', false]
      }
    },
    mounted () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>