<template>
  <div class="row" style="overflow-y: auto; height: 570px;" name="employeeFrame">
    <div class="col-md-12">
      <modal name="TeamSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
        <div class="modal-body">
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="teamSearchHide">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
          <TeamSearch :mode="1" :params="params" @close="teamSearchHide"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-content" style="padding-left: 20px;">
          <div class="row">
            <label for="planId">
              {{labels.team}}
            </label>
            <!-- 追加ボタン -->
            <template>
              <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addTeamRecord">{{$t('button.add_row')}}</button>
              <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" @click="teamSearchShowAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button>
              <label>
                <input type="checkbox" class="gray" v-model="team_hidden" :disabled="isReadOnly" @change="hiddenTeamList">
                {{'有効期間外も表示'}}
              </label>
            </template>
            <div class="error-message-wrapper" v-for="errorMsg in team_error" v-bind:key="errorMsg">
              <small class="text-danger">
                <!-- エラーメッセージ -->
                {{ errorMsg }}
              </small>
            </div>
            <!-- チーム -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"              border
              :data="team_record"
              :style="getStyle()">
              <!-- 削除 -->
              <el-table-column min-width="50" :label="$t('label.delete')">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                  </div>
                  </template>
              </el-table-column>
              <!-- チーム -->
              <el-table-column min-width="200" :label="labels.team_ryaku ? labels.team_ryaku : ''">
                <template slot-scope="props">
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              :disabled="isReadOnly"
                              :key="componentKey"
                              v-model="props.row.team_code">
                    <el-option v-for="option in team_list"
                                :class="option.optionClass"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- 所属期間(開始) -->
              <el-table-column min-width="150" :label="$t('label.employee_team_term_from')">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.term_from"
                      type="date"
                      :placeholder="$t('placeholder.start_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
              <!-- 所属期間(終了) -->
              <el-table-column min-width="150" :label="$t('label.employee_team_term_to')" v-if="!isAddMode()">
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <el-date-picker
                      min-width="150"
                      v-model="props.row.term_to"
                      type="date"
                      :placeholder="$t('placeholder.end_date')"
                      :disabled="isReadOnly"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import TeamSearch from '@/components/Dashboard/Views/Generated/TeamSearch.vue'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      TeamSearch,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        labels: {},
        team_record: [],
        team_error: [],
        team_list: [],
        mode: 0,
        componentKey: 0,
        team_hidden: false,
        retirement_date: '',
        evacuation_team_record: []
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      init () {
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        this.today = Number(String(year) + month + day)
      },
      hiddenTeamList () {
        if (this.team_hidden) {
          // 有効期間外も表示
          this.team_record = this.team_record.concat(this.evacuation_team_record)
          this.evacuation_team_record = []
        } else {
          // 有効期限のみ表示
          let deleteIndexs = []
          let index = 0
          for (let team of this.team_record) {
            if (this.isEnd(team.term_to)) {
              // 期間が過ぎている場合
              this.evacuation_team_record.push(team)
              deleteIndexs.push(index)
            }
            index = index + 1
          }
          // 要素の削除
          for (let listIndex = deleteIndexs.length - 1; listIndex >= 0; listIndex--) {
            this.team_record.splice(deleteIndexs[listIndex], 1)
          }
        }
      },
      // チームの追加ボタン押下時の処理
      addTeamRecord () {
        let record = {
          selected: false,
          team_code: '',
          term_from: '',
          term_to: ''
        }
        this.team_record.push(record)
      },
      teamSearchShowAdd () {
        this.params = {}
        this.$modal.show('TeamSearch')
      },
      teamSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // チーム
          this.team_list = res.common_area.combo_data.team_list
          this.componentKey += 1
        })
        this.$modal.hide('TeamSearch')
      },
      isAddMode () {
        return this.mode === this.modes.add
      },
      setDetails (res, mode) {
        if ((mode !== this.modes.add) || (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '')) {
          for (const data of res.api_area.team_list) {
            let record = {
              selected: false,
              team_code: data.team_code,
              term_from: data.term_from,
              term_to: data.term_to
            }
            this.team_record.push(record)
          }
        }
        if (this.isEnd(this.retirement_date)) {
          this.team_hidden = true
        } else {
          this.hiddenTeamList()
        }
      },
      getStyle () {
        if (this.isAddMode()) {
          return 'width: 450px'
        } else {
          return 'width: 560px'
        }
      },
      isEnd (termTo) {
        if (typeof termTo === 'object') {
          termTo = this.formatDate(termTo)
        }
        if (termTo === null || termTo === '') {
          return false
        }
        termTo = termTo.split('/')
        termTo = termTo.join('')
        if (Number(termTo) < this.today) {
          return true
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .end-select {
    background-color: #d5d5d5;
  }
</style>
