<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <RoleAllocationTable
              v-model="detail.allocation"
              item-name="allocation"
              classes="form-control"
              :item-label="labels.allocation"
              :disabled="isReadOnly"
              :labels="labels"
              :groups="groups"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import EmployeeRoleAllocationDetailMixin from '@/components/Dashboard/Views/Generated/EmployeeRoleAllocationDetailMixin'

  export default {
    name: 'EmployeeRoleAllocationDetail',
    data () {
      return {
        groups: []
      }
    },
    methods: {
      afterInit (res) {
        const groups = []
        for (const row of res.api_area.group_data) {
          groups.push({
            group_code: row.group_code,
            group_name: row.group_name,
            all_selected: false
          })
        }
        this.groups = groups
        const allocation = []
        for (const role of res.common_area.combo_data.role_list) {
          const row = {
            role_code: role.value,
            role_name: role.label
          }
          for (const group of res.api_area.group_data) {
            for (const roleRow of group.role_data) {
              if (role.value === roleRow.value) {
                row[group.group_code] = {
                  selected: roleRow.is_select
                }
                break
              }
            }
          }
          allocation.push(row)
        }
        this.detail.allocation = allocation
        this.componentKey += 1
      },
      convertDetail (detail) {
        const apiArea = {}
        apiArea.group_list = []
        for (const groupRow of this.groups) {
          const group = {}
          group.group_code = groupRow.group_code
          group.employee_code = this.params.employee_code
          group.role_list = []
          for (const row of detail.allocation) {
            const role = {
              role_code: row.role_code,
              allocation_flag: row[groupRow.group_code].selected
            }
            group.role_list.push(role)
          }
          apiArea.group_list.push(group)
        }
        return apiArea
      }
    },
    mixins: [EmployeeRoleAllocationDetailMixin]
  }
</script>
<style scoped>
</style>
