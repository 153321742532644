<template>
  <div class="form-group">
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div :key="componentKey">
        <div style="margin-bottom: 20px;">
          <input type="file" ref="file_upload" style="display: none;" @change="onFileChange"/>
          <div class="drop_area" :style="dropAreaStyle" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent @drop.prevent="dropFile" :class="{enter: isEnter}" type="button">
            <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" @click="$refs.file_upload.click()">{{$t('button.select_file')}}</button>
          </div>
        </div>
        <div style="margin-bottom: 5px;" v-for="(srcPath, index) in srcPaths" v-bind:key="srcPath">
          <a :href="srcPath" target="_blank">
            {{ itemValue[index].substring(itemValue[index].lastIndexOf('/') + 1).replace(itemValue[index].substring(itemValue[index].lastIndexOf('_')), '') + itemValue[index].substring(itemValue[index].lastIndexOf('.')) }}
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" @click="deleteImage(index)">{{$t('button.delete')}}</button>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      rules: String,
      itemLabel: String,
      disabled: Boolean,
      size: {
        type: Number,
        require: false,
        'default': 100 * 1024 * 1024
      },
      width: {
        type: Number,
        require: false,
        'default': 480
      },
      height: {
        type: Number,
        require: false,
        'default': 100
      }
    },
    data () {
      return {
        isEnter: false,
        files: [],
        componentKey: 0,
        itemValue: null,
        srcPaths: []
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
        this.showImage()
      }
    },
    computed: {
      dropAreaStyle () {
        return `width: ${this.width}px; height: ${this.height}px;`
      }
    },
    methods: {
      dragEnter () {
        this.isEnter = true
      },
      dragLeave () {
        this.isEnter = false
      },
      dragOver () {
      },
      dropFile (event) {
        this.createImage(event.dataTransfer.files)
        this.isEnter = false
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createImage(files)
      },
      createImage (files) {
        let self = this
        // 生成する文字列の長さ
        var l = 16
        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'
        var cl = c.length
        this.itemValue = []
        const itemValues = []
        for (const file of files) {
          if (file.size > this.size) {
            swal({
              text: this.$t('message.file_size_over').replace('{size}', this.formatNumber(this.size / 1024)),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            })
            return
          }
        }
        for (const file of files) {
          var r = ''
          for (var i = 0; i < l; i++) {
            r += c[Math.floor(Math.random() * cl)]
          }
          const fileName = file.name.substring(0, file.name.lastIndexOf('.'))
          const extension = file.name.substring(file.name.lastIndexOf('.'))
          const itemValue = `tmp/${this.$store.state.loginUser.companyCode}/${fileName}_${r}${extension}`
          const httpsReference = this.$firebase.storage()
            .ref()
            .child(itemValue)
          httpsReference.put(file).then(function (snapShot) {
            itemValues.push(itemValue)
          }).catch(function (error) {
            console.log(error)
          })
        }
        this.itemValue = itemValues
        self.$emit('input', this.itemValue)
        self.componentKey += 1
      },
      deleteImage (index) {
        this.itemValue.splice(index, 1)
        this.$emit('input', this.itemValue)
        this.componentKey += 1
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      },
      showImage () {
        if (this.itemValue) {
          const self = this
          this.srcPaths = []
          for (const path of this.itemValue) {
            if (!path) continue
            this.$firebase.storage()
              .ref()
              .child(path)
              .getDownloadURL()
              .then(function (url) {
                console.log(url)
                if (!self.srcPaths.includes(url)) {
                  self.srcPaths.push(url)
                }
              })
          }
          self.componentKey += 1
        }
      }
    },
    mounted () {
      this.itemValue = this.value
      this.showImage()
    }
  }
</script>

<style scoped>
  .drop_area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 5px dashed #6d6d6d;
    background-color: #f3f3f3;
    color: var(--text-color-dark);
    border-radius: 20px;
    box-sizing: border-box;
    transition: background-color 160ms ease;
    font-weight:bold;
  }</style>