import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Salary/ReferencePaySlipDetail'
import Bank from '@/components/Dashboard/Views/Salary/BankTransferList'
import CashTransfer from '@/components/Dashboard/Views/Salary/CashTransferList'
import Journal from '@/components/Dashboard/Views/Salary/JournalList'
import SendMail from '@/components/Dashboard/Views/Salary/SendMail'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import EmployeesSearch from '@/components/UIComponents/Inputs/EmployeesSearch'
import vSelect from 'vue-select'
import BarChart from '@/components/UIComponents/Inputs/BarChart'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'ReferencePaySlipSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    Bank,
    CashTransfer,
    Journal,
    SendMail,
    ValidationSelect,
    PPagination,
    EmployeesSearch,
    vSelect,
    BarChart
  },
  data () {
    return {
      monthChartdata: {
        labels: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
        datasets: [
          {
            type: 'bar',
            label: '差引支給額',
            backgroundColor: 'rgba(33, 131, 128, 1.0)',
            order: 1,
            data: []
          },
          {
            type: 'bar',
            label: '控除額合計',
            backgroundColor: 'rgba(216, 17, 89, 1.0)',
            order: 2,
            data: []
          }
        ]
      },
      monthOptions: {
        responsive: false,
        title: {
          display: true,
          text: '月ごとの支給額と控除額',
          fontSize: 16
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            id: 'A',
            ticks: {
              callback: function (value, index, ticks) {
                return value.toLocaleString() + '千円'
              }
            },
            stacked: true
          }]
        }
      },
      yearChartdata: {
        labels: [],
        datasets: [
          {
            type: 'bar',
            label: '差引支給額',
            backgroundColor: 'rgba(33, 131, 128, 1.0)',
            order: 1,
            data: []
          },
          {
            type: 'bar',
            label: '控除額合計',
            backgroundColor: 'rgba(216, 17, 89, 1.0)',
            order: 2,
            data: []
          }
        ]
      },
      yearOptions: {
        responsive: false,
        title: {
          display: true,
          text: '年間の総支給と総控除',
          fontSize: 16
        },
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            id: 'A',
            ticks: {
              callback: function (value, index, ticks) {
                return value.toLocaleString() + '千円'
              }
            },
            stacked: true
          }]
        }
      },
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1600,
      modalWidthBank: 800,
      modalWidthCashTransfer: 400,
      modalWidthJournal: 1400,
      modalWidthSendMail: 1400,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        search_type: '3',
        office_code: '',
        team_code: '',
        employee_code: '',
        employee_name: '',
        employee_classification_code: '',
        group_code: '',
        retirement: true,
        hireYear: false,
        target_year: '',
        target_month: '',
        salary_closing_code: ''
      },
      showOperation: true,
      showLayoutCode: true,
      showLayoutName: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: '',
      tableWrapperStyle: '',
      roles: {
        preview: 0,
        update: 0,
        entry: 0,
        delete: 0,
        print: 0
      },
      fromYearMonth: '',
      toYearMonth: '',
      currentYearMonth: '',
      dispFlg: true,
      isCurrentMonth: false,
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      fromYear: '',
      fromMonth: '',
      toYear: '',
      toMonth: '',
      selectedYear: '2024',
      resultYears: [],
      graphData: []
    }
  },
  computed: {
    screenCode () {
      return '02-003'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    isCurrentMonthClicked () {
      if (!this.isCurrentMonth) {
        this.searchCond.from_year = this.searchCond.to_year = this.currentYear
        this.searchCond.from_month = this.searchCond.to_month = this.currentMonth
      }
    },
    synchronizeFrom () {
      let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
      let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
      let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
      // 給与機能モードの場合、当月より未来を選んでも有効になるように、以下の処理を無効化する
      if (!this.payslipMode) {
        if (from > thisMonth) {
          this.searchCond.from_year = this.currentYear
          this.searchCond.from_month = this.currentMonth
        }
        if (to > thisMonth) {
          this.searchCond.to_year = this.currentYear
          this.searchCond.to_month = this.currentMonth
        }
      }
      if (from > to) {
        this.searchCond.to_year = this.searchCond.from_year
        this.searchCond.to_month = this.searchCond.from_month
      }
      this.adjustFromTo()
      this.searchCond = {...this.searchCond}
    },
    synchronizeTo () {
      let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
      let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
      let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
      // 給与機能モードの場合、当月より未来を選んでも有効になるように、以下の処理を無効化する
      if (!this.payslipMode) {
        if (from > thisMonth) {
          this.searchCond.from_year = this.currentYear
          this.searchCond.from_month = this.currentMonth
        }
        if (to > thisMonth) {
          this.searchCond.to_year = this.currentYear
          this.searchCond.to_month = this.currentMonth
        }
      }
      if (from > to) {
        this.searchCond.from_year = this.searchCond.to_year
        this.searchCond.from_month = this.searchCond.to_month
      }
      this.adjustFromTo()
      this.searchCond = {...this.searchCond}
    },
    adjustFromTo () {
      let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
      let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
      let minMonth = parseInt(this.fromYear, 10) * 100 + parseInt(this.fromMonth, 10)
      if (from < minMonth) {
        this.searchCond.from_year = this.fromYear
        this.searchCond.from_month = this.fromMonth
      }
      if (to < minMonth) {
        this.searchCond.to_year = this.fromYear
        this.searchCond.to_month = this.fromMonth
      }
      let maxMonth = parseInt(this.toYear, 10) * 100 + parseInt(this.toMonth, 10)
      if (from > maxMonth) {
        this.searchCond.from_year = this.toYear
        this.searchCond.from_month = this.toMonth
      }
      if (to > maxMonth) {
        this.searchCond.to_year = this.toYear
        this.searchCond.to_month = this.toMonth
      }
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.comboData.t_employee_pay_slip_exist_list = [
        {label: '', value: '0'},
        {label: this.$t('label.already'), value: '1'}
      ]
      // this.comboData.salary_closing_list.unshift({label: '', value: ''})
      this.roles = res.common_area.button_role // 権限
      this.fromYear = this.fromYearMonth.split('/')[0]
      this.fromMonth = String(parseInt(this.fromYearMonth.split('/')[1], 10))
      this.toYear = this.toYearMonth.split('/')[0]
      this.toMonth = String(parseInt(this.toYearMonth.split('/')[1], 10))
      this.currentYear = this.currentYearMonth.split('/')[0]
      this.currentMonth = String(parseInt(this.currentYearMonth.split('/')[1], 10))
      const from = this.addDateWith(`${this.currentYear}/${this.currentMonth}/01`, -11, 'months')
      this.searchCond.from_year = this.searchCond && this.searchCond.from_year ? this.searchCond.from_year : from.split('/')[0]
      this.searchCond.from_month = this.searchCond && this.searchCond.from_month ? this.searchCond.from_month : parseInt(from.split('/')[1], 10)
      this.searchCond.to_year = this.searchCond && this.searchCond.to_year ? this.searchCond.to_year : this.currentYear
      this.searchCond.to_month = this.searchCond && this.searchCond.to_month ? this.searchCond.to_month : this.currentMonth
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      // this.comboData.salary_closing_list.unshift({label: '', value: ''})
      this.roles = res.common_area.button_role // 権限
      // 給与承認リスト
      this.comboData.is_close_list = [
        {label: this.$t('label.pay_slip_unapproved'), value: '1'},
        {label: this.$t('label.pay_slip_approved'), value: '2'}
      ]
      // グラフ
      this.graphData = res.api_area.graph_data
      this.resultYears = [...res.api_area.graph_data.map((row) => row.target_year)].reverse()
      this.monthChartdata = this.getMonthChartdata()
      this.yearChartdata = this.getYearChartdata()
    },
    selectedYearChanged () {
      this.monthChartdata = this.getMonthChartdata()
    },
    getMonthChartdata () {
      const chartdata = {}
      chartdata.labels = this.monthChartdata.labels
      chartdata.datasets = this.monthChartdata.datasets
      for (const dataset of chartdata.datasets) {
        dataset.data = []
      }
      for (const row of this.graphData) {
        console.log(this.selectedYear)
        if (row.target_year === this.selectedYear) {
          for (var i = 0; i < 12; i++) {
            var isExist = false
            for (const month of row.month_data_list) {
              if (parseInt(month.month, 10) === i + 1) {
                isExist = true
                chartdata.datasets[0].data.push(parseInt(month.deduction_amount / 1000, 10))
                chartdata.datasets[1].data.push(parseInt(month.total_deduction_amount / 1000, 10))
                break
              }
            }
            if (!isExist) {
              chartdata.datasets[0].data.push(0)
              chartdata.datasets[1].data.push(0)
            }
          }
          break
        }
      }
      return chartdata
    },
    getYearChartdata () {
      const chartdata = {}
      chartdata.labels = this.graphData.map((row) => row.target_year)
      chartdata.datasets = this.yearChartdata.datasets
      for (const dataset of chartdata.datasets) {
        dataset.data = []
      }
      for (const row of this.graphData) {
        chartdata.datasets[0].data.push(parseInt(row.deduction_amount / 1000, 10))
        chartdata.datasets[1].data.push(parseInt(row.total_deduction_amount / 1000, 10))
      }
      return chartdata
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      searchCond.term_from = searchCond.from_year + ('00' + parseInt(searchCond.from_month)).slice(-2)
      searchCond.term_to = searchCond.to_year + ('00' + parseInt(searchCond.to_month)).slice(-2)
      delete searchCond.from_year
      delete searchCond.from_month
      delete searchCond.to_year
      delete searchCond.to_month
      delete searchCond.hireYear
      return {
        term_from: searchCond.term_from,
        term_to: searchCond.term_to
      }
    },
    doUpdate () {},
    canPreview (row) {
      return this.roles.preview === 1 // && row.t_employee_pay_slip_exist === 1
    },
    canEntry (row) {
      return this.roles.entry === 1 // && row.t_employee_pay_slip_exist !== 1 && row.salary_is_close === 1
    },
    canUpdate (row) {
      return this.roles.update === 1 // && row.t_employee_pay_slip_exist === 1 && row.salary_is_close === 1
    },
    canDelete (row) {
      return this.roles.delete === 1 // && row.t_employee_pay_slip_exist === 1 && row.salary_is_close === 1
    },
    canPrint (row) {
      return this.roles.print === 1 // && row.t_employee_pay_slip_exist === 1
    },
    canAllPrint (salaryClosingDatas) {
      return false
    },
    executeOperation (execute) {
      return execute ? '' : 'visibility:hidden;'
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/reference_pay_slip/list', reqData)
      .then(res => {
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.salary_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          if (window.previousUrl === '/02/menu02Screen001/detail') {
            const scrollTop = parseInt(sessionStorage.getItem('orgScrollTop'), 10)
            this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop = scrollTop
          }
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/init/reference_pay_slip/list', data)
      .then(res => {
        this.fromYearMonth = res.common_area.combo_data.select_date_from
        this.toYearMonth = res.common_area.combo_data.select_date_to
        this.currentYearMonth = res.common_area.combo_data.select_date
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    doPrintAll () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/print/reference_pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    doPrint (index, row) {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      // data.api_area.search_type = 3
      // data.api_area.employee_code = row.employee_code
      // data.api_area.employee_name = row.employee_name
      data.api_area.term_from = row.target_date
      data.api_area.term_to = row.target_date
      this.send('/print/reference_pay_slip', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    },
    getRowData (detailData) {
      let result = []
      for (const row of detailData) {
        if (row.approverl_flg === 3 || row.approverl_flg === 8 || row.t_employee_pay_slip_exist === 1) {
          if (this.canPrint(row) || this.canPreview(row) || this.canUpdate(row) || this.canEntry(row) || this.canDelete(row)) {
            result.push(row)
          }
        }
      }
      return result
    },
    getDeleteRowData (detailData) {
      let result = []
      for (const row of detailData) {
        if (row.approverl_flg === 3 || row.approverl_flg === 8 || row.t_employee_pay_slip_exist === 1) {
          if (this.canDelete(row)) {
            result.push(row)
          }
        }
      }
      return result
    },
    getIndex (rowData, employeeCode) {
      let index = 0
      for (index = 0; index < rowData.length; index++) {
        if (rowData[index].employee_code === employeeCode) {
          return index
        }
      }
    },
    handleView (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.view
      // let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'referencePaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: detailData, // sendRowData,
            index: index // this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.view
        }
      })
    },
    handleEdit (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.edit
      let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'referencePaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.edit
        }
      })
    },
    handleDelete (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.delete
      let sendRowData = this.getDeleteRowData(detailData)
      this.$router.push({ name: 'referencePaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.delete
        }
      })
    },
    handleAdd (index, row, detailData) {
      sessionStorage.setItem('orgScrollTop', this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop)
      this.params = {}
      this.params.employee_code = row.employee_code
      this.params.employee_name = row.employee_name
      this.params.target_date = row.target_date
      this.mode = this.modes.add
      let sendRowData = this.getRowData(detailData)
      this.$router.push({ name: 'referencePaySlipDetail',
        params: {
          params: {
            employee_code: row.employee_code,
            employee_name: row.employee_name,
            target_date: row.target_date,
            rows: sendRowData,
            index: this.getIndex(sendRowData, row.employee_code)
          },
          mode: this.modes.add
        }
      })
    },
    showBank (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('bank')
    },
    showCashTransfer (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('cashTransfer')
    },
    showJournal (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('journal')
    },
    showSendMail (targetDate) {
      this.params = {}
      this.params.target_date = targetDate
      this.params.salary_bonus_classification = 1 // 1:給与
      this.params.salary_closing_code = this.searchCond.salary_closing_code
      this.mode = this.modes.view
      this.$modal.show('sendMail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    hideBank (...args) {
      this.$modal.hide('bank')
      if (args[0]) {
        this.search()
      }
    },
    hideCashTransfer (...args) {
      this.$modal.hide('cashTransfer')
      if (args[0]) {
        this.search()
      }
    },
    hideJournal (...args) {
      this.$modal.hide('journal')
      if (args[0]) {
        this.search()
      }
    },
    hideSendMail (...args) {
      this.$modal.hide('sendMail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    }
  },
  mounted () {
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
    if (window.previousUrl === '/02/menu02Screen003/detail') {
      const orgSearchCond = localStorage.getItem('orgSearchCond')
      this.searchCond = JSON.parse(orgSearchCond)
      // this.$refs.employeesSearch.searchCond = this.searchCond
      this.search()
    }
    this.init()
  }
}
