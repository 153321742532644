<template>
  <ValidationObserver ref="observer">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottom: 5px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="row" style="overflow-y: auto; height: 537px;" name="companyFrame">
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 雇用保険 -->
              <ValidationRadio
                rules="required"
                v-model="employment_insurance"
                :item-label="labels.employment_insurance"
                :disabled="isReadOnly||company_employment_insurance===2"
                data-v-step="300"
              />
              <template v-if="employment_insurance_flg">
                <!-- 雇用年金取得日 -->
                <ValidationDate
                  v-model="employment_insurance_date"
                  item-name="employment_insurance_date"
                  rules="required"
                  :itemLabel="labels.employment_insurance_date"
                  :placeholder="$t('placeholder.employment_insurance_date')"
                  :disabled="isReadOnly"
                  data-v-step="301"
                />
                <!-- 雇用保険番号 -->
                <ValidationText
                  v-model="employment_insurance_number"
                  item-name="employment_insurance_number"
                  max-length="13"
                  classes="form-control length13"
                  :item-label="labels.employment_insurance_number"
                  :placeholder="$t('placeholder.employment_insurance_number')"
                  :help-text="$t('label.max_length')"
                  :disabled="isReadOnly"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour300" :steps="steps"/>
  </ValidationObserver>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        steps: [],
        stepsBk: [
          {
            target: '[data-v-step="300"]',
            content: '雇用保険に加入する場合「加入」を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="301"]',
            content: '雇用保険の加入資格を得た日を入力してください。',
            params: {placement: 'bottom-start'}
          }
        ],
        labels: {},
        employment_insurance: {},
        employment_insurance_date: '',
        employment_insurance_number: '',
        employment_insurance_flg: false,
        company_employment_insurance: 0,
        mode: 0
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      replay () {
        if (this.employment_insurance.value === '1') {
          this.steps = this.stepsBk
        } else {
          this.steps = [this.stepsBk[0]]
        }
        this.$nextTick(() => {
          this.$tours['myTour300'].start()
        })
      },
      init () {
      },
      allClear () {
        this.employment_insurance.value = '2'
        this.employment_insurance_flg = false
        this.employment_insurance_date = ''
        this.employment_insurance_number = ''
      },
      setDetails (res, mode) {
        if (mode !== this.modes.add) {
          this.employment_insurance.value = String(res.api_area.basic.employment_insurance)
          this.employment_insurance_date = res.api_area.basic.employment_insurance_date
          this.employment_insurance_number = res.api_area.basic.employment_insurance_number
        } else if (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '') {
          this.employment_insurance.value = String(res.api_area.basic.employment_insurance)
          this.employment_insurance_date = res.api_area.basic.employment_insurance_date
        }
        this.company_employment_insurance = res.api_area.company_employment_insurance
        if (this.company_employment_insurance === 2) {
          this.employment_insurance.value = '2'
        }
      }
    },
    watch: {
      'employment_insurance.value': function () {
        if (this.employment_insurance.value === '1') {
          // 雇用保険保険が加入の場合
          // 雇用年金取得日、雇用保険番号を表示する
          this.employment_insurance_flg = true
        } else {
          // 社会保険が未加入の場合
          // 雇用年金取得日、雇用保険番号をブランクにし、非表示にする
          this.employment_insurance_flg = false
          this.employment_insurance_date = ''
          this.employment_insurance_number = ''
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
