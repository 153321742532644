<template>
  <ValidationObserver ref="observer">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-bottom: 5px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="row" style="overflow-y: auto; height: 537px;" name="companyFrame">
      <div class="col-md-12">
        <modal name="bank" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="800">
          <div class="modal-body">
            <BankSearch @close="hideBank"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <modal name="SalaryClosingSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
          <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="salaryClosingSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
            <SalaryClosingSearch :mode="1" :params="params"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 給与締日 -->
              <ValidationSelect
                v-model="salary_closing_code"
                :item-label="$t('label.salary_closing')"
                :options="salary_closing_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly"
                :addButtonFlg="true"
                tourSteps="400,401"
                @showAdd="salaryClosingSearchShowAdd"
              />
              <div>
                <div style="display: inline-block;">
                  <!-- 給与区分 -->
                  <ValidationSelect
                    v-model="salary_category"
                    :item-label="labels.salary_category"
                    :options="salary_category_list"
                    classes="length20"
                    rules="required"
                    :disabled="isReadOnly"
                    data-v-step="402"
                  />
                </div>
                &nbsp;&nbsp;
                <div style="display: inline-block;">
                  <!-- 単価 -->
                  <ValidationText
                    v-model="unit_price"
                    item-name="unit_price"
                    max-length="9"
                    classes="form-control numLength9 number"
                    :item-label="getUnitPriceLabel(labels.unit_price)"
                    rules="min_value: 0|max_value: 999999999"
                    :disabled="true"
                    :tooltip-text="$t('label.unit_price_description')"
                  />
                </div>
              </div>
              <!-- レイアウト -->
              <ValidationSelect
                v-model="layout"
                :item-label="labels.layout"
                :options="layout_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly"
                :addButtonFlg="true"
                data-v-step="403"
                @showAdd="$emit('paySlipLayoutSearchShowAdd')"
              />
              <!-- 支給 -->
              <label for="planId" data-v-step="404">
                {{$t('label.payment')}}
              </label>
              <div class="error-message-wrapper" v-for="errorMsg in salary_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="salaryOneRow" style="width: 600">
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name1)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation1===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation1===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name1)"
                        v-model="props.row.payment_salary_item_code1_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name2)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation2===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation2===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name2)"
                        v-model="props.row.payment_salary_item_code2_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name3)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation3===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation3===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name3)"
                        v-model="props.row.payment_salary_item_code3_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name4)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation4===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation4===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name4)"
                        v-model="props.row.payment_salary_item_code4_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name5)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation5===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation5===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name5)"
                        v-model="props.row.payment_salary_item_code5_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name6)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation6===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation6===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name6)"
                        v-model="props.row.payment_salary_item_code6_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name7)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation7===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation7===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name7)"
                        v-model="props.row.payment_salary_item_code7_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name8)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation8===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation8===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name8)"
                        v-model="props.row.payment_salary_item_code8_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name9)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation9===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation9===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name9)"
                        v-model="props.row.payment_salary_item_code9_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryOneRow[0].payment_salary_item_name10)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation10===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation10===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryOneRow[0].payment_salary_item_name10)"
                        v-model="props.row.payment_salary_item_code10_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="salaryTwoRow" style="width: 600">
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name11)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation11===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation11===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name11)"
                        v-model="props.row.payment_salary_item_code11_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name12)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation12===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation12===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name12)"
                        v-model="props.row.payment_salary_item_code12_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name13)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation13===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation13===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name13)"
                        v-model="props.row.payment_salary_item_code13_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name14)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation14===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation14===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name14)"
                        v-model="props.row.payment_salary_item_code14_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name15)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation15===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation15===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name15)"
                        v-model="props.row.payment_salary_item_code15_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name16)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation16===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation16===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name16)"
                        v-model="props.row.payment_salary_item_code16_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name17)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation17===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation17===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name17)"
                        v-model="props.row.payment_salary_item_code17_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name18)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation18===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation18===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name18)"
                        v-model="props.row.payment_salary_item_code18_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name19)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation19===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation19===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name19)"
                        v-model="props.row.payment_salary_item_code19_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryTwoRow[0].payment_salary_item_name20)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation20===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation20===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryTwoRow[0].payment_salary_item_name20)"
                        v-model="props.row.payment_salary_item_code20_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="salaryThreeRow" style="width: 600">
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name21)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation21===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation21===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name21)"
                        v-model="props.row.payment_salary_item_code21_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name22)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation22===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation22===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name22)"
                        v-model="props.row.payment_salary_item_code22_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name23)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation23===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation23===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name23)"
                        v-model="props.row.payment_salary_item_code13_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name24)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation24===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation24===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name24)"
                        v-model="props.row.payment_salary_item_code24_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name25)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation25===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation25===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name25)"
                        v-model="props.row.payment_salary_item_code25_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name26)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation26===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation26===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name26)"
                        v-model="props.row.payment_salary_item_code26_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name27)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation27===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation27===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name27)"
                        v-model="props.row.payment_salary_item_code27_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name28)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation28===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation28===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name28)"
                        v-model="props.row.payment_salary_item_code28_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name29)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation29===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation29===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name29)"
                        v-model="props.row.payment_salary_item_code29_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(salaryThreeRow[0].payment_salary_item_name30)">
                  <template slot-scope="props">
                    <template v-if="props.row.payment_fixed_fluctuation30===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.payment_fixed_fluctuation30===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(salaryThreeRow[0].payment_salary_item_name30)"
                        v-model="props.row.payment_salary_item_code30_amount"
                        :disabled="isReadOnly"
                        @blur="changePaymentAmount"
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 控除 -->
              <label for="planId">
                {{$t('label.deduction')}}
              </label>
              <div class="error-message-wrapper" v-for="errorMsg in deduction_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="deductionOneRow" style="width: 600">
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name1)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation1===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation1===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name1)"
                        v-model="props.row.deduction_salary_item_code1_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name2)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation2===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation2===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name2)"
                        v-model="props.row.deduction_salary_item_code2_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name3)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation3===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation3===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name3)"
                        v-model="props.row.deduction_salary_item_code3_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name4)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation4===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation4===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name4)"
                        v-model="props.row.deduction_salary_item_code4_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name5)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation5===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation5===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name5)"
                        v-model="props.row.deduction_salary_item_code5_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name6)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation6===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation6===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name6)"
                        v-model="props.row.deduction_salary_item_code6_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name7)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation7===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation7===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name7)"
                        v-model="props.row.deduction_salary_item_code7_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name8)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation8===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation8===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name8)"
                        v-model="props.row.deduction_salary_item_code8_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name9)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation9===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation9===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name9)"
                        v-model="props.row.deduction_salary_item_code9_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionOneRow[0].deduction_salary_item_name10)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation10===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation10===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionOneRow[0].deduction_salary_item_name10)"
                        v-model="props.row.deduction_salary_item_code10_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="deductionTwoRow" style="width: 600">
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name11)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation11===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation11===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name11)"
                        v-model="props.row.deduction_salary_item_code11_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name12)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation12===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation12===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name12)"
                        v-model="props.row.deduction_salary_item_code12_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name13)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation13===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation13===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name13)"
                        v-model="props.row.deduction_salary_item_code13_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name14)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation14===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation14===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name14)"
                        v-model="props.row.deduction_salary_item_code14_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name15)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation15===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation15===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name15)"
                        v-model="props.row.deduction_salary_item_code15_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name16)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation16===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation16===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name16)"
                        v-model="props.row.deduction_salary_item_code16_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name17)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation17===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation17===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name17)"
                        v-model="props.row.deduction_salary_item_code17_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name18)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation18===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation18===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name18)"
                        v-model="props.row.deduction_salary_item_code18_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name19)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation19===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation19===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name19)"
                        v-model="props.row.deduction_salary_item_code19_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
                <el-table-column min-width="100" :label="getItemName(deductionTwoRow[0].deduction_salary_item_name20)">
                  <template slot-scope="props">
                    <template v-if="props.row.deduction_fixed_fluctuation20===2">
                      {{labels.fixedFluctuation_FLUCTUATION}}
                    </template>
                    <template v-else-if="props.row.deduction_fixed_fluctuation20===3">
                      {{labels.fixedFluctuation_SYSTEM}}
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        :placeholder="$t('placeholder.amount')"
                        class="form-control width100"
                        :style="getStyle(deductionTwoRow[0].deduction_salary_item_name20)"
                        v-model="props.row.deduction_salary_item_code20_amount"
                        :disabled="isReadOnly"
                      >
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              <br/>
              <!-- 使用されている給与項目 -->
              <label for="planId">
                {{'使用されている給与項目'}}
              </label>
              <div class="error-message-wrapper" v-for="errorMsg in use_payment_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="use_payment"
                style="width: 550px">
                <!-- 給与項目 -->
                <el-table-column min-width="200" :label="$t('label.item_name')">
                  <template slot-scope="props">
                    {{props.row.salary_item_name}}
                  </template>
                </el-table-column>
                <!-- 金額 -->
                <el-table-column min-width="200" :label="$t('label.amount_of_money')">
                  <template slot-scope="props">
                    {{props.row.basic_price}}
                  </template>
                </el-table-column>
                <!-- 労働時間の範囲 -->
                <el-table-column min-width="150" :label="labels.working_time_ryaku ? labels.working_time_ryaku : ''">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        v-model="props.row.working_start_time">
                      <label>～</label>
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        v-model="props.row.working_end_time">
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <!-- レイアウト外の給与項目 -->
              <label for="planId">
                {{'レイアウト外の給与項目'}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addOtherPaymentRow">{{$t('button.add_row')}}</button>
                <!-- <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" @click="jobTitleSearchShowAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button> -->
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in other_payment_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="other_payment"
                style="width: 610px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- 給与項目 -->
                <el-table-column min-width="200" :label="$t('label.item_name')">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="job_title_name"
                                :disabled="isReadOnly"
                                :key="componentKey"
                                v-model="props.row.salary_item_code">
                      <el-option v-for="option in disp_salary_item_list_exclusion_fluctuation_payment"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 金額 -->
                <el-table-column min-width="200" :label="$t('label.amount_of_money')">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.basic_price"
                      maxlength="10"
                      class="form-control length10"
                      style="display:inline"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
                <!-- 労働時間の範囲 -->
                <el-table-column min-width="150" :label="labels.working_time_ryaku ? labels.working_time_ryaku : ''">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        v-model="props.row.working_start_time">
                      <label>～</label>
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        v-model="props.row.working_end_time">
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <!-- レイアウト外の控除項目 -->
              <label for="planId">
                {{'レイアウト外の控除項目'}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addOtherDeductionRow">{{$t('button.add_row')}}</button>
                <!-- <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" @click="jobTitleSearchShowAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button> -->
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in other_deduction_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="other_deduction"
                style="width: 460px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- 給与項目 -->
                <el-table-column min-width="200" :label="$t('label.item_name')">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="job_title_name"
                                :disabled="isReadOnly"
                                :key="componentKey"
                                v-model="props.row.salary_item_code">
                      <el-option v-for="option in disp_salary_item_list_exclusion_fluctuation_deduction"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 金額 -->
                <el-table-column min-width="200" :label="$t('label.amount_of_money')">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.basic_price"
                      maxlength="10"
                      class="form-control length10"
                      style="display:inline"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br/>
              <!-- 通勤費 -->
              <label for="planId" data-v-step="405">
                {{$t('label.commuting_costs')}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addEmployeeCommuteRecord">{{$t('button.add_row')}}</button>
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in employee_commute_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border :data="employee_commute_record" style="width: 1600px">
                <!-- 削除 -->
                <el-table-column min-width="50" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                  </template>
                </el-table-column>
                <!-- 連番 -->
                <el-table-column min-width="50" :label="$t('label.serial_number')">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.serial_number}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 支給単位 -->
                <el-table-column min-width="200" :label="labels.payment_unit_ryaku ? labels.payment_unit_ryaku : ''">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="team"
                                :disabled="isReadOnly"
                                @change="changePaymentUnit(props.$index)"
                                v-model="props.row.payment_unit">
                      <el-option v-for="option in payment_unit_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 支給月度 -->
                <el-table-column min-width="200" :label="$t('label.commute_target_date')">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="team"
                                :disabled="isReadOnly||props.row.payment_unit===''||props.row.payment_unit==='1'||props.row.payment_unit==='9'"
                                v-model="props.row.target_date">
                      <el-option v-for="option in props.row.edit_target_date_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 交通区分 -->
                <el-table-column min-width="300" :label="labels.traffic_division_ryaku ? labels.traffic_division_ryaku : ''">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="team"
                                :disabled="isReadOnly"
                                @change="changeTrafficDivision(props.$index)"
                                v-model="props.row.traffic_division">
                      <el-option v-for="option in traffic_division_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 交通機関名 -->
                <el-table-column min-width="300" :label="labels.transportation_name_ryaku ? labels.transportation_name_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.transportation_name"
                      maxlength="40"
                      class="form-control length40"
                      style="display:inline"
                      :placeholder="$t('placeholder.transportation_name')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
                <!-- 開始区間 -->
                <el-table-column min-width="300" :label="labels.start_section_ryaku ? labels.start_section_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.start_section"
                      maxlength="32"
                      class="form-control length32"
                      style="display:inline"
                      :placeholder="$t('placeholder.start_section')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
                <!-- 終了区間 -->
                <el-table-column min-width="300" :label="labels.end_section_ryaku ? labels.end_section_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.end_section"
                      maxlength="32"
                      class="form-control length32"
                      style="display:inline"
                      :placeholder="$t('placeholder.end_section')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
                <!-- 交通用具を使用する距離 -->
                <el-table-column min-width="300" :label="labels.distance_to_use_transportation_equipment_ryaku ? labels.distance_to_use_transportation_equipment_ryaku : ''">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="team"
                                @change="distanceToUseTransportationEquipmentChanged(props.row)"
                                :disabled="isReadOnly||props.row.traffic_division!=='3'"
                                v-model="props.row.distance_to_use_transportation_equipment">
                      <el-option v-for="option in distance_to_use_transportation_equipment_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 支給額 -->
                <el-table-column min-width="150" :label="labels.payment_amount_ryaku ? labels.payment_amount_ryaku : ''">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.payment_amount"
                      maxlength="6"
                      class="form-control length6"
                      style="display:inline"
                      :placeholder="$t('placeholder.amount')"
                      :disabled="isReadOnly"
                    >
                  </template>
                </el-table-column>
              </el-table>
              <br/>
              <!-- 税額区分 -->
              <ValidationSelect
                v-model="tax_amount_classification"
                :item-label="labels.tax_amount_classification"
                :options="tax_amount_classification_list"
                classes="length16"
                rules="required"
                :disabled="isReadOnly"
                data-v-step="406"
              />
              <!-- 厚生年金基金加入有無 -->
              <ValidationRadio
                rules="required"
                v-model="pension_fund_contributions_subscription"
                :item-label="$t('label.pension_fund_contributions_subscription')"
                :disabled="isReadOnly||company_pension_fund_contributions===2"
                data-v-step="407"
              />
              <!-- 厚生年金基金取得日 -->
              <template v-if="pension_fund_contributions_subscription.value!=='2'">
                <ValidationDate
                  rules="required"
                  v-model="pension_fund_contributions_date"
                  item-name="pension_fund_contributions_date"
                  :itemLabel="labels.pension_fund_contributions_date"
                  :placeholder="$t('placeholder.pension_fund_contributions_date')"
                  :disabled="isReadOnly"
                  data-v-step="408"
                />
              </template>
              <!-- 報酬月額（ラベル） -->
              <label for="planId" data-v-step="409">
                {{labels.monthl_reward}}
              </label>
              <!-- 計算ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="exeCalculation">{{$t('button.calculation')}}</button>
              </template>
              <br/>
              <input 
                type="text" 
                name="monthl_reward"
                style="display: inline-block;"
                class="form-control length5"
                v-model="monthl_reward" 
                maxlength=10
                :disabled="isReadOnly">
              {{$t('label.yen')}}
              <br/>
              <br/>
              <!-- 報酬月額の最終更新日 -->
              <label for="planId">
                {{labels.last_update_date_of_monthly_reward}}
              </label>
              <br/>
                {{last_update_date_of_monthly_reward}}
              <br/>
              <br/>
              <!-- 厚生年金保険料 -->
              <label for="planId">
                {{labels.welfare_pension_insurance_premium}}
              </label>
              <br/>
                {{getAmountOfMoney(welfare_pension_insurance_premium)}}
              <br/>
              <br/>
              <!-- 健康保険保険料　介護保険料含まない -->
              <label for="planId">
                {{labels.health_insurance_premium_non_include_nursing}}
              </label>
              <br/>
                {{getAmountOfMoney(health_insurance_premium_non_include_nursing)}}
              <br/>
              <br/>
              <!-- 健康保険保険料　介護保険料含む -->
              <label for="planId">
                {{labels.health_insurance_premium_include_nursing}}
              </label>
              <br/>
                {{getAmountOfMoney(health_insurance_premium_include_nursing)}}
              <br/>
              <br/>
              <!-- 健康保険保険料　一般 -->
              <label for="planId">
                {{labels.health_insurance_premium_general}}
              </label>
              <br/>
                {{getAmountOfMoney(health_insurance_premium_general)}}
              <br/>
              <br/>
              <!-- 健康保険保険料　調整 -->
              <label for="planId">
                {{labels.health_insurance_premium_adjustment}}
              </label>
              <br/>
                {{getAmountOfMoney(health_insurance_premium_adjustment)}}
              <br/>
              <br/>
              <!-- 介護保険　保険料 -->
              <label for="planId">
                {{labels.long_term_care_insurance_premium}}
              </label>
              <br/>
                {{getAmountOfMoney(long_term_care_insurance_premium)}}
              <br/>
              <br/>
              <!-- 厚生年金基金保険料 -->
              <label for="planId">
                {{labels.employees_pension_fund_premium}}
              </label>
              <br/>
                {{getAmountOfMoney(employees_pension_fund_premium)}}
              <br/>
              <br/>
              <!-- 振込先情報 -->
              <label for="planId" data-v-step="410">
                {{$t('label.transfer_information')}}
              </label>
              <div class="error-message-wrapper" v-for="errorMsg in transfer_information_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="transfer_information"
                style="width: 1150px">
                <!-- 振込先 -->
                <el-table-column min-width="25" :label="$t('label.transfer')">
                  <template slot-scope="props">
                    {{props.row.count}}
                  </template>
                </el-table-column>
                <el-table-column min-width="25" label="">
                  <template slot-scope="props">
                    <button type="button" :disabled="isReadOnly" class="btn btn-sm btn-default btn-warning" @click="showBank(props.row.row_number)"><i class="ti-search"></i></button>
                    <template v-if="props.row.bank_code!=''">
                      <a class="btn btn-simple btn-icon btn-danger remove"  @click="bankDelete(props.$index, props.row)"><i class="ti-close" :title="labels.button_delete"></i></a>
                    </template>
                  </template>
                </el-table-column>
                <!-- 銀行 -->
                <el-table-column min-width="50" :label="$t('label.bank')">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.bank_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.disp_bank}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 支店 -->
                <el-table-column min-width="50" :label="$t('label.branch')">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.branch_code}}</small>
                    </div>
                    <div style="text-align: left;">
                      {{props.row.disp_branch}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 口座種別 -->
                <el-table-column min-width="75" :label="$t('label.account_classification')">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="team"
                                :disabled="isReadOnly||isNotTransfer(props.row.bank_code)"
                                v-model="props.row.account_classification">
                      <el-option v-for="option in account_classification_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <!-- 口座番号 -->
                <el-table-column min-width="45" :label="$t('label.account_number')">
                  <template slot-scope="props">
                    <input
                      type="text"
                      v-model="props.row.account_number"
                      maxlength="7"
                      class="form-control numLength7"
                      style="display:inline"
                      :placeholder="$t('placeholder.account_number')"
                      :disabled="isReadOnly||isNotTransfer(props.row.bank_code)"
                    >
                  </template>
                </el-table-column>
                <el-table-column min-width="60" :label="''">
                  <template slot-scope="props">
                    <div v-if="props.$index === 0">
                      <div class="form-group" style="top: 18px;">
                        <p-radio v-model="selectAmount" label="1" :inline="true" :disabled="isReadOnly||isNotTransfer(props.row.bank_code)">{{'全額'}}</p-radio>
                        <p-radio v-model="selectAmount" label="2" :inline="true" :disabled="isReadOnly||isNotTransfer(props.row.bank_code)">{{'指定'}}</p-radio>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- 振込金額 -->
                <el-table-column min-width="50" :label="$t('label.transfer_amount_of_money')">
                  <template slot-scope="props">
                    <div v-if="props.$index === 0">
                      <input
                        type="text"
                        v-model="props.row.transfer_amount_of_money"
                        maxlength="7"
                        class="form-control numLength7 number"
                        style="display:inline"
                        :placeholder="$t('placeholder.transfer_amount_of_money')"
                        :disabled="isReadOnly||props.row.bank_code===''||selectAmount==='1'"
                      >
                    </div>
                    <div v-else style="text-align: center;">
                      {{$t('label.balance')}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour400" :steps="steps"/>
  </ValidationObserver>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import BankSearch from '@/components/Dashboard/Views/Components/BankSearch'
  import SalaryClosingSearch from '@/components/Dashboard/Views/Generated/SalaryClosingSearch.vue'
  import PaySlipLayoutSearch from '@/components/Dashboard/Views/Master/PaySlipLayoutSearch.vue'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      BankSearch,
      SalaryClosingSearch,
      PaySlipLayoutSearch,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        steps: [
          {
            target: '[data-v-step="400"]',
            content: '給与の支払日を選択してください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="401"]',
            content: '新しく給与締日を登録することもできます。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="402"]',
            content: '従業員の給与形態を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="403"]',
            content: '給与明細のレイアウトを選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="404"]',
            content: '固定項目がある場合、額を入力できます。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="405"]',
            content: '通勤費(固定額)を支給する場合入力してください。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="406"]',
            content: '所得税の控除額の区分です。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="407"]',
            content: '厚生年金基金の加入有無を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="409"]',
            content: '社会保険に加入している場合、入力して「計算」をクリックしてください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="410"]',
            content: '給与の支払い情報を入力してください。',
            params: {placement: 'top'}
          }
        ],
        labels: {},
        salary_closing_code: '',
        salary_closing_list: [],
        layout: '',
        layout_list: [],
        layout_list_all: [],
        tableData: [
          {}
        ],
        totalBasicSalary: 0,
        salaryOneRow: [
          {
            payment_salary_item_name1: '',
            payment_salary_item_code1: '',
            payment_salary_item_code1_amount: '0',
            payment_fixed_fluctuation1: 0,
            payment_salary_item_name2: '',
            payment_salary_item_code2: '',
            payment_salary_item_code2_amount: '0',
            payment_fixed_fluctuation2: 0,
            payment_salary_item_name3: '',
            payment_salary_item_code3: '',
            payment_salary_item_code3_amount: '0',
            payment_fixed_fluctuation3: 0,
            payment_salary_item_name4: '',
            payment_salary_item_code4: '',
            payment_salary_item_code4_amount: '0',
            payment_fixed_fluctuation4: 0,
            payment_salary_item_name5: '',
            payment_salary_item_code5: '',
            payment_salary_item_code5_amount: '0',
            payment_fixed_fluctuation5: 0,
            payment_salary_item_name6: '',
            payment_salary_item_code6: '',
            payment_salary_item_code6_amount: '0',
            payment_fixed_fluctuation6: 0,
            payment_salary_item_name7: '',
            payment_salary_item_code7: '',
            payment_salary_item_code7_amount: '0',
            payment_fixed_fluctuation7: 0,
            payment_salary_item_name8: '',
            payment_salary_item_code8: '',
            payment_salary_item_code8_amount: '0',
            payment_fixed_fluctuation8: 0,
            payment_salary_item_name9: '',
            payment_salary_item_code9: '',
            payment_salary_item_code9_amount: '0',
            payment_fixed_fluctuation9: 0,
            payment_salary_item_name10: '',
            payment_salary_item_code10: '',
            payment_salary_item_code10_amount: '0',
            payment_fixed_fluctuation10: 0
          }
        ],
        salaryTwoRow: [
          {
            payment_salary_item_name11: '',
            payment_salary_item_code11: '',
            payment_salary_item_code11_amount: '0',
            payment_fixed_fluctuation11: 0,
            payment_salary_item_name12: '',
            payment_salary_item_code12: '',
            payment_salary_item_code12_amount: '0',
            payment_fixed_fluctuation12: 0,
            payment_salary_item_name13: '',
            payment_salary_item_code13: '',
            payment_salary_item_code13_amount: '0',
            payment_fixed_fluctuation13: 0,
            payment_salary_item_name14: '',
            payment_salary_item_code14: '',
            payment_salary_item_code14_amount: '0',
            payment_fixed_fluctuation14: 0,
            payment_salary_item_name15: '',
            payment_salary_item_code15: '',
            payment_salary_item_code15_amount: '0',
            payment_fixed_fluctuation15: 0,
            payment_salary_item_name16: '',
            payment_salary_item_code16: '',
            payment_salary_item_code16_amount: '0',
            payment_fixed_fluctuation16: 0,
            payment_salary_item_name17: '',
            payment_salary_item_code17: '',
            payment_salary_item_code17_amount: '0',
            payment_fixed_fluctuation17: 0,
            payment_salary_item_name18: '',
            payment_salary_item_code18: '',
            payment_salary_item_code18_amount: '0',
            payment_fixed_fluctuation18: 0,
            payment_salary_item_name19: '',
            payment_salary_item_code19: '',
            payment_salary_item_code19_amount: '0',
            payment_fixed_fluctuation19: 0,
            payment_salary_item_name20: '',
            payment_salary_item_code20: '',
            payment_salary_item_code20_amount: '0',
            payment_fixed_fluctuation20: 0
          }
        ],
        salaryThreeRow: [
          {
            payment_salary_item_name21: '',
            payment_salary_item_code21: '',
            payment_salary_item_code21_amount: '0',
            payment_fixed_fluctuation21: 0,
            payment_salary_item_name22: '',
            payment_salary_item_code22: '',
            payment_salary_item_code22_amount: '0',
            payment_fixed_fluctuation22: 0,
            payment_salary_item_name23: '',
            payment_salary_item_code23: '',
            payment_salary_item_code23_amount: '0',
            payment_fixed_fluctuation23: 0,
            payment_salary_item_name24: '',
            payment_salary_item_code24: '',
            payment_salary_item_code24_amount: '0',
            payment_fixed_fluctuation24: 0,
            payment_salary_item_name25: '',
            payment_salary_item_code25: '',
            payment_salary_item_code25_amount: '0',
            payment_fixed_fluctuation25: 0,
            payment_salary_item_name26: '',
            payment_salary_item_code26: '',
            payment_salary_item_code26_amount: '0',
            payment_fixed_fluctuation26: 0,
            payment_salary_item_name27: '',
            payment_salary_item_code27: '',
            payment_salary_item_code27_amount: '0',
            payment_fixed_fluctuation27: 0,
            payment_salary_item_name28: '',
            payment_salary_item_code28: '',
            payment_salary_item_code28_amount: '0',
            payment_fixed_fluctuation28: 0,
            payment_salary_item_name29: '',
            payment_salary_item_code29: '',
            payment_salary_item_code29_amount: '0',
            payment_fixed_fluctuation29: 0,
            payment_salary_item_name30: '',
            payment_salary_item_code30: '',
            payment_salary_item_code30_amount: '0',
            payment_fixed_fluctuation30: 0
          }
        ],
        deductionOneRow: [
          {
            deduction_salary_item_name1: '',
            deduction_salary_item_code1: '',
            deduction_salary_item_code1_amount: '0',
            deduction_fixed_fluctuation1: 0,
            deduction_salary_item_name2: '',
            deduction_salary_item_code2: '',
            deduction_salary_item_code2_amount: '0',
            deduction_fixed_fluctuation2: 0,
            deduction_salary_item_name3: '',
            deduction_salary_item_code3: '',
            deduction_salary_item_code3_amount: '0',
            deduction_fixed_fluctuation3: 0,
            deduction_salary_item_name4: '',
            deduction_salary_item_code4: '',
            deduction_salary_item_code4_amount: '0',
            deduction_fixed_fluctuation4: 0,
            deduction_salary_item_name5: '',
            deduction_salary_item_code5: '',
            deduction_salary_item_code5_amount: '0',
            deduction_fixed_fluctuation5: 0,
            deduction_salary_item_name6: '',
            deduction_salary_item_code6: '',
            deduction_salary_item_code6_amount: '0',
            deduction_fixed_fluctuation6: 0,
            deduction_salary_item_name7: '',
            deduction_salary_item_code7: '',
            deduction_salary_item_code7_amount: '0',
            deduction_fixed_fluctuation7: 0,
            deduction_salary_item_name8: '',
            deduction_salary_item_code8: '',
            deduction_salary_item_code8_amount: '0',
            deduction_fixed_fluctuation8: 0,
            deduction_salary_item_name9: '',
            deduction_salary_item_code9: '',
            deduction_salary_item_code9_amount: '0',
            deduction_fixed_fluctuation9: 0,
            deduction_salary_item_name10: '',
            deduction_salary_item_code10: '',
            deduction_salary_item_code10_amount: '0',
            deduction_fixed_fluctuation10: 0
          }
        ],
        deductionTwoRow: [
          {
            deduction_salary_item_name11: '',
            deduction_salary_item_code11: '',
            deduction_salary_item_code11_amount: '0',
            deduction_fixed_fluctuation11: 0,
            deduction_salary_item_name12: '',
            deduction_salary_item_code12: '',
            deduction_salary_item_code12_amount: '0',
            deduction_fixed_fluctuation12: 0,
            deduction_salary_item_name13: '',
            deduction_salary_item_code13: '',
            deduction_salary_item_code13_amount: '0',
            deduction_fixed_fluctuation13: 0,
            deduction_salary_item_name14: '',
            deduction_salary_item_code14: '',
            deduction_salary_item_code14_amount: '0',
            deduction_fixed_fluctuation14: 0,
            deduction_salary_item_name15: '',
            deduction_salary_item_code15: '',
            deduction_salary_item_code15_amount: '0',
            deduction_fixed_fluctuation15: 0,
            deduction_salary_item_name16: '',
            deduction_salary_item_code16: '',
            deduction_salary_item_code16_amount: '0',
            deduction_fixed_fluctuation16: 0,
            deduction_salary_item_name17: '',
            deduction_salary_item_code17: '',
            deduction_salary_item_code17_amount: '0',
            deduction_fixed_fluctuation17: 0,
            deduction_salary_item_name18: '',
            deduction_salary_item_code18: '',
            deduction_salary_item_code18_amount: '0',
            deduction_fixed_fluctuation18: 0,
            deduction_salary_item_name19: '',
            deduction_salary_item_code19: '',
            deduction_salary_item_code19_amount: '0',
            deduction_fixed_fluctuation19: 0,
            deduction_salary_item_name20: '',
            deduction_salary_item_code20: '',
            deduction_salary_item_code20_amount: '0',
            deduction_fixed_fluctuation20: 0
          }
        ],
        salary_error: [],
        deduction_error: [],
        transfer_information: [
          {
            count: this.$t('label.first'),
            transfer_method: '',
            bank_code: '',
            disp_bank: '',
            branch_code: '',
            disp_branch: '',
            account_classification: '',
            account_number: '',
            transfer_amount_of_money: '',
            row_number: 0
          },
          {
            count: this.$t('label.second'),
            transfer_method: '',
            bank_code: '',
            disp_bank: '',
            branch_code: '',
            disp_branch: '',
            account_classification: '',
            account_number: '',
            transfer_amount_of_money: '',
            row_number: 1
          }
        ],
        transfer_information_error: [],
        transfer_method_list: [],
        account_classification_list: [],
        selectTransfer: 0,
        employee_commute_record: [],
        employee_commute_error: [],
        payment_unit_list: [],
        edit_target_date_list: [],
        target_date_list: [],
        distance_to_use_transportation_equipment_list: [],
        distance_to_use_transportation_equipment_mapping: {},
        traffic_division_list: [],
        tax_amount_classification: '',
        tax_amount_classification_list: [],
        pension_fund_contributions_subscription: {},
        last_update_date_of_monthly_reward: '0',
        welfare_pension_insurance_premium: '0',
        health_insurance_premium_non_include_nursing: '0',
        health_insurance_premium_include_nursing: '0',
        health_insurance_premium_general: '0',
        health_insurance_premium_adjustment: '0',
        long_term_care_insurance_premium: '0',
        employees_pension_fund_premium: '0',
        pension_fund_contributions_date: '',
        layout_inf_list: [],
        is_long_term_care_insurance_care_category: '',
        layout_change_flg: false,
        monthl_reward: '0',
        mode: 0,
        salary_category: '',
        salary_category_list: [],
        salary_category_layout_mapping_list: [],
        employee_payment_list_length: 0,
        salary_item_list_exclusion_fluctuation_payment: [],
        salary_item_list_exclusion_fluctuation_deduction: [],
        disp_salary_item_list_exclusion_fluctuation_payment: [],
        disp_salary_item_list_exclusion_fluctuation_deduction: [],
        use_payment: [],
        other_payment: [],
        other_deduction: [],
        use_payment_error: [],
        other_payment_error: [],
        other_deduction_error: [],
        payroll_management: '',
        employee_payment_list: [],
        company_pension_fund_contributions: 0,
        selectAmount: '',
        unit_price: ''
      }
    },
    computed: {
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      getUnitPriceLabel (defaultLabel) {
        let result = defaultLabel
        if (defaultLabel === undefined) {
          result = ''
        }
        let today = new Date()
        let year = today.getFullYear()
        result = result + this.$t('label.employee_unit_price').replace('{year}', year)
        return result
      },
      replay () {
        this.$tours['myTour400'].start()
      },
      init () {
      },
      salaryAndDeductionClear () {
        this.salaryOneRow = [
          {
            payment_salary_item_name1: '',
            payment_salary_item_code1: '',
            payment_salary_item_code1_amount: '0',
            payment_fixed_fluctuation1: 0,
            payment_salary_item_name2: '',
            payment_salary_item_code2: '',
            payment_salary_item_code2_amount: '0',
            payment_fixed_fluctuation2: 0,
            payment_salary_item_name3: '',
            payment_salary_item_code3: '',
            payment_salary_item_code3_amount: '0',
            payment_fixed_fluctuation3: 0,
            payment_salary_item_name4: '',
            payment_salary_item_code4: '',
            payment_salary_item_code4_amount: '0',
            payment_fixed_fluctuation4: 0,
            payment_salary_item_name5: '',
            payment_salary_item_code5: '',
            payment_salary_item_code5_amount: '0',
            payment_fixed_fluctuation5: 0,
            payment_salary_item_name6: '',
            payment_salary_item_code6: '',
            payment_salary_item_code6_amount: '0',
            payment_fixed_fluctuation6: 0,
            payment_salary_item_name7: '',
            payment_salary_item_code7: '',
            payment_salary_item_code7_amount: '0',
            payment_fixed_fluctuation7: 0,
            payment_salary_item_name8: '',
            payment_salary_item_code8: '',
            payment_salary_item_code8_amount: '0',
            payment_fixed_fluctuation8: 0,
            payment_salary_item_name9: '',
            payment_salary_item_code9: '',
            payment_salary_item_code9_amount: '0',
            payment_fixed_fluctuation9: 0,
            payment_salary_item_name10: '',
            payment_salary_item_code10: '',
            payment_salary_item_code10_amount: '0',
            payment_fixed_fluctuation10: 0
          }
        ]
        this.salaryTwoRow = [
          {
            payment_salary_item_name11: '',
            payment_salary_item_code11: '',
            payment_salary_item_code11_amount: '0',
            payment_fixed_fluctuation11: 0,
            payment_salary_item_name12: '',
            payment_salary_item_code12: '',
            payment_salary_item_code12_amount: '0',
            payment_fixed_fluctuation12: 0,
            payment_salary_item_name13: '',
            payment_salary_item_code13: '',
            payment_salary_item_code13_amount: '0',
            payment_fixed_fluctuation13: 0,
            payment_salary_item_name14: '',
            payment_salary_item_code14: '',
            payment_salary_item_code14_amount: '0',
            payment_fixed_fluctuation14: 0,
            payment_salary_item_name15: '',
            payment_salary_item_code15: '',
            payment_salary_item_code15_amount: '0',
            payment_fixed_fluctuation15: 0,
            payment_salary_item_name16: '',
            payment_salary_item_code16: '',
            payment_salary_item_code16_amount: '0',
            payment_fixed_fluctuation16: 0,
            payment_salary_item_name17: '',
            payment_salary_item_code17: '',
            payment_salary_item_code17_amount: '0',
            payment_fixed_fluctuation17: 0,
            payment_salary_item_name18: '',
            payment_salary_item_code18: '',
            payment_salary_item_code18_amount: '0',
            payment_fixed_fluctuation18: 0,
            payment_salary_item_name19: '',
            payment_salary_item_code19: '',
            payment_salary_item_code19_amount: '0',
            payment_fixed_fluctuation19: 0,
            payment_salary_item_name20: '',
            payment_salary_item_code20: '',
            payment_salary_item_code20_amount: '0',
            payment_fixed_fluctuation20: 0
          }
        ]
        this.salaryThreeRow = [
          {
            payment_salary_item_name21: '',
            payment_salary_item_code21: '',
            payment_salary_item_code21_amount: '0',
            payment_fixed_fluctuation21: 0,
            payment_salary_item_name22: '',
            payment_salary_item_code22: '',
            payment_salary_item_code22_amount: '0',
            payment_fixed_fluctuation22: 0,
            payment_salary_item_name23: '',
            payment_salary_item_code23: '',
            payment_salary_item_code23_amount: '0',
            payment_fixed_fluctuation23: 0,
            payment_salary_item_name24: '',
            payment_salary_item_code24: '',
            payment_salary_item_code24_amount: '0',
            payment_fixed_fluctuation24: 0,
            payment_salary_item_name25: '',
            payment_salary_item_code25: '',
            payment_salary_item_code25_amount: '0',
            payment_fixed_fluctuation25: 0,
            payment_salary_item_name26: '',
            payment_salary_item_code26: '',
            payment_salary_item_code26_amount: '0',
            payment_fixed_fluctuation26: 0,
            payment_salary_item_name27: '',
            payment_salary_item_code27: '',
            payment_salary_item_code27_amount: '0',
            payment_fixed_fluctuation27: 0,
            payment_salary_item_name28: '',
            payment_salary_item_code28: '',
            payment_salary_item_code28_amount: '0',
            payment_fixed_fluctuation28: 0,
            payment_salary_item_name29: '',
            payment_salary_item_code29: '',
            payment_salary_item_code29_amount: '0',
            payment_fixed_fluctuation29: 0,
            payment_salary_item_name30: '',
            payment_salary_item_code30: '',
            payment_salary_item_code30_amount: '0',
            payment_fixed_fluctuation30: 0
          }
        ]
        this.deductionOneRow = [
          {
            deduction_salary_item_name1: '',
            deduction_salary_item_code1: '',
            deduction_salary_item_code1_amount: '0',
            deduction_fixed_fluctuation1: 0,
            deduction_salary_item_name2: '',
            deduction_salary_item_code2: '',
            deduction_salary_item_code2_amount: '0',
            deduction_fixed_fluctuation2: 0,
            deduction_salary_item_name3: '',
            deduction_salary_item_code3: '',
            deduction_salary_item_code3_amount: '0',
            deduction_fixed_fluctuation3: 0,
            deduction_salary_item_name4: '',
            deduction_salary_item_code4: '',
            deduction_salary_item_code4_amount: '0',
            deduction_fixed_fluctuation4: 0,
            deduction_salary_item_name5: '',
            deduction_salary_item_code5: '',
            deduction_salary_item_code5_amount: '0',
            deduction_fixed_fluctuation5: 0,
            deduction_salary_item_name6: '',
            deduction_salary_item_code6: '',
            deduction_salary_item_code6_amount: '0',
            deduction_fixed_fluctuation6: 0,
            deduction_salary_item_name7: '',
            deduction_salary_item_code7: '',
            deduction_salary_item_code7_amount: '0',
            deduction_fixed_fluctuation7: 0,
            deduction_salary_item_name8: '',
            deduction_salary_item_code8: '',
            deduction_salary_item_code8_amount: '0',
            deduction_fixed_fluctuation8: 0,
            deduction_salary_item_name9: '',
            deduction_salary_item_code9: '',
            deduction_salary_item_code9_amount: '0',
            deduction_fixed_fluctuation9: 0,
            deduction_salary_item_name10: '',
            deduction_salary_item_code10: '',
            deduction_salary_item_code10_amount: '0',
            deduction_fixed_fluctuation10: 0
          }
        ]
        this.deductionTwoRow = [
          {
            deduction_salary_item_name11: '',
            deduction_salary_item_code11: '',
            deduction_salary_item_code11_amount: '0',
            deduction_fixed_fluctuation11: 0,
            deduction_salary_item_name12: '',
            deduction_salary_item_code12: '',
            deduction_salary_item_code12_amount: '0',
            deduction_fixed_fluctuation12: 0,
            deduction_salary_item_name13: '',
            deduction_salary_item_code13: '',
            deduction_salary_item_code13_amount: '0',
            deduction_fixed_fluctuation13: 0,
            deduction_salary_item_name14: '',
            deduction_salary_item_code14: '',
            deduction_salary_item_code14_amount: '0',
            deduction_fixed_fluctuation14: 0,
            deduction_salary_item_name15: '',
            deduction_salary_item_code15: '',
            deduction_salary_item_code15_amount: '0',
            deduction_fixed_fluctuation15: 0,
            deduction_salary_item_name16: '',
            deduction_salary_item_code16: '',
            deduction_salary_item_code16_amount: '0',
            deduction_fixed_fluctuation16: 0,
            deduction_salary_item_name17: '',
            deduction_salary_item_code17: '',
            deduction_salary_item_code17_amount: '0',
            deduction_fixed_fluctuation17: 0,
            deduction_salary_item_name18: '',
            deduction_salary_item_code18: '',
            deduction_salary_item_code18_amount: '0',
            deduction_fixed_fluctuation18: 0,
            deduction_salary_item_name19: '',
            deduction_salary_item_code19: '',
            deduction_salary_item_code19_amount: '0',
            deduction_fixed_fluctuation19: 0,
            deduction_salary_item_name20: '',
            deduction_salary_item_code20: '',
            deduction_salary_item_code20_amount: '0',
            deduction_fixed_fluctuation20: 0
          }
        ]
      },
      allClear () {
        this.salary_closing_code = ''
        this.layout = ''
        this.salary_category = ''
        this.salaryAndDeductionClear()
        this.transfer_information = [
          {
            count: this.$t('label.first'),
            transfer_method: '',
            bank_code: '',
            disp_bank: '',
            branch_code: '',
            disp_branch: '',
            account_classification: '',
            account_number: '',
            transfer_amount_of_money: '',
            row_number: 0
          },
          {
            count: this.$t('label.second'),
            transfer_method: '',
            bank_code: '',
            disp_bank: '',
            branch_code: '',
            disp_branch: '',
            account_classification: '',
            account_number: '',
            transfer_amount_of_money: '',
            row_number: 1
          }
        ]
        this.selectTransfer = 0
        this.tax_amount_classification = ''
        this.last_update_date_of_monthly_reward = '0'
        this.welfare_pension_insurance_premium = '0'
        this.health_insurance_premium_non_include_nursing = '0'
        this.health_insurance_premium_include_nursing = '0'
        this.long_term_care_insurance_premium = '0'
        this.employees_pension_fund_premium = '0'
        this.pension_fund_contributions_date = ''
        this.is_long_term_care_insurance_care_category = ''
        this.layout_change_flg = false
        this.monthl_reward = '0'
      },
      changeTransferMethod (row) {
        if (row.transfer_method === '2') {
          row.bank_code = ''
          row.disp_bank = ''
          row.branch_code = ''
          row.disp_branch = ''
          row.account_classification = ''
          row.account_number = ''
        } else if (row.transfer_method === '') {
          row.bank_code = ''
          row.disp_bank = ''
          row.branch_code = ''
          row.disp_branch = ''
          row.account_classification = ''
          row.account_number = ''
          row.transfer_amount_of_money = ''
        }
      },
      salaryClosingSearchShowAdd () {
        this.params = {}
        this.$modal.show('SalaryClosingSearch')
      },
      salaryClosingSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 給与締日
          this.salary_closing_list = res.common_area.combo_data.salary_closing_list
        })
        this.$modal.hide('SalaryClosingSearch')
      },
      getAmountOfMoney (amount) {
        if (amount === undefined || amount === null || amount === '') {
          return '0' + this.$t('label.currency')
        }
        return this.formatNumber(amount) + this.$t('label.currency')
      },
      isNotTransfer (transferMethod) {
        return transferMethod === null || transferMethod === ''
      },
      // 通勤費の追加ボタン押下時の処理
      addEmployeeCommuteRecord () {
        let record = {
          selected: false,
          serial_number: this.employee_commute_record.length + 1,
          payment_unit: '',
          target_date: '',
          distance_to_use_transportation_equipment: '',
          transportation_name: '',
          start_section: '',
          end_section: '',
          payment_amount: '',
          traffic_division: '',
          edit_target_date_list: []
        }
        this.employee_commute_record.push(record)
      },
      showBank (number) {
        this.selectTransfer = number
        this.$modal.show('bank')
      },
      hideBank (selected) {
        if (selected) {
          this.transfer_information[this.selectTransfer].bank_code = selected.bank_code
          this.transfer_information[this.selectTransfer].disp_bank = selected.bank_name
          this.transfer_information[this.selectTransfer].branch_code = selected.branch_code
          this.transfer_information[this.selectTransfer].disp_branch = selected.branch_name
        }
        this.$modal.hide('bank')
      },
      // 項目名取得
      getItemName (code) {
        if (code === '') {
          return ''
        } else {
          return code
        }
      },
      getStyle (code) {
        if (code === '' || code === null) {
          return 'display:inline;text-align: right;visibility:hidden;'
        } else {
          return 'display:inline;text-align: right;'
        }
      },
      distanceToUseTransportationEquipmentChanged (row) {
        const maxPaymentAmount = this.distance_to_use_transportation_equipment_mapping[row.distance_to_use_transportation_equipment]
        row.payment_amount = String(maxPaymentAmount)
      },
      changeTrafficDivision (index) {
        if (this.employee_commute_record[index].traffic_division !== '3') {
          this.employee_commute_record[index].distance_to_use_transportation_equipment = ''
        }
      },
      changePaymentUnit (index) {
        this.employee_commute_record[index].target_date = ''
        this.employee_commute_record[index].edit_target_date_list = this.getTargetDateList(this.employee_commute_record[index].payment_unit)
      },
      // テキストから数値に変換
      textToNumber (text) {
        if (text === undefined || text === null || text === '') {
          return 0
        }
        // 元々数値の場合はそのまま返却
        if (typeof text === 'number') {
          return text
        }
        return Number(text.replace(/,/g, ''))
      },
      addOtherPaymentRow () {
        let row = {
          selected: false,
          salary_item_code: '',
          basic_price: 0,
          working_start_time: '',
          working_end_time: ''
        }
        this.other_payment.push(row)
      },
      addOtherDeductionRow () {
        let row = {
          selected: false,
          salary_item_code: '',
          basic_price: 0
        }
        this.other_deduction.push(row)
      },
      createDispList () {
        this.disp_salary_item_list_exclusion_fluctuation_payment = []
        this.disp_salary_item_list_exclusion_fluctuation_deduction = []
        let paymentNgList = []
        let deductionNgList = []
        let index = 1
        for (index = 1; index <= 20; index++) {
          let code = 'payment_salary_item_code' + String(index)
          let setCode = ''
          if (index <= 10) {
            setCode = this.salaryOneRow[0][code]
          } else {
            setCode = this.salaryTwoRow[0][code]
          }
          paymentNgList.push(setCode)
        }
        for (index = 1; index <= 20; index++) {
          let deductionCode = 'deduction_salary_item_code' + String(index)
          let setDeductionCode = ''
          if (index <= 10) {
            setDeductionCode = this.deductionOneRow[0][deductionCode]
          } else {
            setDeductionCode = this.deductionTwoRow[0][deductionCode]
          }
          deductionNgList.push(setDeductionCode)
        }
        // 要素検索
        let arrayIndex = 0
        if (this.salary_item_list_exclusion_fluctuation_payment !== null) {
          for (let salaryItemList of this.salary_item_list_exclusion_fluctuation_payment) {
            if (salaryItemList.salary_category === Number(this.salary_category)) {
              for (let item of salaryItemList.values) {
                arrayIndex = paymentNgList.indexOf(item.value)
                if (arrayIndex === -1) {
                  this.disp_salary_item_list_exclusion_fluctuation_payment.push(item)
                }
              }
            }
          }
        }
        if (this.salary_item_list_exclusion_fluctuation_deduction !== null) {
          for (let salaryItemList of this.salary_item_list_exclusion_fluctuation_deduction) {
            if (salaryItemList.salary_category === Number(this.salary_category)) {
              for (let item of salaryItemList.values) {
                arrayIndex = deductionNgList.indexOf(item.value)
                if (arrayIndex === -1) {
                  this.disp_salary_item_list_exclusion_fluctuation_deduction.push(item)
                }
              }
            }
          }
        }
      },
      // 計算ボタン押下時の処理
      exeCalculation () {
        let employeeDetail = this.$parent.$parent.$parent
        this.is_long_term_care_insurance_care_category = employeeDetail.$refs.employeeSocialInsuranceInfo.is_long_term_care_insurance_care_category
        if (this.is_long_term_care_insurance_care_category === '') {
          this.is_long_term_care_insurance_care_category = '2'
        }
        let layout = this.layout.split('-=-')
        let param = {
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          screen_code: this.screenCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            is_long_term_care_insurance_care_category: Number(this.is_long_term_care_insurance_care_category),
            social_insurance: Number(employeeDetail.$refs.employeeSocialInsuranceInfo.social_insurance.value),
            layout_code: layout[0],
            monthly_fee: this.amountToNumber(this.monthl_reward),
            payment_list: []
          }
        }
        // 給与情報を編集
        let index = 1
        for (index = 1; index <= 20; index++) {
          let code = 'payment_salary_item_code' + String(index)
          let amount = 'payment_salary_item_code' + String(index) + '_amount'
          let setCode = ''
          let setAmount = ''
          if (index <= 10) {
            setCode = this.salaryOneRow[0][code]
            setAmount = this.salaryOneRow[0][amount]
          } else {
            setCode = this.salaryTwoRow[0][code]
            setAmount = this.salaryTwoRow[0][amount]
          }
          if (setCode !== undefined && setCode !== null && setCode !== '') {
            let pushParam = {
              salary_item_code: setCode,
              basic_price: this.amountToNumber(setAmount)
            }
            param.api_area.payment_list.push(pushParam)
          }
        }
        for (index = 1; index <= 20; index++) {
          let deductionCode = 'deduction_salary_item_code' + String(index)
          let deductionAmount = 'deduction_salary_item_code' + String(index) + '_amount'
          let setDeductionCode = ''
          let setDeductionAmount = ''
          if (index <= 10) {
            setDeductionCode = this.deductionOneRow[0][deductionCode]
            setDeductionAmount = this.deductionOneRow[0][deductionAmount]
          } else {
            setDeductionCode = this.deductionTwoRow[0][deductionCode]
            setDeductionAmount = this.deductionTwoRow[0][deductionAmount]
          }
          if (setDeductionCode !== undefined && setDeductionCode !== null && setDeductionCode !== '') {
            let pushParam = {
              salary_item_code: setDeductionCode,
              basic_price: this.amountToNumber(setDeductionAmount)
            }
            param.api_area.payment_list.push(pushParam)
          }
        }
        this.send('/calc/employee', param)
        .then(res => {
          this.monthl_reward = res.api_area.standard_monthly_fee
          this.last_update_date_of_monthly_reward = res.api_area.last_update_date_of_monthly_reward.replace(/-/g, '/')
          this.welfare_pension_insurance_premium = res.api_area.welfare_pension_insurance_premium
          this.health_insurance_premium_non_include_nursing = res.api_area.health_insurance_premium_non_include_nursing
          this.health_insurance_premium_include_nursing = res.api_area.health_insurance_premium_include_nursing
          this.health_insurance_premium_general = res.api_area.health_insurance_premium_general
          this.health_insurance_premium_adjustment = res.api_area.health_insurance_premium_adjustment
          this.long_term_care_insurance_premium = res.api_area.long_term_care_insurance_premium
          this.employees_pension_fund_premium = res.api_area.employees_pension_fund_premium
        })
      },
      amountToNumber (amount) {
        if (amount === undefined || amount === null || amount === '') {
          return 0
        } else if (typeof amount === 'number') {
          return amount
        }
        return Number(amount.replace(/,/g, ''))
      },
      createUsePaymentList () {
        this.use_payment = []
        let paymentFixedFluctuation = ''
        let paymentSalaryItemCode = ''
        let paymentSalaryItemName = ''
        let paymentSalaryItemCodeAmount = ''
        for (let i = 1; i <= 10; i++) {
          paymentFixedFluctuation = 'payment_fixed_fluctuation' + i
          paymentSalaryItemCode = 'payment_salary_item_code' + i
          paymentSalaryItemName = 'payment_salary_item_name' + i
          paymentSalaryItemCodeAmount = 'payment_salary_item_code' + i + '_amount'
          if (this.salaryOneRow[0][paymentFixedFluctuation] === 1) {
            let pushData = {
              salary_item_code: this.salaryOneRow[0][paymentSalaryItemCode],
              salary_item_name: this.salaryOneRow[0][paymentSalaryItemName],
              basic_price: this.salaryOneRow[0][paymentSalaryItemCodeAmount],
              working_start_time: '',
              working_end_time: ''
            }
            this.use_payment.push(pushData)
          }
        }
        for (let i = 11; i <= 20; i++) {
          paymentFixedFluctuation = 'payment_fixed_fluctuation' + i
          paymentSalaryItemCode = 'payment_salary_item_code' + i
          paymentSalaryItemName = 'payment_salary_item_name' + i
          paymentSalaryItemCodeAmount = 'payment_salary_item_code' + i + '_amount'
          if (this.salaryTwoRow[0][paymentFixedFluctuation] === 1) {
            let pushData = {
              salary_item_code: this.salaryTwoRow[0][paymentSalaryItemCode],
              salary_item_name: this.salaryTwoRow[0][paymentSalaryItemName],
              basic_price: this.salaryTwoRow[0][paymentSalaryItemCodeAmount],
              working_start_time: '',
              working_end_time: ''
            }
            this.use_payment.push(pushData)
          }
        }
        for (let i = 21; i <= 30; i++) {
          paymentFixedFluctuation = 'payment_fixed_fluctuation' + i
          paymentSalaryItemCode = 'payment_salary_item_code' + i
          paymentSalaryItemName = 'payment_salary_item_name' + i
          paymentSalaryItemCodeAmount = 'payment_salary_item_code' + i + '_amount'
          if (this.salaryThreeRow[0][paymentFixedFluctuation] === 1) {
            let pushData = {
              salary_item_code: this.salaryThreeRow[0][paymentSalaryItemCode],
              salary_item_name: this.salaryThreeRow[0][paymentSalaryItemName],
              basic_price: this.salaryThreeRow[0][paymentSalaryItemCodeAmount],
              working_start_time: '',
              working_end_time: ''
            }
            this.use_payment.push(pushData)
          }
        }
        if (this.employee_payment_list) {
          for (const paymentList of this.employee_payment_list.payment_list) {
            for (let usePayment of this.use_payment) {
              if (paymentList.salary_item_code === usePayment.salary_item_code) {
                usePayment.working_start_time = paymentList.working_start_time
                usePayment.working_end_time = paymentList.working_end_time
              }
            }
          }
        }
      },
      changePaymentAmount () {
        for (let usePayment of this.use_payment) {
          let paymentSalaryItemCode = ''
          let paymentSalaryItemCodeAmount = ''
          for (let i = 1; i <= 10; i++) {
            paymentSalaryItemCode = 'payment_salary_item_code' + i
            paymentSalaryItemCodeAmount = 'payment_salary_item_code' + i + '_amount'
            if (usePayment.salary_item_code === this.salaryOneRow[0][paymentSalaryItemCode]) {
              usePayment.basic_price = this.salaryOneRow[0][paymentSalaryItemCodeAmount]
            }
          }
          for (let i = 11; i <= 20; i++) {
            paymentSalaryItemCode = 'payment_salary_item_code' + i
            paymentSalaryItemCodeAmount = 'payment_salary_item_code' + i + '_amount'
            if (usePayment.salary_item_code === this.salaryTwoRow[0][paymentSalaryItemCode]) {
              usePayment.basic_price = this.salaryTwoRow[0][paymentSalaryItemCodeAmount]
            }
          }
        }
      },
      setDetails (res, mode) {
        if ((mode !== this.modes.add) || (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '')) {
          this.salary_closing_code = String(res.api_area.basic.salary_closing_code)
          this.salary_category = String(res.api_area.basic.salary_category)
          this.payroll_management = String(res.api_area.basic.payroll_management)
          if (res.api_area.salary.tax === null) {
            this.tax_amount_classification = ''
            this.pension_fund_contributions_subscription.value = ''
            this.pension_fund_contributions_date = ''
            this.monthl_reward = '0'
            this.last_update_date_of_monthly_reward = '0'
            this.welfare_pension_insurance_premium = '0'
            this.health_insurance_premium_non_include_nursing = '0'
            this.health_insurance_premium_include_nursing = '0'
            this.health_insurance_premium_general = '0'
            this.health_insurance_premium_adjustment = '0'
            this.long_term_care_insurance_premium = '0'
            this.employees_pension_fund_premium = '0'
          } else {
            this.tax_amount_classification = String(res.api_area.salary.tax.tax_amount_classification)
            this.pension_fund_contributions_subscription.value = String(res.api_area.salary.tax.pension_fund_contributions)
            this.pension_fund_contributions_date = res.api_area.salary.tax.pension_fund_contributions_date
            this.monthl_reward = res.api_area.salary.tax.monthl_reward
            this.last_update_date_of_monthly_reward = res.api_area.salary.tax.last_update_date_of_monthly_reward
            this.welfare_pension_insurance_premium = res.api_area.salary.tax.welfare_pension_insurance_premium
            this.health_insurance_premium_non_include_nursing = res.api_area.salary.tax.health_insurance_premium_non_include_nursing
            this.health_insurance_premium_include_nursing = res.api_area.salary.tax.health_insurance_premium_include_nursing
            this.health_insurance_premium_general = res.api_area.salary.tax.health_insurance_premium_general
            this.health_insurance_premium_adjustment = res.api_area.salary.tax.health_insurance_premium_adjustment
            this.long_term_care_insurance_premium = res.api_area.salary.tax.long_term_care_insurance_premium
            this.employees_pension_fund_premium = res.api_area.salary.tax.employees_pension_fund_premium
          }
          let employeePaymentList = res.api_area.salary.employee_payment_list
          this.employee_payment_list = res.api_area.salary.employee_payment_list
          this.employee_payment_list_length = 1
          if (employeePaymentList !== undefined) {
            this.layout = String(employeePaymentList.layout_code) + '-=-' + employeePaymentList.layout_name
            // レイアウト変更処理
            for (const data of this.layout_inf_list) {
              if (this.layout === data.layout_code + '-=-' + data.layout_name) {
                let arryaItemName = ''
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'payment_salary_item_code' + i
                  this.salaryOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'payment_salary_item_code' + i
                  this.salaryTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 21; i <= 30; i++) {
                  arryaItemName = 'payment_salary_item_code' + i
                  this.salaryThreeRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'payment_salary_item_name' + i
                  this.salaryOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'payment_salary_item_name' + i
                  this.salaryTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 21; i <= 30; i++) {
                  arryaItemName = 'payment_salary_item_name' + i
                  this.salaryThreeRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'payment_salary_item_code' + i + '_amount'
                  this.salaryOneRow[0][arryaItemName] = '0'
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'payment_salary_item_code' + i + '_amount'
                  this.salaryTwoRow[0][arryaItemName] = '0'
                }
                for (let i = 21; i <= 30; i++) {
                  arryaItemName = 'payment_salary_item_code' + i + '_amount'
                  this.salaryThreeRow[0][arryaItemName] = '0'
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'payment_fixed_fluctuation' + i
                  this.salaryOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'payment_fixed_fluctuation' + i
                  this.salaryTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 21; i <= 30; i++) {
                  arryaItemName = 'payment_fixed_fluctuation' + i
                  this.salaryThreeRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'deduction_salary_item_code' + i
                  this.deductionOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'deduction_salary_item_code' + i
                  this.deductionTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'deduction_salary_item_name' + i
                  this.deductionOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'deduction_salary_item_name' + i
                  this.deductionTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'deduction_salary_item_code' + i + '_amount'
                  this.deductionOneRow[0][arryaItemName] = '0'
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'deduction_salary_item_code' + i + '_amount'
                  this.deductionTwoRow[0][arryaItemName] = '0'
                }
                for (let i = 1; i <= 10; i++) {
                  arryaItemName = 'deduction_fixed_fluctuation' + i
                  this.deductionOneRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let i = 11; i <= 20; i++) {
                  arryaItemName = 'deduction_fixed_fluctuation' + i
                  this.deductionTwoRow[0][arryaItemName] = data[arryaItemName]
                }
                for (let payment of employeePaymentList.payment_list) {
                  let itemCode = ''
                  let itemAmount = ''
                  let findFlg = false
                  for (let i = 1; i <= 10; i++) {
                    itemCode = 'payment_salary_item_code' + i
                    itemAmount = 'payment_salary_item_code' + i + '_amount'
                    if (this.salaryOneRow[0][itemCode] === payment.salary_item_code) {
                      this.salaryOneRow[0][itemAmount] = String(payment.basic_price)
                      findFlg = true
                      break
                    }
                  }
                  if (!findFlg) {
                    for (let i = 11; i <= 20; i++) {
                      itemCode = 'payment_salary_item_code' + i
                      itemAmount = 'payment_salary_item_code' + i + '_amount'
                      if (this.salaryTwoRow[0][itemCode] === payment.salary_item_code) {
                        this.salaryTwoRow[0][itemAmount] = String(payment.basic_price)
                        findFlg = true
                        break
                      }
                    }
                  }
                  if (!findFlg) {
                    for (let i = 21; i <= 30; i++) {
                      itemCode = 'payment_salary_item_code' + i
                      itemAmount = 'payment_salary_item_code' + i + '_amount'
                      if (this.salaryThreeRow[0][itemCode] === payment.salary_item_code) {
                        this.salaryThreeRow[0][itemAmount] = String(payment.basic_price)
                        findFlg = true
                        break
                      }
                    }
                  }
                  if (!findFlg) {
                    for (let i = 1; i <= 10; i++) {
                      itemCode = 'deduction_salary_item_code' + i
                      itemAmount = 'deduction_salary_item_code' + i + '_amount'
                      if (this.deductionOneRow[0][itemCode] === payment.salary_item_code) {
                        this.deductionOneRow[0][itemAmount] = String(payment.basic_price)
                        findFlg = true
                        break
                      }
                    }
                  }
                  if (!findFlg) {
                    for (let i = 11; i <= 20; i++) {
                      itemCode = 'deduction_salary_item_code' + i
                      itemAmount = 'deduction_salary_item_code' + i + '_amount'
                      if (this.deductionTwoRow[0][itemCode] === payment.salary_item_code) {
                        this.deductionTwoRow[0][itemAmount] = String(payment.basic_price)
                        findFlg = true
                        break
                      }
                    }
                  }
                  if (findFlg) {
                    continue
                  }
                  if (this.salary_item_list_exclusion_fluctuation_payment !== null) {
                    for (const paymentList of this.salary_item_list_exclusion_fluctuation_payment) {
                      if (paymentList.salary_category === Number(this.salary_category)) {
                        for (const paymentValue of paymentList.values) {
                          if (paymentValue.value === payment.salary_item_code) {
                            this.other_payment.push(payment)
                          }
                        }
                      }
                    }
                  }
                  if (this.salary_item_list_exclusion_fluctuation_deduction !== null) {
                    for (const deductionList of this.salary_item_list_exclusion_fluctuation_deduction) {
                      if (deductionList.salary_category === Number(this.salary_category)) {
                        for (const deductionValue of deductionList.values) {
                          if (deductionValue.value === payment.salary_item_code) {
                            this.other_deduction.push(payment)
                          }
                        }
                      }
                    }
                  }
                }
                this.$nextTick()
                break
              }
              this.createUsePaymentList()
            }
          }
          for (const data of res.api_area.layout_format_list) {
            if (data.layout_code + '-=-' + data.layout_name === this.layout) {
              let itemName = ''
              for (let i = 1; i <= 10; i++) {
                itemName = 'payment_salary_item_name' + i
                this.salaryOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'payment_salary_item_name' + i
                this.salaryTwoRow[0][itemName] = data[itemName]
              }
              for (let i = 21; i <= 30; i++) {
                itemName = 'payment_salary_item_name' + i
                this.salaryThreeRow[0][itemName] = data[itemName]
              }
              for (let i = 1; i <= 10; i++) {
                itemName = 'payment_salary_item_code' + i
                this.salaryOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'payment_salary_item_code' + i
                this.salaryTwoRow[0][itemName] = data[itemName]
              }
              for (let i = 1; i <= 10; i++) {
                itemName = 'payment_fixed_fluctuation' + i
                this.salaryOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'payment_fixed_fluctuation' + i
                this.salaryTwoRow[0][itemName] = data[itemName]
              }
              for (let i = 1; i <= 10; i++) {
                itemName = 'deduction_salary_item_name' + i
                this.deductionOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'deduction_salary_item_name' + i
                this.deductionTwoRow[0][itemName] = data[itemName]
              }
              for (let i = 1; i <= 10; i++) {
                itemName = 'deduction_salary_item_code' + i
                this.deductionOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'deduction_salary_item_code' + i
                this.deductionTwoRow[0][itemName] = data[itemName]
              }
              for (let i = 1; i <= 10; i++) {
                itemName = 'deduction_fixed_fluctuation' + i
                this.deductionOneRow[0][itemName] = data[itemName]
              }
              for (let i = 11; i <= 20; i++) {
                itemName = 'deduction_fixed_fluctuation' + i
                this.deductionTwoRow[0][itemName] = data[itemName]
              }
            }
          }
          this.createUsePaymentList()
          if (res.api_area.salary.employee_transfer !== null && mode !== this.modes.add) {
            this.transfer_information[0].bank_code = String(res.api_area.salary.employee_transfer.bank_code_1)
            this.transfer_information[0].disp_bank = String(res.api_area.salary.employee_transfer.bank_name_1)
            this.transfer_information[0].branch_code = String(res.api_area.salary.employee_transfer.branch_code_1)
            this.transfer_information[0].disp_branch = String(res.api_area.salary.employee_transfer.branch_code_name_1)
            this.transfer_information[0].account_classification = String(res.api_area.salary.employee_transfer.account_classification_1)
            this.transfer_information[0].account_number = String(res.api_area.salary.employee_transfer.account_number_1)
            this.transfer_information[0].transfer_amount_of_money = String(res.api_area.salary.employee_transfer.transfer_amount_of_money_1)
            if (this.transfer_information[0].transfer_amount_of_money !== null && this.transfer_information[0].transfer_amount_of_money !== '0') {
              this.selectAmount = '2'
            } else {
              this.selectAmount = '1'
            }
            this.transfer_information[1].bank_code = String(res.api_area.salary.employee_transfer.bank_code_2)
            this.transfer_information[1].disp_bank = String(res.api_area.salary.employee_transfer.bank_name_2)
            this.transfer_information[1].branch_code = String(res.api_area.salary.employee_transfer.branch_code_2)
            this.transfer_information[1].disp_branch = String(res.api_area.salary.employee_transfer.branch_code_name_2)
            this.transfer_information[1].account_classification = String(res.api_area.salary.employee_transfer.account_classification_2)
            this.transfer_information[1].account_number = String(res.api_area.salary.employee_transfer.account_number_2)
          }
          this.employee_commute_record = []
          if (mode !== this.modes.add) {
            for (const data of res.api_area.salary.employee_commute_list) {
              let editTargetDateList = this.getTargetDateList(String(data.payment_unit))
              let record = {
                selected: false,
                serial_number: this.employee_commute_record.length + 1,
                payment_unit: String(data.payment_unit),
                target_date: String(data.target_month),
                distance_to_use_transportation_equipment: String(data.distance_to_use_transportation_equipment),
                transportation_name: data.transportation_name,
                start_section: data.start_section,
                end_section: data.end_section,
                payment_amount: data.payment_amount,
                traffic_division: String(data.traffic_division),
                edit_target_date_list: editTargetDateList
              }
              this.employee_commute_record.push(record)
            }
          }
        }
        this.company_pension_fund_contributions = res.api_area.company_pension_fund_contributions
        if (this.company_pension_fund_contributions === 2) {
          this.pension_fund_contributions_subscription.value = '2'
        }
        this.createDispList()
      },
      changeSalaryCategory () {
        this.layout_list = []
        if (this.salary_category_layout_mapping_list[this.salary_category] !== undefined) {
          for (const data of this.salary_category_layout_mapping_list[this.salary_category]) {
            for (const layout of this.layout_list_all) {
              let layoutSplit = layout.value.split('-=-')
              if (layoutSplit[0] === data) {
                this.layout_list.push(layout)
              }
            }
          }
        }
        let found = false
        for (const data of this.layout_list) {
          if (data.value === this.layout) {
            found = true
          }
        }
        if (!found) {
          this.layout = ''
          this.salaryAndDeductionClear()
        }
      },
      getTargetDateList (paymentUnit) {
        let result = [
          {
            label: '',
            value: ''
          }
        ]
        if (paymentUnit === '1') {
          result = []
        } else if (paymentUnit === '2') {
          for (const data of this.target_date_list) {
            if (Number(data.value) <= 3) {
              result.push(data)
            }
          }
        } else if (paymentUnit === '3') {
          for (const data of this.target_date_list) {
            if (Number(data.value) > 3) {
              result.push(data)
            }
          }
        }
        return result
      },
      getNumber (number) {
        if (number === null || number === undefined) {
          return 0
        }
        return Number(number)
      },
      bankDelete (index, row) {
        row.bank_code = ''
        row.disp_bank = ''
        row.branch_code = ''
        row.disp_branch = ''
        row.account_classification = ''
        row.account_number = ''
        if (index === 0) {
          this.selectAmount = ''
        }
        row.transfer_amount_of_money = ''
      }
    },
    watch: {
      'layout': function (newVal, oldVal) {
        let parent = this.$parent.$parent.$parent
        let basicPayrollManagement = parent.$refs.employeeBasicInfo.payroll_management
        if ((this.layout_change_flg || basicPayrollManagement.value !== this.payroll_management) || this.employee_payment_list_length === 0) {
          if (newVal !== oldVal) {
            this.other_payment = []
            this.other_deduction = []
            // レイアウト変更処理
            for (const data of this.layout_inf_list) {
              if (newVal === data.layout_code + '-=-' + data.layout_name) {
                let itemName = ''
                for (let i = 1; i <= 10; i++) {
                  itemName = 'payment_salary_item_code' + i
                  this.salaryOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'payment_salary_item_code' + i
                  this.salaryTwoRow[0][itemName] = data[itemName]
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'payment_salary_item_name' + i
                  this.salaryOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'payment_salary_item_name' + i
                  this.salaryTwoRow[0][itemName] = data[itemName]
                }
                for (let i = 21; i <= 30; i++) {
                  itemName = 'payment_salary_item_name' + i
                  this.salaryThreeRow[0][itemName] = data[itemName]
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'payment_salary_item_code' + i + '_amount'
                  this.salaryOneRow[0][itemName] = '0'
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'payment_salary_item_code' + i + '_amount'
                  this.salaryTwoRow[0][itemName] = '0'
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'payment_fixed_fluctuation' + i
                  this.salaryOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'payment_fixed_fluctuation' + i
                  this.salaryTwoRow[0][itemName] = data[itemName]
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'deduction_salary_item_code' + i
                  this.deductionOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'deduction_salary_item_code' + i
                  this.deductionTwoRow[0][itemName] = data[itemName]
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'deduction_salary_item_name' + i
                  this.deductionOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'deduction_salary_item_name' + i
                  this.deductionTwoRow[0][itemName] = data[itemName]
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'deduction_salary_item_code' + i + '_amount'
                  this.deductionOneRow[0][itemName] = '0'
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'deduction_salary_item_code' + i + '_amount'
                  this.deductionTwoRow[0][itemName] = '0'
                }
                for (let i = 1; i <= 10; i++) {
                  itemName = 'deduction_fixed_fluctuation' + i
                  this.deductionOneRow[0][itemName] = data[itemName]
                }
                for (let i = 11; i <= 20; i++) {
                  itemName = 'deduction_fixed_fluctuation' + i
                  this.deductionTwoRow[0][itemName] = data[itemName]
                }
                this.$nextTick()
                this.createDispList()
                break
              }
            }
            this.createUsePaymentList()
          }
        } else {
          this.layout_change_flg = true
        }
      },
      'salary_category': function () {
        this.changeSalaryCategory()
        this.createDispList()
      },
      'pension_fund_contributions_subscription.value': function () {
        if (this.pension_fund_contributions_subscription.value === '2') {
          this.pension_fund_contributions_date = ''
        }
      },
      'selectAmount': function () {
        if (this.selectAmount === '1') {
          this.transfer_information[0].transfer_amount_of_money = ''
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
