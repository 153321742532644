<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <ValidationSelect
            v-model="searchCond.language"
            item-name="language"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.language_list)"
            :item-label="labels.language"
            :options="comboData.language_list"
            frame-class="search-condition-item"
            @change="languageChanged"
          />
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.message_id}}</label>
            <input type="text" :placeholder="$t('placeholder.message_id')" maxlength="10" class="form-control length10" v-model="searchCond.message_id">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.message}}</label>
            <input type="text" :placeholder="$t('placeholder.message')" maxlength="30" class="form-control length60" v-model="searchCond.message">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.correspondence_action}}</label>
            <input type="text" :placeholder="$t('placeholder.correspondence_action')" maxlength="30" class="form-control length60" v-model="searchCond.correspondence_action">
          </div>
          <ValidationSelect
            v-model="searchCond.message_type"
            item-name="message_type"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.message_type_list)"
            :item-label="labels.message_type_ryaku === undefined ? labels.messageType_ryaku === undefined ? '' : labels.messageType_ryaku : labels.message_type_ryaku"
            :options="comboData.message_type_list"
            frame-class="search-condition-item"
            @change="messageTypeChanged"
          />
          <ValidationSelect
            v-model="searchCond.message_classification"
            item-name="message_classification"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.message_classification_list)"
            :item-label="labels.message_classification_ryaku === undefined ? labels.messageClassification_ryaku === undefined ? '' : labels.messageClassification_ryaku : labels.message_classification_ryaku"
            :options="comboData.message_classification_list"
            frame-class="search-condition-item"
            @change="messageClassificationChanged"
          />
          <ValidationSelect
            v-model="searchCond.cause"
            item-name="cause"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.cause_list)"
            :item-label="labels.cause_ryaku === undefined ? labels.cause_ryaku === undefined ? '' : labels.cause_ryaku : labels.cause_ryaku"
            :options="comboData.cause_list"
            frame-class="search-condition-item"
            @change="causeChanged"
          />
          <ValidationSelect
            v-model="searchCond.correspondence"
            item-name="correspondence"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.correspondence_list)"
            :item-label="labels.correspondence_ryaku === undefined ? labels.correspondence_ryaku === undefined ? '' : labels.correspondence_ryaku : labels.correspondence_ryaku"
            :options="comboData.correspondence_list"
            frame-class="search-condition-item"
            @change="correspondenceChanged"
          />
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showLanguage" :width="getColumnWidth(comboData.language_list, labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku, 'label', 'value')" :label="labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.language, comboData.language_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showMessageId" :width="getColumnWidth(tableData, labels.message_id_ryaku === undefined ? labels.messageId_ryaku === undefined ? '' : labels.messageId_ryaku : labels.message_id_ryaku, 'message_id')" :label="labels.message_id_ryaku === undefined ? labels.messageId_ryaku === undefined ? '' : labels.messageId_ryaku : labels.message_id_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.message_id}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showMessage" :width="getColumnWidth(tableData, labels.message_ryaku === undefined ? labels.message_ryaku === undefined ? '' : labels.message_ryaku : labels.message_ryaku, 'message')" :label="labels.message_ryaku === undefined ? labels.message_ryaku === undefined ? '' : labels.message_ryaku : labels.message_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.message}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCorrespondenceAction" :width="getColumnWidth(tableData, labels.correspondence_action_ryaku === undefined ? labels.correspondenceAction_ryaku === undefined ? '' : labels.correspondenceAction_ryaku : labels.correspondence_action_ryaku, 'correspondence_action')" :label="labels.correspondence_action_ryaku === undefined ? labels.correspondenceAction_ryaku === undefined ? '' : labels.correspondenceAction_ryaku : labels.correspondence_action_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.correspondence_action}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showMessageType" :width="getColumnWidth(comboData.message_type_list, labels.message_type_ryaku === undefined ? labels.messageType_ryaku === undefined ? '' : labels.messageType_ryaku : labels.message_type_ryaku, 'label', 'value')" :label="labels.message_type_ryaku === undefined ? labels.messageType_ryaku === undefined ? '' : labels.messageType_ryaku : labels.message_type_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.message_type, comboData.message_type_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showMessageClassification" :width="getColumnWidth(comboData.message_classification_list, labels.message_classification_ryaku === undefined ? labels.messageClassification_ryaku === undefined ? '' : labels.messageClassification_ryaku : labels.message_classification_ryaku, 'label', 'value')" :label="labels.message_classification_ryaku === undefined ? labels.messageClassification_ryaku === undefined ? '' : labels.messageClassification_ryaku : labels.message_classification_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.message_classification, comboData.message_classification_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCause" :width="getColumnWidth(comboData.cause_list, labels.cause_ryaku === undefined ? labels.cause_ryaku === undefined ? '' : labels.cause_ryaku : labels.cause_ryaku, 'label', 'value')" :label="labels.cause_ryaku === undefined ? labels.cause_ryaku === undefined ? '' : labels.cause_ryaku : labels.cause_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.cause, comboData.cause_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCorrespondence" :width="getColumnWidth(comboData.correspondence_list, labels.correspondence_ryaku === undefined ? labels.correspondence_ryaku === undefined ? '' : labels.correspondence_ryaku : labels.correspondence_ryaku, 'label', 'value')" :label="labels.correspondence_ryaku === undefined ? labels.correspondence_ryaku === undefined ? '' : labels.correspondence_ryaku : labels.correspondence_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.correspondence, comboData.correspondence_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.update_date}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{ props.row.update_employee_name }}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;">
                    <small>{{ props.row.update_employee_code }}</small>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import MessageSearchMixin from '@/components/Dashboard/Views/Generated/MessageSearchMixin'

  export default {
    name: 'MessageSearch',
    methods: {
      afterInit (res) {
        this.comboData.language_list = this.comboData.language_type_list
        this.comboData.message_type_list.unshift({label: '', value: null})
        this.comboData.message_classification_list.unshift({label: '', value: null})
        this.comboData.cause_list.unshift({label: '', value: null})
        this.comboData.correspondence_list.unshift({label: '', value: null})
        this.searchCond.language = 'JPN'
        this.searchCond.message_type = this.comboData.message_type_list[0].value
        this.searchCond.message_classification = this.comboData.message_classification_list[0].value
        this.searchCond.cause = this.comboData.cause_list[0].value
        this.searchCond.correspondence = this.comboData.correspondence_list[0].value
      },
      convertSearchCond (searchCond) {
        searchCond.choice_language = searchCond.language
        delete searchCond.language
        if (searchCond.message_type) {
          searchCond.message_type = parseInt(searchCond.message_type, 10)
        }
        if (searchCond.message_classification) {
          searchCond.message_classification = parseInt(searchCond.message_classification, 10)
        }
        if (searchCond.cause) {
          searchCond.cause = parseInt(searchCond.cause, 10)
        }
        if (searchCond.correspondence) {
          searchCond.correspondence = parseInt(searchCond.correspondence, 10)
        }
        return searchCond
      },
      afterSearch (res) {
        this.comboData.language_list = this.comboData.language_type_list
      }
    },
    mixins: [MessageSearchMixin]
  }
</script>
<style scoped>
</style>
