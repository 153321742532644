<template>
  <div>
    <div style="width: 100%; overflow: hidden;" :key="componentKey" v-if="initialized">
      <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
      <ValidationObserver ref="observer">
        <div style="margin-bottom: 10px;">
          <div style="margin-bottom: 5px;">
            <div style="display: inline-block; width: 49%;">
              <span style="font-size: xx-large; font-weight: bold;">{{tableData.title}}</span>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: bottom;">
              <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
            </div>
          </div>
          <div style="display: inline-block; width: 49%; vertical-align: top; padding-top: 10px;">
              <button type="button" class="btn btn-sm btn-default btn-primary" style="position: relative; z-index: 3;" :disabled="!prevEnabled" @click="prevMonth">{{ $t('button.previous_month') }}</button>
              <button type="button" class="btn btn-sm btn-default btn-primary" style="position: relative; z-index: 3;" @click="thisMonth">{{ $t('button.this_month') }}</button>
              <button type="button" class="btn btn-sm btn-default btn-primary" style="position: relative; z-index: 3;" :disabled="!nextEnabled" @click="nextMonth">{{ $t('button.next_month') }}</button>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right;">
            <div v-if="googleApiClientId">
              <div v-if="isGoogleToken">
                <button type="button" class="btn btn-sm btn-default btn-warning" style="position: relative; z-index: 3;" @click="googleDisconnect">
                  <span>{{ $t('button.google_oauth_deauthorization') }}</span>
                </button>
              </div>
              <div v-else>
                <a href="/#/google-user-data-policy" target="_blank" style="color: #317FB8; position: relative; z-index: 3; vertical-align: top; line-height: 300%;">Google APIデータポリシー</a>
                <a href="#" class="google-signin" style="position: relative; z-index: 3;" @click.prevent="googleConnect">
                  <div class="google-signin"></div>
                </a>
              </div>
            </div>
          </div>
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" :data="tableData.data"
                    :empty-text="$t('label.no_data')"
                    :style="tableStyle"
                    ref="table"
                    :cell-style="getCellStyle">
            <el-table-column :label="$t('label.sunday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_sunday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_sunday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_sunday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_sunday, props.row.day_of_sunday, props.row.color_sunday)">{{props.row.day_of_sunday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_sunday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_sunday"
                                v-model="props.row.day_type_of_sunday">
                      <el-option v-for="dayType in props.row.holiday_list_sunday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_sunday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_sunday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_sunday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_sunday + '-' + props.row.hourly_annual_holiday_term_time_to_sunday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_sunday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.monday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_monday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_monday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_monday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_monday, props.row.day_of_monday, props.row.color_monday)">{{props.row.day_of_monday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_monday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_monday"
                                v-model="props.row.day_type_of_monday">
                      <el-option v-for="dayType in props.row.holiday_list_monday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_monday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_monday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_monday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_monday + '-' + props.row.hourly_annual_holiday_term_time_to_monday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_monday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.tuesday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_tuesday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_tuesday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_tuesday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_tuesday, props.row.day_of_tuesday, props.row.color_tuesday)">{{props.row.day_of_tuesday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_tuesday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_tuesday"
                                v-model="props.row.day_type_of_tuesday">
                      <el-option v-for="dayType in props.row.holiday_list_tuesday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_tuesday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_tuesday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_tuesday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_tuesday + '-' + props.row.hourly_annual_holiday_term_time_to_tuesday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_tuesday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.wednesday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_wednesday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_wednesday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_wednesday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_wednesday, props.row.day_of_wednesday, props.row.color_wednesday)">{{props.row.day_of_wednesday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_wednesday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_wednesday"
                                v-model="props.row.day_type_of_wednesday">
                      <el-option v-for="dayType in props.row.holiday_list_wednesday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_wednesday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_wednesday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_wednesday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_wednesday + '-' + props.row.hourly_annual_holiday_term_time_to_wednesday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_wednesday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.thursday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_thursday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_thursday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_thursday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_thursday, props.row.day_of_thursday, props.row.color_thursday)">{{props.row.day_of_thursday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_thursday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_thursday"
                                v-model="props.row.day_type_of_thursday">
                      <el-option v-for="dayType in props.row.holiday_list_thursday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_thursday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_thursday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_thursday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_thursday + '-' + props.row.hourly_annual_holiday_term_time_to_thursday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_thursday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.friday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_friday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_friday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_friday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_friday, props.row.day_of_friday, props.row.color_friday)">{{props.row.day_of_friday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_friday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_friday"
                                v-model="props.row.day_type_of_friday">
                      <el-option v-for="dayType in props.row.holiday_list_friday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_friday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_friday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_friday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_friday + '-' + props.row.hourly_annual_holiday_term_time_to_friday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_friday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('label.saturday')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title">{{scope.column.label}}</div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="props.row.day_of_saturday">
                  <template v-if="tableData.year + '/' + ('0' + tableData.month).slice(-2) + '/' + ('0' + props.row.day_of_saturday).slice(-2) === formatToday()">
                    <div class="circle-fill">
                    {{props.row.day_of_saturday}}
                  </div>
                  </template>
                  <template v-else>
                    <div style="largeline-height: 23px;">
                      <span :style="getColor(props.row.day_type_of_saturday, props.row.day_of_saturday, props.row.color_saturday)">{{props.row.day_of_saturday}}</span>
                    </div>
                  </template>
                  <div style="width: 100%;">
                    <el-select :class="getSelectStyle(props.row.day_of_saturday)"
                                size="large"
                                :disabled="isReadOnly || props.row.disabled_saturday"
                                v-model="props.row.day_type_of_saturday">
                      <el-option v-for="dayType in props.row.holiday_list_saturday"
                                  class="select-success"
                                  :value="dayType.value"
                                  :label="dayType.label"
                                  :key="dayType.label">
                      </el-option>
                    </el-select>
                    <div v-for="item of props.row.google_calendar_list_saturday" :key="item.index">
                        <div v-if="item.time === ''">{{ item.summary }}</div>
                        <div v-else>{{ googleTimeFormat(item.time) + ' ' + item.summary }}</div>
                    </div>
                  </div>
                  <span style="color: red" v-if="props.row.regular_holiday_saturday">{{ $t('label.regular_holiday') }}</span>
                  <span style="color: red" v-if="props.row.hourly_annual_holiday_saturday">{{ $t('label.hourly_annual_holiday') + props.row.hourly_annual_holiday_term_time_from_saturday + '-' + props.row.hourly_annual_holiday_term_time_to_saturday }}</span>
                  <span style="color: red" v-if="props.row.planned_holiday_saturday">{{ $t('label.planned_holiday') }}</span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="display: inline-block; text-align: left; position: relative; z-index: 3;" v-if="labels.update===1">
          <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanging" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
        <div style="display: inline-block; text-align: left; position: relative; z-index: 3;" v-if="labels.update===1">
          <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="!tableData.update_count" @click="doDelete">
            {{$t('button.reset')}}
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default {
    name: 'PersonalCalendar',
    mixins: [mixin],
    props: {
      isDialog: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        componentKey: 0,
        tableData: [],
        tableRowClassName: '',
        tableWrapperStyle: '',
        tableStyle: 'position: relative; z-index: 3;',
        labels: {},
        comboData: {},
        selectedRow: {},
        prevEnabled: false,
        nextEnabled: false,
        isChanging: false,
        isGoogleToken: false,
        googleApiClientId: process.env.VUE_APP_GOOGLE_API_CLIENT_ID
      }
    },
    computed: {
      screenCode () {
        return 'DB-008'
      },
      googleApiScreenCode () {
        return '00-000'
      },
      isReadOnly () {
        return this.labels.update !== 1
      }
    },
    methods: {
      getCellStyle (cell) {
        let day = ''
        let dayType = ''
        let color = null
        let isEditable = true
        if (cell.columnIndex === 0 && cell.row.day_of_sunday) {
          day = cell.row.day_of_sunday
          dayType = cell.row.day_type_of_sunday
          color = cell.row.color_sunday
          isEditable = cell.row.is_editable_sunday
        } else if (cell.columnIndex === 1 && cell.row.day_of_monday) {
          day = cell.row.day_of_monday
          dayType = cell.row.day_type_of_monday
          color = cell.row.color_monday
          isEditable = cell.row.is_editable_monday
        } else if (cell.columnIndex === 2 && cell.row.day_of_tuesday) {
          day = cell.row.day_of_tuesday
          dayType = cell.row.day_type_of_tuesday
          color = cell.row.color_tuesday
          isEditable = cell.row.is_editable_tuesday
        } else if (cell.columnIndex === 3 && cell.row.day_of_wednesday) {
          day = cell.row.day_of_wednesday
          dayType = cell.row.day_type_of_wednesday
          color = cell.row.color_wednesday
          isEditable = cell.row.is_editable_wednesday
        } else if (cell.columnIndex === 4 && cell.row.day_of_thursday) {
          day = cell.row.day_of_thursday
          dayType = cell.row.day_type_of_thursday
          color = cell.row.color_thursday
          isEditable = cell.row.is_editable_thursday
        } else if (cell.columnIndex === 5 && cell.row.day_of_friday) {
          day = cell.row.day_of_friday
          dayType = cell.row.day_type_of_friday
          color = cell.row.color_friday
          isEditable = cell.row.is_editable_friday
        } else if (cell.columnIndex === 6 && cell.row.day_of_saturday) {
          day = cell.row.day_of_saturday
          dayType = cell.row.day_type_of_saturday
          color = cell.row.color_saturday
          isEditable = cell.row.is_editable_saturday
        }
        let style = ''
        const thisDate = this.tableData.year + '/' + ('0' + this.tableData.month).slice(-2) + '/' + ('0' + day).slice(-2)
        if (this.formatToday() === thisDate) {
          // style += 'background-color: #ffff87; '
        } else if (dayType === '2' || dayType === '5' || dayType === '6' || dayType === '7' || dayType === '8' || dayType === '9') {
          style += 'background-color: #e6f0ff;'
        } else if (dayType === '4') {
          style += 'background-color: #FFEBD8;'
        } else if (dayType === '3') {
          style += 'background-color: #ffebf0;'
        } else if (!isEditable) {
          style += 'background-color: #f5f7fa; '
        } else {
          if (color === 2) {
            style += 'background-color: #e6f0ff;'
          } else if (color === 3) {
            style += 'background-color: #ffebf0;'
          } else {
            style += 'background-color: #ffffff;'
          }
        }
        return style + 'min-width: 14%; height: 90px; border: 1px solid #EEE; vertical-align: top;'
      },
      customValidate () { return true },
      prevMonth () {
        const prevDate = new Date(this.tableData.year, this.tableData.month - 1, 1)
        prevDate.setMonth(prevDate.getMonth() - 1)
        const prevYm = prevDate.getFullYear() + ('0' + (prevDate.getMonth() + 1)).slice(-2)
        this.init(prevYm)
      },
      thisMonth () {
        this.init()
      },
      nextMonth () {
        const nextDate = new Date(this.tableData.year, this.tableData.month - 1, 1)
        nextDate.setMonth(nextDate.getMonth() + 1)
        const nextYm = nextDate.getFullYear() + ('0' + (nextDate.getMonth() + 1)).slice(-2)
        this.init(nextYm)
      },
      convertDetail (detail) {
        if (!detail.data) {
          return detail
        }
        let calendar = ''
        for (const week of detail.data) {
          if (week.day_of_sunday) {
            calendar += week.day_type_of_sunday === week.org_day_type_of_sunday || week.day_type_of_sunday.length > 1 ? '0' : String(week.day_type_of_sunday)
          }
          if (week.day_of_monday) {
            calendar += week.day_type_of_monday === week.org_day_type_of_monday || week.day_type_of_monday.length > 1 ? '0' : String(week.day_type_of_monday)
          }
          if (week.day_of_tuesday) {
            calendar += week.day_type_of_tuesday === week.org_day_type_of_tuesday || week.day_type_of_tuesday.length > 1 ? '0' : String(week.day_type_of_tuesday)
          }
          if (week.day_of_wednesday) {
            calendar += week.day_type_of_wednesday === week.org_day_type_of_wednesday || week.day_type_of_wednesday.length > 1 ? '0' : String(week.day_type_of_wednesday)
          }
          if (week.day_of_thursday) {
            calendar += week.day_type_of_thursday === week.org_day_type_of_thursday || week.day_type_of_thursday.length > 1 ? '0' : String(week.day_type_of_thursday)
          }
          if (week.day_of_friday) {
            calendar += week.day_type_of_friday === week.org_day_type_of_friday || week.day_type_of_friday.length > 1 ? '0' : String(week.day_type_of_friday)
          }
          if (week.day_of_saturday) {
            calendar += week.day_type_of_saturday === week.org_day_type_of_saturday || week.day_type_of_saturday.length > 1 ? '0' : String(week.day_type_of_saturday)
          }
        }
        detail.calender = calendar
        detail.target_month = detail.month
        detail.target_year = detail.year
        detail.update_count = detail.update_count ? parseInt(detail.update_count, 10) : null
        delete detail.data
        delete detail.month
        delete detail.year
        delete detail.title
        return detail
      },
      doUpdate () {
        this.submit('update')
      },
      doDelete () {
        swal({
          text: this.$t('message.confirm_to_reset'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no')
        }).then(() => {
          this.submit('delete')
        }).catch(() => {})
      },
      submit (operation) {
        this.$refs.observer.validate().then(isValid => {
          let isCustomValid = this.customValidate()
          if (!isValid || !isCustomValid) {
            this.$emit('on-submit', this.registrationForm, false)
            this.$refs.frame.scrollTop = 0
            return
          }
          let detail = null
          if (operation === 'update') {
            detail = {...this.tableData}
            detail.update_count = this.tableData.update_count ? parseInt(this.tableData.update_count, 10) : null
          } else if (operation === 'delete') {
            detail = {}
            detail.target_year = this.tableData.year
            detail.target_month = this.tableData.month
            detail.update_count = this.tableData.update_count ? parseInt(this.tableData.update_count, 10) : null
          }
          let reqData = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: this.nullToBlank(this.convertDetail(detail))
          }
          if (!reqData.api_area.update_count) {
            reqData.api_area.update_count = null
          }
          this.isChanging = true
          this.send(`/${operation}/calendar`, reqData)
          .then(res => {
            this.componentKey += 1
            if (res.message.message_classification === 1) {
              this.isChanging = false
              if (this.isAllZero(reqData.api_area.calender)) {
                this.tableData.update_count = null
              } else {
                this.tableData.update_count = res.api_area.update_count
              }
            }
          }).catch(error => {
            this.isChanging = false
            console.error(error)
          })
        })
      },
      getSelectStyle (day) {
        let style = ''
        const thisDate = this.tableData.year + '/' + ('0' + this.tableData.month).slice(-2) + '/' + ('0' + day).slice(-2)
        if (this.formatToday() === thisDate) {
          style += 'text-bold '
        }
        return style + 'select-success'
      },
      getColor (dayType, day, color) {
        let style = ''
        const thisDate = this.tableData.year + '/' + ('0' + this.tableData.month).slice(-2) + '/' + ('0' + day).slice(-2)
        if (this.formatToday() === thisDate) {
          style += 'font-weight: bold; '
        }
        if (dayType === '1') {
          return style + 'color: #000000;'
        } else if (dayType === '2' || dayType === '5' || dayType === '6' || dayType === '7' || dayType === '8' || dayType === '9') {
          return style + 'color: #0000FF;'
        } else if (dayType === '4') {
          return style + 'color: #FF8C00;'
        } else if (dayType === '3') {
          return style + 'color: #FF0000;'
        } else {
          if (color === 1) {
            return style + 'color: #000000;'
          } else if (color === 2) {
            return style + 'color: #0000FF;'
          } else if (color === 3) {
            return style + 'color: #FF0000;'
          } else {
            return style + 'color: #000000;'
          }
        }
      },
      createCalendar (apiArea) {
        const result = {
          year: apiArea.target_year,
          month: apiArea.target_month,
          title: String(apiArea.target_year) + this.$t('label.year') + String(apiArea.target_month) + this.$t('label.month'),
          update_count: apiArea.update_count,
          data: []
        }
        let lastData = {}
        for (const dateInfo of apiArea.calendar_data) {
          let dayType = null
          switch (dateInfo.day_of_the_week) {
            case 6:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_sunday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_sunday = dayType
              lastData.regular_holiday_sunday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_sunday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_sunday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_sunday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_sunday = dateInfo.planned_holiday
              lastData.disabled_sunday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_sunday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_sunday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_sunday = dateInfo.google_calendar_list
              lastData.color_sunday = dateInfo.color
              lastData.is_editable_sunday = dateInfo.isEditable
              break
            case 0:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_monday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_monday = dayType
              lastData.regular_holiday_monday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_monday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_monday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_monday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_monday = dateInfo.planned_holiday
              lastData.disabled_monday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_monday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_monday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_monday = dateInfo.google_calendar_list
              lastData.color_monday = dateInfo.color
              lastData.is_editable_monday = dateInfo.isEditable
              break
            case 1:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_tuesday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_tuesday = dayType
              lastData.regular_holiday_tuesday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_tuesday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_tuesday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_tuesday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_tuesday = dateInfo.planned_holiday
              lastData.disabled_tuesday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_tuesday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_tuesday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_tuesday = dateInfo.google_calendar_list
              lastData.color_tuesday = dateInfo.color
              lastData.is_editable_tuesday = dateInfo.isEditable
              break
            case 2:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_wednesday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_wednesday = dayType
              lastData.regular_holiday_wednesday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_wednesday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_wednesday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_wednesday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_wednesday = dateInfo.planned_holiday
              lastData.disabled_wednesday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_wednesday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_wednesday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_wednesday = dateInfo.google_calendar_list
              lastData.color_wednesday = dateInfo.color
              lastData.is_editable_wednesday = dateInfo.isEditable
              break
            case 3:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_thursday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_thursday = dayType
              lastData.regular_holiday_thursday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_thursday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_thursday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_thursday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_thursday = dateInfo.planned_holiday
              lastData.disabled_thursday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_thursday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_thursday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_thursday = dateInfo.google_calendar_list
              lastData.color_thursday = dateInfo.color
              lastData.is_editable_thursday = dateInfo.isEditable
              break
            case 4:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_friday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_friday = dayType
              lastData.regular_holiday_friday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_friday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_friday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_friday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_friday = dateInfo.planned_holiday
              lastData.disabled_friday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_friday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_friday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_friday = dateInfo.google_calendar_list
              lastData.color_friday = dateInfo.color
              lastData.is_editable_friday = dateInfo.isEditable
              break
            case 5:
              dayType = dateInfo.ground_code ? dateInfo.ground_code : dateInfo.employee_holiday_calendar_flg ? String(dateInfo.employee_holiday_calendar_flg) : String(dateInfo.holiday_calendar_flg)
              lastData.day_of_saturday = this.formatDateWith(dateInfo.date.replace('/', '-'), 'D')
              lastData.day_type_of_saturday = dayType
              lastData.regular_holiday_saturday = dateInfo.regular_holiday
              lastData.hourly_annual_holiday_saturday = dateInfo.hourly_annual_holiday
              lastData.hourly_annual_holiday_term_time_from_saturday = dateInfo.hourly_annual_holiday_term_time_from
              lastData.hourly_annual_holiday_term_time_to_saturday = dateInfo.hourly_annual_holiday_term_time_to
              lastData.planned_holiday_saturday = dateInfo.planned_holiday
              lastData.disabled_saturday = !!dateInfo.ground_code || !dateInfo.isEditable
              lastData.holiday_list_saturday = dateInfo.ground_code ? this.comboData.ground_list : apiArea.paid_leave_payment === 1 || dayType === '8' ? this.comboData.holiday_list : this.comboData.holiday_list_exclude_plan_grant
              lastData.org_day_type_of_saturday = String(dateInfo.holiday_calendar_flg)
              lastData.google_calendar_list_saturday = dateInfo.google_calendar_list
              lastData.color_saturday = dateInfo.color
              lastData.is_editable_saturday = dateInfo.isEditable
              break
          }
          if (dateInfo.day_of_the_week === 5) {
            result.data.push(lastData)
            lastData = {}
          }
        }
        if (lastData.day_of_sunday) {
          result.data.push(lastData)
        }
        return result
      },
      init (targetYm = null) {
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        let operation = 'init'
        if (targetYm != null) {
          params.api_area.target_ym = targetYm
          operation = 'view'
        }
        this.send(`/${operation}/calendar`, params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.prevEnabled = res.api_area.last_month
          this.nextEnabled = res.api_area.next_month
          this.tableData = this.createCalendar(res.api_area)
          for (const row of this.tableData.data) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
        })
        if (this.googleApiClientId) {
          let gapiParams = {
            screen_code: this.googleApiScreenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
            }
          }
          this.send(`/google_apis/init/google_token`, gapiParams)
          .then(gapiRes => {
            this.isGoogleToken = !!gapiRes.api_area.google_token
          })
        }
      },
      async googleConnect () {
        try {
          const authCode = await this.$gAuth.getAuthCode()
          let params = {
            screen_code: this.googleApiScreenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              auth_code: authCode
            }
          }
          this.sendGoogleToken(params)
        } catch (error) {
          console.error(error)
        }
      },
      googleDisconnect () {
        let params = {
          screen_code: this.googleApiScreenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            auth_code: ''
          }
        }
        this.sendGoogleToken(params)
      },
      sendGoogleToken (params) {
        this.send(`/google_apis/update/google_token`, params)
        .then(res => {
          this.$router.go({path: this.$router.currentRoute.path, force: true})
        })
      },
      googleTimeFormat (time) {
        try {
          let allTimes = time.split('-')
          let startTimes = allTimes[0].split(':')
          let endTimes = allTimes[1].split(':')
          return `${startTimes[0]}:${startTimes[1]}-${endTimes[0]}:${endTimes[1]}`
        } catch (error) {
          return ''
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .text-bold {
    font-weight: bold;
  }
  .google-signin {
    display: inline-block;
    background: url(/static/img/buttons/btn_google_signin_light_normal_web.png);
    width: 191px;
    height: 46px;
  }
  .google-signin:hover {
    display: inline-block;
    background: url(/static/img/buttons/btn_google_signin_light_focus_web.png);
    width: 191px;
    height: 46px;
  }
  .google-signin:active {
    display: inline-block;
    background: url(/static/img/buttons/btn_google_signin_light_pressed_web.png);
    width: 191px;
    height: 46px;
  }
  .circle-fill {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #C50052;
    color: white;
    text-align: center;
    line-height: 170%;
  }
</style>
