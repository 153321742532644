<template>
  <div :data-v-step="tourSteps.split(',')[0]" :class="frameClass === '' ? 'form-group' : `form-group ${frameClass}`">
    <label>{{ isHide ? '　' : itemLabel }}</label>
    <span class="required" v-show="!isHide && rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span><a v-show="hasHelp" href="#" @click.prevent="$emit('help-clicked')" style="margin-left: 10px; color: #F00;"><span class="ti-help-alt"></span></a>
    <template v-if="addButtonFlg&&!disabled"><button type="button" class="btn btn-primary btn-sm btn-default" :data-v-step="tourSteps.split(',')[1]" @click="showAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{addButtonName==='' ? this.$t('button.new') : addButtonName}}</button></template>
    <ValidationProvider ref="provider" :debounce="100" v-slot="{ valid, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
      <div v-if="helpText">
        <small class="help-block">
          {{ helpText }}
        </small>
      </div>
      <div class="error-message-wrapper" v-show="!valid">
        <small class="text-danger">
          {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
      <div :style="isHide ? 'visibility: hidden;' : ''">
        <el-select :class="itemClasses"
                    size="large"
                    :name="itemName"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    v-model="itemValue"
                    v-tooltip.right-end="tooltip"
                    @change="changed">
          <template slot="prefix">
            <span :style="`padding-left: 5px; color: ${options && options.filter((row) => row.value === itemValue).length > 0 ? options.filter((row) => row.value === itemValue)[0].color : ''}`">{{ options && options.filter((row) => row.value === itemValue).length > 0 ? options.filter((row) => row.value === itemValue)[0].color === '#999' ? '○' : options.filter((row) => row.value === itemValue)[0].color ? '●' : '' : '' }}</span>
          </template>
          <el-option v-for="option in options"
                      class="select-success"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
            <span :style="`color: ${option.color}`">{{ option.color === '#999' ? '○&nbsp;' : option.color ? '●&nbsp;' : '' }}</span>
            {{ option.label }}
          </el-option>
        </el-select>{{unit ? '&nbsp;' + unit : ''}}
        <template v-if="hasModal">
          <button type="button" :disabled="disabled" class="btn btn-sm btn-default btn-warning" :data-v-step="tourSteps.split(',')[1]" @click="$emit('show' + snakeToPascal(itemName))">{{buttonLabel}}</button>
        </template>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: [String, Number],
      itemName: String,
      rules: String,
      itemLabel: String,
      options: Array,
      modalName: String,
      disabled: Boolean,
      buttonLabel: String,
      classes: String,
      placeholder: String,
      frameClass: {
        type: String,
        require: false,
        'default': ''
      },
      isHide: {
        type: Boolean,
        require: false,
        'default': false
      },
      hasHelp: {
        type: Boolean,
        require: false,
        'default': false
      },
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      helpText: {
        type: String,
        require: false,
        'default': ''
      },
      tourSteps: {
        type: String,
        require: false,
        'default': ','
      },
      tooltip: {
        type: String,
        require: false,
        'default': ''
      },
      addButtonFlg: {
        type: Boolean,
        require: false,
        'default': false
      },
      addButtonName: {
        type: String,
        require: false,
        'default': ''
      }
    },
    methods: {
      snakeToPascal (snake) {
        let answer = ''
        for (const row of snake.split('_')) {
          answer += row.substring(0, 1).toUpperCase() + row.substring(1)
        }
        console.log(answer)
        return answer
      },
      changed () {
        this.$emit('input', this.itemValue)
        this.$emit('change')
      },
      showAdd () {
        this.$emit('showAdd')
      }
    },
    data () {
      return {
        params: {},
        itemValue: null,
        itemClasses: 'select-success'
      }
    },
    computed: {
      hasModal () {
        return this.modalName != null && this.modalName !== ''
      },
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
        this.itemClasses = 'select-success ' + this.classes
      }
    },
    mounted () {
      this.itemValue = this.value
      this.itemClasses = 'select-success ' + this.classes
    }
  }
</script>

<style>
  .el-select .el-input__prefix {
    padding-top: 10px;
  }
</style>
