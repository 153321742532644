import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import VTooltip from 'v-tooltip'
import PPagination from '@/components/UIComponents/Pagination'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import vSelect from 'vue-select'
import mixin from '@/lib/mixin'
import NumberOfEmployeesSetting from './NumberOfEmployeesSetting'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(VTooltip)

export default {
  name: 'ShiftSettingSearchMixin',
  mixins: [mixin],
  components: {
    PPagination,
    ValidationText,
    ValidationTextNumber,
    ValidationSelect,
    vSelect,
    NumberOfEmployeesSetting
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      workScheduleList: undefined,
      searchCond: {
        office_code: '',
        target_year: '',
        target_month: ''
      },
      detail: undefined,
      days: undefined,
      shiftBudgetList: undefined,
      showOperation: true,
      showPrint: true,
      showEmployee: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: '',
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      maxHeight: 440,
      scrollRight: false,
      showNotify: false
    }
  },
  computed: {
    screenCode () {
      return '06-004'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged || this.labels.update !== 1
    },
    pagedData () {
      return this.detail.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.detail.shift_setting_info_list.length
      return this.detail.shift_setting_info_list.length
    },
    differentGrossProfit () {
      if (!this.detail.planned_gross_profit || !this.detail.gross_profit_plan) return ''
      return this.formatFloat(this.detail.planned_gross_profit - this.detail.gross_profit_plan, 1)
    },
    differentLaborCost () {
      if (!this.detail.planned_labor_cost || !this.detail.personnel_cost_plan) return ''
      return parseInt(this.detail.planned_labor_cost.replaceAll(',', ''), 10) - parseInt(this.detail.personnel_cost_plan.replaceAll(',', ''), 10)
    },
    shiftSettingTerm () {
      if (!this.detail.shift_setting_info_list) return ''
      const shiftSettingTerm = []
      shiftSettingTerm.push(this.labels.office)
      shiftSettingTerm.push('：')
      shiftSettingTerm.push(this.getLabel(this.getSelectName(this.detail.office_code, this.comboData.office_list)))
      shiftSettingTerm.push('　')
      shiftSettingTerm.push(this.$t('label.term'))
      shiftSettingTerm.push('：')
      shiftSettingTerm.push(this.detail.term_from)
      shiftSettingTerm.push(' - ')
      shiftSettingTerm.push(this.detail.term_to)
      return shiftSettingTerm.join('')
    }
  },
  methods: {
    showNumberOfEmployeesSetting (index, row) {
      this.index = index
      this.$modal.show('numberOfEmployeesSetting')
    },
    hideNumberOfEmployeesSetting (detail) {
      this.$modal.hide('numberOfEmployeesSetting')
      if (detail.changed) {
        this.applyNumberOfEmployees(detail)
      }
    },
    applyNumberOfEmployees (detail) {
      // 日の合計、月の合計、予定人件費、予定粗利計算
      for (let i = 0; i < this.detail.shift_setting_info_list.length; i++) {
        if (i !== this.index) continue
        const shiftBudget = this.detail.shift_setting_info_list[i]
        for (let index = 0; index < shiftBudget.days.length; index++) {
          const targetDate = `${this.detail.target_year}/${shiftBudget.days[index].date.substring(0, 5)}`
          const weekday = parseInt(this.formatDateWith(targetDate, 'd'), 10)
          if (detail.apply_weekday.items[(weekday + 6) % 7].value) {
            shiftBudget.days[index].number_of_people = detail.number_of_employees
          }
          this.calcDayTotal(index, shiftBudget)
        }
        this.calcMonthTotal(shiftBudget)
      }
      this.calcSum()
      this.calcPlan()
    },
    toggleScrollRight () {
      console.log(this.$refs.table.$refs.bodyWrapper)
      console.log(this.$refs.table.$refs.bodyWrapper.firstChild.style.width)
      if (this.scrollRight) {
        this.$refs.table.$refs.bodyWrapper.scrollLeft = 0
      } else {
        this.$refs.table.$refs.bodyWrapper.scrollLeft = parseInt(this.$refs.table.$refs.bodyWrapper.firstChild.style.width, 10) - 100
      }
      this.scrollRight = !this.scrollRight
    },
    clearValues () {
      // 日の合計、月の合計、予定人件費、予定粗利計算
      for (const shiftBudget of this.detail.shift_setting_info_list) {
        for (let index = 0; index < shiftBudget.days.length; index++) {
          shiftBudget.days[index].number_of_people = '0'
          this.calcDayTotal(index, shiftBudget)
        }
        this.calcMonthTotal(shiftBudget)
      }
      this.calcSum()
      this.calcPlan()
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      // 事業所
      this.searchCond.office_code = this.comboData.office_list[0].value
      let today = new Date()
      // 期間に来月を設定
      if (today.getMonth() !== 11) {
        this.searchCond.target_year = today.getFullYear()
        this.searchCond.target_month = today.getMonth() + 2
      // 12月の場合、翌年の1月を設定
      } else {
        this.searchCond.target_year = today.getFullYear() + 1
        this.searchCond.target_month = 1
      }

      this.workScheduleList = {}
      // 勤務体系に稼働時間を設定
      for (const workSchedule of res.common_area.combo_data.work_schedule_list) {
        workSchedule.job_time = workSchedule.job_time / 60
        this.workScheduleList[workSchedule.value] = workSchedule
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      // 更新で非活性になったのを解除
      this.isChanged = false
      // カンマ付与
      this.formatComma(this.detail, 'sales_plan')
      this.formatComma(this.detail, 'planned_labor_cost')
      // Stringに変換
      this.detail.gross_profit_plan = String(this.detail.gross_profit_plan)
      // 人件費計算
      this.calcPersonnelCostPlan()
      // メッセージ追加
      this.detail.message = ''

      // days作成(ループ用)
      this.days = JSON.parse(JSON.stringify(this.detail.shift_setting_info_list[0].days))
      // days編集
      for (let index = 0; index < this.days.length; index++) {
        this.days[index].index = index
      }

      // shiftBudgetList作成(列追加用)
      this.shiftBudgetList = JSON.parse(JSON.stringify(this.detail.shift_setting_info_list[0]))
      // shiftBudgetList初期化
      this.shiftBudgetList.business_type = ''
      this.shiftBudgetList.unit_price = '0'
      this.shiftBudgetList.work_schedule_code = ''
      this.shiftBudgetList.total = 0
      this.shiftBudgetList.delete_check = false
      this.shiftBudgetList.business_type_error = ''
      this.shiftBudgetList.work_schedule_code_error = ''
      this.shiftBudgetList.labor_cost = 0
      this.shiftBudgetList.confirm_flag = false
      for (const day of this.shiftBudgetList.days) {
        day.number_of_people = '0'
        day.unit_price = '0'
        day.daily_cost = 0
      }

      // detail.shift_setting_info_list編集
      for (const shiftBudget of this.detail.shift_setting_info_list) {
        // 時給再設定
        shiftBudget.unit_price = shiftBudget.days[0].unit_price
        // カンマ付与
        this.formatComma(shiftBudget, 'unit_price')
        let index = 0
        for (const day of shiftBudget.days) {
          // カンマ付与
          this.formatComma(day, 'number_of_people')
          // 日の合計計算
          this.calcDayTotal(index, shiftBudget)
          index++
        }
        // 削除フラグ追加
        shiftBudget.delete_check = false
        // エラー追加
        shiftBudget.business_type_error = ''
        shiftBudget.work_schedule_code_error = ''
        // 1カ月の合計金額追加
        this.calcMonthTotal(shiftBudget)
      }
      // 合計人数、合計人件費
      for (const day of this.shiftBudgetList.days) {
        day.sum_of_people = 0
        day.sum_of_daily_cost = 0
      }
      this.detail.shift_setting_info_list.push(JSON.parse(JSON.stringify(this.shiftBudgetList)))
      this.detail.shift_setting_info_list.push(JSON.parse(JSON.stringify(this.shiftBudgetList)))
      // 合計の計算
      this.calcSum()
      // 予定人件費、予定粗利計算
      this.calcPlan()
    },
    // 合計の計算
    calcSum () {
      for (var i = 0; i < this.shiftBudgetList.days.length; i++) {
        var sumOfPeople = 0
        var sumOfDailyCost = 0
        for (var j = 0; j < this.detail.shift_setting_info_list.length - 2; j++) {
          const shiftSettingInfo = this.detail.shift_setting_info_list[j]
          sumOfPeople += parseInt(shiftSettingInfo.days[i].number_of_people, 10)
          sumOfDailyCost += parseInt(shiftSettingInfo.days[i].daily_cost, 10)
        }
        this.detail.shift_setting_info_list[this.detail.shift_setting_info_list.length - 2].days[i].sum_of_people = sumOfPeople
        this.detail.shift_setting_info_list[this.detail.shift_setting_info_list.length - 1].days[i].sum_of_people = sumOfPeople
        this.detail.shift_setting_info_list[this.detail.shift_setting_info_list.length - 1].days[i].sum_of_daily_cost = sumOfDailyCost
        this.shiftBudgetList.days[i].sum_of_people = sumOfPeople
        this.shiftBudgetList.days[i].sum_of_daily_cost = sumOfDailyCost
      }
    },
    // セル結合
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex >= this.detail.shift_setting_info_list.length - 2) {
        if (rowIndex === this.detail.shift_setting_info_list.length - 2 && columnIndex === 0) {
          return [2, 4]
        } else if (columnIndex < 4) {
          return [0, 0]
        }
      }
    },
    // セルのStyle
    getCellStyle ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.detail.shift_setting_info_list.length - 3) {
        return 'border-bottom: 3px solid #ddd;'
      } else if (rowIndex >= this.detail.shift_setting_info_list.length - 2 && columnIndex < 5) {
        return 'background-color: #eee; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;'
      }
    },
    // 勤務時間
    getWorkScheduleLabel (row) {
      const workSchedule = this.workScheduleList[row.work_schedule_code]
      return workSchedule && workSchedule.job_start ? `${workSchedule.job_start} - ${workSchedule.job_end}` : '　'
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      return searchCond
    },
    // カンマを付与
    formatComma (detail, item) {
      if (detail[item] || detail[item] === 0) {
        if (Number.isNaN(detail[item])) {
          return
        }
        detail[item] = this.formatNumber(String(detail[item]))
      }
    },
    // カンマ付の数値を数値に変換
    calcNum (item) {
      if (item) {
        item = Number(String(item).replaceAll(',', ''))
      } else {
        item = 0
      }
      return item
    },
    // 独自バリデーション用
    customValidate () {
      let isSuccess = true
      let pkList = []
      for (var i = 0; i < this.detail.shift_setting_info_list.length - 2; i++) {
        const shiftBudget = this.detail.shift_setting_info_list[i]
        // 削除がtrueの場合、次の要素を処理
        if (shiftBudget.delete_check) {
          continue
        }
        // 職種は必須
        if (shiftBudget.business_type === '') {
          shiftBudget.business_type_error = this.$t('message.field_required_simple')
          isSuccess = false
        } else {
          shiftBudget.business_type_error = ''
        }
        // 勤務体系は必須
        if (shiftBudget.work_schedule_code === '') {
          shiftBudget.work_schedule_code_error = this.$t('message.field_required_simple')
          isSuccess = false
        } else {
          shiftBudget.work_schedule_code_error = ''
        }
        // PK(職種+勤務体系)を設定
        pkList.push(shiftBudget.business_type + '_' + shiftBudget.work_schedule_code)
      }
      // PKListと重複削除したPKListの件数が一致しない場合、エラー
      if (pkList.length !== new Set(pkList).size) {
        this.detail.message = this.$t('message.shift_pk_check')
        isSuccess = false
      } else {
        this.detail.message = ''
      }

      return isSuccess
    },
    // 送信データ調整用
    convertDetail (detail) {
      // 数値に変換
      this.convertNum(detail, 'sales_plan')
      this.convertNum(detail, 'personnel_cost_plan')
      this.convertNum(detail, 'planned_labor_cost')

      // floatに変換
      this.convertFloat(detail, 'gross_profit_plan')
      this.convertFloat(detail, 'planned_gross_profit')

      // detail.shift_setting_info_list編集
      let shiftBudgetList = []
      for (var i = 0; i < detail.shift_setting_info_list.length - 2; i++) {
        const shiftBudget = detail.shift_setting_info_list[i]
        // 削除がtrueの場合、次の要素を処理
        if (shiftBudget.delete_check) {
          continue
        }
        // 数値に変換
        this.convertNum(shiftBudget, 'unit_price')
        for (const day of shiftBudget.days) {
          // 数値に変換
          this.convertNum(day, 'number_of_people')
          this.convertNum(day, 'unit_price')
          delete day.sum_of_daily_cost
          delete day.sum_of_people
        }

        // 不要な項目削除
        delete shiftBudget.delete_check
        delete shiftBudget.total
        delete shiftBudget.business_type_error
        delete shiftBudget.work_schedule_code_error
        delete shiftBudget.labor_cost
        delete shiftBudget.confirm_flag

        shiftBudgetList.push(shiftBudget)
      }
      // 編集したshift_setting_info_listを設定
      detail.shift_setting_info_list = shiftBudgetList

      // 不要な項目削除
      delete detail.message
      delete detail.day_info
      delete detail.term_from
      delete detail.term_to

      return detail
    },
    // 数値に変換
    convertNum (detail, item) {
      if (detail[item]) {
        detail[item] = Number(String(detail[item]).replaceAll(',', ''))
      } else {
        detail[item] = 0
      }
    },
    // floatに変換
    convertFloat (detail, item) {
      if (detail[item]) {
        detail[item] = parseFloat(String(detail[item]).replaceAll(',', ''))
      } else {
        detail[item] = 0
      }
    },
    officeCodeChanged () {},
    // 売上計画入力
    inputSalesPlan () {
      // 人件費計画、予定人件費、予定粗利計算
      this.calcPersonnelCostPlan()
      this.calcPlan()
    },
    // 粗利計画入力
    inputGrossProfitPlan () {
      // 人件費計画計算
      this.calcPersonnelCostPlan()
    },
    // 削除チェック
    isDeleteCheckChanged () {
      // 予定人件費、予定粗利計算
      this.calcPlan()
    },
    // 勤務体系変更
    workScheduleCodeChanged (shiftBudget) {
      // 日の合計、月の合計、予定人件費、予定粗利計算
      for (let index = 0; index < shiftBudget.days.length; index++) {
        this.calcDayTotal(index, shiftBudget)
      }
      this.calcMonthTotal(shiftBudget)
      this.calcSum()
      this.calcPlan()
    },
    // 時給入力
    inputUnitPrice (shiftBudget) {
      // 日の合計、月の合計、予定人件費、予定粗利計算
      for (let index = 0; index < shiftBudget.days.length; index++) {
        this.calcDayTotal(index, shiftBudget)
      }
      this.calcMonthTotal(shiftBudget)
      this.calcSum()
      this.calcPlan()
    },
    // 人数入力
    inputNumberOfPeople (index, shiftBudget) {
      // 日の合計、月の合計、予定人件費、予定粗利計算
      this.calcDayTotal(index, shiftBudget)
      this.calcMonthTotal(shiftBudget)
      this.calcSum()
      this.calcPlan()
    },
    // 人件費計画計算
    calcPersonnelCostPlan () {
      if (this.detail.sales_plan !== '' && this.detail.sales_plan !== '0' && this.detail.gross_profit_plan !== '' && this.detail.gross_profit_plan !== '0') {
        this.detail.personnel_cost_plan = String(Number(String(this.detail.sales_plan).replaceAll(',', '')) * (100 - parseFloat(String(this.detail.gross_profit_plan).replaceAll(',', ''))) / 100)
        this.formatComma(this.detail, 'personnel_cost_plan')
      } else {
        this.detail.personnel_cost_plan = '0'
      }
    },
    // 日の合計計算
    calcDayTotal (index, shiftBudget) {
      // 人数が存在
      if (shiftBudget.days[index].number_of_people !== '') {
        // 勤務体系、時給が存在
        if (shiftBudget.work_schedule_code !== '' && this.workScheduleList[shiftBudget.work_schedule_code].job_time !== 0 && shiftBudget.unit_price !== '' && shiftBudget.unit_price !== '0') {
          // 時給 × 稼働時間 × 人数
          shiftBudget.days[index].daily_cost = Number(String(shiftBudget.unit_price).replaceAll(',', '')) * this.workScheduleList[shiftBudget.work_schedule_code].job_time * Number(String(shiftBudget.days[index].number_of_people).replaceAll(',', ''))
        } else {
          shiftBudget.days[index].daily_cost = 0
        }
      } else {
        shiftBudget.days[index].number_of_people = '0'
        shiftBudget.days[index].daily_cost = 0
        this.componentKey += 1
      }
    },
    // 一カ月の合計金額計算
    calcMonthTotal (shiftBudget) {
      let total = 0
      for (const day of shiftBudget.days) {
        total = total + this.calcNum(day.daily_cost)
      }
      shiftBudget.total = total
    },
    // 予定人件費、予定粗利計算
    calcPlan () {
      // 時給 * 勤務体系の勤務時間 * 人数 の日付、職種の合算 = 予定人件費
      let plannedLaborCost = 0
      for (const shiftBudget of this.detail.shift_setting_info_list) {
        // 削除にチェックが入っている場合、次の要素を処理
        if (shiftBudget.delete_check || !shiftBudget.total) {
          continue
        }
        plannedLaborCost = plannedLaborCost + shiftBudget.total
      }
      this.detail.planned_labor_cost = plannedLaborCost
      this.formatComma(this.detail, 'planned_labor_cost')
      const salesPlan = this.calcNum(this.detail.sales_plan)
      // 売上計画または予定人件費が0の場合、0.0
      if (salesPlan === 0) {
        this.detail.planned_gross_profit = '0.0'
      } else {
        // 100 - 予定人件費 / 売上計画 * 100 = 予定粗利
        let plannedGrossProfit = (Math.floor((100 - plannedLaborCost / salesPlan * 100) * 10) / 10).toFixed(1)
        if (plannedGrossProfit > 100.0) {
          plannedGrossProfit = 100.0
        } else if (plannedGrossProfit < -100.0) {
          plannedGrossProfit = -100.0
        }
        this.detail.planned_gross_profit = String(plannedGrossProfit)
      }
    },
    doUpdate () {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.componentKey += 1
          return
        }
        let detail = JSON.parse(JSON.stringify(this.detail))
        detail.update_count = Number(this.detail.update_count)
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        this.message = {}
        this.showNotify = false
        this.send('/update/shift_budget', reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.showNotify = true
            this.detail.update_count = res.api_area.update_count
            this.updateResultByResponse(res)
            // this.isChanged = true
            // this.showSearchCondition = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/shift_budget/list', reqData)
      .then(res => {
        this.showNotify = false
        this.updateResultByResponse(res)
      })
    },
    updateResultByResponse (res) {
      this.pagination.currentPage = 1
      this.detail = res.api_area
      let labels = this.getLabels(res.common_area)
      this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
      this.labels = {...this.labels, ...labels}
      this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
      for (const row of this.detail.shift_setting_info_list) {
        for (const entry of Object.entries(row)) {
          if (entry[1] === null) {
            row[entry[0]] = ''
          }
        }
      }
      this.afterSearch(res)
      if (this.detail.shift_setting_info_list.length > 0 && this.showSearchCondition) {
        this.toggleSearchCondition()
      }
      this.$nextTick(() => {
        let width = 0
        if (!this.$refs.table) return
        for (const child of this.$refs.table.$children) {
          if (child.width) {
            width += parseInt(child.width, 10)
          }
        }
        const maxWidth = window.innerWidth - 360
        if (width > maxWidth) {
          width = maxWidth
        }
        this.$refs.pager.style.width = width + 'px'
        this.maxHeight = window.innerHeight - 560
        this.componentKey += 1
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/shift_budget/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      } else if (row.update_date) {
        if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
          return 'update-row'
        }
      }
      return ''
    },
    setImage () {
      const self = this
      for (const row of this.detail.shift_setting_info_list) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    },
    replaceDate (date) {
      return date.replaceAll('-', '/').substring(5)
    },
    getJobTime (employee, rowEmployeeList) {
      let jobTime = ''
      for (const rowEmployee of rowEmployeeList) {
        if (rowEmployee.employee_code === employee.employee_code) {
          if (rowEmployee.work_status === 2) {
            jobTime = jobTime + '×'
          }
          jobTime = jobTime + rowEmployee.job_start + '~' + rowEmployee.job_end
          break
        }
      }
      return jobTime
    },
    // 行追加
    addRow () {
      this.detail.shift_setting_info_list.push(JSON.parse(JSON.stringify(this.shiftBudgetList)))
      this.$nextTick(() => {
        let width = 0
        if (!this.$refs.table) return
        for (const child of this.$refs.table.$children) {
          if (child.width) {
            width += parseInt(child.width, 10)
          }
        }
        const maxWidth = window.innerWidth - 360
        if (width > maxWidth) {
          width = maxWidth
        }
        this.$refs.pager.style.width = width + 'px'
        const maxHeight = this.maxHeight
        this.maxHeight = window.innerHeight - 560
        if (maxHeight !== this.maxHeight) {
          this.componentKey += 1
        }
      })
    }
  },
  created () {
    this.init()
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(year)
    }
  }
}
