<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{$t('label.send_shift')}}</h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <h5>
              <strong>{{params.target_month.substring(0, 4)}}{{$t('label.year')}}{{this.zeroSuppress(params.target_month.substring(4))}}{{$t('label.monthly')}}</strong>
            </h5>
            <ValidationObserver ref="observer">
              <label>{{$t('label.sending_method')}}</label>
              <br />
              <p-radio
                v-model="detail.send_type"
                label="1"
                :inline="true"
                :disabled="isReadOnly"
                @click="selectButtonClick(true)"
              >{{$t('label.send_message_individually')}}</p-radio>
              <p-radio
                v-model="detail.send_type"
                label="2"
                :inline="true"
                :disabled="isReadOnly"
                @click="selectButtonClick(true)"
              >{{$t('label.send_whole_shift')}}</p-radio>
            </ValidationObserver>
            <!-- 一括ボタン -->
            <table style="margin: 10px 0;">
              <tr>
                <td colspan="5" width="450">
                  <button
                    type="button"
                    class="btn btn-sm btn-default btn-primary"
                    :disabled="isReadOnly"
                    @click="selectButtonClick()"
                  >{{this.selectButtonLabel}}</button>
                </td>
              </tr>
            </table>
            <div class="error-message-wrapper" v-show="errormessage !== ''">
              <small class="text-danger">
                {{ errormessage }}
              </small>
            </div>
            <!-- Table開始 -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"
              class="table-width"
              border
              :data="tableData"
              :empty-text="$t('label.no_data')"
              ref="table"
            >
              <!-- 送信 -->
              <el-table-column width="50" :label="$t('label.send')" align="center">
                <template slot-scope="props">
                  <div style="text-align: center;" v-show="!((!props.row.is_personal_checkbox_available && String(detail.send_type) === '1') || (!props.row.is_shift_checkbox_available && String(detail.send_type) === '2'))">
                    <input
                      type="checkbox"
                      :disabled="isReadOnly"
                      v-model="props.row.selected"
                      class="gray"
                    />
                  </div>
                </template>
              </el-table-column>
              <!-- 従業員名 -->
              <el-table-column
                :width="150"
                :label="labels.employee_ryaku === undefined ? '' : labels.employee_ryaku"
                align="center"
              >
                <template slot-scope="props">
                  <div style="text-align: left;">{{props.row.employee_name}}</div>
                  <div style="text-align: left; color: gray;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 職種 -->
              <el-table-column
                :width="150"
                :label="labels.business_ryaku === undefined ? '' : labels.business_ryaku"
                align="center"
              >
                <template slot-scope="props">
                  <div style="text-align: left;">{{props.row.business_name}}</div>
                </template>
              </el-table-column>
              <!-- メールアドレス -->
              <el-table-column
                :width="270"
                :label="labels.mail_address === undefined ? '' : labels.mail_address"
                align="center"
              >
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <template v-if="props.row.mail_address">{{props.row.mail_address}}</template>
                    <!-- ブランクの場合、メールアドレスが設定されていません。を表示 -->
                    <template v-else>{{$t('label.mail_address_notset')}}</template>
                  </div>
                </template>
              </el-table-column>
              <!-- 個別送信済み -->
              <el-table-column
                :width="150"
                :label="$t('label.last_send_date_personal') === undefined ? '' : $t('label.last_send_date_personal')"
                align="center"
              >
                <template slot-scope="props">
                  <div style="text-align: left;">{{props.row.personal_send_date}}</div>
                </template>
              </el-table-column>
              <!-- シフト表送信済み -->
              <el-table-column
                :width="150"
                :label="$t('label.last_send_shift_schedule') === undefined ? '' : $t('label.last_send_shift_schedule')"
                align="center"
              >
                <template slot-scope="props">
                  <div style="text-align: left;">{{props.row.shift_send_date}}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <template v-if="!this.isChanged">
        <div style="text-align: left;">
          <button
            type="button"
            v-if="!send_flg"
            class="btn btn-sm btn-default btn-primary"
            @click="doSend"
          >{{$t('button.send')}}</button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import DesiredShiftSendMixin from '@/components/Dashboard/Views/Monthly/DesiredShiftSendMixin'

  export default {
    name: 'DesiredShiftSend',
    mixins: [DesiredShiftSendMixin]
  }
</script>
<style scoped>
</style>
