import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

export default {
  name: 'BankTransferListlMixin',
  mixins: [mixin],
  components: {
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        term_from: '',
        salary_category: ''
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      salary_closing_data: [{}],
      roles: {
        print: 0
      },
      bonusPaySlipName: ''
    }
  },
  computed: {
    screenCode () {
      return 'SS-023'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.salary_closing_data = res.api_area.salary_closing_data
      this.roles = res.common_area.button_role // 権限
      this.bonusPaySlipName = res.api_area.bonus_pay_slip_name
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    init () {
      let data = {
        screen_code: 'SS-023',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_date,
          salary_bonus_classification: this.params.salary_bonus_classification,
          salary_closing_code: this.params.salary_closing_code
        }
      }
      this.send('/init/bank_transfer_list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    doPrint () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_date,
          salary_bonus_classification: this.params.salary_bonus_classification,
          salary_closing_code: this.params.salary_closing_code
        }
      }
      this.send('/print/bank_transfer_list', data)
      .then(() => {
        swal({
          text: this.$t('message.report_confirmation'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.openReportPage()
        })
      })
    }
  },
  created () {
    this.init()
  }
}
