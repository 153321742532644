<template>
  <div>
    <h5>{{office.office_name}}</h5>
    <!-- テーブル表示 -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="office.employee_list"
              style="width: 530px">
      <!-- 従業員 -->
      <el-table-column min-width="100" :label="labels.employee === undefined ? '' : labels.employee">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <small>{{props.row.employee_code}}</small>
            <br>
            {{props.row.employee_name}}
          </div>
          <div>
          </div>
        </template>
      </el-table-column>
      <!-- 任期を定めて指定された委員 -->
      <el-table-column
        :min-width="220"
        width="220px"
        :label="labels.is_term">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.is_term" class="gray" :disabled="!!props.row.is_etc_term" />
          </div>
        </template>
      </el-table-column>
      <!-- その他の委員 -->
      <el-table-column
        :min-width="120"
        width="120px"
        :label="labels.is_etc_term">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.is_etc_term" class="gray" :disabled="!!props.row.is_term" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <br>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Object,
      itemName: String,
      classes: String,
      labels: Object
    },
    data () {
      return {
        office: {}
      }
    },
    watch: {
      value () {
        this.office = this.value
      }
    },
    mounted () {
      this.office = this.value
    }
  }
</script>
