<template>
  <div>
    <modal name="approver" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :is-modal="true" :company-code="selectedCompanyCode" @close="hideApproverSearch"/>
      </div>
    </modal>
    <div style="margin-bottom: 10px;" v-if="!disabled">
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{$t('button.add_row')}}</button>
    </div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      :data="tableData"
      :empty-text="$t('label.no_data')">
      <!-- 削除チェックボックス -->
      <el-table-column
        width="60"
        :label="$t('label.delete')"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="disabled"/>
          </div>
        </template>
      </el-table-column>
      <!-- 順番 -->
      <el-table-column
        prop="turn"
        width="60"
        :label="labels.route_number_ryaku"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <span>{{props.row.activity_code}}</span>
          </div>
        </template>
      </el-table-column>
      <!-- 会社 -->
      <el-table-column
        width="210"
        :label="labels.company_ryaku"
        align="center">
        <template slot-scope="props">
          <div>
            <el-select class="select-success length12"
                        size="large"
                        :disabled="disabled"
                        placeholder=""
                        v-model="props.row.approverl_company_code"
                        @change="companyChanged(props.row)">
              <el-option v-for="company in comboData.company_list"
                          class="select-success"
                          :value="company.value"
                          :label="company.label"
                          :key="company.value">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <!-- 利用権限 -->
      <el-table-column
        width="210"
        :label="labels.role_ryaku"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <el-select class="select-success length12"
                      size="large"
                      :disabled="disabled"
                      placeholder=""
                      v-model="props.row.approverl_role_code"
                      @change="roleSelected(props.row)">
              <el-option v-for="role in props.row.role_list"
                          class="select-success"
                          :value="role.role_code"
                          :label="role.role_name"
                          :key="role.role_code">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <!-- 従業員 -->
      <el-table-column
        width="540"
        :label="labels.employee_ryaku"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="text"
                    class="form-control length10"
                    style="display: inline-block; background-color: transparent;"
                    :disabled="disabled"
                    maxlength="10"
                    v-model="props.row.approverl_employee_code"
                    @blur="approverSelected(props.$index, 1)">
            <input type="text" 
                    class="form-control length20"
                    style="display: inline-block; background-color: transparent;"
                    :disabled="disabled"
                    maxlength="30"
                    v-model="props.row.approverl_employee_name"
                    @blur="approverSelected(props.$index, 2)">
            <button type="button" v-if="!disabled" class="btn btn-sm btn-default btn-warning" @click="showApproverSearch(props.$index)" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
          </div> 
        </template>
      </el-table-column>
      <!-- 部署 -->
      <el-table-column
        width="210"
        :label="labels.group_ryaku"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <el-select class="select-success length12"
                        size="large"
                        :disabled="disabled"
                        placeholder=""
                        v-model="props.row.approverl_group_code"
                        @change="groupSelected(props.row)">
              <el-option v-for="group in props.row.group_list"
                          class="select-success"
                          :value="group.group_code"
                          :label="group.group_name"
                          :key="group.group_code">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <!-- 権限 -->
      <el-table-column
        width="120"
        :label="labels.authority_ryaku"
        align="center">
        <template slot-scope="props">
          <div>
            <el-select class="select-success width100"
                        size="large"
                        :disabled="disabled"
                        placeholder=""
                        v-model="props.row.function">
              <el-option v-for="authority in comboData.approval_function_list"
                          class="select-success"
                          :value="authority.value"
                          :label="authority.label"
                          :key="authority.value">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      EmployeeSearch
    },
    props: {
      value: Array,
      comboData: Object,
      labels: Object,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        searchCond: {},
        currentIndex: 0,
        tableData: [],
        selectedCompanyCode: ''
      }
    },
    watch: {
      value () {
        this.tableData = this.value
        if (this.tableData && this.tableData.length === 0) {
          this.addRow()
        }
        for (const row of this.tableData) {
          this.updateList(row, false)
        }
      }
    },
    mounted () {
      this.tableData = this.value
      if (this.tableData && this.tableData.length === 0) {
        this.addRow()
      }
      for (const row of this.tableData) {
        this.updateList(row, false)
      }
    },
    methods: {
      companyChanged (row) {
        row.approverl_group_code = undefined
        row.approverl_group_name = undefined
        row.approverl_role_code = undefined
        row.approverl_role_name = undefined
        row.approverl_employee_code = undefined
        row.approverl_employee_name = undefined
        this.updateList(row, true)
      },
      updateList (row, forceCreate) {
        if (!this.comboData || !this.comboData.mapping_company_and_group) return
        if (row.group_list && !forceCreate) {
          const groupList = []
          for (const group of row.group_list) {
            groupList.push({
              group_code: group.group_code ? group.group_code : group.value,
              group_name: group.group_name ? group.group_name : group.label
            })
          }
          row.group_list = groupList
        } else {
          row.group_list = []
          for (const company of this.comboData.mapping_company_and_group) {
            if (company.company_code === row.approverl_company_code) {
              row.group_list = company.group_info
              break
            }
          }
        }
        row.role_list = []
        for (const company of this.comboData.mapping_company_and_role) {
          if (company.company_code === row.approverl_company_code) {
            row.role_list = company.role_info
            break
          }
        }
      },
      showApproverSearch (index) {
        // if (!this.tableData[index].approverl_company_code) return
        this.currentIndex = index
        this.selectedCompanyCode = this.tableData[index].approverl_company_code
        this.$modal.show('approver')
      },
      hideApproverSearch (selected) {
        if (selected) {
          // this.tableData[this.currentIndex].approverl_company_code = selected.company_code
          // this.tableData[this.currentIndex].approverl_company_name = selected.company_name
          this.tableData[this.currentIndex].approverl_company_code = this.$store.state.loginUser.companyCode
          this.tableData[this.currentIndex].approverl_company_name = this.$store.state.loginUser.companyName
          this.tableData[this.currentIndex].approverl_group_code = selected.group_list[0].group_code
          this.tableData[this.currentIndex].approverl_group_name = selected.group_list[0].group_name
          this.tableData[this.currentIndex].approverl_employee_code = selected.employee_code
          this.tableData[this.currentIndex].approverl_employee_name = selected.employee_name
          this.tableData[this.currentIndex].group_list = selected.group_list
          this.tableData[this.currentIndex].approverl_role_code = ''
          this.tableData[this.currentIndex].approverl_role_name = ''
        }
        this.$modal.hide('approver')
      },
      roleSelected (row) {
        if (row.approverl_role_code.length > 0) {
          row.approverl_group_code = ''
          row.approverl_group_name = ''
          row.approverl_employee_code = ''
          row.approverl_employee_name = ''
        }
      },
      groupSelected (row) {
        if (row.approverl_group_code.length > 0) {
          row.approverl_role_code = ''
          row.approverl_role_name = ''
        }
      },
      approverSelected (index, searchType) {
        if ((searchType === 1 && this.tableData[index].approverl_employee_code.length === 0) ||
        (searchType === 2 && this.tableData[index].approverl_employee_name.length === 0)) {
          this.tableData[index].approverl_group_code = ''
          this.tableData[index].approverl_group_name = ''
          this.tableData[index].approverl_employee_code = ''
          this.tableData[index].approverl_employee_name = ''
          this.tableData[index].group_list = []
          return
        }
        if (!this.canShow('/SS/menuSSScreen018')) return
        let searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          group_code: this.$store.state.loginUser.groupCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: '',
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: false
          }
        }
        if (searchType === 1) {
          searchCond.api_area.employee_code = this.tableData[index].approverl_employee_code
        } else {
          searchCond.api_area.employee_name = this.tableData[index].approverl_employee_name
        }
        this.$emit('processing', true)
        this.send('/view/search_employee/list', searchCond)
        .then(res => {
          if (res.api_area.employee_list.length === 1) {
            this.currentIndex = index
            this.hideApproverSearch(res.api_area.employee_list[0])
          } else {
            this.tableData[index].approverl_group_code = ''
            this.tableData[index].approverl_group_name = ''
            this.tableData[index].approverl_employee_code = ''
            this.tableData[index].approverl_employee_name = ''
            this.tableData[index].group_list = []
          }
          this.$emit('processing', false)
        }).catch(() => {
          this.$emit('processing', false)
        })
      },
      addRow () {
        this.tableData.push({
          company_code: this.$store.state.loginUser.companyCode,
          company_name: this.$store.state.loginUser.companyName,
          approverl_role_code: '',
          approverl_role_name: '',
          approverl_group_code: '',
          approverl_gropu_name: '',
          approverl_employee_coe: '',
          approverl_employee_name: '',
          function: ''
        })
        let routeNumber = 1
        for (const row of this.tableData) {
          row.activity_code = routeNumber
          row.route_number = String(routeNumber)
          routeNumber++
        }
      }
    }
  }
</script>

<style scoped>
</style>