<template>
  <div>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
                  
              <div style="width: 450px" class="col-md-5 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <form method="#" action="#">
                  <div style="margin-bottom: 20px; font-size: 14px;">
                    変更するパスワードを入力してください。
                  </div>
                  <div class="card" data-background="color" data-color="blue">
                    <div class="card-header">
                      <h3 class="card-title">{{$t('label.new_password')}}</h3>
                    </div>
                    <div class="card-content">
                      <ValidationObserver ref="observer">
                        <ValidationText
                          v-model="mailAddress"
                          item-name="mailAddress"
                          max-length="255"
                          classes="form-control length30"
                          rules="required"
                          item-label="メールアドレス"
                          :placeholder="$t('placeholder.mail_address')"
                          :disabled="false"
                        />
                        <ValidationPassword
                          v-model="password"
                          type="password"
                          item-name="password"
                          max-length="30"
                          classes="form-control length30"
                          rules="required|min:12|isUnusable|isPasswordWeakly"
                          item-label="新しいパスワード"
                          :placeholder="$t('placeholder.input_password')"
                          :disabled="false"
                          :help-text="$t('message.password_text')"
                          :weaklyMessage="$t('message.is_weakly_password')"
                          :unusableMessage="$t('message.is_unusable_string_password')"
                          :wordCountMessage="$t('message.is_word_count_password')"
                        />
                      </ValidationObserver>
                    </div>
                    <br>
                    <div class="card-footer text-center">
                      <button type="button" class="btn btn-sm btn-default btn-wd btn-warning" @click="resetPassword">変更</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  import VPasswordStrengthMeter from 'v-password-strength-meter'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader,
      ValidationText,
      ValidationSelect,
      ValidationPassword,
      VPasswordStrengthMeter
    },
    data () {
      return {
        mailAddress: '',
        token: '',
        password: ''
      }
    },
    methods: {
      resetPassword () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          this.token = this.$route.query.token
          let param = {
            token: this.token,
            new_password: this.password,
            access_mail_address: this.mailAddress
          }
          this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/update/reset_password`, param)
          .then(res => {
            swal({
              text: res.message.text,
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              type: 'success',
              allowOutsideClick: false
            }).then(() => {
              window.location = '/#/admin/login'
            })
          })
          .catch(error => {
            console.error(error)
            if (error.message !== 'システムエラー') {
              swal({
                text: '登録に失敗しました。',
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            }
          })
        })
      }
    },
    created () {
    }
  }
</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
  .recaptcha {
    padding-left: 28px;
    padding-right: 28px;
  }
</style>
<style>
  .form-background {
    background: #eee !important;
  }
</style>