<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">{{$t('label.bank_transfer_list')}}</h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]" />
        </div>
        <div class="card-content" ref="frame">
          <div :key="componentKey">
            <h5>
              <strong>{{params.target_date.substring(0, 4)}}{{$t('label.year')}}{{params.target_date.substring(4, 6)}}{{$t('label.monthly')}}{{ params.salary_bonus_classification === 2 ? bonusPaySlipName : '' }}</strong>
            </h5>
            <!-- 締め日別で繰り返し表示 -->
            <div
              v-for="salary_closing_data  in salary_closing_data"
              v-bind:key="salary_closing_data.salary_closing_name"
            >
              <table>
                <tr>
                  <td colspan="5" width="430">
                    <label>{{salary_closing_data.salary_closing_name}}</label>
                  </td>
                  <td width="150">
                    <div
                      style="text-align: right;"
                    >{{$t('label.payslip_number')}}:{{salary_closing_data.salary_closing_count}}</div>
                  </td>
                  <td width="120">
                    <div
                      style="text-align: right;"
                    >{{$t('label.total')}}:{{formatNumber(salary_closing_data.salary_closing_total_amount)}}</div>
                  </td>
                </tr>
              </table>
              <hr />
              <div
                v-for="bank_data  in salary_closing_data.bank_data"
                v-bind:key="bank_data.bank_code"
              >
                <table>
                  <tr>
                    <td colspan="5" width="430">
                      <small>{{labels.bank_name + ' ' + bank_data.bank_code + ':' + bank_data.bank_name}}</small>
                    </td>
                    <td width="150">
                      <div style="text-align: right;">
                        <small>{{$t('label.payslip_number')}}:{{bank_data.bank_count}}</small>
                      </div>
                    </td>
                    <td width="120">
                      <div style="text-align: right;">
                        <small>{{$t('label.total')}}:{{formatNumber(bank_data.bank_total_amount)}}</small>
                      </div>
                    </td>
                  </tr>
                </table>
                <!-- Table開始 -->
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="bank_data.amonut_data"
                  :empty-text="$t('label.no_data')"
                  ref="table"
                >
                  <!-- 締め日 -->
                  <el-table-column
                    :width="150"
                    :label="params.salary_bonus_classification === 2 || labels.closing_name === undefined ? '' : labels.closing_name"
                  >
                    <div style="text-align: left;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: left;">{{salary_closing_data.salary_closing_name}}</div>
                  </el-table-column>
                  <!-- 銀行名 -->
                  <el-table-column
                    :width="100"
                    :label="labels.closing_name === undefined ? '' : labels.bank_name"
                  >
                    <div style="text-align: left;">
                      <small>{{bank_data.bank_code}}</small>
                    </div>
                    <div style="text-align: left;">{{bank_data.bank_name}}</div>
                  </el-table-column>
                  <!-- 支払日 -->
                  <el-table-column
                    :width="100"
                    :label="labels.bank_code === undefined ? '' : labels.branch_code"
                  >
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.branch_code}}</small>
                      </div>
                      <div style="text-align: left;">{{props.row.branch_name}}</div>
                    </template>
                  </el-table-column>

                  <!-- 口座番号 -->
                  <el-table-column
                    :width="100"
                    :label="labels.bank_code === undefined ? '' : labels.account_number"
                  >
                    <template slot-scope="props">
                      <div
                        style="text-align: left;"
                      >{{getLabel(getSelectName(props.row.account_classification, comboData.account_classification_list))}}</div>
                      <div style="text-align: left;">{{props.row.account_number}}</div>
                    </template>
                  </el-table-column>
                  <!-- 従業員名 -->
                  <el-table-column
                    :width="150"
                    :label="labels.bank_code === undefined ? '' : labels.employee_name"
                  >
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.employee_code}}</small>
                      </div>
                      <div style="text-align: left;">{{props.row.employee_name}}</div>
                    </template>
                  </el-table-column>
                  <!-- 金額 -->
                  <el-table-column
                    :width="100"
                    :label="labels.bank_code === undefined ? '' : labels.price"
                  >
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;">{{formatNumber(props.row.transfer_amount)}}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <!-- ボタン -->
      <div style="text-align: left;">
        <template v-if="roles.print === 1 && salary_closing_data.length > 0">
          <button
            type="button"
            class="btn btn-sm btn-default btn-warning"
            @click="doPrint"
          >{{$t('button.print')}}</button>
        </template>
      </div>
    </form>
  </div>
</template>
<script>
  import BankTransferListlMixin from '@/components/Dashboard/Views/Salary/BankTransferListlMixin'

  export default {
    name: 'BankTransferListlMixin',
    mixins: [BankTransferListlMixin]
  }
</script>
<style scoped>
</style>
