<template>
  <div>
    <input
      type="text"
      :name="itemName"
      :placeholder="placeholder1"
      :class="classes"
      style="display: inline-block;"
      v-model="startEndTime[0]"
      :maxlength="maxLength"
      :disabled="disabled"
      @blur="blur"
      @keypress.enter="$emit('input', startEndTime)">
    <span>～</span>
    <input type="checkbox" v-model="startEndTime[2]" class="gray" :id=id :disabled="disabled">
    <label :for=id>{{$t('label.next_day')}}</label>&nbsp;&nbsp;
    <input
      type="text"
      :name="itemName"
      :placeholder="placeholder2"
      :class="classes"
      style="display: inline-block;"
      v-model="startEndTime[1]"
      :maxlength="maxLength"
      :disabled="disabled"
      @blur="blur"
      @keypress.enter="$emit('input', startEndTime)">
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      maxLength: String,
      classes: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean
    },
    data () {
      return {
        startEndTime: ['', '', false],
        id: 'nextDay' + '|' + new Date().getTime().toString(16)
      }
    },
    watch: {
      value () {
        this.startEndTime = this.value
      }
    },
    mounted () {
      this.startEndTime = this.value
    },
    methods: {
      blur () {
        this.$emit('input', this.startEndTime)
        this.$emit('blur')
      }
    }
  }
</script>
