<template>
  <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <template v-if="isErrorPositionTop">
      <div v-if="!valid">
        <small class="text-danger">
            {{ getMessage(errors[0]) }}
          </small>
      </div>
    </template>
    <input
      :type="type"
      :name="itemName"
      :placeholder="placeholder"
      :class="classes"
      style="display: inline-block; text-align: right;"
      v-model="itemValue"
      :maxlength="decimal ? Number(integral) + Number(decimal) + 1: integral"
      :integral="integral"
      :decimal="decimal"
      :disabled="disabled"
      @input="inputChange"
      @focus="focus"
      @blur="blur"
      @keypress="$emit('input', itemValue)">{{unit ? '&nbsp;' + unit : ''}}
  </ValidationProvider>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        require: false,
        'default': 'text'
      },
      value: String,
      itemName: String,
      integral: {
        type: String,
        require: true,
        'default': 0
      },
      decimal: {
        type: String,
        require: false
      },
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      isReplace: {
        type: Boolean,
        require: false,
        'default': true
      },
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      isErrorPositionTop: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data: () => ({
      itemValue: null
    }),
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      inputChange () {
        if (this.isReplace) {
          this.itemValue = this.itemValue ? this.itemValue.length > 1 ? this.itemValue.substring(0, 1).replace(/[^(\-|0-9)]+/i, '') + this.itemValue.substring(1).replace(/[^(.|0-9)]+/i, '') : this.itemValue.replace(/[^(\-|0-9)]+/i, '') : this.itemValue
        }
      },
      blur () {
        if (!isNaN(this.itemValue)) {
          if (this.decimal) {
            this.itemValue = this.formatFloat(this.itemValue, this.decimal)
          } else {
            this.itemValue = this.formatNumber(this.itemValue)
          }
        }
        this.$emit('input', this.itemValue)
        this.$emit('blur')
      },
      focus () {
        if (!this.itemValue) {
          return
        }
        this.itemValue = String(this.itemValue).replaceAll(',', '')
        this.$emit('input', this.itemValue)
        this.$emit('focus')
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>
