<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{pageTitle}}
          </h4>
        </div>
        <div class="card-content">
          <MessageArea :page-type="pageType.sheet" :message="message" :placeholders="[pageTitle]"/>
          <ValidationObserver ref="observer">
            <LunchOrder
              v-model="detail.lunch_order"
              :labels="labels"
              :roles="labels"
              :disabled="isReadOnly"
              @update="update"
            />
          </ValidationObserver>
        </div>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import LunchOrderSheetMixin from '@/components/Dashboard/Views/Generated/LunchOrderSheetMixin'

  export default {
    name: 'LunchOrderSheet',
    methods: {
      afterInit (res) {
        this.detail.lunch_order = res.api_area.lunch_order_data
      },
      update (selected, acceptable) {
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            supplier_code: selected.supplier_code,
            acceptable: acceptable
          }
        }
        this.send(`/update/lunch_order`, reqData)
        .then(res => {
          for (const row of this.detail.lunch_order) {
            if (row.supplier_code === selected.supplier_code) {
              row.acceptable = acceptable
              this.componentKey += 1
              break
            }
          }
        }).catch(error => {
          console.error(error)
        })
      }
    },
    mixins: [LunchOrderSheetMixin]
  }
</script>
<style scoped>
</style>
