<template>
  <div style="display: inline-block;">
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled"
                v-model="hour"
                @change="emitIfValid"
    >
      <el-option v-for="hour in hours"
                  class="select-success"
                  :value="hour"
                  :label="hour"
                  :key="hour">
      </el-option>
    </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
    <el-select :class="classes"
                size="large"
                placeholder=""
                :disabled="disabled || (this.isLateNight && this.hour === '7') || (this.isLateEarlyDeparture && this.hour === '8')"
                v-model="minute"
                @change="emitIfValid"
    >
      <el-option v-for="minute in minutes"
                  class="select-success"
                  :value="minute"
                  :label="minute"
                  :key="minute">
      </el-option>
    </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      disabled: Boolean,
      classes: String,
      labels: Object,
      isLateNight: {
        type: Boolean,
        require: false,
        'default': false
      },
      isLateEarlyDeparture: {
        type: Boolean,
        require: false,
        'default': false
      },
      maxHour: {
        type: Number,
        require: false,
        'default': 0
      }
    },
    methods: {
      emitIfValid () {
        console.log(this.isLateNight, this.hour, this.hour === '7')
        if ((this.isLateNight && this.hour === '7') || (this.isLateEarlyDeparture && this.hour === '8')) {
          this.minute = '00'
        }
        if (this.hour === '') {
          this.hour = '0'
        }
        if (this.minute === '') {
          this.minute = '00'
        }
        var minutes = parseInt(this.hour, 10) * 60 + parseInt(this.minute, 10)
        this.$emit('input', String(minutes))
      }
    },
    data () {
      return {
        hours: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        minutes: [],
        orgMinutes: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
        hour: '',
        minute: ''
      }
    },
    watch: {
      value () {
        if (this.value) {
          this.hour = String(Math.floor(this.value / 60))
          this.minute = ('0' + String(this.value % 60)).slice(-2)
        }
        if (this.isLateNight) {
          this.hours = ['0', '1', '2', '3', '4', '5', '6', '7']
        }
        if (this.isLateEarlyDeparture) {
          this.hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
        }
        if (this.maxHour !== 0) {
          let hours = ['0']
          for (let i = 0; i < this.maxHour; i++) {
            hours.push(String(i + 1))
          }
          this.hours = hours
        }
      }
    },
    mounted () {
      if (this.value) {
        this.hour = String(Math.floor(this.value / 60))
        this.minute = ('0' + String(this.value % 60)).slice(-2)
      }
      if (this.isLateNight) {
        this.hours = ['0', '1', '2', '3', '4', '5', '6', '7']
      }
      if (this.isLateEarlyDeparture) {
        this.hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8']
      }
      if (this.maxHour !== 0) {
        let hours = ['0']
        for (let i = 0; i < this.maxHour; i++) {
          hours.push(String(i + 1))
        }
        this.hours = hours
      }
      if (this.isLateNight && !this.hour && !this.minute) {
        this.minutes = ['00', '15', '30', '45']
      } else {
        this.minutes = this.orgMinutes
      }
    }
  }
</script>

<style scoped>
</style>
