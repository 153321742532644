<template>
  <div>
    <modal name="targetEmployee" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="searchCond" :is-modal="true" :show-retirement="false" :search-all="false" @close="hideTargetEmployeeSearch"/>
      </div>
    </modal>
    <modal name="boss" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="searchCond" :is-modal="true" :show-retirement="false" :search-all="true" @close="hideBossSearch"/>
      </div>
    </modal>
    <template v-if="applicationInfoDto.screen_mode === '' || applicationInfoDto.screen_mode === '1' || applicationInfoDto.screen_mode === '2'">
      <div class="deputy-apply" v-if="!applicationInfoDto.target_employee_code || applicationInfoDto.target_employee_code != $store.state.loginUser.employeeCode">
        {{ $t('label.deputy_apply') }}
      </div>
    </template>
    <div class="card">
      <div class="card-content" :key="componentKey">
        <template v-if="applicationInfoDto.screen_mode === '' || applicationInfoDto.screen_mode === '1' || applicationInfoDto.screen_mode === '2'">
          <div>
            <div class="form-group" style="display: inline-block;">
              <div style="display: inline-block; font-size: 20px; font-weight: bold;">
                {{workflowPageTitle}}
              </div>
            </div>
            <div class="form-group" style="display: inline-block;" v-if="applicationInfoDto.application_number">
              <label style="margin-right: 10px;">{{labels.application_number ? labels.application_number : ''}}</label>
              <div style="display: inline-block;">
                {{applicationInfoDto.application_number}}
              </div>
            </div>
            <div class="form-group" style="display: inline-block;" v-if="applicationInfoDto.re_application_number && applicationInfoDto.re_application_number !== 0">
              <span>（</span>
              <div class="form-group width90" style="display: inline-block;">
                <label>{{labels.re_application_number}}</label>
              </div>
              <div style="display: inline-block;">
                <a
                  class="btn btn-simple btn-info"
                  style="padding: 0; color: blue; text-decoration: underline;"
                  @click="$emit('showWorkflow')"
                >{{applicationInfoDto.re_application_number}}</a>
              </div>
              <span>）</span>
            </div>
            <div class="form-group" style="display: inline-block; margin-left: 10px;">
              <div style="display: inline-block;">
                {{applicationInfoDto.applicant_employee_name}}
                {{ applicationInfoDto.applicant_employee_code ? '(' + applicationInfoDto.applicant_employee_code + ')' : '' }}
                &nbsp;
                {{applicationInfoDto.applicant_company_name}}
                {{applicationInfoDto.applicant_group_name}}
                {{$t('label.title_group')}}
              </div>
            </div>
            <div class="form-group" style="display: inline-block; margin-left: 10px;">
              <label style="margin-right: 10px;">{{labels.apply_date}}</label>
              <div style="display: inline-block;">
                {{applicationInfoDto.apply_date}}
              </div>
            </div>
          </div>
          <div>
            <div class="form-group width90" style="display: inline-block;">
              <label>{{$t('label.target_person')}}</label>
            </div>
            <div class="form-group" style="display: inline-block;">
              <label style="margin-right: 10px;">{{labels.group_name}}</label>
              <el-select class="select-success width270"
                          size="large"
                          :disabled="isReadOnly || !applicationInfoDto.target_employee_code"
                          placeholder=""
                          v-model="applicationInfoDto.target_group_code"
                          @change="targetGroupSelected">
                <el-option v-for="group in comboData.target_group_list"
                            class="select-success"
                            :value="group.value"
                            :label="group.label"
                            :key="group.value">
                </el-option>
              </el-select>
            </div>
            <div class="form-group" style="display: inline-block;">
              <label style="margin-left: 30px; margin-right: 10px;">{{labels.employee_name}}</label>
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control width110" 
                  v-model="applicationInfoDto.target_employee_code"
                  :disabled="!canTargetSelect"
                  @blur="targetEmployeeSelected">
              </div>
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control width150" 
                  v-model="applicationInfoDto.target_employee_name" 
                  :disabled="true">
              </div>
              <template v-if="canTargetSelect">
                <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="isReadOnly" @click="showTargetEmployeeSearch" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
              </template>
            </div>
          </div>
          <template v-if="hasBoss">
            <div>
              <div class="form-group width90" style="display: inline-block;">
                <label>{{$t('label.boss')}}</label>
              </div>
              <div class="form-group" style="display: inline-block;">
                <label style="margin-right: 10px;">{{labels.group_name}}</label>
                <el-select class="select-success width290"
                            size="large"
                            :disabled="isReadOnly"
                            placeholder=""
                            v-model="applicationInfoDto.boss_group_code"
                            @change="bossGroupSelected">
                  <el-option v-for="group in applicationInfoDto.boss_groups"
                              class="select-success"
                              :value="group.value"
                              :label="group.label"
                              :key="group.value">
                  </el-option>
                </el-select>
              </div>
              <div class="form-group" style="display: inline-block;">
                <label style="margin-left: 30px; margin-right: 10px;">{{labels.employee_name}}</label>
                <div style="display: inline-block;">
                  <input 
                    type="text"
                    class="form-control width110" 
                    v-model="applicationInfoDto.boss_employee_code"
                    @blur="bossSelected">
                </div>
                <div style="display: inline-block;">
                  <input 
                    type="text"
                    class="form-control width150" 
                    v-model="applicationInfoDto.boss_employee_name" 
                    :disabled="true">
                </div>
                <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="isReadOnly" @click="showBossSearch" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
              </div>
            </div>
            <div>
              <span class="strong-text">{{ $t('label.boss_description')}}</span>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="form-group"">
            <div style="font-size: 20px; font-weight: bold;">
              {{applicationInfoDto.application_form_name}}
            </div>
          </div>
          <div>
            <div class="form-group" style="display: inline-block;">
              <label style="margin-right: 10px;">{{labels.application_number ? labels.application_number : ''}}</label>
              <div style="display: inline-block;">
                {{applicationInfoDto.application_number}}
              </div>
            </div>
            <div class="form-group" style="display: inline-block; margin-left: 30px;">
              <label style="margin-right: 10px;">{{labels.re_application_number ? labels.re_application_number : ''}}</label>
              <div style="display: inline-block;">
                <template v-if="applicationInfoDto.re_application_number">
                  <a
                    class="btn btn-simple btn-info"
                    style="padding: 0; color: blue; text-decoration: underline;"
                    @click="$emit('showWorkflow')"
                  >{{applicationInfoDto.re_application_number}}</a>
                </template>
                <template v-else>
                  {{ '-' }}
                </template>
              </div>
            </div>
            <div class="form-group" style="display: inline-block; margin-left: 50px;">
              <label style="margin-right: 10px;">{{labels.apply_date}}</label>
              <div style="display: inline-block;">
                {{ applicationInfoDto.apply_date }}
              </div>
            </div>
          </div>
          <div>
            <div class="form-group" style="display: inline-block;">
              <label style="margin-right: 10px;">{{$t('label.apply_person')}}</label>
              <div style="display: inline-block;">
                {{applicationInfoDto.applicant_company_code}}
                &nbsp;/&nbsp;
                {{applicationInfoDto.applicant_group_name}}
                &nbsp;/&nbsp;
                {{applicationInfoDto.applicant_employee_name}}
              </div>
            </div>
            <div class="form-group" style="display: inline-block; margin-left: 30px;">
              <label style="margin-right: 10px;">{{$t('label.target_person')}}</label>
              <div style="display: inline-block;">
                {{applicationInfoDto.target_company_code}}
                &nbsp;/&nbsp;
                {{applicationInfoDto.target_group_name}}
                &nbsp;/&nbsp;
                {{applicationInfoDto.target_employee_name}}
              </div>
            </div>
            <template v-if="hasBoss">
              <div class="form-group" style="display: inline-block;">
                <label style="margin-right: 10px;">{{labels.boss}}</label>
                <div style="display: inline-block;">
                  {{applicationInfoDto.c_company_code}}
                  &nbsp;/&nbsp;
                  {{applicationInfoDto.c_group_name}}
                  &nbsp;/&nbsp;
                  {{applicationInfoDto.c_employee_name}}
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import swal from 'sweetalert2'

  export default{
    mixins: [mixin],
    components: {
      EmployeeSearch,
      ValidationText,
      ValidationSelect
    },
    props: {
      value: Object,
      hasBoss: Boolean,
      comboData: Object,
      labels: Object,
      isReadOnly: Boolean,
      canTargetSelect: Boolean,
      applicationFormCode: String
    },
    data () {
      return {
        componentKey: 0,
        searchCond: {
          company_code: this.$store.state.loginUser.companyCode,
          office_code: '',
          group_code: '',
          team_code: '',
          office_range: 2,
          group_range: 2,
          team_range: 2,
          search_employee_code: '',
          employee_classification_code: '',
          employee_code: '',
          search_type: '1',
          retirement: false
        },
        applicationInfoDto: {}
      }
    },
    computed: {
      workflowPageTitle () {
        if (this.labels.overtimeApplication) {
          return this.labels.overtimeApplication
        } else if (this.labels.imprintCorrectionApplication) {
          return this.labels.imprintCorrectionApplication
        } else if (this.labels.lateNightOverworkApplication) {
          return this.labels.lateNightOverworkApplication
        } else if (this.labels.lateTimeApplication) {
          return this.labels.lateTimeApplication.replace(this.labels.applicationObject, '') + '/' + this.labels.earlyDepartureTimeApplication
        } else if (this.labels.groundConfirmEmployee) {
          return this.labels.groundConfirmEmployee
        } else if (this.labels.paidLeaveEmployee) {
          return this.labels.paidLeaveEmployee
        } else if (this.labels.transferHolidayWorkApplication) {
          return this.labels.transferHolidayWorkApplication
        } else if (this.labels.holidayWorkApplication) {
          return this.labels.holidayWorkApplication
        } else if (this.labels.attendanceRecordApplication) {
          return this.labels.attendanceRecordApplication
        } else if (this.labels.addressChangeApplication) {
          return this.labels.addressChangeApplication
        } else if (this.labels.commutingRouteChangeApplication) {
          return this.labels.commutingRouteChangeApplication
        } else if (this.labels.personalInformationChangeApplication) {
          return this.labels.personalInformationChangeApplication
        } else {
          return this.pageTitle
        }
      }
    },
    methods: {
      showTargetEmployeeSearch () {
        this.$modal.show('targetEmployee')
      },
      hideTargetEmployeeSearch (selected, isOverwrite = true) {
        if (selected) {
          if (isOverwrite) {
            const groupList = []
            for (const row of selected.group_list) {
              if (row.default_group_code === 1) {
                this.applicationInfoDto.target_group_code = row.group_code
              }
              groupList.push({
                label: row.group_name,
                value: row.group_code
              })
            }
            this.comboData.target_group_list = groupList
          }
          this.applicationInfoDto.target_employee_code = selected.employee_code
          this.applicationInfoDto.target_employee_name = selected.employee_name
          if (isOverwrite) {
            this.$emit('initApply', null)
          }
          this.componentKey += 1
        }
        this.$modal.hide('targetEmployee')
      },
      targetGroupSelected () {
        this.targetEmployeeSelected(false)
      },
      targetEmployeeSelected (isEmployeeChanged = true) {
        if (!this.canShow('/SS/menuSSScreen018')) return
        if (!this.applicationInfoDto.target_employee_code) {
          this.applicationInfoDto.target_employee_name = ''
          this.applicationInfoDto.target_group_code = ''
          this.componentKey += 1
          return
        }
        if (isEmployeeChanged) {
          this.applicationInfoDto.target_group_code = ''
          this.comboData.target_group_list = []
        }
        this.applicationInfoDto.target_employee_name = ''
        this.componentKey += 1
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.applicationInfoDto.target_employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: false
          }
        }
        this.send('/view/search_employee/list', searchCond)
        .then(res => {
          if (res.api_area.employee_list.length > 0) {
            this.hideTargetEmployeeSearch(res.api_area.employee_list[0], isEmployeeChanged)
          } else {
            swal({
              title: this.$t('message.employee_not_found'),
              html: this.$t('message.employee_not_found_description'),
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            }).then(() => {
              this.applicationInfoDto.target_employee_code = ''
            })
          }
        })
      },
      showBossSearch () {
        this.$modal.show('boss')
      },
      hideBossSearch (selected, isOverwrite = true) {
        if (selected) {
          this.applicationInfoDto.boss_company_code = selected.company_code
          this.applicationInfoDto.boss_company_name = selected.company_name
          if (isOverwrite) {
            let selectedGroupCode = selected.group_list[0].group_code
            let selectedGroupName = selected.group_list[0].group_name
            for (const row of selected.group_list) {
              row.label = row.group_name
              row.value = row.group_code
              if (row.default_group_code === 1) {
                selectedGroupCode = row.group_code
                selectedGroupName = row.group_name
              }
            }
            this.applicationInfoDto.boss_group_code = selected.group_list ? selectedGroupCode : ''
            this.applicationInfoDto.boss_group_name = selected.group_list ? selectedGroupName : ''
            this.applicationInfoDto.boss_groups = selected.group_list ? selected.group_list : this.comboData.group_list
          }
          this.applicationInfoDto.boss_employee_code = selected.employee_code
          this.applicationInfoDto.boss_employee_name = selected.employee_name
          let params = {
            screen_code: '00-000',
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              application_form_code: this.applicationFormCode,
              target_company_code: this.applicationInfoDto.target_company_code,
              target_group_code: this.applicationInfoDto.target_group_code,
              target_employee_code: this.applicationInfoDto.target_employee_code,
              boss_group_code: this.applicationInfoDto.boss_group_code,
              boss_employee_code: this.applicationInfoDto.boss_employee_code
            }
          }
          this.$emit('updateRoute', params)
          this.componentKey += 1
        } else {
          this.$emit('updateRoute')
          this.componentKey += 1
        }
        this.$modal.hide('boss')
      },
      bossGroupSelected () {
        this.bossSelected(false)
      },
      bossSelected (isEmployeeChanged = true) {
        if (!this.canShow('/SS/menuSSScreen018')) return
        if (!this.applicationInfoDto.boss_employee_code) {
          this.applicationInfoDto.boss_employee_name = ''
          this.applicationInfoDto.boss_group_code = ''
          let params = {
            screen_code: '00-000',
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              application_form_code: this.applicationFormCode,
              target_company_code: this.applicationInfoDto.target_company_code,
              target_group_code: this.applicationInfoDto.target_group_code,
              target_employee_code: this.applicationInfoDto.target_employee_code,
              boss_group_code: this.applicationInfoDto.boss_group_code,
              boss_employee_code: this.applicationInfoDto.boss_employee_code
            }
          }
          this.$emit('updateRoute', params)
          this.componentKey += 1
          return
        }
        this.applicationInfoDto.boss_company_code = ''
        this.applicationInfoDto.boss_company_name = ''
        if (isEmployeeChanged) {
          this.applicationInfoDto.boss_group_code = ''
          this.applicationInfoDto.boss_group_name = ''
          this.applicationInfoDto.boss_groups = []
        }
        this.applicationInfoDto.boss_employee_name = ''
        this.componentKey += 1
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.applicationInfoDto.boss_employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: true
          }
        }
        this.send('/view/search_employee/list', searchCond)
        .then(res => {
          this.hideBossSearch(res.api_area.employee_list[0], isEmployeeChanged)
        })
      }
    },
    watch: {
      value () {
        this.applicationInfoDto = this.value
      }
    },
    mounted () {
      this.applicationInfoDto = this.value
    }
  }
</script>
<style scoped>
  .deputy-apply {
    color: #4097D4;
    background-color: #BBDCF9;
    border: 1px solid #4097D4;
    padding: 5px 15px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: bold;
  }
</style>
