<template>
  <div>
    <div :class="frameClass==='' ? 'form-group' : 'form-group ' + frameClass" style="display: inline-block; vertical-align: top;">
      <label>{{ itemLabel }}</label>
      <div>
        <el-select class="select-success widthYear"
                    size="large"
                    placeholder=""
                    v-model="startEndMonth[0]"
                    :disabled="disabled"
                    @change="synchronizeFrom">
          <el-option v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year">
          </el-option>
        </el-select>&nbsp;{{$t('label.year')}}&nbsp;
        <el-select class="select-success widthMonth"
                    size="large"
                    placeholder=""
                    v-model="startEndMonth[1]"
                    :disabled="disabled"
                    ref="fromMonth"
                    @change="synchronizeFrom">
          <el-option v-for="month in months"
                      class="select-success"
                      :value="month"
                      :label="month"
                      :key="month">
          </el-option>
        </el-select>&nbsp;{{$t('label.month')}}&nbsp;
        ～
        <el-select class="select-success widthYear"
                    size="large"
                    placeholder=""
                    v-model="startEndMonth[2]"
                    :disabled="disabled"
                    @change="synchronizeTo">
          <el-option v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year">
          </el-option>
        </el-select>&nbsp;{{$t('label.year')}}&nbsp;
        <el-select class="select-success widthMonth"
                    size="large"
                    placeholder=""
                    ref="toMonth"
                    v-model="startEndMonth[3]"
                    :disabled="disabled"
                    @change="synchronizeTo">
          <el-option v-for="month in months"
                      class="select-success"
                      :value="month"
                      :label="month"
                      :key="month">
          </el-option>
        </el-select>&nbsp;{{$t('label.month')}}&nbsp;
      </div>
    </div>
    <div :class="frameClass==='' ? 'form-group' : 'form-group ' + frameClass" style="display: inline-block; vertical-align: top;">
      <label for="isCurrentMonth">{{$t('label.this_month')}}</label>
      <div style="padding: 6px 0;">
        <input 
          type="checkbox" 
          v-model="isCurrentMonth" 
          class="gray" 
          id="isCurrentMonth" 
          @click="isCurrentMonthClicked"
        >
      </div>
    </div>
  </div>
</template>

<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: Array,
      itemLabel: String,
      itemName: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      frameClass: {
        type: String,
        require: false,
        'default': ''
      },
      fromJanuary: {
        type: Boolean,
        require: false,
        'default': false
      },
      pickerOptions: {
        type: Object,
        require: false,
        'default': () => ({
          disabledDate (time) {
            return false
          }
        })
      }
    },
    data () {
      return {
        years: [],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        currentYear: undefined,
        currentMonth: undefined,
        startEndMonth: ['', '', '', ''],
        isCurrentMonth: false
      }
    },
    methods: {
      synchronizeFrom () {
        let from = parseInt(this.startEndMonth[0], 10) * 100 + parseInt(this.startEndMonth[1], 10)
        let to = parseInt(this.startEndMonth[2], 10) * 100 + parseInt(this.startEndMonth[3], 10)
        let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
        if (from > thisMonth) {
          this.startEndMonth[0] = this.currentYear
          this.startEndMonth[1] = this.currentMonth
        }
        if (to > thisMonth) {
          this.startEndMonth[2] = this.currentYear
          this.startEndMonth[3] = this.currentMonth
        }
        if (from > to) {
          this.startEndMonth[2] = this.startEndMonth[0]
          this.startEndMonth[3] = this.startEndMonth[1]
        }
      },
      synchronizeTo () {
        let from = parseInt(this.startEndMonth[0], 10) * 100 + parseInt(this.startEndMonth[1], 10)
        let to = parseInt(this.startEndMonth[2], 10) * 100 + parseInt(this.startEndMonth[3], 10)
        let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
        if (from > thisMonth) {
          this.startEndMonth[0] = this.currentYear
          this.startEndMonth[1] = this.currentMonth
        }
        if (to > thisMonth) {
          this.startEndMonth[2] = this.currentYear
          this.startEndMonth[3] = this.currentMonth
        }
        if (from > to) {
          this.startEndMonth[0] = this.startEndMonth[2]
          this.startEndMonth[1] = this.startEndMonth[3]
        }
      },
      isCurrentMonthClicked () {
        if (!this.isCurrentMonth) {
          this.startEndMonth[0] = this.startEndMonth[2] = this.currentYear
          this.startEndMonth[1] = this.startEndMonth[3] = this.currentMonth
        }
      }
    },
    watch: {
      value () {
        this.startEndMonth = this.value
      }
    },
    created () {
      this.currentYear = new Date().getFullYear()
      this.currentMonth = new Date().getMonth() + 1
      for (let year = this.currentYear + 1; year >= 2009; year--) {
        this.years.push(year)
      }
    },
    mounted () {
      this.startEndMonth = this.value
      if (!this.startEndMonth[0]) this.startEndMonth[0] = this.currentYear
      if (!this.startEndMonth[1]) this.startEndMonth[1] = this.fromJanuary ? 1 : this.currentMonth
      if (!this.startEndMonth[2]) this.startEndMonth[2] = this.currentYear
      if (!this.startEndMonth[3]) this.startEndMonth[3] = this.currentMonth
    }
  }
</script>
