<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <form @submit.prevent="" method="GET" action="/#/admin/menu" id="creditCard" ref="creditCard">
          <div class="card" data-background="color" data-color="blue">
            <div class="card-header">
              <h4 class="card-title">{{ $t('button.delete_company_cancellation') }}</h4>
            </div>
            <div class="card-content">
              <div style="text-align: center;">
                <template v-for="row in tableData">
                  <div style="margin-top: 30px;" :key="row.company_code">
                    <a href="#" @click.prevent="deleteCompany(row)">{{$t('button.delete_company').replace('{companyName}', row.company_name)}}</a>
                  </div>
                </template>
                <div style="margin: 30px 0;">
                  <a href="#" @click.prevent="cancellation">{{$t('button.cancellation')}}</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    data () {
      return {
        tableData: [],
        labels: {}
      }
    },
    methods: {
      deleteCompany (row) {
        swal({
          text: this.$t('message.confirm_to_delete_company').replace('{companyName}', row.company_name),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no')
        }).then(() => {
          if (this.$store.state.userLoginUser.corporate === 1) {
            const params = {
              screen_code: '00-000',
              company_code: row.company_code,
              group_code: this.$store.state.loginUser.groupCode,
              employee_code: this.$store.state.loginUser.employeeCode,
              api_area: {
                update_count: row.update_count
              }
            }
            this.send(`/delete/company_info`, params)
            .then(res => {
              const newData = []
              for (const company of this.tableData) {
                if (company.company_code !== row.company_code) {
                  newData.push(company)
                }
              }
              this.tableData = newData
              swal({
                text: this.$t('message.company_delete_complete').replace('{companyName}', row.company_name),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                type: 'success',
                allowOutsideClick: false
              })
            })
          } else {
            const params = {
              mail_address: localStorage.getItem('migrationMailAddress'),
              api_area: {
                password: this.password,
                company_code: row.company_code,
                company_name: row.company_name
              }
            }
            this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/delete/company`, params)
            .then(res => {
              const newData = []
              for (const company of this.tableData) {
                if (company.company_code !== row.company_code) {
                  newData.push(company)
                }
              }
              this.tableData = newData
              swal({
                text: this.$t('message.company_delete_complete').replace('{companyName}', row.company_name),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                type: 'success',
                allowOutsideClick: false
              })
            })
          }
        })
      },
      cancellation () {
        if (this.tableData.length > 0) {
          swal({
            text: this.$t('message.cannot_do_cancellation'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        swal({
          text: this.$t('message.confirm_to_cancellation'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no')
        }).then(() => {
          // 法人契約している場合は解約
          const customerId = localStorage.getItem('customerId')
          if (customerId) {
            const self = this
            this.$axios.get(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions?customerId=' + customerId)
            .then(res => {
              console.log(res.data)
              const postData = {
                subscriptionId: res.data.subscriptionId,
                cancelAtPeriodEnd: 'true'
              }
              self.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/stripeSubscriptions', postData)
              .then(res => {
                const requestJson = {
                  mail_address: localStorage.getItem('migrationMailAddress'),
                  api_area: {}
                }
                self.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/delete/user`, requestJson)
                .then(res => {
                  swal({
                    text: self.$t('message.cancellation_complete'),
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                    type: 'success',
                    allowOutsideClick: false
                  }).then(() => {
                    self.$firebase.auth().signOut().then(() => {
                      window.location = '/'
                    })
                  })
                })
              })
            })
          } else {
            const requestJson = {
              mail_address: localStorage.getItem('migrationMailAddress'),
              api_area: {}
            }
            this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/delete/user`, requestJson)
            .then(res => {
              swal({
                text: this.$t('message.cancellation_complete'),
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                type: 'success',
                allowOutsideClick: false
              }).then(() => {
                this.$firebase.auth().signOut().then(() => {
                  window.location = '/'
                })
              })
            })
          }
        })
      }
    },
    created () {
      this.tableData = JSON.parse(localStorage.getItem('companyList'))
      const request = {
        mail_address: localStorage.getItem('migrationMailAddress'),
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
      })
    }
  }

</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .card input {
    background: #eee !important;
  }
</style>
