<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label>
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="'|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]).replace('{item1}', labels.reference_month).replace('{item2}', labels.number_of_working_days_per_week_ryaku).replace('{item3}', $t('label.term_from_ryaku')).replace('{item4}', $t('label.term_to_ryaku')).replace('{item5}', labels.grant_days) }}
          </small>
        </div>
      </div>
      <BaseDateSetting
        v-model="tableData"
        :labels="labels"
        :disabled="disabled"
        :classes="classes"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import BaseDateSetting from './BaseDateSetting'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      BaseDateSetting
    },
    props: {
      value: Array,
      rules: String,
      labels: Object,
      classes: String,
      disabled: Boolean,
      itemLabel: String
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>