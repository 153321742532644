import { render, staticRenderFns } from "./TransferHolidayWork.vue?vue&type=template&id=05c0c9e6&scoped=true"
import script from "./TransferHolidayWork.vue?vue&type=script&lang=js"
export * from "./TransferHolidayWork.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c0c9e6",
  null
  
)

export default component.exports