<template>
  <div>
    <div style="margin-bottom: 5px;">
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow">{{$t('button.add_row')}}</button>
    </div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      :key="componentKey"
      class="table-stripe"
      :data="tableData"
      style="width: 991px;"
      :empty-text="$t('label.no_data')">
      <el-table-column
        width="60"
        :label="$t('label.delete')"
        align="center">
        <template slot="header" slot-scope="scope"> 
          <div class="phase-header tableHeader">
            <div class="phase-header__title">
              {{scope.column.label}}
            </div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="false"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="labels.reference_month_ryaku ? labels.reference_month_ryaku : ''"
        width="230"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.reference_month_month"
                        @change="updateReferenceMonth(props.row, true)"
            >
              <el-option v-for="month in months"
                          class="select-success"
                          :value="month"
                          :label="month"
                          :key="month">
              </el-option>
            </el-select>&nbsp;{{$t('label.month')}}&nbsp;
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.reference_month_day"
                        @change="updateReferenceMonth(props.row, false)"
            >
              <el-option v-for="day in props.row.reference_month_days"
                          class="select-success"
                          :value="day"
                          :label="day"
                          :key="day">
              </el-option>
            </el-select>&nbsp;{{$t('label.day')}}&nbsp;
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="labels.number_of_working_days_per_week_ryaku ? labels.number_of_working_days_per_week_ryaku : ''"
        width="120"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="text" 
                    class="form-control numLength2"
                    style="display: inline-block;"
                    :disabled="disabled"
                    maxlength="30"
                    v-model="props.row.number_of_working_days_per_week">
            {{$t('label.unit_day')}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('label.term_from_ryaku')"
        width="230"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.term_from_month"
                        @change="updateTermFrom(props.row, true)"
            >
              <el-option v-for="month in months"
                          class="select-success"
                          :value="month"
                          :label="month"
                          :key="month">
              </el-option>
            </el-select>&nbsp;{{$t('label.month')}}&nbsp;
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.term_from_day"
                        @change="updateTermFrom(props.row, false)"
            >
              <el-option v-for="day in props.row.term_from_days"
                          class="select-success"
                          :value="day"
                          :label="day"
                          :key="day">
              </el-option>
            </el-select>&nbsp;{{$t('label.day')}}&nbsp;
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('label.term_to_ryaku')"
        width="230"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.term_to_month"
                        @change="updateTermTo(props.row, true)"
            >
              <el-option v-for="month in months"
                          class="select-success"
                          :value="month"
                          :label="month"
                          :key="month">
              </el-option>
            </el-select>&nbsp;{{$t('label.month')}}&nbsp;
            <el-select :class="classes"
                        size="large"
                        placeholder=""
                        :disabled="disabled"
                        v-model="props.row.term_to_day"
                        @change="updateTermTo(props.row, false)"
            >
              <el-option v-for="day in props.row.term_to_days"
                          class="select-success"
                          :value="day"
                          :label="day"
                          :key="day">
              </el-option>
            </el-select>&nbsp;{{$t('label.day')}}&nbsp;
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="labels.grant_days ? labels.grant_days_ryaku : ''"
        width="120"
        align="center">
        <template slot-scope="props">
          <div style="text-align: center;">
            <input type="text" 
                    class="form-control numLength2"
                    style="display: inline-block;"
                    :disabled="disabled"
                    maxlength="30"
                    v-model="props.row.grant_days">
            {{$t('label.unit_day')}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      labels: Object,
      classes: String,
      disabled: Boolean
    },
    data () {
      return {
        componentKey: 0,
        tableData: [],
        months: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        months28: ['2'],
        months30: ['4', '6', '9', '11'],
        months31: ['1', '3', '5', '7', '8', '10', '12'],
        days: [],
        days28: [
          '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28'
        ],
        days29: [
          '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'
        ],
        days30: ['1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30'
        ],
        days31: ['1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31'
        ]
      }
    },
    watch: {
      value () {
        const tableData = this.value
        for (const row of tableData) {
          this.setDays(row)
        }
        this.tableData = tableData
      }
    },
    mounted () {
      const tableData = this.value
      for (const row of tableData) {
        this.setDays(row)
      }
      this.tableData = tableData
    },
    methods: {
      setDays (row) {
        if (this.months28.includes(row.reference_month_month)) {
          row.reference_month_days = this.days28
        } else if (this.months30.includes(row.reference_month_month)) {
          row.reference_month_days = this.days30
        } else {
          row.reference_month_days = this.days31
        }
        if (this.months28.includes(row.term_from_month)) {
          row.term_from_days = this.days29
        } else if (this.months30.includes(row.term_from_month)) {
          row.term_from_days = this.days30
        } else {
          row.term_from_days = this.days31
        }
        if (this.months28.includes(row.term_to_month)) {
          row.term_to_days = this.days29
        } else if (this.months30.includes(row.term_to_month)) {
          row.term_to_days = this.days30
        } else {
          row.term_to_days = this.days31
        }
      },
      headerClicked () {
        for (const row of this.tableData) {
          row.selected = !this.headerSelected
        }
      },
      addRow () {
        let row = {
          selected: false,
          name: '',
          reference_month_days: this.days31,
          term_from_days: this.days31,
          term_to_days: this.days31
        }
        if (this.tableData) {
          this.tableData.push(row)
        } else {
          this.tableData = [row]
        }
      },
      deleteRow () {
        const tableData = []
        for (const row of this.tableData) {
          if (!row.selected) {
            tableData.push(row)
          }
        }
        this.tableData = tableData
      },
      updateReferenceMonth (row, isMonthChanged) {
        if (isMonthChanged) {
          row.reference_month_day = '1'
          this.setDays(row)
        }
        if (row.reference_month_month && row.reference_month_day) {
          row.reference_month = ('0' + row.reference_month_month).slice(-2) + '/' + ('0' + row.reference_month_day).slice(-2)
        }
        this.componentKey += 1
      },
      updateTermFrom (row, isMonthChanged) {
        if (isMonthChanged) {
          row.term_from_day = '1'
          this.setDays(row)
        }
        if (row.term_from_month && row.term_from_day) {
          row.term_from = ('0' + row.term_from_month).slice(-2) + '/' + ('0' + row.term_from_day).slice(-2)
        }
        this.componentKey += 1
      },
      updateTermTo (row, isMonthChanged) {
        if (isMonthChanged) {
          row.term_to_day = '1'
          this.setDays(row)
        }
        if (row.term_to_month && row.term_to_day) {
          row.term_to = ('0' + row.term_to_month).slice(-2) + '/' + ('0' + row.term_to_day).slice(-2)
        }
        this.componentKey += 1
      }
    }
  }
</script>

<style scoped>
</style>