<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            {{$t('label.change_mail_address')}}
          </div>
          <div class="card-content">
            <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" />
            <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
            <ValidationObserver ref="observer">
              <ValidationText
                type="text"
                v-model="accessMailAddress"
                classes="form-control length60"
                :disabled="isChanged"
                :item-label="$t('label.mail_address')"
                :placeholder="$t('placeholder.mail_address')"
                item-name="accessMailAddress"
                :rules="`required|mailaddress|notSameMailAddress:${$store.state.userLoginUser.accessMailAddress}`"
                :help-text="$t('label.max_length')"
                max-length="255"
              />
            </ValidationObserver>
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanged" @click="updateMailAddress">
              <span class="btn-label"><i class="fa fa-check"></i></span>
              {{$t('button.update')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText
    },
    data () {
      return {
        labels: {},
        comboData: {},
        accessMailAddress: '',
        updateCount: 1,
        isChanged: false
      }
    },
    methods: {
      updateMailAddress () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }
          const request = {
            mail_address: localStorage.getItem('migrationMailAddress'),
            api_area: {
              access_mail_address: this.accessMailAddress
            }
          }
          this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/send/change_access_mail_address`, request)
          .then(res => {
            this.isChanged = true
          })
        })
      },
      doClose () {
        this.$emit('close', this.isChanged)
      },
      init () {
        const request = {
          mail_address: localStorage.getItem('migrationMailAddress'),
          api_area: {}
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.updateCount = res.api_area.update_count
          this.accessMailAddress = res.api_area.access_mail_address
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
