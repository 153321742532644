<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal
      name="detail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidth"
      :pivotX="0.9"
    >
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" />
      </div>
    </modal>
    <modal
      name="bank"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthBank"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Bank :mode="mode" :params="params" @close="hideBank" />
      </div>
    </modal>
    <modal
      name="cashTransfer"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthCashTransfer"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <CashTransfer :mode="mode" :params="params" @close="hideCashTransfer" />
      </div>
    </modal>
    <modal
      name="journal"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthJournal"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Journal :mode="mode" :params="params" @close="hideJournal" />
      </div>
    </modal>
    <modal
      name="sendMail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidthSendMail"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <SendMail :mode="mode" :params="params" :retirement="searchCond.retirement" @close="hideSendMail" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <Help
          class="pull-right"
          :help="help"
          :placeholders="[pageTitle]"
          div-style="padding-bottom: 5px;"
        />
      </div>
    </div>
    <div ref="frame">
      <form method="#" action="#">
        <div class="card">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">{{$t('label.search_condition')}}</h4>
            </div>
            <div
              style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;"
            >
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-show="showSearchCondition">
            <!-- <EmployeesSearch
              ref="employeesSearch"
              :orgSearchCond="searchCond"
              :labels="labels"
              :comboData="comboData"
              :disabled="isChanged"
              :from-year-month="fromYearMonth"
              :to-year-month="toYearMonth"
              :current-year-month="currentYearMonth"
              :payslipMode="true"
              :show-search-all="false"
              @search="searchClicked"
            /> -->
            <!--期間のチェックボックス -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <div>
                <label>{{$t('label.term')}}</label>
                <div>
                  <vSelect name="from_year"
                              class="style-chooser length5"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="years"
                              v-model="searchCond.from_year"
                              :disabled="isCurrentMonth"
                              @input="synchronizeFrom">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                  <vSelect name="from_month"
                              class="style-chooser length3"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="months"
                              v-model="searchCond.from_month"
                              :disabled="isCurrentMonth"
                              ref="fromMonth"
                              @input="synchronizeFrom">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                  ～
                  <vSelect name="to_year"
                              class="style-chooser length5"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="years"
                              v-model="searchCond.to_year"
                              :disabled="isCurrentMonth"
                              @input="synchronizeTo">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                  <vSelect name="to_month"
                              class="style-chooser length3"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="months"
                              ref="toMonth"
                              v-model="searchCond.to_month"
                              :disabled="isCurrentMonth"
                              @input="synchronizeTo">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                </div>
              </div>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
              <div>
                <label for="isCurrentMonth">{{$t('label.this_month')}}</label>
                <div style="padding: 6px 0;">
                  <input
                    type="checkbox"
                    v-model="isCurrentMonth"
                    class="gray"
                    id="isCurrentMonth"
                    @click="isCurrentMonthClicked"
                  >
                </div>
              </div>
            </div>
            <div class="search-button-area">
              <!-- 検索ボタン -->
              <template v-if="labels.search === 1">
                <button type="button" class="btn btn-sm btn-default btn-search" :disabled="isChanged" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </template>
            </div>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" />
      <template v-if="tableData && tableData.length > 0">
        <template v-if="tableData.length">
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <div style="margin-top: 10px;">
                <!-- 一括ダウンロードボタン -->
                <template v-if="roles.print === 1">
                  <button
                    type="button"
                    class="btn btn-sm btn-default btn-warning"
                    @click="doPrintAll()"
                    :style="executeOperation(roles.print === 1)"
                  >{{$t('button.batch_print')}}</button>
                  <hr />
                </template>
                <!-- 対象月別で繰り返し表示 -->
                <!-- <div
                  class="col-sm-12"
                  style="margin-bottom: 20px;"
                  v-for="salary_data in tableData"
                  v-bind:key="salary_data.target_date"
                > -->
                <div style="text-align: left;">
                  <!-- <h5>
                    <strong>{{salary_data.target_date.substring(0, 4)}}{{$t('label.year')}}{{salary_data.target_date.substring(4)}}{{$t('label.monthly')}}</strong>
                  </h5> -->
                  <!-- 給与明細送信 -->
                  <!-- <button
                    type="button"
                    class="btn btn-primary btn-sm btn-default"
                    @click="showSendMail(salary_data.target_date)"
                    v-show="canShow('/SS/menuSSScreen025')"
                    :disabled="canAllPrint(salary_data.salary_closing_data)"
                  >{{$t('button.send_payslip')}}</button> -->
                  <!-- 銀行振込一覧ボタン -->
                  <!-- <button
                    type="button"
                    class="btn btn-primary btn-sm btn-default"
                    @click="showBank(salary_data.target_date)"
                    v-show="canShow('/SS/menuSSScreen023')"
                    :disabled="canAllPrint(salary_data.salary_closing_data)"
                  >{{$t('button.bank_transfer_list')}}</button> -->
                  <!-- 現金一覧表ボタン -->
                  <!-- <button
                    type="button"
                    class="btn btn-primary btn-sm btn-default"
                    @click="showCashTransfer(salary_data.target_date)"
                    v-show="canShow('/SS/menuSSScreen026')"
                    :disabled="canAllPrint(salary_data.salary_closing_data)"
                  >{{$t('button.cash_transfer')}}</button> -->
                  <!-- 仕訳データ一覧ボタン -->
                  <!-- <button
                    type="button"
                    class="btn btn-primary btn-sm btn-default"
                    @click="showJournal(salary_data.target_date)"
                    v-show="canShow('/SS/menuSSScreen024')"
                    :disabled="canAllPrint(salary_data.salary_closing_data)"
                  >{{$t('button.journal_list')}}</button> -->
                </div>
                <br />
                <!-- Table開始 -->
                <div style="display: inline-block;">
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                        class="table-width"
                    border
                    :data="tableData"
                    :empty-text="$t('label.no_data')"
                    :style="tableStyle"
                    ref="table"
                    :row-class-name="tableRowClassName"
                  >
                    <!-- 操作 -->
                    <el-table-column
                      :width="140"
                      v-if="showOperation"
                      fixed="left"
                      :key="componentKey"
                      :label="$t('label.operation')"
                    >
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <!-- 印刷 -->
                        <template v-if="roles.print === 1">
                          <button
                            type="button"
                            class="btn btn-sm btn-default btn-warning"
                            :style="executeOperation(canPrint(props.row))"
                            @click="doPrint(props.$index, props.row)"
                          >{{$t('button.print')}}</button>
                        </template>
                        <!-- 表示 -->
                        <template v-if="canPreview(props.row)">
                          <button type="button" class="btn btn-sm btn-default btn-warning" @click="handleView(props.$index, props.row, tableData)">{{$t('button.detail')}}</button>
                        </template>
                      </template>
                    </el-table-column>
                    <!-- 年月 -->
                    <el-table-column
                      :width="120"
                      :label="$t('label.target_year_month')"
                    >
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div class="strong-text">{{props.row.target_date.substring(0, 4) + $t('label.year') + props.row.target_date.substring(4) + $t('label.month')}}</div>
                      </template>
                    </el-table-column>
                    <!-- 総支給額 -->
                    <el-table-column
                      :width="100"
                      :label="labels && labels.total_payment_amount_ryaku"
                    >
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: right;">
                          <template v-if="props.row.diff_total_payment_amount > 0">
                            <span class="ti-arrow-up" style="color: blue; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_total_payment_amount === 0">
                            <span class="ti-arrow-right" style="color: green; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_total_payment_amount < 0">
                            <span class="ti-arrow-down" style="color: red; font-weight: bold;"></span>
                          </template>
                          &nbsp;
                          {{formatNumber(props.row.total_payment_amount) + $t('label.unit_yen')}}
                          <br>
                          <template v-if="props.row.diff_total_payment_amount > 0">
                            <span style="font-size: 10px; color: blue;">({{ formatNumber(props.row.diff_total_payment_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_total_payment_amount === 0">
                            <span style="font-size: 10px; color: green;">({{ formatNumber(props.row.diff_total_payment_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_total_payment_amount < 0">
                            <span style="font-size: 10px; color: red;">({{ formatNumber(props.row.diff_total_payment_amount) + $t('label.unit_yen') }})</span>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 総控除額 -->
                    <el-table-column
                      :width="100"
                      :label="labels && labels.total_deduction_amount_ryaku"
                    >
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: right;">
                          <template v-if="props.row.diff_total_deduction_amount > 0">
                            <span class="ti-arrow-up" style="color: blue; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_total_deduction_amount === 0">
                            <span class="ti-arrow-right" style="color: green; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_total_deduction_amount < 0">
                            <span class="ti-arrow-down" style="color: red; font-weight: bold;"></span>
                          </template>
                          &nbsp;
                          {{ formatNumber(props.row.total_deduction_amount) + $t('label.unit_yen') }}
                          <br>
                          <template v-if="props.row.diff_total_deduction_amount > 0">
                            <span style="font-size: 10px; color: blue;">({{ formatNumber(props.row.diff_total_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_total_deduction_amount === 0">
                            <span style="font-size: 10px; color: green;">({{ formatNumber(props.row.diff_total_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_total_deduction_amount < 0">
                            <span style="font-size: 10px; color: red;">({{ formatNumber(props.row.diff_total_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- 差引支給額 -->
                    <el-table-column
                      :width="100"
                      :label="labels && labels.deduction_amount_ryaku"
                    >
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: right;">
                          <template v-if="props.row.diff_deduction_amount > 0">
                            <span class="ti-arrow-up" style="color: blue; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_deduction_amount === 0">
                            <span class="ti-arrow-right" style="color: green; font-weight: bold;"></span>
                          </template>
                          <template v-else-if="props.row.diff_deduction_amount < 0">
                            <span class="ti-arrow-down" style="color: red; font-weight: bold;"></span>
                          </template>
                          &nbsp;
                          {{ formatNumber(props.row.deduction_amount) + $t('label.unit_yen') }}
                          <br>
                          <template v-if="props.row.diff_deduction_amount > 0">
                            <span style="font-size: 10px; color: blue;">({{ formatNumber(props.row.diff_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_deduction_amount === 0">
                            <span style="font-size: 10px; color: green;">({{ formatNumber(props.row.diff_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                          <template v-else-if="props.row.diff_deduction_amount < 0">
                            <span style="font-size: 10px; color: red;">({{ formatNumber(props.row.diff_deduction_amount) + $t('label.unit_yen') }})</span>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="p-homeChart">
                  <div>
                    <vSelect name="selectedYear"
                              class="style-chooser length4"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="resultYears"
                              @input="selectedYearChanged"
                              v-model="selectedYear">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>&nbsp;{{ $t('label.year') }}
                  </div>
                  <BarChart :chartdata="monthChartdata" :options="monthOptions" :width="300" :height="300" />
                </div>
                <div class="p-homeChart" style="padding-top: 38px;">
                  <BarChart :chartdata="yearChartdata" :options="yearOptions" :width="300" :height="300" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">{{$t('label.search_result')}}</h4>
            </div>
            <div class="card-content">
              <div style="margin-top: 10px;">{{$t('label.no_data')}}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
  import ReferencePaySlipSearchMixin from '@/components/Dashboard/Views/Salary/ReferencePaySlipSearchMixin'

  export default {
    name: 'ReferencePaySlipSearch',
    mixins: [ReferencePaySlipSearchMixin]
  }
</script>
<style scoped>
  .p-homeChart {
    display: inline-block;
    vertical-align: top;
    padding-left: 100px;
  }
</style>
<style>
  .el-table .closed-row {
    background-color: #f0f8ff !important;
  }
</style>
