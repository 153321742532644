<template>
  <div class="card">
    <div class="card-content">
      <p>
        {{$t('label.difference_amount') + '  ' + differenceAmount}}
      </p>
      <p>
        {{$t('label.after_change')}}
        <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="addRow">{{$t('button.add_row')}}</button>
      </p>
      <template v-if="errMsgs.length > 0">
        <div v-for="errMsg in errMsgs" v-bind:key="errMsg">
          <small style="color: #C84513; margin-left: 5px;">{{errMsg}}</small>
        </div>
      </template>
      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                :data="application.after"
                :empty-text="$t('label.no_data')"
                :key="componentKey">
        <el-table-column width="40" :label="$t('label.delete')" align="center">
          <template slot-scope="props">
            <template v-if="!props.row.isFixed">
              <div style="text-align: center;">
                <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly">
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="labels.payment_unit ? labels.payment_unit : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.payment_unit"
                        :disabled="isReadOnly"
                        @change="paymentUnitChanged(props.row)">
              <el-option v-for="element in comboData.payment_unit_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="labels.target_date ? labels.target_date : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.target_date"
                        :disabled="isReadOnly || (props.row.payment_unit !== '2' && props.row.payment_unit !== '3')">
              <el-option v-for="element in props.row.target_date_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="labels.traffic_division ? labels.traffic_division : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.traffic_division"
                        :disabled="isReadOnly">
              <el-option v-for="group in comboData.traffic_division_list"
                          class="select-success"
                          :value="group.value"
                          :label="group.label"
                          :key="group.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="190" :label="labels.transportation_name ? labels.transportation_name : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length10" 
                  v-model="props.row.transportation_name" 
                  :disabled="isReadOnly">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" :label="labels.start_section ? labels.start_section : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length6" 
                  v-model="props.row.start_section" 
                  :disabled="isReadOnly">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" :label="labels.end_section ? labels.end_section : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length6" 
                  v-model="props.row.end_section" 
                  :disabled="isReadOnly">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="190" :label="labels.distance_to_use_transportation_equipment ? labels.distance_to_use_transportation_equipment : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        :title="props.row.distance_to_use_transportation_equipment_label"
                        placeholder=""
                        v-model="props.row.distance_to_use_transportation_equipment"
                        @change="distanceToUseTransportationEquipmentChanged(props.row)"
                        :disabled="isReadOnly || (props.row.traffic_division !== '3')">
              <el-option v-for="element in comboData.distance_to_use_transportation_equipment_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="120" :label="labels.payment_amount ? labels.payment_amount : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control numLength6" 
                  style="display: inline-block; text-align: right;"
                  v-model="props.row.payment_amount" 
                  :disabled="isReadOnly">&nbsp;{{$t('label.unit_yen')}}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <p>
        {{$t('label.before_change')}}
      </p>
      <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                :data="application.before"
                :empty-text="$t('label.no_data')">
        <el-table-column width="40" label="" align="center">
        </el-table-column>
        <el-table-column width="170" :label="labels.payment_unit ? labels.payment_unit : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.payment_unit"
                        :disabled="true">
              <el-option v-for="element in comboData.payment_unit_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="labels.target_date ? labels.target_date : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.target_date"
                        :disabled="true">
              <el-option v-for="element in comboData.target_date_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="labels.traffic_division ? labels.traffic_division : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        placeholder=""
                        v-model="props.row.traffic_division"
                        :disabled="true">
              <el-option v-for="group in comboData.traffic_division_list"
                          class="select-success"
                          :value="group.value"
                          :label="group.label"
                          :key="group.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="190" :label="labels.transportation_name ? labels.transportation_name : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length10" 
                  v-model="props.row.transportation_name" 
                  :disabled="true">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" :label="labels.start_section ? labels.start_section : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length6" 
                  v-model="props.row.start_section" 
                  :disabled="true">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="150" :label="labels.end_section ? labels.end_section : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control length6" 
                  v-model="props.row.end_section" 
                  :disabled="true">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="190" :label="labels.distance_to_use_transportation_equipment ? labels.distance_to_use_transportation_equipment : ''" align="center">
          <template slot-scope="props">
            <el-select class="select-success length8"
                        size="large"
                        :title="props.row.distance_to_use_transportation_equipment_label"
                        placeholder=""
                        v-model="props.row.distance_to_use_transportation_equipment"
                        :disabled="true">
              <el-option v-for="element in comboData.distance_to_use_transportation_equipment_list"
                          class="select-success"
                          :value="element.value"
                          :label="element.label"
                          :key="element.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="120" :label="labels.payment_amount ? labels.payment_amount : ''" align="center">
          <template slot-scope="props">
            <div style="text-align: left;">
              <div style="display: inline-block;">
                <input 
                  type="text"
                  class="form-control numLength6" 
                  style="display: inline-block; text-align: right;"
                  v-model="props.row.payment_amount" 
                  :disabled="true">&nbsp;{{$t('label.unit_yen')}}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.supplement}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.supplement"
            :placeholder="isReadOnly ? '' : $t('placeholder.commuting_route_change_supplement')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationText,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    computed: {
      differenceAmount () {
        let differenceAmount = 0
        if (this.application.after) {
          for (const row of this.application.after) {
            if (row.payment_amount) {
              differenceAmount += parseInt(row.payment_amount.replace(',', ''), 10)
            }
          }
        }
        if (this.application.before) {
          for (const row of this.application.before) {
            if (row.payment_amount) {
              differenceAmount -= parseInt(row.payment_amount.replace(',', ''), 10)
            }
          }
        }
        if (differenceAmount < 0) {
          return this.formatNumber(-1 * differenceAmount) + this.$t('label.unit_yen') + '  ' + this.$t('label.decrease')
        } else {
          return this.formatNumber(differenceAmount) + this.$t('label.unit_yen') + '  ' + this.$t('label.increase')
        }
      }
    },
    methods: {
      distanceToUseTransportationEquipmentChanged (row) {
        const maxPaymentAmount = this.comboData.distance_to_use_transportation_equipment_mapping[row.distance_to_use_transportation_equipment]
        row.payment_amount = String(maxPaymentAmount)
      },
      paymentUnitChanged (row) {
        if (row.payment_unit !== '2' && row.payment_unit !== '3') {
          row.target_date = ''
        } else {
          if (row.payment_unit === '2') {
            row.target_date_list = this.comboData.target_date_list_4
          } else {
            row.target_date_list = this.comboData.target_date_list_2
          }
        }
      },
      addRow () {
        const row = {
          serial_number: '',
          traffic_division: '',
          distance_to_use_transportation_equipment: '',
          payment_unit: '',
          target_date: '',
          payment_amount: '',
          transportation_name: '',
          start_section: '',
          end_section: ''
        }
        this.application.after.push(row)
        this.componentKey += 1
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
  .after-change {
    padding-left: 20px;
  }
</style>
