<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <ValidationRadio
            v-model="searchCond.language_search_type"
            :item-label="labels.language_search_type"
            :is-search-condition="true"
          />
          <ValidationSelect
            v-model="searchCond.language"
            item-name="language"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.language_list)"
            :item-label="labels.language"
            :options="comboData.language_list"
            frame-class="search-condition-item"
            @change="languageChanged"
          />
          <div class="form-group search-condition-item">
            <label>{{labels.field_name}}</label>
            <input type="text" :placeholder="$t('placeholder.field_name')" maxlength="128" class="form-control length128" v-model="searchCond.field_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="form-group search-condition-item">
            <label>{{labels.label_name}}</label>
            <input type="text" :placeholder="$t('placeholder.label_name')" maxlength="128" class="form-control length128" v-model="searchCond.label_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="form-group search-condition-item">
            <label>{{labels.abbreviated_name}}</label>
            <input type="text" :placeholder="$t('placeholder.abbreviated_name')" maxlength="128" class="form-control length128" v-model="searchCond.abbreviated_name" :disabled="searchCond.language_search_type && searchCond.language_search_type.value===1">
          </div>
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showSelected" :width="50" :label="labels.selected_ryaku === undefined ? labels.selected_ryaku === undefined ? '' : labels.selected_ryaku : labels.selected_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.selected" class="gray" id="selected" :disabled="true"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showLanguage" :width="getColumnWidth(comboData.language_list, labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku, 'label', 'value')" :label="labels.language_ryaku === undefined ? labels.language_ryaku === undefined ? '' : labels.language_ryaku : labels.language_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.language, comboData.language_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showFieldName" :width="getColumnWidth(tableData, labels.field_name_ryaku === undefined ? labels.fieldName_ryaku === undefined ? '' : labels.fieldName_ryaku : labels.field_name_ryaku, 'field_name')" :label="labels.field_name_ryaku === undefined ? labels.fieldName_ryaku === undefined ? '' : labels.fieldName_ryaku : labels.field_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.field_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showLabelName" :width="410" :label="labels.label_name_ryaku === undefined ? labels.labelName_ryaku === undefined ? '' : labels.labelName_ryaku : labels.label_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.label_name"
                    item-name="label_name"
                    max-length="128"
                    classes="form-control length30"
                    rules="required"
                    :is-error-position-top="true"
                    :item-label="labels.label_name_ryaku === undefined ? labels.labelName_ryaku === undefined ? '' : labels.labelName_ryaku : labels.label_name_ryaku"
                    :placeholder="$t('placeholder.label_name')"
                    :help-text="$t('label.max_length')"
                    @input="labelNameChanged(props.row)"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showAbbreviatedName" :width="410" :label="labels.abbreviated_name_ryaku === undefined ? labels.abbreviatedName_ryaku === undefined ? '' : labels.abbreviatedName_ryaku : labels.abbreviated_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <ValidationText
                    v-model="props.row.abbreviated_name"
                    item-name="abbreviated_name"
                    max-length="128"
                    classes="form-control length30"
                    rules="required"
                    :is-error-position-top="true"
                    :item-label="labels.abbreviated_name_ryaku === undefined ? labels.abbreviatedName_ryaku === undefined ? '' : labels.abbreviatedName_ryaku : labels.abbreviated_name_ryaku"
                    :placeholder="$t('placeholder.abbreviated_name')"
                    :help-text="$t('label.max_length')"
                  >
                  </ValidationText>
                </template>
              </el-table-column>
              <el-table-column v-if="showChangeOkFlg" :width="50" :label="labels.change_ok_flg_ryaku === undefined ? labels.changeOkFlg_ryaku === undefined ? '' : labels.changeOkFlg_ryaku : labels.change_ok_flg_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" v-model="props.row.change_ok_flg" class="gray" id="change_ok_flg"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isChanged" @click="doUpdate">
        <span class="btn-label"><i class="fa fa-check"></i></span>
        {{$t('button.update')}}
      </button>
      <br><br><br><br><br>
    </template>
    </div>
  </div>
</template>
<script>
  import LanguageSearchMixin from '@/components/Dashboard/Views/Generated/LanguageSearchMixin'

  export default {
    name: 'LanguageSearch',
    data () {
      return {
        showOperation: false
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.language_list = res.common_area.combo_data.language_type_list
        this.searchCond.language_search_type = {
          value: 1,
          disabled: false,
          items: [
            {
              label: this.$t('label.language_search_type1'),
              value: 1
            },
            {
              label: this.$t('label.language_search_type2'),
              value: 2
            }
          ]
        }
        this.searchCond.language = 'JPN'
      },
      afterSearch (res) {
        this.comboData.language_list = res.common_area.combo_data.language_type_list
        this.labels.selected_ryaku = this.$t('button.delete')
        this.labels.change_ok_flg_ryaku = this.$t('label.change_ok_flg')
        for (const row of this.tableData) {
          row.language = row.choice_language
          row.change_ok_flg = row.change_ok_flg === 1
        }
      },
      convertSearchCond (searchCond) {
        searchCond.choice = searchCond.language_search_type.value
        searchCond.choice_language = searchCond.language ? searchCond.language : 'JPN'
        delete searchCond.language_search_type
        delete searchCond.language
        return searchCond
      },
      labelNameChanged (row) {
        row.abbreviated_name = row.label_name
      },
      doUpdate () {
        const detail = {}
        const languageList = []
        for (const row of this.tableData) {
          if (row.field_name) {
            const element = {
              del_flag: !!row.selected,
              language: row.language,
              field_name: row.field_name,
              label_name: row.label_name ? row.label_name : null,
              abbreviated_name: row.abbreviated_name ? row.abbreviated_name : null,
              change_ok_flg: row.change_ok_flg ? 1 : 2
            }
            languageList.push(element)
          }
        }
        detail.language_list = languageList
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: detail
        }
        this.send(`/update/language/list`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            // this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      }
    },
    mixins: [LanguageSearchMixin]
  }
</script>
<style scoped>
</style>
