<template>
  <div class="row" style="overflow-y: auto; height: 570px;" name="employeeFrame">
    <div class="col-md-12">
      <div class="card">
        <div class="card-content" style="padding-left: 20px;">
          <template v-if="!isReadOnly">
            <label for="planId">
              {{labels.employee_application_control}}
            </label>
            <br>
            <el-select class="select-success"
                        size="large"
                        name="team"
                        :disabled="isReadOnly"
                        v-model="employee_application_control">
              <el-option v-for="option in employee_application_control_list"
                          class="select-success"
                          :value="option.value"
                          :label="option.label"
                          :key="option.label">
              </el-option>
            </el-select>
            <button type="button" class="btn btn-sm btn-default btn-primary" @click="allEmployeeApplicationControl">{{$t('label.all_employee_application_control')}}</button>
            <br>
            <br>
          </template>
          <label for="planId">
            {{$t('label.application_inf')}}
          </label>
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"            border
            :data="filteredData"
            style="width: 550px">
            <!-- 申請分類コード -->
            <el-table-column min-width="100" :label="$t('label.application_classification')">
              <template slot-scope="props">
                {{getApplicationClassificationName(props.row.application_classification_code)}}
              </template>
            </el-table-column>
            <!-- 申請書 -->
            <el-table-column min-width="120" :label="$t('label.application')">
              <template slot-scope="props">
                <div style="text-align: left; height: 40px; line-height: 350%;">
                  {{props.row.application_form_name}}
                </div>
              </template>
            </el-table-column>
            <!-- 従業員申請管理区分 -->
            <el-table-column min-width="200" :label="labels.employee_application_control_ryaku ? labels.employee_application_control_ryaku : ''">
              <template slot-scope="props">
                <template v-if="disableApplicationForms.includes(props.row.application_form_code)">
                  {{ employee_application_control_list.find((row) => row.value === props.row.employee_application_control).label }}
                </template>
                <template v-else>
                  <el-select class="select-success"
                              size="large"
                              name="team"
                              @change="applicationControlChanged(props.row, props.row.employee_application_control)"
                              :disabled="isReadOnly"
                              v-model="props.row.employee_application_control">
                    <el-option v-for="option in employee_application_control_list"
                                class="select-success"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import {DatePicker} from 'element-ui'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      'el-date-picker': DatePicker
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        labels: {},
        application_record: [],
        application_classification_list: [],
        employee_application_control_list: [],
        employee_application_control: '3'
      }
    },
    computed: {
      filteredData () {
        return !this.application_record ? [] : this.application_record.filter(e => e.application_form_code !== '0000000017' && e.application_form_code !== '0000000021')
      },
      isReadOnly () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete
      },
      isReadOnly_Pk () {
        let parent = this.$parent.$parent.$parent
        return parent.mode === this.modes.view || parent.mode === this.modes.delete || parent.mode === this.modes.edit
      }
    },
    methods: {
      applicationControlChanged (row) {
        if (row.application_form_code === '0000000016' && row.employee_application_control === '2') {
          swal({
            html: '出勤簿申請を管理しないに変更すると以下の申請も管理しないに変更されます。よろしいですか？<br>・深夜申請<br>・法定休日出勤申請<br>・残業申請<br>・遅刻申請<br>・早退申請',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            for (const data of this.application_record) {
              if (data.application_form_code === '0000000004' || // 深夜
                data.application_form_code === '0000000007' || // 休日出勤
                data.application_form_code === '0000000002' || // 残業
                data.application_form_code === '0000000005' || // 遅刻
                data.application_form_code === '0000000006' // 早退
              ) {
                data.employee_application_control = '2'
              }
            }
          }).catch(() => {})
        }
      },
      init () {
      },
      setDetails (res, mode) {
        if ((mode !== this.modes.add) || (mode === this.modes.add && sessionStorage.getItem('employee_code') !== '')) {
          let applicationRecord = []
          for (const data of res.api_area.application_form_list) {
            let record = {
              application_form_code: data.application_form_code,
              application_form_name: data.application_form_name,
              application_classification_code: data.application_classification_code,
              employee_application_control: this.disableApplicationForms.includes(data.application_form_code) ? '1' : String(data.employee_application_control) === '' ? '3' : String(data.employee_application_control)
            }
            applicationRecord.push(record)
          }
          this.application_record = applicationRecord
        }
      },
      // 申請書名取得
      getApplicationClassificationName (code) {
        for (const data of this.application_classification_list) {
          if (data.value === code) {
            return data.label
          }
        }
        return ''
      },
      allEmployeeApplicationControl () {
        for (const data of this.application_record) {
          if (!this.disableApplicationForms.includes(data.application_form_code)) {
            data.employee_application_control = this.employee_application_control
            data.before__application_control = this.employee_application_control
          }
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
