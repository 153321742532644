import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSalaryItemNameText from '@/components/UIComponents/Inputs/ValidationSalaryItemNameText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
import ValidationSelectMini from '@/components/UIComponents/Inputs/ValidationSelectMini'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'

export default {
  name: 'SalaryItemDetailMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationText,
    ValidationSalaryItemNameText,
    ValidationTextNumber,
    ValidationSelect,
    ValidationSearchSelect,
    ValidationSelectMini,
    ValidationCheckboxes,
    ValidationTextArea
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        salary_item_name: '',
        salary_bonus_classification: '1',
        employment_insurance_target_category: '1',
        tax_target_category: '1',
        social_insurance_target_category: '1',
        fixed_fluctuation: '1',
        account_code: '',
        sub_account_code: '',
        availability: '1',
        basic_salary: '1',
        payment_deduction_category: '',
        year_classification: true,
        month_classification: true,
        month_day_classification: true,
        day_month_classification: true,
        time_classification: true,
        bonus_classification: true,
        sign: '1',
        payment_method: '',
        calculation_by_salary_item_data: undefined,
        formula: undefined,
        allowance: '',
        included_fixed_wages_social_security: undefined,
        daily_division: 2
      },
      original_name: '',
      tableData: undefined,
      steps: [
        {
          target: '[data-v-step="0"]',
          content: '支給項目か控除項目を選択してください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="1"]',
          content: '給与項目の名前を入力してください。この名前が給与・賞与明細に表示されます。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="2"]',
          content: '選択した項目の明細レイアウトに設定することができます。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="3"]',
          content: '登録する給与項目を、給与明細レイアウトで利用する給与区分にチェックを入れてください。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="4"]',
          content: '固定項目は、基本給など固定の額を設定する項目です。変動項目は、残業手当など計算式を利用する項目です。',
          params: {placement: 'top-start'}
        },
        {
          target: '[data-v-step="5"]',
          content: '最終的な符号を選択してください。欠勤控除等の給与項目は「ー」を選択します。',
          params: {placement: 'bottom-start'}
        },
        {
          target: '[data-v-step="6"]',
          content: 'コード 仕訳データの勘定科目、補助勘定科目を選択してください。',
          params: {placement: 'top-start'}
        }
      ],
      labels: {},
      comboData: {},
      payment_list: [],
      deduction_list: [],
      calculation_list: [],
      salaryPaymentMethod_list: [],
      bonusPaymentMethod_list: [],
      sub_account_list: [],
      sub_account_list_all: [],
      account_mapping_list: [],
      sign: {
        // DIVISION 除算 1
        // MULTI_PLICATION 乗算 2
        options: [{
          value: '1',
          label: '÷'
        }, {
          value: '2',
          label: '×'
        }]
      },
      message_payment_notedit: '',
      message_deduction_notedit: '',
      message_calc_notedit: '',
      fixedFluctuationDisabled: false,
      systemFlg: false,
      defaultFixedFluctuation: '',
      selectedFixedList: '',
      selectedAggregationCategoryList: ''
    }
  },
  computed: {
    screenCode () {
      return '03-008'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged || this.detail.fixed_fluctuation === '3'
    },
    isAllowanceReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  watch: {
    'detail.account_code': function () {
      this.changeSubAccountList()
    },
    'detail.salary_bonus_classification': function () {
      this.fixedFluctuationDisabled = this.isFixedFluctuationDisabled()
      if (this.detail.salary_bonus_classification === '2') {
        // 賞与が選ばれた場合、固定項目を指定する
        this.detail.fixed_fluctuation = this.systemFlg ? this.defaultFixedFluctuation : '1'
      } else {
        // 給与が選ばれた場合、初期値を設定する
        this.detail.fixed_fluctuation = this.defaultFixedFluctuation
      }
    }
  },
  methods: {
    salaryBonusClassificationChanged () {
      if (this.detail.salary_bonus_classification === '2') {
        this.detail.fixed_fluctuation = this.systemFlg ? this.defaultFixedFluctuation : '1'
        this.detail.daily_division = 2
      }
    },
    insertFormula (sign) {
      this.detail.formula = this.detail.formula ? this.detail.formula += sign : sign
    },
    deleteFormula () {
      this.detail.formula = this.detail.formula ? this.detail.formula.substring(0, this.detail.formula.length - 1) : ''
    },
    fixedListChanged () {
      for (const row of this.comboData.fixed_list) {
        if (row.value === this.selectedFixedList) {
          this.detail.formula = this.detail.formula ? this.detail.formula + ' [' + row.label + ']' : '[' + row.label + ']'
          break
        }
      }
    },
    aggregationCategoryListChanged () {
      for (const row of this.comboData.aggregation_category_list) {
        if (row.value === this.selectedAggregationCategoryList) {
          this.detail.formula = this.detail.formula ? this.detail.formula + ' [' + row.label + ']' : '[' + row.label + ']'
          break
        }
      }
    },
    availabilityChanged () {
      if (this.detail.is_using_salary_item_code && this.detail.availability !== 1 && this.detail.availability !== '1') {
        swal({
          text: this.$t('message.unavailable_choice'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          allowOutsideClick: false
        }).then(() => {
          this.detail.availability = 1
        }).catch(() => {})
      }
    },
    // 給与リスト作成
    editSalaryPaymentMethodList () {
      this.salaryPaymentMethod_list = [
        {
          id: 1,
          label: this.labels.year_classification,
          selected: this.detail.year_classification
        },
        {
          id: 2,
          label: this.labels.month_classification,
          selected: this.detail.month_classification
        },
        {
          id: 3,
          label: this.labels.month_day_classification,
          selected: this.detail.month_day_classification
        },
        {
          id: 4,
          label: this.labels.day_month_classification,
          selected: this.detail.day_month_classification
        },
        {
          id: 5,
          label: this.labels.time_classification,
          selected: this.detail.time_classification
        }
      ]
    },
    // 賞与リスト作成
    editBonusPaymentMethodList () {
      this.bonusPaymentMethod_list = [
        {
          id: 8,
          label: this.labels.bonus_classification,
          selected: true
        }
      ]
    },
    changeSubAccountList () {
      this.sub_account_list = []
      for (const data of this.account_mapping_list[this.detail.account_code]) {
        for (const subAccount of this.sub_account_list_all) {
          if (subAccount.value === data) {
            this.sub_account_list.push(subAccount)
          }
        }
      }
      let foundFlg = false
      for (const subAccountList of this.sub_account_list) {
        if (this.detail.sub_account_code === subAccountList.value) {
          foundFlg = true
          break
        }
      }
      if (!foundFlg) {
        this.detail.sub_account_code = ''
      }
    },
    editClassification (target, classification, mode) {
      // 1.afterInit 用
      if (mode === 1) {
        if (target[classification] === '1') {
          // AVAILABLE 利用可能
          target[classification] = true
        } else {
          // UNUSABLE 利用不可
          target[classification] = false
        }
        // 2.convertDetail用
      } else {
        if (target[classification] === true) {
          // AVAILABLE 利用可能
          target[classification] = 1
        } else {
          // UNUSABLE 利用不可
          target[classification] = 2
        }
      }
    },
    addRecord () {
      this.calculation_list.push({
        // 削除
        deleted: false,
        // 計算順序
        calculation_order: 0,
        // 集計区分 (乗算除算)
        division_multiplication_division: '1',
        // 集計区分
        aggregation_category: null,
        // 固定値
        fixed_value: null
      })
    },
    // サーバ送信用にnullをブランクに変換する
    servNullToBlank (target, name) {
      if (!target[name]) {
        target[name] = ''
      }
      return target[name]
    },
    // サーバ送信用に数値に変換する
    servInt (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    servInt0 (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = 0
      }
      return target[name]
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      this.defaultFixedFluctuation = this.detail.fixed_fluctuation
      if (this.detail.fixed_fluctuation === '3') {
        this.systemFlg = true
      }
      this.fixedFluctuationDisabled = this.isFixedFluctuationDisabled()
      this.comboData.account_list = res.common_area.combo_data.account_mapping_list.account_list
      this.sub_account_list_all = res.common_area.combo_data.account_mapping_list.sub_account_list
      this.account_mapping_list = res.common_area.combo_data.account_mapping_list.account_mapping_list
      this.comboData.fixed_list = [...this.comboData.payment_list, ...this.comboData.deduction_list]
      // 日割り対象
      if (!this.detail.daily_division) {
        this.detail.daily_division = 2
      }
      // 社会保険料を決定する上での固定賃金区分
      this.detail.included_fixed_wages_social_security = {
        color: 'gray',
        disabled: this.isReadOnly,
        items: [
          {
            id: 1,
            label: this.labels.included_fixed_wages_social_security,
            value: !!this.detail.included_fixed_wages_social_security
          }
        ]
      }
      // 補助勘定科目の編集
      if (this.detail.sub_account_code) {
        this.detail.sub_account_code = this.detail.account_code + '_' + this.detail.sub_account_code
      }
      // 非課税枠[以下まで]の編集
      if (!this.detail.tax_exemption_limit) {
        this.detail.tax_exemption_limit = ''
      }
      // 計算リストの編集
      this.calculation_list = this.detail.calculation_by_salary_item_data
      if (!this.calculation_list) {
        this.calculation_list = [{
          // 削除
          deleted: false,
          // 計算順序
          calculation_order: 0,
          // 集計区分 (乗算除算)
          division_multiplication_division: '1',
          // 集計区分
          aggregation_category: '',
          // 固定値
          fixed_value: null
        }]
      }
      for (const entry of Object.entries(this.calculation_list)) {
        // 符号の型を補正する
        if (entry[1].division_multiplication_division) {
          entry[1].division_multiplication_division = String(entry[1].division_multiplication_division)
        }
        // 集計区分の型を補正する
        if (entry[1].aggregation_category) {
          entry[1].aggregation_category = String(entry[1].aggregation_category)
        }
        // 固定値の型を補正する
        if (entry[1].fixed_value || entry[1].fixed_value === 0) {
          entry[1].fixed_value = String(entry[1].fixed_value)
        }
      }
      // 支給および控除選択箇所の調整
      if (this.detail.salary_target_item_data) {
        for (const entry of Object.entries(this.detail.salary_target_item_data)) {
          if (entry[1].addition_subtraction_classification === 1) {
            this.payment_list.push(entry[1])
          } else {
            this.deduction_list.push(entry[1])
          }
        }
      }
      // ７項目までリストを追加する
      for (let i = this.payment_list.length + 1; i <= 7; i++) {
        this.payment_list.push({
          salary_target_item_code: '',
          calculation_order: i,
          addition_subtraction_classification: 1
        })
      }
      // ７項目までリストを追加する
      for (let i = this.deduction_list.length + 1; i <= 7; i++) {
        this.deduction_list.push({
          salary_target_item_code: '',
          calculation_order: i,
          addition_subtraction_classification: 2
        })
      }
      // 式の変換
      if (this.detail.formula) {
        for (const row of this.comboData.fixed_list) {
          this.detail.formula = this.detail.formula.replaceAll('{' + row.value + '}', '[' + row.label + ']')
        }
        for (const row of this.comboData.aggregation_category_enum_list) {
          for (const row2 of this.comboData.aggregation_category_list) {
            if (row.value === row2.value) {
              this.detail.formula = this.detail.formula.replaceAll('{' + row.label + '}', '[' + row2.label + ']')
              break
            }
          }
        }
      }
      // 符号の調整
      if (this.detail.sign) {
        this.detail.sign = String(this.detail.sign)
      }
      // 固定/変動区分 システム項目以外の場合
      // ラジオボタンからシステム項目を削除する
      if (this.detail.fixed_fluctuation !== '3') {
        // 3(システム項目)以下を抽出する
        this.comboData.fixed_fluctuation_list = this.comboData.fixed_fluctuation_list.filter(item => item.value < 3)
      } else {
        // 3(システム項目)を抽出する
        this.comboData.fixed_fluctuation_list = this.comboData.fixed_fluctuation_list.filter(item => item.value > 2)
      }
      // 給与支給方法
      // チェックボックスの補正
      if (this.mode !== this.modes.add) {
        this.editClassification(this.detail, 'year_classification', 1)
        this.editClassification(this.detail, 'month_classification', 1)
        this.editClassification(this.detail, 'month_day_classification', 1)
        this.editClassification(this.detail, 'day_month_classification', 1)
        this.editClassification(this.detail, 'time_classification', 1)
        this.editClassification(this.detail, 'bonus_classification', 1)
      }
      // 利用可否
      if (this.detail.is_using_salary_item_code) {
        this.detail.availability = 1
        // this.comboData.availability_list.splice(1, 1)
      }
      // 給与リスト作成
      this.editSalaryPaymentMethodList()
      // 賞与リスト作成
      this.editBonusPaymentMethodList()
      // システム項目名
      this.original_name = res.api_area.salary_item_data.salary_item_format_name
    },
    // 独自バリデーション用
    customValidate () {
      // エラーメッセージの初期化
      this.message_payment_notedit = ''
      this.message_calc_notedit = ''

      // 変動項目の場合
      // if (this.detail.fixed_fluctuation === '2') {
      //   // 支給項目がすべて未入力の場合
      //   var isExistPayment = false
      //   for (const entry of Object.entries(this.payment_list)) {
      //     if (entry[1].salary_target_item_code) {
      //       isExistPayment = true
      //       break
      //     }
      //   }
      //   // 支給項目が入っていない場合エラー
      //   if (!isExistPayment) {
      //     this.message_payment_notedit = this.$t('message.salaryItem_detail_payment_notedit')
      //     return false
      //   }
      //   // 削除チェックボックスがついていないものだけを抽出する
      //   this.calculation_list = this.calculation_list.filter((item) => {
      //     return (!item.deleted)
      //   })
      //   // 計算箇所のリストが０件の場合エラー
      //   if (!this.calculation_list.length) {
      //     this.message_calc_notedit = this.$t('message.salaryItem_detail_calc_notedit')
      //     return false
      //   }
      //    // 集計区分と固定値がどちらも未入力の場合エラー
      //   for (const entry of Object.entries(this.calculation_list)) {
      //     if (!entry[1].aggregation_category && !entry[1].fixed_value) {
      //       this.message_calc_notedit = this.$t('message.salaryItem_detail_calc_notedit')
      //       return false
      //     }
      //   }
      // }
      return true
    },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode === this.modes.delete) {
        return detail
      }

      if (detail.salary_bonus_classification === '1') {
        for (const entry of Object.entries(this.salaryPaymentMethod_list)) {
          if (entry[1].id === 1) {
            // 年俸制
            detail.year_classification = entry[1].selected
          } else if (entry[1].id === 2) {
            // 月給制
            detail.month_classification = entry[1].selected
          } else if (entry[1].id === 3) {
            // 月給日給制
            detail.month_day_classification = entry[1].selected
          } else if (entry[1].id === 4) {
            // 日給月給制
            detail.day_month_classification = entry[1].selected
          } else if (entry[1].id === 5) {
            // 時給制
            detail.time_classification = entry[1].selected
          }
        }
        // 賞与
        detail.bonus_classification = false
      } else {
        // 年俸制
        detail.year_classification = false
        // 月給制
        detail.month_classification = false
        // 月給日給制
        detail.month_day_classification = false
        // 日給月給制
        detail.day_month_classification = false
        // 時給制
        detail.time_classification = false
        // 賞与
        detail.bonus_classification = true
      }

      // 数値型に変換する
      this.servInt(detail, 'fixed_fluctuation')
      this.servInt(detail, 'payment_deduction_category')
      this.servInt(detail, 'sign')
      this.servInt(detail, 'availability')
      this.servInt(detail, 'employment_insurance_target_category')
      this.servInt(detail, 'payment_method')
      this.servInt(detail, 'salary_bonus_classification')
      this.servInt(detail, 'social_insurance_target_category')
      this.servInt(detail, 'tax_target_category')
      this.servInt(detail, 'tax_exemption_limit')
      this.servInt(detail, 'allowance')
      this.servInt(detail, 'basic_salary')
      this.servInt(detail, 'daily_division')
      // チェックボックスの補正
      this.editClassification(detail, 'year_classification', 2)
      this.editClassification(detail, 'month_classification', 2)
      this.editClassification(detail, 'month_day_classification', 2)
      this.editClassification(detail, 'day_month_classification', 2)
      this.editClassification(detail, 'time_classification', 2)
      this.editClassification(detail, 'bonus_classification', 2)

      // 支給項目の編集
      // #   PAYMENT 支給項目 1
      // #   DEDUCTION 控除項目 2
      // #   SUMMARY 集計項目 3
      if (detail.payment_deduction_category !== 1) {
        // #   TARGET 対象 1
        // #   OUT_OF_SCOPE 対象外 2
        detail.employment_insurance_target_category = 2 // 雇用保険対象区分
        detail.tax_target_category = 2 // 所得税対象区分
        // 対象外 2
        detail.basic_salary = 2
        // 対象外 4
        detail.payment_method = 4
      }
      // 所得税対象区分の編集
      if (detail.tax_target_category !== 1 || detail.tax_exemption_limit === '') {
        detail.tax_exemption_limit = null // 非課税枠[以下まで]の編集
      }
      // 社会保険料を決定する上での固定賃金区分の編集
      detail.included_fixed_wages_social_security = detail.included_fixed_wages_social_security.items[0].value

      // 変動項目以外の場合、変動項目用の項目を空配列に書き換える
      // detail.salary_target_item_data = []
      // detail.calculation_by_salary_item_data = []
      // if (detail.fixed_fluctuation === 2) {
      //   var idx = 1
      //   // 支給・控除の編集
      //   for (const entry of Object.entries(this.payment_list.concat(this.deduction_list))) {
      //     if (entry[1].salary_target_item_code) {
      //       entry[1].calculation_order = idx
      //       idx = idx + 1
      //       detail.salary_target_item_data.push(entry[1])
      //     }
      //   }
      //   // 計算の編集
      //   idx = 1
      //   // 計算リストのディープコピーを行う
      //   detail.calculation_by_salary_item_data = this.calculation_list.map(list => ({...list}))
      //   // 削除チェックボックスがついていないものだけを抽出する
      //   detail.calculation_by_salary_item_data = detail.calculation_by_salary_item_data.filter((item) => {
      //     return (!item.deleted)
      //   })
      //   // DB登録用に編集を行う
      //   for (const entry of Object.entries(detail.calculation_by_salary_item_data)) {
      //     // 削除フラグを削除する
      //     delete entry[1].deleted
      //     // 計算順序
      //     entry[1].calculation_order = idx
      //     idx = idx + 1
      //     // 集計区分 (乗算除算)
      //     entry[1].division_multiplication_division = parseInt(entry[1].division_multiplication_division)
      //     // 集計区分、固定値はいずれかが入力される
      //     // 集計区分
      //     if (entry[1].aggregation_category) {
      //       entry[1].aggregation_category = parseInt(entry[1].aggregation_category)
      //     } else {
      //       delete entry[1].aggregation_category
      //     }
      //     // 固定値
      //     if (entry[1].fixed_value) {
      //       entry[1].fixed_value = parseFloat(entry[1].fixed_value)
      //     } else {
      //       delete entry[1].fixed_value
      //     }
      //   }
      // }
      delete detail.salary_target_item_data
      delete detail.calculation_by_salary_item_data
      if (detail.formula) {
        for (const row of this.comboData.fixed_list) {
          detail.formula = detail.formula.replaceAll('[' + row.label + ']', '{' + row.value + '}')
        }
        for (const row of this.comboData.aggregation_category_enum_list) {
          for (const row2 of this.comboData.aggregation_category_list) {
            if (row.value === row2.value) {
              detail.formula = detail.formula.replaceAll('[' + row2.label + ']', '{' + row.label + '}')
              break
            }
          }
        }
      }
      if (detail.sub_account_code) {
        detail.sub_account_code = detail.sub_account_code.split('_')[1]
      }
      delete detail.is_using_salary_item_code
      return detail
    },
    handleDelete  (index, row) {
      delete this.calculation_list.splice(index, 1)
    },
    onChangeAggregationCategory  (index, row) {
      row.fixed_value = null
    },
    onChangeFixedValue  (index, row) {
      row.aggregation_category = null
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {salary_item_code: this.detail.salary_item_code}
          detail.salary_item_name = this.detail.salary_item_name
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        // 固定項目の場合、計算の設定を削除する
        if (reqData.api_area.fixed_fluctuation === 1) {
          delete reqData.api_area.formula
        }
        delete reqData.api_area.salary_item_format_name
        this.send(`/${operation}/salary_item`, reqData, false)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
          if (this.mode === this.modes.edit) {
            // 固定→可変にした場合
            if (res.api_area !== null) {
              // api_areaに値が入っていたらストップ
              if (res.api_area.confirmation_required) {
                this.help = res.common_area.help
                // 警告表示
                swal({
                  text: res.message.text,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                  cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                  type: 'warning',
                  showCancelButton: true,
                  reverseButtons: true
                }).then(() => {
                  reqData.api_area.confirmed = true
                  this.updateRetry(reqData)
                }).catch(() => {
                  reqData.api_area.confirmed = false
                  this.updateRetry(reqData)
                })
              }
            } else {
              this.message = res.message
              this.help = res.common_area.help
            }
          } else {
            this.message = res.message
            this.help = res.common_area.help
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    updateRetry (reqData) {
      this.send(`/update/salary_item`, reqData)
      .then(res => {
        this.componentKey += 1
        if (res.message.message_classification === 1) {
          this.isChanged = true
        }
      }).catch(error => {
        console.error(error)
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          salary_item_code: this.params.salary_item_code
        }
      }
      this.send('/init/salary_item', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area.salary_item_data)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    isFixedFluctuationDisabled () {
      if (this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged || this.detail.fixed_fluctuation === '3') {
        return true
      }
      // 支給区分が賞与だった場合
      if (this.detail.salary_bonus_classification === '2') {
        // 非活性にする
        return true
      } else {
        return false
      }
    },
    paymentDeductionCategoryChanged () {
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
      if (this.detail.payment_deduction_category === '1') {
        this.detail.payment_method = ''
      } else if (this.detail.payment_deduction_category === '2') {
        this.detail.salary_bonus_classification = '1'
      }
      this.componentKey += 1
    },
    mouseDown (event) {
      event.target.style.cursor = 'grabbing'
    },
    dragStart (event) {
      event.target.style.cursor = 'default'
      event.dataTransfer.setData('mytext', event.target.innerText)
      event.stopPropagation()
    },
    dragEnter (event) {
      event.preventDefault()
    },
    dragLeave (event) {
      event.preventDefault()
    },
    drop (event) {
      const data = event.dataTransfer.getData('mytext')
      if (data) {
        if (!this.detail.formula) {
          this.detail.formula = ''
        }
        this.detail.formula += '[' + data + ']'
        event.target.focus()
      }
      event.preventDefault()
    }
  },
  created () {
    this.init()
  }
}
