<template>
  <div>
    <h5 class="text-center">会社の基本情報、労働時間の端数の取り扱いにつきましてご確認をお願いいたします。</h5>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 460px);" name="companyFrame" :key="componentKey">
      <div class="col-md-5 col-md-offset-1">
        <div class="card">
          <div class="card-header" style="font-size: 18px;">
            <img src="static/img/corpus.png" style="height: 50px; width: auto;">
          </div>
          <div class="card-content">
            <div style="margin-bottom: 5px;"></div>
            <div class="form-group">
              <div><label>会社</label></div>
              {{before.company_code}}&nbsp;&nbsp;{{before.company_nm}}
            </div>
            <div class="form-group">
              <div><label>言語設定</label></div>
              {{getSelectName(before.language, languages)}}
            </div>
            <div class="form-group">
              <div><label>ホームページ</label></div>
              {{before.homepage}}
            </div>
            <div class="form-group">
              <div><label>郵便番号</label></div>
              {{before.post}}
            </div>
            <div class="form-group">
              <div><label>都道府県</label></div>
              <div style="height: 40px;">
                {{before.prefectural}}
              </div>
            </div>
            <div class="form-group">
              <div><label>市区</label></div>
              <div style="height: 40px;">
                {{before.municipality}}
              </div>
            </div>
            <div class="form-group">
              <div><label>町村</label></div>
              {{before.town}}
            </div>
            <div class="form-group">
              <div><label>ビル／番地</label></div>
              {{before.building}}
            </div>
            <div class="form-group">
              <div><label>電話番号</label></div>
              {{before.telephone1}}
            </div>
            <div class="form-group">
              <div><label>ファックス番号</label></div>
              {{before.fax}}
            </div>
            <div class="form-group">
              <div><label>業種（大分類）</label></div>
              <div style="height: 42px;">
                {{before.industry1_name}}
              </div>
            </div>
            <div class="form-group">
              <div><label>業種（中分類）</label></div>
              <div style="height: 42px;">
                {{before.industry2_name}}
              </div>
            </div>
            <div class="form-group">
              <div><label>業種（小分類）</label></div>
              <div style="height: 63px;">
                {{before.industry3_name}}
              </div>
            </div>
            <div class="form-group">
              <label>週初めの曜日</label>
              <div style="height: 42px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>労働秒の丸め</label>
              <div style="height: 34px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>労働時間の丸め</label>
              <div style="height: 34px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>月単位での丸め</label>
              <div style="height: 34px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <div><label>所定時間計算単位</label></div>
              <div style="height: 42px;">
                {{getSelectName(before.rounding_term, workMinuteRoundTypes)}}
              </div>
            </div>
            <div class="form-group">
              <div><label>残業時間計算単位</label></div>
              <div style="height: 42px;">
                {{getSelectName(before.rounding_term_over_work, workMinuteRoundTypes)}}
              </div>
            </div>
            <div class="form-group">
              <label>単位時間未満</label>
              <div style="height: 35px;">
                ※コーパスでは勤務体系ごとに管理しているため存在しません。
              </div>
            </div>
            <div class="form-group">
              <label>単位時間未満</label>
              <div style="height: 35px;">
                ※コーパスでは勤務体系ごとに管理しているため存在しません。
              </div>
            </div>
            <div class="form-group">
              <label>法人番号</label>
              <div style="height: 86px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>労働保険番号</label>
              <div style="height: 62px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>雇用保険加入有無</label>
              <div style="height: 35px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>健康保険加入有無</label>
              <div style="height: 35px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>厚生年金加入有無</label>
              <div style="height: 35px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>厚生年金基金加入有無</label>
              <div style="height: 35px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <label>事業の種類の番号</label>
              <div style="height: 42px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-header" style="font-size: 18px;">
            <img src="static/img/LOGO_A2.png" style="height: 50px; width: auto;">
          </div>
          <div class="card-content">
            <ValidationObserver ref="observer" v-slot="{ errors }">
              <div style="margin-bottom: 5px;">
                <template v-for="(error, index) in errors">
                  <template v-if="error.length > 0">
                    <div v-bind:key="index">
                      <small class="text-danger" v-if="error.length > 0">
                        {{error[0].replace(error[0].substring(error[0].indexOf('|'), error[0].indexOf('|') + 12), '')}}
                      </small>
                    </div>
                  </template>
                </template>
              </div>
              <div class="form-group">
                <div><label>会社</label></div>
                {{after.company_code}}&nbsp;&nbsp;{{after.company_nm}}
              </div>
              <div class="form-group">
                <div><label>言語設定</label></div>
                {{getSelectName(after.language, languages)}}
              </div>
              <div class="form-group">
                <div><label>ホームページ</label></div>
                {{after.homepage}}
              </div>
              <div class="form-group">
                <div><label>郵便番号</label></div>
                {{after.post}}
              </div>
              <ValidationSelect
                v-model="after.state_code"
                item-label="都道府県"
                :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list[0].state_list : []"
                :disabled="!!after.state_code"
                item-name="state_code"
                rules="required"
                @change="prefecturesChanged"
              />
              <ValidationSelect
                v-model="after.city_code"
                item-label="市区"
                :options="comboData.state_municipality_mapping_list ? comboData.state_municipality_mapping_list[0].city_list : []"
                :disabled="!!after.city_code"
                item-name="city_code"
                rules="required"
              />
              <div class="form-group">
                <div><label>町村</label></div>
                {{after.town}}
              </div>
              <div class="form-group">
                <div><label>ビル／番地</label></div>
                {{after.building}}
              </div>
              <div class="form-group">
                <div><label>電話番号</label></div>
                {{after.telephone1}}
              </div>
              <div class="form-group">
                <div><label>ファックス番号</label></div>
                {{after.fax}}
              </div>
              <ValidationSelect
                v-model="after.industry1"
                item-label="業種（大分類）"
                :options="industries1"
                :disabled="false"
                item-name="industry1"
                rules="required"
                @change="industry1Changed"
              />
              <ValidationSelect
                v-model="after.industry2"
                item-label="業種（中分類）"
                :options="industries2"
                :disabled="false"
                item-name="industry2"
                rules="required"
                @change="industry2Changed"
                :key="componentKey2"
               />
              <ValidationSelect
                v-model="after.industry3"
                item-label="業種（小分類）"
                :options="industries3"
                :disabled="false"
                item-name="industry3"
                help-text="総務省　日本標準産業分類に準拠"
                rules="required"
                :key="componentKey3"
              />
              <ValidationSelect
                v-model="after.start_day_of_the_week"
                item-label="週初めの曜日"
                :options="firstWeekdays"
                :disabled="false"
                item-name="firstWeekday"
                rules="required"
                classes="width110"
              />
              <ValidationRadio
                rules="required"
                v-model="after.roundingSecond"
                item-label="労働秒の丸め"
              />
              <ValidationRadio
                rules="required"
                v-model="after.workHourRoundType"
                item-label="労働時間の丸め"
              />
              <ValidationRadio
                rules="required"
                v-model="after.workHourRoundTypeMonthly"
                :view-style="after.workHourRoundType && after.workHourRoundType.value === '2' ? '' : 'visibility: hidden;'"
                item-label="月単位での丸め"
              />
              <ValidationSelect
                v-model="after.rounding_term"
                item-label="労働時間の最小単位（分）"
                :options="workMinuteRoundTypes"
                :is-hide="after.workHourRoundType && after.workHourRoundType.value !== '3'"
                item-name="rounding_term"
                rules="required"
                classes="width110"
              />
              <ValidationSelect
                v-model="after.rounding_term_over_work"
                item-label="時間外労働時間の最小単位（分）"
                :options="workMinuteRoundTypes"
                :is-hide="after.workHourRoundType && after.workHourRoundType.value !== '3'"
                item-name="rounding_term_over_work"
                rules="required"
                classes="width110"
              />
              <ValidationRadio
                rules="required"
                v-model="after.workStartTimeRoundTypeDaily"
                :view-style="after.workHourRoundType && after.workHourRoundType.value === '3' ? '' : 'visibility: hidden;'"
                item-label="出勤時間の丸め（日単位）"
              />
              <ValidationRadio
                rules="required"
                v-model="after.workEndTimeRoundTypeDaily"
                :view-style="after.workHourRoundType && after.workHourRoundType.value === '3' ? '' : 'visibility: hidden;'"
                item-label="退勤時間の丸め（日単位）"
              />
              <ValidationText
                v-model="after.corporate_number"
                classes="form-control width130"
                :disabled="false"
                item-label="法人番号（不明の場合は下記にて検索をお願いします。）"
                help-text=""
                max-length="13"
              />
              <div style="margin: -10px 0 10px 0">
                <span style="font-size: 9px;">国税庁　法人番号公表サイト</span><br>
                <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" style="color: #0000EE;">https://www.houjin-bangou.nta.go.jp/</a>
              </div>
              <ValidationText
                v-model="after.labor_insurance_number"
                classes="form-control numLength14"
                :disabled="false"
                pattern="[^(0-9)]+"
                item-label="労働保険番号"
                help-text="36協定の帳票に出力する労働保険番号をハイフンなしで入力してください"
                max-length="14"
              />
              <ValidationRadio
                rules="required"
                v-model="after.employmentInsuranceExistence"
                item-label="雇用保険加入有無"
              />
              <ValidationRadio
                rules="required"
                v-model="after.healthInsuranceExistence"
                item-label="健康保険加入有無"
              />
              <ValidationRadio
                rules="required"
                v-model="after.welfarePensionExistence"
                item-label="厚生年金加入有無"
              />
              <ValidationRadio
                rules="required"
                v-model="after.employeesPensionFundExistence"
                item-label="厚生年金基金加入有無"
              />
              <ValidationSelect
                v-model="after.business_type_number"
                item-label="事業の種類の番号"
                :options="businessTypeNumberList"
                :disabled="false"
                item-name="businessTypeNumber"
                rules="required"
                classes="length20"
                @change="businessTypeNumberChanged"
              />
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationRadio
    },
    data () {
      return {
        companyError: '',
        componentKey: 0,
        componentKey2: 1000,
        componentKey3: 2000,
        workMinuteRoundTypes: [],
        workSecondRoundType: [{label: '労働時間を切り上げ', value: '1'}],
        firstWeekdays: [{label: '日曜', value: '1'}, {label: '月曜', value: '2'}],
        businessTypeNumberList: [],
        businessTypeNumberMappingList: [],
        industries1: [{label: 'H 情報通信業', value: 'H'}],
        industries2: [{label: '39 情報サービス業', value: '39'}],
        industries3: [{label: '391 ソフトウェア業', value: '391'}],
        industries1_full: [],
        industries2_full: [],
        industries3_full: [],
        industry_mapping_big_and_during: [],
        industry_mapping_during_and_small: [],
        prefectures: [{label: '愛知', value: '22'}, {label: '岐阜', value: '23'}, {label: '三重', value: '24'}],
        languages: [{label: '日本語', value: 'jp'}],
        before: {},
        after: {
          language: 'jp',
          workHourRoundType: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'none',
                label: '丸め処理をしない',
                value: '1'
              },
              {
                id: 'monthly',
                label: '月単位',
                value: '2'
              },
              {
                id: 'daily',
                label: '日単位',
                value: '3'
              }
            ]
          },
          workHourRoundTypeMonthly: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '30分未満は切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '30分未満は切り上げ',
                value: '2'
              }
            ]
          },
          workStartTimeRoundTypeDaily: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          },
          workEndTimeRoundTypeDaily: {
            value: undefined,
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
        },
        comboData: {}
      }
    },
    methods: {
      prefecturesChanged (index) {
        if (this.after.prefectures_before) {
          this.after.city_code = ''
        }
        if (this.after.state_code) {
          this.after.prefectures_before = this.after.state_code
          this.comboData.state_municipality_mapping_list[0].city_list = []
          if (!this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.after.state_code]) {
            for (const prefecture of this.comboData.state_municipality_mapping_list[0].state_list) {
              if (prefecture.label === this.after.state_code) {
                this.after.state_code = prefecture.value
                break
              }
            }
          }
          if (this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.after.state_code]) {
            for (const code of this.comboData.state_municipality_mapping_list[0].state_municipality_mapping_list[this.after.state_code]) {
              for (const entry of this.comboData.state_municipality_mapping_list[0].municipality_list) {
                if (code === entry.value) {
                  this.comboData.state_municipality_mapping_list[0].city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.state_municipality_list.city_list = []
        }
        this.componentKey += 1
      },
      businessTypeNumberChanged () {
        this.after.workers_accident_compensation_rate = this.businessTypeNumberMappingList[this.after.business_type_number][0]
      },
      industry1Changed () {
        if (this.after.industry1_before) {
          this.after.industry2 = ''
          this.after.industry3 = ''
        }
        this.after.industry1_before = this.after.industry1
        this.industries2 = []
        for (const code of this.industry_mapping_big_and_during[this.after.industry1]) {
          for (const entry of this.industries2_full) {
            if (code === entry.value) {
              this.industries2.push(entry)
            }
          }
        }
        this.componentKey2 += 1
      },
      industry2Changed () {
        if (this.after.industry2_before) {
          this.after.industry3 = ''
        }
        if (this.after.industry2) {
          this.after.industry2_before = this.after.industry2
          this.industries3 = []
          for (const code of this.industry_mapping_during_and_small[this.after.industry2]) {
            for (const entry of this.industries3_full) {
              if (code === entry.value) {
                this.industries3.push(entry)
              }
            }
          }
        } else {
          this.industries3 = [...this.industries3_full]
        }
        this.componentKey3 += 1
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        this.before = migrationData.company_info.before
        this.after = migrationData.company_info.after
        this.before.city = this.before.municipality + this.before.street
        this.after.city = this.after.municipality + this.after.street
        this.workSecondRoundType = migrationData.combo_data.workSecondRoundType
        this.firstWeekdays = migrationData.combo_data.firstWeekdays
        this.businessTypeNumberList = migrationData.combo_data.businessTypeNumberList
        this.industries1 = migrationData.combo_data.industries1
        this.industries2 = migrationData.combo_data.industries2
        this.industries3 = migrationData.combo_data.industries3
        this.prefectures = migrationData.combo_data.prefectures
        this.workMinuteRoundTypes = migrationData.combo_data.workMinuteRoundTypes
        this.industries1_full = [...this.industries1]
        this.industries2_full = [...this.industries2]
        this.industries3_full = [...this.industries3]
        this.industry_mapping_big_and_during = migrationData.combo_data.industry_mapping_big_and_during
        this.industry_mapping_during_and_small = migrationData.combo_data.industry_mapping_during_and_small
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_company', params)
        .then(res => {
          this.comboData = res.common_init_data.combo_data
          this.comboData.state_municipality_mapping_list[0].city_list = res.common_init_data.combo_data.state_municipality_mapping_list[0].municipality_list
          this.before = {...res.init_data.before_data.company_data[0]}
          this.before.language = 'jp'
          if (this.before.post && this.before.post.length === 7) {
            this.before.post = this.before.post.substring(0, 3) + '-' + this.before.post.substring(3)
          }
          for (const row of res.common_init_data.combo_data.state_municipality_mapping_list[0].state_list) {
            if (row.value === this.before.state_code) {
              this.before.prefectural = row.label
              break
            }
          }
          for (const row of res.common_init_data.combo_data.state_municipality_mapping_list[0].municipality_list) {
            if (row.value === this.before.state_code + '_' + this.before.municipality_code) {
              this.before.municipality = row.label
              break
            }
          }
          this.before.rounding_term = String(this.before.rounding_term)
          this.before.rounding_term_over_work = String(this.before.rounding_term_over_work)
          this.before.roundingSecond = {
            value: String(this.before.rounding_second),
            disabled: true,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          this.before.workHourRoundType = {
            value: String(this.before.rounding_type),
            disabled: true,
            items: [
              {
                id: 'none',
                label: '丸め処理をしない',
                value: '1'
              },
              {
                id: 'monthly',
                label: '月単位',
                value: '2'
              },
              {
                id: 'daily',
                label: '日単位',
                value: '3'
              }
            ]
          }
          this.before.workHourRoundTypeMonthly = {
            value: String(this.before.rounding_month),
            disabled: true,
            items: [
              {
                id: 'roundDown',
                label: '30分未満は切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '30分未満は切り上げ',
                value: '2'
              }
            ]
          }
          this.before.workStartTimeRoundTypeDaily = {
            value: String(this.before.rounding_job_start),
            disabled: true,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          this.before.workEndTimeRoundTypeDaily = {
            value: String(this.before.rounding_job_end),
            disabled: true,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          let yesNone = [
            {
              id: 'NONE',
              label: '無し',
              value: '2'
            },
            {
              id: 'YES',
              label: '有り',
              value: '1'
            }
          ]
          this.before.employmentInsuranceExistence = {
            value: String(this.before.employment_insurance_existence),
            disabled: true,
            items: yesNone
          }
          this.before.healthInsuranceExistence = {
            value: String(this.before.health_insurance_existence),
            disabled: true,
            items: yesNone
          }
          this.before.welfarePensionExistence = {
            value: String(this.before.welfare_pension_existence),
            disabled: true,
            items: yesNone
          }
          this.before.employeesPensionFundExistence = {
            value: String(this.before.employees_pension_fund_existence),
            disabled: true,
            items: yesNone
          }
          this.after = {...res.init_data.after_data.company_data[0]}
          this.after.language = 'jp'
          if (this.after.post.length === 7) {
            this.after.post = this.after.post.substring(0, 3) + '-' + this.after.post.substring(3)
          }
          if (this.after.state_code) this.prefecturesChanged()
          if (this.after.municipality_code) {
            this.after.city_code = this.after.state_code + '_' + this.after.municipality_code
          } else {
            this.after.city_code = ''
          }
          this.after.business_type_number = '94'
          this.after.rounding_term = this.after.rounding_term ? String(this.after.rounding_term) : '1'
          this.after.rounding_term_over_work = this.after.rounding_term_over_work ? String(this.after.rounding_term_over_work) : '1'
          this.after.roundingSecond = {
            value: String(this.after.rounding_second),
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          this.after.workHourRoundType = {
            value: String(this.after.rounding_type),
            disabled: false,
            items: [
              {
                id: 'none',
                label: '丸め処理をしない',
                value: '1'
              },
              {
                id: 'monthly',
                label: '月単位',
                value: '2'
              },
              {
                id: 'daily',
                label: '日単位',
                value: '3'
              }
            ]
          }
          this.after.workHourRoundTypeMonthly = {
            value: String(this.after.rounding_month),
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '30分未満は切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '30分未満は切り上げ',
                value: '2'
              }
            ]
          }
          this.after.workStartTimeRoundTypeDaily = {
            value: String(this.after.rounding_job_start) === '' ? '1' : String(this.after.rounding_job_start),
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          this.after.workEndTimeRoundTypeDaily = {
            value: String(this.after.rounding_job_end) === '' ? '1' : String(this.after.rounding_job_end),
            disabled: false,
            items: [
              {
                id: 'roundDown',
                label: '労働時間を切り捨て',
                value: '1'
              },
              {
                id: 'roundUp',
                label: '労働時間を切り上げ',
                value: '2'
              }
            ]
          }
          this.after.employmentInsuranceExistence = {
            value: String(this.after.employment_insurance_existence),
            disabled: false,
            items: yesNone
          }
          this.after.healthInsuranceExistence = {
            value: String(this.after.health_insurance_existence),
            disabled: false,
            items: yesNone
          }
          this.after.welfarePensionExistence = {
            value: String(this.after.welfare_pension_existence),
            disabled: false,
            items: yesNone
          }
          this.after.employeesPensionFundExistence = {
            value: String(this.after.employees_pension_fund_existence),
            disabled: false,
            items: yesNone
          }
          this.workSecondRoundType = []
          for (const second of res.common_init_data.combo_data.rounding_second_list[0]) {
            const workSecond = {
              label: this.getLabel(second.label),
              value: String(second.value)
            }
            this.workSecondRoundType.push(workSecond)
          }
          this.firstWeekdays = []
          for (const weekday of res.common_init_data.combo_data.start_day_of_the_week_list[0]) {
            const firstWeekday = {
              label: this.getLabel(weekday.label),
              value: String(weekday.value)
            }
            this.firstWeekdays.push(firstWeekday)
          }
          this.businessTypeNumberList = []
          for (const businessType of res.common_init_data.combo_data.workers_accident_compensation_insurance_mapping_list[0].workers_accident_compensation_insurance_list) {
            const businessTypeNumber = {
              label: this.getLabel(businessType.label),
              value: String(businessType.value)
            }
            this.businessTypeNumberList.push(businessTypeNumber)
          }
          this.businessTypeNumberMappingList = res.common_init_data.combo_data.workers_accident_compensation_insurance_mapping_list[0].workers_accident_compensation_insurance_mapping_list
          this.industries1 = res.common_init_data.combo_data.industry_codes_big_list
          this.industries2 = res.common_init_data.combo_data.industry_codes_during_list
          this.industries3 = res.common_init_data.combo_data.industry_codes_small_list
          this.industries1_full = [...this.industries1]
          this.industries2_full = [...this.industries2]
          this.industries3_full = [...this.industries3]
          this.industry_mapping_big_and_during = res.init_data.industry_mapping_big_and_during
          this.industry_mapping_during_and_small = res.init_data.industry_mapping_during_and_small
          this.prefectures = [{label: this.before.prefectural, value: this.before.prefectural}]
          this.workMinuteRoundTypes = []
          for (const roundType of res.common_init_data.combo_data.rounding_term_list[0]) {
            const minuteRoundType = {
              label: this.getLabel(roundType.label),
              value: String(roundType.value)
            }
            this.workMinuteRoundTypes.push(minuteRoundType)
          }
          this.businessTypeNumberChanged()
          this.componentKey += 1
        })
      }
    }
  }
</script>
<style>
</style>
