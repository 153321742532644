<template>
    <div class="form-group" style="margin-bottom: 0;">
    <template v-if="itemLabel">
    <label>{{ itemLabel }}</label>
    </template>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <small class="help-block" v-show="valid | !validated">
            {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
        </small>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div>
        <input
          :type="type"
          :name="itemName"
          :placeholder="placeholder"
          :class="classes"
          style="display: inline-block; text-align: right;"
          v-model="itemValue"
          :maxlength="maxLength"
          :disabled="disabled"
          @focus="focus"
          @blur="blur"
          @keypress="$emit('input', itemValue)">{{unit ? '&nbsp;' + unit : ''}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      type: {
        type: String,
        require: false,
        'default': 'text'
      },
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      unit: {
        type: String,
        require: false,
        'default': ''
      }
    },
    data: () => ({
      itemValue: null
    }),
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      blur () {
        if (this.itemValue.match(/^\d+$/)) {
          this.itemValue = this.itemValue + ':00'
        }
        this.$emit('input', this.itemValue)
        this.$emit('blur')
      },
      focus () {
        this.$emit('input', this.itemValue)
        this.$emit('focus')
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>
