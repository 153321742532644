<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="Workflow" :draggable="true" :clickToClose="false" height="auto" :width="1400" :pivotX="0.6">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :apply-record="applyRecord" :is-modal="true" @close="hide" @show-attendance-record="showAttendanceRecord"/>
        </div>
      </modal>
      <modal name="applicationAttendanceRecord" :draggable="true" :clickToClose="false" height="auto" :width="1600" :pivotX="0.8">
        <div class="modal-body">
          <AttendanceRecordDetail @close="hideAttendanceRecord"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <!-- 申請番号 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_number}}</label>
              <input type="text" :placeholder="$t('placeholder.application_number')" maxlength="10" class="form-control numLength10" style="text-align:right" v-model="searchCond.application_number">
            </div>
            <!-- 申請分類 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.applicationClassification}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in apllicationClassification" v-bind:key = item.application_classification_code>
                <input type="checkbox" class="gray" :value="item.application_classification_code" v-model="searchCond.application_classification" @change="applicationClassificationChanged"/>{{item.application_classification_name}}&nbsp;
              </div>
            </div>
            <!-- 申請書 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_form_name_ryaku}}</label><br>
              <vSelect name="application_form_code"
                        class="style-chooser length16"
                        :options="comboData.application_form_list"
                        v-model="searchCond.application_form_code">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>
            </div>
            <!-- 申請書状態 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_status}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in applicationStatus" v-bind:key = item.application_status_code>
                <input type="checkbox" class="gray" :value="item.application_status_code" v-model="searchCond.application_status"/>{{item.application_status_name}}&nbsp;
              </div>
            </div>
            <br>
            <!-- 申請日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.apply_date}}</label>
              <br>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.term_from" @change="dateSynchronizeFrom"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.term_to" @change="dateSynchronizeTo"></el-date-picker>
            </div>
            <!-- 対象日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{$t('label.label_header_target_date')}}</label>
              <br>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.target_date_from" @change="targetDateSynchronizeFrom"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.target_date_to" @change="targetDateSynchronizeTo"></el-date-picker>
            </div>
            <!-- 部署 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.group_ryaku}}</label><br>
              <vSelect name="application_form_code"
                        class="style-chooser length16"
                        :clearable="false"
                        :options="comboData.group_list"
                        v-model="searchCond.group_code">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>
            </div>
            <!-- 検索ボタン -->
            <template v-if="roles.search === 1">
              <div style="text-align: left;">
                <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </template>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
      <template v-if="tableData && tableData.length > 0">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content">
            <el-select
              class="select-success length2"
              :disabled="isChanged"
              v-model="pagination.perPage">
              <el-option
                class="select-success"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="pagedData"
                        :empty-text="$t('label.no_data')"
                        :style="tableStyle"
                        ref="table"
                        :row-class-name="tableRowClassName">
                <!-- 対象者の従業員 -->
                <el-table-column v-if="showTargetEmployee" width="180" :label="$t('label.target_employee')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.target_employee_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.target_employee_code}}</small>
                    </div>
                    <div style="text-align: left; line-height: 100%;">
                      <small>{{$t('label.apply_person') + ' ' + props.row.applicant_employee_name}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 申請書 -->
                <el-table-column v-if="showApplicationFormName" width="200" :label="labels.application_form_name_ryaku === undefined ? labels.applicationFormName_ryaku === undefined ? '' : labels.applicationFormName_ryaku : labels.application_form_name_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.application_form_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.application_classification_name}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 申請日 -->
                <el-table-column v-if="showApplyDate" width="180" :label="labels.apply_date_ryaku === undefined ? labels.applyDate_ryaku === undefined ? '' : labels.applyDate_ryaku : labels.apply_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: small; font-weight: bold;">
                      {{props.row.apply_date}}
                    </div>
                    <div style="text-align: left;">
                      <a class="btn btn-simple btn-icon like" @click="show(props.$index, props.row)" :disabled="isChanged" :style="isChanged ? 'pointer-events: none; text-decoration: underline;' : 'text-decoration: underline;'">
                        {{labels.application_number + ' ' + props.row.application_number}}
                      </a>
                    </div>
                    <div style="text-align: left;" v-if="props.row.re_application_number">
                      {{labels.re_application_number + ' ' + props.row.re_application_number}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 対象日 -->
                <el-table-column width="100" :label="$t('label.label_header_target_date')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <template v-if="props.row.target_date.indexOf('～') === -1">
                      <div style="text-align: left; font-size: small; font-weight: bold;">
                        {{props.row.target_date}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: left; font-size: small; font-weight: bold;">
                        {{props.row.target_date.substring(0, props.row.target_date.indexOf('～'))}}
                      </div>
                      <div style="text-align: left; font-size: small; font-weight: bold;">
                        {{props.row.target_date.substring(props.row.target_date.indexOf('～'))}}
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <!-- 申請書状態 -->
                <el-table-column v-if="showApplicationStatus" :width="getColumnWidth(tableData, labels.application_status_ryaku === undefined ? labels.applicationStatus_ryaku === undefined ? '' : labels.applicationStatus_ryaku : labels.application_status_ryaku, 'application_status')" :label="labels.application_status_ryaku === undefined ? labels.applicationStatus_ryaku === undefined ? '' : labels.applicationStatus_ryaku : labels.application_status_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center">
                      <el-tag :style="`border-radius: 16px !important; color: white; background-color: ${props.row.color} !important;`">
                        {{getLabel(getSelectName(props.row.application_status, comboData.application_status))}}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              </div>
              <div class="row" ref="pager">
                <div class="col-sm-6 pagination-info">
                  <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total"
                                :disabled="isChanged"
                                @pageChanged="pageChanged">
                  </p-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, DatePicker, Tag} from 'element-ui'
  import vSelect from 'vue-select'
  import Workflow from '@/components/Dashboard/Views/Working/Workflow/Workflow'
  import PPagination from '@/components/UIComponents/Pagination'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Tag)
  export default{
    mixins: [mixin],
    components: {
      vSelect,
      Workflow,
      AttendanceRecordDetail: () => import('@/components/Dashboard/Views/Working/AttendanceRecordDetail'),
      PPagination,
      'el-date-picker': DatePicker
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        draggable: true,
        isChanged: false,
        beforePerPage: 25,
        beforeCurrentPage: 1,
        showSearchCondition: true,
        modalWidth: 800,
        modalWidthSub: 1100,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        tableData: undefined,
        searchCond: {
          application_number: '',
          application_classification: [],
          application_status: [],
          term_from: '',
          term_to: '',
          target_date_from: '',
          target_date_to: '',
          group_code: ''
        },
        searchedApiArea: {},
        showOperation: true,
        showApplicationClassification: true,
        showApplicationFormName: true,
        showApplicationStatus: true,
        showApplyDate: true,
        showTargetEmployee: true,
        showApplicantEmployee: true,
        showApplicationNumber: true,
        showReApplicationNumber: true,
        labels: {},
        roles: {},
        comboData: {},
        apllicationClassification: [],
        applicationStatus: [],
        applicationFormCode: '',
        applyRecord: {},
        tableWrapperStyle: '',
        tableStyle: ''
      }
    },
    methods: {
      applicationClassificationChanged () {
        const applicationFormList = []
        for (const row of this.searchCond.application_classification) {
          if (this.comboData.application_classification_form_mapping_list[row]) {
            for (const row3 of this.comboData.application_classification_form_mapping_list[row]) {
              for (const row4 of this.comboData.org_application_form_list) {
                if (row3 === row4.value) {
                  applicationFormList.push(row4)
                  break
                }
              }
            }
          }
        }
        this.comboData.application_form_list = applicationFormList
      },
      showAttendanceRecord () {
        this.$modal.show('applicationAttendanceRecord')
      },
      hideAttendanceRecord () {
        this.$modal.hide('applicationAttendanceRecord')
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      customValidate (apiArea) {
        // 申請番号数字チェック
        // ブランクならnullに変換
        if (apiArea.application_number === '') {
          apiArea.application_number = null
          // 数字以外ならエラーメッセージを返却
        } else if (isNaN(apiArea.application_number)) {
          return this.$t('message.decimal_field_required').replace('{_field_}', this.labels.application_number)
          // 上記以外の場合、数字に変換
        } else {
          apiArea.application_number = parseInt(apiArea.application_number)
        }
        // 再申請番号はnull
        apiArea.re_application_number = null
        return null
      },
      afterInit (res) {
        this.comboData.org_application_form_list = [...res.common_area.combo_data.application_classification_form_mapping_list.application_form_list]
        this.comboData.application_classification_form_mapping_list = res.common_area.combo_data.application_classification_form_mapping_list.application_classification_form_mapping_list
        this.comboData.application_status = this.comboData.application_status_list
      },
      getDateColumnWidth (rows, columnNameValue, columnName1) {
        let maxBytes = this.getBytes(columnNameValue)
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1])
            if (bytes > maxBytes) {
              maxBytes = 14
              break
            }
          }
          if (maxBytes === 14) {
            break
          }
        }
        return maxBytes * 8 + 20
      },
      pageChanged () {
        this.isChanged = true
        // 一度も検索していない場合、init処理
        if (this.searchedApiArea.application_number === undefined) {
          this.init(false)
        } else {
          this.search(false)
        }
      },
      search (searchButtonFlag = true) {
        let apiArea
        // 検索ボタンの場合、検索条件を設定する
        if (searchButtonFlag) {
          apiArea = {
            application_number: this.searchCond.application_number,
            re_application_number: null,
            application_classification_code_list: this.convertAssociative('application_classification_code', this.searchCond.application_classification),
            application_form: this.searchCond.application_form_code ? this.searchCond.application_form_code.value : '',
            application_status_list: this.convertAssociative('application_status', this.searchCond.application_status),
            apply_date_from: this.searchCond.term_from,
            apply_date_to: this.searchCond.term_to,
            target_date_from: this.searchCond.target_date_from,
            target_date_to: this.searchCond.target_date_to,
            group_code: this.searchCond.group_code ? this.searchCond.group_code.value : '',
            pagerCount: this.pagination.currentPage,
            limit: this.pagination.perPage
          }
          // ページングの場合、前回の検索条件にページカウントとリミットを設定する
        } else {
          // ダミーデータを設定する
          this.tableData = this.getDummyData(this.tableData, this.pagination.currentPage, this.pagination.perPage, this.beforeCurrentPage, this.beforePerPage)
          this.componentKey += 1
          apiArea = this.searchedApiArea
          apiArea.pagerCount = this.pagination.currentPage
          apiArea.limit = this.pagination.perPage
        }
        // APIに渡す検索条件を設定
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: apiArea
        }
        this.nullToBlank(searchCond.api_area)
        const message = this.customValidate(searchCond.api_area)
        if (message !== null) {
          this.message.text = message
          return
        }
        // 検索条件をディープコピー
        this.searchedApiArea = Object.assign({}, apiArea, {})
        // 検索API呼び出し
        this.send('/workflow/view/wf_application_status/list', searchCond)
        .then(res => {
          if (searchButtonFlag) {
            this.pagination.currentPage = 1
          }
          this.tableData = this.getColoredData(Array.isArray(res.api_area) ? res.api_area : res.api_area.application_info_data)
          let labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.labels = {...this.labels, ...labels}
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
          if (this.tableData.length > 0) {
            this.toggleSearchCondition()
          }
          // 表示するページ以外を空配列で埋める
          this.tableData = this.getPageList(this.tableData, this.pagination.currentPage, this.pagination.perPage, res.api_area.total_count)
          this.$nextTick(() => {
            let width = 0
            if (this.$refs.table) {
              for (const child of this.$refs.table.$children) {
                if (child.width) {
                  width += parseInt(child.width, 10)
                }
              }
            }
            this.tableStyle = 'width: ' + (width + 1) + 'px'
            const maxWidth = window.innerWidth - 360
            if (width > maxWidth) {
              width = maxWidth
              this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
            } else {
              this.tableWrapperStyle = ''
            }
            if (this.$refs.pager) {
              this.$refs.pager.style.width = width + 'px'
            }
            if (this.$route.params.attendanceRecord) {
              for (const row of this.tableData) {
                if (row.application_number === parseInt(this.$route.params.attendanceRecord.application_number, 10)) {
                  this.show(0, row)
                  break
                }
              }
            }
            this.componentKey += 1
          })
          this.isChanged = false
          this.beforePerPage = this.pagination.perPage
          this.beforeCurrentPage = this.pagination.currentPage
        })
        .catch(error => {
          console.error(error)
        })
      },
      indexCheck (list, code) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].code === code) {
            return i
          }
        }
        return -1
      },
      init (firstFlg = true) {
        // 初期表示
        // APIに渡すパラメータを設定
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            pagerCount: this.pagination.currentPage,
            limit: this.pagination.perPage
          }
        }
        // 初期化API呼び出し
        this.send('/workflow/init/wf_application_status/list', params)
        .then(res => {
          if (firstFlg) {
            // ラベル情報をthis.labelsに設定
            this.labels = this.getLabels(res.common_area)
            this.comboData = res.common_area.combo_data
            this.afterInit(res)
            // ボタン権限をthis.rolesに設定
            this.roles = res.common_area.button_role
            // 申請分類を設定
            for (let apllicationClassification of res.common_area.combo_data.application_classification_list) {
              let oneApllicationClassification = {
                application_classification_code: apllicationClassification.value,
                application_classification_name: apllicationClassification.label
              }
              this.apllicationClassification.push(oneApllicationClassification)
            }
            // 申請書ステータスを設定
            for (let applicationStatus of res.common_area.combo_data.application_status_list) {
              let oneApplicationStatus = {
                application_status_code: applicationStatus.value,
                application_status_name: this.getLabel(applicationStatus.label)
              }
              this.applicationStatus.push(oneApplicationStatus)
            }
            // 申請分類をチェック
            for (let result of this.comboData.application_classification_list) {
              this.searchCond.application_classification.push(result.value)
            }
            // 状態をチェック
            for (let result of this.comboData.application_status_list) {
              // 下書き、引き戻し、差し戻し、審査中の場合
              if (result.value === 2 || result.value === 3 || result.value === 4 || result.value === 5) {
                this.searchCond.application_status.push(result.value)
              }
            }
            // 部署の初期値をログイン部署に設定
            for (const group of this.comboData.group_list) {
              if (group.value === this.$store.state.loginUser.groupCode) {
                this.searchCond.group_code = group
                break
              }
            }
          }
          if (this.roles.search === 1) {
            this.tableData = this.getColoredData(Array.isArray(res.api_area) ? res.api_area : res.api_area.application_info_data)
            for (const row of this.tableData) {
              for (const entry of Object.entries(row)) {
                if (entry[1] === null) {
                  row[entry[0]] = ''
                }
              }
            }
          }
          // 表示するページ以外を空配列で埋める
          this.tableData = this.getPageList(this.tableData, this.pagination.currentPage, this.pagination.perPage, res.api_area.total_count)
          this.$nextTick(() => {
            let width = 0
            if (this.$refs.table) {
              for (const child of this.$refs.table.$children) {
                if (child.width) {
                  width += parseInt(child.width, 10)
                }
              }
            }
            this.tableStyle = 'width: ' + (width + 1) + 'px'
            const maxWidth = window.innerWidth - 360
            if (width > maxWidth) {
              width = maxWidth
              this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
            } else {
              this.tableWrapperStyle = ''
            }
            if (this.$refs.pager) {
              this.$refs.pager.style.width = width + 'px'
            }
            if (this.$route.params.attendanceRecord) {
              this.searchCond.application_status.push(7)
              this.search()
            }
            this.componentKey += 1
          })
          this.isChanged = false
        })
      },
      show (index, row) {
        this.applicationFormCode = row.application_form_code
        this.applyRecord = {
          screen_mode: row.screen_mode,
          company_code: row.target_company_code,
          group_code: row.target_group_code,
          employee_code: row.target_employee_code,
          application_number: row.application_number,
          route_type: null,
          route_number: null
        }
        this.$modal.show('Workflow')
      },
      hide (...args) {
        this.$modal.hide('Workflow')
        if (args[0]) {
          this.isChanged = true
          this.componentKey += 1
          this.search()
        }
      },
      tableRowClassName () {
        return ''
      },
      // 日付のシンクロ(FROM)
      dateSynchronizeFrom () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_to = this.searchCond.term_from
        }
      },
      // 日付のシンクロ(TO)
      dateSynchronizeTo () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_from = to = this.searchCond.term_to
        }
      },
      // 対象日のシンクロ(FROM)
      targetDateSynchronizeFrom () {
        let from = this.searchCond.target_date_from
        let to = this.searchCond.target_date_to
        if (from > to && to !== '') {
          this.searchCond.target_date_to = this.searchCond.target_date_from
        }
      },
      // 対象日のシンクロ(TO)
      targetDateSynchronizeTo () {
        let from = this.searchCond.target_date_from
        let to = this.searchCond.target_date_to
        if (from > to && to !== '') {
          this.searchCond.target_date_from = this.searchCond.target_date_to
        }
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
