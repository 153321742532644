<template>
  <div>
    <modal name="dependentRelativeClassification" :draggable="true" :clickToClose="true" :width="400" height="auto">
      <div class="modal-body">
        <DependentRelativeClassification @close="closeClicked" />
      </div>
    </modal>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 100px)" name="employeeFrame" :key="componentKey">
      <div class="col-md-11 col-md-offset-1">
        <button type="button" class="btn btn-warning btn-sm btn-default" @click="close">閉じる</button>
      </div>
      <div class="col-md-5 col-md-offset-1">
        <div class="card">
          <div class="card-header" style="font-size: 18px;">
            <img src="static/img/corpus.png" style="height: 50px; width: auto;">
          </div>
          <div class="card-content">
            <div class="form-group">
              <div><label>従業員</label></div>
              {{employees[from].before.employee_code}}&nbsp;&nbsp;{{employees[from].before.employee_name}}
            </div>
            <div class="form-group">
              <div><label>従業員区分</label></div>
              <div style="height: 20px;">
                {{getSelectName(employees[from].before.employee_classification_code, employeeClassifications)}}
              </div>
            </div>
            <div class="form-group">
              <div><label>言語設定</label></div>
              {{getSelectName(employees[from].before.language, languages)}}
            </div>
            <div class="form-group">
              <div><label>入社年月日</label></div>
              {{employees[from].before.hire_date}}
            </div>
            <div class="form-group">
              <div><label>退社年月日</label></div>
              {{employees[from].before.retirement_date}}
            </div>
            <div class="form-group">
              <div><label>メールアドレス</label></div>
              {{employees[from].before.mail_address}}
            </div>
            <div class="form-group">
              <div><label>性別</label></div>
              {{getRadioName(employees[from].before.sex)}}
            </div>
            <div class="form-group" style="height: 65px;">
              <div><label>生年月日</label></div>
              {{employees[from].before.birthday}}
            </div>
            <div class="form-group">
              <div><label>電話番号</label></div>
              {{employees[from].before.tel}}
            </div>
            <div class="form-group">
              <div><label>緊急連絡先</label></div>
              {{employees[from].before.emergency_contact}}
            </div>
            <div class="form-group">
              <div><label>社会保険</label></div>
              {{getRadioName(employees[from].before.social_insurance)}}
            </div>
            <div class="form-group">
              <div><label>雇用保険</label></div>
              {{getRadioName(employees[from].before.employment_insurance)}}
            </div>
            <div class="form-group">
              <div><label>労働管理</label></div>
              {{getRadioName(employees[from].before.attendance_management)}}
            </div>
            <div class="form-group">
              <div><label>賃金管理</label></div>
              {{getRadioName(employees[from].before.payroll_management)}}
            </div>
            <div class="form-group">
              <div><label>有給管理</label></div>
              {{getRadioName(employees[from].before.paid_leave_payment)}}
            </div>
            <div class="form-group">
              <div><label>利用状況</label></div>
              {{getRadioName(employees[from].before.availability)}}
            </div>
            <div class="form-group">
              <div><label>週の労働日数</label></div>
              {{getRadioName(employees[from].before.number_of_working_days_per_week)}}
            </div>
            <div class="form-group">
              <div><label>ICカード番号</label></div>
              {{employees[from].before.FeriCa}}
            </div>
            <div class="form-group">
              <div><label>税法上の扶養家族人数</label></div>
              {{employees[from].before.dependent_count}}
            </div>
            <div class="form-group">
              <div><label>健康保険記号</label></div>
              {{employees[from].before.health_insurance_sign}}
            </div>
            <div class="form-group">
              <div><label>健康保険No</label></div>
              {{employees[from].before.health_insurance_no}}
            </div>
            <div class="form-group">
              <div><label>基礎年金番号</label></div>
              {{employees[from].before.basic_pension_number}}
            </div>
            <div class="form-group">
              <div><label>雇用保険番号</label></div>
              {{employees[from].before.employment_insurance_number}}
            </div>
            <div class="form-group">
              <label>マイナンバー</label>
              <div style="height: 45px;">
                ※コーパスでは取り扱っておりません。
              </div>
            </div>
            <div class="form-group">
              <div><label>勤務体系</label></div>
              <div style="height: 40px;">
                {{getSelectName(employees[from].before.work_schedule_code, workSchedules)}}
              </div>
            </div>
            <div class="form-group">
              <div><label>支払日</label></div>
              <div style="height: 40px;">
                {{getSelectName(employees[from].before.salary_closing_code, salaryClosings)}}
              </div>
            </div>
            <div style="height: 67px;">
              <div><label>顔写真</label></div>
              <div style="visibility: hidden;">
                <img v-show="uploadImage" :src="uploadImage" style="height: 50px; margin-bottom: 10px;"/>
                <input type="file" @change="onFileChange"/>
              </div>
            </div>
            <div class="form-group" style="height: 87px;">
              <div><label>郵便番号</label></div>
              {{employees[from].before.post_code}}
            </div>
            <div class="form-group" style="height: 67px;">
              <div><label>都道府県</label></div>
              {{employees[from].before.prefectures}}
            </div>
            <div class="form-group" style="height: 65px;">
              <div><label>市区</label></div>
              {{employees[from].before.city}}
            </div>
            <div class="form-group" style="height: 84px;">
              <div><label>町村</label></div>
              {{employees[from].before.town}}
            </div>
            <div class="form-group" style="height: 84px;">
              <div><label>ビル/番地</label></div>
              {{employees[from].before.building}}
            </div>
            <template v-for="dependent in employees[from].before.dependent_detail">
              <div class="card" v-bind:key="dependent.dependent_name">
                <div class="card-header">
                  <div style="height: 83px;"></div>
                  扶養家族
                </div>
                <div class="card-content">
                  <div class="form-group">
                    <div><label>氏名</label></div>
                    {{dependent.dependent_name}}
                  </div>
                  <div class="form-group">
                    <div><label>生年月日</label></div>
                    <div style="height: 40px;">
                      {{dependent.birthday}}
                    </div>
                  </div>
                  <div class="form-group">
                    <div><label>続柄</label></div>
                    <div style="height: 40px;">
                      {{getSelectName(dependent.relationship, relationships)}}
                    </div>
                  </div>
                  <template v-if="isQuestionsShow(dependent.dependent_name)">
                    <div class="form-group">
                      <label>民法の規定による配偶者ですか？</label>
                      <div style="height: 35px;">
                        ※コーパスでは取り扱っておりません。
                      </div>
                    </div>
                    <div class="form-group">
                      <label>納税者と生計を一にしていますか？</label>
                      <div style="height: 36px;">
                        ※コーパスでは取り扱っておりません。
                      </div>
                    </div>
                    <div class="form-group">
                      <label>年間の合計所得金額が38万円以下(2020年分以降は48万円以下)ですか？</label>
                      <div style="height: 36px;">
                        ※コーパスでは取り扱っておりません。
                      </div>
                    </div>
                    <div class="form-group">
                      <label>青色申告者の事業専従者としてその年を通じて一度も給与の支払を受けていませんか？または白色申告者の事業専従者ではありませんか？</label>
                      <div style="height: 36px;">
                        ※コーパスでは取り扱っておりません。
                      </div>
                    </div>
                  </template>
                  <div class="form-group">
                    <label>同居区分</label>
                    <div style="height: 36px;">
                      ※コーパスでは取り扱っておりません。
                    </div>
                  </div>
                  <div class="form-group">
                    <label>扶養親族区分</label>
                    <div style="height: 40px;">
                      ※コーパスでは取り扱っておりません。
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-header" style="font-size: 18px;">
            <div style="color: red;">{{employeeError}}</div>
            <img src="static/img/LOGO_A2.png" style="height: 50px; width: auto;">
          </div>
          <div class="card-content">
            <ValidationObserver ref="observer">
              <div class="form-group">
                <div><label>従業員</label></div>
                {{employees[from].after.employee_code}}&nbsp;&nbsp;{{employees[from].after.employee_name}}
              </div>
              <div class="form-group">
                <div><label>従業員区分</label></div>
                {{getSelectName(employees[from].after.employee_classification_code, employeeClassifications)}}
              </div>
              <div class="form-group">
                <div><label>言語設定</label></div>
                {{getSelectName(employees[from].after.language, languages)}}
              </div>
              <div class="form-group">
                <div><label>入社年月日</label></div>
                {{employees[from].after.hire_date}}
              </div>
              <div class="form-group">
                <div><label>退社年月日</label></div>
                {{employees[from].after.retirement_date}}
              </div>
              <div class="form-group">
                <div><label>メールアドレス</label></div>
                {{employees[from].after.mail_address}}
              </div>
              <div class="form-group">
                <div><label>性別</label></div>
                {{getRadioName(employees[from].after.sex)}}
              </div>
              <ValidationDate
                v-model="employees[from].after.birthday"
                classes="form-control length10"
                :disabled="false"
                item-label="生年月日"
                :placeholder="$t('placeholder.start_date')"
                item-name="birthday"
                :picker-options = "pickerOptions"
                :rules="requiredIfNotRetiredAndPayrollManagement"
              />
              <div class="form-group">
                <div><label>電話番号</label></div>
                {{employees[from].after.tel}}
              </div>
              <div class="form-group">
                <div><label>&nbsp;</label></div>
                {{employees[from].before.emergency_contact}}
              </div>
              <div class="form-group">
                <div><label>社会保険</label></div>
                {{getRadioName(employees[from].after.social_insurance)}}
              </div>
              <div class="form-group">
                <div><label>雇用保険</label></div>
                {{getRadioName(employees[from].after.employment_insurance)}}
              </div>
              <div class="form-group">
                <div><label>労働管理</label></div>
                {{getRadioName(employees[from].after.attendance_management)}}
              </div>
              <div class="form-group">
                <div><label>賃金管理</label></div>
                {{getRadioName(employees[from].after.payroll_management)}}
              </div>
              <div class="form-group">
                <div><label>有給管理</label></div>
                {{getRadioName(employees[from].after.paid_leave_payment)}}
              </div>
              <div class="form-group">
                <div><label>利用可否</label></div>
                {{getRadioName(employees[from].after.availability)}}
              </div>
              <div class="form-group">
                <div><label>週の労働日数</label></div>
                {{getRadioName(employees[from].after.number_of_working_days_per_week)}}
              </div>
              <div class="form-group">
                <div><label>FeliCa</label></div>
                {{employees[from].after.FeriCa}}
              </div>
              <div class="form-group">
                <div><label>税法上の扶養家族人数</label></div>
                {{employees[from].after.dependent_count}}
              </div>
              <div class="form-group">
                <div><label>健康保険記号</label></div>
                {{employees[from].after.health_insurance_sign}}
              </div>
              <div class="form-group">
                <div><label>健康保険No</label></div>
                {{employees[from].after.health_insurance_no}}
              </div>
              <div class="form-group">
                <div><label>基礎年金番号</label></div>
                {{employees[from].after.basic_pension_number}}
              </div>
              <div class="form-group">
                <div><label>雇用保険番号</label></div>
                {{employees[from].after.employment_insurance_number}}
              </div>
              <ValidationText
                v-model="employees[from].after.my_number"
                classes="form-control length12"
                :disabled="false"
                item-label="マイナンバー"
                item-name="my_number"
                max-length="12"
              />
              <ValidationSelect
                v-model="employees[from].after.work_schedule_code"
                item-label="勤務体系"
                :options="workSchedules"
                :disabled="workScheduleDisabled"
                item-name="work_schedule_code"
                rules="required"
              />
              <ValidationSelect
                v-model="employees[from].after.salary_closing_code"
                item-label="給与締日"
                :options="salaryClosings"
                :disabled="salaryClosingDisabled"
                item-name="salary_closing_code"
                :rules="requiredIfNotRetiredAndPayrollManagement"
              />
              <div class="form-group">
                <div><label>顔写真</label></div>
                <img v-show="uploadImage" :src="uploadImage" style="height: 50px; margin-bottom: 10px;"/>
                <input type="file" @change="onFileChange"/>
              </div>
              <ValidationPostalCode
                v-model="employees[from].after.post_code"
                classes="form-control length8"
                :disabled="false"
                item-label="郵便番号"
                item-name="postal_code"
                :placeholder="$t('placeholder.postal_code')"
                :help-text="$t('label.max_length')"
                max-length="8"
                :button-label="$t('button.search')"
                @search="postalCodeSearch"
              />
              <ValidationSelect
                v-model="employees[from].after.prefectures"
                item-label="都道府県"
                :options="comboData.state_municipality_list ? comboData.state_municipality_list.state_list : []"
                :disabled="false"
                item-name="language"
                classes="width110"
                @change="prefecturesChanged"
              />
              <ValidationSelect
                v-model="employees[from].after.city"
                item-name="city"
                classes="length30"
                item-label="市区"
                :options="comboData.state_municipality_list ? comboData.state_municipality_list.city_list : []"
                :disabled="false"
              />
              <ValidationText
                v-model="employees[from].after.town"
                classes="form-control length30"
                :disabled="false"
                item-label="町村"
                item-name="town"
                :placeholder="$t('placeholder.town')"
                :help-text="$t('label.max_length')"
                max-length="50"
              />
              <ValidationText
                v-model="employees[from].after.building"
                item-name="building"
                max-length="30"
                classes="form-control length30"
                item-label="ビル/番地"
                :placeholder="$t('placeholder.building')"
                :help-text="$t('label.max_length')"
              />
              <template v-for="dependent in employees[from].after.dependent_detail">
                <div class="card" v-bind:key="dependent.dependent_name">
                  <div class="card-header">
                    <ValidationRadio
                      rules=""
                      v-model="dependent.delete"
                      item-label="扶養家族から削除します。"
                      @click="deleteClicked"
                    />
                    <span :style="deleteStyle(dependent)">扶養家族</span>
                  </div>
                  <div class="card-content" :style="deleteStyle(dependent)">
                    <div class="form-group">
                      <div><label>氏名</label></div>
                      {{dependent.dependent_name}}
                    </div>
                    <ValidationDate
                      v-model="dependent.birthday"
                      item-label="生年月日"
                      :disabled="false"
                      item-name="dependent_birthday_1"
                      :picker-options = "pickerOptions"
                      :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                    />
                    <ValidationSelect
                      v-model="dependent.relationship"
                      item-label="続柄"
                      :options="relationships"
                      :disabled="false"
                      item-name="relationship_1"
                      classes="width110"
                      :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                    />
                    <template v-if="dependent.relationship === '1' || dependent.relationship === '2'">
                      <ValidationRadio
                        :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                        v-model="dependent.relationshipQuestion1"
                        item-label="民法の規定による配偶者ですか？"
                        item-label-sub="扶養家族に関する質問"
                      />
                      <ValidationRadio
                        :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                        v-model="dependent.relationshipQuestion2"
                        item-label="納税者と生計を一にしていますか？"
                        item-label-sub="扶養家族に関する質問"
                      />
                      <ValidationRadio
                        :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                        v-model="dependent.relationshipQuestion3"
                        item-label="年間の合計所得金額が38万円以下(2020年分以降は48万円以下)ですか？"
                        item-label-sub="扶養家族に関する質問"
                      />
                      <ValidationRadio
                        :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                        v-model="dependent.relationshipQuestion4"
                        item-label="青色申告者の事業専従者としてその年を通じて一度も給与の支払を受けていませんか？または白色申告者の事業専従者ではありませんか？"
                        item-label-sub="扶養家族に関する質問"
                      />
                    </template>
                    <ValidationRadio
                      :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                      v-model="dependent.living_together"
                      item-label="同居区分"
                    />
                    <ValidationSelect
                      v-model="dependent.dependent_relative_classification"
                      item-label="扶養親族区分"
                      :options="dependentRelativeClassifications"
                      :disabled="false"
                      item-name="dependent_relative_classification_1"
                      classes="width150"
                      :rules="requiredIfNotRetiredOrNotDeleted(dependent)"
                      :has-help="true"
                      @help-clicked="helpClicked"
                    />
                  </div>
                </div>
              </template>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="col-md-11 col-md-offset-1">
        <button type="button" class="btn btn-warning btn-sm btn-default" @click="save">設定</button>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import DependentRelativeClassification from './DependentRelativeClassification.vue'
  import mixin from '@/lib/mixin'
  import { Loader } from 'google-maps'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationDate,
      ValidationSelect,
      ValidationRadio,
      ValidationPostalCode,
      DependentRelativeClassification
    },
    props: {
      employeeInfo: Object,
      comboData: Object
    },
    data () {
      return {
        employeeError: '',
        uploadImage: false,
        componentKey: 0,
        from: 0,
        dependentRelativeClassifications: [{label: '一般', value: '1'}],
        relationships: [{label: '父', value: '1'}, {label: '母', value: '2'}],
        workSchedules: [{label: 'オンザロード', value: 'otr'}],
        salaryClosings: [{label: '10日締め', value: '001'}],
        languages: [{label: '日本語', value: 'JPN'}],
        employeeClassifications: [{label: '正社員', value: '1'}, {label: '準社員', value: '2'}, {label: '契約社員', value: '3'}],
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now()
          }
        },
        employees: [
          {
            before: {},
            after: {}
          }
        ],
        google: null
      }
    },
    methods: {
      deleteClicked () {
        this.$forceUpdate()
      },
      helpClicked () {
        this.$modal.show('dependentRelativeClassification')
      },
      closeClicked () {
        this.$modal.hide('dependentRelativeClassification')
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createImage(files[0])
      },
      createImage (file) {
        let self = this
        let appendPath = `migration/${sessionStorage.getItem('corpusCompanyCode')}/${this.employees[0].after.employee_code}.jpg`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.put(file, {contentType: 'image/jpeg'}).then(function (snapShot) {
          self.employees[0].after.append_path = appendPath
          httpsReference.getDownloadURL().then(function (url) {
            self.uploadImage = url
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      prefecturesChanged () {
        if (this.employees[this.from].after.prefectures_before) {
          this.employees[this.from].after.city = ''
        }
        if (this.employees[this.from].after.prefectures) {
          this.employees[this.from].after.prefectures_before = this.employees[this.from].after.prefectures
          this.comboData.state_municipality_list.city_list = []
          if (!this.comboData.state_municipality_list.state_municipality_mapping_list[this.employees[this.from].after.prefectures]) {
            for (const prefecture of this.comboData.state_codes_list) {
              if (prefecture.label === this.employees[this.from].after.prefectures) {
                this.employees[this.from].after.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.state_municipality_list.state_municipality_mapping_list[this.employees[this.from].after.prefectures]) {
            for (const code of this.comboData.state_municipality_list.state_municipality_mapping_list[this.employees[this.from].after.prefectures]) {
              for (const entry of this.comboData.state_municipality_list.municipality_list) {
                if (code === entry.value) {
                  this.comboData.state_municipality_list.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.state_municipality_list.city_list = []
        }
      },
      postalCodeSearch (postalCode) {
        const self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
            const myLatlng = results[0].geometry.location
            self.geocoder.geocode({location: myLatlng}, function (results, status) {
              if (status === 'OK') {
                for (const result of results) {
                  if (result.types) {
                    let isFound = false
                    for (const type of result.types) {
                      if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                        const componentsLength = result.address_components.length
                        self.employees[self.from].after.post_code = result.address_components[componentsLength - 1].long_name
                        for (const prefecture of self.comboData.state_municipality_list.state_list) {
                          if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                            self.employees[self.from].after.prefectures = prefecture.value
                            break
                          }
                        }
                        if (self.employees[self.from].after.prefectures) {
                          self.prefecturesChanged()
                        }
                        for (const municipality of self.comboData.state_municipality_list.municipality_list) {
                          if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                            self.employees[self.from].after.city = municipality.value
                            break
                          }
                        }
                        self.employees[self.from].after.town = ''
                        for (var i = componentsLength - 5; i >= 0; i--) {
                          self.employees[self.from].after.town = self.employees[self.from].after.town + result.address_components[i].long_name
                        }
                        self.componentKey += 1
                        isFound = true
                        break
                      }
                    }
                    if (isFound) break
                  }
                }
              }
            })
          })
        }
      },
      save () {
        this.employeeError = ''
        this.$refs.observer.validate()
        .then(isValid => {
          for (const dependent of this.employees[this.from].after.dependent_detail) {
            if (dependent.delete.value === '2') {
              if (dependent.relationship === '1' || dependent.relationship === '2') {
                if (dependent.relationshipQuestion1.value === '' ||
                    dependent.relationshipQuestion2.value === '' ||
                    dependent.relationshipQuestion3.value === '' ||
                    dependent.relationshipQuestion4.value === ''
                ) {
                  this.employeeError = '扶養家族（夫または妻）に関する質問に回答してください。'
                  isValid = false
                  break
                }
              }
            }
          }
          if (!isValid) {
            if (this.employeeError.length === 0) {
              this.employeeError = '入力内容に不備があります。'
            }
            document.getElementsByName('employeeFrame')[0].scrollTop = 0
            this.$emit('on-submit', this.registerForm, isValid)
            return
          }
          var employee = {...this.employees[this.from].after}
          for (const entry of this.employeeClassifications) {
            if (entry.value === employee.employee_classification_code) {
              employee.employee_classification_name = entry.label
              break
            }
          }
          for (const entry of this.workSchedules) {
            if (entry.value === employee.work_schedule_code) {
              employee.work_schedule_name = entry.label
              break
            }
          }
          this.$emit('close', true, employee)
        }).catch(error => {
          console.log(error)
        })
      },
      close () {
        this.$emit('close', false)
      },
      isQuestionsShow (dependentName) {
        for (const dependent of this.employees[this.from].after.dependent_detail) {
          if (dependent.dependent_name === dependentName) {
            if (dependent.relationship === '1' || dependent.relationship === '2') {
              return true
            }
            break
          }
        }
        return false
      },
      async setGeocoder () {
        if (!this.google) {
          this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
        }
        this.geocoder = new this.google.maps.Geocoder()
      }
    },
    computed: {
      deleteStyle: function () {
        return function (dependent) {
          return dependent.delete && dependent.delete.value === '2' ? '' : 'visibility: hidden;'
        }
      },
      requiredIfNotRetiredOrNotDeleted: function () {
        return function (dependent) {
          return (dependent.delete && dependent.delete.value === '1') || (this.employees[this.from] && this.employees[this.from].after.retirement_date) ? '' : 'required'
        }
      },
      requiredIfNotRetiredAndPayrollManagement () {
        return ((this.employees[this.from] && this.employees[this.from].after.retirement_date) || (this.employees[this.from] && this.employees[this.from].after.payroll_management.value === '2')) ? '' : 'required'
      },
      requiredIfNotRetired () {
        return this.employees[this.from] && this.employees[this.from].after.retirement_date ? '' : 'required'
      },
      workScheduleDisabled () {
        return !this.employees[this.from].after.isEditable
      },
      salaryClosingDisabled () {
        return !this.employees[this.from].after.isSalaryClosingEditable
      }
    },
    created () {
      let self = this
      let employee = {
        before: {...this.employeeInfo.before},
        after: {...this.employeeInfo.after}
      }
      let paths = employee.after.append_path.split('/')
      let fileName = paths[paths.length - 1]
      var appendPath = ''
      if (fileName === 'dummy.png') {
        appendPath = `employee/${fileName}`
      } else {
        appendPath = `migration/${sessionStorage.getItem('corpusCompanyCode')}/${fileName}`
      }
      const httpsReference = this.$firebase.storage()
        .ref()
        .child(appendPath)
      httpsReference.getDownloadURL().then(function (url) {
        self.uploadImage = url
      }).catch(function (error) {
        console.log(error)
      })
      if (employee.before.post_code.length === 7) {
        employee.before.post_code = employee.before.post_code.substring(0, 3) + '-' + employee.before.post_code.substring(3)
      }
      if (employee.after.post_code.length === 7) {
        employee.after.post_code = employee.after.post_code.substring(0, 3) + '-' + employee.after.post_code.substring(3)
      }
      employee.before.dependent_count = String(employee.before.dependent_count)
      employee.after.dependent_count = String(employee.after.dependent_count)
      var items = [
        {
          id: 'male',
          label: '男',
          value: '1'
        },
        {
          id: 'female',
          label: '女',
          value: '2'
        }
      ]
      employee.before.sex = {
        value: String(employee.before.sex),
        disabled: true,
        items: items
      }
      employee.after.sex = {
        value: String(employee.after.sex),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'available',
          label: '利用可能',
          value: '1'
        },
        {
          id: 'unavailable',
          label: '利用不可',
          value: '2'
        }
      ]
      employee.before.availability = {
        value: String(employee.before.availability),
        disabled: true,
        items: items
      }
      employee.after.availability = {
        value: String(employee.after.availability),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'joined',
          label: '加入',
          value: '1'
        },
        {
          id: 'notJoined',
          label: '未加入',
          value: '2'
        }
      ]
      employee.before.social_insurance = {
        value: String(employee.before.social_insurance),
        disabled: true,
        items: items
      }
      employee.after.social_insurance = {
        value: String(employee.after.social_insurance),
        disabled: true,
        items: items
      }
      employee.before.employment_insurance = {
        value: String(employee.before.employment_insurance),
        disabled: true,
        items: items
      }
      employee.after.employment_insurance = {
        value: String(employee.after.employment_insurance),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'manage',
          label: '管理する',
          value: '1'
        },
        {
          id: 'notManage',
          label: '管理しない',
          value: '2'
        }
      ]
      employee.before.attendance_management = {
        value: String(employee.before.attendance_management),
        disabled: true,
        items: items
      }
      employee.after.attendance_management = {
        value: String(employee.after.attendance_management),
        disabled: true,
        items: items
      }
      employee.before.payroll_management = {
        value: String(employee.before.payroll_management),
        disabled: true,
        items: items
      }
      employee.after.payroll_management = {
        value: String(employee.after.payroll_management),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'given',
          label: '有給休暇あり',
          value: '1'
        },
        {
          id: 'notGiven',
          label: '有給休暇なし',
          value: '2'
        }
      ]
      employee.before.paid_leave_payment = {
        value: String(employee.before.paid_leave_payment),
        disabled: true,
        items: items
      }
      employee.after.paid_leave_payment = {
        value: String(employee.after.paid_leave_payment),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'one',
          label: '1日',
          value: '1'
        },
        {
          id: 'two',
          label: '2日',
          value: '2'
        },
        {
          id: 'three',
          label: '3日',
          value: '3'
        },
        {
          id: 'four',
          label: '4日',
          value: '4'
        },
        {
          id: 'five',
          label: '5日',
          value: '5'
        }
      ]
      employee.before.number_of_working_days_per_week = {
        value: String(employee.before.number_of_working_days_per_week),
        disabled: true,
        items: items
      }
      employee.after.number_of_working_days_per_week = {
        value: String(employee.after.number_of_working_days_per_week),
        disabled: true,
        items: items
      }
      items = [
        {
          id: 'NO',
          label: 'いいえ',
          value: '2'
        },
        {
          id: 'YES',
          label: 'はい',
          value: '1'
        }
      ]
      if (employee.before.dependent_detail) {
        for (const dependent of employee.before.dependent_detail) {
          if (!dependent.relationshipQuestion1) {
            dependent.relationshipQuestion1 = {
              value: dependent.relationship_question1 === undefined ? '' : String(dependent.relationship_question1),
              disabled: true,
              items: items
            }
            dependent.relationshipQuestion2 = {
              value: dependent.relationship_question2 === undefined ? '' : String(dependent.relationship_question2),
              disabled: true,
              items: items
            }
            dependent.relationshipQuestion3 = {
              value: dependent.relationship_question3 === undefined ? '' : String(dependent.relationship_question3),
              disabled: true,
              items: items
            }
            dependent.relationshipQuestion4 = {
              value: dependent.relationship_question4 === undefined ? '' : String(dependent.relationship_question4),
              disabled: true,
              items: items
            }
          }
        }
      }
      if (employee.after.dependent_detail) {
        for (const dependent of employee.after.dependent_detail) {
          if (!dependent.relationshipQuestion1) {
            dependent.relationshipQuestion1 = {
              value: dependent.relationship_question1 === undefined ? '' : String(dependent.relationship_question1),
              disabled: false,
              items: items
            }
            dependent.relationshipQuestion2 = {
              value: dependent.relationship_question2 === undefined ? '' : String(dependent.relationship_question2),
              disabled: false,
              items: items
            }
            dependent.relationshipQuestion3 = {
              value: dependent.relationship_question3 === undefined ? '' : String(dependent.relationship_question3),
              disabled: false,
              items: items
            }
            dependent.relationshipQuestion4 = {
              value: dependent.relationship_question4 === undefined ? '' : String(dependent.relationship_question4),
              disabled: false,
              items: items
            }
          }
        }
      }
      items = [
        {
          id: 'together',
          label: '同居あり',
          value: '1'
        },
        {
          id: 'notTogether',
          label: '同居なし',
          value: '2'
        }
      ]
      if (employee.before.dependent_detail) {
        for (const dependent of employee.before.dependent_detail) {
          dependent.relationship = String(dependent.relationship)
          if (!(dependent.living_together instanceof Object)) {
            dependent.living_together = {
              value: String(dependent.living_together),
              disabled: true,
              items: items
            }
          }
        }
      }
      if (employee.after.dependent_detail) {
        for (const dependent of employee.after.dependent_detail) {
          dependent.relationship = String(dependent.relationship)
          if (!(dependent.living_together instanceof Object)) {
            dependent.living_together = {
              value: String(dependent.living_together),
              disabled: false,
              items: items
            }
          }
        }
      }
      this.employees = [employee]
      this.dependentRelativeClassifications = []
      for (const classification of this.comboData.dependent_relative_classification_list[0]) {
        let relativeClassification = {
          label: this.getLabel(classification.label),
          value: String(classification.value)
        }
        this.dependentRelativeClassifications.push(relativeClassification)
      }
      this.employeeClassifications = []
      for (const classification of this.comboData.employee_classification_list[0]) {
        let employeeClassification = {
          label: this.getLabel(classification.label),
          value: String(classification.value)
        }
        this.employeeClassifications.push(employeeClassification)
      }
      this.relationships = []
      for (const relation of this.comboData.relationship_list[0]) {
        let relationship = {
          label: this.getLabel(relation.label),
          value: String(relation.value)
        }
        this.relationships.push(relationship)
      }
      this.workSchedules = []
      for (const schedule of this.comboData.working_info_list[0]) {
        let workSchedule = {
          label: this.getLabel(schedule.label),
          value: String(schedule.value)
        }
        this.workSchedules.push(workSchedule)
      }
      const salaryClosings = []
      if (this.comboData.salary_closing_list.length > 0) {
        for (const closing of this.comboData.salary_closing_list[0]) {
          let salaryClosing = {
            label: this.getLabel(closing.value),
            value: String(closing.label)
          }
          salaryClosings.push(salaryClosing)
        }
        this.salaryClosings = salaryClosings
      }
      this.comboData.state_municipality_list = this.comboData.state_municipality_mapping_list[0]
      if (!this.comboData.state_municipality_list) {
        this.comboData.state_municipality_list = {}
        this.comboData.state_municipality_list.state_list = []
        this.comboData.state_municipality_list.municipality_list = []
      }
      this.comboData.state_municipality_list.city_list = []
      if (employee.after.prefectures) this.prefecturesChanged()
      this.setGeocoder()
      this.componentKey += 1
    }
  }
</script>
<style>
</style>
