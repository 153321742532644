<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.place_name"
              item-name="place_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.place_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.place_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <div style="color: #C84513; margin-bottom: 5px;"><small>{{errMsg2}}</small></div>
            <div class="form-group">
              <div><label>{{$t('label.latlng')}}</label></div>
              <span>{{$t('label.add_pin')}}</span>
              <div id="regularMap" class="map" @click="mapClicked" style="margin: 10px 0;"></div>
              <button type="button" :disabled="isReadOnly || !this.detail.lat" class="btn btn-sm btn-default btn-primary" @click="clearMarker">{{$t('button.cancel')}}</button>
            </div>
            <ValidationPostalCode
              v-model="detail.post_code"
              item-name="post_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.post_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.post_code')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              tourSteps="1,2"
              @search="postalCodeSearch"
              @pin="putPin"
            />
            <ValidationSelectSimple
              v-model="detail.prefectures"
              item-name="prefectures"
              :placeholder="$t('label.select_description')"
              classes="length6"
              rules="required"
              :item-label="labels.prefectures"
              :options="comboData.prefectures_list"
              :disabled="isReadOnly"
              @change="prefecturesChanged"
            />
            <ValidationSelectSimple
              v-model="detail.city"
              item-name="city"
              :placeholder="$t('label.select_description')"
              classes="length50"
              rules="required"
              :item-label="labels.city"
              :options="comboData.city_list"
              :disabled="isReadOnly"
              @change="cityChanged"
            />
            <ValidationText
              v-model="detail.town"
              item-name="town"
              max-length="50"
              classes="form-control length50"
              :item-label="labels.town"
              :placeholder="isReadOnly ? '' : $t('placeholder.town')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.building"
              item-name="building"
              max-length="30"
              classes="form-control length30"
              :item-label="labels.building"
              :placeholder="isReadOnly ? '' : $t('placeholder.building')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationCheckboxes
              v-model="detail.stamping_radius_flg"
              :item-label="labels.stamping_radius_flg"
              :disabled="isReadOnly"
            />
            <div style="color: #C84513; margin-bottom: 5px;"><small>{{errMsg}}</small></div>
            <ValidationText
              v-model="detail.stamping_radius"
              item-name="stamping_radius"
              max-length="4"
              classes="form-control numLength4 text-right"
              :item-label="labels.stamping_radius"
              :placeholder="isReadOnly ? '' : $t('placeholder.stamping_radius')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              :unit="$t('label.unit_meter')"
              v-show="detail.stamping_radius_flg.items && !!detail.stamping_radius_flg.items[0].value"
              @blur="setCircle"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import PlaceDetailMixin from '@/components/Dashboard/Views/Generated/PlaceDetailMixin'

  export default {
    name: 'PlaceDetail',
    data () {
      return {
        errMsg: undefined,
        errMsg2: undefined,
        place: {
          prefectures_before: undefined
        }
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.prefectures_list = this.comboData.state_list
        this.comboData.city_list = []
        this.comboData.mapping_list = res.common_area.combo_data.state_municipality_list.state_municipality_mapping_list
        this.detail.prefectures = this.detail.state_code
        if (this.detail.prefectures) this.prefecturesChanged()
        this.detail.city = this.detail.municipality_code ? this.detail.state_code + '_' + this.detail.municipality_code : this.detail.municipality_code
        this.labels.latlng = this.labels.add_place
        this.labels.prefectures = this.labels.state_code_ryaku
        this.labels.city = this.labels.municipality_code_ryaku
        const stampingRadiusFlg = this.labels.stamping_radius_flg
        delete this.labels.stamping_radius_flg
        this.detail.stamping_radius_flg = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 1,
              label: stampingRadiusFlg,
              value: res.api_area ? !!res.api_area.stamping_radius_flg : false
            }
          ]
        }
        if (this.mode !== this.modes.add && this.detail.lat && this.detail.lng) {
          this.setMarker(false)
        }
      },
      prefecturesChanged () {
        if (this.place.prefectures_before && this.place.prefectures_before !== this.detail.prefectures) {
          this.detail.city = ''
        }
        if (this.detail.prefectures) {
          this.place.prefectures_before = this.detail.prefectures
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.detail.prefectures]) {
            for (const prefecture of this.comboData.prefectures_list) {
              if (prefecture.label === this.detail.prefectures) {
                this.detail.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.detail.prefectures]) {
            for (const code of this.comboData.mapping_list[this.detail.prefectures]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.city_list = []
        }
      },
      afterMapClicked (myLatlng, isForceUpdate) {
        let self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({location: myLatlng}, function (results, status) {
            if (status === 'OK') {
              for (const result of results) {
                if (result.types) {
                  let isFound = false
                  for (const type of result.types) {
                    if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                      const componentsLength = result.address_components.length
                      self.detail.post_code = result.address_components[componentsLength - 1].long_name
                      if (!self.detail.prefectures || isForceUpdate) {
                        for (const prefecture of self.comboData.prefectures_list) {
                          if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                            self.detail.prefectures = prefecture.value
                            break
                          }
                        }
                      }
                      if (self.detail.prefectures) {
                        self.prefecturesChanged()
                      }
                      if (!self.detail.city || isForceUpdate) {
                        for (const municipality of self.comboData.municipality_list) {
                          if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                            self.detail.city = municipality.value
                            break
                          }
                        }
                      }
                      if (!self.detail.town || isForceUpdate) {
                        self.detail.town = ''
                        for (var i = componentsLength - 5; i >= 0; i--) {
                          self.detail.town = self.detail.town + result.address_components[i].long_name
                        }
                      }
                      self.componentKey += 1
                      self.updateLocation()
                      isFound = true
                      break
                    }
                  }
                  if (isFound) break
                }
              }
            } else {
              self.detail.lat = undefined
              self.detail.lng = undefined
            }
          })
        }
      },
      customValidate () {
        this.errMsg = ''
        this.errMsg2 = ''
        if (!!this.detail.stamping_radius_flg.items[0].value && !this.detail.stamping_radius) {
          this.errMsg = this.$t('message.stamping_radius_required')
          return false
        }
        if (!!this.detail.stamping_radius_flg.items[0].value && (!this.detail.lat || !this.detail.lng)) {
          this.errMsg2 = this.$t('message.latlng_required')
          return false
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode === this.modes.add) {
          delete detail.place_code
        }
        if (this.mode !== this.modes.delete) {
          detail.state_code = detail.prefectures
          delete detail.prefectures
          delete detail.state_name
          detail.municipality_code = detail.city ? detail.city.split('_')[1] : detail.city
          delete detail.city
          delete detail.municipality_name
          detail.stamping_radius_flg = !!detail.stamping_radius_flg.items[0].value
          detail.stamping_radius = detail.stamping_radius_flg && detail.stamping_radius ? parseInt(detail.stamping_radius, 10) : null
        }
        return detail
      }
    },
    mixins: [PlaceDetailMixin]
  }
</script>
<style scoped>
</style>
