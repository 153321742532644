import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import LegalRuleSurveillanceWarningDetail from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceWarningDetail'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'LegalRuleSurveillanceDayDataMixin',
  mixins: [mixin],
  components: {
    LegalRuleSurveillanceWarningDetail,
    PPagination
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      showSearchCondition: true,
      modalWidthSub: 1080,
      tableData: [],
      searchCond: {
        search_type: 3,
        office_code: '',
        group_code: '',
        team_code: '',
        employee_code: '',
        employee_name: '',
        employee_classification_code: '',
        business_code: '',
        retirement: false,
        hireYear: false,
        target_term: ''
      },
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      errorList: [],
      legal_rule_url: '',
      disp_from: [],
      disp_to: [],
      maxLength: 0,
      dayInf: [],
      dispDataLabel: '労働時間'
    }
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 1
        if (row.legal_rule_violator_month_data.length > 0) {
          if (this.maxLength < row.legal_rule_violator_month_data[0].daily_report_list.length) {
            this.maxLength = row.legal_rule_violator_month_data[0].daily_report_list.length
            this.dayInf = []
            for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
              this.dayInf.push(month.target_date)
            }
          }
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 日毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            // 【おためし】一旦労働時間を格納
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.real_total_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        } else {
          if (this.maxLength === 0) {
            for (let i = 0; i < 30; i++) {
              let pushData = {
                targetDate: '',
                dispParam: '',
                type: '',
                errorList: []
              }
              row.dispData.push(pushData)
            }
            this.maxLength = 30
          }
        }
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
    },
    getDipsParam (searchMonht, rowData) {
      for (let row of rowData) {
        if (row.targetDate === searchMonht) {
          if (row.dispParam === '') {
            return ''
          }
          if (row.type === 'minute') {
            let minute = row.dispParam % 60
            if (minute < 10) {
              minute = '0' + minute
            }
            return (row.dispParam / 60 | 0) + ':' + minute
          } else if (row.type === 'day') {
            return row.dispParam + '日'
          } else if (row.type === 'hour') {
            return row.dispParam + '時間'
          }
        }
      }
    },
    getErrorList (searchMonht, rowData) {
      for (let row of rowData) {
        if (row.targetDate === searchMonht) {
          return row.errorList
        }
      }
    },
    isError (searchMonht, rowData) {
      for (let row of rowData.dispData) {
        if (row.targetDate === searchMonht) {
          return row.errorList.length > 0
        }
      }
    },
    // 労働時間に切り替え
    changeRealTotalMinutes () {
      this.dispDataLabel = '労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 1
        for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          let date = month.target_date.split('/')
          let pushData = {
            targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
            dispParam: month.real_total_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 所定労働時間に切り替え
    changeJobTotalMinutes () {
      this.dispDataLabel = '所定労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 2
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.job_total_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 所定外労働時間に切り替え
    changeJobOverworkMinutes () {
      this.dispDataLabel = '所定外労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 3
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.job_overwork_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 所定休日労働時間に切り替え
    changeJobHolidayHours () {
      this.dispDataLabel = '所定休日労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 5
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.job_holiday_hours,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 法定労働時間に切り替え
    changeLegalJobMinutes () {
      this.dispDataLabel = '法定労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 6
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.legal_job_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 法定外労働時間に切り替え
    changeLegalOverworkMinutes () {
      this.dispDataLabel = '法定外労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 7
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.legal_overwork_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 法定休日労働時間に切り替え
    changeLegalHolidayOverworkMinutes () {
      this.dispDataLabel = '法定休日労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 9
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.legal_holiday_overwork_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 深夜労働時間に切り替え
    changeLateNightOverworkMinutes () {
      this.dispDataLabel = '深夜労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 10
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.late_night_overwork_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 休憩時間に切り替え
    changeBreakMinutes () {
      this.dispDataLabel = '休憩時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 11
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.break_minutes,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 有給時間数に切り替え
    changePaidHolidayHours () {
      this.dispDataLabel = '有給時間数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 13
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.paid_holiday_hours,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    // 育児時間休暇数に切り替え
    changeChildTimeLeaveHours () {
      this.dispDataLabel = '育児時間休暇数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 15
        if (row.legal_rule_violator_month_data.length > 0) {
          for (let month of row.legal_rule_violator_month_data[0].daily_report_list) {
            // 月毎のループ
            // エラーがあるかチェック
            let errorList = []
            for (let panError of month.pan_error_list) {
              if (panError.error_item === itemNumber) {
                panError.errorFlg = true
                errorList.push(panError)
              }
            }
            for (let panWarning of month.pan_warning_list) {
              if (panWarning.error_item === itemNumber) {
                panWarning.errorFlg = false
                errorList.push(panWarning)
              }
            }
            let date = month.target_date.split('/')
            let pushData = {
              targetDate: String(Number(date[1])) + '月' + String(Number(date[2])) + '日',
              dispParam: month.child_time_leave_hours,
              type: 'minute',
              errorList: errorList
            }
            row.dispData.push(pushData)
          }
        }
      }
      this.componentKey += 1
    },
    tableCellClassName ({row, column, rowIndex, columnIndex}) {
      let exeNum = 0
      exeNum = row.dispData.length
      if (columnIndex === 0) {
        return ''
      }
      let searchMonth = this.getDispTargetMonth(columnIndex - 1)
      if (exeNum >= columnIndex) {
        for (let oneDispData of row.dispData) {
          if (oneDispData.targetDate === searchMonth) {
            if (!oneDispData.errorList) {
              return ''
            }
            if (oneDispData.errorList.length > 0) {
              let errorFlg = false
              for (let error of oneDispData.errorList) {
                errorFlg = error.errorFlg
              }
              if (errorFlg) {
                return 'error-cell'
              } else {
                return 'warning-cell'
              }
            } else {
              return 'normal-cell'
            }
          }
        }
        return 'normal-cell'
      }
    },
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    search () {
    },
    init () {
      let data = {
        screen_code: '09-011',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          office_code: '',
          team_code: '',
          employee_code: '',
          employee_name: '',
          employee_classification_code: '',
          group_code: '',
          retirement: false,
          business_type: '',
          target_month_from: this.params.target_month_from,
          target_month_to: this.params.target_month_to,
          target_month: this.params.targetMonth
        }
      }
      this.send('/init/legal_rule_violator', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.tableData = res.api_area
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterInit(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
      })
    },
    viewWarning (errorList, legalRuleUrl) {
      this.errorList = []
      for (const data of errorList) {
        let error = []
        let oneRecord = {}
        if (data.data_unit === 1) {
          // HH 単位[時]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.hours'),
            column3: data.actual_time + this.$t('label.hours'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 2) {
          // HHMM 単位[時分]
          oneRecord = {
            column1: data.target_date,
            column2: this.getHhmm(data.agreement),
            column3: this.getHhmm(data.actual_time),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 3) {
          // MM 単位[分]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.minute'),
            column3: data.actual_time + this.$t('label.minute'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 4) {
          // YYYY 単位[年]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.year'),
            column3: data.actual_time + this.$t('label.year'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 5) {
          // YYYYMM 単位[年月]
          oneRecord = {
            column1: data.target_date,
            column2: this.getYyyymm(data.agreement),
            column3: this.getYyyymm(data.actual_time),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 6) {
          // DAY 単位[日]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.day'),
            column3: data.actual_time + this.$t('label.day'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 7) {
          // COUNT 単位[回数]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.count'),
            column3: data.actual_time + this.$t('label.count'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 8) {
          // YEN 単位[円]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.yen'),
            column3: data.actual_time + this.$t('label.yen'),
            column4: data.pan_error_contents
          }
        }
        error.push(oneRecord)
        let twoRecord = {
          column1: data.chapter_contents,
          column2: data.article_contents,
          column3: data.term_contents,
          column4: data.issue_contents
        }
        error.push(twoRecord)
        this.errorList.push(error)
      }
      this.legal_rule_url = legalRuleUrl
      this.$modal.show('detailWarning')
    },
    getDispTargetMonth (offset) {
      if (this.dayInf[offset] === undefined) {
        return ''
      }
      let dispDay = this.dayInf[offset].split('/')
      return String(Number(dispDay[1])) + '月' + String(Number(dispDay[2])) + '日'
    },
    getHhmm (minute) {
      let hour = parseInt(minute / 60, 10)
      minute = minute % 60
      let strMinute = ''
      if (minute <= 9) {
        strMinute = '0' + String(minute)
      } else {
        strMinute = String(minute)
      }
      return hour + ':' + strMinute
    },
    getYyyymm (value) {
      let strValue = String(value)
      let result = strValue.slice(0, 4) + this.$t('label.year') + strValue.slice(-2) + this.$t('label.month')
      return result
    },
    hide (...args) {
      this.$modal.hide('monitoringConditions')
    },
    hideWarning (...args) {
      this.errorList = []
      this.$modal.hide('detailWarning')
    },
    getTableWidth (maxLength) {
      if (maxLength === 0) {
        return 'width: 180px'
      }
      return 'width: 1600px'
    }
  },
  created () {
    this.init()
  }
}
