import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelectSimple from '@/components/UIComponents/Inputs/ValidationSelectSimple'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
import { Loader } from 'google-maps'

export default {
  name: 'PlaceDetailMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationText,
    ValidationSelectSimple,
    ValidationCheckboxes,
    ValidationPostalCode
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {
        place_code: '',
        place_name: '',
        lat: '',
        lng: '',
        post_code: '',
        prefectures: '',
        city: '',
        town: '',
        building: '',
        stamping_radius_flg: {},
        stamping_radius: ''
      },
      geocoder: {},
      map: {},
      marker: null,
      geocoder2: {},
      google: null,
      steps: [
        {
          target: '[data-v-step="0"]',
          content: '登録したい場所の名前を入力してください。名前は事業所の所在地として利用されます。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="1"]',
          content: '登録する場所の郵便番号を入力してください。マップ上をクリックすることで自動的に入力することもできます。',
          params: {placement: 'bottom'}
        },
        {
          target: '[data-v-step="2"]',
          content: '郵便番号で検索して入力することもできます。',
          params: {placement: 'top'}
        }
      ],
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '03-021'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    afterMapClicked (myLatlng, isForceUpdate) {},
    prefecturesChanged () {},
    cityChanged () {},
    replay () {
      this.$tours['myTour'].start()
    },
    postalCodeSearch (postalCode) {
      const self = this
      if (this.geocoder.geocode) {
        this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
          if (status === 'OK') {
            const myLatLng = results[0].geometry.location
            self.detail.lat = String(myLatLng.lat())
            self.detail.lng = String(myLatLng.lng())
            // self.setMarker(true)
            const myLatlng = new self.google.maps.LatLng(self.detail.lat, self.detail.lng)
            self.afterMapClicked(myLatlng, true)
          }
        })
      }
    },
    putPin (postalCode) {
      const self = this
      if (this.geocoder.geocode) {
        this.geocoder.geocode({address: self.detail.prefectures + self.detail.city + self.detail.town, region: 'ja'}, function (results, status) {
          if (status === 'OK') {
            const myLatLng = results[0].geometry.location
            self.detail.lat = String(myLatLng.lat())
            self.detail.lng = String(myLatLng.lng())
            self.setMarker(false)
          }
        })
      }
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation, confirmed = null) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {place_code: this.detail.place_code}
          detail.place_name = this.detail.place_name
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        if (confirmed !== null) {
          reqData.api_area.confirmed = confirmed
        }
        this.send(`/${operation}/place`, reqData)
        .then(res => {
          if (this.mode === this.modes.add) {
            this.detail.place_code = res.api_area.place_data[0].place_code
          }
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    async setGeocoder () {
      if (!this.google) {
        this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
      }
      this.geocoder2 = new this.google.maps.Geocoder()
    },
    clearMarker () {
      this.detail.lat = ''
      this.detail.lng = ''
      if (this.marker) {
        this.marker.setMap(null)
      }
    },
    mapClicked (event) {
      if (event.latLng && !this.isChanged) {
        this.detail.lat = String(event.latLng.lat()).substring(0, 10)
        this.detail.lng = String(event.latLng.lng()).substring(0, 10)
        this.setMarker(false)
      }
    },
    setCircle () {
      if (this.circle) {
        this.circle.setMap(null)
      }
      if (this.detail.lat && this.detail.lng && this.detail.stamping_radius) {
        this.circle = new this.google.maps.Circle({
          strokeColor: '#2e8b57',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#2e8b57',
          fillOpacity: 0.35,
          center: this.marker.position,
          radius: parseInt(this.detail.stamping_radius, 10)
        })
        this.circle.setMap(this.map)
      }
    },
    setMarker (forceUpdate) {
      if (!this.google) return
      const myLatlng = new this.google.maps.LatLng(this.detail.lat, this.detail.lng)
      if (this.marker) {
        this.marker.setMap(null)
      }
      this.marker = new this.google.maps.Marker({
        position: myLatlng,
        title: this.detail.place_name
      })
      this.marker.setMap(this.map)
      this.setCircle()
      this.afterMapClicked(myLatlng, forceUpdate)
    },
    updateLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            let coords = position.coords
            if (!this.google) {
              this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
            }
            const myLat = this.detail.lat ? this.detail.lat : coords.latitude
            const myLng = this.detail.lng ? this.detail.lng : coords.longitude
            const myLatLng = new this.google.maps.LatLng(myLat, myLng)
            const mapOptions = {
              zoom: 17,
              center: myLatLng,
              scrollwheel: false
            }
            this.geocoder = new this.google.maps.Geocoder()
            this.map = new this.google.maps.Map(document.getElementById('regularMap'), mapOptions)
            this.google.maps.event.addListener(this.map, 'click', this.mapClicked)
            if (this.marker) {
              this.marker.setMap(this.map)
              this.setCircle()
            }
          }.bind(this),
          function () {
            swal({
              title: this.labels.failed_get_location,
              buttonStyling: false,
              cancelButtonClass: 'btn btn-warning btn-sm btn-default',
              allowOutsideClick: false
            })
          }
        )
      } else {
        swal({
          title: this.labels.failed_get_location,
          buttonStyling: false,
          cancelButtonClass: 'btn btn-warning btn-sm btn-default',
          allowOutsideClick: false
        })
      }
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          place_code: this.params.place_code
        }
      }
      this.send('/init/place', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
      })
      this.updateLocation()
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
