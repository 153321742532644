<template>
  <div style="display: inline-block;">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
      <Times
        v-model="itemValue"
        :disabled="disabled"
        :classes="classes"
        :labels="labels"
        :is-late-night="isLateNight"
        :is-late-early-departure="isLateEarlyDeparture"
      />
      <small class="text-danger" v-show="!valid">
        {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
      </small>
    </ValidationProvider>
  </div>
</template>

<script>
  import Times from '@/components/UIComponents/Inputs/Times'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Times
    },
    props: {
      value: String,
      itemName: String,
      rules: String,
      itemLabel: String,
      disabled: Boolean,
      classes: String,
      labels: Object,
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      isLateNight: {
        type: Boolean,
        require: false,
        'default': false
      },
      isLateEarlyDeparture: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        itemValue: undefined
      }
    },
    computed: {
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    watch: {
      itemValue () {
        this.$emit('input', this.itemValue)
      },
      value () {
        this.itemValue = this.value
      }
    },
    created () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>
