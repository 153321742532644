import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import NoticeDetail from '@/components/Dashboard/Views/Setting/NoticeDetail'
import StartEndDate from '@/components/UIComponents/Inputs/StartEndDate'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'NoticeSearchMixin',
  mixins: [mixin],
  components: {
    NoticeDetail,
    StartEndDate,
    ValidationCheckboxes,
    PPagination
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      showSearchCondition: true,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: [],
      searchCond: {
        notice_date: ['', ''],
        is_unread: {},
        retirement: false
      },
      showOperation: true,
      showLunchName: true,
      showUnitPrice: true,
      showQuantity: true,
      showPrice: true,
      showOrderDate: true,
      showPurchaseDate: true,
      showPaymentFlg: true,
      showCatchFlg: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      tableStyle: '',
      expandRowKeys: []
    }
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    selectAll () {
      const checked = document.getElementById('allSelect').checked
      console.log(checked)
      for (const data of this.tableData) {
        data.selected = checked
      }
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      const items = []
      for (const noticeType of this.comboData.notice_type_list) {
        let data = {
          id: Number(noticeType.value),
          label: noticeType.label,
          value: true
        }
        items.push(data)
      }
      this.searchCond.notice_type = {
        color: 'gray',
        disabled: false,
        items: items
      }
      const expandRowKeys = []
      for (const row of res.api_area.notice_data) {
        row.selected = false
        if (!row.is_read) expandRowKeys.push(row.notice_number)
      }
      this.expandRowKeys = expandRowKeys
      this.tableData = res.api_area.notice_data
      let ymd = this.getNowYMD()
      this.searchCond.notice_date = [
        ymd,
        ymd
      ]
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/employee_notice/list', reqData)
      .then(res => {
        const expandRowKeys = []
        for (const row of res.api_area.notice_data) {
          row.selected = false
          if (!row.is_read) expandRowKeys.push(row.notice_number)
        }
        this.expandRowKeys = expandRowKeys
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.notice_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode
      }
      this.send('/init/employee_notice/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    },
    getNoticeTypeName (num) {
      for (const noticeType of this.comboData.notice_type_list) {
        if (num === Number(noticeType.value)) {
          return noticeType.label
        }
      }
    },
    getReadName (isRead) {
      if (isRead) {
        return this.$t('label.read')
      } else {
        return this.$t('label.unread')
      }
    },
    getFontStyle (isRead) {
      if (isRead) {
        return 'text-align: center;'
      } else {
        return 'text-align: center; font-size: medium; font-weight: bold;'
      }
    },
    handleView (index, row) {
      this.params = {}
      this.params.notice_number = row.notice_number
      this.params.is_read = row.is_read
      this.params.contents = row.contents
      this.params.notice_type = row.notice_type
      this.params.notification = row.notification
      this.params.notification_display_date_from = row.notification_display_date_from
      this.params.notification_display_date_to = row.notification_display_date_to
      this.params.notification_transmission_date = row.notification_transmission_date
      this.mode = this.modes.view
      this.$modal.show('noticeDetail')
    },
    hide (...args) {
      this.$modal.hide('noticeDetail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.notice_type === 2) {
        return 'out-emergency'
      } else if (row.notice_type === 3) {
        return 'out-warning'
      }
      return 'out-info'
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    },
    getNowYMD () {
      let dt = new Date()
      let y = dt.getFullYear()
      let m = ('00' + (dt.getMonth() + 1)).slice(-2)
      let d = ('00' + dt.getDate()).slice(-2)
      let result = y + '-' + m + '-' + d
      return result
    },
    multiUpdate (isReadAfterUpdate) {
      const noticeList = []
      for (const row of this.tableData) {
        if (row.selected) {
          if (!row.is_read === isReadAfterUpdate) {
            noticeList.push({
              notice_number: row.notice_number,
              is_read: isReadAfterUpdate
            })
          }
        }
      }
      this.submit(noticeList)
    },
    submit (selectedList) {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          notice_list: selectedList
        }
      }
      this.send('/update/employee_notice/list', data)
      .then(res => {
        for (const row of selectedList) {
          if (row.is_read) {
            // 削除
            let result = this.$store.state.noticeList.filter((noticeList) => {
              return (noticeList.notice_number !== row.notice_number)
            })
            this.$store.state.noticeList = result
          } else {
            // 追加
            let addNotice = {
              contents: row.contents,
              is_read: row.is_read,
              notice_number: row.notice_number,
              notice_type: row.notice_type,
              notification: row.notification,
              notification_display_date_from: row.notification_display_date_from,
              notification_display_date_to: row.notification_display_date_to,
              notification_transmission_date: row.notification_transmission_date
            }
            this.$store.state.noticeList.push(addNotice)
          }
        }
        this.$store.commit('setNoticeList', this.$store.state.noticeList)
        this.search()
      })
    },
    getContents (content) {
      if (content.indexOf('http') === -1) return content
      return content.replace(/(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/i, '<a href="$1" target="_blank" style="color: #317FB8; word-break: break-all;">$1</a>')
    }
  },
  created () {
    this.init()
  }
}
