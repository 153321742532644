<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
      <span class="btn-label"><i class="ti-close"></i></span>
      {{$t('button.close')}}
    </button>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
      <div class="col-sm-12">
        {{ detail.employee_name }}
        <div class="row">
          <div class="col-md-5">
            <div class="card" style="height: 350px;">
              <div class="card-content">
                <BarChart :chartdata="weekdayChartdata" :options="weekdayOptions" :width="600" :height="320" />
              </div>
            </div>
            <div class="card" style="height: 350px;">
              <div class="card-content">
                <BarChart :chartdata="weekendChartdata" :options="weekendOptions" :width="600" :height="320" />
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="card" style="height: 720px;">
              <div class="card-header" style="text-align: center;">
                <span style="font-size: 16px; font-weight: bold; font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif; color: #666;">内訳</span>
              </div>
              <div class="card-content">
                <BarChart :chartdata="weekdaySubChart.chartdata" :options="weekdaySubChart.options" :width="260" :height="210" :styles="{display: 'inline-block'}" v-for="weekdaySubChart in weekdaySubChartList" v-bind:key="weekdaySubChart.options.title.text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LegalRuleSurveillanceDetailMixin from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceDetailMixin'

  export default {
    name: 'LegalRuleSurveillance',
    mixins: [LegalRuleSurveillanceDetailMixin]
  }
</script>
<style scoped>
</style>
