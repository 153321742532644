<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-primary btn-sm btn-default" @click="rankingClicked" v-if="rankingName">
          {{`${rankingName}${$t('button.move_to')}`}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
      <div class="col-sm-12">
        <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
      </div>
    </div>
    <div class="card">
      <div class="row card-content">
        <div class="col-md-7">
          <form method="#" action="#" @submit.prevent="">
            <div class="card">
              <div class="card-header" style="padding-top: 0;">
                <h4 class="card-title">
                  {{pageTitle}}
                </h4>
              </div>
              <div class="card-content" :key="componentKey">
                <ViewLabel
                  :value="company.company_code"
                  :itemLabel="labels.lbl_company_code"
                />
                <ValidationObserver ref="observer">
                  <ViewLabel
                    :value="company.company_name"
                    :itemLabel="labels.company_name"
                  />
                  <div class="form-group" style="display: inline-block; vertical-align: top;">
                    <label>{{labels.corporate_number}}</label>
                    <small class="help-block">
                      {{$t('label.max_length').replace('{maxlength}', 13)}}
                    </small>
                    <div>
                      <input 
                        type="text" 
                        :placeholder="$t('placeholder.corporate_number')"
                        class="form-control length13" 
                        v-model="company.corporate_number"
                        maxlength="13"
                        :disabled="false">
                    </div>
                  </div>
                  <div class="form-group" style="display: inline-block; vertical-align: bottom;">
                    <label>{{$t('label.corporate_number_page')}}</label>
                    <div>
                      <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" style="color: #0000EE;">https://www.houjin-bangou.nta.go.jp/</a>
                    </div>
                  </div>
                  <ValidationText
                    v-model="company.home_page"
                    item-name="home_page"
                    max-length="255"
                    classes="form-control length60"
                    :item-label="labels.home_page"
                    placeholder="本社"
                    :help-text="$t('label.max_length')"
                  />
                  <ValidationSelect
                    v-model="company.language"
                    item-name="langage"
                    rules="required"
                    :disabled="true"
                    :item-label="labels.language"
                    :options="comboData.language_type_list"
                  />
                  <ValidationPostalCode
                    v-model="company.post_code"
                    classes="form-control length8"
                    :disabled="false"
                    :item-label="labels.post_code"
                    item-name="postal_code"
                    :placeholder="$t('placeholder.postal_code')"
                    rules="required"
                    :help-text="$t('label.max_length')"
                    max-length="8"
                    :button-label="$t('button.postal_code_search_simple')"
                    tourSteps="0,1"
                    @search="postalCodeSearch"
                  />
                  <ValidationSearchSelect
                    v-model="company.prefectures"
                    :item-label="labels.state_name_ryaku"
                    :options="comboData.state_municipality_list ? comboData.state_municipality_list.state_list : []"
                    :disabled="false"
                    item-name="prefectures"
                    rules="required"
                    :clearable="false"
                    classes="length8"
                    @change="prefecturesChanged"
                  />
                  <ValidationSearchSelect
                    v-model="company.city"
                    item-name="city"
                    classes="length60"
                    :item-label="labels.municipality_name_ryaku"
                    rules="required"
                    :clearable="false"
                    :options="comboData.state_municipality_list ? comboData.state_municipality_list.city_list : []"
                    :disabled="false"
                  />
                  <ValidationText
                    v-model="company.town"
                    classes="form-control length60"
                    :disabled="false"
                    :item-label="labels.town"
                    item-name="town"
                    :placeholder="$t('placeholder.town')"
                    rules="required"
                    :help-text="$t('label.max_length')"
                    max-length="50"
                  />
                  <ValidationText
                    v-model="company.building"
                    item-name="building"
                    max-length="30"
                    classes="form-control length60"
                    :item-label="labels.building"
                    :placeholder="$t('placeholder.building')"
                    :help-text="$t('label.max_length')"
                  />
                  <ValidationText
                    v-model="company.tel"
                    item-name="tel"
                    max-length="20"
                    classes="form-control length20"
                    rules="required"
                    :item-label="labels.tel"
                    :placeholder="$t('placeholder.tel')"
                    :help-text="$t('label.max_length')"
                    data-v-step="2"
                  />
                  <ValidationText
                    v-model="company.fax"
                    item-name="fax"
                    max-length="20"
                    classes="form-control length20"
                    :item-label="labels.fax"
                    placeholder="000-000-0000"
                    :help-text="$t('label.max_length')"
                  />
                  <ValidationRadio
                    rules="required"
                    v-model="company.attendance_management"
                    :item-label="labels.attendance_management"
                    @change="attendanceManagementChanged"
                    data-v-step="3"
                  />
                  <ValidationRadio
                    rules="required"
                    v-model="company.payroll_management"
                    :item-label="labels.payroll_management"
                    @change="payrollManagementChanged"
                    data-v-step="4"
                  />
                </ValidationObserver>
                <div class="form-group">
                  <label>{{ $t('label.annual_holidays') }}</label>
                  <br>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                            :data="company.office_data"
                            style="width: 221px;"
                            :empty-text="$t('label.no_data')">
                    <el-table-column width="100" :label="labels.office" :key="componentKey3">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <small>{{props.row.office_code}}</small>
                        </div>
                        <div style="text-align: left;">
                          {{props.row.office_name}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="120" :label="$t('label.annual_holidays')">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;">
                          {{`${props.row.days_holiday}${$t('label.unit_day')}`}}
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <ViewLabel
                  :value="company.enrolled_count"
                  :itemLabel="$t('label.enrolled_count')"
                  :unit="$t('label.unit_people')"
                />
                <ViewLabel
                  :value="company.average_age"
                  :itemLabel="$t('label.average_age')"
                  :unit="$t('label.unit_age')"
                />
                <ViewLabel
                  :value="formatNumber(company.average_wage)"
                  :itemLabel="$t('label.average_wage')"
                  :unit="$t('label.unit_yen')"
                />
                <ViewLabel
                  :value="company.average_overtime_hours"
                  :itemLabel="$t('label.average_overtime_hours')"
                  :unit="$t('label.unit_hours')"
                />
                <ViewLabel
                  :value="company.average_paid_holidays"
                  :itemLabel="$t('label.average_paid_holidays')"
                />
                <ViewLabel
                  :itemLabel="$t('label.average_enrolled_months')"
                  :value1="company.average_enrolled_months_year"
                  :unit1="$t('label.unit_year')"
                  :value2="company.average_enrolled_months_month"
                  :unit2="$t('label.unit_months')"
                />
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                          :data="company.employee_summary_list"
                          style="width: 402px;"
                          :empty-text="$t('label.no_data')">
                  <el-table-column width="100" :label="$t('label.fiscal_year')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        {{props.row.fiscal_year}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" :label="$t('label.hire_employee_count')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        {{props.row.hire_employee_count}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" :label="$t('label.retirement_employee_count')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        {{props.row.retirement_employee_count}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" :label="$t('label.active_employee_count')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        {{props.row.active_employee_count}}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- ↓実装時にこれを使う -->
            <!-- <template v-if="mode==modes.edit"> -->
            <template>
              <div style="text-align: left;">
                <button type="button" class="btn btn-sm btn-default btn-info" @click="submit">
                  {{$t('button.update')}}
                </button>
              </div>
            </template>
          </form>
        </div>
        <div class="col-md-5">
          <form method="#" action="#">
            <div class="card">
              <div class="card-header" style="padding-top: 0;">
                <h4 class="card-title">
                </h4>
              </div>
              <div class="card-content" :key="componentKey2">
                <ValidationObserver ref="observer">
                  <ValidationImage
                    v-model="company.append_path"
                    :item-label="$t('label.company_logo')"
                    :size="256 * 1024"
                  />
                  <ValidationRadio
                    rules="required"
                    v-model="company.disclosure_of_company"
                    :item-label="labels.disclosure_of_company"
                    data-v-step="5"
                  />
                  <template v-if="company.attendance_management && company.attendance_management.value === 1">
                    <ValidationRadio
                      rules="required"
                      v-model="company.is_smilemark_available"
                      :item-label="labels.is_smile_mark"
                      data-v-step="6"
                    />
                    <ValidationSelect
                      v-model="company.start_day_of_the_week"
                      item-name="start_day_of_the_week"
                      rules="required"
                      :item-label="labels.start_day_of_the_week"
                      :options="comboData.day_of_the_week_list"
                      data-v-step="7"
                    />
                    <ValidationRadio
                      v-model="company.rounding_second"
                      :item-label="labels.rounding_second"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.rounding_type"
                      :item-label="labels.rounding_type"
                      data-v-step="8"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.rounding_month"
                      v-if="company.rounding_type && company.rounding_type.value=='2'"
                      :item-label="labels.rounding_month"
                    />
                    <ValidationText
                      v-model="company.rounding_term"
                      item-name="rounding_term"
                      max-length="2"
                      classes="form-control length2"
                      rules="required|min_value:1"
                      v-if="company.rounding_type && company.rounding_type.value=='3'"
                      :item-label="labels.rounding_term"
                      :help-text="$t('label.max_length')"
                      :unit="$t('label.unit_minutes')"
                    />
                    <ValidationText
                      v-model="company.rounding_term_over_work"
                      item-name="rounding_term_over_work"
                      max-length="2"
                      classes="form-control length2"
                      rules="required|min_value:1"
                      v-if="company.rounding_type && company.rounding_type.value=='3'"
                      :item-label="labels.rounding_term_over_work"
                      :help-text="$t('label.max_length')"
                      :unit="$t('label.unit_minutes')"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.rounding_job_start"
                      v-if="company.rounding_type && company.rounding_type.value=='3'"
                      :item-label="labels.rounding_job_start"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.rounding_job_end"
                      v-if="company.rounding_type && company.rounding_type.value=='3'"
                      :item-label="labels.rounding_job_end"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.flextime_full_two_day_weekend_system"
                      :item-label="$t('label.flextime_full_two_day_weekend_system')"
                    />
                    <div>
                      <label>{{ $t('label.number_of_working_hours_on_the_xxth') }}</label>
                    </div>
                    <ValidationText
                      v-model="company.number_of_working_hours_on_the_28th"
                      item-name="number_of_working_hours_on_the_28th"
                      max-length="6"
                      rules="required|isTime|max_time_value:176:00"
                      classes="form-control numLength6"
                      :item-label="$t('label.number_of_working_hours_on_the_28th')"
                      :placeholder="isReadOnly ? '' :  '160:00'"
                      :disabled="isReadOnly"
                      :help-text="$t('label.max_length')"
                      :is-company="true"
                    />
                    <div style="width: 50px; display: inline-block;"></div>
                    <ValidationText
                      v-model="company.number_of_working_hours_on_the_29th"
                      item-name="number_of_working_hours_on_the_29th"
                      max-length="6"
                      rules="required|isTime|max_time_value:182:12"
                      classes="form-control numLength6"
                      :item-label="$t('label.number_of_working_hours_on_the_29th')"
                      :placeholder="isReadOnly ? '' :  '165:42'"
                      :disabled="isReadOnly"
                      :help-text="$t('label.max_length')"
                      :is-company="true"
                    />
                    <br/>
                    <ValidationText
                      v-model="company.number_of_working_hours_on_the_30th"
                      item-name="number_of_working_hours_on_the_30th"
                      max-length="6"
                      rules="required|isTime|max_time_value:188:30"
                      classes="form-control numLength6"
                      :item-label="$t('label.number_of_working_hours_on_the_30th')"
                      :placeholder="isReadOnly ? '' :  '171:24'"
                      :disabled="isReadOnly"
                      :help-text="$t('label.max_length')"
                      :is-company="true"
                    />
                    <div style="width: 50px; display: inline-block;"></div>
                    <ValidationText
                      v-model="company.number_of_working_hours_on_the_31th"
                      item-name="number_of_working_hours_on_the_31th"
                      max-length="6"
                      rules="required|isTime|max_time_value:194:48"
                      classes="form-control numLength6"
                      :item-label="$t('label.number_of_working_hours_on_the_31th')"
                      :placeholder="isReadOnly ? '' :  '177:06'"
                      :disabled="isReadOnly"
                      :help-text="$t('label.max_length')"
                      :is-company="true"
                    />
                    <br/>
                  </template>
                  <ValidationSearchSelect
                    v-model="company.industry_code_big"
                    item-name="industry_code_big"
                    rules="required"
                    :clearable="false"
                    :item-label="labels.industry_code_big"
                    :options="comboData.industry_list ? comboData.industry_list.industry_code_big_list : []"
                    data-v-step="9"
                    @change="industryCodeBigChanged"
                  />
                  <ValidationSearchSelect
                    v-model="company.industry_code_during"
                    item-name="industry_code_during"
                    rules="required"
                    :clearable="false"
                    :item-label="labels.industry_code_during"
                    :options="comboData.industry_code_during_list"
                    @change="industryCodeDuringChanged"
                  />
                  <ValidationSearchSelect
                    v-model="company.industry_code_small"
                    item-name="industry_code_small"
                    rules="required"
                    :clearable="false"
                    :item-label="labels.industry_code_small"
                    :options="comboData.industry_code_small_list"
                  />
                  <ValidationText
                    v-model="company.labor_insurance_number"
                    item-name="labor_insurance_number"
                    max-length="18"
                    classes="form-control length18"
                    :item-label="labels.labor_insurance_number"
                    :help-text="$t('label.max_length')"
                  />
                  <template v-if="company.payroll_management && company.payroll_management.value === 1">
                    <ValidationRadio
                      rules="required"
                      v-model="company.social_insurance"
                      :item-label="labels.social_insurance"
                      data-v-step="10"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.collection_of_social_insurance_premiums"
                      :item-label="labels.collection_of_social_insurance_premiums"
                      v-show="company.social_insurance && company.social_insurance.value == 1"
                      :inline="false"
                      data-v-step="13"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.employment_insurance"
                      :item-label="labels.employment_insurance"
                      data-v-step="11"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.pension_fund_contributions"
                      :item-label="labels.pension_fund_contributions"
                      data-v-step="12"
                    />
                    <ValidationRadio
                      rules="required"
                      v-model="company.collection_of_resident_tax"
                      :item-label="labels.collection_of_resident_tax"
                      :inline="false"
                    />
                  </template>
                  <label>{{labels.applicationForm ? labels.applicationForm : ''}}</label>
                  <div style="margin-bottom: 10px;">
                    <el-select class="select-success"
                                size="large"
                                v-model="copyFrom">
                      <el-option v-for="option in comboData.company_application_control_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.value">
                      </el-option>
                    </el-select>
                    <button type="button" class="btn btn-sm btn-default btn-primary" @click="copyApplicationControl">{{$t('label.all_employee_application_control')}}</button>
                  </div>
                  <div style="color: #C84513; margin-bottom: 5px;" v-for="error in companyApplicationListErrors" v-bind:key="error">
                    <small>{{error}}</small>
                  </div>
                  <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                    border
                    style="width: 601px;"
                    :data="filteredData"
                    :empty-text="$t('label.no_data')">
                    <el-table-column
                      width="200"
                      :label="labels.applicationForm ? labels.applicationForm : ''"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          {{props.row.application_form_name}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="200"
                      :label="labels.company_application_control ? labels.company_application_control : ''"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <template v-if="disableApplicationForms.includes(props.row.application_form_code)">
                            {{ comboData.company_application_control_list.find((row) => row.value === props.row.application_control).label }}
                          </template>
                          <template v-else>
                            <el-select class="select-success length10"
                                        size="large"
                                        :disabled="isReadOnly"
                                        @change="synchronize(props.row)"
                                        v-model="props.row.application_control">
                              <el-option v-for="option in comboData.company_application_control_list"
                                          class="select-success"
                                          :value="option.value"
                                          :label="option.label"
                                          :key="option.value">
                              </el-option>
                            </el-select>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="200"
                      :label="labels.alert_management_control ? labels.alert_management_control : ''"
                      align="center">
                      <template slot-scope="props">
                        <div style="text-align: left;">
                          <el-select class="select-success length10"
                                      size="large"
                                      :disabled="isReadOnly || props.row.application_control === '2'"
                                      v-model="props.row.alert_management_control">
                            <el-option v-for="option in comboData.alert_management_control_list"
                                        class="select-success"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.value">
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <Tour tour-name="myTour" :steps="steps"/>
                </ValidationObserver>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <br>
          <br>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationTextAfter from '@/components/UIComponents/Inputs/ValidationTextAfter'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationImage from '@/components/UIComponents/Inputs/ValidationImage'
  import ViewLabel from '@/components/UIComponents/Inputs/ViewLabel'
  import { Loader } from 'google-maps'

  export default{
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationTextAfter,
      ValidationSelect,
      ValidationSearchSelect,
      ValidationDate,
      ValidationCheckboxes,
      ValidationRadio,
      ValidationPostalCode,
      ValidationImage,
      ViewLabel
    },
    data () {
      return {
        componentKey: 0,
        componentKey2: 1000,
        componentKey3: 2000,
        geocoder: {},
        labels: {},
        comboData: {},
        company: {},
        companyApplicationListErrors: [],
        copyFrom: '1',
        steps: [
          {
            target: '[data-v-step="0"]',
            content: '会社の郵便番号を入力してください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="1"]',
            content: '郵便番号で検索して続きの住所を自動入力することができます。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="2"]',
            content: '会社の電話番号を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="3"]',
            content: '勤怠を管理する場合「管理する」を選択してください。管理しないに設定した場合、従業員の管理設定はできません。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="4"]',
            content: '給与を管理する場合「管理する」を選択してください。管理しないに設定した場合、従業員の管理設定はできません。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="5"]',
            content: 'ホワイト企業ランキング等に、会社名の公開が可能な場合「する」を選択してください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="6"]',
            content: '従業員のストレス度を表すマークを利用するか選択してください。スマホ打刻で利用できます。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="7"]',
            content: '1週間の起算の曜日を選択してください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="8"]',
            content: '丸め処理をするかどうか選択してください。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="9"]',
            content: '会社の業種を選択してください。総務省 日本標準産業分類の項目が表示されます。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="10"]',
            content: '会社の社会保険制度の設定です。未加入に設定した場合、従業員の加入設定はできません。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="11"]',
            content: '会社の雇用保険制度の設定です。未加入に設定した場合、従業員の加入設定はできません。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="12"]',
            content: '会社の厚生年金基金制度の設定です。未加入に設定した場合、従業員の加入設定はできません。',
            params: {placement: 'top-start'}
          },
          {
            target: '[data-v-step="13"]',
            content: '算定基礎届・月額変更届の作成で、報酬月額の変更月を判断するために利用されます。',
            params: {placement: 'top-start'}
          }
        ],
        google: null
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
      },
      rankingName () {
        for (let menu of this.$store.state.loginUser.sidebarLinks) {
          for (let child of menu.children) {
            if (child.code === '09-017') {
              return child.name
            }
          }
        }
        return ''
      },
      filteredData () {
        return !this.company.company_application_list ? [] : this.company.attendance_management.value === 1 ? this.company.company_application_list.filter(e => e.application_form_code !== '0000000017' && e.application_form_code !== '0000000021') : this.company.company_application_list.filter(e => e.application_form_code === '0000000018' || e.application_form_code === '0000000019' || e.application_form_code === '0000000020')
      }
    },
    methods: {
      attendanceManagementChanged () {
        if (this.company.attendance_management.value === 2) {
          swal({
            html: this.company.attendance_management_alert_message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
          }).catch(() => {
            this.company.attendance_management.value = 1
          })
        }
      },
      payrollManagementChanged () {
        if (this.company.payroll_management.value === 2) {
          swal({
            html: this.company.wage_management_alert_message,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            this.company.social_insurance.value = 2
            this.company.social_insurance.disabled = true
            this.company.employment_insurance.value = 2
            this.company.employment_insurance.disabled = true
            this.company.pension_fund_contributions.value = 2
            this.company.pension_fund_contributions.disabled = true
            this.company.collection_of_resident_tax.value = 2
            this.company.collection_of_resident_tax.disabled = true
          }).catch(() => {
            this.company.payroll_management.value = 1
          })
        } else {
          this.company.social_insurance.disabled = false
          this.company.employment_insurance.disabled = false
          this.company.pension_fund_contributions.disabled = false
          this.company.collection_of_resident_tax.disabled = false
        }
      },
      rankingClicked () {
        this.$router.push({name: 'score'})
      },
      copyApplicationControl () {
        if (this.copyFrom) {
          for (const row of this.company.company_application_list) {
            if (!this.disableApplicationForms.includes(row.application_form_code)) {
              row.application_control = this.copyFrom
              row.alert_management_control = this.copyFrom
              if (row.application_control === '2') {
                row.alert_management_control = '2'
              }
            }
          }
        }
      },
      synchronize (row) {
        if (row.application_control === '2') {
          row.alert_management_control = '2'
        }
        if (row.application_form_code === '0000000016' && row.application_control === '2') {
          swal({
            html: this.$t('message.attendance_application_change_warning'),
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            for (const data of this.company.company_application_list) {
              if (data.application_form_code === '0000000004' || // 深夜
                data.application_form_code === '0000000007' || // 休日出勤
                data.application_form_code === '0000000002' || // 残業
                data.application_form_code === '0000000005' || // 遅刻
                data.application_form_code === '0000000006' // 早退
              ) {
                data.application_control = '2'
                data.alert_management_control = '2'
              }
            }
          }).catch(() => {})
        }
      },
      industryCodeBigChanged () {
        this.company.industry_code_during = ''
        this.company.industry_code_small = ''
        this.comboData.industry_code_during_list = []
        for (const code of this.comboData.industry_list.industry_mapping_big_and_during[this.company.industry_code_big]) {
          for (const entry of this.comboData.industry_list.industry_code_during_list) {
            if (code === entry.value) {
              this.comboData.industry_code_during_list.push(entry)
            }
          }
        }
        this.componentKey += 1
      },
      industryCodeDuringChanged () {
        this.company.industry_code_small = ''
        if (this.company.industry_code_during) {
          this.comboData.industry_code_small_list = []
          for (const code of this.comboData.industry_list.industry_mapping_during_and_small[this.company.industry_code_during]) {
            for (const entry of this.comboData.industry_list.industry_code_small_list) {
              if (code === entry.value) {
                this.comboData.industry_code_small_list.push(entry)
              }
            }
          }
        } else {
          this.comboData.industry_code_small = [...this.industry_code_small_full]
        }
        this.componentKey += 1
      },
      prefecturesChanged () {
        if (this.company.prefectures_before && this.company.prefectures_before !== this.company.prefectures) {
          this.company.city = ''
        }
        if (this.company.prefectures) {
          this.company.prefectures_before = this.company.prefectures
          this.comboData.state_municipality_list.city_list = []
          if (!this.comboData.state_municipality_list.state_municipality_mapping_list[this.company.prefectures]) {
            for (const prefecture of this.comboData.state_codes_list) {
              if (prefecture.label === this.company.prefectures) {
                this.company.prefectures = prefecture.value
                break
              }
            }
          }
          if (this.comboData.state_municipality_list.state_municipality_mapping_list[this.company.prefectures]) {
            for (const code of this.comboData.state_municipality_list.state_municipality_mapping_list[this.company.prefectures]) {
              for (const entry of this.comboData.state_municipality_list.municipality_list) {
                if (code === entry.value) {
                  this.comboData.state_municipality_list.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.state_municipality_list.city_list = []
        }
        this.componentKey += 1
      },
      postalCodeSearch (postalCode) {
        const self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
            const myLatlng = results[0].geometry.location
            self.geocoder.geocode({location: myLatlng}, function (results, status) {
              if (status === 'OK') {
                for (const result of results) {
                  if (result.types) {
                    let isFound = false
                    for (const type of result.types) {
                      if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                        const componentsLength = result.address_components.length
                        self.company.post_code = result.address_components[componentsLength - 1].long_name
                        for (const prefecture of self.comboData.state_municipality_list.state_list) {
                          if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                            self.company.prefectures = prefecture.value
                            break
                          }
                        }
                        if (self.company.prefectures) {
                          self.prefecturesChanged()
                        }
                        for (const municipality of self.comboData.state_municipality_list.municipality_list) {
                          if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                            self.company.city = municipality.value
                            break
                          }
                        }
                        self.company.town = ''
                        for (var i = componentsLength - 5; i >= 0; i--) {
                          self.company.town = self.company.town + result.address_components[i].long_name
                        }
                        self.componentKey += 1
                        isFound = true
                        break
                      }
                    }
                    if (isFound) break
                  }
                }
              }
            })
          })
        }
      },
      submit () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
            this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
            return
          }
          const companyApplicationList = []
          const alertManagementList = []
          for (const row of this.company.company_application_list) {
            companyApplicationList.push({
              application_form_code: row.application_form_code,
              company_application_control: parseInt(row.application_control, 10)
            })
            alertManagementList.push({
              application_form_code: row.application_form_code,
              alert_management_control: parseInt(row.application_control, 10)
            })
          }
          const params = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              company_name: this.company.company_name,
              corporate_number: this.company.corporate_number,
              home_page: this.company.home_page,
              language: this.company.language,
              post_code: this.company.post_code,
              state_code: this.company.prefectures,
              municipality_code: this.company.city ? this.company.city.split('_')[1] : this.company.city,
              town: this.company.town,
              building: this.company.building,
              tel: this.company.tel,
              fax: this.company.fax,
              attendance_management: this.company.attendance_management.value,
              payroll_management: this.company.payroll_management.value,
              append_path: this.company.append_path,
              disclosure_of_company: parseInt(this.company.disclosure_of_company.value, 10),
              is_smile_mark: parseInt(this.company.is_smilemark_available.value, 10),
              start_day_of_the_week: this.company.start_day_of_the_week ? parseInt(this.company.start_day_of_the_week, 10) : 1,
              rounding_second: this.company.rounding_second ? parseInt(this.company.rounding_second.value, 10) : 1,
              rounding_type: this.company.rounding_type ? parseInt(this.company.rounding_type.value, 10) : 1,
              rounding_month: this.company.rounding_month.value ? parseInt(this.company.rounding_month.value, 10) : 1,
              rounding_term: this.company.rounding_term ? parseInt(this.company.rounding_term, 10) : 1,
              rounding_term_over_work: this.company.rounding_term_over_work ? parseInt(this.company.rounding_term_over_work, 10) : 1,
              rounding_job_start: this.company.rounding_job_start.value ? parseInt(this.company.rounding_job_start.value, 10) : null,
              rounding_job_end: this.company.rounding_job_end.value ? parseInt(this.company.rounding_job_end.value, 10) : null,
              flextime_full_two_day_weekend_system: this.company.flextime_full_two_day_weekend_system.value ? parseInt(this.company.flextime_full_two_day_weekend_system.value, 10) : null,
              number_of_working_hours_on_the_28th: this.company.number_of_working_hours_on_the_28th,
              number_of_working_hours_on_the_29th: this.company.number_of_working_hours_on_the_29th,
              number_of_working_hours_on_the_30th: this.company.number_of_working_hours_on_the_30th,
              number_of_working_hours_on_the_31th: this.company.number_of_working_hours_on_the_31th,
              social_insurance: this.company.social_insurance.value ? parseInt(this.company.social_insurance.value, 10) : 1,
              employment_insurance: this.company.employment_insurance.value ? parseInt(this.company.employment_insurance.value, 10) : 1,
              pension_fund_contributions: this.company.pension_fund_contributions.value ? parseInt(this.company.pension_fund_contributions.value, 10) : 1,
              collection_of_resident_tax: this.company.collection_of_resident_tax.value ? parseInt(this.company.collection_of_resident_tax.value, 10) : 1,
              collection_of_social_insurance_premiums: this.company.collection_of_social_insurance_premiums.value ? parseInt(this.company.collection_of_social_insurance_premiums.value, 10) : 1,
              industry_code_big: this.company.industry_code_big,
              industry_code_during: this.company.industry_code_during,
              industry_code_small: this.company.industry_code_small,
              labor_insurance_number: this.company.labor_insurance_number,
              // company_application_list: companyApplicationList,
              // alert_management_list: alertManagementList,
              update_count: this.company.update_count
            }
          }
          if (this.company.company_application_list.length > 0) {
            params.api_area.company_application_list = companyApplicationList
            params.api_area.alert_management_list = alertManagementList
          }
          params.api_area = this.nullToBlank(params.api_area)
          if (this.company.append_path === null || (this.company.append_path.length > 0 && this.company.append_path.indexOf('tmp') === -1)) {
            params.api_area.append_path = null
          }
          this.send(`/update/company_info`, params)
          .then(res => {
            const loginInfo = this.$store.state.loginUser
            loginInfo.companyName = this.company.company_name
            this.$store.commit('save', loginInfo)
            const self = this
            if (this.company.append_path && this.company.append_path.indexOf('tmp') > -1) {
              const extension = this.company.append_path.substring(this.company.append_path.lastIndexOf('.'))
              const httpsCompanyReference = this.$firebase.storage()
                .ref()
                .child(`company/${this.$store.state.loginUser.companyCode}/${this.$store.state.loginUser.companyCode}${extension}`)
              httpsCompanyReference.getDownloadURL().then(function (url) {
                self.$store.commit('setLogoUrl', url)
              })
            }
            if (this.company.append_path === '') {
              self.$store.commit('setLogoUrl', '')
            }
            this.company.update_count = res.api_area.update_count
            this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
          })
          .catch(error => {
            console.error(error)
          })
        })
      },
      async setGeocoder () {
        if (!this.google) {
          this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
        }
        this.geocoder = new this.google.maps.Geocoder()
      },
      init () {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/company_info', data)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          res.common_area.combo_data.state_municipality_list.city_list = []
          this.comboData.state_municipality_list = res.common_area.combo_data.state_municipality_list
          this.comboData.industry_list = res.common_area.combo_data.industry_list
          this.comboData.industry_code_during_list = [...this.comboData.industry_list.industry_code_during_list]
          this.comboData.industry_code_small_list = [...this.comboData.industry_list.industry_code_small_list]
          this.company = res.api_area
          this.company.number_of_working_hours_on_the_28th = String(this.company.number_of_working_hours_on_the_28th)
          this.company.number_of_working_hours_on_the_29th = String(this.company.number_of_working_hours_on_the_29th)
          this.company.number_of_working_hours_on_the_30th = String(this.company.number_of_working_hours_on_the_30th)
          this.company.number_of_working_hours_on_the_31th = String(this.company.number_of_working_hours_on_the_31th)
          this.company.start_day_of_the_week = String(this.company.start_day_of_the_week)
          this.company.rounding_term = String(this.company.rounding_term)
          this.company.rounding_term_over_work = String(this.company.rounding_term_over_work)
          this.company.average_age = this.formatFloat(this.company.average_age, 1)
          this.company.average_enrolled_months = this.formatFloat(this.company.average_enrolled_months, 1)
          // 都道府県
          this.company.prefectures = this.company.state_code
          if (this.company.prefectures) this.prefecturesChanged()
          this.company.city = this.company.municipality_code ? this.company.state_code + '_' + this.company.municipality_code : this.company.municipality_code
          // 労働管理
          for (const row of res.common_area.combo_data.attendance_management_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.attendance_management = {
            value: this.company.attendance_management,
            disabled: false,
            items: res.common_area.combo_data.attendance_management_list
          }
          // 賃金管理
          for (const row of res.common_area.combo_data.payroll_management_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.payroll_management = {
            value: this.company.payroll_management,
            disabled: false,
            items: res.common_area.combo_data.payroll_management_list
          }
          // 在籍人数
          for (const row of this.company.employee_data) {
            row.fiscal_year = String(row.year) + this.$t('label.year')
            row.hire_employee_count = row.hire_count
            row.retirement_employee_count = row.retirement_count
            row.active_employee_count = row.enrolled_count
          }
          this.company.employee_summary_list = this.company.employee_data
          // 会社名の公開
          const disclosureList = []
          disclosureList.push({
            id: 1,
            label: this.$t('label.do'),
            value: 1
          })
          disclosureList.push({
            id: 2,
            label: this.$t('label.not_do'),
            value: 2
          })
          this.company.disclosure_of_company = {
            value: this.company.disclosure_of_company,
            disabled: false,
            items: disclosureList
          }
          // スマイルマーク利用有無
          for (const row of res.common_area.combo_data.is_smile_mark_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.is_smilemark_available = {
            value: this.company.is_smile_mark,
            disabled: false,
            items: res.common_area.combo_data.is_smile_mark_list
          }
          // 労働秒の丸め
          for (const row of res.common_area.combo_data.rounding_second_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.rounding_second = {
            value: String(this.company.rounding_second),
            disabled: false,
            items: res.common_area.combo_data.rounding_second_list
          }
          // 労働時間の丸め
          for (const row of res.common_area.combo_data.rounding_type_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.rounding_type = {
            value: String(this.company.rounding_type),
            disabled: false,
            items: res.common_area.combo_data.rounding_type_list
          }
          // 月単位での丸め
          for (const row of res.common_area.combo_data.rounding_month_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.rounding_month = {
            value: this.company.rounding_month ? String(this.company.rounding_month) : '1',
            disabled: false,
            items: res.common_area.combo_data.rounding_month_list
          }
          // 出勤時間の丸め（日単位）
          for (const row of res.common_area.combo_data.rounding_job_start_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.rounding_job_start = {
            value: this.company.rounding_job_start ? String(this.company.rounding_job_start) : '1',
            disabled: false,
            items: res.common_area.combo_data.rounding_job_start_list
          }
          // 退勤時間の丸め（日単位）
          for (const row of res.common_area.combo_data.rounding_job_end_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.rounding_job_end = {
            value: this.company.rounding_job_end ? String(this.company.rounding_job_end) : '1',
            disabled: false,
            items: res.common_area.combo_data.rounding_job_end_list
          }
          // 完全週休二日制におけるフレックスタイムの適用有無
          this.company.flextime_full_two_day_weekend_system = {
            value: this.company.flextime_full_two_day_weekend_system ? String(this.company.flextime_full_two_day_weekend_system) : '2',
            disabled: false,
            items: res.common_area.combo_data.is_smile_mark_list
          }
          // 社会保険加入有無
          for (const row of res.common_area.combo_data.social_insurance_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.social_insurance = {
            value: this.company.social_insurance ? String(this.company.social_insurance) : '1',
            disabled: false,
            items: res.common_area.combo_data.social_insurance_list
          }
          // 雇用保険加入有無
          for (const row of res.common_area.combo_data.employment_insurance_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.employment_insurance = {
            value: this.company.employment_insurance ? String(this.company.employment_insurance) : '1',
            disabled: false,
            items: res.common_area.combo_data.employment_insurance_list
          }
          // 厚生年金基金加入有無
          for (const row of res.common_area.combo_data.pension_fund_contributions_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.pension_fund_contributions = {
            value: this.company.pension_fund_contributions ? String(this.company.pension_fund_contributions) : '1',
            disabled: false,
            items: res.common_area.combo_data.pension_fund_contributions_list
          }
          // 徴収タイミング
          for (const row of res.common_area.combo_data.collection_of_resident_tax_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.collection_of_resident_tax = {
            value: this.company.collection_of_resident_tax ? String(this.company.collection_of_resident_tax) : '1',
            disabled: false,
            items: res.common_area.combo_data.collection_of_resident_tax_list
          }
          // 社会保険料の徴収タイミング
          for (const row of res.common_area.combo_data.collection_of_social_insurance_premiums_list) {
            row.id = row.value
            row.label = this.getLabel(row.label)
          }
          this.company.collection_of_social_insurance_premiums = {
            value: this.company.collection_of_social_insurance_premiums ? String(this.company.collection_of_social_insurance_premiums) : '1',
            disabled: false,
            items: res.common_area.combo_data.collection_of_social_insurance_premiums_list
          }
          // 申請管理
          const companyApplicationList = []
          if (this.company.company_application_list) {
            for (const row1 of this.company.company_application_list) {
              for (const row2 of this.company.alert_management_list) {
                if (row1.application_form_code === row2.application_form_code) {
                  companyApplicationList.push({
                    application_form_code: row1.application_form_code,
                    application_form_name: row1.application_form_name,
                    application_control: this.disableApplicationForms.includes(row1.application_form_code) ? '1' : String(row1.company_application_control),
                    alert_management_control: String(row2.alert_management_control)
                  })
                }
              }
            }
          }
          this.company.company_application_list = companyApplicationList
          this.mode = res.common_area.button_role.update === 1 ? this.modes.edit : this.modes.view
          if (this.company.payroll_management.value === 2) {
            this.company.social_insurance.disabled = true
            this.company.employment_insurance.disabled = true
            this.company.pension_fund_contributions.disabled = true
            this.company.collection_of_resident_tax.disabled = true
          }
          this.setGeocoder()
          this.componentKey2 += 1
          this.componentKey3 += 1
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
