<template>
  <div class="row" ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <form method="#" action="#">
        <template v-if="bankSelected">
          <button type="button" class="btn btn-sm btn-default btn-warning" @click="back">{{$t('button.back')}}</button>
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
              <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
              <br>
              <p>{{$t('label.selected_bank')}}<b style="font-size: larger;">{{`${selectedBankName} (${selectedBankCode})`}}</b></p>
              <span>{{$t('label.branch_search_description')}}</span>
            </div>
            <div class="card-content">
              <ValidationText
                v-model="searchCond.branch_code"
                item-name="branch_code"
                max-length="3"
                classes="form-control numLength3"
                :item-label="$t('label.branch_code')"
                :placeholder="$t('placeholder.branch_code')"
                :disabled="false"
                :help-text="$t('label.max_length')"
              />
              <ValidationRadio
                v-model="searchCond.firstLetter"
                rules="required"
                :item-label="labels.bankName"
                :columns="10"
              />
              <div>
                <button type="button" class="btn btn-sm btn-default btn-search" @click="searchBranch">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
              <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
              <br>
              <span>{{$t('label.bank_search_description')}}<br>{{$t('label.bank_search_description2')}}</span>
            </div>
            <div class="card-content">
              <ValidationText
                v-model="searchCond.bank_code"
                item-name="bank_code"
                max-length="4"
                classes="form-control numLength4"
                :item-label="$t('label.bank_code')"
                :placeholder="$t('placeholder.bank_code')"
                :disabled="false"
                :help-text="$t('label.max_length')"
              />
              <div style="margin-bottom: 10px;">
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext('0001', $t('label.mizuho') + labels.bank)">{{$t('label.mizuho')}}</button>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext('0005', $t('label.ufj') + labels.bank)">{{$t('label.ufj')}}</button>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext('0009', $t('label.mitsui') + labels.bank)">{{$t('label.mitsui')}}</button>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext('0010', $t('label.risona') + labels.bank)">{{$t('label.risona')}}</button>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext('9900', $t('label.yucho') + labels.bank)">{{$t('label.yucho')}}</button>
              </div>
              <ValidationRadio
                v-model="searchCond.firstLetter"
                rules="required"
                :item-label="labels.bankName"
                :columns="10"
              />
              <div>
                <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </div>
          </div>
        </template>
      </form>
    </div>
    <div class="col-md-12">
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    </div>
    <template v-if="tableData.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content row" :key="componentKey">
            <div class="col-sm-4">
              <el-select
                class="select-success length2"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 10px;">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="pagedData"
                        style="width: 100%">
                <el-table-column
                  :width="120"
                  fixed="left"
                  :label="$t('label.operation')"
                  v-if="!bankSelected"
                  align="center">
                  <template slot-scope="props">
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="goNext(props.row.bank_code, props.row.bank_name + labels.bank)">{{$t('button.select')}}</button>
                  </template>
                </el-table-column>
                <el-table-column min-width="300" :label="labels.bank" align="center" v-if="!bankSelected">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.bank_code}}</small>
                      <br>
                      {{props.row.bank_name}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :width="90"
                  fixed="left"
                  :label="$t('label.operation')"
                  v-if="bankSelected"
                  align="center">
                  <template slot-scope="props">
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="select(props.row)">{{$t('button.select')}}</button>
                  </template>
                </el-table-column>
                <el-table-column min-width="300" :label="labels.branch" align="center" v-if="bankSelected">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <small>{{props.row.branch_code}}</small>
                      <br>
                      {{props.row.branch_name}}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination,
      ValidationText,
      ValidationRadio
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        selectedBankCode: '',
        selectedBankName: '',
        bankSelected: false,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        labels: {
          bank: '銀行'
        },
        comboData: {},
        searchCond: {
          bank_code: '',
          firstLetter: {}
        },
        tableData: []
      }
    },
    methods: {
      back () {
        this.selectedBankCode = ''
        this.selectedBankName = ''
        this.searchCond.bank_code = ''
        this.searchCond.branch_code = ''
        this.searchCond.firstLetter.value = ''
        this.tableData = []
        this.message.text = ''
        this.bankSelected = false
      },
      goNext (bankCode, bankName) {
        this.selectedBankCode = bankCode
        this.selectedBankName = bankName
        this.tableData = []
        this.message.text = ''
        this.bankSelected = true
        this.$nextTick(() => {
          this.$refs.frame.style = ''
        })
      },
      search () {
        let params = {
          screen_code: 'SS-022',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            bank_code: this.searchCond.bank_code,
            bank_japanese_name: this.searchCond.firstLetter.value
          }
        }
        this.send('/view/bank/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.tableData = res.api_area.bank_data
          this.pagination.currentPage = 1
        })
        this.$nextTick(() => {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 100px);'
        })
      },
      searchBranch () {
        let params = {
          screen_code: 'SS-022',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            bank_code: this.selectedBankCode,
            branch_code: this.searchCond.branch_code,
            branch_japanese_name: this.searchCond.firstLetter.value
          }
        }
        this.send('/view/bank/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.tableData = res.api_area.bank_data
          this.pagination.currentPage = 1
        })
        this.$nextTick(() => {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 100px);'
        })
      },
      init () {
        let params = {
          screen_code: 'SS-022',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/bank/list', params)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
        })
        // 初期表示
        this.searchCond.firstLetter = {
          value: '',
          disabled: false,
          items: [
            { label: 'あ', value: 'ｱ' }, { label: 'か', value: 'ｶ' }, { label: 'さ', value: 'ｻ' }, { label: 'た', value: 'ﾀ' }, { label: 'な', value: 'ﾅ' },
            { label: 'は', value: 'ﾊ' }, { label: 'ま', value: 'ﾏ' }, { label: 'や', value: 'ﾔ' }, { label: 'ら', value: 'ﾗ' }, { label: 'わ', value: 'ﾜ' },
            { label: 'い', value: 'ｲ' }, { label: 'き', value: 'ｷ' }, { label: 'し', value: 'ｼ' }, { label: 'ち', value: 'ﾁ' }, { label: 'に', value: 'ﾆ' },
            { label: 'ひ', value: 'ﾋ' }, { label: 'み', value: 'ﾐ' }, { label: 'ヰ', value: 'ｲ', hide: true }, { label: 'り', value: 'ﾘ' }, { label: '　', value: '　', hide: true },
            { label: 'う', value: 'ｳ' }, { label: 'く', value: 'ｸ' }, { label: 'す', value: 'ｽ' }, { label: 'つ', value: 'ﾂ' }, { label: 'ぬ', value: 'ﾇ' },
            { label: 'ふ', value: 'ﾌ' }, { label: 'む', value: 'ﾑ' }, { label: 'ゆ', value: 'ﾕ' }, { label: 'る', value: 'ﾙ' }, { label: '　', value: '　', hide: true },
            { label: 'え', value: 'ｴ' }, { label: 'け', value: 'ｹ' }, { label: 'せ', value: 'ｾ' }, { label: 'て', value: 'ﾃ' }, { label: 'ね', value: 'ﾈ' },
            { label: 'へ', value: 'ﾍ' }, { label: 'め', value: 'ﾒ' }, { label: 'ヱ', value: 'ｴ', hide: true }, { label: 'れ', value: 'ﾚ' }, { label: '　', value: '　', hide: true },
            { label: 'お', value: 'ｵ' }, { label: 'こ', value: 'ｺ' }, { label: 'そ', value: 'ｿ' }, { label: 'と', value: 'ﾄ' }, { label: 'の', value: 'ﾉ' },
            { label: 'ほ', value: 'ﾎ' }, { label: 'も', value: 'ﾓ' }, { label: 'よ', value: 'ﾖ' }, { label: 'ろ', value: 'ﾛ' }, { label: '未選択', value: '' }
          ]
        }
      },
      close () {
        this.$emit('close', null)
      },
      select (row) {
        this.$emit('close', row)
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
