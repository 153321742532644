<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" movie-key="" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" @submit.prevent="" :key="componentKey">
      <EmployeeAge
        v-model="detail.employee_age"
        :labels="labels"
        :combo-data="comboData"
        :disabled="isReadOnly"
        :parent-message="message"
        :search-cond="searchCond"
        ref="child"
        @init="init"
        @searchSucceeded="searchSucceeded"
      />
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import EmployeeAgeSheetMixin from '@/components/Dashboard/Views/Generated/EmployeeAgeSheetMixin'

  export default {
    name: 'EmployeeAgeSheet',
    methods: {
      afterInit (res) {
        const items = []
        for (const row of this.comboData.is_long_term_care_insurance_care_category_list) {
          const item = {
            id: row.value,
            label: this.getLabel(row.label),
            value: true
          }
          items.push(item)
        }
        this.searchCond.retirement = false
        this.searchCond.is_long_term_care_insurance_care_category = {
          color: 'gray',
          disabled: false,
          items: items
        }
        this.detail.employee_age = res.api_area.employee_data
        this.$nextTick(() => {
          this.$refs.child.setAge()
        })
      },
      convertDetail (detail) {
        const apiArea = []
        if (this.searchCond.search_type === 4) {
          for (const role of detail.authority) {
            for (const menu of role.data1) {
              for (const screen of menu.data2) {
                let element = {
                  role_code: role.titleKey,
                  screen_code: screen.titleKey,
                  entry: screen.authority.entry,
                  update: screen.authority.update,
                  delete: screen.authority.delete,
                  print: screen.authority.print,
                  search: screen.authority.search,
                  upload: screen.authority.upload,
                  download: screen.authority.download,
                  preview: screen.authority.preview,
                  update_count: screen.update_count
                }
                apiArea.push(element)
              }
            }
          }
        } else if (this.searchCond.search_type === 5) {
          for (const menu of detail.authority) {
            for (const screen of menu.data1) {
              for (const role of screen.data2) {
                let element = {
                  role_code: role.titleKey,
                  screen_code: screen.titleKey,
                  entry: role.authority.entry,
                  update: role.authority.update,
                  delete: role.authority.delete,
                  print: role.authority.print,
                  search: role.authority.search,
                  upload: role.authority.upload,
                  download: role.authority.download,
                  preview: role.authority.preview,
                  update_count: role.update_count
                }
                apiArea.push(element)
              }
            }
          }
        }
        return {search_info: this.searchCond, authority_list: apiArea}
      }
    },
    mixins: [EmployeeAgeSheetMixin]
  }
</script>
<style scoped>
</style>
