<template>
  <div>
    <div class="form-group">
      <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
      <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="fullLabel + '|' + new Date().getTime().toString(16)">
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            {{ errors[0] ? errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
          </small>
        </div>
        <div>
          <Times
            v-model="itemValue"
            :disabled="disabled"
            :classes="classes"
            :labels="labels"
            :maxHour="maxHour"
          />
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  import Times from '@/components/UIComponents/Inputs/Times'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      Times
    },
    props: {
      value: String,
      itemName: String,
      rules: String,
      itemLabel: String,
      disabled: Boolean,
      classes: String,
      labels: Object,
      labelPrefix: {
        type: String,
        require: false,
        'default': ''
      },
      maxHour: {
        type: Number,
        require: false,
        'default': 0
      }
    },
    data () {
      return {
        itemValue: undefined
      }
    },
    computed: {
      fullLabel () {
        return this.labelPrefix ? this.labelPrefix + '：' + this.itemLabel : this.itemLabel
      }
    },
    watch: {
      itemValue () {
        this.$emit('input', this.itemValue)
      },
      value () {
        this.itemValue = this.value
      }
    },
    created () {
      this.itemValue = this.value
    }
  }
</script>

<style scoped>
</style>
