<template>
  <div>
    <modal name="emoticonSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <EmoticonSelect @close="hideEmoticon"/>
      </div>
    </modal>
    <div v-if="initialized">
      <div style="margin-bottom: 10px;" v-if="isDialog">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="$emit('close')">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
      </div>
      <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
      <div>
        <div style="display: inline-block; width: 69%;">
          <el-select class="select-success"
                      style="position: relative; z-index: 3;"
                      size="large"
                      :disabled="officeDisabled"
                      v-model="officeCode">
            <el-option v-for="office in officeList"
                        class="select-success"
                        :value="office.office_code"
                        :label="office.office_name"
                        :key="office.office_code">
            </el-option>
          </el-select>
          <label>{{ `${$t('label.ground')}：${groundName}` }}</label>
          <template v-if="dispCheckBox">
            <input type="checkbox" v-model="workGround" class="gray" style="position: relative; z-index: 3;" id="workGround" @change="workGroundChanged" />
            <label for="workGround" style="position: relative; z-index: 3;">{{$t('label.work_ground')}}</label>
          </template>
          &nbsp;&nbsp;
          <input type="checkbox" v-model="includeNoStamp" class="gray" style="position: relative; z-index: 3;" id="includeNoStamp" @change="includeNoStampChanged" />
          <label for="includeNoStamp" style="position: relative; z-index: 3;">{{$t('label.include_no_stamp')}}</label>
        </div>
        <div style="display: inline-block; width: 30%; text-align: right; vertical-align: bottom;">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]"/>
        </div>
      </div>
      <div style="padding-top: 5px;">
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" :data="tableData"
                  border
                  ref="table"
                  style="position: relative; z-index: 3;"
                  max-height="170"
                  :key="componentKey"
        >
          <el-table-column :min-width="200" :label="$t('label.work_schedule')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div class="stamp-cell-left" style="font-size: medium; font-weight: bold;">
                {{ props.row.work_schedule_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="$t('label.date')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div class="stamp-cell" style="font-size: medium; font-weight: bold;">
                {{ props.row.today }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="$t('label.job_start')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <template v-if="props.row.stampingStartTime">
                <div class="stamp-cell" style="font-size: medium; font-weight: bold;">
                    {{ props.row.stampingStartTime }}
                  </div>
                </template>
              <template v-else>
                <div style="text-align: center;">
                  <button class="btn btn-info btn-sm btn-default" style="position: relative; z-index: 3;" v-show="!props.row.disableJobStart" @click="submitStamp('1', props.row)">{{$t('button.job_start')}}</button>
                  <a href="#" @click.prevent="goImprintCorrection(props.row, 1)" style="color: #317FB8;" v-show="props.row.is_start_time_imprint_correction">{{$t('label.imprint_correction')}}</a>
                  <input 
                    type="text" 
                    style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                    v-model="props.row.startTimeInput"
                    v-show="props.row.is_start_time_input">
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="$t('label.job_end')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <template v-if="props.row.stampingEndTime">
                <div class="stamp-cell" style="font-size: medium; font-weight: bold;">
                  {{ props.row.stampingEndTime }}
                </div>
              </template>
              <template v-else>
                <div class="stamp-cell">
                  <button class="btn btn-info btn-sm btn-default" style="position: relative; z-index: 3;" v-show="!props.row.disableJobEnd" @click="submitStamp('2', props.row)">{{$t('button.job_end')}}</button>
                  <a href="#" @click.prevent="goImprintCorrection(props.row, 2)" style="color: #317FB8;" v-show="props.row.is_end_time_imprint_correction">{{$t('label.imprint_correction')}}</a>
                  <input 
                    type="text" 
                    style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                    v-model="props.row.endTimeInput"
                    v-show="props.row.is_end_time_input">
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="$t('label.label_break')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <template v-if="props.row.disableBreakStart && props.row.disableBreakEnd">
                <div class="stamp-cell">
                </div>
              </template>
              <template v-else>
                <div class="stamp-cell">
                  <button class="btn btn-info btn-sm btn-default" style="position: relative; z-index: 3;" v-show="!props.row.disableBreakStart" @click="submitStamp('3', props.row)">{{$t('button.break_start')}}</button>
                  <button class="btn btn-info btn-sm btn-default" style="position: relative; z-index: 3;" v-show="!props.row.disableBreakEnd" @click="submitStamp('4', props.row)">{{$t('button.break_end')}}</button>
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="$t('label.telework_flg')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <div class="stamp-cell">
                <input type="checkbox" v-model="props.row.telework_flg" class="gray" style="position: relative; z-index: 3;" :disabled="props.row.disableTelework" v-show="props.row.showTelework">
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 5px; position: relative; z-index: 3;" v-if="isInputExist">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="regist">
          <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.add')}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import EmoticonSelect from '@/components/Dashboard/Views/Dashboard/EmoticonSelect'
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationRadio,
      EmoticonSelect
    },
    props: {
      isDialog: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        componentKey: 0,
        labels: {},
        stampDatetime: {
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          disableJobStart: false,
          disableJobEnd: true,
          disableBreakStart: true,
          disableBreakEnd: true
        },
        clockUrl: '/static/clock_mini/index.html?isjob=1&width=200',
        emoticonValue: 'normal.png',
        emoticonLabel: '',
        stampType: undefined,
        workSchedule: {},
        workScheduleList: [],
        stampingStartTime: undefined,
        stampingEndTime: undefined,
        apiArea: [],
        today: '',
        tableDataOff: [],
        tableDataOn: [],
        tableData: [],
        isSmileMark: false,
        tableStyle: '',
        isInputExist: false,
        includeNoStamp: false,
        officeCode: '',
        officeList: [],
        officeDisabled: false,
        dispCheckBox: false,
        workGround: false,
        groundCode: '',
        groundName: '',
        coords: {}
      }
    },
    computed: {
      stampTypeName () {
        if (this.stampType === '1') {
          return this.$t('button.job_start')
        } else if (this.stampType === '2') {
          return this.$t('button.job_end')
        } else if (this.stampType === '3') {
          return this.$t('button.break_start')
        } else if (this.stampType === '4') {
          return this.$t('button.break_end')
        }
        return ''
      },
      emoticonCode () {
        switch (this.emoticonValue) {
          case 'well.png': {
            return 1
          }
          case 'normal.png': {
            return 9
          }
          case 'lonely.png': {
            return 7
          }
          case 'glad.png': {
            return 2
          }
          case 'sad.png': {
            return 3
          }
          case 'mad.png': {
            return 4
          }
          case 'sleepy.png': {
            return 6
          }
          case 'nervous.png': {
            return 8
          }
          case 'sick.png': {
            return 5
          }
          default: {
            return null
          }
        }
      }
    },
    methods: {
      regist () {
        const stampingList = []
        for (const row of this.tableData) {
          if (row.is_start_time_input || row.is_end_time_input) {
            stampingList.push({
              target_date: row.target_date,
              stamping_start_time: !row.stampingStartTime ? !row.startTimeInput ? '' : this.formatTime(this.convertHhmmss2Hhmm(row.startTimeInput)) : this.formatTime(this.convertHhmmss2Hhmm(row.stampingStartTime)),
              stamping_end_time: !row.stampingEndTime ? !row.endTimeInput ? '' : this.formatTime(this.convertHhmmss2Hhmm(row.endTimeInput)) : this.formatTime(this.convertHhmmss2Hhmm(row.stampingEndTime)),
              work_schedule_code: row.work_schedule_code,
              telework_flg: !!row.telework_flg
            })
          }
        }
        let sendData = {
          screen_code: 'DB-003',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            stamping_list: stampingList
          }
        }
        this.send('/insert/timecard/external', sendData)
        .then(res => {
          console.log(res)
        })
      },
      goImprintCorrection (row, stampType) {
        const params = {target_date: row.target_date}
        if (stampType === 1) {
          params.stamping_start_time_change_flg = true
          params.stamping_end_time_change_flg = false
          params.telework_change_flg = false
        } else if (stampType === 2) {
          if (row.stampingStartTime) {
            params.stamping_start_time_change_flg = false
            params.stamping_end_time_change_flg = true
            params.telework_change_flg = false
          } else {
            params.stamping_start_time_change_flg = true
            params.stamping_end_time_change_flg = true
            params.telework_change_flg = false
          }
        }
        this.$router.push({name: 'workflowStampTime', params: params})
      },
      submitStamp (stampType, selectedRow) {
        this.stampType = stampType
        this.submit(selectedRow)
      },
      showEmoticon () {
        this.$modal.show('emoticonSelect')
      },
      hideEmoticon (selectedEmoticon) {
        this.$modal.hide('emoticonSelect')
        if (selectedEmoticon) {
          this.emoticonValue = selectedEmoticon.value
          this.emoticonLabel = selectedEmoticon.label
        }
        this.componentKey += 1
      },
      submit (selectedRow) {
        let self = this
        const smileMark = this.isSmileMark && this.stampType === '1' ? 9 : null
        let teleworkFlg = selectedRow.telework_flg
        if (!selectedRow.showTelework) {
          for (const row of this.tableData) {
            if (row.target_date === selectedRow.target_date && row.showTelework) {
              teleworkFlg = row.telework_flg
            }
          }
        }
        let params = {
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          office_code: this.officeCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          work_schedule_code: selectedRow.work_schedule_code,
          telework_flg: teleworkFlg,
          stamping_type: parseInt(this.stampType, 10),
          device_id: 3,
          smile_mark: smileMark,
          lat: this.coords.latitude ? this.coords.latitude.toFixed(6) : '',
          lng: this.coords.longitude ? this.coords.longitude.toFixed(6) : '',
          is_change_work_ground: this.workGround
        }
        this.sendDirect(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN + '/' + process.env.VUE_APP_PUBLISH_FUNCTION_NAME, params)
        .then(data => {
          this.isSmileMark = false
          const stamp = data.stamping_time.split(' ')
          selectedRow.disableTelework = false
          if (self.stampType === '1') {
            selectedRow.startDate = stamp[0].replaceAll('/', '-')
            selectedRow.stampingStartTime = selectedRow.startTime = stamp[1]
            selectedRow.disableJobStart = true
            selectedRow.disableJobEnd = false
            selectedRow.disableBreakStart = false
            selectedRow.disableBreakEnd = true
            selectedRow.disableTelework = true
            selectedRow.is_working = 1
            selectedRow.is_breaktime = false
            this.stampingStartTime = stamp[1]
            self.clockUrl = `/static/clock_mini/index.html?startdate=&start=&enddate=&end=&isjob=1&width=200`
          } else if (self.stampType === '2') {
            selectedRow.endDate = stamp[0].replaceAll('/', '-')
            selectedRow.stampingEndTime = selectedRow.endTime = stamp[1]
            selectedRow.disableJobStart = true
            selectedRow.disableJobEnd = true
            selectedRow.disableBreakStart = true
            selectedRow.disableBreakEnd = true
            selectedRow.is_working = 2
            selectedRow.is_breaktime = false
            this.stampingEndTime = stamp[1]
            self.clockUrl = `/static/clock_mini/index.html?startdate=&start=&enddate=&end=&isjob=0&width=200`
          } else if (self.stampType === '3') {
            selectedRow.disableJobStart = true
            selectedRow.disableJobEnd = true
            selectedRow.disableBreakStart = true
            selectedRow.disableBreakEnd = false
            selectedRow.is_working = 1
            selectedRow.is_breaktime = true
          } else if (self.stampType === '4') {
            selectedRow.disableJobStart = true
            selectedRow.disableJobEnd = false
            selectedRow.disableBreakStart = false
            selectedRow.disableBreakEnd = true
            selectedRow.is_working = 1
            selectedRow.is_breaktime = false
          }
          if (selectedRow.isAdded) {
            for (let i = 0; i < this.tableData.length; i++) {
              const row = this.tableData[i]
              if (row.work_schedule_code === selectedRow.work_schedule_code &&
                  row.target_date !== selectedRow.target_date
              ) {
                this.tableData.splice(i, 1)
                break
              }
            }
          }
          localStorage.setItem('stampDatetime', JSON.stringify(this.tableData))
          let text = data.stamping_time + ' ' + self.stampTypeName
          this.$emit('stamped', text)
          this.apiArea = this.tableData
          this.setStamp()
        })
        .catch(error => {
          console.error(error)
          swal({
            text: '打刻に失敗しました。',
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        })
      },
      isStartOlderThanEnd (startDate, startTime, endDate, endTime) {
        const startPos = new Date(startDate.replaceAll('-', '/') + ' ' + startTime.substring(0, 2) + ':' + startTime.substring(2, 4) + ':00').getTime()
        const endPos = new Date(endDate.replaceAll('-', '/') + ' ' + endTime.substring(0, 2) + ':' + endTime.substring(2, 4) + ':00').getTime()
        return startPos < endPos
      },
      setStamp () {
        const tableDataOn = []
        const tableDataOff = []
        let isWorkingExist = false
        for (const row of this.apiArea) {
          if (row.is_working === 1) {
            isWorkingExist = true
            break
          }
        }
        this.isInputExist = false
        let prevElement = {}
        this.officeDisabled = true
        for (const row of this.apiArea) {
          const element = {}
          element.stampingStartTime = undefined
          element.stampingEndTime = undefined
          element.is_working = row.is_working
          element.is_breaktime = row.is_breaktime
          element.is_start_time_imprint_correction = row.is_start_time_imprint_correction && row.is_start_time_imprint_correction_color === 3
          element.is_end_time_imprint_correction = row.is_end_time_imprint_correction && row.is_end_time_imprint_correction_color === 3
          element.is_start_time_input = row.is_start_time_input
          element.is_end_time_input = row.is_end_time_input
          if (element.is_start_time_input || element.is_end_time_input) {
            this.isInputExist = true
          }
          for (const workSchedule of this.$store.state.loginUser.workScheduleList) {
            if (workSchedule.work_schedule_code === row.work_schedule_code) {
              if (workSchedule.off_site_deemed_working_hours_flag) {
                row.is_working = 2
                element.isSmileMark = false
              }
              break
            }
          }
          if (isWorkingExist && row.is_working !== 1) {
            row.is_working = 2
          }
          element.isSmileMark = false
          element.disableTelework = false
          if (prevElement.target_date !== row.target_date) {
            element.showTelework = true
          } else {
            if (!prevElement.disableJobStart && row.is_working === 0) {
              element.showTelework = true
            } else {
              if (!prevElement.disableJobStart || !prevElement.stampingStartTime) {
                prevElement.showTelework = false
                element.showTelework = true
              } else if (row.is_working === 0 || !row.stamping_start_time) {
                element.showTelework = false
              } else {
                const prevMinutes = this.toMinutes(prevElement.stampingStartTime)
                const thisMinutes = this.toMinutes(row.stamping_start_time)
                if (prevMinutes < thisMinutes) {
                  element.showTelework = false
                } else {
                  prevElement.showTelework = false
                  element.showTelework = true
                }
              }
            }
          }
          if (row.is_working === 0) {
            element.disableJobStart = false
            element.disableJobEnd = true
            element.disableBreakStart = true
            element.disableBreakEnd = true
            element.isSmileMark = true
            this.officeDisabled = false
          } else if (row.is_working === 1) {
            if (row.is_breaktime) {
              element.disableJobStart = true
              element.disableJobEnd = true
              element.disableBreakStart = true
              element.disableBreakEnd = false
            } else {
              element.disableJobStart = true
              element.disableJobEnd = false
              element.disableBreakStart = false
              element.disableBreakEnd = true
            }
            element.disableTelework = true
          } else {
            element.disableJobStart = true
            element.disableJobEnd = true
            element.disableBreakStart = true
            element.disableBreakEnd = true
            element.disableTelework = true
          }
          element.stampingStartTime = row.stamping_start_time ? row.stamping_start_time : row.stampingStartTime ? row.stampingStartTime : undefined
          element.stampingEndTime = row.stamping_end_time ? row.stamping_end_time : row.stampingEndTime ? row.stampingEndTime : undefined
          element.work_schedule_code = row.work_schedule_code
          element.work_schedule_name = row.work_schedule_name
          element.today = row.target_date ? this.formatDateWith(row.target_date.replace('/', '-'), 'M/D(ddd)') : this.formatDateWith(this.today.replace('/', '-'), 'M/D(ddd)')
          element.target_date = row.target_date
          element.telework_flg = row.telework_flg
          element.isAdded = row.isAdded
          if (row.is_working === 2 && !element.stampingEndTime) {
            element.showTelework = false
          }
          tableDataOn.push(element)
          if (element.target_date === this.today) {
            tableDataOff.push(element)
          }
          prevElement = element
        }
        this.tableDataOn = tableDataOn
        this.tableDataOff = tableDataOff
        this.includeNoStampChanged()
      },
      workGroundChanged () {
        for (const row of this.apiArea) {
          if (row.target_date === this.today) {
            row.is_working = this.workGround ? 0 : 2
          }
        }
        this.setStamp()
      },
      includeNoStampChanged () {
        if (this.includeNoStamp) {
          this.tableData = this.tableDataOn
        } else {
          this.tableData = this.tableDataOff
        }
        this.renderTable()
      },
      renderTable () {
        this.tableData = [...this.tableData]
        this.componentKey += 1
      },
      init () {
        let params = {
          screen_code: 'DB-003',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/status/timecard/external', params)
        .then(res => {
          for (const workSchedule of this.$store.state.loginUser.workScheduleList) {
            if (workSchedule.default_flag === 1) {
              const items = []
              for (const workSchedule of this.$store.state.loginUser.workScheduleList) {
                items.push({
                  id: workSchedule.work_schedule_code,
                  label: workSchedule.work_schedule_name,
                  value: workSchedule.work_schedule_code
                })
              }
              this.workSchedule = {
                value: workSchedule.work_schedule_code,
                disabled: false,
                items: items
              }
              break
            }
          }
          this.today = res.api_area.today
          this.apiArea = res.api_area.working_info
          this.dispCheckBox = res.api_area.disp_check_box
          this.groundCode = res.api_area.ground_code
          this.groundName = res.api_area.ground_name
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async function (position) {
                this.coords = position.coords
              }.bind(this)
            )
          }
          this.setStamp()
        })
      }
    },
    created () {
      this.officeList = this.$store.state.loginUser.officeList
      this.officeCode = this.$store.state.loginUser.officeCode
      this.isSmileMark = this.$store.state.loginUser.isSmileMark
      this.emoticonLabel = this.$t('label.smile_mark_normal')
      this.init()
    }
  }
</script>
<style scoped>
  .stamp-cell {
    text-align: center;
    padding: 10px 0;
  }
  .stamp-cell-left {
    text-align: left;
    padding: 10px 0;
  }
</style>