import Vue from 'vue'
import { Table, TableColumn, Select, Option, DatePicker } from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import vSelect from 'vue-select'
import History from './TimeCardHistory'
import mixin from '@/lib/mixin'
import { Loader } from 'google-maps'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  mixins: [mixin],
  components: {
    PPagination,
    vSelect,
    History,
    'el-date-picker': DatePicker
  },
  data () {
    return {
      componentKey: 0,
      showSearchCondition: true,
      draggable: true,
      modalWidth: 600,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        search_year: '',
        search_month: '',
        work_schedule_code: ''
      },
      isCurrentMonth: false,
      showOperation: true,
      showTargetDate: true,
      showWorkSchedule: true,
      showEmployee: true,
      showStampingStartTime: true,
      showStampingEndTime: true,
      showStartTime: true,
      showEndTime: true,
      showSmileMark: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      geocoder: {},
      tableStyle: '',
      searchYear: '',
      searchMonth: null,
      google: null,
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    // 当月チェック
    isCurrentMonthClicked () {
      if (!this.isCurrentMonth) {
        this.searchCond.search_year = this.searchYear
        this.searchCond.search_month = this.searchMonth
      }
    },
    // URL作成
    createURL (lat, lng) {
      // if (address === '') {
      //   return
      // }
      window.open('https://www.google.co.jp/maps/place/' + lat + ',' + lng + '/@' + lat + ',' + lng + ',18z?hl=ja/', '_blank')
      // window.open('https://www.google.com/maps/place/' + address, '_blank')
    },
    // 出勤、退勤打刻の幅は項目が存在する場合27、存在しない場合ラベルのバイト数
    getTimeColumnWidth (rows, columnNameValue, columnName1) {
      let maxBytes = this.getBytes(columnNameValue)
      for (const row of rows) {
        if (row[columnName1]) {
          const bytes = this.getBytes(row[columnName1])
          if (bytes > maxBytes) {
            maxBytes = 27
            break
          }
        }
        if (maxBytes === 27) {
          break
        }
      }
      return maxBytes * 8 + 20
    },
    // 休憩開始、休憩終了打刻の幅は項目が存在する場合27、存在しない場合ラベルのバイト数
    getBreakColumnWidth (rows, columnNameValue, columnName1) {
      let maxBytes = this.getBytes(columnNameValue)
      for (const row1 of rows) {
        for (const row of row1.break_time_list) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1])
            if (bytes > maxBytes) {
              maxBytes = 27
              break
            }
          }
        }
        if (maxBytes === 27) {
          break
        }
      }
      return maxBytes * 8 + 20
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    convertSearchCond (searchCond) {
      searchCond.target_month = `${searchCond.search_year}/${('0' + searchCond.search_month).slice(-2)}`
      delete searchCond.search_year
      delete searchCond.search_month
      return searchCond
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      if (reqData.api_area.term_from === null) {
        reqData.api_area.term_from = ''
      }
      if (reqData.api_area.term_to === null) {
        reqData.api_area.term_to = ''
      }
      this.send('/view/timecard/list', reqData)
      .then(res => {
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.timecard_data
        let labels = this.getLabels(res.common_area)
        this.labels = {...this.labels, ...labels}
        let prevTargetDate = ''
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
          // 住所を設定
          row.start_time = row.stamping_start_time
          row.start_address = ''
          row.end_time = row.stamping_end_time
          row.end_address = ''
          for (let index = 0; index < row.break_time_list.length; index++) {
            row.break_time_list[index].break_start_time = row.break_time_list[index].break_start_time
            row.break_time_list[index].break_time_start_address = ''
            row.break_time_list[index].break_end_time = row.break_time_list[index].break_end_time
            row.break_time_list[index].break_time_end_address = ''
          }
          row.smile_mark = this.getLabel(this.getSelectName(row.smile_mark, this.comboData.smile_mark_list))
          row.error_reason = this.getLabel(row.error_reason)
          if (row.target_date === prevTargetDate) {
            row.target_date = '　'
          } else {
            prevTargetDate = row.target_date
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.toggleSearchCondition()
        }
        this.$nextTick(() => {
          let width = 0
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          this.$refs.pager.style.width = width + 'px'
          this.componentKey += 1
        })
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {}
      }
      this.send('/init/timecard/list', data)
      .then(async res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        // 勤務体系リストの先頭にブランクを追加
        this.comboData.work_schedule_list.unshift({'label': '', 'value': ''})
        this.afterInit(res)
        if (!this.google) {
          this.google = await new Loader(process.env.VUE_APP_API_KEY).load()
        }
        this.geocoder = new this.google.maps.Geocoder()
        // 今日日付を設定
        this.searchYear = this.searchCond.search_year = res.api_area.this_month.substring(0, 4)
        this.searchMonth = this.searchCond.search_month = parseInt(res.api_area.this_month.substring(4), 10)
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.time_card_code = row.time_card_code
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.time_card_code = row.time_card_code
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.time_card_code = row.time_card_code
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {orgSearchCond: this.searchCond}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (isChanged) {
      this.$modal.hide('detail')
      if (isChanged) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag) {
        return 'out-row'
      }
      return ''
    },
    // 日付のシンクロ(FROM)
    dateSynchronizeFrom () {
      let from = this.searchCond.term_from
      let to = this.searchCond.term_to
      let today = this.formatToday()
      if (from > today) {
        this.searchCond.term_from = today
      }
      if (to > today) {
        this.searchCond.term_to = today
      }
      if (from > to && to !== '') {
        this.searchCond.term_to = this.searchCond.term_from
      }
    },
    // 日付のシンクロ(TO)
    dateSynchronizeTo () {
      let from = this.searchCond.term_from
      let to = this.searchCond.term_to
      let today = this.formatToday()
      if (from > today) {
        this.searchCond.term_from = today
      }
      if (to > today) {
        this.searchCond.term_to = today
      }
      if (from > to && to !== '') {
        this.searchCond.term_from = to = this.searchCond.term_to
      }
    }
  },
  created () {
    const currentYear = new Date().getFullYear()
    for (let year = currentYear; year >= 2009; year--) {
      this.years.push(String(year))
    }
    this.init()
  }
}
