<template>
  <div class="row" ref="frame">
    <div style="padding-right: 20px;">
      <i class="ti-close pull-right" :title="$t('button.close')" @click="$emit('close')"></i>
    </div>
    <br clear="all"/>
    <div class="col-md-6" style="text-align: center; padding-top: 40px; padding-left: 0;">
      <img src="static/img/report/horizontal.png" width="200" :style="horizontalStyle" @click="select('08-002-001')"/>
    </div>
    <div class="col-md-6" style="text-align: center; padding-left: 0;">
      <img src="static/img/report/vertical.png" width="150" :style="verticalStyle" @click="select('08-002-002')"/>
    </div>
    <div class="col-md-12" style="margin-top: 20px;">
      <button type="button" class="btn btn-sm btn-default" @click="$emit('close', selectedReportCode)" :disabled="!selectedReportCode">
        {{$t('button.print')}}
      </button>
    </div>
  </div>
</template>
<script>
  export default{
    computed: {
      horizontalStyle () {
        return this.selectedReportCode === '08-002-001' ? 'border: 2px solid #666;' : ''
      },
      verticalStyle () {
        return this.selectedReportCode === '08-002-002' ? 'border: 2px solid #666;' : ''
      }
    },
    data () {
      return {
        selectedReportCode: ''
      }
    },
    methods: {
      select (reportCode) {
        this.selectedReportCode = reportCode
      }
    }
  }
</script>
<style>
</style>
