<template>
  <div class="form-group">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]).replace('{item}', itemLabel) }}
          </small>
        </div>
      </div>
      <NameTable
        v-model="value"
        :item-label="itemLabel"
        :placeholder="placeholder"
        :classes="classes"
        :max-length="maxLength"
        :disabled="disabled"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import NameTable from '@/components/UIComponents/Inputs/NameTable'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      NameTable
    },
    props: {
      value: Array,
      rules: String,
      itemLabel: String,
      placeholder: String,
      classes: String,
      maxLength: String,
      disabled: Boolean
    },
    computed: {
      tableClasses () {
        return 'length' + (this.maxLength * 2 + 20)
      }
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>