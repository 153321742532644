import mixin from '@/lib/mixin'
import Tour from '@/components/UIComponents/Inputs/Tour'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'

export default {
  name: 'ShiftDecisionMessageViewMixin',
  mixins: [mixin],
  components: {
    Tour,
    ValidationTextArea
  },
  props: {
    messageViewParams: Object
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      isProcessing: false,
      detail: {},
      messageList: {},
      isDisabled: false,
      loginUserName: '',
      labels: {},
      comboData: {}
    }
  },
  computed: {
    screenCode () {
      return '06-006'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
    },
    // 独自バリデーション用
    customValidate () {
      return true
    },
    // 送信データ調整用
    convertDetail (details) {
    },
    doClose (detail) {
      if (detail != null) {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            this.componentKey += 1
            return
          }
          this.$emit('close', detail)
        })
      } else {
        this.$emit('close', detail)
      }
    },
    init () {
      this.messageList = this.messageViewParams.messageList
      this.detail.message = this.messageViewParams.message
      this.isDisabled = this.messageViewParams.isDisabled
      this.loginUserName = this.messageViewParams.loginUserName
      this.labels = this.messageViewParams.labels
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 320) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 320px);'
          this.$refs.frame.scrollTop = window.innerHeight
        }
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
