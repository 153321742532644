<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <EmployeesSearch
            :orgSearchCond="searchCond"
            :labels="labels"
            :comboData="comboData"
            :disabled="false"
            :show-search-all="true"
            @search="searchClicked"
          />
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageTypes" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div style="color: #C84513; margin-bottom: 5px;" v-for="error in employeeRoundingErrors" v-bind:key="error">
            <small>{{error}}</small>
          </div>
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :max-height="maxHeight"
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployee" width="180" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium; font-weight: bold;">
                    {{props.row.employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                  <div style="text-align: left; line-height: 100%;" v-if="searchCond.search_type!='3'">
                    <small>{{props.row.employee_classification_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='0'">
                    <small>{{props.row.office_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='1'">
                    <small>{{props.row.group_name}}</small>
                  </div>
                  <div style="text-align: left;" v-if="searchCond.search_type=='2'">
                    <small>{{props.row.team_name}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="280" :label="labels.rounding_type">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationSelect
                      v-model="props.row.rounding_type"
                      item-name="rounding_type"
                      :placeholder="$t('label.select_description')"
                      classes="length17"
                      :item-label="labels.rounding_type"
                      :options="comboData.rounding_type_list"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      @change="roundingTypeChanged(props.row)"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="190" :label="labels.rounding_month">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationSelect
                      v-model="props.row.rounding_month"
                      item-name="rounding_month"
                      :placeholder="$t('label.select_description')"
                      classes="length10"
                      :item-label="labels.rounding_month"
                      :options="comboData.rounding_month_list"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      v-show="props.row.rounding_type === '2'"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="190" :label="labels.rounding_term">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationText
                      v-model="props.row.rounding_term"
                      item-name="rounding_term"
                      max-length="2"
                      classes="form-control length2"
                      :item-label="labels.rounding_term"
                      :help-text="$t('label.max_length')"
                      :unit="$t('label.unit_minutes')"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      v-show="props.row.rounding_type === '3'"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="205" :label="labels.rounding_term_over_work">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationText
                      v-model="props.row.rounding_term_over_work"
                      item-name="rounding_term_over_work"
                      max-length="2"
                      classes="form-control length2"
                      :item-label="labels.rounding_term_over_work"
                      :help-text="$t('label.max_length')"
                      :unit="$t('label.unit_minutes')"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      v-show="props.row.rounding_type === '3'"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="250" :label="labels.rounding_job_start.split('　')[1]">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationSelect
                      v-model="props.row.rounding_job_start"
                      item-name="rounding_job_start"
                      :placeholder="$t('label.select_description')"
                      classes="length16"
                      :item-label="labels.rounding_job_start"
                      :options="comboData.rounding_job_start_list"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      v-show="props.row.rounding_type === '3'"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="250" :label="labels.rounding_job_end.split('　')[1]">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <ValidationSelect
                      v-model="props.row.rounding_job_end"
                      item-name="rounding_job_end"
                      :placeholder="$t('label.select_description')"
                      classes="length16"
                      :item-label="labels.rounding_job_end"
                      :options="comboData.rounding_job_end_list"
                      :disabled="labels.update !== 1 || isChanged || props.row.retirement_flag"
                      v-show="props.row.rounding_type === '3'"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
            <template v-if="labels.update === 1">
              <!-- 更新 -->
              <div style="text-align: left; display: inline-block; width: 70px;">
                <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate()">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.update')}}
                </button>
                <br>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import EmployeeRoundingSearchMixin from '@/components/Dashboard/Views/Master/EmployeeRoundingSearchMixin'
  // import swal from 'sweetalert2'

  export default {
    name: 'EmployeeRoundingSearch',
    data () {
      return {
        modalWidth: 400,
        showOperation: false,
        maxHeight: 0,
        employeeRoundingErrors: []
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.rounding_type_list.unshift({ label: this.$t('label.rounding_type_0'), value: '0' })
      },
      convertSearchCond (searchCond) {
        searchCond.employee_group_code = searchCond.group_code
        delete searchCond.group_code
        return searchCond
      },
      afterSearch (res) {
        this.isChanged = false
        if (this.searchCond.search_type === 0) {
          this.showOffice = true
          this.showGroup = false
          this.showTeam = false
        } else if (this.searchCond.search_type === 2) {
          this.showOffice = false
          this.showGroup = false
          this.showTeam = true
        } else {
          this.showOffice = false
          this.showGroup = true
          this.showTeam = false
        }
        this.labels.update = 1
        this.searchedCond = JSON.parse(JSON.stringify(this.searchCond))
        for (const row of this.tableData) {
          if (!row.rounding_type) {
            row.rounding_type = '0'
          } else {
            row.rounding_type = String(row.rounding_type)
          }
          if (row.rounding_month) {
            row.rounding_month = String(row.rounding_month)
          }
          row.rounding_term = String(row.rounding_term)
          row.rounding_term_over_work = String(row.rounding_term_over_work)
          if (row.rounding_job_start) {
            row.rounding_job_start = String(row.rounding_job_start)
          }
          if (row.rounding_job_end) {
            row.rounding_job_end = String(row.rounding_job_end)
          }
        }
        this.$nextTick(() => {
          let width = 0
          if (!this.$refs.table) return
          for (const child of this.$refs.table.$children) {
            if (child.width) {
              width += parseInt(child.width, 10)
            }
          }
          this.tableStyle = 'width: ' + (width + 1) + 'px'
          const maxWidth = window.innerWidth - 360
          if (width > maxWidth) {
            width = maxWidth
            this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
          } else {
            this.tableWrapperStyle = ''
          }
          this.$refs.pager.style.width = width + 'px'
          this.$refs.summary.style.width = width + 'px'
          this.maxHeight = window.innerHeight - 550
          this.componentKey += 1
        })
      },
      roundingTypeChanged (row) {
        row.rounding_month = undefined
        row.rounding_term = undefined
        row.rounding_term_over_work = undefined
        row.rounding_job_start = undefined
        row.rounding_job_end = undefined
        this.tableData = [...this.tableData]
      },
      customValidate () {
        this.employeeRoundingErrors = []
        for (var i = 0; i < this.tableData.length; i++) {
          const employee = this.tableData[i]
          if (employee.rounding_type === '2') {
            if (!employee.rounding_month) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_required_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_month))
            }
          } else if (employee.rounding_type === '3') {
            if (!employee.rounding_term) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_required_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_term))
            } else if (isNaN(employee.rounding_term) || Number(employee.rounding_term) < 1) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_number_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_term))
            }
            if (!employee.rounding_term_over_work) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_required_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_term_over_work))
            } else if (isNaN(employee.rounding_term_over_work) || Number(employee.rounding_term_over_work) < 1) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_number_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_term_over_work))
            }
            if (!employee.rounding_job_start) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_required_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_job_start.split('　')[1]))
            }
            if (!employee.rounding_job_end) {
              this.employeeRoundingErrors.push(this.$t('message.is_item_required_for_row_rounding').replace('{row}', i + 1).replace('{employee}', employee.employee_name).replace('{item}', this.labels.rounding_job_end.split('　')[1]))
            }
          }
        }
        return this.employeeRoundingErrors.length === 0
      },
      doUpdate () {
        this.pageTypes = this.pageType.detail
        let isCustomValid = this.customValidate()
        if (!isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.parentNode.parentNode.parentNode.parentNode.scrollTop = 0
          return
        }
        const employeeRoundingList = []
        for (const employee of this.tableData) {
          const employeeRounding = {
            del_flag: employee.rounding_type === '0',
            employee_code: employee.employee_code,
            rounding_type: employee.rounding_type === '0' ? 1 : parseInt(employee.rounding_type, 10),
            rounding_month: employee.rounding_type === '2' ? parseInt(employee.rounding_month, 10) : null,
            rounding_term: employee.rounding_type === '3' ? parseInt(employee.rounding_term, 10) : 0,
            rounding_term_over_work: employee.rounding_type === '3' ? parseInt(employee.rounding_term_over_work, 10) : 0,
            rounding_job_start: employee.rounding_type === '3' ? parseInt(employee.rounding_job_start, 10) : null,
            rounding_job_end: employee.rounding_type === '3' ? parseInt(employee.rounding_job_end, 10) : null,
            update_count: employee.update_count
          }
          employeeRoundingList.push(employeeRounding)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: this.searchedCond.search_type,
            office_code: this.searchedCond.office_code,
            team_code: this.searchedCond.team_code,
            employee_code: this.searchedCond.employee_code,
            employee_name: this.searchedCond.employee_name,
            employee_classification_code: this.searchedCond.employee_classification_code,
            retirement: this.searchedCond.retirement,
            employee_group_code: this.searchedCond.employee_group_code,
            rounding_of_employee_working_hours: employeeRoundingList
          }
        }
        this.send('/update/rounding_of_employee_working_hours/list', reqData)
        .then(res => {
          if (res.message.message_classification === 1) {
            this.isChanged = true
            this.showSearchCondition = true
          }
          this.pagination.currentPage = 1
          this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.employee_rounding_list
          let labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.labels = {...this.labels, ...labels}
          this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
          this.afterSearch(res)
        }).catch(error => {
          console.error(error)
        })
      }
    },
    mixins: [EmployeeRoundingSearchMixin]
  }
</script>
<style scoped>
</style>
