<template>
<div class='row' style='overflow-y: auto; width : 1300px'>
  <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
  <div class='col-md-12'>
    <form method='#' action='#'>
      <button type='button' class='btn btn-sm btn-default btn-warning' @click='doClose'>閉じる</button>
      <div class='card'>
        <div class='card-header'>
          <h4 class='card-title'>{{pageTitle}}</h4>
        </div>
        <div class='card-content'>
          <table class="table-striped" width="1200px">
            <thead>
              <tr>
                <th rowspan="3" colspan="2">その月の社会保険料控除後の給与等の金額</th>
                <th colspan="8" style="text-align:center">甲</th>
                <th rowspan="3" style="text-align:center">乙</th>
              </tr>
              <tr>
                <th colspan="8" style="text-align:center">扶養親族等の数</th>
              </tr>
              <tr>
                <th width="100px" style="text-align:center">0人</th>
                <th width="100px" style="text-align:center">1人</th>
                <th width="100px" style="text-align:center">2人</th>
                <th width="100px" style="text-align:center">3人</th>
                <th width="100px" style="text-align:center">4人</th>
                <th width="100px" style="text-align:center">5人</th>
                <th width="100px" style="text-align:center">6人</th>
                <th width="100px" style="text-align:center">7人</th>
              </tr>
              <tr>
                <th width="100px" style="text-align:center">以上</th>
                <th width="100px" style="text-align:center">未満</th>
                <th colspan="8" style="text-align:center">税額</th>
                <th width="100px" style="text-align:center">税額</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='tableData in tableData' v-bind="thats_all">
                <td align="right">{{tableData.thats_all}}</td>
                <td align="right">{{tableData.less_than}}</td>
                <td align="right">{{tableData.income_tax_0}}</td>
                <td align="right">{{tableData.income_tax_1}}</td>
                <td align="right">{{tableData.income_tax_2}}</td>
                <td align="right">{{tableData.income_tax_3}}</td>
                <td align="right">{{tableData.income_tax_4}}</td>
                <td align="right">{{tableData.income_tax_5}}</td>
                <td align="right">{{tableData.income_tax_6}}</td>
                <td align="right">{{tableData.income_tax_7}}</td>
                <td align="right">{{tableData.income_tax_x}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import mixin from '@/lib/mixin'
// import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'

export default {
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect,
    ValidationDate,
    ValidationCheckboxes,
    ValidationRadio
  },
  props: {
    mode: Number,
    labels: Object,
    params: Object
  },
  methods: {

    doClose () {
      this.$emit('close', {})
    },
    doDelete () {
      this.submit('delete', this.labels.label_delete_finished, this.labels.label_delete_unfinished)
    },
    created () {}

  },
  data () {
    return {
      id: 0,
      tableData: [
        { thats_all: '88,000', less_than: '89,000', income_tax_0: '130', income_tax_1: '0', income_tax_2: '0', income_tax_3: '0', income_tax_4: '0', income_tax_5: '0', income_tax_6: '0', income_tax_7: '0', income_tax_x: '3,200' },
        { thats_all: '121,000', less_than: '123,000', income_tax_0: '1,850', income_tax_1: '220', income_tax_2: '0', income_tax_3: '0', income_tax_4: '0', income_tax_5: '0', income_tax_6: '0', income_tax_7: '0', income_tax_x: '4,500' },
        { thats_all: '165,000', less_than: '167,000', income_tax_0: '3,550', income_tax_1: '1,930', income_tax_2: '320', income_tax_3: '0', income_tax_4: '0', income_tax_5: '0', income_tax_6: '0', income_tax_7: '0', income_tax_x: '11,100' },
        { thats_all: '239,000', less_than: '242,000', income_tax_0: '6,210', income_tax_1: '4,590', income_tax_2: '2,980', income_tax_3: '1,360', income_tax_4: '0', income_tax_5: '0', income_tax_6: '0', income_tax_7: '0', income_tax_x: '33,400' },
        { thats_all: '251,000', less_than: '254,000', income_tax_0: '6,640', income_tax_1: '5,020', income_tax_2: '3,410', income_tax_3: '1,790', income_tax_4: '170', income_tax_5: '0', income_tax_6: '0', income_tax_7: '0', income_tax_x: '37,500' },
        { thats_all: '317,000', less_than: '320,000', income_tax_0: '9,890', income_tax_1: '7,470', income_tax_2: '5,860', income_tax_3: '4,250', income_tax_4: '2,620', income_tax_5: '1,010', income_tax_6: '0', income_tax_7: '0', income_tax_x: '58,800' },
        { thats_all: '365,000', less_than: '368,000', income_tax_0: '13,810', income_tax_1: '10,580', income_tax_2: '7,820', income_tax_3: '6,210', income_tax_4: '4,580', income_tax_5: '2,970', income_tax_6: '1,360', income_tax_7: '0', income_tax_x: '75,200' },
        { thats_all: '737,000', less_than: '740,000', income_tax_0: '73,080', income_tax_1: '66,610', income_tax_2: '60,150', income_tax_3: '53,670', income_tax_4: '47,210', income_tax_5: '40,750', income_tax_6: '34,270', income_tax_7: '27,810', income_tax_x: '258,300' }
      ]
    }
  }
}
</script>

<style>
::placeholder {
  color: #cfcfca;
  opacity: 1;
  filter: alpha(opacity=100);
}

table thead tr th {
  color: #cccccc;
  background: #3d415b;
  height: 10px
}

table tbody tr td {
  height: 10px;
}
</style>
