import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import Detail from '@/components/Dashboard/Views/Master/TaskDetail'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'TaskSearchMixin',
  mixins: [mixin],
  components: {
    Detail,
    PPagination
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      searchCond: {
        employee_classification_name: ''
      },
      showOperation: true,
      showEmployeeClassificationName: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableStyle: ''
    }
  },
  computed: {
    screenCode () {
      return '03-012'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {},
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    search () {
      const self = this
      const db = this.$firebase.firestore()
      db.collection('companies')
      .doc(this.$store.state.loginUser.companyCode)
      .collection('rooms')
      .get()
      .then((querySnapshot) => {
        const tableData = []
        querySnapshot.forEach((doc) => {
          tableData.push({
            id: doc.id,
            task_name: doc.data().name
          })
        })
        self.tableData = tableData
        self.componentKey += 1
      })
    },
    init () {
      this.labels = {
        entry: 1,
        search: 1,
        preview: 1,
        update: 1,
        delete: 1,
        task_name: 'ルーム名'
      }
    },
    handleView (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.view
      this.$modal.show('detail')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.edit
      this.$modal.show('detail')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.id = row.id
      this.params.task_name = row.task_name
      this.mode = this.modes.delete
      this.$modal.show('detail')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('detail')
    },
    hide (...args) {
      this.$modal.hide('detail')
      if (args[0]) {
        this.search()
      }
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      }
      return ''
    }
  },
  created () {
    this.init()
  }
}
