<template>
  <div class="form-group">
    <!-- <div v-for="row in tableData" v-bind:key="row.role_code">
      <ValidationCheckboxes
        v-model="row.role_authority"
        :item-label="row.role_name"
        :disabled="disabled"
      />
    </div> -->
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="tableData"
              style="width: 1020px;">
      <template>
        <!-- ロール名 -->
        <el-table-column min-width="60" :label="''">
          <template slot-scope="props">
            {{props.row.role_name}}
          </template>
        </el-table-column>
        <!-- 登録 -->
        <el-table-column min-width="40" :label="$t('button.add')" v-if="authority.items && authority.items[0].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[0].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- 更新 -->
        <el-table-column min-width="50" :label="$t('button.update')" v-if="authority.items && authority.items[1].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[1].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- 削除 -->
        <el-table-column min-width="50" :label="$t('button.delete')" v-if="authority.items && authority.items[2].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[2].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- PDF作成 -->
        <el-table-column min-width="60" :label="$t('button.print')" v-if="authority.items && authority.items[3].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[3].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- 検索 -->
        <el-table-column min-width="50" :label="$t('button.search')" v-if="authority.items && authority.items[4].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[4].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- アップロード -->
        <el-table-column min-width="80" :label="$t('button.upload')" v-if="authority.items && authority.items[5].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[5].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- ダウンロード -->
        <el-table-column min-width="80" :label="$t('button.download')" v-if="authority.items && authority.items[6].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[6].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
        <!-- 確認 -->
        <el-table-column min-width="40" :label="$t('button.view')" v-if="authority.items && authority.items[7].value">
          <template slot="header" slot-scope="scope">
            <div class="phase-header tableHeader">
              <div class="phase-header__title">{{scope.column.label}}</div>
            </div>
          </template>
          <template slot-scope="props">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.role_authority.items[7].value" class="gray" :disabled="disabled" />
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>

  </div>
</template>

<script>
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationCheckboxes
    },
    props: {
      value: Array,
      disabled: Boolean,
      authority: {
        type: Object,
        require: false,
        'default': () => ({
          items: [
            { value: true },
            { value: true },
            { value: true },
            { value: true },
            { value: true },
            { value: true },
            { value: true },
            { value: true }
          ]
        })
      }
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>

<style scoped>
</style>