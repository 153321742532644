export default {
  ja: {
    message: {
      no_calc: '計算ボタンを押してから実行してください。',
      required_item_list_error: '{item1}は{item2}の計算で使用されているため設定してください。',
      is_time_for_row: '{row}行目の{item}はhh:mm形式(0:00～24:00)で入力してください。',
      is_time_reverse_for_row: '{row}行目の{item}が反転しています。',
      is_time_all_input_for_row: '{row}行目の{item}は両方の時間を入力してください。',
      duplicate_error: '{item}が重複しています。',
      row_duplicate_error: '{row}行目の{item}が重複しています。',
      remark_max_9rows: '備考は9行以内で入力してください。',
      no_change_password: '現在のパスワードと新しいパスワードが同じです。',
      paidHolidayReferenceCheck: '選択された日数と入社年月日に該当する統一支給日が登録されていません。有給休暇支給ルールの設定をご確認ください。',
      is_item_limit_number_for_row: '{row}行目の{item}は{limit}以下で入力してください。',
      is_default_group_required: '規定部署は1つ選択してください。',
      paidHolidayOver: '有給の最大日数を超えています。',
      group_term_to_warning: '所属期間（終了）が未入力の部署を1件以上入力してください。',
      evacuation_group_duplicate_error: '有効期間外の部署と重複しています。',
      paid_reference_date_error: '{_field_}は未来日を入力してください。',
      employee_code_is_space: '従業員番号に空白が入力されています。',
      employee_code_is_alpha_and_num: '従業員番号に英数字以外が入力されています。',
      employee_paid_confirmation: '{item1}、{item2}を変更すると<br>有給管理データの整合性が取れなくなる可能性があります。',
      basic_pension_number_input_error: '基礎年金番号は数字4桁-数字6桁で入力してください。',
      group_term_to_error: '{group_name}は有効期間外の部署です。',
      group_term_error: '{group_name}の所属期間が有効期間外です。',
      team_term_error: '{team_name}の所属期間が有効期間外です。'
    },
    label: {
      employee_unit_price: '({year}年の労働日で時給を算出)',
      is_hidden_late_time: '遅刻/早退時間非表示フラグ',
      max_9row_and_max_length: '{maxlength}文字、9行目まで入力できます',
      notice_confirmation: 'お知らせ既読状態',
      new_hint: '新しいヒント',
      password_auto_input: 'パスワード未入力状態で入社年月日を設定すると「AA年#月#日」で自動入力されます。',
      coppy_entry: 'コピー登録',
      child_time_leave_days: '育休(日)',
      child_time_leave_hours: '育休(時)',
      force_password_change: 'パスワードの強制変更',
      welfare_pension_salary_insurance_rate: '厚生年金保険料率'
    },
    button: {
      back_to_list_page: '一覧に戻る',
      next_employee: '次の従業員へ',
      back_employee: '前の従業員へ',
      confirmation: '確認',
      add_application_classification: '申請分類追加',
      add_application_form: '申請書追加',
      do_change: '変更する',
      no_change: 'キャンセル'
    },
    placeholder: {
      work_history: '株式会社勤怠 経理部',
      pseudonym_reading: 'きんたい　たろう',
      application_form_code: '0000000001',
      application_form_name: '打刻補正申請',
      table_name: 'ImprintCorrectionApplication',
      screen_code: '04-005'
    },
    help: {
    }
  },
  en: {
    message: {
    },
    label: {
    },
    button: {
    },
    placeholder: {
    },
    help: {
    }
  }
}
