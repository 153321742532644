<template>
  <div>
    <table border>
      <tbody>
        <!-- 0段目 幅調整用 -->
        <tr style="border-top:none; border-right:none; border-left:none;">
          <td width="150"></td>
          <td width="200"></td>
          <td width="120"></td>
          <td width="230"></td>
          <td width="100"></td>
        </tr>
        <!-- 1段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.office_type')}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.business_name')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.business_address') + '（' + $t('label.tel_no') + '）'}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.total_amount_of_workers')}}
            </div>
          </td>
        </tr>
        <!-- 2段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{officeData.industry_small_name}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{officeData.company_office_name}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: left;">
              {{officeData.post_code ? '（〒' + officeData.post_code + '）' : ''}}<br>
              {{'　' + address}}<br>
              <div style="text-align: right;">
                {{officeData.tel ? '（' + $t('label.tel_no') + '：' + officeData.tel + '）' : ''}}
              </div>
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{total_amount_of_workers + $t('label.unit_people')}}
            </div>
          </td>
        </tr>
        <!-- 3段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.business_type')}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.target_amount_of_workers')}}
              <br>
              {{$t('label.over18_workers')}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.working_hours_in_a_week')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.transform_labor_time_term')}}
            </div>
          </td>
        </tr>
        <!-- 4段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{officeData.business_name}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{target_amount_of_workers + $t('label.unit_people')}}
            </div>
            <div style="text-align: center;">
              {{'(' + over18_target_amount_of_workers + $t('label.unit_people') + ')'}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{ officeData.limit_legal_one_weeks_minutes ? String(parseInt(officeData.limit_legal_one_weeks_minutes / 60, 10)) + $t('label.unit_hours') + ('0' + String(officeData.limit_legal_one_weeks_minutes % 60)).slice(-2) + $t('label.unit_minutes') : ''}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_from')"
                rules="required"
                :disabled="disabled"
                v-model="officeData.term_from"
                @input="setTermTo"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_to')"
                rules="required"
                :disabled="true"
                v-model="officeData.term_to"></el-date-picker>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="margin-top: 10px;">
      <div v-show="errMsg">
        <small class="text-danger">
          {{ errMsg }}
        </small>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="show">
        {{$t('button.detail')}}
      </button>
    </div>      
  </div>
</template>

<script>
  import { DatePicker } from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: Object,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object,
      errMsg: String
    },
    computed: {
      address () {
        if (!this.officeData) return ''
        let address = ''
        if (this.officeData.prefectures) {
          address += this.officeData.prefectures
        }
        if (this.officeData.city) {
          address += this.officeData.city
        }
        if (this.officeData.town) {
          address += this.officeData.town
        }
        if (this.officeData.building) {
          address += ' ' + this.officeData.building
        }
        return address
      },
      total_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.employee_total_count) {
              workerCount += row.employee_total_count
            }
          }
        }
        return workerCount
      },
      target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += row.employee_total_count
              break
            }
          }
        }
        return workerCount
      },
      over18_target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += (row.employee_total_count - row.employee_U18_count)
              break
            }
          }
        }
        return workerCount
      }
    },
    data () {
      return {
        officeData: {}
      }
    },
    methods: {
      show () {
        this.$emit('show-schedule', this.officeData)
      },
      setTermTo () {
        const dateFrom = new Date(this.officeData.term_from)
        this.officeData.term_to = new Date(new Date(this.officeData.term_from).setDate(dateFrom.getDate() + 6))
        const schedules = []
        for (let i = 0; i < 7; i++) {
          schedules.push({
            target_date: this.formatDate(new Date(new Date(this.officeData.term_from).setDate(dateFrom.getDate() + i))),
            ground_code: '',
            job_start: '',
            job_end: '',
            break_minutes: ''
          })
        }
        this.officeData.work_schedule_list = schedules
      },
      updateDefaultWorkSchedules (row) {
        if (this.data.default_work_schedule === row.work_schedule_code && !row.selected) {
          this.data.default_work_schedule = ''
        }
        if (!row.selected) {
          row.off_site_deemed_working_hours_flag = false
        }
        const selectedWorkSchedules = []
        for (const workSchedule of this.data.work_schedules) {
          if (workSchedule.selected) {
            selectedWorkSchedules.push(workSchedule)
          }
        }
        this.selectedWorkSchedules = selectedWorkSchedules
      }
    },
    watch: {
      value () {
        this.officeData = this.value
      }
    },
    mounted () {
      this.officeData = this.value
    }
  }
</script>
