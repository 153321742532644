<template>
  <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
    <template v-if="isErrorPositionTop">
      <div v-if="!valid">
        <small class="text-danger">
          {{ errors[0] ? errors[0].indexOf('|') === -1 ? errors[0] : errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
    </template>
    <input 
      type="text" 
      :name="itemName"
      :placeholder="placeholder"
      :class="classes" 
      v-model="itemValue" 
      :maxlength="maxLength"
      :disabled="disabled"
      style="display: inline-block;"
      @blur="blur"
      @focus="focus">{{unit ? '&nbsp;' + unit : ''}}
    <template v-if="!isErrorPositionTop">
      <small class="text-danger" v-show="!valid">
        {{ errors[0] ? errors[0].indexOf('|') === -1 ? errors[0] : errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
      </small>
    </template>
  </ValidationProvider>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      isErrorPositionTop: {
        type: Boolean,
        require: false,
        'default': false
      },
      unit: {
        type: String,
        require: false,
        'default': ''
      }
    },
    data () {
      return {
        itemValue: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      blur () {
        this.$emit('input', this.itemValue)
        this.$emit('blur')
      },
      focus () {
        this.$emit('focus')
      }
    }
  }
</script>

<style scoped>
</style>