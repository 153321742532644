<template>
  <div>
    <table border>
      <tbody>
        <!-- 0段目 幅調整用 -->
        <tr style="border-top:none; border-right:none; border-left:none;">
          <td width="150"></td>
          <td width="100"></td>
          <td width="100"></td>
          <td width="150"></td>
          <td width="10"></td>
          <td width="230"></td>
          <td width="10"></td>
          <td width="250"></td>
        </tr>
        <!-- 1段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.office_type')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.business_name')}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.business_address') + '（' + $t('label.tel_no') + '）'}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.total_amount_of_workers')}}
            </div>
          </td>
        </tr>
        <!-- 2段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{officeData.industry_small_name}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{officeData.company_office_name}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: left;">
              {{officeData.post_code ? '（〒' + officeData.post_code + '）' : ''}}<br>
              {{'　' + address}}<br>
              <div style="text-align: right;">
                {{officeData.tel ? '（' + $t('label.tel_no') + '：' + officeData.tel + '）' : ''}}
              </div>
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{total_amount_of_workers + $t('label.unit_people')}}
            </div>
          </td>
        </tr>
        <!-- 3段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.business_type')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.target_amount_of_workers')}}
              <br>
              {{$t('label.less18_workers')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.transformation_term')}}
              <br>
              {{'（' + $t('label.starting_date') + '）'}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.working_hours_in_a_month')}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.valid_term_of_application')}}
            </div>
          </td>
        </tr>
        <!-- 4段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{officeData.business_name}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{target_amount_of_workers + $t('label.unit_people')}}
            </div>
            <div style="text-align: center;">
              {{'(' + less18_target_amount_of_workers + $t('label.unit_people') + ')'}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{officeData.starting_date}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{ $t('label.other_paper') }}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_from')"
                rules="required"
                :disabled="isReadOnly"
                v-model="officeData.term_from"
                @input="setSchedule"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_to')"
                rules="required"
                :disabled="isReadOnly"
                v-model="officeData.term_to"
                @input="setSchedule"></el-date-picker>
            </div>
          </td>
        </tr>
        <!-- 5段目 -->
        <tr>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.longest_working_hours_in_a_day')}}
            </div>
            <div style="text-align: center;">
              {{$t('label.less18_workers')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_day_hours"
                          @change="longestDayHoursChanged">
                <el-option v-for="hour in hours"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_day_minutes">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
            <div style="text-align: center;">
              <span>(</span>
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_day_hours_less18"
                          @change="longestDayHoursLess18Changed">
                <el-option v-for="hour in hours2"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_day_minutes_less18">
                <el-option v-for="minute in minutes2"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}
              <span>)</span>
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.longest_working_hours_in_a_week')}}
            </div>
            <div style="text-align: center;">
              {{$t('label.less18_workers')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_week_hours"
                          @change="longestWeekHoursChanged">
                <el-option v-for="hour in hours"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_week_minutes">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
            <div style="text-align: center;">
              <span>(</span>
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_week_hours_less18"
                          @change="longestWeekHoursLess18Changed">
                <el-option v-for="hour in hours2"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_week_minutes_less18">
                <el-option v-for="minute in minutes2"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}
              <span>)</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="margin-top: 10px;">
      <div v-show="errMsg">
        <small class="text-danger">
          {{ errMsg }}
        </small>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="show">
        {{$t('button.detail')}}
      </button>
    </div>      
  </div>
</template>

<script>
  import { DatePicker } from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: Object,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object,
      errMsg: String
    },
    computed: {
      address () {
        if (!this.officeData) return ''
        let address = ''
        if (this.officeData.prefectures) {
          address += this.officeData.prefectures
        }
        if (this.officeData.city) {
          address += this.officeData.city
        }
        if (this.officeData.town) {
          address += this.officeData.town
        }
        if (this.officeData.building) {
          address += ' ' + this.officeData.building
        }
        return address
      },
      total_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.employee_total_count) {
              workerCount += row.employee_total_count
            }
          }
        }
        return workerCount
      },
      target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += row.employee_total_count
              break
            }
          }
        }
        return workerCount
      },
      less18_target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += row.employee_U18_count
              break
            }
          }
        }
        return workerCount
      }
    },
    data () {
      return {
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
          '60', '61', '62', '63', '64', '65', '66', '67', '68', '69',
          '70', '71', '72', '73', '74', '75', '76', '77', '78', '79',
          '80', '81', '82', '83', '84', '85', '86', '87', '88', '89',
          '90', '91', '92', '93', '94', '95', '96', '97', '98', '99'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        hours2: [],
        minutes2: [],
        officeData: {},
        isReadOnly: false
      }
    },
    methods: {
      longestDayHoursChanged () {
        if (!this.officeData.longest_day_minutes) {
          this.officeData.longest_day_minutes = '00'
        }
      },
      longestDayHoursLess18Changed () {
        if (!this.officeData.longest_day_minutes_less18) {
          this.officeData.longest_day_minutes_less18 = '00'
        }
      },
      longestWeekHoursChanged () {
        if (!this.officeData.longest_week_minutes) {
          this.officeData.longest_week_minutes = '00'
        }
      },
      longestWeekHoursLess18Changed () {
        if (!this.officeData.longest_week_minutes_less18) {
          this.officeData.longest_week_minutes_less18 = '00'
        }
      },
      show () {
        this.$emit('show-schedule', this.officeData)
      },
      setSchedule () {
        if (!this.officeData.term_from || !this.officeData.term_to) return
        const dateFrom = new Date(this.officeData.term_from)
        const dateTo = new Date(this.officeData.term_to)
        const schedules = []
        const daysCount = (dateTo.getTime() - dateFrom.getTime()) / 86400000
        if (daysCount > 31) {
          swal({
            text: this.$t('message.transformation_month_term_is_out_of_range'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          this.officeData.term_from = null
          this.officeData.term_to = null
          return
        }
        this.officeData.starting_date = this.officeData.term_from
        for (let i = 0; i <= daysCount; i++) {
          schedules.push({
            target_date: this.formatDate(new Date(new Date(this.officeData.term_from).setDate(dateFrom.getDate() + i))),
            ground_code: '',
            job_start: '',
            job_end: '',
            break_minutes: ''
          })
        }
        this.officeData.work_schedule_list = schedules
      },
      updateDefaultWorkSchedules (row) {
        if (this.data.default_work_schedule === row.work_schedule_code && !row.selected) {
          this.data.default_work_schedule = ''
        }
        if (!row.selected) {
          row.off_site_deemed_working_hours_flag = false
        }
        const selectedWorkSchedules = []
        for (const workSchedule of this.data.work_schedules) {
          if (workSchedule.selected) {
            selectedWorkSchedules.push(workSchedule)
          }
        }
        this.selectedWorkSchedules = selectedWorkSchedules
      }
    },
    watch: {
      value () {
        this.officeData = this.value
      }
    },
    created () {
      this.hours2 = ['', ...this.hours]
      this.minutes2 = ['', ...this.minutes]
    },
    mounted () {
      this.officeData = this.value
    }
  }
</script>
