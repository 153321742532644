<template>
  <div>
    <input 
      type="text" 
      :name="itemName"
      :placeholder="placeholder1"
      :class="classes" 
      style="display: inline-block;"
      v-model="startEndText[0]" 
      :maxlength="maxLength"
      :disabled="disabled"
      @keypress.enter="$emit('input', startEndText)">
    <span>～</span>
    <input 
      type="text" 
      :name="itemName"
      :placeholder="placeholder2"
      :class="classes" 
      style="display: inline-block;"
      v-model="startEndText[1]" 
      :maxlength="maxLength"
      :disabled="disabled"
      @blur="$emit('input', startEndText)"
      @keypress.enter="$emit('input', startEndText)">
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      itemName: String,
      maxLength: String,
      classes: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean
    },
    data () {
      return {
        startEndText: ['', '']
      }
    },
    watch: {
      value () {
        this.startEndText = this.value
      }
    },
    mounted () {
      this.startEndText = this.value
    }
  }
</script>
