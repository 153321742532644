<template>
  <div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
              :data="tableData"
              style="width: 600px">

      <el-table-column min-width="100" :label="$t('label.closing_term1')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            {{props.row.left_month}}
          </div>
        </template>

      </el-table-column>
      <el-table-column min-width="150" :label="labels ? labels.vueLabel_payment_due_date : ''">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <el-date-picker min-width="150" v-model="props.row.left_salary_month" type="date" placeholder="2020-01-31" :disabled="disabled"></el-date-picker>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="100" :label="$t('label.closing_term2')">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: center;">
            {{props.row.right_month}}
          </div>
        </template>

      </el-table-column>
      <el-table-column min-width="150" :label="labels ? labels.vueLabel_payment_due_date : ''">
        <template slot="header" slot-scope="scope">
          <div class="phase-header tableHeader">
            <div class="phase-header__title">{{scope.column.label}}</div>
          </div>
        </template>
        <template slot-scope="props">
          <div style="text-align: left;">
            <el-date-picker min-width="150" v-model="props.row.right_salary_month" type="date" placeholder="2020-01-31" :disabled="disabled"></el-date-picker>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: Array,
      itemName: String,
      classes: String,
      labels: Object,
      placeholder: String,
      disabled: Boolean
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    }
  }
</script>
