import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
import ValidationEmployeeText from '@/components/UIComponents/Inputs/ValidationEmployeeText'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
import { DatePicker } from 'element-ui'

export default {
  name: 'LegalRuleDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationSelect,
    ValidationDate,
    ValidationRadio,
    ValidationEmployeeText,
    EmployeeSearch,
    'el-date-picker': DatePicker
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    let self = this
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      copy_legal_rule: '',
      detail: {
        office_code: '',
        starting_date: '',
        term_from: '',
        term_to: '',
        agreement_date: '',
        agreement_parties_job_title_code: '',
        agreement_parties_employee_code: '',
        election_method: '',
        procedure_of_exceed_the_time_limit: '',
        procedure_health_code_1: '',
        procedure_health_code_2: '',
        procedure_health_code_3: '',
        specific_content: '',
        filing_date: '',
        management_parties_job_title_code: '',
        management_parties_employee_code: '',
        labor_standards_inspection_office: '',
        document_style: '',
        legal_rule_list: [],
        agreement_parties_employee: ['', ''],
        management_parties_employee: ['', '']
      },
      office_data_list: [],
      officeData: {
        office_code: '',
        office_name: '',
        industry_small_name: '',
        company_office_name: '',
        post_code: '',
        prefectures: '',
        town: '',
        city: '',
        building: '',
        address: '',
        tel: '',
        business_employee_count: [],
        job_total_minutes: '',
        job_holiday: ''
      },
      legal_overwork: [],
      legal_holiday_overwork: [],
      transformation_time_labor: [],
      limit_overwork: [],
      commonErrors: [],
      legalOverworkErrors: [],
      legalHolidayOverworkErrors: [],
      transformationTimeLaborErrors: [],
      limitOverworkErrors: [],
      office_job_employee_list: [],
      job_employee_list: [],
      employee_list: [],
      labels: {},
      comboData: {},
      legal_rule_list: [],
      res_legal_rule_list: [],
      futureday: {
        disabledDate (date) {
          let termFrom = self.formatDate(self.detail.term_from)
          let termFromDate = new Date(termFrom)
          return date <= termFromDate
        }
      },
      agreementPartiesSelectMethod: {
        value: 1,
        disabled: false,
        items: []
      },
      managementPartiesSelectMethod: {
        value: 1,
        disabled: false,
        items: []
      }
    }
  },
  computed: {
    screenCode () {
      return '07-002'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () {
      this.commonErrors = []
      // 協定の有効期間
      if (!this.detail.term_from) {
        this.commonErrors.push(this.$t('message.field_required').replace('{_field_}', this.$t('label.agreement_validity_period')))
      } else if (!this.detail.term_to) {
        this.commonErrors.push(this.$t('message.field_required').replace('{_field_}', this.$t('label.agreement_validity_period')))
      }
      // 起算日チェック
      if (!this.detail.starting_date) {
        this.commonErrors.push(this.$t('message.field_required').replace('{_field_}', this.$t('label.starting_date')))
      }
      this.legalOverworkErrors = []
      let row = 1
      let businessTypeList = []
      for (const tmp of this.legal_overwork) {
        if (!this.isEmpty(tmp)) {
          if (tmp.business_type !== '') {
            businessTypeList.push(tmp.business_type)
          }
          this.legalOverworkErrors = this.requiredValidate(this.legalOverworkErrors, tmp.reasons_over_work_contents, this.$t('label.reasons_over_work_contents_1'), row, true) // 時間外労働をさせる必要のある具体的事由
          this.legalOverworkErrors = this.requiredValidate(this.legalOverworkErrors, tmp.business_type, this.labels.business_type_ryaku, row, true) // 職種
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.employee_count, this.$t('label.worker_count'), row, true) // 労働者数
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.job_total_minutes, this.$t('label.limit_job_time'), row, false) // 所定労働時間
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_legal_one_day_minutes, this.$t('label.limit_legal_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, true) // 法定労働時間を超える時間数（日）
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_job_one_day_minutes, this.$t('label.limit_job_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, false) // 所定労働時間を超える時間数（日）
          // this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.special_provisions_year_count, this.$t('label.special_provisions_year_count'), row, true) // 限度時間を超えて労働させることができる回数（６回以内に限る。）
          // this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.special_provisions_rate_month, this.$t('label.special_provisions_rate_month') + '(' + this.$t('label.one_month') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（月）
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_legal_one_month_minutes, this.$t('label.limit_legal_one_month_minutes') + '(' + this.$t('label.one_month') + ')', row, true) // 法定労働時間を超える時間数（月）
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_job_one_month_minutes, this.$t('label.limit_job_one_month_minutes') + '(' + this.$t('label.one_month') + ')', row, false) //  所定労働時間を超える時間数（月）
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_legal_one_year_minutes, this.$t('label.limit_legal_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, true) // 法定労働時間を超える時間数（年）
          this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.limit_job_one_year_minutes, this.$t('label.limit_job_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, false) // 所定労働時間を超える時間数（年）
          // this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.special_provisions_rate_year, this.$t('label.special_provisions_rate_year') + '(' + this.$t('label.one_year') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（年）
          // this.legalOverworkErrors = this.numberValidate(this.legalOverworkErrors, tmp.number_of_legal_holidays_allowed_to_work, this.$t('label.number_of_legal_holidays_allowed_to_work'), row, true) // 労働させることができる法定休日の日数
        }
        row++
      }
      // 職種重複チェック
      businessTypeList = businessTypeList.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x)
      })
      if (businessTypeList.length !== 0) {
        this.legalOverworkErrors.push(this.$t('message.business_type_pk_check'))
      }

      this.legalHolidayOverworkErrors = []
      row = 1
      businessTypeList = []
      for (const tmp of this.legal_holiday_overwork) {
        if (!this.isEmpty(tmp)) {
          if (tmp.business_type !== '') {
            businessTypeList.push(tmp.business_type)
          }
          this.legalHolidayOverworkErrors = this.requiredValidate(this.legalHolidayOverworkErrors, tmp.reasons_over_work_contents, this.$t('label.reasons_over_work_contents_2'), row, true) // 時間外労働をさせる必要のある具体的事由
          this.legalHolidayOverworkErrors = this.requiredValidate(this.legalHolidayOverworkErrors, tmp.business_type, this.labels.business_type_ryaku, row, true) // 職種
          this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.employee_count, this.$t('label.worker_count'), row, true) // 労働者数
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_legal_one_day_minutes, this.$t('label.limit_legal_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, true) // 法定労働時間を超える時間数（日）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_job_one_day_minutes, this.$t('label.limit_job_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, false) // 所定労働時間を超える時間数（日）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.special_provisions_year_count, this.$t('label.special_provisions_year_count'), row, true) // 限度時間を超えて労働させることができる回数（６回以内に限る。）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.special_provisions_rate_month, this.$t('label.special_provisions_rate_month') + '(' + this.$t('label.one_month') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（月）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_legal_one_month_minutes, this.$t('label.limit_legal_one_month_minutes') + '(' + this.$t('label.one_month') + ')', row, true) // 法定労働時間を超える時間数（月）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_job_one_month_minutes, this.$t('label.limit_job_one_month_minutes') + '(' + this.$t('label.one_month') + ')', row, false) //  所定労働時間を超える時間数（月）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_legal_one_year_minutes, this.$t('label.limit_legal_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, true) // 法定労働時間を超える時間数（年）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.limit_job_one_year_minutes, this.$t('label.limit_job_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, false) // 所定労働時間を超える時間数（年）
          // this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.special_provisions_rate_year, this.$t('label.special_provisions_rate_year') + '(' + this.$t('label.one_year') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（年）
          this.legalHolidayOverworkErrors = this.numberValidate(this.legalHolidayOverworkErrors, tmp.number_of_legal_holidays_allowed_to_work, this.$t('label.number_of_legal_holidays_allowed_to_work'), row, true) // 労働させることができる法定休日の日数
          // 開始時間hh:mm形式チェック（48:00まで）
          if (!tmp.legal_holiday_job_start.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
            if (tmp.legal_holiday_job_start !== '48:00') {
              this.legalHolidayOverworkErrors.push(this.$t('message.row_start_time_error').replace('{row}', row))
            }
          }
          // 終了時間hh:mm形式チェック（48:00まで）
          if (!tmp.legal_holiday_job_end.match(/^([0-9]|[0-3][0-9]|4[0-7]):([0-5][0-9])$/)) {
            if (tmp.legal_holiday_job_end !== '48:00') {
              this.legalHolidayOverworkErrors.push(this.$t('message.row_end_time_error').replace('{row}', row))
            }
          }
        }
        row++
      }
      // 職種重複チェック
      businessTypeList = businessTypeList.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x)
      })
      if (businessTypeList.length !== 0) {
        this.legalHolidayOverworkErrors.push(this.$t('message.business_type_pk_check'))
      }

      this.transformationTimeLaborErrors = []
      row = 1
      businessTypeList = []
      for (const tmp of this.transformation_time_labor) {
        if (!this.isEmpty(tmp)) {
          if (tmp.business_type !== '') {
            businessTypeList.push(tmp.business_type)
          }
          this.transformationTimeLaborErrors = this.requiredValidate(this.transformationTimeLaborErrors, tmp.reasons_over_work_contents, this.$t('label.reasons_over_work_contents_1'), row, true) // 時間外労働をさせる必要のある具体的事由
          this.transformationTimeLaborErrors = this.requiredValidate(this.transformationTimeLaborErrors, tmp.business_type, this.labels.business_type_ryaku, row, true) // 職種
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.employee_count, this.$t('label.worker_count'), row, true) // 労働者数
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.job_total_minutes, this.$t('label.limit_job_time'), row, false) // 所定労働時間
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_legal_one_day_minutes, this.$t('label.limit_legal_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, true) // 法定労働時間を超える時間数（日）
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_job_one_day_minutes, this.$t('label.limit_job_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, false) // 所定労働時間を超える時間数（日）
          // this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.special_provisions_year_count, this.$t('label.special_provisions_year_count'), row, true) // 限度時間を超えて労働させることができる回数（６回以内に限る。）
          // this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.special_provisions_rate_month, this.$t('label.special_provisions_rate_month') + '(' + this.$t('label.one_month') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（月）
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_legal_one_month_minutes, this.$t('label.limit_legal_one_month_minutes_sp') + '(' + this.$t('label.one_month') + ')', row, true) // 法定労働時間を超える時間数（月）
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_job_one_month_minutes, this.$t('label.limit_job_one_month_minutes_sp') + '(' + this.$t('label.one_month') + ')', row, false) //  所定労働時間を超える時間数（月）
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_legal_one_year_minutes, this.$t('label.limit_legal_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, true) // 法定労働時間を超える時間数（年）
          this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.limit_job_one_year_minutes, this.$t('label.limit_job_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, false) // 所定労働時間を超える時間数（年）
          // this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.special_provisions_rate_year, this.$t('label.special_provisions_rate_year') + '(' + this.$t('label.one_year') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（年）
          // this.transformationTimeLaborErrors = this.numberValidate(this.transformationTimeLaborErrors, tmp.number_of_legal_holidays_allowed_to_work, this.$t('label.number_of_legal_holidays_allowed_to_work'), row, true) // 労働させることができる法定休日の日数
        }
        row++
      }
      // 職種重複チェック
      businessTypeList = businessTypeList.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x)
      })
      if (businessTypeList.length !== 0) {
        this.transformationTimeLaborErrors.push(this.$t('message.business_type_pk_check'))
      }

      this.limitOverworkErrors = []
      row = 1
      businessTypeList = []
      for (const tmp of this.limit_overwork) {
        if (!this.isEmpty(tmp)) {
          if (tmp.business_type !== '') {
            businessTypeList.push(tmp.business_type)
          }
          this.limitOverworkErrors = this.requiredValidate(this.limitOverworkErrors, tmp.reasons_over_work_contents, this.$t('label.reasons_over_work_contents_1'), row, true) // 時間外労働をさせる必要のある具体的事由
          this.limitOverworkErrors = this.requiredValidate(this.limitOverworkErrors, tmp.business_type, this.labels.business_type_ryaku, row, true) // 職種
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.employee_count, this.$t('label.worker_count'), row, true) // 労働者数
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_legal_one_day_minutes, this.$t('label.limit_legal_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, true) // 法定労働時間を超える時間数（日）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_job_one_day_minutes, this.$t('label.limit_job_one_day_minutes') + '(' + this.$t('label.one_day') + ')', row, false) // 所定労働時間を超える時間数（日）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.special_provisions_year_count, this.$t('label.special_provisions_year_count'), row, true) // 限度時間を超えて労働させることができる回数（６回以内に限る。）
          this.limitOverworkErrors = this.numberLimitValidate(this.limitOverworkErrors, tmp.special_provisions_year_count, this.$t('label.special_provisions_year_count'), row, 6) // 限度時間を超えて労働させることができる回数（６回以内に限る。）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_legal_one_month_minutes_all, this.$t('label.limit_legal_one_month_minutes_sp') + '(' + this.$t('label.one_month') + ')', row, true) // 法定労働時間を超える時間数（月）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_job_one_month_minutes, this.$t('label.limit_job_one_month_minutes_sp') + '(' + this.$t('label.one_month') + ')', row, false) //  所定労働時間を超える時間数（月）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.special_provisions_rate_month, this.$t('label.special_provisions_rate_month') + '(' + this.$t('label.one_month') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（月）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_legal_one_year_minutes, this.$t('label.limit_legal_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, true) // 法定労働時間を超える時間数（年）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.limit_job_one_year_minutes, this.$t('label.limit_job_one_year_minutes') + '(' + this.$t('label.one_year') + ')', row, false) // 所定労働時間を超える時間数（年）
          this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.special_provisions_rate_year, this.$t('label.special_provisions_rate_year') + '(' + this.$t('label.one_year') + ')', row, true) // 限度時間を超えた労働に係る割増賃金率（年）
          // this.limitOverworkErrors = this.numberValidate(this.limitOverworkErrors, tmp.number_of_legal_holidays_allowed_to_work, this.$t('label.number_of_legal_holidays_allowed_to_work'), row, true) // 労働させることができる法定休日の日数
        }
        row++
      }
      // 職種重複チェック
      businessTypeList = businessTypeList.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x)
      })
      if (businessTypeList.length !== 0) {
        this.limitOverworkErrors.push(this.$t('message.business_type_pk_check'))
      }

      return this.commonErrors.length === 0 && this.legalOverworkErrors.length === 0 && this.legalHolidayOverworkErrors.length === 0 && this.transformationTimeLaborErrors.length === 0 && this.limitOverworkErrors.length === 0
    },
    // 空チェック
    isEmpty (map) {
      for (let key in map) {
        if (map[key] !== '' && map[key] !== null) {
          return false
        }
      }
      return true
    },
    // 必須チェック
    requiredValidate (errList, value, item, row) {
      if (value === '' || value === null) {
        errList.push(this.$t('message.is_item_required_for_row').replace('{row}', row).replace('{item}', item))
      }
      return errList
    },
    // 数字チェック
    numberValidate (errList, value, item, row, requiredFlag) {
      if (value === '' || value === null) {
        if (requiredFlag) {
          errList.push(this.$t('message.is_item_required_for_row').replace('{row}', row).replace('{item}', item))
        }
      } else if (isNaN(value)) {
        errList.push(this.$t('message.is_item_number_for_row').replace('{row}', row).replace('{item}', item))
      }
      return errList
    },
    // 数字上限チェック
    numberLimitValidate (errList, value, item, row, limit) {
      if (value !== '' && value !== null) {
        if (value > limit) {
          errList.push(this.$t('message.is_item_limit_number_for_row').replace('{row}', row).replace('{item}', item).replace('{limit}', limit))
        }
      }
      return errList
    },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode === this.modes.delete) {
        return detail
      }
      let legalRuleList = []
      // 法定外労働
      if (detail.document_style === '1' || detail.document_style === '2') {
        for (const legalOverwork of this.legal_overwork) {
          if (!this.isEmpty(legalOverwork)) {
            let tmp = JSON.parse(JSON.stringify(legalOverwork))
            tmp.reasons_type = 1
            tmp.employee_count = this.stringToNumber(tmp.employee_count)
            tmp.job_total_minutes = this.hourToMinute(tmp.job_total_minutes)
            tmp.limit_legal_one_day_minutes = this.hourToMinute(tmp.limit_legal_one_day_minutes)
            tmp.limit_job_one_day_minutes = this.hourToMinute(tmp.limit_job_one_day_minutes)
            tmp.special_provisions_year_count = this.stringToNumber(tmp.special_provisions_year_count)
            tmp.special_provisions_rate_month = this.stringToNumber(tmp.special_provisions_rate_month)
            tmp.limit_legal_one_month_minutes = this.hourToMinute(tmp.limit_legal_one_month_minutes)
            tmp.limit_legal_one_month_minutes_all = this.hourToMinute(tmp.limit_legal_one_month_minutes_all)
            tmp.limit_job_one_month_minutes = this.hourToMinute(tmp.limit_job_one_month_minutes)
            tmp.limit_legal_one_year_minutes = this.hourToMinute(tmp.limit_legal_one_year_minutes)
            tmp.limit_job_one_year_minutes = this.hourToMinute(tmp.limit_job_one_year_minutes)
            tmp.special_provisions_rate_year = this.stringToNumber(tmp.special_provisions_rate_year)
            tmp.number_of_legal_holidays_allowed_to_work = this.stringToNumber(tmp.number_of_legal_holidays_allowed_to_work)
            legalRuleList.push(tmp)
          }
        }
      }
      // 休日労働
      if (detail.document_style === '1' || detail.document_style === '2') {
        for (const legalHolidayOverwork of this.legal_holiday_overwork) {
          if (!this.isEmpty(legalHolidayOverwork)) {
            let tmp = JSON.parse(JSON.stringify(legalHolidayOverwork))
            tmp.reasons_type = 2
            tmp.employee_count = this.stringToNumber(tmp.employee_count)
            tmp.limit_legal_one_day_minutes = this.hourToMinute(tmp.limit_legal_one_day_minutes)
            tmp.limit_job_one_day_minutes = this.hourToMinute(tmp.limit_job_one_day_minutes)
            tmp.special_provisions_year_count = this.stringToNumber(tmp.special_provisions_year_count)
            tmp.special_provisions_rate_month = this.stringToNumber(tmp.special_provisions_rate_month)
            tmp.limit_legal_one_month_minutes = this.hourToMinute(tmp.limit_legal_one_month_minutes)
            tmp.limit_legal_one_month_minutes_all = this.hourToMinute(tmp.limit_legal_one_month_minutes_all)
            tmp.limit_job_one_month_minutes = this.hourToMinute(tmp.limit_job_one_month_minutes)
            tmp.limit_legal_one_year_minutes = this.hourToMinute(tmp.limit_legal_one_year_minutes)
            tmp.limit_job_one_year_minutes = this.hourToMinute(tmp.limit_job_one_year_minutes)
            tmp.special_provisions_rate_year = this.stringToNumber(tmp.special_provisions_rate_year)
            tmp.number_of_legal_holidays_allowed_to_work = this.stringToNumber(tmp.number_of_legal_holidays_allowed_to_work)
            tmp.job_total_minutes = 0
            legalRuleList.push(tmp)
          }
        }
      }
      // 変形時間労働
      if (detail.document_style === '1' || detail.document_style === '2') {
        for (const transformationTimeLabor of this.transformation_time_labor) {
          if (!this.isEmpty(transformationTimeLabor)) {
            let tmp = JSON.parse(JSON.stringify(transformationTimeLabor))
            tmp.reasons_type = 3
            tmp.employee_count = this.stringToNumber(tmp.employee_count)
            tmp.limit_legal_one_day_minutes = this.hourToMinute(tmp.limit_legal_one_day_minutes)
            tmp.limit_job_one_day_minutes = this.hourToMinute(tmp.limit_job_one_day_minutes)
            tmp.special_provisions_year_count = this.stringToNumber(tmp.special_provisions_year_count)
            tmp.special_provisions_rate_month = this.stringToNumber(tmp.special_provisions_rate_month)
            tmp.limit_legal_one_month_minutes = this.hourToMinute(tmp.limit_legal_one_month_minutes)
            tmp.limit_legal_one_month_minutes_all = this.hourToMinute(tmp.limit_legal_one_month_minutes_all)
            tmp.limit_job_one_month_minutes = this.hourToMinute(tmp.limit_job_one_month_minutes)
            tmp.limit_legal_one_year_minutes = this.hourToMinute(tmp.limit_legal_one_year_minutes)
            tmp.limit_job_one_year_minutes = this.hourToMinute(tmp.limit_job_one_year_minutes)
            tmp.special_provisions_rate_year = this.stringToNumber(tmp.special_provisions_rate_year)
            tmp.number_of_legal_holidays_allowed_to_work = this.stringToNumber(tmp.number_of_legal_holidays_allowed_to_work)
            legalRuleList.push(tmp)
          }
        }
      }
      // 限度時間超過
      if (detail.document_style === '2') {
        for (const limitOverwork of this.limit_overwork) {
          if (!this.isEmpty(limitOverwork)) {
            let tmp = JSON.parse(JSON.stringify(limitOverwork))
            tmp.reasons_type = 4
            tmp.employee_count = this.stringToNumber(tmp.employee_count)
            tmp.limit_legal_one_day_minutes = this.hourToMinute(tmp.limit_legal_one_day_minutes)
            tmp.limit_job_one_day_minutes = this.hourToMinute(tmp.limit_job_one_day_minutes)
            tmp.special_provisions_year_count = this.stringToNumber(tmp.special_provisions_year_count)
            tmp.special_provisions_rate_month = this.stringToNumber(tmp.special_provisions_rate_month)
            tmp.limit_legal_one_month_minutes = this.hourToMinute(tmp.limit_legal_one_month_minutes)
            tmp.limit_legal_one_month_minutes_all = this.hourToMinute(tmp.limit_legal_one_month_minutes_all)
            tmp.limit_job_one_month_minutes = this.hourToMinute(tmp.limit_job_one_month_minutes)
            tmp.limit_legal_one_year_minutes = this.hourToMinute(tmp.limit_legal_one_year_minutes)
            tmp.limit_job_one_year_minutes = this.hourToMinute(tmp.limit_job_one_year_minutes)
            tmp.special_provisions_rate_year = this.stringToNumber(tmp.special_provisions_rate_year)
            tmp.number_of_legal_holidays_allowed_to_work = this.stringToNumber(tmp.number_of_legal_holidays_allowed_to_work)
            tmp.job_total_minutes = 0
            legalRuleList.push(tmp)
          }
        }
      }
      detail.legal_rule_list = legalRuleList
      detail.document_style = this.stringToNumber(detail.document_style)
      detail.election_method = this.stringToNumber(detail.election_method)
      detail.procedure_health_code_1 = this.stringToNumber(detail.procedure_health_code_1)
      detail.procedure_health_code_2 = this.stringToNumber(detail.procedure_health_code_2)
      detail.procedure_health_code_3 = this.stringToNumber(detail.procedure_health_code_3)

      if (this.agreementPartiesSelectMethod.value === 2) {
        detail.agreement_parties_employee_code = detail.agreement_parties_employee[0]
      }
      delete detail.agreement_parties_employee
      if (this.managementPartiesSelectMethod.value === 2) {
        detail.management_parties_employee_code = detail.management_parties_employee[0]
      }
      delete detail.management_parties_employee

      return detail
    },
    // 事業所を変更時の処理
    officeCodeChanged () {
      for (const officeData of this.office_data_list) {
        if (officeData.office_code === this.detail.office_code) {
          this.officeData.office_code = officeData.office_code
          this.officeData.office_name = officeData.office_name
          this.officeData.industry_small_name = officeData.industry_small_name
          this.officeData.company_office_name = officeData.company_office_name
          this.officeData.post_code = officeData.post_code
          this.officeData.prefectures = officeData.prefectures
          this.officeData.town = officeData.town
          this.officeData.city = officeData.city
          this.officeData.building = officeData.building
          this.officeData.tel = officeData.tel
          this.officeData.business_employee_count = officeData.business_employee_count
          this.officeData.job_total_minutes = officeData.job_total_minutes
          this.officeData.job_holiday = officeData.job_holiday
          officeData.state_name = ''
          for (const row of this.comboData.state_municipality_mapping_list.state_list) {
            if (row.value === officeData.state_code) {
              officeData.state_name = row.label
              break
            }
          }
          officeData.municipality_name = ''
          if (officeData.state_name) {
            for (const row of this.comboData.state_municipality_mapping_list.municipality_list) {
              if (row.value === officeData.state_code + '_' + officeData.municipality_code) {
                officeData.municipality_name = row.label
                break
              }
            }
          }
          this.officeData.address = officeData.state_name + officeData.municipality_name + officeData.town + officeData.building
          break
        }
      }
      this.job_employee_list = []
      for (const officeJobEmploee of this.office_job_employee_list) {
        if (officeJobEmploee.office_code === this.detail.office_code) {
          for (const Jobemployee of officeJobEmploee.job_employee_list[0]) {
            this.job_employee_list.push({ 'value': Jobemployee.job_title_code, 'label': Jobemployee.job_title_name })
          }
          this.employee_list = officeJobEmploee.job_employee_list[0]
          break
        }
      }
    },
    businessTypeChangedLegalOverwork (index) {
      this.legal_overwork[index].employee_count = this.legal_overwork[index].business_type ? this.getBusinessEmployeeCount(this.legal_overwork[index].business_type) : ''
      this.legal_overwork[index].job_total_minutes = this.legal_overwork[index].business_type ? String(this.officeData.job_total_minutes / 60) : ''
    },
    businessTypeChangedLegalHolidayOverwork (index) {
      this.legal_holiday_overwork[index].employee_count = this.legal_holiday_overwork[index].business_type ? this.getBusinessEmployeeCount(this.legal_holiday_overwork[index].business_type) : ''
    },
    businessTypeChangedTransformationTimeLabor (index) {
      this.transformation_time_labor[index].employee_count = this.transformation_time_labor[index].business_type ? this.getBusinessEmployeeCount(this.transformation_time_labor[index].business_type) : ''
      this.transformation_time_labor[index].job_total_minutes = this.transformation_time_labor[index].business_type ? String(this.officeData.job_total_minutes / 60) : ''
    },
    businessTypeChangedLimitOverwork (index) {
      this.limit_overwork[index].employee_count = this.limit_overwork[index].business_type ? this.getBusinessEmployeeCount(this.limit_overwork[index].business_type) : ''
    },
    getBusinessEmployeeCount (businessType) {
      let result = ''
      for (const businessEmployeeCount of this.officeData.business_employee_count) {
        if (businessEmployeeCount.business_type === businessType) {
          result = businessEmployeeCount.employee_U18_count
        }
      }
      return result
    },
    documentStyleChanged () {
      // スクロールバー設定
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
    },
    copyLegalRuleChanged () {
      let officeCode = ''
      let startingDate = ''
      for (const data of this.res_legal_rule_list) {
        if (this.copy_legal_rule === data[0].label) {
          this.detail.office_code = data[0].office_code
          this.detail.document_style = String(data[0].document_style)
          officeCode = data[0].office_code
          startingDate = data[0].starting_date
        }
      }
      // this.officeCodeChanged()
      // this.documentStyleChanged()
      this.init(officeCode, startingDate)
    },
    // 文字列を数値に変換（ブランクの場合null）
    stringToNumber (str) {
      let num = null
      if (str === '' || str === null) {
        return num
      } else if (isNaN(str)) {
        return 'error'
      }
      num = Number(str)
      return num
    },
    // 時間を分に変換（ブランクの場合null）
    hourToMinute (hour) {
      let minute = null
      if (hour === '' || hour === null) {
        return minute
      } else if (isNaN(hour)) {
        return 'error'
      }
      minute = Number(hour) * 60
      return minute
    },
    // 分を時間に変換（ブランクの場合null）
    minuteToHour (minute) {
      let hour = ''
      if (minute === '' || minute === null) {
        return hour
      } else if (isNaN(minute)) {
        return 'error'
      }
      hour = Number(minute) / 60
      return hour
    },
    procedureHealthCode1Changed () {},
    procedureHealthCode2Changed () {},
    procedureHealthCode3Changed () {},
    agreementPartiesJobTitleCodeChanged () {
      this.detail.agreement_parties_employee_code = ''
    },
    agreementPartiesEmployeeCodeChanged () {},
    electionMethodChanged () {},
    managementPartiesJobTitleCodeChanged () {
      this.detail.management_parties_employee_code = ''
    },
    managementPartiesEmployeeCodeChanged () {},

    getEmployeeList (jobTitle) {
      let employeeList = []
      for (const jobEmploee of this.employee_list) {
        if (jobEmploee.job_title_code === jobTitle) {
          for (const employee of jobEmploee.employee_list) {
            employeeList.push({ 'value': employee.employee_code, 'label': employee.employee_name })
          }
          break
        }
      }
      return employeeList
    },
    // 法定外労働テーブル
    addLegalOverworkRow () {
      let row = this.getRow()
      this.legal_overwork.push(row)
    },
    // 休日労働テーブル
    addLegalHolidayOverworkRow () {
      let row = this.getRow()
      this.legal_holiday_overwork.push(row)
    },
    // 変形時間労働テーブル
    addTransformationTimeLaborRow () {
      let row = this.getRow()
      this.transformation_time_labor.push(row)
    },
    // 限度時間超過テーブル
    addLimitOverworkRow () {
      let row = this.getRow()
      this.limit_overwork.push(row)
    },
    getRow () {
      let row = {
        business_type: '',
        reasons_over_work_contents: '',
        employee_count: '',
        job_total_minutes: '',
        limit_legal_one_day_minutes: '',
        limit_job_one_day_minutes: '',
        special_provisions_year_count: '',
        special_provisions_rate_month: '',
        limit_legal_one_month_minutes: '',
        limit_legal_one_month_minutes_all: '',
        limit_job_one_month_minutes: '',
        limit_legal_one_year_minutes: '',
        limit_job_one_year_minutes: '',
        special_provisions_rate_year: '',
        number_of_legal_holidays_allowed_to_work: '',
        legal_holiday_job_start: '',
        legal_holiday_job_end: ''
      }
      return row
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.submit('insert')
    },
    doUpdate () {
      this.submit('update')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {office_code: this.detail.office_code}
          detail.starting_date = this.detail.starting_date
          detail.document_style = Number(this.detail.document_style)
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(this.nullToBlank(detail))
        }
        if (this.copy_legal_rule) {
          delete reqData.api_area.update_count
        }
        this.send(`/${operation}/legal_rule`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init (officeCode, startingDate) {
      let data = {}
      if (officeCode) {
        data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            office_code: officeCode,
            starting_date: startingDate
          }
        }
      } else {
        data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            office_code: this.params.office_code,
            starting_date: this.params.starting_date
          }
        }
      }
      this.send('/init/legal_rule', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.comboData.state_municipality_mapping_list = res.common_area.combo_data.state_municipality_mapping_list

        if (this.legal_rule_list.length === 0) {
          this.res_legal_rule_list = res.common_area.combo_data.legal_rule_list
          for (const data of res.common_area.combo_data.legal_rule_list) {
            let legalRule = {
              label: data[0].label,
              value: data[0].label
            }
            this.legal_rule_list.push(legalRule)
          }
        }

        this.agreementPartiesSelectMethod = {
          value: this.mode === this.modes.add && !officeCode ? 1 : 2,
          disabled: this.isReadOnly,
          items: [
            { label: this.$t('label.select_method1'), value: 1 },
            { label: this.$t('label.select_method2'), value: 2 }
          ]
        }
        this.managementPartiesSelectMethod = {
          value: this.mode === this.modes.add && !officeCode ? 1 : 2,
          disabled: this.isReadOnly,
          items: [
            { label: this.$t('label.select_method1'), value: 1 },
            { label: this.$t('label.select_method2'), value: 2 }
          ]
        }
        // TODO 様式タイプを上書き
        this.comboData.document_style_list = [
          {
            'label': '様式1',
            'value': '1'
          },
          {
            'label': '様式2',
            'value': '2'
          }
        ]
        // ブランクを追加
        this.comboData.business_list.unshift({ 'label': '', 'value': '' })
        this.comboData.procedure_health_list.unshift({ 'label': '', 'value': '' })

        if (res.api_area) {
          this.legal_overwork = []
          this.legal_holiday_overwork = []
          this.transformation_time_labor = []
          this.limit_overwork = []
          for (const entry of Object.entries(res.api_area)) {
            // detailに設定しない
            if (entry[0] === 'office_job_employee_list') {
              if (!officeCode) {
                this.office_job_employee_list = entry[1]
              }
              continue
            } else if (entry[0] === 'office_data') {
              if (!officeCode) {
                this.office_data_list = entry[1]
              }
              continue
            } else if (entry[0] === 'legal_rule_format_list') {
              if (entry[1] === null) {
                continue
              }
              for (const ent of Object.entries(entry[1])) {
                if (ent[0] === 'legal_rule_list') {
                  for (const legalRule of ent[1]) {
                    delete legalRule.company_code
                    delete legalRule.office_code
                    delete legalRule.starting_date
                    delete legalRule.update_count
                    legalRule.employee_count = legalRule.employee_count ? String(legalRule.employee_count) : this.getBusinessEmployeeCount(legalRule.business_type)
                    legalRule.limit_legal_one_day_minutes = this.minuteToHour(legalRule.limit_legal_one_day_minutes)
                    legalRule.limit_job_one_day_minutes = this.minuteToHour(legalRule.limit_job_one_day_minutes)
                    legalRule.limit_legal_one_month_minutes = this.minuteToHour(legalRule.limit_legal_one_month_minutes)
                    legalRule.limit_legal_one_month_minutes_all = this.minuteToHour(legalRule.limit_legal_one_month_minutes_all)
                    legalRule.limit_job_one_month_minutes = this.minuteToHour(legalRule.limit_job_one_month_minutes)
                    legalRule.limit_legal_one_year_minutes = this.minuteToHour(legalRule.limit_legal_one_year_minutes)
                    legalRule.limit_job_one_year_minutes = this.minuteToHour(legalRule.limit_job_one_year_minutes)
                    if (legalRule.reasons_type === 1) {
                      legalRule.job_total_minutes = this.minuteToHour(legalRule.job_total_minutes)
                      this.legal_overwork.push(legalRule)
                    } else if (legalRule.reasons_type === 2) {
                      delete legalRule.job_total_minutes
                      this.legal_holiday_overwork.push(legalRule)
                    } else if (legalRule.reasons_type === 3) {
                      legalRule.job_total_minutes = this.minuteToHour(legalRule.job_total_minutes)
                      this.transformation_time_labor.push(legalRule)
                    } else if (legalRule.reasons_type === 4) {
                      delete legalRule.job_total_minutes
                      this.limit_overwork.push(legalRule)
                    }
                    delete legalRule.reasons_type
                  }
                  continue
                } else if (ent[0] === 'company_code') {
                  continue
                }
                if (typeof ent[1] === 'number') {
                  this.detail[ent[0]] = ent[1] == null ? '' : String(ent[1])
                } else if (typeof this.detail[ent[0]] === 'string') {
                  this.detail[ent[0]] = ent[1] == null ? '' : ent[1]
                } else {
                  this.detail[ent[0]] = ent[1]
                }
              }
            }
          }

          // 事業所情報を設定
          this.officeCodeChanged()

          // 既存の36協定書をコピーして作成の場合
          if (this.copy_legal_rule) {
            this.detail.starting_date = ''
            this.detail.term_from = ''
            this.detail.term_to = ''
            this.detail.agreement_date = ''
            this.detail.filing_date = ''
            // 労働者数、所定労働時間を最新の状態で設定
            for (let i = 0; i < this.legal_overwork.length; i++) {
              this.businessTypeChangedLegalOverwork(i)
            }
            for (let i = 0; i < this.legal_holiday_overwork.length; i++) {
              this.businessTypeChangedLegalHolidayOverwork(i)
            }
            for (let i = 0; i < this.transformation_time_labor.length; i++) {
              this.businessTypeChangedTransformationTimeLabor(i)
            }
            for (let i = 0; i < this.limit_overwork.length; i++) {
              this.businessTypeChangedLimitOverwork(i)
            }
          }
          this.detail.agreement_parties_employee = [this.detail.agreement_parties_employee_code, '']
          this.detail.management_parties_employee = [this.detail.management_parties_employee_code, '']
        }

        // 表示件数になるまで行追加
        while (this.legal_overwork.length < 4) {
          this.addLegalOverworkRow()
        }
        while (this.legal_holiday_overwork.length < 4) {
          this.addLegalHolidayOverworkRow()
        }
        while (this.transformation_time_labor.length < 4) {
          this.addTransformationTimeLaborRow()
        }
        while (this.limit_overwork.length < 8) {
          this.addLimitOverworkRow()
        }

        // 登録以外の場合、協定当事者の職名、協定当事者の従業員番号を設定
        if (this.mode !== this.modes.add || !!officeCode) {
          this.detail.agreement_parties_job_title_code = res.api_area.legal_rule_format_list.agreement_parties_job_title_code
          this.detail.agreement_parties_employee_code = res.api_area.legal_rule_format_list.agreement_parties_employee_code
        }
        // 更新の場合、変更前情報を設定する
        if (this.mode === this.modes.edit) {
          this.detail.office_code_before = this.detail.office_code
          this.detail.starting_date_before = this.detail.starting_date
          this.detail.document_style_before = this.stringToNumber(this.detail.document_style)
        }
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    showAgreementPartiesEmployee () {
      this.$modal.show('agreement_parties_employee')
    },
    hideAgreementPartiesEmployee (selected) {
      if (selected) {
        this.detail.agreement_parties_employee = [selected.employee_code, selected.employee_name]
      }
      this.$modal.hide('agreement_parties_employee')
    },
    agreementPartiesSelectMethodChanged () {
      this.detail.agreement_parties_job_title_code = ''
      this.detail.agreement_parties_employee_code = ''
      this.detail.agreement_parties_employee = ['', '']
    },
    showManagementPartiesEmployee () {
      this.$modal.show('management_parties_employee')
    },
    hideManagementPartiesEmployee (selected) {
      if (selected) {
        this.detail.management_parties_employee = [selected.employee_code, selected.employee_name]
      }
      this.$modal.hide('management_parties_employee')
    },
    managementPartiesSelectMethodChanged () {
      this.detail.management_parties_job_title_code = ''
      this.detail.management_parties_employee_code = ''
      this.detail.management_parties_employee = ['', '']
    }
  },
  created () {
    this.init()
  }
}
