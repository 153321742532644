<template>
  <div :class="frameClass==='' ? 'form-group' : 'form-group ' + frameClass">
    <label>{{ itemLabel }}</label>
    <div>
      <el-date-picker 
        v-model="startEndDate[0]" 
        type="date" 
        :name="itemName"
        :placeholder="placeholder1"
        :disabled="disabled"
        :clearable="clearable"
        :picker-options="pickerOptions"
        @change="fromChanged">
      </el-date-picker>
      <span>～</span>
      <el-date-picker 
        v-model="startEndDate[1]" 
        type="date" 
        :name="itemName"
        :placeholder="placeholder2"
        :disabled="disabled"
        :clearable="clearable"
        :picker-options="pickerOptions"
        @change="toChanged">
      </el-date-picker>{{unit ? '&nbsp;' + unit : ''}}
    </div>
  </div>
</template>

<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker
    },
    props: {
      value: Array,
      itemLabel: String,
      itemName: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      frameClass: {
        type: String,
        require: false,
        'default': ''
      },
      minDate: {
        type: String,
        require: false,
        'default': ''
      },
      maxDate: {
        type: String,
        require: false,
        'default': ''
      },
      clearable: {
        type: Boolean,
        require: false,
        'default': true
      }
    },
    data () {
      let vm = this
      return {
        startEndDate: ['', ''],
        fromDate: Date.now() - 100 * 365 * 24 * 60 * 60 * 1000,
        toDate: Date.now() + 100 * 365 * 24 * 60 * 60 * 1000,
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() < vm.fromDate || time.getTime() > vm.toDate
          }
        }
      }
    },
    methods: {
      fromChanged () {
        this.$emit('input', this.startEndDate)
        this.$emit('fromChange', this.startEndDate)
      },
      toChanged () {
        this.$emit('input', this.startEndDate)
        this.$emit('toChange', this.startEndDate)
      }
    },
    watch: {
      value () {
        if (this.minDate) this.fromDate = new Date(this.minDate)
        if (this.maxDate) this.toDate = new Date(this.maxDate)
        this.startEndDate = this.value
      }
    },
    mounted () {
      if (this.minDate) this.fromDate = new Date(this.minDate)
      if (this.maxDate) this.toDate = new Date(this.maxDate)
      this.startEndDate = this.value
    }
  }
</script>
