<template>
  <div>
    <table border>
      <tbody :key="componentKey">
        <!-- 0段目 幅調整用 -->
        <tr style="border-top:none; border-right:none; border-left:none;">
          <td width="120"></td>
          <td width="20"></td>
          <td width="60"></td>
          <td width="200"></td>
          <td width="20"></td>
          <td width="90"></td>
          <td width="30"></td>
          <td width="50"></td>
          <td width="200"></td>
          <td width="10"></td>
          <td width="80"></td>
          <td width="150"></td>
        </tr>
        <!-- 1段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.office_type')}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.business_name')}}
            </div>
          </td>
          <td colspan="6">
            <div style="text-align: center;">
              {{$t('label.business_address') + '（' + $t('label.tel_no') + '）'}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.total_amount_of_workers')}}
            </div>
          </td>
        </tr>
        <!-- 2段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{officeData.industry_small_name}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{officeData.company_office_name}}
            </div>
          </td>
          <td colspan="6">
            <div style="text-align: left;">
              {{officeData.post_code ? '（〒' + officeData.post_code + '）' : ''}}<br>
              {{'　' + address}}<br>
              <div style="text-align: right;">
                {{officeData.tel ? '（' + $t('label.tel_no') + '：' + officeData.tel + '）' : ''}}
              </div>
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{total_amount_of_workers + $t('label.unit_people')}}
            </div>
          </td>
        </tr>
        <!-- 3段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{$t('label.target_amount_of_workers')}}
              <br>
              {{$t('label.over18_workers')}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.target_period_or_specific_period')}}
              <br>
              {{'（' + $t('label.starting_date') + '）'}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.working_hours_in_a_month')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.average_working_hours_in_a_week')}}
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              {{$t('label.transform_labor_time_term_year')}}
            </div>
          </td>
        </tr>
        <!-- 4段目 -->
        <tr>
          <td>
            <div style="text-align: center;">
              {{target_amount_of_workers + $t('label.unit_people')}}
            </div>
            <div style="text-align: center;">
              {{'(' + over18_target_amount_of_workers + $t('label.unit_people') + ')'}}
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{officeData.starting_date}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.other_paper')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.average_working_hours"
                          @change="averageWorkingHoursChanged">
                <el-option v-for="hour in hours"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.average_working_minutes">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
          </td>
          <td>
            <div style="text-align: center;">
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_from')"
                rules="required"
                :disabled="disabled"
                v-model="officeData.term_from"
                @input="setSchedule"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_to')"
                rules="required"
                :disabled="disabled"
                v-model="officeData.term_to"
                @input="setSchedule"></el-date-picker>
            </div>
          </td>
        </tr>
        <!-- 5段目 -->
        <tr>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.longest_working_hours_in_a_day')}}
            </div>
            <div style="text-align: center;">
              {{$t('label.less18_workers')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_working_day_working_hours"
                          @change="longestWorkingDayWorkingHoursChanged">
                <el-option v-for="hour in (over3month ? hours10 : hours)"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled || (over3month && officeData.longest_working_day_working_hours == '10')"
                          v-model="officeData.longest_working_day_working_minutes"
                          @change="longestWorkingDayWorkingMinutesChanged">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
            <div style="text-align: center;">
              <span>(</span>
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_working_day_working_hours_under_18"
                          @change="longestWorkingDayWorkingHoursUnder18Changed">
                <el-option v-for="hour in (over3month ? hours8 : hours2)"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled || (over3month && officeData.longest_working_day_working_hours_under_18 == '8')"
                          v-model="officeData.longest_working_day_working_minutes_under_18"
                          @change="longestWorkingDayWorkingMinutesUnder18Changed">
                <el-option v-for="minute in minutes2"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}
              <span>)</span>
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.longest_working_hours_in_a_week')}}
            </div>
            <div style="text-align: center;">
              {{$t('label.less18_workers')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_working_week_working_hours"
                          @change="longestWorkingWeekWorkingHoursChanged">
                <el-option v-for="hour in (over3month ? hours52 : hours)"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled || (over3month && officeData.longest_working_week_working_hours == '52')"
                          v-model="officeData.longest_working_week_working_minutes"
                          @change="longestWorkingWeekWorkingMinutesChanged">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
            <div style="text-align: center;">
              <span>(</span>
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.longest_working_week_working_hours_under_18"
                          @change="longestWorkingWeekWorkingHoursUnder18Changed">
                <el-option v-for="hour in (over3month ? hours48 : hours2)"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled || (over3month && officeData.longest_working_week_working_hours_under_18 == '48')"
                          v-model="officeData.longest_working_week_working_minutes_under_18"
                          @change="longestWorkingWeekWorkingMinutesUnder18Changed">
                <el-option v-for="minute in minutes2"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}
              <span>)</span>
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              {{$t('label.total_working_days_in_a_term')}}
            </div>
          </td>
          <td>
            <div class="form-group" style="text-align: center;">
              <ValidationText
                v-model="officeData.total_working_days"
                item-name="total_working_days"
                max-length="3"
                classes="form-control numLength3"
                :placeholder="disabled ? '' : $t('placeholder.limit_legal_one_weeks_minutes')"
                :disabled="disabled"
                :rules="'max_value_if:280,' + over3month"
                :unit="$t('label.unit_days')"
                :is-error-position-top="true"
              />
            </div>
          </td>
        </tr>
        <!-- 6段目 -->
        <tr>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.maximum_consecutive_weeks_with_more_than_48_hours_worked')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.maximum_consecutive_weeks_with_more_than_48_hours_worked">
                <el-option v-for="week in (over3month ? weeks3 : weeks)"
                            class="select-success"
                            :value="week"
                            :label="week"
                            :key="week">
                </el-option>
              </el-select>&nbsp;{{$t('label.unit_weeks')}}&nbsp;
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.target_number_of_consecutive_working_days')}}
            </div>
          </td>
          <td colspan="2">
            <div class="form-group" style="text-align: center;">
              <ValidationText
                v-model="officeData.target_number_of_consecutive_working_days"
                item-name="target_number_of_consecutive_working_days"
                max-length="3"
                classes="form-control numLength3"
                :placeholder="disabled ? '' : $t('placeholder.limit_legal_one_weeks_minutes')"
                :disabled="disabled"
                :rules="'max_value_if:6,' + over3month"
                :unit="$t('label.unit_days_between')"
                :is-error-position-top="true"
              />
            </div>
          </td>
        </tr>
        <!-- 7段目 -->
        <tr>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.weeks_with_more_than_48_hours_worked')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.weeks_with_more_than_48_hours_worked">
                <el-option v-for="week in weeks"
                            class="select-success"
                            :value="week"
                            :label="week"
                            :key="week">
                </el-option>
              </el-select>&nbsp;{{$t('label.unit_weeks')}}&nbsp;
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.special_number_of_consecutive_working_days')}}
            </div>
          </td>
          <td colspan="2">
            <div class="form-group" style="text-align: center;">
              <ValidationText
                v-model="officeData.special_number_of_consecutive_working_days"
                item-name="special_number_of_consecutive_working_days"
                max-length="3"
                classes="form-control numLength3"
                :placeholder="disabled ? '' : $t('placeholder.limit_legal_one_weeks_minutes')"
                :disabled="disabled"
                :rules="'max_value_if:12,' + over3month"
                :unit="$t('label.unit_days_between')"
                :is-error-position-top="true"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table border style="margin-top: 10px;">
      <tbody>
        <!-- 0段目 幅調整用 -->
        <tr style="border-top:none; border-right:none; border-left:none;">
          <td width="120"></td>
          <td width="20"></td>
          <td width="60"></td>
          <td width="200"></td>
          <td width="20"></td>
          <td width="90"></td>
          <td width="30"></td>
          <td width="50"></td>
          <td width="200"></td>
          <td width="10"></td>
          <td width="80"></td>
          <td width="150"></td>
        </tr>
        <!-- 1段目 -->
        <tr>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.old_term')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_from')"
                rules="required"
                :disabled="disabled"
                v-model="officeData.old_term_from"
                @input="checkOldTerm"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker
                type="date"
                value-format="yyyy/MM/dd"
                :placeholder="$t('placeholder.term_to')"
                rules="required"
                :disabled="disabled"
                v-model="officeData.old_term_to"
                @input="checkOldTerm"></el-date-picker>
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.old_longest_working_day_working_time')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.old_longest_working_day_working_hours"
                          @change="oldLongestWorkingDayWorkingHoursChanged">
                <el-option v-for="hour in hours"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.old_longest_working_day_working_minutes">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
          </td>
        </tr>
        <!-- 2段目 -->
        <tr>
          <td colspan="3">
            <div style="text-align: center;">
              {{$t('label.old_longest_working_week_working_time')}}
            </div>
          </td>
          <td colspan="3">
            <div style="text-align: center;">
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.old_longest_working_week_working_hours"
                          @change="oldLongestWorkingWeekWorkingHoursChanged">
                <el-option v-for="hour in hours"
                            class="select-success"
                            :value="hour"
                            :label="hour"
                            :key="hour">
                </el-option>
              </el-select>&nbsp;{{$t('label.hours')}}&nbsp;
              <el-select class="select-success length2"
                          size="large"
                          placeholder=""
                          :disabled="disabled"
                          v-model="officeData.old_longest_working_week_working_minutes">
                <el-option v-for="minute in minutes"
                            class="select-success"
                            :value="minute"
                            :label="minute"
                            :key="minute">
                </el-option>
              </el-select>&nbsp;{{$t('label.minute')}}&nbsp;
            </div>
          </td>
          <td colspan="4">
            <div style="text-align: center;">
              {{$t('label.old_total_working_days')}}
            </div>
          </td>
          <td colspan="2">
            <div style="text-align: center;">
              <input
                type="text"
                class="form-control numLength3"
                style="display: inline-block;"
                :disabled="disabled"
                maxlength="30"
                :placeholder="disabled ? '' : $t('placeholder.limit_legal_one_weeks_minutes')"
                v-model="officeData.old_total_working_days">
              &nbsp;{{$t('label.unit_days')}}&nbsp;
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="margin-top: 10px;">
      <div v-show="errMsg">
        <small class="text-danger">
          {{ errMsg }}
        </small>
      </div>
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="show">
        {{$t('button.detail')}}
      </button>
    </div>      
  </div>
</template>

<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationTextHorizontal'
  import { DatePicker } from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      'el-date-picker': DatePicker
    },
    props: {
      value: Object,
      itemName: String,
      classes: String,
      disabled: Boolean,
      labels: Object,
      errMsg: String
    },
    computed: {
      address () {
        if (!this.officeData) return ''
        let address = ''
        if (this.officeData.prefectures) {
          address += this.officeData.prefectures
        }
        if (this.officeData.city) {
          address += this.officeData.city
        }
        if (this.officeData.town) {
          address += this.officeData.town
        }
        if (this.officeData.building) {
          address += ' ' + this.officeData.building
        }
        return address
      },
      total_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.employee_total_count) {
              workerCount += row.employee_total_count
            }
          }
        }
        return workerCount
      },
      target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += row.employee_total_count
              break
            }
          }
        }
        return workerCount
      },
      over18_target_amount_of_workers () {
        if (!this.officeData) return 0
        let workerCount = 0
        if (this.officeData.business_employee_count) {
          for (const row of this.officeData.business_employee_count) {
            if (row.business_type === this.officeData.business_type) {
              workerCount += row.employee_U18_count
              break
            }
          }
        }
        return workerCount
      }
    },
    data () {
      return {
        componentKey: 0,
        over3month: false,
        weeks: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52'
        ],
        weeks3: [
          '0', '1', '2', '3'
        ],
        hours: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59',
          '60', '61', '62', '63', '64', '65', '66', '67', '68', '69',
          '70', '71', '72', '73', '74', '75', '76', '77', '78', '79',
          '80', '81', '82', '83', '84', '85', '86', '87', '88', '89',
          '90', '91', '92', '93', '94', '95', '96', '97', '98', '99'
        ],
        hours8: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8'
        ],
        hours10: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10'
        ],
        hours52: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52'
        ],
        hours48: [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48'
        ],
        minutes: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
          '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
          '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
          '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
          '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
          '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
        ],
        hours2: [],
        minutes2: [],
        officeData: {},
        isReadOnly: false
      }
    },
    methods: {
      longestWorkingDayWorkingHoursChanged () {
        if (!this.officeData.longest_working_day_working_minutes) {
          this.officeData.longest_working_day_working_minutes = '00'
        } else if (this.over3month && this.officeData.longest_working_day_working_hours === '10') {
          this.officeData.longest_working_day_working_minutes = '00'
        }
      },
      longestWorkingDayWorkingMinutesChanged () {
        this.componentKey++
      },
      longestWorkingDayWorkingHoursUnder18Changed () {
        if (!this.officeData.longest_working_day_working_minutes_under_18) {
          this.officeData.longest_working_day_working_minutes_under_18 = '00'
        } else if (this.over3month && this.officeData.longest_working_day_working_hours_under_18 === '8') {
          this.officeData.longest_working_day_working_minutes_under_18 = '00'
        }
      },
      longestWorkingDayWorkingMinutesUnder18Changed () {
        this.componentKey++
      },
      longestWorkingWeekWorkingHoursChanged () {
        if (!this.officeData.longest_working_week_working_minutes) {
          this.officeData.longest_working_week_working_minutes = '00'
        } else if (this.over3month && this.officeData.longest_working_week_working_hours === '52') {
          this.officeData.longest_working_week_working_minutes = '00'
        }
      },
      longestWorkingWeekWorkingMinutesChanged () {
        this.componentKey++
      },
      longestWorkingWeekWorkingHoursUnder18Changed () {
        if (!this.officeData.longest_working_week_working_minutes_under_18) {
          this.officeData.longest_working_week_working_minutes_under_18 = '00'
        } else if (this.over3month && this.officeData.longest_working_week_working_hours_under_18 === '48') {
          this.officeData.longest_working_week_working_minutes_under_18 = '00'
        }
      },
      longestWorkingWeekWorkingMinutesUnder18Changed () {
        this.componentKey++
      },
      oldLongestWorkingDayWorkingHoursChanged () {
        if (!this.officeData.old_longest_working_day_working_minutes) {
          this.officeData.old_longest_working_day_working_minutes = '00'
        }
      },
      oldLongestWorkingWeekWorkingHoursChanged () {
        if (!this.officeData.old_longest_working_week_working_minutes) {
          this.officeData.old_longest_working_week_working_minutes = '00'
        }
      },
      averageWorkingHoursChanged () {
        if (!this.officeData.average_working_minutes) {
          this.officeData.average_working_minutes = '00'
        }
      },
      show () {
        this.$emit('show-schedule', this.officeData)
      },
      setSchedule () {
        this.over3month = false
        if (!this.officeData.term_from || !this.officeData.term_to) return
        const dateFrom = new Date(this.officeData.term_from)
        const dateTo = new Date(this.officeData.term_to)
        const schedules = []
        const daysCount = (dateTo.getTime() - dateFrom.getTime()) / 86400000
        if (daysCount < 29 || daysCount > 366) {
          swal({
            text: this.$t('message.transformation_year_term_is_out_of_range'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          this.officeData.term_from = null
          this.officeData.term_to = null
          return
        }
        this.setOver3Month()
        this.officeData.starting_date = this.officeData.term_from
        for (let i = 0; i <= daysCount; i++) {
          schedules.push({
            target_date: this.formatDate(new Date(new Date(this.officeData.term_from).setDate(dateFrom.getDate() + i))),
            ground_code: '',
            job_start: '',
            job_end: '',
            break_minutes: ''
          })
        }
        this.officeData.work_schedule_list = schedules
      },
      setOver3Month () {
        const dateFrom = new Date(this.officeData.term_from)
        const dateTo = new Date(this.officeData.term_to)
        const daysCount = (dateTo.getTime() - dateFrom.getTime()) / 86400000
        this.over3month = daysCount > 90
      },
      checkOldTerm () {
        if (!this.officeData.old_term_from || !this.officeData.old_term_to) return
        const dateFrom = new Date(this.officeData.old_term_from)
        const dateTo = new Date(this.officeData.old_term_to)
        const daysCount = (dateTo.getTime() - dateFrom.getTime()) / 86400000
        if (daysCount < 29 || daysCount > 366) {
          swal({
            text: this.$t('message.transformation_year_term_is_out_of_range'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          this.officeData.old_term_from = null
          this.officeData.old_term_to = null
        }
      },
      updateDefaultWorkSchedules (row) {
        if (this.data.default_work_schedule === row.work_schedule_code && !row.selected) {
          this.data.default_work_schedule = ''
        }
        if (!row.selected) {
          row.off_site_deemed_working_hours_flag = false
        }
        const selectedWorkSchedules = []
        for (const workSchedule of this.data.work_schedules) {
          if (workSchedule.selected) {
            selectedWorkSchedules.push(workSchedule)
          }
        }
        this.selectedWorkSchedules = selectedWorkSchedules
      }
    },
    watch: {
      value () {
        this.officeData = this.value
      }
    },
    created () {
      this.hours2 = ['', ...this.hours]
      this.minutes2 = ['', ...this.minutes]
    },
    mounted () {
      this.officeData = this.value
      this.setOver3Month()
    }
  }
</script>
