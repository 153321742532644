<template>
  <ValidationObserver ref="observer">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 330px);" name="holidayFrame" :key="componentKey">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="officeMessage" :placeholders="[pageTitle]"/>
        <div style="margin-bottom: 10px">
          <span>{{$t('label.office_description')}}</span>
        </div>
        <div class="card">
          <div class="card-content">
            <!-- <ValidationTexts
              v-model="detail.office_code_reference_number"
              rules="required"
              classes1="form-control length10"
              :disabled1="false"
              :item-label1="labels.office_code"
              :placeholder1="$t('placeholder.office_code')"
              item-name1="office_code"
              :help-text1="$t('help.office_code')"
              max-length1="10"
              classes2="form-control length4"
              :disabled2="false"
              :item-label2="labels.reference_number"
              :placeholder2="$t('placeholder.reference_number')"
              item-name2="reference_number"
              :help-text2="$t('help.reference_number')"
              max-length2="4"
            /> -->
            <ValidationText
              v-model="detail.office_code"
              item-name="office_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.office_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.office_code')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.office_name"
              item-name="office_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.office_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.office_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationSelect
              v-model="detail.industry_code"
              item-name="industry_code"
              :placeholder="$t('label.select_description')"
              :help-text="$t('label.industry_code_description')"
              classes="length60"
              :item-label="$t('label.industry')"
              :options="comboData.industry_list"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.special_measures"
              rules="required"
              :item-label="labels.special_measures"
              :help-text="$t('help.special_measures')"
              :disabled="isReadOnly"
            />
            <table class="special-measures" style="margin: -20px 0 20px 0;" v-show="detail.special_measures && detail.special_measures.value==='1'">
              <caption>特例措置対象事業場の対象となる業種</caption>
              <tr><td></td><td style="text-align: center;">該当する業種</td></tr>
              <tr><td>商業</td><td>卸売業、小売業、理美容業、倉庫業、駐車場業、不動産管理業、出版業（印刷部門を除く。）その他の商業</td></tr>
              <tr><td>映画・演劇業</td><td>映画の映写、演劇、その他興業の事業（映画製作・ビデオ制作の事業を除く。）</td></tr>
              <tr><td>保健衛生業</td><td>病院、診療所、保育園、老人ホーム等の社会福祉施設、浴場業（個室付浴場業を除く。）、その他の保健衛生業</td></tr>
              <tr><td>接客娯楽業</td><td>旅館、飲食店、ゴルフ場、公園・遊園地、その他の娯楽業</td></tr>
            </table>
            <ValidationRadio
              v-model="detail.regulatory_grace_exclusion"
              rules="required"
              :item-label="labels.regulatory_grace_exclusion"
              :disabled="isReadOnly"
              :has-help="true"
            />
            <ValidationTimes
              v-model="detail.job_total_minutes"
              item-name="job_total_minutes"
              classes="select-success length2"
              rules="required"
              :item-label="labels.job_total_minutes"
              :disabled="isReadOnly"
              :labels="labels"
            />
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationTexts from '@/components/UIComponents/Inputs/ValidationTexts'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationTimes from '@/components/UIComponents/Inputs/ValidationTimes'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationTexts,
      ValidationSelect,
      ValidationSearchSelect,
      ValidationRadio,
      ValidationTimes
    },
    computed: {
      isReadOnly () {
        return false
      },
      isPrimaryKeyReadOnly () {
        return false
      }
    },
    data () {
      return {
        componentKey: 0,
        detail: {
          office_code_reference_number: ['', '']
        },
        labels: {
          office_code: '事業所コード',
          reference_number: '整理番号',
          office_name: '事業所名',
          tel: '電話番号',
          fax: 'ファックス番号',
          industry_code_big: '業種(大分類)',
          industry_code_during: '業種(中分類)',
          industry_code_small: '業種(小分類)',
          job_total_minutes: '所定労働時間'
        },
        comboData: {
          industry_code_big: [],
          industry_code_during: [],
          industry_code_small: []
        },
        office: {
          industry_code_big_before: undefined,
          industry_code_during_before: undefined
        },
        officeMessage: {}
      }
    },
    methods: {
      industryCodeBigChanged () {
        if (this.office.industry_code_big_before) {
          this.detail.industry_code_during = ''
          this.detail.industry_code_small = ''
        }
        this.office.industry_code_big_before = this.detail.industry_code_big
        this.comboData.industry_code_during_list = []
        for (const code of this.comboData.industry_mapping_big_and_during[this.detail.industry_code_big]) {
          for (const entry of this.comboData.industry_code_during_full) {
            if (code === entry.value) {
              this.comboData.industry_code_during_list.push(entry)
            }
          }
        }
        this.componentKey += 1
      },
      industryCodeDuringChanged () {
        if (this.office.industry_code_during_before) {
          this.detail.industry_code_small = ''
        }
        if (this.detail.industry_code_during) {
          this.office.industry_code_during_before = this.detail.industry_code_during
          this.comboData.industry_code_small_list = []
          for (const code of this.comboData.industry_mapping_during_and_small[this.detail.industry_code_during]) {
            for (const entry of this.comboData.industry_code_small_full) {
              if (code === entry.value) {
                this.comboData.industry_code_small_list.push(entry)
              }
            }
          }
        } else {
          this.comboData.industry_code_small = [...this.industry_code_small_full]
        }
        this.componentKey += 1
      },
      industryCodeSmallChanged () {},
      placeCodeChanged (stateCode) {
        this.comboData.industry_list = []
        if (!this.comboData.state_industry_mapping[stateCode]) return
        const industryList = [{label: '', value: ''}]
        for (const industryCode of this.comboData.state_industry_mapping[stateCode]) {
          industryList.push({
            label: this.comboData.state_industry_code_name_list[stateCode + '_' + industryCode][0],
            value: stateCode + '_' + industryCode
          })
        }
        this.comboData.industry_list = industryList
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_office`, request)
        .then(res => {
          this.officeMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          this.comboData.industry_mapping_big_and_during = res.common_area.combo_data.industry_list.industry_mapping_big_and_during
          this.comboData.industry_mapping_during_and_small = res.common_area.combo_data.industry_list.industry_mapping_during_and_small
          this.comboData.industry_code_during_full = [...this.comboData.industry_code_during_list]
          this.comboData.industry_code_small_full = [...this.comboData.industry_code_small_list]
          this.comboData.state_industry_code_name_list = res.common_area.combo_data.state_industry_mapping_list.state_industry_code_name_list
          this.comboData.state_industry_mapping = res.common_area.combo_data.state_industry_mapping_list.state_industry_mapping
          this.comboData.place_state_mapping_list = res.common_area.combo_data.place_state_mapping_list
          const items = []
          for (const row of this.comboData.special_measures_list) {
            items.push({
              id: row.value,
              label: this.getLabel(row.label),
              value: String(row.value)
            })
          }
          items.reverse()
          this.detail.special_measures = {
            value: res.api_area ? String(res.api_area.special_measures) : '2',
            disabled: this.isReadOnly,
            items: items
          }
          this.detail.regulatory_grace_exclusion = {
            value: res.api_area ? res.api_area.regulatory_grace_exclusion ? '1' : '2' : '2',
            disabled: this.isReadOnly,
            items: items
          }
          this.detail.job_total_minutes = '480'
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
