<template>
  <div>
    <div class="text-center" style="font-size: 16px; margin-bottom: 20px;">
      Artemisの利用を始めるにあたり、これまでのコードを変更したい場合、変更できます。<br>
      引き続きCorpusと同じコードを利用する場合はそのまま「次へ」ボタンを押下してください。<br>
      <span style="color: #FF0000;">※コードの変更はArtemis上で後から行う事はできませんのでご注意下さい。</span>
    </div>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 500px);" name="codeFrame">
      <div class="col-md-10 col-md-offset-2" style="margin-bottom: 10px;">
        事業所
      </div>
      <div class="col-md-4 col-md-offset-2">
        <div class="card">
          <div class="card-header">
            変更前
          </div>
          <div class="card-content">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="offices"
                      style="width: 100%">
              <el-table-column width="110" label="事業所コード">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; line-height: 340%;">
                    {{props.row.before.area_cd}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="300" label="事業所名">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; line-height: 340%;">
                    {{props.row.before.area_nm}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <div style="color: red;">{{officeError}}</div>
            変更後
          </div>
          <div class="card-content">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="offices"
                      style="width: 100%">
              <el-table-column width="130" label="事業所コード">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <input 
                    type="text"
                    maxlength="10"
                    class="form-control width110" 
                    v-model="props.row.after.area_cd" 
                    :disabled="false">
                </template>
              </el-table-column>
              <el-table-column width="300" label="事業所名">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; vertical-align: middle;">
                    {{props.row.after.area_nm}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="col-md-10 col-md-offset-2" style="margin-bottom: 10px;">
        勤務体系
      </div>
      <div class="col-md-4 col-md-offset-2">
        <div class="card">
          <div class="card-header">
            変更前
          </div>
          <div class="card-content">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="workSchedules"
                      style="width: 100%">
              <el-table-column width="110" label="勤務体系コード">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; line-height: 340%;">
                    {{props.row.before.work_system_cd}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="300" label="勤務体系名">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; line-height: 340%;">
                    {{props.row.before.work_system_nm}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <div style="color: red;">{{workScheduleError}}</div>
            変更後
          </div>
          <div class="card-content">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-striped"
                      :data="workSchedules"
                      style="width: 100%">
              <el-table-column width="130" label="勤務体系コード">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <input 
                    type="text"
                    maxlength="10"
                    class="form-control width110" 
                    v-model="props.row.after.work_system_cd" 
                    :disabled="false">
                </template>
              </el-table-column>
              <el-table-column width="300" label="勤務体系名">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="form-group" style="display: table-cell; vertical-align: middle;">
                    {{props.row.after.work_system_nm}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      PPagination,
      ValidationText,
      ValidationDate,
      ValidationSelect,
      ValidationRadio
    },
    computed: {
      pagedOfficeData () {
        return this.offices.slice(this.officesFrom, this.officesTo)
      },
      queriedOfficeData () {
        if (!this.searchQuery) {
          this.officesPagination.total = this.offices.length
          return this.pagedOfficeData
        }
        let result = this.offices
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.officesPagination.total = result.length
        return result.slice(this.officesFrom, this.officesTo)
      },
      officesTo () {
        let highBound = this.officesFrom + this.officesPagination.perPage
        if (this.officesPagination.total < highBound) {
          highBound = this.officesTotal
        }
        return highBound
      },
      officesFrom () {
        return this.officesPagination.perPage * (this.officesPagination.currentPage - 1)
      },
      officesTotal () {
        this.officesPagination.total = this.offices.length
        return this.offices.length
      },
      pagedBusinessData () {
        return this.businesses.slice(this.businessesFrom, this.businessesTo)
      },
      queriedBusinessData () {
        if (!this.searchQuery) {
          this.businessesPagination.total = this.businesses.length
          return this.pagedBusinessData
        }
        let result = this.businesses
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.businessesPagination.total = result.length
        return result.slice(this.businessesFrom, this.businessesTo)
      },
      businessesTo () {
        let highBound = this.businessesFrom + this.businessesPagination.perPage
        if (this.businessesPagination.total < highBound) {
          highBound = this.businessesTotal
        }
        return highBound
      },
      businessesFrom () {
        return this.businessesPagination.perPage * (this.businessesPagination.currentPage - 1)
      },
      businessesTotal () {
        this.businessesPagination.total = this.businesses.length
        return this.businesses.length
      },
      pagedWorkScheduleData () {
        return this.workSchedules.slice(this.workSchedulesFrom, this.workSchedulesTo)
      },
      queriedWorkScheduleData () {
        if (!this.searchQuery) {
          this.workSchedulesPagination.total = this.workSchedules.length
          return this.pagedWorkScheduleData
        }
        let result = this.workSchedules
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.workSchedulesPagination.total = result.length
        return result.slice(this.workSchedulesFrom, this.workSchedulesTo)
      },
      workSchedulesTo () {
        let highBound = this.workSchedulesFrom + this.workSchedulesPagination.perPage
        if (this.workSchedulesPagination.total < highBound) {
          highBound = this.workSchedulesTotal
        }
        return highBound
      },
      workSchedulesFrom () {
        return this.workSchedulesPagination.perPage * (this.workSchedulesPagination.currentPage - 1)
      },
      workSchedulesTotal () {
        this.workSchedulesPagination.total = this.workSchedules.length
        return this.workSchedules.length
      }
    },
    data () {
      return {
        componentKey: 0,
        officesPagination: {
          perPageOptions: [25, 50, 100],
          currentPage: 1,
          perPage: 25,
          total: 2
        },
        offices: [],
        officeError: '',
        businessesPagination: {
          perPageOptions: [25, 50, 100],
          currentPage: 1,
          perPage: 25,
          total: 3
        },
        businesses: [],
        businessError: '',
        workSchedulesPagination: {
          perPageOptions: [25, 50, 100],
          currentPage: 1,
          perPage: 25,
          total: 8
        },
        workSchedules: [],
        workScheduleError: '',
        labels: {
          label_search_total: '{total}件中{from}件目から{to}件目までを表示'
        }
      }
    },
    created () {
      let migrationData = JSON.parse(sessionStorage.getItem('migrationData'))
      if (migrationData) {
        this.offices = migrationData.office_info
        this.businesses = migrationData.business_info
        this.workSchedules = migrationData.work_schedule_info
      } else {
        let params = {
          screen_code: '123',
          company_code: sessionStorage.getItem('corpusCompanyCode'),
          employee_code: 'admin'
        }
        this.send('/migration/init/mg_code_system', params)
        .then(res => {
          this.offices = []
          var i = 0
          for (i = 0; i < res.init_data.after_data.office_list.length; i++) {
            let office = {
              before: res.init_data.before_data.office_list[i],
              after: res.init_data.after_data.office_list[i]
            }
            this.offices.push(office)
          }
          this.businesses = []
          for (i = 0; i < res.init_data.after_data.business_list.length; i++) {
            let business = {
              before: res.init_data.before_data.business_list[i],
              after: res.init_data.after_data.business_list[i]
            }
            this.businesses.push(business)
          }
          this.workSchedules = []
          for (i = 0; i < res.init_data.after_data.work_schedule_list.length; i++) {
            let workSchedule = {
              before: res.init_data.before_data.work_schedule_list[i],
              after: res.init_data.after_data.work_schedule_list[i]
            }
            this.workSchedules.push(workSchedule)
          }
        })
      }
      /*
      this.offices = []
      for (var i = 0; i < this.officesPagination.total; i++) {
        let officeData = {
          office_code: ('00' + String(i + 1)).slice(-3),
          office_name: '事業所' + ('00' + String(i + 1)).slice(-3)
        }
        var officeAfter = JSON.parse(JSON.stringify(officeData))
        // var officeAfter = {}
        let office = {
          before: JSON.parse(JSON.stringify(officeData)),
          after: officeAfter
        }
        this.offices.push(office)
      }
      this.businesses = []
      for (var j = 0; j < this.businessesPagination.total; j++) {
        let businessData = {
          business_type: ('00' + String(j + 1)).slice(-3),
          business_name: '職種' + ('00' + String(j + 1)).slice(-3)
        }
        var businessAfter = JSON.parse(JSON.stringify(businessData))
        // var businessAfter = {}
        let business = {
          before: JSON.parse(JSON.stringify(businessData)),
          after: businessAfter
        }
        this.businesses.push(business)
      }
      this.workSchedules = []
      for (var k = 0; k < this.workSchedulesPagination.total; k++) {
        let workScheduleData = {
          work_schedule_code: ('00' + String(k + 1)).slice(-3),
          work_schedule_name: '勤務体系' + ('00' + String(k + 1)).slice(-3)
        }
        var workScheduleAfter = JSON.parse(JSON.stringify(workScheduleData))
        // var workScheduleAfter = {}
        let workSchedule = {
          before: JSON.parse(JSON.stringify(workScheduleData)),
          after: workScheduleAfter
        }
        this.workSchedules.push(workSchedule)
      }
      */
    }
  }
</script>
<style>
</style>
