<template>
  <div ref="frame" :key="componentKey">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div style="display: inline-block;" v-if="comboData.group_list">
      <div class="card" v-if="labels" style="width: 400px; height: 520px; text-align: center;">
        <div class="card-content">
          <img v-if="detail.employee_photo" :src="detail.employee_photo" style="width: 50%; height: 50%; border-radius: 50%;"/>
          <div class="content-value">
            <p><b></b></p>
            <p><b><font size="4">{{detail.employee_name}}</font></b><font size="2">({{detail.employee_code}})</font></P>
            <p>
                <font size="4"><div class="content-label">{{detail.age}}{{labels.vueLabel_unit_age}}</div></font>
                <font size="2">
                  ({{getLabel(getSelectName(detail.sex, comboData.sex_list))}})
                </font>
            </p>
            <p>
              <div v-for="job_title in detail.employee_job_title_list" v-bind:key="job_title">
                {{getLabel(getSelectName(job_title.job_title_code, comboData.job_title_list))}}
              </div>
            </p>
            <p><font size="2">{{detail.mail_address}}</font></P>
          </div>
        </div>
      </div>
      <div class="card" v-if="labels" style="width: 400px; text-align: center;">
        <div class="card-content">
          <table class="basicInfo" style="width: 300px;">
            <!-- 所属 -->
            <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_group}}</div></font></b></caption>
            <!-- 部署 -->
            <tr v-for="group in detail.employee_group_list" v-bind:key="group">
              <td>
                <div class="content-value" style="width: 300px; margin-left: 5px;">
                  {{getLabel(getSelectName(group.group_code, comboData.group_list))}}
                </div>
              </td>
            </tr>
          </table>
          <table class="basicInfo" style="width: 300px;">
            <caption><b><font size="1"></font></b></caption>
            <!-- 事業所 -->
            <tr>
              <td>
                <div class="content-value" style="width: 300px; margin-left: 5px;">
                  {{getLabel(getSelectName(detail.office_code, comboData.office_list))}}
                </div>
              </td>
            </tr>
          </table>
          <table class="basicInfo" style="width: 300px;">
            <caption><b><font size="1"></font></b></caption>
            <!-- チーム -->
            <tr v-for="team in detail.employee_team_list" v-bind:key="team">
              <th>
                <div class="content-label" style="width: 100px; margin-left: 5px;">
                  {{labels.team}}
                </div>
              </th>
            </tr>
            <tr v-for="team in detail.employee_team_list" v-bind:key="team">
              <td>
                <div class="content-value" style="width: 300px; margin-left: 5px;">
                  {{getLabel(getSelectName(team.team_code, comboData.team_list))}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card" v-if="labels" style="width: 400px; text-align: center;">
        <div class="card-content">
          <table class="basicInfo" style="width: 300px;">
            <!-- スキル -->
            <tr>
              <th>
                <div class="content-label" style="width: 100px; margin-left: 5px;">
                  {{labels.event_date}}
                </div>
              </th>
              <th>
                <div class="content-label" style="width: 200px; margin-left: 5px;">
                  {{labels.skill}}
                </div>
              </th>
            </tr>
            <tr v-for="skill in detail.skill_list" v-bind:key="skill">
              <!-- イベント年月日 -->
              <td>
                <div class="content-value" style="margin-left: 5px;">
                  {{skill.event_date}}
                </div>
              </td>
              <!-- スキル -->
              <td>
                <div class="content-value" style="margin-left: 5px;">
                  {{skill.skill}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div style="display: inline-block; vertical-align: top; margin-left: 5px;">
      <table class="noborder" lass="basicInfo" style="width: 1100px;">
        <tr>
          <div style="display: inline-block; vertical-align: top; margin-left: 5px;">
            <div class="card" v-if="labels" style="width: 380px; height: 800px; text-align: center;">
              <div class="card-content">
                <table class="basicInfo" style="width: 250px;">
                  <!-- 基本情報 -->
                  <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_basic_info}}</div></font></b></caption>
                  <!-- 従業員区分 -->
                  <tr>
                    <th>
                      <div class="content-label" style="width: 100px; margin-left: 5px;">
                        {{labels.employeeClassification}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.employee_classification_code, comboData.employee_classification_list))}}
                      </div>
                    </td>
                  </tr>
                  <!-- 採用区分 -->
                  <template v-if="detail.recruitment_category_code && detail.recruitment_category_code != '9999999999'">
                    <tr>
                      <th>
                        <div class="content-label" style="width: 100px; margin-left: 5px;">
                          {{labels.recruitmentCategory}}
                        </div>
                      </th>
                      <td>
                        <div class="content-value" style="width: 150px; margin-left: 5px;">
                          {{getLabel(getSelectName(detail.recruitment_category_code, comboData.recruitment_category_list))}}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!-- 言語 -->
                  <tr>
                    <th>
                      <div class="content-label" style="width: 100px; margin-left: 5px;">
                        {{labels.language}}
                      </div>
                    </th>
                    <td>
                        <div class="content-value" style="width: 150px; margin-left: 5px;">
                          {{getLabel(getSelectName(detail.language, comboData.language_type_list))}}
                        </div>
                    </td>
                  </tr>
                  <!-- 入社年月日 -->
                  <tr>
                    <th>
                      <div class="content-label" style="width: 100px; margin-left: 5px;">
                        {{labels.hire_date}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{detail.hire_date}}
                      </div>
                    </td>
                  </tr>
                  <!-- 契約更新日 -->
                  <tr>
                    <th>
                      <div class="content-label" style="width: 100px; margin-left: 5px;">
                        <font color="#6a5acd">{{labels.contract_renewal_date}}</font>
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{detail.contract_renewal_date}}
                      </div>
                    </td>
                  </tr>
                </table>

                <table class="basicInfo" style="width: 250px;">
                  <caption><b><font size="1"></font></b></caption>
                  <!-- 職名 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.business}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.business_type, comboData.business_list))}}
                      </div>
                    </td>
                  </tr>
                </table>

                <table class="basicInfo" style="width: 250px;">
                  <caption><b></b></caption>
                  <!-- 労働管理 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.attendance_management}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.attendance_management, comboData.attendance_management_list))}}
                      </div>
                    </td>
                  </tr>
                  <!-- 勤怠締日 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.closing}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.closing_code, comboData.closing_list))}}
                      </div>
                    </td>
                  </tr>
                </table>

                <table class="basicInfo" style="width: 250px;">
                  <caption><b></b></caption>
                  <!-- マイナンバー -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.my_number}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 150px; margin-left: 5px;">
                        {{detail.my_number}}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-left: 30px; margin-left: 5px;">
            <div class="card" v-if="labels" style="width: 360px; height: 800px; text-align: center;">
              <div class="card-content">
              <table class="basicInfo" style="width: 300px;">
                <!-- 連絡先 -->
                <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_address}}</div></font></b></caption>
                <!-- 電話番号 -->
                <tr>
                  <th>
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      {{labels.tel}}
                    </div>
                  </th>
                  <td>
                    <div class="content-value" style="width: 170px; margin-left: 5px;">
                      {{detail.tel}}
                    </div>
                  </td>
                </tr>
                <!-- 内線番号 -->
                <tr>
                  <th>
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      {{labels.extension_number}}
                    </div>
                  </th>
                  <td>
                    <div class="content-value" style="width: 170px; margin-left: 5px;">
                      {{detail.extension_number}}
                    </div>
                  </td>
                </tr>
                <!-- 住所 -->
                <tr>
                  <th colspan="2">
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      <!-- 届出住所 -->
                      <div class="content-label">{{labels.vueLabel_title_private_address}}</div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="content-value" style="width: 170px; margin-left: 5px;">  
                      <P>〒{{detail.post_code}}</p>
                      <P>{{detail.prefectures}}</p>
                      <P>{{detail.city}}{{detail.town}}</p>
                      <P>{{detail.building}}</p>
                    </div>
                  </td>
                </tr>
                <!-- 電話番号 -->
                <tr>
                  <th>
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      {{labels.tel}}
                    </div>
                  </th>
                  <td>
                    <div class="content-value" style="width: 170px; margin-left: 5px;">
                      {{detail.worker_list_tel}}
                    </div>
                  </td>
                </tr>
                <!-- 緊急連絡先 -->
                <tr>
                  <th>
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      {{labels.emergency_contact}}
                    </div>
                  </th>
                  <td>
                    <div class="content-value" style="width: 170px; margin-left: 5px;">
                      {{detail.emergency_contact}}
                    </div>
                  </td>
                </tr>
              </table>
              <table class="basicInfo" style="width: 300px;">
                <caption><b><font size="3"></font></b></caption>
                <!-- その他 -->
                <tr>
                  <th>
                    <div class="content-value" style="width: 80px; margin-left: 5px;">
                      {{labels.other}}
                    </div>
                  </th>
                  <td>
                    <div class="content-value" style="width: 170px; margin-left: 5px;">
                      <P>{{detail.other}}</p>
                    </div>
                  </td>
                </tr>
              </table>
              </div>
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-left: 30px; margin-left: 5px;">
            <div class="card" v-if="labels" style="width: 350px; height: 800px; text-align: center;">
              <div class="card-content">
                <table class="basicInfo" style="width: 250px;">
                  <!-- 打刻情報 -->
                  <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_wokring_info}}</div></font></b></caption>
                  <!-- feliCa -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.feliCa}}
                      </div>
                    </th>
                  <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{detail.feliCa}}
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="basicInfo" style="width: 250px;">
                  <!-- 給与情報 -->
                  <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_saraly_info}}</div></font></b></caption>
                  <!-- 給与区分 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.salary_category}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.salary_category, comboData.salary_category_list))}}
                      </div>
                    </td>
                  </tr>
                  <!-- レイアウトコード -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.layout_code}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{detail.layout_code}}
                      </div>
                    </td>
                  </tr>
                  <!-- 見込み残業時間 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.estimated_overtime_hours}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        <div class="content-label">{{detail.estimated_overtime_hours}}{{labels.vueLabel_title_time}}</div>
                      </div>
                    </td>
                  </tr>
                  <!-- 単価 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.unit_price}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        <div class="content-label">{{formatNumber(detail.unit_price)}}{{labels.vueLabel_title_yen}}</div>
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="basicInfo" style="width: 250px;">
                  <!-- 有給情報 -->
                  <caption><b><font size="3"><div class="content-label">{{labels.vueLabel_title_paid_leave_info}}</div></font></b></caption>
                  <!-- 有給休暇 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.paid_leave_payment}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{getLabel(getSelectName(detail.paid_leave_payment, comboData.paid_leave_payment_list))}}
                      </div>
                    </td>
                  </tr>
                  <!-- 週の労働日数 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.number_of_working_days_per_week}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        <div class="content-label">{{detail.number_of_working_days_per_week}}{{labels.vueLabel_day_week}}</div>
                      </div>
                    </td>
                  </tr>
                  <!-- 次回有給付与日 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.paid_reference_date}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{detail.paid_reference_date}}
                      </div>
                    </td>
                  </tr>
                  <!-- 一年間で取得可能な時間有給日数 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.available_in_one_year}}
                      </div>
                    </th>
                    <td>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        <div class="content-label">{{labels.vueLabel_remaining}} {{detail.available_in_one_year}}{{labels.vueLabel_day}}</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </tr>
        <tr>
          <div style="display: inline-block; vertical-align: top; margin-left: 5px;">
            <div class="card" v-if="labels" style="width: 1100px; text-align: center;">
              <div class="card-content">
                <table class="basicInfo" style="width: 250px;">
                  <caption><b><font size="3">{{detail.dependent}}</font></b></caption>
                  <!-- 扶養家族 -->
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.dependent_name_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 130px; margin-left: 5px;">
                        {{labels.relationship_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 80px; margin-left: 5px;">
                        {{labels.birthday_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 50px; margin-left: 5px;">
                        {{labels.sex_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 120px; margin-left: 5px;">
                        {{labels.my_number}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 80px; margin-left: 5px;">
                        {{labels.deductible_spouse_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 80px; margin-left: 5px;">
                        {{labels.disability_classification_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 80px; margin-left: 5px;">
                        {{labels.living_together_ryaku}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 80px; margin-left: 5px;">
                        {{labels.dependent_relative_classification_ryaku}}
                      </div>
                    </th>
                  </tr>
                  <tr v-for="dependent in detail.dependent_list" v-bind:key="dependent">
                    <!-- 扶養家族 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{dependent.dependent_name}}
                      </div>
                    </td>
                    <!-- 続柄 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{getLabel(getSelectName(dependent.relationship, comboData.relationship_list))}}
                      </div>
                    </td>
                    <!-- 生年月日 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{dependent.birthday}}
                      </div>
                    </td>
                    <!-- 性別 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{getLabel(getSelectName(dependent.sex, comboData.sex_list))}}
                      </div>
                    </td>
                    <!-- マイナンバー -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{dependent.my_number}}
                      </div>
                    </td>
                    <!-- 配偶者控除 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{dependent.deductible_spouse ? 'あり' : 'なし'}}
                      </div>
                    </td>
                    <!-- 障害者区分 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{getLabel(getSelectName(dependent.disability_classification, comboData.disability_classification_list))}}
                      </div>
                    </td>
                    <!-- 同居区分 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{getLabel(getSelectName(dependent.living_together, comboData.living_together_list))}}
                      </div>
                    </td>
                    <!-- 扶養親族区分 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{getLabel(getSelectName(dependent.dependent_relative_classification, comboData.dependent_relative_classification_list))}}
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="basicInfo" style="width: 200px;">
                  <caption><b><font size="3">{{detail.educational}}</font></b></caption>
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.event_date}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 885px; margin-left: 5px;">
                        {{labels.event}}
                      </div>
                    </th>
                  </tr>
                  <tr v-for="employeeEducationalBackground in detail.employeeEducationalBackground_list" v-bind:key="employeeEducationalBackground">
                    <!-- イベント年月日 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{employeeEducationalBackground.event_date}}
                      </div>
                    </td>
                    <!-- イベント -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{employeeEducationalBackground.event}}
                      </div>
                    </td>
                  </tr>
                </table>
                <table class="basicInfo" style="width: 200px;">
                  <caption><b><font size="3">{{detail.work_history}}</font></b></caption>
                  <tr>
                    <th>
                      <div class="content-value" style="width: 100px; margin-left: 5px;">
                        {{labels.event_date}}
                      </div>
                    </th>
                    <th>
                      <div class="content-value" style="width: 885px; margin-left: 5px;">
                        {{labels.work_history}}
                      </div>
                    </th>
                  </tr>
                  <tr v-for="employeeWorkHistory in detail.employeeWorkHistory_list" v-bind:key="employeeWorkHistory">
                    <!-- イベント年月日 -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{employeeWorkHistory.event_date}}
                      </div>
                    </td>
                    <!-- イベント -->
                    <td>
                      <div class="content-value" style="margin-left: 5px;">
                        {{employeeWorkHistory.work_history}}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </tr>
      </table>
    </div>

  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    name: 'EmployeeInfo',
    mixins: [mixin],
    data () {
      return {
        componentKey: 0,
        type: false,
        labels: {},
        comboData: {},
        detail: {
          append_path: '',
          employee_photo: undefined
        }
      }
    },
    methods: {
      createImage () {
        let self = this
        this.$firebase.storage()
        .ref()
        .child(this.detail.append_path)
        .getDownloadURL()
        .then(function (url) {
          self.detail.employee_photo = url
          self.componentKey += 1
        })
      },
      init () {
        let data = {
          screen_code: 'SS-021',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/employee_info', data)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          if (res.api_area) {
            this.detail = {...res.api_area}
            for (const entry of Object.entries(res.api_area)) {
              if (typeof entry[1] === 'number') {
                this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                this.detail[entry[0]] = entry[1]
              }
            }
            this.createImage()
          }
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .basicInfo {
    border-collapse: collapse; 
    border: solid 1px #DDDDDD; 
    background-color: white;
    vertical-align: middle;
    text-align: left;
  }
  .basicInfo > tr, .basicInfo > tr > th, .basicInfo > tr > td {
    border: solid 1px #DDDDDD;
    padding: 10px;
  }
  .basicInfo > tr > th {
    background-color: #EEEEEE;
  }
</style>
