import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSelectMini from '@/components/UIComponents/Inputs/ValidationSelectMini'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'

export default {
  name: 'CreateBonusPaySlipDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationTextNumber,
    ValidationSelect,
    ValidationSelectMini,
    ValidationTextArea
  },
  data () {
    return {
      mode: 0,
      params: {},
      componentKey: 0,
      id: 0,
      isChanged: false,
      isViewDetail: false,
      detail: {
        target_date: '',
        term_from: '',
        term_to: '',
        salary_category: '',
        is_payment_hand_type: '000000000000000000000000000000',
        is_deduction_hand_type: '000000000000000000000000000000'
      },
      tableData: undefined,
      labels: {},
      comboData: {},
      fixed_fluctuation_mapping_list: [{}],
      updateBeforeDetail: {},
      calcExeFlg: false
    }
  },
  computed: {
    screenCode () {
      return '02-002'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    },
    isModified () {
      let detail = null
      detail = {...this.detail}
      detail = this.convertDetail(detail)
      delete detail.layout_code
      delete this.updateBeforeDetail.layout_code
      return JSON.stringify(detail) !== JSON.stringify(this.updateBeforeDetail)
    }
  },
  methods: {
    isPaymentHandType (index) {
      return this.detail.is_payment_hand_type.substring(index - 1, index) === '1'
    },
    updatePaymentHandType (index, value) {
      if (index === 1) {
        this.detail.is_payment_hand_type = value + this.detail.is_payment_hand_type.substring(1)
      } else if (index === this.detail.is_payment_hand_type.length) {
        this.detail.is_payment_hand_type = this.detail.is_payment_hand_type.substring(0, index - 1) + value
      } else {
        this.detail.is_payment_hand_type = this.detail.is_payment_hand_type.substring(0, index - 1) + value + this.detail.is_payment_hand_type.substring(index)
      }
      this.detail = {...this.detail}
      if (value === '0') {
        this.calc()
      }
    },
    isDeductionHandType (index) {
      return this.detail.is_deduction_hand_type.substring(index - 1, index) === '1'
    },
    updateDeductionHandType (index, value) {
      if (index === 1) {
        this.detail.is_deduction_hand_type = value + this.detail.is_deduction_hand_type.substring(1)
      } else if (index === this.detail.is_deduction_hand_type.length) {
        this.detail.is_deduction_hand_type = this.detail.is_deduction_hand_type.substring(0, index - 1) + value
      } else {
        this.detail.is_deduction_hand_type = this.detail.is_deduction_hand_type.substring(0, index - 1) + value + this.detail.is_deduction_hand_type.substring(index)
      }
      this.detail = {...this.detail}
      if (value === '0') {
        this.calc()
      }
    },
    // 支払額1~10
    payment_salary_item01 () {
      let result = [{}]
      let n = 1
      while (n <= 10) {
        // カンマ区切り設定
        this.formatComma('payment_salary_item_code' + n + '_amount')
        result[0]['payment_salary_item_code' + n] = this.detail['payment_salary_item_code' + n]
        result[0]['payment_salary_item_name' + n] = this.detail['payment_salary_item_name' + n]
        n++
      }
      return result
    },
    // 支払額11~20
    payment_salary_item11 () {
      let result = [{}]
      let n = 11
      while (n <= 20) {
        // カンマ区切り設定
        this.formatComma('payment_salary_item_code' + n + '_amount')
        result[0]['payment_salary_item_code' + n] = this.detail['payment_salary_item_code' + n]
        result[0]['payment_salary_item_name' + n] = this.detail['payment_salary_item_name' + n]
        n++
      }
      return result
    },
    // 控除額1~10
    deduction_salary_item01 () {
      let result = [{}]
      let n = 1
      while (n <= 10) {
        // カンマ区切り設定
        this.formatComma('deduction_salary_item_code' + n + '_amount')
        result[0]['deduction_salary_item_code' + n] = this.detail['deduction_salary_item_code' + n]
        result[0]['deduction_salary_item_name' + n] = this.detail['deduction_salary_item_name' + n]
        n++
      }
      return result
    },
    // 控除額11~20
    deduction_salary_item11 () {
      let result = [{}]
      let n = 11
      while (n <= 20) {
        // カンマ区切り設定
        this.formatComma('deduction_salary_item_code' + n + '_amount')
        result[0]['deduction_salary_item_code' + n] = this.detail['deduction_salary_item_code' + n]
        result[0]['deduction_salary_item_name' + n] = this.detail['deduction_salary_item_name' + n]
        n++
      }
      return result
    },
    // 合計1
    payslipTotalTableBase () {
      return [{dummy: 'dummy'}]
    },
    // カンマ付与(detailを書き換えます)
    formatComma (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatNumber(String(this.detail[name]).replaceAll(',', ''))
      }
      return this.detail[name]
    },
    formatTime (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatDispMinute(parseInt(this.detail[name]))
      }
      return this.detail[name]
    },
    // サーバ送信用にnullをブランクに変換する
    servNullToBlank (target, name) {
      if (!target[name]) {
        target[name] = ''
      }
      return target[name]
    },
    // サーバ送信用に数値に変換する
    servInt (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    // サーバ送信用に時刻を10進数に変換する
    servTime (target, name) {
      if (target[name]) {
        const toHour = parseInt(target[name].split(':')[0], 10)
        const toMinute = parseInt(target[name].split(':')[1], 10)
        let ans = toHour * 60 + toMinute
        target[name] = ans
      } else {
        target[name] = null
      }
      return target[name]
    },
    // 固定変動区分が固定項目以外のときにtrueを返す
    // # 1 FIXED 固定項目
    // # 2 FLUCTUATION 変動項目
    // # 3 SYSTEM システム項目
    isNotFixedFluctuation (itemCode) {
      for (const entry of Object.entries(this.fixed_fluctuation_mapping_list)) {
        for (const fixedFluctuation of Object.entries(entry[1])) {
          if (fixedFluctuation[0] === itemCode) {
            return fixedFluctuation[1] !== 1
          }
        }
      }
    },
    showEditIcon (itemCode, canChange) {
      return this.isNotFixedFluctuation(itemCode) && canChange && !this.isReadOnly
    },
    isNotEditable (itemCode, handType) {
      return this.isNotFixedFluctuation(itemCode) && !handType
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      if (this.detail.layout_code) {
        this.isViewDetail = true
      }
      if (res.common_area.combo_data) {
        // 支払額
        this.payment_salary_item_list = res.common_area.combo_data.salary_category_salary_item_mapping_list.payment_salary_item_list
        // 控除額
        this.deduction_salary_item_list = res.common_area.combo_data.salary_category_salary_item_mapping_list.deduction_salary_item_list
        this.fixed_fluctuation_mapping_list =
        res.common_area.combo_data.salary_category_salary_item_mapping_list.salary_item_code_fixed_fluctuation_mapping_list
      }
      // ラベルの編集
      this.labels.layout_code = this.getLabel(res.common_area.label.Layout)// レイアウト
      this.detail.employee_code = this.params.employee_code
      this.detail.employee_name = this.params.employee_name
      this.detail.target_date = this.params.target_date
      this.detail.bonus_pay_slip_name = this.params.bonus_pay_slip_name
      this.detail.employee_pay_slip_target_date = this.params.employee_pay_slip_target_date
      this.detail.term_from = this.params.term_from
      this.detail.term_to = this.params.term_to

      // 対象額/社会保険額/合計
      this.formatComma('taxable_amount_2') // 所得税対象額
      this.formatComma('social_insurance_amount') // 社会保険対象額
      this.formatComma('employement_insurance_amount') // 雇用保険対象額
      this.formatComma('general_health_insurance') // 健康保険 一般
      this.formatComma('health_insurance_adjustment') // 健康保険 調整
      this.formatComma('care_insurance') // 介護保険
      this.formatComma('social_insurance_total') // 社会保険計
      this.formatComma('total_payment_amount') // 支給額合計
      this.formatComma('total_deduction_amount') // 控除額合計
      this.formatComma('deduction_amount') // 差引支給額
      this.formatComma('first_transfer_amount') // 第一振込先 金額
      this.formatComma('second_transfer_amount') // 第二振込先 金額
      this.formatComma('cash_payment_amount') // 現金支給額
      this.formatComma('in_kind_payment_amount') // 現物支給額

      let detail = null
      detail = {...this.detail}
      this.updateBeforeDetail = this.convertDetail(detail)
    },
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      if (this.mode === this.modes.delete) {
        return detail
      }
      let n = 1
      while (n <= 30) {
        if (!detail['payment_salary_item_code' + n + '_amount']) {
          detail['payment_salary_item_code' + n + '_amount'] = null
        } else {
          detail['payment_salary_item_code' + n + '_amount'] = parseInt(detail['payment_salary_item_code' + n + '_amount'].replaceAll(',', ''))
        }
        if (!detail['deduction_salary_item_code' + n + '_amount']) {
          detail['deduction_salary_item_code' + n + '_amount'] = null
        } else {
          detail['deduction_salary_item_code' + n + '_amount'] = parseInt(detail['deduction_salary_item_code' + n + '_amount'].replaceAll(',', ''))
        }
        n++
      }
      this.servNullToBlank(detail, 'employee_code') //  従業員番号
      this.servNullToBlank(detail, 'employee_name') //  従業員名
      this.servNullToBlank(detail, 'term_to') //  有効期間(終了)
      this.servNullToBlank(detail, 'layout_code') //  レイアウトコード
      this.servInt(detail, 'taxable_amount') //  所得税対象額
      this.servInt(detail, 'taxable_amount_2') //  社会保険料控除後の給与額
      this.servInt(detail, 'employement_insurance_amount') //  雇用保険対象額
      this.servInt(detail, 'social_insurance_amount') //  社会保険対象額
      this.servInt(detail, 'social_insurance_total') //  社会保険計
      this.servInt(detail, 'general_health_insurance') // 健康保険 一般
      this.servInt(detail, 'health_insurance_adjustment') // 健康保険 調整
      this.servInt(detail, 'care_insurance') // 介護保険
      this.servInt(detail, 'total_payment_amount') //  支給額合計
      this.servInt(detail, 'total_deduction_amount') //  控除額合計
      this.servInt(detail, 'deduction_amount') //  差引支給額
      this.servInt(detail, 'first_transfer_amount') //  第一振込先 金額
      this.servInt(detail, 'second_transfer_amount') //  第二振込先 金額
      this.servInt(detail, 'cash_payment_amount') //  現金支給額
      this.servInt(detail, 'in_kind_payment_amount') //  現金支給額
      this.nullToBlank('remark') //  備考

      delete detail.salary_category
      delete detail.date_of_sending_pay_slip
      delete detail.bonus_pay_slip_name
      delete detail.company_code
      delete detail.employee_pay_slip_target_date
      delete detail.electoric_money_amount
      for (var i = 0; i < 30; i++) {
        delete detail[`deduction_salary_item_code${i + 1}_can_change`]
        delete detail[`payment_salary_item_code${i + 1}_can_change`]
      }
      detail.term_from = detail.term_from.replaceAll('/', '').substring(0, 6)
      detail.term_to = detail.term_to.replaceAll('/', '').substring(0, 6)
      return detail
    },
    doBack () {
      this.$router.go(-1)
    },
    doAdd () {
      let detail = null
      detail = {...this.detail}
      detail = this.convertDetail(detail)
      delete detail.layout_code
      delete this.updateBeforeDetail.layout_code
      if (JSON.stringify(detail) !== JSON.stringify(this.updateBeforeDetail)) {
        swal({
          text: this.$t('message.no_calc'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'error',
          reverseButtons: true
        }).then(() => {
        }).catch(() => {})
        return
      }
      this.submit('insert')
    },
    doUpdate () {
      let detail = null
      detail = {...this.detail}
      detail = this.convertDetail(detail)
      delete detail.layout_code
      delete this.updateBeforeDetail.layout_code
      if (JSON.stringify(detail) !== JSON.stringify(this.updateBeforeDetail)) {
        swal({
          text: this.$t('message.no_calc'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'error',
          reverseButtons: true
        }).then(() => {
        }).catch(() => {})
        return
      }
      this.submit('update')
    },
    layout_view () {
      this.view()
    },
    calc () {
      this.calcExeFlg = true
      this.presubmit('calc')
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    presubmit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        this.message.text = ''
        this.isChanged = true
        let detail = null
        let count = parseInt(this.detail.update_count, 10)
        detail = {...this.detail}
        let data = {
          screen_code: '02-002',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        data.api_area.employee_pay_slip_target_date = this.params.employee_pay_slip_target_date
        delete data.api_area.update_count

        this.send('/bonus/calc/employee_bonus_pay_slip', data)
        .then(res => {
          if (res.api_area) {
            for (const entry of Object.entries(res.api_area)) {
              if (typeof entry[1] === 'number') {
                this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                this.detail[entry[0]] = entry[1]
              }
            }
          }
          this.detail.update_count = count
          this.afterInit(res)
          this.$nextTick(() => {
            if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
              this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
            }
            this.isChanged = false
          })
          this.componentKey += 1
        })
        this.componentKey += 1
      })
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        if (this.mode === this.modes.add) {
          detail = {...this.detail}
          delete detail.update_count
        } else if (this.mode === this.modes.edit) {
          detail = {...this.detail}
          detail.update_count = parseInt(this.detail.update_count, 10)
        } else if (this.mode === this.modes.delete) {
          detail = {employee_code: this.detail.employee_code}
          detail.employee_name = this.detail.employee_name
          detail.target_date = this.detail.target_date
          detail.update_count = parseInt(this.detail.update_count, 10)
        }
        let reqData = {
          screen_code: '02-002',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        this.send(`/bonus/${operation}/employee_bonus_pay_slip`, reqData)
        .then(res => {
          this.componentKey += 1
          if (res.message.message_classification === 1) {
            this.isChanged = true
          }
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: '02-002',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.employee_code,
          employee_name: this.params.employee_name,
          target_date: this.params.target_date,
          bonus_pay_slip_name: this.params.bonus_pay_slip_name,
          employee_pay_slip_target_date: this.params.employee_pay_slip_target_date,
          term_from: this.params.term_from
        }
      }
      this.send('/bonus/init/employee_bonus_pay_slip', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    view () {
      this.message.text = ''
      this.isChanged = true
      let data = {
        screen_code: '02-002',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.employee_code,
          employee_name: this.params.employee_name,
          target_date: this.params.target_date,
          bonus_pay_slip_name: this.params.bonus_pay_slip_name,
          employee_pay_slip_target_date: this.params.employee_pay_slip_target_date,
          term_from: this.params.term_from,
          term_to: this.params.term_to,
          layout_code: this.params.layout_code
        }
      }
      this.send('/bonus/view/employee_bonus_pay_slip', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res)
        // レイアウトを選択しました。のメッセージを編集する
        this.message.text = this.$t('label.select_layout').replace('{layout}',
        this.getSelectName(this.detail.layout_code, this.comboData.bonus_layout_list))
        this.isChanged = false
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    if (this.$route.params.params) {
      this.params = this.$route.params.params
      this.mode = this.$route.params.mode
      localStorage.setItem('payslipParams', JSON.stringify(this.$route.params))
    } else {
      const paramsString = localStorage.getItem('payslipParams')
      const params = JSON.parse(paramsString)
      this.params = params.params
      this.mode = params.mode
    }

    if (this.mode === this.modes.add) {
      this.view()
    } else {
      this.init()
    }
  }
}
