<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>

              <div class="col-md-10 col-md-offset-1">
								<div style="font-size: small">
									<span style="font-size:larger;font-weight:bold;">プライバシーポリシー</span><br />
									<br />
									<ul>
										<li>
									株式会社PocketSoft（以下、弊社）は、個人情報保護を弊社の重要な責務と認識し、「Artemis Collier（アルテミスコリエ）」（以下、本サービス）を利用する個人および法人（以下、ユーザ）の個人情報は弊社が定めるプライバシーポリシーに則り、その収集や取り扱いには細心の注意を払います。
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;"> 1. 個人情報について</span>
									<br />
									<br />
									<ul>
										<li>
									個人情報とは
									<br />
									<br />
											<ul>
												<li>
									本サービスにおいて「個人情報」とは、氏名、住所、電話番号、メールアドレス、クレジットカード番号、銀行口座番号など会員個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）を指します。本サービスでは、会員が個人情報を送信することを了承した場合にのみ収集されます。
											<br />
											<br />
												</li>
											</ul>
										</li>
										<li>
									個人情報の収集目的
									<br />
									<br />
											<ul>
												<li>
									本サービスでは、本サービスをご利用いただくにあたって、会員に個人情報を登録していただく場合があります。 ご登録いただいた会員の個人情報は、次の目的のために使用し、その情報は厳重に管理します。
									<br />
									<br />
													<ol>
														<li>
															本サービスの提供・管理・運営のため
														</li>
														<li>
															会員が本サービスを利用するにあたり必要な連絡をするため
														</li>
														<li>
															本サービスの運営を目的とする個人情報が特定されない統計データを収集・作成送付するため
														</li>
														<li>
															お問い合わせに返答するため
														</li>
														<li>
															その他、ユーザの同意を得た目的のため
									<br />
									<br />
														</li>
													</ol>
													上記以外に利用の必要がある場合は、事前にその旨を連絡させて頂きます。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									個人情報の利用
									<br />
									<br />
											<ul>
												<li>
									本サービスが会員の個人情報を利用又は第三者に開示するのは、下記の場合に限ります。
									<br />
									<br />
													<ol>
														<li>
															本サービスを第三者に譲渡した場合。
														</li>
														<li>
															会員と本サービスが契約の当事者になった場合で、当該契約の準備又は履行のため必要な場合。
														</li>
														<li>
															本サービスが従うべき法的義務のため必要な場合。
														</li>
														<li>
															会員の生命・健康・財産等の重大な利益を保護するため必要な場合。
														</li>
														<li>
															公共の利益の保護又は本サービス若しくは個人情報の開示の対象となる第三者の法令に基づく権限行使のため必要な場合。
														</li>
														<li>
															本サービスが、会員の属性の集計、分析を行い、個人が識別・特定できないように加工したもの（以下「統計資料」と称します）を作成し、新規サービスの開発等の業務遂行のために利用、処理する場合。また、本サービスが、統計資料を業務提携先等に提供する場合。
														</li>
														<li>
															会員の利益を侵害しない範囲内において、本サービス及び個人情報の開示の対象となる第三者その他当事者の利益のため必要な場合。
														</li>
														<li>
															申出が、裁判所、検察庁、若しくは行政機関の命令に基づく場合、または警察等の行政機関から捜査権限を定める法令に基づき正式な照会を受けた場合、又は弁護士法23条の2等の法令に基づく申出に伴う場合
														</li>
													</ol>
												</li>
											</ul>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">2. 顔データの収集と使用について</span>
									<br />
									<br />
									<ul>
										<li>
									弊社の顔認証打刻アプリは、カメラを使用して顔の画像を収集する場合があります。この顔データは、顔認識機能を通じてユーザーにパーソナライズされた機能やコンテンツを提供するために利用されます。収集された顔データは、ユーザーのデバイス内で暗号化され、アプリ内でのみ処理されます。第三者との間で顔データを共有することはありません。
顔データは、ユーザーがアプリ内での利用を終了するまで保持されます。ユーザーがデータの削除を要求した場合には、顔データは完全に削除されます。
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">3. その他</span>
									<br />
									<br />
									<ul>
										<li>
									プライバシーポリシーの改定について
									<br />
									<br />
											<ul>
												<li>
									弊社は、プライバシーポリシーの全部または一部を改定することがあります。重要な変更がある場合には、サイト上でお知らせします。
									<br />
									<br />
												</li>
											</ul>
										</li>
									</ul>
									<br />
									平成25年4月30日 制定・施行
									<br />
									<br />
									令和5年6月1日 改定
									<br />
									<br />
									<br />
									<br />
									<br />
								</div>
								<div class="maine_moji"></div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
</style>
