<template>
  <div class="dialog-frame">
    <form method="#" action="#" :key="componentKey">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <template v-if="dispFlg">
        <div class="card" style="height: 120px; vertical-align:bottom;">
          <!-- 有給休暇 -->
          <div class="card-header">
            <h4 class="card-title" style="margin: 0;">
              {{screenCodeTitle(screenCode)}}
            </h4>
          </div>
          <div class="card-content">
            <div class="col-sm-3" style="text-align: left;">
              {{employee_code}}
              <br>
              {{employee_name}}
            </div>
            <div class="col-sm-3" style="text-align: center;">
              <!-- 次回有給付与日 -->
              {{$t('label.label_paid_reference_date')}}
              <br>
              <template v-if="paid_reference_date">
                {{formatDate(paid_reference_date)}}
              </template>
            </div>
            <div class="col-sm-3" style="text-align: right;">
              <!-- 次回付与日数 -->
              {{this.$t('label.label_grant_days')}}
              <br>
              {{grant_days}}
              {{$t('label.day')}}
            </div>
            <div class="col-sm-3" style="text-align: right;">
              <!-- 次回予定勤続月数 -->
              {{$t('label.label_estimated_months_of_service')}}
              <br>
              {{estimated_months_of_service}}
              {{$t('label.months')}}
            </div>
          </div>
        </div>
        <div class="card-content row">
          <div class="col-sm-12" style="margin-bottom: 20px;">
            <!-- テーブル表示(上部) -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData2"
                      height="132"
                      style="width: 100%">
              <!-- 支給年月日 -->
              <template>
                <el-table-column min-width="50" :label="labels.payment_date_ryaku === undefined ? '' : labels.payment_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{formatDate(props.row.payment_date)}}
                  </template>
                </el-table-column>
              </template>
              <!-- 支給日数 -->
              <el-table-column min-width="30" :label="labels.payment_days_ryaku === undefined ? '' : labels.payment_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{editHyphen(props.row.payment_days)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 支給時間 -->
              <el-table-column min-width="30" :label="labels.payment_times_ryaku === undefined ? '' : labels.payment_times_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.payment_times)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 支給理由 -->
              <template>
                <el-table-column min-width="50" :label="labels.payment_reason_ryaku === undefined ? '' : labels.payment_reason_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{editHyphen(getLabel(getSelectName(props.row.payment_reason, comboData.paid_leave_reason_list)))}}
                  </template>
                </el-table-column>
              </template>
              <!-- 失効年月日 -->
              <template>
                <el-table-column min-width="50" :label="labels.expiration_date_ryaku === undefined ? '' : labels.expiration_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{formatDate(props.row.expiration_date)}}
                  </template>
                </el-table-column>
              </template>
              <!-- 失効日数 -->
              <template>
                <el-table-column min-width="30" :label="labels.expiration_days_ryaku === undefined ? '' : labels.expiration_days_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{props.row.expiration_days}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 失効時間 -->
              <template>
                <el-table-column min-width="30" :label="labels.expiration_times_ryaku === undefined ? '' : labels.expiration_times_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{formatDispMinute(props.row.expiration_times)}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 失効理由 -->
              <template>
                <el-table-column min-width="80" :label="labels.expiration_reason_ryaku === undefined ? '' : labels.expiration_reason_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{editHyphen(getLabel(getSelectName(props.row.expiration_reason, comboData.paid_leave_reason_list)))}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 有給保持日数 -->
              <template>
                <el-table-column min-width="50" :label="labels.hold_days_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{props.row.hold_days}}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <!-- 有給保持時間数 -->
              <template>
                <el-table-column min-width="60" :label="labels.hold_times_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: right;">
                      {{formatDispMinute(props.row.hold_times)}}
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </div>
        <div class="card-content row">
          <div class="col-sm-12" style="margin-bottom: 20px;">
            <!-- テーブル表示(下部) -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      height="490"
                      style="width: 100%">
              <!-- 取得開始日 -->
              <template>
                <el-table-column min-width="80" :label="labels.term_from_ryaku === undefined ? '' : labels.term_from_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{formatDate(props.row.term_from)}}
                  </template>
                </el-table-column>
              </template>
              <!-- 取得終了日 -->
              <template>
                <el-table-column min-width="80" :label="labels.term_to_ryaku === undefined ? '' : labels.term_to_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{formatDate(props.row.term_to)}}
                  </template>
                </el-table-column>
              </template>
              <!-- 有給休暇の種類 -->
              <template>
                <el-table-column min-width="110" :label="labels.paid_holiday_type_ryaku === undefined ? '' : labels.paid_holiday_type_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{editHyphen(getLabel(getSelectName(props.row.paid_holiday_type, comboData.paid_holiday_type_list)))}}
                  </template>
                </el-table-column>
              </template>
              <!-- 取得日数 -->
              <el-table-column min-width="65" :label="labels.get_days_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{editHyphen(props.row.get_days)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 取得開始時間 -->
              <el-table-column min-width="85" :label="$t('label.label_term_time_from')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{editDispTime(props.row.term_time_from)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 取得終了時間 -->
              <el-table-column min-width="85" :label="$t('label.label_term_time_to')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{editDispTime(props.row.term_time_to)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 取得時間数 -->
              <el-table-column min-width="70" :label="labels.get_times_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right;">
                    {{formatDispMinute(props.row.get_times)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 補足説明 -->
              <el-table-column min-width="260" :label="labels.contents_ryaku === undefined ? '' : labels.contents_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{editHyphen(props.row.contents)}}
                  </div>
                </template>
              </el-table-column>
              <!-- 申請番号 -->
              <template>
                <el-table-column min-width="200" :label="labels.application_number_ryaku === undefined ? '' : labels.application_number_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <template>
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </template>
                    </div>
                  </template>
                  <template slot-scope="props">
                    {{editHyphen(props.row.application_number)}}
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card" style="height: 75px; vertical-align:bottom;">
          <div class="card-content">
            <div class="col-sm-12" style="text-align: left;">
              <h5 class="card-title" style="margin: 0;">
                {{message1}}
                <br>
                {{message2}}
              </h5>
            </div>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'

  export default {
    mixins: [mixin],
    components: {
      ValidationText
    },
    props: {
      mode: Number,
      params: Object
    },
    data () {
      return {
        componentKey: 0,
        id: 0,
        employee_code: '',
        employee_name: '',
        estimated_months_of_service: '',
        paid_reference_date: '',
        grant_days: '',
        tableData: undefined,
        tableData2: undefined,
        paid_leave_data: {},
        labels: {},
        comboData: {},
        dispFlg: true,
        message1: '',
        message2: ''
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
      },
      isPrimaryKeyReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete
      }
    },
    methods: {
      // イベントを処理する場合はカスタマイズエリアに実装してください。
      // 初期処理カスタマイズ用
      afterInit (res) {
      },
      // 独自バリデーション用
      customValidate () { return true },
      // 送信データ調整用
      convertDetail (detail) { return detail },
      doClose () {
        this.$emit('close')
      },
      editHyphen (num) {
        // 引数が空の場合、有給休暇
        if (num === '' || num === null) {
          return '-'
        } else {
          return num
        }
      },
      editDispTime (strHour) {
        // 引数が空の場合、ブランクを返す。空でない場合、桁数を調整する。
        if (strHour === '' || strHour === null) {
          return ''
        } else {
          // 一文字目が'0'の場合、一文字目を削る。
          if (strHour.slice(0, 1) === '0') {
            return strHour.slice(1, 5)
          } else {
            return strHour
          }
        }
      },
      init () {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: this.$store.state.loginUser.employeeCode
          }
        }
        this.send('/init/paid_leave_status', data)
        .then(res => {
          if (res.api_area === null) {
            this.dispFlg = false
            this.message1 = res.message.text
            this.message2 = res.message.correspondence_action
          } else {
            this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.paid_leave_employee_list
            this.tableData2 = Array.isArray(res.api_area) ? res.api_area : res.api_area.paid_annual_account_employee_list
            this.labels = this.getLabels(res.common_area)
            this.comboData = this.getComboData(res.common_area.combo_data)
            this.employee_code = this.$store.state.loginUser.employeeCode
            this.employee_name = this.$store.state.loginUser.employeeName
            this.estimated_months_of_service = res.api_area.estimated_months_of_service
            this.paid_reference_date = res.api_area.paid_reference_date
            this.grant_days = res.api_area.grant_days
            // テーブル作成用
            for (const row of this.tableData) {
              for (const entry of Object.entries(row)) {
                if (entry[1] === null) {
                  row[entry[0]] = ''
                }
              }
            }
          }
          this.afterInit(res)
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
.table-scroll {
  overflow-y:scroll
}
.table-height {
  height: 490px;
}
.table-height2 {
  height: 130px;
}
</style>
