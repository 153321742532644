import mixin from '@/lib/mixin'
// import swal from 'sweetalert2'
import LunchOrder from '@/components/UIComponents/Inputs/LunchOrder'

export default {
  name: 'LunchOrderSheetMixin',
  mixins: [mixin],
  components: {
    LunchOrder
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      detail: {
        lunch_order: []
      },
      labels: {},
      comboData: {}
    }
  },
  computed: {
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 更新処理カスタマイズ用
    afterSubmit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) { return detail },
    doUpdate () {
      this.submit('update')
    },
    doBack () {
      this.$router.go(-1)
    },
    submit (operation) {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.$emit('on-submit', this.registrationForm, false)
          return
        }
        let detail = null
        detail = {...this.detail}
        let reqData = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: this.convertDetail(detail)
        }
        this.send(`/${operation}/lunch_order`, reqData)
        .then(res => {
          this.$refs.frame.parentNode.parentNode.parentNode.scrollTop = 0
          this.detail.update_count = this.detail.update_count ? String(parseInt(this.detail.update_count, 10) + 1) : '1'
          this.afterSubmit(res)
          this.componentKey += 1
        }).catch(error => {
          console.error(error)
        })
      })
    },
    init () {
      let data = {
        screen_code: '03-028',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
        }
      }
      this.send('/init/lunch_order/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            }
          }
          this.afterInit(res)
          this.componentKey += 1
        }
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  }
}
