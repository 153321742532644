<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="approver" :draggable="true" :clickToClose="false" height="auto" :width="700">
      <div class="modal-body">
        <EmployeeSearch :search-all="true" @close="hideApproverSearch"/>
      </div>
    </modal>
    <div>
      <div class="col-sm-6">
        <div style="margin-bottom: 5px;" v-if="$route.params.paramRoleCode">
          <button type="button" class="btn btn-sm btn-default btn-warning" @click="doBack">
            {{$t('button.back')}}
          </button>
        </div>
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <form method="#" action="#" :key="componentKey">
      <MessageArea :page-type="pageType.proxyApproval" :message="message" :placeholders="[pageTitle]"/>
      <ValidationObserver ref="observer">
      </ValidationObserver>
      <div class="col-md-12">
        <!-- テーブル(自分を代理承認者にしている一覧) -->
        <template>
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">
                {{labels.label_deputy_approverl_data}}
              </h4>
            </div>
            <div class="card-content row">
              <div style="margin-top: 10px; margin-left: 10px;">
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" class="table-width"
                          border
                          :data="tableData"
                          ref="table"
                          style="width: 100%">
                  <!-- 操作（解除チェックボックス） -->
                  <el-table-column
                    width="100"
                    align="center"
                    :label="$t('label.label_delete')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                          <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <input type="checkbox" v-model="props.row.del_flag" class="gray">
                    </template>
                  </el-table-column>
                  <!-- 所属会社 -->
                  <el-table-column width="200" :label="labels.company_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.company_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.company_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 所属部署 -->
                  <el-table-column width="200" :label="labels.group_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.group_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.group_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 従業員氏名 -->
                  <el-table-column width="200" :label="labels.employee_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.employee_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.employee_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 依頼理由 -->
                  <el-table-column width="490" :label="labels.deputy_contents_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <input type="text"
                          class="form-control length34"
                          style="display: inline-block;"
                          maxlength="30"
                          v-model="props.row.deputy_contents"
                          disabled>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-12">
        <!-- テーブル(自分の代理承認者一覧) -->
        <template>
          <div class="card">
            <div class="card-header search-result-title">
              <h4 class="card-title">
                {{labels.label_approverl_data}}
              </h4>
            </div>
            <div class="card-content row">
              <div style="margin-top: 10px; margin-left: 10px">
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                          :data="tableData2"
                          ref="table2"
                          style="width: 100%">
                  <!-- クリア（クリアボタン）-->
                  <el-table-column
                    width="100"
                    align="center"
                    :label="$t('label.operation')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <button type="button" class="btn btn-sm btn-default btn-warning" @click="tabeleRowClear(props.$index, props.row)">
                        {{$t('button.clear')}}
                      </button>
                    </template> 
                  </el-table-column>
                  <!-- 所属会社 -->
                  <el-table-column width="200" :label="labels.company_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.company_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.company_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 所属部署 -->
                  <el-table-column width="200" :label="labels.group_ryaku ? labels.group_ryaku : ''">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.group_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.group_name}}
                      </div>
                    </template>
                  </el-table-column>
                   <!-- 従業員氏名 -->
                  <el-table-column width="200" :label="labels.employee_ryaku ? labels.employee_ryaku : ''">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>{{props.row.employee_code}}</small>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.employee_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 代理承認者の会社コード / 代理承認者の会社名-->
                  <el-table-column width="200" :label="labels.deputy_approverl_company_name_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                    <div style="text-align: center;">
                      <el-select class="select-success"
                                  placeholder=""
                                  v-model="props.row.deputy_approverl_company_code"
                                  @change="changeApproverlCompany(props.$index)">
                        <el-option v-for="deputy_approverl_company in comboData.company_list"
                                    class="select-success"
                                    :value="deputy_approverl_company.value"
                                    :label="deputy_approverl_company.label"
                                    :key="deputy_approverl_company.value">
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                  </el-table-column>
                  <!-- 代理承認者 -->
                  <el-table-column
                    width="540"
                    :label="labels.deputy_approverl_employee_name_ryaku"
                    align="center">
                    <template slot-scope="props">
                      <div style="text-align: center;">
                        <input type="text"
                              class="form-control length10"
                              style="display: inline-block;"
                              maxlength="10"
                              v-model="props.row.deputy_approverl_employee_code"
                              @blur="approverSelected(props.$index, 1)">
                        <input type="text"
                              class="form-control length20"
                              style="display: inline-block;"
                              maxlength="30"
                              v-model="props.row.deputy_approverl_employee_name"
                              @blur="approverSelected(props.$index, 2)">
                        <button type="button" class="btn btn-sm btn-default btn-warning" @click="showApproverSearch(props.$index)" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 代理承認者の部署コード / 代理承認者の部署名-->
                  <el-table-column width="200" :label="labels.deputy_approverl_group_name_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <el-select class="select-success"
                                placeholder=""
                                :disabled="isGroup(props.$index)"
                                v-model="props.row.deputy_approverl_group_code"
                                @change="changeApproverlGroup(props.$index)">
                        <el-option v-for="approverl_group in getApproverlGrupList(props.$index)"
                                    class="select-success"
                                    :value="approverl_group.group_code"
                                    :label="approverl_group.group_name"
                                    :key="approverl_group.group_code">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <!-- 依頼理由 -->
                  <el-table-column width="430" :label="labels.deputy_contents_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <input type="text"
                          class="form-control length30"
                          style="display: inline-block;"
                          maxlength="30"
                          v-model="props.row.deputy_contents">
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div style="text-align: left; margin-top: 20px;">
        <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
              <span class="btn-label"><i class="fa fa-check"></i></span>
          {{$t('button.update')}}
        </button>
      </div>
    </form>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
  import ProxyApprovalSettingMixin from '@/components/Dashboard/Views/Setting/ProxyApprovalSettingMixin'

  export default {
    name: 'ProxyApprovalSetting',
    methods: {
    },
    mixins: [ProxyApprovalSettingMixin]
  }
</script>
<style scoped>
.table-scroll {
  overflow-y:scroll
}
</style>
