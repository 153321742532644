<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <small class="help-block" v-show="valid | !validated">
          {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
        </small>
        <small class="help-block" v-show="mode === modes.add && employeeFlag">
          {{$t('label.password_auto_input')}}
        </small>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div>
        <input 
          :type="type" 
          :name="itemName"
          :placeholder="placeholder"
          :class="classes" 
          style="display: inline-block;"
          v-model="itemValue" 
          :maxlength="maxLength"
          :disabled="disabled"
          :secureLength="8"
          autocomplete="new-password"
          @blur="$emit('input', itemValue)"
          @score="showScore"
          @keypress="$emit('input', itemValue)">{{unit ? '&nbsp;' + unit : ''}}
        <template v-if="isPassword">
          <span class="field-icon">
            <i toggle="password-field" class="mdi mdi-eye toggle-password">
            </i>
          </span>
        </template>
        <VPasswordStrengthMeter 
          :password="itemValue"
          :enableFeedback="false"
          @strengthChange="showScore"
          class="length30"
        />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import $ from 'jquery'
  import VPasswordStrengthMeter from 'v-password-strength-meter'

  export default {
    mixins: [mixin],
    components: {
      VPasswordStrengthMeter
    },
    props: {
      type: {
        type: String,
        require: false,
        'default': 'password'
      },
      value: String,
      itemName: String,
      maxLength: String,
      classes: String,
      rules: String,
      itemLabel: String,
      placeholder: String,
      disabled: Boolean,
      helpText: String,
      unit: {
        type: String,
        require: false,
        'default': ''
      },
      isClosingDate: {
        type: Boolean,
        require: false,
        'default': false
      },
      weaklyMessage: {
        type: String,
        require: true
      },
      unusableMessage: {
        type: String,
        require: true
      },
      wordCountMessage: {
        type: String,
        require: true
      },
      employeeFlag: {
        type: String,
        require: false,
        'default': false
      },
      mode: {
        type: Number,
        require: false,
        'default': 0
      }
    },
    data () {
      return {
        itemValue: '',
        passwordValidation: true,
        passwordScore: 0,
        isPassword: true
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
      if (this.isPassword) {
        // 目のマークの処理
        $('.toggle-password').click(function () {
          $(this).toggleClass('mdi-eye mdi-eye-off')
          var input = $(this).parent().prev('input')
          if (input.attr('type') === 'password') {
            input.attr('type', 'text')
          } else {
            input.attr('type', 'password')
          }
        })
      }
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      },
      showScore (score) {
        this.passwordScore = score
      }
    }
  }
</script>

<style scoped>
  /*パスワードの非表示*/
  .mdi-eye:before {
    background: url(/static/img/toggleicons/eye-off.png);
    background-size: contain;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top:10px;
    right: 20px;
  }
  /*パスワードの表示*/
  .mdi-eye-off:before {
    background: url(/static/img/toggleicons/eye.png);
    background-size: contain;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top:10px;
    right: 20px;
  }
  .field-icon {
    position: absolute;
  }
</style>