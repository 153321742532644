<template>
  <div ref="frame" :key="componentKey">
    <modal name="bank" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="800">
      <div class="modal-body">
        <BankSearch @close="hide"/>
      </div>
    </modal>
    <div style="display: inline-block;" v-if="comboData.group_list">
      <div class="card" v-if="labels" style="width: 200px; text-align: center;">
        <div class="card-content">
          <img v-show="detail.employee_photo" :src="detail.employee_photo" style="width: 100px;"/>
          <div class="content-value">
            {{detail.employee_name}}
          </div>
          <div class="content-value" v-for="group in comboData.group_list" v-bind:key="group.value">
            {{group.label}}
          </div>
        </div>
      </div>
      <span>銀行検索のテスト</span>
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="show" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
      <div v-if="selectedBankCode" style="padding-top: 20px;">
        {{`${selectedBankName} (${selectedBankCode}) / ${selectedBranchName} (${selectedBranchCode})`}}
      </div>
    </div>
    <div style="display: inline-block; vertical-align: top; margin-left: 30px;">
      <table class="basicInfo">
        <caption><b>基本情報</b></caption>
        <tr>
          <th>
            <div class="content-label">
              {{labels.employee_code}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{detail.employee_code}}
            </div>
          </td>
        </tr>
        <template v-if="detail.tel || detail.extension_number">
          <tr>
            <th>
              <div class="content-label">
                {{labels.tel}}({{labels.extension_number}})
              </div>
            </th>
            <td>
              <div class="content-value">
                {{detail.tel}}({{detail.extension_number}})
              </div>
            </td>
          </tr>
        </template>
        <tr>
          <th>
            <div class="content-label">
              {{labels.mail_address}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{detail.mail_address}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="content-label">
              {{labels.employee_classification_code}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{getLabel(getSelectName(detail.employee_classification_code, comboData.employee_classification_list))}}
            </div>
          </td>
        </tr>
        <template v-if="detail.recruitment_category_code && detail.recruitment_category_code != '9999999999'">
          <tr>
            <th>
              <div class="content-label">
                {{labels.recruitment_category_code}}
              </div>
            </th>
            <td>
              <div class="content-value">
                {{getLabel(getSelectName(detail.recruitment_category_code, comboData.recruitment_category_list))}}
              </div>
            </td>
          </tr>
        </template>
        <tr>
          <th>
            <div class="content-label">
              {{labels.hire_date}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{detail.hire_date}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="content-label">
              {{labels.sex}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{detail.sex}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="content-label">
              {{labels.birthday}}
            </div>
          </th>
          <td>
            <div class="content-value">
              {{detail.birthday}}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  import BankSearch from '@/components/Dashboard/Views/Components/BankSearch'
  import mixin from '@/lib/mixin'

  export default {
    name: 'EmployeeInfo',
    mixins: [mixin],
    components: {
      BankSearch
    },
    data () {
      return {
        componentKey: 0,
        selectedBankCode: '',
        selectedBankName: '',
        selectedBranchCode: '',
        selectedBranchName: '',
        type: false,
        labels: {},
        comboData: {},
        detail: {
          append_path: '',
          employee_photo: undefined
        }
      }
    },
    methods: {
      show () {
        this.$modal.show('bank')
      },
      hide (selected) {
        if (selected) {
          this.selectedBankCode = selected.bank_code
          this.selectedBankName = selected.bank_name
          this.selectedBranchCode = selected.branch_code
          this.selectedBranchName = selected.branch_name
        }
        this.$modal.hide('bank')
      },
      createImage () {
        let self = this
        this.$firebase.storage()
        .ref()
        .child(this.detail.append_path)
        .getDownloadURL()
        .then(function (url) {
          self.detail.employee_photo = url
          self.componentKey += 1
        })
      },
      init () {
        let data = {
          screen_code: 'SS-021',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
          }
        }
        this.send('/init/employee_info', data)
        .then(res => {
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          if (res.api_area) {
            this.detail = {...res.api_area}
            for (const entry of Object.entries(res.api_area)) {
              if (typeof entry[1] === 'number') {
                this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
              } else if (typeof this.detail[entry[0]] === 'string') {
                this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
              } else {
                this.detail[entry[0]] = entry[1]
              }
            }
            this.createImage()
          }
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
  .basicInfo {
    border-collapse: collapse; 
    border: solid 1px #DDDDDD; 
    background-color: white;
  }
  .basicInfo > tr, .basicInfo > tr > th, .basicInfo > tr > td {
    border: solid 1px #DDDDDD;
    padding: 10px;
  }
  .basicInfo > tr > th {
    background-color: #EEEEEE;
  }
</style>
