<template>
  <ValidationObserver ref="observer" v-slot="{ errors }">
    <div class="row" style="overflow-y: auto; height: calc(100vh - 330px);" ref="companyFrame">
      <div class="col-md-12">
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;" movie-key="company_regist"/>
        <MessageArea :page-type="pageType.search" :message="companyMessage" :placeholders="[pageTitle]"/>
        <div style="margin-bottom: 5px;">
          <template v-for="(error, index) in errors">
            <template v-if="error.length > 0">
              <div v-bind:key="index">
                <small class="text-danger" v-if="error.length > 0">
                  {{error[0].indexOf('|') > -1 ? error[0].replace(error[0].substring(error[0].indexOf('|'), error[0].indexOf('|') + 12), '') : error[0]}}
                </small>
              </div>
            </template>
          </template>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" style="display: inline-block; vertical-align: top;">
                  <label>{{labels.company_code}}</label>
                  <div>
                    <input 
                      type="text" 
                      class="form-control length10" 
                      v-model="company.company_code" 
                      maxlength="10"
                      :disabled="true">
                  </div>
                </div>
                <div class="form-group" style="display: inline-block; vertical-align: top;">
                  <label>{{labels.corporate_number}}</label>
                  <div>
                    <input 
                      type="text" 
                      :placeholder="$t('placeholder.corporate_number')"
                      class="form-control length13" 
                      v-model="company.corporate_number"
                      maxlength="13"
                      :disabled="false">
                  </div>
                </div>
                <div class="form-group" style="display: inline-block; vertical-align: bottom;">
                  <label>{{$t('label.corporate_number_page')}}</label>
                  <div>
                    <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank" style="color: #0000EE;">https://www.houjin-bangou.nta.go.jp/</a>
                  </div>
                </div>
                <ValidationText
                  v-model="company.company_nm"
                  classes="form-control length60"
                  :disabled="false"
                  :item-label="labels.company_name"
                  :placeholder="$t('placeholder.company_name')"
                  item-name="company_name"
                  rules="required"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                />
                <ValidationSelect
                  v-model="company.start_day_of_the_week"
                  :item-label="labels.start_day_of_the_week"
                  :options="comboData.day_of_the_week_list"
                  :disabled="false"
                  item-name="firstWeekday"
                  rules="required"
                  classes="width110"
                />
                <!-- <ValidationText
                  v-model="company.homepage"
                  classes="form-control"
                  :disabled="false"
                  :item-label="labels.home_page"
                  item-name="homepage"
                  :placeholder="$t('placeholder.homepage')"
                  :help-text="$t('label.max_length')"
                  max-length="255"
                />
                <ValidationSelect
                  v-model="company.language"
                  :item-label="labels.language"
                  :options="comboData.language_type_list"
                  :disabled="true"
                  item-name="language"
                  rules="required"
                  classes="width110"
                /> -->
              </div>
              <div class="col-md-6">
                <div :key="componentKey4">
                  <div><label>{{$t('label.company_logo')}}</label></div>
                  <div style="margin-bottom: 20px;">
                    <input type="file" @change="onCompanyFileChange"/>
                  </div>
                  <img v-show="company.company_logo" :src="company.company_logo" style="height: 100px; margin-bottom: 10px;"/>
                  <div>
                    <button type="button" v-show="company.company_logo" class="btn btn-sm btn-default btn-warning" @click="deleteCompanyImage">{{$t('button.delete')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div style="margin-bottom: 10px">{{$t('label.industry_description')}}</div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <ValidationSelect
                  v-model="company.industry_code_big"
                  :item-label="labels.industry_code_big"
                  :options="comboData.industry_code_big_list"
                  :disabled="false"
                  item-name="industry1"
                  rules="required"
                  @change="industryCodeBigChanged"
                />
                <ValidationSelect
                  v-model="company.industry_code_during"
                  :item-label="labels.industry_code_during"
                  :options="comboData.industry_code_during_list"
                  :disabled="false"
                  item-name="industry2"
                  rules="required"
                  :key="componentKey2"
                  @change="industryCodeDuringChanged"
                  />
                <ValidationSelect
                  v-model="company.industry_code_small"
                  :item-label="labels.industry_code_small"
                  :options="comboData.industry_code_small_list"
                  :disabled="false"
                  item-name="industry3"
                  help-text="総務省　日本標準産業分類に準拠"
                  rules="required"
                  :key="componentKey3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div style="margin-bottom: 10px">{{$t('label.company_address_description')}}</div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-content">
                <ValidationText
                  v-model="company.place_name"
                  classes="form-control length60"
                  :disabled="false"
                  :item-label="labels.place_name"
                  item-name="place_name"
                  rules="required"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                />
                <ValidationPostalCode
                  v-model="company.post_code"
                  classes="form-control width100"
                  :disabled="false"
                  :item-label="labels.post_code"
                  item-name="postal_code"
                  :placeholder="$t('placeholder.postal_code')"
                  rules="required"
                  :help-text="$t('label.max_length')"
                  max-length="8"
                  @search="postalCodeSearch"
                  @pin="putPin"
                />
                <ValidationSelect
                  v-model="company.prefectural"
                  :item-label="labels.state_name_ryaku"
                  :options="comboData.state_codes_list"
                  :disabled="false"
                  item-name="language"
                  rules="required"
                  classes="width110"
                  @change="prefecturesChanged"
                />
                <ValidationSelect
                  v-model="company.city"
                  item-name="city"
                  classes="length50"
                  :item-label="labels.municipality_name_ryaku"
                  rules="required"
                  :options="comboData.city_list"
                  :disabled="false"
                  :key="componentKey"
                />
                <ValidationText
                  v-model="company.town"
                  classes="form-control length100"
                  :disabled="false"
                  :item-label="labels.town"
                  item-name="town"
                  :placeholder="$t('placeholder.town')"
                  rules="required"
                  :help-text="$t('label.max_length')"
                  max-length="50"
                />
                <ValidationText
                  v-model="company.building"
                  classes="form-control length60"
                  :disabled="false"
                  :item-label="labels.building"
                  item-name="building"
                  :placeholder="$t('placeholder.building')"
                  :help-text="$t('label.max_length')"
                  max-length="30"
                />
                <ValidationText
                  v-model="company.telephone1"
                  classes="form-control length20"
                  :disabled="false"
                  :item-label="labels.tel"
                  item-name="tel"
                  :placeholder="$t('placeholder.tel')"
                  rules="required|tel"
                  :help-text="$t('label.max_length')"
                  max-length="20"
                />
                <!-- <ValidationText
                  v-model="company.fax"
                  classes="form-control length20"
                  :disabled="false"
                  :item-label="labels.fax"
                  item-name="fax"
                  :placeholder="$t('placeholder.fax')"
                  rules="tel"
                  :help-text="$t('label.max_length')"
                  max-length="20"
                /> -->
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-content" style="height: 710px;">
                <div class="form-group">
                  <div id="regularMap" class="map" @click="mapClicked" style="margin: 10px 0;"></div>
                  <button type="button" :disabled="false" class="btn btn-sm btn-default btn-primary" @click="clearMarker">{{$t('button.cancel')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12">
        <div style="margin-bottom: 10px">既定の賃金管理を選択してください。</div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <ValidationRadio
                  rules="required"
                  v-model="company.payroll_management"
                  item-label="賃金管理"
                  :disabled="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="company.payroll_management && company.payroll_management.value === '1'">
        <div style="margin-bottom: 10px">{{$t('label.insurance_situation_description')}}</div>
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <ValidationText
                  v-model="company.labor_insurance_number"
                  classes="form-control length14"
                  :disabled="false"
                  :item-label="labels.labor_insurance_number"
                  :placeholder="$t('placeholder.labor_insurance_number')"
                  :help-text="$t('label.labor_insurance_number_text')"
                  max-length="14"
                />
                <ValidationSelect
                  v-model="company.social_insurance"
                  :item-label="labels.social_insurance"
                  :options="comboData.social_insurance_list"
                  :disabled="false"
                  item-name="social_insurance"
                  rules="required"
                  classes="length20"
                />
                <ValidationSelect
                  v-model="company.employment_insurance"
                  :item-label="labels.employment_insurance"
                  :options="comboData.employment_insurance_list"
                  :disabled="false"
                  item-name="employment_insurance"
                  rules="required"
                  classes="length20"
                />
                <ValidationSelect
                  v-model="company.pension_fund_contributions"
                  :item-label="labels.pension_fund_contributions"
                  :options="comboData.pension_fund_contributions_list"
                  :disabled="false"
                  item-name="pension_fund_contributions"
                  rules="required"
                  classes="length20"
                />
                <ValidationSelect
                  v-model="company.type_of_business"
                  :item-label="labels.type_of_business"
                  :options="comboData.type_of_business_list"
                  :disabled="false"
                  item-name="type_of_business"
                  rules="required"
                  classes="length20"
                />
                <ValidationSelect
                  v-model="company.business_type_number"
                  :item-label="labels.business_type_number"
                  :options="comboData.workers_accident_compensation_insurance_list"
                  :disabled="false"
                  item-name="business_type_number"
                  rules="required"
                  classes="length20"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </ValidationObserver>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect,
      ValidationRadio,
      ValidationPostalCode
    },
    props: {
      futureGoogle: Promise
    },
    data () {
      return {
        componentKey: 0,
        componentKey2: 1000,
        componentKey3: 2000,
        componentKey4: 3000,
        industry1: '',
        industry1_before: '',
        industry2: '',
        industry2_before: '',
        industries1_full: [],
        industries2_full: [],
        industries3_full: [],
        industry_mapping_big_and_during: [],
        industry_mapping_during_and_small: [],
        company: {
          language: 'JPN',
          business_type_number: '94'
        },
        geocoder: {},
        map: {},
        marker: null,
        labels: {},
        comboData: {
          language_list: [],
          industry_code_big_list: [],
          industry_code_during_list: [],
          industry_code_small_list: [],
          prefectures_list: [],
          social_insurance_list: [],
          employment_insurance_list: [],
          pension_fund_contributions_list: [],
          type_of_business_list: []
        },
        companyMessage: {},
        google: null,
        size: 256 * 1024
      }
    },
    methods: {
      industryCodeBigChanged () {
        if (this.company.industry_code_big_before) {
          this.company.industry_code_during = ''
          this.company.industry_code_small = ''
        }
        this.company.industry_code_big_before = this.company.industry_code_big
        this.comboData.industry_code_during_list = []
        for (const code of this.comboData.industry_mapping_big_and_during[this.company.industry_code_big]) {
          for (const entry of this.comboData.industry_code_during_full) {
            if (code === entry.value) {
              this.comboData.industry_code_during_list.push(entry)
            }
          }
        }
      },
      industryCodeDuringChanged () {
        if (this.company.industry_code_during_before) {
          this.company.industry_code_small = ''
        }
        if (this.company.industry_code_during) {
          this.company.industry_code_during_before = this.company.industry_code_during
          this.comboData.industry_code_small_list = []
          for (const code of this.comboData.industry_mapping_during_and_small[this.company.industry_code_during]) {
            for (const entry of this.comboData.industry_code_small_full) {
              if (code === entry.value) {
                this.comboData.industry_code_small_list.push(entry)
              }
            }
          }
        } else {
          this.comboData.industry_code_small = [...this.industry_code_small_full]
        }
      },
      prefecturesChanged () {
        if (this.company.prefectures_before) {
          this.company.city = ''
        }
        if (this.company.prefectural) {
          this.company.prefectures_before = this.company.prefectural
          this.comboData.city_list = []
          if (!this.comboData.mapping_list[this.company.prefectural]) {
            for (const prefecture of this.comboData.state_codes_list) {
              if (prefecture.label === this.company.prefectural) {
                this.company.prefectural = prefecture.value
                break
              }
            }
          }
          if (this.comboData.mapping_list[this.company.prefectural]) {
            for (const code of this.comboData.mapping_list[this.company.prefectural]) {
              for (const entry of this.comboData.municipality_list) {
                if (code === entry.value) {
                  this.comboData.city_list.push(entry)
                }
              }
            }
          }
        } else {
          this.comboData.city_list = []
        }
      },
      onCompanyFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createCompanyImage(files[0])
      },
      createCompanyImage (file) {
        if (file.size > this.size) {
          swal({
            text: this.$t('message.file_size_over').replace('{size}', this.formatNumber(this.size / 1024)),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
          return
        }
        let self = this
        const mailAddress = sessionStorage.getItem('mailAddress')
        let appendPath = `new-company/${mailAddress}/companyLogo.jpg`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.put(file, {contentType: 'image/jpeg'}).then(function (snapShot) {
          self.company.company_append_path = appendPath
          httpsReference.getDownloadURL().then(function (url) {
            self.company.company_logo = url
            self.componentKey4 += 1
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      deleteCompanyImage () {
        this.company.company_logo = undefined
        this.componentKey4 += 1
      },
      clearMarker () {
        if (this.marker) {
          this.marker.setMap(null)
        }
      },
      postalCodeSearch (postalCode) {
        let self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({componentRestrictions: {postalCode: postalCode}, region: 'ja'}, function (results, status) {
            if (status === 'OK') {
              const myLatLng = results[0].geometry.location
              self.company.lat = String(myLatLng.lat())
              self.company.lng = String(myLatLng.lng())
              // self.setMarker(true)
              const myLatlng = new self.google.maps.LatLng(self.company.lat, self.company.lng)
              self.geocode(myLatlng, true)
            }
          })
        }
      },
      putPin (postalCode) {
        let self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({address: self.company.prefectural + self.company.city + self.company.town, region: 'ja'}, function (results, status) {
            if (status === 'OK') {
              const myLatLng = results[0].geometry.location
              self.company.lat = String(myLatLng.lat())
              self.company.lng = String(myLatLng.lng())
              self.setMarker(false)
            }
          })
        }
      },
      mapClicked (event) {
        if (event.latLng) {
          this.company.lat = String(event.latLng.lat()).substring(0, 10)
          this.company.lng = String(event.latLng.lng()).substring(0, 10)
          this.setMarker(false)
        }
      },
      setMarker (forceUpdate) {
        const myLatlng = new this.google.maps.LatLng(this.company.lat, this.company.lng)
        if (this.marker) {
          this.marker.setMap(null)
        }
        this.marker = new this.google.maps.Marker({
          position: myLatlng,
          title: this.company.place_name
        })
        this.marker.setMap(this.map)
        this.geocode(myLatlng, forceUpdate)
      },
      geocode (latlng, forceUpdate) {
        let self = this
        if (this.geocoder.geocode) {
          this.geocoder.geocode({location: latlng}, function (results, status) {
            if (status === 'OK') {
              for (const result of results) {
                if (result.types) {
                  let isFound = false
                  for (const type of result.types) {
                    if (type === 'sublocality_level_2' || type === 'sublocality_level_3' || type === 'sublocality_level_4') {
                      const componentsLength = result.address_components.length
                      self.company.post_code = result.address_components[componentsLength - 1].long_name
                      if (!self.company.prefectural || forceUpdate) {
                        for (const prefecture of self.comboData.state_codes_list) {
                          if (prefecture.label === result.address_components[componentsLength - 3].long_name) {
                            self.company.prefectural = prefecture.value
                            break
                          }
                        }
                      }
                      if (self.company.prefectural) {
                        self.prefecturesChanged()
                      }
                      if (!self.company.city || forceUpdate) {
                        for (const municipality of self.comboData.municipality_list) {
                          if (municipality.label === result.address_components[componentsLength - 4].long_name) {
                            self.company.city = municipality.value
                            break
                          }
                        }
                      }
                      if (!self.company.town || forceUpdate) {
                        self.company.town = ''
                        for (var i = componentsLength - 5; i >= 0; i--) {
                          self.company.town = self.company.town + result.address_components[i].long_name
                        }
                      }
                      self.componentKey += 1
                      self.updateLocation()
                      isFound = true
                      break
                    }
                  }
                  if (isFound) break
                }
              }
            }
          })
        }
      },
      updateLocation () {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async function (position) {
              let coords = position.coords
              const myLat = this.company.lat ? this.company.lat : coords.latitude
              const myLng = this.company.lng ? this.company.lng : coords.longitude
              const myLatLng = new this.google.maps.LatLng(myLat, myLng)
              const mapOptions = {
                zoom: 15,
                center: myLatLng,
                scrollwheel: false
              }
              this.geocoder = new this.google.maps.Geocoder()
              this.map = new this.google.maps.Map(document.getElementById('regularMap'), mapOptions)
              this.google.maps.event.addListener(this.map, 'click', this.mapClicked)
              if (this.marker) {
                this.marker.setMap(this.map)
              }
            }.bind(this),
            function () {
              swal({
                title: this.labels.failed_get_location,
                buttonStyling: false,
                cancelButtonClass: 'btn btn-warning btn-sm btn-default',
                allowOutsideClick: false
              })
            }
          )
        } else {
          swal({
            title: this.labels.failed_get_location,
            buttonStyling: false,
            cancelButtonClass: 'btn btn-warning btn-sm btn-default',
            allowOutsideClick: false
          })
        }
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate`, request)
        .then(res => {
          this.companyMessage = this.message
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(res.common_area.combo_data)
          this.comboData.industry_mapping_big_and_during = res.common_area.combo_data.industry_list.industry_mapping_big_and_during
          this.comboData.industry_mapping_during_and_small = res.common_area.combo_data.industry_list.industry_mapping_during_and_small
          this.comboData.industry_code_during_full = [...this.comboData.industry_code_during_list]
          this.comboData.industry_code_small_full = [...this.comboData.industry_code_small_list]
          this.comboData.state_codes_list = this.comboData.state_list
          this.comboData.city_list = []
          this.comboData.mapping_list = res.common_area.combo_data.state_municipality_list.state_municipality_mapping_list
          this.company.payroll_management = {
            value: '2',
            disabled: false,
            items: [
              {
                id: 1,
                label: '管理する',
                value: '1'
              },
              {
                id: 2,
                label: '管理しない',
                value: '2'
              }
            ]
          }
        })
      }
    },
    created () {
      this.init()
      this.futureGoogle.then((google) => {
        this.google = google
        this.updateLocation()
      })
    }
  }
</script>
<style scoped>
</style>
