<template>
	<div>
		<LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
									
              <div class="col-md-10 col-md-offset-1">
								<div style="font-size: small">
									<span style="font-size:larger;font-weight:bold;">利用規約</span><br />
									<br />
									<ul>
										<li>
									本利用規約は、株式会社PocketSoft（以下、当社といいます。）が提供する、統合サービス「Artemis Collier（アルテミスコリエ）」（以下、本サービスといいます。）を利用するために利用者登録を行った個人および法人（以下、利用者）との利用条件を定めるものとします。また、本利用規約は本サービスの利用に関して生ずるすべてに適用されるものとし、利用者または利用希望者は、本規約を熟読し、本規約の内容を十分に理解した上でこれを承諾して、本サービスを利用するものとします。
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第1章 はじめに</span>
									<br />
									<br />
									<ul>
										<li>
									第1条　定義
									<br />
									<br />
											<ul>
												<li>
									本利用規約の中で使用される用語は、次の通りとします。
											<br />
											<br />
												</li>
											</ul>
											<ol>
												<li>
									「利用者」とは、本利用規約を承認の上、当社と本サービス利用契約（第1条第13項に規定します。）を締結した法人、団体又は個人をいいます。
												</li>
												<li>
									「利用希望者」とは、本サービスの利用を希望する法人、団体又は個人をいいます。
												</li>
												<li>
									「利用者登録」とは、利用希望者が行う本サービスの利用者登録をいいます。
												</li>
												<li>
									「利用者情報」とは、利用希望者及び利用者が利用者登録時に登録した当社が定める情報、本サービス利用中に当社が必要と判断して登録を求めた情報及びこれらの情報について利用者自身が追加、変更を行った場合の情報をいいます。
												</li>
												<li>
									「利用者送信コンテンツ」とは、利用者が、本サービス内に開示・登録・送信・掲載等を行う情報をいいます。
												</li>
												<li>
									「コンテンツ」とは、文章・画像・プログラム・データ等の情報をいいますが、これらに限りません。
												</li>
												<li>
									「メールアドレス」とは、パスワードと組み合わせて、利用者とその他の者とを識別するために用いられる符号をいいます。
												</li>
												<li>
									「パスワード」とは、メールアドレスと組み合わせて、利用者とその他の者とを識別するために用いられる符号をいいます。
												</li>
												<li>
									「従業員」とは、利用者が登録した会社情報を利用する個人をいいます。
												</li>
												<li>
									「会社コード」とは、利用者が管理する会社情報とその他の者が登録した会社情報とを識別するために用いられる符号をいいます。
												</li>
												<li>
									「従業員番号」とは、会社コードおよび従業員パスワードと組み合わせて、同じ会社情報を利用しているそのほかの従業員とを識別するために用いられる符号をいいます。
												</li>
												<li>
									「従業員パスワード」とは、会社コードおよび従業員番号と組み合わせて、従業員とその他の者とを識別するために用いられる符号をいいます。
												</li>
												<li>
									「本サービス利用契約」とは、利用者が本サービスを利用するに際し、利用者及び当社との間に発生する本サービスの利用に関する契約関係をいいます。
												</li>
												<li>
									「本サイト」とは、当社が運営する本サービスを提供するサイトを指します。
												</li>
												<li>
									「本アプリ」とは、当社が運営する本サービスを提供するアプリケーション及び関連ソフトウェアをいいます。
												</li>
												<li>
									「API連携サービス」とは、利用者の同意により各コンテンツパートナーが提供するAPI等のシステムを介して本サイト又は本アプリが各コンテンツサイトへアクセスして情報を取得し、取得した情報を本サイト又は本アプリ上に表示することができるサービスをいいます。
												</li>
											</ol>
										</li>
									<br />
										<li>
									第2条　本規約の範囲・改訂
									<br />
									<br />
											<ol>
												<li>
									本利用規約は、当社が提供する本サイト、本アプリを利用する利用者との関係を定めるものとします。また、本利用規約は本サイト、本アプリの利用に関するすべての法律関係に適用されるものとします。また、当社は、利用者への了解を得ることなく本規約を変更できるものとします。規約の変更・追加により利用者に生じた一切の損害について、直接損害か間接損害か、予見できたか否かを問わず、一切の責任を負いません。変更後の利用規約は本サイト上に表示・告知した時点より効力を生じるものとし、利用者は定期的に本規約の最新の内容を確認する義務を負うものとします。
												</li>
												<li>
									利用者は、当社からリンクされた他のサイトを利用する場合、当該他のサイトの利用規約等に同意のうえ、当該規約等に従って利用するものとします。
												</li>
												<li>
									本利用規約の他、本サイト、本アプリに関する利用ガイドは本利用規約を構成するものとし、利用者が本サイト、本アプリを利用するときは利用ガイドを遵守する義務を負います。
												</li>
											</ol>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第2章 利用者</span>
									<br />
									<br />
									<ul>
										<li>
									第3条　新規登録
									<br />
									<br />
											<ol>
												<li>
									新規登録手続きは、利用者となる本人が行うものとします。利用者となる本人以外が行う登録は一切認められません。
												</li>
												<li>
									利用者は、新規登録時及び新規登録後に、本サイト、本アプリを利用するにあたり、真実の内容にて登録を行わなければなりません。
												</li>
												<li>
									登録内容に変更がある場合は、利用者は直ちに登録内容を修正し、常に利用者自身の正確な情報が登録されているよう、管理・修正する責任を負います。また、利用者情報は必ず登録するものとします 。
												</li>
												<li>
									利用者は、いかなる場合においても、アカウントを第三者に譲渡・販売・貸与・その他の処分をすることは出来ません。
												</li>
												<li>
									利用者が前項の規定に違反したために被った損害について、直接損害か間接損害かを問わず、当社は一切責任を負いません。利用者は利用者が前各項の規定に違反したために第三者が被った損害がある場合、利用者が当該第三者に責任を負うものとし、当社は一切責任を負いません。
												</li>
											</ol>
									<br />
										</li>
										<li>
									第4条　利用者資格
									<br />
									<br />
											<ol>
												<li>
									本サイト、本アプリへの利用者登録の条件は以下のとおりとします。
									<br />
									<br />
													<ol>
														<li>
									満18才以上であること
														</li>
														<li>
									日本語を理解し、読み書き出来ること
														</li>
														<li>
									本利用規約に同意すること
														</li>
														<li>
									満18歳以上であって未成年である場合は、利用者となること及び本利用規約に従って本サイト、本アプリを利用することを、利用者登録する前に親権者の包括的な同意を得ていること
														</li>
														<li>
									自らが反社会的勢力でなく、過去にも反社会的勢力でなく、反社会的勢力を利用したことがないこと、かつ、自己の主要な出資者又は役職員が反社会的勢力の構成員でないこと
									<br />
									<br />
														</li>
													</ol>
												</li>
												<li>
									前項のほか、以下に該当する場合、当社は、新規登録の申込後でも利用者の許可なくアカウントを削除することができます。
									<br />
									<br />
													<ol>
														<li>
									フリーメール以外のメールアドレスを保有していない方
														</li>
														<li>
									第5条1項各号に定める事由があると認められる方
														</li>
													</ol>
												</li>
									<br />
												<li>
									当社が、利用者登録の申込を承諾しない場合、利用希望者に対し、承諾しない理由を開示したり説明したりする義務を負わず、承諾しないことによって利用希望者に生じる損害については一切責任を負いません。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第5条　利用者資格の取消等
									<br />
									<br />
											<ol>
												<li>
									当社は、利用者につき、以下の各号のいずれかに該当する場合は、利用資格を取り消したり、一時的に停止したり、利用資格に伴う権利自体を取り消したり、又は、将来にわたって本サイト、本アプリのご利用をお断りする場合があります。
									<br />
									<br />
													<ol>
														<li>
									不実又は誤認を与える内容にて申込みが行われた場合
														</li>
														<li>
									利用者が、過去に当社が提供する各サービス等において、又は過去に他の利用者との取引において契約上の義務を怠ったことがある場合。また、今後も怠る恐れがあると当社が判断した場合
														</li>
														<li>
									法令又は本規約に違反した場合
														</li>
														<li>
									本規約第11条に定める禁止行為を過去に行い、又は将来行うおそれがあると認められる場合
														</li>
														<li>
									不正行為があった場合
														</li>
														<li>
									利用者資格を満たしていないことが明らかになり、又は満たさなくなった場合
														</li>
														<li>
									本サイト、本アプリの継続的な提供が合理的な理由により困難であると当社が判断した場合
														</li>
														<li>
									その他、当社が、業務の遂行上著しい支障があると判断した場合
									<br />
									<br />
														</li>
													</ol>
												</li>
												<li>
									当社は、前項によって定める処置により生ずる一切の損害について、直接的か間接的かを問わず、一切の責任を負わないものとします。当社は、前項に定める措置をなした時点で当該利用者に支払われることとなっていた金銭について、当社の判断により、一定期間その支払を留保したり、支払をせずに当社が指定する他の方法により精算したりすることができるものとします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第6条　利用者の退会
									<br />
									<br />
											<ol>
												<li>
									利用者は、本サイト又は本アプリ所定の方法により本サービス利用契約を解約することができます。なお、本サービス利用契約の解約は即時有効とし、当該利用者は本サービスを利用することができなくなるとともに、当社は、当社の裁量により、当該利用者の全ての利用者送信コンテンツの表示を停止又は削除することができます。また、当該利用者は、本サービス利用契約解約後も当社が第17条に定める方法により当該利用者の利用者送信コンテンツを保有・利用すること及び本サービス利用契約解約後も当社及びその他の第三者に対する本サービス利用契約上の一切の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではないことを、予め異議無く承諾するものとします。
												</li>
									<br />
												<li>
									前項の場合、当社は、当社の裁量により、当該利用者の利用者情報を削除することができるものとします。また、当社は、本条に基づく本サービス利用契約の解約により利用者、当社及び第三者に生じた損害につき、一切責任を負いません。
												</li>
									<br />
												<li>
									利用者は、退会後に再度本サービスの利用を希望する際は、再度登録手続を行う必要があります。利用者は、再度の登録手続後、退会前のデータが引き継がれないことを予め異議無く承諾するものとします。
												</li>
											</ol>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第3章 本サイト、本アプリの利用</span>
									<br />
									<br />
									<ul>
										<li>
									第7条　本サイト、本アプリの役割と取引の責任について
									<br />
									<br />
											<ul>
												<li>
									本サイト、本アプリはクラウド上でのご利用を前提とした統合サービスです。インターネット回線を利用して勤怠の管理、給与計算、電子申請、プロジェクト管理、等が行えます。
												</li>
											</ul>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第4章 利用者の責任</span>
									<br />
									<br />
									<ul>
										<li>
									第8条　利用環境の整備
									<br />
									<br />
											<ul>
												<li>
									利用者は、本サイト、本アプリを利用するために必要な通信機器、ソフトウェア、その他これらに付随して必要となる全ての機器を、自己の費用と責任において準備し、本サイト、本アプリが利用可能な状態に置くものとします。また、自己の費用と責任で、任意の電気通信サービスを経由して本サイトに接続するものとします。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									第9条　自己責任の原則
									<br />
									<br />
											<ul>
												<li>
									利用者は、利用者による本サイト、本アプリの利用とその本サイト、本アプリを利用してなされた行為とその結果について一切の責任を負います。利用者は、本サイト、本アプリの利用により本サイト、本アプリ又は他者に対して損害を与えた場合（利用者が、本利用規約上の義務を履行しないことにより他者又は本サイト、本アプリが損害を被った場合を含みます。）、自己の責任と費用をもって損害を賠償するものとします。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									第10条　メールアドレス、パスワードの管理
									<br />
									<br />
											<ol>
												<li>
									利用者は、登録したメールアドレス、パスワード等を自己の管理の下で管理するものとします。利用者は、利用資格・メールアドレス、パスワード、等を第三者に利用・譲渡・売買・貸与、その他形態を問わず処分することはできません。
												</li>
									<br />
												<li>
									メールアドレス、パスワードを利用して行われたあらゆる行為は、第三者が利用者本人の同意なく行った場合や不正に使用した場合であっても、メールアドレスを保有している利用者自身による行為とみなし、その責任を負うことに同意するものとします。また、その行為によって生じた一切の損害について、当社は利用者の故意や過失の有無を問わず一切の責任を負いません。
												</li>
									<br />
												<li>
									メールアドレス、パスワードの情報が第三者に漏洩した場合、又はその虞がある場合、速やかに当社までご連絡ください。ただし、当社は、当該メールアドレス、パスワードによるサービス利用の停止又は終了を行うことはできますが、その情報漏洩によって生じたあらゆる損害について一切の責任を負いません。
												</li>
									<br />
												<li>
									利用者は、メールアドレス、パスワード等の盗用、紛失、第三者による不正利用に起因する、一時的な本サイト、本アプリの使用停止等についての調査、生じた損害、得べかりし利益等の一切を当社に請求できないこととします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第11条　本サイトの取引に関する禁止事項
									<br />
									<br />
											<ol>
												<li>
									当社は、全ての利用者が法令に則って安全且つ快適に利用して頂くために、利用者に対し、以下に関連する行為を禁止します。利用者が以下に該当する行為を行った場合、当社は、その故意・過失であるかにかかわらず違反行為とみなすことができるものとします。
									<br />
									<br />
													<ol>
														<li>
									他の利用者、当社又は他者の商標権、著作権などの知的財産権、プライバシー権、肖像権、その他の権利を侵害する行為、その他他人に経済的損害を与える行為
														</li>
														<li>
									本規約若しくは法令に違反する行為、詐欺等の犯罪に結びつく行為、犯罪を扇動し、教唆する行為
														</li>
														<li>
									公序良俗に反する行為
														</li>
														<li>
									性行為や性的サービスを想起させる表現、わいせつ、児童ポルノ又は児童虐待に相当する画像、文書等を送信若しくは表示する行為、 又はこれらを収録した媒体を販売する行為、又はその送信、表示、販売を想起させる広告を表示又は送信する行為
														</li>
														<li>
									医療・弁護・法的文書作成・旅行代理など許認可・登録・届出等が必要となる行為
														</li>
														<li>
									他の利用者、当社又は他人に対して、プライバシーを侵害したり、名誉若しくは信用その他他人に精神的損害を与えたりする行為、ストーカー行為等の規制等に関する法律に違反する行為、大量又は長時間の架電、同様の問い合わせの繰り返しを過度に行う行為、メッセージ機能により大量のメッセージを送信する行為、義務や理由のないことを強要する行為
														</li>
														<li>
									人種差別、部落差別その他の差別を意識させる行為
														</li>
														<li>
									出会い系サービスに関連する行為
														</li>
														<li>
									脱法ドラッグ（いわゆる合法ドラッグ）に関する行為
														</li>
														<li>
									自身の詳細な個人情報又は他の利用者、当社若しくは他者の個人情報（電話番号や住所等）を発信及び公開する行為、又は依頼内容において、提案時に利用者自身の詳細な個人情報の記載を要求する行為
														</li>
														<li>
									本サイト外で本サイト及び本サイト付随機能を商業目的で利用する行為、無限連鎖講及びマルチ商法、又はそれに類するもの、その恐れのあるもの、あるいは当社が無限連鎖講及びマルチ商法、又はそれに類するもの、その恐れのあるものと判断する内容を掲載する行為
														</li>
														<li>
									本サイト若しくは本サイトの一部（コンテンツ・情報・機能・システム・プログラム等）を使用・転用・転売・複製・送信・翻訳・翻案などして、いかなる手法を問わず商業・営業目的の活動、営利を目的とした利用及びその準備を目的とした利用をすること、その他本サイトの2次利用や複製行為
														</li>
														<li>
									本サイトのセキュリティホールやバグの利用・不正アクセスや人為的な高負荷アクセスを発生させる行為、本サイトによりアクセス可能な本サイト又は他者の情報を改ざん、消去する行為、他者の設備又は本サイト用設備（本サイトがサービスを提供するために用意する通信設備、通信回線、電子計算機、その他の機器及びソフトウェアをいい、以下同様とします。）に無権限でアクセスし、又はその利用若しくは運営に支障を与える行為、本サイトを逆アセンブル、逆コンパイル、リバースエンジニアリングする行為。
														</li>
														<li>
									本サイトにコンピュータウィルス等の不正プログラムを送信する行為、又は他者が受信可能な状態におく行為
														</li>
														<li>
									本サイトを広告や宣伝・営業手段として利用する行為
														</li>
														<li>
									本サイトを翻訳・転載・引用・複写・コピーなどをして利用する行為
														</li>
														<li>
									当社からの連絡に対する不当な応答の遅延や無視を行う行為
														</li>
														<li>
									ステルスマーケティング等に該当する行為、又は、ステルスマーケティング等に利用する行為
														</li>
														<li>
									その他、当社が不適当と判断した行為
									<br />
									<br />
														</li>
													</ol>
												</li>
												<li>
									利用者が前項各号に違反し、当社、他の利用者又は他者に対して損害を与えた場合、故意過失を問わず、利用者は自己の責任と費用をもって損害を賠償するものとします。
												</li>
									<br />
												<li>
									本条第1項各号の内容は、本規約改定により適宜追加修正される場合があるものとし、利用者は、本サイトの利用にあたり、常に最新の内容を確認する義務を負うものとします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第12条　監視業務
									<br />
									<br />
											<ul>
												<li>
									当社は、利用者が本利用規約に従った本サービスの利用をしているか、また、本利用規約に反する行為や不正がないかを監視する業務を独自の裁量で行う権利を有するものとします。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									第13条　第三者の知的財産権の尊重
									<br />
									<br />
											<ol>
												<li>
									利用者は、他の利用者等及び第三者の知的財産権を尊重する義務を負います。
												</li>
												<li>
									利用者が前項の義務を怠ったことにより、権利者又は権利者から許諾を受けた者との間で紛争やトラブルが生じた場合は、利用者の責任と費用で解決するものとし、当社には一切迷惑をかけないものとします。万一、当社が損害を被った場合は、利用者は当社に当該損害を賠償する責任を負います。
												</li>
											</ol>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第5章 運営・免責</span>
									<br />
									<br />
									<ul>
										<li>
									第14条　本サイト、本アプリの一時的な中断
									<br />
									<br />
											<ol>
												<li>
									当社は、以下のいずれかの事由が生じた場合には、利用者に事前に通知することなく、一時的に本サイト、本アプリを中断することがあります。その場合に本サイト、本アプリ内の利用・有効期限は延長される場合があります。
									<br />
									<br />
													<ol>
														<li>
									本サイト用設備等の保守を定期的に又は緊急に行う場合
														</li>
														<li>
									火災、停電等により本サイトの提供ができなくなった場合
														</li>
														<li>
									地震、噴火、洪水、津波等の天災により本サイトの提供ができなくなった場合
														</li>
														<li>
									戦争、動乱、暴動、騒乱、労働争議等により本サイト、本アプリの提供ができなくなった場合
														</li>
														<li>
									当社の使用する設備やシステム等の障害、保守及びメンテナンス等の事由による場合
														</li>
														<li>
									その他、運用上又は技術上当社が本サイト、本アプリの一時的な中断が必要と判断した場合
									<br />
									<br />
														</li>
													</ol>
												</li>
												<li>
									当社は、前項各号のいずれか、又はその他の事由により本サイト、本アプリの提供の遅延又は中断等が発生したとしても、これに起因する利用者又は他者が被った損害について、この本規約で特に定める場合を除き、一切責任を負わないものとします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第15条　本サイト、本アプリの知的財産権
									<br />
									<br />
											<ol>
												<li>
									本サイト、本アプリに含まれる工業所有権、ノウハウ、プログラム、著作権その他の知的財産権及びそれらに関連する全ての権利は当社又はそれらの所有者に帰属するものとし、利用者は、あらかじめ当社より書面による承諾を得た場合を除いて、本サイト、本アプリの模倣、本サイト、本アプリに含まれるノウハウの使用、画像等の著作物、ロゴ、文章等の著作物を複製、翻案、公衆送信その他権利の侵害、その他前記各権利を侵害する行為などはできないものとします。また、利用者は、著作権法に定める私的使用の範囲を超えて本サービスを複製することはできません。
												</li>
									<br />
												<li>
									利用者が本条の規定に違反して問題が生じた場合、利用者は、自己の費用と責任において問題を解決するものとし、当社に迷惑や損害を与えてはなりません。
												</li>
									<br />
												<li>
									本サービスは日本国内をサービス提供対象とし、当社は日本国外における権利者の知的財産権に対していかなる保証もせず、また一切の責任を負いません。
												</li>
									<br />
												<li>
									本サイト又は本アプリ上には商標、ロゴ及びサービスマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、本利用規約により利用者その他の第三者に対し何ら当該商標等を譲渡し、又は使用を許諾するものではなく、利用者は、本サイト又は本アプリ上の未登録商標に対し商標登録の出願をしてはならないものとします。また、利用者は本サービスに関わる発明その他の知的財産に関し特許権、実用新案権、意匠権、商標権を出願申請し、著作権に関する登録を申請してはならないものとします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第16条　非保証・免責
									<br />
									<br />
											<ol>
												<li>
									前条までに規定する内容の他、本条に定める場合、当社及び情報提供者は免責されるものとします。
												</li>
									<br />
												<li>
									当社は、利用者が本サービスを利用することにより知り得た情報に起因して損害を受けた場合でも、事由の如何を問わず、何らの責任を負わないものとします。
												</li>
									<br />
												<li>
									当社は、利用者が本サービスの提供を受けられなかったときでも、何ら責任を負わないものとします。本項における「本サービスの提供を受けられなかったとき」には、通信回線の障害その他事由の如何を問わず、利用者が本サービスの提供を受けることができなかったあらゆる場合を含むものとします。
												</li>
									<br />
												<li>
									当社は本サービスにおけるいかなるデータ、情報についてもウイルス等の不可抗力の要因により消去・変更されないことを保証するものではありません。利用者は、かかるデータ、情報を自己の責任において適宜保存するものとします。
												</li>
									<br />
												<li>
									利用者が利用した機器・回線・ソフトウェア等により生じた責任及び損害、並びに、利用者が本サイト、本アプリを利用する上での過誤による責任及び損害は、利用者が負うものとし、当社は利用者の故意や過失の有無を問わず、一切責任を負いません。
												</li>
									<br />
												<li>
									利用者は、本サイト、本アプリで作成したデータなどについて、自己の責任において保存するものとします。当社サーバー側では、これらのデータの保存内容について保証しないものとします。また、利用者は当社に対し、本サイト、本アプリで保存するデータの消失やサーバーの稼動停止によりクレーム、紛争、損害賠償の請求などが起こった場合の損害、責任について一切を免責するものとします。ただし、当社は、同データ等が消失（本人による削除は除きます。）し、又は他者により改ざんされた場合は、技術的に可能かつ合理的な範囲でデータ等の復旧に努めるものとし、その復旧への努力を以って、消失又は改ざんに伴う利用者又は他者からの損害賠償の請求を免れるものとします。またこの復旧への努力の程度は、当社がその裁量で判断決定することができるものとします。
												</li>
									<br />
												<li>
									当社は、サービス提供の状態、アクセスの可能性、使用の状態については一切保証しておりません。
												</li>
									<br />
												<li>
									当社は、本サイト、本アプリが利用者の皆様に役立つよう最大限の努力を行いますが、利用者に対する当社の責任は、利用者が支障なく本サイト、本アプリを利用できるよう、合理的な努力をもって本サイトを運営することに限られるものとし、本サイトの内容において、欠陥、一時停止、一部削除、変更、終了及びそれらが原因で発生した利用者又は他者の損害、本サイト、本アプリの利用により又は利用できなかったことにより発生した利用者の損害、並びに本サイトに起因する利用者又は他者の損害に対し、いかなる責任も負わないものとし、損害賠償義務を一切負わないものとします。
												</li>
									<br />
												<li>
									当社及び情報提供者は、原則として、利用者間の通信や活動に関与しません。万一利用者間で紛争や問題が生じた場合であっても、当該利用者間で解決するものとし、当社及び情報提供者はその責任を負いません。
												</li>
									<br />
												<li>
									利用者以外の第三者と利用者との間で紛争が起こった場合には、紛争の当事者である利用者は自己の責任で解決するものとし、当社及び情報提供者は一切関与する義務を負わないものとします。また、当該第三者が損害を被った場合には、当該利用者が賠償するものとし、当社及び情報提供者は一切の責任を負いません。
												</li>
									<br />
												<li>
									本サイト又は本アプリ上の情報には経営健全に関する分析予想が含まれている可能性がありますが、それらの記述は予想であり、当社は内容の正確性、信頼性等を保証するものではありません。当社は、当該情報に基づいて利用者が被ったいかなる損害についても、一切の責任を負いません。また、当社及び情報提供者は、本サイト又は本アプリ上のいかなる情報をも、更新又は訂正する義務を負わないものとします。
												</li>
									<br />
												<li>
									当社は、利用者が本サービスを利用することにより取得した情報に関する問い合せについては、対応する義務を負わないものとします。
												</li>
									<br />
												<li>
									当社は、利用者が本サービスを利用することにより取得した情報の完全性、正確性、確実性、有用性等について、何らの保証も行わず、責任を負担しないものとします。
												</li>
									<br />
												<li>
									当社は、本サービスに関連して利用者が被った損害について、当社に故意又は重過失があったときを除き、一切賠償の責任を負いません。なお、当社に故意又は重過失があった場合、及び消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が利用者に対して損害賠償責任を負う場合においても、当社の損害賠償責任の範囲は、当社の責に帰すべき事由により現実に発生した直接かつ通常の損害に限られるものとし、かつ、その賠償額は損害の事由が生じた時点から遡って過去1年間に当該利用者から現実に受領した本サービスの利用料金の総額を上限とします。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第17条　本規約違反への対処
									<br />
									<br />
											<ol>
												<li>
									当社は、利用者が本利用規約に違反した場合若しくはそのおそれのある場合、利用者による本サイトの利用に関し他者から本サイトにクレーム・請求等がなされ、かつ当社が必要と認めた場合、又はその他の理由で不適当と当社が判断した場合は、当該利用者に対し、次のいずれか又はこれらを組み合わせた措置を講ずることができます。
									<br />
									<br />
													<ol>
														<li>
									本利用規約に違反する行為又はそのおそれのある行為を止めること、及び同様の行為を繰り返さないことを要求し、これらを裁判上の差止請求として実現すること。
														</li>
														<li>
									他者との間で、クレーム・請求等の解消のための協議（裁判外紛争解決手続きを含みます。）を行うことを要求します。
														</li>
														<li>
									利用者が発信又は表示する情報を削除することを要求します。
														</li>
														<li>
									利用者が発信又は表示する情報の全部若しくは一部を削除し、又は他者が閲覧できない状態に置きます。
									<br />
									<br />
														</li>
													</ol>
												</li>
												<li>
									前項の規定は第9条その他本利用規約に定める利用者の自己責任の原則を否定するものではありません。
												</li>
									<br />
												<li>
									利用者は、第1項の規定が当社に同項に定める措置を講ずべき義務を課すものではないことを承諾します。また、利用者は、当社が第1項各号に定める措置を講じた場合に、当該措置に起因する結果に関し、当社を免責するものとします。
												</li>
									<br />
												<li>
									利用者は、第1項の第4号の措置が、当社の裁量により事前の通知なく行われることを承諾します。
									<br />
									<br />
												</li>
											</ol>
										</li>
										<li>
									第18条　違約金及び損害賠償等
									<br />
									<br />
											<ol>
												<li>
									利用者が本利用規約に掲げる禁止事項に違反し、または、不正もしくは違法な行為を行ったことにより当社または第三者に損害が生じた場合、利用者はその一切の損害（弁護士費用、当社が第三者に行ったお詫びもしくは謝罪広告の費用を含む）を当社に賠償する責任を負います。
												</li>
									<br />
												<li>
									利用者が本利用規約に掲げる禁止事項に違反して第5条の措置がとられた場合は、当社は、違約金として1項に定める損害賠償金に相当する金額を、当該利用者から没収できるものとします。
												</li>
											</ol>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第6章 個人情報の保護</span>
									<br />
									<br />
									<ul>
										<li>
									第19条　個人情報の収集目的
									<br />
									<br />
											<ul>
												<li>
									当社は、個人情報およびそれに類する情報を「プライバシーポリシー(別ページ）」に基づき、適切に取り扱うものとします。
												</li>
											</ul>
										</li>
									</ul>
									<br />
									<span style="font-weight:bold;">第7章 その他</span>
									<br />
									<br />
									<ul>
										<li>
									第20条　通知または連絡
									<br />
									<br />
											<ul>
												<li>
									利用者への通知または連絡が必要な場合には、メールを用いて行います。利用者が、当社に対し連絡が必要であると判断した場合には、メールを用いて連絡を行うものとします。当社では、当社が特に必要と認めた場合を除き、電話または来訪によるご連絡は受け付けしておりません。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									第21条　準拠法
									<br />
									<br />
											<ul>
												<li>
									本利用規約、本サービス利用契約、及びこれに関する一切の事項については、日本国法を準拠法とし、本利用規約及び本サービス利用契約は、日本国法に従って解釈されるものとします。
									<br />
									<br />
												</li>
											</ul>
										</li>
										<li>
									第22条　専属的合意管轄裁判所
									<br />
									<br />
											<ul>
												<li>
									本サービスに関連して、利用者と当社の間で問題が生じた場合、両者は誠意を持って協議するものとし、協議しても解決しない場合には名古屋地方裁判所第一審の専属的合意管轄裁判所とします。
									<br />
									<br />
												</li>
											</ul>
										</li>
									</ul>
									<br />
									令和3年9月30日 制定・施行
									<br />
									<br />
								</div>
								<br />
								<br />
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'

  export default {
    components: {
      LoginHeader
    }
  }
</script>
<style scoped>
</style>
