<template>
  <div class="form-group">
    <label>{{ itemLabel }}</label><span class="required" v-show="rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span>
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <small class="help-block" v-show="valid | !validated">
          {{ helpText ? helpText.replace('{maxlength}', maxLength) : ''}}
        </small>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <EmployeeText
        v-model="itemValue"
        :item-name="itemName"
        :item-label="itemLabel"
        :placeholder1="$t('placeholder.employee_code')"
        :placeholder2="$t('placeholder.employee_name')"
        :disabled="disabled"
        :index="index"
        :salary-flag="salaryFlag"
        @showEmployeeSearch="$emit('showEmployeeSearch')"
        @input="input"
      >
      </EmployeeText>
    </ValidationProvider>
  </div>
</template>

<script>
  import EmployeeText from './EmployeeText'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      EmployeeText
    },
    props: {
      value: Array,
      itemName: String,
      rules: String,
      itemLabel: String,
      placeholder1: String,
      placeholder2: String,
      disabled: Boolean,
      helpText: String,
      salaryFlag: {
        type: Boolean,
        require: false,
        'default': undefined
      },
      index: Number
    },
    data () {
      return {
        itemValue: ['', '']
      }
    },
    methods: {
      input (sentValue) {
        this.$emit('input', sentValue)
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    },
    watch: {
      value () {
        this.itemValue = [...this.value]
      }
    },
    mounted () {
      this.itemValue = [...this.value]
    }

  }
</script>
