<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="Workflow" :draggable="true" :clickToClose="false" height="auto" :width="1400" :pivotX="0.6">
        <div class="modal-body">
          <Workflow :application-form-code="applicationFormCode" :approve-record="approveRecord" :is-modal="true" @close="hide" @show-attendance-record="showAttendanceRecord"/>
        </div>
      </modal>
      <modal name="approvalAttendanceRecord" :draggable="true" :clickToClose="false" height="auto" :width="1600" :pivotX="0.8">
        <div class="modal-body">
          <AttendanceRecordDetail @close="hideAttendanceRecord"/>
        </div>
      </modal>
      <modal name="approverlEmployee" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
        <div class="modal-body">
          <EmployeeSearch :orgSearchCond="employeeSearchCond" :is-modal="true" @close="hideApproverlEmployee"/>
        </div>
      </modal>
      <modal name="targetEmployee" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
        <div class="modal-body">
          <EmployeeSearch :orgSearchCond="employeeSearchCond" :is-modal="true" @close="hideTargetEmployee"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <!-- 申請番号 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.application_number}}</label>
              <input type="text" :placeholder="$t('placeholder.application_number')" maxlength="10" class="form-control numLength10" style="text-align:right" v-model="searchCond.application_number">
            </div>
            <!-- 申請分類 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.applicationClassification}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in comboData.application_classification_list" v-bind:key = item.value>
                <input type="checkbox" class="gray" :value="item.value" v-model="searchCond.application_classification" @change="applicationClassificationChanged"/>{{item.label}}&nbsp;
              </div>
            </div>
            <!-- 申請書 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.applicationForm}}</label><br>
              <vSelect name="application_form_code"
                        class="style-chooser length16"
                        :options="comboData.application_form_list"
                        v-model="searchCond.application_form_code">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>
            </div>
            <!-- 状態 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.activity_status}}</label><br>
              <div style="display: inline-block; padding: 6px 0;" v-for="item in comboData.activity_status4display_list" v-bind:key = item.value>
                <p-radio v-model="searchCond.activity_status4display" :label="item.value" :inline="true">{{$t(item.label)}}&nbsp;&nbsp;&nbsp;&nbsp;</p-radio>
              </div>
            </div>
            <br>
            <!-- 申請日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{labels.apply_date}}</label>
              <br>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.term_from" @change="dateSynchronizeFrom"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.term_to" @change="dateSynchronizeTo"></el-date-picker>
            </div>
            <!-- 対象日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{$t('label.label_header_target_date')}}</label>
              <br>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.target_date_from" @change="targetDateSynchronizeFrom"></el-date-picker>
              <label>&#12316;</label>
              <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.target_date_to" @change="targetDateSynchronizeTo"></el-date-picker>
            </div>
            <!-- 到達日 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <div style="display: inline-block">
                <label>{{labels.reaching_date}}</label>
                <br>
                <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_from')" v-model="searchCond.reaching_date_from" @change="reachingDateSynchronizeFrom"></el-date-picker>
                <label>&#12316;</label>
                <el-date-picker type="date" value-format="yyyy/MM/dd" :placeholder="$t('placeholder.term_to')" v-model="searchCond.reaching_date_to" @change="reachingDateSynchronizeTo"></el-date-picker>
              </div>
            </div>
            <br>
            <!-- 承認者の従業員 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{$t('label.approverl_employee')}}</label>
              <div>
                <input
                  type="text"
                  class="form-control numLength10"
                  style="display:inline-block;"
                  v-model="searchCond.approverl_employee_code"
                  @blur="approverlEmployeeSelected">
              </div>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>&nbsp;</label>
              <div>
                <div style="display: inline-block;">
                  <input
                    type="text"
                    class="form-control length20"
                    style="display:inline-block;"
                    :disabled="true"
                    v-model="approverlEmployeeName">
                </div>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="showApproverlSearch" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
              </div>
            </div>
            <!-- 対象者の従業員 -->
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>{{$t('label.target_employee')}}</label>
              <div>
                <input
                  type="text"
                  class="form-control numLength10"
                  style="display:inline-block;"
                  v-model="searchCond.target_employee_code"
                  @blur="targetEmployeeSelected">
              </div>
            </div>
            <div class="form-group search-condition-item" style="display: inline-block">
              <label>&nbsp;</label>
              <div>
                <div style="display: inline-block;">
                  <input
                    type="text"
                    class="form-control length20"
                    style="display:inline-block;"
                    :disabled="true"
                    v-model="targetEmployeeName">
                </div>
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="showTargetSearch" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
              </div>
            </div>
            <!-- 検索ボタン -->
            <template v-if="roles.search === 1">
              <div style="text-align: left;">
                <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </div>
            </template>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
      <template v-if="tableData && tableData.length > 0">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content">
            <el-select
              class="select-success length2"
              :disabled="isChanged"
              v-model="pagination.perPage">
              <el-option
                class="select-success"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="pagedData"
                        :empty-text="$t('label.no_data')"
                        :style="tableStyle"
                        ref="table"
                        :row-class-name="tableRowClassName">
                <!-- 承認者の従業員 -->
                <el-table-column v-if="showApproverlEmployee" width="180" :label="$t('label.approverl_employee')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.approverl_role_code ? props.row.approverl_role_name : props.row.approverl_employee_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.approverl_role_code ? '' : props.row.approverl_employee_code}}</small>
                    </div>
                    <div style="text-align: left; line-height: 100%;">
                      <small>{{props.row.approverl_role_code ? '' : props.row.approverl_group_name}}</small>
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.approverl_company_name}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 申請書 -->
                <el-table-column v-if="showApplicationFormName" width="200" :label="labels.application_form_name_ryaku === undefined ? labels.applicationFormName_ryaku === undefined ? '' : labels.applicationFormName_ryaku : labels.application_form_name_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.application_form_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.application_classification_name}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 承認者状態 -->
                <el-table-column v-if="showActivityStatus" :width="getColumnWidth(comboData.activity_status_list, labels.activity_status_ryaku, 'label', 'value')" :label="labels.activity_status_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: center">
                      <el-tag :style="`border-radius: 16px !important; color: white; background-color: ${props.row.color} !important;`">
                        {{getLabel(getSelectName(props.row.activity_status, comboData.activity_status_list))}}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
                <!-- 到達日 -->
                <el-table-column v-if="showReachingDate" width="180" :label="labels.reaching_date_ryaku === undefined ? labels.reachingDate_ryaku === undefined ? '' : labels.reachingDate_ryaku : labels.reaching_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: small; font-weight: bold;">
                      {{props.row.reaching_date}}
                    </div>
                    <div style="text-align: left;">
                      <template v-if="props.row.link_flag">
                        <a class="btn btn-simple btn-icon like" @click="show(props.$index, props.row)" :disabled="isChanged" :style="isChanged ? 'pointer-events: none; text-decoration: underline;' : 'text-decoration: underline;'">
                          {{labels.application_number + ' ' + props.row.application_number}}
                        </a>
                      </template>
                      <template v-else>
                        {{labels.application_number + ' ' + props.row.application_number}}
                      </template>
                    </div>
                    <div style="text-align: left;" v-if="props.row.re_application_number">
                      {{labels.re_application_number + ' ' + props.row.re_application_number}}
                    </div>
                  </template>
                </el-table-column>
                <!-- 処理日 -->
                <el-table-column v-if="showProcessDate" width="180" :label="labels.process_date_ryaku === undefined ? labels.processDate_ryaku === undefined ? '' : labels.processDate_ryaku : labels.process_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: small; font-weight: bold;">
                      {{props.row.process_date}}
                    </div>
                    <div style="text-align: left;">
                      <template v-if="props.row.process_date">
                        {{getLabel(getSelectName(props.row.approval_function, comboData.approval_function_list))}}
                      </template>
                      <template v-else>
                        {{$t('label.not_processed')}}
                        <br>
                      </template>
                    </div>
                  </template>
                </el-table-column>
                <!-- 対象者の従業員  -->
                <el-table-column v-if="showTargetEmployee" :width="getTargetColumnWidth(tableData, $t('label.target_employee'), 'target_employee_name')" :label="$t('label.target_employee')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium; font-weight: bold;">
                      {{props.row.target_employee_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.target_employee_code}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 申請者の従業員  -->
                <el-table-column v-if="showTargetEmployee" :width="getTargetColumnWidth(tableData, $t('label.applicant_employee'), 'applicant_employee_name')" :label="$t('label.applicant_employee')">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.applicant_employee_name}}
                    </div>
                    <div style="text-align: left;">
                      <small>{{props.row.applicant_employee_code}}</small>
                    </div>
                  </template>
                </el-table-column>
                <!-- 申請日 -->
                <el-table-column v-if="showApplyDate" :width="getDateColumnWidth(tableData, labels.apply_date_ryaku, 'apply_date')" :label="labels.apply_date_ryaku === undefined ? labels.applyDate_ryaku === undefined ? '' : labels.applyDate_ryaku : labels.apply_date_ryaku">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      {{props.row.apply_date}}
                    </div>
                    <template v-if="!props.row.target_date || props.row.target_date.indexOf('～') === -1">
                      <div style="text-align: left;">
                        {{props.row.target_date ? '(' + $t('label.label_header_target_date') + ':' + props.row.target_date + ')' : ''}}
                      </div>
                    </template>
                    <template v-else>
                      <div style="text-align: left;">
                        {{'(' + $t('label.label_header_target_date') + ':' + props.row.target_date.substring(0, props.row.target_date.indexOf('～'))}}
                      </div>
                      <div style="text-align: left;">
                        {{props.row.target_date.substring(props.row.target_date.indexOf('～')) + ')'}}
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
              </div>
              <div class="row" ref="pager">
                <div class="col-sm-6 pagination-info">
                  <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total"
                                :disabled="isChanged"
                                @pageChanged="pageChanged">
                  </p-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, DatePicker, Tag} from 'element-ui'
  import vSelect from 'vue-select'
  import Workflow from '@/components/Dashboard/Views/Working/Workflow/Workflow'
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import PPagination from '@/components/UIComponents/Pagination'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Tag)
  export default{
    mixins: [mixin],
    components: {
      vSelect,
      Workflow,
      AttendanceRecordDetail: () => import('@/components/Dashboard/Views/Working/AttendanceRecordDetail'),
      EmployeeSearch,
      PPagination,
      'el-date-picker': DatePicker
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        componentKey: 0,
        draggable: true,
        isChanged: false,
        beforePerPage: 25,
        beforeCurrentPage: 1,
        showSearchCondition: true,
        modalWidth: 800,
        modalWidthSub: 1100,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100],
          total: 0
        },
        tableData: undefined,
        searchCond: {
          application_number: '',
          application_classification: [],
          activity_status4display: undefined,
          term_from: '',
          term_to: '',
          target_date_from: '',
          target_date_to: '',
          reaching_date_from: '',
          reaching_date_to: '',
          approverl_employee_code: '',
          target_employee_code: ''
        },
        employeeSearchCond: {
          company_code: '',
          office_code: '',
          group_code: '',
          team_code: '',
          office_range: '',
          group_range: '',
          team_range: '',
          search_employee_code: '',
          search_employee_name: '',
          employee_classification_code: '',
          from_year: '',
          from_month: '',
          to_year: '',
          to_month: '',
          working_month_from: '',
          working_month_to: '',
          employee_code: '',
          search_type: '0',
          retirement: false,
          search_date_type: '9'
        },
        searchedApiArea: {},
        showOperation: true,
        showApplicationClassification: true,
        showApplicationFormName: true,
        showActivityStatus: true,
        showReachingDate: true,
        showApprovalFunction: true,
        showProcessDate: true,
        showApplyDate: true,
        showApproverlCompanyName: true,
        showApproverlGroup: true,
        showApproverlEmployee: true,
        showTargetEmployee: true,
        showApplicantEmployee: true,
        showApplicationNumber: true,
        showReApplicationNumber: true,
        labels: {},
        roles: {},
        comboData: {},
        applicationFormCode: '',
        approveRecord: {},
        tableWrapperStyle: '',
        tableStyle: '',
        approverlEmployeeName: '',
        targetEmployeeName: ''
      }
    },
    methods: {
      applicationClassificationChanged () {
        const applicationFormList = []
        for (const row of this.searchCond.application_classification) {
          if (this.comboData.application_classification_form_mapping_list[row]) {
            for (const row3 of this.comboData.application_classification_form_mapping_list[row]) {
              for (const row4 of this.comboData.org_application_form_list) {
                if (row3 === row4.value) {
                  applicationFormList.push(row4)
                  break
                }
              }
            }
          }
        }
        this.comboData.application_form_list = applicationFormList
      },
      approverlEmployeeSelected () {
        this.approverlEmployeeName = ''
        if (!this.searchCond.approverl_employee_code) return
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.searchCond.approverl_employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: false
          }
        }
        this.send('/view/search_employee/list', searchCond, false)
        .then(res => {
          const selected = res.api_area.employee_list[0]
          if (selected) {
            this.approverlEmployeeName = selected.employee_name
          }
        })
      },
      targetEmployeeSelected () {
        this.targetEmployeeName = ''
        if (!this.searchCond.target_employee_code) return
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.searchCond.target_employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: false
          }
        }
        this.send('/view/search_employee/list', searchCond, false)
        .then(res => {
          const selected = res.api_area.employee_list[0]
          if (selected) {
            this.targetEmployeeName = selected.employee_name
          }
        })
      },
      showApproverlSearch () {
        this.$modal.show('approverlEmployee')
      },
      hideApproverlEmployee (selected) {
        if (selected) {
          this.searchCond.approverl_employee_code = selected.employee_code
          this.approverlEmployeeName = selected.employee_name
        }
        this.$modal.hide('approverlEmployee')
      },
      showTargetSearch () {
        this.$modal.show('targetEmployee')
      },
      hideTargetEmployee (selected) {
        if (selected) {
          this.searchCond.target_employee_code = selected.employee_code
          this.targetEmployeeName = selected.employee_name
        }
        this.$modal.hide('targetEmployee')
      },
      showAttendanceRecord () {
        this.$modal.show('approvalAttendanceRecord')
      },
      hideAttendanceRecord () {
        this.$modal.hide('approvalAttendanceRecord')
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      customValidate (apiArea) {
        // 申請番号数字チェック
        // ブランクならnullに変換
        if (apiArea.application_number === '') {
          apiArea.application_number = null
          // 数字以外ならエラーメッセージを返却
        } else if (isNaN(apiArea.application_number)) {
          return this.$t('message.decimal_field_required').replace('{_field_}', this.labels.application_number)
          // 上記以外の場合、数字に変換
        } else {
          apiArea.application_number = parseInt(apiArea.application_number)
        }
        return null
      },
      afterInit (res) {
        this.comboData.application_classification_form_mapping_list = res.common_area.combo_data.application_classification_form_mapping_list.application_classification_form_mapping_list
        this.comboData.org_application_form_list = [...res.common_area.combo_data.application_classification_form_mapping_list.application_form_list]
        this.comboData.activity_status4display = this.comboData.activity_status4display_list
      },
      getDateColumnWidth (rows, columnNameValue, columnName1) {
        let maxBytes = this.getBytes(columnNameValue)
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1])
            if (bytes > maxBytes) {
              maxBytes = 14
              break
            }
          }
          if (maxBytes === 14) {
            break
          }
        }
        return maxBytes * 8 + 20
      },
      getTargetColumnWidth (rows, columnNameValue, columnName1) {
        let maxBytes = this.getBytes(columnNameValue)
        if (rows) {
          for (const row of rows) {
            if (row[columnName1]) {
              const bytes = this.getBytes(row[columnName1])
              if (bytes > maxBytes) {
                maxBytes = bytes
              }
            }
            if (row['target_employee_code'] !== row['applicant_employee_code']) {
              const bytes = this.getBytes(row['target_employee_name'] + row['applicant_employee_name'])
              if (bytes > maxBytes) {
                maxBytes = bytes
              }
            }
          }
        }
        return maxBytes > 30 ? maxBytes * 7 + 20 : maxBytes * 8 + 20
      },
      pageChanged () {
        if (this.beforeCurrentPage === this.pagination.currentPage) return
        this.isChanged = true
        this.search(false)
      },
      search (searchButtonFlag = true) {
        let apiArea
        // 検索ボタンの場合、検索条件を設定する
        if (searchButtonFlag) {
          apiArea = {
            application_number: this.searchCond.application_number,
            application_classification_code_list: this.convertAssociative('application_classification_code', this.searchCond.application_classification),
            application_form: this.searchCond.application_form_code ? this.searchCond.application_form_code.value : '',
            activity_status4display_list: [{'activity_status4display': parseInt(this.searchCond.activity_status4display, 10)}],
            apply_date_from: this.searchCond.term_from,
            apply_date_to: this.searchCond.term_to,
            target_date_from: this.searchCond.target_date_from,
            target_date_to: this.searchCond.target_date_to,
            reaching_date_from: this.searchCond.reaching_date_from,
            reaching_date_to: this.searchCond.reaching_date_to,
            pagerCount: this.pagination.currentPage,
            limit: this.pagination.perPage
          }
          if (this.searchCond.approverl_employee_code) {
            apiArea.approverl_employee_code = this.searchCond.approverl_employee_code
          }
          if (this.searchCond.target_employee_code) {
            apiArea.target_employee_code = this.searchCond.target_employee_code
          }
          // ページングの場合、前回の検索条件にページカウントとリミットを設定する
        } else {
          // ダミーデータを設定する
          this.tableData = this.getDummyData(this.tableData, this.pagination.currentPage, this.pagination.perPage, this.beforeCurrentPage, this.beforePerPage)
          this.componentKey += 1
          apiArea = this.searchedApiArea
          apiArea.pagerCount = this.pagination.currentPage
          apiArea.limit = this.pagination.perPage
        }
        // APIに渡す検索条件を設定
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: apiArea
        }
        this.nullToBlank(searchCond.api_area)
        const message = this.customValidate(searchCond.api_area)
        if (message !== null) {
          this.message.text = message
          return
        }
        // 検索条件をディープコピー
        this.searchedApiArea = Object.assign({}, apiArea, {})
        // 検索API呼び出し
        this.send('/workflow/view/wf_approval/list', searchCond)
        .then(res => {
          if (searchButtonFlag) {
            this.pagination.currentPage = 1
          }
          this.tableData = this.getColoredData(Array.isArray(res.api_area) ? res.api_area : res.api_area.approval_info_data)
          let labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.labels = {...this.labels, ...labels}
          for (const row of this.tableData) {
            for (const entry of Object.entries(row)) {
              if (entry[1] === null) {
                row[entry[0]] = ''
              }
            }
          }
          if (this.tableData.length > 0) {
            this.toggleSearchCondition()
          }
          // 表示するページ以外を空配列で埋める
          this.tableData = this.getPageList(this.tableData, this.pagination.currentPage, this.pagination.perPage, res.api_area.total_count)
          this.$nextTick(() => {
            let width = 0
            for (const child of this.$refs.table.$children) {
              if (child.width) {
                width += parseInt(child.width, 10)
              }
            }
            this.tableStyle = 'width: ' + (width + 1) + 'px'
            const maxWidth = window.innerWidth - 360
            if (width > maxWidth) {
              width = maxWidth
              this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
            } else {
              this.tableWrapperStyle = ''
            }
            this.$refs.pager.style.width = width + 'px'
            this.componentKey += 1
          })
          this.isChanged = false
          this.beforePerPage = this.pagination.perPage
          this.beforeCurrentPage = this.pagination.currentPage
        })
        .catch(error => {
          console.error(error)
        })
      },
      getColoredData (rows) {
        const tableData = []
        for (const row of rows) {
          switch (row.activity_status) {
            case 1:
              row.color = 'rgb(134, 140, 183)'
              break
            case 2:
              row.color = 'rgb(237, 128, 119)'
              break
            case 3:
              row.color = 'rgb(57, 57, 57)'
              break
            case 4:
              row.color = 'rgb(68, 136, 197)'
              break
            case 5:
              row.color = 'rgb(234, 44, 0)'
              break
            case 6:
              row.color = 'rgb(161, 175, 47)'
              break
            case 7:
              row.color = 'rgb(46, 175, 98)'
              break
            case 8:
              row.color = 'rgb(46, 175, 159)'
              break
            case 9:
              row.color = 'rgb(59, 157, 189)'
              break
            case 10:
              row.color = 'rgb(94, 181, 166)'
              break
            case 11:
              row.color = 'rgb(220, 153, 37)'
              break
            case 12:
              row.color = 'rgb(244, 40, 88)'
              break
            case 13:
              row.color = 'rgb(224, 123, 154)'
              break
          }
          console.log(row.color)
          tableData.push(row)
        }
        return tableData
      },
      indexCheck (list, code) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].code === code) {
            return i
          }
        }
        return -1
      },
      init () {
        // 初期表示
        // APIに渡すパラメータを設定
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode
        }
        // 初期化API呼び出し
        this.send('/workflow/init/wf_approval/list', params)
        .then(res => {
          // ラベル情報をthis.labelsに設定
          this.labels = this.getLabels(res.common_area)
          this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
          this.afterInit(res)
          // ボタン権限をthis.rolesに設定
          this.roles = res.common_area.button_role
          // 申請分類をチェック
          for (let result of this.comboData.application_classification_list) {
            this.searchCond.application_classification.push(result.value)
          }
          this.searchCond.activity_status4display = '2' // 承認待ち
        })
      },
      show (index, row) {
        this.applicationFormCode = row.application_form_code
        this.approveRecord = {
          screen_mode: row.screen_mode,
          target_company_code: row.target_company_code,
          target_group_code: row.target_group_code,
          target_employee_code: row.target_employee_code,
          approverl_company_code: row.approverl_company_code,
          approverl_group_code: row.approverl_group_code,
          approverl_employee_code: row.approverl_employee_code,
          approverl_role_code: row.approverl_role_code,
          application_number: row.application_number,
          route_type: row.route_type,
          route_number: row.route_number
        }
        this.$modal.show('Workflow')
      },
      hide () {
        this.$modal.hide('Workflow')
      },
      tableRowClassName () {
        return ''
      },
      // 申請日のシンクロ(FROM)
      dateSynchronizeFrom () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_to = this.searchCond.term_from
        }
      },
      // 申請日のシンクロ(TO)
      dateSynchronizeTo () {
        let from = this.searchCond.term_from
        let to = this.searchCond.term_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.term_from = today
        }
        if (to > today) {
          this.searchCond.term_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.term_from = to = this.searchCond.term_to
        }
      },
      // 対象日のシンクロ(FROM)
      targetDateSynchronizeFrom () {
        let from = this.searchCond.target_date_from
        let to = this.searchCond.target_date_to
        if (from > to && to !== '') {
          this.searchCond.target_date_to = this.searchCond.target_date_from
        }
      },
      // 対象日のシンクロ(TO)
      targetDateSynchronizeTo () {
        let from = this.searchCond.target_date_from
        let to = this.searchCond.target_date_to
        if (from > to && to !== '') {
          this.searchCond.target_date_from = this.searchCond.target_date_to
        }
      },
      // 到達日のシンクロ(FROM)
      reachingDateSynchronizeFrom () {
        let from = this.searchCond.reaching_date_from
        let to = this.searchCond.reaching_date_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.reaching_date_from = today
        }
        if (to > today) {
          this.searchCond.reaching_date_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.reaching_date_to = this.searchCond.reaching_date_from
        }
      },
      // 到達日のシンクロ(TO)
      reachingDateSynchronizeTo () {
        let from = this.searchCond.reaching_date_from
        let to = this.searchCond.reaching_date_to
        let today = this.formatToday()
        if (from > today) {
          this.searchCond.reaching_date_from = today
        }
        if (to > today) {
          this.searchCond.reaching_date_to = today
        }
        if (from > to && to !== '') {
          this.searchCond.reaching_date_from = to = this.searchCond.reaching_date_to
        }
      },
      // stringのリストをnumberのリストに変換
      stringListToNumberList (list) {
        var resultList = []
        for (let tmp of list) {
          resultList.push(Number(tmp))
        }
        return resultList
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
