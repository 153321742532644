<template>
  <div :key="componentKey">
    <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
      <div class="modal-body">
        <!-- 従業員検索モーダル パラメータ：検索条件 -->
        <EmployeeSearch :orgSearchCond="convertSearchCond" :is-modal="true" @close="hide"/>
      </div>
    </modal>
    <div v-if="legalRuleMode" class="form-group search-condition-item">
      <!-- 36協定監視の表示用ラジオボタン 0=従業員別 1=違反者、継続者のみ -->
      <p-radio v-model="searchCond.legal_rule_disp_type" label="0" :inline="true" :disabled="disabled">{{'従業員別'}}</p-radio>
      <p-radio v-model="searchCond.legal_rule_disp_type" label="1" :inline="true" :disabled="disabled">{{'違反者、継続者のみ'}}</p-radio>
    </div>
    <div class="form-group search-condition-item">
      <!-- 検索条件ラジオボタン 0=事業所で絞り込み 1=部署で絞り込み 2=チームで絞り込み 3=従業員で絞り込み -->
      <p-radio v-model="searchCond.search_type" label="0" :inline="true" :disabled="disabled">{{labels.office ? $t('label.filter_by').replace('{item}', labels.office) : ''}}</p-radio>
      <p-radio v-model="searchCond.search_type" label="1" :inline="true" :disabled="disabled">{{labels.group ? $t('label.filter_by').replace('{item}', labels.group) : ''}}</p-radio>
      <p-radio v-model="searchCond.search_type" label="2" :inline="true" :disabled="disabled">{{labels.team ? $t('label.filter_by').replace('{item}', labels.team) : ''}}</p-radio>
      <p-radio v-model="searchCond.search_type" label="3" :inline="true" :disabled="disabled">{{labels.employee ? $t('label.filter_by').replace('{item}', labels.employee) : ''}}</p-radio>
    </div>
    <!--期間のチェックボックス -->
    <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="currentYearMonth">
      <div>
        <label>{{$t('label.term')}}</label>
        <div>
          <vSelect name="from_year"
                      class="style-chooser length5"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="years"
                      v-model="searchCond.from_year"
                      :disabled="isCurrentMonth"
                      @input="synchronizeFrom">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
          <vSelect name="from_month"
                      class="style-chooser length3"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="months"
                      v-model="searchCond.from_month"
                      :disabled="isCurrentMonth"
                      ref="fromMonth"
                      @input="synchronizeFrom">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
          ～
          <vSelect name="to_year"
                      class="style-chooser length5"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="years"
                      v-model="searchCond.to_year"
                      :disabled="isCurrentMonth"
                      @input="synchronizeTo">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
          <vSelect name="to_month"
                      class="style-chooser length3"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="months"
                      ref="toMonth"
                      v-model="searchCond.to_month"
                      :disabled="isCurrentMonth"
                      @input="synchronizeTo">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
        </div>
      </div>
    </div>
    <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="currentYearMonth">
      <div>
        <label for="isCurrentMonth">{{$t('label.this_month')}}</label>
        <div style="padding: 6px 0;">
          <input
            type="checkbox"
            v-model="isCurrentMonth"
            class="gray"
            id="isCurrentMonth"
            @click="isCurrentMonthClicked"
          >
        </div>
      </div>
    </div>
    <!--期間（36協定用） -->
    <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="legalRuleMode">
      <div>
        <label>{{$t('label.term')}}</label>
        <div>
          <template v-if="targetTerms !== null">
            <el-select class="select-success width300"
                        size="large"
                        placeholder=""
                        v-model="searchCond.target_term">
              <el-option v-for="term in targetTerms"
                          class="select-success"
                          :value="term.value"
                          :label="term.label"
                          :key="term.value">
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-select class="select-success widthYear"
                        size="large"
                        placeholder=""
                        v-model="searchCond.target_term">
              <el-option v-for="year in years"
                          class="select-success"
                          :value="year"
                          :label="year"
                          :key="year">
              </el-option>
            </el-select>
            &nbsp;{{$t('label.year')}}&nbsp;
          </template>
        </div>
      </div>
    </div>
    <!-- 事業所名プルダウン(検索条件：0の場合のみ表示) -->
    <template v-if="searchCond.search_type=='0'">
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.office}}</label>
        <div>
          <vSelect name="office_code"
                      class="style-chooser length20"
                      :options="offices"
                      v-model="searchCond.office_code">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>
        </div>
      </div>
    </template>
    <!-- 部署名プルダウン(検索条件：1の場合のみ表示) -->
    <template v-if="searchCond.search_type=='1'">
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.group}}</label>
        <div>
          <vSelect name="group_code"
                      class="style-chooser length20"
                      :options="groups"
                      v-model="searchCond.group_code">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>
        </div>
      </div>
    </template>
    <!-- チーム名プルダウン(検索条件：2の場合のみ表示) -->
    <template v-if="searchCond.search_type=='2'">
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.team}}</label>
        <div>
          <vSelect name="team_code"
                      class="style-chooser length20"
                      :options="teams"
                      v-model="searchCond.team_code">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>
        </div>
      </div>
    </template>
    <!-- 従業員番号、従業員名、従業員検索ボタン(検索条件：3の場合のみ表示) -->
    <template v-if="searchCond.search_type=='3'">
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.employee_code}}</label>
        <div>
          <input
            type="text"
            class="form-control width130"
            style="display:inline-block;"
            :disabled="disabled"
            :placeholder="labels.PH_CODE"
            v-model="searchCond.employee_code"
            @blur="employeeSelected"
          >
        </div>
      </div>
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.employee_name}}</label>
        <div>
          <div style="display: inline-block;">
            <input
              type="text"
              class="form-control width300"
              style="display:inline-block;"
              :disabled="disabled"
              :placeholder="labels.PH_EMPLOYEENAME"
              v-model="searchCond.employee_name">
          </div>
          <button type="button" class="btn btn-sm btn-default btn-warning" :disabled="disabled" @click="show" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
        </div>
      </div>
    </template>
    <!-- 従業員区分名(検索条件：3以外の場合のみ表示) -->
    <template v-if="searchCond.search_type!='3'">
      <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
        <label>{{labels.employeeClassification}}</label>
        <div>
          <vSelect name="employee_classification"
                      class="style-chooser length12"
                      :options="employee_types"
                      v-model="searchCond.employee_classification_code">
            <div slot="no-options">{{$t('message.no_matching_options')}}</div>
          </vSelect>
        </div>
      </div>
    </template>
    <br/>
    <!-- 入社年度別検索用のチェックボックス -->
    <template v-if="hireYearMode">
      <div class="form-group" style="display: inline-block; vertical-align: top;">
        <div>
          <input type="checkbox" v-model="searchCond.hireYear" class="gray" :disabled="disabled" />
          <label>{{$t('label.hire_year')}}</label>
        </div>
      </div>
      <br/>
    </template>
    <!-- 該当者のみ用のチェックボックス -->
    <template v-if="showQualifiedEmployee">
      <div class="form-group" style="display: inline-block; vertical-align: top;">
        <div>
          <input type="checkbox" v-model="searchCond.qualified_employee" class="gray" :disabled="disabled" />
          <label>{{$t('label.qualified_employee')}}</label>
        </div>
      </div>
      <br/>
    </template>
    <!-- 賃金台帳用の対象年プルダウン -->
    <div class="form-group search-condition-item" v-if="showTargetYear">
      <label>{{$t('label.target_year')}}</label>
      <div>
        <el-select class="select-success numLength6"
                    size="large"
                    placeholder=""
                    :disabled="disabled"
                    v-model="searchCond.target_year">
          <el-option v-for="year in years"
                      class="select-success"
                      :value="year"
                      :label="year"
                      :key="year">
          </el-option>
        </el-select>
        {{$t('label.year')}}
      </div>
    </div>
    <!-- 給与明細作成用の給与締め日プルダウン -->
    <div class="form-group search-condition-item" v-if="payslipMode">
      <label>{{$t('label.salary_closing')}}</label>
      <div>
        <el-select class="select-success length20"
                    size="large"
                    placeholder=""
                    :disabled="disabled"
                    v-model="searchCond.salary_closing_code">
          <el-option v-for="row in comboData.salary_closing_list"
                      class="select-success"
                      :value="row.value"
                      :label="row.label"
                      :key="row.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 退職者検索用のチェックボックス -->
    <div class="form-group" style="display: inline-block; vertical-align: top;" v-if="showSearchAll">
      <div>
        <input type="checkbox" v-model="searchCond.retirement" class="gray" :disabled="disabled || retirementDisabledFlg" id="retirement" />
        <label for="retirement">{{$t('label.search_all_employees')}}</label>
      </div>
    </div>
    <!-- 賞与用のチェックボックス -->
    <div class="form-group" style="display: inline-block; vertical-align: top;" v-if="bonusMode">
      <div>
        <input type="checkbox" v-model="searchCond.including_outside_period" class="gray" :disabled="disabled" id="outside" />
        <label for="outside">{{$t('label.including_outside_period')}}</label>
      </div>
    </div>
    <div class="search-button-area">
      <!-- 監視条件登録ボタン -->
      <template v-if="legalRuleMode === true">
        <button type="button" class="btn btn-warning btn-sm btn-default" :disabled="disabled" @click="$emit('addMonitoringConditions')" v-show="canShow('/SS/menuSSScreen015')">{{$t('button.addMonitoringConditions')}}</button>
      </template>
      <!-- 新規追加ボタン -->
      <template v-if="labels.entry === 1 && showAddBtn === true">
        <button type="button" class="btn btn-primary btn-sm btn-default" :disabled="disabled" @click="$emit('showAdd')" >
          <span class="btn-label"><i class="ti-settings"></i></span>
          {{$t('button.new')}}
        </button>
      </template>
      <!-- 検索ボタン -->
      <template v-if="labels.search === 1">
        <button type="button" class="btn btn-sm btn-default btn-search" :disabled="disabled" @click="searchClicked">
          <span class="btn-label"><i class="ti-search"></i></span>
          {{$t('button.search')}}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  // 従業員検索用モーダルインポート
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      EmployeeSearch,
      vSelect
    },
    props: {
      orgSearchCond: Object,
      labels: Object,
      comboData: Object,
      disabled: Boolean,
      showSearchAll: {
        type: Boolean,
        required: false,
        'default': true
      },
      showAddBtn: Boolean,
      hireYearMode: {
        type: Boolean,
        required: false,
        'default': false
      },
      showQualifiedEmployee: {
        type: Boolean,
        required: false,
        'default': false
      },
      showTargetYear: {
        type: Boolean,
        required: false,
        'default': false
      },
      fromYearMonth: {
        type: String,
        required: false,
        'default': ''
      },
      toYearMonth: {
        type: String,
        required: false,
        'default': ''
      },
      currentYearMonth: {
        type: String,
        required: false,
        'default': ''
      },
      legalRuleMode: {
        type: Boolean,
        required: false,
        'default': false
      },
      payslipMode: {
        type: Boolean,
        required: false,
        'default': false
      },
      bonusMode: {
        type: Boolean,
        required: false,
        'default': false
      },
      targetTerms: {
        type: Array,
        required: false,
        'default': null
      }
    },
    data () {
      return {
        componentKey: 0,
        searchCond: {
          target_year: null
        },
        convertSearchCond: {},
        offices: [],
        groups: [],
        teams: [],
        employee_types: [],
        businesses: [],
        retirementDisabledFlg: false,
        isCurrentMonth: false,
        years: [],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        searchTargetTerms: []
      }
    },
    methods: {
      searchClicked () {
        this.setConvertSearchCond()
        this.$emit('search', this.convertSearchCond)
      },
      setConvertSearchCond () {
        this.convertSearchCond = JSON.parse(JSON.stringify(this.searchCond))
        if (this.searchCond.office_code && typeof this.searchCond.office_code === 'object') {
          this.convertSearchCond.office_code = this.searchCond.office_code.value
        }
        if (this.searchCond.group_code && typeof this.searchCond.group_code === 'object') {
          this.convertSearchCond.group_code = this.searchCond.group_code.value
        }
        if (this.searchCond.team_code && typeof this.searchCond.team_code === 'object') {
          this.convertSearchCond.team_code = this.searchCond.team_code.value
        }
        if (this.searchCond.employee_classification_code && typeof this.searchCond.employee_classification_code === 'object') {
          this.convertSearchCond.employee_classification_code = this.searchCond.employee_classification_code.value
        }
      },
      show () {
        // モーダルemployeeを表示
        this.setConvertSearchCond()
        this.$modal.show('employee')
      },
      hide (selected) {
        if (selected) {
          // モーダルemployeeで従業員が選ばれた場合、従業員番号と従業員名を検索条件に設定
          this.searchCond.employee_code = selected.employee_code
          this.searchCond.employee_name = selected.employee_name
        }
        // モーダルemployeeを非表示
        this.$modal.hide('employee')
      },
      employeeSelected () {
        if (!this.canShow('/SS/menuSSScreen018')) return
        if (!this.searchCond.employee_code) {
          this.searchCond.employee_name = ''
          return
        }
        this.searchCond.employee_name = ''
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.searchCond.employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: false,
            all_flag: false
          }
        }
        this.send('/view/search_employee/list', searchCond, false)
        .then(res => {
          if (res.api_area.employee_list && res.api_area.employee_list.length > 0) {
            this.hide(res.api_area.employee_list[0])
          }
        })
      },
      isCurrentMonthClicked () {
        if (!this.isCurrentMonth) {
          this.searchCond.from_year = this.searchCond.to_year = this.currentYear
          this.searchCond.from_month = this.searchCond.to_month = this.currentMonth
        }
      },
      synchronizeFrom () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
        // 給与機能モードの場合、当月より未来を選んでも有効になるように、以下の処理を無効化する
        if (!this.payslipMode) {
          if (from > thisMonth) {
            this.searchCond.from_year = this.currentYear
            this.searchCond.from_month = this.currentMonth
          }
          if (to > thisMonth) {
            this.searchCond.to_year = this.currentYear
            this.searchCond.to_month = this.currentMonth
          }
        }
        if (from > to) {
          this.searchCond.to_year = this.searchCond.from_year
          this.searchCond.to_month = this.searchCond.from_month
        }
        this.adjustFromTo()
      },
      synchronizeTo () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        let thisMonth = parseInt(this.currentYear, 10) * 100 + parseInt(this.currentMonth, 10)
        // 給与機能モードの場合、当月より未来を選んでも有効になるように、以下の処理を無効化する
        if (!this.payslipMode) {
          if (from > thisMonth) {
            this.searchCond.from_year = this.currentYear
            this.searchCond.from_month = this.currentMonth
          }
          if (to > thisMonth) {
            this.searchCond.to_year = this.currentYear
            this.searchCond.to_month = this.currentMonth
          }
        }
        if (from > to) {
          this.searchCond.from_year = this.searchCond.to_year
          this.searchCond.from_month = this.searchCond.to_month
        }
        this.adjustFromTo()
      },
      adjustFromTo () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        let minMonth = parseInt(this.fromYear, 10) * 100 + parseInt(this.fromMonth, 10)
        if (from < minMonth) {
          this.searchCond.from_year = this.fromYear
          this.searchCond.from_month = this.fromMonth
        }
        if (to < minMonth) {
          this.searchCond.to_year = this.fromYear
          this.searchCond.to_month = this.fromMonth
        }
        let maxMonth = parseInt(this.toYear, 10) * 100 + parseInt(this.toMonth, 10)
        console.log(from, to, maxMonth)
        if (from > maxMonth) {
          this.searchCond.from_year = this.toYear
          this.searchCond.from_month = this.toMonth
        }
        if (to > maxMonth) {
          this.searchCond.to_year = this.toYear
          this.searchCond.to_month = this.toMonth
        }
      },
      setData () {
        if (this.orgSearchCond) {
          const searchCond = {
            search_type: String(this.orgSearchCond.search_type),
            office_code: this.orgSearchCond.office_code,
            group_code: this.orgSearchCond.group_code,
            team_code: this.orgSearchCond.team_code,
            employee_code: this.orgSearchCond.employee_code,
            employee_name: this.orgSearchCond.employee_name,
            employee_classification_code: this.orgSearchCond.employee_classification_code,
            business_code: this.orgSearchCond.business_code,
            retirement: this.orgSearchCond.retirement,
            hireYear: this.orgSearchCond.hireYear,
            target_term: this.orgSearchCond.target_term,
            including_outside_period: this.orgSearchCond.including_outside_period
          }
          this.offices = this.comboData.office_list
          this.groups = this.comboData.group_list
          this.teams = this.comboData.team_list
          this.employee_types = this.comboData.employee_classification_list
          this.businesses = this.comboData.business_list
          if (this.legalRuleMode) {
            searchCond.legal_rule_disp_type = String(this.orgSearchCond.legal_rule_disp_type)
          }
          if (this.showTargetYear) {
            searchCond.target_year = this.searchCond && this.searchCond.target_year ? this.searchCond.target_year : this.orgSearchCond && this.orgSearchCond.target_year ? this.orgSearchCond.target_year : String(new Date().getFullYear())
          }
          if (this.showQualifiedEmployee) {
            searchCond.qualified_employee = this.orgSearchCond.qualified_employee
          }
          if (this.payslipMode) {
            searchCond.salary_closing_code = this.orgSearchCond.salary_closing_code
          }
          if (this.currentYearMonth) {
            this.fromYear = this.fromYearMonth.split('/')[0]
            this.fromMonth = String(parseInt(this.fromYearMonth.split('/')[1], 10))
            this.toYear = this.toYearMonth.split('/')[0]
            this.toMonth = String(parseInt(this.toYearMonth.split('/')[1], 10))
            this.currentYear = this.currentYearMonth.split('/')[0]
            this.currentMonth = String(parseInt(this.currentYearMonth.split('/')[1], 10))
            searchCond.from_year = this.searchCond && this.searchCond.from_year ? this.searchCond.from_year : this.currentYear
            searchCond.from_month = this.searchCond && this.searchCond.from_month ? this.searchCond.from_month : this.currentMonth
            searchCond.to_year = this.searchCond && this.searchCond.to_year ? this.searchCond.to_year : this.currentYear
            searchCond.to_month = this.searchCond && this.searchCond.to_month ? this.searchCond.to_month : this.currentMonth
          }
          if (this.offices) {
            for (const office of this.offices) {
              if (office.label === '') office.label = '　'
              if (office.value === this.orgSearchCond.office_code) {
                searchCond.office_code = office
                break
              }
            }
          }
          if (this.groups) {
            for (const group of this.groups) {
              if (group.label === '') group.label = '　'
              if (group.value === this.orgSearchCond.group_code) {
                searchCond.group_code = group
                break
              }
            }
          }
          if (this.teams) {
            for (const team of this.teams) {
              if (team.label === '') team.label = '　'
              if (team.value === this.orgSearchCond.team_code) {
                searchCond.team_code = team
                break
              }
            }
          }
          if (this.employee_types) {
            for (const employeeType of this.employee_types) {
              if (employeeType.label === '') employeeType.label = '　'
              if (employeeType.value === this.orgSearchCond.employee_classification_code) {
                searchCond.employee_classification_code = employeeType
                break
              }
            }
          }
          this.searchCond = searchCond
          this.componentKey += 1
        }
      }
    },
    watch: {
      comboData () {
        this.setData()
      },
      'searchCond.hireYear': function () {
        if (this.searchCond.hireYear) {
          // 入社年度別がオンの場合、退職者をチェックし非活性にする
          this.searchCond.retirement = true
          this.retirementDisabledFlg = true
        } else {
          // 入社年度別がオフの場合、退職者を活性にする
          this.retirementDisabledFlg = false
        }
      }
    },
    created () {
      const currentYear = new Date().getFullYear()
      for (let year = currentYear + 1; year >= 2009; year--) {
        this.years.push(String(year))
      }
    },
    mounted () {
      this.setData()
    }
  }
</script>
