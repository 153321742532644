<template>
  <ValidationObserver ref="observer">
    <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
      <i class="ti-help"></i>
    </button>
    <div class="row" style="overflow-y: auto; height: 542px;" name="companyFrame">
      <div class="col-md-12">
        <modal name="ClosingSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
          <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="closingSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
            <ClosingSearch :mode="1" :params="params"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <modal name="OfficeSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
          <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="officeSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
            <OfficeSearch :mode="1" :params="params"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <modal name="BusinessSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
          <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="businessSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
            <BusinessSearch :mode="1" :params="params"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <modal name="JobTitleSearch" :draggable="false" :clickToClose="false" height="auto" :scrollable="true" :width="1200" :pivotX="0.6">
          <div class="modal-body">
            <button type="button" class="btn btn-sm btn-default btn-danger" @click="jobTitleSearchHide">
              <span class="btn-label"><i class="ti-close"></i></span>
              {{$t('button.close')}}
            </button>
            <JobTitleSearch :mode="1" :params="params"/>
          </div>
        </modal>
      </div>
      <div class="col-md-12">
        <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
        <div class="card">
          <div class="card-content" style="padding-left: 20px;">
            <div class="row">
              <!-- 従業員番号 -->
              <ValidationText
                v-model="employee_code"
                item-name="employee_code"
                max-length="10"
                rules="required|isEmployeeCodeSpace|isAlphaAndNum"
                classes="form-control length10"
                :item-label="labels.employee_code"
                :placeholder="$t('placeholder.employee_code')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly_Pk || lockFlg"
                data-v-step="0"
              />
              <!-- 従業員名 -->
              <ValidationText
                v-model="employee_name"
                item-name="employee_name"
                max-length="30"
                rules="required"
                classes="form-control length60"
                :item-label="labels.employee_name"
                :placeholder="$t('placeholder.employee_name')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
                data-v-step="1"
              />
              <!-- 従業員名（かな） -->
              <ValidationText
                v-model="pseudonym_reading"
                item-name="pseudonym_reading"
                max-length="50"
                rules=""
                classes="form-control length60"
                :item-label="labels.pseudonym_reading"
                :placeholder="$t('placeholder.pseudonym_reading')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- 従業員区分 -->
              <ValidationSelect
                v-model="employee_classification"
                :item-label="labels.employeeClassification"
                :options="employee_classification_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly || lockFlg"
                data-v-step="2"
              />
              <!-- 採用区分 -->
              <ValidationSelect
                v-model="recruitment_category_code"
                :item-label="labels.recruitment_category_code"
                :options="recruitment_category_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly || lockFlg"
                data-v-step="3"
              />
              <!-- 言語種類（2022/11/14 大久保 英語対応ができるまでは変更不可にしておく） -->
              <ValidationSelect
                v-model="language"
                :item-label="labels.language"
                :options="language_type_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly || lockFlg || true"
              />
              <!-- 画像ファイル -->
              <template v-if="!isReadOnly">
                <ValidationImage
                  v-model="append_path"
                  :item-label="$t('label.image_file')"
                  :size="256 * 1024"
                />
              </template>
              <!-- 入社年月日 -->
              <div @click="openMessage">
                <ValidationDate
                  v-model="hire_date"
                  item-name="hire_date"
                  rules="required"
                  :itemLabel="labels.hire_date"
                  :placeholder="$t('placeholder.start_date')"
                  :disabled="isReadOnly || lockFlg || unchangeable_hire_date_msg!==''"
                  data-v-step="4"
                />
              </div>
              <!-- 退社年月日 -->
              <ValidationDate
                v-model="retirement_date"
                item-name="retirement_date"
                :itemLabel="labels.retirement_date"
                :placeholder="$t('placeholder.start_date')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- メールアドレス -->
              <ValidationTextAutocompleteOff
                v-model="mail_address"
                item-name="mail_address"
                rules="mailaddress"
                max-length="255"
                classes="form-control length255"
                :item-label="labels.mail_address"
                :placeholder="$t('placeholder.mail_address')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
                :isEmailCheck="this.mode === this.modes.add"
                :isSendRegistrationCompletionEmail="is_send_registration_completion_email"
                @setEmailFlg="setEmailFlg"
              />
              <template v-if="isEditMode()">
                <input type="checkbox" v-model="force_password_change" class="gray"/>
                <label>{{$t('label.force_password_change')}}</label>
                <br><br>
              </template>
              <!-- パスワード -->
              <template v-if="mode === modes.add || force_password_change">
                <ValidationPassword
                  v-model="password"
                  type="password"
                  item-name="password"
                  max-length="30"
                  classes="form-control length30"
                  rules="required|min:12|isUnusable|isPasswordWeakly"
                  :item-label="labels.password"
                  :placeholder="$t('placeholder.input_password')"
                  :disabled="isReadOnly || lockFlg"
                  :help-text="$t('message.password_text')"
                  :weaklyMessage="$t('message.is_weakly_password')"
                  :unusableMessage="$t('message.is_unusable_string_password')"
                  :wordCountMessage="$t('message.is_word_count_password')"
                  :employeeFlag="true"
                  :mode="mode"
                  data-v-step="5"
                />
              </template>
              <!-- 性別 -->
              <ValidationSelect
                v-model="sex"
                :item-label="labels.sex"
                :options="sex_list"
                classes="length16"
                rules="required"
                :disabled="isReadOnly || lockFlg"
                data-v-step="6"
              />
              <!-- 内線番号 -->
              <ValidationText
                v-model="extension_number"
                item-name="tel"
                max-length="5"
                classes="form-control length5"
                :item-label="labels.extension_number"
                :placeholder="$t('placeholder.extension_number')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- 契約更新日 -->
              <ValidationDate
                v-model="contract_renewal_date"
                item-name="contract_renewal_date"
                :itemLabel="labels.contract_renewal_date"
                :placeholder="$t('placeholder.contract_renewal_date')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- 労働管理 -->
              <ValidationRadio
                rules="required"
                v-model="attendance_management"
                :item-label="labels.attendance_management"
                :disabled="isReadOnly||company_attendance_management===2 || lockFlg"
                data-v-step="7"
                @change="attendanceManagementChanged"
              />
              <!-- 締日 -->
              <template v-if="attendance_management.value === '1'">
                <ValidationSelect
                  v-model="closing_code"
                  :item-label="$t('label.closing')"
                  :options="closing_list"
                  classes="length20"
                  rules="required"
                  :disabled="isReadOnly || lockFlg"
                  :addButtonFlg="true"
                  tourSteps="71,72"
                  @showAdd="closingSearchShowAdd"
                />
              </template>
              <!-- 高度プロフェッショナル制度対象者 -->
              <template v-if="attendance_management.value === '1'">
                <ValidationRadio
                  rules="required"
                  v-model="white_collar_exemption"
                  :item-label="labels.white_collar_exemption"
                  :disabled="isReadOnly || lockFlg"
                  @change="whiteCollarExemptionChanged"
                />
              </template>
              <!-- シフト利用有無 -->
              <template v-if="attendance_management.value === '1' && !this.white_collar_exemption.value">
                <ValidationRadio
                  rules="required"
                  v-model="is_shift_work"
                  :item-label="labels.is_shift_work"
                  :disabled="isReadOnly || lockFlg"
                  @change="isShiftWorkChanged"
                />
              </template>
              <!-- 賃金管理 -->
              <ValidationRadio
                rules="required"
                v-model="payroll_management"
                :item-label="labels.payroll_management"
                :disabled="isReadOnly||company_payroll_management===2 || lockFlg"
                data-v-step="8"
              />
              <!-- 利用可否 -->
              <ValidationRadio
                rules="required"
                v-model="availability"
                :item-label="labels.availability"
                :disabled="isReadOnly || lockFlg"
                data-v-step="9"
              />
              <!-- 生年月日 -->
              <ValidationDate
                v-model="birthday"
                item-name="birthday"
                :rules="isPayrollManagement()"
                :itemLabel="labels.birthday"
                :placeholder="$t('placeholder.start_date')"
                :disabled="isReadOnly || lockFlg"
                data-v-step="91"
              />
              <!-- feliCa -->
              <ValidationText
                v-model="feliCa"
                item-name="feliCa"
                max-length="16"
                classes="form-control length16"
                :item-label="labels.feliCa"
                :placeholder="$t('placeholder.felica')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- マイナンバー -->
              <ValidationText
                v-model="my_number"
                item-name="my_number"
                max-length="12"
                classes="form-control length12"
                :item-label="labels.my_number"
                :placeholder="$t('placeholder.my_number')"
                :help-text="$t('label.max_length')"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- 見込み残業時間 -->
              <ValidationText
                v-model="estimated_overtime_hours"
                item-name="estimated_overtime_hours"
                max-length="2"
                classes="form-control numLength2 number"
                :item-label="labels.estimated_overtime_hours"
                :placeholder="$t('placeholder.estimated_overtime_hours')"
                :help-text="$t('label.max_length')"
                rules="min_value: 0|max_value: 99"
                :disabled="isReadOnly || lockFlg"
              />
              <!-- パートナー参照可否
              <ValidationRadio
                rules="required"
                v-model="is_visible"
                :item-label="labels.is_visible"
                :disabled="isReadOnly || lockFlg"
              /> -->
              <!-- 事業所 -->
              <ValidationSelect
                v-model="office_code"
                :item-label="labels.office"
                :options="office_list"
                classes="length12"
                rules="required"
                :disabled="isReadOnly || lockFlg"
                :addButtonFlg="true"
                @showAdd="officeSearchShowAdd"
                data-v-step="10"
              />
              <!-- 職種 -->
              <ValidationSelect
                v-model="business"
                :item-label="labels.business"
                :options="business_list"
                classes="length20"
                rules="required"
                :disabled="isReadOnly || lockFlg"
                :addButtonFlg="true"
                @showAdd="businessSearchShowAdd"
                data-v-step="11"
              />
              <!-- 職名 -->
              <label for="planId">
                {{labels.job_title_name}}
              </label>
              <!-- 追加ボタン -->
              <template>
                <button v-if="!isReadOnly" type="button" class="btn btn-sm btn-default btn-primary" @click="addEmployeeJobTitleNameRecord">{{$t('button.add_row')}}</button>
                <button v-if="!isReadOnly" type="button" class="btn btn-primary btn-sm btn-default" @click="jobTitleSearchShowAdd"><span class="btn-label"><i class="ti-settings"></i></span>{{$t('button.new')}}</button>
              </template>
              <div class="error-message-wrapper" v-for="errorMsg in employee_job_title_error" v-bind:key="errorMsg">
                <small class="text-danger">
                  <!-- エラーメッセージ -->
                  {{ errorMsg }}
                </small>
              </div>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                border
                :data="employee_job_title"
                style="width: 280px">
                <!-- 削除 -->
                <el-table-column min-width="60" :label="$t('label.delete')">
                  <template slot-scope="props">
                    <div style="text-align: center;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                    </template>
                </el-table-column>
                <!-- 職名 -->
                <el-table-column min-width="200" :label="labels.job_title_name_ryaku ? labels.job_title_name_ryaku : ''">
                  <template slot-scope="props">
                    <el-select class="select-success"
                                size="large"
                                name="job_title_name"
                                :disabled="isReadOnly"
                                :key="componentKey"
                                v-model="props.row.job_title_code">
                      <el-option v-for="option in job_title_list"
                                  class="select-success"
                                  :value="option.value"
                                  :label="option.label"
                                  :key="option.label">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tour tour-name="myTour" :steps="steps"/>
  </ValidationObserver>
</template>
<script>
  import Tour from '@/components/UIComponents/Inputs/Tour'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationTextAutocompleteOff from '@/components/UIComponents/Inputs/ValidationTextAutocompleteOff'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import ValidationPostalCode from '@/components/UIComponents/Inputs/ValidationPostalCode'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadio'
  import ValidationPassword from '@/components/UIComponents/Inputs/ValidationPassword'
  import ValidationImage from '@/components/UIComponents/Inputs/ValidationImage'
  import ClosingSearch from '@/components/Dashboard/Views/Generated/ClosingSearch'
  import OfficeSearch from '@/components/Dashboard/Views/Generated/OfficeSearch.vue'
  import BusinessSearch from '@/components/Dashboard/Views/Generated/BusinessSearch.vue'
  import JobTitleSearch from '@/components/Dashboard/Views/Generated/JobTitleSearch.vue'
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      Tour,
      ValidationText,
      ValidationTextAutocompleteOff,
      ValidationSelect,
      ValidationPostalCode,
      ValidationDate,
      ValidationRadio,
      ValidationPassword,
      ValidationImage,
      ClosingSearch,
      OfficeSearch,
      BusinessSearch,
      JobTitleSearch,
      'el-date-picker': DatePicker
    },
    props: {
      params: Object
    },
    data () {
      return {
        steps: [],
        stepsBk: [
          {
            target: '[data-v-step="0"]',
            content: '管理用の番号です。従業員はログイン画面でこの番号を入力してログインします。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="1"]',
            content: '従業員の氏名を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="2"]',
            content: '従業員の雇用形態を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="3"]',
            content: '採用方法を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="4"]',
            content: '入社年月日を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="5"]',
            content: '入社年月日を入力すると自動で入力されます。任意のパスワードに変更できます。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="6"]',
            content: '性別を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="7"]',
            content: '打刻をする等、勤怠を管理する場合「管理する」を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="71"]',
            content: '勤怠の締日を指定してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="72"]',
            content: '新しく締日を登録することもできます。',
            params: {placement: 'top'}
          },
          {
            target: '[data-v-step="8"]',
            content: '明細書を作成する等、給与を管理する場合「管理する」を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="9"]',
            content: '一時的にアカウントへログイン不可にする場合に「利用不可」を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="91"]',
            content: '生年月日を入力してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="10"]',
            content: '従業員が所属する事業所を選択してください。',
            params: {placement: 'bottom-start'}
          },
          {
            target: '[data-v-step="11"]',
            content: '従業員の職種を選択してください。',
            params: {placement: 'top-start'}
          }
        ],
        labels: {},
        employee_code: '',
        employee_name: '',
        pseudonym_reading: '',
        employee_classification: '',
        recruitment_category_code: '',
        language: 'JPN',
        append_path: '',
        send_append_path: '',
        hire_date: '',
        retirement_date: '',
        mail_address: '',
        password: '',
        sex: '',
        birthday: '',
        basicTel: '',
        extension_number: '',
        contract_renewal_date: '',
        closing_code: '',
        closing_list: [],
        attendance_management: {},
        white_collar_exemption: {},
        is_shift_work: {},
        payroll_management: {},
        availability: {},
        feliCa: '',
        my_number: '',
        unit_price: '',
        estimated_overtime_hours: '0',
        is_visible: {},
        business: '',
        employee_classification_list: [],
        recruitment_category_list: [],
        language_type_list: [],
        sex_list: [],
        business_list: [],
        office_list: [],
        mode: 0,
        imgChangeFlg: false,
        employee_job_title: [],
        employee_job_title_error: [],
        office_code: '',
        componentKey: 0,
        first_flg: true,
        employee_update_date: '',
        payroll_management_change_flag: false,
        lockFlg: true,
        is_rate_exists: false,
        force_password_change: false,
        unchangeable_hire_date_msg: '',
        is_send_registration_completion_email: false,
        send_email_disabled: true,
        attendance_non_manage_msg: '',
        can_change_attendance_non_manage: true,
        shift_work_msg: '',
        can_change_shift_work: true
      }
    },
    computed: {
      isReadOnly () {
        return this.mode === this.modes.view || this.mode === this.modes.delete
        // return true
      },
      isReadOnly_Pk () {
        return this.mode === this.modes.view || this.mode === this.modes.delete || this.mode === this.modes.edit
      }
    },
    watch: {
      'payroll_management.value': function () {
        let tab = document.getElementsByClassName('wizard-nav wizard-nav-pills')
        if (this.payroll_management.value === '2') {
          tab[0].children[4].style.display = 'none'
          tab[0].children[5].style.display = 'none'
          tab[0].children[6].style.display = 'none'
          tab[0].children[7].style.display = 'none'
          tab[0].children[8].style.display = 'none'
          let parent = this.$parent.$parent.$parent
          parent.$refs.employeeEmploymentInsuranceInfo.allClear()
          parent.$refs.employeeSocialInsuranceInfo.allClear()
          parent.$refs.employeeTaxCostInfo.allClear()
          parent.$refs.employeeSalaryInfo.allClear()
          parent.$refs.employeeDependentInfo.allClear()
          for (let group of parent.$refs.employeeGroupInfo.group_record) {
            let newRoleList = []
            for (let role of group.role_list) {
              if (role !== 'payroll_management' || !group.addFlg) {
                newRoleList.push(role)
              }
            }
            group.role_list = newRoleList
          }
        } else if (this.payroll_management.value === '1') {
          if (!this.is_rate_exists) {
            // 警告表示
            swal({
              text: '賃金管理する場合は社会保険/労働保険料率を登録してください。',
              type: 'error',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            }).then(() => {
              this.payroll_management.value = ''
              this.payroll_management.value = '2'
              tab[0].children[4].style.display = 'none'
              tab[0].children[5].style.display = 'none'
              tab[0].children[6].style.display = 'none'
              tab[0].children[7].style.display = 'none'
              tab[0].children[8].style.display = 'none'
              let parent = this.$parent.$parent.$parent
              parent.$refs.employeeEmploymentInsuranceInfo.allClear()
              parent.$refs.employeeSocialInsuranceInfo.allClear()
              parent.$refs.employeeTaxCostInfo.allClear()
              parent.$refs.employeeSalaryInfo.allClear()
              parent.$refs.employeeDependentInfo.allClear()
              for (let group of parent.$refs.employeeGroupInfo.group_record) {
                let newRoleList = []
                for (let role of group.role_list) {
                  if (role !== 'payroll_management' || !group.addFlg) {
                    newRoleList.push(role)
                  }
                }
                group.role_list = newRoleList
              }
            }).catch(() => {})
          } else {
            this.payroll_management_change_flag = true
            tab[0].children[4].style.display = ''
            tab[0].children[5].style.display = ''
            tab[0].children[6].style.display = 'none'
            tab[0].children[7].style.display = ''
            tab[0].children[8].style.display = ''
            // 勤怠管理するの場合
            let parent = this.$parent.$parent.$parent
            for (let group of parent.$refs.employeeGroupInfo.group_record) {
              group.role_list.push('payroll_management')
            }
          }
        }
      },
      'attendance_management.value': function () {
        let tab = document.getElementsByClassName('wizard-nav wizard-nav-pills')
        if (this.attendance_management.value === '2' || this.white_collar_exemption.value) {
          if (this.can_change_attendance_non_manage) {
            tab[0].children[9].style.display = 'none'
            tab[0].children[11].style.display = 'none'
            let parent = this.$parent.$parent.$parent
            parent.$refs.employeeWorkingScheduleInfo.allClear()
            parent.$refs.employeePaidLeavePaymentInfo.allClear()
            for (let group of parent.$refs.employeeGroupInfo.group_record) {
              let newRoleList = []
              for (let role of group.role_list) {
                if (role !== 'attendance_management' || !group.addFlg) {
                  newRoleList.push(role)
                }
              }
              group.role_list = newRoleList
            }
          }
        } else {
          // 勤怠管理するの場合
          let parent = this.$parent.$parent.$parent
          for (let group of parent.$refs.employeeGroupInfo.group_record) {
            group.role_list.push('attendance_management')
          }
          tab[0].children[9].style.display = ''
          tab[0].children[11].style.display = ''
        }
      },
      'white_collar_exemption.value': function () {
        let tab = document.getElementsByClassName('wizard-nav wizard-nav-pills')
        if (this.attendance_management.value === '2' || this.white_collar_exemption.value) {
          tab[0].children[9].style.display = 'none'
          tab[0].children[11].style.display = 'none'
          let parent = this.$parent.$parent.$parent
          parent.$refs.employeeWorkingScheduleInfo.allClear()
          parent.$refs.employeePaidLeavePaymentInfo.allClear()
        } else {
          tab[0].children[9].style.display = ''
          tab[0].children[11].style.display = ''
        }
      },
      'hire_date': function (newVal, oldVal) {
        if (newVal !== null && newVal !== '' && this.mode === this.modes.add) {
          if (this.password === '') {
            let passwordValue = this.getFormatDate(newVal)
            this.password = 'AA' + passwordValue.replace(/\//g, '#')
          }
        }
        if (this.unchangeable_hire_date_msg === '') {
          let parent = this.$parent.$parent.$parent
          if (parent.$refs.employeePaidLeavePaymentInfo.exist_paid_holiday_management) {
            if (this.mode === this.modes.view || this.mode === this.modes.delete) {
              return
            }
            if (this.mode === this.modes.edit && this.first_flg) {
              this.first_flg = false
              return
            }
            let numberOfWorkingDaysPerWeek = parent.$refs.employeePaidLeavePaymentInfo.number_of_working_days_per_week
            if (numberOfWorkingDaysPerWeek.value === '') {
              for (const item of numberOfWorkingDaysPerWeek.items) {
                if (!item.disabled) {
                  numberOfWorkingDaysPerWeek.value = item.value
                  break
                }
              }
              if (numberOfWorkingDaysPerWeek.value === '') {
                numberOfWorkingDaysPerWeek.value = 5
              }
            }
          }
        }
      },
      'mail_address': function (newVal, oldVal) {
        if (newVal === '') {
          this.send_email_disabled = true
        } else {
          this.is_send_registration_completion_email = true
          this.send_email_disabled = false
        }
      }
    },
    methods: {
      whiteCollarExemptionChanged () {
        if (this.white_collar_exemption.value) {
          this.is_shift_work.value = 2
        }
      },
      isShiftWorkChanged () {
        if (this.is_shift_work.value === 2 && !this.can_change_shift_work) {
          swal({
            html: this.shift_work_msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            width: '600px'
          }).then(() => {
            this.is_shift_work.value = 1
          }).catch(() => {
          })
        }
      },
      attendanceManagementChanged () {
        if (this.attendance_management.value === '2' && !this.can_change_attendance_non_manage) {
          swal({
            html: this.attendance_non_manage_msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            width: '580px'
          }).then(() => {
            this.attendance_management.value = '1'
          }).catch(() => {
          })
        }
      },
      replay () {
        const deleteIndexList = []
        if (this.payroll_management.value !== '1') {
          deleteIndexList.push(12)
        }
        if (this.attendance_management.value !== '1') {
          deleteIndexList.push(9)
          deleteIndexList.push(8)
        }
        if (!(this.mode === this.modes.add || this.force_password_change)) {
          deleteIndexList.push(5)
        }
        let steps = [...this.stepsBk]
        for (const index of deleteIndexList) {
          steps.splice(index, 1)
        }
        this.steps = steps
        this.$nextTick(() => {
          this.$tours['myTour'].start()
        })
      },
      setEmailFlg (value) {
        this.is_send_registration_completion_email = value
      },
      onEmployeeFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        this.createEmployeeImage(files[0])
      },
      createEmployeeImage (file) {
        let self = this
        this.imgChangeFlg = true
        // 生成する文字列の長さ
        var l = 16

        // 生成する文字列に含める文字セット
        var c = 'abcdefghijklmnopqrstuvwxyz0123456789'

        var cl = c.length
        var r = ''
        for (var i = 0; i < l; i++) {
          r += c[Math.floor(Math.random() * cl)]
        }
        const extension = file.name.substring(file.name.lastIndexOf('.'))
        let appendPath = `tmp/${this.$store.state.loginUser.companyCode}/${r}${extension}`
        const httpsReference = this.$firebase.storage()
          .ref()
          .child(appendPath)
        httpsReference.put(file).then(function () {
          self.append_path = appendPath
          self.send_append_path = appendPath
          httpsReference.getDownloadURL().then(function (url) {
            self.append_path = url
          })
        }).catch(function (error) {
          console.log(error)
        })
      },
      init () {
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        this.today = Number(String(year) + month + day)
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: sessionStorage.getItem('employee_code')
          }
        }
        this.mode = Number(sessionStorage.getItem('mode'))
        this.send('/init/employee', data)
        .then(res => {
          this.$parent.$parent.$parent.help = res.common_area.help_basic
          this.$parent.$parent.$parent.help_basic = res.common_area.help_basic
          this.$parent.$parent.$parent.help_career = res.common_area.help_career
          this.$parent.$parent.$parent.help_department = res.common_area.help_department
          this.$parent.$parent.$parent.help_team = res.common_area.help_team
          this.$parent.$parent.$parent.help_social_insurance = res.common_area.help_social_insurance
          this.$parent.$parent.$parent.help_employment_insurance = res.common_area.help_employment_insurance
          this.$parent.$parent.$parent.help_resident_tax = res.common_area.help_resident_tax
          this.$parent.$parent.$parent.help_salary = res.common_area.help_salary
          this.$parent.$parent.$parent.help_dependents = res.common_area.help_dependents
          this.$parent.$parent.$parent.help_paid_holiday = res.common_area.help_paid_holiday
          this.$parent.$parent.$parent.help_application = res.common_area.help_application
          this.$parent.$parent.$parent.help_service_system = res.common_area.help_service_system
          this.$parent.$parent.$parent.help_employee_renewal = res.common_area.help_employee_renewal
          this.$parent.$parent.$parent.resData = res
          this.$parent.$parent.$parent.labels = this.getLabels(res.common_area)
          this.$parent.$parent.$parent.mode = this.mode
          // ラベル情報をthis.labelsに設定
          this.labels = this.getLabels(res.common_area)
          // ボタン権限をthis.rolesに設定
          this.roles = res.common_area.button_role
          // 有給関連メッセージ
          this.unchangeable_hire_date_msg = res.api_area.unchangeable_hire_date_msg && this.mode === this.modes.edit ? res.api_area.unchangeable_hire_date_msg : ''
          // リストの作成
          // 従業員区分
          this.employee_classification_list = res.common_area.combo_data.employee_classification_list
          // 採用区分
          this.recruitment_category_list = []
          if (this.mode === this.modes.add) {
            for (const list of res.common_area.combo_data.recruitment_category_list) {
              if (list.value !== '9999999999') {
                this.recruitment_category_list.push(list)
              }
            }
          } else {
            this.recruitment_category_list = res.common_area.combo_data.recruitment_category_list
          }
          // 言語種類
          this.language_type_list = res.common_area.combo_data.language_type_list
          // 性別
          this.sex_list = this.getLabelList(res.common_area.combo_data.sex_list)
          // 締日
          this.closing_list = res.common_area.combo_data.closing_list
          // 職名
          this.job_title_list = res.common_area.combo_data.job_title_list
          // 労働管理
          this.attendance_management = {
            value: '1',
            disabled: false,
            items: this.getLabelList(res.common_area.combo_data.attendance_management_list)
          }
          // 賃金管理
          this.payroll_management = {
            value: '',
            disabled: false,
            items: this.getLabelList(res.common_area.combo_data.payroll_management_list)
          }
          // 利用可否
          this.availability = {
            value: '',
            disabled: false,
            items: this.getLabelList(res.common_area.combo_data.availability_list)
          }
          this.availability.value = '1'
          // 職種
          this.business_list = res.common_area.combo_data.business_list
          if (this.mode === this.modes.add) {
            let businessList = []
            for (let business of this.business_list) {
              if (!this.isEnd(business.term_to)) {
                businessList.push(business)
              }
            }
          }
          // 事業所
          this.office_list = res.common_area.combo_data.office_list
          // パートナー参照可否
          this.is_visible = {
            value: '',
            disabled: false,
            items: this.getLabelList(res.common_area.combo_data.is_visible_list)
          }
          this.is_visible.value = '2'
          // 高度プロフェッショナル制度対象者
          let whiteCollarExemptionList = []
          whiteCollarExemptionList.push({label: this.$t('label.target'), value: true})
          whiteCollarExemptionList.push({label: this.$t('label.no_target'), value: false})
          this.white_collar_exemption = {
            value: false,
            disabled: false,
            items: whiteCollarExemptionList
          }
          // シフト利用有無
          let isShiftWorkList = []
          isShiftWorkList.push({label: this.$t('label.target'), value: 1})
          isShiftWorkList.push({label: this.$t('label.no_target'), value: 2})
          this.is_shift_work = {
            value: 2,
            disabled: false,
            items: isShiftWorkList
          }
          this.is_rate_exists = res.api_area.is_rate_exists
          if (this.mode !== this.modes.add) {
            this.employee_update_date = res.api_area.basic.employee_update_date
            this.employee_code = res.api_area.basic.employee_code
            this.employee_name = res.api_area.basic.employee_name
            this.pseudonym_reading = res.api_area.basic.pseudonym_reading
            this.employee_classification = String(res.api_area.basic.employee_classification_code)
            this.recruitment_category_code = res.api_area.basic.recruitment_category_code
            this.language = res.api_area.basic.language
            this.append_path = res.api_area.basic.append_path
            this.hire_date = res.api_area.basic.hire_date
            this.retirement_date = res.api_area.basic.retirement_date
            let parent = this.$parent.$parent.$parent
            parent.$refs.employeeGroupInfo.retirement_date = res.api_area.basic.retirement_date
            parent.$refs.employeeTeamInfo.retirement_date = res.api_area.basic.retirement_date
            this.mail_address = res.api_area.basic.mail_address
            this.sex = String(res.api_area.basic.sex)
            this.birthday = res.api_area.basic.birthday
            this.basicTel = res.api_area.basic.basicTel
            this.extension_number = res.api_area.basic.extension_number
            this.contract_renewal_date = res.api_area.basic.contract_renewal_date
            this.closing_code = String(res.api_area.basic.closing_code)
            this.attendance_management.value = String(res.api_area.basic.attendance_management)
            this.company_attendance_management = res.api_area.company_attendance_management
            if (this.company_attendance_management === 2) {
              this.attendance_management.value = '2'
            }
            this.payroll_management.value = String(res.api_area.basic.payroll_management)
            this.company_payroll_management = res.api_area.company_payroll_management
            if (this.company_payroll_management === 2) {
              this.payroll_management.value = '2'
            }
            this.availability.value = res.api_area.basic.availability
            this.feliCa = res.api_area.basic.feliCa
            this.my_number = res.api_area.basic.my_number
            this.estimated_overtime_hours = res.api_area.basic.estimated_overtime_hours === null ? 0 : res.api_area.basic.estimated_overtime_hours
            this.unit_price = res.api_area.basic.unit_price
            this.is_visible.value = res.api_area.basic.is_visible
            this.office_code = res.api_area.basic.office_code
            this.business = String(res.api_area.basic.business_type)
            this.employee_job_title = res.api_area.basic.job_title_code_list
            if (res.api_area.basic.white_collar_exemption) {
              this.white_collar_exemption.value = res.api_area.basic.white_collar_exemption
            }
            this.is_shift_work.value = res.api_area.basic.is_shift_work
            this.can_change_attendance_non_manage = res.api_area.can_change_attendance_non_manage
            this.attendance_non_manage_msg = res.api_area.attendance_non_manage_msg
            this.can_change_shift_work = res.api_area.can_change_shift_work
            this.shift_work_msg = res.api_area.shift_work_msg
          } else if (this.mode === this.modes.add && data.api_area.employee_code !== '') {
            this.employee_update_date = ''
            this.employee_code = ''
            this.employee_name = ''
            this.pseudonym_reading = ''
            this.employee_classification = String(res.api_area.basic.employee_classification_code)
            this.recruitment_category_code = res.api_area.basic.recruitment_category_code
            this.language = res.api_area.basic.language
            this.append_path = ''
            this.hire_date = res.api_area.basic.hire_date
            this.retirement_date = ''
            let parent = this.$parent.$parent.$parent
            parent.$refs.employeeGroupInfo.retirement_date = res.api_area.basic.retirement_date
            this.mail_address = ''
            this.sex = ''
            this.birthday = ''
            this.basicTel = ''
            this.extension_number = ''
            this.contract_renewal_date = ''
            this.closing_code = String(res.api_area.basic.closing_code)
            this.attendance_management.value = String(res.api_area.basic.attendance_management)
            this.company_attendance_management = res.api_area.company_attendance_management
            if (this.company_attendance_management === 2) {
              this.attendance_management.value = '2'
            }
            this.payroll_management.value = String(res.api_area.basic.payroll_management)
            this.company_payroll_management = res.api_area.company_payroll_management
            if (this.company_payroll_management === 2) {
              this.payroll_management.value = '2'
            }
            this.availability.value = res.api_area.basic.availability
            this.feliCa = ''
            this.my_number = ''
            this.estimated_overtime_hours = '0'
            this.unit_price = ''
            this.is_visible.value = res.api_area.basic.is_visible
            this.office_code = res.api_area.basic.office_code
            this.business = String(res.api_area.basic.business_type)
            this.employee_job_title = res.api_area.basic.job_title_code_list
            if (res.api_area.basic.white_collar_exemption) {
              this.white_collar_exemption.value = res.api_area.basic.white_collar_exemption
            }
            this.is_shift_work.value = res.api_area.basic.is_shift_work
          } else {
            this.company_attendance_management = res.api_area.company_attendance_management
            if (this.company_attendance_management === 2) {
              this.attendance_management.value = '2'
            }
            this.company_payroll_management = res.api_area.company_payroll_management
            if (this.company_payroll_management === 2) {
              this.payroll_management.value = '2'
            }
          }
          this.$parent.$parent.$parent.setAll()
          let tab = document.getElementsByClassName('wizard-nav wizard-nav-pills')
          if (this.payroll_management.value === '2') {
            tab[0].children[4].style.display = 'none'
            tab[0].children[5].style.display = 'none'
            tab[0].children[6].style.display = 'none'
            tab[0].children[7].style.display = 'none'
          } else {
            tab[0].children[4].style.display = ''
            tab[0].children[5].style.display = ''
            tab[0].children[6].style.display = 'none'
            tab[0].children[7].style.display = ''
          }
          if (this.attendance_management.value === '2') {
            tab[0].children[11].style.display = 'none'
          } else {
            tab[0].children[11].style.display = ''
          }
          this.lockFlg = false
        })
      },
      isEnd (termTo) {
        if (typeof termTo === 'object') {
          termTo = this.formatDate(termTo)
        }
        if (termTo === null || termTo === '') {
          return false
        }
        termTo = termTo.split('/')
        termTo = termTo.join('')
        if (Number(termTo) < this.today) {
          return true
        }
      },
      closingSearchShowAdd () {
        this.params = {}
        this.$modal.show('ClosingSearch')
      },
      officeSearchShowAdd () {
        this.params = {}
        this.$modal.show('OfficeSearch')
      },
      businessSearchShowAdd () {
        this.params = {}
        this.$modal.show('BusinessSearch')
      },
      jobTitleSearchShowAdd () {
        this.params = {}
        this.$modal.show('JobTitleSearch')
      },
      closingSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 締日
          this.closing_list = res.common_area.combo_data.closing_list
        })
        this.$modal.hide('ClosingSearch')
      },
      officeSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 事業所
          this.office_list = res.common_area.combo_data.office_list
        })
        this.$modal.hide('OfficeSearch')
      },
      businessSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 職種
          this.business_list = res.common_area.combo_data.business_list
        })
        this.$modal.hide('BusinessSearch')
      },
      jobTitleSearchHide (...args) {
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            employee_code: ''
          }
        }
        this.send('/init/employee', data)
        .then(res => {
          // 職名
          this.job_title_list = res.common_area.combo_data.job_title_list
          this.componentKey += 1
        })
        this.$modal.hide('JobTitleSearch')
      },
      // 職名の追加ボタン押下時の処理
      addEmployeeJobTitleNameRecord () {
        let record = {
          selected: false,
          job_title_name: ''
        }
        this.employee_job_title.push(record)
      },
      isAddMode () {
        return this.mode === this.modes.add
      },
      isEditMode () {
        return this.mode === this.modes.edit
      },
      getStyle () {
        if (this.isAddMode()) {
          return 'width: 400px'
        } else {
          return 'width: 550px'
        }
      },
      getFormatDate (date) {
        if (date === '' || date === undefined || date === null) {
          return ''
        } else {
          return this.formatDate(date)
        }
      },
      isPayrollManagement () {
        if (this.payroll_management.value === '1') {
          return 'required'
        } else {
          return ''
        }
      },
      openMessage () {
        if (this.unchangeable_hire_date_msg !== '') {
          swal({
            html: this.unchangeable_hire_date_msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            width: '600px'
          }).then(() => {
          }).catch(() => {
          })
        }
      }
    },
    created () {
      this.init()
    },
    mounted () {
      this.$nextTick(() => {
        let tab = document.getElementsByClassName('wizard-nav wizard-nav-pills')
        tab[0].children[6].style.display = 'none'
      })
    }
  }
</script>
<style scoped>
</style>
