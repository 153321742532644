<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.application_form_name"
              item-name="application_form_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.application_form_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.application_form_name')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationSelect
              v-model="detail.application_classification_code"
              item-name="application_classification_code"
              :placeholder="$t('label.select_description')"
              classes="length60"
              rules="required"
              :item-label="labels.application_classification_name"
              :options="comboData.application_classification_list"
              :disabled="isReadOnly"
              @change="applicationClassificationCodeChanged"
              data-v-step="0"
            />
            <ValidationCheckboxes
              v-model="detail.skip_apply_employee"
              :item-label="labels.skip_apply_employee"
              :disabled="isReadOnly"
              data-v-step="1"
            />
            <ValidationRadio
              v-model="detail.pulling_flag"
              rules="required"
              :item-label="labels.pulling_flag"
              :disabled="isReadOnly"
              data-v-step="2"
            />
            <ValidationRadio
              v-model="detail.route_flag"
              rules="required"
              :item-label="labels.route_flag"
              vid="routeFlag"
              :disabled="isReadOnly"
              data-v-step="3"
            />
            <ValidationCheckboxes
              v-model="detail.auto_approverl_flag"
              :item-label="labels.auto_approverl_flag"
              :disabled="isReadOnly"
              v-show="detail.route_flag && detail.route_flag.value === '2'"
            />
            <ValidationSelect
              v-model="detail.job_title_code"
              item-name="job_title_code"
              :placeholder="$t('label.select_description')"
              classes="length10"
              :item-label="labels.job_title_name"
              :options="comboData.job_title_list"
              :disabled="isReadOnly"
              @change="jobTitleCodeChanged"
              :unit="$t('label.unit_stop_at')"
              v-show="detail.route_flag && detail.route_flag.value === '3'"
            />
            <ValidationText
              v-model="detail.sort_number"
              item-name="sort_number"
              max-length="5"
              classes="form-control numLength5 text-right"
              rules="required"
              :item-label="labels.sort_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.sort_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="4"
            />
            <ValidationText
              v-model="detail.executed_after_approverl"
              item-name="executed_after_approverl"
              max-length="255"
              classes="form-control length255"
              :item-label="labels.executed_after_approverl"
              :placeholder="isReadOnly ? '' : $t('placeholder.executed_after_approverl')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="false"
            />
            <div style="color: #C84513; margin-bottom: 5px;">
              <small>{{errMsg}}</small>
            </div>
            <ValidationRouteAllocation
              v-model="detail.route_allocation"
              :disabled="isReadOnly"
              :combo-data="comboData"
              rules="routeRequired|isUniqueGroupCode|isIndividualRouteRequired:@routeFlag|isCommonRouteRequired:@routeFlag"
              :labels="labels"
              :application-form-code="params.application_form_code"
              :show-individual-route="detail.route_flag.value === '2'"
              tourSteps="5"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ApplicationFormDetailMixin from '@/components/Dashboard/Views/Generated/ApplicationFormDetailMixin'

  export default {
    name: 'ApplicationFormDetail',
    data () {
      return {
        errMsg: ''
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.group_list.unshift({label: '', value: ''})
        this.comboData.job_title_list.unshift({label: '', value: ''})
        this.detail.skip_apply_employee = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 1,
              label: this.$t('label.skip_apply_employee'),
              value: res.api_area.skip_apply_employee
            }
          ]
        }
        this.detail.auto_approverl_flag = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 2,
              label: this.$t('label.auto_approverl_flag').replace('{item}', this.labels.employee),
              value: res.api_area.auto_approverl_flag === 1
            }
          ]
        }
        this.detail.route_flag = {
          value: String(res.api_area.route_flag),
          disabled: this.isReadOnly,
          items: [
            {
              id: this.comboData.route_flag_list[0].value,
              label: this.getLabel(this.comboData.route_flag_list[0].label),
              value: this.comboData.route_flag_list[0].value
            },
            {
              id: this.comboData.route_flag_list[1].value,
              label: this.getLabel(this.comboData.route_flag_list[1].label),
              value: this.comboData.route_flag_list[1].value
            },
            {
              id: this.comboData.route_flag_list[2].value,
              label: this.getLabel(this.comboData.route_flag_list[2].label),
              value: this.comboData.route_flag_list[2].value
            }
          ]
        }
        this.detail.pulling_flag = {
          value: String(res.api_area.pulling_flag),
          disabled: this.isReadOnly,
          items: [
            {
              id: this.comboData.pulling_flag_list[0].value,
              label: this.getLabel(this.comboData.pulling_flag_list[0].label),
              value: this.comboData.pulling_flag_list[0].value
            },
            {
              id: this.comboData.pulling_flag_list[1].value,
              label: this.getLabel(this.comboData.pulling_flag_list[1].label),
              value: this.comboData.pulling_flag_list[1].value
            },
            {
              id: this.comboData.pulling_flag_list[2].value,
              label: this.getLabel(this.comboData.pulling_flag_list[2].label),
              value: this.comboData.pulling_flag_list[2].value
            }
          ]
        }
        this.detail.auto_approverl_flag = {
          color: 'gray',
          disabled: this.isReadOnly,
          items: [
            {
              id: 2,
              label: this.$t('label.auto_approverl_flag').replace('{item}', this.labels.employee),
              value: res.api_area.auto_approverl_flag === 1
            }
          ]
        }
        for (const row of res.api_area.application_form_route_data) {
          row.isNew = false
        }
        this.detail.route_allocation = res.api_area.application_form_route_data
        this.comboData.individual_route_list.unshift({label: '', value: ''})
        this.comboData.common_route_list.unshift({label: '', value: ''})
      },
      customValidate () {
        this.errMsg = ''
        if (this.detail.route_flag.value === '1' || this.detail.route_flag.value === '2') {
          let cnt = 0
          for (const row of this.detail.application_form_route_data) {
            if (!row.selected) {
              cnt++
            }
          }
          if (cnt === 0) {
            this.errMsg = this.$t('message.is_table_required').replace('{item}', this.$t('label.approval_route'))
            return false
          }
        }
        return true
      },
      convertDetail (detail) {
        if (this.mode !== this.modes.delete) {
          detail.application_form_route_list = [...detail.application_form_route_data]
          delete detail.application_form_route_data
          delete detail.route_allocation
          detail.skip_apply_employee = detail.skip_apply_employee.items[0].value
          detail.pulling_flag = parseInt(detail.pulling_flag.value, 10)
          detail.withdrawal_flag = 1
          detail.route_flag = parseInt(detail.route_flag.value, 10)
          if (detail.route_flag === 2) {
            detail.auto_approverl_flag = detail.auto_approverl_flag.items[0].value ? 1 : 2
          } else {
            detail.auto_approverl_flag = 2
          }
          if (detail.job_title_code === '') {
            detail.job_title_code = null
          }
          detail.sort_number = parseInt(detail.sort_number, 10)
          const applicationFormRouteList = []
          for (const row of detail.application_form_route_list) {
            if (row.isNew && row.selected) continue
            const element = {
              application_form_code: row.application_form_code,
              group_code: row.group_code ? row.group_code : null,
              individual_route_code: this.emptyToBlank(row.individual_route_code),
              common_route_code: this.emptyToBlank(row.common_route_code),
              update_count: row.update_count,
              del_flag: row.selected ? row.selected : false
            }
            applicationFormRouteList.push(element)
          }
          detail.application_form_route_list = applicationFormRouteList
        }
        return detail
      }
    },
    mixins: [ApplicationFormDetailMixin]
  }
</script>
<style scoped>
</style>
