import mixin from '@/lib/mixin'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'
import OfficeDetail from '@/components/Dashboard/Views/Generated/OfficeDetail'
import { DatePicker } from 'element-ui'

export default {
  name: 'CalculationBasisDetailMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationTextNumber,
    ValidationSelect,
    ValidationDate,
    OfficeDetail,
    vSelect,
    'el-date-picker': DatePicker
  },
  props: {
    mode: Number,
    params: Object
  },
  data () {
    let self = this
    return {
      componentKey: 0,
      isRegistKey: 0,
      changeBeforeModificationDateYearKey: 0,
      salaryChangeMonthKey: 0,
      retroactivePaymentMonthKey: 0,
      decisionReasonKey: 0,
      remarksReason12Key: 0,
      employeeClassificationType1Key: 0,
      employeeClassificationType2Key: 0,
      employeeClassificationType3Key: 0,
      changeAmountKey: 0,
      id: 0,
      showDetailItems: true,
      isChecked: false,
      isChanged: false,
      showResult: false,
      isConfirm: false,
      detail: {
        office_code: '',
        notification_date: '',
        owner_name: '',
        company_worker_name: '',
        office_reference_number: '',
        detail_list: []
      },
      labels: {},
      comboData: {},
      officeParams: {},
      errorsMap: {
        office_reference_number: '',
        table: ''
      },
      originalErrorsList: [],
      errorsList: [],
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      monthsBlank: ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      thisYear: {
        disabledDate (date) {
          // 届出日反映前はそのまま返却
          if (!self.showResult) {
            return
          }
          // 届出日決定後はその年のみ変更可能
          let test = self.formatDate(self.detail.notification_date)
          let notificationDate = new Date(test)
          let fromDate = new Date(notificationDate.getFullYear() - 1, 12, 1)
          let toDate = new Date(notificationDate.getFullYear(), 12, 1)
          return date < fromDate || date >= toDate
        }
      }
    }
  },
  computed: {
    screenCode () {
      return '07-010'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    }
  },
  methods: {
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res, isFirst) {
      // 新規登録の詳細時は処理なし
      if (isFirst) {
        return
      }

      // 登録項目を表示
      this.showResult = true

      for (let index = 0; index < this.detail.detail_list.length; index++) {
        for (const entry of Object.entries(this.detail.detail_list[index])) {
          if (typeof entry[1] === 'number') {
            this.detail.detail_list[index][entry[0]] = entry[1] == null ? '' : String(entry[1])
          } else if (typeof this.detail.detail_list[index][entry[0]] === 'string') {
            this.detail.detail_list[index][entry[0]] = entry[1] == null ? '' : entry[1]
          } else {
            this.detail.detail_list[index][entry[0]] = entry[1]
          }
        }
        let detail = {
          insured_person_code: '',
          insured_person_name: '',
          insured_person_reference_number: '',
          birthday: '',
          basic_pension_number: '',
          modification_date: '',
          change_before_modification_date: '',
          change_before_modification_date_year: '',
          change_before_modification_date_month: '',
          change_before_modification_date_wareki: '',
          change_before_grade_health_insurance_standard_monthly_salary: '',
          change_before_health_insurance_standard_monthly_salary: '',
          change_before_grade_welfare_pension_standard_monthly_salary: '',
          change_before_welfare_pension_standard_monthly_salary: '',
          salary_change_month: '',
          salary_change: '',
          payroll_month1: '',
          job_total_days1: '',
          cash_payment_amount1: '',
          total_payment_amount1: '',
          payroll_month2: '',
          job_total_days2: '',
          cash_payment_amount2: '',
          total_payment_amount2: '',
          payroll_month3: '',
          job_total_days3: '',
          cash_payment_amount3: '',
          total_payment_amount3: '',
          total_payment_amount_summary: '',
          total_payment_amount_average: '',
          modified_average_amount: '',
          decision_reason: '',
          remarks_others: '',
          employee_classification_type1: '',
          employee_classification_type2: '',
          employee_classification_type3: '',
          is_target1: false,
          is_target2: false,
          is_target3: false,
          remarks_reason1: '',
          remarks_reason2: '',
          is_non_change: false,
          is_regist: false
        }
        // 定義されていないものはブランク埋め
        for (const entry in detail) {
          if (this.detail.detail_list[index][entry] === undefined) {
            this.detail.detail_list[index][entry] = ''
          }
        }
        let errors = {
          change_before_health_insurance_standard_monthly_salary: '',
          change_before_welfare_pension_standard_monthly_salary: '',
          insured_person_reference_number: '',
          change_before_modification_date_year: '',
          change_before_modification_date_month: '',
          salary_change_month: '',
          salary_change: '',
          retroactive_payment_month: '',
          retroactive_payment_amount: '',
          employee_classification_type1: '',
          employee_classification_type2: '',
          employee_classification_type3: '',
          job_total_days1: '',
          job_total_days2: '',
          job_total_days3: '',
          cash_payment_amount1: '',
          cash_payment_amount2: '',
          cash_payment_amount3: '',
          in_kind_payment_amount1: '',
          in_kind_payment_amount2: '',
          in_kind_payment_amount3: ''
        }
        this.originalErrorsList.push(errors)

        // 従前の健康保険標準報酬月額、従前の厚生年金標準報酬月額をDBの値の1/1000にする
        this.detail.detail_list[index].change_before_health_insurance_standard_monthly_salary = this.detail.detail_list[index].change_before_health_insurance_standard_monthly_salary / 1000
        this.detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary = this.detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary / 1000

        // カンマを付与
        this.formatComma(index, 'change_before_health_insurance_standard_monthly_salary')
        this.formatComma(index, 'change_before_welfare_pension_standard_monthly_salary')
        this.formatComma(index, 'retroactive_payment_amount')
        this.formatComma(index, 'cash_payment_amount1')
        this.formatComma(index, 'cash_payment_amount2')
        this.formatComma(index, 'cash_payment_amount3')
        this.formatComma(index, 'in_kind_payment_amount1')
        this.formatComma(index, 'in_kind_payment_amount2')
        this.formatComma(index, 'in_kind_payment_amount3')
        this.formatComma(index, 'total_payment_amount1')
        this.formatComma(index, 'total_payment_amount2')
        this.formatComma(index, 'total_payment_amount3')
        this.formatComma(index, 'total_payment_amount_summary')
        this.formatComma(index, 'total_payment_amount_average')
        this.formatComma(index, 'modified_average_amount')

        // 従前の改定年月
        if (this.detail.detail_list[index].change_before_modification_date) {
          this.detail.detail_list[index].change_before_modification_date_year = this.detail.detail_list[index].change_before_modification_date.substring(0, 4)
          this.detail.detail_list[index].change_before_modification_date_month = parseInt(this.detail.detail_list[index].change_before_modification_date.substring(4, 6), 10)
          this.detail.detail_list[index].change_before_modification_date_wareki = this.seirekiToWareki(this.detail.detail_list[index].change_before_modification_date, 'narrow')
        }

        // 遡及支払月
        if (this.detail.detail_list[index].retroactive_payment_month) {
          this.detail.detail_list[index].retroactive_payment_month = parseInt(this.detail.detail_list[index].retroactive_payment_month.substring(4, 6), 10)
        }
        // idがある場合、登録対象
        if (this.detail.detail_list[index].id !== '') {
          this.detail.detail_list[index].is_regist = true
        }

        // 金額計算
        this.changeAmount(index, '', false)
      }
      // エラーリストを設定
      this.errorsList = JSON.parse(JSON.stringify(this.originalErrorsList))

      // 従業員区分タイプにブランク追加
      this.comboData.employee_classification_type_list.unshift({ 'label': '', 'value': '' })

      // 昇給降給区分にブランク追加
      this.comboData.salary_change_list.unshift({ 'label': '', 'value': '' })

      // 給料決定理由にブランク追加
      this.comboData.decision_reason_list.unshift({ 'label': '', 'value': '' })

      // 給料決定理由からその他削除
      this.comboData.decision_reason_list.pop()

      // 更新の場合、前回届出日を設定する
      if (this.mode === this.modes.edit) {
        this.detail.before_notification_date = this.detail.notification_date
      }
    },
    // カンマを付与
    formatComma (index, item) {
      if (this.detail.detail_list[index][item]) {
        if (Number.isNaN(this.detail.detail_list[index][item])) {
          return
        }
        this.detail.detail_list[index][item] = this.formatNumber(String(this.detail.detail_list[index][item]))
      }
    },
    // 独自バリデーション用
    customValidate () {
      // チェックしたことがあればtrue
      this.isChecked = true
      // チェック成功判定
      let isSuccess = true

      if (this.detail.office_reference_number === '') {
        this.errorsMap.office_reference_number = this.$t('message.field_required_simple')
        isSuccess = false
      }

      // 初期化
      this.errorsList = JSON.parse(JSON.stringify(this.originalErrorsList))
      this.isConfirm = false
      // detail_listのチェック
      for (let index = 0; index < this.detail.detail_list.length; index++) {
        // 変更不要フラグがtrueの場合、チェックなし
        if (this.detail.detail_list[index].is_non_change) {
          continue
        // 保存対象がfalseの場合、チェックなし
        } else if (!this.detail.detail_list[index].is_regist) {
          this.isConfirm = true
          continue
        }
        // 必須チェック
        this.requiredValidate(index, 'change_before_health_insurance_standard_monthly_salary')
        this.requiredValidate(index, 'change_before_welfare_pension_standard_monthly_salary')
        this.requiredValidate(index, 'insured_person_reference_number')
        this.requiredValidate(index, 'change_before_modification_date_year')
        this.requiredValidate(index, 'change_before_modification_date_month')

        // 組合せチェック⑦
        this.combination7Validate(index)

        // 組合せチェック⑧
        this.combination8Validate(index)

        // 組合せチェック⑨～⑫
        this.combination9_12Validate(index, '1')
        this.combination9_12Validate(index, '2')
        this.combination9_12Validate(index, '3')

        // エラー表示場所を変える
        if (this.errorsList[index].change_before_modification_date_month) {
          this.errorsList[index].change_before_modification_date_year = this.errorsList[index].change_before_modification_date_month
        }
        if (this.errorsList[index].salary_change) {
          this.errorsList[index].salary_change_month = this.errorsList[index].salary_change
        }
      }

      // エラーがあった場合、falseを設定
      this.errorsMap.table = ''
      let i = 0
      for (let errors of this.errorsList) {
        i++
        for (let error in errors) {
          if (errors[error] !== '') {
            isSuccess = false
            if (this.errorsMap.table === '') {
              this.errorsMap.table = '項目名：' + i
            } else {
              this.errorsMap.table = this.errorsMap.table + '、 ' + i
            }
            break
          }
        }
      }

      return isSuccess
    },
    // 必須チェック
    requiredValidate (index, item) {
      if (this.detail.detail_list[index][item] === '' || this.detail.detail_list[index][item] === null) {
        this.errorsList[index][item] = this.$t('message.field_required_simple')
      } else {
        this.errorsList[index][item] = ''
      }
    },
    // 組合せチェック⑦
    combination7Validate (index) {
      // 一度も登録ボタン押下してないなら出さない
      if (!this.isChecked) {
        return
      }
      // 昇(降)給月がブランク以外の場合、昇(降)給区分は必須
      if (this.detail.detail_list[index].salary_change_month !== '') {
        if (this.detail.detail_list[index].salary_change === '') {
          this.errorsList[index].salary_change_month = this.$t('message.combination_message').replace('{item}', this.$t('label.month')).replace('{item2}', this.$t('label.salary_change'))
        }
      }
      // 昇(降)給区分がブランク以外の場合、昇(降)給月は必須
      if (this.detail.detail_list[index].salary_change !== '') {
        if (this.detail.detail_list[index].salary_change_month === '') {
          this.errorsList[index].salary_change_month = this.$t('message.combination_message').replace('{item}', this.$t('label.salary_change')).replace('{item2}', this.$t('label.month'))
        }
      }
    },
    // 組合せチェック⑧
    combination8Validate (index) {
      // 一度も登録ボタン押下してないなら出さない
      if (!this.isChecked) {
        return
      }
      // 遡及月がブランク以外の場合、遡及支払額は必須
      if (this.detail.detail_list[index].retroactive_payment_month !== '') {
        if (this.detail.detail_list[index].retroactive_payment_amount === '') {
          this.errorsList[index].retroactive_payment_month = this.$t('message.combination_message').replace('{item}', this.$t('label.month')).replace('{item2}', this.$t('label.retroactive_payment_amount'))
        }
      }
      // 遡及支払額がブランク以外の場合、遡及月は必須
      if (this.detail.detail_list[index].retroactive_payment_amount !== '') {
        if (this.detail.detail_list[index].retroactive_payment_month === '') {
          this.errorsList[index].retroactive_payment_month = this.$t('message.combination_message').replace('{item}', this.$t('label.retroactive_payment_amount')).replace('{item2}', this.$t('label.month'))
        }
      }
    },
    // 組合せチェック⑨～⑫
    combination9_12Validate (index, number) {
      // 変数
      let employeeClassificationType = 'employee_classification_type' + number
      let jobTotalDays = 'job_total_days' + number
      let cashPaymentAmount = 'cash_payment_amount' + number
      let inKindPaymentAmount = 'in_kind_payment_amount' + number
      // エラー初期化
      this.errorsList[index][employeeClassificationType] = ''
      this.errorsList[index][jobTotalDays] = ''
      this.errorsList[index][cashPaymentAmount] = ''
      this.errorsList[index][inKindPaymentAmount] = ''

      // 従業員区分がブランク以外の場合、日数は必須
      if (this.detail.detail_list[index][employeeClassificationType] !== '') {
        if (this.detail.detail_list[index][jobTotalDays] === '') {
          this.errorsList[index][employeeClassificationType] = this.$t('message.change_amount_message')
        }
      }
      // 日数がブランク以外の場合、従業員区分は必須
      if (this.detail.detail_list[index][jobTotalDays] !== '') {
        if (this.detail.detail_list[index][employeeClassificationType] === '') {
          this.errorsList[index][employeeClassificationType] = this.$t('message.change_amount_message')
        }
      }
      // 従業員区分、日数がブランクの場合、通貨は入力不可
      // 従業員区分、日数がブランクの場合、現物は入力不可
      if (this.detail.detail_list[index][employeeClassificationType] === '' && this.detail.detail_list[index][jobTotalDays] === '') {
        if (this.detail.detail_list[index][cashPaymentAmount] !== '') {
          this.errorsList[index][employeeClassificationType] = this.$t('message.change_amount_message')
        }
        if (this.detail.detail_list[index][inKindPaymentAmount] !== '') {
          this.errorsList[index][employeeClassificationType] = this.$t('message.change_amount_message')
        }
      }
    },
    // 送信データ調整用
    convertDetail (detail) {
      // 削除の場合、そのまま返却する
      if (this.mode === this.modes.delete) {
        return detail
      }

      let detailList = []
      for (let index = 0; index < detail.detail_list.length; index++) {
        // 変更不要フラグがtrueの場合、ダミーデータで埋める
        if (detail.detail_list[index].is_non_change) {
          detail.detail_list[index].change_before_health_insurance_standard_monthly_salary = '0'
          detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary = '0'
          detail.detail_list[index].change_before_modification_date = '999912'
          detail.detail_list[index].insured_person_reference_number = '0'
        // 登録対象がfalseの場合、次の要素を処理する
        } else if (!detail.detail_list[index].is_regist) {
          continue
        }

        // 数値に変換
        this.covertNum(index, detail, 'insured_person_reference_number')
        this.covertNum(index, detail, 'change_before_grade_health_insurance_standard_monthly_salary')
        this.covertNum(index, detail, 'change_before_health_insurance_standard_monthly_salary')
        this.covertNum(index, detail, 'change_before_grade_welfare_pension_standard_monthly_salary')
        this.covertNum(index, detail, 'change_before_welfare_pension_standard_monthly_salary')
        this.covertNum(index, detail, 'retroactive_payment_amount')
        this.covertNum(index, detail, 'job_total_days1')
        this.covertNum(index, detail, 'job_total_days2')
        this.covertNum(index, detail, 'job_total_days3')
        this.covertNum(index, detail, 'cash_payment_amount1')
        this.covertNum(index, detail, 'cash_payment_amount2')
        this.covertNum(index, detail, 'cash_payment_amount3')
        this.covertNum(index, detail, 'in_kind_payment_amount1')
        this.covertNum(index, detail, 'in_kind_payment_amount2')
        this.covertNum(index, detail, 'in_kind_payment_amount3')
        this.covertNum(index, detail, 'total_payment_amount1')
        this.covertNum(index, detail, 'total_payment_amount2')
        this.covertNum(index, detail, 'total_payment_amount3')
        this.covertNum(index, detail, 'total_payment_amount_summary')
        this.covertNum(index, detail, 'total_payment_amount_average')
        this.covertNum(index, detail, 'modified_average_amount')
        this.covertNum(index, detail, 'salary_change')
        this.covertNum(index, detail, 'salary_change_month')
        this.covertNum(index, detail, 'decision_reason')
        this.covertNum(index, detail, 'employee_classification_type1')
        this.covertNum(index, detail, 'employee_classification_type2')
        this.covertNum(index, detail, 'employee_classification_type3')
        this.covertNum(index, detail, 'remarks_reason1')
        this.covertNum(index, detail, 'remarks_reason2')

        // 従前の健康保険標準報酬月額、従前の厚生年金標準報酬月額を画面の値の1000倍にする
        detail.detail_list[index].change_before_health_insurance_standard_monthly_salary = detail.detail_list[index].change_before_health_insurance_standard_monthly_salary * 1000
        detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary = detail.detail_list[index].change_before_welfare_pension_standard_monthly_salary * 1000

        // 遡及支払月が入力されている場合
        if (detail.detail_list[index].retroactive_payment_month) {
          // 改定年月の年を取得
          let retroactivePaymentYear = detail.detail_list[index].modification_date.substring(0, 4)
          // 遡及支払月を2桁にフォーマット
          let retroactivePaymentMonth = ('00' + parseInt(detail.detail_list[index].retroactive_payment_month)).slice(-2)
          // 改定年月の月が遡及支払月より小さい場合、一年前を設定する
          if (detail.detail_list[index].modification_date.substring(4, 6) > retroactivePaymentMonth) {
            detail.detail_list[index].retroactive_payment_month = retroactivePaymentYear + retroactivePaymentMonth
          } else {
            retroactivePaymentYear = parseInt(retroactivePaymentYear) - 1
            detail.detail_list[index].retroactive_payment_month = retroactivePaymentYear + retroactivePaymentMonth
          }
        }

        // 70歳以上被用者算定以外の場合、基礎年金番号、備考／算定基礎月1、2にnullを設定
        if (detail.detail_list[index].decision_reason !== 1) {
          detail.detail_list[index].basic_pension_number = null
          detail.detail_list[index].remarks_reason1 = null
          detail.detail_list[index].remarks_reason2 = null
        }

        // 不要な項目削除
        delete detail.detail_list[index].id
        delete detail.detail_list[index].change_before_modification_date_year
        delete detail.detail_list[index].change_before_modification_date_month
        delete detail.detail_list[index].change_before_modification_date_wareki
        delete detail.detail_list[index].grade_health_insurance_standard_monthly_salary
        delete detail.detail_list[index].health_insurance_standard_monthly_salary
        delete detail.detail_list[index].grade_welfare_pension_standard_monthly_salary
        delete detail.detail_list[index].welfare_pension_standard_monthly_salary
        delete detail.detail_list[index].salary_increase
        delete detail.detail_list[index].is_regist
        delete detail.detail_list[index].is_target1
        delete detail.detail_list[index].is_target2
        delete detail.detail_list[index].is_target3

        detailList.push(detail.detail_list[index])
      }
      // detail_listを設定
      detail.detail_list = detailList

      // 不要な項目削除
      delete detail.office_reference_number
      delete detail.social_insurance_labor_consultant
      // 登録の場合、update_countを削除
      if (this.mode === this.modes.add) {
        delete detail.update_count
      }

      return detail
    },
    // 数値に変換
    covertNum (index, detail, item) {
      if (detail.detail_list[index][item]) {
        detail.detail_list[index][item] = parseInt(String(detail.detail_list[index][item]).replaceAll(',', ''))
      } else if (detail.detail_list[index][item] === 0) {
        detail.detail_list[index][item] = 0
      } else {
        detail.detail_list[index][item] = null
      }
    },
    showAdd () {
      let params = {}
      params.office_code = this.detail.office_code
      this.officeParams = params
      this.$modal.show('office')
    },
    hideOffice (changed, added) {
      this.$modal.hide('office')
      if (changed) {
        this.detail.office_reference_number = added.office_reference_number
        // this.$refs.detail.detail.place_code = added.place_code
      }
    },
    convertBirthday (birthday) {
      if (birthday === '') {
        return birthday
      }
      let eraNameMap = {
        'M': '1',
        'T': '3',
        'S': '5',
        'H': '7',
        'R': '9'
      }
      let date = new Date(birthday)
      let options = {era: 'narrow', year: '2-digit', month: '2-digit', day: '2-digit'}
      let jpDate = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(date)
      return eraNameMap[jpDate.substring(0, 1)] + '-' + jpDate.toString().substring(1).replace(/\//g, '')
    },
    detailChanged (index) {
      this.detail.detail_list[index].is_regist = true
      this.isRegistKey += 1
    },
    isNonChangeChanged (index) {
      this.detail.detail_list[index].is_regist = false
      this.isRegistKey += 1
    },
    isRegistChanged (index) {
      if (!this.detail.detail_list[index].is_regist) {
        this.detail.detail_list[index].is_regist = true
      } else {
        this.detail.detail_list[index].is_regist = false
      }
      this.isRegistKey += 1
    },
    insuredPersonReferenceNumberChanged (index) {
      this.detailChanged(index)
      this.detail.detail_list[index].insured_person_reference_number = String(this.detail.detail_list[index].insured_person_reference_number).replaceAll(',', '')
    },
    changeBeforeModificationDateYearChanged (index) {
      this.detailChanged(index)
      if (this.detail.detail_list[index].change_before_modification_date_month === '') {
        this.detail.detail_list[index].change_before_modification_date = this.detail.detail_list[index].change_before_modification_date_year
      } else {
        this.detail.detail_list[index].change_before_modification_date = this.detail.detail_list[index].change_before_modification_date_year + ('00' + parseInt(this.detail.detail_list[index].change_before_modification_date_month)).slice(-2)
      }
      if (this.detail.detail_list[index].change_before_modification_date.length === 6) {
        this.detail.detail_list[index].change_before_modification_date_wareki = this.seirekiToWareki(this.detail.detail_list[index].change_before_modification_date, 'narrow')
      }
      this.changeBeforeModificationDateYearKey += 1
    },
    changeBeforeModificationDateMonthChanged (index) {
      this.detailChanged(index)
      this.detail.detail_list[index].change_before_modification_date = this.detail.detail_list[index].change_before_modification_date_year + ('00' + parseInt(this.detail.detail_list[index].change_before_modification_date_month)).slice(-2)
      if (this.detail.detail_list[index].change_before_modification_date.length === 6) {
        this.detail.detail_list[index].change_before_modification_date_wareki = this.seirekiToWareki(this.detail.detail_list[index].change_before_modification_date, 'narrow')
      }
      this.changeBeforeModificationDateYearKey += 1
    },
    salaryChangeMonthChanged (index) {
      this.detailChanged(index)
      this.combination7Validate(index)
      this.salaryChangeMonthKey += 1
    },
    salaryChangeChanged (index) {
      this.detailChanged(index)
      this.combination7Validate(index)
      this.salaryChangeMonthKey += 1
    },
    retroactivePaymentMonthChanged (index) {
      this.detailChanged(index)
      this.combination8Validate(index)
      this.retroactivePaymentMonthKey += 1
    },
    retroactivePaymentAmountChanged (index) {
      this.detailChanged(index)
      this.combination8Validate(index)
    },
    decisionReasonChanged (index) {
      this.detailChanged(index)
      this.decisionReasonKey += 1
    },
    remarksReason12Changed (index) {
      this.detailChanged(index)
      this.remarksReason12Key += 1
    },
    employeeClassificationType1Changed (index) {
      this.detailChanged(index)
      this.changeAmount(index, '1')
      this.employeeClassificationType1Key += 1
    },
    employeeClassificationType2Changed (index) {
      this.detailChanged(index)
      this.changeAmount(index, '2')
      this.employeeClassificationType2Key += 1
    },
    employeeClassificationType3Changed (index) {
      this.detailChanged(index)
      this.changeAmount(index, '3')
      this.employeeClassificationType3Key += 1
    },
    documentStyleChanged () {
      // スクロールバー設定
      this.$nextTick(() => {
        if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
          this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
        }
      })
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    doAdd () {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.showDetailItems = true
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        // 未入力がある場合
        if (this.isConfirm) {
          swal({
            html: `<div style="text-align: center; font-size: 18px;">${this.$t('message.confirm_to_insert1')}<br>${this.$t('message.confirm_to_insert2')}<br><small>${this.$t('message.confirm_to_insert3')}</small></div>`,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            this.submit('insert')
          }).catch(() => {})
          // 全部入力済みの場合
        } else {
          this.submit('insert')
        }
      })
    },
    doUpdate () {
      this.$refs.observer.validate().then(isValid => {
        let isCustomValid = this.customValidate()
        if (!isValid || !isCustomValid) {
          this.showDetailItems = true
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        // 未入力がある場合
        if (this.isConfirm) {
          swal({
            html: `<div style="text-align: center; font-size: 18px;">${this.$t('message.confirm_to_insert1')}<br>${this.$t('message.confirm_to_insert2')}<br><small>${this.$t('message.confirm_to_insert3')}</small></div>`,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            type: 'warning',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no')
          }).then(() => {
            this.submit('update')
          }).catch(() => {})
          // 全部入力済みの場合
        } else {
          this.submit('update')
        }
      })
    },
    doDelete () {
      swal({
        text: this.$t('message.confirm_to_delete'),
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(() => {
        this.submit('delete')
      }).catch(() => {})
    },
    submit (operation) {
      // this.$refs.observer.validate().then(isValid => {
      //   let isCustomValid = this.customValidate(this.mode)
      //   if (!isValid || !isCustomValid) {
      //     this.$emit('on-submit', this.registrationForm, false)
      //     this.$refs.frame.scrollTop = 0
      //     return
      //   }
      let detail = null
      if (this.mode === this.modes.add) {
        detail = JSON.parse(JSON.stringify(this.detail))
      } else if (this.mode === this.modes.edit) {
        detail = JSON.parse(JSON.stringify(this.detail))
        detail.update_count = parseInt(this.detail.update_count, 10)
      } else if (this.mode === this.modes.delete) {
        detail = {office_code: this.detail.office_code}
        detail.notification_date = this.detail.notification_date
        detail.update_count = parseInt(this.detail.update_count, 10)
      }
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertDetail(this.nullToBlank(detail))
      }
      this.send(`/employee_socialInsurance/${operation}/calculation_basis`, reqData)
      .then(res => {
        this.componentKey += 1
        if (res.message.message_classification === 1) {
          this.isChanged = true
        }
      }).catch(error => {
        console.error(error)
      })
      // })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code,
          notification_date: this.params.notification_date,
          is_insert: this.params.is_insert
        }
      }
      this.send('/employee_socialInsurance/init/calculation_basis', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        let isFirst = true
        if (res.api_area) {
          for (const entry of Object.entries(res.api_area)) {
            isFirst = false
            if (typeof entry[1] === 'number') {
              this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
            } else if (typeof this.detail[entry[0]] === 'string') {
              this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
            } else {
              this.detail[entry[0]] = entry[1]
            }
          }
        }
        this.afterInit(res, isFirst)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    },
    // initDetailチェック
    isInitDetail () {
      let isSuccess = true
      return isSuccess
    },
    initDetail () {
      this.$refs.observer.validate().then(isValid => {
        if (!isValid || !this.isInitDetail()) {
          this.$emit('on-submit', this.registrationForm, false)
          this.$refs.frame.scrollTop = 0
          return
        }
        let data = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            office_code: this.detail.office_code,
            notification_date: this.detail.notification_date,
            is_insert: true
          }
        }
        this.send('/employee_socialInsurance/init/calculation_basis', data)
          .then(res => {
            this.labels = this.getLabels(res.common_area)
            this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
            if (res.api_area) {
              for (const entry of Object.entries(res.api_area)) {
                if (typeof entry[1] === 'number') {
                  this.detail[entry[0]] = entry[1] == null ? '' : String(entry[1])
                } else if (typeof this.detail[entry[0]] === 'string') {
                  this.detail[entry[0]] = entry[1] == null ? '' : entry[1]
                } else {
                  this.detail[entry[0]] = entry[1]
                }
              }
            }
            this.afterInit(res, false)
            this.$nextTick(() => {
              if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
                this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
              }
            })
            this.componentKey += 1
          })
        this.componentKey += 1
      })
    },
    /**
     * 従業員区分、日数、通貨、現物変更時に再計算
     * @param {number} index 何番目の詳細
     * @param {string} number 何番目の月(1~3)
     * @param {boolean} isDetailChanged 詳細変更フラグ(true:登録対象ONと組合せチェック、false:処理なし)
     */
    changeAmount (index, number, isDetailChanged = true) {
      // 日数
      let jobTotalDay1 = parseInt(String(this.detail.detail_list[index].job_total_days1))
      let jobTotalDay2 = parseInt(String(this.detail.detail_list[index].job_total_days2))
      let jobTotalDay3 = parseInt(String(this.detail.detail_list[index].job_total_days3))
      // 通貨
      let cashPaymentAmount1 = parseInt(String(this.detail.detail_list[index].cash_payment_amount1).replaceAll(',', ''))
      let cashPaymentAmount2 = parseInt(String(this.detail.detail_list[index].cash_payment_amount2).replaceAll(',', ''))
      let cashPaymentAmount3 = parseInt(String(this.detail.detail_list[index].cash_payment_amount3).replaceAll(',', ''))
      // 現物
      let inKindPaymentAmount1 = parseInt(String(this.detail.detail_list[index].in_kind_payment_amount1).replaceAll(',', ''))
      let inKindPaymentAmount2 = parseInt(String(this.detail.detail_list[index].in_kind_payment_amount2).replaceAll(',', ''))
      let inKindPaymentAmount3 = parseInt(String(this.detail.detail_list[index].in_kind_payment_amount3).replaceAll(',', ''))
      // 対象
      let isTarget1 = true
      let isTarget2 = true
      let isTarget3 = true
      // 合計
      let totalPaymentAmount1 = 0
      let totalPaymentAmount2 = 0
      let totalPaymentAmount3 = 0
      // 総額
      let totalPaymentAmountSummary = 0
      // 平均カウント
      let averageCount = 0

      // 通貨がブランクの場合、0を設定
      if (!jobTotalDay1) {
        jobTotalDay1 = 0
      }
      if (!jobTotalDay2) {
        jobTotalDay2 = 0
      }
      if (!jobTotalDay3) {
        jobTotalDay3 = 0
      }

      // 通貨がブランクの場合、0を設定
      if (!cashPaymentAmount1) {
        cashPaymentAmount1 = 0
      }
      if (!cashPaymentAmount2) {
        cashPaymentAmount2 = 0
      }
      if (!cashPaymentAmount3) {
        cashPaymentAmount3 = 0
      }

      // 現物がブランクの場合、0を設定
      if (!inKindPaymentAmount1) {
        inKindPaymentAmount1 = 0
      }
      if (!inKindPaymentAmount2) {
        inKindPaymentAmount2 = 0
      }
      if (!inKindPaymentAmount3) {
        inKindPaymentAmount3 = 0
      }
      // 計算対象判定1
      // 一般の場合
      if (this.detail.detail_list[index].employee_classification_type1 === '1') {
        // 17日未満の場合、対象外
        if (jobTotalDay1 < 17) {
          isTarget1 = false
        }
      // 短時間労働者の場合
      } else if (this.detail.detail_list[index].employee_classification_type1 === '2') {
        // 11日未満の場合、対象外
        if (jobTotalDay1 < 11) {
          isTarget1 = false
        }
        // パートの場合
      } else if (this.detail.detail_list[index].employee_classification_type1 === '3') {
        // 17日未満の場合、対象外
        if (jobTotalDay1 < 17) {
          isTarget1 = false
        }
      // 未選択の場合
      } else {
        isTarget1 = false
      }

      // 計算対象判定2
      // 一般の場合
      if (this.detail.detail_list[index].employee_classification_type2 === '1') {
        // 17日未満の場合、対象外
        if (jobTotalDay2 < 17) {
          isTarget2 = false
        }
      // 短時間労働者の場合
      } else if (this.detail.detail_list[index].employee_classification_type2 === '2') {
        // 11日未満の場合、対象外
        if (jobTotalDay2 < 11) {
          isTarget2 = false
        }
      // パートの場合
      } else if (this.detail.detail_list[index].employee_classification_type2 === '3') {
        // 17日未満の場合、対象外
        if (jobTotalDay2 < 17) {
          isTarget2 = false
        }
      // 未選択の場合
      } else {
        isTarget2 = false
      }

      // 計算対象判定3
      // 一般の場合
      if (this.detail.detail_list[index].employee_classification_type3 === '1') {
        // 17日未満の場合、対象外
        if (jobTotalDay3 < 17) {
          isTarget3 = false
        }
      // 短時間労働者の場合
      } else if (this.detail.detail_list[index].employee_classification_type3 === '2') {
        // 11日未満の場合、対象外
        if (jobTotalDay3 < 11) {
          isTarget3 = false
        }
      // パートの場合
      } else if (this.detail.detail_list[index].employee_classification_type3 === '3') {
        // 17日未満の場合、対象外
        if (jobTotalDay3 < 17) {
          isTarget3 = false
        }
      // 未選択の場合
      } else {
        isTarget3 = false
      }

      // 対象がなかった場合
      if (!isTarget1 && !isTarget2 && !isTarget3) {
        // 全てパートだった場合
        if (this.detail.detail_list[index].employee_classification_type1 === '3' &&
            this.detail.detail_list[index].employee_classification_type2 === '3' &&
            this.detail.detail_list[index].employee_classification_type3 === '3') {
          // 15日未満ではないの場合、対象
          if (!(jobTotalDay1 < 15)) {
            isTarget1 = true
          }
          if (!(jobTotalDay2 < 15)) {
            isTarget2 = true
          }
          if (!(jobTotalDay3 < 15)) {
            isTarget3 = true
          }
        }
      }
      // 対象
      this.detail.detail_list[index].is_target1 = isTarget1
      this.detail.detail_list[index].is_target2 = isTarget2
      this.detail.detail_list[index].is_target3 = isTarget3
      // 合計
      totalPaymentAmount1 = cashPaymentAmount1 + inKindPaymentAmount1
      this.detail.detail_list[index].total_payment_amount1 = this.formatNumber(totalPaymentAmount1)
      if (isTarget1) {
        averageCount++
      } else {
        totalPaymentAmount1 = 0
      }
      totalPaymentAmount2 = cashPaymentAmount2 + inKindPaymentAmount2
      this.detail.detail_list[index].total_payment_amount2 = this.formatNumber(totalPaymentAmount2)
      if (isTarget2) {
        averageCount++
      } else {
        totalPaymentAmount2 = 0
      }
      totalPaymentAmount3 = cashPaymentAmount3 + inKindPaymentAmount3
      this.detail.detail_list[index].total_payment_amount3 = this.formatNumber(totalPaymentAmount3)
      if (isTarget3) {
        averageCount++
      } else {
        totalPaymentAmount3 = 0
      }
      // 総額
      totalPaymentAmountSummary = totalPaymentAmount1 + totalPaymentAmount2 + totalPaymentAmount3
      this.detail.detail_list[index].total_payment_amount_summary = this.formatNumber(totalPaymentAmountSummary)
      // 平均
      if (averageCount !== 0) {
        this.detail.detail_list[index].total_payment_amount_average = this.formatNumber(totalPaymentAmountSummary / averageCount)
      } else {
        this.detail.detail_list[index].total_payment_amount_average = 0
      }
      // 詳細変更時
      if (isDetailChanged) {
        // 登録対象ON
        this.detailChanged(index)
        // 組合せチェック
        this.combination9_12Validate(index, number)
      }

      this.changeAmountKey += 1
    },
    toggleDetailItems () {
      this.showDetailItems = !this.showDetailItems
    }
  },
  created () {
    this.init()
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
  }
}
