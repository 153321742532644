<template>
  <div ref="frame">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" :key="componentKey">
      <button type="button" class="btn btn-sm btn-default btn-warning" @click="doClose">
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
        </div>
        <div class="card-content">
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.task_name"
              item-name="task_name"
              max-length="30"
              classes="form-control length30"
              rules="required"
              :item-label="labels.task_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.task_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.message"
              item-name="message"
              max-length="30"
              classes="form-control length30"
              rules=""
              v-if="mode==modes.edit"
              :item-label="labels.message"
              :placeholder="isReadOnly ? '' : $t('placeholder.message')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <template v-if="mode==modes.edit && !this.isChanged">
              <button type="button" class="btn btn-sm btn-default btn-info" @click="doSend">
                {{$t('button.send')}}
              </button>
            </template>
            <table>
              <tr v-for="msg in detail.messages" v-bind:key="msg.id">
                <td>{{msg.message}}</td>
                <td>{{msg.name}}</td>
                <td>{{msg.time}}</td>
              </tr>
            </table>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="doAdd">
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="doUpdate">
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-info" @click="doDelete">
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import TaskDetailMixin from '@/components/Dashboard/Views/Master/TaskDetailMixin'

  export default {
    name: 'TaskDetail',
    mixins: [TaskDetailMixin]
  }
</script>
<style scoped>
</style>
