<template>
  <div>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <ValidationObserver ref="observer">
                  <form @submit.prevent="login" method="POST" action="#">
                    <div class="card" data-background="color" data-color="blue">
                      <div class="card-header">
                        <h3 class="card-title">{{$t('label.login')}}</h3>
                      </div>
                      <div class="card-content">
                        <ValidationText
                          v-model="companyCode"
                          item-name="companyCode"
                          max-length="10"
                          classes="form-control length24"
                          rules="required"
                          :item-label="$t('label.company_code')"
                          :placeholder="$t('placeholder.company_code')"
                          :disabled="false"
                          :help-text="$t('label.max_length')"
                        />
                        <ValidationText
                          v-model="employeeCode"
                          item-name="employeeCode"
                          max-length="10"
                          classes="form-control length24"
                          rules="required"
                          :item-label="$t('label.employee_code')"
                          :placeholder="$t('placeholder.employee_code')"
                          :disabled="false"
                          :help-text="$t('label.max_length')"
                        />
                        <ValidationText
                          v-model="password"
                          :type="passwordType"
                          item-name="password"
                          max-length="255"
                          classes="form-control length24"
                          rules="required"
                          :item-label="$t('label.password')"
                          :placeholder="$t('placeholder.password')"
                          :disabled="false"
                          :help-text="$t('label.max_length')"
                        />
                        <div class="form-group">
                          <div class="pull-right">
                            <input 
                              type="checkbox" 
                              v-model="showPassword" 
                              class="gray"
                              id="showPassword"
                            >
                            <label for="showPassword" style="cursor: pointer;">
                              {{$t('label.show_password')}}
                            </label>
                          </div>
                        </div>
                      </div>
                      <br>
                      <br>
                      <div class="card-footer text-center">
                        <button type="submit" class="btn btn-sm btn-default btn-wd btn-warning">{{$t('button.login')}}</button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="buttons" style="text-align: center;">
              <a class="button" href="/static/felica/Artemis Collier.zip">{{$t('label.felica_download')}}</a>
              <a class="button" href="/#/qrcode/login">{{$t('label.qrcode_login')}}</a>
              <a class="button" href="/#/admin/login">{{$t('label.admin_login')}}</a>
              &nbsp;
              <a href="https://apps.apple.com/app/id1625521109" target="_blank"><img class="button_appstore" :src="$t('label.app_store_logo_path')" /></a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=jp.co.pocketsoft.flutter.artemis" target="_blank"><img class="button_appstore" :src="$t('label.google_play_logo_path')" /></a>
            </div>
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <div class="copyright">
                  &copy; 2020-2024 PocketSoft Co., Ltd.
                </div>
              </div>
              <div style="display: inline-block;">
                <a style="color: #666" href="/#/notation-on-law">{{$t('label.notation_on_law')}}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader,
      ValidationText
    },
    data () {
      return {
        companyCode: undefined,
        employeeCode: undefined,
        password: undefined,
        showPassword: false,
        options: new Map()
      }
    },
    computed: {
      passwordType () {
        return this.showPassword ? 'text' : 'password'
      },
      picked () {
        var elements = document.getElementsByName('radio1')
        for (var element of elements) {
          if (element.checked) {
            return element.value
          }
        }
        return ''
      }
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }
          let self = this
          this.$firebase.auth().signInWithEmailAndPassword(process.env.VUE_APP_FIREBASE_USER, process.env.VUE_APP_FIREBASE_PASSWORD)
          .then(() => {
            let loginReqData = {
              screen_code: '00-000',
              company_code: this.companyCode,
              employee_code: this.employeeCode,
              password: this.password
            }
            self.send('/login/auth', loginReqData)
            .then(res => {
              if (res.api_area.isSubscription && !res.api_area.cancelAtPeriodEnd) {
                self.$store.commit('setNoticeList', res.api_area.notice_list)
                for (const row of res.api_area.alert_list) {
                  row.selected = false
                }
                self.$store.commit('setAlertList', res.api_area.alert_list)
                if (res.api_area.company_append_path) {
                  const httpsCompanyReference = this.$firebase.storage()
                    .ref()
                    .child(res.api_area.company_append_path)
                  httpsCompanyReference.getDownloadURL().then(function (url) {
                    self.$store.commit('setLogoUrl', url)
                  })
                } else {
                  self.$store.commit('setLogoUrl', '')
                }
                if (res.api_area.employee_append_path) {
                  const httpsReference = this.$firebase.storage()
                    .ref()
                    .child(res.api_area.employee_append_path)
                  httpsReference.getDownloadURL().then(function (url) {
                    self.$store.commit('setIconUrl', url)
                    self.afterLogin(res)
                  }).catch(() => {
                    self.$store.commit('setIconUrl', '')
                    self.afterLogin(res)
                  })
                } else {
                  self.$store.commit('setIconUrl', '')
                  self.afterLogin(res)
                }
              } else {
                let message = this.$t('message.contract_not_enabled')
                if (res.api_area.cancelAtPeriodEnd) {
                  message = this.$t('message.contract_already_end')
                }
                swal({
                  text: message,
                  type: 'error',
                  confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                  buttonsStyling: false,
                  allowOutsideClick: false
                })
              }
            }).catch((error) => {
              console.error(error)
            })
          })
        })
      },
      afterLogin (res) {
        const self = this
        const companyName = res.api_area.company_name
        let language
        switch (res.api_area.language) {
          case 'EN':
            language = 'en'
            break
          default:
            language = 'ja'
            break
        }
        self.$i18n.locale = language
        localStorage.setItem('language', language)
        sessionStorage.setItem('systemCompanyFlg', res.api_area.system_company_flg)
        const sidebarLinks = self.getSidebarLinks(self, res.api_area.system_company_flg,
          res.api_area.menu_list[0], res.api_area.employee_dashboard_list, res.api_area.dashboard_list)
        const groupList = []
        let groupCode = ''
        let groupName = ''
        for (const employeeGroup of res.api_area.employee_group_list) {
          let group = {
            group_code: employeeGroup.value,
            group_name: employeeGroup.label
          }
          groupList.push(group)
          if (employeeGroup.default === 1) {
            groupCode = employeeGroup.value
            groupName = employeeGroup.label
          }
        }
        if (!groupCode) {
          groupCode = res.api_area.employee_group_list[0].value
          groupName = res.api_area.employee_group_list[0].label
        }
        const loginInfo = {
          companyCode: this.companyCode,
          companyName: companyName,
          groupCode: groupCode,
          groupName: groupName,
          employeeCode: this.employeeCode,
          employeeName: res.api_area.employee_name,
          sex: res.api_area.sex,
          userCode: '0000000001',
          sidebarLinks: sidebarLinks,
          groupList: groupList,
          officeCode: res.api_area.office_code,
          officeName: res.api_area.office_name,
          officeList: res.api_area.office_list,
          workScheduleList: res.api_area.work_schedule_list,
          dashboardList: res.api_area.employee_dashboard_list,
          isSmileMark: res.api_area.settings.is_smile_mark,
          attendanceManagement: res.api_area.settings.attendance_management,
          hireDate: res.api_area.hire_date
        }
        self.$store.commit('save', loginInfo)
        const loginPageInfo = {
          companyCode: this.companyCode,
          employeeCode: this.employeeCode,
          password: this.password
        }
        localStorage.setItem('loginPageInfo', JSON.stringify(loginPageInfo))
        self.$store.commit('setDeputyApproverlList', res.api_area.deputy_approverl_list)
        window.location = '/#/DB/menuDBScreen001'
      }
    },
    created () {
      const strLoginPageInfo = localStorage.getItem('loginPageInfo')
      if (strLoginPageInfo) {
        const loginPageInfo = JSON.parse(strLoginPageInfo)
        this.companyCode = loginPageInfo.companyCode
        this.employeeCode = loginPageInfo.employeeCode
        this.password = loginPageInfo.password
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }

</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .card input {
    background: #eee !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
</style>
