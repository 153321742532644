/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - v2.4.2
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2024 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from "vue";
import "./pollyfills";
import VueRouter from "vue-router";
import VueNotify from "vue-notifyjs";
import VueI18n from 'vue-i18n'
import lang from "element-ui/lib/locale/lang/ja";
import locale from "element-ui/lib/locale";
import App from "./App.vue";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import VModal from 'vue-js-modal'
import initProgress from "./progressbar";

// router setup
import routes from "./plugins/routes";

// library imports
import "./assets/sass/paper-dashboard.scss";
import './assets/sass/element_variables.scss'
import "./assets/sass/demo.scss";

import sidebarLinks from "./sidebarLinks";
import "./registerServiceWorker";
import firebase from '@/lib/firebase'
import axios from 'axios'
import store from './plugins/store'

import {data} from '@/lib/lang/index.js'

import GAuth from 'vue-google-oauth2'

const googleApiClientId = process.env.VUE_APP_GOOGLE_API_CLIENT_ID
if (googleApiClientId) {
  Vue.use(GAuth, {
    clientId: googleApiClientId,
    scope: 'openid https://www.googleapis.com/auth/calendar.events.readonly',
    prompt: 'consent',
    fetch_basic_profile: false,
    plugin_name: 'calendar'
  })
}

// plugin setup
Vue.use(VueRouter);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);
Vue.use(VueI18n)
const localLoginUser = JSON.parse(localStorage.getItem('loginUser'))
const localUserLoginUser = JSON.parse(localStorage.getItem('userLoginUser'))
if (localLoginUser && localLoginUser.sidebarLinks) {
  if (localUserLoginUser && localUserLoginUser.sidebarLinks) {
    Vue.use(SideBar, {sidebarLinks: localLoginUser.sidebarLinks, userSidebarLinks: localUserLoginUser.sidebarLinks})
  } else {
    Vue.use(SideBar, {sidebarLinks: localLoginUser.sidebarLinks})
  }
} else {
  if (localUserLoginUser && localUserLoginUser.sidebarLinks) {
    Vue.use(SideBar, {sidebarLinks: sidebarLinks, userSidebarLinks: localUserLoginUser.sidebarLinks})
  } else {
    Vue.use(SideBar, {sidebarLinks: sidebarLinks})
  }
}
locale.use(lang);
Vue.use(VModal)

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// configure i18n
let language = localStorage.getItem('language')
if (!language) {
  language = 'ja'
}
const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'en',
  messages: data
})

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$store = store

// console.log抑制
// if (process.env.NODE_ENV === 'development') {
if (process.env.VUE_APP_ENV === 'production') {
  if (!window.console) {
    window.console = {}
  }
  var methods = [
    'log', 'debug', 'info', 'warn', 'error'
  ]
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {}
  }
}

// ログインチェック
const publicPaths = [
  '/login',
  '/about',
  '/new-company',
  '/terms-of-use',
  '/forget-password',
  '/forget-password-admin',
  '/privacy-policy',
  '/notation-on-law',
  '/chat-support',
  '/admin/login',
  '/qrcode/login',
  '/company-regist',
  '/migration/start',
  '/migration/paymentComplete',
  '/general/internalServerError',
  '/migration/waiting',
  '/reset-password',
  '/private-reset-password',
  '/private-update-access-mail-address',
  '/my-collier/terms-of-use',
  '/my-collier/privacy-policy'
]
router.beforeEach((to, from, next) => {
  window.previousUrl = from.path
  window.currentUrl = to.path

  if (firebase.auth().currentUser) {
    next()
  } else {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        store.commit('load')
        next()
      } else {
        if (publicPaths.includes(to.path)) {
          next()
        } else {
          next({name: 'Login'})
        }
      }
    })
  }
})
Vue.prototype.$publicPaths = publicPaths

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  i18n: i18n
});
