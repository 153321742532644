import mixin from '@/lib/mixin'
import {DatePicker} from 'element-ui'
import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
import ValidationDate from '@/components/UIComponents/Inputs/ValidationDate'

export default {
  name: 'DesiredShiftSendMixin',
  mixins: [mixin],
  components: {
    ValidationText,
    ValidationDate,
    'el-date-picker': DatePicker
  },
  props: {
    mode: Number,
    params: Object,
    retirement: {
      type: Boolean,
      require: false,
      'default': false
    }
  },
  data () {
    return {
      componentKey: 0,
      id: 0,
      isChanged: false,
      detail: {
        send_type: 1,
        datetime: ''
      },
      tableData: [],
      labels: {},
      comboData: {},
      searchCond: {},
      send_flg: false,
      roles: {
        update: 0
      },
      tempmessage: '',
      errormessage: '',
      selectButtonLabel: this.$t('button.send_all_select')
    }
  },
  computed: {
    screenCode () {
      return '06-006'
    },
    isReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.delete || this.isChanged
    },
    isPrimaryKeyReadOnly () {
      return this.mode === this.modes.view || this.mode === this.modes.edit || this.mode === this.modes.delete || this.isChanged
    },
    isFixed () {
      return (fixed) => {
        return {
          'fixed': fixed === true
        }
      }
    }
  },
  methods: {

    // カンマ付与(detailを書き換えます)
    formatComma (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatNumber(String(this.detail[name]).replaceAll(',', ''))
      }
      return this.detail[name]
    },
    formatTime (name) {
      if (this.detail[name]) {
        this.detail[name] = this.formatDispMinute(parseInt(this.detail[name]))
      }
      return this.detail[name]
    },
    // サーバ送信用にnullをブランクに変換する
    servNullToBlank (target, name) {
      if (!target[name]) {
        target[name] = ''
      }
      return target[name]
    },
    // サーバ送信用に数値に変換する
    servInt (target, name) {
      if (target[name]) {
        target[name] = parseInt(String(target[name]).replaceAll(',', ''))
      } else {
        target[name] = null
      }
      return target[name]
    },
    // サーバ送信用に時刻を10進数に変換する
    servTime (target, name) {
      if (target[name]) {
        const toHour = parseInt(target[name].split(':')[0], 10)
        const toMinute = parseInt(target[name].split(':')[1], 10)
        let ans = toHour * 60 + toMinute
        target[name] = ans
      } else {
        target[name] = null
      }
      return target[name]
    },
    getColumnWidth (rows, columnNameValue, columnName1, columnName2) {
      let maxBytes = this.getBytes(columnNameValue)
      if (rows) {
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1]) / 1.4
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
          if (row[columnName2]) {
            const bytes = this.getBytes(row[columnName2]) / 1.4
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
        }
      }
      return maxBytes > 30 ? maxBytes * 7 + 20 : maxBytes * 8 + 20
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {},
    // 独自バリデーション用
    customValidate () { return true },
    // 送信データ調整用
    convertDetail (detail) {
      return detail
    },
    doClose () {
      this.$emit('close', this.isChanged, this.detail)
    },
    isNull (value) {
      return value === null
    },
    getSelectButtonLabel () {
      let allSelectFlg = true
      let label = this.$t('button.send_all_cancel')
      for (let data of this.tableData) {
        if (!data.selected) {
          if ((!data.is_personal_checkbox_available && String(this.detail.send_type) === '1') || (!data.is_shift_checkbox_available && String(this.detail.send_type) === '2')) {
            continue
          }
          allSelectFlg = false
          break
        }
      }
      if (!allSelectFlg) {
        label = this.$t('button.send_all_select')
      }
      this.selectButtonLabel = label
    },
    selectButtonClick (isRadio = false) {
      if (this.selectButtonLabel === this.$t('button.send_all_cancel') || isRadio) {
        // 全部チェック外す処理
        for (let data of this.tableData) {
          data.selected = false
        }
      } else {
        // 全部チェック付ける処理
        for (let data of this.tableData) {
          if ((!data.is_personal_checkbox_available && String(this.detail.send_type) === '1') || (!data.is_shift_checkbox_available && String(this.detail.send_type) === '2')) {
            continue
          }
          data.selected = true
        }
      }
      this.getSelectButtonLabel()
      this.tableData = JSON.parse(JSON.stringify(this.tableData))
    },
    cancelReservationButtonLabel (row) {
      let label = this.$t('button.cancel_reservation_all_cancel')
      for (const data of row) {
        if (!(this.isNull(data.reserve_date_of_sending_pay_slip) || data.reserve_date_of_sending_pay_slip === '')) {
          if (!data.cancel_reservation_selected) {
            label = this.$t('button.cancel_reservation_all_select')
          }
        }
      }
      return label
    },
    cancelReservationButtonClick (row) {
      let label = this.cancelReservationButtonLabel(row)
      if (label === this.$t('button.cancel_reservation_all_cancel')) {
        // 全部チェック外す処理
        for (let data of row) {
          if (!(this.isNull(data.reserve_date_of_sending_pay_slip) || data.reserve_date_of_sending_pay_slip === '')) {
            data.cancel_reservation_selected = false
          }
        }
      } else {
        // 全部チェック付ける処理
        for (let data of row) {
          if (!(this.isNull(data.reserve_date_of_sending_pay_slip) || data.reserve_date_of_sending_pay_slip === '')) {
            data.cancel_reservation_selected = true
          }
        }
      }
      row.splice()
    },
    cancelReservationTargetCount (row) {
      let count = 0
      for (let data of row) {
        if (!(this.isNull(data.reserve_date_of_sending_pay_slip) || data.reserve_date_of_sending_pay_slip === '')) {
          count += 1
        }
      }
      return count
    },
    doSend () {
      this.errormessage = ''
      const sendEmployees = this.tableData
        .filter((row) => !!row.selected)
        .map((row) => ({employee_code: row.employee_code}))
      if (sendEmployees.length === 0) {
        this.errormessage = this.$t('message.select_one_or_more')
        this.$emit('on-submit', this.registrationForm, false)
        this.$refs.frame.scrollTop = 0
        this.componentKey += 1
        return
      }
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          send_type: parseInt(this.detail.send_type, 10),
          target_month: this.params.target_month,
          office_code: this.params.office_code,
          send_employees: sendEmployees
        }
      }
      this.send('/send/shift_schedule_adjustment/list', data)
      .then(res => {
        if (res.message.message_classification === 1) {
          this.componentKey += 1
          this.tempmessage = this.message.text
          this.isChanged = true
        }
        this.send_flg = false
      })
    },
    doRelease () {
      let employeeList = []
      for (const record of this.salary_closing_data) {
        for (const detailData of record.detail_data) {
          if (detailData.cancel_reservation_selected) {
            let employee = {
              employee_code: detailData.employee_code
            }
            employeeList.push(employee)
          }
        }
      }
      if (employeeList.length === 0) {
        return
      }
      let data = {
        screen_code: 'SS-025',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          target_date: this.params.target_month,
          salary_bonus_classification: this.params.salary_bonus_classification,
          employee_list: employeeList
        }
      }
      this.send('/delete/send_mail/list', data)
      .then(res => {
        if (res.message.message_classification === 1) {
          this.componentKey += 1
          this.tempmessage = this.message.text
          this.isChanged = true
          this.init()
        }
        this.send_flg = false
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          office_code: this.params.office_code,
          target_month: this.params.target_month
        }
      }
      this.send('/send/shift_schedule_adjustment/init', data)
      .then(res => {
        this.tableData = Array.isArray(res.api_area) ? res.api_area : res.api_area.employee_data
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        this.afterInit(res)
        this.$nextTick(() => {
          if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
            this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
          }
        })
        this.componentKey += 1
      })
      this.componentKey += 1
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$nextTick(() => {
      if (window.innerHeight - this.$refs.frame.clientHeight < 220) {
        this.$refs.frame.style = 'overflow-y: auto; height: calc(100vh - 220px);'
      }
    })
  }
}
