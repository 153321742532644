<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="form-group search-condition-item">
            <input type="checkbox"
              v-model="searchCond.this_day"
              class="gray"
              id="this_day"
              @change="thisDayChanged"
            />
            <label for="this_day">{{ $t('label.check_this_day') }}</label>
          </div>
          <ValidationSelect
            v-model="searchCond.supplier_code"
            item-name="supplier_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.supplier_list)"
            :item-label="labels.supplier_code_ryaku === undefined ? labels.supplierCode_ryaku === undefined ? '' : labels.supplierCode_ryaku : labels.supplier_code_ryaku"
            :options="comboData.supplier_list"
            :disabled="supplierCodeDisabled"
            frame-class="search-condition-item"
            @change="supplierCodeChanged"
          />
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.lunch_name}}</label>
            <input type="text" :placeholder="$t('placeholder.lunch_name')" maxlength="30" class="form-control length60" v-model="searchCond.lunch_name" :disabled="lunchNameDisabled">
          </div>
            <StartEndDate
              v-model="searchCond.order_date"
              item-name="order_date"
              max-length=""
              classes="form-control length"
              :item-label="labels.order_date"
              :disabled="orderDateDisabled"
              :placeholder1="$t('placeholder.start_date')"
              :placeholder2="$t('placeholder.end_date')"
              frame-class="search-condition-item"
            />
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
      <div style="margin-bottom: 10px;" v-if="labels.print === 1 && tableData.length > 0">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="printClicked">
          {{$t('button.print')}}
        </button>
      </div>
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showSupplierName" :width="getColumnWidthStrong(tableData, labels.supplier_name_ryaku === undefined ? labels.supplierName_ryaku === undefined ? '' : labels.supplierName_ryaku : labels.supplier_name_ryaku, 'supplier_name')" :label="labels.supplier_name_ryaku === undefined ? labels.supplierName_ryaku === undefined ? '' : labels.supplierName_ryaku : labels.supplier_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.supplier_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showLunchName$unitPrice" :width="getColumnWidthStrong(tableData, labels.lunch_name_ryaku === undefined ? labels.lunchName_ryaku === undefined ? '' : labels.lunchName_ryaku : labels.lunch_name_ryaku, 'lunch_name', 'unit_price')" :label="labels.lunch_name_ryaku === undefined ? labels.lunchName_ryaku === undefined ? '' : labels.lunchName_ryaku : labels.lunch_name_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{ props.row.lunch_name}}
                  </div>
                  <div style="text-align: left;" v-if="props.row.unit_price">
                    <small>{{ props.row.unit_price + $t('label.unit_yen')}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showQuantity" :width="getColumnWidthStrong(tableData, labels.quantity_ryaku === undefined ? labels.quantity_ryaku === undefined ? '' : labels.quantity_ryaku : labels.quantity_ryaku, 'quantity')" :label="labels.quantity_ryaku === undefined ? labels.quantity_ryaku === undefined ? '' : labels.quantity_ryaku : labels.quantity_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{formatNumber(props.row.quantity)}}
                    {{$t('label.unit_num')}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showOrderDate" :width="getColumnWidth(tableData, labels.order_date_ryaku === undefined ? labels.orderDate_ryaku === undefined ? '' : labels.orderDate_ryaku : labels.order_date_ryaku, 'order_date')" :label="labels.order_date_ryaku === undefined ? labels.orderDate_ryaku === undefined ? '' : labels.orderDate_ryaku : labels.order_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.order_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showEmployee" :width="getColumnWidthStrong(tableData, labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku, 'employee_code', 'employee_name')" :label="labels.employee_ryaku === undefined ? labels.employee_ryaku === undefined ? '' : labels.employee_ryaku : labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div class="strong-text">
                    {{props.row.employee_name}}
                  </div>
                  <div style="text-align: left;">
                    <small>{{props.row.employee_code}}</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showCatchFlg" :width="getColumnWidth(comboData.catch_flg_list, labels.catch_flg_ryaku === undefined ? labels.catchFlg_ryaku === undefined ? '' : labels.catchFlg_ryaku : labels.catch_flg_ryaku, 'label', 'value')" :label="labels.catch_flg_ryaku === undefined ? labels.catchFlg_ryaku === undefined ? '' : labels.catchFlg_ryaku : labels.catch_flg_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.catch_flg, comboData.catch_flg_list))}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showTargetDate" :width="getColumnWidth(tableData, labels.target_date_ryaku === undefined ? labels.targetDate_ryaku === undefined ? '' : labels.targetDate_ryaku : labels.target_date_ryaku, 'target_date')" :label="labels.target_date_ryaku === undefined ? labels.targetDate_ryaku === undefined ? '' : labels.targetDate_ryaku : labels.target_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.target_date}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import LunchOrderResultListSearchMixin from '@/components/Dashboard/Views/Generated/LunchOrderResultListSearchMixin'
  import swal from 'sweetalert2'

  export default {
    name: 'LunchOrderResultListSearch',
    data () {
      return {
        supplierCodeDisabled: false,
        lunchNameDisabled: false,
        orderDateDisabled: false
      }
    },
    methods: {
      afterInit (res) {
        this.comboData.supplier_list = this.comboData.lunch_supplier_list
      },
      convertSearchCond (searchCond) {
        searchCond = {
          supplier_code: searchCond.supplier_code,
          lunch_name: searchCond.lunch_name,
          order_date_from: searchCond.order_date[0] === null || searchCond.order_date[0] === '' ? '' : this.formatDate(searchCond.order_date[0]) + ' 00:00:00',
          order_date_to: searchCond.order_date[1] === null || searchCond.order_date[1] === '' ? '' : this.formatDate(searchCond.order_date[1]) + ' 23:59:59'
        }
        return searchCond
      },
      afterSearch (res) {
        this.labels.target_date_ryaku = this.$t('label.employee_lunch_target_date')
        this.labels.supplier_name_ryaku = this.labels.supplier_code_ryaku
      },
      thisDayChanged () {
        if (this.searchCond.this_day) {
          this.searchCond.supplier_code = ''
          this.searchCond.lunch_name = ''
          this.searchCond.order_date = [this.formatToday(), this.formatToday()]
        }
        this.supplierCodeDisabled = this.searchCond.this_day
        this.lunchNameDisabled = this.searchCond.this_day
        this.orderDateDisabled = this.searchCond.this_day
      },
      printClicked () {
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            supplier_code: this.searchCond.supplier_code,
            lunch_name: this.searchCond.lunch_name,
            order_date_from: this.searchCond.order_date[0] === null || this.searchCond.order_date[0] === '' ? '' : this.formatDate(this.searchCond.order_date[0]) + ' 00:00:00',
            order_date_to: this.searchCond.order_date[1] === null || this.searchCond.order_date[1] === '' ? '' : this.formatDate(this.searchCond.order_date[1]) + ' 23:59:59'
          }
        }
        this.send('/print/lunch_order_result_list', params)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
      }
    },
    mixins: [LunchOrderResultListSearchMixin]
  }
</script>
<style scoped>
</style>
