<template>
  <div>
    <div>
      <button type="button" class="btn btn-sm btn-default btn-primary" @click="addRow" :disabled="disabled" :data-v-step="tourSteps">{{$t('button.add_row')}}</button>
    </div>
    <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"      border
      :data="tableData"
      :empty-text="$t('label.no_data')">
      <!-- 削除 -->
      <el-table-column
        width="60"
        :label="$t('label.delete')"
        align="center"
      >
        <template slot-scope="props">
          <template v-if="!props.row.isFixed">
            <div style="text-align: center;">
              <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="disabled" />
            </div>
          </template>
        </template>
      </el-table-column>
      <!-- 部署名 -->
      <el-table-column
        width="300"
        :label="labels.group_ryaku ? labels.group_ryaku : ''"
        align="center">
        <template slot-scope="props">
          <div>
            <el-select class="select-success length20"
                      size="large"
                      :disabled="disabled"
                      placeholder=""
                      v-model="props.row.group_code">
              <el-option v-for="group in comboData.group_list"
                          class="select-success"
                          :value="group.value"
                          :label="group.label"
                          :key="group.value">
              </el-option>
            </el-select>
          </div> 
        </template>
      </el-table-column>
      <!-- 個別ルート -->
      <el-table-column
        width="300"
        v-if="showIndividualRoute"
        :label="labels.individualRoute_ryaku ? labels.individualRoute_ryaku : ''"
        align="center">
        <template slot-scope="props">
          <div>
            <el-select class="select-success length20"
                      size="large"
                      :disabled="disabled"
                      placeholder=""
                      v-model="props.row.individual_route_code">
              <el-option v-for="role in comboData.individual_route_list"
                          class="select-success"
                          :value="role.value"
                          :label="role.label"
                          :key="role.value">
              </el-option>
            </el-select>
          </div> 
        </template>
      </el-table-column>
      <!-- 共通ルート -->
      <el-table-column
        width="300"
        :label="labels.commonRoute_ryaku ? labels.commonRoute_ryaku : ''"
        align="center">
        <template slot-scope="props">
          <div>
            <el-select class="select-success length20"
                        size="large"
                        :disabled="disabled"
                        placeholder=""
                        v-model="props.row.common_route_code">
              <el-option v-for="authority in comboData.common_route_list"
                          class="select-success"
                          :value="authority.value"
                          :label="authority.label"
                          :key="authority.value">
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      value: Array,
      comboData: Object,
      labels: Object,
      disabled: Boolean,
      showIndividualRoute: Boolean,
      tourSteps: {
        type: String,
        require: false,
        'default': ''
      },
      applicationFormCode: String
    },
    data () {
      return {
        componentKey: 0,
        searchCond: {},
        currentIndex: 0,
        tableData: []
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      addRow () {
        this.tableData.unshift({
          isNew: true,
          application_form_code: this.applicationFormCode,
          update_count: 0
        })
      }
    }
  }
</script>

<style scoped>
</style>