<template>
  <div class="row">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="employee" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
        <div class="modal-body">
          <EmployeeSearch :orgSearchCond="searchCond" :is-modal="true" @close="hide"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="attendanceRecordReportSelectAll" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="500">
        <div class="modal-body">
          <AttendanceRecordReportSelect @close="hideAttendanceRecordReportSelectAll"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="attendanceRecordReportSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="500">
        <div class="modal-body">
          <AttendanceRecordReportSelect @close="hideAttendanceRecordReportSelect"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="csvTemplateSelect" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="500">
        <div class="modal-body">
          <CsvTemplateSelect :labels="labels" :table-data="templateList" @close="hideCsvTemplateSelect"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <form method="#" action="#">
        <div class="row">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          </div>
        </div>
        <div class="card" style="width: 100%;">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">
                {{$t('label.search_condition')}}
              </h4>
            </div>
            <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <div v-if="!isPersonal">
              <div class="form-group search-condition-item">
                <p-radio v-model="searchCond.search_date_type" label="9" :inline="true" @change="searchDateTypeChanged">{{$t('label.group_by_employee')}}</p-radio>
                <p-radio v-model="searchCond.search_date_type" label="0" :inline="true" @change="searchDateTypeChanged">{{$t('label.group_by_year')}}</p-radio>
                <p-radio v-model="searchCond.search_date_type" label="1" :inline="true" @change="searchDateTypeChanged">{{$t('label.group_by_month')}}</p-radio>
                <p-radio v-model="searchCond.search_date_type" label="2" :inline="true" @change="searchDateTypeChanged">{{$t('label.group_by_week')}}</p-radio>
                <p-radio v-model="searchCond.search_date_type" label="3" :inline="true" @change="searchDateTypeChanged">{{$t('label.group_by_day')}}</p-radio>
              </div>
            </div>
            <template v-if="searchCond.search_date_type==='9'">
              <div class="form-group search-condition-item" v-if="!isPersonal">
                <p-radio v-model="searchCond.search_type" label="0" :inline="true">{{labels.office_name ? $t('label.filter_by').replace('{item}', labels.office) : ''}}</p-radio>
                <p-radio v-model="searchCond.search_type" label="1" :inline="true">{{labels.group_name ? $t('label.filter_by').replace('{item}', labels.group) : ''}}</p-radio>
                <p-radio v-model="searchCond.search_type" label="2" :inline="true">{{labels.team_name ? $t('label.filter_by').replace('{item}', labels.team) : ''}}</p-radio>
                <p-radio v-model="searchCond.search_type" label="3" :inline="true">{{labels.employee_name ? $t('label.filter_by').replace('{item}', labels.employee) : ''}}</p-radio>
              </div>
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{$t('label.term')}}</label>
                <div>
                  <vSelect name="from_year"
                            class="style-chooser length5"
                            style="display: inline-block;"
                            :clearable="false"
                            :options="years"
                            v-model="searchCond.from_year"
                            :disabled="isCurrentMonth"
                            @input="synchronizeFrom">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                  <vSelect name="from_month"
                            class="style-chooser length3"
                            style="display: inline-block;"
                            :clearable="false"
                            :options="months"
                            v-model="searchCond.from_month"
                            :disabled="isCurrentMonth"
                            @input="synchronizeFrom">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                  ～
                  <vSelect name="to_year"
                            class="style-chooser length5"
                            style="display: inline-block;"
                            :clearable="false"
                            :options="years"
                            v-model="searchCond.to_year"
                            :disabled="isCurrentMonth"
                            @input="synchronizeTo">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                  <vSelect name="to_month"
                            class="style-chooser length3"
                            style="display: inline-block;"
                            :clearable="false"
                            :options="months"
                            v-model="searchCond.to_month"
                            :disabled="isCurrentMonth"
                            @input="synchronizeTo">
                    <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                  </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                </div>
              </div>
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label for="isCurrentMonth">{{$t('label.this_month')}}</label>
                <div style="padding: 6px 0;">
                  <input 
                    type="checkbox" 
                    v-model="isCurrentMonth" 
                    class="gray" 
                    id="isCurrentMonth" 
                    @click="isCurrentMonthClicked"
                  >
                </div>
              </div>
              <template v-if="searchCond.search_type=='0'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.office}}</label>
                  <div>
                    <vSelect name="office_code"
                              class="style-chooser length20"
                              :options="offices"
                              v-model="searchCond.office_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-else-if="searchCond.search_type=='1'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.group}}</label>
                  <div>
                    <vSelect name="group_code"
                              class="style-chooser length20"
                              :options="groups"
                              v-model="searchCond.group_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-else-if="searchCond.search_type=='2'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.team}}</label>
                  <div>
                    <vSelect name="team_code"
                              class="style-chooser length20"
                              :options="teams"
                              v-model="searchCond.team_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-else-if="searchCond.search_type==='3' && !isPersonal">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                  <label>{{labels.employee_code}}</label>
                  <div>
                    <input
                      type="text"
                      class="form-control width130"
                      style="display:inline-block;"
                      v-model="searchCond.search_employee_code"
                      @blur="searchEmployeeSelected"
                    >
                  </div>
                </div>
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                  <label>{{labels.employee_name}}</label>
                  <div>
                    <div style="display: inline-block;">
                      <input
                        type="text"
                        class="form-control width300"
                        style="display:inline-block;"
                        v-model="searchCond.search_employee_name">
                    </div>
                    <button type="button" class="btn btn-sm btn-default btn-warning" @click="show" v-show="canShow('/SS/menuSSScreen018')"><i class="ti-search"></i></button>
                  </div>
                </div>
              </template>
              <template v-if="searchCond.search_type!=='3'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.employeeClassification}}</label>
                  <div>
                    <vSelect name="employee_classification_code"
                              class="style-chooser length12"
                              :options="employee_types"
                              v-model="searchCond.employee_classification_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <!-- 退職者検索用のチェックボックス -->
              <!-- <div class="form-group" v-if="!isPersonal">
                <div>
                  <input type="checkbox" v-model="searchCond.retirement" class="gray" />
                  <label>{{$t('label.search_all_employees')}}</label>
                </div>
              </div> -->
              <template v-if="roles.search === 1">
                <div style="text-align: left;">
                  <button type="button" class="btn btn-sm btn-default btn-search" @click="search">
                    <span class="btn-label">
                        <i class="ti-search"></i>
                    </span>
                    {{$t('button.search')}}
                  </button>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="form-group search-condition-item">
                <p-radio v-model="searchCond.search_type" label="0" :inline="true">{{labels.office_name ? $t('label.filter_by').replace('{item}', labels.office) : ''}}</p-radio>
                <p-radio v-model="searchCond.search_type" label="1" :inline="true">{{labels.group_name ? $t('label.filter_by').replace('{item}', labels.group) : ''}}</p-radio>
                <p-radio v-model="searchCond.search_type" label="2" :inline="true">{{labels.team_name ? $t('label.filter_by').replace('{item}', labels.team) : ''}}</p-radio>
              </div>
              <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                <label>{{$t('label.term')}}</label>
                <div>
                  <template v-if="searchCond.search_date_type === '3'">
                    <ValidationDate
                      v-model="searchCond.from_date"
                      item-name="from_date"
                      rules="required"
                      :item-label="$t('label.term')"
                      :placeholder="$t('placeholder.term_from')"
                    />
                    ～
                    <ValidationDate
                      v-model="searchCond.to_date"
                      item-name="to_date"
                      rules="required"
                      :item-label="$t('label.term')"
                      :placeholder="$t('placeholder.term_to')"
                    />
                  </template>
                  <template v-else>
                    <vSelect name="from_year"
                              class="style-chooser length5"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="years"
                              v-model="searchCond.from_year"
                              :disabled="isCurrentMonth"
                              @input="synchronizeFrom">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                    <template v-if="searchCond.search_date_type !== '0'">
                      <vSelect name="from_month"
                                class="style-chooser length3"
                                style="display: inline-block;"
                                :clearable="false"
                                :options="months"
                                v-model="searchCond.from_month"
                                :disabled="isCurrentMonth"
                                @input="synchronizeFrom">
                        <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                      </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                    </template>
                    ～
                    <vSelect name="to_year"
                              class="style-chooser length5"
                              style="display: inline-block;"
                              :clearable="false"
                              :options="years"
                              v-model="searchCond.to_year"
                              :disabled="isCurrentMonth"
                              @input="synchronizeTo">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
                    <template v-if="searchCond.search_date_type !== '0'">
                      <vSelect name="to_month"
                                class="style-chooser length3"
                                style="display: inline-block;"
                                :clearable="false"
                                :options="months"
                                v-model="searchCond.to_month"
                                :disabled="isCurrentMonth"
                                @input="synchronizeTo">
                        <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                      </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
                    </template>
                  </template>
                </div>
              </div>
              <template v-if="searchCond.search_date_type !== '3'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
                  <label for="isCurrentMonth">{{thisDateLabel}}</label>
                  <div style="padding: 6px 0;">
                    <input 
                      type="checkbox" 
                      v-model="isCurrentMonth" 
                      class="gray" 
                      id="isCurrentMonth" 
                      @click="isCurrentMonthClicked"
                    >
                  </div>
                </div>
              </template>
              <template v-if="searchCond.search_type=='0'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.office}}</label>
                  <div>
                    <vSelect name="office_code"
                              class="style-chooser length20"
                              :options="offices"
                              v-model="searchCond.office_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-else-if="searchCond.search_type=='1'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.group}}</label>
                  <div>
                    <vSelect name="group_code"
                              class="style-chooser length20"
                              :options="groups"
                              v-model="searchCond.group_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-else-if="searchCond.search_type=='2'">
                <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;" v-if="!isPersonal">
                  <label>{{labels.team}}</label>
                  <div>
                    <vSelect name="team_code"
                              class="style-chooser length20"
                              :options="teams"
                              v-model="searchCond.team_code">
                      <div slot="no-options">{{$t('message.no_matching_options')}}</div>
                    </vSelect>
                  </div>
                </div>
              </template>
              <template v-if="roles.search === 1">
                <div style="text-align: left;">
                  <button type="button" class="btn btn-sm btn-default btn-search" @click="searchBelongs">
                    <span class="btn-label">
                        <i class="ti-search"></i>
                    </span>
                    {{$t('button.search')}}
                  </button>
                </div>
              </template>
            </template>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <div v-if="message">
        <div class="card" v-if="this.message && this.message.text && this.message.text.trim()">
          <div class="card-content">
            <span v-html="getText"></span>
            <template v-if="message.correspondence_action && message.correspondence_action.length > 0">
              <br>
              <span style="color: #000000;">{{message.correspondence_action}}</span>
            </template>
            <div v-if="!isPersonal">
              <div>
                <span>（</span>
                <input type="checkbox" v-model="overtime" class="gray" id="overtime"><label for="overtime">{{$t('label.overtime')}}</label>
                <input type="checkbox" v-model="lateEarlyDeparture" class="gray" id="lateEarlyDeparture"><label for="lateEarlyDeparture">{{$t('label.late_early_departure')}}</label>
                <input type="checkbox" v-model="lateNight" class="gray" id="lateNight"><label for="lateNight">{{$t('label.late_night')}}</label>
                <input type="checkbox" v-model="paidHoliday" class="gray" id="paidHoliday"><label for="paidHoliday">{{$t('label.paid_holiday')}}</label>
                <span>）</span>
                {{$t('label.wanna_know')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="results.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">
              {{$t('label.search_result')}}
            </h4>
          </div>
          <div class="card-content row">
            <template v-if="showPrintAll">
              <div class="col-sm-12" style="margin-bottom: 10px;">
                <!-- <button type="button" class="btn btn-sm btn-default btn-warning" @click="printAll(undefined)">{{$t('button.print_all')}}</button> -->
                <button type="button" class="btn btn-sm btn-default btn-warning" @click="showAttendanceRecordReportSelectAll">{{$t('button.print_all')}}</button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ $t('label.attendance_record_layout') }}
                &nbsp;&nbsp;
                <ValidationRadio
                  v-model="attendaneRecordLayout"
                  rules="required"
                  :item-label="$t('label.attendance_record_layout')"
                />
                <template v-if="!isPersonal && templateList.length > 0">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button type="button" class="btn btn-sm btn-default btn-warning" @click="showCsvTemplateSelect">{{$t('button.create_csv')}}</button>
                </template>
              </div>
            </template>
            <div class="col-sm-12" style="margin-bottom: 20px;" v-for="result in results" v-bind:key="result.title">
              <p @click="result.show = !result.show" style="cursor: pointer;">{{result.title}}</p>
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="result.data"
                        v-show="result.show"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                <template v-if="orgSearchCond.search_date_type=='9'">
                  <el-table-column min-width="110" :label="headers.employee">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <font size="3"><b>{{props.row.employee_name}}</b></font>
                      </div>
                      <div style="text-align: left;">
                        {{props.row.employee_code}}
                      </div>
                      <div style="text-align: left; line-height: 100%;" v-if="orgSearchCond.search_type!='3'">
                        {{props.row.employee_classification_name}}
                      </div>
                      <div style="text-align: left;" v-if="orgSearchCond.search_type=='0'">
                        {{props.row.office_name}}
                      </div>
                      <div style="text-align: left;" v-if="orgSearchCond.search_type=='1'">
                        {{props.row.group_name}}
                      </div>
                      <div style="text-align: left;" v-if="orgSearchCond.search_type=='2'">
                        {{props.row.team_name}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="75" :label="headers.real_total_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <template v-if="props.row.real_total_minutes===0">
                          <div style="text-align: right;">
                            <small>&nbsp;</small>
                          </div>
                          <div style="text-align: right;" class="attendance-text">
                            {{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}
                          </div>
                        </template>
                        <template v-else>
                          <div style="text-align: right;">
                            <small>&nbsp;</small>
                          </div>
                          <div style="text-align: right;" class="strong-text">
                            {{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.job_total_minutes" v-if="!result.flex">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.job_total_minutes===0 ? 'attendance-text' : 'strong-text'">
                          <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.job_total_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_total_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" class="attendance-text">
                          <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_total_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.job_total_minutes" v-if="result.flex">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.standard_job_minutes===0 ? 'attendance-text' : 'strong-text'">
                          <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.standard_job_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.standard_job_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" class="attendance-text">
                          <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.standard_job_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.job_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.job_overwork_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="overtime && props.row.job_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.legal_job_minutes" v-if="!result.flex">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.legal_job_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.legal_job_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_job_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_job_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.legal_job_minutes" v-if="result.flex">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.standard_legal_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.standard_legal_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.standard_legal_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.standard_legal_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.legal_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.legal_overwork_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="overtime && props.row.legal_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.late_night_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.late_night_overwork_minutes===0 ? 'attendace-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="lateNight && props.row.late_night_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.break_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.break_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.break_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.break_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.late_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.late_minutes===0 ? 'attendance-text' : 'strong-text'">
                          <small>{{props.row.canAdd || props.row.working_system_type === 2 ? '' : props.row.late_days ? props.row.late_days + $t('label.count') : '　'}}</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.late_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd || props.row.working_system_type === 2 ? '' : formatDispMinute(props.row.late_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" :class="lateEarlyDeparture && props.row.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          <small>{{props.row.canAdd || props.row.working_system_type === 2 ? '' : props.row.late_days ? props.row.late_days + $t('label.count') : '　'}}</small>
                        </div>
                        <div style="text-align: right;" :class="lateEarlyDeparture && props.row.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd || props.row.working_system_type === 2 ? '' : formatDispMinute(props.row.late_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.early_departure_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.early_departure_minutes===0 ? 'attendance-text' : 'strong-text'">
                          <small>{{props.row.canAdd || props.row.working_system_type === 2 ? '' : props.row.early_departure_days ? props.row.early_departure_days + $t('label.count') : '　'}}</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.early_departure_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd || props.row.working_system_type === 2 ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" :class="lateEarlyDeparture && props.row.early_departure_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          <small>{{props.row.canAdd || props.row.working_system_type === 2 ? '' : props.row.early_departure_days ? props.row.early_departure_days + $t('label.count') : '　'}}</small>
                        </div>
                        <div style="text-align: right;" :class="lateEarlyDeparture && props.row.early_departure_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd || props.row.working_system_type === 2 ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="75" :label="headers.lack_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.lack_minutes===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd || props.row.working_system_type !== 2 ? '' : formatDispMinute(props.row.lack_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="lateEarlyDeparture && props.row.lack_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd || props.row.working_system_type !== 2 ? '' : formatDispMinute(props.row.lack_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.job_holiday_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.job_holiday_hours===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;" :class="props.row.job_holiday_hours===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" :class="overtime && props.row.job_holiday_hours > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;" :class="overtime && props.row.job_holiday_hours > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.legal_holiday_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.legal_holiday_overwork_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;" :class="props.row.legal_holiday_overwork_minutes===0 ? 'attendance-text' : 'strong-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" :class="overtime && props.row.legal_holiday_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;" :class="overtime && props.row.legal_holiday_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="$t('label.paid_holiday_ryaku')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.paid_holiday_days > 0 || props.row.paid_holiday_hours > 0 ? 'strong-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;" :class="props.row.paid_holiday_days > 0 || props.row.paid_holiday_hours > 0 ? 'strong-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" :class="paidHoliday && (props.row.paid_holiday_days > 0 || props.row.paid_holiday_hours > 0) ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;" :class="paidHoliday && (props.row.paid_holiday_days > 0 || props.row.paid_holiday_hours > 0) ? 'strong-orange-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.child_time_leave_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.child_time_leave_days > 0 || props.row.child_time_leave_hours > 0 ? 'strong-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;" :class="props.row.child_time_leave_hours > 0 ? 'strong-text-right' : 'attendance-text'">
                          {{props.row.canAdd ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="50" :label="headers.compensatory_holiday_days">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          {{props.row.canAdd ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;" :class="props.row.compensatory_holiday_days===0 ? 'attendance-text' : 'strong-text-right'">
                          &nbsp;
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}
                        </div>
                        <div style="text-align: right;">
                          &nbsp;
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="75" :label="headers.closed_days">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;" :class="props.row.closed_days===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : props.row.closed_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : props.row.closed_days + $t('label.day')}}
                        </div>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.estimated_overtime_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="isPersonal">
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" :class="props.row.estimated_overtime_hours===0 ? 'attendance-text' : 'strong-text-right'">
                          {{props.row.canAdd ? '' : formatDispHour(props.row.estimated_overtime_hours)}}
                        </div>
                      </template>
                      <template v-else>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;" class="attendance-text">
                          {{props.row.canAdd ? '' : formatDispHour(props.row.estimated_overtime_hours)}}
                        </div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :width="210"
                    fixed="left"
                    :label="$t('label.operation')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <template v-if="scope.column.label.length === 4">
                          <div class="phase-header__title">{{scope.column.label.substring(0, 2)}}</div>
                          <div class="phase-header__title">{{scope.column.label.substring(2)}}</div>
                        </template>
                        <template v-else>
                          <div class="phase-header__title">{{scope.column.label}}</div>
                        </template>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <template v-if="props.row.button_insert === 1 && roles.entry === 1">
                        <button type="button" class="btn btn-sm btn-default btn-submit" @click="detail(props.$index, props.row)">
                          <span class="btn-label"><i class="fa fa-check"></i></span>
                          {{$t('button.add')}}
                        </button>
                      </template>
                      <template v-else>
                        <template v-if="props.row.button_pdf === 1 && roles.print === 1">
                          <!-- <button v-if="props.row.button_detail === 1" type="button" class="btn btn-sm btn-default btn-warning" @click="print(props.$index, props.row, result.title)"> -->
                          <button v-if="props.row.button_detail === 1" type="button" class="btn btn-sm btn-default btn-warning" @click="showAttendanceRecordReportSelect(props.$index, props.row, result.title)">
                            {{$t('button.print')}}
                          </button>
                        </template>
                        <template v-if="props.row.button_detail === 1 && ((isPersonal && roles.update === 1) || (!isPersonal && roles.preview === 1))">
                          <button type="button" class="btn btn-sm btn-default btn-warning" @click="detail(props.$index, props.row)">{{$t('button.detail')}}</button>
                        </template>
                        <template v-if="props.row.closing_color === 2">
                          <img src="/static/img/approved.png" style="width: 68px;"/>
                        </template>
                      </template>
                    </template>
                  </el-table-column>
                </template>
                <template v-else>
                  <template v-if="orgSearchCond.search_type=='0'">
                    <el-table-column min-width="110" :label="headers.office">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div class="attendance-text">
                          <small>{{props.row.office_code}}</small>
                          <br>
                          {{props.row.office_name}}
                        </div>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;">
                          &nbsp;
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <template v-if="orgSearchCond.search_type=='1'">
                    <el-table-column min-width="110" :label="headers.group">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div class="attendance-text">
                          <small>{{props.row.group_code}}</small>
                          <br>
                          {{props.row.group_name}}
                        </div>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;">
                          &nbsp;
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <template v-if="orgSearchCond.search_type=='2'">
                    <el-table-column min-width="110" :label="headers.team">
                      <template slot="header" slot-scope="scope">
                        <div class="phase-header tableHeader">
                          <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                        </div>
                      </template>
                      <template slot-scope="props">
                        <div class="attendance-text">
                          <small>{{props.row.team_code}}</small>
                          <br>
                          {{props.row.team_name}}
                        </div>
                        <div style="text-align: right;">
                          <small>&nbsp;</small>
                        </div>
                        <div style="text-align: right;">
                          &nbsp;
                        </div>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column min-width="70" :label="headers.employee_count">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : props.row.employee_count}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;">
                        &nbsp;
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.data_type">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: left;" class="attendance-text">
                        {{props.row.canAdd ? '' : $t('label.total')}}
                      </div>
                      <div style="text-align: left;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: left;" class="attendance-text">
                        {{props.row.canAdd ? '' : $t('label.average')}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.real_total_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.real_total_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.real_total_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.job_total_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" class="attendance-text">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.total.job_total_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.job_total_minutes)}}
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.average.job_total_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.job_total_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.job_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.job_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.job_overwork_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.job_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.job_overwork_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.legal_job_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.legal_job_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.legal_job_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.legal_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.legal_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.legal_overwork_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.legal_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.legal_overwork_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.late_night_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="lateNight && props.row.total.late_night_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.late_night_overwork_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" :class="lateNight && props.row.total.late_night_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.late_night_overwork_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.break_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.break_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.break_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.late_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" :class="lateEarlyDeparture && props.row.total.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.total.late_days) + $t('label.count')}}</small>
                      </div>
                      <div style="text-align: right;" :class="lateEarlyDeparture && props.row.total.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.late_minutes)}}
                      </div>
                      <div style="text-align: right;" :class="lateEarlyDeparture && props.row.total.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.average.late_days) + $t('label.count')}}</small>
                      </div>
                      <div style="text-align: right;" :class="lateEarlyDeparture && props.row.total.late_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.late_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.early_departure_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.total.early_departure_days) + $t('label.count')}}</small>
                      </div>
                      <div style="text-align: right;" :class="lateEarlyDeparture && props.row.total.early_departure_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.early_departure_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.average.early_departure_days) + $t('label.count')}}</small>
                      </div>
                      <div style="text-align: right;">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.early_departure_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.job_holiday_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.total.job_holiday_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.job_holiday_hours > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.job_holiday_hours)}}
                      </div>
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.average.job_holiday_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.job_holiday_hours)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.legal_holiday_overwork_minutes">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.total.legal_holiday_overwork_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;" :class="overtime && props.row.total.legal_holiday_overwork_minutes > 0 ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.total.legal_holiday_overwork_minutes)}}
                      </div>
                      <div style="text-align: right;">
                        <small>{{props.row.canAdd ? '' : formatNumber(props.row.average.legal_holiday_overwork_days) + $t('label.day')}}</small>
                      </div>
                      <div style="text-align: right;">
                        {{props.row.canAdd ? '' : formatDispMinute(props.row.average.legal_holiday_overwork_minutes)}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="$t('label.paid_holiday_ryaku')">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.paid_holiday_days > 0 || props.row.average.paid_holiday_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : props.row.total.paid_holiday_days ? props.row.total.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.paid_holiday_days > 0 || props.row.average.paid_holiday_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : parseInt(props.row.total.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.paid_holiday_days > 0 || props.row.average.paid_holiday_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : props.row.average.paid_holiday_days ? props.row.average.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.paid_holiday_days > 0 || props.row.average.paid_holiday_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : parseInt(props.row.average.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="70" :label="headers.compensatory_holiday_days">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : props.row.total.compensatory_holiday_days + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : props.row.average.compensatory_holiday_days + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.child_time_leave_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.child_time_leave_days > 0 || props.row.average.child_time_leave_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : props.row.total.child_time_leave_days ? props.row.total.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.child_time_leave_days > 0 || props.row.average.child_time_leave_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : parseInt(props.row.total.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.child_time_leave_days > 0 || props.row.average.child_time_leave_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : props.row.average.child_time_leave_days ? props.row.average.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;" :class="paidHoliday && (props.row.total.child_time_leave_days > 0 || props.row.average.child_time_leave_hours) ? 'strong-orange-text-right' : 'attendance-text'">
                        {{props.row.canAdd ? '' : parseInt(props.row.average.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.closed_days">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : props.row.total.closed_days + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : props.row.average.closed_days + $t('label.unit_days')}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="60" :label="headers.estimated_overtime_hours">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispHour(props.row.total.estimated_overtime_hours)}}
                      </div>
                      <div style="text-align: right;">
                        <small>&nbsp;</small>
                      </div>
                      <div style="text-align: right;" class="attendance-text">
                        {{props.row.canAdd ? '' : formatDispHour(props.row.average.estimated_overtime_hours)}}
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'
  import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
  import AttendanceRecordReportSelect from '@/components/Dashboard/Views/Components/AttendanceRecordReportSelect'
  import CsvTemplateSelect from '@/components/Dashboard/Views/Components/CsvTemplateSelect'
  import ValidationRadio from '@/components/UIComponents/Inputs/ValidationRadioHorizontal'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    mixins: [mixin],
    components: {
      ValidationDate,
      EmployeeSearch,
      AttendanceRecordReportSelect,
      CsvTemplateSelect,
      ValidationRadio,
      vSelect
    },
    computed: {
      showPrintAll () {
        return this.orgSearchCond.search_date_type === '9' && !this.isPersonal && this.results.length > 0 && this.results[0].data.length > 0
      },
      isPersonal () {
        return this.screenCode === '01-003'
      },
      thisDateLabel () {
        let thisDateLabel = ''
        if (this.searchCond.search_date_type === '0') {
          thisDateLabel = this.$t('label.this_year')
        } else {
          thisDateLabel = this.$t('label.this_month')
        }
        return thisDateLabel
      },
      getText () {
        if (this.message && this.message.text) {
          if (this.message.text.indexOf('http') === -1) {
            const color = this.message.message_classification === 1 ? '#000000' : '#c84513'
            return '<span style="color: ' + color + ';">' + this.message.text.replace('{item1}', this.pageTitle) + '</span>'
          } else {
            const from = this.message.text.indexOf(' ') + 1
            const to = this.message.text.lastIndexOf('-')
            const url = this.message.text.substring(from, to)
            const text = this.message.text.substring(to + 1).replace('{item1}', this.pageTitle)
            return '<a href="' + url + '" target="_blank" style="color: #317FB8;">' + text + '</a>'
          }
        } else {
          return ''
        }
      }
    },
    data () {
      return {
        overtime: false,
        lateEarlyDeparture: false,
        lateNight: false,
        paidHoliday: false,
        currentYear: undefined,
        currentMonth: undefined,
        fromYear: undefined,
        fromMonth: undefined,
        toYear: undefined,
        toMonth: undefined,
        isCurrentMonth: false,
        showSearchCondition: true,
        labels: {},
        templateList: [],
        selectedRow: {},
        results: [],
        headers: {},
        orgSearchCond: {},
        searchCond: {
          company_code: '',
          office_code: '',
          group_code: '',
          team_code: '',
          office_range: '',
          group_range: '',
          team_range: '',
          search_employee_code: '',
          search_employee_name: '',
          employee_classification_code: '',
          from_year: '',
          from_month: '',
          to_year: '',
          to_month: '',
          working_month_from: '',
          working_month_to: '',
          employee_code: '',
          search_type: '3',
          retirement: true,
          search_date_type: '9'
        },
        years: [],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        offices: [],
        groups: [],
        teams: [],
        employee_types: [],
        roles: {},
        printParams: {},
        attendaneRecordLayout: {}
      }
    },
    methods: {
      searchDateTypeChanged () {
        console.log(this.searchCond.search_date_type, this.searchCond.search_type)
        if (this.searchCond.search_date_type !== '9' && this.searchCond.search_type === '3') {
          this.searchCond.search_type = '0'
        }
      },
      toggleSearchCondition () {
        this.showSearchCondition = !this.showSearchCondition
      },
      tableRowClassName ({row, rowIndex}) {
        if (row.closing_color === 2) {
          return 'closed-row'
        }
        return ''
      },
      isCurrentMonthClicked () {
        if (!this.isCurrentMonth) {
          this.searchCond.from_year = this.searchCond.to_year = this.currentYear
          this.searchCond.from_month = this.searchCond.to_month = this.currentMonth
        }
      },
      synchronizeFrom () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        if (from > to) {
          this.searchCond.to_year = this.searchCond.from_year
          this.searchCond.to_month = this.searchCond.from_month
        }
        this.adjustFromTo(true)
      },
      synchronizeTo () {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        if (from > to) {
          this.searchCond.from_year = this.searchCond.to_year
          this.searchCond.from_month = this.searchCond.to_month
        }
        this.adjustFromTo(false)
      },
      adjustFromTo (isFrom) {
        let from = parseInt(this.searchCond.from_year, 10) * 100 + parseInt(this.searchCond.from_month, 10)
        let to = parseInt(this.searchCond.to_year, 10) * 100 + parseInt(this.searchCond.to_month, 10)
        let minMonth = parseInt(this.fromYear, 10) * 100 + parseInt(this.fromMonth, 10)
        if (from < minMonth) {
          this.searchCond.from_year = this.fromYear
          this.searchCond.from_month = this.fromMonth
        }
        if (to < minMonth) {
          this.searchCond.to_year = this.fromYear
          this.searchCond.to_month = this.fromMonth
        }
        let maxMonth = parseInt(this.toYear, 10) * 100 + parseInt(this.toMonth, 10)
        if (from > maxMonth) {
          const isYearOver = this.searchCond.from_year !== this.toYear
          this.searchCond.from_year = this.toYear
          this.searchCond.from_month = this.toMonth
          if (isFrom && isYearOver) {
            this.$nextTick(() => {
              swal({
                text: this.$t('message.closing_date_is_not_found'),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            })
          }
        }
        if (to > maxMonth) {
          const isYearOver = this.searchCond.to_year !== this.toYear
          this.searchCond.to_year = this.toYear
          this.searchCond.to_month = this.toMonth
          if (!isFrom && isYearOver) {
            this.$nextTick(() => {
              swal({
                text: this.$t('message.closing_date_is_not_found'),
                type: 'error',
                confirmButtonClass: 'btn btn-warning btn-sm btn-default',
                buttonsStyling: false,
                allowOutsideClick: false
              })
            })
          }
        }
      },
      hideCsvTemplateSelect (selectedTemplate) {
        this.$modal.hide('csvTemplateSelect')
        if (selectedTemplate) {
          this.createCsv(selectedTemplate)
        }
      },
      showCsvTemplateSelect () {
        if (this.templateList.length === 1) {
          this.createCsv(this.templateList[0])
        } else {
          this.$modal.show('csvTemplateSelect')
        }
      },
      createCsv (template) {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            is_format: template.is_format,
            template_number: template.template_number,
            target_month_from: this.orgSearchCond.from_year + '/' + ('0' + this.orgSearchCond.from_month).slice(-2),
            target_month_to: this.orgSearchCond.to_year + '/' + ('0' + this.orgSearchCond.to_month).slice(-2),
            employee_code_list: this.results[0].data.map((row) => row.employee_code)
          }
        }
        this.send('/export/attendance_list/list', searchCond, false)
        .then(res => {
          swal({
            text: this.$t('message.csv_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
        .catch(error => {
          console.error(error)
        })

      },
      hideAttendanceRecordReportSelectAll (format) {
        this.$modal.hide('attendanceRecordReportSelectAll')
        if (format === '08-002-002') {
          this.printAllVertical()
        } else if (format === '08-002-001') {
          this.printAllHorizontal()
        }
      },
      showAttendanceRecordReportSelectAll () {
        if (this.isPersonal) {
          this.$modal.show('attendanceRecordReportSelectAll')
        } else {
          this.printAll(this.attendaneRecordLayout.value)
        }
      },
      printAllVertical () {
        this.printAll(2)
      },
      printAllHorizontal () {
        this.printAll(1)
      },
      hideAttendanceRecordReportSelect (format) {
        this.$modal.hide('attendanceRecordReportSelect')
        if (format === '08-002-002') {
          this.printVertical()
        } else if (format === '08-002-001') {
          this.printHorizontal()
        }
      },
      showAttendanceRecordReportSelect (index, row, title) {
        if (this.isPersonal) {
          this.printParams.index = index
          this.printParams.row = row
          this.printParams.title = title
          this.$modal.show('attendanceRecordReportSelect')
        } else {
          this.print(index, row, title, this.attendaneRecordLayout.value)
        }
      },
      printVertical () {
        this.print(this.printParams.index, this.printParams.row, this.printParams.title, 2)
      },
      printHorizontal () {
        this.print(this.printParams.index, this.printParams.row, this.printParams.title, 1)
      },
      printAll (direction) {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.orgSearchCond.search_type, 10),
            office_code: this.orgSearchCond.office_code ? this.orgSearchCond.office_code.value : '',
            team_code: this.orgSearchCond.team_code ? this.orgSearchCond.team_code.value : '',
            employee_code: this.orgSearchCond.search_employee_code,
            employee_name: this.orgSearchCond.search_employee_name,
            employee_classification_code: this.orgSearchCond.employee_classification_code ? this.orgSearchCond.employee_classification_code.value : '',
            working_month_from: this.orgSearchCond.from_year + '/' + ('0' + this.orgSearchCond.from_month).slice(-2),
            working_month_to: this.orgSearchCond.to_year + '/' + ('0' + this.orgSearchCond.to_month).slice(-2),
            group_code: this.orgSearchCond.group_code ? this.orgSearchCond.group_code.value : '',
            retirement: this.orgSearchCond.retirement,
            attendance_record_layout: direction
          }
        }
        this.send('/print/attendance_list/list', searchCond, false)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
        .catch(error => {
          console.error(error)
        })
      },
      print (index, row, title, direction) {
        const fromYear = title.indexOf('～') === -1 ? row.target_date.substring(0, 4) : this.orgSearchCond.from_year
        const fromMonth = title.indexOf('～') === -1 ? row.target_date.substring(4) : this.orgSearchCond.from_month
        const toYear = title.indexOf('～') === -1 ? row.target_date.substring(0, 4) : this.orgSearchCond.to_year
        const toMonth = title.indexOf('～') === -1 ? row.target_date.substring(4) : this.orgSearchCond.to_month
        const params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            company_code: this.$store.state.loginUser.companyCode,
            employee_code: row.employee_code,
            target_month_from: fromYear + '/' + ('0' + fromMonth).slice(-2),
            target_month_to: toYear + '/' + ('0' + toMonth).slice(-2),
            attendance_record_layout: direction
          }
        }
        const path = this.isPersonal ? '/print/attendance_employee' : '/print/attendance_list'
        this.send(path, params, false)
        .then(res => {
          swal({
            text: this.$t('message.report_confirmation'),
            type: 'info',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('button.yes'),
            cancelButtonText: this.$t('button.no'),
            confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.openReportPage()
          })
        })
        .catch(error => {
          console.error(error)
        })
      },
      detail (index, row) {
        row.telework_count = '0'
        row.totalGround = ''
        const detailName = this.isPersonal ? 'attendanceRecordDetailPersonal' : 'attendanceRecordDetail'
        this.$router.push({ name: detailName,
          params: {
            overtime: this.overtime,
            lateNight: this.lateNight,
            lateEarlyDeparture: this.lateEarlyDeparture,
            paidHoliday: this.paidHoliday,
            orgSearhCond: this.orgSearchCond,
            headers: this.headers,
            row: row
          }
        })
      },
      searchBelongs () {
        let workingFrom, workingTo
        if (this.searchCond.search_date_type === '0') {
          workingFrom = String(this.searchCond.from_year)
          workingTo = String(this.searchCond.to_year)
        } else if (this.searchCond.search_date_type === '3') {
          workingFrom = this.formatDate(this.searchCond.from_date)
          workingTo = this.formatDate(this.searchCond.to_date)
        } else {
          workingFrom = this.searchCond.from_year + '/' + ('0' + this.searchCond.from_month).slice(-2)
          workingTo = this.searchCond.to_year + '/' + ('0' + this.searchCond.to_month).slice(-2)
        }
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.searchCond.search_type, 10),
            search_date_type: parseInt(this.searchCond.search_date_type),
            office_code: this.searchCond.office_code ? this.searchCond.office_code.value : '',
            group_code: this.searchCond.group_code ? this.searchCond.group_code.value : '',
            team_code: this.searchCond.team_code ? this.searchCond.team_code.value : '',
            working_from: workingFrom,
            working_to: workingTo
          }
        }
        this.orgSearchCond = Object.assign({}, this.searchCond, {})
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        this.send('/view/attendance_group/list', searchCond)
        .then(res => {
          this.afterSearch(res)
        })
      },
      search () {
        let searchCond = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: parseInt(this.searchCond.search_type, 10),
            office_code: this.searchCond.office_code ? this.searchCond.office_code.value : '',
            team_code: this.searchCond.team_code ? this.searchCond.team_code.value : '',
            employee_code: this.isPersonal ? this.$store.state.loginUser.employeeCode : this.searchCond.search_employee_code,
            employee_name: this.searchCond.search_employee_name,
            employee_classification_code: this.searchCond.employee_classification_code ? this.searchCond.employee_classification_code.value : '',
            working_month_from: this.searchCond.from_year + '/' + ('0' + this.searchCond.from_month).slice(-2),
            working_month_to: this.searchCond.to_year + '/' + ('0' + this.searchCond.to_month).slice(-2),
            group_code: this.searchCond.group_code ? this.searchCond.group_code.value : '',
            retirement: this.searchCond.retirement
          }
        }
        this.orgSearchCond = Object.assign({}, this.searchCond, {})
        localStorage.setItem('orgSearchCond', JSON.stringify(this.searchCond))
        const path = this.isPersonal ? '/view/attendance_employee/list' : '/view/attendance_list/list'
        this.send(path, searchCond)
        .then(res => {
          this.attendaneRecordLayout = {
            value: res.api_area.attendance_record_layout ? res.api_area.attendance_record_layout : 1,
            disabled: false,
            items: [
              { label: this.$t('button.horizontal'), value: 1 },
              { label: this.$t('button.vertical'), value: 2 }
            ]
          }
          this.afterSearch(res)
        })
      },
      afterSearch (res) {
        let prevTargetDate = ''
        this.results = []
        let monthResult = {
          'title': '',
          'data': []
        }
        let headerLabels = this.getLabels(res.common_area)
        this.headers.office = headerLabels.office_ryaku
        this.headers.group = headerLabels.group_ryaku
        this.headers.team = headerLabels.team_ryaku
        this.headers.employee_classification_name = this.labels.employeeClassification_ryaku
        this.headers.employee = headerLabels.employee_ryaku
        this.headers.estimated_overtime_hours = headerLabels.estimated_overtime_hours_ryaku
        this.headers.real_total_minutes = headerLabels.real_total_minutes_ryaku
        this.headers.job_total_minutes = headerLabels.job_total_minutes_ryaku
        this.headers.standard_job_minutes = headerLabels.standard_job_minutes_ryaku
        this.headers.job_overwork_minutes = headerLabels.job_overwork_minutes_ryaku
        this.headers.job_holiday_hours = headerLabels.job_holiday_hours_ryaku
        this.headers.legal_job_minutes = headerLabels.legal_job_minutes_ryaku
        this.headers.standard_legal_minutes = headerLabels.standard_legal_minutes_ryaku
        this.headers.legal_overwork_minutes = headerLabels.legal_overwork_minutes_ryaku
        this.headers.legal_holiday_overwork_minutes = headerLabels.legal_holiday_overwork_minutes_ryaku
        this.headers.late_night_overwork_minutes = headerLabels.late_night_overwork_minutes_ryaku
        this.headers.break_minutes = headerLabels.break_minutes_ryaku
        this.headers.late_minutes = headerLabels.late_minutes_ryaku
        this.headers.lack_minutes = headerLabels.lack_minutes_ryaku
        this.headers.early_departure_minutes = headerLabels.early_departure_minutes_ryaku
        this.headers.paid_holiday_days = headerLabels.paid_holiday_days_ryaku
        this.headers.paid_holiday_hours = headerLabels.paid_holiday_hours_ryaku
        this.headers.compensatory_holiday_days = headerLabels.compensatory_holiday_days_ryaku
        this.headers.child_time_leave_hours = headerLabels.child_time_leave_hours_ryaku
        this.headers.closed_days = headerLabels.closed_days_ryaku
        this.headers.operation = headerLabels.operation
        this.headers.employee_count = this.$t('label.employee_count')
        this.headers.data_type = this.$t('label.data_type')
        this.templateList = res.common_area.combo_data.template_list
        const targetYearMonthList = []
        const data = res.api_area.employee_data ? res.api_area.employee_data : res.api_area.office_working_data ? res.api_area.office_working_data : res.api_area.group_working_data ? res.api_area.group_working_data : res.api_area.team_working_data ? res.api_area.team_working_data : []
        for (const result of data) {
          targetYearMonthList.push(result.target_date)
        }
        targetYearMonthList.sort()
        if (this.searchCond.search_date_type === '9') {
          const minYearMonth = targetYearMonthList[0]
          const maxYearMonth = targetYearMonthList[targetYearMonthList.length - 1]
          if (minYearMonth !== maxYearMonth) {
            const title = []
            title.push(minYearMonth.substring(0, 4))
            title.push(this.$t('label.year'))
            title.push(parseInt(minYearMonth.substring(4), 10))
            title.push(this.$t('label.month'))
            title.push('～')
            title.push(maxYearMonth.substring(0, 4))
            title.push(this.$t('label.year'))
            title.push(parseInt(maxYearMonth.substring(4), 10))
            title.push(this.$t('label.month'))
            monthResult = {
              'title': title.join(''),
              'show': true,
              'flex': res.api_area.employee_data_sum[0].working_system_type === 2,
              'data': []
            }
            for (let result of res.api_area.employee_data_sum) {
              result.canEdit = false
              monthResult.data.push(result)
            }
            this.results.push(monthResult)
          }
        }
        if (this.isPersonal) {
          let workingDatetimeFrom = this.searchCond.from_year + '-' + ('0' + this.searchCond.from_month).slice(-2) + '-' + '01'
          let workingDatetimeTo = this.searchCond.to_year + '-' + ('0' + this.searchCond.to_month).slice(-2) + '-' + '01'

          let start = new Date(workingDatetimeFrom)
          let end = new Date(workingDatetimeTo)
          let searchListDate = []
          var d = start
          while (true) {
            if (d > end) {
              break
            }
            searchListDate.push(new Date(d.getTime()))
            d.setMonth(d.getMonth() + 1)
          }
          for (let v of searchListDate) {
            monthResult = {
              'title': v.getFullYear() + this.$t('label.year') + (v.getMonth() + 1) + this.$t('label.month'),
              'show': true,
              'flex': false,
              'data': []
            }
            for (let result of res.api_area.employee_data) {
              let tmpDate = String(v.getFullYear()) + ('0' + (v.getMonth() + 1)).slice(-2)
              if (tmpDate === result.target_date) {
                monthResult.data.push(result)
                monthResult.flex = result.working_system_type === 2
                this.results.push(monthResult)
                break
              }
            }
          }
          if (this.results.length === 0) {
            this.results.push({'title': '', 'show': true, 'data': []})
          }
        } else {
          monthResult = {
            'title': '',
            'show': true,
            'flex': false,
            'data': []
          }
          for (let result of data) {
            if (prevTargetDate === result.target_date) {
              monthResult.data.push(result)
            } else {
              if (monthResult.title !== '') {
                monthResult.flex = monthResult.data[0].working_system_type === 2
                this.results.push(monthResult)
              }
              monthResult = {
                'title': this.getTitle(result),
                'show': true,
                'flex': false,
                'data': [result]
              }
            }
            prevTargetDate = result.target_date
          }
          this.results.push(monthResult)
        }
        if (this.results.length > 0) {
          this.toggleSearchCondition()
        }
      },
      getTitle (result) {
        let title = ''
        if (this.searchCond.search_date_type === '9' || this.searchCond.search_date_type === '1') {
          title = result.target_date.substring(0, 4) + this.$t('label.year') + parseInt(result.target_date.substring(4, 6), 10) + this.$t('label.month')
        } else if (this.searchCond.search_date_type === '2') {
          const year = result.target_date.substring(0, 4)
          const month = parseInt(result.target_date.substring(5, 7))
          const week = result.target_date.slice(-1)
          title = year + this.$t('label.year') + parseInt(month) + this.$t('label.month') + this.$t('label.week').replace('{week}', week)
        } else if (this.searchCond.search_date_type === '0') {
          title = result.target_date.substring(0, 4) + this.$t('label.year')
        } else if (this.searchCond.search_date_type === '3') {
          title = result.target_date.substring(0, 4) + this.$t('label.year') + parseInt(result.target_date.substring(5, 7), 10) + this.$t('label.month') + parseInt(result.target_date.substring(8, 10), 10) + this.$t('label.day')
        }
        return title
      },
      init () {
        // 初期表示
        if (this.isPersonal) {
          this.searchCond.search_type = '3'
          this.searchCond.search_employee_code = this.$store.state.loginUser.employeeCode
        }
        let params = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode
        }
        const path = this.isPersonal ? '/init/attendance_employee/list' : '/init/attendance_list/list'
        this.send(path, params)
        .then(res => {
          this.offices = []
          for (const data of res.common_area.combo_data.office_list) {
            const office = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.offices.push(office)
          }
          this.groups = []
          for (const data of res.common_area.combo_data.group_list) {
            const group = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.groups.push(group)
          }
          this.teams = []
          for (const data of res.common_area.combo_data.team_list) {
            const team = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.teams.push(team)
          }
          this.employee_types = []
          for (const data of res.common_area.combo_data.employee_classification_list) {
            const employeeType = {
              label: this.getLabel(data.label),
              value: data.value
            }
            this.employee_types.push(employeeType)
          }
          this.labels = this.getLabels(res.common_area)
          this.roles = res.common_area.button_role
          const yyyyMM = res.common_area.combo_data.select_date.split('/')
          if ((window.previousUrl === '/01/menu01Screen002/detail' && window.currentUrl === '/01/menu01Screen002/list') ||
            (window.previousUrl === '/01/menu01Screen003/personal' && window.currentUrl === '/01/menu01Screen003/list')) {
            const paramsString = localStorage.getItem('params')
            const params = JSON.parse(paramsString)
            this.overtime = params.overtime
            this.lateNight = params.lateNight
            this.lateEarlyDeparture = params.lateEarlyDeparture
            this.paidHoliday = params.paidHoliday
            const orgSearchCond = localStorage.getItem('orgSearchCond')
            this.searchCond = JSON.parse(orgSearchCond)
            this.search()
          } else {
            this.searchCond.from_year = this.searchCond.to_year = parseInt(yyyyMM[0], 10)
            this.searchCond.from_month = this.searchCond.to_month = parseInt(yyyyMM[1], 10)
          }
          this.currentYear = parseInt(yyyyMM[0], 10)
          this.currentMonth = parseInt(yyyyMM[1], 10)
          const fromYyyyMm = res.common_area.combo_data.select_date_from.split('/')
          this.fromYear = parseInt(fromYyyyMm[0], 10)
          this.fromMonth = parseInt(fromYyyyMm[1], 10)
          const toYyyyMm = res.common_area.combo_data.select_date_to.split('/')
          this.toYear = parseInt(toYyyyMm[0], 10)
          this.toMonth = parseInt(toYyyyMm[1], 10)
          let currentYear = new Date().getFullYear()
          let currentMonth = new Date().getMonth()
          let year
          if (currentMonth === 11) currentYear += 1
          for (year = currentYear; year >= this.fromYear; year--) {
            this.years.push(year)
          }
        })
      },
      convertSearchCond () {
        if (this.searchCond.office_code && typeof this.searchCond.office_code === 'object') {
          this.searchCond.office_code = this.searchCond.office_code.value
        }
        if (this.searchCond.group_code && typeof this.searchCond.group_code === 'object') {
          this.searchCond.group_code = this.searchCond.group_code.value
        }
        if (this.searchCond.team_code && typeof this.searchCond.team_code === 'object') {
          this.searchCond.team_code = this.searchCond.team_code.value
        }
        if (this.searchCond.employee_classification_code && typeof this.searchCond.employee_classification_code === 'object') {
          this.searchCond.employee_classification_code = this.searchCond.employee_classification_code.value
        }
      },
      show () {
        this.convertSearchCond()
        this.$modal.show('employee')
      },
      hide (selected) {
        if (selected) {
          this.searchCond.search_employee_code = selected.employee_code
          this.searchCond.search_employee_name = selected.employee_name
        }
        this.$modal.hide('employee')
      },
      searchEmployeeSelected () {
        if (!this.canShow('/SS/menuSSScreen018')) return
        if (!this.searchCond.search_employee_code) {
          this.searchCond.search_employee_name = ''
          return
        }
        this.searchCond.search_employee_name = ''
        const searchCond = {
          screen_code: 'SS-018',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            search_type: 3,
            office_code: '',
            group_code: '',
            team_code: '',
            employee_code: this.searchCond.search_employee_code,
            employee_name: '',
            employee_classification_code: '',
            retirement: true,
            all_flag: false
          }
        }
        this.send('/view/search_employee/list', searchCond, false)
        .then(res => {
          if (res.api_area.employee_list && res.api_area.employee_list.length > 0) {
            this.hide(res.api_area.employee_list[0])
          }
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style>
  .el-table .closed-row {
    background-color: #f0f8ff !important;
  }
</style>
