<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal
      name="detail"
      :draggable="draggable"
      :clickToClose="false"
      height="auto"
      :width="modalWidth"
      :pivotX="0.6"
    >
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <Help
          class="pull-right"
          :help="help"
          :placeholders="[pageTitle]"
          movie-key="salary_mst"
          div-style="padding-bottom: 5px;"
        />
      </div>
    </div>
    <div ref="frame">
      <form method="#" action="#">
        <div class="card">
          <div class="card-header search-condition-title" @click="toggleSearchCondition">
            <div style="display: inline-block; width: 49%;">
              <h4 class="card-title">{{$t('label.search_condition')}}</h4>
            </div>
            <div
              style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;"
            >
              <template v-if="showSearchCondition">
                <span class="ti-angle-up pull-right"></span>
              </template>
              <template v-else>
                <span class="ti-angle-down pull-right"></span>
              </template>
            </div>
          </div>
          <div class="card-content" v-if="showSearchCondition">
            <div class="form-group search-condition-item">
              <label>{{labels.salary_item_name}}</label>
              <input
                type="text"
                :placeholder="$t('placeholder.salary_item_name')"
                maxlength="20"
                class="form-control length100"
                v-model="searchCond.salary_item_name"
              />
            </div>
            <!-- 支給/控除区分 -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
              <label>{{labels.payment_deduction_category}}</label>
              <div>
                <el-select class="select-success width150"
                            size="large"
                            placeholder=""
                            v-model="searchCond.payment_deduction_category">
                  <el-option v-for="payment_deduction_category in comboData.payment_deduction_category_list"
                              class="select-success"
                              :value="payment_deduction_category.value"
                              :label="payment_deduction_category.label"
                              :key="payment_deduction_category.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 支給区分 -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
              <label>{{labels.salary_bonus_classification}}</label>
              <div>
                <el-select class="select-success width150"
                            size="large"
                            placeholder=""
                            v-model="searchCond.salary_bonus_classification">
                  <el-option v-for="salary_bonus_classification in comboData.salary_bonus_classification_list"
                              class="select-success"
                              :value="salary_bonus_classification.value"
                              :label="salary_bonus_classification.label"
                              :key="salary_bonus_classification.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 計算方法 -->
            <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top; margin-bottom: 0;">
              <label>{{labels.fixed_fluctuation}}</label>
              <div>
                <el-select class="select-success width150"
                            size="large"
                            placeholder=""
                            v-model="searchCond.fixed_fluctuation">
                  <el-option v-for="fixed_fluctuation in comboData.fixed_fluctuation_list"
                              class="select-success"
                              :value="fixed_fluctuation.value"
                              :label="fixed_fluctuation.label"
                              :key="fixed_fluctuation.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <br>
            <br>
            <div class="search-button-area">
              <template v-if="labels.entry === 1">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-default"
                  @click="showAdd"
                >
                  <span class="btn-label"><i class="ti-settings"></i></span>
                  {{$t('button.new')}}
                </button>
              </template>
              <template v-if="labels.search === 1">
                <button
                  type="button"
                  class="btn btn-search btn-sm btn-default"
                  @click="search"
                >
                  <span class="btn-label"><i class="ti-search"></i></span>
                  {{$t('button.search')}}
                </button>
              </template>
            </div>
          </div>
        </div>
      </form>
      <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" />
      <template v-if="tableData && tableData.length > 0">
        <div class="card">
          <div class="card-header search-result-title">
            <h4 class="card-title">{{$t('label.search_result')}}</h4>
          </div>
          <div class="card-content">
            <div class="row" ref="summary">
              <div class="col-sm-6 pagination-info">
                <el-select
                  class="select-success length2"
                  :disabled="isChanged"
                  v-model="pagination.perPage"
                >
                  <el-option
                    class="select-success"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-sm-6"></div>
            </div>
            <div style="margin-top: 10px;">
              <div :style="tableWrapperStyle">
                <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"                  class="table-width"
                  border
                  :data="pagedData"
                  :empty-text="$t('label.no_data')"
                  :style="tableStyle"
                  ref="table"
                  :row-class-name="tableRowClassName"
                >
                  <!-- 操作 -->
                  <el-table-column
                    :width="130"
                    v-if="showOperation"
                    fixed="left"
                    :key="componentKey"
                    :label="$t('label.operation')"
                  >
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <!-- 表示 -->
                      <template v-if="labels.preview === 1">
                        <a
                          class="btn btn-simple btn-icon like"
                          @click="handleView(props.$index, props.row)"
                        >
                          <i class="ti-file" :title="$t('button.view')"></i>
                        </a>
                      </template>

                      <!-- 更新 -->
                      <template v-if="labels.update === 1 && canUpdate(props.row)">
                        <a
                          class="btn btn-simple btn-icon edit"
                          @click="handleEdit(props.$index, props.row)"
                        >
                          <i class="ti-pencil-alt" :title="$t('button.update')"></i>
                        </a>
                      </template>
                      <!-- システム項目の場合、削除は不可とする -->
                      <template v-if="props.row.fixed_fluctuation !== 3">
                        <!-- 削除 -->
                        <template v-if="labels.delete === 1 && canDelete(props.row)">
                          <a
                            class="btn btn-simple btn-icon btn-danger remove"
                            @click="handleDelete(props.$index, props.row)"
                          >
                            <i class="ti-close" :title="$t('button.delete')"></i>
                          </a>
                        </template>
                      </template>
                    </template>
                  </el-table-column>
                  <!-- 給与項目 -->
                  <el-table-column :width="getColumnWidth(tableData, labels.salary_item_name === undefined ? labels.salary_item_name === undefined ? '' : labels.salary_item_name : labels.salary_item_name, 'salary_item_name', 'salary_item_name')" :label="labels.salary_item_name_ryaku === undefined ?  '' : labels.salary_item_name_ryaku ">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <div style="font-size: medium; font-weight: bold;">
                          {{props.row.salary_item_name}}
                        </div>
                        <small>{{getLabel(getSelectName(props.row.fixed_fluctuation, comboData.fixed_fluctuation_list))}}</small>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 支給控除 -->
                  <el-table-column :width="getColumnWidth(tableData, labels.payment_deduction_category === undefined ? labels.payment_deduction_category === undefined ? '' : labels.payment_deduction_category : labels.payment_deduction_category, 'payment_deduction_category', 'payment_deduction_category')" :label="labels.payment_deduction_category_ryaku === undefined ? '' : labels.payment_deduction_category_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left; font-size: medium; font-weight: bold;">
                        <!--  支給控除区分 ラベルから取り出す-->
                        {{getTowChar(getLabel(getSelectName(props.row.payment_deduction_category, comboData.payment_deduction_category_list_for_view)))}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 支給区分 -->
                  <el-table-column :width="getColumnWidth(tableData, labels.salary_bonus_classification === undefined ? labels.salary_bonus_classification === undefined ? '' : labels.salary_bonus_classification : labels.salary_bonus_classification, 'salary_bonus_classification', 'salary_bonus_classification')" :label="labels.salary_bonus_classification_ryaku === undefined ? '' : labels.salary_bonus_classification_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <!--  支給控除区分 ラベルから取り出す-->
                        {{getLabel(getSelectName(props.row.salary_bonus_classification, comboData.salary_bonus_classification_list))}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 計算方法 -->
                  <el-table-column :width="getColumnWidth(tableData, labels.fixed_fluctuation === undefined ? labels.fixed_fluctuation === undefined ? '' : labels.fixed_fluctuation : labels.fixed_fluctuation, 'fixed_fluctuation', 'fixed_fluctuation')" :label="labels.fixed_fluctuation_ryaku === undefined ? '' : labels.fixed_fluctuation_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        <!--  計算方法 ラベルから取り出す-->
                        {{getLabel(getSelectName(props.row.fixed_fluctuation, comboData.fixed_fluctuation_list))}}
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 更新日 -->
                  <el-table-column v-if="showUpdateDate" :width="getColumnWidth(tableData, labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku, 'update_date')" :label="labels.update_date_ryaku === undefined ? labels.updateDate_ryaku === undefined ? '' : labels.updateDate_ryaku : labels.update_date_ryaku">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: left;">
                        {{props.row.update_date}}
                        <div>
                          <small>{{props.row.update_employee_name}}</small>
                        </div>
                        <div>
                          <small>{{props.row.update_employee_code}}</small>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="row" ref="pager">
                <div class="col-sm-6 pagination-info">
                  <p
                    class="category"
                  >{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination
                    class="pull-right"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  ></p-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import SalaryItemSearchMixin from '@/components/Dashboard/Views/Master/SalaryItemSearchMixin'

  export default {
    name: 'SalaryItemSearch',
    mixins: [SalaryItemSearchMixin]
  }
</script>
<style scoped>
</style>
