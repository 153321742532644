export default {
  ja: {
    message: {
    },
    label: {
    },
    button: {
    },
    placeholder: {
    },
    help: {
    }
  },
  en: {
    message: {
    },
    label: {
    },
    button: {
    },
    placeholder: {
    },
    help: {
    }
  }
}
