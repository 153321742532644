import okubo from './TranslationsOkubo'
import takeuchi from './TranslationsTakeuchi'
import miki from './TranslationsMiki'
import googleapis from './TranslationsGoogleApis'

export default {
  ja: {
    message: {
      ...okubo.ja.message,
      ...takeuchi.ja.message,
      ...miki.ja.message,
      ...googleapis.ja.message,
      boss_pk_check: '部署、申請書名の組合せは重複出来ません。',
      both_field_required: '{_field1_}または、{_field2_}のどちらかは必須項目です。入力をお願いします。',
      business_type_pk_check: '職種は重複出来ません。',
      cancellation_complete: 'Artemis Collierを解約しました。ご利用ありがとうございました。',
      cancellation_description: 'こちらの操作は取消ができないため、慎重に行ってください。',
      closing_date_required: '締日はすべて入力してください。',
      closing_date_continuous: '締日はすべて連続する日付を入力してください。',
      closing_message: '出勤簿を締めてよろしいでしょうか？',
      communication_error: 'サーバーとの通信に失敗しました。インターネット接続を確認してください。',
      company_delete_complete: '{companyName}を削除しました。',
      company_regist_complete_title: '会社登録処理を開始しました！',
      company_regist_complete_description: '登録が完了次第メールでご連絡します。しばらくお待ちください。',
      confirm_to_cancellation: 'Artemis Collierを解約します。この操作は取り消せません。続行しますか？',
      confirm_to_delete: '削除します。よろしいですか？',
      confirm_to_delete_company: '{companyName}を削除します。この操作は取り消せません。続行しますか？',
      confirm_to_insert1: '登録対象にチェックが入っていない従業員がいます',
      confirm_to_insert2: '登録してもよろしいですか？',
      confirm_to_insert3: '入力した内容は保存されます',
      confirm_to_order: '注文してよろしいですか？',
      confirm_to_select_all_authority: '権限範囲で[全員]が指定されています。この場合、この部署に所属する従業員は全ての従業員が検索可能となります。よろしいですか?',
      contract_already_end: 'サービスが解約済です。管理者に連絡してください。',
      contract_not_enabled: 'サービス利用料の支払いが完了していないため、ログインできませんでした。管理者に連絡してください。',
      day_not_deletable_at: '{date}には同じ日の行が存在しないため、削除できません。',
      decimal_field_required: '{_field_}は数値で入力をお願いします。',
      decimal_field_higher: '{_field_}は{_value_}以上の数値で入力をお願いします。',
      decimal_required: '{_field_}は数値で入力をお願いします。',
      field_required: '{_field_}は必須項目です。入力をお願いします。',
      field_required_simple: '必須項目です。',
      field_work_time: '時間(XX:XX)を入力してください',
      ground_not_deletable_at: '{date}には削除できない事由が選択されています。',
      holiday_info_not_searched: '検索ボタンを押下して休日情報を設定してください。',
      inconsistent_time: '退勤時間は出勤時間より後の時刻を入力してください。',
      inconsistent_time_at: '{date}の退勤時間は出勤時間より後の時刻を入力してください。',
      incorrect_year_month_order: '終了月は開始月と同じか、開始月より新しい月を選択してください。',
      input_error: '入力値に誤りがあります。',
      invalid_attendance_record: '出勤簿に不備があるため、申請できませんでした。詳細は出勤簿をご確認下さい。',
      invalid_end_time: '退勤時間が不正です。',
      invalid_end_time_at: '{date}の退勤時間が不正です。',
      invalid_holiday_work_hours: '休日労働時間が正しくありません。',
      invalid_late_night_overtime: '深夜労働時間が正しくありません。',
      invalid_overtime: '残業時間が正しくありません。',
      invalid_start_time: '出勤時間が不正です。',
      invalid_start_time_at: '{date}の出勤時間が不正です。',
      is_approval_route_required: '{item4}が入力されていません。入力をお願いします。',
      is_authority_required: '{item3}が未入力の行があります。入力をお願いします。',
      is_confirm_last: '確認は審査が完了した後に設定してください。',
      is_different_integrated_group_selected: '同じ{item}が選択されています。',
      is_division_group_required: '{item}は2つ以上登録してください。',
      is_duplicate_group_code_selected: '同一の部署、または未設定の部署が複数選択されています。',
      is_entered_new_check_password: '新しいパスワード（確認）の入力がありません。',
      is_entered_new_password: '新しいパスワードの入力がありません。',
      is_entered_old_password: '現在のパスワードの入力がありません。',
      is_end_time: '終業時間はhh:mm形式(0:00～23:59)で入力してください',
      is_integrated_group_required: '{item}は2つ以上選択してください。',
      is_item_number_for_row: '{row}行目の{item}は数値を入力してください。',
      is_item_required_for_row: '{row}行目の{item}は必須です。',
      is_mismatch_new_password: '新しいパスワードと新しいパスワード（確認）が一致していません。',
      is_paid_holiday_management_required: '{item1}、{item2}、{item3}、{item4}、{item5}のいずれかが未入力の行があります。全ての項目を入力して下さい。',
      is_paid_holiday_management_numeric: '{item2}、{item5}のいずれかが数値以外の行があります。数値を入力して下さい。',
      is_paid_holiday_payment_numeric: '数値以外の値が入力されている行があります。',
      is_paid_holiday_payment_required: '{_field_}が未入力の行があります。入力をお願いします。',
      is_paid_holiday_payment_required_working_days: '勤続月数毎に少なくとも1つ以上の有給付与日数を入力してください。',
      is_paid_holiday_payment_unique: '勤続月数が重複しています。異なる月数を入力してください。',
      is_role_or_approver_required: '{item1}、または{item2}が未入力の行があります。入力をお願いします。',
      is_select_default_working_schedule_required: '{item}は1つ選択してください。',
      is_start_time: '始業時間はhh:mm形式(0:00～23:59)で入力してください',
      is_table_required: '{item}は1件以上登録してください。',
      is_unusable_string_new_password: '新しいパスワードに使用できない文字列が含まれています。',
      is_unusable_string_password: '使用できない文字列が含まれています。',
      is_weakly_new_password: '新しいパスワードの強度が基準値を下回っています。',
      is_weakly_password: '強度が基準値を下回っています。',
      is_word_count_new_password: '新しいパスワードは12文字以上を設定してください。',
      is_word_count_password: '12文字以上を設定してください。',
      is_working_schedule_required: '{item}は1つ以上選択してください。',
      job_or_legal: '所定休日と法定休日に同じ曜日は設定出来ません。',
      login_input_error: '会社コード、従業員番号、パスワードを全て入力してください。',
      max_value: '{max}以下の数値を入力してください。',
      min: '{length}文字必要です。',
      min_value: '{min}以上の数値を入力してください。',
      month_day_duplicated: '統一支給日が重複しています。異なる日付を選択してください。',
      names_required: '{item}は1つ以上登録してください。',
      name_required: '{item}はすべて入力してください。',
      not_authorized: '権限が登録されていません。管理者にご確認ください。',
      no_holiday: '{_item_}年の祝日データが登録されていないため祝日反映は利用出来ません。',
      num_value: '{item}は数値を入力してください。',
      password_pattern: 'メールアドレスの形式が誤っています。',
      password_text: '英大文字小文字数字を含め、12文字以上30文字以内で入力して下さい。',
      payment_info_not_registered: '支払い情報が未登録です。',
      postalCode: '郵便番号の形式で入力してください。（例 100-0001）',
      release_message: '出勤簿締めを解除してもよろしいでしょうか？',
      report_confirmation: 'PDF作成を開始しました。ダウンロードリストに移動しますか？',
      row_start_time_error: '{row}行目の開始時間が不正です。',
      row_end_time_error: '{row}行目の終了時間が不正です。',
      salaryItem_detail_calc_notedit: '計算情報を１つ以上入力してください。',
      salaryItem_detail_payment_notedit: '支給項目を１つ以上入力してください。',
      salaryItem_detail_system_notedit: 'システム項目は編集できません。',
      system_error_title: 'システムエラー',
      tel: '電話(ファックス)番号の形式で入力してください。（例 03-1000-0001）',
      unexpected_error: '予期せぬエラーが発生しました。しばらく時間を空けてからもう一度実行してください。',
      unselectable_authority: '確認の後に審査を選択することはできません。',
      upper_group_error: '上位部署に従う設定は上位部署を設定した場合のみ選択できます。',
      // 以下未翻訳
      confirm_to_delete_attendance: '一か月分のデータが完全に消去されます。よろしいですか',
      limit_legal_one_weeks_minutes_required: '１週間の所定労働時間は必須項目です。入力をお願いします。',
      transformation_term_from_required: '変形労働時間制による期間は必須項目です。入力をお願いします。',
      legal_job_minutes_error: '就業時間が法定労働時間を超えています。法定労働時間以内で入力してください。',
      no_matching_options: '選択肢がありません。',
      no_difference_application: '申請内容に変更がありません。',
      default_work_schdule_is_required: 'デフォルトの勤務体系が選択されていません。デフォルトの勤務体系を選択してください。',
      transformation_detail_is_required: '変形労働の詳細が設定されていません。詳細を設定してください。',
      transformation_detail_ground_is_required: '変形労働の詳細で事由が設定されていない日があります。全ての日に事由を設定してください。',
      transformation_detail_job_start_end_is_required: '変形労働の詳細で出勤日に出勤または退勤が設定されていない日があります。出勤日には出勤と退勤を設定してください。',
      decimal_required_simple: '数値を入力してください。',
      credit_card_is_invalid: '入力されたカードで登録ができませんでした。有効なカードを入力してください。',
      invalid_working_day_count: '年間の労働日数が変更されているため更新できません。年間の労働日数を変更する場合は締めを全て解除してください。',
      transformation_monthly_required: '1箇月単位の変形労働時間制に関する協定届は必須項目です。入力をお願いします。',
      transformation_yearly_required: '1年単位の変形労働時間制に関する協定届は必須項目です。入力をお願いします。',
      confirm_to_reset: 'リセットします。よろしいですか？',
      is_examination_required: '審査が未設定です。少なくとも1つ以上の審査を設定してください。',
      is_examination_required_workflow: '有効な審査者が存在しません。少なくとも１人以上の権限が審査の審査者を行追加ボタンで追加してください。',
      flex_break_time_error: 'コアタイムが未設定の場合、休憩時間は設定できません。休憩時間を削除するかコアタイムを入力してください。',
      confirm_to_replace_ground: '出退勤時刻が未入力の出勤日または有給奨励日があります。欠勤日にして続行してもよろしいですか？',
      csv_confirmation: 'CSV作成を開始しました。ダウンロードリストに移動しますか？',
      employee_paid_msg: '有給休暇を利用する場合には初めに「マスタ設定]-[有給休暇付与ルール」の設定を行ってください。',
      combination_message: '{item}を入力する場合、{item2}を入力してください。',
      change_amount_message: '⑨～⑫は一月分セットで入力してください。',
      monthly_change_message: '{date}日未満の場合、月額変更届は不要です。',
      required_add_row: '{item}は行追加で必須です。',
      already_add_row: '行追加済の内容です。',
      already_add_employee: '従業員が重複しています。月額変更届対象開始月を変更する場合は先に削除をチェックしてください。',
      monthly_change_nothing: '月額変更届対象者を追加してください。',
      shift_pk_check: '職種、勤務体系の組合せは重複出来ません。',
      check_error: '入力に不備があります。ご確認ください。',
      shift_decision_pk_check: '従業員は重複出来ません。',
      start_end_check: '終了時間が開始時間より前になっています。',
      is_time: 'hh:mm形式(0:00～23:59)で入力してください。',
      is_public: '確定済になっていない従業員が存在します。対象日：{item}',
      is_katakana: 'カタカナで入力してください。',
      is_half_width_alphanumeric: '半角英数字で入力してください。',
      select_one_or_more: '1つ以上選択してください。',
      pk_check: '{item}は重複出来ません。',
      required_shift: 'シフトが登録されていない場合、公開出来ません。'
    },
    label: {
      ...okubo.ja.label,
      ...takeuchi.ja.label,
      ...miki.ja.label,
      ...googleapis.ja.label,
      about_artemis: 'アルテミスの機能紹介',
      account_classification: '口座種別',
      account_number: '口座番号',
      achievement: '実績',
      active_employee_count: '在籍人数',
      actual: '実績',
      add_national_holiday: '祝日をカレンダーに反映',
      add_pin: '登録したい場所にピンを立ててください',
      administrator: '管理者',
      admin_login: '管理者ログインはこちら',
      age: '年齢',
      aggregation_category: '集計区分',
      agreement_parties_employee_name: '協定当事者の氏名',
      agreement_parties_job_title_name: '協定当事者の職名',
      allocation: '割当',
      all_employee_application_control: '申請情報に一括反映',
      already: '済',
      amount_from: '円以上',
      amount_of_money: '金額',
      amount_to: '円未満',
      annual_holidays: '年間休日日数',
      annual_result: '[年間実績]',
      apl: '4月',
      application_type: '申請区分',
      apply_employee: '申請者の従業員',
      apply_person: '申請者',
      applicant_employee: '申請者の従業員',
      application: '申請書',
      application_classification: '申請分類',
      application_inf: '申請情報',
      approval_attendance: '勤怠承認',
      approval_route: '承認経路',
      approval_salary: '給与承認',
      approved: '承認済み',
      approver: '承認者',
      approverl_company: '承認者の会社',
      approverl_employee: '承認者の従業員',
      approverl_employee_name: '承認者の氏名',
      approverl_group: '承認者の部署',
      approverl_role_code: '承認者のロールコード',
      app_store_logo_path: '/static/img/app_store.png',
      apr: '4月',
      artemis_login: 'Artemis Collierのログインはこちら',
      artemis_system_admin: 'Artemis Collierのシステム管理者',
      assign_to_employee: '従業員を割り当て',
      attached_employee: '添付者',
      attached_file: '添付ファイル',
      attendance: '勤怠',
      attendance_record: '出勤簿',
      aug: '8月',
      auto_approverl_flag: '承認ルート上に同じ{item}が二度出現する際に、自動で承認します',
      available: 'あり',
      average: '平均',
      average_age: '平均年齢',
      average_wage: '平均賃金',
      average_overtime_hours: '平均残業時間',
      average_paid_holidays: '平均年間有給取得数',
      average_enrolled_months: '平均在籍月数',
      balance: '残金',
      bank: '銀行',
      bank_code: '銀行コード',
      bank_search_description: '銀行コードを入力、または銀行名をクリックしてください。',
      bank_search_description2: '他の銀行の場合は最初の文字を選択して検索してください。',
      bank_transfer: '銀行振込',
      bank_transfer_list: '銀行振込一覧表',
      base_date_setting: '入社初年度の従業員について有給休暇付与ルールとは別に追加で有給支給する場合は下記に設定して下さい',
      basic_information: '基本情報',
      boss: '上司',
      boss_company: '上司の会社',
      boss_employee: '上司の従業員',
      boss_group: '上司の部署',
      branch: '支店',
      branch_code: '支店コード',
      branch_search_description: '支店コードを入力、または支店名の最初の文字を選択して検索してください。',
      break_add: '休憩登録',
      break_time: '休憩時間',
      business_description: '従業員の36協定管理に必要となります。',
      business_info: '職種', // 名称変更可能な項目のため、会社登録以外での使用不可
      career_inf: '経歴情報',
      cash: '現金',
      catch_flg: '受取済',
      change_language: '言語の変更',
      change_password: 'パスワードの変更',
      change_password_annotation1: '・変更ボタンを押下すると変更手続きが完了します。',
      change_password_annotation2: '・パスワードは半角英字(A-Z、a-z)、数字、ハイフン(-)、アンダースコア(_)、アットマーク(@)、',
      change_password_annotation3: '  感嘆符(!)、シャープ(#)、スペース( )が利用可能です。文字数は１２文字以上を設定して下さい。',
      closing: '締日',
      closing_date_unit: '日締めで',
      closing_term1: '締日 1～6月',
      closing_term2: '締日 7～12月',
      comment: 'コメント',
      common_route: '間接部門',
      commute_target_date: '支給月度',
      commuting_costs: '通勤費',
      company_address_description: '会社の住所を入力してください。',
      company_amount: '会社負担',
      company_code: '会社コード',
      company_description: '基本情報を入力してください。',
      company_info: '会社情報',
      company_logo: '会社ロゴ',
      company_regist: '会社登録',
      company_regist_description: '会社に関する各種情報、および支払情報を登録します。',
      confirm_company_regist: '会社登録確認',
      confirm_company_regist_description1: '会社登録の準備が完了しました。',
      confirm_company_regist_description2: '登録してよろしいですか？',
      confirm_employee_delete: '従業員削除確認',
      confirm_employee_delete_description1: '従業員削除の準備が完了しました。',
      confirm_employee_delete_description2: '削除してよろしいですか？',
      confirm_employee_regist: '従業員登録確認',
      confirm_employee_regist_description1: '従業員登録の準備が完了しました。',
      confirm_employee_regist_description2: '登録してよろしいですか？',
      confirm_employee_update: '従業員更新確認',
      confirm_employee_update_description1: '従業員更新の準備が完了しました。',
      confirm_employee_update_description2: '更新してよろしいですか？',
      copy_role_code: '以下の{item1}を元にして新規{item2}を作成します。',
      corporate_number_page: '国税庁　法人番号公表サイト',
      costs_from_the_next_month: '翌月以降の費用',
      count: '回',
      created_salary: '明細作成済',
      credit: '貸方',
      cumulative_number_of_special_provisions: '特別条項累計回数',
      cumulative_time_of_special_provisions: '特別条項累計時間',
      currency: '円',
      dashboard: 'ダッシュボード',
      data_none: 'データなし',
      data_type: '区分',
      date: '日付',
      day: '日',
      days_5: '5日以上',
      days_4: '4日',
      days_3: '3日',
      days_2: '2日',
      days_1: '1日',
      debit: '借方',
      dec: '12月',
      deduction: '控除',
      deduction_salary_item_code: '控除額',
      default: 'デフォルト',
      default_work_schedule: 'デフォルトの勤務体系',
      delete: '削除',
      delete_national_holiday: '祝日をカレンダーから削除',
      dependent: '扶養家族',
      deputy_apply_employee: '代理申請者の従業員',
      deputy_approver_info: '代理承認者の情報',
      deputy_approver_info_ryaku: '代',
      deputy_target_person: '対象者(申請者)',
      description_of_payslip_calculation: '上記の勤怠データを元に金額を再計算します。',
      difference: '差額',
      display_date: 'お知らせ登録日',
      display_range: '{total}件中{from}件目から{to}件目までを表示',
      dividing_group: '分割元{item}',
      division_group: '分割先{item}',
      early_departure_application: '早退申請',
      elapsed_date: '停滞日',
      employee_amount: '本人負担',
      employee_code: '従業員番号',
      employee_count: '従業員数',
      employee_description: '※利用開始後にArtemis Collierの設定を進めていく方の情報を登録して下さい。',
      employee_group_term_from: '所属期間(開始)',
      employee_group_term_to: '所属期間(終了)',
      employee_info: '従業員の情報',
      employee_info_ryaku: '従',
      employee_lunch: '弁当注文',
      employee_team_term_from: '所属期間(開始)',
      employee_team_term_to: '所属期間(終了)',
      employment_insurance_rate_link: '雇用保険料率について（厚生労働省のリンク）',
      employment_insurance_subscription: '雇用保険加入有無',
      end: '終了',
      end_time: '終了時刻',
      enrolled_count: '従業員数（正社員）',
      existing_closing_name: '{item}（既存の{item}に対象年を追加する場合）',
      existing_employee_between: '　に在籍している従業員',
      face_image: '従業員の顔写真を表示する場合は画像ファイルを指定してください',
      favorite: 'お気に入り',
      feb: '2月',
      felica_download: 'FeliCaアプリダウンロードはこちら',
      filter_by: '{item}で検索',
      first: '第一',
      first_month_cost: '初月費用',
      fiscal_year: '年度',
      fixed_value: '固定値',
      friday: '金曜日',
      friday_ryaku: '金',
      google_play_logo_path: '/static/img/google_play.png',
      grade: '等級',
      group: '部署', // ToDo サーバーから取得できるようになったら削除する。
      group_by_employee: '従業員別',
      group_by_belongs: '所属別',
      group_by_year: '年別',
      group_by_month: '月別',
      group_by_week: '週別',
      group_by_day: '日別',
      group_inf: '部署情報',
      half_amount: '折半額',
      health_insurance_salary_total_rate: '健康保険合計料率',
      help: 'ヘルプ',
      hire: '入社年度',
      hire_employee_count: '入社人数',
      hire_year: '入社年度順に表示',
      holiday_info: '休日情報',
      hour: '時',
      hourly_wage: '時給',
      hourly_wage_overtime_premium: '時給[法定外割増分]',
      hourly_wage_legal_holiday_premium: '時給[法定休日割増分]',
      hourly_wage_midnight_premium: '時給[深夜割増分]',
      hours: '時間',
      icon: 'アイコン',
      image_file: '画像ファイル',
      immediate_transmission: '即時送信',
      imprint_correction_time: '打刻補正時刻',
      imprint_correction_type: '打刻補正区分',
      including_read: '既読も含む',
      increase_amount: '増加額',
      industry_description: '業種を選択してください。',
      insurance_nursing_choice: '被保険者が負担する保険料に円未満の端数がある場合',
      insurance_situation_description: '現在の保険加入状況を入力してください。',
      integrated_group: '統合元{item}',
      in_kind: '現物',
      jan: '1月',
      jly: '7月',
      job_start: '出勤',
      job_end: '退勤',
      journal_list: '仕訳データ一覧表',
      jun: '6月',
      jul: '7月',
      label_approverl_data: 'あなたの代理承認者一覧',
      label_delete: '削除',
      label_deputy_approverl_data: 'あなたを代理承認者にしている一覧',
      label_digestion_days: '消化率',
      label_estimated_months_of_service: '次回予定勤続月数',
      label_grant_days: '次回付与日数',
      label_header_target_date: '対象日',
      label_less_than_a_day: '日未満',
      label_new_check_password: '新しいパスワード（確認）',
      label_new_password: '新しいパスワード',
      label_old_password: '現在のパスワード',
      label_paid_holiday: '通常年休',
      label_paid_holidays_of_use_this_year: '今年の有給利用日数',
      label_paid_reference_date: '次回有給付与日',
      label_special_paid_holiday: '計画年休',
      label_term_from: '取得開始日',
      label_term_to: '取得終了日',
      label_term_time_from: '取得開始時間',
      label_term_time_to: '取得終了時間',
      labor_insurance_number_text: '36協定の帳票に出力する労働保険番号を入力してください',
      late_application: '遅刻申請',
      late_early_departure_type: '申請区分',
      latlng: '場所',
      layout: 'レイアウト',
      ledger: '台帳',
      legal_overwork: '法定外労働',
      legal_holiday_job: '労働させることができる法定休日における始業及び終業の時刻',
      legal_holiday_overwork: '休日労働',
      license_count: 'ライセンス数',
      limit_job_one_day_minutes: '所定労働時間を超える時間数（任意）',
      limit_job_one_month_minutes: '所定労働時間を超える時間数（任意）',
      limit_job_one_month_minutes_sp: '所定労働時間を超える時間数と休日労働の時間数を合算した時間数（任意）',
      limit_job_one_year_minutes: '所定労働時間を超える時間数（任意）',
      limit_legal_one_day_minutes: '法定労働時間を超える時間数',
      limit_legal_one_month_minutes: '法定労働時間を超える時間数',
      limit_legal_one_month_minutes_sp: '法定労働時間を超える時間数と休日労働の時間数を合算した時間数',
      limit_legal_one_year_minutes: '法定労働時間を超える時間数',
      limit_overwork: '限度時間超過',
      login: 'ログイン',
      login_page: 'ログインページ',
      login_user_info: '[ログイン情報]　会社コード：{companyCode}　会社名：{companyName}　従業員番号：{employeeCode}　氏名：{employeeName}',
      logout: 'ログアウト',
      logout_ryaku: 'ロ',
      lunch: '弁当',
      mail_address: 'メールアドレス',
      mail_address_notset: 'メールアドレスが設定されていません。',
      management_parties_employee_name: '使用者の氏名',
      management_parties_job_title_name: '使用者の職名',
      mar: '3月',
      max_length: '{maxlength}文字まで入力できます',
      max_length_decimal: '整数{integral}桁 小数{decimal}桁まで入力できます',
      max_length_integral: '整数{integral}桁まで入力できます',
      may: '5月',
      minute: '分',
      mitsui: '三井住友',
      mizuho: 'みずほ',
      monday: '月曜日',
      monday_ryaku: '月',
      month: '月',
      monthly: '月分',
      monthly_amount: '月額',
      monthly_check_items: '月次チェック項目',
      months: 'ヶ月',
      national_holiday: '祝日',
      new_add: '新規で登録する',
      new_closing_name: '{item}（新規に作成する場合）',
      new_company: '新規登録',
      non_statutory_working_hours: '法定外労働時間',
      notice_message: 'お知らせがあります[{count}件]',
      not_managed: '管理対象外',
      nov: '11月',
      no_data: 'データなし',
      number_of_enrolled: '在籍人数',
      number_of_legal_holidays_allowed_to_work: '労働させることができる法定休日の日数',
      number_people: '人数',
      oct: '10月',
      office_description: 'Artemis Collierで管理される全ての従業員は必ずひとつの事業所に所属することになります。ここではその事業所を登録します。',
      office_info: '事業所', // 名称変更可能な項目のため、会社登録以外での使用不可
      one_day: '１日',
      one_month: '１箇月',
      one_year: '１年',
      operation: '操作',
      operating_company: '運営会社',
      or: 'または',
      order: '順番',
      order_history_option: '注文履歴オプション',
      owner_mail_address: '契約者メールアドレス',
      paid_annual_account: '有給支給年別勘定',
      paid_holiday_inf: '有給情報',
      paid_holiday_setting: '有給休暇設定',
      partner_account_name: '相手勘定科目名',
      partner_sub_account_name: '相手補助勘定科目名',
      password: 'パスワード',
      payment: '支給',
      payment_amount: '納付額',
      payment_description: 'お支払情報を設定してください。',
      payment_flg: '支払済',
      payment_info: '支払情報',
      payment_salary_item_code: '支給額',
      payslip_number: '件数',
      payslip_explanation: '摘要',
      pending: '審査中',
      pension_fund_contributions_subscription: '厚生年金基金加入有無',
      people: '人',
      privacy_policy: 'プライバシーポリシー',
      qrcode_login: 'QRコード打刻画面はこちら',
      read: '既読',
      reasons_over_work_contents_1: '時間外労働をさせる必要のある具体的事由',
      reasons_over_work_contents_2: '休日労働をさせる必要のある具体的事由',
      reasons_over_work_contents_3: '臨時的に限度時間を超えて労働させることができる場合',
      reference: '（参考）',
      registered_user: '既にご登録済みの方はこちら',
      remark: '備考',
      reservation_transmission: '予約送信',
      retirement: '退社年度',
      retirement_employee_count: '退社人数',
      re_ground: '現在の事由',
      risona: 'りそな',
      role_allocation: '権限を割当',
      role_or_approver: '{item}/承認者',
      route_code: 'ルートコード',
      route_flag: '有',
      route_name: 'ルート名',
      rules: '規則',
      salary: '給与',
      salary_closing: '給与締日',
      salary_increase_simulation_description: '現在の給与明細です。',
      salary_inf: '給与情報',
      salaryItem_detail_payment: '支給項目',
      salaryItem_detail_deduction: '控除項目',
      salaryItem_detail_description1: '支給項目と控除項目を設定します',
      salaryItem_detail_description2: '(1)に対して、以下の計算を行います',
      salaryItem_detail_description3: '計算結果に対する符号を設定します',
      salary_payment_method: '給与区分',
      saturday: '土曜日',
      saturday_ryaku: '土',
      scheduled_transmission_date: '送信予定日',
      screen_search: '目的の画面を検索',
      search_all: '有効期間対象外も検索に含む',
      search_all_employees: '退職者を含む',
      search_bank: '銀行検索',
      search_condition: '＋　検索エリア',
      search_result: '検索結果',
      second: '第二',
      select: '選択',
      selected_bank: '選択された金融機関：',
      select_description: '選択してください',
      send: '送信',
      sending_method: '送信方法',
      send_mail_payslip: '給与明細送信',
      send_mail_bonuspayslip: '賞与明細送信',
      sep: '9月',
      serial_number: '連番',
      show_catch: '受取済',
      show_password: 'パスワードを表示',
      show_payment: '支払済',
      sign: '符号',
      simulation_result: 'シミュレーション結果',
      skip_apply_employee: '申請者を承認から外します',
      smile_mark_normal: 'いつも通り',
      special_provisions_year_count: '限度時間を超えて労働させることができる回数（６回以内に限る。）',
      special_provisions_rate_month: '限度時間を超えた労働に係る割増賃金率',
      special_provisions_rate_year: '限度時間を超えた労働に係る割増賃金率',
      start: '開始',
      start_time: '開始時刻',
      status: '状態',
      sunday: '日曜日',
      sunday_ryaku: '日',
      target: '対象',
      target_amount: '対象額',
      target_employee: '対象者の従業員',
      target_person: '対象者',
      target_year: '対象年',
      target_year_month: '対象年月',
      tax_cost: '住民税',
      team_inf: 'チーム情報',
      term: '期間',
      terms_of_use: 'ご利用規約',
      this_business_year: '今年度',
      this_month: '当月',
      this_year: '当年',
      thursday: '木曜日',
      thursday_ryaku: '木',
      today_stamp_time: '本日の打刻時間',
      total: '合計',
      total_amount: '全額',
      total_amount_of_money: '合計金額',
      total_break_time: '休憩時間合計',
      total_payment_amount: '納付額合計',
      total_time: '合計時間',
      transfer: '振込先',
      transfer_information: '振込先情報',
      transfer_amount_of_money: '振込金額',
      transfer_method: '振込方法',
      tuesday: '火曜日',
      tuesday_ryaku: '火',
      type: '種別',
      type_of_day_monthly_summary: '労働日：{total_working_day}日、休日：{total_holiday}日',
      type_of_day_summary: '労働日：{total_working_day}日、休日：{total_holiday}日、有給休暇奨励日：{total_unknown_day}日、有給休暇の計画的付与：{total_unknown2_day}日、使用者の責に帰す休業日：{total_unknown3_day}日',
      ufj: '三菱UFJ',
      unit_age: '歳',
      unit_byte: 'バイト',
      unit_day: '日',
      unit_days_between: '日間',
      unit_hours: '時間',
      unit_mei: '名',
      unit_minutes: '分',
      unit_months: 'か月',
      unit_people: '人',
      unit_ratio: '%',
      unit_times: '回',
      unit_yen: '円',
      unknown_screen: '不明な画面',
      unread: '未読',
      updated_now: '今すぐ更新',
      upper_group: '上位{item}',
      variable_item_setting: '変動項目　計算式設定',
      violation: '違反',
      warning: '警告',
      warning_after_hours: '時間を超えたら警告',
      warning_after_percent: '％を超えたら警告',
      warning_after_days: '日を超えたら警告',
      warning_after_number: '回を超えたら警告',
      warning_content: '警告内容',
      wednesday: '水曜日',
      wednesday_ryaku: '水',
      week: '第{week}週',
      working_days_5: '日数(週5日)',
      working_days_4: '日数(週4日)',
      working_days_3: '日数(週3日)',
      working_days_2: '日数(週2日)',
      working_days_1: '日数(週1日)',
      working_schedule: '勤務体系',
      work_schedule_info: '勤務体系', // 名称変更可能な項目のため、会社登録以外での使用不可
      work_time: '就業時間',
      year: '年',
      yearly: '年次',
      years: '年間',
      years_of_service: '勤続年数',
      year_check_items: '年度チェック項目',
      yen: '円',
      yucho: 'ゆうちょ',
      // 以下、未翻訳
      after_change: '変更後',
      agreement_parties_employee: '協定当事者の従業員',
      agreement_validity_period: '協定の有効期間',
      any: '任意',
      applicable_number: '該当する番号',
      apply_weekday: '適用対象の曜日',
      authority_setting: '権限設定',
      average_working_hours_in_a_week: '対象期間中の１週間の平均労働時間数',
      before_change: '変更前',
      bonus_pay_slip_name: '賞与支給名称',
      business_address: '事業の所在地',
      business_name: '事業の名称',
      business_type: '業務の種類',
      can_set: '〇',
      cancel_reservation: '予約解除',
      cannot_set: '-',
      new_password: '新しいパスワードを設定',
      consumed_paid_holidays_count: '有給取得日数',
      copy_legal_rule: '既存の36協定書をコピーして作成',
      count_first: '一回目',
      count_second: '二回目',
      count_third: '三回目',
      cumulative_number_of_special_provisions_count: '特別条項累計回数(違反回数)',
      cumulative_time_of_special_provisions_count: '特別条項累計時間(違反回数)',
      day_week: '日 / 週',
      educational: '学歴',
      eighteen_year_old: '満18歳',
      evaluation_period: '査定期間',
      extension_1: '①については{hour}時間ま',
      extension_2: 'で、②については{hour}時間まで',
      extension_hour: '延長することができる時間数',
      extension_hour_holiday: '及び休日労働の時間数',
      forget_password: 'パスワードを忘れた場合',
      industrial_safety_health_pan_error_count: '労働安全衛生法違反回数',
      inoculation_venue: '接種会場',
      job_holiday: '所定休日',
      label_monthly_overwork_minutes: '今月の法定外労働時間（{hour}時間{minutes}分）',
      label_limit_legal_one_month_minutes: '36協定書（{hour}時間{minutes}分）',
      language_search_type1: 'マスタに未登録の予約語を検索します。',
      language_search_type2: '全ての予約語を検索します。',
      law_search: '法令検索',
      legal_holiday_job_1: '労働させることができる法定',
      legal_holiday_job_2: '休日における始業及び終業の時刻',
      legal_holiday_overwork_count: '法定休日労働時間(違反回数)',
      legal_holiday_overwork_days_count: '法定休日労働日数(違反回数)',
      legal_overwork_count: '法定外労働時間(違反回数)',
      less18_workers: '（満18歳未満の者）',
      limit_job: '所定労働時間を',
      limit_job_time: '所定労働時間',
      limit_legal: '法定労働時間を',
      limit_overwork_count_1: '限度時間を超え',
      limit_overwork_count_2: 'て労働させるこ',
      limit_overwork_count_3: 'とができる回数',
      limit_overwork_count_4: '(6回以内に限る。)',
      limit_overwork_time_1_legal: '法定労働時間を超',
      limit_overwork_time_1_job: '法定労働時間を超',
      limit_overwork_time_2: 'える時間数と休日',
      limit_overwork_time_3: '労働の時間数を合',
      limit_overwork_time_4: '算した時間数',
      limit_overwork_one_month: '時間外労働及び休日労働を合算した時間数。100時間未満に限る。',
      limit_overwork_one_year_1: '時間外労働のみの時間数。',
      limit_overwork_one_year_2: '720時間以内に限る。',
      limit_overwork_rate_1: '限度時間を超',
      limit_overwork_rate_2: 'えた労働に係',
      limit_overwork_rate_3: 'る割増賃金率',
      longest_working_days_in_specific_term: '特定期間中の最も長い連続労働日数',
      longest_working_days_in_target_term: '対象期間中の最も長い連続労働日数',
      longest_working_hours_in_a_day: '労働時間が最も長い日の労働時間数',
      longest_working_hours_in_a_week: '労働時間が最も長い週の労働時間数',
      management_parties_employee: '使用者の従業員',
      maximum_consecutive_weeks_with_more_than_48_hours_worked: '労働時間が48時間を超える週の最長連続週数',
      more_than_once: '回以上',
      more_then: '以上の者',
      next_day: '翌日',
      normal_working_time: '通常勤務',
      number_of_legal_holidays_allowed_to_work_1: '労働させることができる',
      number_of_legal_holidays_allowed_to_work_2: '法定休日の日数',
      number_of_inoculations: '接種回数',
      office_type: '事業所の種類',
      old_longest_working_day_working_time: '旧協定の労働時間が最も長い日の労働時間数',
      old_longest_working_week_working_time: '旧協定の労働時間が最も長い週の労働時間数',
      old_term: '旧協定の対象期間',
      old_total_working_days: '旧協定の対象期間中の総労働日数',
      older_than: '才以上',
      other_paper: '（別紙）',
      over_18_target_amount_of_workers: '（満18歳以上の者）',
      over_48_hours_longest_weeks: '労働時間が48時間を超える週の最長連続週数',
      over_48_hours_weeks: '対象期間中の労働時間が48時間を超える週数',
      over_time: '超える時間数',
      over18_workers: '（満18歳以上の者）',
      overtime_work: '時間外労働',
      overtime_work_1: '① 下記②に該当しない労働者',
      overtime_work_2_1: '② １年単位の変形労働時間制',
      overtime_work_2_2: 'により労働する労働者',
      paid_holiday_payment_pan_error_count: '有給取得日数違反回数',
      pay_slip_approved: '承認済',
      pay_slip_unapproved: '未承認',
      procedure_health_1: '限度時間を超えて労働させる労働者に対する健康及び',
      procedure_health_2: '福祉を確保するための措置',
      reasons_over_work_contents_1_1: '時間外労働をさせる',
      reasons_over_work_contents_1_2: '必要のある具体的事由',
      regulatory_grace_exclusion_help: '一部の業種については、上述の時間外労働の上限規制に猶予期間や適用除外規定が設けられており、それぞれの業務業種ごとに次のように規定されています。<br><br>(1)自動車運転の業務：2024年4月より、罰則付きの時間外労働規制の適用。適用後上限時間は年960時間とし、将来的には一般則の上限時間の適用を目指す旨が引き続き検討されています。<br><br>(2)建設事業：2024年4月より、罰則付きの時間外労働規制の適用。適用後上限時間は一般則の通り。ただし、災害時における復旧・復興の事業について例外が設けられ、単月で月100時間未満、連続する2カ月から6カ月平均で月80時間以内とする要件は適用されません。この点についても、将来的には一般則の適用について引き続き検討されます。<br><br>(3)新技術、新商品等の研究開発の業務：専門的、科学的な知識、技術を有する者が従事する新技術、新商品等の研究開発の業務の特殊性が存在するため、対象を明確化し、医師の面接指導、代替休暇の付与等の健康確保措置を設けた上で、適用除外とされています。<br><br>(4)鹿児島県及び沖縄県における砂糖製造業：2024年4月より、罰則付きの時間外労働規制が適用されます。<br><br>(5)医師：2024年4月を目途に規制を適用することとされていますが、詳細は医療界の参加による検討の場で引き続き検討されます。<br><br>',
      remaining: '残り',
      reserve_date_of_sending_pay_slip: '送信予約日',
      select_layout: '{layout}を選択しました。',
      special_number_of_consecutive_working_days: '特定期間中の最も長い連続労働日数',
      specific_content: '具体的内容',
      starting_date: '起算日',
      target_amount_of_workers: '該当労働者数',
      target_number_of_consecutive_working_days: '対象期間中の最も長い連続労働日数',
      tel_no: '電話番号',
      telework_flg: 'テレワーク',
      term_from_ryaku: '入社日[から]',
      term_to_ryaku: '入社日[まで]',
      title_address: '連絡先',
      title_basic_info: '基本情報',
      title_group: '所属',
      title_paid_leave_info: '有給情報',
      title_private_address: '届出住所',
      title_saraly_info: '給与情報',
      title_skill: 'スキル',
      title_time: '時間',
      title_wokring_info: '打刻情報',
      title_yen: '円',
      total_amount_of_workers: '常時使用する労働者数',
      total_time_count: '合計時間(違反回数)',
      total_working_days_in_a_term: '対象期間中の総労働日数',
      transform_labor_time_term: '変形労働時間制による期間',
      transform_labor_time_term_year: '協定の有効期間',
      transformation_monthly: '１箇月単位の変形労働時間制に関する協定届',
      transformation_term: '変形期間',
      transformation_weekly: '1週間単位の非定型的変形労働時間制に関する協定届',
      transformation_yearly: '1年単位の変形労働時間制に関する協定届',
      unit_days: '日',
      unit_price_pan_error_count: '最低労働賃金違反回数',
      unit_stop_at: '止まり',
      unit_weeks: '週',
      unit_year: '年',
      update_employee_ryaku: '更新者',
      vaccination_count: '接種回数',
      vaccination_data: '接種日',
      vaccination_serial_number: '製造番号',
      valid_term_of_application: '協定の有効期間',
      weeks_with_more_than_48_hours_worked: '対象期間中の労働時間が48時間を超える週数',
      work_history: '職歴',
      worker_count: '労働者数',
      working_day_hours_in_a_month: '対象期間中の各日及び各週の労働時間並びに所定休日',
      working_hours_in_a_month: '変形期間中の各日及び各週の労働時間並びに所定休日',
      working_hours_in_a_week: '１週間の所定労働時間',
      ymd: '年月日',
      overtime: '残業時間',
      late_early_departure: '遅刻/早退',
      late_night: '深夜残業',
      paid_holiday: '有給休暇',
      paid_holiday_ryaku: '有休',
      wanna_know: 'が知りたい',
      pie_chart_title: '年代別統計',
      pie_chart_subtitle: '平均年齢 {age}歳',
      generation10: '20歳未満',
      generation20: '20～29歳',
      generation30: '30～39歳',
      generation40: '40～49歳',
      generation50: '50～59歳',
      generation60: '60歳以上',
      old_user_password: '現在のパスワード',
      new_user_password: '新しいパスワード',
      label_break: '休憩',
      smile_mark: 'スマイルマーク',
      work_schedule: '勤務体系',
      api: 'API名',
      difference_amount: '差額',
      increase: '増',
      decrease: '減',
      registered_address: '登録住所',
      header_assign_to_screen: 'この権限で利用可能となる画面を選択',
      header_assign_to_employee: 'この権限を割り当てる従業員を選択',
      label_remaining_monthly_overwork_minutes: '残り時間 {hour}時間{minutes}分',
      tranfer_date_remarks: '{work_date}の休日は出勤し、代わりに{holiday_date}にお休みを頂きます。',
      job_start_end: '始業/終業時間',
      required: '必須',
      paid_holiday_date: '取得日',
      pie_chart_title2: '男女別統計',
      male: '男性',
      female: '女性',
      remaining_paid_holiday: '有給の残り',
      address: '住所',
      unit_count: '件',
      cash_transfer_list: '現金一覧表',
      boss_description: '上司が設定されていないため上司を指定して下さい。上司はメニューの[設定]-[上司]より設定できます。',
      stamping_start_time_correction: '出勤時刻の補正',
      stamping_end_time_correction: '退勤時刻の補正',
      telework_flg_correction: 'テレワークフラグの補正',
      work_schedule_correction: '勤務体系の補正',
      unit_num: '個',
      check_this_day: '当日予約分',
      before_imprint_correction_time: '変更前時刻',
      timeline_work: '所定/法定',
      timeline_overwork: '所定外+法定外',
      timeline_break: '休憩',
      timeline_late: '遅刻・早退',
      target_period_or_specific_period: '対象期間及び特定期間',
      notation_on_law: '特定商取引法に基づく表記',
      residents_card: '添付書類',
      residents_card2: '(住民票等)',
      deputy_approverl_description1: '代理承認者に指定されています。({employeeCode}:{employeeName})',
      deputy_approverl_description2: '代理承認者に指定されています。({employeeCode}:{employeeName}、他{num}名)',
      actual_work_start: '{hour}時{minute}分に出勤します。',
      actual_work_end: '{hour}時{minute}分に退勤します。',
      regular_holiday: '通常年休',
      hourly_annual_holiday: '時間単位年休',
      planned_holiday: '計画年休',
      core_time: 'コアタイム',
      flex_time: 'フレキシブルタイム',
      chat_support: 'チャットサポート時間についてのお知らせ',
      check_no_stamp_only: '打刻忘れのみ抽出',
      paid_payment1: '支給',
      paid_payment2: '失効',
      vaccine: 'ワクチン',
      inoculation: '接種',
      not_processed: '処理されていません',
      full_amount: '全額',
      item_name: '項目名',
      widow: '寡婦',
      single_parent: 'ひとり親',
      is_working_student: '勤労学生',
      no_target: '対象外',
      qualified_employee: '該当者のみ',
      salary_change: '昇(降)給',
      retroactive_payment_amount: '遡及支払額',
      payroll_month1: '月額変更届対象開始月',
      tooltip_shift: 'シフト送信、PDF作成は公開後に利用出来ます。',
      open_or_close_detail_items: '＋　基本項目',
      request_submission_flg: '依頼送信'
    },
    button: {
      ...okubo.ja.button,
      ...takeuchi.ja.button,
      ...miki.ja.button,
      ...googleapis.ja.button,
      accept_start: '受付開始',
      accept_end: '受付終了',
      add: '登録',
      addMonitoringConditions: '監視条件登録',
      add_row: '行追加',
      add_to_dashboard: 'ダッシュボードに追加',
      agree: '賛成',
      allAllocationSelect: '全て割当を選択',
      allAllocationRelease: '全て割当を解除',
      allocation: '割当',
      apply: '申請',
      approve: '承認',
      assign_to_screen: '選択',
      assign_to_employee: '選択',
      auto_input: '自動入力',
      auto_input_with_end_of_month: '月末締めで自動入力',
      back: '戻る',
      bank_transfer_list: '銀行振込一覧表',
      batch_print: '一括ダウンロード',
      break_end: '休憩終了',
      break_start: '休憩開始',
      button_change_password: '変更',
      calc: '計算',
      calculation: '計算',
      cancel: '取り消し',
      cancellation: 'サービスを解約',
      clear: 'クリア',
      close: 'Close',
      closing_attendance_record_close: '締め',
      closing_attendance_record_release: '解除',
      company_add: '会社を追加',
      confirm: '確認',
      copy_company_data: '会社情報の電話番号、ファックス番号、業種を設定する',
      delete: '削除',
      delete_company: '{companyName}を削除',
      delete_row: '行削除',
      deny: '否認',
      detail: '詳細',
      disagree: '反対',
      download: 'ダウンロード',
      draft: '下書き保存',
      drop: '取り下げ',
      duplicate: 'コピーして新規作成',
      employment_insurance_rate: '雇用保険料率の最新化',
      execute: '実行',
      expand: '拡大',
      forced_approve: '強制承認',
      go_payment_page: '支払い画面へ',
      hold: '保留',
      job_start: '出勤',
      job_end: '退勤',
      journal_list: '仕訳データ一覧表',
      judge: '審査',
      login: 'ログイン',
      logout: 'ログアウト',
      lunch: '弁当',
      migrate_from_corpus: 'コーパスから{companyName}を移行',
      move: '移動',
      next: '次へ',
      next_month: '翌月',
      new: '新規に追加します',
      no: 'いいえ',
      order_history: '注文履歴',
      popup_cancel: 'キャンセル',
      postal_code_search: '郵便番号で検索し地図上のピンを変更します',
      postal_code_search_simple: '郵便番号で検索します',
      precede_approve: '先取り承認',
      previous_month: '前月',
      print: 'PDF作成',
      print_all: 'PDF一括作成',
      pull_back: '引き戻し',
      reapply: '再申請',
      reflect_all_months: '上記の内容で全ての月に反映',
      release: '解除',
      reload: '更新',
      remand: '差し戻し',
      reserve: '予約',
      reserve_simple: '注文',
      reset_all_months: '上記の内容で全ての月に反映',
      reset_specific_month: '{month}月のみ反映',
      search: '検索',
      select: '選択',
      send: '送信',
      send_payslip: '給与明細送信',
      send_bonuspayslip: '賞与明細送信',
      show_actual: '実績を表示',
      show_current_ground: '現在の事由を表示',
      skip_approve: '引き上げ承認',
      this_month: '当月',
      understand: '了承しました',
      unread: '未読にする',
      update: '更新',
      upload: 'アップロード',
      view: '確認',
      yes: 'はい',
      // 以下未翻訳
      apply_to: '適用',
      cancel_fix: '解除',
      cancel_reservation: '予約解除',
      clear_all: '一括クリア',
      edit: '編集',
      edit_all: '一括編集',
      fix: '確定',
      reprint: '再印刷',
      show_attendance_record: '出勤簿表示',
      update_credit_card: 'クレジットカード番号更新',
      cash_transfer: '現金一覧表',
      select_file: 'ファイルを選択',
      expand_attendance_record: '詳細',
      shrink_attendance_record: '簡易',
      csv: 'CSV作成',
      update_invoice: '失敗した請求を決済する',
      reflect_not_closed_months: '上記の内容で全ての月に反映',
      force_approve: '強制承認',
      reset: 'リセット',
      create_csv: 'CSV作成',
      create_bonus: '賞与明細書作成',
      batch_printing: 'PDF作成(一括)',
      send_all_select: '送信一括選択',
      send_all_cancel: '送信一括解除',
      cancel_reservation_all_select: '予約解除一括選択',
      cancel_reservation_all_cancel: '予約解除一括解除',
      reflect_above_content: '上記の内容で反映',
      office_master_setting: '事業所マスタ設定',
      add_col: '列追加',
      public: '公開',
      private: '非公開',
      customer_department: '取引先部門',
      supplier_department: '仕入先部門'
    },
    placeholder: {
      ...okubo.ja.placeholder,
      ...takeuchi.ja.placeholder,
      ...miki.ja.placeholder,
      ...googleapis.ja.placeholder,
      account_number: '0000000',
      amount: '12,000',
      append_title: '手書き申請書',
      application_classification_code: '001',
      application_classification_name: '勤怠関連',
      application_form_name: '残業申請',
      application_number: '1000000000',
      basic_pension_number: '00000000000',
      bank_code: '0000',
      branch_code: '000',
      break_end_time: '13:00',
      break_start_time: '12:00',
      break_time_start: '12:00',
      break_time_end: '13:00',
      building: '勤怠商事ビル',
      business_type: '001',
      business_name: '一般職',
      cancel_contents: '対応が不要となったため。',
      city: '千代田区大手町1-99-99',
      closing_code: '001',
      closing: '10',
      closing_name: '10日締め',
      collection_day: '15',
      company_code: '00000001',
      company_name: '勤怠商事株式会社',
      contents: '2021年10月3日(日) 14:00～16:00にメンテナンスのため、サービスを停止致します。\n\nお客様にはご不便をお掛けしますが、何卒ご理解ご協力の程、宜しくお願い致します。',
      contract_renewal_date: '2021-01-01',
      corporate_number: '00000001',
      date_of_collection: '10',
      day: '10',
      dependent_code: '001',
      dependent_count: '3',
      dependent_name: '勤怠 太郎',
      display_date: '2021-01-01',
      division_reservation_date: '2021-01-01',
      elapsed_date: '10',
      emergency_contact: '03-1234-5678',
      employee_classification_code: '01',
      employee_classification_name: '正社員',
      employee_code: '00001',
      employee_name: '勤怠　太郎',
      employment_insurance_date: '2021-01-01',
      employment_insurance_number: '0000000000000',
      end_date: '2021-12-31',
      end_section: '豊橋',
      end_time: '18:00',
      estimated_months_of_service: '18',
      estimated_overtime_hours: '40',
      event: '勤怠大学　経営学部　経営学科 卒業',
      executed_after_approverl: 'https://some-api.com',
      expiration_days: '10',
      expiration_times: '4',
      extension_number: '00001',
      fax: '03-2345-6789',
      felica: '0000000aaa0000aa',
      first_month_cost: '10000',
      fixed_value: '1.25',
      ground_code: '001',
      ground_name: '出勤日',
      group_code: '001',
      group_name: '営業部',
      health_insurance_sign: '00000000',
      health_insurance_no: '00000000',
      hire_date: '2001-04-01',
      hold_days: '10',
      hold_times: '4',
      homepage: 'http://www.kintai-shoji.co.jp',
      home_page: 'http://www.yamada-rice.co.jp',
      input_password: 'パスワードを入力して下さい。',
      job_title_name: '部長',
      integrated_reservation_date: '2021-01-01',
      job_start: '09:00',
      job_end: '18:00',
      password: 'password',
      labor_insurance_number: '01203000009999',
      labor_standards_inspection_office: '名古屋北',
      lat: '35.17565',
      late_early_departure_content: '体調不良のため。',
      late_early_departure_supplement: '現場監督には報告済み',
      layout_code: 'A0001',
      layout_name: 'レイアウト名',
      legal_day: '5',
      legal_holiday_job_end: '18:00',
      legal_holiday_job_start: '9:00',
      legal_percent: '20',
      legal_time: '4',
      limit_job_one_day_minutes: '4',
      limit_job_one_month_minutes: '40',
      limit_job_one_year_minutes: '370',
      limit_legal_one_day_minutes: '3',
      limit_legal_one_month_minutes: '30',
      limit_legal_one_year_minutes: '250',
      lng: '136.90979',
      local_government_code: '102016',
      lunch_name: '日替わり弁当',
      mail_address: 't.kintai@kintai-shoji.co.jp',
      message: 'メッセージ',
      my_number: '000000000000',
      need_to_exceed: '繁忙期のため',
      notice_number: '100',
      notification: 'メンテナンスのお知らせ',
      notification_display_date_from: '2021-01-01',
      notification_display_date_to: '2021-12-31',
      notification_transmission_date: '2021-01-01',
      number_of_legal_holidays_allowed_to_work: '1',
      number_of_working_days_per_week: '5',
      office_code: '001',
      office_name: '本社',
      other: 'その他',
      overtime_content: 'テスト仕様書作成',
      overtime_reason: '本日納期のタスクを完了させるため。',
      payment_date: '2022-04-01',
      payment_days: '10',
      payment_times: '4',
      pension_fund_contributions_date: '2021-01-01',
      place_code: '001',
      place_name: '本社',
      post_code: '100-9999',
      postal_code: '100-9999',
      price: '500',
      procedure_of_exceed_the_time_limit: '労働者代表者に対する事前申し入れ',
      purchase_date: '2021-07-01',
      quantity: '1',
      reasons_over_work_contents: '受注の集中',
      reason_for_retirement: '退職理由',
      reference_number: '0001',
      role_code: '001',
      role_name: '一般',
      rounding_term: '15',
      rounding_term_over_work: '30',
      route_code: '001',
      route_name: '営業ルート',
      salary_closing_name: '2021年給与締め日',
      salary_closing: '10',
      salary_item_name: '基本給',
      social_insurance_date: '2021-01-01',
      sort_number: '1',
      special_provisions_rate_month: '35',
      special_provisions_rate_year: '35',
      special_provisions_year_count: '3',
      specific_content: '対象労働者への医師による面接指導の実施',
      start_date: '2021-01-01',
      start_section: '名古屋',
      start_time: '09:00',
      supplier_name: '勤怠米店',
      target_date: '2021',
      task_name: '打ち合わせ',
      team_code: '001',
      team_name: '営業チーム',
      tel: '03-1234-5678',
      term_from: '2021-01-01',
      term_to: '2029-12-31',
      time: '40',
      town: '大手町1-99-99',
      transfer_amount_of_money: '15,000',
      transportation_name: 'JR勤怠',
      unit_price: '500,000',
      unit_price2: '500',
      work_schedule_code: '001',
      work_schedule_name: '本社',
      working_system_abbreviation: '本社',
      // 以下未翻訳
      abbreviated_name: '言語',
      address_change_supplement: '引っ越ししたため。',
      age: '40',
      agreement_date: '2022/01/01',
      bonus_pay_slip_name: '夏季賞与',
      choice_screen_code: '001',
      commuting_route_change_supplement: '引っ越ししたため。',
      contents2: '打刻した時間を確認できます。',
      field_name: 'language',
      filing_date: '2022/01/01',
      icon_name: 'ti-01-001',
      inoculation_venue: '',
      keyword: 'タイムカード',
      label_name: '言語',
      limit_legal_one_weeks_minutes: '10',
      menu_code: '01',
      number_of_inoculations: '2',
      personal_information_change_supplement: '入籍したため。',
      release_version: '1',
      screen_name: 'タイムカード',
      serial_number: '',
      skill: '勤怠管理1級取得',
      starting_date: '2022/01/01',
      vue_path: 'Menu01Screen001.vue',
      working_interval: '10',
      limit_exemption_working_interval: '12',
      api: 'RestfulApiInitLunchOrderResultList',
      help1: 'https://linkpoint.zendesk.com/hc/ja/articles/900005536066-従業員画面の使い方',
      help2: 'https://linkpoint.zendesk.com/hc/ja/articles/4408466684185-基本情報画面の使い方',
      help3: 'https://linkpoint.zendesk.com/hc/ja/articles/4408466696217-経歴情報画面の使い方',
      help4: 'https://linkpoint.zendesk.com/hc/ja/articles/4408458928409-部署情報画面の使い方',
      help5: 'https://linkpoint.zendesk.com/hc/ja/articles/4408466707097-チーム情報画面の使い方',
      help6: 'https://linkpoint.zendesk.com/hc/ja/articles/4408458321177-社会保険画面の使い方',
      help7: 'https://linkpoint.zendesk.com/hc/ja/articles/4408467527577-雇用保険画面の使い方',
      help8: 'https://linkpoint.zendesk.com/hc/ja/articles/4408466884121-住民税画面の使い方',
      help9: 'https://linkpoint.zendesk.com/hc/ja/articles/4408466886937-給与情報画面の使い方',
      help10: 'https://linkpoint.zendesk.com/hc/ja/articles/4408458647705-扶養家族画面の使い方',
      help11: 'https://linkpoint.zendesk.com/hc/ja/articles/4408458652825-有給休暇画面の使い方',
      help12: 'https://linkpoint.zendesk.com/hc/ja/articles/4408467060889-申請情報画面の使い方',
      help13: 'https://linkpoint.zendesk.com/hc/ja/articles/4408459108505-勤務体系画面の使い方',
      help14: 'https://linkpoint.zendesk.com/hc/ja/articles/4408617649305-従業員更新確認画面の使い方',
      help15: 'https://linkpoint.zendesk.com/hc/ja/articles/900005536066-従業員画面の使い方',
      message_id: 'MSG-N001',
      correspondence_action: '内容をご確認下さい。',
      recruitment_category_name: '新卒採用',
      company_japanese_name: 'キンタイショウジカブシキガイシャ',
      company_english_name: 'Kintaishoji Co., Ltd',
      company_name_abbreviation: '勤怠商事',
      department_name: '営業部門',
      position: '部長',
      product_name: '仕様変更対応',
      product_japanese_name: 'シヨウヘンコウタイオウ',
      product_english_name: 'Specification change',
      subject: '追加開発'
    },
    help: {
      ...okubo.ja.help,
      ...takeuchi.ja.help,
      ...miki.ja.help,
      ...googleapis.ja.help,
      work_schedule_code: 'Artemis Collierでは、9:00-18:00勤務、18:00-23:00勤務など、複数の勤務体系を使った打刻が可能です。それらの勤務体系を識別するために必要となります。コードは10文字以内で自由に決めてください。(例) EIGYO , HONSYA, RINJI 等',
      work_time: '09:00の形式で入力してください。',
      is_job_before_start_time: '出勤の打刻をしてから始業時間が始まるまでの時間も勤務時間に含める場合、「含む」を選択してください。',
      break_time: '(例) 12:00～13:00',
      closing_name: '勤怠の締め日を識別するために必要となります。締日名には、「10日締め」「月末締め」など、管理しやすい名前を入力してください',
      target_year: '締日のマスタを作成します。作成する年度を入力して下さい。',
      office_code: '事業所を識別するために必要となります。コードは10文字以内で自由に決めてください。(例) HONSYA , NAGOYA, 0000000001,  等',
      reference_number: '健康保険の事業所整理記号となります。不明の場合、または保険に加入していない場合は入力は省略できます。',
      special_measures: '労働基準法では「1日8時間、週40時間」を法定労働時間として定めていますが、下記に該当する事業場については「1日8時間、週44時間」まで労働させることが可能となっています。 この事業場を「特例措置対象事業場」と呼びます。※常時使用する労働者が10名以内の事業場のみ',
      employee_code: '従業員を識別するために必要となります。会社で採番している社員番号、等を入力して下さい。コードは10文字以内で自由に決めてください。'
    },
    movie: {
      ...okubo.ja.movie,
      ...takeuchi.ja.movie,
      ...miki.ja.movie,
      ...googleapis.ja.movie,
      company_regist: 'https://www.youtube.com/watch?v=5VkDhV5DEEQ&t=15s',
      boss: 'https://www.youtube.com/watch?v=l3oI15sUrbE',
      salary_mst: 'https://www.youtube.com/watch?v=t2lmyIaozDY',
      imprint_correction: 'https://www.youtube.com/watch?v=XMtYBYqEofQ'
    }
  }
}
