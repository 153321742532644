<template>
  <div>
    <div style="margin-bottom: 10px;" v-if="isDialog">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="$emit('close')">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
    </div>
    <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
    <div>
      <iframe style="border: none; margin: 0 0 0 5px;" width="270" height="240" :src="`${clockUrl}`"></iframe>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    props: {
      isDialog: {
        type: Boolean,
        require: false,
        'default': false
      }
    },
    data () {
      return {
        componentKey: 0,
        labels: {},
        clockUrl: '/static/clock_mini/index.html?isjob=1&width=240'
      }
    },
    methods: {
      init () {
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style scoped>
</style>
