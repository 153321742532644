<template>
  <div style="height: 920px;">
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="applicationClassificationDetail" :draggable="true" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
        <div class="modal-body">
          <ApplicationClassificationDetail :mode="mode" :params="params" @close="classificationHide"/>
        </div>
      </modal>
    </div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame" style="overflow-y: auto; height: 750px;"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.application_form_name"
              item-name="application_form_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.application_form_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.application_form_name')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationSelect
              v-model="detail.application_classification_code"
              :item-label="labels.applicationClassification"
              :options="comboData.application_classification_format_list"
              classes="length20"
              rules="required"
              :disabled="isReadOnly"
            />
            <ValidationRadio
              v-model="detail.skip_apply_employee"
              rules="required"
              :item-label="labels.skip_apply_employee"
              :disabled="isReadOnly"
              data-v-step="2"
            />
            <ValidationRadio
              v-model="detail.pulling_flag"
              rules="required"
              :item-label="labels.pulling_flag"
              :disabled="isReadOnly"
              data-v-step="2"
            />
            <ValidationText
              v-model="detail.sort_number"
              item-name="sort_number"
              max-length="5"
              classes="form-control numLength5 text-right"
              rules="required"
              :item-label="labels.sort_number"
              :placeholder="isReadOnly ? '' : $t('placeholder.sort_number')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.table_name"
              item-name="table_name"
              max-length="50"
              classes="form-control numLength50"
              rules="required"
              :item-label="labels.table_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.table_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationText
              v-model="detail.screen_code"
              item-name="screen_code"
              max-length="6"
              classes="form-control numLength6"
              rules="required"
              :item-label="labels.screen_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.screen_code')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ApplicationFormDetailMixin from '@/components/Dashboard/Views/Operation/ApplicationFormDetailMixin'

  export default {
    name: 'ApplicationFormDetail',
    data () {
      return {
        errMsg: '',
        modalWidth: 800
      }
    },
    methods: {
    },
    mixins: [ApplicationFormDetailMixin]
  }
</script>
<style scoped>
</style>
