<template>
  <div class="wrapper">

    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" :title="$store.state.loginUser.companyName">
      <user-menu></user-menu>
      <form class="navbar-form navbar-left navbar-search-form navbar-search-form-mobile" role="search">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input type="text" value="" class="form-control" placeholder="Search...">
        </div>
      </form>
      <mobile-menu></mobile-menu>
    </side-bar>

    <div class="main-panel" ref="mainPanel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar" :key="$route.fullPath">

      </dashboard-content>
      <div id="page_top" v-if="isEnabled"><a href="#" @click.prevent.stop="scrollTop" @touchstart.prevent.stop="scrollTop"></a></div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import MobileMenu from 'src/components/UIComponents/SidebarPlugin/MobileMenu.vue'

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      UserMenu,
      MobileMenu
    },
    data () {
      return {
        isEnabled: false
      }
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      scrollTop () {
        this.$refs.mainPanel.scrollTop = 0
      },
      handleScroll () {
        this.isEnabled = this.$refs.mainPanel.scrollTop > 100
      }
    },
    mounted () {
      this.$refs.mainPanel.addEventListener('scroll', this.handleScroll)
    }
  }

</script>
<style scoped>
#page_top{
  width: 90px;
  height: 90px;
  position: fixed;
  left: 280px;
  bottom: 0;
  opacity: 0.6;
  z-index: 99;
}
#page_top a{
  position: relative;
  display: block;
  width: 90px;
  height: 90px;
  text-decoration: none;
}
#page_top a::before{
  font-family: 'FontAwesome';
  font-weight: 900;
  content: '\f102';
  font-size: 25px;
  color: #3f98ef;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -40px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
#page_top a::after{
  content: 'PAGE TOP';
  font-size: 13px;
  color: #fff;
  position: absolute;
  top: 45px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  color: #3f98ef;
}
</style>