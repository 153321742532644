<template>
  <div class="card">
    <div class="card-content">
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.confirm_day}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationDate
            v-model="application.target_date"
            item-name="target_date"
            rules="required"
            :itemLabel="labels.confirm_day"
            placeholder="2020-01-01"
            :disabled="(isReadOnly || application.is_fixed) && isModal"
            @input="$emit('initApply', application.target_date)"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.ground}}</label>
        </div>
        <ValidationSelect
          v-model="application.ground_code"
          item-name="ground_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          rules="required"
          :item-label="labels.ground"
          :options="comboData.workflow_ground_code_list"
          :disabled="isReadOnly"
        />
      </div>
      <div class="form-group" :key="componentKey">
        <div class="length5" style="display: inline-block;">
          <label>{{$t('label.re_ground')}}</label>
        </div>
        <div style="display: inline-block; margin-right: 20px;" v-if="application.actual_ground">
          {{application.actual_ground}}
        </div>
      </div>
      <!-- <div class="form-group">
        <div class="length5" style="display: inline-block;">
          <label>{{labels.workSchedule}}</label>
        </div>
        <ValidationSelect
          v-model="application.work_schedule_code"
          item-name="work_schedule_code"
          :placeholder="$t('label.select_description')"
          classes="length20"
          :item-label="labels.workSchedule"
          :options="comboData.work_schedule_list"
          :disabled="isReadOnly || application.is_fixed"
        />
      </div> -->
      <div class="form-group">
        <div class="length5" style="display: inline-block; vertical-align: top;">
          <label>{{labels.contents}}</label>
        </div>
        <div style="display: inline-block; margin: 0; padding: 0;">
          <ValidationTextArea
            v-model="application.supplement"
            item-name="supplement"
            max-length="100"
            classes="form-control width480"
            :item-label="labels.contents"
            :placeholder="isReadOnly ? '' : $t('placeholder.overtime_reason')"
            :disabled="isReadOnly"
            :rows="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {DatePicker} from 'element-ui'
  import mixin from '@/lib/mixin'
  import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextAreaHorizontal'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
  import ValidationDate from '@/components/UIComponents/Inputs/ValidationDateHorizontal'

  export default{
    mixins: [mixin],
    components: {
      'el-date-picker': DatePicker,
      ValidationTextArea,
      ValidationSelect,
      ValidationDate
    },
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {}
      }
    },
    watch: {
      value () {
        this.application = this.value
      }
    },
    mounted () {
      this.application = this.value
    }
  }
</script>
<style scoped>
</style>
