<template>
  <div>
    <div class="row" style="overflow-y: auto; height: calc(100vh - 364px);" :key="componentKey">
      <div class="col-md-12">
        <div style="margin-bottom: 10px">{{$t('label.payment_description')}}</div>
        <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        <MessageArea :page-type="pageType.search" :message="paymentMessage" :placeholders="[pageTitle]"/>
        <div style="color: red;">{{error}}</div>
        <div class="card">
          <div class="card-content">
            <ValidationObserver ref="observer">
              <ValidationSelect
                v-model="licenseCount"
                :item-label="$t('label.license_count')"
                :options="options"
                :disabled="false"
              />
              <ValidationText
                v-model="email"
                item-name="email"
                max-length="100"
                classes="form-control width300"
                rules="required|mailaddress"
                :disabled="true"
                :item-label="$t('label.owner_mail_address')"
              />
            </ValidationObserver>
          </div>
          <div class="card-footer">
            <br>
            <button type="button" class="btn btn-sm btn-default btn-warning" @click="goPaymentPage">
              {{$t('button.go_payment_page')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ValidationText from '@/components/UIComponents/Inputs/ValidationText'
  import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationText,
      ValidationSelect
    },
    data () {
      return {
        componentKey: 0,
        error: '',
        licenseCount: '10',
        options: [
          {label: '10', value: '10'},
          {label: '15', value: '15'},
          {label: '20', value: '20'},
          {label: '25', value: '25'},
          {label: '30', value: '30'},
          {label: '35', value: '35'},
          {label: '40', value: '40'},
          {label: '45', value: '45'},
          {label: '50', value: '50'},
          {label: '55', value: '55'},
          {label: '60', value: '60'},
          {label: '65', value: '65'},
          {label: '70', value: '70'},
          {label: '75', value: '75'},
          {label: '80', value: '80'},
          {label: '85', value: '85'},
          {label: '90', value: '90'},
          {label: '95', value: '95'},
          {label: '100', value: '100'}
        ],
        email: '',
        paymentMessage: {}
      }
    },
    methods: {
      goPaymentPage () {
        let paymentInfo = {
          licenseCount: this.licenseCount,
          email: this.email
        }
        localStorage.setItem('paymentInfo', JSON.stringify(paymentInfo))
        window.open('/#/migration/waiting', 'checkout', 'width=900,height=600')
      },
      init () {
        const mailAddress = sessionStorage.getItem('mailAddress')
        this.email = mailAddress
        const request = {
          mail_address: mailAddress
        }
        this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/corporate_user/init/corporate_payment`, request)
        .then(res => {
          this.paymentMessage = this.message
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>
<style>
</style>
