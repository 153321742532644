<template>
  <div class="row" ref="frame">
    <div class="col-md-12">
      <form method="#" action="#">
        <div>
          <button type="button" class="btn btn-sm btn-default btn-danger" @click="close">
            <span class="btn-label"><i class="ti-close"></i></span>
            {{$t('button.close')}}
          </button>
        </div>
        <div style="margin: 10px 0;" v-if="mode===modes.add || mode===modes.edit">
          <button type="button" class="btn btn-sm btn-default btn-warning" @click="editAll">{{$t('button.edit_all')}}</button>
          <button type="button" class="btn btn-sm btn-default btn-primary" @click="clearAll">{{$t('button.clear_all')}}</button>
        </div>
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                :data="tableData"
                style="width: 100%;"
                max-height="600"
                :key="componentKey"
        >
          <el-table-column :width="100"
                            fixed="left"
                            v-if="mode===modes.add || mode===modes.edit"
                            :label="$t('label.operation')">
            <template slot="header" slot-scope="scope">
              <div class="phase-header tableHeader">
                <div class="phase-header__title">{{scope.column.label}}</div>
              </div>
            </template>
            <template slot-scope="props">
              <a class="btn btn-simple btn-icon edit" @click="edit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.edit')"></i></a>              
              <a class="btn btn-simple btn-icon eraser" @click="clear(props.row)"><i class="ti-eraser" :title="$t('button.clear')"></i></a>              
            </template>
          </el-table-column>
          <el-table-column width="76" :label="$t('label.date')">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                <span class="weekday">
                  {{getDispDay(props.row.target_date.replaceAll('/', '-'))}}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" :label="labels.ground">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="margin-top: 1px;">
                {{ props.row.ground_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="55" :label="$t('label.job_start')">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.job_start}}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="55" :label="$t('label.job_end')">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.job_end}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="60" :label="$t('label.break_time')">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: left; white-space: normal;">
                {{ props.row.break_minutes }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </form>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import moment from 'moment'
  import mixin from '@/lib/mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default{
    mixins: [mixin],
    components: {
      PPagination
    },
    props: {
      mode: Number,
      params: Object
    },
    computed: {
    },
    data () {
      return {
        componentKey: 0,
        detailHeaders: {},
        tableData: [],
        labels: {},
        comboData: {},
        isReadOnly: false
      }
    },
    methods: {
      edit (index, row) {
        row.labels = this.params.labels
        row.combo_data = this.params.combo_data
        this.$emit('edit', row)
      },
      editAll () {
        const params = {}
        params.labels = this.params.labels
        params.combo_data = this.params.combo_data
        this.$emit('edit', params)
      },
      clear (row) {
        row.ground_code = ''
        row.ground_name = ''
        row.job_start = ''
        row.job_end = ''
        row.break_time_list = []
        row.break_minutes = ''
        this.componentKey += 1
      },
      clearAll () {
        for (const row of this.tableData) {
          this.clear(row)
        }
      },
      getDispDay (date) {
        return moment(date).format('M/D(ddd)')
      },
      close () {
        this.$emit('close', this.tableData)
      }
    },
    created () {
      moment.locale(localStorage.getItem('language'))
      this.labels = this.params.labels
      this.tableData = this.params.work_schedule_list
    }
  }
</script>
<style>
</style>
