<template>
  <div class="card" :key="componentKey">
    <template v-if="errMsgs.length > 0">
      <div class="card-header">
        <small style="color: #C84513; margin-left: 5px;">{{errMsgs.length > 0 ? errMsgs[0] : ''}}</small>
      </div>
    </template>
    <div class="card-content" v-if="!application.hasInitData">
      <div class="form-group">
        <div style="display: inline-block; vertical-align: top;">
          <label>{{$t('label.target_year_month')}}</label>
          &nbsp;
          <a
            class="btn btn-simple btn-info"
            style="padding: 0; color: blue; text-decoration: underline;"
            @click="$emit('showAttendanceRecord')"
          >{{application.year ? application.year + $t('label.year') + application.month + $t('label.month') : ''}}</a>
          &nbsp;
          &nbsp;
        </div>
        <div style="display: inline-block;">
          <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                    :data="application.working_data"
                    style="width: 100%">
            <el-table-column min-width="110" :label="headers.employee">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <div style="text-align: left;">
                  <font size="3"><b>{{props.row.employee_name}}</b></font>
                </div>
                <div style="text-align: left;">
                  <small>{{props.row.employee_code}}</small>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.real_total_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.real_total_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.real_total_minutes)}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.job_total_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.job_total_minutes===0">
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.job_total_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.job_total_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small></b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.job_total_minutes)}}
                  </div>
                  <div style="text-align: right;">
                    <small>{{props.row.canAdd ? '' : props.row.job_total_days + $t('label.day')}}</small>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.job_overwork_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.job_overwork_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="overtime && props.row.job_overwork_minutes > 0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.job_overwork_minutes)}}
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.legal_job_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.legal_job_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_job_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.legal_job_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_job_minutes)}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.legal_overwork_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.legal_overwork_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="overtime && props.row.legal_overwork_minutes > 0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_overwork_minutes)}}
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.late_night_overwork_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.late_night_overwork_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="lateNight && props.row.late_night_overwork_minutes > 0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}</b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.late_night_overwork_minutes)}}
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.break_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.break_minutes===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.break_minutes)}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.break_minutes)}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.break_minutes)}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.late_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.late_minutes===0">
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.late_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.late_days + $t('label.count')}}</small>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.late_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.late_days + $t('label.count')}}</small></b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="lateEarlyDeparture && props.row.late_minutes > 0">
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.late_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.late_days + $t('label.count')}}</small></b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.late_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.late_days + $t('label.count')}}</small>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.early_departure_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.early_departure_minutes===0">
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.early_departure_days + $t('label.count')}}</small>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.early_departure_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.early_departure_days + $t('label.count')}}</small></b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="lateEarlyDeparture && props.row.early_departure_minutes > 0">
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.early_departure_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.early_departure_days + $t('label.count')}}</small></b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.early_departure_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.early_departure_days + $t('label.count')}}</small>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="75" :label="headers.lack_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.lack_minutes)}}
                  </div>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispMinute(props.row.lack_minutes)}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.job_holiday_hours">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.job_holiday_hours===0">
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}</small>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}</small></b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="overtime && props.row.job_holiday_hours > 0">
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}</small></b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.job_holiday_hours)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.job_holiday_days + $t('label.day')}}</small>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.legal_holiday_overwork_minutes">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.legal_holiday_overwork_minutes===0">
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}</small>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}</small></b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="overtime && props.row.legal_holiday_overwork_minutes > 0">
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}</b></font>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b><small>{{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}</small></b></font>
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : formatDispMinute(props.row.legal_holiday_overwork_minutes)}}
                    </div>
                    <div style="text-align: right;">
                      <small>{{props.row.canAdd ? '' : props.row.legal_holiday_overwork_days + $t('label.day')}}</small>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="$t('label.paid_holiday_ryaku')">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <div :style="props.row.paid_holiday_days > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.canAdd ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div :style="props.row.paid_holiday_hours > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.canAdd ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
                <template v-else>
                  <div :style="paidHoliday && props.row.paid_holiday_days > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.canAdd ? '' : props.row.paid_holiday_days ? props.row.paid_holiday_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div :style="paidHoliday && props.row.paid_holiday_hours > 0 ? 'text-align: right; font-size: medium; font-weight: bold;' : 'text-align: right;'">
                    {{props.row.canAdd ? '' : parseInt(props.row.paid_holiday_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="70" :label="headers.compensatory_holiday_days">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <template v-if="props.row.compensatory_holiday_days===0">
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      {{props.row.canAdd ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}
                    </div>
                  </template>
                  <template v-else>
                    <div style="text-align: right;">
                      <small>&nbsp;</small>
                    </div>
                    <div style="text-align: right;">
                      <font size="3"><b>{{props.row.canAdd ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}</b></font>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : props.row.compensatory_holiday_days + $t('label.unit_days')}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.child_time_leave_hours_ryaku">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <div style="text-align: right;" class="attendance-text">
                    {{props.row.canAdd ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div style="text-align: right;" class="attendance-text">
                    {{props.row.canAdd ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
                <template v-else>
                  <div style="text-align: right;" class="attendance-text">
                    {{props.row.canAdd ? '' : props.row.child_time_leave_days ? props.row.child_time_leave_days + $t('label.unit_days') : '0' + $t('label.unit_days')}}
                  </div>
                  <div style="text-align: right;" class="attendance-text">
                    {{props.row.canAdd ? '' : parseInt(props.row.child_time_leave_hours / 60, 10).toLocaleString() + $t('label.unit_hours')}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.estimated_overtime_hours">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <div :style="props.row.estimated_overtime_hours===0 ? 'text-align: right;' : 'text-align: right; font-size: medium; font-weight: bold;'">
                    <small>&nbsp;</small>
                  </div>
                  <div :style="props.row.estimated_overtime_hours===0 ? 'text-align: right;' : 'text-align: right; font-size: medium; font-weight: bold;'">
                    {{props.row.canAdd ? '' : formatDispHour(props.row.estimated_overtime_hours)}}
                  </div>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : formatDispHour(props.row.estimated_overtime_hours)}}
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column min-width="60" :label="headers.closed_days">
              <template slot="header" slot-scope="scope">
                <div class="phase-header tableHeader">
                  <div class="phase-header__title"><span v-html="breakLabel(scope.column.label, 1)"></span></div>
                </div>
              </template>
              <template slot-scope="props">
                <template v-if="isPersonal">
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : props.row.closed_days + $t('label.unit_days')}}
                  </div>
                </template>
                <template v-else>
                  <div style="text-align: right;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: right;">
                    {{props.row.canAdd ? '' : props.row.closed_days + $t('label.unit_days')}}
                  </div>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    props: {
      value: Object,
      comboData: Object,
      labels: {
        type: Object,
        default: {}
      },
      errMsgs: Array,
      isReadOnly: Boolean,
      isModal: Boolean
    },
    data () {
      return {
        componentKey: 0,
        application: {},
        headers: {},
        isPersonal: true
      }
    },
    watch: {
      value () {
        this.application = this.value
        this.headers = this.labels
      }
    },
    mounted () {
      this.application = this.value
      this.headers = this.labels
    }
  }
</script>
<style scoped>
</style>
