<template>
  <div class="row">
    <div class="col-md-12">
      <form method="#" action="#">
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{$t('label.shift_number_of_employees_setting')}}
            </h4>
          </div>
          <div class="card-content">
            <ValidationObserver ref="observer">
            <div class="row">
              <div class="col-sm-12">
                <!-- 人数 -->
                <ValidationTextNumber
                  v-model="detail.number_of_employees"
                  :item-label="$t('label.shift_number_of_employees')"
                  integral="2"
                  classes="form-control length2"
                  rules="required|min_value:0"
                  placeholder="2"
                  :disabled="false"
                  :unit="$t('label.unit_people')"
                />
              </div>
              <div class="col-sm-12">
                <br><br>
                <ValidationCheckboxes
                  v-model="detail.apply_weekday"
                  :item-label="$t('label.apply_weekday')"
                  :show-check-all="true"
                />
              </div>
              <div class="col-sm-12" style="margin-top: 20px;">
                <div style="text-align: right;">
                  <button type="button" class="btn btn-sm btn-default btn-primary" @click="apply">{{$t('button.apply_to')}}</button>
                </div>
              </div>
            </div>
            </ValidationObserver>
          </div>  
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import ValidationCheckboxes from '@/components/UIComponents/Inputs/ValidationCheckboxes'
  import ValidationTextNumber from '@/components/UIComponents/Inputs/ValidationTextNumber'
  import mixin from '@/lib/mixin'

  export default{
    mixins: [mixin],
    components: {
      ValidationCheckboxes,
      ValidationTextNumber
    },
    props: {
      labels: Object,
      comboData: Object,
      workScheduleList: Array
    },
    data () {
      return {
        detail: {
          number_of_employees: '',
          apply_weekday: {
            color: 'gray',
            disabled: false,
            items: []
          },
          changed: false
        },
        shiftPatternList: []
      }
    },
    methods: {
      apply () {
        this.$refs.observer.validate().then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registrationForm, false)
            return
          }

          this.detail.changed = true
          this.$emit('close', this.detail)
        })
      },
      doClose () {
        this.$emit('close', this.detail)
      }
    },
    created () {
      const items = []
      items.push({
        id: 2,
        label: this.$t('label.monday_ryaku'),
        value: false
      })
      items.push({
        id: 3,
        label: this.$t('label.tuesday_ryaku'),
        value: false
      })
      items.push({
        id: 4,
        label: this.$t('label.wednesday_ryaku'),
        value: false
      })
      items.push({
        id: 5,
        label: this.$t('label.thursday_ryaku'),
        value: false
      })
      items.push({
        id: 6,
        label: this.$t('label.friday_ryaku'),
        value: false
      })
      items.push({
        id: 7,
        label: this.$t('label.saturday_ryaku'),
        value: false
      })
      items.push({
        id: 1,
        label: this.$t('label.sunday_ryaku'),
        value: false
      })
      this.detail.apply_weekday = {
        color: 'gray',
        disabled: false,
        items: items
      }
    }
  }
</script>
<style>
</style>
