<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div>
        <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
          <span class="btn-label"><i class="ti-close"></i></span>
          {{$t('button.close')}}
        </button>
        <button class="btn btn-sm btn-default btn-icon btn-fill" style="margin-left: 10px;" @click="replay" :title="$t('label.quick_tour')">
          <i class="ti-help"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.work_schedule_code"
              item-name="work_schedule_code"
              max-length="10"
              classes="form-control length10"
              rules="required"
              :item-label="labels.work_schedule_code"
              :placeholder="isReadOnly ? '' : $t('placeholder.work_schedule_code')"
              :disabled="isPrimaryKeyReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="0"
            />
            <ValidationText
              v-model="detail.work_schedule_name"
              item-name="work_schedule_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.work_schedule_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.work_schedule_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="1"
            />
            <ValidationText
              v-model="detail.working_system_abbreviation"
              item-name="working_system_abbreviation"
              max-length="4"
              classes="form-control length8"
              rules="required"
              :item-label="labels.working_system_abbreviation"
              :placeholder="isReadOnly ? '' : $t('placeholder.working_system_abbreviation')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              data-v-step="2"
            />
            <ValidationSelect
              v-model="detail.working_system_type"
              item-name="working_system_type"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.working_system_type"
              :options="comboData.working_system_type_list"
              :disabled="isReadOnly"
              @change="workingSystemTypeChanged"
              data-v-step="3"
            />
            <ValidationRadio
              v-model="detail.is_job_before_start_time"
              rules="required"
              :item-label="labels.is_job_before_start_time"
              :disabled="isReadOnly"
              data-v-step="4"
              v-show="!showFlex"
            />
            <ValidationStartEndTime
              v-model="detail.work_time"
              item-name="work_time"
              max-length="5"
              classes="form-control length5"
              rules="isStartTime|isEndTime"
              :item-label="$t('label.work_time')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="!showFlex"
              data-v-step="5"
            />
            <ValidationStartEndTime
              v-model="detail.core_time"
              item-name="core_time"
              max-length="5"
              classes="form-control length5"
              rules="isFlexStartTime|isFlexEndTime"
              :item-label="$t('label.core_time')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="showFlex"
            />
            <ValidationStartEndTime
              v-model="detail.flex_time"
              item-name="flex_time"
              max-length="5"
              classes="form-control length5"
              rules="isFlexStartTime|isFlexEndTime"
              :item-label="$t('label.flex_time')"
              :placeholder1="isReadOnly ? '' : $t('placeholder.start_time')"
              :placeholder2="isReadOnly ? '' : $t('placeholder.end_time')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="showFlex"
            />
            <div class="form-group" data-v-step="6">
              <div>
                <label>{{ $t('label.fixed_break_time_label') }}</label>
              </div>
              <span style="font-weight: bold;">{{ $t('label.range_break_time_label') }}</span>
              <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isReadOnly" @click="addStartEndTextRow" v-show="mode==modes.add || mode==modes.edit">{{$t('button.add_row')}}</button>
              <div style="color: #C84513; margin-bottom: 5px;" v-for="error in breakTimeListErrors" v-bind:key="error">
                <small>{{error}}</small>
              </div>
              <el-table
                border
                :data="detail.break_time_list"
                :empty-text="$t('label.no_data')"
                style="width: 330px">
                <el-table-column
                  width="60"
                  :label="$t('label.delete')"
                  align="center">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <input type="checkbox" v-model="props.row.selected" class="gray" :disabled="isReadOnly"/>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150"
                  :label="labels.break_time_list ? labels.break_time_list : $t('label.break_time')"
                  align="center">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <input type="checkbox" v-model="props.row.start_time_next" class="gray" :id="'startNextDay' + props.$index" :disabled="isReadOnly">
                      <label :for="'startNextDay' + props.$index">{{$t('label.next_day')}}</label>
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        :placeholder="isReadOnly ? '' : $t('placeholder.break_time_start')"
                        v-model="props.row.start_time">
                      <label>～</label>
                      <input type="checkbox" v-model="props.row.end_time_next" class="gray" :id="'endNextDay' + props.$index" :disabled="isReadOnly">
                      <label :for="'endNextDay' + props.$index">{{$t('label.next_day')}}</label>
                      <input
                        type="text"
                        class="input-text"
                        style="padding-right: 0.3em; width: 50px; text-align: right;"
                        :disabled="isReadOnly"
                        maxlength="5"
                        :placeholder="isReadOnly ? '' : $t('placeholder.break_time_end')"
                        v-model="props.row.end_time">
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <BreakTimeList
              v-model="detail.break_time_range_list"
              :disabled="isReadOnly"
              :mode="mode"
              :labels="labels"
              :break-time-range-list-errors="breakTimeRangeListErrors"
            />
            <ValidationDate
              v-model="detail.term_from"
              item-name="term_from"
              rules="required"
              :item-label="labels.term_from"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_from')"
              :disabled="isReadOnly"
              data-v-step="7"
            />
            <ValidationDate
              v-model="detail.term_to"
              item-name="term_to"
              :item-label="labels.term_to"
              :placeholder="isReadOnly ? '' : $t('placeholder.term_to')"
              :disabled="isReadOnly"
              :picker-options="futureday"
              v-show="mode==modes.edit || mode==modes.view || mode==modes.delete"
            />
            <Tour tour-name="myTour" :steps="steps"/>
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import WorkScheduleDetailMixin from '@/components/Dashboard/Views/Generated/WorkScheduleDetailMixin'

  export default {
    name: 'WorkScheduleDetail',
    data () {
      return {
        breakTimeRangeListErrors: [],
        showFlex: false
      }
    },
    methods: {
      afterInit (res) {
        const jobEnd = this.to24Time(this.detail.job_end)
        this.detail.work_time = [this.detail.job_start, jobEnd, jobEnd !== this.detail.job_end]
        const coreEnd = this.to24Time(this.detail.core_end)
        this.detail.core_time = [this.detail.core_start, coreEnd, coreEnd !== this.detail.core_end]
        const flexEnd = this.to24Time(this.detail.flex_end)
        this.detail.flex_time = [this.detail.flex_start, flexEnd, flexEnd !== this.detail.flex_end]
        for (const breakTime of this.detail.break_time_list) {
          const breakStart = this.to24Time(breakTime.start_time)
          const breakEnd = this.to24Time(breakTime.end_time)
          breakTime.start_time_next = breakStart !== breakTime.start_time
          breakTime.end_time_next = breakEnd !== breakTime.end_time
          breakTime.start_time = breakStart
          breakTime.end_time = breakEnd
        }
        for (const breakTime of this.detail.break_time_range_list) {
          breakTime.range_break_hour = String(parseInt(breakTime.range_break_minutes / 60, 10))
          breakTime.range_break_minute = ('0' + String(parseInt(breakTime.range_break_minutes % 60, 10))).slice(-2)
          breakTime.break_minutes = String(breakTime.break_minutes)
        }
        this.detail.is_job_before_start_time = {
          value: this.mode === this.modes.add ? '' : String(res.api_area.is_job_before_start_time),
          disabled: this.isReadOnly,
          items: this.comboData.job_before_start_time_list
        }
        if (this.detail.working_system_type === '2') {
          this.detail.work_time = ['09:00', '18:00', false]
          this.showFlex = true
        } else {
          this.detail.core_time = ['11:00', '14:00', false]
          this.detail.flex_time = ['09:00', '18:00', false]
          this.showFlex = false
        }
        this.detail = {...this.detail}
      },
      workingSystemTypeChanged () {
        if (this.detail.working_system_type === '2') {
          this.detail.is_job_before_start_time.value = '1'
          this.detail.work_time = ['09:00', '18:00', false]
          this.detail.core_time = ['', '', false]
          this.detail.flex_time = ['', '', false]
          this.showFlex = true
        } else {
          this.detail.work_time = ['', '', false]
          this.detail.core_time = ['11:00', '14:00', false]
          this.detail.flex_time = ['09:00', '18:00', false]
          this.showFlex = false
        }
      },
      customValidate () {
        let row = 1
        let breakTimeCount = 0
        this.breakTimeListErrors = []
        for (const breakTime of this.detail.break_time_list) {
          if (!breakTime.selected && (breakTime.start_time || breakTime.end_time)) {
            // 休憩時間チェック
            // 開始時間hh:mm形式チェック（23:59まで）
            if (!breakTime.start_time.match(/^([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9])$/)) {
              this.breakTimeListErrors.push(this.$t('message.row_start_time_error').replace('{row}', row))
            }
            // 終了時間hh:mm形式チェック（23:59まで）
            if (!breakTime.end_time.match(/^([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9])$/)) {
              this.breakTimeListErrors.push(this.$t('message.row_end_time_error').replace('{row}', row))
            }
            breakTimeCount++
          }
          row++
        }
        if (this.detail.working_system_type === '2' && !this.detail.core_time[0] && breakTimeCount > 0) {
          this.breakTimeListErrors.push(this.$t('message.flex_break_time_error'))
        }
        row = 1
        this.breakTimeRangeListErrors = []
        for (const tmpBreakTime of this.detail.break_time_range_list) {
          if (!tmpBreakTime.selected) {
            if (isNaN(tmpBreakTime.break_minutes)) {
              this.breakTimeRangeListErrors.push(this.$t('message.is_item_number_for_row').replace('{row}', row).replace('{item}', this.labels.break_minutes))
              continue
            }
            const rangeBreakTime = parseInt(tmpBreakTime.range_break_hour, 10) * 60 + parseInt(tmpBreakTime.range_break_minute, 10)
            const breakTime = parseInt(tmpBreakTime.break_minutes, 10)
            if (rangeBreakTime < breakTime) {
              this.breakTimeRangeListErrors.push(this.$t('message.range_break_time_error').replace('{row}', row))
            }
          }
          row++
        }
        return this.breakTimeListErrors.length + this.breakTimeRangeListErrors.length === 0
      },
      convertDetail (detail) {
        let sendDetail = {}
        if (this.mode !== this.modes.delete) {
          sendDetail.term_from = detail.term_from
          sendDetail.term_to = detail.term_to
          sendDetail.update_count = detail.update_count
          sendDetail.work_schedule_code = detail.work_schedule_code
          sendDetail.work_schedule_name = detail.work_schedule_name
          sendDetail.working_system_abbreviation = detail.working_system_abbreviation
          sendDetail.working_system_type = parseInt(detail.working_system_type, 10)
          sendDetail.is_job_before_start_time = parseInt(detail.is_job_before_start_time.value, 10)
          if (detail.working_system_type === '2') {
            sendDetail.job_start = ''
            sendDetail.job_end = ''
            sendDetail.core_start = !detail.core_time[0] ? '' : ('0' + detail.core_time[0]).slice(-5)
            sendDetail.core_end = !detail.core_time[1] ? '' : this.to48Time(('0' + detail.core_time[1]).slice(-5), detail.core_time[2])
            sendDetail.flex_start = !detail.flex_time[0] ? '' : ('0' + detail.flex_time[0]).slice(-5)
            sendDetail.flex_end = !detail.flex_time[1] ? '' : this.to48Time(('0' + detail.flex_time[1]).slice(-5), detail.flex_time[2])
            if (!detail.flex_start) {
              this.detail.is_job_before_start_time.value = '1'
              sendDetail.is_job_before_start_time = 1
            }
          } else {
            sendDetail.job_start = !detail.work_time[0] ? '' : ('0' + detail.work_time[0]).slice(-5)
            sendDetail.job_end = !detail.work_time[1] ? '' : this.to48Time(('0' + detail.work_time[1]).slice(-5), detail.work_time[2])
            sendDetail.core_start = ''
            sendDetail.core_end = ''
            sendDetail.flex_start = ''
            sendDetail.flex_end = ''
          }
          const tmpList = []
          for (const breakTime of detail.break_time_list) {
            if (!breakTime.selected && (breakTime.start_time || breakTime.end_time)) {
              const tmpBreakTime = {}
              tmpBreakTime.start_time = this.to48Time(('0' + breakTime.start_time).slice(-5), breakTime.start_time_next)
              tmpBreakTime.end_time = this.to48Time(('0' + breakTime.end_time).slice(-5), breakTime.end_time_next)
              tmpList.push(tmpBreakTime)
            }
          }
          sendDetail.break_time_list = tmpList
          const rangeList = []
          for (const breakTime of detail.break_time_range_list) {
            if (!breakTime.selected) {
              const tmpBreakTime = {}
              tmpBreakTime.range_break_minutes = parseInt(breakTime.range_break_hour, 10) * 60 + parseInt(breakTime.range_break_minute, 10)
              tmpBreakTime.break_minutes = parseInt(breakTime.break_minutes, 10)
              rangeList.push(tmpBreakTime)
            }
          }
          sendDetail.break_time_range_list = rangeList
        } else {
          sendDetail = {...detail}
        }
        return sendDetail
      }
    },
    mixins: [WorkScheduleDetailMixin]
  }
</script>
<style scoped>
</style>
