<template>
  <div style="margin-top: 20px; padding: 0 20px;">
    <!-- 閉じるボタン -->
    <button type="button" class="btn btn-sm btn-default btn-warning" @click="doClose">
      {{$t('button.close')}}
    </button>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-wizard" id="wizardCard">
          <div class="card-header">
            <h4 class="card-title">{{pageTitle}}</h4>
          </div>
          <div style="padding-left: 15px;">
            <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
            <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
          </div>
          <form-wizard shape="tab"
                      :title="$t('label.company_regist')"
                      :subtitle="$t('label.company_regist_description')"
                      @on-complete="wizardComplete"
                      @on-change="changeTab"
                      ref="wizard"
                      error-color="#EB5E28"
                      color="#C50052">
            <tab-content :title="$t('label.basic_information')"
                        :before-change="validateBasicInfo"
                        icon="ti-info">
              <employee-basic-info ref="employeeBasicInfo"></employee-basic-info>
            </tab-content>
            <tab-content :title="$t('label.career_inf')"
                        :before-change="validateCareerInfo"
                        icon="ti-write">
              <employee-career-info ref="employeeCareerInfo"></employee-career-info>
            </tab-content>
            <tab-content :title="$t('label.group_inf')"
                        :before-change="validateGroupInfo"
                        icon="ti-desktop">
              <employee-group-info ref="employeeGroupInfo"></employee-group-info>
            </tab-content>
            <tab-content :title="$t('label.team_inf')"
                        :before-change="validateTeamInfo"
                        icon="ti-user">
              <employee-team-info ref="employeeTeamInfo"></employee-team-info>
            </tab-content>
            <tab-content :title="labels.social_insurance"
                        :before-change="validateSocialInsuranceInfo"
                        icon="ti-face-smile">
              <employee-social-insurance-info ref="employeeSocialInsuranceInfo"></employee-social-insurance-info>
            </tab-content>
            <tab-content :title="labels.employment_insurance"
                        :before-change="validateEmploymentInsuranceInfo"
                        icon="ti-bag">
              <employee-employment-insurance-info ref="employeeEmploymentInsuranceInfo"></employee-employment-insurance-info>
            </tab-content>
            <tab-content :title="$t('label.tax_cost')"
                        :before-change="validateTaxCostInfo"
                        icon="ti-location-pin">
              <employee-tax-cost-info ref="employeeTaxCostInfo"></employee-tax-cost-info>
            </tab-content>
            <tab-content :title="$t('label.salary_inf')"
                        :before-change="validateSalaryInfo"
                        icon="ti-money">
              <employee-salary-info @paySlipLayoutSearchShowAdd="$emit('paySlipLayoutSearchShowAdd')" ref="employeeSalaryInfo"></employee-salary-info>
            </tab-content>
            <tab-content :title="$t('label.dependent')"
                        :before-change="validateDependentInfo"
                        icon="ti-home">
              <employee-dependent-info ref="employeeDependentInfo"></employee-dependent-info>
            </tab-content>
            <tab-content :title="labels.paid_leave_payment"
                        :before-change="validatePaidLeavePaymentInfo"
                        icon="ti-heart">
              <employee-paid-leave-payment-info ref="employeePaidLeavePaymentInfo"></employee-paid-leave-payment-info>
            </tab-content>
            <tab-content :title="$t('label.application_inf')"
                        :before-change="validateApplicationInfo"
                        icon="ti-files">
              <employee-application-info ref="employeeApplicationInfo"></employee-application-info>
            </tab-content>
            <tab-content :title="$t('label.working_schedule')"
                        :before-change="validateWorkingScheduleInfo"
                        icon="ti-time">
              <employee-working-schedule-info ref="employeeWorkingScheduleInfo"></employee-working-schedule-info>
            </tab-content>

            <tab-content v-if="this.mode !== this.modes.view" :title="getConfirmTitle()"
                         icon="ti-check">
              <div style="padding: 200px 0; overflow-y: auto; height: 570px;">
                <h4 class="text-center text-space">
                  {{getConfirmText1()}}
                  <br>
                  {{getConfirmText2()}}
                </h4>
              </div>
            </tab-content>

            <button v-show="!executeFlg" slot="prev" @click="prevClick" class="btn btn-warning btn-sm btn-default btn-wd btn-back">{{$t('button.back')}}</button>
            <button v-show="isNext()" slot="next" @click="nextClick" class="btn btn-warning btn-sm btn-default btn-wd btn-next">{{$t('button.next')}}</button>
            <button v-show="!executeFlg && this.mode !== this.modes.view" slot="finish" class="btn btn-info btn-sm btn-default btn-wd">{{getButtonName()}}</button>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import EmployeeBasicInfo from './EmployeeBasicInfo'
  import EmployeeCareerInfo from './EmployeeCareerInfo'
  import EmployeeGroupInfo from './EmployeeGroupInfo'
  import EmployeeTeamInfo from './EmployeeTeamInfo'
  import EmployeeSocialInsuranceInfo from './EmployeeSocialInsuranceInfo'
  import EmployeeEmploymentInsuranceInfo from './EmployeeEmploymentInsuranceInfo'
  import EmployeeTaxCostInfo from './EmployeeTaxCostInfo'
  import EmployeeSalaryInfo from './EmployeeSalaryInfo'
  import EmployeeDependentInfo from './EmployeeDependentInfo'
  import EmployeePaidLeavePaymentInfo from './EmployeePaidLeavePaymentInfo'
  import EmployeeApplicationInfo from './EmployeeApplicationInfo'
  import EmployeeWorkingScheduleInfo from './EmployeeWorkingScheduleInfo'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    data () {
      return {
        wizardModel: {},
        resData: {},
        labels: [],
        mode: 0,
        application_form_list: [],
        executeFlg: false,
        finishFlg: false,
        wizard: this.$refs.wizard
      }
    },
    components: {
      FormWizard,
      TabContent,
      EmployeeBasicInfo,
      EmployeeCareerInfo,
      EmployeeGroupInfo,
      EmployeeTeamInfo,
      EmployeeSocialInsuranceInfo,
      EmployeeEmploymentInsuranceInfo,
      EmployeeTaxCostInfo,
      EmployeeSalaryInfo,
      EmployeeDependentInfo,
      EmployeePaidLeavePaymentInfo,
      EmployeeApplicationInfo,
      EmployeeWorkingScheduleInfo
    },
    props: {
      params: Object
    },
    created () {
      this.tableData = [
              {}
      ]
      this.init()
    },
    methods: {
      doClose () {
        this.$emit('close', {})
      },
      back () {
        this.$router.go(-1)
      },
      isNext () {
        var wizard = this.$refs.wizard
        if (this.$refs.employeeBasicInfo === undefined) {
          return !this.executeFlg
        }
        if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
          if ((wizard.activeTabIndex === 8 || wizard.activeTabIndex === 10) && this.mode === this.modes.view) {
            return false
          }
        }
        return !this.executeFlg
      },
      prevClick () {
        let wizard = this.$refs.wizard
        if (wizard.activeTabIndex === 8) {
          if (this.$refs.employeeBasicInfo.payroll_management.value === '2') {
            wizard.changeTab(wizard.activeTabIndex, 4)
          }
        }
        if (wizard.activeTabIndex === 10) {
          if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
            wizard.changeTab(wizard.activeTabIndex, 9)
          }
        }
        if (wizard.activeTabIndex === 12) {
          if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
            wizard.changeTab(wizard.activeTabIndex, 11)
          }
        }
        if (wizard.activeTabIndex === 7) {
          wizard.changeTab(wizard.activeTabIndex, 6)
        }
      },
      nextClick () {
        let wizard = this.$refs.wizard
        if (wizard.activeTabIndex === 3) {
          if (this.$refs.employeeBasicInfo.payroll_management.value === '2') {
            wizard.changeTab(wizard.activeTabIndex, 8)
          }
        }
        if (wizard.activeTabIndex === 8) {
          if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
            wizard.changeTab(wizard.activeTabIndex, 9)
          }
        }
        if (wizard.activeTabIndex === 10) {
          if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
            wizard.changeTab(wizard.activeTabIndex, 11)
          }
        }
        if (wizard.activeTabIndex === 5) {
          wizard.changeTab(wizard.activeTabIndex, 6)
        }
      },
      changeTab (active, next) {
        this.setHelp(next)
        this.wizard.changeTab(active, next)
      },
      setHelp (index) {
        if (index === 0) {
          this.help = this.help_basic
        } else if (index === 1) {
          this.help = this.help_career
        } else if (index === 2) {
          this.help = this.help_department
        } else if (index === 3) {
          this.help = this.help_team
        } else if (index === 4) {
          this.help = this.help_social_insurance
        } else if (index === 5) {
          this.help = this.help_employment_insurance
        } else if (index === 6) {
          this.help = this.help_resident_tax
        } else if (index === 7) {
          this.help = this.help_salary
        } else if (index === 8) {
          this.help = this.help_dependents
        } else if (index === 9) {
          this.help = this.help_paid_holiday
        } else if (index === 10) {
          this.help = this.help_application
        } else if (index === 11) {
          this.help = this.help_service_system
        } else if (index === 12) {
          this.help = this.help_employee_renewal
        }
      },
      setAll () {
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        this.today = Number(String(year) + month + day)
        this.setCareerInfo()
        this.setGroupInfo()
        this.setTeamInfo()
        this.setSocialInsuranceInfo()
        this.setEmploymentInsuranceInfo()
        this.setTaxCostInfo()
        this.setSalaryInfo()
        this.setDependentInfo()
        this.setPaidLeavePaymentInfo()
        this.setApplicationInfo()
        this.setWorkingScheduleInfo()
        if (this.mode !== this.modes.add) {
          var wizard = this.$refs.wizard
          wizard.activateAll()
        }
      },
      getConfirmTitle () {
        if (this.mode === this.modes.add) {
          return this.$t('label.confirm_employee_regist')
        } else if (this.mode === this.modes.edit) {
          return this.$t('label.confirm_employee_update')
        } else if (this.mode === this.modes.delete) {
          return this.$t('label.confirm_employee_delete')
        }
      },
      getConfirmText1 () {
        let result = ''
        if (this.mode === this.modes.add) {
          result = this.$t('label.confirm_employee_regist_description1')
        } else if (this.mode === this.modes.edit) {
          result = this.$t('label.confirm_employee_update_description1')
        } else if (this.mode === this.modes.delete) {
          result = this.$t('label.confirm_employee_delete_description1')
        }
        if (this.finishFlg) {
          result = ''
        }
        return result
      },
      getConfirmText2 () {
        let result = ''
        if (this.mode === this.modes.add) {
          result = this.$t('label.confirm_employee_regist_description2')
        } else if (this.mode === this.modes.edit) {
          result = this.$t('label.confirm_employee_update_description2')
        } else if (this.mode === this.modes.delete) {
          result = this.$t('label.confirm_employee_delete_description2')
        }
        if (this.finishFlg) {
          result = ''
        }
        return result
      },
      // 基本情報で次へボタン
      validateBasicInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        return this.$refs.employeeBasicInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          // 表の入力チェック
          let errCnt = 0
          let checkCnt = 0
          this.$refs.employeeBasicInfo.employee_job_title_error = []
          for (const data of this.$refs.employeeBasicInfo.employee_job_title) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.job_title_code === null || data.job_title_code === '') {
              errCnt++
              this.$refs.employeeBasicInfo.employee_job_title_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeBasicInfo.labels.job_title_name_ryaku))
            }
          }
          // エラーがあった場合
          if (errCnt > 0) {
            return false
          }
          // モード
          this.$refs.employeeCareerInfo.mode = this.mode
          // 単価
          this.$refs.employeeSalaryInfo.unit_price = this.$refs.employeeBasicInfo.unit_price
          return isValid
        })
      },
      setCareerInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeCareerInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeCareerInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 都道府県
        this.$refs.employeeCareerInfo.state_list = this.resData.common_area.combo_data.state_municipality_mapping_list.state_list
        // 自治体
        this.$refs.employeeCareerInfo.municipality_list_all = this.resData.common_area.combo_data.state_municipality_mapping_list.municipality_list
        // マッピング
        this.$refs.employeeCareerInfo.state_municipality_mapping_list = this.resData.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
        this.$refs.employeeCareerInfo.setDetails(this.resData, this.mode)
      },
      // 経歴情報で次へボタン
      validateCareerInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        return this.$refs.employeeCareerInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          // 表の入力チェック
          let errCnt = 0
          let checkCnt = 0
          this.$refs.employeeCareerInfo.employee_educational_background_error = []
          let educationalBackgroundEventDate = []
          for (const data of this.$refs.employeeCareerInfo.employee_educational_background_record) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.event_date === null || data.event_date === '') {
              errCnt++
              this.$refs.employeeCareerInfo.employee_educational_background_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
            }
            educationalBackgroundEventDate.push(this.getFormatDate(data.event_date))
            if (data.event === null || data.event === '') {
              errCnt++
              this.$refs.employeeCareerInfo.employee_educational_background_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.event_ryaku))
            }
          }
          let removeList = Array.from(new Set(educationalBackgroundEventDate))
          if (educationalBackgroundEventDate.length !== removeList.length) {
            // 年月日が重複している場合
            errCnt++
            this.$refs.employeeCareerInfo.employee_educational_background_error.push(this.$t('message.duplicate_error').replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
          }
          this.$refs.employeeCareerInfo.skill_error = []
          let skillEventDate = []
          checkCnt = 0
          for (const data of this.$refs.employeeCareerInfo.skill_record) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.event_date === null || data.event_date === '') {
              errCnt++
              this.$refs.employeeCareerInfo.skill_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
            }
            skillEventDate.push(this.getFormatDate(data.event_date))
            if (data.skill === null || data.skill === '') {
              errCnt++
              this.$refs.employeeCareerInfo.skill_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.skill_ryaku))
            }
          }
          removeList = Array.from(new Set(skillEventDate))
          if (skillEventDate.length !== removeList.length) {
            // 年月日が重複している場合
            errCnt++
            this.$refs.employeeCareerInfo.skill_error.push(this.$t('message.duplicate_error').replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
          }
          this.$refs.employeeCareerInfo.work_history_error = []
          let workHistoryEventDate = []
          checkCnt = 0
          for (const data of this.$refs.employeeCareerInfo.work_history_record) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.event_date === null || data.event_date === '') {
              errCnt++
              this.$refs.employeeCareerInfo.work_history_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
            }
            workHistoryEventDate.push(this.getFormatDate(data.event_date))
            if (data.work_history === null || data.work_history === '') {
              errCnt++
              this.$refs.employeeCareerInfo.work_history_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeCareerInfo.labels.work_history_ryaku))
            }
          }
          removeList = Array.from(new Set(workHistoryEventDate))
          if (workHistoryEventDate.length !== removeList.length) {
            // 年月日が重複している場合
            errCnt++
            this.$refs.employeeCareerInfo.work_history_error.push(this.$t('message.duplicate_error').replace('{item}', this.$refs.employeeCareerInfo.labels.event_date_ryaku))
          }
          // エラーがあった場合
          if (errCnt > 0) {
            return false
          }
          // モード
          this.$refs.employeeGroupInfo.mode = this.mode
          return isValid
        })
      },
      setGroupInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeGroupInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeGroupInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 部署
        this.$refs.employeeGroupInfo.group_list = this.resData.common_area.combo_data.group_list
        for (let group of this.$refs.employeeGroupInfo.group_list) {
          if (this.isEnd(group.term_to)) {
            group.optionClass = 'select-success end-select'
          } else {
            group.optionClass = 'select-success'
          }
        }
        // 利用権限範囲
        this.$refs.employeeGroupInfo.range_list = this.getLabelList(this.resData.common_area.combo_data.range_list)
        // 役割
        this.$refs.employeeGroupInfo.role_list = this.resData.common_area.combo_data.role_list
        this.$refs.employeeGroupInfo.setDetails(this.resData, this.mode)
      },
      // 部署情報で次へボタン
      validateGroupInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        // 現在の日付を取得（時間は無視）
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        // 表の入力チェック
        let errCnt = 0
        let checkCnt = 0
        let groupCnt = 0
        this.$refs.employeeGroupInfo.group_error = []
        let groupList = []
        const endEmptyList = []
        for (const data of this.$refs.employeeGroupInfo.group_record) {
          checkCnt++
          if (data.selected) {
            continue
          }
          groupCnt++
          if (data.group_code === null || data.group_code === '') {
            errCnt++
            this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeGroupInfo.labels.group_ryaku))
          }
          if (data.range === null || data.range === '') {
            errCnt++
            this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeGroupInfo.labels.range_ryaku))
          }
          if (data.role_list === null || data.role_list.length === 0) {
            errCnt++
            this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeGroupInfo.labels.role_ryaku))
          }
          if (data.term_from === null || data.term_from === '') {
            errCnt++
            this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeGroupInfo.labels.term_from_ryaku))
          } else {
            // 所属期間（開始）が入力されている場合、有効期間のチェックをする
            let inputTermFrom = new Date(data.term_from)
            for (const selectGroup of this.$refs.employeeGroupInfo.group_list) {
              if (selectGroup.value === data.group_code) {
                let groupTermFrom = new Date(selectGroup.term_from)
                if (inputTermFrom < groupTermFrom) {
                  errCnt++
                  this.$refs.employeeGroupInfo.group_error.push(this.$t('message.group_term_error').replace('{group_name}', selectGroup.label))
                  break
                }
                if (selectGroup.term_to !== null && selectGroup.term_to !== '' && data.term_to !== null && data.term_to !== '') {
                  let groupTermTo = new Date(selectGroup.term_to)
                  let inputTermTo = new Date(data.term_to)
                  if (inputTermTo > groupTermTo) {
                    errCnt++
                    this.$refs.employeeGroupInfo.group_error.push(this.$t('message.group_term_error').replace('{group_name}', selectGroup.label))
                    break
                  }
                }
              }
            }
          }
          if (data.term_to === null || data.term_to === '') {
            endEmptyList.push(data)
            for (const selectGroup of this.$refs.employeeGroupInfo.group_list) {
              if (selectGroup.value === data.group_code && selectGroup.term_to !== '') {
                const groupTermTo = new Date(selectGroup.term_to)
                if (groupTermTo < today) {
                  errCnt++
                  this.$refs.employeeGroupInfo.group_error.push(this.$t('message.group_term_to_error').replace('{group_name}', selectGroup.label))
                }
              }
            }
          }
          groupList.push(data.group_code)
        }
        // 部署が0件の場合
        if (groupCnt === 0) {
          errCnt++
          this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_table_required').replace('{item}', this.$refs.employeeGroupInfo.labels.group))
        }
        // 部署の所属期間（終了）が全て入力されている場合
        if (endEmptyList.length === 0) {
          errCnt++
          this.$refs.employeeGroupInfo.group_error.push(this.$t('message.group_term_to_warning'))
        } else {
          // 部署の所属期間が連続していない場合
          let minTermFrom = new Date('2099/12/31')
          for (const row of endEmptyList) {
            const rowTermFrom = new Date(row.term_from)
            if (rowTermFrom < minTermFrom) {
              minTermFrom = rowTermFrom
            }
          }
          let checkDate = new Date(minTermFrom.setDate(minTermFrom.getDate() - 1))
          const hireDate = new Date(this.$refs.employeeBasicInfo.hire_date)
          const minCheckDate = hireDate < today ? today : hireDate
          while (checkDate > minCheckDate) {
            let valid = false
            for (const data of this.$refs.employeeGroupInfo.group_record) {
              if (data.term_from !== null && data.term_from !== '') {
                const dataTermFrom = new Date(data.term_from)
                if (dataTermFrom <= checkDate) {
                  const dataTermTo = new Date(data.term_to)
                  if (checkDate <= dataTermTo) {
                    valid = true
                    break
                  }
                }
              }
            }
            if (!valid) {
              errCnt++
              this.$refs.employeeGroupInfo.group_error.push(this.$t('message.no_group_term_exist'))
              break
            }
            checkDate = new Date(checkDate.setDate(checkDate.getDate() - 1))
          }
        }
        // デフォルトが未選択の場合
        if (this.$refs.employeeGroupInfo.selectDefaultGroupCode === 'default') {
          errCnt++
          this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_default_group_required'))
        } else {
          let findFlg = false
          for (const data of this.$refs.employeeGroupInfo.group_record) {
            if (data.selected) {
              continue
            }
            if (data.random === this.$refs.employeeGroupInfo.selectDefaultGroupCode) {
              findFlg = true
            }
          }
          if (!findFlg) {
            errCnt++
            this.$refs.employeeGroupInfo.group_error.push(this.$t('message.is_default_group_required'))
          }
        }
        // エラーがあった場合
        if (errCnt > 0) {
          return false
        }
        // モード
        this.$refs.employeeTeamInfo.mode = this.mode
        return true
      },
      setTeamInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeTeamInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeTeamInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // チーム
        this.$refs.employeeTeamInfo.team_list = this.resData.common_area.combo_data.team_list
        for (let team of this.$refs.employeeTeamInfo.team_list) {
          if (this.isEnd(team.term_to)) {
            team.optionClass = 'select-success end-select'
          } else {
            team.optionClass = 'select-success'
          }
        }
        this.$refs.employeeTeamInfo.setDetails(this.resData, this.mode)
      },
      // チーム情報で次へボタン
      validateTeamInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        // 表の入力チェック
        let errCnt = 0
        let checkCnt = 0
        this.$refs.employeeTeamInfo.team_error = []
        for (const data of this.$refs.employeeTeamInfo.team_record) {
          checkCnt++
          if (data.selected) {
            continue
          }
          if (data.team_code === null || data.team_code === '') {
            errCnt++
            this.$refs.employeeTeamInfo.team_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeTeamInfo.labels.team_ryaku))
          }
          if (data.term_from === null || data.term_from === '') {
            errCnt++
            this.$refs.employeeTeamInfo.team_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeTeamInfo.labels.term_from_ryaku))
          } else {
            // 所属期間（開始）が入力されている場合、有効期間のチェックをする
            let inputTermFrom = new Date(data.term_from)
            for (const selectTeam of this.$refs.employeeTeamInfo.team_list) {
              if (selectTeam.value === data.team_code) {
                let teamTermFrom = new Date(selectTeam.term_from)
                if (inputTermFrom < teamTermFrom) {
                  errCnt++
                  this.$refs.employeeTeamInfo.team_error.push(this.$t('message.team_term_error').replace('{team_name}', selectTeam.label))
                  break
                }
                if (selectTeam.term_to !== null && selectTeam.term_to !== '' && data.term_to !== null && data.term_to !== '') {
                  let teamTermTo = new Date(selectTeam.term_to)
                  let inputTermTo = new Date(data.term_to)
                  if (inputTermTo > teamTermTo) {
                    errCnt++
                    this.$refs.employeeTeamInfo.team_error.push(this.$t('message.team_term_error').replace('{team_name}', selectTeam.label))
                    break
                  }
                }
              }
            }
          }
        }
        // エラーがあった場合
        if (errCnt > 0) {
          return false
        }
        // モード
        this.$refs.employeeSocialInsuranceInfo.mode = this.mode
        return true
      },
      setSocialInsuranceInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeSocialInsuranceInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeSocialInsuranceInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 社会保険有無
        let socialInsurance = this.getValue(this.$refs.employeeSocialInsuranceInfo.social_insurance.value)
        this.$refs.employeeSocialInsuranceInfo.social_insurance = {
          value: socialInsurance,
          disabled: false,
          items: this.getLabelList(this.resData.common_area.combo_data.social_insurance_list)
        }
        // 介護保険対象区分
        this.$refs.employeeSocialInsuranceInfo.is_long_term_care_insurance_care_category_list = this.getLabelList(this.resData.common_area.combo_data.is_long_term_care_insurance_target_category_list)
        this.$refs.employeeSocialInsuranceInfo.premium_exemption_during_childcare_leave_list = this.getLabelList(this.resData.common_area.combo_data.premium_exemption_during_childcare_leave_list)
        this.$refs.employeeSocialInsuranceInfo.setDetails(this.resData, this.mode)
      },
      // 社会保険で次へボタン
      validateSocialInsuranceInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        return this.$refs.employeeSocialInsuranceInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          // モード
          this.$refs.employeeEmploymentInsuranceInfo.mode = this.mode
          return isValid
        })
      },
      setEmploymentInsuranceInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeEmploymentInsuranceInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeEmploymentInsuranceInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 雇用保険
        let employmentInsurance = this.getValue(this.$refs.employeeEmploymentInsuranceInfo.employment_insurance.value)
        this.$refs.employeeEmploymentInsuranceInfo.employment_insurance = {
          value: employmentInsurance,
          disabled: false,
          items: this.getLabelList(this.resData.common_area.combo_data.employment_insurance_list)
        }
        this.$refs.employeeEmploymentInsuranceInfo.setDetails(this.resData, this.mode)
      },
      // 雇用保険で次へボタン
      validateEmploymentInsuranceInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        return this.$refs.employeeEmploymentInsuranceInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          // モード
          this.$refs.employeeTaxCostInfo.mode = this.mode
          return isValid
        })
      },
      setTaxCostInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeTaxCostInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeTaxCostInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 都道府県
        this.$refs.employeeTaxCostInfo.state_list = this.resData.common_area.combo_data.state_municipality_mapping_list.state_list
        // 自治体
        this.$refs.employeeTaxCostInfo.municipality_list_all = this.resData.common_area.combo_data.state_municipality_mapping_list.municipality_list
        // マッピング
        this.$refs.employeeTaxCostInfo.state_municipality_mapping_list = this.resData.common_area.combo_data.state_municipality_mapping_list.state_municipality_mapping_list
        this.$refs.employeeTaxCostInfo.setDetails(this.resData, this.mode)
      },
      // 住民税で次へボタン
      validateTaxCostInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        if (this.$refs.employeeBasicInfo.payroll_management.value === '2') {
          return true
        }
        return this.$refs.employeeTaxCostInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          // モード
          this.$refs.employeeSalaryInfo.mode = this.mode
          return isValid
        })
      },
      setSalaryInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeSalaryInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeSalaryInfo.roles = this.resData.common_area.button_role
        // リストの作成
        // 給与締日
        this.$refs.employeeSalaryInfo.salary_closing_list = this.resData.common_area.combo_data.salary_closing_list
        // レイアウト
        this.$refs.employeeSalaryInfo.layout_list_all = this.resData.common_area.combo_data.salary_category_layout_mapping_list.layout_list
        for (let layout of this.$refs.employeeSalaryInfo.layout_list_all) {
          layout.value = layout.value + '-=-' + layout.label
        }
        // レイアウト情報
        this.$refs.employeeSalaryInfo.layout_inf_list = this.resData.api_area.layout_format_list
        // 給与区分
        this.$refs.employeeSalaryInfo.salary_category_list = []
        let salaryCategoryList = this.getLabelList(this.resData.common_area.combo_data.salary_category_layout_mapping_list.salary_category_list)
        // 給与区分から日給制、日給時給制を削除
        for (let salaryCategory of salaryCategoryList) {
          if (salaryCategory.value !== '5' && salaryCategory.value !== '6') {
            this.$refs.employeeSalaryInfo.salary_category_list.push(salaryCategory)
          }
        }
        // マッピング
        this.$refs.employeeSalaryInfo.salary_category_layout_mapping_list = this.resData.common_area.combo_data.salary_category_layout_mapping_list.salary_category_layout_mapping_list
        // 振込方法
        let transferMethodList = []
        transferMethodList.push({label: '', value: ''})
        for (let transfer of this.getLabelList(this.resData.common_area.combo_data.transfer_method_list)) {
          if (transfer.value !== '2') {
            transferMethodList.push(transfer)
          }
        }
        this.$refs.employeeSalaryInfo.transfer_method_list = transferMethodList
        // 口座種別
        this.$refs.employeeSalaryInfo.account_classification_list = this.getLabelList(this.resData.common_area.combo_data.account_classification_list)
        // 給与の支給リスト
        this.$refs.employeeSalaryInfo.salary_item_list_exclusion_fluctuation_payment = this.resData.common_area.combo_data.salary_item_list_exclusion_fluctuation_payment
        // 給与の控除リスト
        this.$refs.employeeSalaryInfo.salary_item_list_exclusion_fluctuation_deduction = this.resData.common_area.combo_data.salary_item_list_exclusion_fluctuation_deduction
        // 支給単位
        this.$refs.employeeSalaryInfo.payment_unit_list = this.getLabelList(this.resData.common_area.combo_data.payment_unit_list)
        // 支給月度
        this.$refs.employeeSalaryInfo.target_date_list = this.getLabelList(this.resData.common_area.combo_data.target_month_list)
        // 交通用具を使用する距離
        this.$refs.employeeSalaryInfo.distance_to_use_transportation_equipment_list = this.getLabelList(this.resData.common_area.combo_data.distance_to_use_transportation_equipment_mapping_list.distance_to_use_transportation_equipment_list)
        // 交通用具を使用する距離と支給額のマッピング
        this.$refs.employeeSalaryInfo.distance_to_use_transportation_equipment_mapping = this.resData.common_area.combo_data.distance_to_use_transportation_equipment_mapping_list.distance_to_use_transportation_equipment_mapping
        // 交通区分
        this.$refs.employeeSalaryInfo.traffic_division_list = this.getLabelList(this.resData.common_area.combo_data.traffic_division_list)
        // 税額区分
        this.$refs.employeeSalaryInfo.tax_amount_classification_list = this.getLabelList(this.resData.common_area.combo_data.tax_amount_classification_list)
        // 厚生年金基金加入有無
        let pensionFundContributionsSubscription = this.getValue(this.$refs.employeeSalaryInfo.pension_fund_contributions_subscription.value)
        this.$refs.employeeSalaryInfo.pension_fund_contributions_subscription = {
          value: pensionFundContributionsSubscription,
          disabled: false,
          items: this.getLabelList(this.resData.common_area.combo_data.pension_fund_contributions_list)
        }
        if (this.mode === this.modes.add && sessionStorage.getItem('employee_code') === '') {
          this.$refs.employeeSalaryInfo.layout_change_flg = true
        }
        this.$refs.employeeSalaryInfo.setDetails(this.resData, this.mode)
      },
      // 給与で次へボタン
      validateSalaryInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        if (this.$refs.employeeBasicInfo.payroll_management.value === '2') {
          return true
        }
        return this.$refs.employeeSalaryInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          let errCnt = 0
          let checkCnt = 0
          // 支給リストの入力チェック
          this.$refs.employeeSalaryInfo.use_payment_error = []
          let salaryItemCodeList = []
          for (const data of this.$refs.employeeSalaryInfo.use_payment) {
            checkCnt++
            if (data.salary_item_code === null || data.salary_item_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '項目名'))
            }
            if (data.basic_price === null || data.basic_price === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '金額'))
            }
            // 重複チェック
            for (const item of salaryItemCodeList) {
              if (item === data.salary_item_code) {
                errCnt++
                this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.row_duplicate_error').replace('{row}', checkCnt).replace('{item}', '項目名'))
              }
            }
            salaryItemCodeList.push(data.salary_item_code)
            // 時間帯のチェック
            if ((data.working_start_time === '' && data.working_end_time === '') || (data.working_start_time !== '' && data.working_end_time !== '')) {
              if (data.working_start_time !== '' && data.working_end_time !== '') {
                // 両方時間がある時だけチェックする
                // 入力形式のチェック
                if (!this.isTime(data.working_start_time) || !this.isTime(data.working_end_time)) {
                  this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.is_time_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
                  errCnt++
                }
                // 時間反転はエラー
                let startTIme = data.working_start_time
                let endTIme = data.working_end_time
                startTIme = startTIme.replace(':', '')
                endTIme = endTIme.replace(':', '')
                startTIme = Number(startTIme)
                endTIme = Number(endTIme)
                if (startTIme >= endTIme) {
                  // 開始の方が大きいとエラー
                  this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.is_time_reverse_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
                  errCnt++
                }
              }
            } else {
              // 片方のみの入力だとエラー
              this.$refs.employeeSalaryInfo.use_payment_error.push(this.$t('message.is_time_all_input_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
              errCnt++
            }
          }
          // レイアウト外の支給リストの入力チェック
          this.$refs.employeeSalaryInfo.other_payment_error = []
          checkCnt = 0
          salaryItemCodeList = []
          for (const data of this.$refs.employeeSalaryInfo.other_payment) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.salary_item_code === null || data.salary_item_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '項目名'))
            }
            if (data.basic_price === null || data.basic_price === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '金額'))
            }
            // 重複チェック
            for (const item of salaryItemCodeList) {
              if (item === data.salary_item_code) {
                errCnt++
                this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.row_duplicate_error').replace('{row}', checkCnt).replace('{item}', '項目名'))
              }
            }
            salaryItemCodeList.push(data.salary_item_code)
            // 時間帯のチェック
            if ((data.working_start_time === '' && data.working_end_time === '') || (data.working_start_time !== '' && data.working_end_time !== '')) {
              if (data.working_start_time !== '' && data.working_end_time !== '') {
                // 両方時間がある時だけチェックする
                // 入力形式のチェック
                if (!this.isTime(data.working_start_time) || !this.isTime(data.working_end_time)) {
                  this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.is_time_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
                  errCnt++
                }
                // 時間反転はエラー
                let startTIme = data.working_start_time
                let endTIme = data.working_end_time
                startTIme = startTIme.replace(':', '')
                endTIme = endTIme.replace(':', '')
                startTIme = Number(startTIme)
                endTIme = Number(endTIme)
                if (startTIme >= endTIme) {
                  // 開始の方が大きいとエラー
                  this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.is_time_reverse_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
                  errCnt++
                }
              }
            } else {
              // 片方のみの入力だとエラー
              this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.is_time_all_input_for_row').replace('{row}', checkCnt).replace('{item}', this.labels.working_time_ryaku))
              errCnt++
            }
          }
          // レイアウト外の支給リストの入力チェック
          this.$refs.employeeSalaryInfo.other_deduction_error = []
          checkCnt = 0
          for (const data of this.$refs.employeeSalaryInfo.other_deduction) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.salary_item_code === null || data.salary_item_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.other_deduction_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '項目名'))
            }
            if (data.basic_price === null || data.basic_price === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.other_deduction_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', '金額'))
            }
          }
          // 交通費表の入力チェック
          this.$refs.employeeSalaryInfo.employee_commute_error = []
          checkCnt = 0
          for (const data of this.$refs.employeeSalaryInfo.employee_commute_record) {
            checkCnt++
            if (data.selected) {
              continue
            }
            if (data.payment_unit === null || data.payment_unit === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.employee_commute_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeSalaryInfo.labels.payment_unit_ryaku))
            }
            if (data.traffic_division === null || data.traffic_division === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.employee_commute_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeSalaryInfo.labels.traffic_division_ryaku))
            }
            if (data.payment_amount === null || data.payment_amount === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.employee_commute_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeSalaryInfo.labels.payment_amount_ryaku))
            }
            if (!this.isNum(data.payment_amount)) {
              errCnt++
              this.$refs.employeeSalaryInfo.employee_commute_error.push(this.$t('message.is_item_number_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeSalaryInfo.labels.payment_amount_ryaku))
            }
            // 交通用具が選択された場合、距離は必須
            if (data.traffic_division === '3') {
              if (data.distance_to_use_transportation_equipment === null || data.distance_to_use_transportation_equipment === '') {
                errCnt++
                this.$refs.employeeSalaryInfo.employee_commute_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeSalaryInfo.labels.distance_to_use_transportation_equipment_ryaku))
              }
            }
          }
          // 振込先表の入力チェック
          this.$refs.employeeSalaryInfo.transfer_information_error = []
          let transferInformation = this.$refs.employeeSalaryInfo.transfer_information
          if (transferInformation[0].bank_code !== null && transferInformation[0].bank_code !== '') {
            if (transferInformation[0].branch_code === null || transferInformation[0].branch_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 1).replace('{item}', this.$t('label.branch')))
            }
            if (transferInformation[0].account_classification === null || transferInformation[0].account_classification === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 1).replace('{item}', this.$t('label.account_classification')))
            }
            if (this.$refs.employeeSalaryInfo.selectAmount === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 1).replace('{item}', '全額、指定'))
            }
            if (transferInformation[0].account_number === null || transferInformation[0].account_number === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 1).replace('{item}', this.$t('label.account_number')))
            }
          }
          if (this.$refs.employeeSalaryInfo.selectAmount === '2') {
            if (transferInformation[1].bank_code === null || transferInformation[1].bank_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 2).replace('{item}', this.$t('label.bank')))
            }
            if (transferInformation[1].branch_code === null || transferInformation[1].branch_code === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 2).replace('{item}', this.$t('label.branch')))
            }
            if (transferInformation[1].account_classification === null || transferInformation[0].account_classification === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 2).replace('{item}', this.$t('label.account_classification')))
            }
            if (transferInformation[1].account_number === null || transferInformation[1].account_number === '') {
              errCnt++
              this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_required_for_row').replace('{row}', 2).replace('{item}', this.$t('label.account_number')))
            }
          }
          if (!this.isNum(transferInformation[0].transfer_amount_of_money)) {
            errCnt++
            this.$refs.employeeSalaryInfo.transfer_information_error.push(this.$t('message.is_item_number_for_row').replace('{row}', 1).replace('{item}', this.$t('label.transfer_amount_of_money')))
          }
          // 支給の入力チェック
          this.$refs.employeeSalaryInfo.salary_error = []
          let salaryOneRow = this.$refs.employeeSalaryInfo.salaryOneRow[0]
          let salaryTwoRow = this.$refs.employeeSalaryInfo.salaryTwoRow[0]
          let salaryThreeRow = this.$refs.employeeSalaryInfo.salaryThreeRow[0]
          for (let i = 1; i <= 30; i++) {
            if (i <= 10) {
              if (salaryOneRow[`payment_salary_item_name` + i] !== '' && salaryOneRow[`payment_fixed_fluctuation` + i] === 1 && !this.isNum(salaryOneRow[`payment_salary_item_code` + i + `_amount`])) {
                errCnt++
                this.$refs.employeeSalaryInfo.salary_error.push(this.$t('message.num_value').replace('{item}', salaryOneRow[`payment_salary_item_name` + i]))
              }
            } else if (i <= 20) {
              if (salaryTwoRow[`payment_salary_item_name` + i] !== '' && salaryTwoRow[`payment_fixed_fluctuation` + i] === 1 && !this.isNum(salaryTwoRow[`payment_salary_item_code` + i + `_amount`])) {
                errCnt++
                this.$refs.employeeSalaryInfo.salary_error.push(this.$t('message.num_value').replace('{item}', salaryTwoRow[`payment_salary_item_name` + i]))
              }
            } else {
              if (salaryThreeRow[`payment_salary_item_name` + i] !== '' && salaryThreeRow[`payment_fixed_fluctuation` + i] === 1 && !this.isNum(salaryThreeRow[`payment_salary_item_code` + i + `_amount`])) {
                errCnt++
                this.$refs.employeeSalaryInfo.salary_error.push(this.$t('message.num_value').replace('{item}', salaryThreeRow[`payment_salary_item_name` + i]))
              }
            }
          }
          // 控除の入力チェック
          this.$refs.employeeSalaryInfo.deduction_error = []
          let deductionOneRow = this.$refs.employeeSalaryInfo.deductionOneRow[0]
          let deductionTwoRow = this.$refs.employeeSalaryInfo.deductionTwoRow[0]
          for (let i = 1; i <= 20; i++) {
            if (i <= 10) {
              if (deductionOneRow[`deduction_salary_item_name` + i] !== '' && deductionOneRow[`deduction_fixed_fluctuation` + i] === 1 && !this.isNum(deductionOneRow[`deduction_salary_item_code` + i + `_amount`])) {
                errCnt++
                this.$refs.employeeSalaryInfo.deduction_error.push(this.$t('message.num_value').replace('{item}', deductionOneRow[`deduction_salary_item_name` + i]))
              }
            } else {
              if (deductionTwoRow[`deduction_salary_item_name` + i] !== '' && deductionTwoRow[`deduction_fixed_fluctuation` + i] === 1 && !this.isNum(deductionTwoRow[`deduction_salary_item_code` + i + `_amount`])) {
                errCnt++
                this.$refs.employeeSalaryInfo.deduction_error.push(this.$t('message.num_value').replace('{item}', deductionTwoRow[`deduction_salary_item_name` + i]))
              }
            }
          }
          // 変動項目のチェック
          let layoutCode = this.$refs.employeeSalaryInfo.layout.split('-=-')
          let layoutInfList = this.$refs.employeeSalaryInfo.layout_inf_list
          for (const layoutData of layoutInfList) {
            if (layoutData.layout_code === layoutCode[0]) {
              for (const requiredItems of layoutData.required_item_list) {
                for (const reqItem of requiredItems.required_item_list) {
                  // 必須項目が設定されているか調べる
                  let findFlg = false
                  for (let i = 1; i <= 30; i++) {
                    if (i <= 10) {
                      if (salaryOneRow[`payment_salary_item_code` + i] === reqItem) {
                        findFlg = true
                        break
                      }
                    } else if (i <= 20) {
                      if (salaryTwoRow[`payment_salary_item_code` + i] === reqItem) {
                        findFlg = true
                        break
                      }
                    } else {
                      if (salaryThreeRow[`payment_salary_item_code` + i] === reqItem) {
                        findFlg = true
                        break
                      }
                    }
                  }
                  if (!findFlg) {
                    let otherPayment = this.$refs.employeeSalaryInfo.other_payment
                    for (const payment of otherPayment) {
                      if (!payment.selected) {
                        if (payment.salary_item_code === reqItem) {
                          findFlg = true
                          break
                        }
                      }
                    }
                  }
                  if (!findFlg) {
                    // 見つかっていない場合エラー
                    let item1 = ''
                    for (const paymentData of this.$refs.employeeSalaryInfo.salary_item_list_exclusion_fluctuation_payment) {
                      if (paymentData.salary_category === Number(this.$refs.employeeSalaryInfo.salary_category)) {
                        for (const payment of paymentData.values) {
                          if (payment.value === reqItem) {
                            item1 = payment.label
                          }
                        }
                      }
                    }
                    this.$refs.employeeSalaryInfo.other_payment_error.push(this.$t('message.required_item_list_error').replace('{item1}', item1).replace('{item2}', requiredItems.salary_item_name))
                    errCnt++
                  }
                }
              }
            }
          }
          // エラーがあった場合
          if (errCnt > 0) {
            return false
          }
          // モード
          this.$refs.employeeDependentInfo.mode = this.mode
          return isValid
        })
      },
      isNum (value) {
        // カンマを外す処理
        if (value === null || value === undefined || typeof value === 'number') {
          return true
        }
        value = value.replace(/,/g, '')
        return !isNaN(value)
      },
      setDependentInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeDependentInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeDependentInfo.roles = this.resData.common_area.button_role
        // 性別
        this.$refs.employeeDependentInfo.sex_list = this.getLabelList(this.resData.common_area.combo_data.sex_list)
        // 続柄
        this.$refs.employeeDependentInfo.relationship_list = this.getLabelList(this.resData.common_area.combo_data.relationship_list)
        // 障害者区分
        this.$refs.employeeDependentInfo.disability_classification_list = this.getLabelList(this.resData.common_area.combo_data.disability_classification_list)
        this.$refs.employeeDependentInfo.disability_classification_list.unshift({label: '', value: ''})
        // 同居区分
        this.$refs.employeeDependentInfo.living_together_list = this.getLabelList(this.resData.common_area.combo_data.living_together_list)
        // 同居区分
        this.$refs.employeeDependentInfo.dependent_relative_classification_list = this.getLabelList(this.resData.common_area.combo_data.dependent_relative_classification_list)
        // 寡婦/ひとり親
        this.$refs.employeeDependentInfo.is_widow_list = this.getLabelList(this.resData.common_area.combo_data.is_widow_list)
        this.$refs.employeeDependentInfo.setDetails(this.resData, this.mode)
      },
      // 扶養家族で次へボタン
      validateDependentInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        // 表の入力チェック
        let errCnt = 0
        let checkCnt = 0
        this.$refs.employeeDependentInfo.dependent_error = []
        for (const data of this.$refs.employeeDependentInfo.dependent_record) {
          checkCnt++
          if (data.selected) {
            continue
          }
          if (data.dependent_name === null || data.dependent_name === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.dependent_name_ryaku))
          }
          if (data.sex === null || data.sex === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.sex_ryaku))
          }
          if (data.birthday === null || data.birthday === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.birthday_ryaku))
          }
          if (data.relationship === null || data.relationship === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.relationship_ryaku))
          }
          if (data.living_together === null || data.living_together === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.living_together_ryaku))
          }
          if (data.dependent_relative_classification === null || data.dependent_relative_classification === '') {
            errCnt++
            this.$refs.employeeDependentInfo.dependent_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeeDependentInfo.labels.dependent_relative_classification_ryaku))
          }
        }
        // エラーがあった場合
        if (errCnt > 0) {
          return false
        }
        // モード
        this.$refs.employeePaidLeavePaymentInfo.mode = this.mode
        return true
      },
      setPaidLeavePaymentInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeePaidLeavePaymentInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeePaidLeavePaymentInfo.roles = this.resData.common_area.button_role
        // 給与締日
        this.$refs.employeePaidLeavePaymentInfo.months_of_service_list = this.resData.common_area.combo_data.months_of_service_list
        // 週の労働日数
        let numberOfWorkingDaysPerWeek = this.getValue(this.$refs.employeePaidLeavePaymentInfo.number_of_working_days_per_week.value)
        let radioItems = []
        for (const element of [5, 4, 3, 2, 1]) {
          let item = null
          for (let numberOfWorkingDaysPerWeek of this.resData.common_area.combo_data.number_of_working_days_per_week_list) {
            if (element === numberOfWorkingDaysPerWeek.value) {
              let itemLabel = numberOfWorkingDaysPerWeek.label.split(',')
              item = {
                id: String(radioItems.length),
                value: numberOfWorkingDaysPerWeek.value,
                label: itemLabel[0],
                disabled: false
              }
              break
            }
          }
          if (item === null) {
            item = {
              id: String(element),
              value: element,
              label: this.$t('label.days_' + element),
              disabled: true
            }
          }
          radioItems.push(item)
        }
        this.$refs.employeePaidLeavePaymentInfo.number_of_working_days_per_week = {
          value: numberOfWorkingDaysPerWeek,
          disabled: false,
          items: radioItems
        }
        let paidLeavePayment = this.getValue(this.$refs.employeePaidLeavePaymentInfo.paid_leave_payment.value)
        this.$refs.employeePaidLeavePaymentInfo.paid_leave_payment = {
          value: paidLeavePayment,
          disabled: false,
          items: this.getLabelList(this.resData.common_area.combo_data.paid_leave_payment_list)
        }
        this.$refs.employeePaidLeavePaymentInfo.paid_leave_reason_list = this.getLabelList(this.resData.common_area.combo_data.paid_leave_reason_list)
        this.$refs.employeePaidLeavePaymentInfo.setDetails(this.resData, this.mode)
      },
      // 有給休暇で次へボタン
      validatePaidLeavePaymentInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        return this.$refs.employeePaidLeavePaymentInfo.$refs.observer.validate()
        .then(isValid => {
          if (!isValid) {
            this.$emit('on-submit', this.registerForm, isValid)
          }
          if (this.getValue(this.$refs.employeePaidLeavePaymentInfo.paid_leave_payment.value === '1')) {
            // 表の入力チェック
            let errCnt = 0
            let checkCnt = 0
            this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error = []
            let paymentDateArray = []
            let duplicateFlg = false
            let paidHoliday = 0
            for (const data of this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_record) {
              checkCnt++
              if (data.selected) {
                continue
              }
              if (data.payment_date === null || data.payment_date === '') {
                errCnt++
                this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.is_item_required_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.payment_date_ryaku))
              }
              // 支給年月日重複チェック
              for (const date of paymentDateArray) {
                if (date === this.getFormatDate(data.payment_date) && !duplicateFlg) {
                  errCnt++
                  duplicateFlg = true
                  this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.duplicate_error').replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.payment_date_ryaku))
                }
              }
              paymentDateArray.push(data.payment_date)
              if (!this.isNum(data.payment_days)) {
                errCnt++
                this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.is_item_number_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.payment_days_ryaku))
              }
              if (!this.isNum(data.payment_times)) {
                errCnt++
                this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.is_item_number_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.payment_times_ryaku))
              }
              if (!this.isNum(data.expiration_days)) {
                errCnt++
                this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.is_item_number_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.expiration_days_ryaku))
              }
              if (!this.isNum(data.expiration_times)) {
                errCnt++
                this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.is_item_number_for_row').replace('{row}', checkCnt).replace('{item}', this.$refs.employeePaidLeavePaymentInfo.labels.expiration_times_ryaku))
              }
              // 最大有給日数を超えたらエラー
              paidHoliday = paidHoliday + Number(data.hold_days)
              // paidHoliday = paidHoliday + (Number(data.hold_times) / 8)
            }
            if (Number(this.$refs.employeePaidLeavePaymentInfo.paid_holiday_management_maximum_days) < paidHoliday) {
              errCnt++
              this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_error.push(this.$t('message.paidHolidayOver'))
            }
            if (this.isEnd(this.$refs.employeePaidLeavePaymentInfo.paid_reference_date)) {
              errCnt++
              this.$refs.employeePaidLeavePaymentInfo.paidReferenceDateErrorMsg = this.$t('message.paid_reference_date_error')
            } else {
              this.$refs.employeePaidLeavePaymentInfo.paidReferenceDateErrorMsg = ''
            }
            // エラーがあった場合
            if (errCnt > 0) {
              return false
            }
            // モード
            this.$refs.employeeApplicationInfo.mode = this.mode
            return isValid
          } else if (this.getValue(this.$refs.employeePaidLeavePaymentInfo.paid_leave_payment.value === '2')) {
            // 有給管理しない場合はチェックOK
            return true
          } else {
            // 未入力の場合チェックエラー
            return false
          }
        })
      },
      setApplicationInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeApplicationInfo.labels = this.getLabels(this.resData.common_area)
        // ボタン権限をthis.rolesに設定
        this.$refs.employeeApplicationInfo.roles = this.resData.common_area.button_role
        // 申請分類
        this.$refs.employeeApplicationInfo.application_classification_list = this.resData.common_area.combo_data.application_classification_form_mapping_list.application_classification_list
        // 従業員申請管理区分
        this.$refs.employeeApplicationInfo.employee_application_control_list = this.getLabelList(this.resData.common_area.combo_data.employee_application_control_list)
        // 申請情報作成
        this.$refs.employeeApplicationInfo.application_record = []
        this.application_form_list = this.resData.common_area.combo_data.application_classification_form_mapping_list.application_form_list
        for (const data of this.resData.common_area.combo_data.application_classification_form_mapping_list.application_classification_list) {
          if (this.resData.common_area.combo_data.application_classification_form_mapping_list.application_classification_form_mapping_list[data.value] === undefined) {
            continue
          }
          for (const applicationFrom of this.resData.common_area.combo_data.application_classification_form_mapping_list.application_classification_form_mapping_list[data.value]) {
            // 新規追加時は従業員申請を会社申請管理区分に従うに設定する
            let record = {
              application_form_code: applicationFrom,
              application_form_name: this.getApplicationFromName(applicationFrom),
              application_classification_code: data.value,
              employee_application_control: this.disableApplicationForms.includes(applicationFrom) ? '1' : '3'
            }
            this.$refs.employeeApplicationInfo.application_record.push(record)
          }
        }
        this.$refs.employeeApplicationInfo.setDetails(this.resData, this.mode)
      },
      validateApplicationInfo () {
        // モード
        this.$refs.employeeWorkingScheduleInfo.mode = this.mode
        return true
      },
      setWorkingScheduleInfo () {
        // ラベル情報をthis.labelsに設定
        this.$refs.employeeWorkingScheduleInfo.labels = this.getLabels(this.resData.common_area)
        this.$refs.employeeWorkingScheduleInfo.working_schedule_record = []
        for (const data of this.resData.api_area.work_schedule_list) {
          let record = {
            selected: false,
            work_schedule_code: data.work_schedule_code,
            work_schedule_name: data.work_schedule_name,
            term_from: data.term_from,
            term_to: data.term_to,
            off_site_deemed_working_hours_flag: false,
            default_work_schedule_code: '',
            is_need_gps: false,
            is_disp_late_time: false
          }
          this.$refs.employeeWorkingScheduleInfo.working_schedule_record.push(record)
        }
        this.$refs.employeeWorkingScheduleInfo.setDetails(this.resData, this.mode)
      },
      validateWorkingScheduleInfo () {
        // 参照モードはチェックしない
        if (this.mode === this.modes.view) {
          return true
        }
        if (this.$refs.employeeBasicInfo.attendance_management.value === '2' || this.$refs.employeeBasicInfo.white_collar_exemption.value) {
          return true
        }
        let errCnt = 0
        let selectCnt = 0
        this.$refs.employeeWorkingScheduleInfo.working_schedule_error = []
        for (const data of this.$refs.employeeWorkingScheduleInfo.working_schedule_record) {
          if (data.selected) {
            selectCnt++
          }
        }
        if (selectCnt === 0) {
          errCnt++
          this.$refs.employeeWorkingScheduleInfo.working_schedule_error.push(this.$t('message.is_working_schedule_required').replace('{item}', this.$t('label.working_schedule')))
        }
        if (this.$refs.employeeWorkingScheduleInfo.selectDefaultWorkingSchedule === '') {
          errCnt++
          this.$refs.employeeWorkingScheduleInfo.working_schedule_error.push(this.$t('message.is_select_default_working_schedule_required').replace('{item}', this.$t('label.default_work_schedule')))
        }
        // エラーがあった場合
        if (errCnt > 0) {
          return false
        }
        return true
      },
      getApplicationFromName (value) {
        for (const data of this.application_form_list) {
          if (value === data.value) {
            return data.label
          }
        }
        return ''
      },
      amountToNumber (amount) {
        if (amount === undefined || amount === null || amount === '') {
          return 0
        } else if (typeof amount === 'number') {
          return amount
        }
        return Number(amount.replace(/,/g, ''))
      },
      // テキストから数値に変換
      textToNumber (text) {
        if (text === undefined || text === null || text === '') {
          return 0
        }
        // 元々数値の場合はそのまま返却
        if (typeof text === 'number') {
          return text
        }
        return Number(text.replace(/,/g, ''))
      },
      getFormatDate (date) {
        if (date === '' || date === undefined || date === null) {
          return ''
        } else {
          return this.formatDate(date)
        }
      },
      getPrefecturesLabel (value) {
        for (const data of this.$refs.employeeCareerInfo.state_list) {
          if (data.value === value) {
            return data.label
          }
        }
        return ''
      },
      getTime (value) {
        let splitValue = value.split(':')
        if (splitValue.length === 2) {
          if (splitValue[0].length === 1) {
            splitValue[0] = '0' + splitValue[0]
          }
          if (splitValue[1].length === 1) {
            splitValue[1] = '0' + splitValue[1]
          }
          return splitValue[0] + ':' + splitValue[1]
        } else {
          return ''
        }
      },
      // パラメータ作成
      createParam () {
        let layoutCode = this.$refs.employeeSalaryInfo.layout.split('-=-')
        // 寡婦/ひとり親
        let isWidow = 9
        if (this.$refs.employeeDependentInfo.is_widow === '') {
          isWidow = null
        } else {
          isWidow = Number(this.$refs.employeeDependentInfo.is_widow)
        }
        // 勤労学生
        let isWorkingStudent = 2
        if (this.$refs.employeeDependentInfo.is_working_student) {
          isWorkingStudent = 1
        }
        let param = {
          screen_code: this.screenCode,
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.$store.state.loginUser.groupCode,
          employee_code: this.$store.state.loginUser.employeeCode,
          api_area: {
            basic: {
              employee_code: this.$refs.employeeBasicInfo.employee_code,
              employee_name: this.$refs.employeeBasicInfo.employee_name,
              pseudonym_reading: this.$refs.employeeBasicInfo.pseudonym_reading,
              employee_classification_code: this.$refs.employeeBasicInfo.employee_classification,
              recruitment_category_code: this.$refs.employeeBasicInfo.recruitment_category_code,
              language: this.$refs.employeeBasicInfo.language,
              append_path: this.$refs.employeeBasicInfo.append_path,
              hire_date: this.$refs.employeeBasicInfo.hire_date,
              retirement_date: this.getValue(this.$refs.employeeBasicInfo.retirement_date),
              mail_address: this.$refs.employeeBasicInfo.mail_address,
              sex: Number(this.$refs.employeeBasicInfo.sex),
              birthday: this.$refs.employeeBasicInfo.birthday,
              extension_number: this.getValue(this.$refs.employeeBasicInfo.extension_number),
              contract_renewal_date: this.getValue(this.$refs.employeeBasicInfo.contract_renewal_date),
              attendance_management: Number(this.$refs.employeeBasicInfo.attendance_management.value),
              payroll_management: Number(this.$refs.employeeBasicInfo.payroll_management.value),
              paid_leave_payment: Number(this.$refs.employeePaidLeavePaymentInfo.paid_leave_payment.value),
              paid_reference_date: this.$refs.employeePaidLeavePaymentInfo.paid_reference_date,
              estimated_months_of_service: this.$refs.employeePaidLeavePaymentInfo.estimated_months_of_service,
              availability: Number(this.$refs.employeeBasicInfo.availability.value),
              number_of_working_days_per_week: this.$refs.employeePaidLeavePaymentInfo.number_of_working_days_per_week.value === '' ? 0 : Number(this.$refs.employeePaidLeavePaymentInfo.number_of_working_days_per_week.value),
              feliCa: this.$refs.employeeBasicInfo.feliCa,
              my_number: this.$refs.employeeBasicInfo.my_number,
              estimated_overtime_hours: String(this.$refs.employeeBasicInfo.estimated_overtime_hours),
              unit_price: this.amountToNumber(this.$refs.employeeBasicInfo.unit_price),
              is_visible: Number(this.$refs.employeeBasicInfo.is_visible.value),
              social_insurance_date: this.$refs.employeeSocialInsuranceInfo.social_insurance_date,
              health_insurance_sign: this.getValue(this.$refs.employeeSocialInsuranceInfo.health_insurance_sign),
              health_insurance_no: this.getValue(this.$refs.employeeSocialInsuranceInfo.health_insurance_no),
              is_long_term_care_insurance_care_category: this.$refs.employeeSocialInsuranceInfo.is_long_term_care_insurance_care_category === '' ? null : Number(this.$refs.employeeSocialInsuranceInfo.is_long_term_care_insurance_care_category),
              basic_pension_number: this.$refs.employeeSocialInsuranceInfo.basic_pension_number,
              employment_insurance: Number(this.$refs.employeeEmploymentInsuranceInfo.employment_insurance.value),
              employment_insurance_date: this.$refs.employeeEmploymentInsuranceInfo.employment_insurance_date,
              employment_insurance_number: this.$refs.employeeEmploymentInsuranceInfo.employment_insurance_number,
              office_code: this.$refs.employeeBasicInfo.office_code,
              business_type: this.$refs.employeeBasicInfo.business,
              job_title_code_list: [],
              password: this.$refs.employeeBasicInfo.password,
              closing_code: this.$refs.employeeBasicInfo.closing_code,
              salary_closing_code: this.$refs.employeeSalaryInfo.salary_closing_code,
              salary_category: this.$refs.employeeSalaryInfo.salary_category === '' ? null : Number(this.$refs.employeeSalaryInfo.salary_category),
              is_widow: isWidow,
              is_working_student: isWorkingStudent,
              white_collar_exemption: this.$refs.employeeBasicInfo.white_collar_exemption.value,
              is_shift_work: this.$refs.employeeBasicInfo.is_shift_work.value
            },
            history: {
              post_code: this.$refs.employeeCareerInfo.post_code,
              prefectures: this.$refs.employeeCareerInfo.state,
              city: this.getCityCode(this.$refs.employeeCareerInfo.city),
              town: this.$refs.employeeCareerInfo.town,
              building: this.$refs.employeeCareerInfo.building,
              tel: this.$refs.employeeCareerInfo.tel,
              emergency_contact: this.$refs.employeeCareerInfo.emergency_contact,
              other: this.$refs.employeeCareerInfo.other,
              reason_for_retirement: this.$refs.employeeCareerInfo.reason_for_retirement,
              employee_educational_background: [],
              employee_skill: [],
              employee_work_history: []
            },
            application_form_list: [],
            working_schedule_employee_list: [],
            group_list: [],
            employee_group_role_list: [],
            team_list: [],
            salary: {
              employee_payment: {
                layout_code: layoutCode[0] === '' ? null : layoutCode[0],
                payment_list: []
              },
              employee_transfer: {
                transfer_method_1: '',
                bank_code_1: '',
                branch_code_1: '',
                account_classification_1: '',
                account_number_1: '',
                transfer_amount_of_money_1: 0,
                transfer_method_2: '',
                bank_code_2: '',
                branch_code_2: '',
                account_classification_2: '',
                account_number_2: ''
              },
              tax: {
                local_government_code: this.$refs.employeeTaxCostInfo.local_government_code,
                // first_month_cost: String(this.amountToNumber(this.$refs.employeeTaxCostInfo.first_month_cost)),
                // costs_from_the_next_month: String(this.amountToNumber(this.$refs.employeeTaxCostInfo.costs_from_the_next_month)),
                monthl_reward: this.amountToNumber(this.$refs.employeeSalaryInfo.monthl_reward),
                pension_fund_contributions: Number(this.$refs.employeeSalaryInfo.pension_fund_contributions_subscription.value),
                pension_fund_contributions_date: this.$refs.employeeSalaryInfo.pension_fund_contributions_date,
                employment_insurance: Number(this.$refs.employeeEmploymentInsuranceInfo.employment_insurance.value),
                tax_amount_classification: this.$refs.employeeSalaryInfo.tax_amount_classification === '' ? null : Number(this.$refs.employeeSalaryInfo.tax_amount_classification),
                monthly_fee: this.amountToNumber(this.$refs.employeeSalaryInfo.monthl_reward),
                social_insurance: Number(this.$refs.employeeSocialInsuranceInfo.social_insurance.value),
                premium_exemption_during_childcare_leave: Number(this.$refs.employeeSocialInsuranceInfo.premium_exemption_during_childcare_leave)
              },
              dependent_list: [],
              employee_commute_list: []
            },
            paid_annual_account_employee_list: []
          }
        }
        if (!this.$refs.employeeBasicInfo.is_send_registration_completion_email) {
          param.api_area.is_send_registration_completion_email = this.$refs.employeeBasicInfo.is_send_registration_completion_email
        }
        // 締日
        if (param.api_area.basic.attendance_management === 2) {
          param.api_area.basic.closing_code = ''
        }
        // 高度プロフェッショナル制度対象者
        if (param.api_area.basic.attendance_management === 2) {
          param.api_area.basic.white_collar_exemption = null
        }
        // 次回予定勤続月数
        if (param.api_area.basic.estimated_months_of_service === '') {
          delete param.api_area.basic.estimated_months_of_service
        }
        // 給与情報を編集
        let index = 1
        for (index = 1; index <= 30; index++) {
          let code = 'payment_salary_item_code' + String(index)
          let amount = 'payment_salary_item_code' + String(index) + '_amount'
          let setCode = ''
          let setAmount = ''
          if (index <= 10) {
            setCode = this.$refs.employeeSalaryInfo.salaryOneRow[0][code]
            setAmount = this.$refs.employeeSalaryInfo.salaryOneRow[0][amount]
          } else if (index <= 20) {
            setCode = this.$refs.employeeSalaryInfo.salaryTwoRow[0][code]
            setAmount = this.$refs.employeeSalaryInfo.salaryTwoRow[0][amount]
          } else {
            setCode = this.$refs.employeeSalaryInfo.salaryThreeRow[0][code]
            setAmount = this.$refs.employeeSalaryInfo.salaryThreeRow[0][amount]
          }
          if (setCode !== undefined && setCode !== null && setCode !== '') {
            let pushParam = {
              salary_item_code: setCode,
              basic_price: this.amountToNumber(setAmount)
            }
            for (let usePayment of this.$refs.employeeSalaryInfo.use_payment) {
              if (usePayment.salary_item_code === pushParam.salary_item_code) {
                pushParam['working_start_time'] = this.getTime(usePayment.working_start_time)
                pushParam['working_end_time'] = this.getTime(usePayment.working_end_time)
              }
            }
            param.api_area.salary.employee_payment.payment_list.push(pushParam)
          }
        }
        for (index = 1; index <= 20; index++) {
          let deductionCode = 'deduction_salary_item_code' + String(index)
          let deductionAmount = 'deduction_salary_item_code' + String(index) + '_amount'
          let setDeductionCode = ''
          let setDeductionAmount = ''
          if (index <= 10) {
            setDeductionCode = this.$refs.employeeSalaryInfo.deductionOneRow[0][deductionCode]
            setDeductionAmount = this.$refs.employeeSalaryInfo.deductionOneRow[0][deductionAmount]
          } else {
            setDeductionCode = this.$refs.employeeSalaryInfo.deductionTwoRow[0][deductionCode]
            setDeductionAmount = this.$refs.employeeSalaryInfo.deductionTwoRow[0][deductionAmount]
          }
          if (setDeductionCode !== undefined && setDeductionCode !== null && setDeductionCode !== '') {
            let pushParam = {
              salary_item_code: setDeductionCode,
              basic_price: this.amountToNumber(setDeductionAmount)
            }
            param.api_area.salary.employee_payment.payment_list.push(pushParam)
          }
        }
        for (const payment of this.$refs.employeeSalaryInfo.other_payment) {
          if (!payment.selected) {
            let pushPayment = {
              salary_item_code: payment.salary_item_code,
              basic_price: Number(payment.basic_price),
              working_start_time: this.getTime(payment.working_start_time),
              working_end_time: this.getTime(payment.working_end_time)
            }
            param.api_area.salary.employee_payment.payment_list.push(pushPayment)
          }
        }
        for (const deduction of this.$refs.employeeSalaryInfo.other_deduction) {
          if (!deduction.selected) {
            let pushDeduction = {
              salary_item_code: deduction.salary_item_code,
              basic_price: Number(deduction.basic_price)
            }
            param.api_area.salary.employee_payment.payment_list.push(pushDeduction)
          }
        }
        // 画像を編集
        if (param.api_area.basic.append_path === null || (param.api_area.basic.append_path.length > 0 && param.api_area.basic.append_path.indexOf('tmp') === -1)) {
          param.api_area.basic.append_path = null
        }
        // 職名レコード追加
        for (const data of this.$refs.employeeBasicInfo.employee_job_title) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            job_title_code: ''
          }
          pushData.job_title_code = data.job_title_code
          param.api_area.basic.job_title_code_list.push(pushData)
        }
        // 学歴レコード追加
        for (const data of this.$refs.employeeCareerInfo.employee_educational_background_record) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            event_date: '',
            event: ''
          }
          pushData.event_date = this.getFormatDate(data.event_date)
          pushData.event = data.event
          param.api_area.history.employee_educational_background.push(pushData)
        }
        // スキルレコード追加
        for (const data of this.$refs.employeeCareerInfo.skill_record) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            event_date: '',
            skill: ''
          }
          pushData.event_date = this.getFormatDate(data.event_date)
          pushData.skill = data.skill
          param.api_area.history.employee_skill.push(pushData)
        }
        // 職歴レコード追加
        for (const data of this.$refs.employeeCareerInfo.work_history_record) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            event_date: '',
            work_history: ''
          }
          pushData.event_date = this.getFormatDate(data.event_date)
          pushData.work_history = data.work_history
          param.api_area.history.employee_work_history.push(pushData)
        }
        // 部署レコード追加
        let groupRecord = this.$refs.employeeGroupInfo.group_record.concat(this.$refs.employeeGroupInfo.evacuation_group_record)
        for (const data of groupRecord) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let groupDuplication = false
          for (let checkGroup of param.api_area.group_list) {
            if (data.group_code === checkGroup.group_code) {
              groupDuplication = true
              let oldGroupTermFrom = this.getFormatDate(checkGroup.term_from).replace(/\//g, '')
              let newGroupTermFrom = this.getFormatDate(data.term_from).replace(/\//g, '')
              if (Number(oldGroupTermFrom) < Number(newGroupTermFrom)) {
                // 新しい方が日付が未来の場合
                checkGroup.group_code = data.group_code
                checkGroup.range = Number(data.range)
                checkGroup.term_from = this.getFormatDate(data.term_from)
                checkGroup.term_to = this.getFormatDate(data.term_to)
                if (this.$refs.employeeGroupInfo.selectDefaultGroupCode === data.random) {
                  checkGroup.default_group_code = 1
                }
              }
            }
          }
          if (groupDuplication) {
            continue
          }
          let pushData = {
            group_code: '',
            range: 0,
            term_from: '',
            term_to: '',
            default_group_code: 2
          }
          pushData.group_code = data.group_code
          pushData.range = Number(data.range)
          pushData.term_from = this.getFormatDate(data.term_from)
          pushData.term_to = this.getFormatDate(data.term_to)
          if (this.$refs.employeeGroupInfo.selectDefaultGroupCode === data.random) {
            pushData.default_group_code = 1
          }
          param.api_area.group_list.push(pushData)
          // 従業員部署別利用権限
          let skip = false
          for (const role of data.role_list) {
            let rolePushData = {
              group_code: data.group_code,
              role_code: role
            }
            for (const employeeGroupRole of param.api_area.employee_group_role_list) {
              if (employeeGroupRole.group_code === data.group_code && employeeGroupRole.role_code === role) {
                skip = true
              }
            }
            if (!skip) {
              param.api_area.employee_group_role_list.push(rolePushData)
            }
          }
        }
        // チームレコード追加
        for (const data of this.$refs.employeeTeamInfo.team_record) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            team_code: '',
            term_from: '',
            term_to: ''
          }
          pushData.team_code = data.team_code
          pushData.term_from = this.getFormatDate(data.term_from)
          pushData.term_to = this.getFormatDate(data.term_to)
          param.api_area.team_list.push(pushData)
        }
        // 振込先
        let count = 0
        for (const data of this.$refs.employeeSalaryInfo.transfer_information) {
          count++
          if (count === 1) {
            if (data.bank_code !== null && data.bank_code !== '') {
              param.api_area.salary.employee_transfer.transfer_method_1 = 1
            } else {
              param.api_area.salary.employee_transfer.transfer_method_1 = null
            }
            param.api_area.salary.employee_transfer.bank_code_1 = data.bank_code
            param.api_area.salary.employee_transfer.branch_code_1 = data.branch_code
            param.api_area.salary.employee_transfer.account_classification_1 = data.account_classification === '' ? null : Number(data.account_classification)
            param.api_area.salary.employee_transfer.account_number_1 = data.account_number
            param.api_area.salary.employee_transfer.transfer_amount_of_money_1 = this.textToNumber(data.transfer_amount_of_money)
          } else if (count === 2) {
            if (data.bank_code !== null && data.bank_code !== '') {
              param.api_area.salary.employee_transfer.transfer_method_2 = 1
            } else {
              param.api_area.salary.employee_transfer.transfer_method_2 = null
            }
            param.api_area.salary.employee_transfer.bank_code_2 = data.bank_code
            param.api_area.salary.employee_transfer.branch_code_2 = data.branch_code
            param.api_area.salary.employee_transfer.account_classification_2 = data.account_classification === '' ? null : Number(data.account_classification)
            param.api_area.salary.employee_transfer.account_number_2 = data.account_number
          }
        }
        if (param.api_area.salary.employee_transfer.transfer_method_1 === null) {
          delete param.api_area.salary.employee_transfer
        }
        // 扶養家族レコード追加
        for (const data of this.$refs.employeeDependentInfo.dependent_record) {
          // 削除がチェックされていたらスキップ
          if (data.selected) {
            continue
          }
          let pushData = {
            dependent_name: '',
            sex: 0,
            birthday: '',
            relationship: 0,
            day_of_death: '',
            deductible_spouse: false,
            disability_classification: '',
            living_together: 0,
            dependent_relative_classification: 0,
            my_number: ''
          }
          pushData.dependent_name = data.dependent_name
          pushData.sex = Number(data.sex)
          pushData.birthday = this.getFormatDate(data.birthday)
          pushData.relationship = Number(data.relationship)
          pushData.day_of_death = this.getFormatDate(data.day_of_death)
          pushData.deductible_spouse = data.deductible_spouse
          pushData.disability_classification = data.disability_classification
          pushData.living_together = Number(data.living_together)
          pushData.dependent_relative_classification = Number(data.dependent_relative_classification)
          pushData.my_number = data.my_number
          param.api_area.salary.dependent_list.push(pushData)
        }
        // 有給を管理しない場合クリアする
        if (param.api_area.basic.paid_leave_payment === '2') {
          param.api_area.basic.paid_reference_date = ''
          param.api_area.basic.number_of_working_days_per_week = 0
        } else {
          // 有給支給年度別勘定
          for (const data of this.$refs.employeePaidLeavePaymentInfo.paid_annual_account_record) {
            // 削除がチェックされていたらスキップ
            if (data.selected) {
              continue
            }
            let pushData = {
              payment_date: '',
              payment_days: 0,
              payment_times: 0,
              payment_reason: 0,
              expiration_date: '',
              expiration_days: 0,
              expiration_times: 0,
              expiration_reason: 0,
              hold_days: 0,
              hold_times: 0
            }
            pushData.payment_date = this.getFormatDate(data.payment_date)
            pushData.payment_days = Number(data.payment_days)
            pushData.payment_times = Number(data.payment_times)
            pushData.payment_reason = Number(data.payment_reason)
            pushData.expiration_date = this.getFormatDate(data.expiration_date)
            pushData.expiration_days = this.getNumber(data.expiration_days)
            pushData.expiration_times = this.getNumber(data.expiration_times)
            pushData.expiration_reason = data.expiration_reason === null ? '' : String(data.expiration_reason)
            pushData.hold_days = Number(data.hold_days)
            pushData.hold_times = Number(data.hold_times)
            if (data.id) {
              pushData.id = Number(data.id)
              pushData.update_count = Number(data.update_count)
            }
            param.api_area.paid_annual_account_employee_list.push(pushData)
          }
        }
        // 申請情報
        for (const data of this.$refs.employeeApplicationInfo.application_record) {
          let paramData = {
            application_form_code: data.application_form_code,
            employee_application_control: Number(data.employee_application_control)
          }
          param.api_area.application_form_list.push(paramData)
        }
        // 勤務体系
        for (const data of this.$refs.employeeWorkingScheduleInfo.working_schedule_record) {
          // 割当がチェックされていなかったらスキップ
          if (!data.selected) {
            continue
          }
          let paramData = {
            work_schedule_code: data.work_schedule_code,
            default_work_schedule_code: 2,
            off_site_deemed_working_hours_flag: data.off_site_deemed_working_hours_flag,
            is_need_gps: data.is_need_gps,
            is_disp_late_time: !data.is_disp_late_time
          }
          if (this.$refs.employeeWorkingScheduleInfo.selectDefaultWorkingSchedule === data.work_schedule_code) {
            paramData.default_work_schedule_code = 1
          }
          param.api_area.working_schedule_employee_list.push(paramData)
        }
        // 通勤費
        for (const employeeCommuteRecord of this.$refs.employeeSalaryInfo.employee_commute_record) {
          // 削除がチェックされていたらスキップ
          if (employeeCommuteRecord.selected) {
            continue
          }
          let paramData = {
            traffic_division: Number(employeeCommuteRecord.traffic_division),
            distance_to_use_transportation_equipment: employeeCommuteRecord.distance_to_use_transportation_equipment === '' ? null : Number(employeeCommuteRecord.distance_to_use_transportation_equipment),
            payment_unit: Number(employeeCommuteRecord.payment_unit),
            target_month: employeeCommuteRecord.target_date === '' ? null : Number(employeeCommuteRecord.target_date),
            payment_amount: this.amountToNumber(employeeCommuteRecord.payment_amount),
            transportation_name: employeeCommuteRecord.transportation_name === '' ? null : employeeCommuteRecord.transportation_name,
            start_section: employeeCommuteRecord.start_section === '' ? null : employeeCommuteRecord.start_section,
            end_section: employeeCommuteRecord.end_section === '' ? null : employeeCommuteRecord.end_section
          }
          param.api_area.salary.employee_commute_list.push(paramData)
        }
        // 賃金管理をしない場合、salaryを空にする
        if (this.$refs.employeeBasicInfo.payroll_management.value === '2') {
          let dependentList = param.api_area.salary.dependent_list
          param.api_area.salary = {
            dependent_list: dependentList
          }
        }
        return param
      },
      isEnd (termTo) {
        if (typeof termTo === 'object') {
          termTo = this.formatDate(termTo)
        }
        if (termTo === null || termTo === '') {
          return false
        }
        termTo = termTo.split('/')
        termTo = termTo.join('')
        if (Number(termTo) < this.today) {
          return true
        }
      },
      getButtonName () {
        if (this.mode === this.modes.add) {
          return this.$t('button.add')
        } else if (this.mode === this.modes.edit) {
          return this.$t('button.update')
        } else {
          return this.$t('button.delete')
        }
      },
      getValue (param) {
        if (param === undefined || param === null || param === '') {
          return ''
        }
        return param
      },
      getNumber (param) {
        if (param === undefined || param === null || param === '') {
          return 0
        }
        return Number(param)
      },
      getCityCode (value) {
        if (value !== null) {
          let result = value.split('_')
          return result[1]
        }
      },
      wizardComplete () {
        let employee = null
        let operation = ''
        if (this.mode === this.modes.add || this.mode === this.modes.edit) {
          employee = this.createParam()
          // 更新の場合update_countをセットする
          if (this.mode === this.modes.edit) {
            // is_send_registration_completion_emailも削除する
            delete employee.api_area.is_send_registration_completion_email
            employee.api_area.basic.update_count = this.resData.api_area.basic.update_count
            if (this.$refs.employeeBasicInfo.force_password_change) {
              employee.api_area.basic.force_password_change = this.$refs.employeeBasicInfo.force_password_change
            } else {
              employee.api_area.basic.password = undefined
            }
            operation = 'update'
          } else {
            operation = 'insert'
          }
        } else if (this.mode === this.modes.delete) {
          employee = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              employee_code: this.$refs.employeeBasicInfo.employee_code,
              employee_name: this.$refs.employeeBasicInfo.employee_name,
              update_count: this.resData.api_area.basic.update_count,
              confirmed: false
            }
          }
          operation = 'delete'
        }
        if (operation !== 'delete') {
          this.send(`/${operation}/employee`, employee, false)
          .then(res => {
            if (res.api_area && res.api_area.confirmation_required) {
              swal({
                html: res.message.text + '<br>' + res.message.correspondence_action,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('button.yes'),
                cancelButtonText: this.$t('button.no')
              }).then(() => {
                employee.api_area.confirmed = true
                this.send(`/${operation}/employee`, employee)
                .then(res => {
                  this.afterSend(res, employee)
                }).catch(error => {
                  console.error(error)
                })
              }).catch(() => {
                this.executeFlg = false
                this.finishFlg = false
              })
              return
            } else {
              this.message = res.message
            }
            this.afterSend(res, employee)
          }).catch(error => {
            console.error(error)
          })
        } else {
          this.send(`/${operation}/employee`, employee, false)
          .then(res => {
            if (res.api_area !== null && res.api_area.confirmation_required) {
              swal({
                html: res.message.text + '<br>' + res.message.correspondence_action,
                buttonsStyling: false,
                confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('button.yes'),
                cancelButtonText: this.$t('button.no')
              }).then(() => {
                employee.api_area.confirmed = true
                this.send(`/${operation}/employee`, employee)
                .then(res => {
                  this.componentKey += 1
                  if (res.message.message_classification === 1) {
                    this.isChanged = true
                  }
                  this.executeFlg = true
                  this.finishFlg = true
                }).catch(error => {
                  console.error(error)
                })
              }).catch(() => {})
            } else {
              this.message = res.message
              this.help = res.common_area.help
              this.componentKey += 1
              if (res.message.message_classification === 1) {
                this.isChanged = true
              }
              this.executeFlg = true
              this.finishFlg = true
            }
          }).catch(error => {
            console.error(error)
          })
        }
      },
      afterSend (res, employee) {
        this.componentKey += 1
        if (res.message.message_classification === 1) {
          this.isChanged = true
        }
        this.executeFlg = true
        this.finishFlg = true
        if (this.$store.state.loginUser.employeeCode === this.$refs.employeeBasicInfo.employee_code) {
          // 部署が変わった時の処理
          let changeGroupCode = ''
          for (const updateGroup of employee.api_area.group_list) {
            if (updateGroup.default_group_code === 1) {
              changeGroupCode = updateGroup.group_code
              break
            }
          }
          if (this.$store.state.loginUser.groupCode !== changeGroupCode) {
            swal({
              html: this.$t('message.group_changed'),
              type: 'warning',
              confirmButtonClass: 'btn btn-warning btn-sm btn-default',
              buttonsStyling: false,
              allowOutsideClick: false
            }).then(() => {
              this.logout().then(() => {
                window.location = '/'
              })
            })
          } else {
            const employeeDashboardList = []
            for (const row of this.$store.state.dashboardList) {
              if (row.sort_number > 0) {
                employeeDashboardList.push(row)
              }
            }
            const sidebarLinks = this.getSidebarLinks(this, 2,
              res.common_area.combo_data.menu_screen_employee_list, employeeDashboardList, this.$store.state.dbList)
            const loginInfo = this.$store.state.loginUser
            loginInfo.sidebarLinks = sidebarLinks
            loginInfo.employeeName = this.$refs.employeeBasicInfo.employee_name
            this.$store.commit('save', loginInfo)
          }
          let language
          switch (employee.api_area.basic.language) {
            case 'EN':
              language = 'en'
              break
            default:
              language = 'ja'
              break
          }
          const self = this
          const iconUrl = self.$store.state.iconUrl.split('?')[0]
          const extension = iconUrl.substring(iconUrl.lastIndexOf('.'))
          const httpsReference = this.$firebase.storage()
            .ref()
            .child(`employee/${this.$store.state.loginUser.companyCode}/${this.$refs.employeeBasicInfo.employee_code}${extension}`)
          httpsReference.getDownloadURL().then(function (url) {
            self.$store.commit('setIconUrl', url)
            if ((self.$i18n.locale === 'en' && language !== 'en') || (self.$i18n.locale !== 'en' && language === 'en')) {
              self.$i18n.locale = language
              localStorage.setItem('language', language)
              window.location.reload()
            }
          }).catch(() => {
            self.$store.commit('setIconUrl', '')
            if ((self.$i18n.locale === 'en' && language !== 'en') || (self.$i18n.locale !== 'en' && language === 'en')) {
              self.$i18n.locale = language
              localStorage.setItem('language', language)
              window.location.reload()
            }
          })
        }
      },
      init () {
      }
    },
    watch: {
      'wizard.activeTabIndex': function () {
        this.setHelp(this.wizard.activeTabIndex)
      }
    }
  }
</script>
<style>
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #000000 !important;
    color: white;
  }
  .vue-form-wizard .wizard-nav-pills>li>a {
    color: #000000 !important;
  }
  .card-wizard {
    background-color: #EEEEEE !important;
  }
  .wizard-header {
    display:none;
  }
  .vue-form-wizard.md .wizard-icon-circle.tab_shape {
    height: 40px !important;
  }
  .number {
    text-align: right !important;
  }
</style>
