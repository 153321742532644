<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <div class="col-md-12">
      <modal name="monitoringConditions" :draggable="draggable" :clickToClose="false" height="900" :width="modalWidth" :pivotX="0.9">
        <div class="modal-body">
          <!-- 監視条件登録 -->
          <LegalRuleSurveillanceCondition :mode="mode" :params="params" @close="hideCondition"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="detail" :draggable="draggable" :clickToClose="false" height="900" :width="modalWidth" :pivotX="0.9">
        <div class="modal-body">
          <!-- 詳細画面 -->
          <LegalRuleSurveillanceDetail :mode="mode" :params="params" @close="hide"/>
        </div>
      </modal>
    </div>
    <div class="col-md-12">
      <modal name="warning" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidthSub">
        <div class="modal-body">
          <!-- 警告一覧 -->
          <LegalRuleSurveillanceWarningDetail :mode="mode" :params="params" :record="errorList" :legal_rule_url="legal_rule_url" :labels="labels" @close="hideWarning"/>
        </div>
      </modal>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <EmployeesSearch
            :orgSearchCond="searchCond"
            :labels="labels"
            :comboData="comboData"
            :disabled="isChanged"
            :show-search-all="true"
            :showAddBtn="false"
            :hireYearMode="false"
            :legalRuleMode="true"
            :targetTerms="targetTerms"
            @reaggregate="reaggregateClicked"
            @search="searchClicked"
            @addMonitoringConditions="addMonitoringConditions"
          />
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData.length > 0 && searchCond.legal_rule_disp_type === 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
            <!-- 再計算ボタン -->
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style="font-size: 14px;">
              {{ $t('label.aggregate_date') + updateDate }}
            </span>
            &nbsp;&nbsp;
            <button type="button" class="btn btn-search btn-sm btn-default" @click="reaggregateClicked">
              <span class="btn-label"><i class="ti-bar-chart"></i></span>
              {{$t('button.reaggregate')}}
            </button>
          </h4>
        </div>
        <div class="card-content">
          <span style="font-weight: bold">期間：</span>&nbsp;&nbsp;{{ targetTerm }}
          <div style="margin-top: 10px; margin-left:10px">
            <div :style="tableWrapperStyle">
              <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                        :data="tableData"
                        ref="table"
                        :key="componentKey"
                        :cell-class-name="tableCellClassName"
                        style="width: 1350px">
                <el-table-column min-width="100" label="従業員" fixed="left">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <div style="font-size: medium; font-weight: bold;">
                        <a style="color: #317FB8; cursor: pointer;" @click="showDetail(props.row)">{{props.row.employee_name}}</a>
                      </div>
                      {{props.row.employee_code}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="80">
                  <template slot-scope="props">
                    <div style="text-align: left;">
                      <div>
                        労働時間
                      </div>
                      <div>
                        所定外
                      </div>
                      <div>
                        法定外
                      </div>
                      <div style="height: 1px; border-bottom: 1px solid #ccc;"></div>
                      <div>
                        所定休日
                      </div>
                      <div>
                        法定休日
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <template v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]">
                  <el-table-column min-width="80" :label="getDispTargetMonthNoBr(disp_from[0], disp_from[1], i)">
                    <template slot="header" slot-scope="scope">
                      <div class="phase-header tableHeader">
                        <div class="phase-header__title">{{scope.column.label}}</div>
                      </div>
                    </template>
                    <template slot-scope="props">
                      <div style="text-align: right;">
                        <div>
                          <template v-if="isError(props.row.legal_rule_violator_month_list[i], 1)">
                            <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewError(props.row.legal_rule_violator_month_list[i], 1, props.row.legal_rule_url)">
                              {{getDipsRealTotalMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else-if="isWarning(props.row.legal_rule_violator_month_list[i], 1)">
                            <div style="font-size: medium; font-weight: bold; color: #ffa500; cursor: pointer;" @click="viewWarning(props.row.legal_rule_violator_month_list[i], 1, props.row.legal_rule_url)">
                              {{getDipsRealTotalMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{getDipsRealTotalMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-if="isError(props.row.legal_rule_violator_month_list[i], 3)">
                            <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewError(props.row.legal_rule_violator_month_list[i], 3, props.row.legal_rule_url)">
                              {{getDipsJobOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else-if="isWarning(props.row.legal_rule_violator_month_list[i], 3)">
                            <div style="font-size: medium; font-weight: bold; color: #ffa500; cursor: pointer;" @click="viewWarning(props.row.legal_rule_violator_month_list[i], 3, props.row.legal_rule_url)">
                              {{getDipsJobOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{getDipsJobOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-if="isError(props.row.legal_rule_violator_month_list[i], 7)">
                            <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewError(props.row.legal_rule_violator_month_list[i], 7, props.row.legal_rule_url)">
                              {{getDipsLegalOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else-if="isWarning(props.row.legal_rule_violator_month_list[i], 7)">
                            <div style="font-size: medium; font-weight: bold; color: #ffa500; cursor: pointer;" @click="viewWarning(props.row.legal_rule_violator_month_list[i], 7, props.row.legal_rule_url)">
                              {{getDipsLegalOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{getDipsLegalOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <div :style="!getDipsRealTotalMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list) ? '' : 'height: 1px; border-bottom: 1px solid #ccc;'"></div>
                          <template v-if="isError(props.row.legal_rule_violator_month_list[i], 5)">
                            <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewError(props.row.legal_rule_violator_month_list[i], 5, props.row.legal_rule_url)">
                              {{getDipsJobHolidayHours(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else-if="isWarning(props.row.legal_rule_violator_month_list[i], 5)">
                            <div style="font-size: medium; font-weight: bold; color: #ffa500; cursor: pointer;" @click="viewWarning(props.row.legal_rule_violator_month_list[i], 5, props.row.legal_rule_url)">
                              {{getDipsJobHolidayHours(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{getDipsJobHolidayHours(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-if="isError(props.row.legal_rule_violator_month_list[i], 9)">
                            <div style="font-size: medium; font-weight: bold; color: #ff0000; cursor: pointer;" @click="viewError(props.row.legal_rule_violator_month_list[i], 9, props.row.legal_rule_url)">
                              {{getDipsLegalHolidayOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else-if="isWarning(props.row.legal_rule_violator_month_list[i], 9)">
                            <div style="font-size: medium; font-weight: bold; color: #ffa500; cursor: pointer;" @click="viewWarning(props.row.legal_rule_violator_month_list[i], 9, props.row.legal_rule_url)">
                              {{getDipsLegalHolidayOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                          <template v-else>
                            <div>
                              {{getDipsLegalHolidayOverworkMinutes(disp_from[0], disp_from[1], i, props.row.legal_rule_violator_month_list)}}
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </template>
                <el-table-column min-width="150" label="有給消化率">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="width: 80%; margin-left: auto; margin-right: auto;">
                      <Gauge
                        :min="0"
                        :max="100"
                        v-model="props.row.digestion_days"
                        :threshold="100"
                        :decimals="0"
                        color="arc below-needle3"
                        :max-score="100"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="otherTable.length > 0 && searchCond.legal_rule_disp_type === 1">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div style="margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="otherTable"
                      :key="componentKey"
                      style="width: 1600px">
              <el-table-column min-width="100" label="従業員" fixed="left">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold;">
                    {{props.row.employee_name}}
                    </div>
                    {{props.row.employee_code}}
                    <br>
                    {{props.row.employee_classification_name}}
                    <br>
                    {{props.row.office_name}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="対象日">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold;">
                      {{props.row.targetDate}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="区分">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold;">
                      {{props.row.division}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="監視項目">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold;">
                      {{getErrorItem(props.row.error_item)}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="データ">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold;">
                      {{getActualTime(props.row.actual_time, props.row.data_unit)}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="400" label="違反内容">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold; word-break: break-all;">
                      {{props.row.chapter_contents}}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="600" label="違反内容詳細">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <div style="font-size: medium; font-weight: bold; word-break: break-all;">
                      {{props.row.contents}}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import LegalRuleSurveillanceSearchMixin from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceSearchMixin'

  export default {
    name: 'LegalRuleSurveillanceSearch',
    methods: {
      convertSearchCond (searchCond) {
        let type = []
        for (const item of searchCond.notice_type.items) {
          if (item.value) {
            type.push(item.id)
          }
        }
        searchCond = {
          notice_date_from: searchCond.notice_date[0] === null || searchCond.notice_date[0] === '' ? '2000/01/01' : this.formatDate(searchCond.notice_date[0]),
          notice_date_to: searchCond.notice_date[1] === null || searchCond.notice_date[1] === '' ? '2999/12/31' : this.formatDate(searchCond.notice_date[1]),
          notice_type: type,
          is_unread: !searchCond.retirement
        }
        return searchCond
      }
    },
    mixins: [LegalRuleSurveillanceSearchMixin]
  }
</script>
<style>
  .el-table .red-row {
    background-color: #f0f8ff !important;
  }
  .el-table__body tr.hover-row .error-cell {
    background-color: #ffe0eb !important;
  }
  .warning-cell {
    background-color: #faffc5 !important;
  }
</style>
