<template>
  <div
    :class="sidebarClasses"
    :data-background-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <template v-if="title === $t('label.user_logo_title')">
        <a
          class="simple-text logo-normal"
          href="/#/user/menu"
        >
          <div style="overflow: hidden; text-overflow: ellipsis; margin-left: 30px; margin-top: 10px;">
            {{ title }}
          </div>
        </a>
      </template>
      <template v-else-if="logoUrl">
        <a
          class="simple-text logo-mini"
          href="/#/DB/menuDBScreen001"
        >
          <div class="logo-img">
            <img :src="logoUrl" alt />
          </div>
        </a>
      </template>
      <template v-else>
        <a
          class="simple-text logo-normal"
          href="/#/DB/menuDBScreen001"
        >
          <div style="overflow: hidden; text-overflow: ellipsis; margin-left: 30px; margin-top: 10px;">
            {{ title }}
          </div>
        </a>
      </template>
    </div>
    <div style="position: relative; z-index: 4;">
      <slot></slot>
    </div>
    <VuePerfectScrollbar class="scrollbar-area sidebar-wrapper" :settings="settings">
      <ul :class="navClasses">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            ></sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
      <br>
      <br>
      <br>
      <br>
      <br>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      VuePerfectScrollbar
    },
    props: {
      title: {
        type: String,
        default: 'Artemis'
      },
      type: {
        type: String,
        default: 'sidebar',
        validator: (value) => {
          let acceptedValues = ['sidebar', 'navbar']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black', 'artemis']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      logo: {
        type: String,
        default: 'static/img/LOGO_A1.png'
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        settings: {
          maxScrollbarLength: 400
        }
      }
    },
    computed: {
      sidebarClasses () {
        if (this.type === 'sidebar') {
          return 'sidebar'
        } else {
          return 'collapse navbar-collapse off-canvas-sidebar'
        }
      },
      navClasses () {
        if (this.type === 'sidebar') {
          return 'nav'
        } else {
          return 'nav navbar-nav'
        }
      },
      logoUrl () {
        return this.$store.state.logoUrl.length > 0 ? this.$store.state.logoUrl : undefined
      }
    },
    mounted () {
      if (window.innerWidth < 1920) {
        if (!this.$sidebar.isMinimized) {
          this.$sidebar.toggleMinimize()
        }
      }
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
  /* .logo-img img {
    max-width: 24px !important;
  } */
  .scrollbar-area {
    max-height: calc(100vh - 160px);
  }
</style>
