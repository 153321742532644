import { render, staticRenderFns } from "./LegalRuleSurveillanceWarningDetail.vue?vue&type=template&id=962c4042&scoped=true"
import script from "./LegalRuleSurveillanceWarningDetail.vue?vue&type=script&lang=js"
export * from "./LegalRuleSurveillanceWarningDetail.vue?vue&type=script&lang=js"
import style0 from "./LegalRuleSurveillanceWarningDetail.vue?vue&type=style&index=0&id=962c4042&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962c4042",
  null
  
)

export default component.exports