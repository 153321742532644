<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth" :pivotX="0.6">
      <div class="modal-body">
        <Detail :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.notice_number}}</label>
            <input type="text" :placeholder="$t('placeholder.notice_number')" maxlength="10" class="form-control length10" v-model="searchCond.notice_number">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.notification}}</label>
            <input type="text" :placeholder="$t('placeholder.notification')" maxlength="4096" class="form-control length8192" v-model="searchCond.notification">
          </div>
          <div class="form-group search-condition-item" style="display: inline-block">
            <label>{{labels.display_date}}</label>
            <div>
              <el-date-picker
                :placeholder="$t('placeholder.display_date')"
                maxlength="0"
                v-model="searchCond.display_date"
                type="date"
                name="display_date"
              >
              </el-date-picker>
            </div>
          </div>
          <ValidationSelect
            v-model="searchCond.notice_type"
            item-name="notice_type"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.notice_type_list)"
            :item-label="labels.notice_type_ryaku === undefined ? labels.noticeType_ryaku === undefined ? '' : labels.noticeType_ryaku : labels.notice_type_ryaku"
            :options="comboData.notice_type_list"
            frame-class="search-condition-item"
            @change="noticeTypeChanged"
          />
          <div class="search-button-area">
            <template v-if="labels.entry === 1">
              <button type="button" class="btn btn-primary btn-sm btn-default" @click="showAdd">
                <span class="btn-label"><i class="ti-settings"></i></span>
                {{$t('button.new')}}
              </button>
            </template>
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="display: inline-block; vertical-align: top; margin-top: 10px;"><div :style="tableWrapperStyle">
            <el-table class="table-width"
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      border
                      :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <el-table-column :width="130"
                               v-if="showOperation"
                               :key="componentKey"
                               :label="$t('label.operation')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="labels.preview === 1">
                    <a class="btn btn-simple btn-icon like" @click="handleView(props.$index, props.row)"><i class="ti-file" :title="$t('button.view')"></i></a>
                  </template>
                  <template v-if="labels.update === 1 && canUpdate(props.row)">
                    <a class="btn btn-simple btn-icon edit" @click="handleEdit(props.$index, props.row)"><i class="ti-pencil-alt" :title="$t('button.update')"></i></a>
                  </template>
                  <template v-if="labels.delete === 1 && canDelete(props.row)">
                    <a class="btn btn-simple btn-icon btn-danger remove" @click="handleDelete(props.$index, props.row)"><i class="ti-close" :title="$t('button.delete')"></i></a>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-if="showConfirmation" :width="getButtonColumnWidth([$t('button.confirmation')])" label="">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <button type="button" class="btn btn-warning btn-sm btn-default" @click="confirmationClicked(props.$index, props.row)">{{$t('button.confirmation')}}</button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNoticeNumber" :width="getColumnWidth(tableData, labels.notice_number_ryaku === undefined ? labels.noticeNumber_ryaku === undefined ? '' : labels.noticeNumber_ryaku : labels.notice_number_ryaku, 'notice_number')" :label="labels.notice_number_ryaku === undefined ? labels.noticeNumber_ryaku === undefined ? '' : labels.noticeNumber_ryaku : labels.notice_number_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.notice_number}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNotification" :width="getColumnWidth(tableData, labels.notification_ryaku === undefined ? labels.notification_ryaku === undefined ? '' : labels.notification_ryaku : labels.notification_ryaku, 'notification')" :label="labels.notification_ryaku === undefined ? labels.notification_ryaku === undefined ? '' : labels.notification_ryaku : labels.notification_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.notification}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showContents" :width="getColumnWidth(tableData, labels.contents_ryaku === undefined ? labels.contents_ryaku === undefined ? '' : labels.contents_ryaku : labels.contents_ryaku, 'contents')" :label="labels.contents_ryaku === undefined ? labels.contents_ryaku === undefined ? '' : labels.contents_ryaku : labels.contents_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.contents}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNotificationTransmissionDate" :width="getColumnWidth(tableData, labels.notification_transmission_date_ryaku === undefined ? labels.notificationTransmissionDate_ryaku === undefined ? '' : labels.notificationTransmissionDate_ryaku : labels.notification_transmission_date_ryaku, 'notification_transmission_date')" :label="labels.notification_transmission_date_ryaku === undefined ? labels.notificationTransmissionDate_ryaku === undefined ? '' : labels.notificationTransmissionDate_ryaku : labels.notification_transmission_date_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.notification_transmission_date}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNotificationDisplayDateFrom" :width="getColumnWidth(tableData, labels.notification_display_date_from_ryaku === undefined ? labels.notificationDisplayDateFrom_ryaku === undefined ? '' : labels.notificationDisplayDateFrom_ryaku : labels.notification_display_date_from_ryaku, 'notification_display_date_from')" :label="labels.notification_display_date_from_ryaku === undefined ? labels.notificationDisplayDateFrom_ryaku === undefined ? '' : labels.notificationDisplayDateFrom_ryaku : labels.notification_display_date_from_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.notification_display_date_from}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNotificationDisplayDateTo" :width="getColumnWidth(tableData, labels.notification_display_date_to_ryaku === undefined ? labels.notificationDisplayDateTo_ryaku === undefined ? '' : labels.notificationDisplayDateTo_ryaku : labels.notification_display_date_to_ryaku, 'notification_display_date_to')" :label="labels.notification_display_date_to_ryaku === undefined ? labels.notificationDisplayDateTo_ryaku === undefined ? '' : labels.notificationDisplayDateTo_ryaku : labels.notification_display_date_to_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{props.row.notification_display_date_to}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="showNoticeType" :width="getColumnWidth(comboData.notice_type_list, labels.notice_type_ryaku === undefined ? labels.noticeType_ryaku === undefined ? '' : labels.noticeType_ryaku : labels.notice_type_ryaku, 'label', 'value')" :label="labels.notice_type_ryaku === undefined ? labels.noticeType_ryaku === undefined ? '' : labels.noticeType_ryaku : labels.notice_type_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    {{getLabel(getSelectName(props.row.notice_type, comboData.notice_type_list))}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="row" ref="pager">
              <div class="col-sm-4 pagination-info">
                <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
              </div>
              <div class="col-sm-8" style="padding: 0">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
  import OperationNoticeSearchMixin from '@/components/Dashboard/Views/Generated/OperationNoticeSearchMixin'

  export default {
    name: 'OperationNoticeSearch',
    methods: {
      convertSearchCond (searchCond) {
        if (searchCond.display_date) {
          searchCond.display_date = this.formatDate(searchCond.display_date)
        }
        searchCond.notice_number = searchCond.notice_number ? parseInt(searchCond.notice_number, 10) : null
        searchCond.notice_type = searchCond.notice_type ? parseInt(searchCond.notice_type, 10) : null
        return searchCond
      },
      afterInit () {
        this.labels.display_date = this.$t('label.display_date')
      },
      confirmationClicked (index, row) {
        this.$router.push({name: 'confirmationSearch', params: {noticeNumber: row.notice_number}})
      }
    },
    mixins: [OperationNoticeSearchMixin]
  }
</script>
<style scoped>
</style>
