<template>
  <div class="form-group">
    <label v-if="itemLabel">{{ viewStyle === '' ? itemLabel : '　' }}</label><span class="required" v-show="itemLabel && viewStyle === '' && rules && rules.indexOf('required') > -1">{{$t('label.required')}}</span><span v-show="hasHelp" class="ti-help-alt" v-tooltip.right-end="$t('label.regulatory_grace_exclusion_help')" style="margin-left: 5px;"></span>
    <ValidationProvider ref="provider" v-slot="{ valid, validated, errors }" :rules="rules" :vid="vid" :name="itemLabelSub ? itemLabelSub + '|' + new Date().getTime().toString(16) : itemLabel + '|' + new Date().getTime().toString(16)">
      <small class="help-block" v-show="valid | !validated">
        {{ helpText ? helpText : ''}}
      </small>
      <div class="error-message-wrapper" v-show="!valid">
        <small class="text-danger">
          {{ errors[0] ? errors[0].indexOf('|') === -1 ? errors[0] : errors[0].replace(errors[0].substring(errors[0].indexOf('|'), errors[0].indexOf('|') + 12), '') : '' }}
        </small>
      </div>
      <div :style="viewStyle">
        <template v-for="(item, index) in obj.items">
          <template v-if="index !== 0 && index % columns === 0">
            <br v-bind:key="item.id">
          </template>
          <p-radio 
            v-bind:key="item.id"
            v-model="obj.value" 
            :style="item.hide ? 'visibility: hidden': ''"
            :label="item.value" 
            :inline="inline"
            :disabled="item.disabled || obj.disabled || disabled"
            @click="onClick"
            @change="$emit('change')"
          >
            {{item.label}}　
          </p-radio>
        </template>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VTooltip from 'v-tooltip'
  import mixin from '@/lib/mixin'

  Vue.use(VTooltip)

  export default {
    mixins: [mixin],
    props: {
      rules: String,
      value: Object,
      itemLabel: String,
      helpText: String,
      disabled: Boolean,
      columns: {
        type: Number,
        require: false,
        'default': 99
      },
      viewStyle: {
        type: String,
        require: false,
        'default': ''
      },
      itemLabelSub: {
        type: String,
        require: false,
        'default': ''
      },
      hasHelp: {
        type: Boolean,
        require: false,
        'default': false
      },
      inline: {
        type: Boolean,
        require: false,
        'default': true
      },
      vid: {
        type: String,
        require: false,
        'default': ''
      }
    },
    computed: {
      isExist () {
        return this.obj !== undefined && this.obj.items !== undefined
      }
    },
    data () {
      return {
        obj: {
          value: undefined,
          disabled: true,
          items: []
        }
      }
    },
    methods: {
      onClick () {
        this.$emit('input', this.obj)
        this.$emit('click')
      }
    },
    watch: {
      value () {
        this.obj = this.value
      }
    },
    mounted () {
      if (this.value) {
        this.obj = this.value
      }
    }
  }
</script>

<style scoped>
</style>