import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import LegalRuleSurveillanceCondition from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceCondition'
import LegalRuleSurveillanceDetail from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceDetail'
import LegalRuleSurveillanceWarningDetail from '@/components/Dashboard/Views/Analysis/LegalRuleSurveillanceWarningDetail'
import EmployeesSearch from '@/components/UIComponents/Inputs/EmployeesSearch'
import Gauge from '@/components/UIComponents/Inputs/Gauge'
import mixin from '@/lib/mixin'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'LegalRuleSurveillanceSearchMixin',
  mixins: [mixin],
  components: {
    LegalRuleSurveillanceCondition,
    LegalRuleSurveillanceDetail,
    LegalRuleSurveillanceWarningDetail,
    PPagination,
    EmployeesSearch,
    Gauge
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 1600,
      modalWidthSub: 800,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableData: [],
      searchCond: {
        legal_rule_disp_type: 0,
        search_type: 3,
        office_code: '',
        group_code: '',
        team_code: '',
        employee_code: '',
        employee_name: '',
        employee_classification_code: '',
        business_code: '',
        retirement: false,
        hireYear: false,
        target_term: ''
      },
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      targetTerms: null,
      errorList: [],
      legal_rule_url: '',
      disp_from: [],
      disp_to: [],
      realTotalMinutesErrorCount: 0,
      jobTotalMinutesErrorCount: 0,
      jobOverworkMinutesErrorCount: 0,
      jobHolidayDaysErrorCount: 0,
      jobHolidayHoursErrorCount: 0,
      legalJobMinutesErrorCount: 0,
      legalOverworkMinutesErrorCount: 0,
      legalHolidayOverworkDaysErrorCount: 0,
      legalHolidayOverworkMinutesErrorCount: 0,
      lateNightOverworkMinutesErrorCount: 0,
      breakMinutesErrorCount: 0,
      paidHolidayDaysErrorCount: 0,
      paidHolidayHoursErrorCount: 0,
      childTimeLeaveDaysErrorCount: 0,
      childTimeLeaveHoursErrorCount: 0,
      compensatoryHolidayDaysErrorCount: 0,
      leaveDaysErrorCount: 0,
      closedDaysErrorCount: 0,
      realTotalMinutesWarningCount: 0,
      jobTotalMinutesWarningCount: 0,
      jobOverworkMinutesWarningCount: 0,
      jobHolidayDaysWarningCount: 0,
      jobHolidayHoursWarningCount: 0,
      legalJobMinutesWarningCount: 0,
      legalOverworkMinutesWarningCount: 0,
      legalHolidayOverworkDaysWarningCount: 0,
      legalHolidayOverworkMinutesWarningCount: 0,
      lateNightOverworkMinutesWarningCount: 0,
      breakMinutesWarningCount: 0,
      paidHolidayDaysWarningCount: 0,
      paidHolidayHoursWarningCount: 0,
      childTimeLeaveDaysWarningCount: 0,
      childTimeLeaveHoursWarningCount: 0,
      compensatoryHolidayDaysWarningCount: 0,
      leaveDaysWarningCount: 0,
      closedDaysWarningCount: 0,
      otherTable: [],
      dispDataLabel: '労働時間',
      updateDate: ''
    }
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    targetTerm () {
      console.log(this.targetTerms, this.searchCond)
      if (this.targetTerms) {
        for (const row of this.targetTerms) {
          if (row.value === this.searchCond.target_term) {
            return row.label
          }
        }
      }
      return ''
    }
  },
  methods: {
    addMonitoringConditions () {
      this.$modal.show('monitoringConditions')
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      if (res.api_area.legal_rule_list === undefined || res.api_area.legal_rule_list === null || res.api_area.legal_rule_list.length === 0) {
        this.targetTerms = null
        this.searchCond.target_term = String(new Date().getFullYear())
      } else {
        this.targetTerms = []
        const currentYear = new Date().getFullYear()
        for (let year = 2009; year <= currentYear; year++) {
          const termFrom = year * 10000 + 101
          const termTo = year * 10000 + 1231
          let termFromEndDateLegalRule = null
          let termToStartDateLegalRule = null
          for (const legalRule of res.api_area.legal_rule_list) {
            const legalRuleFrom = Number(legalRule.term_from.replace(/\//g, ''))
            const legalRuleTo = Number(legalRule.term_to.replace(/\//g, ''))
            if (legalRuleFrom <= termFrom && termFrom <= legalRuleTo) {
              termFromEndDateLegalRule = legalRule
            }
            if (legalRuleFrom <= termTo && termTo <= legalRuleTo) {
              termToStartDateLegalRule = legalRule
            }
          }
          if (termFromEndDateLegalRule == null) {
            if (termToStartDateLegalRule == null) {
              let pushData = {
                label: year + '年(' + year + '/01/01 〜 ' + year + '/12/31)',
                value: year + '/01/01' + '@' + year + '/12/31',
                number: termFrom
              }
              this.targetTerms.push(pushData)
            } else {
              const endDate = this.dateAdd(termToStartDateLegalRule.term_from, -1)
              let pushData = {
                label: year + '年(' + year + '/01/01 〜 ' + endDate + ')',
                value: year + '/01/01' + '@' + year + '/12/31',
                number: termFrom
              }
              this.targetTerms.push(pushData)
              pushData = {
                label: termToStartDateLegalRule.combo_label,
                value: termToStartDateLegalRule.term_from + '@' + termToStartDateLegalRule.term_to,
                number: Number(termToStartDateLegalRule.term_from.replace(/\//g, ''))
              }
              this.targetTerms.push(pushData)
            }
          } else {
            if (termToStartDateLegalRule == null) {
              const startDate = this.dateAdd(termFromEndDateLegalRule.term_to, 1)
              let pushData = {
                label: year + '年(' + startDate + ' 〜 ' + year + '/12/31)',
                value: startDate + '@' + year + '/12/31',
                number: Number(startDate.replace(/\//g, ''))
              }
              this.targetTerms.push(pushData)
            }
          }
        }
        for (const legalRule of res.api_area.legal_rule_list) {
          let pushData = {
            label: legalRule.combo_label,
            value: legalRule.term_from + '@' + legalRule.term_to,
            number: Number(legalRule.term_from.replace(/\//g, ''))
          }
          let isExist = false
          for (const row of this.targetTerms) {
            if (row.label === pushData.label) {
              isExist = true
              break
            }
          }
          if (!isExist) {
            this.targetTerms.push(pushData)
          }
        }
        this.targetTerms.sort((a, b) => b.number - a.number)
        if (this.targetTerms.length > 0 && this.searchCond.target_term === '') {
          const today = this.formatToday()
          for (const row of this.targetTerms) {
            const term = row.value.split('@')
            if (this.compareDate(term[0], today) <= 0) {
              if (this.compareDate(term[1], today) >= 0) {
                this.searchCond.target_term = row.value
                break
              }
            }
          }
        }
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      this.realTotalMinutesErrorCount = 0
      this.jobTotalMinutesErrorCount = 0
      this.jobOverworkMinutesErrorCount = 0
      this.jobHolidayDaysErrorCount = 0
      this.jobHolidayHoursErrorCount = 0
      this.legalJobMinutesErrorCount = 0
      this.legalOverworkMinutesErrorCount = 0
      this.legalHolidayOverworkDaysErrorCount = 0
      this.legalHolidayOverworkMinutesErrorCount = 0
      this.lateNightOverworkMinutesErrorCount = 0
      this.breakMinutesErrorCount = 0
      this.paidHolidayDaysErrorCount = 0
      this.paidHolidayHoursErrorCount = 0
      this.childTimeLeaveDaysErrorCount = 0
      this.childTimeLeaveHoursErrorCount = 0
      this.compensatoryHolidayDaysErrorCount = 0
      this.leaveDaysErrorCount = 0
      this.closedDaysErrorCount = 0
      this.realTotalMinutesWarningCount = 0
      this.jobTotalMinutesWarningCount = 0
      this.jobOverworkMinutesWarningCount = 0
      this.jobHolidayDaysWarningCount = 0
      this.jobHolidayHoursWarningCount = 0
      this.legalJobMinutesWarningCount = 0
      this.legalOverworkMinutesWarningCount = 0
      this.legalHolidayOverworkDaysWarningCount = 0
      this.legalHolidayOverworkMinutesWarningCount = 0
      this.lateNightOverworkMinutesWarningCount = 0
      this.breakMinutesWarningCount = 0
      this.paidHolidayDaysWarningCount = 0
      this.paidHolidayHoursWarningCount = 0
      this.childTimeLeaveDaysWarningCount = 0
      this.childTimeLeaveHoursWarningCount = 0
      this.compensatoryHolidayDaysWarningCount = 0
      this.leaveDaysWarningCount = 0
      this.closedDaysWarningCount = 0
      this.otherTable = []
      for (let row of this.tableData) {
        let otherTablePushData = {
          employee_code: row.employee_code,
          employee_name: row.employee_name,
          business_name: row.business_name,
          employee_classification_name: row.employee_classification_name
        }
        for (let month of row.legal_rule_violator_month_list) {
          if (!month.pan_error_list) month.pan_error_list = []
          for (let panError of month.pan_error_list) {
            otherTablePushData.targetDate = panError.target_date
            otherTablePushData.actual_time = panError.actual_time
            otherTablePushData.data_unit = panError.data_unit
            otherTablePushData.error_item = panError.error_item
            otherTablePushData.chapter_contents = panError.chapter_contents
            otherTablePushData.contents = panError.pan_error_contents
            otherTablePushData.division = '違反'
            this.otherTable.push(otherTablePushData)
            if (panError.error_item === 1) {
              this.realTotalMinutesErrorCount += 1
            } else if (panError.error_item === 2) {
              this.jobTotalMinutesErrorCount += 1
            } else if (panError.error_item === 3) {
              this.jobOverworkMinutesErrorCount += 1
            } else if (panError.error_item === 4) {
              this.jobHolidayDaysErrorCount += 1
            } else if (panError.error_item === 5) {
              this.jobHolidayHoursErrorCount += 1
            } else if (panError.error_item === 6) {
              this.legalJobMinutesErrorCount += 1
            } else if (panError.error_item === 7) {
              this.legalOverworkMinutesErrorCount += 1
            } else if (panError.error_item === 8) {
              this.legalHolidayOverworkDaysErrorCount += 1
            } else if (panError.error_item === 9) {
              this.legalHolidayOverworkMinutesErrorCount += 1
            } else if (panError.error_item === 10) {
              this.lateNightOverworkMinutesErrorCount += 1
            } else if (panError.error_item === 11) {
              this.breakMinutesErrorCount += 1
            } else if (panError.error_item === 12) {
              this.paidHolidayDaysErrorCount += 1
            } else if (panError.error_item === 13) {
              this.paidHolidayHoursErrorCount += 1
            } else if (panError.error_item === 14) {
              this.childTimeLeaveDaysErrorCount += 1
            } else if (panError.error_item === 15) {
              this.childTimeLeaveHoursErrorCount += 1
            } else if (panError.error_item === 16) {
              this.compensatoryHolidayDaysErrorCount += 1
            } else if (panError.error_item === 18) {
              this.leaveDaysErrorCount += 1
            } else if (panError.error_item === 19) {
              this.closedDaysErrorCount += 1
            }
          }
          if (!month.pan_warning_list) month.pan_warning_list = []
          for (let panWarning of month.pan_warning_list) {
            otherTablePushData.targetDate = panWarning.target_date
            otherTablePushData.actual_time = panWarning.actual_time
            otherTablePushData.data_unit = panWarning.data_unit
            otherTablePushData.error_item = panWarning.error_item
            otherTablePushData.chapter_contents = panWarning.chapter_contents
            otherTablePushData.contents = panWarning.pan_error_contents
            otherTablePushData.division = '警告'
            this.otherTable.push(otherTablePushData)
            if (panWarning.error_item === 1) {
              this.realTotalMinutesWarningCount += 1
            } else if (panWarning.error_item === 2) {
              this.jobTotalMinutesWarningCount += 1
            } else if (panWarning.error_item === 3) {
              this.jobOverworkMinutesWarningCount += 1
            } else if (panWarning.error_item === 4) {
              this.jobHolidayDaysWarningCount += 1
            } else if (panWarning.error_item === 5) {
              this.jobHolidayHoursWarningCount += 1
            } else if (panWarning.error_item === 6) {
              this.legalJobMinutesWarningCount += 1
            } else if (panWarning.error_item === 7) {
              this.legalOverworkMinutesWarningCount += 1
            } else if (panWarning.error_item === 8) {
              this.legalHolidayOverworkDaysWarningCount += 1
            } else if (panWarning.error_item === 9) {
              this.legalHolidayOverworkMinutesWarningCount += 1
            } else if (panWarning.error_item === 10) {
              this.lateNightOverworkMinutesWarningCount += 1
            } else if (panWarning.error_item === 11) {
              this.breakMinutesWarningCount += 1
            } else if (panWarning.error_item === 12) {
              this.paidHolidayDaysWarningCount += 1
            } else if (panWarning.error_item === 13) {
              this.paidHolidayHoursWarningCount += 1
            } else if (panWarning.error_item === 14) {
              this.childTimeLeaveDaysWarningCount += 1
            } else if (panWarning.error_item === 15) {
              this.childTimeLeaveHoursWarningCount += 1
            } else if (panWarning.error_item === 16) {
              this.compensatoryHolidayDaysWarningCount += 1
            } else if (panWarning.error_item === 18) {
              this.leaveDaysWarningCount += 1
            } else if (panWarning.error_item === 19) {
              this.closedDaysWarningCount += 1
            }
          }
          if (month.daily_report_list) {
            for (let dailyReport of month.daily_report_list) {
              if (!dailyReport.pan_error_list) dailyReport.pan_error_list = []
              for (let panError of dailyReport.pan_error_list) {
                otherTablePushData.targetDate = panError.target_date
                otherTablePushData.actual_time = panError.actual_time
                otherTablePushData.data_unit = panError.data_unit
                otherTablePushData.error_item = panError.error_item
                otherTablePushData.chapter_contents = panError.chapter_contents
                otherTablePushData.contents = panError.pan_error_contents
                otherTablePushData.division = '違反'
                this.otherTable.push(otherTablePushData)
                if (panError.error_item === 1) {
                  this.realTotalMinutesErrorCount += 1
                } else if (panError.error_item === 2) {
                  this.jobTotalMinutesErrorCount += 1
                } else if (panError.error_item === 3) {
                  this.jobOverworkMinutesErrorCount += 1
                } else if (panError.error_item === 4) {
                  this.jobHolidayDaysErrorCount += 1
                } else if (panError.error_item === 5) {
                  this.jobHolidayHoursErrorCount += 1
                } else if (panError.error_item === 6) {
                  this.legalJobMinutesErrorCount += 1
                } else if (panError.error_item === 7) {
                  this.legalOverworkMinutesErrorCount += 1
                } else if (panError.error_item === 8) {
                  this.legalHolidayOverworkDaysErrorCount += 1
                } else if (panError.error_item === 9) {
                  this.legalHolidayOverworkMinutesErrorCount += 1
                } else if (panError.error_item === 10) {
                  this.lateNightOverworkMinutesErrorCount += 1
                } else if (panError.error_item === 11) {
                  this.breakMinutesErrorCount += 1
                } else if (panError.error_item === 12) {
                  this.paidHolidayDaysErrorCount += 1
                } else if (panError.error_item === 13) {
                  this.paidHolidayHoursErrorCount += 1
                } else if (panError.error_item === 14) {
                  this.childTimeLeaveDaysErrorCount += 1
                } else if (panError.error_item === 15) {
                  this.childTimeLeaveHoursErrorCount += 1
                } else if (panError.error_item === 16) {
                  this.compensatoryHolidayDaysErrorCount += 1
                } else if (panError.error_item === 18) {
                  this.leaveDaysErrorCount += 1
                } else if (panError.error_item === 19) {
                  this.closedDaysErrorCount += 1
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                otherTablePushData.targetDate = panWarning.target_date
                otherTablePushData.actual_time = panWarning.actual_time
                otherTablePushData.data_unit = panWarning.data_unit
                otherTablePushData.error_item = panWarning.error_item
                otherTablePushData.chapter_contents = panWarning.chapter_contents
                otherTablePushData.contents = panWarning.pan_error_contents
                otherTablePushData.division = '警告'
                this.otherTable.push(otherTablePushData)
                if (panWarning.error_item === 1) {
                  this.realTotalMinutesWarningCount += 1
                } else if (panWarning.error_item === 2) {
                  this.jobTotalMinutesWarningCount += 1
                } else if (panWarning.error_item === 3) {
                  this.jobOverworkMinutesWarningCount += 1
                } else if (panWarning.error_item === 4) {
                  this.jobHolidayDaysWarningCount += 1
                } else if (panWarning.error_item === 5) {
                  this.jobHolidayHoursWarningCount += 1
                } else if (panWarning.error_item === 6) {
                  this.legalJobMinutesWarningCount += 1
                } else if (panWarning.error_item === 7) {
                  this.legalOverworkMinutesWarningCount += 1
                } else if (panWarning.error_item === 8) {
                  this.legalHolidayOverworkDaysWarningCount += 1
                } else if (panWarning.error_item === 9) {
                  this.legalHolidayOverworkMinutesWarningCount += 1
                } else if (panWarning.error_item === 10) {
                  this.lateNightOverworkMinutesWarningCount += 1
                } else if (panWarning.error_item === 11) {
                  this.breakMinutesWarningCount += 1
                } else if (panWarning.error_item === 12) {
                  this.paidHolidayDaysWarningCount += 1
                } else if (panWarning.error_item === 13) {
                  this.paidHolidayHoursWarningCount += 1
                } else if (panWarning.error_item === 14) {
                  this.childTimeLeaveDaysWarningCount += 1
                } else if (panWarning.error_item === 15) {
                  this.childTimeLeaveHoursWarningCount += 1
                } else if (panWarning.error_item === 16) {
                  this.compensatoryHolidayDaysWarningCount += 1
                } else if (panWarning.error_item === 18) {
                  this.leaveDaysWarningCount += 1
                } else if (panWarning.error_item === 19) {
                  this.closedDaysWarningCount += 1
                }
              }
            }
          }
        }
      }
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 1
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          // 【おためし】一旦労働時間を格納
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.real_total_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      // データのソート
      for (let row of this.tableData) {
        let newList = []
        for (let index = 0; index < 12; index++) {
          let targetDate = this.getTargetMonth(this.disp_from[0], this.disp_from[1], index)
          for (let oneViolator of row.legal_rule_violator_month_list) {
            if (oneViolator.target_month === targetDate) {
              newList.push(oneViolator)
            }
          }
        }
        row.legal_rule_violator_month_list = newList
      }
      // 更新日の設定
      for (let row of this.tableData) {
        if (row.update_date) {
          this.updateDate = row.update_date
          break
        }
      }
      // this.$nextTick(() => this.$refs.RealTotalMinutes.focus())
    },
    changeDayData (year, month, offset) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      this.params = {
        targetMonth: String(targetYear) + '/' + strMonth,
        target_month_from: this.searchCond.from,
        target_month_to: this.searchCond.to
      }
      this.showDetail()
    },
    getTargetMonth (year, month, offset) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      return String(targetYear) + strMonth
    },
    getDispTargetMonth (year, month, offset) {
      let result = ''
      let targetYear = Number(year)
      let targetMonth = Number(month)
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      result = String(targetYear) + this.$t('label.year')
      result = result + '<br>'
      result = result + String(Number(strMonth)) + this.$t('label.month')
      result = result + '<br>'
      return result
    },
    getDispTargetMonthNoBr (year, month, offset) {
      let result = ''
      let targetMonth = Number(month)
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      result = result + String(Number(strMonth)) + this.$t('label.month')
      return result
    },
    getDipsParam (year, month, offset, rowData) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of rowData) {
        if (row.targetMonth === searchMonth) {
          if (row.dispParam === '') {
            return ''
          }
          if (row.type === 'minute') {
            let minute = row.dispParam % 60
            if (minute < 10) {
              minute = '0' + minute
            }
            return (row.dispParam / 60 | 0) + ':' + minute
          } else if (row.type === 'day') {
            return row.dispParam + '日'
          } else if (row.type === 'hour') {
            return row.dispParam + '時間'
          }
        }
      }
    },
    getDipsRealTotalMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.real_total_minutes === '') {
            return ''
          }
          let minute = row.real_total_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.real_total_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsJobTotalMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.job_total_minutes === '') {
            return ''
          }
          let minute = row.job_total_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.job_total_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsJobOverworkMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.job_overwork_minutes === '') {
            return ''
          }
          let minute = row.job_overwork_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.job_overwork_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsJobHolidayDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.job_holiday_days === '') {
            return ''
          }
          return row.job_holiday_days + '日'
        }
      }
    },
    getDipsJobHolidayHours (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.job_holiday_hours === '') {
            return ''
          }
          let minute = row.job_holiday_hours % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.job_holiday_hours / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsLegalJobMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.legal_job_minutes === '') {
            return ''
          }
          let minute = row.legal_job_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.legal_job_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsLegalOverworkMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.legal_overwork_minutes === '') {
            return ''
          }
          let minute = row.legal_overwork_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.legal_overwork_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsLegalHolidayOverworkDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.legal_holiday_overwork_days === '') {
            return ''
          }
          return row.legal_holiday_overwork_days + '日'
        }
      }
    },
    getDipsLegalHolidayOverworkMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.legal_holiday_overwork_minutes === '') {
            return ''
          }
          let minute = row.legal_holiday_overwork_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.legal_holiday_overwork_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsLateNightOverworkMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.late_night_overwork_minutes === '') {
            return ''
          }
          let minute = row.late_night_overwork_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.late_night_overwork_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsBreakMinutes (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.break_minutes === '') {
            return ''
          }
          let minute = row.break_minutes % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.break_minutes / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsPaidHolidayDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.paid_holiday_days === '') {
            return ''
          }
          return row.paid_holiday_days + '日'
        }
      }
    },
    getDipsPaidHolidayHours (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.paid_holiday_hours === '') {
            return ''
          }
          let minute = row.paid_holiday_hours % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.paid_holiday_hours / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsChildTimeLeaveDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.child_time_leave_days === '') {
            return ''
          }
          return row.child_time_leave_days + '日'
        }
      }
    },
    getDipsChildTimeLeaveHours (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.child_time_leave_hours === '') {
            return ''
          }
          let minute = row.child_time_leave_hours % 60
          if (minute < 10) {
            minute = '0' + minute
          }
          return (row.child_time_leave_hours / 60 | 0) + ':' + minute
        }
      }
    },
    getDipsCompensatoryHolidayDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.compensatory_holiday_days === '') {
            return ''
          }
          return row.compensatory_holiday_days + '日'
        }
      }
    },
    getDipsLeaveDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.leave_days === '') {
            return ''
          }
          return row.leave_days + '日'
        }
      }
    },
    getDipsClosedDays (year, month, offset, legalRuleViolatorMonthList) {
      let targetYear = Number(year)
      let targetMonth = Number(month)
      let searchMonth = ''
      targetMonth = targetMonth + offset
      if (targetMonth > 12) {
        targetYear = targetYear + 1
        targetMonth = targetMonth % 12
      }
      let strMonth = ''
      if (targetMonth < 10) {
        strMonth = '0' + String(targetMonth)
      } else {
        strMonth = String(targetMonth)
      }
      searchMonth = String(targetYear) + strMonth
      for (let row of legalRuleViolatorMonthList) {
        if (row.target_month === searchMonth) {
          if (row.closed_days === '') {
            return ''
          }
          return row.closed_days + '日'
        }
      }
    },
    // 労働時間に切り替え
    changeRealTotalMinutes () {
      this.dispDataLabel = '労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 1
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.real_total_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 所定労働時間に切り替え
    changeJobTotalMinutes () {
      this.dispDataLabel = '所定労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 2
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.job_total_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 所定外労働時間に切り替え
    changeJobOverworkMinutes () {
      this.dispDataLabel = '所定外労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 3
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.job_overwork_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 所定休日労働日数に切り替え
    changeJobHolidayDays () {
      this.dispDataLabel = '所定休日労働日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 4
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.job_holiday_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 所定休日労働時間に切り替え
    changeJobHolidayHours () {
      this.dispDataLabel = '所定休日労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 5
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.job_holiday_hours,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 法定労働時間に切り替え
    changeLegalJobMinutes () {
      this.dispDataLabel = '法定労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 6
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.legal_job_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 法定外労働時間に切り替え
    changeLegalOverworkMinutes () {
      this.dispDataLabel = '法定外労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 7
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.legal_overwork_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 法定休日労働日数に切り替え
    changeLegalHolidayOverworkDays () {
      this.dispDataLabel = '法定休日労働日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 8
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.legal_holiday_overwork_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 法定休日労働時間に切り替え
    changeLegalHolidayOverworkMinutes () {
      this.dispDataLabel = '法定休日労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 9
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.legal_holiday_overwork_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 深夜労働時間に切り替え
    changeLateNightOverworkMinutes () {
      this.dispDataLabel = '深夜労働時間'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 10
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.late_night_overwork_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 休憩時間に切り替え
    changeBreakMinutes () {
      for (let row of this.tableData) {
        this.dispDataLabel = '休憩時間'
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 11
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.break_minutes,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 有給日数に切り替え
    changePaidHolidayDays () {
      this.dispDataLabel = '有給日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 12
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.paid_holiday_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 有給時間数に切り替え
    changePaidHolidayHours () {
      this.dispDataLabel = '有給時間数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 13
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.paid_holiday_hours,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 育児休暇日数に切り替え
    changeChildTimeLeaveDays () {
      this.dispDataLabel = '育児休暇日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 14
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.child_time_leave_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 育児時間休暇数に切り替え
    changeChildTimeLeaveHours () {
      this.dispDataLabel = '育児時間休暇数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 15
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.child_time_leave_hours,
            type: 'minute',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 代休日数に切り替え
    changeCompensatoryHolidayDays () {
      this.dispDataLabel = '代休日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 16
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.compensatory_holiday_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 休職日数に切り替え
    changeLeaveDays () {
      this.dispDataLabel = '休職日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 18
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.leave_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 休業日数に切り替え
    changeClosedDays () {
      this.dispDataLabel = '休業日数'
      for (let row of this.tableData) {
        // 従業員毎のループ
        // 表示用データ初期化
        row.dispData = []
        let itemNumber = 19
        for (let month of row.legal_rule_violator_month_list) {
          // 月毎のループ
          // エラーがあるかチェック
          let errorList = []
          for (let panError of month.pan_error_list) {
            if (panError.error_item === itemNumber) {
              panError.errorFlg = true
              errorList.push(panError)
            }
          }
          for (let panWarning of month.pan_warning_list) {
            if (panWarning.error_item === itemNumber) {
              panWarning.errorFlg = false
              errorList.push(panWarning)
            }
          }
          if (month.daily_report_list.length > 0) {
            for (let dailyReport of month.daily_report_list) {
              for (let panError of dailyReport.pan_error_list) {
                if (panError.error_item === itemNumber) {
                  panError.errorFlg = true
                  errorList.push(panError)
                }
              }
              for (let panWarning of dailyReport.pan_warning_list) {
                if (panWarning.error_item === itemNumber) {
                  panWarning.errorFlg = false
                  errorList.push(panWarning)
                }
              }
            }
          }
          let pushData = {
            targetMonth: month.target_month,
            dispParam: month.closed_days,
            type: 'day',
            errorList: errorList
          }
          row.dispData.push(pushData)
        }
      }
      this.componentKey += 1
    },
    // 送信データ調整用
    convertSearchCond (searchCond) { return searchCond },
    doUpdate () {},
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    getButtonClass (index) {
      if (index === 1) {
        if (this.realTotalMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.realTotalMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 2) {
        if (this.jobTotalMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.jobTotalMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 3) {
        if (this.jobOverworkMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.jobOverworkMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 4) {
        if (this.jobHolidayDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.jobHolidayDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 5) {
        if (this.jobHolidayHoursErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.jobHolidayHoursWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 6) {
        if (this.legalJobMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.legalJobMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 7) {
        if (this.legalOverworkMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.legalOverworkMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 8) {
        if (this.legalHolidayOverworkDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.legalHolidayOverworkDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 9) {
        if (this.legalHolidayOverworkMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.legalHolidayOverworkMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 10) {
        if (this.lateNightOverworkMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.lateNightOverworkMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 11) {
        if (this.breakMinutesErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.breakMinutesWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 12) {
        if (this.paidHolidayDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.paidHolidayDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 13) {
        if (this.paidHolidayHoursErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.paidHolidayHoursWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 14) {
        if (this.childTimeLeaveDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.childTimeLeaveDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 15) {
        if (this.childTimeLeaveHoursErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.childTimeLeaveHoursWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 16) {
        if (this.compensatoryHolidayDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.compensatoryHolidayDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 18) {
        if (this.leaveDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.leaveDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
      if (index === 19) {
        if (this.closedDaysErrorCount > 0) {
          return 'btn btn-default btn-info'
        } else if (this.closedDaysWarningCount > 0) {
          return 'btn btn-default btn-search'
        } else {
          return 'btn btn-default'
        }
      }
    },
    getErrorItem (code) {
      for (let data of this.comboData.error_item_list) {
        if (data.value === String(code)) {
          return data.label
        }
      }
    },
    getActualTime (actualTime, dataUnit) {
      if (dataUnit === 1) {
        // HH 単位[時]
        return actualTime + this.$t('label.hours')
      } else if (dataUnit === 2) {
        // HHMM 単位[時分]
        return this.getHhmm(actualTime)
      } else if (dataUnit === 3) {
        // MM 単位[分]
        return actualTime + this.$t('label.minute')
      } else if (dataUnit === 4) {
        // YYYY 単位[年]
        return actualTime + this.$t('label.year')
      } else if (dataUnit === 5) {
        // YYYYMM 単位[年月]
        return this.getYyyymm(actualTime)
      } else if (dataUnit === 6) {
        // DAY 単位[日]
        return actualTime + this.$t('label.day')
      } else if (dataUnit === 7) {
        // COUNT 単位[回数]
        return actualTime + this.$t('label.count')
      } else if (dataUnit === 8) {
        // YEN 単位[円]
        return actualTime + this.$t('label.yen')
      }
    },
    tableCellClassName ({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return ''
      }
      // let searchMonth = this.getTargetMonth(this.disp_from[0], this.disp_from[1], rowIndex)
      if (!row.pan_error_list) {
        return ''
      }
      let itemNumber = columnIndex
      if (itemNumber === 17) {
        itemNumber = 18
      } else if (itemNumber === 18) {
        itemNumber = 19
      }
      for (let error of row.pan_error_list) {
        if (error.error_item === itemNumber) {
          return 'error-cell'
        }
      }
      for (let error of row.daily_report_list) {
        for (let panError of error.pan_error_list) {
          if (panError.error_item === itemNumber) {
            return 'error-cell'
          }
        }
      }
      return 'normal-cell'
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: this.searchCond.search_type,
          office_code: this.searchCond.office_code,
          team_code: this.searchCond.team_code,
          employee_code: this.searchCond.search_employee_code,
          employee_name: this.searchCond.search_employee_name,
          employee_classification_code: this.searchCond.employee_classification_code,
          group_code: this.searchCond.group_code,
          retirement: this.searchCond.retirement,
          business_type: this.searchCond.business_code,
          target_month_from: this.searchCond.from,
          target_month_to: this.searchCond.to,
          calc_flag: this.searchCond.calc_flag
        }
      }
      this.disp_from = this.searchCond.from.split('/')
      this.disp_to = this.searchCond.to.split('/')
      this.send('/view/legal_rule_violator/list', reqData)
      .then(res => {
        // const res = this.dummyResponse
        this.tableData = res.api_area
        let labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
        this.labels = {...this.labels, ...labels}
        for (const row of this.tableData) {
          for (const entry of Object.entries(row)) {
            if (entry[1] === null) {
              row[entry[0]] = ''
            }
          }
        }
        this.afterSearch(res)
        if (this.tableData.length > 0) {
          this.showSearchCondition = false
        }
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode
      }
      this.send('/init/legal_rule_violator/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
        if (this.$route.params.attendanceRecord) {
          this.searchCond.target_term = this.$route.params.attendanceRecord.target_month_from + '@' + this.$route.params.attendanceRecord.target_month_to
          this.searchClicked(this.searchCond)
        }
      })
    },
    reaggregateClicked () {
      this.searchCond.calc_flag = true
      this.search()
    },
    searchClicked (newSearchCond) {
      this.searchCond.calc_flag = false
      this.searchCond.legal_rule_disp_type = parseInt(newSearchCond.legal_rule_disp_type, 10)
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.searchCond.office_code = newSearchCond.office_code
      this.searchCond.group_code = newSearchCond.group_code
      this.searchCond.team_code = newSearchCond.team_code
      this.searchCond.search_employee_code = newSearchCond.employee_code
      this.searchCond.search_employee_name = newSearchCond.employee_name
      this.searchCond.employee_classification_code = newSearchCond.employee_classification_code
      this.searchCond.business_code = newSearchCond.business_code
      this.searchCond.search_retirement = newSearchCond.retirement
      this.searchCond.hireYearMode = newSearchCond.hireYear
      this.searchCond.target_term = newSearchCond.target_term
      this.searchCond.retirement = newSearchCond.retirement
      if (newSearchCond.target_term !== undefined) {
        let fromTo = newSearchCond.target_term.split('@')
        if (fromTo.length === 2) {
          this.searchCond.from = fromTo[0]
          this.searchCond.to = fromTo[1]
        } else {
          this.searchCond.from = newSearchCond.target_term + '/01/01'
          this.searchCond.to = newSearchCond.target_term + '/12/31'
        }
      }
      this.search()
    },
    showDetail (row) {
      this.params.row = row
      if (this.searchCond.target_term.length === 4) {
        this.params.target_term = this.searchCond.target_term + '/01/01@' + this.searchCond.target_term + '/12/31'
      } else {
        this.params.target_term = this.searchCond.target_term
      }
      this.$modal.show('detail')
    },
    isError (row, itemNumber) {
      if (!row) return false
      if (!row.pan_error_list && !row.daily_report_list) {
        return false
      } else {
        if (row.pan_error_list) {
          for (let error of row.pan_error_list) {
            if (error.error_item === itemNumber) {
              return true
            }
          }
        }
        if (row.daily_report_list) {
          for (let error of row.daily_report_list) {
            if (!error.pan_error_list) error.pan_error_list = []
            for (let panError of error.pan_error_list) {
              if (panError.error_item === itemNumber) {
                return true
              }
            }
          }
        }
      }
    },
    isWarning (row, itemNumber) {
      if (!row) return false
      if (!row.pan_warning_list && !row.daily_report_list) {
        return false
      } else {
        if (row.pan_warning_list) {
          for (let error of row.pan_warning_list) {
            if (error.error_item === itemNumber) {
              return true
            }
          }
        }
        if (row.daily_report_list) {
          for (let error of row.daily_report_list) {
            if (!error.pan_error_list) error.pan_warning_list = []
            for (let panError of error.pan_warning_list) {
              if (panError.error_item === itemNumber) {
                return true
              }
            }
          }
        }
      }
    },
    viewError (row, itemNumber, legalRuleUrl) {
      let errorList = []
      for (let errorData of row.pan_error_list) {
        if (errorData.error_item === itemNumber) {
          errorList.push(errorData)
        }
      }
      if (row.daily_report_list) {
        for (let errorData of row.daily_report_list) {
          for (let panError of errorData.pan_error_list) {
            if (panError.error_item === itemNumber) {
              errorList.push(panError)
            }
          }
        }
      }
      this.viewErrorWarning(row, errorList, legalRuleUrl)
    },
    viewWarning (row, itemNumber, legalRuleUrl) {
      let errorList = []
      for (let errorData of row.pan_warning_list) {
        if (errorData.error_item === itemNumber) {
          errorList.push(errorData)
        }
      }
      if (row.daily_report_list) {
        for (let errorData of row.daily_report_list) {
          for (let panError of errorData.pan_warning_list) {
            if (panError.error_item === itemNumber) {
              errorList.push(panError)
            }
          }
        }
      }
      this.viewErrorWarning(row, errorList, legalRuleUrl)
    },
    viewErrorWarning (row, errorList, legalRuleUrl) {
      console.log(errorList)
      for (const data of errorList) {
        let error = []
        let oneRecord = {}
        if (data.data_unit === 1) {
          // HH 単位[時]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.hours'),
            column3: data.actual_time + this.$t('label.hours'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 2) {
          // HHMM 単位[時分]
          oneRecord = {
            column1: data.target_date,
            column2: this.getHhmm(data.agreement),
            column3: this.getHhmm(data.actual_time),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 3) {
          // MM 単位[分]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.minute'),
            column3: data.actual_time + this.$t('label.minute'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 4) {
          // YYYY 単位[年]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.year'),
            column3: data.actual_time + this.$t('label.year'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 5) {
          // YYYYMM 単位[年月]
          oneRecord = {
            column1: data.target_date,
            column2: this.getYyyymm(data.agreement),
            column3: this.getYyyymm(data.actual_time),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 6) {
          // DAY 単位[日]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.day'),
            column3: data.actual_time + this.$t('label.day'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 7) {
          // COUNT 単位[回数]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.count'),
            column3: data.actual_time + this.$t('label.count'),
            column4: data.pan_error_contents
          }
        } else if (data.data_unit === 8) {
          // YEN 単位[円]
          oneRecord = {
            column1: data.target_date,
            column2: data.agreement + this.$t('label.yen'),
            column3: data.actual_time + this.$t('label.yen'),
            column4: data.pan_error_contents
          }
        }
        error.push(oneRecord)
        let twoRecord = {
          column1: data.chapter_contents,
          column2: data.article_contents,
          column3: data.term_contents,
          column4: data.issue_contents,
          row: [
            {
              target_date: row.target_date
            }
          ]
        }
        error.push(twoRecord)
        this.errorList.push(error)
      }
      this.legal_rule_url = legalRuleUrl
      this.$modal.show('warning')
    },
    getHhmm (minute) {
      let hour = parseInt(minute / 60, 10)
      minute = minute % 60
      let strMinute = ''
      if (minute <= 9) {
        strMinute = '0' + String(minute)
      } else {
        strMinute = String(minute)
      }
      return hour + ':' + strMinute
    },
    getYyyymm (value) {
      let strValue = String(value)
      let result = strValue.slice(0, 4) + this.$t('label.year') + strValue.slice(-2) + this.$t('label.month')
      return result
    },
    hide (...args) {
      this.$modal.hide('detail')
    },
    hideCondition (...args) {
      this.$modal.hide('monitoringConditions')
    },
    hideWarning (...args) {
      this.errorList = []
      this.$modal.hide('warning')
    }
  },
  created () {
    this.init()
  }
}
