<template>
  <div class="form-group">
    <!-- 同じ名称の項目が複数存在する場合、最後のバリデーション結果のみ評価されるため、nameに乱数を付加する。 -->
    <ValidationProvider ref="provider" :bails="false" v-slot="{ valid, errors }" :rules="rules" :name="labels.closing + '|' + new Date().getTime().toString(16)">
      <template v-if="!valid">
        <div v-for="error in errors" v-bind:key="error">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(error) }}
          </small>
        </div>
      </template>
      <ClosingDateTable 
        v-model="tableData"
        :item-name="itemName"
        :classes="classes"
        :labels="labels"
        :placeholder="placeholder"
        :disabled="disabled"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import mixin from '@/lib/mixin'
  import ClosingDateTable from '@/components/UIComponents/Inputs/ClosingDateTable'

  export default {
    mixins: [mixin],
    components: {
      ClosingDateTable
    },
    props: {
      value: Array,
      itemName: String,
      classes: String,
      rules: String,
      labels: Object,
      placeholder: String,
      disabled: Boolean
    },
    data () {
      return {
        tableData: []
      }
    },
    watch: {
      startEndText () {
        this.$emit('input', this.tableData)
      },
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>