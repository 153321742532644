<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <MessageArea :page-type="pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame" :key="componentKey">
          <ValidationObserver ref="observer">
            <div  v-for="(setting_list) of detail.setting_list" :key="setting_list.attendance_type">
              <!-- アラート通知区分 -->
              <big><strong>{{getLabel(getSelectName(setting_list.attendance_type, comboData.attendance_type_list))}}</strong></big>
              <!-- アラート管理 -->
              <ValidationRadio
                v-model="setting_list.alert_management_control"
                :item-label="labels.alert_management_control"
                rules="required"
                :disabled="isReadOnly"
                @change="alertManagementControlChanged"
              />
              <!-- アラート通知方法 -->
              <template v-if="setting_list.alert_management_control.value===1 | setting_list.alert_management_control.value==='1'">
                <ValidationTimes
                  v-model="setting_list.relative_time"
                  item-name="job_total_minutes"
                  classes="select-success length2"
                  rules="required"
                  :item-label="labels.relative_time"
                  :disabled="isReadOnly"
                  :labels="labels"
                  :maxHour=3
                  data-v-step="9"
                />
              </template>
              <hr width="300" align="left" style="border: 1px solid #ddd;">
            </div>
          </ValidationObserver>
        </div>
      </div>
      <template v-if="!this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import ForgotImprintMixin from '@/components/Dashboard/Views/Master/ForgotImprintMixin'

  export default {
    name: 'ForgotImprint',
    mixins: [ForgotImprintMixin]
  }
</script>
<style scoped>
</style>
